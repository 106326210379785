import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Row, Col, Card, CardBody } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvForm } from "availity-reactstrap-validation";
import { isNullOrUndefined } from "utils/validations";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { showAlert } from "components/molecules/Alert/Alert";
import { useListContext } from "contextAPI/ListsContext";
import { loaderElement } from "utils/loaderElement";
import { showAlertServiceError } from "utils/alerts";
import cx from "classnames";
import Loader from "react-loaders";
import useForm from "hooks/useForm";
import useRecord from "hooks/useRecord";
import swal from "sweetalert";
import i18n from "locales/i18n";

import TextBox from "components/molecules/Fields/Strings/TextBox";
import TextArea from "components/molecules/Fields/Strings/TextArea";
import ComboBox from "components/molecules/Fields/Strings/ComboBox";
import Lookup from "components/molecules/Fields/Strings/Lookup";
import Label from "components/molecules/Fields/Strings/Label";
import NumericTextBox from "components/molecules/Fields/Integer/NumericTextBox";
import DatePicker from "components/molecules/Fields/Date/DatePicker";
import DateTimePicker from "components/molecules/Fields/DateTime/DateTimePicker";
import FieldStepConfigFooter from "components/molecules/FieldStepConfigFooter";

const ProcessFillingForms = (props) => {
  const {
    formName,
    recordNumber,
    processTaskId,
    recordUuid,
    formUuid,
    selectedFields,
    setShowFormsFillingProcess,
    showExternalFormFillingProcess,
    setShowExternalFormsFillingProcess,
    isFilingInBatch,
    isLoadingComplyProcessBatch,
  } = props;
  const [fieldsStepConfig, setFieldsStepConfig] = useState([]);
  const [validateRequiredPickers, setValidateRequiredPickers] = useState({});
  const [fieldValue, setFieldValue] = useState({});
  const [isLoadingFillingForm, setIsLoadingFillingForm] = useState(false);
  const [isLoadingDataForm, setIsLoadingDataForm] = useState(false);
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);
  const [actionProcessConfig] = useState(0);
  const [formFields, setFormFields] = useState([]);
  const [headerFieldRequest] = useState({ header: "filling_in_form_fields" });
  const [requiredDates, setRequiredDates] = useState({});
  const [
    disabledSubmitRecordInBatch,
    setDisabledSubmitRecordInBatch,
  ] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const {
    validationEditField,
    setValidationEditField,
    setValidationFieldDatePicker,
  } = useFieldsRecordContext();
  const { setCanNext } = useListContext();
  const {
    getFieldsStepWorkflowConfig,
    createRecordStepWorkFlowConfig,
    updateFieldsStepWorkFlowConfigBatch,
    updateFieldsStepWorkFlowConfig,
  } = useForm();
  const { getRecordsById } = useRecord();
  const allfieldsContainsUniqueTrue = fieldsStepConfig.every(
    (field) => field.unique === true
  );

  /**
   * Generates a JSX element representing the name of the form filling step.
   * @constant {JSX.Element} nameFormFillingInStep - JSX element containing form name, record data label, and record number.
   * @param {string} formName - The name of the form being filled.
   * @param {string} recordNumber - The number associated with the record data.
   * @param {object} i18n - The internationalization object providing translation capabilities.
   * @returns {JSX.Element} - A JSX element representing the name of the form filling step.
   */
  let nameFormFillingInStep = (
    <h5 className="text-info font-weight-bold">
      {formName} - {i18n.t("recordData8")}: {recordNumber}
    </h5>
  );

  /**
   * Generates an alert component indicating that all fields are unique.
   * @constant {JSX.Element} alertAllFieldsAreUnique - The JSX element representing the alert.
   * @param {string} className - The CSS class name to be applied to the alert component.
   * @param {string} color - The color variant of the alert component (e.g., "info", "warning", "success", "danger").
   * @param {string} icon - The icon to be displayed alongside the alert message.
   * @param {string} message - The message to be displayed within the alert component.
   * @returns {JSX.Element} The alert component indicating that all fields are unique.
   */
  let alertAllFieldsAreUnique = (
    <Alert className="mbg-3 mb-4" color="info">
      <span className="pr-2">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      {i18n.t("RecordForm.Alert")}
    </Alert>
  );

  /**
   * Conditionally renders the name of the form for filling based on certain conditions.
   * @param {boolean} showExternalFormFillingProcess - A flag indicating whether the form filling process is being shown externally.
   * @param {boolean} isFilingInBatch - A flag indicating whether the filing is being done in batch mode.
   * @param {string} formName - The name of the form to be displayed.
   * @returns {JSX.Element|null} The JSX element representing the form name if conditions are met, otherwise null.
   */
  if (showExternalFormFillingProcess === true || isFilingInBatch === true) {
    nameFormFillingInStep = (
      <h5 className="text-info font-weight-bold">{formName}</h5>
    );
  }

  /**
   * Generates an alert component if all fields contain unique values and the filing is in batch mode.
   * @param {boolean} allfieldsContainsUniqueTrue - Indicates whether all fields contain unique values.
   * @param {boolean} isFilingInBatch - Indicates whether the filing is in batch mode.
   * @returns {JSX.Element|null} The alert component indicating that all fields contain unique values and filing is in batch mode,
   * or null if the conditions are not met.
   */
  if (allfieldsContainsUniqueTrue === true && isFilingInBatch === true) {
    alertAllFieldsAreUnique = (
      <Alert className="mbg-3 mb-4 " color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")}
            {": "}
          </span>
          <span>{i18n.t("taskBatchProcess.AlertFilingFormUniqueFields")}</span>
        </span>
      </Alert>
    );
  }

  /**
   * This function is used to set the fields configured from the field filling configuration.
   * @param {string} processTaskId - Text string that is responsible for bringing the task ID of a process.
   * @returns {void}
   */
  const getFieldsByStep = useCallback(() => {
    setIsLoadingFillingForm(true);

    if (showExternalFormFillingProcess === true) {
      getFieldsStepWorkflowConfig(processTaskId, true)
        .then((response) => {
          const fieldsList = response.data.data.list_of_fields;
          if (isNullOrUndefined(fieldsList) === false) {
            const newFieldByForm = fieldsList.filter(
              (field) => field.status === 1
            );
            setFieldsStepConfig(newFieldByForm);
            const requiredDateFields = fieldsList.filter((field) => {
              return (
                (field.control_data === 6 || field.control_data === 7) &&
                field.required
              );
            });

            const datesReuired = requiredDateFields.reduce(
              (datesResults, date) => {
                datesResults[date.uuid] = date.uuid;
                return datesResults;
              },
              {}
            );

            setRequiredDates(datesReuired);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFillingForm(false);
        });
    } else {
      getFieldsStepWorkflowConfig(processTaskId, false)
        .then((response) => {
          const fieldsList = response.data.data.list_of_fields;
          if (isNullOrUndefined(fieldsList) === false) {
            const newFieldByForm = fieldsList.filter(
              (field) => field.status === 1
            );
            setFieldsStepConfig(newFieldByForm);
            const requiredDateFields = fieldsList.filter((field) => {
              return (
                (field.control_data === 6 || field.control_data === 7) &&
                field.required
              );
            });

            const datesReuired = requiredDateFields.reduce(
              (datesResults, date) => {
                datesResults[date.uuid] = date.uuid;
                return datesResults;
              },
              {}
            );

            setRequiredDates(datesReuired);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFillingForm(false);
        });
    }
  }, [processTaskId, showExternalFormFillingProcess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * function to get the data of a process.
   * @param {string} formUuid - string that receives save the id of the formId.
   * @param {string} recordUuid - string that receives save the id of the recordId.
   * @param {string} actionProcessConfig - string that remains with zero to have the query to the endpoint.
   * @returns {void}
   */
  const getRecordByStep = useCallback(() => {
    setIsLoadingDataForm(true);
    if (showExternalFormFillingProcess === false && isFilingInBatch !== true) {
      getRecordsById(formUuid, recordUuid, actionProcessConfig)
        .then((response) => {
          const recordData = response.data;
          if (isNullOrUndefined(recordData) === false) {
            setFieldValue(recordData);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingDataForm(false);
        });
    }
  }, [formUuid, recordUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles key press events for field submission, preventing the default behavior if the pressed key is Enter.
   * @param {object} enterEventFieldSubmit - The key press event object for the field submission.
   * @param {string} enterEventFieldSubmit.key - The key that was pressed (e.g., "Enter").
   * @returns {void}
   */
  const handleOnKeyPressFieldSubmit = (enterEventFieldSubmit) => {
    if (enterEventFieldSubmit.key === "Enter") {
      enterEventFieldSubmit.preventDefault();
    }
  };

  /**
   * Function to handle the for to save the configured fields.
   * @param {string} fieldUuid - string that stores the id value of the field to configure.
   * @param {string} value - string that stores the value in a string.
   * @returns {Promise} - A promise that resolves to the server's response.
   */
  const handleChangeField = (fieldUuid, value) => {
    const fieldIndex = formFields.findIndex(
      (field) => field.field_uuid === fieldUuid
    );
    if (fieldIndex !== -1) {
      setFormFields(
        formFields.map((field) => {
          if (field.field_uuid === fieldUuid) {
            return { field_uuid: fieldUuid, value };
          } else {
            return field;
          }
        })
      );
    } else {
      setFormFields([...formFields, { field_uuid: fieldUuid, value }]);
    }
  };

  /**
   * Retrieves an array of UUIDs for fields that require validation based on specific criteria.
   * This function filters the fieldsStepConfig array based on the following criteria:
   * The field status is set to 1.
   * The control_data is either 6 or 7.
   * The field is marked as required.
   * The function then maps the filtered fields to their respective UUIDs and returns the resulting array.
   * @returns {string[]} An array of UUIDs for fields that require validation.
   */
  const getIdsToValidate = () => {
    return fieldsStepConfig
      .filter(
        (field) =>
          field.status === 1 &&
          (field.control_data === 6 || field.control_data === 7) &&
          field.required === true
      )
      .map((field) => field.uuid);
  };

  /**
   * Validates field IDs based on the presence of corresponding form fields and non-null values.
   * @returns {Array<string>} idsToValidate - An array of IDs to be validated.
   * @returns {Array<string>} idsConValueNullFormFields - IDs from `idsToValidate` without corresponding form fields.
   * @returns {Array<string>} invalidIds - IDs with null or empty values in the `fieldValue` object.
   */
  const idsToValidate = getIdsToValidate();
  const idsConValueNullFormFields = idsToValidate.filter(
    (id) => !formFields.some((element) => element.field_uuid === id)
  );
  const invalidIds = idsConValueNullFormFields.filter(
    (id) => !fieldValue[id] || fieldValue[id] === null || fieldValue[id] === ""
  );

  /**
   * Handles the submission configuration for sending form field data.
   * This function manages the submission configuration for sending form field data. It performs various checks and operations based on the provided parameters.
   * If the `showExternalFormFillingProcess` is false and there are no form fields, it displays a warning alert and prevents the default form submission.
   * If there are invalid field IDs (`invalidIds`), it sets validation for date pickers, prevents the default form submission, and returns.
   * If there are required pickers to validate (`validateRequiredPickers`), it prevents the default form submission and returns.
   * If there are no field errors, it constructs a configured step object and triggers the submission process.
   * If `showExternalFormFillingProcess` is true, it creates a new record step using `createRecordStepWorkFlowConfig`.
   * If `showExternalFormFillingProcess` is false, it updates the fields step using `updateFieldsStepWorkFlowConfig`.
   * The function also handles various error scenarios and displays appropriate alerts.
   * @param {Event} eventFieldSubmit - The submission event associated with the form fields.
   * @param {Array} fieldErrors - An array containing errors related to the form fields.
   * @returns {void}
   */
  const handleSubmitSendConfigField = (eventFieldSubmit, fieldErrors) => {
    if (!showExternalFormFillingProcess && formFields.length === 0) {
      showAlert({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("form.alertWarning"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      }).then(() => eventFieldSubmit.preventDefault());
      return;
    }

    if (invalidIds.length > 0) {
      setValidationFieldDatePicker({ required: true, id: invalidIds });
      eventFieldSubmit.preventDefault();
      return;
    }

    if (Object.keys(validateRequiredPickers).length > 0) {
      eventFieldSubmit.preventDefault();
      return;
    }

    if (fieldErrors.length === 0) {
      let { header } = headerFieldRequest;
      let configuredStep = {
        [header]: formFields,
      };

      if (isFilingInBatch === true) {
        configuredStep = {
          [header]: formFields,
          process_task_uuid: selectedFields,
        };
      }

      setIsLoadingSubmitForm(true);
      const handleError = (error) => {
        if (error.response !== undefined) {
          if (error.response.data.code === "500032") {
            showAlert({
              text:
                i18n.t("form.external.config.alert") +
                " " +
                error.response.data.data,
              icon: "error",
            }).then(() => window.location.reload());
          }

          if (error.response.data.code === "500033") {
            showAlert({
              text: i18n.t("form.external.config.alert2"),
              icon: "error",
            }).then(() => window.location.reload());
          }
        }

        if (
          error.response !== undefined &&
          error.response.data.code === "30010"
        ) {
          showAlert({
            text: i18n.t("form.external.config.alert3"),
            icon: "error",
          }).then(() => window.location.reload());
        }

        if (error) {
          setValidationEditField({
            id: Object.keys(error.response.data.data),
            unique: true,
          });
        } else {
          return null;
        }
      };

      if (showExternalFormFillingProcess === true && isFilingInBatch !== true) {
        createRecordStepWorkFlowConfig(processTaskId, configuredStep)
          .then((response) => {
            showAlert({
              title: response.data.data.record,
              text: i18n.t("recordCreated"),
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(handleError)
          .finally(() => {
            setIsLoadingSubmitForm(false);
          });
      }

      if (
        showExternalFormFillingProcess === false &&
        isFilingInBatch === true
      ) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("taskBatchProcess.AlertFilingForm"),
          icon: "warning",
          buttons: [
            i18n.t("createusers.createButton2"),
            i18n.t("modal.Done.footerButton"),
          ],
        }).then((willClose) => {
          if (willClose) {
            setIsLoadingSubmitForm(true);
            updateFieldsStepWorkFlowConfigBatch(configuredStep)
              .then((response) => {
                if (response.status === 202) {
                  setDisabledSubmitRecordInBatch(true);
                  setOverlayVisible(true);
                  setCanNext(false);
                  showAlert({
                    text: i18n.t("form.fieldlist.status.alert"),
                  }).then(() => {
                    setIsLoadingSubmitForm(false);
                  });
                }
              })
              .catch(handleError)
              .finally(() => {
                setIsLoadingSubmitForm(false);
              });
          } else {
            eventFieldSubmit.preventDefault();
            setIsLoadingSubmitForm(false);
          }
        });
      }

      if (
        showExternalFormFillingProcess === false &&
        isFilingInBatch === false
      ) {
        updateFieldsStepWorkFlowConfig(processTaskId, configuredStep)
          .then(() => {
            showAlert({
              text: i18n.t("form.fieldlist.status.alert"),
            }).then(() => {
              setIsLoadingSubmitForm(false);
              window.location.reload();
            });
          })
          .catch(handleError)
          .finally(() => {
            setIsLoadingSubmitForm(false);
          });
      }
    }
  };

  /**
   * useEffect hook that updates the 'canNext' state based on the value of 'isFilingInBatch'.
   * If 'isFilingInBatch' is true, 'canNext' is set to true.
   * @param {boolean} isFilingInBatch - Indicates whether the filing is being done in batch.
   * If true, 'canNext' will be set to true.
   * @param {function} setCanNext - State setter function to update the 'canNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (isFilingInBatch === true) {
      setCanNext(true);
    }
  }, [isFilingInBatch, setCanNext]);

  /**
   * This effect It is used to load the initial data of the application
   * @param {funcion} getFieldsByStep - function to load the list of fields when loading the page.
   * @param {funcion} getRecordByStep - function to obtain the processes according to the step.
   * @returns {void}
   */
  useEffect(() => {
    const promises = [getFieldsByStep(), getRecordByStep()];
    Promise.all(promises);
  }, [getFieldsByStep, getRecordByStep]);

  /**
   * A React useEffect hook that calculates and updates the required date pickers validation status.
   * This hook evaluates each date field in the provided formFields array and checks if its value is null
   * while also checking if it is marked as required in the requiredDates object. If a date field is found
   * to be both null and required, it is recorded in the mandatoryDates object for validation.
   * @param {Array} formFields - An array containing the date fields to be validated.
   * @param {Object} requiredDates - An object containing the UUIDs of the required date fields.
   * @param {function} setValidateRequiredPickers - State setter function for updating required pickers validation status.
   * @returns {void}
   */
  useEffect(() => {
    const mandatoryDates = {};
    formFields.forEach((date) => {
      if (date.value === null && requiredDates[date.field_uuid] !== undefined) {
        mandatoryDates[date.field_uuid] = date.field_uuid;
      }
    });
    setValidateRequiredPickers(mandatoryDates);
  }, [formFields, requiredDates]);

  /**
   * A useEffect hook that updates the state for disabling the submit record in batch based on certain conditions.
   * @param {boolean} allfieldsContainsUniqueTrue - A boolean indicating whether all fields contain unique values.
   * @param {boolean} isFilingInBatch - A boolean indicating whether the filing is being done in batch.
   * @param {function} setDisabledSubmitRecordInBatch - A state setter function for updating the disabled state of the submit record in batch.
   * @returns {void}
   */
  useEffect(() => {
    if (allfieldsContainsUniqueTrue === true && isFilingInBatch === true) {
      setDisabledSubmitRecordInBatch(true);
    } else {
      setDisabledSubmitRecordInBatch(false);
    }
  }, [allfieldsContainsUniqueTrue, isFilingInBatch]);

  return (
    <Fragment>
      {loaderElement(isLoadingSubmitForm || isLoadingComplyProcessBatch)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            <Card
              className={cx("main-card mb-3", {
                "forms-fields": isFilingInBatch === true,
              })}
            >
              <div className="form-container">
                <CardBody>
                  {nameFormFillingInStep}

                  <div className="divider" />

                  {alertAllFieldsAreUnique}

                  {(() => {
                    if (
                      isLoadingFillingForm === true &&
                      isLoadingDataForm === true
                    ) {
                      return (
                        <div className="text-center mx-auto my-auto">
                          <div className="loader-wrapper d-flex justify-content-center align-items-center">
                            <Loader color="#0072BC" type="ball-pulse-rise" />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <AvForm onSubmit={handleSubmitSendConfigField}>
                          <div className="pl-5">
                            {fieldsStepConfig.map((fieldConfig) => {
                              switch (fieldConfig.control_data) {
                                case 1:
                                  let fieldTextUniqueInBatch = false;
                                  if (isFilingInBatch === true) {
                                    fieldTextUniqueInBatch = fieldConfig.unique;
                                  }
                                  return (
                                    <TextBox
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      unique={validationEditField.unique}
                                      uniqueFilingInBatch={
                                        fieldTextUniqueInBatch
                                      }
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      minLength={fieldConfig.len_min_chars}
                                      maxLength={fieldConfig.len_max_chars}
                                      handleOnChangeRecord={handleChangeField}
                                      handleOnKeyPress={
                                        handleOnKeyPressFieldSubmit
                                      }
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 2:
                                  return (
                                    <TextArea
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      minLength={fieldConfig.len_min_chars}
                                      maxLength={fieldConfig.len_max_chars}
                                      handleOnChangeRecord={handleChangeField}
                                      handleOnKeyPress={
                                        handleOnKeyPressFieldSubmit
                                      }
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 3:
                                  return (
                                    <ComboBox
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      isFilterForm={false}
                                      itemLink={fieldConfig.item_link}
                                      handleOnChangeRecord={handleChangeField}
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 4:
                                  return (
                                    <div className="mb-3">
                                      <Label
                                        id={fieldConfig.uuid}
                                        label={fieldConfig.label}
                                        hide={false}
                                      />
                                    </div>
                                  );
                                case 5:
                                  let fieldNumericUniqueInBatch = false;
                                  if (isFilingInBatch === true) {
                                    fieldNumericUniqueInBatch =
                                      fieldConfig.unique;
                                  }

                                  return (
                                    <NumericTextBox
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      unique={validationEditField.unique}
                                      uniqueFilingInBatch={
                                        fieldNumericUniqueInBatch
                                      }
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      allowedNegative={
                                        fieldConfig.allowed_negative
                                      }
                                      handleOnChangeRecord={handleChangeField}
                                      handleOnKeyPress={
                                        handleOnKeyPressFieldSubmit
                                      }
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 6:
                                  return (
                                    <DatePicker
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      isFilterForm={false}
                                      edit_manually={fieldConfig.edit_manually}
                                      show_pass_dates={
                                        fieldConfig.show_pass_dates
                                      }
                                      handleOnChangeRecord={handleChangeField}
                                      showExternalFormFillingProcess={
                                        showExternalFormFillingProcess
                                      }
                                      validateRequiredPickers={
                                        validateRequiredPickers
                                      }
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 7:
                                  return (
                                    <DateTimePicker
                                      id={fieldConfig.uuid}
                                      label={fieldConfig.label}
                                      hide={false}
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      isFilterForm={false}
                                      edit_manually={fieldConfig.edit_manually}
                                      show_pass_dates={
                                        fieldConfig.show_pass_dates
                                      }
                                      handleOnChangeRecord={handleChangeField}
                                      showExternalFormFillingProcess={
                                        showExternalFormFillingProcess
                                      }
                                      validateRequiredPickers={
                                        validateRequiredPickers
                                      }
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );
                                case 8:
                                  return (
                                    <Lookup
                                      id={fieldConfig.uuid}
                                      hide={false}
                                      label={fieldConfig.label}
                                      required={fieldConfig.required}
                                      isPreview={false}
                                      isFilterForm={false}
                                      isEdit={true}
                                      handleOnChangeRecord={handleChangeField}
                                      handleOnKeyPress={
                                        handleOnKeyPressFieldSubmit
                                      }
                                      formLookupName={
                                        fieldConfig.form_lookup_name
                                      }
                                      formLookupFields={
                                        fieldConfig.form_lookup_fields
                                      }
                                      formUuid={fieldConfig.form_uuid}
                                      value={fieldValue[fieldConfig.uuid]}
                                    />
                                  );

                                default:
                                  return null;
                              }
                            })}
                          </div>
                          <FieldStepConfigFooter
                            isFilingInBatch={isFilingInBatch}
                            disabledSubmitRecordInBatch={
                              disabledSubmitRecordInBatch
                            }
                            isLoadingFillingForm={isLoadingSubmitForm}
                            setShowFormsFillingProcess={
                              setShowFormsFillingProcess
                            }
                            setShowExternalFormsFillingProcess={
                              setShowExternalFormsFillingProcess
                            }
                            setFormFields={setFormFields}
                            setValidationFieldDatePicker={
                              setValidationFieldDatePicker
                            }
                          />
                        </AvForm>
                      );
                    }
                  })()}
                  {overlayVisible && (
                    <div className="overlay">
                      <div className="overlay-content">
                        <p className="text-info font-weight-bold">
                          {i18n.t("form.fieldlist.status.alert")}
                        </p>
                      </div>
                    </div>
                  )}
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ProcessFillingForms.propTypes = {
  formName: PropTypes.string.isRequired,
  recordNumber: PropTypes.string,
  processTaskId: PropTypes.string.isRequired,
  recordUuid: PropTypes.string,
  formUuid: PropTypes.string,
  selectedFields: PropTypes.array,
  setShowFormsFillingProcess: PropTypes.func.isRequired,
  showExternalFormFillingProcess: PropTypes.bool.isRequired,
  setShowExternalFormsFillingProcess: PropTypes.func,
  isFilingInBatch: PropTypes.bool,
  isLoadingComplyProcessBatch: PropTypes.bool,
};

export default ProcessFillingForms;
