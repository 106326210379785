import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { buildAlertContent, processErrorData } from "utils/processErrorData";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { enumsFormStatus } from "utils/enums";
import useForms from "hooks/useForm";
import Loader from "react-loaders";
import cx from "classnames";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionsFields = (props) => {
  const { fieldId, formId, hasRecords, status, editFields, count, fields } = props;
  const [isLoadingField, setIsLoadingField] = useState(false);
  const { deleteFields } = useForms();
  const { statusForm, setIsLoadingDeleteField } = useFieldsRecordContext();
  const hashCount = window.btoa(count);
  const hashHasRecord = window.btoa(hasRecords);
  let disabledHandleOnDelete = null;
  let disabledHandleOnEdit = null;

  /**
   * Determines whether the 'handleOnDelete' action should be disabled based on the provided conditions.
   * @param {boolean} hasRecords - A flag indicating whether there are records to delete.
   * @param {number} status - The status code used to determine whether the action should be disabled.
   * @returns {boolean} - True if the 'handleOnDelete' action should be disabled, otherwise false.
   */
  if (status === enumsFormStatus.FORMS_STATUS_ACTIVE ||
    statusForm.status !== enumsFormStatus.FORMS_STATUS_ERASER ||
    (fields.length === 1 && hasRecords) === true) {
    disabledHandleOnDelete = true;
  } else {
    disabledHandleOnDelete = false;
  }

  /**
   * Determines the value of 'disabledHandleOnEdit' based on the 'editFields' condition.
   * @param {boolean} editFields - A boolean flag indicating whether editing is enabled or not.
   * @returns {boolean} - The value of 'disabledHandleOnEdit' based on the 'editFields' condition.
   */
  if (editFields === true) {
    disabledHandleOnEdit = false;
  } else {
    disabledHandleOnEdit = true;
  }

  /**
   * Deletes a field associated with a form and handles success and error cases with notifications.
   * @returns {void}
   * @throws {Error} Throws an error if the deletion process encounters an issue.
   */
  const deleteField = () => {
    setIsLoadingDeleteField(true);
    deleteFields(formId, fieldId)
      .then((response) => {
        if (response.status === 200) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("deleteField.Success"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        if (isNullOrUndefined(error.response.data.data) === false) {
          let errorObject = processErrorData(error.response.data.data);
          let alertErrors = buildAlertContent(errorObject);
          swal({
            title: i18n.t("modal.DoneError.header"),
            content: {
              element: "div",
              attributes: {
                innerHTML: alertErrors,
                className: "custom-content-class custom-alert",
              },
            },
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingDeleteField(false);
      });
  };

  /**
   * Handles displaying a success message using a sweet alert when editing fields.
   * @returns {object|null} - Returns a SweetAlert2 instance with a success message if 'editFields' is true; otherwise, returns null.
   */
  const handleEditFields = () => {
    if (editFields === true) {
      return swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("deleteField.Success"),
        icon: "success",
        button: i18n.t("modal.Done.footerButton"),
      });
    } else {
      return null;
    }
  };

  /**
   * Handles the deletion of a field in a form.
   * This function displays a confirmation dialog to the user and deletes the field
   * if the user confirms. It also handles loading state and prevents the default
   * event behavior if necessary.
   * @param {Event} eventDeleteField - The event object for the delete action.
   * @returns {void}
   */
  const handleOnDeleteField = (eventDeleteField) => {
    setIsLoadingField(true);
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("deleteField.Alert"),
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willDelete) => {
      if (willDelete) {
        if (isLoadingField === true)
          return (
            <div>
              <div className="loader-wrapper d-flex justify-content-center align-items-center">
                <Loader color="primary" type="ball-pulse-rise" />
              </div>
            </div>
          );
        deleteField(formId, fieldId);
      } else {
        eventDeleteField.preventDefault();
        setIsLoadingField(false);
      }
    });
  };

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem disabled={disabledHandleOnEdit}>
          <Link
            disabled={disabledHandleOnEdit}
            onClick={() => handleEditFields}
            to={`/configuration/edit/${fieldId}/${formId}/${hashHasRecord}`}
          >
            <i
              className={cx("dropdown-icon lnr-pencil ", {
                item_disabled: disabledHandleOnEdit === true,
              })}
            />

            <span
              className={cx("", {
                item_disabled: disabledHandleOnEdit === true,
              })}
            >
              {i18n.t("buttonActions.option2")}
            </span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/configuration/order/${fieldId}/${hashCount}/${formId}`}>
            <i className="dropdown-icon lnr-pencil" />
            <span>{i18n.t("form.order")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link
            to={`/configuration/consult/${fieldId}/${formId}/${editFields}/${hashHasRecord}`}
          >
            <i className="dropdown-icon lnr-cog" />
            <span>{i18n.t("form.config")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem disabled={disabledHandleOnDelete}>
          <Link to="#">
            <i
              className={cx("dropdown-icon lnr-trash", {
                "text-danger": disabledHandleOnDelete === false,
                item_disabled: disabledHandleOnDelete === true,
              })}
            />
            <span
              className={cx("", {
                item_disabled: disabledHandleOnDelete === true,
              })}
              onClick={handleOnDeleteField}
            >
              {i18n.t("buttonActions.option3")}
            </span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsFields.propTypes = {
  fieldId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  hasRecords: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  editFields: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

export default ButtonActionsFields;
