import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Col,
  CardFooter,
  Label,
  Card,
  CardBody,
  CardTitle,
  Spinner
} from "reactstrap";
import { useParams } from "react-router-dom";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Loader } from "react-loaders";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const DocumentaryTypeEditions = () => {
  const params = useParams();
  const { updateDocumentaryType } = useTrd();
  const [documentaryName, setDocumentaryName] = useState({
    name: ""
  });
  const [isLoadingDocumentaryEdition, setIsLoadingDocumentaryEdition] = useState(false);
  const documentary = window.atob(params.documentaryName);
  const documentaryObject = JSON.parse(documentary);

  let loaderElement = null;

  /**
   * This function handles the submission of an edited documentary type and displays success or error
   * messages accordingly.
   * @param {string} documentaryId - The ID of the documentary.
   * @param {string} documentaryName - The name of the documentary.
   * @return {void}
   */

  const handleSubmitDocumentaryEdition = (eventSubmitDocumentaryType, errors) => {
    eventSubmitDocumentaryType.preventDefault();
    if (errors.length === 0) {
      if (documentaryName.name === documentaryObject.name) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "warning",
          button: i18n.t("modal.Done.footerButton"),
        })
        return
      }
      setIsLoadingDocumentaryEdition(true)
      updateDocumentaryType(params.documentaryId, documentaryName)
        .then(() => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("documentary.type.alert.edit"),
            icon: "success",
            timer: 3000,
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location.href = "/documentary-types/list"
          })
        })
        .catch(() => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("documentary.type.alert.alredy.exist"),
            icon: "warning",
            button: i18n.t("modal.Done.footerButton"),
          });
        })
        .finally(() => setIsLoadingDocumentaryEdition(false))
    }
  }

  /**
   * This function updates the state of a documentary name based on user input.
   * @param eventChangeDocumentaryName - It is an event object that is passed as an argument to the
   * handleChangeDocumentaryName function. It represents the event that occurred when the user changes
   * the value of an input field.
   */

  const handleChangeDocumentaryName = (eventChangeDocumentaryName) => {
    setDocumentaryName({
      ...documentaryName,
      [eventChangeDocumentaryName.target.name]: eventChangeDocumentaryName.target.value
    })
  }

  /**
  * Handles the event of loss of focus in the edition of the name of the documentary.
  * @param {Object} eventChangeDocumentaryName - The documentary name change event.
  * @returns {void}
  */

  const handleOnBlurDocumentaryEdition = (eventChangeDocumentaryName) => {
    setDocumentaryName({
      ...documentaryName,
      [eventChangeDocumentaryName.target.name]: eventChangeDocumentaryName.target.value.trim()
    })
  };

  /**
  * Handles keypress event on documentary name edit.
  * Prevents an action from being performed if the key pressed is "Enter".
  * @param {Object} eventDocumentaryName - The documentary name event.
  * @returns {void}
  */

  const handleOnKeyPressDocumentaryEdition = (eventDocumentaryName) => {
    if (eventDocumentaryName.key === "Enter") {
      eventDocumentaryName.preventDefault();
    }
  };

  /**  The `useEffect` hook is used to perform side effects in a functional component. In this case, it is
   * used to set the initial state of the `documentaryName` variable to the value of `documentaryObject`
   * when the component mounts. 
   * @return {void}
   */

  useEffect(() => {
    setDocumentaryName(documentaryObject)
    // eslint-disable-next-line
  }, [])

  /**
   * Renders a loader element if the loader state is true.
   * @param {boolean} loader - Indicates whether the loader should be displayed.
   * @returns {JSX.Element|null} Loader element JSX if loader state is true, otherwise null.
   */
  if (isLoadingDocumentaryEdition === true) {
    loaderElement = (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="primary" type="ball-pulse-rise" />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loaderElement}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{i18n.t("documentary.type.table.label6.1")}</CardTitle>
            <AvForm onSubmit={handleSubmitDocumentaryEdition} >
              <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>
              <AvGroup row>
                <Label for="name" className="is-required" sm={2}>
                  {i18n.t("documentary.type.table.label7")}
                </Label>
                <Col md={10}>
                  <AvField
                    onChange={handleChangeDocumentaryName}
                    onKeyPress={handleOnKeyPressDocumentaryEdition}
                    onBlur={handleOnBlurDocumentaryEdition}
                    type="text"
                    name="name"
                    id="name"
                    data-tip
                    data-for="name"
                    validate={{
                      pattern: {
                        value: "/^([A-Za-z0-9 À-ÿ-_]+)$/",
                        errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("documentary.type.label2.error")}`,
                      },
                      minLength: {
                        value: 4,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: 100,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={documentaryName.name}
                  />
                </Col>
              </AvGroup>

              <CardFooter className="d-block text-right">
                <Link to="/documentary-types/list">
                  <Button size="lg" className="col-mt-3 mr-3" color="gray">
                    {i18n.t("createusers.createButton2")}
                  </Button>
                </Link>
                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingDocumentaryEdition}
                  className="col-mt-3"
                  color="cyan"
                >
                  {(() => {
                    if (isLoadingDocumentaryEdition === true) {
                      return (<Spinner size="sm" color="secondary" type="grow" />);
                    }
                  })()}
                  {i18n.t("trd.buttonUptade")}

                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DocumentaryTypeEditions;
