import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Label, Col, CustomInput, Alert, Card } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { useListContext } from "contextAPI/ListsContext";
import { regexLenCharsMinMax, regexLenMinChars } from "utils/regexExpressions";
import i18n from "locales/i18n";

const LenCharsMinMax = (props) => {
  const { isTextArea } = props;
  const { handleOnChange, setFieldForm, fieldForm } = useFieldsRecordContext();
  const { setCanNext } = useListContext();

  let unicValidate = null;

  /**
   * Conditionally render a checkbox input for uniqueness validation if it's not a text area field.
   * @param {boolean} isTextArea - A boolean indicating whether the field is a text area.
   * @param {function} handleOnChange - A function to handle changes in the input.
   * @param {object} fieldForm - An object representing the form field configuration.
   * @param {boolean} fieldForm.unique - A boolean indicating whether uniqueness is enabled for the field.
   * @param {object} i18n - An object with translation functions for localization.
   * @returns {JSX.Element|null} - A JSX element containing a checkbox input for uniqueness validation, or null if it's a text area field.
   */
  if (isTextArea === false) {
    unicValidate = (
      <AvGroup row>
        <Col md={12}>
          <AvInput
            id="unique"
            name="unique"
            type="checkbox"
            onChange={handleOnChange}
            tag={CustomInput}
            required
            label={i18n.t("form.field38")}
            errorMessage={i18n.t("form.fieldFeedback3")}
            value={fieldForm.unique}
          />
        </Col>
      </AvGroup>
    );
  } else {
    unicValidate = null;
  }

  /**
   * Handle changes in the maximum value input field.
   *
   * @param {object} eventMinMaxChars - The event object containing information about the input change.
   * @param {string} eventMinMaxChars.target.name - The name attribute of the input field.
   * @param {string} eventMinMaxChars.target.value - The new value of the input field.
   * @param {function} setFieldForm - A function to update the state of the form field.
   * @returns {void}
   */
  const handlemaxValue = (eventMinMaxChars) => {
    let target = eventMinMaxChars.target.value;
    if (target.match(regexLenCharsMinMax)) {
      setFieldForm({
        ...fieldForm,
        [eventMinMaxChars.target.name]: target,
      });
    } else {
      setFieldForm({
        ...fieldForm,
        [eventMinMaxChars.target.name]: target,
      });
    }
  };

  /**
   * Handle changes in the minimum character input field.
   *
   * @param {object} eventMinMaxChars - The event object containing information about the input change.
   * @param {string} eventMinMaxChars.target.name - The name attribute of the input field.
   * @param {string} eventMinMaxChars.target.value - The new value of the input field.
   * @param {function} setFieldForm - A function to update the state of the form field.
   * @returns {void}
   */

  const handleminValue = (eventMinMaxChars) => {
    let target = eventMinMaxChars.target.value;
    if (target.match(regexLenMinChars)) {
      setFieldForm({
        ...fieldForm,
        [eventMinMaxChars.target.name]: target,
      });
    } else {
      setFieldForm({
        ...fieldForm,
        [eventMinMaxChars.target.name]: target,
      });
    }
  };

  /**
   * A React effect that validates and sets the "canNext" state based on the values
   * of "len_max_chars" and "len_min_chars" in the "fieldForm" object. It checks if
   * both values match a numeric regexLenMinChars and if "len_max_chars" is greater than or equal
   * to "len_min_chars". If the conditions are met, "canNext" is set to true, indicating
   * that the validation fails; otherwise, it's set to false, indicating a successful
   * validation.
   */

  useEffect(() => {
    const isLenMaxCharsZeroOrUndefined =
      !regexLenMinChars.test(fieldForm.len_max_chars) || !fieldForm.len_max_chars;
    const isLenMinCharsZeroOrUndefined =
      !regexLenMinChars.test(fieldForm.len_min_chars) || !fieldForm.len_min_chars;

    if (
      isLenMaxCharsZeroOrUndefined ||
      isLenMinCharsZeroOrUndefined ||
      Number(fieldForm.len_max_chars) < Number(fieldForm.len_min_chars)
    ) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
    // eslint-disable-next-line
  }, [fieldForm.len_max_chars, fieldForm.len_min_chars]);

  /**
   * A React effect to set the "unique" property in the fieldForm object to false
   * when isTextArea is falsy.
   */

  useEffect(() => {
    if (!isTextArea) {
      fieldForm["unique"] = false;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Card className="forms-fields">
        <AvGroup row>
          <Col md="12">
            <Alert className="mbg-3" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("maxCharacters")}
            </Alert>
          </Col>
        </AvGroup>
        <AvGroup row>
          <Label for="len_min_chars" className="is-required" sm={4}>
            {i18n.t("form.field35")}
          </Label>
          <Col md={3}>
            <AvInput
              id="len_min_chars"
              name="len_min_chars"
              type="text"
              onChange={handleminValue}
              autoFocus="true"
              minLength="1"
              maxLength="2"
              required
              pattern={regexLenCharsMinMax}
              autoComplete="off"
              value={fieldForm.len_min_chars}
            />
            <AvFeedback>{i18n.t("form.fieldFeedback5")}</AvFeedback>
          </Col>
        </AvGroup>
        <AvGroup row>
          <Label for="len_max_chars" className="is-required" sm={4}>
            {i18n.t("form.field34")}
          </Label>
          <Col md={3}>
            <AvInput
              id="len_max_chars"
              name="len_max_chars"
              type="text"
              onChange={handlemaxValue}
              minLength="1"
              maxLength="3"
              required
              pattern={regexLenCharsMinMax}
              autoComplete="off"
              value={fieldForm.len_max_chars}
            />
            <AvFeedback>{i18n.t("form.fieldFeedback4")}</AvFeedback>
          </Col>
        </AvGroup>
        {unicValidate}
      </Card>
    </Fragment>
  );
};

LenCharsMinMax.propTypes = {
  isTextArea: PropTypes.bool,
};

export default LenCharsMinMax;