import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  EDIT_FILE_DATA,
  MOVE_FILE,
  MOVE_FILE_TO_TRASH,
  SIGN_ELECTRONICALLY,
  TYPE,
} from "constants/securityConst";
import { enumsTypeUser } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import useLogin from "hooks/useLogin";
import i18n from "locales/i18n";

const ButtonActionsFIles = (props) => {
  const {
    fileId,
    recordId,
    recordUuid,
    formId,
    trdExistRec2,
    nameFile,
    subjectFile,
    publication,
    integrationOption,
    typeExtension,
    handleOpenEditFile,
    typeConfigurationFile,
    handleOpenMoveFile,
    backgroundId,
    levelId,
    expedientId,
    showActionsButtons,
    handleOpenMoveTrash,
  } = props;
  const { isLogged } = useLogin();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const EDIT_FILES_DATA = window.localStorage.getItem(EDIT_FILE_DATA);
  const MOVE_FILES = window.localStorage.getItem(MOVE_FILE);
  const MOVE_FILES_TO_TRASH = window.localStorage.getItem(MOVE_FILE_TO_TRASH);
  const SIGN_FILES_ELECTRONICALLY = window.localStorage.getItem(SIGN_ELECTRONICALLY);

  let linkActionsEditDtaFile = null;
  let linkActionSingature = null;
  let linkActionMoveFile = null;
  let linkActionMoveFileToTrash = null;
  let linkFileActions = null;
  let disabledActionButton = true;
  let accessToEditDataFile;
  let accessToMoveFiles;
  let accessToMoveFilesToTrash;
  let accessToSignElectronically;

  /**
   * Determines if the user has access to edit data file based on login status, user type, and form ID.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} EDIT_FILES_DATA - Data related to editing files.
   * @param {string} USERTYPE - Type of the user.
   * @param {string} formId - The ID of the form to be edited.
   * @param {boolean} accessToEditDataFile - Indicates if the user has access to edit the data file.
   */
  if (
    (isLogged === true &&
      isNullOrUndefined(EDIT_FILES_DATA) === false &&
      EDIT_FILES_DATA.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessToEditDataFile = true;
  } else {
    accessToEditDataFile = false;
  }

  /**
   * Determines if the user has access to move files based on their login status and user type.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} formId - The ID of the form.
   * @param {string[]} MOVE_FILES - An array of file IDs that can be moved.
   * @param {string} USERTYPE - The type of user.
   * @param {object} enumsTypeUser - Enumerated types for user roles.
   * @returns {boolean} - True if the user has access to move files, false otherwise.
   */
  if (
    (isLogged === true && isNullOrUndefined(MOVE_FILES) === false && MOVE_FILES.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessToMoveFiles = true;
  } else {
    accessToMoveFiles = false;
  }

  /**
   * Determines if the user has access to move files to trash based on their login status and user type.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} MOVE_FILES_TO_TRASH - List of file IDs that can be moved to trash.
   * @param {string} USERTYPE - The type of user (QUERY, STANDARD, ADMIN).
   * @param {boolean} accessToMoveFilesToTrash - Indicates if the user has access to move files to trash.
   */
  if (
    (isLogged === true &&
      isNullOrUndefined(MOVE_FILES_TO_TRASH) === false &&
      MOVE_FILES_TO_TRASH.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessToMoveFilesToTrash = true;
  } else {
    accessToMoveFilesToTrash = false;
  }

  /**
   * Determines if the user has access to sign a file electronically based on their login status and user type.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} SIGN_FILES_ELECTRONICALLY - List of file IDs that can be signed electronically.
   * @param {string} formId - The ID of the form to be signed.
   * @param {string} USERTYPE - The type of the logged-in user.
   * @param {object} enumsTypeUser - Enumerated types for user roles.
   * @returns {boolean} - True if the user has access to sign electronically, false otherwise.
   */
  if (
    (isLogged === true &&
      isNullOrUndefined(SIGN_FILES_ELECTRONICALLY) === false &&
      SIGN_FILES_ELECTRONICALLY.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessToSignElectronically = true;
  } else {
    accessToSignElectronically = false;
  }

  /**
   * Updates the state of the action button based on the access permissions and file type extension.
   * If the user has access to edit data files, move files, move files to trash, or sign electronically (for PDF files),
   * the action button is enabled; otherwise, it is disabled.
   */
  if (
    accessToEditDataFile === true ||
    accessToMoveFiles === true ||
    accessToMoveFilesToTrash === true ||
    (accessToSignElectronically === true && (typeExtension === "pdf") === true)
  ) {
    disabledActionButton = false;
  }

  /**
   * Creates a frozen object containing signature data.
   * @param {string} fileId - The ID of the file.
   * @param {string} formId - The ID of the form.
   * @param {string} recordUuid - The UUID of the record.
   * @param {string} recordId - The ID of the record.
   * @param {string} trdExistVal - The TRD existence value.
   * @param {string} nameFile - The name of the file.
   * @param {string} subjectFile - The subject of the file.
   * @param {string} publication - The publication information.
   * @returns {Object} A frozen object with the provided signature data.
   */
  const signatureData = Object.freeze({
    fileId: fileId,
    formId: formId,
    recordUuid: recordUuid,
    recordId: recordId,
    trdExistVal: trdExistRec2,
    nameFile: nameFile,
    subjectFile: subjectFile,
    publication: publication,
  });

  /**
   * Converts an object to a JSON string, encodes it using Base64, and returns the resulting hash value.
   * @param {Object} signatureData - The object to be converted and hashed.
   * @returns {string} The hash value obtained by encoding the object.
   */
  const signatureDataExp = JSON.stringify(signatureData);
  const encoder = new TextEncoder();
  const dataSignature = encoder.encode(signatureDataExp);
  const signatureDataHash = window.btoa(String.fromCharCode.apply(null, dataSignature));

  /**
   * Determines if the current path includes "details".
   * @returns {boolean} Returns true if the path includes "details", otherwise false.
   */
  const location = useLocation();
  const currentPath = location.pathname;
  const pathName = currentPath.split("/")[2];
  let isDetails;
  if (pathName === "details") {
    isDetails = true;
  } else {
    isDetails = false;
  }

  /**
   * Constructs a link for file actions based on the details status.
   * @param {boolean} isDetails - A boolean indicating whether the details are true or not.
   * @param {string} recordUuid - The UUID of the record.
   * @param {string} formId - The ID of the form.
   * @param {string} fileId - The ID of the file.
   * @returns {string} Returns the constructed link for file actions.
   */
  if (isDetails === true) {
    linkFileActions = `/record/details/${recordUuid}/${formId}/${fileId}`;
  } else {
    linkFileActions = `/record/detail/${recordUuid}/${formId}/${fileId}`;
  }

  /**
   * Renders a dropdown item for editing data file if access is granted.
   * @param {boolean} accessToEditDataFile - Indicates whether access to edit data file is granted.
   * @param {function} handleOpenEditFile - Function to handle opening the edit file event.
   * @param {string} typeConfigurationFile - The type of configuration file.
   * @param {string} linkFileActions - The link for file actions.
   * @param {object} i18n - The internationalization object for translations.
   */
  if (accessToEditDataFile === true) {
    linkActionsEditDtaFile = (
      <DropdownItem>
        <Link
          onClick={(eventType) => handleOpenEditFile(eventType, typeConfigurationFile)}
          to={linkFileActions}
        >
          <i className="dropdown-icon lnr-pencil"> </i>
          <span>{i18n.t("recordDetail.files.options")}</span>
        </Link>
      </DropdownItem>
    );
  }

  /**
   * Determine whether to render a signature action button based on integrationOption and typeExtension.
   * @param {string[]} integrationOption - The integration option array.
   * @param {string} typeExtension - The type of file extension.
   * @param {string} signatureDataHash - The hash data for signature.
   * @param {Object} i18n - The i18n object used for translations.
   * @returns {JSX.Element|null} A button component for signature action, or null if the conditions are not met.
   */
  if (
    integrationOption[1] === "1" &&
    (typeExtension === "pdf") === true &&
    accessToSignElectronically === true
  ) {
    linkActionSingature = (
      <DropdownItem>
        <Link to={`/files/signature/${formId}/${signatureDataHash}`}>
          <i className="dropdown-icon lnr-pencil"> </i>
          <span>{i18n.t("signature.buttonActions")}</span>
        </Link>
      </DropdownItem>
    );
  }

  /**
   * Conditionally renders a dropdown item for moving a file if certain conditions are met.
   * @param {boolean} trdExistRec2 - Indicates if a specific condition is met.
   * @param {boolean} showActionsButtons - Indicates if the action buttons should be displayed.
   * @param {boolean} accessToMoveFiles - Indicates if the user has access to move files.
   * @param {function} handleOpenMoveFile - Function to handle opening the move file action.
   * @param {string} backgroundId - The background ID for the file.
   * @param {string} levelId - The level ID for the file.
   * @param {string} expedientId - The expedient ID for the file.
   */
  if (trdExistRec2 === true && showActionsButtons === true && accessToMoveFiles === true) {
    linkActionMoveFile = (
      <DropdownItem>
        <Link
          onClick={(eventOpenMove) =>
            handleOpenMoveFile(eventOpenMove, backgroundId, levelId, expedientId)
          }
          to={linkFileActions}
        >
          <i className="dropdown-icon lnr-move"> </i>
          <span>{i18n.t("trd.moveFile1")}</span>
        </Link>
      </DropdownItem>
    );
  }

  /**
   * Renders a dropdown item for moving a file to trash if the user has access.
   * @param {boolean} accessToMoveFilesToTrash - Indicates if the user has access to move files to trash.
   * @param {string} backgroundId - The background ID of the file.
   * @param {string} levelId - The level ID of the file.
   * @param {string} expedientId - The expedient ID of the file.
   * @param {function} handleOpenMoveTrash - Function to handle opening the move to trash action.
   */
  if (accessToMoveFilesToTrash === true) {
    linkActionMoveFileToTrash = (
      <DropdownItem>
        <Link
          onClick={(eventOpenMove) =>
            handleOpenMoveTrash(eventOpenMove, backgroundId, levelId, expedientId)
          }
          to={linkFileActions}
        >
          <i className="dropdown-icon lnr-trash text-danger"> </i>
          <span>{i18n.t("recordDetail.files.remove6")}</span>
        </Link>
      </DropdownItem>
    );
  }

  if (disabledActionButton === false) {
    return (
      <Fragment>
        <UncontrolledButtonDropdown color="cyan" className="mb-2 mr-2 d-block w-100 text-center">
          <DropdownToggle caret className="btn-icon btn-icon-only btn btn-link" color="link">
            <i className="lnr-menu-circle btn-icon-wrapper" />
          </DropdownToggle>
          <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
            <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>
            {linkActionsEditDtaFile}
            {linkActionSingature}
            {linkActionMoveFile}
            {linkActionMoveFileToTrash}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Fragment>
    );
  } else {
    return null;
  }
};

ButtonActionsFIles.propTypes = {
  fileId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  recordUuid: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  trdExistRec2: PropTypes.bool.isRequired,
  nameFile: PropTypes.string.isRequired,
  subjectFile: PropTypes.string.isRequired,
  publication: PropTypes.any.isRequired,
  integrationOption: PropTypes.string.isRequired,
  typeExtension: PropTypes.string.isRequired,
  handleOpenEditFile: PropTypes.func.isRequired,
  typeConfigurationFile: PropTypes.string.isRequired,
  handleOpenMoveFile: PropTypes.func,
  backgroundId: PropTypes.string,
  levelId: PropTypes.string,
  expedientId: PropTypes.string,
  showActionsButtons: PropTypes.bool.isRequired,
  handleOpenMoveTrash: PropTypes.func.isRequired,
};

export default ButtonActionsFIles;
