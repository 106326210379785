import React, {Fragment} from 'react'
import i18n from 'locales/i18n'
import AdminTemplate from "components/templates/AdminTemplate";
import ChangeUserStatu from 'components/organism/ChangeUserStatu';
const ChangeUserStatus = () => {
    return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("editUserHeading")}
				subheading={i18n.t("editUserSubHeading")}
				icon="users"

			>
				<ChangeUserStatu />
			</AdminTemplate>
		</Fragment>
	)
}

export default ChangeUserStatus