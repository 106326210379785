import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Label, Button, Spinner, CardFooter, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { getMonth, getYear, addYears } from "date-fns";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { customStylesSelect } from "components/organism/Notifications/Notifications.styles";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { months } from "utils/yearsMonthsDatePicker";
import { regexSubjectUploadFile } from "utils/regexExpressions";
import { enumsEditFile, enumsSupportTypes } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import useRecord from "hooks/useRecord";
import range from "lodash/range";
import swal from "sweetalert";
import i18n from "locales/i18n";

const RecordFileListInfo = (props) => {
  const { setIsOpenFileEdit, files } = props;
  const { fileId } = useParams();
  const { updateFileById } = useRecord();
  const {
    setIsChangePage,
    setCloseFileTables,
    setIsOpenFileEditTrd,
  } = useRecordDetailContext();
  const file = files.filter((file) => file.uuid === fileId);
  const [isLoadingFileEdit, setIsLoadingFileEdit] = useState(false);
  const [startDate, setStartDate] = useState(new Date(file[0].publication_at));
  const years = range(1950, getYear(new Date()) + 80, 1);
  const [infoFileEdit, setInfoFileEdit] = useState({
    subject: file[0].subject,
    support_type: file[0].support_type.toString(),
    publication_at: file[0].publication_at,
  });
  const [tempFieldEdit] = useState({
    subject: file[0].subject,
    support_type: file[0].support_type.toString(),
    publication_at: file[0].publication_at,
  });
  const [keysFieldsUpdated] = useState([
    "subject",
    "support_type",
    "publication_at",
  ]);

  let loaderEditFile = null;

  /**
   * Returns a default date format or `null` based on the input value.
   * @param {string} value - The input value to check.
   * @returns {string|null} Returns "dd/mm/yyyy" if the input value is an empty string; otherwise, returns `null`.
   */
  const returnDate = (value) => {
    if (value === "") {
      return "dd/mm/yyyy";
    } else {
      return null;
    }
  };

  /**
   * Conditionally renders a loading spinner if the file is currently being edited.
   * @param {boolean} isLoadingFileEdit - Indicates if the file is in the process of being edited.
   * @returns {JSX.Element|null} A `Spinner` component if `isLoadingFileEdit` is `true`, otherwise `null`.
   */
  if (isLoadingFileEdit === true) {
    loaderEditFile = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Handles the value change on a file edit event.
   * Updates the status of `infoFileEdit` with the new file information.
   * @param {Event} eventHandleFileEdit - The file change event.
   * @returns {void}
   */
  const handleOnChangeFileEdit = (eventHandleFileEdit) => {
    setInfoFileEdit({
      ...infoFileEdit,
      [eventHandleFileEdit.target.name]: eventHandleFileEdit.target.value,
    });
  };

  /**
   * Handles the blur event for file editing.
   * @param {Event} eventHandleFileEdit - The event object for the blur event.
   * @returns {void}
   */
  const handleOnBlurFileEdit = (eventHandleFileEdit) => {
    setInfoFileEdit({
      ...infoFileEdit,
      [eventHandleFileEdit.target.name]: eventHandleFileEdit.target.value.trim(),
    });
  };

  /**
   * Handles the change event for date selection.
   * @param {Date} date - The selected date.
   * @returns {void}
   */
  const handleOnChangeDate = (date) => {
    setStartDate(date);
    setInfoFileEdit({
      ...infoFileEdit,
      publication_at: moment.utc(date).format("YYYY-MM-DDTHH:mm+00:00"),
    });
  };

  /**
   * Handles the change event for the support type selection.
   * @param {Event} eventHandleFileEdit - The event object for the change event.
   * @returns {void}
   */
  const handleOnChangeTypeSupport = (eventHandleFileEdit) => {
    setInfoFileEdit({
      ...infoFileEdit,
      support_type: eventHandleFileEdit.target.value.toString(),
    });
  };

  /**
   * Closes the file edit modal.
   * @returns {void}
   */
  const closeFileEdit = () => {
    setCloseFileTables(true);
    setIsOpenFileEditTrd(false);
    setIsOpenFileEdit(false);
  };

  /**
   * A custom input component wrapped around a Button element with specific styling and behavior.
   * This component is created using React's forwardRef to allow passing refs to the underlying Button element.
   * @function CustomInput
   * @param {object} props - The props object containing the following properties:
   * @param {string} props.value - The value to be displayed within the button.
   * @param {function} props.onClick - The function to be executed when the button is clicked.
   * @param {React.Ref} ref - A ref forwarded to the underlying Button element.
   * @returns {JSX.Element} A Button component with custom styling and behavior.
   */
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      block
      className="text-left-3"
      type="button"
      size="lg"
      color="grayLigth"
      onClick={onClick}
      ref={ref}
    >
      {returnDate(value)}
      {value}
    </Button>
  ));

  /**
   * Handles the submit event for updating a file.
   * @param {Event} eventSubmitFile - The event object for the submit event.
   * @param {Array} errors - An array of errors (if any) related to the form fields.
   * @returns {void}
   */
  const handleOnSubmitFileUpdated = (eventSubmitFile, errors) => {
    eventSubmitFile.preventDefault();
    if (errors.length === 0) {
      const modifiedFile = keysFieldsUpdated.reduce(
        (acomulatorField, fieldKey) => {
          if (infoFileEdit[fieldKey] !== tempFieldEdit[fieldKey]) {
            acomulatorField[fieldKey] = infoFileEdit[fieldKey];
          }
          return acomulatorField;
        },
        {}
      );
      if (Object.keys(modifiedFile).length === 0) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        });
        return;
      }
      setIsLoadingFileEdit(true);
      updateFileById(fileId, modifiedFile)
        .then((response) => {
          if (response.code === 4019) {
            setIsOpenFileEditTrd(false);
            setCloseFileTables(true);
            setIsChangePage((prevIsChange) => !prevIsChange);
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("recordDetail.files.update.alert"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              closeFileEdit();
            });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFileEdit(false);
        });
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleOnSubmitFileUpdated}>
          <Row>
            <Col md="4">
              <AvGroup>
                <Label for="interrupt_motive" className="is-required">
                  {i18n.t("trd.fromLabel3")}
                </Label>
                <AvField
                  id="subject"
                  name="subject"
                  type="text"
                  onChange={handleOnChangeFileEdit}
                  value={infoFileEdit.subject}
                  onBlur={handleOnBlurFileEdit}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `${i18n.t("recordDetail.files.text1")}`,
                    },
                    pattern: {
                      value: regexSubjectUploadFile,
                      errorMessage: `${i18n.t("invalidField2")}`,
                    },
                    minLength: {
                      value: enumsEditFile.MIN_LENGTH_SUBJECT,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: enumsEditFile.MAX_LENGTH_SUBJECT,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                  }}
                  autoComplete="off"
                ></AvField>
              </AvGroup>
            </Col>
            <Col md="4">
              <AvGroup>
                <Label for="interrupt_motive" className="is-required">
                  {i18n.t("recordDetail.files.support")}
                </Label>
                <AvField
                  id="suportTypeStr"
                  name="suportTypeStr"
                  type="select"
                  onChange={handleOnChangeTypeSupport}
                  value={infoFileEdit.support_type}
                  autoComplete="off"
                >
                  <option value={enumsSupportTypes.FHYSICAL}>
                    {i18n.t("record.supportType.option1")}
                  </option>
                  <option value={enumsSupportTypes.ELECTRONIC}>
                    {i18n.t("record.supportType.option2")}
                  </option>
                  <option value={enumsSupportTypes.GENERIC}>
                    {i18n.t("record.supportType.option3")}
                  </option>
                </AvField>
              </AvGroup>
            </Col>

            <Col md="4">
              <div>
                <Label for="publication_at" className="is-required">
                  {i18n.t("record.dateDocument")}
                </Label>

                <DatePicker
                  id="expiration_date"
                  name="expiration_date"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="m-4 d-flex justify-content-center">
                      <button
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={handleOnChangeDate}
                  customInput={<CustomInput />}
                  locale={i18n.t("datapicker.language")}
                  className="date__input-container"
                  fixedHeight
                  dateFormat="dd/MM/yyyy"
                  placeholderText={i18n.t("datapicker.placeHolder")}
                  maxDate={addYears(new Date(), 2)}
                  styles={customStylesSelect}
                />
              </div>
            </Col>

            <div className="d-block text-right w-100">
              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={closeFileEdit}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingFileEdit}
                  className="col-mt-3"
                  color="cyan"
                >
                  {loaderEditFile}
                  {i18n.t("createusers.createButton3")}
                </Button>
              </CardFooter>
            </div>
          </Row>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

RecordFileListInfo.propTypes = {
  setIsOpenFileEdit: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
};

export default RecordFileListInfo;
