import React, { Fragment, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { CSSTransitionGroup } from "react-transition-group";
import { enumsControlDataFields } from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const WorkflowFilingFormsInStepTable = (props) => {
  const {
    listFields,
    configFormforStep,
    setConfigFormforStep,
    isLoadingFieldsList,
    prevSelectedFields,
    formRequired,
    externalAllFields,
  } = props;
  const [selectAllFields, setSelectAllFields] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [obligatedSelected, setObligatedSelected] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(listFields.length / itemsPerPage);
  const paginatedData = useMemo(() => {
    const startPage = currentPage * itemsPerPage;
    const endPage = startPage + itemsPerPage;
    return listFields.slice(startPage, endPage);
  }, [listFields, currentPage]);
  let pages;

  /**
   * Sets the value of 'pages' based on the value of 'selectAllFields'.
   * If 'selectAllFields' is true, 'pages' is set to 1.
   * If 'selectAllFields' is false, 'pages' is set to 'totalPages'.
   * @param {boolean} selectAllFields - A boolean indicating whether to select all fields.
   * @param {number} totalPages - The total number of pages to consider when 'selectAllFields' is false.
   */
  if (selectAllFields === true) {
    pages = 1;
  } else {
    pages = totalPages;
  }

  /**
   *Handles the selection of all fields in a form.
   *@param {object} eventSelectField - The event object representing the field selection
   *@param {boolean} eventSelectField.target.checked - The checked state of the field selection
   *@param {function} setSelectAllFields - The state setter function for the select all fields option
   *@param {array} listFields - An array of objects representing the available fields
   *@param {function} setSelectedFields - The state setter function for the selected fields
   *@param {object} configFormforStep - The configuration object for the form step
   *@param {boolean} configFormforStep.all_fields - The flag indicating if all fields are selected
   *@param {array} configFormforStep.form_fields_uuid - An array of UUIDs representing the form fields
   *@param {function} setConfigFormforStep - The state setter function for the form step configuration
   */
  const handleSelectAllFields = (eventSelectField) => {
    const checkedSelectAllFields = eventSelectField.target.checked;
    setSelectAllFields(checkedSelectAllFields);
    if (checkedSelectAllFields === true) {
      setConfigFormforStep({
        ...configFormforStep,
        all_fields: true,
        form_fields_uuid: [],
      });
    } else {
      setSelectedFields([]);
      setConfigFormforStep({
        ...configFormforStep,
        all_fields: false,
        form_fields_uuid: [],
      });
    }
  };

  /**
   *Handles the selection of a field.
   *@param {object} field - The field object to be selected or unselected.
   *@param {object} eventSelectFields - The event object containing the checkbox state.
   *@param {boolean} eventSelectFields.target.checked - The checked state of the checkbox.
   *@param {string} field.value - The value of the field.
   *@param {function} setSelectedFields - The state setter function for the selected fields.
   *@param {array} prevSelectedFields - The previously selected fields.
   *@param {boolean} setSelectAllFields - The state setter function for the "Select All" checkbox.
   */
  const handleSelectFields = (field, eventSelectFields) => {
    const checkedField = eventSelectFields.target.checked;
    const { value } = field;
    if (checkedField === true) {
      setSelectedFields((prevSelectedFields) => [...prevSelectedFields, value]);
    } else {
      setSelectedFields((prevSelectedFields) =>
        prevSelectedFields.filter((selectedValue) => selectedValue !== value)
      );

      setSelectAllFields(false);
    }
  };

  /**
   *Handles the selection of the obligated checkbox and updates the state accordingly.
   *@param {Object} eventObligatedSelect - The event object for the obligated checkbox selection
   *@param {boolean} eventObligatedSelect.target.checked - The checked value of the obligated checkbox
   *@param {function} setObligatedSelected - The state setter function for the obligated checkbox selection
   *@param {Object} configFormforStep - The current configuration form for the step
   *@param {boolean} configFormforStep.required_filled_fields - The value indicating whether required filled fields are enabled
   *@param {function} setConfigFormforStep - The state setter function for the configuration form for the step
   */
  const handleObligatedSelect = (eventObligatedSelect) => {
    const checkedObligatedSelect = eventObligatedSelect.target.checked;
    setObligatedSelected(checkedObligatedSelect);
    setConfigFormforStep({
      ...configFormforStep,
      required_filled_fields: checkedObligatedSelect,
    });
  };

  /**
   * Renders the label based on the control data value.
   * @param {number} controlData - The control data value.
   * @param {string} labelField - The label field value.
   * @returns {JSX.Element} The rendered label element.
   */
  const renderLabel = (controlData, labelField) => {
    if (controlData === enumsControlDataFields.LABEL) {
      return (
        <span className="text-capitalize">
          {labelField} - {i18n.t("createWorkflow.configurations.label4")}
        </span>
      );
    } else {
      return (
        <span className="text-capitalize">{labelField}</span>
      );
    }
  };

  /**
   *useEffect hook that filters the 'listFields' array to extract required fields and updates the state of required fields accordingly.
   *@param {array} listFields - an array of objects representing the fields to be filtered
   *@param {function} setRequiredFields - the state setter function for the required fields of the component
   */
  useEffect(() => {
    const filteredFields = listFields.filter(
      (field) => field.requiredField === true
    );
    const requiredFieldValues = filteredFields.map((field) => field.value);

    setRequiredFields(Array.from(new Set(requiredFieldValues)));
  }, [listFields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React hook that performs an effect when selected fields, obligated state, or select all fields change.
   * @param {string[]} prevSelectedFields - An array of previously selected fields.
   * @param {boolean} formRequired - Indicator for required fields in the form.
   * @param {boolean} externalAllFields - Indicator for selecting all external fields.
   * @returns {void}
   */
  useEffect(() => {
    setSelectedFields(prevSelectedFields);
    setObligatedSelected(formRequired);
    setSelectAllFields(externalAllFields);
    if (externalAllFields === true) {
      setSelectedFields([]);
      setRequiredFields([]);
    } else {
      setSelectedFields(prevSelectedFields);
    }
  }, [prevSelectedFields, formRequired, externalAllFields]);

  /**
   *useEf*fect hook that updates the state of the component based on changes to the 'selectedFields' prop.
   *@param {boolean} selectAllFields - a boolean value indicating whether all fields are selected or not
   *@param {array} selectedFields - an array of UUIDs representing the selected form fields
   *@param {object} configFormforStep - the current configuration of the form for a specific step
   *@param {function} setConfigFormforStep - the state setter function for the form configuration of the step
   */
  useEffect(() => {
    if (requiredFields.length > 0) {
      setConfigFormforStep((prevConfigForm) => ({
        ...prevConfigForm,
        form_fields_uuid: [...selectedFields, ...requiredFields],
      }));
    } else {
      setConfigFormforStep((prevConfigForm) => ({
        ...prevConfigForm,
        form_fields_uuid: [...selectedFields],
      }));
    }
  }, [requiredFields, selectedFields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="d-flex align-items-center  ">
          <input
            type="checkbox"
            checked={selectAllFields}
            onChange={handleSelectAllFields}
            disabled={configFormforStep.form_uuid === ""}
          />
          &nbsp;
          <span>{i18n.t("createWorkflow.configurations.selectAllFields")}</span>
        </div>
        <br />
        <ReactTable
          data={paginatedData}
          columns={[
            {
              Header: [i18n.t("form.fieldtable2")],
              accessor: "label",
              width: 500,
              className: "text-capitalize",
              Cell: (row) => {
                const controlData = row.original.controlData;
                const labelField = row.original.label;
                return (
                  <div>
                    {renderLabel(controlData, labelField)}
                  </div>
                );
              },
            },
            {
              Header: [i18n.t("createWorkflow.configurations.isRequiredField")],
              accessor: "requiredField",
              Cell: ({ value }) => {
                if (value === true) {
                  return (
                    <div className="text-center">
                      <input type="checkbox" checked disabled />
                    </div>
                  );
                }
                return null;
              },
            },
            {
              Header: [i18n.t("createWorkflow.configurations.isSelectedField")],
              accessor: "value",
              Cell: ({ value, original }) => {
                if (
                  original.requiredField === false ||
                  original.requiredField === null
                ) {
                  return (
                    <div className="text-center">
                      <input
                        type="checkbox"
                        checked={
                          selectedFields.includes(value) || selectAllFields
                        }
                        disabled={selectAllFields}
                        onChange={(eventSelectFields) =>
                          handleSelectFields(original, eventSelectFields)
                        }
                      />
                    </div>
                  );
                }
                return null;
              },
            },
          ]}
          manual
          className="-striped -highlight"
          pages={pages}
          page={currentPage}
          showPageJump={false}
          defaultPageSize={itemsPerPage}
          loading={isLoadingFieldsList}
          noDataText={i18n.t("tableRowsEmpty")}
          previousText={i18n.t("previousText")}
          nextText={i18n.t("nextText")}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          canNext={false}
          onPageChange={(pageIndex) => setCurrentPage(pageIndex)}
        />
        <br />
        <div className="d-flex align-items-center  ">
          <input
            type="checkbox"
            checked={obligatedSelected}
            onChange={handleObligatedSelect}
            disabled={configFormforStep.form_uuid === ""}
          />
          &nbsp;
          <span>
            {i18n.t("createWorkflow.configurations.isRequiredFillOut")}
          </span>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkflowFilingFormsInStepTable.propTypes = {
  listFields: PropTypes.array.isRequired,
  configFormforStep: PropTypes.object.isRequired,
  setConfigFormforStep: PropTypes.func.isRequired,
  isLoadingFieldsList: PropTypes.bool.isRequired,
  paginationFields: PropTypes.shape({
    pageFields: PropTypes.number.isRequired,
    per_page: PropTypes.number.isRequired,
  }).isRequired,
  setPaginationFields: PropTypes.func.isRequired,
  totalPagesFields: PropTypes.number.isRequired,
};

export default WorkflowFilingFormsInStepTable;
