export function getDateFormat(date) {
  var date_return = "";
  if (isNaN(date.getTime())) {
    date_return = "";
  } else {
    date_return = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " ";
  }

  return date_return;
}

/**
 * Returns the current year as a number.
 * @returns {number} The current year.
 */
export function getYear() {
  var today = new Date();
  return today.getFullYear();
}

/**
 * Get the formatted timezone offset for the local time zone.
 * @returns {string} The formatted timezone offset in the format "+HH:MM" or "-HH:MM".
 * @param {Date} dateReal - The current date and time.
 * @param {number} timeZone - The timezone offset in minutes.
 * @param {string} offsetSign - The sign of the timezone offset ("+" or "-").
 * @param {number} offsetHours - The absolute value of the offset in hours.
 * @param {number} offsetMinutesRemainder - The remainder of the absolute value of the offset in minutes.
 * @param {string} formattedOffset - The formatted timezone offset string.
 */
export function getFormattedTimezoneOffset() {
  const dateReal = new Date();
  const timeZone = dateReal.getTimezoneOffset();
  let offsetSign;
  if (timeZone > 0) {
    offsetSign = "";
  } else {
    offsetSign = "-";
  }
  const offsetHours = Math.floor(Math.abs(timeZone) / 60);
  const offsetMinutesRemainder = Math.abs(timeZone) % 60;
  const formattedOffset =
    offsetSign +
    String(offsetHours).padStart(2, "0") +
    ":" +
    String(offsetMinutesRemainder).padStart(2, "0");
  return formattedOffset;
}
