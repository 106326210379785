import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { capitalizeText } from "utils/formatText";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import {
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { loaderElement } from "utils/loaderElement";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkFlowStepsTable from "components/organism/WorkFlowStepsTable";
import i18n from "locales/i18n";

const WorkFlowSteps = () => {
  const { id, hash, formId } = useParams();
  const {
    listWorkflowSteps,
    isLoading,
    isLoadingSteps,
    pagination,
    setPagination,
    getWorkFlowBySearch,
  } = useWorkflowContext();

  /**
   * Retrieves and processes workflow permissions from local storage.
   * @returns {string} workflowAllPermissions - All workflow form permissions as a comma-separated string.
   * @returns {string} workflowSomePermissions - Some specific workflow form permissions as a comma-separated string.
   * @returns {Array<string>} permissionsGranted - An array of permissions granted, obtained by splitting workflowSomePermissions.
   */
  const workflowAllPermissions =
    window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const workflowSomePermissions = window.localStorage.getItem(
    WORKFLOW_FORMS_PERMISSIONS
  );
  const permissionsGranted = workflowSomePermissions.split(",");

  /**
   * Decodes a Base64-encoded string and parses it as JSON to retrieve workflow information.
   * @param {string} hash - The Base64-encoded string containing workflow information to decode.
   * @returns {object} WorkflowInfo - An object containing the parsed workflow information.
   */
  const unHashWorkflowInfo = window.atob(hash);
  const WorkflowInfo = JSON.parse(unHashWorkflowInfo);

  /**
   * A useEffect hook that checks permissions and navigates back in the browser's history if certain conditions are met.
   * @param {string} id - The identifier used to check permissions for a specific operation.
   * @param {string} workflowAllPermissions - A string representing whether the user has all permissions for the workflow.
   * @param {Array<string>} permissionsGranted - An array of permission identifiers granted to the user.
   * @returns {void} This hook does not return a value; it performs side effects by potentially navigating the browser's history.
   */
  useEffect(() => {
    if (id) {
      if (
        workflowAllPermissions === "false" &&
        !permissionsGranted.includes(id)
      ) {
        window.history.back();
      }
    }
  }, [id, workflowAllPermissions, permissionsGranted]);

  /**
   * A React useEffect hook that fetches workflow data based on the provided ID.
   * This hook triggers the `getWorkFlowBySearch` function when the `id` or `getWorkFlowBySearch` function reference changes.
   * @param {Function} getWorkFlowBySearch - A function responsible for fetching workflow data.
   * @param {string} id - The unique identifier to search for a specific workflow.
   * @returns {void}
   */
  useEffect(() => {
    getWorkFlowBySearch(id);
  }, [getWorkFlowBySearch, id]);

  return (
    <Fragment>
      {loaderElement(isLoadingSteps)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container">
                <h5 className="text-info font-weight-bold">
                  {i18n.t("createWorkflow.config.nameProcess")}:{" "}
                  <span className="text-dark ml-2">
                    {capitalizeText(WorkflowInfo.name)}
                  </span>
                </h5>
              </Col>
              <Col
                lg="7"
                md="8"
                sm="12"
                className="button-container mt-2 mt-md-0"
              >
                <Row className="row justify-content-end">
                  <Link to="/workflow/list">
                    <Button className="btn-icon mr-2" color="cyan">
                      <FontAwesomeIcon
                        icon={faArrowAltCircleLeft}
                        className="mr-2"
                      />
                      {i18n.t("createWorkflow.config.buttonBack")}
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </CardBody>

          <CardBody>
            <WorkFlowStepsTable
              listWorkflowSteps={listWorkflowSteps}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              workFlowUuid={id}
              statusProcess={WorkflowInfo.status}
              formId={formId}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default WorkFlowSteps;
