import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Button, Col, Row } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import { enumsTaskType, enumsTypeUser } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { DELETE_PATH_EXPEDIENT, TYPE } from "constants/securityConst";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import RecordUpLoadFiles from "components/organism/RecordUploadFiles";
import RecordListExpedient from "components/organism/RecordListExpedient";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";

const RecordUpLoadFilesByExpedient = (props) => {
  const { id } = useParams();
  const formUuid = id;
  const DELETEPATHEXPEDIENT = window.localStorage.getItem(
    DELETE_PATH_EXPEDIENT
  );
  const USERTYPE = window.localStorage.getItem(TYPE);
  const {
    pathConfiguration,
    currentPath,
    hasExpedientFixedPath,
    hasOrdination,
    isRecordCreate,
    recordUuid,
    isFreeTask,
    isProcess,
    isProcessBatch,
  } = props;
  const {
    folderName,
    sethasExpedientFixedPath,
    setExpedientSetPath,
    setShowRightPanelTreeExpedients,
    expedientSetPath,
  } = useTrdContext();
  const { deletePathSetExpedient } = useTrd();
  const [showExpedientesList, setShowExpedientesList] = useState(false);
  let buttonDeleteExpedientPath = null;

  /**
   * Generates an informational alert element displaying the uploaded file's folder name.
   * @param {string} folderName - The name of the folder where the file was uploaded.
   * @returns {JSX.Element} JSX element representing an alert with information about the uploaded file's folder.
   */
  let alertPath = (
    <Alert className="mbg-3" color="info" isOpen={true}>
      <span className="pr-2">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      {i18n.t("uploadFile.file")}: <strong>{folderName}</strong>
    </Alert>
  );

  /**
   * Handles the deletion of an expedient path by making an API request to delete the path associated with the specified record UUID.
   * @returns {Promise<void>} A promise that resolves after the path deletion operation completes.
   */
  const handleOnDeleteExpedietPath = () =>
    deletePathSetExpedient(recordUuid, formUuid).then((response) => {
      if (response.status === 202) {
        setExpedientSetPath({});
        sethasExpedientFixedPath(false);
        setShowExpedientesList(true);
      }
    });

  /**
   * Determines whether to display a delete button for an expedient path based on various conditions.
   * @param {boolean} DELETEPATHEXPEDIENT - Flag indicating if delete path expedient functionality is enabled.
   * @param {string[]} DELETEPATHEXPEDIENT - Array containing form UUIDs eligible for deletion.
   * @param {string} formUuid - UUID of the form being considered for deletion.
   * @param {string} USERTYPE - Type of user accessing the functionality.
   * @param {string} isFreeTask - Type of task being evaluated.
   * @param {string} enumsTaskType - Enumeration of task types.
   * @param {boolean} isProcess - Flag indicating if the process is ongoing.
   * @param {boolean} isProcessBatch - Flag indicating if the process is part of a batch.
   * @param {boolean} hasOrdination - Flag indicating if the form has an ordination.
   * @param {string} buttonDeleteExpedientPath - JSX element representing the delete button for expedient paths.
   * @param {function} handleOnDeleteExpedietPath - Callback function for handling deletion when the button is clicked.
   * @returns {JSX.Element|null} JSX element representing the delete button if conditions are met; otherwise, null.
   */
  if (
    (isNullOrUndefined(DELETEPATHEXPEDIENT) === false &&
      DELETEPATHEXPEDIENT.includes(formUuid) === true &&
      isNullOrUndefined(USERTYPE) === false &&
      isFreeTask !== enumsTaskType.FREE_TASK &&
      isProcess !== true &&
      isProcessBatch !== true &&
      hasOrdination === false) ||
    (hasOrdination === false && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    buttonDeleteExpedientPath = (
      <Button
        color="cyan"
        onClick={handleOnDeleteExpedietPath}
        className="ml-2"
      >
        {i18n.t("trdExpedientListSetPathQuit")}
      </Button>
    );
  }

  /**
   * Conditionally renders an informational alert with a fixed path display if `hasExpedientFixedPath` is true.
   * @param {boolean} hasExpedientFixedPath - Indicates whether the fixed path should be displayed.
   * @param {string} currentPath - The current path information to display.
   * @param {object} expedientSetPath - Configuration object for setting the expedient path.
   * @param {ReactNode} buttonDeleteExpedientPath - React element representing the button to delete the expedient path.
   * @returns {ReactNode | null} The rendered alert component if `hasExpedientFixedPath` is true; otherwise, null.
   */
  if (
    hasExpedientFixedPath === true &&
    isNullOrUndefined(pathConfiguration) === false
  ) {
    alertPath = (
      <Alert className="mbg-2" color="info">
        <Row className=" align-items-center justify-content-between">
          <Col lg="10" md="6" sm="12" className="d-flex ">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={expedientSetPath}
            />
          </Col>
          <Col lg="auto" md="6" sm="12" className="d-flex justify-content-end">
            {buttonDeleteExpedientPath}
          </Col>
        </Row>
      </Alert>
    );
  }

  /**
   * Clean-up function for the effect.
   * It is called when the component unmounts.
   * @returns {void}
   */
  useEffect(() => {
    return () => {
      setShowRightPanelTreeExpedients(false);
    };
  });

  if (showExpedientesList === true) {
    return (
      <RecordListExpedient
        formIdInfo={formUuid}
        expedientComeFrom={null}
        hasExpedientFixedPath={false}
        isFreeTask={enumsTaskType.NO_PROCES_TASK}
        isProcess={false}
        isProcessBatch={false}
      />
    );
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
          className="scrollingContainer"
        >
          <div className="app-inner-layout rm-sidebar">
            <div className="app-inner-layout__content ">{alertPath}</div>

            <RecordUpLoadFiles
              fileByTrd={true}
              isRecordCreate={isRecordCreate}
              recordUuid={recordUuid}
            />
          </div>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

RecordUpLoadFilesByExpedient.propTypes = {
  pathConfiguration: PropTypes.string.isRequired,
  currentPath: PropTypes.array.isRequired,
  hasExpedientFixedPath: PropTypes.bool.isRequired,
  hasOrdination: PropTypes.bool.isRequired,
  isRecordCreate: PropTypes.bool.isRequired,
  recordUuid: PropTypes.string.isRequired,
  isFreeTask: PropTypes.number.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
};

export default RecordUpLoadFilesByExpedient;
