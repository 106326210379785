import React, { Fragment, useEffect, useState, useMemo } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useListContext } from "contextAPI/ListsContext";
import { enumsProcessTaskType, enumsTypeStatusUser } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationGetUsers } from "utils/initialPaginationsConfig";
import { capitalizeText } from "utils/formatText";
import useWorkFlow from "hooks/useWorkFlow";
import useUser from "hooks/useUser";
import ModalGateWay from "components/molecules/ModalGateWay";
import ModalResponsibleStep from "components/molecules/ModalResponsibleStep";
import i18n from "locales/i18n";
import swal from "sweetalert";

const FulfillProcess = (props) => {
  const {
    taskType,
    isProcessBatch,
    selectedFields,
    treeExist,
    setIsLoadingRequest,
    isLoadingRequest,
  } = props;
  const { id } = useParams();
  const { selectedOption } = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [isLoadingFullfill, setIsLoadingFullfill] = useState(false);
  const [isErrorSelectUser, setIsErrorSelectUser] = useState(false);
  const [showModalGateWay, setShowModalGateWay] = useState(false);
  const [complyProcess, setComplyProcess] = useState({
    status: 2,
    next_user_uuid: "",
  });
  const [complyProcessReturned] = useState({
    status: 2,
    next_user_uuid: null,
  });
  const [typeResponsibleUser, setTypeResponsibleUser] = useState(null);
  const [formDataFullfilBatch, setFormDataFullfilBatch] = useState(
    new FormData()
  );
  const { page, per_page } = initialPaginationGetUsers;
  const { setRecordNext } = useListContext();
  const {
    isLoading,
    setIsLoading,
    getWorkFlowBySearch,
    nextStepId,
    workflowId,
    process,
    listWorkflowSteps,
    usersDinamic,
    setUsersDinamic,
    responsibleUser,
    setResponsibleUser,
    responsibleList,
    setResponsibleList,
    showModalReasign,
    setShowModalReasign,
    complyProcessGateWay,
    setComplyProcessGateWay,
    selectLabelSequenceFlow,
    requiredfilledform,
    fullfilCompleted,
    formExternalName,
    isLoadingResponsilblesList,
    setIsLoadingResponsilblesList,
    isCompletedTask,
    setIsLoadingComplyProcessBatch,
    isLoadingComplyProcessBatch,
    formIdInfo,
    complyTaskRequest,
    setComplyTaskRequest,
    setIsNextStep,
    commentCreateProcessBatch,
    configProcessBatch,
    checkedOmitedUploadFile,
    setDropdownOpen,
  } = useWorkflowContext();
  const { parentData } = useTrdContext();
  const {
    fulfillProcess,
    getListResponsibles,
    accomplishProcessBatch,
  } = useWorkFlow();
  const { getUsers } = useUser();
  let search = "";
  let spinnerButtonFulfill = null;
  let alertMessageComply = null;
  let disabledButtonComply = false;
  let handleProcessSubmit = null;

  /**
   * Determines if the "disabledButtonComply" should be set to true based on the provided conditions.
   * If any of the conditions are true, the button is disabled.
   * @param {boolean} isLoadingResponsilblesList - Indicates whether the responsible list is currently loading.
   * @param {boolean} isLoadingFullfill - Indicates whether the fulfillment process is currently loading.
   * @param {boolean} isCompletedTask - Indicates whether the task is completed.
   * @returns {boolean} - True if any of the conditions are true, indicating that the button should be disabled; otherwise, false.
   */
  if (
    isLoadingResponsilblesList === true ||
    isLoadingFullfill === true ||
    isCompletedTask === true ||
    isLoadingRequest === true
  ) {
    disabledButtonComply = true;
  }

  /**
   * Sets alert message for complying with the task based on process batch state.
   * @param {boolean} isProcessBatch - Flag indicating whether process batch is active.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @returns {string} - Alert message for complying with the task.
   */
  if (isProcessBatch === true) {
    alertMessageComply = i18n.t("taskBatchProcess.alert.fullfill");
  } else {
    alertMessageComply = i18n.t("taskComply");
  }

  /**
   * Conditionally sets spinner for button fulfilling based on loading state of responsibilities list.
   * @param {boolean} isLoadingResponsilblesList - Flag indicating whether the responsibilities list is loading.
   * @param {JSX.Element|null} - Spinner component or null.
   */
  if (isLoadingResponsilblesList === true || isLoadingRequest === true) {
    spinnerButtonFulfill = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   *Fetches the list of responsibles for a given workflow and step ID
   *@function fetchResponsibles
   *@param {string} workflowId - The ID of the workflow
   *@param {string} nextStepId - The ID of the next step in the workflow
   *@returns {Promise<void>}
   */
  async function fetchResponsibles(workflowId, nextStepId) {
    try {
      const response = await getListResponsibles(workflowId, nextStepId);
      const typeUsers = response.data.type_user_responsible;
      const userResponsibleList = response.data.user_responsible_list;

      if (isNullOrUndefined(typeUsers) === false) {
        setTypeResponsibleUser(typeUsers);
        if (typeUsers !== 3) {
          if (isNullOrUndefined(userResponsibleList) === false) {
            if (typeUsers === 1) {
              setResponsibleUser(userResponsibleList[0]);
            } else {
              setResponsibleList(userResponsibleList);
            }
          } else {
            showAlertServiceError();
          }
        }
      }
    } finally {
      setIsLoadingResponsilblesList(false);
      setIsLoadingRequest(false);
    }
  }

  /**
   * Fulfills a batch of processes using the provided form data.
   * @param {object} formDataFullfilBatch - The data necessary to fulfill the batch of processes.
   * @param {boolean} formDataFullfilBatch.someProperty - Description of some property within the form data.
   * Add more @param tags as needed to describe other properties.
   * @returns {void}
   */
  function fulfillProcessesBatch(formDataFullfilBatch) {
    accomplishProcessBatch(formDataFullfilBatch)
      .then((response) => {
        if (isNullOrUndefined(response) === false) {
          setIsNextStep(true);
          setRecordNext(true);
          setShowModalReasign(false);
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response.data.code === "500054" &&
          isNullOrUndefined(error.response.data.data[500036]) === false
        ) {
          let errorFilesList = error.response.data.data[500036];
          let fileListItems = errorFilesList.map(
            (file, index) =>
              `<li key=${index}><em>${capitalizeText(file)}</em></li>`
          );

          swal({
            title: i18n.t("modal.DoneError.header"),
            text: `${i18n.t("500054")}`,
            content: {
              element: "div",
              attributes: {
                innerHTML: `<ul>${fileListItems.join("")}</ul>`,
                className: "custom-content-class",
              },
            },
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
      .finally(() => {
        setIsLoadingComplyProcessBatch(false);
      });
  }

  /**
   * Define a function that fulfills a process with the given complyProcess parameter.
   * create an alert for control in case of response 202
   * @param {string} modal - Modal showAlertTaskfulfill, for confirmation of selection of desition.
   * @param {object} complyProcess - The process to comply with.
   * @returns {Promise} - A promise that resolves to the server's response.
   */
  function fulfillProcesses(complyProcess) {
    fulfillProcess(id, complyProcess)
      .then((response) => {
        if (response.status === 202) {
          if (
            isNullOrUndefined(response.data.code) === false &&
            response.data.code === 5063
          ) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: `${i18n.t("createWorkflow.fullFill.Complete")}.\n${i18n.t(
                "taskReturnSuccessAlertInactiveUser"
              )}`,
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location = "/taskBox";
            });
            setShowModalReasign(false);
          } else {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("createWorkflow.fullFill.Complete"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location = "/taskBox";
            });
            setShowModalReasign(false);
          }
        }
      })
      .finally(() => {
        setIsLoadingFullfill(false);
      });
  }

  /**
   * Function to handle the form submission for complying with a process
   * Also show thw modal in the first step
   * @param {string} modal - Modal showAlertShure, for confirmation of selection of desition.
   * @param {function} fulfillProcesses - The function that is executed to acomplish the task.
   * @returns {Promise} - A promise that resolves to the server's response.
   */
  const handleOnSubmitComplyProcess = (complyProcessEvent) => {
    if (requiredfilledform === true && fullfilCompleted === false) {
      complyProcessEvent.preventDefault();
      swal({
        title: i18n.t("modal.DoneError.header"),
        text:
          i18n.t("processAlert.fulfillProcessRequired") +
          " " +
          formExternalName,
        icon: "warning",
        button: i18n.t("modal.Done.footerButton"),
      });
    } else {
      if (process.hasOwnProperty("exclusive_labels")) {
        setShowModalGateWay(true);
      } else {
        const showAlertShure = () => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: alertMessageComply,
            icon: "warning",
            buttons: [
              i18n.t("createusers.createButton2"),
              i18n.t("modal.Done.footerButton"),
            ],
          }).then((willClose) => {
            if (willClose) {
              if (typeResponsibleUser !== null && typeResponsibleUser === 2) {
                setShowModalReasign(true);
              } else {
                if (isProcessBatch === true) {
                  setIsLoadingComplyProcessBatch(true);
                  fulfillProcessesBatch(formDataFullfilBatch);
                } else {
                  setIsLoadingFullfill(true);
                  fulfillProcesses(complyProcess);
                }
              }
            } else {
              setDropdownOpen(false);
              setIsLoading(false);
            }
          });
        };

        showAlertShure();
      }
    }
  };

  /**
   * Handles the submission process for complying with returned processes.
   * This function displays a SweetAlert modal with a warning icon and two buttons.
   * Depending on the user's choice, it either initiates the fulfillment process or closes the dropdown.
   * @returns {void}
   */
  const handleOnSubmitComplyProcessReturned = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: alertMessageComply,
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willClose) => {
      if (willClose) {
        setIsLoadingFullfill(true);
        fulfillProcesses(complyProcessReturned);
      } else {
        setDropdownOpen(false);
        setIsLoading(false);
      }
    });
  };

  /**
   * Determines the appropriate submit handler based on the task type.
   * @param {string} taskType - The type of the task to be processed.
   * @param {Object} enumsProcessTaskType - An object containing the task type enums.
   * @param {string} enumsProcessTaskType.RETURN - Enum value representing a return task type.
   * @param {Function} handleOnSubmitComplyProcessReturned - Function to handle submit for return tasks.
   * @param {Function} handleOnSubmitComplyProcess - Function to handle submit for regular tasks.
   * @returns {Function} The appropriate submit handler function based on the task type.
   */
  if (taskType === enumsProcessTaskType.RETURN) {
    handleProcessSubmit = handleOnSubmitComplyProcessReturned;
  } else {
    handleProcessSubmit = handleOnSubmitComplyProcess;
  }

  /**
   * Function to handle the close of modals
   * @param {object} complyProcessGateWay - The object of the elements for acomplish the task acoirding to the desition.
   * @param {object} complyProcess - The object of the elements for acomplish the task without desition.
   * @returns {void}
   */
  const handleOnCloseFulfillProcess = () => {
    setDropdownOpen(false);
    setShowModalReasign(false);
    setIsErrorSelectUser(false);
    setComplyProcess({
      ...complyProcess,
      next_user_uuid: "",
    });
    setComplyProcessGateWay({
      ...complyProcessGateWay,
      next_user_uuid: "",
      next_step_uuid: "",
    });
  };

  /**
   * Function to handle the change of users in modalReasign.
   * @param {object} complyProcessGateWay - The object of the elements for acomplish the task acoirding to the desition.
   * @param {object} complyProcess - The object of the elements for acomplish the task without desition.
   * @returns {void}
   */
  const handleOnChangeUsersFulfillProcess = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorSelectUser(true);
    } else {
      setComplyProcess({
        ...complyProcess,
        next_user_uuid: selectedOption.value,
      });
      setComplyProcessGateWay({
        ...complyProcessGateWay,
        next_user_uuid: selectedOption.value,
      });
      setIsErrorSelectUser(false);
    }
  };

  /**
   * Function to handle the submit for modal Reasign
   * @param {object} complyProcessGateWay - The object of the elements for acomplish the task acoirding to the desition.
   * @param {object} complyProcess - The object of the elements for acomplish the task without desition.
   * @param {function} fulfillProcesses - The function that is executed to acomplish the task.
   * @returns {void}
   */
  const handleOnSubmitForm = (eventComplyProcess) => {
    let processComply = null;

    if (
      selectLabelSequenceFlow === true &&
      isNullOrUndefined(complyProcessGateWay.next_user_uuid) === false
    ) {
      processComply = complyProcessGateWay;
    } else if (isNullOrUndefined(complyProcess.next_user_uuid) === false) {
      processComply = complyProcess;
    }

    if (processComply !== null) {
      setIsErrorSelectUser(false);
      if (isProcessBatch === true) {
        setIsLoadingComplyProcessBatch(true);
        fulfillProcessesBatch(formDataFullfilBatch);
      } else {
        setIsLoadingFullfill(true);
        fulfillProcesses(processComply);
      }
    } else {
      eventComplyProcess.preventDefault();
      setIsErrorSelectUser(true);
    }
  };

  /**
   * Executes an effect to update form data for batch processing if the process is a batch process.
   * @param {boolean} isProcessBatch - Indicates whether the process is a batch process.
   * @param {object} complyProcess - Information related to the compliance process.
   * @param {string} complyProcess.next_user_uuid - UUID of the next responsible user.
   * @param {object} complyProcessGateWay - Information related to the compliance process gateway.
   * @param {string} complyProcessGateWay.next_step_uuid - UUID of the next step in the process gateway.
   * @param {Array<string>} selectedFields - An array of selected fields/tasks.
   * @param {object} commentCreateProcessBatch - Information related to comments for the batch process creation.
   * @param {string} commentCreateProcessBatch.comment - Comment for the batch process creation.
   * @param {object} configProcessBatch - Configuration information for the batch process.
   * @param {Array<object>} configProcessBatch.file - An array of files to be uploaded.
   * @param {object} configProcessBatch.uploadForm - Information related to the form upload.
   * @param {string} configProcessBatch.uploadForm.subject - Subject of the uploaded form.
   * @param {string} configProcessBatch.uploadForm.support_type - Type of support for the uploaded form.
   * @param {string} configProcessBatch.uploadForm.publication_at - Publication date of the uploaded form.
   * @param {object} parentData - Information related to the parent data.
   * @param {string} parentData.id - UUID of the parent data.
   * @param {string} formIdInfo - UUID of the form.
   * @param {boolean} checkedOmitedUploadFile - Indicates whether the upload file is checked as omitted.
   * @returns {void}
   */
  useEffect(() => {
    if (isProcessBatch === true) {
      const updateFormDataFulFillBatch = new FormData();
      if (isNullOrUndefined(complyProcess.next_user_uuid) === false) {
        updateFormDataFulFillBatch.append(
          "select_responsible",
          complyProcess.next_user_uuid
        );
      }
      if (isNullOrUndefined(complyProcessGateWay.next_step_uuid) === false) {
        updateFormDataFulFillBatch.append(
          "select_step",
          complyProcessGateWay.next_step_uuid
        );
      }
      if (isNullOrUndefined(selectedFields) === false) {
        selectedFields.forEach((task) => {
          updateFormDataFulFillBatch.append("tasks", task);
        });
      }
      if (isNullOrUndefined(commentCreateProcessBatch) === false) {
        updateFormDataFulFillBatch.append(
          "commentary",
          commentCreateProcessBatch.comment
        );
      }
      if (
        isNullOrUndefined(configProcessBatch) === false &&
        checkedOmitedUploadFile === true
      ) {
        configProcessBatch.file.forEach((fileSelect) => {
          updateFormDataFulFillBatch.append("file", fileSelect);
          updateFormDataFulFillBatch.append("size", fileSelect.size);
        });
      }
      if (
        isNullOrUndefined(parentData) === false &&
        checkedOmitedUploadFile === true &&
        treeExist === true
      ) {
        updateFormDataFulFillBatch.append("trd_uuid", parentData.id);
      }
      if (
        isNullOrUndefined(formIdInfo) === false &&
        checkedOmitedUploadFile === true
      ) {
        updateFormDataFulFillBatch.append("form_uuid", formIdInfo);
      }

      setFormDataFullfilBatch(updateFormDataFulFillBatch);
    } // eslint-disable-next-line
  }, [
    complyProcess,
    selectedFields,
    commentCreateProcessBatch,
    configProcessBatch,
    parentData,
    formIdInfo,
    checkedOmitedUploadFile,
  ]);

  /**
   * This effect sets the 'next_user_uuid' property in complyProcess state to the value
   *  of the 'responsibleUser' if it exists in the 'usersDinamic' array.
   * It also updates the 'next_user_uuid' property in complyProcessGateWay state.
   * Also create control for duplicate elements.
   * @param {string} responsibleUser - The UUID of the responsible user.
   * @param {array} usersDinamic - The array of dynamic users.
   * @returns {void}
   */
  useEffect(() => {
    const userResponsible = usersDinamic.find(
      (user) => user.value === responsibleUser
    );
    if (isNullOrUndefined(userResponsible) === false) {
      setComplyProcess((prevComplyProcess) => ({
        ...prevComplyProcess,
        next_user_uuid: userResponsible.value,
      }));
      setComplyProcessGateWay((prevComplyProcessGateWay) => ({
        ...prevComplyProcessGateWay,
        next_user_uuid: userResponsible.value,
      }));
    } else {
      setComplyProcess((prevComplyProcess) => ({
        ...prevComplyProcess,
        next_user_uuid: null,
      }));
      setComplyProcessGateWay((prevComplyProcessGateWay) => ({
        ...prevComplyProcessGateWay,
        next_user_uuid: "",
      }));
    }

    if (responsibleList !== null && responsibleList !== undefined) {
      const filteredUsersTemp = Array.from(
        new Set(
          usersDinamic
            .filter((userResponsibleList) => {
              return responsibleList.includes(userResponsibleList.value);
            })
            .map((userResponsibleList) => JSON.stringify(userResponsibleList))
        )
      );

      setFilteredUsers(
        filteredUsersTemp.map((userResponsibleList) =>
          JSON.parse(userResponsibleList)
        )
      );
    }
  }, [responsibleUser, responsibleList, usersDinamic, setComplyProcessGateWay]);

  /**
   * Use the useEffect hook to get the responsible users for the next step in the workflow.
   * The effect runs whenever the listWorkflowSteps, process, getListResponsibles,
   * nextStepId, or workflowId values change.
   * @param {array} listWorkflowSteps - The array of workflow steps.
   * @param {object} process - The object of the workflow process.
   * @param {function} getListResponsibles - The function that is executed to get the users responsible by ID.
   * @param {string} nextStepId - The ID of the next step in the workflow.
   * @param {string} workflowId - The ID of the workflow.
   * @returns {void}
   */
  useEffect(() => {
    if (
      listWorkflowSteps.length > 0 &&
      process.hasOwnProperty("exclusive_labels") === false
    ) {
      const isNextStepEndEvent = listWorkflowSteps.some(
        (step) => step.type_name === "END_EVENT" && step.uuid === nextStepId
      );

      if (isNextStepEndEvent === false) {
        fetchResponsibles(workflowId, nextStepId);
      } else {
        setIsLoadingResponsilblesList(false);
        setIsLoadingRequest(false);
      }
    } else if (process.hasOwnProperty("exclusive_labels") === true) {
      setIsLoadingResponsilblesList(false);
      setIsLoadingRequest(false);
    }
  }, [process, nextStepId, workflowId, listWorkflowSteps]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React Hook to handle the effect when `complyTaskRequest` changes.
   * This useEffect hook is triggered when `complyTaskRequest` changes. If both `isProcessLot` and
   * `complyTaskRequest` are true, it simulates a submit event, invokes the `handleOnSubmitComplyProcess`
   * function, and sets `complyTaskRequest` to false.
   * @param {Function} effect - The callback function to be executed when `complyTaskRequest` changes.
   * @param {Array} dependencies - An array of dependencies. The effect will run if any of these dependencies change.
   * @returns {void}
   */
  useEffect(() => {
    if (complyTaskRequest === true && isLoadingRequest === false) {
      handleOnSubmitComplyProcess();
      setComplyTaskRequest(false);
    }
  }, [complyTaskRequest, isLoadingRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Use the useEffect hook to fetch the workflow by search ID
   * whenever the workflowId or getWorkFlowBySearch function changes.
   * Use the useMemo hook to filter the listWorkflowSteps array
   * and return an array of UUIDs for the end events.
   * @param {string} workflowId - The ID of the workflow to search for.
   * @param {function} getWorkFlowBySearch - The function that is executed to get the workflow by search ID.
   * @returns {void}
   * @param {array} listWorkflowSteps - The array of workflow steps to filter.
   * @returns {array} - The array of UUIDs for final events.
   */
  useEffect(() => {
    if (isNullOrUndefined(workflowId) === false) {
      getWorkFlowBySearch(workflowId);
    }
  }, [getWorkFlowBySearch, workflowId]);

  /**
   * useMemo hook that returns an array of UUIDs corresponding to the "END_EVENT" steps in the list of workflow steps.
   * The useMemo hook is used to memoize the result to prevent unnecessary re-renders when the dependencies don't change.
   * @returns {string[]} An array of UUIDs representing the "END_EVENT" steps.
   */
  const finishEvent = useMemo(() => {
    const finish = listWorkflowSteps.filter(
      (step) => step.type_name === "END_EVENT"
    );
    return finish.map((step) => step.uuid);
  }, [listWorkflowSteps]);

  /**
   * Executes a side effect on component mount.
   * Retrieves users data based on provided parameters and updates component state accordingly.
   * @param {number} page - The page number to retrieve users from.
   * @param {number} per_page - The number of users to retrieve per page.
   * @param {string} search - The search query to filter users by.
   * @returns {void}
   */
  useEffect(() => {
    setIsLoadingRequest(true);
    getUsers(page, per_page, search).then((usersResponse) => {
      const usersList = usersResponse.data.items;
      if (isNullOrUndefined(usersList) === false) {
        usersList.forEach((user) => {
          if (
            user.status !== enumsTypeStatusUser.INACTIVE &&
            user.status !== enumsTypeStatusUser.RETIRED
          ) {
            setUsersDinamic((prevState) => [
              ...prevState,
              {
                value: user.uuid,
                label: `${user.first_name} ${user.last_name} (${user.user_name})`,
              },
            ]);
          }
        });
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This effect sets the 'next_user_uuid' property in complyProcess state to null
   * if 'finishEvent' is equal to 'nextStepId'.
   * @param {string} finishEvent - The ID of the finish event.
   * @param {string} nextStepId - The ID of the next step in the workflow.
   * @returns {void}
   */
  useEffect(() => {
    if (finishEvent === nextStepId) {
      setComplyProcess((prevState) => ({ ...prevState, next_user_uuid: null }));
    }
  }, [finishEvent, nextStepId]);

  return (
    <Fragment>
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle
          className="ml-auto btn-icon btn-block btn-mb-responsive"
          color="green"
          onClick={handleProcessSubmit}
          disabled={disabledButtonComply}
        >
          {spinnerButtonFulfill}
          <FontAwesomeIcon icon={faCog} className="mr-2" />
          <span>{i18n.t("taskButton1")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>

      <ModalGateWay
        showModalGateWay={showModalGateWay}
        setShowModalGateWay={setShowModalGateWay}
        handleOnCloseFulfillProcess={handleOnCloseFulfillProcess}
        nameModalGateWay={process.subject}
        labelsSequenceFlow={process.exclusive_labels}
        fulfillProcesses={fulfillProcesses}
        fulfillProcessesBatch={fulfillProcessesBatch}
        listWorkflowSteps={listWorkflowSteps}
        isProcessBatch={isProcessBatch}
        formDataFullfilBatch={formDataFullfilBatch}
      />

      <ModalResponsibleStep
        showModalReasign={showModalReasign}
        handleOnCloseFulfillProcess={handleOnCloseFulfillProcess}
        handleOnSubmitForm={handleOnSubmitForm}
        handleOnChangeUsersFulfillProcess={handleOnChangeUsersFulfillProcess}
        isErrorSelectUser={isErrorSelectUser}
        selectedOption={selectedOption}
        filteredUsers={filteredUsers}
        isLoading={isLoading}
        isLoadingComplyProcessBatch={isLoadingComplyProcessBatch}
      />
    </Fragment>
  );
};

export default FulfillProcess;
