import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import i18n from "locales/i18n";
import ResizeDetector from "react-resize-detector";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import useLogin from "hooks/useLogin";
import PageTitleAlt2 from "layout/AppMain/PageTitleAlt2";
import AppHeader from "layout/AppHeader";
import AppSidebar from "layout/AppSidebar";
import AppFooter from "layout/AppFooter";
import { useHistory } from "react-router-dom";
import ThemeOptions from "layout/ThemeOptions";

const AdminTemplate = (props) => {
  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;
  const history = useHistory();

  const { isLogged } = useLogin();

  /**
   *useEffect hook that redirects the user to the home page if they are not logged in.
   *@param {boolean} isLogged - a boolean value indicating if the user is logged in
   *@param {object} history - the history object provided by React Router for programmatic navigation
   */
  useEffect(() => {
    if (!isLogged) {
      history.push("/");
    }
  }, [isLogged, history]);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              {
                "closed-sidebar": enableClosedSidebar || width < 1250,
              },
              {
                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
              },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}
          >
            <ThemeOptions />
            <AppHeader />
            <div className="app-main">
              <AppSidebar />
              <div className="app-main__outer">
                <div className="app-main__inner">
                  <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                  >
                    <PageTitleAlt2
                      heading={i18n.t(props.heading)}
                      subheading={i18n.t(props.subheading)}
                      icon={`pe-7s-${props.icon} text-blue`}
                    />
                    {props.children}
                  </CSSTransitionGroup>
                </div>
                <AppFooter />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(AdminTemplate));
