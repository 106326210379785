import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import useForm from "hooks/useForm";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionsForm = (props) => {
  const { formId } = props;
  const { getFields, deleteForm } = useForm();
  const [canDelete, setCanDelete] = useState(false);
  const [pagination] = useState(initialPaginationStandart);

  /**
   * Sets the 'canDelete' state to 'true'.
   * This function is used to enable the deletion of an item or perform a related action
   * when invoked.
   * @returns {void}
   */
  const handleOnDelete = () => {
    setCanDelete(true);
  };

  /**
   * Displays a confirmation dialog for deleting a form and handles the deletion process.
   * This function shows a confirmation dialog using the 'swal' library, prompting the user to confirm
   * the deletion of a form. If the user confirms, it sends a request to delete the form and displays
   * a success or error message based on the response.
   * @throws {Error} Throws an error if the deletion request fails.
   */
  const deleteAction = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("form.field60"),
      icon: "info",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willDelete) => {
      if (willDelete) {
        deleteForm(formId)
          .then((response) => {
            if (response.status === 200) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("form.field58"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => (window.location.href = "/formsdesigner/list"));
            }
          })
          .catch((error) => {
            const swalMessageConfig = {
              title: i18n.t("modal.DoneError.header"),
              icon: "error",
              button: i18n.t("modal.Done.footerButton"),
              dangerMode: true,
            };

            switch (error.response.data.code) {
              case "30080":
                swalMessageConfig.text = `${i18n.t(
                  "form.field56"
                )} \n${error.response.data.data.workflows
                  .map((workflow) => {
                    return `* ${workflow}`;
                  })
                  .join("\n")}`;
                break;

              case "30082":
                swalMessageConfig.text = `${i18n.t(
                  "form.field57"
                )} \n${error.response.data.data.roles
                  .map((rol) => {
                    return `* ${rol}`;
                  })
                  .join("\n")}`;
                break;

              case "30097":
                swalMessageConfig.text = `${i18n.t(
                  "30097"
                )} \n${error.response.data.data
                  .map((form) => {
                    return `* ${form.name}`;
                  })
                  .join("\n")}`;
                break;

              default:
                break;
            }

            swal(swalMessageConfig);
          });
      }
    });
  };

  /**
   * Executes a side effect when the 'canDelete' flag is true.
   * This effect checks if there are records associated with a form, and if so, prompts the user to confirm deletion.
   * If there are no records or the user confirms, it triggers the 'deleteAction' function.
   * @param {boolean} canDelete - A flag indicating whether the delete operation is allowed.
   * @param {object} pagination - An object containing pagination information, including 'page' and 'per_page'.
   * @param {number} formId - The identifier of the form to be deleted.
   * @param {function} getFields - A function to retrieve fields associated with a form.
   * @param {function} deleteAction - A function to perform the deletion action.
   * @returns {void}
   */
  useEffect(() => {
    if (canDelete) {
      const { page, per_page } = pagination;
      getFields(page, per_page, formId, 0).then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          if (response.data.has_records === true) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("form.field54"),
              icon: "info",
              buttons: [
                i18n.t("createusers.createButton2"),
                i18n.t("modal.Done.footerButton"),
              ],
            }).then((willDelete) => {
              if (willDelete) {
                deleteAction();
              }
            });
          } else {
            deleteAction();
          }
        } else {
          showAlertServiceError();
        }
      });
    }

    return () => {
      setCanDelete(false);
    };
  }, [canDelete]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link to={`/formsdesigner/fields/${formId}`}>
            <i className="dropdown-icon lnr-list" />
            <span>{i18n.t("form.designerLabel12")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/formsdesigner/edit/${formId}`}>
            <i className="dropdown-icon lnr-pencil" />
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="#">
            <i className="dropdown-icon lnr-trash text-danger" />
            <span onClick={handleOnDelete}>
              {i18n.t("buttonActions.option3")}
            </span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsForm.propTypes = {
  formId: PropTypes.string.isRequired,
};

export default ButtonActionsForm;
