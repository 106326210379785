import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { enumsRolesPermissions, enumsTypeUser } from "utils/enums";
import useAxiosInterceptor from "utils/axiosInterceptor";
import Login from "components/pages/Login";
import RecoveryPassword from "components/pages/RecoveryPassword";
import ChangePassword from "components/pages/ChangePassword";
import ChangePasswordExpiration from "components/pages/ChangePasswordExpiration";
import ChangeForgottenPasswordReset from "components/pages/ChangeForgottenPasswordReset";
import Dashboard from "components/pages/Dashboard";
import Metrics from "components/pages/Metrics";
import MetricsRecordsByForm from "components/pages/MetricsRecordsByForm";
import MetricsRecordByYear from "components/pages/MetricsRecordsByYear";
import UserList from "components/pages/UserList";
import CreateUser from "components/pages/CreateUser";
import Trd from "components/pages/Trd";
import CreateList from "components/pages/CreateList";
import UserRoles from "components/pages/UserRoles";
import WorkGroup from "components/pages/UserWorkGroup";
import UserWorkGroupForm from "components/pages/UserWorkgroupForm";
import UserWorkGroupMembers from "components/pages/UserWorkGroupMember";
import WorkFlow from "components/pages/WorkFlow";
import WorkFlowList from "components/pages/WorkFlowList";
import UserRolesForm from "components/pages/UserRolesForm";
import UserRolesMembers from "components/pages/UserRolesMembers";
import BpmnModels from "components/pages/BpmnModels";
import UserRolesConfig from "components/pages/UserRolesConfig";
import ConsultList from "components/pages/ConsultList";
import ItemsList from "components/pages/ItemsList";
import TrdExpedients from "components/pages/TrdExpedients";
import NotFound from "components/pages/NotFound";
import FormsDesigner from "components/pages/FormsDesigner/FormsDesigner";
import FormsList from "components/pages/FormsList";
import FieldsDesigner from "components/pages/FieldDesigner";
import RecordData from "components/pages/RecordData";
import RecordFields from "components/pages/RecordFields";
import RecordDetail from "components/pages/RecordDetail";
import RecordDetails from "components/pages/RecordDetails";
import RecordList from "components/pages/RecordList";
import RecordListByRecord from "components/pages/RecordListBYRecord";
import RecordListByForm from "components/pages/RecordListByForm";
import RecordTraceability from "components/pages/RecordTraceability";
import EditList from "components/pages/EditList";
import EditItemList from "components/pages/EditItemList";
import useLogin from "hooks/useLogin";
import TrdOrdinationUnits from "components/pages/TrdOrdinationUnits";
import AccessDenied from "components/molecules/Messages/Error/AccessDenied";
import EditRecord from "components/pages/EditRecord";
import EditExpedients from "components/pages/EditExpedients";
import taskBox from "components/pages/TaskBox";
import TaskDetail from "components/pages/TaskDetail";
import ProcessDetail from "components/pages/ProcessDetail";
import WorkFlowEdit from "components/pages/WorkFlowEdit";
import WorkFlowSteps from "components/pages/WorkFlowSteps";
import WorkFlowConfigurationSteps from "components/pages/WorkFlowConfigurationSteps";
import WorkflowView from "components/pages/WorkflowView";
import Integrations from "components/pages/Integrations";
import DetailIntegrations from "components/pages/DetailIntegrations";
import RequestIntegration from "components/pages/RequestIntegration";
import ElectronicSignature from "components/pages/ElectronicSignature";
import ReportElectronicSignatures from "components/pages/ReportElectronicSignatures";
import Signatories from "components/pages/Signatories";
import WorkflowsTraceability from "components/pages/WorkflowsTraceability";
import FilterForm from "components/pages/FilterForm";
import ConsultConfigurationFields from "components/pages/ConsultConfigurationFields";
import ChangeOrderFields from "components/pages/ChangeOrderFields";
import ChangeUserStatus from "components/pages/ChangeUserStatus";
import DocumentaryTypes from "components/pages/DocumentaryTypes";
import UserLog from "components/pages/UserLog";
import TrdExpedientsConsultation from "components/pages/TrdExpedientsConsultation";
import DocumentaryTypesEdition from "components/pages/DocumentaryTypesEdition";
import NotFoundFile from "components/pages/NotFoundFile/NotFoundFile";
import StickerPage from "components/pages/StickerPage";
import ProcessManagement from "components/pages/ProcessManagement/ProcessManagement";
import TrashConsult from "components/pages/TrashConsult";
import AuditLogs from "components/pages/AuditLogs";
import {
  TYPE,
  ROLES_PERMISSIONS,
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
  PASSWORD_EXPIRATION_DAYS,
  LIMIT_EXPIRATION_DAY,
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS,
} from "constants/securityConst";
import ExportData from "components/pages/ExportData/ExportData";

const App = () => {
  const USERTYPE = window.localStorage.getItem(TYPE);
  const ROLE_PERMISSION = window.localStorage.getItem(ROLES_PERMISSIONS);

  //APPLICATION OF PERMISSIONS IN FORMS
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(DESIGNER_FORMS_PERMISSIONS);
  const PASSWORD_EXPIRATION = window.localStorage.getItem(PASSWORD_EXPIRATION_DAYS);

  //APPLICATION OF PERMISSIONS IN WORKFLOWS
  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(WORKFLOW_FORMS_PERMISSIONS);

  // CONTROL AND PERMISSIONS
  const { isLogged } = useLogin();
  useAxiosInterceptor();

  let access;
  let accessTRD;
  let accessDesignerForms;
  let accessDesignerFormsConsult;
  let accessWorkflowsForms;
  let accessWorkflowsFormsConsult;
  let accessList;
  let accessUserManager;
  let accessRoles;
  let accessIntegrations;
  let accessWorkGroups;

  /**
   * Checks if the user is logged in and has a user type other than QUERY.
   * Sets access to true if conditions are met, false otherwise.
   */
  if (isLogged === true && USERTYPE !== enumsTypeUser.QUERY) {
    access = true;
  } else {
    access = false;
  }

  /**
   * Checks the user's access to TRD based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as a query user,
   * sets the access to TRD to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.TRD_CONSFIGURATION)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessTRD = true;
  } else {
    accessTRD = false;
  }

  /**
   * Checks the user's access to designer forms based on their login status, user type, and designer form permissions.
   * If the user is logged in, has a standard user type, and has designer form permissions, or if the user is logged in as an admin,
   * sets the access to designer forms to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true && USERTYPE === enumsTypeUser.STANDARD && DESIGNER_FORM === "true") ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessDesignerForms = true;
  } else {
    accessDesignerForms = false;
  }

  /**
   * Checks if the user is logged in, has a standard user type, and has permission for designer forms.
   * Sets accessDesignerFormsConsult to true if conditions are met, false otherwise.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    DESIGNER_FROMS_PERMISSION !== ""
  ) {
    accessDesignerFormsConsult = true;
  } else {
    accessDesignerFormsConsult = false;
  }

  /**
   * Checks the user's access to the workflows forms based on their login status, user type, and permissions.
   * Sets the accessWorkflowsForms variable to true if the user is logged in as a standard user with access to all workflow forms or as an admin; otherwise, sets it to false.
   */
  if (
    (isLogged === true && USERTYPE === enumsTypeUser.STANDARD && WORKFLOW_ALL_FORM === "true") ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessWorkflowsForms = true;
  } else {
    accessWorkflowsForms = false;
  }

  /**
   * Checks if the user is logged in, has a standard user type, and has permission to access workflow forms.
   * Sets the accessWorkflowsFormsConsult variable accordingly.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    WORKFLOW_FORMS_PERMISSION !== ""
  ) {
    accessWorkflowsFormsConsult = true;
  } else {
    accessWorkflowsFormsConsult = false;
  }

  /**
   * Checks the user's access rights based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as an admin,
   * sets the accessList flag to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.LIST_DESIGNER)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessList = true;
  } else {
    accessList = false;
  }

  /**
   * Checks the user's access rights to the user manager based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as an admin, grants access to the user manager.
   * @returns {boolean} - True if the user has access to the user manager, false otherwise.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.USER_MANAGER)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessUserManager = true;
  } else {
    accessUserManager = false;
  }

  /**
   * Checks the user's access roles based on login status, user type, and role permissions.
   * If the user is logged in, has a standard user type, and specific role permissions, sets accessRoles to true.
   * If the user is logged in and has an admin user type, sets accessRoles to true.
   * Otherwise, sets accessRoles to false.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    ROLE_PERMISSION.includes(enumsRolesPermissions.ROLE_MANAGER)
  ) {
    accessRoles = true;
  } else if (isLogged === true && USERTYPE === enumsTypeUser.ADMIN) {
    accessRoles = true;
  } else {
    accessRoles = false;
  }

  /**
   * Determines the access to integrations based on the user's login status and user type.
   * If the user is logged in and not an admin, access to integrations is denied; otherwise, access is granted.
   */
  if (isLogged === true && USERTYPE !== enumsTypeUser.ADMIN) {
    accessIntegrations = false;
  } else {
    accessIntegrations = true;
  }

  /**
   * Determines if the user has access to work groups based on their login status, user type, and role permissions.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} USERTYPE - The type of user (QUERY, STANDARD, ADMIN).
   * @param {array} ROLE_PERMISSION - The array of role permissions.
   * @returns {boolean} - True if the user has access to work groups, false otherwise.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    ROLE_PERMISSION.includes(enumsRolesPermissions.WORK_GROUP_MANAGER)
  ) {
    accessWorkGroups = true;
  } else if (isLogged === true && USERTYPE === enumsTypeUser.ADMIN) {
    accessWorkGroups = true;
  } else {
    accessWorkGroups = false;
  }

  return (
    <Router>
      <Switch>
        {/*------------ URL'S LOGUIN, PASSWORD */}
        <Route exact path="/" component={Login} />
        {isLogged === true ? (
          <Route exact path="/user/recoverpassword" component={NotFound} />
        ) : (
          <Route exact path="/user/recoverpassword" component={RecoveryPassword} />
        )}
        <Route exact path="/nueva-contrasena" component={ChangePassword} />
        {isLogged === true ? (
          <Route exact path="/restablecimiento-de-contrasena" component={NotFound} />
        ) : (
          <Route
            exact
            path="/restablecimiento-de-contrasena"
            component={ChangeForgottenPasswordReset}
          />
        )}
        {/*---  Change Expirated Password and renew password  */}
        {(() => {
          if (isLogged === true) {
            return <Route exact path="/renew-password" component={ChangePasswordExpiration} />;
          } else {
            return <Route exact path="/renew-password" component={NotFound} />;
          }
        })()}

        {(() => {
          if (isLogged === true) {
            return <Route exact path="/export-data" component={ExportData} />;
          } else {
            return <Route exact path="/export-data" component={NotFound} />;
          }
        })()}

        {(() => {
          if (isLogged === true) {
            return <Route exact path="/file-not-found" component={NotFoundFile} />;
          } else {
            return <Route exact path="/file-not-found" component={NotFound} />;
          }
        })()}
        {/*------------- URL'S DASHBOARD */}
        {isLogged ? (
          <Route exact path="/dashboard" component={Dashboard} />
        ) : (
          <Route exact path="/" component={Dashboard} />
        )}
        {/*------------- URL'S RECORD LIST FORMS */}
        <Route exact path="/record/list/forms" component={RecordData} />
        <Route exact path="/record/create/:id" component={RecordFields} />
        <Route exact path="/record/details/:recordId/:id" component={RecordDetails} />
        <Route exact path="/record/details/:recordId/:id/:fileId" component={RecordDetails} />
        <Route exact path="/record/edit/:id/:recordId" component={EditRecord} />
        <Route exact path="/traceability/record/:id/:recordId" component={RecordTraceability} />
        {/*------------- URL'S RECORD CONSULT LIST FORMS */}
        <Route exact path="/record/list/form" component={RecordListByForm} />
        <Route exact path="/record/list/table/:id" component={RecordList} />
        <Route exact path="/record/list/forms/filters/:id" component={FilterForm} />
        <Route exact path="/record/detail/:recordId/:id/:fileId" component={RecordDetail} />
        <Route exact path="/record/detail/:recordId/:id" component={RecordDetail} />
        <Route exact path="/trd/expedients/consultation" component={TrdExpedientsConsultation} />
        {/*----------- URL'S CONSULT BY RECORD */}
        <Route exact path="/record/list/record" component={RecordListByRecord} />
        {/*----------- URL'S UPLOAD FILES AND SIGNATORIES */}
        <Route exact path="/files/signature/:formId/:hash" component={ElectronicSignature} />;
        <Route exact path="/signatories/:id" component={Signatories} />
        {/*----------- URL'S DEATILRECORDPROCESS */}
        <Route
          exact
          path="/traceability/workflow/:id/:recordId"
          component={WorkflowsTraceability}
        />
        {(() => {
          if (isLogged === true) {
            return <Route exact path="/process/detail/:id/:processId" component={ProcessDetail} />;
          } else {
            return <Redirect to="/" />;
          }
        })()}
        {(() => {
          if (isLogged === true && access) {
            return <Route exact path="/process/management" component={ProcessManagement} />;
          } else {
            return <Route exact path="/process/management" component={AccessDenied} />;
          }
        })()}
        {(() => {
          if (isLogged === true) {
            return <Route exact path="/taskBox/detail/:id" component={TaskDetail} />;
          } else {
            return <Redirect to="/" />;
          }
        })()}
        <Route exact path="/taskBox" component={taskBox} />
        {/*------------- URL'S REPORT SIGNATURES */}
        <Route exact path="/reports/signatures" component={ReportElectronicSignatures} />
        {/*--------- URL'S TRASH BIN */}
        <Route exact path="/trash" component={TrashConsult} />
        {/*------------- URL'S PARAMETER TRD */}
        {access && accessTRD ? (
          <Route exact path="/trd" component={Trd} />
        ) : (
          <Route exact path="/trd" component={AccessDenied} />
        )}
        {access && accessTRD ? (
          <Route exact path="/trd/expedient/:id?/:backgroundId?" component={TrdExpedients} />
        ) : (
          <Route exact path="/trd/expedient/:id?/:backgroundId?" component={AccessDenied} />
        )}
        {access && accessTRD ? (
          <Route exact path="/trd/ordination/:id?/:backgroundId?" component={TrdOrdinationUnits} />
        ) : (
          <Route exact path="/trd/ordination/:id?/:backgroundId?" component={AccessDenied} />
        )}
        {(() => {
          if (access === true) {
            return <Route exact path="/application/config/sticker" component={StickerPage} />;
          } else {
            return <Route exact path="/application/config/sticker" component={AccessDenied} />;
          }
        })()}
        {(() => {
          if (access === true && accessTRD === true) {
            return (
              <Route
                exact
                path="/trd/expedient/edit/:expedientId/:backgroundId/:levelId"
                component={EditExpedients}
              />
            );
          } else {
            return (
              <Route
                exact
                path="/trd/expedient/edit/:expedientId/:backgroundId/:levelId"
                component={AccessDenied}
              />
            );
          }
        })()}
        {access ? (
          <Route exact path="/documentary-types/list" component={DocumentaryTypes} />
        ) : (
          <Route exact path="/documentary-types/list" component={AccessDenied} />
        )}
        {(() => {
          if (access === true) {
            return (
              <Route
                exact
                path="/documentary-types/list/:documentaryId/:documentaryName"
                component={DocumentaryTypesEdition}
              />
            );
          } else {
            return (
              <Route
                exact
                path="/documentary-types/list/:documentaryId/:documentaryName"
                component={AccessDenied}
              />
            );
          }
        })()}
        {/*------------- URL'S FORMSDESIGNER */}
        {access &&
          accessDesignerForms &&
          !accessDesignerFormsConsult &&
          PASSWORD_EXPIRATION !== LIMIT_EXPIRATION_DAY ? (
          <Route exact path="/formsdesigner/create" component={FormsDesigner} />
        ) : (
          <Route exact path="/formsdesigner/create" component={AccessDenied} />
        )}
        {(access && accessDesignerForms) || (access && accessDesignerFormsConsult) ? (
          <Route exact path="/formsdesigner/list" component={FormsList} />
        ) : (
          <Route exact path="/formsdesigner/list" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/formsdesigner/edit/:id" component={FormsDesigner} />
        ) : (
          <Route exact path="/formsdesigner/edit/:id" component={AccessDenied} />
        )}
        {/*----------- URL'S FORMSDESIGNER FIELDS */}
        {access ? (
          <Route exact path="/formsdesigner/fields/:id/" component={FieldsDesigner} />
        ) : (
          <Route exact path="/formsdesigner/fields/:id/" component={AccessDenied} />
        )}
        {access ? (
          <Route
            exact
            path="/configuration/edit/:id/:form_uuid/:hashRecord/"
            component={ConsultConfigurationFields}
          />
        ) : (
          <Route
            exact
            path="/configuration/edit/:id/:form_uuid/:hashRecord/"
            component={AccessDenied}
          />
        )}
        {access ? (
          <Route
            exact
            path="/configuration/order/:id/:hash/:formId"
            component={ChangeOrderFields}
          />
        ) : (
          <Route exact path="/configuration/order/:id/:hash/:formId" component={AccessDenied} />
        )}
        {(() => {
          if (access === true) {
            return (
              <Route
                exact
                path="/configuration/consult/:id/:form_uuid/:status/:hashRecord"
                component={ConsultConfigurationFields}
              />
            );
          } else {
            return (
              <Route
                exact
                path="/configuration/consult/:id/:form_uuid/:status/:hashRecord"
                component={AccessDenied}
              />
            );
          }
        })()}
        {/*------------ URL'S CREATELIST */}
        {access && accessList ? (
          <Route exact path="/formsdesigner/createlist" component={CreateList} />
        ) : (
          <Route exact path="/formsdesigner/createlist" component={AccessDenied} />
        )}
        {access && accessList ? (
          <Route exact path="/formsdesigner/consultlist" component={ConsultList} />
        ) : (
          <Route exact path="/formsdesigner/consultlist" component={AccessDenied} />
        )}
        {access && accessList ? (
          <Route exact path="/formsdesigner/editlist/:id" component={EditList} />
        ) : (
          <Route exact path="/formsdesigner/editlist/:id" component={AccessDenied} />
        )}
        {/*----------- URL'S ITEMSlIST */}
        {access && accessList ? (
          <Route exact path="/formsdesigner/itemslist/:id/:parentId?" component={ItemsList} />
        ) : (
          <Route exact path="/formsdesigner/itemslist/:id/:parentId?" component={AccessDenied} />
        )}
        {access && accessList ? (
          <Route
            exact
            path="/formsdesigner/editItem/list/:listId/itemslist/:id"
            component={EditItemList}
          />
        ) : (
          <Route
            exact
            path="/formsdesigner/editItem/list/:listId/itemslist/:id"
            component={AccessDenied}
          />
        )}
        {/*----------- URL'S ADMINUSERS */}
        {access && accessUserManager && PASSWORD_EXPIRATION !== LIMIT_EXPIRATION_DAY ? (
          <Route exact path="/user/create" component={CreateUser} />
        ) : (
          <Route exact path="/user/create" component={AccessDenied} />
        )}
        {access && accessUserManager ? (
          <Route exact path="/user/list" component={UserList} />
        ) : (
          <Route exact path="/user/list" component={AccessDenied} />
        )}
        {access && accessUserManager ? (
          <Route exact path="/user/edit/:id" component={CreateUser} />
        ) : (
          <Route exact path="/user/edit/:id" component={AccessDenied} />
        )}
        {access && accessUserManager ? (
          <Route exact path="/user/edit/status/:id" component={ChangeUserStatus} />
        ) : (
          <Route exact path="/user/edit/status/:id" component={AccessDenied} />
        )}
        {accessUserManager ? (
          <Route exact path="/user/log/:id" component={UserLog} />
        ) : (
          <Route exact path="/user/log/:id" component={AccessDenied} />
        )}
        {(() => {
          if (access === true && accessWorkGroups === true) {
            return <Route exact path="/user/workgroup" component={WorkGroup} />;
          } else {
            return <Route exact path="/user/workgroup" component={AccessDenied} />;
          }
        })()}
        {(() => {
          if (access === true && accessWorkGroups === true) {
            return <Route exact path="/user/create/workgroup" component={UserWorkGroupForm} />;
          } else {
            return <Route exact path="/user/create/workgroup" component={AccessDenied} />;
          }
        })()}
        {(() => {
          if (access === true && accessWorkGroups === true) {
            return (
              <Route
                exact
                path="/user/workgroup/members/:id/:statusGroup"
                component={UserWorkGroupMembers}
              />
            );
          } else {
            return (
              <Route
                exact
                path="/user/workgroup/members/:id/:statusGroup"
                component={AccessDenied}
              />
            );
          }
        })()}
        {(() => {
          if (access === true && accessWorkGroups === true) {
            return <Route exact path="/user/create/workgroup/:id" component={UserWorkGroupForm} />;
          } else {
            return <Route exact path="/user/create/workgroup/:id" component={AccessDenied} />;
          }
        })()}
        {/*---------- URL'S ROLES */}
        {access && accessRoles ? (
          <Route exact path="/user/roles" component={UserRoles} />
        ) : (
          <Route exact path="/user/roles" component={AccessDenied} />
        )}
        {access && accessRoles && PASSWORD_EXPIRATION !== LIMIT_EXPIRATION_DAY ? (
          <Route exact path="/user/create/roles" component={UserRolesForm} />
        ) : (
          <Route exact path="/user/create/roles" component={AccessDenied} />
        )}
        {access && accessRoles ? (
          <Route exact path="/user/roles/edit/:id" component={UserRolesForm} />
        ) : (
          <Route exact path="/user/roles/edit/:id" component={AccessDenied} />
        )}
        {access && accessRoles ? (
          <Route exact path="/user/roles/members/:id" component={UserRolesMembers} />
        ) : (
          <Route exact path="/user/roles/members/:id" component={AccessDenied} />
        )}
        {access && accessRoles ? (
          <Route exact path="/user/roles/config/:id/:hash" component={UserRolesConfig} />
        ) : (
          <Route exact path="/user/roles/config/:id/:hash" component={AccessDenied} />
        )}
        {/*--------- URL'S METRICS */}
        {access ? (
          <Route exact path="/metrics" component={Metrics} />
        ) : (
          <Route exact path="/metrics" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/metrics/recordListbyForm" component={MetricsRecordsByForm} />
        ) : (
          <Route exact path="/metrics/recordListbyForm" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/audit/logs" component={AuditLogs} />
        ) : (
          <Route exact path="/audit/logs" component={AccessDenied} />
        )}
        {(() => {
          if (access === true) {
            return <Route exact path="/metrics/recordListByYear" component={MetricsRecordByYear} />;
          } else {
            return <Route exact path="/metrics/recordsListByYear" component={AccessDenied} />;
          }
        })()}
        {/*------------- URL'S WORKFLOWDESIGNER */}
        {access && accessWorkflowsForms && !accessWorkflowsFormsConsult ? (
          <Route exact path="/workflow" component={WorkFlow} />
        ) : (
          <Route exact path="/workflow" component={AccessDenied} />
        )}
        {access && accessWorkflowsForms && !accessWorkflowsFormsConsult ? (
          <Route exact path="/workflow/designer/:id" component={BpmnModels} />
        ) : (
          <Route exact path="/workflow/designer/:id" component={AccessDenied} />
        )}
        {(access && accessWorkflowsForms) || (access && accessWorkflowsFormsConsult) ? (
          <Route exact path="/workflow/list" component={WorkFlowList} />
        ) : (
          <Route exact path="/workflow/list" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/workflow/edit/:id" component={WorkFlowEdit} />
        ) : (
          <Route exact path="/workflow/edit/:id" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/workflow/steps/:id/:hash/:formId" component={WorkFlowSteps} />
        ) : (
          <Route exact path="/workflow/steps/:id/:hash/:formId" component={AccessDenied} />
        )}
        {access ? (
          <Route exact path="/workflow/view/:model" component={WorkflowView} />
        ) : (
          <Route exact path="/workflow/view/:model" component={AccessDenied} />
        )}
        {/*------------- URL'S WORKFLOWDESIGNER STEPS */}
        {access ? (
          <Route
            exact
            path="/workflow/steps/configuration/:id/:workflowid/:hash/:formId"
            component={WorkFlowConfigurationSteps}
          />
        ) : (
          <Route
            exact
            path="/workflow/steps/configuration/:id/:workflowid/:hash"
            component={AccessDenied}
          />
        )}
        {/*--------- URL'S INTEGRATIONS */}
        {access && accessIntegrations ? (
          <Route exact path="/integrations" component={Integrations} />
        ) : (
          <Route exact path="/integrations" component={AccessDenied} />
        )}
        {access && accessIntegrations ? (
          <Route exact path="/integrations/:id" component={DetailIntegrations} />
        ) : (
          <Route exact path="/integrations/:id" component={AccessDenied} />
        )}
        {access && accessIntegrations ? (
          <Route exact path="/integrations/request/:id" component={RequestIntegration} />
        ) : (
          <Route exact path="/integrations/request/:id" component={AccessDenied} />
        )}
        {/*---------- URL NOTFOUND */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
