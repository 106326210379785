import i18n from "locales/i18n";

export const nameTypes = (number) => {
  switch (number) {
    case 1:
      return i18n.t("form.field7");
    case 2:
      return i18n.t("form.field8");
    case 3:
      return i18n.t("form.field9");
    case 4:
      return i18n.t("form.field10");
    default:
      return "";
  }
};

export const controlData = (number) => {
  switch (number) {
    case 1:
      return i18n.t("form.field12");
    case 2:
      return i18n.t("form.field13");
    case 3:
      return i18n.t("form.field14");
    case 4:
      return i18n.t("form.field15");
    case 5:
      return i18n.t("form.field16");
    case 6:
      return i18n.t("form.field17");
    case 7:
      return i18n.t("form.field18");
    case 8:
      return i18n.t("form.field61");
    default:
      return "";
  }
};

export const actions = (action) => {
  switch (action) {
    case 1:
      return i18n.t("log.user.creation");
    case 2:
      return i18n.t("log.user.modification");
    case 3:
      return i18n.t("log.user.chageStatus");
    case 4:
      return i18n.t("log.user.password.assignment");
    case 5:
      return i18n.t("log.user.password.reset");
    case 6:
      return i18n.t("log.user.startLogin");
    case 7:
      return i18n.t("log.user.logOut");
    default:
      return "";
  }
};

export const nameProperty = (value) => {
  switch (value) {
    case "phone":
      return i18n.t("log.user.phone");
    case "email":
      return i18n.t("log.user.email");
    case "document_reference":
      return i18n.t("log.user.document_reference");
    case "first_name":
      return i18n.t("log.user.first_names");
    case "last_name":
      return i18n.t("log.user.last_name");
    case "user_name":
      return i18n.t("log.user.first_name");
    case "document_type":
      return i18n.t("log.user.document_type");
    case "user_type":
      return i18n.t("log.user.user_type");
    default:
      return value;
  }
};
