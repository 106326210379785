import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PropTypes from "prop-types";
import { Alert, Row, Col, Card, CardHeader } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTrdContext } from "contextAPI/TrdContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import TaskUpLoadFiles from "components/organism/TaskUploadFiles";
import cx from "classnames";
import i18n from "locales/i18n";

const TaskUploadFilesByExpedient = (props) => {
  const {
    pathConfiguration,
    hasExpedientFixedPath,
    currentPath,
    isProcess,
    isProcessBatch,
  } = props;
  const {
    folderName,
    expedientSetPath,
    setShowRightPanelTreeExpedients,
  } = useTrdContext();
  const { processTaskBatch } = useWorkflowContext();
  let titleFileByBatch = null;

  /**
   * Generates a title file component based on the condition of processing task batch.
   * @param {boolean} processTaskBatch - Indicates whether the task batch is being processed or not.
   * If true, it generates a title file component; otherwise, it does nothing.
   * @returns {JSX.Element|null} - The generated title file component wrapped in a JSX element,
   * or null if the task batch is not being processed.
   */
  if (processTaskBatch === true) {
    titleFileByBatch = (
      <CardHeader className="mb-4">
        {i18n.t("recordDetail.files.options")}
      </CardHeader>
    );
  }

  /**
   * Creates an alert component to display information about a file upload path.
   * @param {string} folderName - The name of the folder or file being referenced.
   * @returns {JSX.Element} The JSX element representing the alert component.
   */
  let alertPath = (
    <Alert className="mbg-3" color="info" isOpen={true}>
      <span className="pr-2">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      {i18n.t("uploadFile.file")}: <strong>{folderName}</strong>
    </Alert>
  );

  /**
   * Renders an informational alert containing the current path based on specific conditions.
   * @param {boolean} hasExpedientFixedPath - Indicates whether there is a fixed path for the expedient.
   * @param {string} currentPath - The current path information to be displayed.
   * @param {object} expedientSetPath - Configuration object for setting the expedient path.
   * @returns {JSX.Element | null} JSX element representing the alert component with path information,
   */
  if (
    hasExpedientFixedPath === true &&
    isNullOrUndefined(pathConfiguration) === false
  ) {
    alertPath = (
      <Alert className="mbg-2" color="info">
        <Row className=" align-items-center justify-content-between">
          <Col lg="10" md="6" sm="12" className="d-flex ">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={expedientSetPath}
            />
          </Col>
        </Row>
      </Alert>
    );
  }

  /**
   * useEffect hook that sets 'showRightPanelTreeExpedients' state to false when the component unmounts.
   * This is typically used to perform cleanup or side effects when the component is about to unmount.
   * @returns {void}
   */
  useEffect(() => {
    return () => {
      setShowRightPanelTreeExpedients(false);
    };
  });

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        className="scrollingContainer"
      >
        <div className="app-inner-layout rm-sidebar">
          <div className="app-inner-layout__content ">
            <Card
              className={cx("forms-fields mb-4", {
                "forms-fields mb-4 form-container-batch":
                  processTaskBatch === true,
              })}
            >
              {titleFileByBatch}
              <div className="app-inner-layout__content ">{alertPath}</div>

              <TaskUpLoadFiles
                fileByTrd={true}
                isProcess={isProcess}
                isProcessBatch={isProcessBatch}
              />
            </Card>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

TaskUploadFilesByExpedient.propTypes = {
  pathConfiguration: PropTypes.string,
  hasExpedientFixedPath: PropTypes.bool,
  currentPath: PropTypes.array,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
};

export default TaskUploadFilesByExpedient;
