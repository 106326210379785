import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { regexSubjectUploadFile } from "utils/regexExpressions";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import cx from "classnames";
import i18n from "locales/i18n";

const SearchBoxLookupField = (props) => {
  const {
    isLoadingFilesTrd,
    isLoadingFiles,
    searchedSubject,
    setSearchedSubject,
    paginationRecord,
    setPaginationRecord,
    paginationTrd,
    setPaginationTrd,
    errorCharacters,
    setErrorCharacters,
  } = props;
  const { setIsChangePage } = useRecordDetailContext();
  let regexSearchValue = regexSubjectUploadFile;

  /**
   * Handles changes to the search input value and updates the search data state, validates the input against a regex pattern.
   * @param {Object} eventChange - The event object representing the change in the input field.
   */
  const handleOnChangeSearch = (eventChange) => {
    const searchValueTiped = eventChange.target.value;
    const searchValueEncoded = encodeURIComponent(searchValueTiped);

    if (regexSearchValue.test(searchValueTiped) === false) {
      eventChange.preventDefault();
      setErrorCharacters(i18n.t("createRoles.InputInvalid"));
    } else {
      setErrorCharacters("");
    }
    setSearchedSubject(searchValueEncoded);
  };

  /**
   * Handles search validation before triggering a specified action.
   * This function checks if pagination records are defined, prevents the default action of the event,
   * and then validates the search data using a regular expression (`regex`). If the search data
   * passes the validation, the specified action function is called. *
   * @param {object} event - The event object associated with the triggering action.
   * This is typically an event object from a form submission or a button click.
   * @param {function} action - The action function to be executed if the search validation passes.
   * This function will only be called if the provided search data passes the validation.
   * @returns {void}
   */
  const handleSearchValidation = (event, action) => {
    if (paginationRecord !== undefined && paginationTrd !== undefined) {
      event.preventDefault();
      if (
        regexSubjectUploadFile.test(searchedSubject) === true &&
        errorCharacters === ""
      ) {
        action();
      }
    }
  };

  /**
   * Handles keypress events for search functionality, specifically the "Enter" key.
   * @param {KeyboardEvent} eventSearch - The keypress event object for the search input field.
   * @param {object} paginationRecords - The pagination records object.
   * @param {function} setPaginationRecords - The state setter function for updating pagination records.
   */
  const handleOnKeyPressForSearch = (eventSearch) => {
    if (eventSearch.key === "Enter") {
      handleSearchValidation(eventSearch, () => {
        setPaginationRecord({ ...paginationRecord, page: 1 });
        setPaginationTrd({ ...paginationTrd, page: 1 });
        setIsChangePage(true);
      });
    }
  };

  /**
   * Handles the click event of the open button, resetting the pagination page to 1 if paginationRecords is defined.
   * @param {Event} eventSearch - The click event object triggered when the open button is clicked.
   * @param {object} paginationRecords - The pagination records object.
   * @param {function} setPaginationRecords - The state setter function for updating pagination records.
   * @returns {void}
   */
  const handleOnClickOpenButton = (eventSearch) => {
    handleSearchValidation(eventSearch, () => {
      setPaginationRecord({ ...paginationRecord, page: 1 });
      setPaginationTrd({ ...paginationTrd, page: 1 });
      setIsChangePage(true);
    });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div
          className={cx("search-wrapperExplorer ml-auto search-box", {
            active: true,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              className="search-input"
              placeholder={i18n.t("searchbox.placeholder")}
              disabled={isLoadingFiles || isLoadingFilesTrd}
              onKeyPress={handleOnKeyPressForSearch}
              onChange={handleOnChangeSearch}
              value={decodeURIComponent(searchedSubject)}
            />
            <button
              className="search-icon"
              disabled={isLoadingFiles || isLoadingFilesTrd}
              onClick={handleOnClickOpenButton}
            >
              <span />
            </button>
          </div>
          {errorCharacters && (
            <span className="text-danger text small">{errorCharacters}</span>
          )}
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SearchBoxLookupField.propTypes = {
  searchedSubject: PropTypes.string.isRequired,
  setSearchedSubject: PropTypes.func.isRequired,
  paginationRecord: PropTypes.object.isRequired,
  setPaginationRecord: PropTypes.func.isRequired,
  paginationTrd: PropTypes.object.isRequired,
  setPaginationTrd: PropTypes.func.isRequired,
  errorCharacters: PropTypes.string.isRequired,
  setErrorCharacters: PropTypes.func.isRequired,
};

export default SearchBoxLookupField;
