import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import TrashFilesList from "components/organism/TrashFilesList";
import i18n from "locales/i18n";

const TrashConsult = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("trash.label1")}
        icon="trash"
        subheading={i18n.t("trash.label9")}
      >
        <TrashFilesList />
      </AdminTemplate>
    </Fragment>
  );
};

export default TrashConsult;
