import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  CardFooter,
  Card,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { enumsMaxMenuHeight, enumsTypeStatusUser, enumsWorkflowStatus } from "utils/enums";
import { initialPaginationGetUsers, initialPaginationTasks } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { loaderElement } from "utils/loaderElement";
import { regexNumericField } from "utils/regexExpressions";
import * as moment from "moment";
import cx from "classnames";
import useUser from "hooks/useUser";
import useWorkFlow from "hooks/useWorkFlow";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import DateRangePicker from "./DateRangePicker";
import ProcessManagementList from "components/organism/ProcessManagementList";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ProcessManagementForm = () => {
  const [selectedOptionsUsers, setSelectedOptionsUsers] = useState(null);
  const [selectedOptionsWorkflows, setSelectedOptionsWorkflows] = useState(null);
  const [selectedOptionsSteps, setSelectedOptionsSteps] = useState(null);
  const [usersDinamic, setUsersDinamic] = useState([]);
  const [workflowsDinamic, setWorkflowsDinamic] = useState([]);
  const [workflowsStepsDinamic, setWorkflowsStepsDinamic] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(false);
  const [isLoadingWorkflowStep, setIsLoadingWorkflowStep] = useState(false);
  const [isLoadingManagementProcess, setIsLoadingManagementProcess] = useState(false);
  const [orderHeaderBy, setOrderHeaderBy] = useState();
  const [orderHeaderType, setOrderHeaderType] = useState();
  const [filterDone, setFilterDone] = useState(false);
  const [isSortableFilter, setIsSortableFilter] = useState(false);
  const [paginationPorcessFilter, setPaginationPorcessFilter] = useState(initialPaginationTasks);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [processFilteredList, setProcessFilteredList] = useState([]);
  const [stepsProcessDisabled, setStepsProcessDisabled] = useState(false);
  const [isRequiredNumberProcess, setIsRequiredNumberProcess] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorsDate, setErrorsDate] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [queryProcessData, setQueryProcessData] = useState({
    user_uuid: null,
    workflow_uuid: null,
    step_uuid: null,
    process_number: null,
    start_date: null,
    end_date: null,
  });
  const { page, per_page } = initialPaginationGetUsers;
  const { getUsers } = useUser();
  const { getWorkFlows, getWorkFlowSteps, managementProcess } = useWorkFlow();
  let spinnerButton = null;
  let disabledButton = false;
  let selectUserDisabled = false;
  let selectWorkflowDisabled = false;
  let selectWorkflowStepsDisabled = false;
  let usersSelectByFilterPlaceholder = null;
  let workflowSelectByFilterPlaceholder = null;
  let workflowStepsSelectByFilterPlaceholder = null;

  /**
   * Updates the state of the user selection dropdown based on loading status.
   * If users are currently being loaded, the user selection dropdown is disabled
   * and a loading placeholder is shown. Otherwise, the placeholder indicates that
   * a user can be selected.
   * @param {boolean} isLoadingUsers - The current loading status of users.
   * @param {boolean} selectUserDisabled - A flag to disable the user selection dropdown.
   * @param {string} usersSelectByFilterPlaceholder - The placeholder text for the user selection dropdown.
   * @param {object} i18n - The internationalization object for translation.
   */
  if (isLoadingUsers === true) {
    selectUserDisabled = true;
    usersSelectByFilterPlaceholder = i18n.t("loading");
  } else {
    usersSelectByFilterPlaceholder = i18n.t("createWorkflow.config.listResponsibleSelectsOne");
  }

  /**
   * Updates the state of the workflow step selector and its placeholder text based on the loading status.
   * @param {boolean} isLoadingWorkflowStep - Indicates whether the workflow steps are currently being loaded.
   * @param {boolean} selectWorkflowStepsDisabled - The state of the workflow steps selector's disabled property.
   * @param {string} workflowStepsSelectByFilterPlaceholder - The placeholder text for the workflow steps selector.
   * @param {Object} i18n - The internationalization object used for translating text.
   */
  if (isLoadingWorkflowStep === true) {
    selectWorkflowStepsDisabled = true;
    workflowStepsSelectByFilterPlaceholder = i18n.t("loading");
  } else {
    workflowStepsSelectByFilterPlaceholder = i18n.t("processManagement.SelectProcess");
  }

  /**
   * Updates the state of the workflow select input and its placeholder based on loading status.
   * @param {boolean} isLoadingWorkflows - Indicates whether the workflows are currently loading.
   * @param {boolean} selectWorkflowDisabled - Variable to disable the workflow select input.
   * @param {string} workflowSelectByFilterPlaceholder - Placeholder text for the workflow select input.
   * @param {object} i18n - Internationalization object used for translations.
   */
  if (isLoadingWorkflows === true) {
    selectWorkflowDisabled = true;
    workflowSelectByFilterPlaceholder = i18n.t("loading");
  } else {
    workflowSelectByFilterPlaceholder = i18n.t("processManagement.SelectProcess");
  }

  /**
   * Updates the state of the spinner and the button's disabled property based on the loading status.
   * @param {boolean} isLoadingManagementProcess - Indicates whether the management process is currently loading.
   * @param {React.Component} Spinner - The Spinner component from the UI library.
   * @param {boolean} disabledButton - The state of the button's disabled property.
   * @returns {void}
   */
  if (isLoadingManagementProcess === true) {
    spinnerButton = <Spinner size="sm" color="secondary" type="grow" />;
    disabledButton = true;
  }

  /**
   * Fetches users and updates the state with a filtered and sorted list.
   * @param {number} page - The current page number for pagination.
   * @param {number} per_page - The number of users to fetch per page.
   * @param {Function} setIsLoadingUsers - Function to update the loading state for users.
   * @param {Function} getUsers - Function to fetch users from the API.
   * @param {Function} setUsersDinamic - Function to update the users state.
   * @param {Function} showAlertServiceError - Function to show an alert for service errors.
   * @param {Object} enumsTypeStatusUser - Enum containing user status types.
   */
  const getUsersBySearch = useCallback(() => {
    setIsLoadingUsers(true);
    getUsers(page, per_page, "")
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          const usersList = response.data.items;
          usersList
            .filter(
              (user) =>
                user.status !== enumsTypeStatusUser.RETIRED &&
                user.status !== enumsTypeStatusUser.INACTIVE
            )
            .sort((positionNameA, positionNameB) => {
              const nombresA = `${positionNameA.name} ${positionNameA.last_name} `.toLocaleLowerCase();
              const nombresB = `${positionNameB.name} ${positionNameB.last_name} `.toLocaleLowerCase();
              return nombresB.localeCompare(nombresA);
            })
            .forEach((user) => {
              setUsersDinamic((prevState) => [
                ...prevState,
                {
                  value: user.uuid,
                  label: `${user.first_name} ${user.last_name} (${user.user_name})`,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches and processes workflows, updating the dynamic workflows state.
   * @param {number} page - The current page number for pagination.
   * @param {number} per_page - The number of items per page for pagination.
   * @param {function} setIsLoadingWorkflows - A state setter function to indicate loading status.
   * @param {function} getWorkFlows - A function that retrieves workflows from the server.
   * @param {function} isNullOrUndefined - A utility function to check if a value is null or undefined.
   * @param {object} i18n - Internationalization object for translating strings.
   * @param {object} enumsWorkflowStatus - Enumeration object containing workflow statuses.
   * @param {function} setWorkflowsDinamic - A state setter function to update the dynamic workflows.
   * @param {function} showAlertServiceError - A function to display an error alert.
   */
  const getProcessBySearch = useCallback(() => {
    setIsLoadingWorkflows(true);
    getWorkFlows(page, per_page, "")
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          const workflowList = response.data.items;
          workflowList.push({
            uuid: false,
            name: i18n.t("processManagement.FreeProcess"),
            status: enumsWorkflowStatus.ACTIVE,
          });
          workflowList
            .filter((workflow) => workflow.status === enumsWorkflowStatus.ACTIVE)
            .sort((positionNameA, positionNameB) => {
              const nombresA = positionNameA.name.toLocaleLowerCase();
              const nombresB = positionNameB.name.toLocaleLowerCase();
              return nombresA.localeCompare(nombresB);
            })
            .forEach((workflow) => {
              setWorkflowsDinamic((prevState) => [
                ...prevState,
                {
                  value: workflow.uuid,
                  label: workflow.name,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingWorkflows(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches active workflow steps for a selected workflow ID and updates state variables.
   * @param {string} selectWorkflowId - The ID of the selected workflow to fetch steps for.
   * @param {function} setIsLoadingWorkflowStep - Setter function to update loading state for workflow steps.
   * @param {function} setWorkflowsStepsDinamic - Setter function to update dynamic workflow steps.
   * @param {function} getWorkFlowSteps - Function to fetch workflow steps based on workflow ID.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {function} showAlertServiceError - Function to display an alert for service errors.
   * @returns {void}
   */
  const getActiveSteps = useCallback((selectWorkflowId) => {
    setIsLoadingWorkflowStep(true);
    setWorkflowsStepsDinamic([]);
    getWorkFlowSteps(selectWorkflowId)
      .then((response) => {
        if (isNullOrUndefined(response) === false) {
          const workflowSteps = response;
          workflowSteps.forEach((step) => {
            setWorkflowsStepsDinamic((prevState) => [
              ...prevState,
              {
                value: step.uuid,
                label: step.name,
              },
            ]);
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingWorkflowStep(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Retrieves filtered query process data based on specified parameters.
   * @param {Object} queryProcessData The data object containing query parameters:
   * @param {string} queryProcessData.user_uuid User UUID for filtering.
   * @param {string} queryProcessData.workflow_uuid Workflow UUID for filtering.
   * @param {string} queryProcessData.step_uuid Step UUID for filtering.
   * @param {number} queryProcessData.process_number Process number for filtering.
   * @param {Date} queryProcessData.start_date Start date for filtering.
   * @param {Date} queryProcessData.end_date End date for filtering.
   * @param {string} orderHeaderType Type of ordering for the process list.
   * @param {string} orderHeaderBy Field by which the process list should be ordered.
   */
  const getQueryProcessByFilter = useCallback(
    (queryProcessData, orderHeaderType, orderHeaderBy) => {
      const { page, per_page } = paginationPorcessFilter;
      setIsLoadingManagementProcess(true);
      managementProcess(page, per_page, queryProcessData, orderHeaderType, orderHeaderBy)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            const processDataFilter = response.data;
            if (processDataFilter.items.length === 0) {
              setFilterDone(false);
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("taskFilter.label5"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
            setIsSortableFilter(true);
            setProcessFilteredList(processDataFilter.items);
            setTotalCount(processDataFilter.count);
            setTotalPages(processDataFilter.pages);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingManagementProcess(false);
        });
    },
    [paginationPorcessFilter] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Handles the change event when selecting users.
   * Updates the selected options state and updates the user_uuid in queryProcessData accordingly.
   * @param {Object} selectedOption - The selected option object representing the user.
   * @param {string} selectedOption.value - The UUID of the selected user.
   */
  const handleChangeUsers = (selectedOption) => {
    setSelectedOptionsUsers(selectedOption);
    if (selectedOption) {
      setQueryProcessData((prevData) => ({
        ...prevData,
        user_uuid: selectedOption.value,
      }));
    } else {
      setQueryProcessData((prevData) => ({
        ...prevData,
        user_uuid: null,
      }));
    }
  };

  /**
   * Handles the change in selected workflows and updates relevant state data.
   * @param {Object} selectedOption - The selected option object containing value and label.
   * @param {string} selectedOption.value - The value representing the selected workflow's UUID.
   * @param {string} selectedOption.label - The label representing the name or description of the selected workflow.
   */
  const handleChangeWorkflows = (selectedOption) => {
    setSelectedOptionsWorkflows(selectedOption);
    if (selectedOption) {
      setQueryProcessData((prevData) => ({
        ...prevData,
        workflow_uuid: selectedOption.value,
      }));
    } else {
      setQueryProcessData((prevData) => ({
        ...prevData,
        workflow_uuid: null,
        step_uuid: null,
      }));
    }
  };

  /**
   * Handles the change event when a step option is selected.
   * Updates the selected step option and modifies the query process data accordingly.
   * @param {Object} selectedOption - The selected step option object containing at least a `value` property.
   * @param {string} selectedOption.value - The value representing the selected step's UUID.
   */
  const handleChangeSteps = (selectedOption) => {
    setSelectedOptionsSteps(selectedOption);
    if (selectedOption) {
      setQueryProcessData((prevData) => ({
        ...prevData,
        step_uuid: selectedOption.value,
      }));
    } else {
      setQueryProcessData((prevData) => ({
        ...prevData,
        step_uuid: null,
      }));
    }
  };

  /**
   * Handles the change event for the process number input field.
   * Updates the query process data state with the new process number value.
   * @param {Object} eventChangeProcessNumber - The change event object triggered by the input field.
   * @param {EventTarget} eventChangeProcessNumber.target - The target element that triggered the event.
   * @param {string} eventChangeProcessNumber.target.value - The new value entered in the input field.
   */
  const handleOnChangeProcessNumber = (eventChangeProcessNumber) => {
    const processNumber = eventChangeProcessNumber.target.value;
    if (processNumber) {
      setQueryProcessData((prevData) => ({
        ...prevData,
        process_number: Number(processNumber),
      }));
    } else {
      setQueryProcessData((prevData) => ({
        ...prevData,
        process_number: null,
      }));
    }
  };

  /**
   * Handles the form submission for querying data based on specified criteria.
   * Prevents submission if required fields are not filled or errors are present.
   * @param {Event} eventSubmit - The form submission event object.
   * @param {Array} errors - Array containing validation errors.
   */
  const handleOnSubmitQuery = (eventSubmit, errors) => {
    if (queryProcessData.process_number === null) {
      eventSubmit.preventDefault();
      if (startDate === null && endDate === null) {
        setErrorsDate({
          startDateError: i18n.t("processManagement.StartDateRequired"),
          endDateError: i18n.t("processManagement.EndtDateRequired"),
        });
        eventSubmit.preventDefault();
      } else if (startDate === null && endDate !== null) {
        setErrorsDate({
          startDateError: i18n.t("processManagement.StartDateRequired"),
        });
        eventSubmit.preventDefault();
      } else if (startDate !== null && endDate === null) {
        setErrorsDate({
          endDateError: i18n.t("processManagement.EndtDateRequired"),
        });
        eventSubmit.preventDefault();
      }
    }

    if (
      errors.length === 0 &&
      isNullOrUndefined(errorsDate.startDateError) === true &&
      isNullOrUndefined(errorsDate.endDateError) === true
    ) {
      setFilterDone(true);
    } else {
      setFilterDone(false);
      eventSubmit.preventDefault();
    }
  };

  /**
   * useEffect hook to reset date error states based on process_number changes.
   * @param {Object} queryProcessData - The state object containing query parameters.
   * @param {string | null} queryProcessData.process_number - The process number used for conditional logic.
   * @param {Function} setErrorsDate - Setter function to update date error states.
   * @param {Object} errorsDate - Current state object containing date error messages.
   * @param {string} errorsDate.startDateError - Error message for the start date validation.
   * @param {string} errorsDate.endDateError - Error message for the end date validation.
   */
  useEffect(() => {
    if (queryProcessData.process_number !== null) {
      setErrorsDate({
        startDateError: "",
        endDateError: "",
      });
    }
  }, [queryProcessData.process_number]);

  /**
   * Effect hook to handle updates based on changes to queryProcessData.
   * Disables steps process and clears selected options if workflow_uuid is null or false.
   * Sets isRequiredNumberProcess to true if both startDate and endDate are null.
   * @param {Object} queryProcessData - The state object containing query data.
   * @param {boolean} queryProcessData.workflow_uuid - The UUID of the workflow.
   * @param {Date | null} startDate - The start date selected by the user.
   * @param {Date | null} endDate - The end date selected by the user.
   * @param {function} setStepsProcessDisabled - Function to set steps process disabled state.
   * @param {function} setSelectedOptionsSteps - Function to set selected options for steps.
   * @param {function} setIsRequiredNumberProcess - Function to set isRequiredNumberProcess state.
   */
  useEffect(() => {
    if (queryProcessData.workflow_uuid === null || queryProcessData.workflow_uuid === false) {
      setStepsProcessDisabled(true);
      setSelectedOptionsSteps(null);
    } else {
      setStepsProcessDisabled(false);
    }

    if (startDate === null && endDate === null) {
      setIsRequiredNumberProcess(true);
    } else {
      setIsRequiredNumberProcess(false);
    }

    setSelectedOptionsSteps(null);
  }, [queryProcessData.workflow_uuid, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the `queryProcessData` state based on the provided `startDate` and `endDate`.
   * @param {Date|null} startDate - The start date to be set in the query process data. If null, `start_date` will be set to null.
   * @param {Date|null} endDate - The end date to be set in the query process data. If null, `end_date` will be set to null.
   * @param {Function} setQueryProcessData - Function to update the state of `queryProcessData`.
   * @param {Function} isNullOrUndefined - Utility function to check if a value is null or undefined.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(startDate) === false && isNullOrUndefined(endDate) === false) {
      const currentEndDate = moment(endDate);
      const newEndDate = currentEndDate.add(1, "days");
      setQueryProcessData((prevData) => ({
        ...prevData,
        start_date: moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
        end_date: moment.utc(newEndDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
      }));
    } else {
      setQueryProcessData((prevData) => ({
        ...prevData,
        start_date: null,
        end_date: null,
      }));
    }
  }, [startDate, endDate]);

  /**
   * Executes functions to fetch users and processes based on search criteria upon component mount or dependencies change.
   * @param {Function} getUsersBySearch - Function to fetch users based on search criteria.
   * @param {Function} getProcessBySearch - Function to fetch processes based on search criteria.
   * @returns {void}
   */
  useEffect(() => {
    getUsersBySearch();
    getProcessBySearch();
  }, [getUsersBySearch, getProcessBySearch]);

  /**
   * Effect hook to fetch active steps when `workflow_uuid` changes.
   * @param {object} queryProcessData - The state object containing query parameters.
   * @param {string} queryProcessData.workflow_uuid - The UUID of the workflow to fetch steps for.
   * @param {function} getActiveSteps - Function to fetch active steps based on `workflow_uuid`.
   */
  useEffect(() => {
    if (
      isNullOrUndefined(queryProcessData.workflow_uuid) === false &&
      queryProcessData.workflow_uuid !== false
    ) {
      getActiveSteps(queryProcessData.workflow_uuid);
    }
  }, [queryProcessData.workflow_uuid, getActiveSteps]);

  /**
   * Executes a callback function to fetch query process data based on filter conditions.
   * @param {boolean} filterDone - Indicates if filtering is enabled.
   * @param {boolean} orderHeaderBy - The order header by condition.
   * @param {boolean} orderHeaderType - The order header type condition.
   * @param {Object} queryProcessData - The data object used for querying processes.
   * @param {string} queryProcessData.user_uuid - The UUID of the user for filtering.
   * @param {string} queryProcessData.workflow_uuid - The UUID of the workflow for filtering.
   * @param {string} queryProcessData.step_uuid - The UUID of the step for filtering.
   * @param {number} queryProcessData.process_number - The process number for filtering.
   * @param {Date|string} queryProcessData.start_date - The start date for filtering (UTC).
   * @param {Date|string} queryProcessData.end_date - The end date for filtering (UTC).
   * @param {function} getQueryProcessByFilter - The function to fetch query results based on filters.
   */
  useEffect(() => {
    if (
      filterDone === true &&
      isNullOrUndefined(orderHeaderBy) === true &&
      isNullOrUndefined(orderHeaderType) === true
    ) {
      getQueryProcessByFilter(queryProcessData);
    }
  }, [paginationPorcessFilter, filterDone, getQueryProcessByFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a callback function when dependencies change, fetching query process data based on filters.
   * @param {boolean} isSortableFilter - Flag indicating if sorting filters are enabled.
   * @param {string|null|undefined} orderHeaderBy - Field to order query results by.
   * @param {string|null|undefined} orderHeaderType - Order type ('asc' or 'desc').
   * @param {Function} getQueryProcessByFilter - Function to fetch query process data based on filters.
   * @param {Object} queryProcessData - Object containing query parameters (user_uuid, workflow_uuid, etc.).
   * @param {any[]} paginationPorcessFilter - Dependencies that trigger the effect (e.g., pagination changes).
   */
  useEffect(() => {
    if (isSortableFilter === true) {
      if (
        isNullOrUndefined(orderHeaderBy) === false &&
        isNullOrUndefined(orderHeaderType) === false
      ) {
        getQueryProcessByFilter(queryProcessData, orderHeaderType, orderHeaderBy);
      } else {
        getQueryProcessByFilter(queryProcessData);
      }
    }
  }, [paginationPorcessFilter, orderHeaderBy, orderHeaderType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (filterDone === true && processFilteredList.length > 0) {
    return (
      <Fragment>
        {loaderElement(isLoadingManagementProcess)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <ProcessManagementList
            processFilteredList={processFilteredList}
            isLoadingManagementProcess={isLoadingManagementProcess}
            totalPages={totalPages}
            paginationPorcessFilter={paginationPorcessFilter}
            setPaginationPorcessFilter={setPaginationPorcessFilter}
            totalCount={totalCount}
            setOrderHeaderBy={setOrderHeaderBy}
            setOrderHeaderType={setOrderHeaderType}
            setFilterDone={setFilterDone}
            queryProcessData={queryProcessData}
            usersDinamic={usersDinamic}
            workflowsDinamic={workflowsDinamic}
            workflowsStepsDinamic={workflowsStepsDinamic}
            getQueryProcessByFilter={getQueryProcessByFilter}
          />
        </CSSTransitionGroup>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {loaderElement(isLoadingUsers || isLoadingManagementProcess)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card>
            <AvForm onSubmit={handleOnSubmitQuery}>
              <CardHeader className="card-header-lg">
                <h5 className="text-info font-weight-bold">{i18n.t("filterQuery.title")}</h5>
              </CardHeader>
              <CardBody>
                <Alert color="info">
                  <span>
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2 " />
                    <span className="font-weight-bold mbg-3">
                      {i18n.t("modal.DoneError.header")} :{" "}
                    </span>
                    <span>{i18n.t("processManagement.AlertInfo")}</span>
                  </span>
                </Alert>
                <Row>
                  <Col md="3">
                    <div className="mb-4">
                      <Label for="user_uuid">{i18n.t("createWorkflow.config.responsible")}</Label>
                      <Select
                        id="user_uuid"
                        name="user_uuid"
                        maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
                        menuPlacement="auto"
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        placeholder={usersSelectByFilterPlaceholder}
                        options={usersDinamic}
                        isDisabled={selectUserDisabled}
                        onChange={handleChangeUsers}
                        value={selectedOptionsUsers}
                        noOptionsMessage={() => i18n.t("create.work.group.members.alert3")}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-4">
                      <Label for="workflow_uuid">{i18n.t("processName")}</Label>
                      <Select
                        id="workflow_uuid"
                        name="workflow_uuid"
                        maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
                        menuPlacement="auto"
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        placeholder={workflowSelectByFilterPlaceholder}
                        options={workflowsDinamic}
                        isDisabled={selectWorkflowDisabled}
                        onChange={handleChangeWorkflows}
                        value={selectedOptionsWorkflows}
                        noOptionsMessage={() => i18n.t("taskFilter.label8")}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <AvGroup>
                      <Label
                        for="process_number"
                        className={cx("", { "is-required": isRequiredNumberProcess === true })}
                      >
                        {i18n.t("createWorflow.Label3")}
                      </Label>
                      <AvField
                        id="process_number"
                        name="process_number"
                        type="text"
                        autoComplete="off"
                        onChange={handleOnChangeProcessNumber}
                        value={queryProcessData.process_number}
                        validate={{
                          pattern: {
                            value: regexNumericField,
                            errorMessage: `${i18n.t("recordValidatePositive")}`,
                          },
                          required: {
                            value: isRequiredNumberProcess,
                            errorMessage: `${i18n.t("fieldRequired")}`,
                          },
                        }}
                      ></AvField>
                    </AvGroup>
                  </Col>

                  <Col md="3">
                    <div className="mb-4">
                      <Label for="step_uuid">{i18n.t("taskFilter.label12")}</Label>
                      <Select
                        id="step_uuid"
                        name="step_uuid"
                        maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
                        menuPlacement="auto"
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        placeholder={workflowStepsSelectByFilterPlaceholder}
                        options={workflowsStepsDinamic}
                        isDisabled={stepsProcessDisabled || selectWorkflowStepsDisabled}
                        onChange={handleChangeSteps}
                        value={selectedOptionsSteps}
                        noOptionsMessage={() => i18n.t("taskReturnStepSelectNoOptions")}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="7" md="12" sm="12">
                    <div className="mb-4">
                      <DateRangePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        errorsDate={errorsDate}
                        setErrorsDate={setErrorsDate}
                        dataProcessNumber={queryProcessData.process_number}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="d-block text-right">
                <Button
                  type="submit"
                  size="lg"
                  className="col-mt-3"
                  color="cyan"
                  disabled={disabledButton}
                >
                  {spinnerButton}
                  <FontAwesomeIcon icon={faSearch} className="mr-2" />
                  {i18n.t("taskFilter.label10")}
                </Button>
              </CardFooter>
            </AvForm>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default ProcessManagementForm;
