import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_WORKFLOW } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useWorkflowsDiagram() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };
  const headersBpmnView = {
    "Content-Type": "application/xml",
    "Access-Control-Allow-Origin": "*"
  }

  const Created_workflow = async (data) => {
    const response = await axios.post(`${URL_WORKFLOW}/workflows/`, data,
      {
        headers,
        endpointError: endpointError.workflow.Created_workflow
      })
    return response
  }

  const Created_workflow_proccess = async (data, id) => {
    const response = await axios.post(`${URL_WORKFLOW}/workflows/file-config/${id}/`, data,
      {
        headers,
        endpointError: endpointError.workflow.Created_workflow_proccess
      },
    )
    return response
  }


  const upload_xml = async (file, id) => {
    const response = await axios.post(`${URL_WORKFLOW}/workflows/file-config/${id}/`, file,
      {
        headers,
        endpointError: endpointError.workflow.upload_xml
      })

    return response
  }

  const delete_workflow_diagram = async (id) => {
    const response = await axios.delete(`${URL_WORKFLOW}/workflows/file-config/${id}/`,
      {
        headers,
        endpointError: endpointError.delete_workflow_diagram
      })

    return response
  }

  const getProcessView = async (urlProcess) => {
    const response = await axios.get(`${urlProcess}`,
      {
        headersBpmnView,
        endpointError: endpointError.getProcessView
      });

      return response;
  };

  return {
    Created_workflow,
    Created_workflow_proccess,
    upload_xml,
    delete_workflow_diagram,
    getProcessView,
  };
}
