import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
	Alert,
	UncontrolledButtonDropdown,
	DropdownToggle,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Row,
	Col,
	Button,
	Spinner,
	CardFooter,
} from "reactstrap";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { initialMaxUsersConfig } from "utils/initialMaxUsersConfig";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { showAlertServiceError } from "utils/alerts";
import { enumsInactiveUserWorkGroup } from "utils/enums";
import swal from "sweetalert";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";
import useUser from "hooks/useUser";

const ModalAddMembersToWorkGroup = (props) => {
	const {
		idWorkGroup,
		countMembersWorkGroup
	} = props;
	const { getUserWorkGroupByRol, addUsersToWorkGroup } = useUser();
	const [numberOfMembers] = useState(initialMaxUsersConfig);
	const [showModalMemberGroup, setShowModalMemberGroup] = useState(false);
	const [isLoadingMemberGroup, setIsLoadingMemberGroup] = useState(false);
	const [membersDinamic, setMembersDinamic] = useState([]);
	const [selectedUsersWorkgorup, setSelectedOptionUsersWorkgorup] = useState([]);
	const [addMembers, setAddMembers] = useState({
		members: [],
	});
	const [isErrorAddMemberToWorkGroup, setIsErrorAddMemberToWorkGroup] = useState(false);
	const [isLmitOfWorkUser, setIsLimitOfWorkGroup] = useState(false);

	let loadingWorkgroup = null;
	let isLimitOfFiftyUserMember = null;
	let isLimiOfFiftyUserMemberInfo = i18n.t("createRoles.MembersNousers");
	let selectMemberUsersWorkgroup = null;

	/**
	 * Displays an error message if there is an error adding a member to the workgroup.
	 * @param {boolean} isErrorAddMemberToWorkGroup - Indicates whether there was an error adding a member to the workgroup.
	 * @param {Object} i18n - An internationalization object for translations.
	 * @returns {JSX.Element|null} Returns a JSX element with an error message if there is an error, otherwise null.
	 */
	if (isErrorAddMemberToWorkGroup === true) {
		selectMemberUsersWorkgroup = (
			<span className="text-danger small">
				{i18n.t("fieldRequired")}
			</span>
		)
	};

	/**
	 * Determines if the limit of fifty user members is reached and sets the appropriate values.
	 * @param {number} isLmitOfWorkUser - The current number of work users.
	 * @param {number} countMembersWorkGroup - The current number of members in the work group.
	 * @param {Object} numberOfMembers - An object containing the maximum number of members allowed.
	 * @param {number} numberOfMembers.maximumMembers - The maximum number of members allowed.
	 * @param {Array} membersDinamic - An array of dynamic members.
	 * @param {Object} i18n - An internationalization object for translations.
	 * @returns {Object} An object containing the updated values for `isLimitOfFiftyUserMember` and `isLimiOfFiftyUserMemberInfo`.
	 */
	if (isLmitOfWorkUser === numberOfMembers.maximumMembers ||
		countMembersWorkGroup === numberOfMembers.maximumMembers) {
		isLimitOfFiftyUserMember = [];
		isLimiOfFiftyUserMemberInfo = i18n.t("create.work.group.members.label17");
	} else {
		isLimitOfFiftyUserMember = membersDinamic;
		isLimiOfFiftyUserMemberInfo = i18n.t("createRoles.MembersNousers");
	}

	/**
	 * Determines if the workgroup is loading and returns a loading indicator if true.
	 * @param {boolean} isLoadingMemberGroup - Indicates whether the member group is currently loading.
	 * @returns {JSX.Element|null} Returns a loading spinner if the member group is loading, otherwise null.
	 */
	if (isLoadingMemberGroup === true) {
		loadingWorkgroup = (
			<Spinner size="sm" color="secondary" type="grow" />
		)
	}

	/**
	 * Closes the modal for adding members to the workgroup and resets the relevant state variables. 
	 * @name closeModalAddMembersToWorkgroup
	 * @returns {void}
	 */
	const closeModalAddMembersToWorkgroup = () => {
		setShowModalMemberGroup(false);
		setAddMembers({
			...addMembers,
			members: [],
		});
		setIsErrorAddMemberToWorkGroup(false);
		setSelectedOptionUsersWorkgorup([]);
	}

	/**
	 * Fetches users by role and performs a search.
	 * @returns {void}
	 */
	const getUsersByRolSearch = useCallback(() => {
		getUserWorkGroupByRol(idWorkGroup).then((response) => {
			let listUsers = response.data.data;
			if (Array.isArray(listUsers) && listUsers !== undefined && listUsers !== null) {
				listUsers
					.sort((firstUser, secondUser) => {
						const firstUserOfGroup = `${firstUser.first_name} ${firstUser.last_name}`.toLocaleLowerCase();
						const secondUserOfGroup = `${secondUser.first_name} ${secondUser.last_name}`.toLocaleLowerCase();
						return secondUserOfGroup.localeCompare(firstUserOfGroup);
					})
					.forEach((user) => {
						if (user.status !== enumsInactiveUserWorkGroup.INACTIVE_USER && user.status !== enumsInactiveUserWorkGroup.RETIRED_USER) {
							let label = `${user.first_name} ${user.last_name}`;
							if (user.user_name) {
								label += ` (${user.user_name})`;
							} else {
								label += ` (usuario@usuario)`;
							}
							setMembersDinamic((prevState) => [
								...prevState,
								{
									value: user.uuid,
									label: label,
								},
							]);
						}
					});
			}
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Handles the onChange event for selecting users.
	 * @param {Array} selectedUsersWorkgorup - The selected options.
	 * @returns {void}
	 */
	const handleOnChangeUsers = useCallback((selectedUsersWorkgorup) => {
		if (selectedUsersWorkgorup.length === 0) {
			setIsErrorAddMemberToWorkGroup(true);
			setAddMembers({
				...addMembers,
				members: [],
			});
		} else {
			setAddMembers({
				...addMembers,
				members: selectedUsersWorkgorup.map((users) => users.value),
			});
			setIsErrorAddMemberToWorkGroup(false);
		}
		setSelectedOptionUsersWorkgorup(selectedUsersWorkgorup);
	}, [addMembers]);

	/**
	 * Handles the onClick event for adding members to a work group.
	 * @returns {void}
	 */
	const handleOnClickMemebersToWorkGroup = () => {
		setShowModalMemberGroup(true);
	};

	/**
	 * Handles the onClose event for closing the add members modal.
	 * @returns {void}
	 */
	const handleOnCloseModalAddMembersWorkGroup = () => {
		setShowModalMemberGroup(false);
	};

	/**
	 * Handles the onSubmit event for adding users to a work group.
	 * @param {Event} event - The event object for the onSubmit event.
	 * @param {Array} errors - The array of errors.
	 * @returns {void}
	 */
	const handleOnSubmit = (eventSubmitMembers, errors) => {
		if (addMembers.members.length === 0 || selectedUsersWorkgorup.length === 0) {
			setIsErrorAddMemberToWorkGroup(true);
			setIsLoadingMemberGroup(false);
			eventSubmitMembers.preventDefault();
		}
		eventSubmitMembers.preventDefault();
		if (errors.length === 0) {
			if (addMembers.members.length === 0 || selectedUsersWorkgorup.length === 0) {
				eventSubmitMembers.preventDefault();
			} else {
				setIsLoadingMemberGroup(true)
				let usersUuid = selectedUsersWorkgorup.map((users) => users.value)
				const service = {
					users: usersUuid
				}
				addUsersToWorkGroup(idWorkGroup, service)
					.then((response) => {
						if (response.status === 201) {
							setShowModalMemberGroup(false);
							swal({
								title: i18n.t("modal.DoneError.header"),
								text: i18n.t("create.work.group.members.alert1"),
								icon: "success",
								button: i18n.t("modal.Done.footerButton"),
							}).then(() => {
								window.location.reload();
							})
						} else {
							showAlertServiceError();
						}
					})
					.finally(() => { setIsLoadingMemberGroup(false) })
			}
		}
	};

	/**
	 * Executes the effect to get users by role and perform a search.
	 * @returns {void}
	 */
	useEffect(() => {
		getUsersByRolSearch();
	}, [getUsersByRolSearch]);

	/**
	 * Executes the effect to update the limit of work group based on the number of members in the select.
	 * @returns {void}
	 */
	useEffect(() => {
		const numberOfMembersInSelect = Number(addMembers.members.length) + countMembersWorkGroup;
		setIsLimitOfWorkGroup(numberOfMembersInSelect)
		// eslint-disable-next-line
	}, [addMembers])


	return (
		<Fragment>
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<div>
					<UncontrolledButtonDropdown>
						<DropdownToggle
							disabled={countMembersWorkGroup > numberOfMembers.limitNumberOfMembers}
							className="button"
							color="success"
							onClick={handleOnClickMemebersToWorkGroup}
						>
							<span>
								{"+ "}
								{i18n.t("create.work.group.members.button")}
							</span>
						</DropdownToggle>
					</UncontrolledButtonDropdown>
				</div>
				<Modal
					isOpen={showModalMemberGroup}
					toggle={handleOnCloseModalAddMembersWorkGroup}
					className="modal-large-size"
					size="xl"
				>
					<ModalHeader>
						<Label>{i18n.t("create.work.group.members.label1")}</Label>
					</ModalHeader>
					<ModalBody>
						<AvForm onSubmit={handleOnSubmit}>
							<Row>
								<Col md="12">
									<Alert className="mbg-3" color="info" isOpen={true}>
										<span className="pr-2">
											<FontAwesomeIcon icon={faInfoCircle} />
										</span>
										{i18n.t("create.work.group.members.label19")}
									</Alert>{" "}
									<AvGroup>
										<Label
											for="users_uuid"
											className={cx("is-required", {
												"labels-error": isErrorAddMemberToWorkGroup === true,
											})}
										>
											{i18n.t("taskReassign.users")}
										</Label>
										<div>
											<Select
												id="users_uuid"
												name="users_uuid"
												classNamePrefix={cx("", {
													select: isErrorAddMemberToWorkGroup === true,
												})}
												className="select_users_scrollable"
												placeholder={i18n.t("notifications.usersPlaceholder")}
												closeMenuOnSelect={true}
												components={makeAnimated()}
												isMulti
												value={selectedUsersWorkgorup}
												options={isLimitOfFiftyUserMember}
												onChange={handleOnChangeUsers}
												noOptionsMessage={() => isLimiOfFiftyUserMemberInfo}
											/>
											{selectMemberUsersWorkgroup}
										</div>
									</AvGroup>
								</Col>
							</Row>

							<CardFooter className="d-block text-right">
								<Button
									size="lg"
									onClick={closeModalAddMembersToWorkgroup}
									className="col-mt-3 mr-3"
									color="gray"
								>
									{i18n.t("createusers.createButton2")}
								</Button>

								<Button
									type="submit"
									size="lg"
									disabled={isLoadingMemberGroup}
									className="col-mt-3"
									color="cyan"
								>
									{loadingWorkgroup}
									{i18n.t("createRoles.MembersButton")}
								</Button>
							</CardFooter>
						</AvForm>
					</ModalBody>
				</Modal>
			</CSSTransitionGroup>
		</Fragment>
	);
};

ModalAddMembersToWorkGroup.propTypes = {
	idWorkGroup: PropTypes.string.isRequired,
	countMembersWorkGroup: PropTypes.number.isRequired
};

export default ModalAddMembersToWorkGroup
