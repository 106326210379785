import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Button, Col } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import { Link } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  enumsProcessTaskType,
  enumsSortedTask,
  enumsSortedTypeTask,
} from "utils/enums";
import FiltersApplyByFiltersFormsRecords from "components/molecules/FiltersApplyByFiltersFormsRecords";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const TaskBoxListTableFilter = (props) => {
  const {
    tasks,
    pagination,
    setPagination,
    isLoading,
    totalPages,
    isFilter,
    selectedOptions,
    selectedOptionsWorkflow,
    selectedOptionsStep,
    selectedFields,
    setSelectedFields,
    isVisibleButtonProcessBatch,
    taskSelectedByBatch,
    setTaskSelectedByBatch,
  } = props;

  const [disabledCheck, setDisabledCheck] = useState(false);
  const [isClicked, setIsClicked] = useState({});
  const [clickCounts, setClickCounts] = useState({});
  let sortedConfig = [{ id: null, desc: false }];
  const [sorted, setSorted] = useState(sortedConfig);
  const {
    setOrderHeaderBy,
    setOrderHeaderType,
    filterDone,
  } = useWorkflowContext();

  let maximumSelectedTasksAllowed = 20;
  let labelsByApplyFilters = null;
  let disabledAccesTaskDetail = false;
  let selectFormLabelByFilter = null;
  let selectWorkflowLabelByFilter = null;
  let selectStepLabelByFilter = null;
  let paginationZise = null;
  let disabledChecks = false;
  let taskasigned = tasks;
  let disaleSortedButton = false;
  let sortableOption = true;

  /**
   * Represents a checkbox element with a label for selecting all tasks.
   * @param {boolean} isVisibleButtonProcessBatch - Determines the visibility of the checkbox button.
   * @param {boolean} disabledCheck - Determines whether the checkbox is disabled or not.
   * @param {function} handleSelectAllTasks - Callback function invoked when the checkbox state changes.
   * @returns {JSX.Element} A JSX element representing the checkbox button with a label.
   */
  let checkedTaskButton = (
    <div className="d-flex position-relative mb-2">
      <input
        className="mr-2"
        disabled={isVisibleButtonProcessBatch === false}
        type="checkbox"
        checked={disabledCheck}
        onChange={(eventSelectAllTasks) =>
          handleSelectAllTasks(eventSelectAllTasks)
        }
      />
      {i18n.t("taskFilter.label23")}
    </div>
  );

  if (taskasigned.length === 0 || isVisibleButtonProcessBatch === false) {
    disaleSortedButton = true;
    sortableOption = false;
  }

  /**
   * Updates the 'disabledAccesTaskDetail' flag based on the visibility of the process batch button.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating whether the process batch button is visible.
   * If set to 'false', the 'disabledAccesTaskDetail' flag will be set to 'true'.
   * @returns {void}
   */
  if (isVisibleButtonProcessBatch === false) {
    disabledAccesTaskDetail = true;
  }

  /**
   * Conditionally assigns a value to the 'checkedTaskButton' variable based on the visibility of the process batch button.
   * If the 'isVisibleButtonProcessBatch' flag is false, 'checkedTaskButton' is set to null.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating the visibility of the process batch button.
   * If true, the button is visible; otherwise, it's hidden.
   * @returns {any} - The value assigned to 'checkedTaskButton'. It will be null if 'isVisibleButtonProcessBatch' is false.
   */
  if (isVisibleButtonProcessBatch === false) {
    checkedTaskButton = null;
  }

  /**
   * Checks the length of the selectedFields array and updates the disabled state accordingly.
   * @param {number} selectedFieldsLength - The length of the selectedFields array.
   * @returns {boolean} - The updated disabled state.
   */
  if (selectedFields.length > maximumSelectedTasksAllowed - 1) {
    disabledChecks = true;
  } else {
    disabledChecks = false;
  }

  /**
   * Assigns a task based on batch selection if the button for processing batch is not visible.
   * If set to false, the task will be assigned based on the batch selection.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating the visibility of the button for processing batch.
   * @param {any} taskSelectedByBatch - The task selected based on batch processing.
   * @returns {any} - The assigned task, which will be the same as taskSelectedByBatch if isVisibleButtonProcessBatch is false.
   */
  if (isVisibleButtonProcessBatch === false) {
    taskasigned = taskSelectedByBatch;
  }

  /**
   * Conditional rendering of a component based on the value of 'isFilter'.
   * If 'isFilter' is true, it renders the 'FiltersApplyByFiltersFormsRecords' component.
   * @param {boolean} isFilter - A boolean value indicating whether filters are applied or not.
   *  If true, the 'FiltersApplyByFiltersFormsRecords' component will be rendered.
   *  If false, nothing will be rendered.
   * @returns {JSX.Element|null} - The JSX element of the component to be rendered based on the condition.
   */
  if (isFilter === true) {
    labelsByApplyFilters = <FiltersApplyByFiltersFormsRecords />;
  }

  /**
   * Conditionally renders select form label by filter based on selected options and filter state.
   * @param {boolean} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {Object} selectedOptions - Object representing the selected options.
   * @param {boolean} isFilter - Flag indicating whether a filter is active.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @returns {JSX.Element|null} - Select form label by filter or null.
   */
  if (isNullOrUndefined(selectedOptions.value) === false && isFilter === true) {
    selectFormLabelByFilter = (
      <div className="d-flex">
        <h6 className="text-info font-weight-bold">
          {i18n.t("taskFilter.label15")}:
        </h6>
        <p className="ml-2">{selectedOptions.label}</p>
      </div>
    );
  }

  /**
   * Generates a JSX element representing workflow labels based on selected options and filter status.
   * @param {Object} selectedOptionsStep - Object containing the selected step options.
   * @param {Object} selectedOptionsWorkflow - Object containing the selected workflow options.
   * @param {boolean} isFilter - Boolean indicating whether filtering is applied.
   * @returns {JSX.Element} JSX element representing the workflow labels.
   */
  if (
    isNullOrUndefined(selectedOptionsStep.value) === false &&
    isNullOrUndefined(selectedOptionsWorkflow.value) === false &&
    isFilter === true
  ) {
    selectWorkflowLabelByFilter = (
      <div className="d-flex">
        <h6 className="text-info font-weight-bold">
          {i18n.t("taskDetailType2")}:
        </h6>
        <p className="ml-2 mr-2">{selectedOptionsWorkflow.label}</p>
        <h6 className="text-info font-weight-bold">
          {i18n.t("taskFilter.label17")}:
        </h6>
        <p className="ml-2">{selectedOptionsStep.label}</p>
      </div>
    );
  } else if (
    isNullOrUndefined(selectedOptionsWorkflow.value) === false &&
    isFilter === true
  ) {
    selectWorkflowLabelByFilter = (
      <div className="d-flex">
        <h6 className="text-info font-weight-bold">
          {i18n.t("taskDetailType2")}:
        </h6>
        <p className="ml-2">{selectedOptionsWorkflow.label}</p>
      </div>
    );
  }

  /**
   * Determines the pagination size based on the provided parameters.
   * @param {boolean} isFilter - A boolean indicating whether filtering is enabled.
   *   If true, pagination size may be adjusted based on the number of tasks.
   *   If false, pagination size remains constant based on the provided pagination configuration.
   * @param {number} tasksLength - The number of tasks in the current dataset.
   * @param {object} pagination - Pagination configuration object containing settings such as per_page.
   * @param {number} pagination.per_page - The desired number of items per page for pagination
   * @returns {number} The determined pagination size based on the provided parameters.
   */
  if (isFilter === true && isVisibleButtonProcessBatch === true) {
    if (tasks.length <= pagination.per_page) {
      paginationZise = tasks.length;
    } else {
      paginationZise = pagination.per_page;
    }
  } else {
    if (isVisibleButtonProcessBatch === false) {
      paginationZise = taskasigned.length;
    } else {
      paginationZise = pagination.per_page;
    }
  }

  /**
   * Handles the change event when sorting is applied to a data set.
   * @param {Array<Object>} newSorted - An array containing information about the new sorting applied.
   * @param {string} newSorted[].id - The identifier of the column being sorted.
   * @param {boolean} newSorted[].desc - Indicates whether sorting is in descending order.
   * @param {function} setSorted - State setter function for updating the sorted data.
   * @param {Object} enumsSortedTypeTask - An object containing constants for sorted types.
   * @param {Object} clickCounts - An object tracking the number of clicks on each column for sorting.
   * @param {function} setOrderHeaderBy - State setter function for updating the header by which the data is sorted.
   * @param {function} setOrderHeaderType - State setter function for updating the type of sorting (ascending or descending).
   * @param {function} showAlertServiceError - Function to show an alert in case of a service error.
   * @returns {void}
   */
  const handleOnSortedChange = (newSorted) => {
    setSorted(newSorted);
    const datafilter = newSorted[0];

    if (isNullOrUndefined(datafilter) === false) {
      let orderBy = datafilter.id;
      let orderType;

      if (datafilter.desc === true) {
        orderType = enumsSortedTypeTask.DESCENDING;
      }

      if (datafilter.desc === false) {
        orderType = enumsSortedTypeTask.ASCENDING;
      }

      if (clickCounts[orderBy] === 2) {
        orderType = undefined;
        setSorted(sorted);
        setOrderHeaderBy(undefined);
        setOrderHeaderType(undefined);
      } else {
        setOrderHeaderBy(orderBy);
        setOrderHeaderType(orderType);
      }
    } else {
      showAlertServiceError();
    }
  };

  /**
   * Handles sorting functionality for a specific column in a table.
   * @param {string} column - The name of the column to be sorted.
   * @param {Object} enumsSortedTypeTask - An object containing sorted type constants for tasks.
   * @param {Object} clickCounts - An object representing the count of clicks on each column for sorting.
   * @param {Function} setIsClicked - State setter function to update the clicked state of the column headers.
   * @param {Function} setClickCounts - State setter function to update the click counts for each column.
   * @returns {void}
   */
  const handleSort = (column) => {
    setClickCounts((prevClickCounts) => ({
      [column]: (prevClickCounts[column] || 0) + 1,
    }));

    setIsClicked((prevIsClicked) => {
      let newHeaderIsClicked = { ...prevIsClicked };

      if (
        prevIsClicked[column] === undefined ||
        prevIsClicked[column] !== enumsSortedTypeTask.ASCENDING
      ) {
        Object.keys(prevIsClicked).forEach((key) => {
          newHeaderIsClicked[key] = false;
        });
      }

      if (prevIsClicked[column] === enumsSortedTypeTask.ASCENDING) {
        newHeaderIsClicked[column] = enumsSortedTypeTask.DESCENDING;
      } else {
        newHeaderIsClicked[column] = enumsSortedTypeTask.ASCENDING;
      }

      if (clickCounts[column] === 2) {
        newHeaderIsClicked[column] = undefined;
        setClickCounts({});
      }

      return newHeaderIsClicked;
    });
  };

  /**
   * Determines the appropriate sort icon based on the sorting state of a column.
   * @param {string} column - The column identifier for which to determine the sort icon.
   * @param {Object} isClicked - Object containing the sorting state for each column.
   * @param {boolean} isClicked[column] - Indicates whether the column has been clicked for sorting.
   * @param {boolean|undefined} isClicked[column] - Sorting state of the specified column.
   * @param {string} isClicked[column] - Sorting direction of the specified column. Can be "ASCENDING", "DESCENDING", or undefined.
   * @param {Object} enumsSortedTypeTask - Enumerated values for sorting types.
   * @param {string} enumsSortedTypeTask.ASCENDING - Constant representing ascending sorting order.
   * @param {string} enumsSortedTypeTask.DESCENDING - Constant representing descending sorting order.
   * @returns {React.Element} The appropriate FontAwesomeIcon component representing the sort icon.
   */
  const getSortIcon = (column) => {
    if (isClicked[column] === undefined || isClicked[column] === false)
      return <FontAwesomeIcon icon={faSortAlphaDown} color="gray" />;

    if (isClicked[column] === enumsSortedTypeTask.ASCENDING)
      return <FontAwesomeIcon icon={faSortAlphaDown} color="green" />;

    if (isClicked[column] === enumsSortedTypeTask.DESCENDING)
      return <FontAwesomeIcon icon={faSortAlphaUp} color="green" />;
  };

  /**
   * Determines whether the checkbox should be disabled for a given task ID.
   * @param {string} taskId - ID of the task.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {boolean} disabledChecks - Flag indicating whether checks are disabled.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating whether the button for processing batch is visible.
   * @returns {boolean} - True if checkbox should be disabled, otherwise false.
   */
  const shouldDisabledCheck = (taskId) => {
    const isTaskIdNotIncluded = selectedFields.includes(taskId) === false;
    const isDisabledOrNotSelected =
      disabledChecks === true && isTaskIdNotIncluded === true;
    const isButtonNotVisible = isVisibleButtonProcessBatch === false;
    return isDisabledOrNotSelected === true || isButtonNotVisible === true;
  };

  /**
   * Handles selecting all tasks.
   * Clears the selected fields and selects all tasks if the checkbox is not disabled.
   * @param {Array} tasks - Array of tasks.
   * @param {boolean} disabledCheck - Flag indicating whether the checkbox is disabled.
   * @param {function} setSelectedFields - State setter function to update selected fields.
   * @param {function} setDisabledCheck - State setter function to update disabled check.
   * @returns {void}
   */
  const handleSelectAllTasks = () => {
    setSelectedFields([]);
    if (disabledCheck === false) {
      const processTask = tasks.filter((task) => task.is_free_task === false);
      const idsOfTheTasks = processTask.map((obj) => obj.uuid);
      setSelectedFields([...idsOfTheTasks]);
      setTaskSelectedByBatch([...processTask]);
    } else {
      setSelectedFields([]);
      setTaskSelectedByBatch([]);
    }
    setDisabledCheck((prevState) => !prevState);
  };

  /**
   *Handles the selection of a field.
   *@param {object} field - The field object to be selected or unselected.
   *@param {object} eventSelectFields - The event object containing the checkbox state.
   *@param {boolean} eventSelectFields.target.checked - The checked state of the checkbox.
   *@param {string} field.value - The value of the field.
   *@param {function} setSelectedFields - The state setter function for the selected fields.
   *@param {array} prevSelectedFields - The previously selected fields.
   *@param {boolean} setSelectAllFields - The state setter function for the "Select All" checkbox.
   */
  const handleSelectFields = (field, eventSelectFields) => {
    const checkedField = eventSelectFields.target.checked;

    if (checkedField === true) {
      setSelectedFields((prevSelectedFields) => [
        ...prevSelectedFields,
        field.uuid,
      ]);
      setTaskSelectedByBatch((prevSelectedFields) => [
        ...prevSelectedFields,
        field,
      ]);
    } else {
      setSelectedFields((prevSelectedFields) =>
        prevSelectedFields.filter(
          (selectedValue) => selectedValue !== field.uuid
        )
      );
      setTaskSelectedByBatch((prevSelectedFields) =>
        prevSelectedFields.filter(
          (selectedField) => selectedField.uuid !== field.uuid
        )
      );
    }
  };

  /**
   * useEffect hook that resets the 'isClicked' state when the 'filterDone' prop is true.
   * @param {boolean} filterDone - A boolean flag indicating whether filtering is done.
   * When true, it triggers the reset of the 'isClicked' state.
   * @param {function} setIsClicked - State setter function for updating the 'isClicked' state.
   * @param {function} setClickCounts - State setter function for updating the 'clickCounts' state.
   * @returns {void}
   */
  useEffect(() => {
    if (filterDone === true) {
      setIsClicked({});
      setClickCounts({});
    }
  }, [filterDone]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            {selectFormLabelByFilter}
            {selectStepLabelByFilter}
            {selectWorkflowLabelByFilter}
            {labelsByApplyFilters}
            {checkedTaskButton}
            <ReactTable
              data={taskasigned}
              sorted={sorted}
              onSortedChange={(newSorted) => handleOnSortedChange(newSorted)}
              columns={[
                {
                  Header: (
                    <div>
                      <span className="text-primary font-weight-bold">
                        {i18n.t("taskFilter.label24")}{" "}
                      </span>
                    </div>
                  ),
                  id: "uuid",
                  accessor: "uuid",
                  width: 70,
                  sortable: false,
                  Cell: ({ _, original }) => {
                    if (original.task_type === enumsProcessTaskType.RETURN) {
                      return null;
                    } else {
                      return (
                        original.is_free_task === false && (
                          <div className="text-center">
                            <input
                              type="checkbox"
                              disabled={shouldDisabledCheck(original.uuid)}
                              checked={selectedFields.includes(original.uuid)}
                              onChange={(eventSelectFields) =>
                                handleSelectFields(original, eventSelectFields)
                              }
                            />
                          </div>
                        )
                      );
                    }
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="transparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.CONSECUTIVE)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("createWorflow.Label3")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.CONSECUTIVE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("createWorflow.Label3");
                    }
                  },

                  id: enumsSortedTask.CONSECUTIVE,
                  accessor: " consecutive",
                  sortable: sortableOption,
                  Cell: (row) => {
                    let linkTask;
                    if (row.original.is_free_task === false) {
                      linkTask = `/process/detail/${row.original.uuid}/${row.original.process_uuid}`;
                    } else {
                      linkTask = `/taskBox/detail/${row.original.uuid}`;
                    }
                    return (
                      <div className="d-flex justify-content-center">
                        <Link disabled={disabledAccesTaskDetail} to={linkTask}>
                          <Button
                            block
                            outline
                            className="mb-2 mr-2 btn-pill btn-dashed"
                            color="info"
                            disabled={disabledAccesTaskDetail}
                          >
                            <span className="badge-pill text-bold">
                              {row.original.consecutive}
                            </span>
                          </Button>
                        </Link>
                      </div>
                    );
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.RECORD_NUMBER)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable3")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.RECORD_NUMBER)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable3");
                    }
                  },
                  id: enumsSortedTask.RECORD_NUMBER,
                  accessor: "record_number",
                  sortable: sortableOption,
                  Cell: (row) => <div className="text-center">{row.value}</div>,
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.CREATED_AT)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable4")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.CREATED_AT)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable4");
                    }
                  },
                  id: enumsSortedTask.CREATED_AT,
                  accessor: "created_at",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <p>{valueFinal}</p>;
                  },
                },

                {
                  Header: (
                    <div>
                      <span className="text-primary font-weight-bold">
                        {i18n.t("taskBoxTableTaskType")}{" "}
                      </span>
                    </div>
                  ),
                  id: enumsSortedTask.TASKTYPE,
                  accessor: "task_type",
                  sortable: false,
                  Cell: ({ value }) => {
                    let processTaskType = "";

                    switch (value) {
                      case enumsProcessTaskType.NORMAL:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeNormal")}</span>
                        );
                        break;
                      case enumsProcessTaskType.RETURN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeDevolution")}
                          </span>
                        );
                        break;
                      case enumsProcessTaskType.RETURN_ORIGIN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeReturnToOrigin")}
                          </span>
                        );
                        break;
                      case enumsProcessTaskType.REASSIGNMENT:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeReassign")}</span>
                        );
                        break;
                      case enumsProcessTaskType.REASSIGNMENT_ORIGIN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeReassignToOrigin")}
                          </span>
                        );
                        break;

                      default:
                        break;
                    }

                    return (
                      <span className="text-center">{processTaskType}</span>
                    );
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.PROCESS_TYPE)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable9")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.PROCESS_TYPE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable9");
                    }
                  },
                  id: enumsSortedTask.PROCESS_TYPE,
                  accessor: "type",
                  sortable: sortableOption,
                  Cell: ({ value }) => (
                    <div className="text-center">{value}</div>
                  ),
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.SENDER)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable1")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.SENDER)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable1");
                    }
                  },
                  id: enumsSortedTask.SENDER,
                  accessor: "sender_fullname",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    return <div className="text-capitalize">{value}</div>;
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.SUBJECT)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable5")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.SUBJECT)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable5");
                    }
                  },
                  id: enumsSortedTask.SUBJECT,
                  accessor: "subject",
                  sortable: sortableOption,
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.RECEPTION_DATE)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable6")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.RECEPTION_DATE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable6");
                    }
                  },
                  id: enumsSortedTask.RECEPTION_DATE,
                  accessor: "reception_date",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    let receptionDate;
                    if (value === "0000-00-00T00:00:00+00:00") {
                      receptionDate = "";
                    } else {
                      receptionDate = valueFinal;
                    }
                    return <span>{receptionDate}</span>;
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() =>
                              handleSort(enumsSortedTask.EXPIRATION_DATE)
                            }
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable7")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.EXPIRATION_DATE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable7");
                    }
                  },
                  id: enumsSortedTask.EXPIRATION_DATE,
                  accessor: "expiration_date",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    let receptionDate;
                    if (value === "0000-00-00T00:00:00+00:00") {
                      receptionDate = "";
                    } else {
                      receptionDate = valueFinal;
                    }
                    return <span>{receptionDate}</span>;
                  },
                },
              ]}
              manual
              className="-striped -highlight"
              loading={isLoading}
              pageSize={paginationZise}
              pages={totalPages}
              page={pagination.page - 1}
              previousText={`${i18n.t("previousText")}`}
              nextText={`${i18n.t("nextText")}`}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
              showPageJump={false}
              showPagination={!disabledAccesTaskDetail}
              defaultPageSize={pagination.per_page}
              noDataText={`${i18n.t("tableRowsEmpty")}`}
              onPageSizeChange={(per_page) => {
                setPagination({ ...pagination, per_page: per_page });
              }}
              onPageChange={(page) => {
                setPagination({ ...pagination, page: page + 1 });
              }}
            />
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

TaskBoxListTableFilter.propTypes = {
  tasks: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  isFilter: PropTypes.bool.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  selectedOptionsWorkflow: PropTypes.object.isRequired,
  selectedOptionsStep: PropTypes.object.isRequired,
  selectedFields: PropTypes.array,
  setSelectedFields: PropTypes.func,
  isVisibleButtonProcessBatch: PropTypes.bool,
  taskSelectedByBatch: PropTypes.array,
  setTaskSelectedByBatch: PropTypes.func,
};

export default TaskBoxListTableFilter;
