import React, { useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import { Alert, Row, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { getDateFormat2 } from "utils/getDateFormat2";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import useWorkFlow from "hooks/useWorkFlow";
import Loader from "react-loaders";

const WorkflowTraceabilityComments = (props) => {
  const { id } = props;
  const [isLoadingComments, setIsLoadingCommnets] = useState(false);
  const [comments, setComments] = useState([]);
  const { getCommentsProcessByUuid } = useWorkFlow();

  /**
   * Fetches comments by ID and updates the component state with the retrieved data.
   * It sets the loading state to true before initiating the request and sets it back to false after the request completes.
   * If the response data is not null or undefined, it sets the comments state with the response data.
   * Otherwise, it shows a service error alert.
   * @returns {void}
   */
  const getCommentsById = useCallback(() => {
    setIsLoadingCommnets(true);
    getCommentsProcessByUuid(id)
      .then((response) => {
        if (isNullOrUndefined(response.data.data) === false) {
          setComments(response.data.data);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingCommnets(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook to fetch comments by ID when the component mounts
   * and whenever the `getCommentsById` function changes.
   * @return {void}
   */
  useEffect(() => {
    getCommentsById();
  }, [getCommentsById]);

  /**
   * Renders the comments section.
   * If there are no comments, it displays a title and an alert message.
   * @param {Array} comments - The list of comments.
   * @returns {JSX.Element|null} The rendered comments section or null.
   */
  if (comments.length === 0) {
    return (
      <div>
        <CardTitle
          className="text-start"
        >
          {i18n.t("taskCommentsTittle")}
        </CardTitle>
        <br />
        <Alert className="block" color="info">
          {i18n.t("taskCommentsAlert")}
        </Alert>
      </div>
    )
  };

  /**
   * Renders a loading spinner if the loading state is true.
   * @function renderLoadingState
   * @param {boolean} isLoadingComments - Indicates if the loading state is true.
   * @returns {JSX.Element|null} The rendered loading spinner or null.
   */
  if (isLoadingComments === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <div>
      <div>
        <CardTitle
          className="text-start"
        >
          {i18n.t("taskCommentsTittle")}
        </CardTitle>
        {comments.map((coment) => (
          <div>
            <VerticalTimeline
              className="vertical-time-icons"
              layout={"1-column"}
              animate={false}
            >
              <VerticalTimelineElement
                className="vertical-timeline-item"
                icon={
                  <div className="timeline-icon border-cyan bg-success">
                    <FontAwesomeIcon
                      icon={faComment}
                      className="text-white fa-flip-horizontal"
                    />
                  </div>
                }
              >
                <h6 className="text-primary">{coment.comment}</h6>
                <Row>
                  {getDateFormat2(new Date(coment.created_at))}
                  &nbsp; &nbsp;
                  <p className="text-capitalize">{coment.user_fullname}</p>
                </Row>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        ))}
      </div>
    </div>
  );
};

WorkflowTraceabilityComments.propTypes = {
  id: PropTypes.string.isRequired,
};

export default WorkflowTraceabilityComments;
