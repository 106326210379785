import React, { useState, useCallback } from "react";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { levelClassificationTrd } from "utils/levelClassificationTrd";
import { initialPaginationRecordListExpedient } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
const TrdContext = React.createContext(undefined);

export function TrdContextProvider({ children }) {
  const [hasFiles, setHasFiles] = useState(false);
  const {
    getTreeByDomain,
    getTreeExpedientByDomain,
    getExpedients,
    getSerieSubserieStructure,
    getPathSetExpedient,
  } = useTrd();
  const [showOrdenation, setShowOrdenation] = useState(false);
  const [seieSubserieId, setSeieSubserieId] = useState("");
  const [seieSubserieBackgroundId, setSeieSubserieBackgroundId] = useState("");
  const [subDivisionId, setSubDivisionId] = useState("");
  const [isStructure, setIsStructure] = useState(false);
  const [validateField, setValidateField] = useState(true);
  const [recordShowExpTree, setRecordShowExpTree] = useState(false);
  const [levelFolder, setLevelFolder] = useState(0);
  const [showFormConditional, setShowFormConditional] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);
  const [
    showRightPanelTreeExpedients,
    setShowRightPanelTreeExpedients,
  ] = useState(false);
  const [showFolderCondition, setShowFolderCondition] = useState(false);
  const [showDirectoryCondition, setShowDirectoryCondition] = useState(false);
  const [showCreateExpedientForm, setShowCreateExpedientForm] = useState(false);
  const [showCreateSubDivisonForm, setShowCreateSubDivisonForm] = useState(
    false
  );
  const [showEditSubDivisonForm, setShowEditSubDivisonForm] = useState(false);
  const [showEditDirectoryForm, setShowEditDirectoryForm] = useState(false);
  const [showEditSectionForm, setShowEditSectionForm] = useState(false);
  const [showCreateSection, setShowCreateSection] = useState(false);
  const [showEditSerieForm, setShowEditSerieForm] = useState(false);
  const [showEditSubSerieForm, setShowEditSubSerieForm] = useState(false);
  const [showEditSubSectionForm, setShowEditSubSectionForm] = useState(false);
  const [showEditBackground, setShowEditBackground] = useState(false);
  const [showEditSection, setShowEditSection] = useState(false);
  const [editDirectory, setEditDirectory] = useState(false);
  const [option, setOption] = useState(true);
  const [openOptions, setOpenOptions] = useState(false);
  const [optionDir, setOptionDir] = useState(true);
  const [optionDisabled, setOptionDisabled] = useState(false);
  const [optionEdit, setOptionEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateDirectoryForm, setShowCreateDirectoryForm] = useState(false);
  const [isCreateExpedient, setIsCreateExpedient] = useState(false);
  const [showExpedientTree, setShowExpedientTree] = useState(false);
  const [selectLevel, setSelectLevel] = useState(0);
  const [expedientParent, setExpedientParent] = useState("");
  const [fundName, setFundName] = useState("");
  const [expedientName, setExpedientName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [structureTrd, setStructureTrd] = useState([]);
  const [structureTrdExpedient, setStructureTrdExpedient] = useState([]);
  const [
    structureTrdSeriesSubseries,
    setStructureTrdSeriesSubseries,
  ] = useState([]);
  const [currentLevelSelected, setCurrentLevelSelected] = useState(0);
  const [isByRecord, setIsByRecord] = useState(false);
  const [submitByRecord, setSubmitByRecord] = useState(false);
  const [listExpedients, setListExpedients] = useState([]);
  const [nodataMessage, setNodataMessage] = useState(i18n.t("tableRowsEmpty"));
  const [totalPages, setTotalPages] = useState(0);
  const [loadingExpedientList, setLoadingExpedientList] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [errorCharacters, setErrorCharacters] = useState("");
  const [status, setStatus] = useState();
  const [parentData, setParentData] = useState({
    id: 0,
    level: 0,
  });
  const [trdForm, setTrdForm] = useState({
    name: "",
    code: "",
    level: 1,
  });
  const [createExpedientForm, setCreateExpedientForm] = useState({
    name: "",
    subject: "",
    status: 1,
    level: 5,
  });
  const [createSubDivisionForm, setCreateSubDivisionForm] = useState({
    name: "",
    level: 6,
  });
  const [createSubDivisionFormSTR, setCreateSubDivisionFormSTR] = useState({
    subdivision_struct: [""],
    level: 6,
  });
  const [editSubDivisionForm, setEditSubDivisionForm] = useState({
    name: "",
  });
  const [editSubDivisionFormSTR, setEditSubDivisionFormSTR] = useState({
    base_subdivision_uuid: "",
    name: "",
  });
  const [createDirectoryFormSTR, setCreateDirectoryFormSTR] = useState({
    serie_subserie_uuid: "",
    directory_struct: [""],
    level: 7,
  });
  const [createDirectoryForm, setCreateDirectoryForm] = useState({
    name: "",
    level: 7,
  });
  const [editDirectoryFormSTR, setEditDirectoryFormSTR] = useState({
    base_subdivision_uuid: "",
    base_directory_uuid: "",
    name: "",
  });
  const [editDirectoryForm, setEditDirectoryForm] = useState({
    name: "",
  });
  const [editSectionFormService, setEditSectionFormService] = useState({
    name: "",
    code: "",
    parent_id: "",
  });
  const [editBackground, setEditBackground] = useState({
    name: "",
  });
  const [editSubSectionFormService, setEditSubSectionFormService] = useState({
    name: "",
    code: "",
    parent_id: "",
    background_parent_id: "",
  });
  const [foldersId, setFoldersId] = useState({
    backgroundId: "",
    sectionId: "",
    subSectionId: "",
    serieId: "",
    subSerieId: "",
    expedientId: "",
    subDivisionId: "",
    subDirectoryId: "",
  });
  const [editSerieFormService, setEditSerieFormService] = useState({
    name: "",
    code: "",
    parent_id: "",
    background_parent_id: "",
  });
  const [hasSubseries, setHasSubseries] = useState(false);
  const [hasExpedients, setHasExpedients] = useState(false);
  const [expedientParentByPath, setExpedientParentByPath] = useState(null);
  const [foldersIdByPath, setFoldersIdByPath] = useState({
    backgroundId: "",
  });
  const [refreshDataExpedient, setRefreshDataExpedient] = useState(false);
  const [paginationRecordList, setPaginationRecordList] = useState(
    initialPaginationRecordListExpedient
  );
  const [hasExpedientFixedPath, sethasExpedientFixedPath] = useState(false);
  const [expedientSetPath, setExpedientSetPath] = useState({});
  const [expedientFixedUuid, setExpedientFixedUuid] = useState("");
  const [isLoadingExpedietnPath, setIsLoadingExpedietnPath] = useState(false);
  const [expedientPathSet, setExpedientPathSet] = useState({
    form_uuid: "",
    record_uuid: "",
    serie_subserie_uuid: "",
  });
  const [isLoadingOrdination, setIsLoadingOrdination] = useState(false);
  const [hasOrdination, setHasOrdination] = useState(false);
  const [expedientStatus, setExpedientStatus] = useState();

  const reStructureData = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i]["code"] && data[i]["level"] !== 5) {
        data[i].id = data[i]["uuid"];
        data[i].parentId = data[i]["parent_id"];
        data[i].label = `${data[i]["code"]}-${
          data[i]["name"]
        } (${levelClassificationTrd(data[i]["level"])})`;
        delete data[i].uuid;
        delete data[i].parent_id;
      } else if (data[i]["is_backend"]) {
        data[i].id = data[i]["uuid"];
        data[i].parentId = data[i]["parent_id"];
        data[i].label = `${data[i]["name"]} (${levelClassificationTrd(0)})`;
        data[i].level = 0;
        delete data[i].uuid;
        delete data[i].parent_id;
      } else if (data[i]["level"] === 5) {
        data[i].id = data[i]["uuid"];
        data[i].parentId = null;
        data[i].label = `${data[i]["name"]} (${levelClassificationTrd(
          data[i]["level"]
        )})`;
        delete data[i].uuid;
        delete data[i].parent_id;
      } else {
        data[i].id = data[i]["uuid"];
        data[i].parentId = data[i]["parent_id"];
        data[i].label = `${data[i]["name"]} (${levelClassificationTrd(
          data[i]["level"]
        )})`;
        delete data[i].uuid;
        delete data[i].parent_id;
      }
    }
    return data;
  };

  const validateSerieinSubserie = (level, id) => {
    let isCreate = false;
    const validateSection = structureTrd.some(
      (structure) => structure.parentId === id
    );

    if (level === 2 || level === 1) {
      isCreate = false;
      setIsCreateExpedient(false);
      return;
    }

    if (level === 4) {
      setTrdForm({
        ...trdForm,
        level: 4,
      });
      setIsCreateExpedient(false);
      isCreate = true;
      return isCreate;
    } else if (validateSection) {
      setIsCreateExpedient(false);
      isCreate = false;
    } else {
      isCreate = true;
      setIsCreateExpedient(true);
    }
    return isCreate;
  };

  const getExpedientsBySearch = useCallback(
    (page, per_page, id, backgroundId, searchData, status) => {
      setLoadingExpedientList(true);
      getExpedients(page, per_page, id, backgroundId, searchData, status)
        .then((response) => {
          const dataResponse = response.data;
          if (isNullOrUndefined(dataResponse) === false) {
            setListExpedients(dataResponse.items);
            setTotalPages(dataResponse.pages);
            if (searchData !== "" && dataResponse.items.length === 0) {
              setNodataMessage(i18n.t("lookupFilterMessageEmpty"));
            }
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setLoadingExpedientList(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getTreeByDomainCallback = useCallback(() => {
    setIsLoading(true);
    getTreeByDomain()
      .then((response) => {
        if (response.status === 200) {
          setStructureTrd(reStructureData(response.data.data));
        }
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setStructureTrd([]);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTreeExpedientByDomainCallback = useCallback(
    (backgroundId, expedientId) => {
      setIsLoadingOrdination(true);
      getTreeExpedientByDomain(backgroundId, expedientId)
        .then((response) => {
          if (response.status === 200) {
            const ordinationStricture = response.data.data;
            setStructureTrdExpedient(reStructureData(ordinationStricture));
            const hasOrdenationTemp = ordinationStricture.some(
              (element) => element.level === 6 || element.level === 7
            );
            const expedientStatus = ordinationStricture.find(
              (element) => element.level === 5
            );
            setExpedientStatus(expedientStatus.status);
            setHasOrdination(hasOrdenationTemp);
          }
        })
        .catch((error) => {
          setStructureTrdExpedient([]);
        })
        .finally(() => {
          setShowExpedientTree(true);
          setIsLoadingOrdination(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getSeriesSubseriesStructure = useCallback(
    (backgroundId, serieSubserieId) => {
      setIsLoading(true);
      getSerieSubserieStructure(backgroundId, serieSubserieId)
        .then((response) => {
          let respStructure = response.data.data;
          let newRespStructure = respStructure.sort((a, b) =>
            ("" + a.name).localeCompare(b.name, undefined, { numeric: true })
          );
          if (response.status === 200) {
            setStructureTrdSeriesSubseries(reStructureData(newRespStructure));
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          setStructureTrdSeriesSubseries([]);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getHasExpedientStructure = useCallback((recordUuid) => {
    setIsLoadingExpedietnPath(true);
    getPathSetExpedient(recordUuid)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const infoParentPath = response.data;
          if (Object.keys(infoParentPath.path).length > 0) {
            sethasExpedientFixedPath(true);
            setExpedientFixedUuid(infoParentPath.expedient_uuid);
            setExpedientSetPath(infoParentPath.path);
          }
        }
      })
      .finally(() => {
        setIsLoadingExpedietnPath(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateDeleteLevelTrd = (forms) => {
    let isLimitExcededMsg = "";
    let limitFormsToShow = 10;
    if (forms.length > limitFormsToShow) {
      isLimitExcededMsg = i18n.t("trd.delete.error1");
    }

    const newForms = forms.slice(0, 10);
    if (forms.length > 0) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: `${i18n.t("trd.delete.error")} : ${"\n"}
        ${"\n"} ${newForms
          .map((form) => form.name)
          .join("\n")} ${isLimitExcededMsg}`,
        icon: "error",
        dangerMode: true,
        button: i18n.t("modal.Done.footerButton"),
      });
    }
  };

  return (
    <TrdContext.Provider
      value={{
        getTreeByDomainCallback,
        levelFolder,
        setLevelFolder,
        fundName,
        setFundName,
        structureTrd,
        setStructureTrd,
        reStructureData,
        showRightPanel,
        setShowRightPanel,
        trdForm,
        setTrdForm,
        showFolderCondition,
        setShowFolderCondition,
        foldersId,
        setFoldersId,
        parentData,
        setParentData,
        showFormConditional,
        setShowFormConditional,
        showEditBackground,
        setShowEditBackground,
        showEditSection,
        setShowEditSection,
        showCreateSection,
        setShowCreateSection,
        editBackground,
        setEditBackground,
        createExpedientForm,
        setCreateExpedientForm,
        showCreateExpedientForm,
        setShowCreateExpedientForm,
        validateSerieinSubserie,
        selectLevel,
        setSelectLevel,
        isCreateExpedient,
        setIsCreateExpedient,
        createSubDivisionForm,
        setCreateSubDivisionForm,
        showCreateSubDivisonForm,
        setShowCreateSubDivisonForm,
        showCreateDirectoryForm,
        setShowCreateDirectoryForm,
        createDirectoryForm,
        setCreateDirectoryForm,
        currentLevelSelected,
        setCurrentLevelSelected,
        setIsLoading,
        isLoading,
        showExpedientTree,
        setShowExpedientTree,
        structureTrdExpedient,
        setStructureTrdExpedient,
        getTreeExpedientByDomainCallback,
        expedientName,
        setExpedientName,
        showRightPanelTreeExpedients,
        setShowRightPanelTreeExpedients,
        expedientParent,
        setExpedientParent,
        isByRecord,
        setIsByRecord,
        submitByRecord,
        setSubmitByRecord,
        folderName,
        setFolderName,
        showDirectoryCondition,
        setShowDirectoryCondition,
        option,
        setOption,
        optionEdit,
        setOptionEdit,
        editSubDivisionForm,
        setEditSubDivisionForm,
        showEditSubDivisonForm,
        setShowEditSubDivisonForm,
        optionDisabled,
        setOptionDisabled,
        showEditDirectoryForm,
        setShowEditDirectoryForm,
        editDirectory,
        setEditDirectory,
        editDirectoryForm,
        setEditDirectoryForm,
        optionDir,
        setOptionDir,
        showEditSectionForm,
        setShowEditSectionForm,
        showEditSerieForm,
        setShowEditSerieForm,
        showEditSubSectionForm,
        setShowEditSubSectionForm,
        openOptions,
        setOpenOptions,
        showEditSubSerieForm,
        setShowEditSubSerieForm,
        editSectionFormService,
        setEditSectionFormService,
        editSubSectionFormService,
        setEditSubSectionFormService,
        editSerieFormService,
        setEditSerieFormService,
        recordShowExpTree,
        setRecordShowExpTree,
        hasFiles,
        setHasFiles,
        getExpedientsBySearch,
        listExpedients,
        nodataMessage,
        totalPages,
        loadingExpedientList,
        errorCharacters,
        setErrorCharacters,
        searchData,
        setSearchData,
        status,
        setStatus,

        showOrdenation,
        setShowOrdenation,
        getSeriesSubseriesStructure,
        structureTrdSeriesSubseries,
        isStructure,
        setIsStructure,
        createSubDivisionFormSTR,
        setCreateSubDivisionFormSTR,
        seieSubserieId,
        setSeieSubserieId,
        seieSubserieBackgroundId,
        subDivisionId,
        setSubDivisionId,
        setSeieSubserieBackgroundId,
        validateField,
        setValidateField,
        createDirectoryFormSTR,
        setCreateDirectoryFormSTR,
        editSubDivisionFormSTR,
        setEditSubDivisionFormSTR,
        editDirectoryFormSTR,
        setEditDirectoryFormSTR,
        hasSubseries,
        setHasSubseries,
        hasExpedients,
        setHasExpedients,
        expedientParentByPath,
        setExpedientParentByPath,
        foldersIdByPath,
        setFoldersIdByPath,
        validateDeleteLevelTrd,
        paginationRecordList,
        setPaginationRecordList,
        refreshDataExpedient,
        setRefreshDataExpedient,
        getHasExpedientStructure,
        hasExpedientFixedPath,
        sethasExpedientFixedPath,
        expedientSetPath,
        setExpedientSetPath,
        isLoadingExpedietnPath,
        setIsLoadingExpedietnPath,
        expedientPathSet,
        setExpedientPathSet,
        expedientFixedUuid,
        setExpedientFixedUuid,
        isLoadingOrdination,
        hasOrdination,
        expedientStatus,
      }}
    >
      {children}
    </TrdContext.Provider>
  );
}

export function useTrdContext() {
  const context = React.useContext(TrdContext);
  if (!context) {
    throw new Error(
      "useTrdContext debe estar dentro del proveedor TrdContext."
    );
  }
  return context;
}
