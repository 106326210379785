import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { isNullOrUndefined } from "utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import TaskUpLoadFilesTRD from "components/organism/TaskUploadFilesTRD";
import TaskUploadFiles from "components/organism/TaskUploadFiles";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";

const ButtonAtachFilesTask = (props) => {
  const {
    getFilesProcessById,
    processId,
    getFilesTasksById,
    taskId,
    formIdInfo,
    hasExpedientFixedPath,
    expedientFixedUuid,
    hasOrdination,
    expedientStatus,
    structureTrdExpedient,
    disabled,
    isFreeTask,
    isProcess,
    isCompletedTask,
    isLoadingSteps,
  } = props;
  const [treeExist, setTreeExist] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    setParentId,
    uploadForm,
    setUploadForm,
    setStartDate,
    setFiles,
  } = useRecordDetailContext();
  const {
    uploadFormWorkflow,
    setUploadFormWorkflow,
    setDropdownOpen,
  } = useWorkflowContext();
  const { getTreeExist } = useTrd();
  let componentToRenderUploadFileModal;

  /**
   * Opens a modal by setting the showModal state to true.
   * @returns {void}
   */
  const handleOnClickModal = () => {
    setShowModal(true);
  };

  /**
   * Handles the closing of a modal by resetting state values and performing additional actions based on conditions.
   * @param {boolean} isProcess - Indicates whether the current operation is related to a process.
   * @param {string} processId - The identifier of the process (if `isProcess` is true).
   * @param {string} taskId - The identifier of the task (if `isProcess` is false).
   * @param {function} getFilesProcessById - Function to retrieve files associated with a specific process by ID.
   * @param {function} getFilesTasksById - Function to retrieve files associated with a specific task by ID.
   * @param {object} uploadFormWorkflow - State object for the upload form workflow.
   * @param {function} setUploadFormWorkflow - State setter function for `uploadFormWorkflow`.
   * @param {string[]} setStartDate - State setter function for the start date.
   * @param {object[]} setFiles - State setter function for the files array.
   * @param {object} uploadForm - State object for the upload form.
   * @param {function} setUploadForm - State setter function for `uploadForm`.
   * @param {boolean} setShowModal - State setter function for showing/hiding the modal.
   * @param {string} setParentId - State setter function for the parent ID.
   * @returns {void}
   */
  const handleOnCloseModal = () => {
    setDropdownOpen(false);
    setUploadFormWorkflow({
      ...uploadFormWorkflow,
      files: [],
    });
    setStartDate("");
    setFiles([]);
    setUploadForm({
      ...uploadForm,
      subject: "",
      support_type: "",
      publication_at: "",
    });
    setShowModal(false);
    setParentId("");
    if (isProcess === true) {
      getFilesProcessById(processId);
    } else {
      getFilesTasksById(taskId);
    }
  };

  /**
   * Fetches tree existence data and updates the state with the response data.
   * @returns {void}
   */
  const getTreeExistBySearch = useCallback(() => {
    getTreeExist().then((response) => {
      if (isNullOrUndefined(response.data) === false) {
        setTreeExist(response.data);
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a component for uploading files based on the existence of a tree.
   * If the tree exists, renders the TaskUpLoadFilesTRD component with provided props.
   * If the tree doesn't exist, renders the TaskUploadFiles component with provided props.
   * @param {boolean} treeExist - Indicates whether the tree exists.
   * @param {boolean} isFreeTask - Indicates whether the task is free.
   * @param {boolean} isProcess - Indicates whether the task is part of a process.
   * @param {object} formIdInfo - Information about the form ID.
   * @returns {JSX.Element} Component to render for uploading files.
   */
  if (treeExist === true) {
    componentToRenderUploadFileModal = (
      <TaskUpLoadFilesTRD
        formIdInfo={formIdInfo}
        hasExpedientFixedPath={hasExpedientFixedPath}
        expedientFixedUuid={expedientFixedUuid}
        hasOrdination={hasOrdination}
        expedientStatus={expedientStatus}
        structureTrdExpedient={structureTrdExpedient}
        isProcess={isProcess}
        isFreeTask={isFreeTask}
        isLoadingSteps={isLoadingSteps}
      />
    );
  } else {
    componentToRenderUploadFileModal = (
      <TaskUploadFiles
        fileByTrd={false}
        isProcess={isProcess}
        isProcessBatch={false}
      />
    );
  }

  /**
   * Fetches tree existence data and updates the state with the response data.
   * @returns {void}
   */
  useEffect(() => {
    getTreeExistBySearch();
  }, [getTreeExistBySearch]);

  return (
    <Fragment>
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle
          className="ml-auto btn-icon btn-mb-responsive"
          color="cyan"
          onClick={handleOnClickModal}
          disabled={disabled || isCompletedTask}
        >
          <FontAwesomeIcon icon={faFile} className="mr-2" />
          <span>{i18n.t("recordDetail.upload")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>

      <Modal
        isOpen={showModal}
        toggle={handleOnCloseModal}
        className="modal-atach-file-size"
      >
        <ModalHeader>
          <h5 className="text-info font-weight-bold  font-weight-normal">
            {i18n.t("recordDetail.upload")}
          </h5>
        </ModalHeader>
        <ModalBody>{componentToRenderUploadFileModal}</ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            className="col-mt-3 mr-3"
            color="gray"
            onClick={handleOnCloseModal}
          >
            {i18n.t("createusers.createButton2")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

ButtonAtachFilesTask.propTypes = {
  getFilesProcessById: PropTypes.func,
  processId: PropTypes.string,
  getFilesTasksById: PropTypes.func,
  taskId: PropTypes.string,
  formIdInfo: PropTypes.string.isRequired,
  hasExpedientFixedPath: PropTypes.bool.isRequired,
  expedientFixedUuid: PropTypes.string.isRequired,
  hasOrdination: PropTypes.bool.isRequired,
  expedientStatus: PropTypes.number.isRequired,
  structureTrdExpedient: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFreeTask: PropTypes.number.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isCompletedTask: PropTypes.bool,
  isLoadingExpedietnPath: PropTypes.bool.isRequired,
};

export default ButtonAtachFilesTask;
