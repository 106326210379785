import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, CardBody } from "reactstrap";
import ReactTable from "react-table";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { showAlertServiceError } from "utils/alerts";

const UserListMembersRolesTable = (props) => {
  const {
    listMembers,
    idRol,
    isLoadingListMembers,
    setIsLoadingDeleteMembers,
    pagination,
    setPagination,
    totalPages
  } = props;
  const { deleteMember } = useRoles();

  /**
   * Handle the deletion of a member identified by their ID.
   * This function sets a loading flag, sends a request to delete a member, and
   * displays a success message if the deletion is successful. It also reloads the
   * page after successful deletion and clears the loading flag.
   * @param {string} idMember - The unique identifier of the member to be deleted.
   * @param {string} idRol - The role ID associated with the member to be deleted.
   * @returns {void}
   */
  const handleOnDeleteMember = (idMember) => {
    setIsLoadingDeleteMembers(true);
    deleteMember(idRol, idMember)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createRoles.MembersAlertDelete"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location.reload();
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingDeleteMembers(false);
      });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={listMembers}
            columns={[
              {
                columns: [
                  {
                    Header: i18n.t("createRoles.MembersNames"),
                    accessor: "name",
                  },
                  {
                    Header: i18n.t("createRoles.MembersUsers"),
                    accessor: "userName",
                  },
                  {
                    Header: i18n.t("createRoles.MembersDelete"),
                    accessor: "uuid",
                    Cell: (row) => {
                      return (
                        <div className="text-center">
                          <Button
                            className="btn-icon text-primary"
                            size="lg"
                            color="link"
                            onClick={() =>
                              handleOnDeleteMember(row.original.uuid)
                            }
                          >
                            <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                              <div>
                                <i className="lnr-icon lnr-trash text-primary " />
                              </div>
                            </div>
                          </Button>
                        </div>
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingListMembers}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserListMembersRolesTable;
