export const customStylesSelect = {
  menu: (provided, state) => ({
    ...provided,
    color: "#495057",
    zIndex: 999,
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#dc3545",
    color: "#dc3545",
    zIndex: 999,
  }),
};

export const customNoStylesSelect = {
  menu: (provided, state) => ({
    ...provided,
    color: "#495057",
    zIndex: 999,
  }),
  control: (provided) => ({
    ...provided,
    color: "#495057",
    zIndex: 999,
  }),
};

export const labelError = {
  color: "#dc3545",
};

export const labelNoError = {
  color: "#495057",
};
