import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvForm,
  AvField,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  Button,
  Col,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Label,
  Spinner,
  CardTitle
} from "reactstrap";
import { Loader } from "react-loaders";
import { useParams } from "react-router-dom";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { isNullOrUndefined, arrayGreaterThanZero } from "utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { capitalizeText } from "utils/formatText";
import { showAlertServiceError } from "utils/alerts";
import { paginationWorkflowAddFields, paginationWorkflowFieldsAdd } from "utils/initialPaginationsConfig";
import i18n from "locales/i18n";
import useWorkFlow from "hooks/useWorkFlow";
import useForm from "hooks/useForm";
import swal from "sweetalert";
import WorkFlowAddFieldsTable from "components/organism/WorkFlowAddFieldsTable";

const WorkFlowAddFields = () => {
  const { id, workflowid } = useParams();
  const stepId = id;
  const {
    setViewAddFields,
    formIdInfo,
    editConfigStepsTab3FormData,
    setForcedUpdateFormName,
    setShowOptions
  } = useWorkflowContext();

  const {
    cofigurationFormInOneStep,
    getConfigurationFormInOneStepById,
    updateCofigurationFormInOneStep
  } = useWorkFlow();

  const { getFields } = useForm();
  const { formId } = formIdInfo;
  const [formsItems, setFormsItems] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [listFields, setListFields] = useState([]);
  const [fieldsSelected, setFieldsSelected] = useState([]);
  const [listOfItemsInSelector, setListOfItemsInSelector] = useState([]);
  const [pagination] = useState(paginationWorkflowAddFields);
  const [paginationWorkflowFields] = useState(paginationWorkflowFieldsAdd)
  const [isErrorSelect] = useState(false);
  const [isLoadingWorkflowAddFields, setIsLoadingWorkflowAddFields] = useState(false);

  let renderListOptionesAddFields = null;
  let loadingSpinnerAddFields = null;
  let labelForButtonSubmit = null;

  /**
   * Sets the `labelForButtonSubmit` variable based on the value of `editConfigStepsTab3FormData`.
   * If `editConfigStepsTab3FormData` is true, assigns the label for the submit button from the translation key "createusers.createButton3".
   * Otherwise, assigns the label for the submit button from the translation key "createWorkflowDesign.saveModal".
   * @param {boolean} editConfigStepsTab3FormData - A boolean indicating whether the configuration steps for tab 3 form data are in edit mode.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {string} - The translated label for the submit button based on the `editConfigStepsTab3FormData` value.
   */
  if (editConfigStepsTab3FormData === true) {
    labelForButtonSubmit = (i18n.t("createusers.createButton3"));
  } else {
    labelForButtonSubmit = (i18n.t("createWorkflowDesign.saveModal"));
  }

  /**
   * Sets the `loadingSpinnerAddFields` variable to a loading spinner JSX element if `isLoadingWorkflowAddFields` is true.
   * This code checks if `isLoadingWorkflowAddFields` is true and, if so, assigns a JSX element 
   * for a loading spinner to the `loadingSpinnerAddFields` variable. The spinner is displayed with a small size, 
   * secondary color, and a grow type.
   * @param {boolean} isLoadingWorkflowAddFields - A boolean indicating if the workflow fields are currently loading.
   * @param {JSX.Element|null} loadingSpinnerAddFields - The loading spinner element or null if not loading.
   * @returns {void} - This code does not return any value.
   */
  if (isLoadingWorkflowAddFields === true) {
    loadingSpinnerAddFields = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Renders a list of options for a selector based on the `listOfItemsInSelector` array.
   * This function checks if the `listOfItemsInSelector` array contains more than zero items. If so,
   * it maps over the array to create a list of `<option>` elements. Each `<option>` is assigned
   * a value corresponding to the item's UUID and displays the capitalized label of the item.
   * @param {Array<Object>} listOfItemsInSelector - An array of items to be rendered as options. Each item should have:
   *   - {string} uuid - The unique identifier of the item.
   *   - {string} label - The label of the item to be displayed in the option.
   * @param {Function} capitalizeText - A function to capitalize the label text.
   * @returns {JSX.Element[]} - An array of JSX `<option>` elements, or `null` if `listOfItemsInSelector` is empty.
   */
  if (arrayGreaterThanZero(listOfItemsInSelector) === true) {
    renderListOptionesAddFields = (
      listOfItemsInSelector.map((fields) => (
        <option value={fields.uuid} key={fields.uuid} className="text-capitalize">
          {capitalizeText(fields.label)}
        </option>
      ))
    )
  }

  /**
   * Handles the click event when a value is selected.
   * If the `selectedValue` is null or undefined, displays an informational message.
   * If the `selectedValue` is not null and not already present in the `listFields` array,
   * it adds the `selectedValue` to the `listFields` array and clears the `selectedValue`.
   */
  const handleOnClickAddField = () => {
    if (isNullOrUndefined(selectedValue) === true) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("createWorkflow.configurations.alert"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
      return
    }
    if (selectedValue && listFields.indexOf(selectedValue) === -1) {
      setListFields([...listFields, selectedValue])
      setSelectedValue("")
    }
  };

  /**
   * Retrieves the active form fields based on the provided `formId` and updates the `formsItems` state.
   * Uses the pagination parameters `page`, `perPage`, and `actions` to fetch the fields.
   * @param {number} formId - The ID of the form to retrieve fields from.
   * @returns {void}
   */
  const getActiveForm = useCallback(() => {
    if (isNullOrUndefined(formId) === false) {
      const { page, perPage, actions } = pagination;
      getFields(page, perPage, formId, actions)
        .then((response) => {
          if (isNullOrUndefined(response.data.items) === false) {
            const activeFields = response.data.items.filter((field) => field.status === 1)
            setFormsItems(activeFields);
          } else {
            showAlertServiceError();
          }
        })
    }
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Handles the deletion of a field based on its ID.
   * It filters the `fieldsSelected` array to remove the field with the specified ID.
   * It also filters the `listFields` array to remove the ID of the field.
   * @param {string} fieldId - The ID of the field to be deleted.
   */
  const handleDeleteField = (fieldId) => {
    setFieldsSelected(fieldsSelected.filter(field => field !== fieldId));
    setListFields(listFields.filter(listItem => listItem !== fieldId));
  }

  /**
   * Handles the submission of adding fields.
   * It prevents the default form submission behavior.
   * If the `listFields` array is empty, displays a warning message.
   * If `editConfigStepsTab3FormData` is false, sends a configuration payload to create a step.
   * If `editConfigStepsTab3FormData` is true, sends a configuration payload to update a step.
   * Displays success messages and reloads the page upon successful submission.
   * @param {Event} eventSubmitAddFields - The event object of the form submission.
   */
  const handleSubmitAddFields = (eventSubmitAddFields) => {
    eventSubmitAddFields.preventDefault();
    if (arrayGreaterThanZero(listFields) === false) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("createWorkflow.configurations.alert2"),
        icon: "warning",
        button: i18n.t("modal.Done.footerButton"),
      });
    } else if (editConfigStepsTab3FormData === false) {
      const configurationFieldsPayload = {
        "type_of_form_to_fill_in": 1,
        "form_fields_uuid": listFields
      }
      setIsLoadingWorkflowAddFields(true)
      cofigurationFormInOneStep(workflowid, stepId, configurationFieldsPayload)
        .then(() => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createWorkflow.configurations.alert3"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location.reload();
          })
        })
        .finally(() => { setIsLoadingWorkflowAddFields(false) })
    } else if (editConfigStepsTab3FormData === true) {
      const updateConfigurationFieldsPayload = {
        "form_fields_uuid": listFields
      }
      setIsLoadingWorkflowAddFields(true)
      updateCofigurationFormInOneStep(workflowid, stepId, updateConfigurationFieldsPayload)
        .then(() => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createWorkflow.configurations.alert3.1"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setShowOptions("3");
            setViewAddFields(false);
            setForcedUpdateFormName(true);
          })
        })
        .finally(() => {
          setIsLoadingWorkflowAddFields(false)
        })
    };
  };

  /**
   * Sets the view of fields by setting `viewAddFields` to `false`.
   * @returns {void}
   */
  const setViewFields = () => {
    setViewAddFields(false)
  }

  /**
   * Handles the selected field value by updating the `selectedValue` state.
   * @param {Object} eventChangeFields - The event object for the field value change.
   * @returns {void}
   */
  const hanldeSelectedFieldValue = (eventChangeFields) => {
    setSelectedValue(eventChangeFields.target.value)
  }

  /**
   * Updates the selected fields based on changes to `formsItems`, `listFields`, or `editConfigStepsTab3FormData`.
   * It filters the `formsItems` based on the condition that the field's UUID is present in the `listFields`,
   * and then updates the `fieldsSelected` state with the filtered items.
   * @param {Array} formsItems - The array of form items to filter and select from.
   * @param {Array} listFields - The array of field UUIDs that determine the selected fields.
   * @param {any} editConfigStepsTab3FormData - The data related to the edit configuration in tab 3.
   * @returns {void}
   */
  useEffect(() => {
    const selectedFieldsItems = formsItems
      .filter(field => listFields.indexOf(field.uuid) !== -1)
      .map(field => ({ label: field.label, uuid: field.uuid }));
    setFieldsSelected(selectedFieldsItems);
  }, [formsItems, listFields, editConfigStepsTab3FormData]);

  /**
   * Executes the effect when the component mounts or when the dependencies change.
   * Filters the `formsItems` array to find items that are not present in `fieldsSelected` array,
   * and sets the filtered items in `listOfItemsInSelector`.
   * @param {Array} formsItems - The array of form items to filter.
   * @param {Array} fieldsSelected - The array of selected fields to compare against.
   * @param {any} editConfigStepsTab3FormData - The data related to the edit configuration in tab 3.
   * @returns {void}
   */

  useEffect(() => {
    const repeatedItems = formsItems.filter(field => !fieldsSelected.some(fieldSelected => fieldSelected.uuid === field.uuid));
    setListOfItemsInSelector(repeatedItems);
  }, [formsItems, fieldsSelected, editConfigStepsTab3FormData]);

  /**
 * Performs an effect when the dependencies change to filter and update a list of items in a selector.
 * The effect filters the `formsItems` array to include only the items that do not exist in the `fieldsSelected` array.
 * The filtered items are then set in the `listOfItemsInSelector` state.
 * @param {Array} formsItems - The array of items to be filtered.
 * @param {Array} fieldsSelected - The array of selected fields.
 * @param {boolean} editConfigStepsTab3FormData - Flag indicating if the configuration steps form data is being edited.
 */

  useEffect(() => {
    const repeatedItems = formsItems.filter(field => !fieldsSelected.some(fieldSelected => fieldSelected.uuid === field.uuid));
    setListOfItemsInSelector(repeatedItems)
  }, [formsItems, fieldsSelected, editConfigStepsTab3FormData])

  /**
   * Fetches the active form when the value of `formId` changes.
   * It calls the `getActiveForm` function to retrieve the active form data.
   * @param {string} formId - The ID of the form to fetch.
   */
  useEffect(() => {
    getActiveForm();
    // eslint-disable-next-line
  }, [formId]);

  /**
  * Perform an operation when the value of `editConfigStepsTab3FormData` changes.
  * If `editConfigStepsTab3FormData` is true, get the form's configuration
  * in a specific step and updates the selected fields and the list of fields.
  * @param {boolean} editConfigStepsTab3FormData - A value indicating whether to perform the operation.
  */
  useEffect(() => {
    if (editConfigStepsTab3FormData === true) {
      setIsLoadingWorkflowAddFields(true)
      const { page, perPage } = paginationWorkflowFields;
      getConfigurationFormInOneStepById(workflowid, stepId, page, perPage)
        .then((response) => {
          const fieldList = response.data.data.items;
          if (isNullOrUndefined(fieldList) === false) {
            const prevList = fieldList.map((field) => {
              return {
                uuid: field.field_uuid,
                label: field.label
              }
            })
            const uuid = fieldList.map((field) => {
              return field.field_uuid
            })
            setListFields(uuid)
            setFieldsSelected(prevList);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingWorkflowAddFields(false)
        })
    };
    // eslint-disable-next-line 
  }, [editConfigStepsTab3FormData])

  /**
   * Renders a loading spinner if `isLoadingWorkflowAddFields` is true.
   * This function returns a JSX element containing a loader if `isLoadingWorkflowAddFields` is true.
   * The loader is displayed with a specific color and type, and is centered within a wrapper.
   * @param {boolean} isLoadingWorkflowAddFields - A boolean indicating if the workflow fields are loading.
   * @returns {JSX.Element|null} - A JSX element containing the loader if `isLoadingWorkflowAddFields` is true, otherwise null.
   */
  if (isLoadingWorkflowAddFields === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <AvForm onSubmit={handleSubmitAddFields}>
            <CardHeader className="mt-4 ml-3 mb-3">
              <div
                className="card-header-title font-size-lg text-capitalize font-weight-bold"
              >
                <CardTitle>{i18n.t("createWorkflow.configurations.label5")}</CardTitle>
              </div>
              <div className="btn-actions-pane-right absolute right-0">
                <Button
                  className="btn-icon mb-3"
                  color="cyan"
                  onClick={() => window.history.back()}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                  {i18n.t("createWorkflow.config.back")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AvGroup row>
                <Col md={2}>
                  <Label className="is-required mt-2">
                    {i18n.t("createWorkflow.configurations.label2")}
                  </Label>
                </Col>
                <Col md={8}>
                  <AvField
                    id="responsibleOption"
                    name="responsibleOption"
                    type="select"
                    className="text-capitalize"
                    required={isErrorSelect}
                    errorMessage={i18n.t(
                      "createWorkflow.config.typeResponsible"
                    )}
                    value={selectedValue}
                    onChange={hanldeSelectedFieldValue}
                  >
                    <option value={""}>
                      {i18n.t("createWorkflow.configurations.label1")}
                    </option>
                    {renderListOptionesAddFields}
                  </AvField>
                </Col>
                <Col md={2}>
                  <Button
                    disabled={isLoadingWorkflowAddFields}
                    className="btn-block"
                    color="cyan"
                    size="lg"
                    onClick={handleOnClickAddField}
                  >
                    <span>{i18n.t("createWorkflow.config.AddUser")}</span>
                  </Button>
                </Col>
              </AvGroup>
              <AvGroup>
                <Col md={12}>
                  <WorkFlowAddFieldsTable
                    listOfTableFields={fieldsSelected}
                    handleDeleteField={handleDeleteField}
                    isLoadingWorkflowAddFields={isLoadingWorkflowAddFields}
                  />
                </Col>
              </AvGroup>
            </CardBody>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                disabled={isLoadingWorkflowAddFields}
                className="col-mt-3 mr-3"
                color="gray"
                onClick={setViewFields}
              >
                {loadingSpinnerAddFields}
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                disabled={isLoadingWorkflowAddFields}
                className="col-mt-3"
                color="cyan"
              >
                {labelForButtonSubmit}
                {loadingSpinnerAddFields}
              </Button>
            </CardFooter>
          </AvForm>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default WorkFlowAddFields