import React from "react";
import FilterForm from "./FilterForm";
import { FilterContextProvider } from "contextAPI/FilterContext";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";

export default () => {
  return (
    <FilterContextProvider>
      <WorkflowContextProvider>
        <FilterForm />
      </WorkflowContextProvider>
    </FilterContextProvider>
  );
};