import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import useRoles from "hooks/useRoles";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardFooter,
  Container,
  Col,
} from "reactstrap";
import { showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import { loaderElement } from "utils/loaderElement";
import { Link, useHistory, useParams } from "react-router-dom";
import { regexCreateUserRoles } from "utils/regexExpressions";
import { enumsCreateUserFormRoles } from "utils/enums";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert";
import i18n from "locales/i18n";

const CreateUserFormRoles = () => {
  const history = useHistory();
  const { id } = useParams();
  const { createRole, getRole, editRole } = useRoles();
  const [isLoadingCreateFormRoles, setIsLoadingCreateFormRoles] = useState(false);
  const [nameRole, setNameRole] = useState({
    name: "",
  });

  let cardTitle = null;
  let buttonTitle = null;

  /**
   * Determines the button title based on the presence of an ID.
   * If the ID is not null or undefined, sets the button title to the edit button text.
   * Otherwise, sets the button title to the create button text.
   * @param {string|number|null|undefined} id - The ID to check.
   * @param {string} buttonTitle - The variable to hold the button title.
   */
  if (isNullOrUndefined(id) === false) {
    buttonTitle = i18n.t("createusers.createButton3");
  } else {
    buttonTitle = i18n.t("createusers.createButton");
  }

  /**
   * Determines the card title based on the presence of an ID.
   * If the ID is not null or undefined, sets the card title to the edit title.
   * Otherwise, sets the card title to the create title.
   * @param {string|number|null|undefined} id - The ID to check.
   * @param {string} cardTitle - The variable to hold the card title.
   */
  if (isNullOrUndefined(id) === false) {
    cardTitle = i18n.t("createRoles.TittleEdit");
  } else {
    cardTitle = i18n.t("createRoles.Tittle");
  }

  /**
   * Handles the change event for the role name input.
   * @param {Object} eventRolesName - The event object from the role name input.
   * @param {Object} eventRolesName.target - The target element of the event.
   * @param {string} eventRolesName.target.name - The name attribute of the target element.
   * @param {string|number} eventRolesName.target.value - The value of the target element.
   */
  const handleOnChangeRolesName = (eventRolesName) => {
    setNameRole({
      ...nameRole,
      [eventRolesName.target.name]: eventRolesName.target.value,
    });
  };

  /**
   * Fetches the role by its ID and sets the role name state.
   * Uses the getRole function to retrieve role information based on the provided ID.
   * If the role data is found, sets the nameRole state with the role's name.
   * If no role data is found, triggers an alert for a service error.
   * This function is memoized using useCallback to prevent unnecessary re-renders.
   */
  const getRoleById = useCallback(() => {
    setIsLoadingCreateFormRoles(true);
    getRole(id)
      .then((response) => {
        if (isNullOrUndefined(response.data.name) === false) {
          setNameRole({ name: response.data.name });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingCreateFormRoles(false);
      });
    // eslint-disable-next-line
  }, [id]);

  /**
   * useEffect hook to fetch the role by ID when the component mounts.
   * This effect runs only once, when the component mounts.
   * If the ID is not null or undefined, it calls the getRoleById function to fetch and set the role data.
   */
  useEffect(() => {
    if (isNullOrUndefined(id) === false) {
      getRoleById();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Handles the form submission for roles.
   * Prevents the default form submission behavior, checks for validation errors,
   * and either edits an existing role or creates a new one based on the presence of an ID.
   * Provides feedback to the user through alert dialogs and redirects them to the roles page.
   * @param {Object} eventSubmitFormRoles - The event object from the form submission.
   * @param {Function} eventSubmitFormRoles.preventDefault - The method to prevent the default form submission behavior.
   * @param {Array} errors - An array of validation errors.
   */
  const handleOnSubmitFormRoles = (eventSubmitFormRoles, errors) => {
    eventSubmitFormRoles.preventDefault();
    if (errors.length === 0) {
      setIsLoadingCreateFormRoles(true);
      if (id) {
        const roleName = { name: nameRole.name.trim() };
        editRole(id, roleName)
          .then((response) => {
            if (response.status === 202) {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.Alert.Edit"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              });
            } else {
              showAlertServiceError();
            }
            history.push("/user/roles");
          })
          .catch((error) => {
            if (error.response.data.code === "10014") {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("10014"),
                icon: "error",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
            if (error.response.data.code === "10015") {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("10015"),
                icon: "error",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          })
          .finally(() => {
            setIsLoadingCreateFormRoles(false);
          });
      } else {
        const roleName = { name: nameRole.name.trim() };
        createRole(roleName)
          .then((response) => {
            if (response.status === 201) {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.alert.subHead"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              });
            } else {
              showAlertServiceError();
            }
            history.push("/user/roles");
          })
          .catch((error) => {
            if (error.response.data.code === "10014") {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("10014"),
                icon: "error",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
            if (error.response.data.code === "10015") {
              Swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("10015"),
                icon: "error",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          })
          .finally(() => {
            setIsLoadingCreateFormRoles(false);
          });
      }
    }
  };

  /**
   * Handles the key press event for creating a user.
   * Prevents the default action if the pressed key is "Enter".
   * @param {Object} eventPressCreateUser - The event object from the key press.
   * @param {string} eventPressCreateUser.key - The key that was pressed.
   * @param {Function} eventPressCreateUser.preventDefault - The method to prevent the default action.
   */
  const handleOnKeyPressCreateUser = (eventPressCreateUser) => {
    if (eventPressCreateUser.key === "Enter") {
      eventPressCreateUser.preventDefault();
    }
  };

  return (
    <Fragment>
      {loaderElement(isLoadingCreateFormRoles)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>
                {cardTitle}
              </CardTitle>
              <AvForm onSubmit={handleOnSubmitFormRoles} autoComplete="off">
                <ReactTooltip
                  id="name"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  {i18n.t("trd.Tooltip4")}
                </ReactTooltip>
                <AvGroup row>
                  <Label for="name" className="is-required" sm={2}>
                    {i18n.t("createRoles.label1")}
                  </Label>
                  <Col md={10}>
                    <AvField
                      id="name"
                      name="name"
                      type="text"
                      onChange={handleOnChangeRolesName}
                      onKeyPress={handleOnKeyPressCreateUser}
                      data-tip
                      data-for="name"
                      validate={{
                        pattern: {
                          value: regexCreateUserRoles,
                          errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                        },
                        required: {
                          value: true,
                          errorMessage: `${i18n.t(
                            "createRoles.fieldRequired"
                          )}`,
                        },
                        minLength: {
                          value: enumsCreateUserFormRoles.MIN_LENGTH_NAME,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 70 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsCreateUserFormRoles.MAX_LENGTH_NAME,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 70 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={nameRole.name}
                    />
                  </Col>
                </AvGroup>
                <CardFooter className="d-block text-right">
                  <Link to="/user/roles">
                    <Button size="lg" className="col-mt-3 mr-3" color="gray">
                      {i18n.t("createusers.createButton2")}
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isLoadingCreateFormRoles}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {buttonTitle}
                  </Button>
                </CardFooter>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default CreateUserFormRoles;
