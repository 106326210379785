import React, { useEffect, useState } from "react";
import { useListContext } from "contextAPI/ListsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import cx from "classnames";
import i18n from "locales/i18n";

const WizardList = (props) => {
  const { steps, showNavigation } = props;
  const [buttons, setButtons] = useState({
    showPreviousBtn: false,
    showNextBtn: true,
  });
  const {
    canNext,
    compState,
    setCompState,
    errorList,
    items,
    setItems,
    setList,
    setItem,
    createListState,
    setCreateListState,
  } = useListContext();

  /**
   * Generates navigation states for a multi-step process.
   * @param {number} currentIndex - The index of the current step in the process.
   * @param {number} length - The total number of steps in the process.
   * @returns {object} An object containing information about the current step and styles for each step.
   * @property {number} current - The index of the current step.
   * @property {Array<string>} styles - An array of strings representing the styles for each step.
   */
  const getNavStates = (currentIndex, length) => {
    const styles = Array(length).fill("todo");
    styles[currentIndex] = "doing";
    if (currentIndex > 0) {
      styles.fill("done", 0, currentIndex);
    }
    return { current: currentIndex, styles };
  };

  const [state, setState] = useState({
    navState: getNavStates(0, steps.length),
  });

  /**
   * Determines the navigation state based on the current step and total steps length.
   * @param {number} currentStep - The index of the current step in the navigation.
   * @param {number} stepsLength - The total number of steps in the navigation.
   * @returns {Object} An object containing the state of navigation buttons.
   * @returns {boolean} return.showPreviousBtn - Indicates whether the "previous" button should be displayed.
   * @returns {boolean} return.showNextBtn - Indicates whether the "next" button should be displayed.
   */
  const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
      return {
        showPreviousBtn: true,
        showNextBtn: true,
      };
    } else if (currentStep === 0) {
      return {
        showPreviousBtn: false,
        showNextBtn: true,
      };
    } else if (currentStep === 2) {
      return {
        showPreviousBtn: false,
        showNextBtn: false,
      };
    } else {
      return {
        showPreviousBtn: true,
        showNextBtn: false,
      };
    }
  };

  /**
   * Updates the navigation state and buttons for a multi-step process.
   * @param {number} next - The index of the next step in the process.
   * @param {number} stepsLength - The total number of steps in the process.
   * @param {function} setState - State setter function to update the navigation state.
   * @param {function} setCompState - State setter function to update the current step component state.
   * @param {function} setButtons - State setter function to update the navigation buttons state.
   * @param {function} getNavStates - Function to calculate the navigation states based on the next step index and total steps length.
   * @param {function} checkNavState - Function to check the navigation state based on the next step index and total steps length.
   * @returns {void}
   */
  const setNavState = (next) => {
    setState({
      ...state,
      navState: getNavStates(next, steps.length),
    });
    if (next < steps.length) {
      setCompState(next);
    }
    setButtons(checkNavState(next, steps.length));
  };

  /**
   * Increments the navigation state to the next component.
   * @returns {void}
   */
  const next = () => {
    setNavState(compState + 1);
  };

  /**
   * Moves to the previous state in the component navigation.
   * If the current state is greater than 0, it decrements the navigation state,
   * resets certain state variables, and adjusts the createListState accordingly.
   * @returns {void}
   */
  const previous = () => {
    if (compState > 0) {
      setNavState(compState - 1);
      setItems([]);
      setList([]);
      setItem([]);

      if (items.length === 0) {
        setCreateListState({
          name: createListState.name,
          parent_id: createListState.parent_id,
        });
      } else {
        setCreateListState({
          name: "",
          parent_id: "",
          item: {
            name: "",
            parent_id: "",
          },
        });
      }
    }
  };

  /**
   * Concatenates the provided class name with a style value retrieved from the state
   * based on the given index.
   * @param {string} className - The base class name to be concatenated.
   * @param {number} index - The index used to retrieve a style value from the state.
   * @returns {string} The concatenated class name, combining the provided class name with the style value from the state.
   */
  const getClassName = (className, index) => {
    return className + "-" + state.navState.styles[index];
  };

  /**
   * Renders the steps of a form wizard as a list item for each step.
   * @returns {JSX.Element[]} An array of JSX elements representing the steps of the form wizard.
   */
  const renderSteps = () => {
    return steps.map((s, index) => (
      <li
        className={getClassName("form-wizard-step", index)}
        key={index}
        value={index}
      >
        <em>{index + 1}</em>
        <span>{steps[index].name}</span>
      </li>
    ));
  };

  /**
   * Executes a callback function when the 'errorList' state value changes to true.
   * The callback function is responsible for performing a previous action.
   * @param {boolean} errorList - A boolean indicating whether an error list exists or not.
   *   When set to true, it triggers the callback function to perform a previous action.
   * @param {function} previous - The callback function to execute when 'errorList' becomes true.
   *   It typically navigates the user to a previous step or performs a similar action.
   * @returns {void}
   */
  useEffect(() => {
    if (errorList === true) {
      previous();
    }
  }, [errorList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="d-block text-right">
        <a href={`/formsdesigner/consultlist`} data-test-id="consultlist-link">
          <Button
            data-test-id="back-button-to-lists"
            className="btn-icon"
            color="cyan"
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
            {i18n.t("list.designerItem3")}
          </Button>
        </a>
      </div>

      <ol className="forms-wizard">{renderSteps()}</ol>

      {steps[compState].component}

      <div className="clearfix pt-2">
        <div
          className={cx("", {
            showButtonFooter: showNavigation === false,
          })}
        >
          <Button
            data-test-id="prevous-wizard-button"
            color="gray"
            className={cx("btn-shadow float-left btn-wide", {
              showButtonFooter: buttons.showPreviousBtn === false,
            })}
            onClick={previous}
          >
            {i18n.t("itemList.designerButton2")}
          </Button>
          <Button
            data-test-id="next-wizard-button"
            color="cyan"
            className={cx("btn-shadow btn-wide float-right btn-hover-shine", {
              showButtonFooter: buttons.showNextBtn === false,
            })}
            onClick={next}
            disabled={canNext}
          >
            {i18n.t("itemList.designerButton1")}
          </Button>
        </div>
      </div>
    </div>
  );
};

WizardList.defaultProps = {
  showNavigation: true,
};

export default WizardList;
