import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import WorkflowTraceability from "components/organism/WorkflowTraceabilityDeatails";
import i18n from "locales/i18n";

const WorkflowsTraceability = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("workflowTraceability.header")}
        subheading={i18n.t("workflowTraceability.subHeader")}
        icon="news-paper"
      >
        <WorkflowTraceability />
      </AdminTemplate>
    </Fragment>
  );
};

export default WorkflowsTraceability;
