import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import TaskBoxList from "components/organism/TaskBoxList";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import i18n from "locales/i18n";

const TaskBox = () => {
  const { isVisibleButtonProcessBatch } = useWorkflowContext();

  let heading = i18n.t("taskBox");
  let subHeading = i18n.t("taskBox1");
  let icon = "drawer";

  /**
   * Sets the heading, subheading, and icon based on the visibility of the process batch button.
   * If the process batch button is not visible, it sets the heading to a default value, the subheading to another default value,
   * and the icon to represent settings.
   * @param {boolean} isVisibleButtonProcessBatch - A boolean indicating whether the process batch button is visible or not.
   * @returns {object} An object containing the updated heading, subheading, and icon.
   */
  if (isVisibleButtonProcessBatch === false) {
    heading = i18n.t("taskBoxBatchHeader");
    subHeading = i18n.t("taskBoxBatchSubHeader");
    icon = "settings";
  }
  return (
    <Fragment>
      <AdminTemplate heading={heading} subheading={subHeading} icon={icon}>
        <TaskBoxList />
      </AdminTemplate>
    </Fragment>
  );
};

export default TaskBox;
