import React, { useState, useEffect } from "react";
import useIntegration from "hooks/useIntegrations";
import swal from "sweetalert";
import i18n from "locales/i18n";

const SignatureContext = React.createContext(undefined);

export function SignatureContextProvider({ children }) {
  const { createSignature } = useIntegration();
  const [trdExistVal, setTrdExistVal] = useState(false);
  const [uuid, setUuid] = useState("");
  const [isLoadingSignature, setIsLoadingSignature] = useState(false);
  const [req, setReq] = useState(true);
  const [isError, setIsError] = useState(false);
  const [alertMinSignatories, setAlertMinSignatories] = useState(false);
  const [alertMaxSignatories, setAlertMaxSignatories] = useState(false);
  const [showTableSignatories, setShowTableSignatories] = useState(false);
  const [showSignatoriesForm, setShowSignatoriesForm] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [signatoriesList, setSignatoriesList] = useState([]);
  const [signatoriesContext, setSignatoriesContext] = useState([]);
  const [fileInfo, setFileInfo] = useState({
    name: "",
    subject: "",
    publication_at: "",
  });
  const [createSing, setCreateSing] = useState({
    subject: "",
    edit_data: "false",
    notification: "1",
    expiration_days: "30",
  });
  let maxSignatories = 10;

  /**
   * Handles key press events, preventing the default action if the pressed key is "Enter".
   * @param {object} eventKeyPress - The key press event object.
   * @param {string} eventKeyPress.key - The key that was pressed.
   * @returns {void}
   */
  const handleOnKeyPress = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * Handle the onBlur event for an input element by updating the 'createSing' state with trimmed input values.
   * @param {Event} eventOnBlur - The event object representing the onBlur event triggered by the input element.
   * @param {Object} createSing - The current state object that holds the form data.
   * @param {string} eventOnBlur.target.name - The name attribute of the input element, used as a key to update the 'createSing' state.
   * @param {string} eventOnBlur.target.value - The value entered in the input element to be trimmed and stored in the 'createSing' state.
   * @returns {void}
   */
  const handleOnBlur = (eventOnBlur) => {
    setCreateSing({
      ...createSing,
      [eventOnBlur.target.name]: eventOnBlur.target.value.trim(),
    });
  };

  /**
   * Handles the onBlur event for a signature input element, updating the signatories context.
   * @param {Event} eventOnBlurSig - The onBlur event object triggered by the signature input.
   * @param {string} eventOnBlurSig.target.name - The name of the signature input field.
   * @param {string} eventOnBlurSig.target.value - The value entered in the signature input field, trimmed of leading and trailing whitespace.
   * @param {Object} signatoriesContext - The current signatories context object.
   * @returns {void}
   */
  const handleOnBlurSig = (eventOnBlurSig) => {
    setSignatoriesContext({
      ...signatoriesContext,
      [eventOnBlurSig.target.name]: eventOnBlurSig.target.value.trim(),
    });
  };

  /**
   * Handles the change event for a form input and updates the 'createSing' state.
   * @param {Event} eventOnChangeCreateSing - The change event object triggered by an input field.
   * This event object contains information about the event, such as the target input element.
   * You can access the input value and name using eventOnChangeCreateSing.target.value and eventOnChangeCreateSing.target.name.
   */
  const handleOnChange = (eventOnChangeCreateSing) => {
    setCreateSing({
      ...createSing,
      [eventOnChangeCreateSing.target.name]:
        eventOnChangeCreateSing.target.value,
    });
  };

  /**
   * Handles changes in the signature input fields and updates the signatories context state.
   * @param {Event} eventOnChangeSig - The event object representing the change in the signature input field.
   * @param {string} eventOnChangeSig.target.name - The name property of the input field that triggered the change event.
   * @param {string} eventOnChangeSig.target.value - The new value entered in the input field.
   * @return {void}
   */
  const handleOnChangeSig = (eventOnChangeSig) => {
    setSignatoriesContext({
      ...signatoriesContext,
      [eventOnChangeSig.target.name]: eventOnChangeSig.target.value,
    });
  };

  /**
   * Handles the change of selected country in a form, updates context state, and resets error status.
   * @param {Object} selectedOption - The selected country option.
   * @param {string} selectedOption.label - The label or name of the selected country.
   * @param {string|number} selectedOption.value - The value or code associated with the selected country.
   * @param {function} setSelectedOption - A state setter function to update the selected country option.
   * @param {object} signatoriesContext - The context object that stores information related to signatories.
   * @param {string} signatoriesContext.country_name - The name of the selected country to be updated.
   * @param {number} signatoriesContext.country_code - The code or identifier of the selected country to be updated.
   * @param {function} setSignatoriesContext - A state setter function to update the signatories context.
   * @param {function} setIsError - A state setter function to update the error status, typically a boolean.
   * @returns {void}
   */
  const handleOnChangeCountries = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSignatoriesContext({
      ...signatoriesContext,
      country_name: selectedOption.label,
      country_code: Number(selectedOption.value),
    });
    setIsError(false);
  };

  /**
   * Deletes a firmante with the specified email address from the signatoriesList list and updates state.
   * This function takes an email address as an argument, filters the `signatoriesList` list, and removes the firmante with the specified email address.
   * It then updates the `signatoriesList` state with the new list that excludes the deleted firmante.
   * If the length of the updated `signatoriesList` list is 10 or fewer, it also sets the `alertMaxSignatories` state to `false`.
   * @param {string} value - The email address of the firmante to be deleted.
   *   This is used to identify the firmante to be removed from the list.
   * @returns {void}
   */
  function deleteSignatories(value) {
    const newSignatories = signatoriesList.filter(
      (item) => item.uuid !== value
    );
    setSignatoriesList(newSignatories);
    if (newSignatories.length <= 10) {
      setAlertMaxSignatories(false);
    }
  }

  /**
   * Handles the form submission for collecting signatory information and performs validation checks.
   * @param {Event} eventSubmited - The submit event triggered by the form submission.
   * This function is responsible for processing the form submission of signatory information.
   * It performs various validation checks to ensure that the provided information is valid.
   * @throws {Error} If validation fails, an error may be thrown.
   */
  const handleOnSubmitSig = (eventSubmited) => {
    if (signatoriesContext.country_name === undefined) {
      setIsError(true);
    } else if (
      signatoriesContext.signature_order === "" ||
      signatoriesContext.email === "" ||
      signatoriesContext.country_code === "" ||
      signatoriesContext.phone === "" ||
      signatoriesContext.name === "" ||
      signatoriesContext.country_name === ""
    ) {
      setIsError(true);
      setReq(true);
      eventSubmited.preventDefault();
    } else {
      if (
        signatoriesContext.length !== 0 &&
        (signatoriesContext.proof_life === "0" ||
          signatoriesContext.proof_life === "1") &&
        (signatoriesContext.signature_order === 0 ||
          signatoriesContext.signature_order === 1 ||
          signatoriesContext.signature_order === 2 ||
          signatoriesContext.signature_order === 3 ||
          signatoriesContext.signature_order === 4 ||
          signatoriesContext.signature_order === 5 ||
          signatoriesContext.signature_order === 6 ||
          signatoriesContext.signature_order === 7 ||
          signatoriesContext.signature_order === 8 ||
          signatoriesContext.signature_order === 9 ||
          signatoriesContext.signature_order === 10) &&
        signatoriesContext.country_name !== undefined &&
        signatoriesContext.phone !== undefined &&
        signatoriesContext.phone.match(/^([0-9]+)$/) &&
        signatoriesContext.phone.length >= 10 &&
        signatoriesContext.phone.length <= 20 &&
        signatoriesContext.email !== undefined &&
        signatoriesContext.email.match(/^[\w.-]+@[\w.-]+\.\w+/) &&
        signatoriesContext.email.length >= 15 &&
        signatoriesContext.email.length <= 100 &&
        signatoriesContext.name !== undefined &&
        signatoriesContext.name.match(/^[a-zA-Z0-9 À-ÿ]+$/) &&
        signatoriesContext.name.length >= 4 &&
        signatoriesContext.name.length <= 50
      ) {
        setSignatoriesList((prevState) => [
          ...prevState,
          {
            uuid: Date.now(),
            signature_order: signatoriesContext.signature_order,
            email: signatoriesContext.email,
            country_code: signatoriesContext.country_code,
            phone: signatoriesContext.phone,
            name: signatoriesContext.name,
            country_name: signatoriesContext.country_name,
            proof_life: signatoriesContext.proof_life,
          },
        ]);
        setSignatoriesContext({
          signature_order: "",
          email: "",
          country_code: "",
          phone: "",
          name: "",
          country_name: "",
          proof_life: "",
        });
        setSelectedOption([]);
        setReq(false);
        setIsError(false);
        setAlertMinSignatories(false);
        setShowTableSignatories(true);
      }
    }
  };

  /**
   * Handle the form submission for creating a signature.
   * This function checks the number of signatories and prevents submission if the
   * conditions are not met. If the number of signatories is valid, it triggers the
   * creation of a signature and shows a success message.
   * @param {Event} eventSubmited - The form submission event object.
   * @returns {void}
   */
  const handleOnSubmit = (eventSubmited, errors) => {
    if (createSing.signatories.length === 0) {
      eventSubmited.preventDefault();
      setAlertMinSignatories(true);
    } else if (createSing.signatories.length > 10) {
      eventSubmited.preventDefault();
      setAlertMaxSignatories(true);
    } else {
      const serviceCreateSign = {
        ...createSing,
        signatories: createSing.signatories.map(({ uuid, ...rest }) => rest)
      };
      if (signatoriesList.length !== 0) {
        setAlertMinSignatories(false);
        setIsLoadingSignature(true);
        if (errors.length === 0) {
          createSignature(uuid, serviceCreateSign)
            .then((response) => {
              if (response.status === 201) {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("signature.alertDone"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(function () {
                  window.history.back();
                });
              }
            })
            .finally(() => {
              setIsLoadingSignature(false);
            });
        } else {
          setIsLoadingSignature(false);
          return;
        }
      }
    }
  };

  /**
   * An effect that sets an alert if the number of signatories exceeds a specified limit.
   * @param {Array} signatoriesList - An array of signatories to monitor.
   * @param {function} setAlertMaxSignatories - A state setter function to control the alert state.
   * @returns {void}
   */
  useEffect(() => {
    if (signatoriesList.length > maxSignatories) {
      setAlertMaxSignatories(true);
    }
  }, [signatoriesList, setAlertMaxSignatories, maxSignatories]);

  return (
    <SignatureContext.Provider
      value={{
        uuid,
        setUuid,
        isLoadingSignature,
        setIsLoadingSignature,
        req,
        setReq,
        selectedOption,
        setSelectedOption,
        signatoriesList,
        setSignatoriesList,
        signatoriesContext,
        setSignatoriesContext,
        createSing,
        setCreateSing,
        handleOnKeyPress,
        handleOnBlur,
        handleOnBlurSig,
        handleOnChange,
        handleOnChangeSig,
        handleOnChangeCountries,
        deleteSignatories,
        handleOnSubmitSig,
        handleOnSubmit,
        alertMinSignatories,
        setAlertMinSignatories,
        alertMaxSignatories,
        setAlertMaxSignatories,
        isError,
        setIsError,
        showTableSignatories,
        setShowTableSignatories,
        showSignatoriesForm,
        setShowSignatoriesForm,
        trdExistVal,
        setTrdExistVal,
        fileInfo,
        setFileInfo,
      }}
    >
      {children}
    </SignatureContext.Provider>
  );
}
export function useSignatureContext() {
  const context = React.useContext(SignatureContext);
  if (!context) {
    throw new Error(
      "useSignatureContext debe estar dentro del proveedor SignatureContext."
    );
  }
  return context;
}
