import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Card,
  Row,
  Col,
  Label,
  CustomInput,
  Spinner,
} from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import UserRolConfigDesignWorkflowsTable from "components/organism/UserRolConfigDesignWorkflowsTable";
import i18n from "locales/i18n";
import Loader from "react-loaders";
import swal from "sweetalert";
import { useRolesContext } from "contextAPI/RolesContext";
import useRoles from "hooks/useRoles";
import { showAlertServiceError } from "utils/alerts";
import { enumsRolesAcordeon } from "utils/enums";

const UserRolesConfigDesignWorkflows = (props) => {
  const { uuidRol } = props;
  const { id } = useParams();
  const { addPermissionsDesignerWorkflows } = useRoles();
  const {
    workflowsDinamic,
    setDisabledSelect,
    permissionsDesignerWorkflows,
    setPermissionsDesignerWorkflows,
    workflowsSelected,
    setWorkflowsSelected,
    workflows,
    setWorkflows,
    workflowsTable,
    setWorkflowsTable,
    deleteWorkflows,
    setDisabledCheckWorkflows,
    listAdminWorkflows,
    allAdminWorkflows,
    isLoadingPermissionsAdminWorkflowsByRol,
    setAcordeon,
    acordeon,
    getPermissionsByAdminWorkflows,
    getAllWorkFlows
  } = useRolesContext();

  const [uniqueWorkflows, setUniqueWorkflows] = useState([])
  const [loadingConfig, setLoadingConfig] = useState(false)

  let componentLoadingConfigDesignWorkflows = null;
  let disabledSelectWorkflows = null;
  let disabledCheckedAllWorkflows = null;
  let valueAllWorkflowsSelected = null;

  /**
   * Determines the selected value for all workflows based on admin permissions and current designer workflows permissions.
   * @param {boolean} allAdminWorkflows - Indicates if all workflows are selected for admin permissions.
   * @param {Object} permissionsDesignerWorkflows - Current state of designer workflows permissions.
   * @returns {boolean} The selected value for all workflows.
   */
  if (allAdminWorkflows === true) {
    valueAllWorkflowsSelected = true;
  } else {
    valueAllWorkflowsSelected = permissionsDesignerWorkflows.all_workflows;
  }

  /**
   * Determines if all workflows are disabled based on the length of workflowsTable.
   * @param {number} workflowsTableLength - The length of the workflowsTable array.
   * @returns {boolean} Whether all workflows are disabled.
   */
  if (workflowsTable.length > 0) {
    disabledCheckedAllWorkflows = true;
  } else {
    disabledCheckedAllWorkflows = false;
  }

  /**
   * Renders a loading spinner if the configuration is loading.
   * @param {boolean} loadingConfig - The loading state of the configuration.
   * @param {JSX.Element} componentLoadingConfigDesignWorkflows - The JSX element for the loading spinner.
   */
  if (loadingConfig === true) {
    componentLoadingConfigDesignWorkflows = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
* Enables or disables form selection based on the permissions.
* @param {Object} permissionsDesignerForms - Object containing the permissions for the designer forms.
* @param {boolean} permissionsDesignerForms.all_forms - Indicates if all forms are allowed.
* @param {boolean} disabledSelectForms - The state indicating if form selection is disabled.
*/
  if (permissionsDesignerWorkflows.all_workflows) {
    disabledSelectWorkflows = true;
  } else {
    disabledSelectWorkflows = false;
  }

  /**
   * Closes the workflow design modal and resets associated state variables.
   * @function handleCloseDesignWorkflow
   * @returns {void}
   */
  const handleCloseDesignWorkflow = () => {
    setPermissionsDesignerWorkflows({
      all_workflows: false,
      workflows_uuid: [],
    })
    setDisabledCheckWorkflows(false);
    setAcordeon(([false, false, false, false]))
    setWorkflows([]);
    setWorkflowsTable([]);
    setWorkflowsSelected({
      ...workflowsSelected,
      workflow: "",
    });
  };

  /**
   * Handles changes in the selected workflow in the form.
   * @function handleOnChangeSig
   * @param {Object} eventSelectWorkflow - The event object containing information about the selected workflow.
   * @returns {void}
   */
  const handleOnChangeSig = (eventSelectWorkflow) => {
    setWorkflowsSelected({
      ...workflowsSelected,
      workflow: eventSelectWorkflow.target.value,
    });
  };

  /**
   * Handles the submission of workflows in the form.
   * @param {Object} eventSubmitWorkflow - The event object representing the workflow submission.
   * @returns {void}
   */
  const handleOnSubmitWorkflow = (eventSubmitWorkflow) => {
    if (workflowsSelected.workflow === "") {
      setDisabledCheckWorkflows(false);
      eventSubmitWorkflow.preventDefault();
    } else if (workflows.includes(workflowsSelected.workflow)) {
      const showAlertAlreadyExist = () => {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createRoles.configDesignWorkflowsExist"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        });
      };
      showAlertAlreadyExist();
    } else {
      setDisabledCheckWorkflows(true);
      setWorkflows((prevState) => [...prevState, workflowsSelected.workflow]);
      setWorkflowsSelected({ ...workflowsSelected, workflow: "" });
    }
  };

  /**
   * Handles changes in the selection of workflows in the form.
   * @function handleOnChangeSelectWorkflows
   * @param {Object} eventChangeSelectWorkflow - The event object representing the change in workflow selection.
   * @returns {void}
   */
  const handleOnChangeSelectWorkflows = (eventChangeSelectWorkflow) => {
    const target = eventChangeSelectWorkflow.target.value;
    const targetCheckbox = eventChangeSelectWorkflow.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setPermissionsDesignerWorkflows({
        ...permissionsDesignerWorkflows,
        all_workflows: Boolean(targetCheckbox),
      });
    }
    if (target.includes("true")) {
      setDisabledSelect(false);
    } else {
      setDisabledSelect(true);
    }
  };

  /**
   * Handles the submission of designer workflows permissions configuration.
   * @param {Event} event - The form submission event.
   * @param {boolean} setLoadingConfig - Function to set loading state for configuration.
   * @param {Function} addPermissionsDesignerWorkflows - Function to add permissions for designer workflows.
   * @param {string} id - The identifier for the current role or user.
   * @param {Object} permissionsDesignerWorkflows - Current state of designer workflows permissions.
   * @param {Function} setAcordeon - Function to update the accordion state.
   * @param {Function} showAlertServiceError - Function to display an error alert.
   * @returns {void}
   */
  const handleOnSubmitDesignWorkflow = () => {
    setLoadingConfig(true);
    addPermissionsDesignerWorkflows(id, permissionsDesignerWorkflows)
      .then((response) => {
        if (response.status === 201) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createRoles.configPermissions"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setAcordeon(([false, false, false, false]))
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setLoadingConfig(false));
  };

  /**
   * Updates the workflows table based on changes in workflows and workflowsDinamic.
   * @param {Array} workflows - The selected workflows.
   * @param {Array} workflowsDinamic - The dynamic list of workflows with values.
   * @returns {void}
   */
  useEffect(() => {
    // eslint-disable-next-line
    let workflowsData = uniqueWorkflows.filter((workflowsData) => {
      if (workflows.includes(workflowsData.value)) {
        return workflowsData;
      }
    });
    setWorkflowsTable(workflowsData);
  }, [workflows, workflowsDinamic]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches permissions for admin workflows and all workflows when the designer workflow accordion is expanded.
   * @param {boolean} isDesignerWorkflowExpanded - Indicates if the designer workflow accordion is expanded.
   * @param {string} uuidRol - The UUID of the role for which permissions are fetched.
   * @returns {void}
   */
  useEffect(() => {
    if (acordeon[enumsRolesAcordeon.DESIGNER_WORKFLOW] === true) {
      getPermissionsByAdminWorkflows(uuidRol)
      getAllWorkFlows()
    }
    // eslint-disable-next-line 
  }, [acordeon])

  /**
   * Updates the list of unique workflows based on changes in workflowsDinamic.
   * @function updateUniqueWorkflows
   * @param {Array} workflowsDinamic - The dynamic list of workflows with labels.
   * @returns {void}
   */
  useEffect(() => {
    const uniqueWorkflows = Array.from(new Set(workflowsDinamic.map(workflow => workflow.label)))
      .map(label => {
        return workflowsDinamic.find(workflow => workflow.label === label)
      })
    setUniqueWorkflows(() => uniqueWorkflows)
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [workflowsDinamic])

  /**
   * Updates the list of workflows and manages their selection status based on admin workflows and permissions.
   * @function updateWorkflowsList
   * @param {Array} listAdminWorkflows - List of workflows retrieved from admin permissions.
   * @param {boolean} allAdminWorkflows - Indicates if all workflows are selected for admin permissions.
   * @returns {void}
   */
  useEffect(() => {
    if (listAdminWorkflows.length > 0) {
      setWorkflows(workflows.concat(listAdminWorkflows));
      setDisabledCheckWorkflows(true);
    } else if (allAdminWorkflows === true) {
      setDisabledSelect(true);
    }
  }, [listAdminWorkflows, allAdminWorkflows]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Updates permissions for designer workflows based on changes in workflows and workflowsTable.
   * @param {boolean} allWorkflowsSelected - Indicates if all workflows are selected.
   * @param {Array} workflowsTable - The current list of workflows with values.
   * @returns {void}
   */
  useEffect(() => {
    if (permissionsDesignerWorkflows.all_workflows === false) {
      setPermissionsDesignerWorkflows({
        ...permissionsDesignerWorkflows,
        workflows_uuid: workflowsTable.map(workflow => workflow.value),
      });
    } else {
      setPermissionsDesignerWorkflows({
        all_workflows: true,
        workflows_uuid: [],
      });
    }
  }, [workflows, workflowsTable]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Displays a loading spinner if permissions for admin workflows are loading.
   * @param {boolean} isLoadingPermissionsAdminWorkflowsByRol - Indicates if permissions for admin workflows are loading.
   * @returns {JSX.Element} Loading spinner component.
   */
  if (isLoadingPermissionsAdminWorkflowsByRol === true) {
    return (
      <div className="d-flex justify-content-center">
        <Loader color="secondary" type="ball-pulse" size="small" />
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardHeader className="ml-3">
            {i18n.t("createRoles.configDesignWorkflowsHeader")}
          </CardHeader>
          <CardBody>
            <Alert className="mbg-3" color="info">
              <span className="pr-2 text-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2 "
                />
                {i18n.t("createRoles.configDesignWorkflowsAlert")}
              </span>
            </Alert>
            <AvForm onSubmit={handleOnSubmitDesignWorkflow}>
              <AvGroup>
                <Label for="forms" className="is-required">
                  {i18n.t("createRoles.configDesignWorkflowsSelect")}
                </Label>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={10}>
                        <AvField
                          id="workflow"
                          name="workflow"
                          type="select"
                          onChange={handleOnChangeSig}
                          disabled={disabledSelectWorkflows}
                          value={workflowsSelected.workflow}
                        >
                          <option value={""}>
                            {i18n.t("form.designerLabel10.1")}
                          </option>

                          {uniqueWorkflows.map((eventSelecWorkflow) => (
                            <option
                              className="text-capitalize"
                              value={eventSelecWorkflow.value}
                              key={eventSelecWorkflow.value}
                            >
                              {eventSelecWorkflow.label}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          size="lg"
                          className="col-mt-3"
                          color="cyan"
                          disabled={disabledSelectWorkflows}
                          onClick={handleOnSubmitWorkflow}
                        >
                          +{i18n.t("createRoles.MembersButton")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <AvInput
                      id="all_workflows"
                      name="all_workflows"
                      type="checkbox"
                      onChange={handleOnChangeSelectWorkflows}
                      tag={CustomInput}
                      label={i18n.t("filteruser.item1").toUpperCase()}
                      checked={disabledSelectWorkflows}
                      disabled={disabledCheckedAllWorkflows}
                      value={valueAllWorkflowsSelected}
                    />
                  </Col>
                </Row>
              </AvGroup>
              <UserRolConfigDesignWorkflowsTable
                workflowsTable={workflowsTable}
                deleteWorkflows={deleteWorkflows}
                loading={isLoadingPermissionsAdminWorkflowsByRol}
              />

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  disabled={loadingConfig}
                  className="col-mt-3 mr-3"
                  color="gray"
                  onClick={handleCloseDesignWorkflow}
                >
                  {componentLoadingConfigDesignWorkflows}
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  className="col-mt-3"
                  color="cyan"
                >
                  {componentLoadingConfigDesignWorkflows}
                  {i18n.t("createWorkflowDesign.saveModal")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserRolesConfigDesignWorkflows.propTypes = {
  uuidRol: PropTypes.string.isRequired,
};

export default UserRolesConfigDesignWorkflows;
