import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { getDateFormat2 } from "utils/getDateFormat2";
import ButtonActionsSignatures from "components/atoms/ButtonActionsSignatures";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const SignatureListTable = (props) => {
  const {
    listSignatures,
    pagination,
    setPagination,
    isLoadingSignatures,
    totalPages,
  } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={listSignatures}
            columns={[
              {
                columns: [
                  {
                    Header: i18n.t("taskBoxTable5"),
                    accessor: "subject",
                  },
                  {
                    Header: i18n.t("recordList9"),
                    accessor: "record_number",
                    Cell: (row) => (
                      <div className="d-flex justify-content-center">
                        <Link
                          to={`/record/detail/${row.original.record_uuid}/${row.original.form_uuid}`}
                        >
                          {row.original.record_number}
                        </Link>
                      </div>
                    ),
                  },
                  {
                    Header: i18n.t("createRoles.Table.tr3"),
                    accessor: "created_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <p>{valueFinal}</p>;
                    },
                  },
                  {
                    Header: i18n.t("trdExpedientList5"),
                    accessor: "transaction_status",
                    Cell: ({ value }) => {
                      let statusFinal = null;
                      let badgeClass = null;

                      switch (value) {
                        case 0:
                          statusFinal = i18n.t("info.SignatureFilter1");
                          badgeClass = "badge badge-warning";
                          break;
                        case 1:
                          statusFinal = i18n.t("info.SignatureFilter2");
                          badgeClass = "badge badge-success";
                          break;
                        case 2:
                          statusFinal = i18n.t("info.SignatureFilter3");
                          badgeClass = "badge badge-danger";
                          break;
                        default:
                          statusFinal = "";
                          break;
                      }

                      return (
                        <span className="text-center justify-content-center align-items-center">
                          {statusFinal && (
                            <span className={`ml-auto ${badgeClass}`}>
                              {statusFinal}
                            </span>
                          )}
                        </span>
                      );
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    Header: i18n.t("buttonActions.title"),
                    accessor: "actions",
                    Cell: (row) => {
                      return (
                        <ButtonActionsSignatures
                          id={row.original.uuid}
                          transactionStatus={row.original.transaction_status}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingSignatures}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SignatureListTable.propTypes = {
  listSignatures: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingSignatures: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default SignatureListTable;
