import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import { Alert, Row, CardTitle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { getDateFormat2 } from "utils/getDateFormat2";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import useWorkFlow from "hooks/useWorkFlow";
import Loader from "react-loaders";

const RecordTraceabilityComments = (props) => {
  const { isProcess, id, processId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const {
    getCommentsByUuid,
    getCommentsProcessByUuid,
  } = useWorkFlow();

  /**
   * Fetches comments by the provided `id` and updates the comments state.
   * It sets a loading state before the request and resets it after the request completes. 
   * The callback is memoized using the `useCallback` hook to prevent unnecessary re-renders.
   * @function getCommentsById
   * @returns {void} This function does not return a value.
   */
  const getCommentsById = useCallback(() => {
    setIsLoading(true);
    if (isProcess === false) {
      getCommentsByUuid(id)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setComments(response.data.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches comments associated with a process by the provided `processId` and updates the comments state.
   * It sets a loading state before the request and resets it after the request completes. 
   * The callback is memoized using the `useCallback` hook to prevent unnecessary re-renders.
   * @function getCommentsProcessById
   * @returns {void} This function does not return a value. It performs side effects such as fetching data and updating state.
   */
  const getCommentsProcessById = useCallback(() => {
    setIsLoading(true);
    if (isProcess) {
      getCommentsProcessByUuid(processId)
        .then((response) => {
          setComments(response.data.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the `getCommentsById` function when the component mounts or when the `getCommentsById` dependency changes.
   * This effect is triggered by the `useEffect` hook and is used to fetch comments based on the provided `id`.
   * @function useEffect
   * @param {Function} getCommentsById - The function to be called when the effect runs. This function is expected to fetch comments.
   * @returns {void} This effect does not return a value. It performs side effects such as data fetching or state updates.
   */
  useEffect(() => {
    getCommentsById();
  }, [getCommentsById]);

  /**
   * Runs the `getCommentsProcessById` function when the component mounts or when the `getCommentsProcessById` dependency changes. 
   * This effect is triggered by the `useEffect` hook and is used to fetch comments or perform other side effects related to comment processing.
   * @function useEffect
   * @param {Function} getCommentsProcessById - The function to be called when the effect runs. This function is expected to handle comment processing.
   * @returns {void} This effect does not return a value. It performs side effects like data fetching or state updates.
   */
  useEffect(() => {
    getCommentsProcessById();
  }, [getCommentsProcessById]);

  /**
   * Conditionally renders a loading spinner if `isLoading` is true.
   * If `isLoading` is true, the component will display a centered loader spinner.
   * @param {boolean} isLoading - A boolean indicating whether the component is currently loading.
   * @returns {JSX.Element|null} - A JSX element representing the loader spinner if `isLoading` is true, or `null` if false.
   */
  if (isLoading === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  /**
   * Conditionally renders a title and an alert if the `comments` array is empty.
   * If `comments.length` is 0, the component will display a title and an alert message.
   * @param {Array} comments - An array of comments. If empty, the title and alert are rendered.
   * @param {Object} i18n - An object for internationalization (i18n) to fetch translated text.
   * @returns {JSX.Element|null} - A JSX element containing a title and an alert if `comments.length` is 0, or `null` if there are comments.
   */
  if (comments.length === 0) {
    return (
      <div>
        <CardTitle
          className="text-start"
        >
          {i18n.t("taskCommentsTittle")}
        </CardTitle>
        <br />
        <Alert className="block" color="info">
          {i18n.t("taskCommentsAlert")}
        </Alert>
      </div>
    )
  };

  return (
    <Fragment>
      <div>
        <CardTitle
          className="text-start"
        >
          {i18n.t("taskCommentsTittle")}
        </CardTitle>
        {comments.map((elementComment) => (
          <div>
            <VerticalTimeline
              className="vertical-time-icons"
              layout={"1-column"}
              animate={false}
            >
              <VerticalTimelineElement
                className="vertical-timeline-item"
                icon={
                  <div className="timeline-icon border-cyan bg-success">
                    <FontAwesomeIcon
                      icon={faComment}
                      className="text-white fa-flip-horizontal"
                    />
                  </div>
                }
              >
                <h6 className="text-primary">{elementComment.comment}</h6>
                <Row>
                  {getDateFormat2(new Date(elementComment.created_at))}
                  &nbsp; &nbsp;
                  <p className="text-capitalize">{elementComment.user_fullname}</p>
                </Row>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        ))}
      </div>
      <br />
    </Fragment>
  );
};

RecordTraceabilityComments.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  processId: PropTypes.string.isRequired,
};

export default RecordTraceabilityComments;
