import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { useFilterContext } from "contextAPI/FilterContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { initialPaginationTasks } from "utils/initialPaginationsConfig";
import FormsSelectByFiltersTasks from "../FormsSelectByFiltersTasks";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ModalFilterTasks = (props) => {
  const { openModalFilter, setOpenModalFilter, setPagination } = props;
  const {
    selectedOptions,
    setSelectedOptions,
    setSelectFormIdContext,
    setStructureQueryByFilterTask,
    structureQueryByFilterTask,
    getTasksByFilter,
    isFilter,
    pagination,
    selectedOptionsWorkflow,
    setSelectedOptionsWorkflow,
    selectedOptionsStep,
    setSelectedOptionsStep,
    copiedSelectedOptionWorkflow,
    setCopiedSelectedOptionWorkflow,
    copiedSelectedOptionStep,
    setCopiedSelectedOptionStep,
    copiedSelectedOption,
    setCopiedSelectedOption,
    setSelectedFields,
    setIsFilter,
  } = useWorkflowContext();

  const {
    queries,
    queriesRec,
    setQueriesRec,
    setQueries,
    setQueriesTable,
    queriesTable,
  } = useFilterContext();

  const [copiedQueriesTable, setCopiedQueriesTable] = useState([]);
  const [handleOnSubmitFilterState, setHandleOnSubmitFilterState] = useState(
    false
  );
  const [copiedQueries, setCopiedQueries] = useState([]);
  const [toogleHandleRequestSubmit, setToogleHandleRequestSubmit] = useState(
    false
  );
  const [isConsultingByTask, setIsConsultingByTask] = useState(false);

  /**
   * Handles the action to close the modal for filtering.
   * @param {boolean} isFilter - Indicates whether filtering is active or not.
   * @param {string} copiedSelectedOption - The copied selected option for filtering.
   * @param {object[]} copiedQueries - The copied queries for filtering.
   * @param {object[]} copiedQueriesTable - The copied queries for the table view.
   * @param {boolean} setSelectFormIdContext - State setter function for updating the context of the selected form ID.
   * @param {boolean} setOpenModalFilter - State setter function for opening/closing the filter modal.
   * @param {function} setQueries - State setter function for updating queries.
   * @param {function} setQueriesTable - State setter function for updating queries for table view.
   * @param {function} setSelectedOptions - State setter function for updating selected options.
   * @param {function} setStructureQueryByFilterTask - State setter function for updating the structure of queries based on filter task.
   * @returns {void}
   */
  const handleOnCloseModalFilter = () => {
    if (isFilter === false) {
      setSelectedOptionsWorkflow("");
      setSelectedOptionsStep("");
      setSelectedOptions("");
      setOpenModalFilter(false);
      setSelectFormIdContext(false);
      setStructureQueryByFilterTask({
        form_uuid: null,
        search_filter: [],
        workflow_uuid: null,
        step_uuid: null,
      });
    } else {
      setQueries(copiedQueries);
      setQueriesTable(copiedQueriesTable);
      setSelectedOptions(copiedSelectedOption);
      setSelectedOptionsWorkflow(copiedSelectedOptionWorkflow);
      setSelectedOptionsStep(copiedSelectedOptionStep);
      setStructureQueryByFilterTask({
        ...structureQueryByFilterTask,
        form_uuid: copiedSelectedOption.value,
        workflow_uuid: copiedSelectedOptionWorkflow.value,
        step_uuid: selectedOptionsStep.value
      });
      setOpenModalFilter(false);
    }
  };

  /**
   * Handles submitting the task filter.
   * Validates filter structure and displays error message if necessary.
   * Updates state and queries based on filter settings.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {Object} structureQueryByFilterTask - Object representing the structure of the query by filter task.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @param {boolean} isFilter - Flag indicating whether a filter is active.
   * @param {Object} queriesRec - Object representing the queries record.
   * @param {Object} queries - Object representing the queries.
   * @param {function} setHandleOnSubmitFilterState - State setter function to update handle on submit filter state.
   * @param {function} setOpenModalFilter - State setter function to update open modal filter.
   * @param {function} setToogleHandleRequestSubmit - State setter function to update toggle handle request submit.
   * @param {function} setStructureQueryByFilterTask - State setter function to update structure query by filter task.
   * @param {function} setIsConsultingByTask - State setter function to update consulting by task state.
   * @param {function} setSelectedFields - State setter function to update selected fields.
   * @returns {void || null} - Null if form structure is invalid, otherwise undefined.
   */
  const handleOnsubmitFilterTask = () => {
    if (isNullOrUndefined(structureQueryByFilterTask.form_uuid) === true &&
      isNullOrUndefined(structureQueryByFilterTask.workflow_uuid) === true
    ) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("taskFilter.label6"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
      return null;
    }
    setIsFilter(true);
    setPagination(initialPaginationTasks);
    setHandleOnSubmitFilterState(true);
    setOpenModalFilter(false);
    setToogleHandleRequestSubmit((prevState) => !prevState);
    if (isFilter === true) {
      setQueriesRec({
        ...queriesRec,
        search_filter: queries,
      });
    }
    setStructureQueryByFilterTask({
      ...structureQueryByFilterTask,
      search_filter: queries,
    });
    setIsConsultingByTask(true);
    setSelectedFields([]);
  };

  /**
   * Executes an effect when either the handleOnSubmitFilterState or pagination dependencies change.
   * If handleOnSubmitFilterState is true, it fetches tasks filtered by the provided structureQueryByFilterTask.
   * @param {function} callback - The function to execute when the dependencies change.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   * @param {boolean} handleOnSubmitFilterState - A flag indicating whether to apply the filter state.
   * @param {object} structureQueryByFilterTask - The query structure to filter tasks.
   * @param {number} pagination - The pagination indicator.
   * @returns {void}
   */
  useEffect(() => {
    if (handleOnSubmitFilterState === true) {
      getTasksByFilter(structureQueryByFilterTask);
    }
  }, [handleOnSubmitFilterState, pagination, toogleHandleRequestSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the structure of the query by filter task when 'queriesRec' changes.
   * This function checks if the 'queriesTable' array has a length greater than 0. If it does, it updates
   * the 'structureQueryByFilterTask' state by setting the 'search_filter' property to the value of
   * 'queriesRec.search_filter'.
   * @param {Array} queriesTable - An array containing queries.
   * @param {object} queriesRec - An object representing query records.
   * @param {object} structureQueryByFilterTask - The current structure of the query by filter task.
   * @param {function} setStructureQueryByFilterTask - State setter function for updating the structure of the query by filter task.
   * @returns {void}
   */
  useEffect(() => {
    if (queriesTable.length > 0) {
      setStructureQueryByFilterTask({
        ...structureQueryByFilterTask,
        search_filter: queriesRec.search_filter,
      });
    }
  }, [queriesRec]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the structure query by filter task
   * whenever selected options related to filtering change.
   * @param {function} callback - Callback function to execute when dependencies change.
   * @param {Array} dependencies - List of dependencies to monitor for changes.
   */
  useEffect(() => {
    setStructureQueryByFilterTask({
      ...structureQueryByFilterTask,
      form_uuid: selectedOptions.value,
      workflow_uuid: selectedOptionsWorkflow.value,
      step_uuid: selectedOptionsStep.value
    });
  }, [selectedOptionsWorkflow, selectedOptionsStep, selectedOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the copied selected options based on certain conditions.
   * @param {boolean} isFilter - Flag indicating whether filtering is active.
   * @param {Array} selectedOptions - Array of selected options.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {function} setCopiedSelectedOption - State setter function to update copied selected options.
   * @param {Array} queries - Array of queries.
   * @param {Array} queriesTable - Array of queries related to table.
   * @returns {void}
   */
  useEffect(() => {
    if (isConsultingByTask === true) {
      if (isNullOrUndefined(selectedOptions) === false) {
        setCopiedSelectedOption(JSON.parse(JSON.stringify(selectedOptions)));
      }
    }
  }, [selectedOptions, isConsultingByTask]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the copied selected options based on certain conditions.
   * @param {boolean} isFilter - Flag indicating whether filtering is active.
   * @param {Array} selectedOptions - Array of selected options.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {function} setCopiedSelectedOption - State setter function to update copied selected options.
   * @param {Array} queries - Array of queries.
   * @param {Array} queriesTable - Array of queries related to table.
   * @returns {void}
   */
  useEffect(() => {
    if (isConsultingByTask === true) {
      setCopiedSelectedOptionWorkflow(JSON.parse(JSON.stringify(selectedOptionsWorkflow)));
    }
  }, [selectedOptionsWorkflow, isConsultingByTask]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the copied selected options step based on certain conditions.
   * @param {boolean} isConsultingByTask - Flag indicating whether consulting by task is active.
   * @param {Object} selectedOptionsStep - Selected options for the step.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @param {function} setCopiedSelectedOptionStep - State setter function to update copied selected options for step.
   * @returns {void}
   */
  useEffect(() => {
    if (isConsultingByTask === true) {
      if (isNullOrUndefined(selectedOptionsStep) === false) {
        setCopiedSelectedOptionStep(JSON.parse(JSON.stringify(selectedOptionsStep)));
      } else {
        setCopiedSelectedOptionStep({});
      }
    }
  }, [selectedOptionsStep, selectedOptionsWorkflow, isConsultingByTask]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect to set the 'isConsultingByTask' state to false when the 'openModalFilter' dependency changes.
   * This effect is triggered when the 'openModalFilter' dependency changes.
   * @param {boolean} isConsultingByTask - The state indicating whether the component is in consulting mode by task.
   * @param {function} setIsConsultingByTask - The state setter function for updating the consulting mode by task state.
   * @param {boolean} openModalFilter - The dependency indicating whether the modal filter is open or closed.
   * @returns {void}
   */
  useEffect(() => {
    setIsConsultingByTask(false);
  }, [openModalFilter]);

  /**
   * Executes a side effect to update copied queries when consulting by task.
   * @param {boolean} isConsultingByTask - A boolean flag indicating whether the consulting is by task or not.
   * @param {Array} queries - An array of queries to be copied.
   * @returns {void}
   */
  useEffect(() => {
    if (isConsultingByTask === true) {
      setCopiedQueries(JSON.parse(JSON.stringify(queries)));
    }
  }, [isConsultingByTask, queries]);

  /**
   * useEffect hook that updates the copied queries table based on certain conditions.
   * This hook is triggered whenever either 'isConsultingByTask' or 'queriesTable' changes.
   * If 'isConsultingByTask' is true, the 'queriesTable' is deep copied and assigned to 'copiedQueriesTable'.
   * @param {boolean} isConsultingByTask - A boolean flag indicating whether the consultation is based on a task.
   *   If true, the 'queriesTable' will be copied.
   * @param {Array} queriesTable - An array containing the queries table data.
   *   this table is deep copied and assigned to 'copiedQueriesTable' if 'isConsultingByTask' is true.
   * @returns {void}
   */
  useEffect(() => {
    if (isConsultingByTask === true) {
      setCopiedQueriesTable(JSON.parse(JSON.stringify(queriesTable)));
    }
  }, [isConsultingByTask, queriesTable]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Modal isOpen={openModalFilter} className="modal-custom-width">
          <ModalHeader>
            <h5 className="text-info font-weight-bold  font-weight-normal">
              {i18n.t("taskFilter.label9")}
            </h5>
          </ModalHeader>

          <ModalBody>
            <FormsSelectByFiltersTasks
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
            />

          </ModalBody>
          <ModalFooter className="d-block text-right pr-0">
            <Button
              size="lg"
              onClick={handleOnCloseModalFilter}
              className="col-mt-3 mr-3"
              color="gray"
            >
              {i18n.t("createusers.createButton2")}
            </Button>
            <Button
              onClick={handleOnsubmitFilterTask}
              size="lg"
              className="col-mt-3 mr-3"
              color="cyan"
            >
              {i18n.t("taskFilter.label10")}
            </Button>
          </ModalFooter>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ModalFilterTasks.propTypes = {
  openModalFilterTask: PropTypes.func,
  setOpenModalFilter: PropTypes.func,
};

export default ModalFilterTasks;