import React from "react";
import TaskDetail from "./TaskDetail";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <WorkflowContextProvider>
        <TaskDetail />
      </WorkflowContextProvider>
    </RecordDetailContextProvider>
  );
};
