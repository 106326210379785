import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
  CardBody,
} from "reactstrap";
import { handleTypes } from "utils/classificationTypes";
import { useHistory } from "react-router-dom";
import { useTrdContext } from "contextAPI/TrdContext";
import { trimExtraSpaces } from "utils/trimSpaces";
import { enumLevelTrd, enumsExtraLevelTrd, enumsTrdForm } from "utils/enums";
import { regexTrdForm } from "utils/regexExpressions";
import Loader from "react-loaders";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import useClassification from "hooks/useClassification";
import SelectLevel from "components/molecules/SelectLevel";
import CreateExpedientForm from "components/organism/CreateExpedientForm";
import CreateSubDivisionForm from "components/organism/CreateSubDivisionForm";
import SelectLevelDocumetnalUnit from "components/molecules/SelectLevelDocumetnalUnit";
import SelectActionsDirectory from "components/molecules/SelectActionsDirectory";
import EditSectionForm from "components/organism/EditSectionForm";
import EditSerieForm from "components/organism/EditSerieForm";
import EditSubSectionForm from "components/organism/EditSubSectionForm";
import EditSubSerieForm from "components/organism/EditSubSerieForm";
import EditBackground from "components/organism/EditBackground";
import CreateSectionForm from "components/organism/CreateSectionForm";
import swal from "sweetalert";

const TrdForm = () => {
  const [title, setTitle] = useState({});
  const history = useHistory();
  const {
    classificationRequest,
    setIsLoading,
    isLoading,
  } = useClassification();

  const {
    levelFolder,
    trdForm,
    setTrdForm,
    setLevelFolder,
    showFolderCondition,
    setShowFolderCondition,
    setShowRightPanel,
    showFormConditional,
    setShowFormConditional,
    showCreateExpedientForm,
    setShowCreateExpedientForm,
    selectLevel,
    setSelectLevel,
    isCreateExpedient,
    showCreateSubDivisonForm,
    setShowCreateSubDivisonForm,
    showCreateDirectoryForm,
    setShowCreateDirectoryForm,
    currentLevelSelected,
    foldersId,
    parentData,
    createDirectoryForm,
    setCreateDirectoryForm,
    option,
    setOption,
    optionEdit,
    setOptionEdit,
    structureTrdExpedient,
    editSubDivisionForm,
    setEditSubDivisionForm,
    showEditSubDivisonForm,
    setShowEditSubDivisonForm,
    getTreeByDomainCallback,
    optionDisabled,
    setOptionDisabled,
    showEditDirectoryForm,
    setShowEditDirectoryForm,
    editDirectory,
    setEditDirectory,
    editDirectoryForm,
    setEditDirectoryForm,
    optionDir,
    setOptionDir,
    showEditSectionForm,
    setShowEditSectionForm,
    showEditSubSectionForm,
    setShowEditSubSectionForm,
    showEditSubSerieForm,
    setShowEditSubSerieForm,
    showEditSerieForm,
    setShowEditSerieForm,
    hasFiles,
    listExpedients,
    showOrdenation,
    isStructure,
    showEditBackground,
    setShowEditBackground,
    showCreateSection,
    setShowCreateSection,
    structureTrdSeriesSubseries,
  } = useTrdContext();

  let loaderSpinner = null;
  let showRenderButtonSetTrd = null;
  let renderSelectLevelForm = null;
  let renderCreateExpedientForm = null;
  let renderCreateSubdivisionForm = null;
  let renderEditBackground = null;
  let renderCreateSectionForm = null;
  let renderEditSerieForm = null;
  let renderEditSectionForm = null;
  let renderEditSubSectionForm = null;
  let renderSerieForm = null;
  let renderDirectoryForm = null;
  let renderCreateDirectoryForm = null;
  let renderActionsDirectory = null;
  let renderEditDirectoryForm = null;
  let renderShowOrdenation = null;
  let renderShowConditionalForm = null;

  /**
   * Conditionally renders the `SelectLevelDocumetnalUnit` component based on the value of `showCreateDirectoryForm`.
   * This code:
   * - Checks if `showCreateDirectoryForm` is true.
   * - If true, it sets `renderCreateDirectoryForm` to the `SelectLevelDocumetnalUnit` component with various props.
   * @param {boolean} showCreateDirectoryForm - Flag indicating whether to show the create directory form.
   * @param {boolean} isStructure - Determines if the structure is active.
   * @param {string[]} foldersId - Array of folder IDs.
   * @param {Object} createDirectoryForm - The state object for the create directory form.
   * @param {Function} setCreateDirectoryForm - Function to update the create directory form state.
   * @param {Function} setLevelFolder - Function to set the folder level.
   * @param {Function} setShowCreateDirectoryForm - Function to toggle the visibility of the create directory form.
   * @param {Function} setSelectLevel - Function to set the selected level.
   * @param {string} selectLevel - Current selected level.
   * @param {string} option - Current option value.
   * @param {Function} setOption - Function to set the option value.
   * @param {string} optionEdit - Current option value for editing.
   * @param {Function} setOptionEdit - Function to set the option value for editing.
   * @param {Object} structureTrdExpedient - Structure for TRD expedient.
   * @param {Object} editSubDivisionForm - State object for editing the subdivision form.
   * @param {Function} setEditSubDivisionForm - Function to update the edit subdivision form state.
   * @param {boolean} showEditSubDivisonForm - Flag indicating whether to show the edit subdivision form.
   * @param {Function} setShowEditSubDivisonForm - Function to toggle the visibility of the edit subdivision form.
   * @param {Function} getTreeByDomainCallback - Callback function to get the tree by domain.
   * @param {boolean} optionDisabled - Flag indicating whether the option is disabled.
   * @param {Function} setOptionDisabled - Function to set the option disabled state.
   * @param {boolean} hasFiles - Flag indicating whether there are files.
   * @param {Object} structureTrdSeriesSubseries - Structure for TRD series and subseries.
   * @returns {JSX.Element | null} The rendered `SelectLevelDocumetnalUnit` component if `showCreateDirectoryForm` is true, otherwise null.
   */
  if (showCreateDirectoryForm === true) {
    renderCreateDirectoryForm = (
      <SelectLevelDocumetnalUnit
        isStructure={isStructure}
        foldersId={foldersId}
        createDirectoryForm={createDirectoryForm}
        setCreateDirectoryForm={setCreateDirectoryForm}
        showCreateDirectoryForm={showCreateDirectoryForm}
        setLevelFolder={setLevelFolder}
        setShowCreateDirectoryForm={setShowCreateDirectoryForm}
        setSelectLevel={setSelectLevel}
        selectLevel={selectLevel}
        option={option}
        setOption={setOption}
        optionEdit={optionEdit}
        setOptionEdit={setOptionEdit}
        structureTrdExpedient={structureTrdExpedient}
        editSubDivisionForm={editSubDivisionForm}
        setEditSubDivisionForm={setEditSubDivisionForm}
        showEditSubDivisonForm={showEditSubDivisonForm}
        setShowEditSubDivisonForm={setShowEditSubDivisonForm}
        getTreeByDomainCallback={getTreeByDomainCallback}
        optionDisabled={optionDisabled}
        setOptionDisabled={setOptionDisabled}
        hasFiles={hasFiles}
        structureTrdSeriesSubseries={structureTrdSeriesSubseries}
      />
    )
  };

  /**
   * Renders the Show Ordenation component if showOrdenation is true.
   * @param {boolean} showOrdenation - A flag to determine whether to show the ordenation.
   * @returns {JSX.Element|null} - The rendered component or null if showOrdenation is false.
   */
  if (showOrdenation === true) {
    renderShowOrdenation = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <div>
            <div className="loader-wrapper d-flex justify-content-center align-items-center">
              <Loader color="secondary" type="ball-pulse-rise" />
            </div>
          </div>
        </CardBody>
      </CSSTransitionGroup>
    )
  };

  /**
   * Renders the Edit Directory Form component if showEditDirectoryForm is true.
   * @param {boolean} showEditDirectoryForm - A flag to determine whether to show the edit directory form.
   * @param {boolean} isStructure - Indicates if the structure is enabled.
   * @param {object} editDirectoryForm - The current state of the edit directory form.
   * @param {Function} setEditDirectoryForm - Function to set the state of the edit directory form.
   * @param {number} selectLevel - The selected level.
   * @param {object} editDirectory - The current directory being edited.
   * @param {Function} setEditDirectory - Function to set the directory being edited.
   * @param {Array<number>} foldersId - Array of folder IDs.
   * @param {boolean} optionDisabled - Indicates if the option is disabled.
   * @param {Function} setOptionDisabled - Function to set the option disabled state.
   * @param {object} structureTrdExpedient - The structure TRD expedient.
   * @param {string} optionDir - The directory option.
   * @param {Function} setOptionDir - Function to set the directory option.
   * @param {Function} setSelectLevel - Function to set the selected level.
   * @param {object} createDirectoryForm - The current state of the create directory form.
   * @param {Function} setCreateDirectoryForm - Function to set the state of the create directory form.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {Function} setShowCreateDirectoryForm - Function to show or hide the create directory form.
   * @param {Function} setOption - Function to set an option.
   * @param {Function} setOptionEdit - Function to set an option for editing.
   * @param {Function} setShowCreateSubDivisonForm - Function to show or hide the create subdivision form.
   * @param {Function} setShowEditDirectoryForm - Function to show or hide the edit directory form.
   * @param {object} structureTrdSeriesSubseries - The structure TRD series subseries.
   * @returns {JSX.Element|null} - The rendered component or null if showEditDirectoryForm is false.
   */
  if (showEditDirectoryForm === true) {
    renderActionsDirectory = (
      <SelectActionsDirectory
        isStructure={isStructure}
        editDirectoryForm={editDirectoryForm}
        setEditDirectoryForm={setEditDirectoryForm}
        selectLevel={selectLevel}
        editDirectory={editDirectory}
        setEditDirectory={setEditDirectory}
        foldersId={foldersId}
        optionDisabled={optionDisabled}
        setOptionDisabled={setOptionDisabled}
        structureTrdExpedient={structureTrdExpedient}
        optionDir={optionDir}
        setOptionDir={setOptionDir}
        setSelectLevel={setSelectLevel}
        createDirectoryForm={createDirectoryForm}
        setCreateDirectoryForm={setCreateDirectoryForm}
        setLevelFolder={setLevelFolder}
        setShowCreateDirectoryForm={setShowCreateDirectoryForm}
        setOption={setOption}
        setOptionEdit={setOptionEdit}
        setShowCreateSubDivisonForm={setShowCreateSubDivisonForm}
        setShowEditDirectoryForm={setShowEditDirectoryForm}
        structureTrdSeriesSubseries={structureTrdSeriesSubseries}
      />
    )
  };

  /**
   * Renders the Edit Sub Serie Form component if showEditSubSerieForm is true and levelFolder is 12.
   * @param {boolean} showEditSubSerieForm - A flag to determine whether to show the edit sub serie form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showEditSubSerieForm === true && levelFolder === enumsExtraLevelTrd.SERIE_FORM) {
    renderSerieForm = (
      <EditSubSerieForm
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  };

  /**
   * Renders the Edit Sub Section Form component if showEditSubSectionForm is true and levelFolder is 10.
   * @param {boolean} showEditSubSectionForm - A flag to determine whether to show the edit sub section form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showEditSubSectionForm === true && levelFolder === enumsExtraLevelTrd.SUB_SECTION_FORM) {
    renderEditSubSectionForm = (
      <EditSubSectionForm
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  };

  /**
   * Renders the Edit Section Form component if showEditSectionForm is true and levelFolder is 9.
   * @param {boolean} showEditSectionForm - A flag to determine whether to show the edit section form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showEditSectionForm && levelFolder === enumsExtraLevelTrd.SECTION_FORM) {
    renderEditSectionForm = (
      <EditSectionForm
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  };

  /**
   * Renders the Edit Serie Form component if showEditSerieForm is true and levelFolder is 11.
   * @param {boolean} showEditSerieForm - A flag to determine whether to show the edit serie form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showEditSerieForm && levelFolder === enumsExtraLevelTrd.EDIT_SERIE_FORM) {
    renderEditSerieForm = (
      <EditSerieForm
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  };

  /**
   * Renders the Create Section Form component if showCreateSection is true and levelFolder is 15.
   * @param {boolean} showCreateSection - A flag to determine whether to show the create section form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showCreateSection === true && levelFolder === enumsExtraLevelTrd.CREATE_SECTION_FORM) {
    renderCreateSectionForm = (
      <CreateSectionForm
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  };

  /**
   * Renders the Edit Background component if showEditBackground is true and levelFolder is 14.
   * @param {boolean} showEditBackground - A flag to determine whether to show the edit background form.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {Function} setShowFolderCondition - Function to set the condition to show the folder.
   * @returns {JSX.Element|null} - The rendered component or null if the conditions are not met.
   */
  if (showEditBackground === true && levelFolder === enumsExtraLevelTrd.EDIT_BACKGROUND) {
    renderEditBackground = (
      <EditBackground
        setLevelFolder={setLevelFolder}
        trdForm={trdForm}
        setTrdForm={setTrdForm}
        currentLevelSelected={currentLevelSelected}
        setShowFolderCondition={setShowFolderCondition}
      />
    )
  }

  /**
   * Renders the Create Subdivision Form component if showCreateSubDivisonForm is true.
   * @param {boolean} showCreateSubDivisonForm - A flag to determine whether to show the create subdivision form.
   * @returns {JSX.Element|null} - The rendered component or null if the condition is not met.
   */
  if (showCreateSubDivisonForm === true) {
    renderCreateSubdivisionForm = (
      <CreateSubDivisionForm />
    )
  };

  /**
   * Renders the Create Expedient Form component if showCreateExpedientForm is true.
   * @param {boolean} showCreateExpedientForm - A flag to determine whether to show the create expedient form.
   * @returns {JSX.Element|null} - The rendered component or null if the condition is not met.
   */
  if (showCreateExpedientForm === true) {
    renderCreateExpedientForm = (
      <CreateExpedientForm />
    )
  };

  /**
   * Renders the Edit Directory Form component if showFolderCondition is true.
   * @param {boolean} showFolderCondition - A flag to determine whether to show the edit directory form.
   * @param {Function} setLevelFolder - Function to set the level folder.
   * @param {boolean} isCreateExpedient - Indicates if creating an expedient.
   * @param {Function} setShowFormConditional - Function to show or hide the conditional form.
   * @param {Function} setShowCreateExpedientForm - Function to show or hide the create expedient form.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {Function} setShowFolderCondition - Function to show or hide the folder condition.
   * @param {Function} setSelectLevel - Function to set the selected level.
   * @param {number} selectLevel - The selected level.
   * @param {object} trdForm - The TRD form data.
   * @param {boolean} showEditSectionForm - A flag to determine whether to show the edit section form.
   * @param {Function} setShowEditSectionForm - Function to show or hide the edit section form.
   * @param {Function} getTreeByDomainCallback - Callback function to get the tree by domain.
   * @param {Array<number>} foldersId - Array of folder IDs.
   * @returns {JSX.Element|null} - The rendered component or null if the condition is not met.
   */
  if (showFolderCondition === true) {
    renderEditDirectoryForm = (
      <SelectLevel
        setLevelFolder={setLevelFolder}
        isCreateExpedient={isCreateExpedient}
        setShowFormConditional={setShowFormConditional}
        setShowCreateExpedientForm={setShowCreateExpedientForm}
        setTrdForm={setTrdForm}
        setShowFolderCondition={setShowFolderCondition}
        setSelectLevel={setSelectLevel}
        selectLevel={selectLevel}
        trdForm={trdForm}
        showEditSectionForm={showEditSectionForm}
        setShowEditSectionForm={setShowEditSectionForm}
        getTreeByDomainCallback={getTreeByDomainCallback}
        foldersId={foldersId}
      />
    )
  };

  /**
   * Renders a button to set TRD form and reset state if levelFolder is not 0.
   * @param {number} levelFolder - The level of the folder.
   * @param {object} trdForm - The TRD form data.
   * @param {Function} setTrdForm - Function to set the TRD form data.
   * @param {Function} setSelectLevel - Function to set the selected level.
   * @param {Function} setShowFolderCondition - Function to show or hide the folder condition.
   * @param {Function} setShowFormConditional - Function to show or hide the conditional form.
   * @param {number} currentLevelSelected - The current selected level.
   * @param {object} i18n - The internationalization object for translations.
   * @returns {JSX.Element|null} - The rendered button or null if the condition is not met.
   */
  if (levelFolder !== enumLevelTrd.BACKGROUND) {
    showRenderButtonSetTrd = (
      <Button
        size="lg"
        className="col-mt-3 mr-3"
        color="gray"
        onClick={(e) => {
          setTrdForm({
            ...trdForm,
            level: currentLevelSelected,
          });
          setSelectLevel(enumLevelTrd.BACKGROUND);
          setShowFolderCondition(true);
          setShowFormConditional(false);
        }}
      >
        {i18n.t("createusers.createButton2")}
      </Button>
    )
  };

  /**
   * Renders a loading spinner if isLoading is true.
   * @param {boolean} isLoading - A flag to determine whether to show the loading spinner.
   * @returns {JSX.Element|null} - The rendered spinner or null if the condition is not met.
   */
  if (isLoading === true) {
    loaderSpinner = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Displays an alert using SweetAlert with a predefined message and button.
   * @param {object} i18n - The internationalization object for translations.
   * @returns {void}
   */
  const showAlertFillData = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trd.inputTittle8"),
      icon: "info",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Handles the form submission process, including validation and state updates.
   * @param {Event} eventSubmitForm - The event object for the form submission.
   * @param {Array<string>} errors - An array of validation error messages.
   * @param {Function} setIsLoading - Function to set the loading state.
   * @param {Object} trdForm - The form data object.
   * @param {string} trdForm.code - The code property of the form data.
   * @param {Function} classificationRequest - Function to handle the classification request.
   * @param {number} levelFolder - The level of the folder.
   * @param {Function} showAlertFillData - Function to show an alert with a predefined message.
   * @param {object} i18n - The internationalization object for translations.
   * @returns {void}
   */
  const handleOnSubmit = (eventSubmitForm, errors) => {
    setIsLoading(true);
    if (trdForm.code === i18n.t("trd.inputTittle7")) {
      eventSubmitForm.preventDefault();
      showAlertFillData();
    } else if (errors.length === 0) {
      classificationRequest(levelFolder, trdForm);
    }
    setIsLoading(false);
    return;
  };

  /**
   * Handles the blur event for TRD form inputs, trimming the value and updating the form state.
   * @param {Event} eventTrdInputForm - The event object for the input blur event.
   * @param {Function} setTrdForm - Function to update the TRD form state.
   * @param {Object} trdForm - The current TRD form data.
   * @param {string} trdForm.[key] - The dynamic key of the form data being updated.
   * @returns {void}
   */

  const handleOnBlurTrdForm = (eventCreateTrdLevel) => {
    const { value, name } = eventCreateTrdLevel.target;
		const trimmedValue = trimExtraSpaces(value);
    setTrdForm({
      ...trdForm,
      [name]: trimmedValue,
    });
  };

  /**
   * Handles changes to form inputs for the TRD form and updates the form state.
   * @param {Event} eventChangeInputTrdForm - The event object for the input change.
   * @param {Function} setTrdForm - Function to update the TRD form state.
   * @param {Object} trdForm - The current TRD form data.
   * @param {string} trdForm.[key] - The dynamic key of the form data being updated.
   * @returns {void}
   */
  const handleOnChangeTrdForm = (eventChangeInputTrdForm) => {
    setTrdForm({
      ...trdForm,
      [eventChangeInputTrdForm.target.name]: eventChangeInputTrdForm.target.value,
    });
  };

  /**
   * Handles key press events for the TRD form, preventing the default action if the "Enter" key is pressed.
   * @param {KeyboardEvent} eventKeyPressTrdForm - The event object for the key press event.
   * @returns {void}
   */
  const handleOnKeyPressTrdForm = (eventKeyPressTrdForm) => {
    if (eventKeyPressTrdForm.key === "Enter") {
      eventKeyPressTrdForm.preventDefault();
    }
  };

  /**
   * Handles the side effects based on the current `levelFolder` and `titleSwitch` state.
   * 
   * This effect performs various operations including:
   * - Redirecting the user to a different route based on certain conditions.
   * - Toggling visibility of different forms and panels.
   * - Updating the title and form data based on the `titleSwitch` object.
   * Dependencies:
   * - `levelFolder`: The current level of the folder that determines the visibility of forms and redirects.
   * @param {Object} titleSwitch - The object returned by `handleTypes` function which determines which actions to take.
   * @param {Object} parentData - The parent data object containing ID information for redirection.
   * @param {Array} listExpedients - The list of expedients to determine if redirection is necessary.
   * @param {Function} history.push - Function to redirect the user to a different route.
   * @param {Function} setShowEditBackground - Function to show or hide the edit background form.
   * @param {Function} setShowFormConditional - Function to toggle the visibility of the conditional form.
   * @param {Function} setShowCreateDirectoryForm - Function to show or hide the create directory form.
   * @param {Function} setShowEditDirectoryForm - Function to show or hide the edit directory form.
   * @param {Function} setShowCreateExpedientForm - Function to show or hide the create expedient form.
   * @param {Function} setShowEditSectionForm - Function to show or hide the edit section form.
   * @param {Function} setShowCreateSubDivisonForm - Function to show or hide the create sub-division form.
   * @param {Function} setTrdForm - Function to update the TRD form state.
   * @param {Function} setShowRightPanel - Function to toggle the visibility of the right panel.
   * @param {Object} trdForm - The TRD form data object.
   * @param {Function} setTitle - Function to set the title based on `titleSwitch`.
   * @param {number} foldersId.backgroundId - The ID of the background folder.
   * @returns {void}
   */
  useEffect(() => {
    const titleSwitch = handleTypes(levelFolder);
    if (titleSwitch.createExpedient && listExpedients.length !== 0) {
      history.push(`/trd/expedient/${parentData.id}/${foldersId.backgroundId}`);
    } else if (titleSwitch.isEditBackground) {
      setShowEditBackground(true);
      setShowFormConditional(false);
      setShowCreateDirectoryForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateExpedientForm(false);
      setShowEditSectionForm(false);
    } else if (titleSwitch.isCreateSection) {
      setShowCreateSection(true);
      setShowFormConditional(false);
      setShowCreateDirectoryForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateExpedientForm(false);
      setShowEditSectionForm(false)
    } else if (titleSwitch.isSubDivison) {
      setShowCreateSubDivisonForm(true);
      setShowFormConditional(false);
      setShowCreateDirectoryForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateExpedientForm(false);
      setShowEditSectionForm(false);
      return;
    } else if (titleSwitch.isExpedient) {
      setTrdForm({ ...trdForm, level: 4 });
      setShowCreateExpedientForm(false);
      setShowCreateDirectoryForm(false);
      setShowEditDirectoryForm(false);
    } else if (titleSwitch.isDirectory) {
      setShowCreateDirectoryForm(true);
      setShowEditDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
      setShowEditSectionForm(false);
      return;
    } else if (titleSwitch.isEditDirectory) {
      setShowEditDirectoryForm(true);
      setShowCreateDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
      setShowEditSectionForm(false);
      return;
    } else if (titleSwitch.isEditSection) {
      setShowEditSectionForm(true);
      setShowEditDirectoryForm(false);
      setShowCreateDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
      return;
    } else if (titleSwitch.isEditSerie) {
      setShowEditSerieForm(true);
      setShowEditSubSectionForm(false);
      setShowEditSectionForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
      return;
    } else if (titleSwitch.isEditSubSection) {
      setShowEditSubSectionForm(true);
      setShowEditSectionForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
    } else if (titleSwitch.isEditSubSerie) {
      setTrdForm({ ...trdForm });
      setShowEditSubSerieForm(true);
      setShowEditSerieForm(false);
      setShowEditSubSectionForm(false);
      setShowEditSectionForm(false);
      setShowEditDirectoryForm(false);
      setShowCreateDirectoryForm(false);
      setShowCreateSubDivisonForm(false);
      setShowFormConditional(false);
    }

    if (titleSwitch.create !== "") {
      setTitle(titleSwitch);
    } else {
      setShowRightPanel(false);
    }
  }, [levelFolder]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a conditional form with various inputs and buttons based on the visibility state of `showFormConditional` and `showOrdenation`.
   * 
   * The form includes fields for entering a code and name, with validation rules applied to each field. It also includes a submit button and a render button set based on the `showRenderButtonSetTrd` variable.
   * 
   * The form uses `CSSTransitionGroup` for animation effects and includes:
   * - **Code Field**: A required text input field for the code with validation for pattern, length, and required status.
   * - **Name Field**: A required text input field for the name with similar validation rules.
   * - **Submit Button**: A button to submit the form, which shows a spinner while loading.
   * 
   * Conditional rendering logic:
   * - The form is only rendered if `showFormConditional` is `true` and `showOrdenation` is `false`.
   * 
   * @type {JSX.Element | null}
   */
  if (showFormConditional === true && showOrdenation === false) {
    renderShowConditionalForm = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <AvForm onSubmit={handleOnSubmit}>
            <AvGroup>
              <CardTitle>{title.create}</CardTitle>
              <ReactTooltip
                id="code"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip2")}
              </ReactTooltip>
              <Label for="code" className="is-required">
                {i18n.t("trd.fromLabel1")}
              </Label>
              <AvField
                id="code"
                name="code"
                type="text"
                onChange={handleOnChangeTrdForm}
                onKeyPress={handleOnKeyPressTrdForm}
                onBlur={handleOnBlurTrdForm}
                validate={{
                  pattern: {
                    value: regexTrdForm,
                    errorMessage: `${i18n.t("trd.formCodeValidation")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback1")}`,
                  },
                  minLength: {
                    value: enumsTrdForm.MIN_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumsTrdForm.MAX_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="code"
                autoFocus={true}
                autoComplete="off"
                value={trdForm.code}
              />
            </AvGroup>

            <AvGroup>
              <ReactTooltip
                id="name_element"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip3")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {title.name_element}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeTrdForm}
                onKeyPress={handleOnKeyPressTrdForm}
                onBlur={handleOnBlurTrdForm}
                placeholder={i18n.t("trd.inputTittle6")}
                validate={{
                  pattern: {
                    value: regexTrdForm,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: enumsTrdForm.MIN_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumsTrdForm.MAX_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="name_element"
                autoComplete="off"
                required
                value={trdForm.name}
              />
            </AvGroup>

            <CardFooter className="d-block text-right">
              {showRenderButtonSetTrd}
              <Button
                type="submit"
                size="lg"
                className="col-mt-3"
                disabled={isLoading}
                color="cyan"
              >
                {loaderSpinner}
                {i18n.t("createusers.createButton")}
              </Button>
            </CardFooter>
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    )
  };

  return (
    <Fragment>
      {renderSelectLevelForm}

      {renderCreateExpedientForm}

      {renderCreateSubdivisionForm}

      {renderEditBackground}

      {renderCreateSectionForm}

      {renderEditSerieForm}

      {renderEditSectionForm}

      {renderEditSubSectionForm}

      {renderSerieForm}

      {renderDirectoryForm}

      {renderActionsDirectory}

      {renderCreateDirectoryForm}

      {renderEditDirectoryForm}

      {renderShowOrdenation}

      {renderShowConditionalForm}

    </Fragment>
  );
};
export default TrdForm;