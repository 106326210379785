import React, { useState, useCallback, useEffect, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody, Col, Row } from "reactstrap";
import { loaderElement } from "utils/loaderElement";
import { initialPaginationStandart } from 'utils/initialPaginationsConfig';
import { showAlertNoExportDataRecord, showAlertNoExportDataRecords, showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import useForm from "hooks/useForm";
import ExportDataRecordTable from "../ExportDataRecordTable";
import SearchExportDataRecord from "components/atoms/SearchExportDataRecors";
import FilterExportRecords from "components/atoms/FilterExportRecords";

const ExportDataRecordList = () => {
  const { getExportRecords } = useForm();
  const [paginationExportRecordList, setPaginationExportRecordList] = useState(initialPaginationStandart);
  const [isLoadingExportRecordList, setIsLoadingExportRecordList] = useState(false);
  const [searchRecorExport, setSearchRecordExport] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [errorCharacters, setErrorCharacters] = useState("");
  const [listExportedDataRecords, setListExportedDataRecords] = useState([]);
  const [totalPagesRecordExport, setTotalPagesRecordExport] = useState(0);

  /**
   * Fetches exported records based on the current pagination, search query, and filters.
   * Manages the loading state, processes the response, and handles errors.
   * @function getExportRecordsSearch
   * @returns {void}
   */
  const getExportRecordsSearch = useCallback(() => {
    setIsLoadingExportRecordList(true);
    const { page, per_page } = paginationExportRecordList;
    getExportRecords(page, statusFilter, per_page, searchRecorExport)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setListExportedDataRecords(response.data.items);
          setTotalPagesRecordExport(response.data.pages);
          if (response.data.items.length === 0) {
            if(statusFilter === "" && searchRecorExport === "") {
              showAlertNoExportDataRecord();
            } else {
              showAlertNoExportDataRecords();
            }
          } 
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingExportRecordList(false);
      });
  }, [paginationExportRecordList, statusFilter]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * useEffect hook to fetch workflow data based on a search query.
   * This effect runs whenever the `getWorkFlowBySearch` dependency changes.
   * @param {Function} getWorkFlowBySearch - A function to fetch workflow data based on the current search query.
   * @returns {void}
   */
  useEffect(() => {
    getExportRecordsSearch();
  }, [getExportRecordsSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingExportRecordList)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card">
          <CardBody className="pb-2">
            <Row>
              <Col lg="12" md="12" sm="12" className="button-container mt-2 mt-md-0 mb-2">
                <Row className="row justify-content-end">
                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <SearchExportDataRecord
                      searchData={searchRecorExport}
                      setSearchData={setSearchRecordExport}
                      paginationRecords={paginationExportRecordList}
                      setPaginationRecords={setPaginationExportRecordList}
                      errorCharacters={errorCharacters}
                      setErrorCharacters={setErrorCharacters}
                      isRecordListExpedient={false}
                      isExportDataRecord={true}
                    />
                  </Col>

                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <FilterExportRecords
                      setStatus={setStatusFilter}
                      pagination={paginationExportRecordList}
                      setPagination={setPaginationExportRecordList}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <ExportDataRecordTable
              listExportedDataRecords={listExportedDataRecords}
              isLoadingExportRecordList={isLoadingExportRecordList}
              paginationExportRecordList={paginationExportRecordList}
              setPaginationExportRecordList={setPaginationExportRecordList}
              totalPagesRecordExport={totalPagesRecordExport}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default ExportDataRecordList;