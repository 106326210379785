import React, { useEffect, Fragment } from "react";
import { AvGroup, AvInput } from "availity-reactstrap-validation";
import { Col, CustomInput } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import i18n from "locales/i18n";

const Dates = () => {
  const { fieldForm, handleOnChange } = useFieldsRecordContext();

  /**
   * useEffect hook to set default values for the fieldForm object. 
   * This hook runs once when the component mounts and sets the default values 
   * for the `edit_manually` and `show_pass_dates` fields in the `fieldForm` object.
   * @param {Object} fieldForm - The form fields object to be updated.
   */
  useEffect(() => {
    fieldForm["edit_manually"] = false;
    fieldForm["show_pass_dates"] = false;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <AvGroup row>
        <Col sm={12}>
          <AvInput
            id="edit_manually"
            name="edit_manually"
            type="checkbox"
            onChange={handleOnChange}
            tag={CustomInput}
            required
            label={i18n.t("form.field41")}
            errorMessage={i18n.t("form.fieldFeedback3")}
            value={fieldForm.edit_manually}
          />
        </Col>
      </AvGroup>
      <AvGroup row>
        <Col sm={12}>
          <AvInput
            onChange={handleOnChange}
            tag={CustomInput}
            type="checkbox"
            name="show_pass_dates"
            id="show_pass_dates"
            required
            label={i18n.t("form.field42")}
            errorMessage={i18n.t("form.fieldFeedback3")}
            value={fieldForm.show_pass_dates}
          />
        </Col>
      </AvGroup>
    </Fragment>
  );
};

export default Dates;
