import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Card, CardHeader, Label, Alert } from "reactstrap";
import { regexAcceptPrimaryCharacters } from "utils/regexExpressions";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { useListContext } from "contextAPI/ListsContext";
import { isNullOrUndefined } from "utils/validations";
import { loaderElement } from "utils/loaderElement";
import i18n from "locales/i18n";

const FormCommentProcessBatch = (props) => {
  const { currentProcess } = props;
  const [copiedCheckedUploadFile, setCopiedCheckedUploadFile] = useState(null);
  const { setCanNext } = useListContext();
  const {
    isLoadingComplyProcessBatch,
    commentCreateProcessBatch,
    setCommentCreateProcessBatch,
    checkedOmitedUploadFile,
    setCheckedUploadFile,
    checkedFilingForm,
    setCheckedFilingForm,
    showFormFillingProcess,
    setUploadFormWorkflow,
    setUploadFormStartDate,
  } = useWorkflowContext();
  let MINIMUM_COMMENT_ALLOWED = 4;
  let MAXIMUM_COMMENT_ALLOWED = 1000;
  let checkFilingForm = null;

  /**
   * Generates a checkbox element for a form filling process if the condition to show the form filling process is true.
   * @param {boolean} showFormFillingProcess - Indicates whether the form filling process should be displayed.
   * @param {string} currentProcess - The name or identifier of the current process.
   * @param {boolean} checkedFilingForm - Indicates whether the form filling checkbox is checked.
   * @param {function} setCheckedFilingForm - State setter function for updating the status of the form filling checkbox.
   * @returns {JSX.Element|null} - Returns the JSX element representing the checkbox for the form filling process if showFormFillingProcess is true; otherwise, returns null.
   */
  if (showFormFillingProcess === true) {
    checkFilingForm = (
      <div className="mb-4">
        <div className="font-weight-normal">
          {i18n.t("form.fieldlist.label")}{" "}
          <span className="font-weight-bold">{currentProcess}</span>
        </div>
        <div className="d-flex align-items-center pb-0 mb-4">
          <input
            className="mr-2"
            type="checkbox"
            checked={checkedFilingForm}
            onChange={() => {
              setCheckedFilingForm(!checkedFilingForm);
            }}
          />
          {i18n.t("form.fieldlist.label1")}
        </div>
      </div>
    );
  }

  /**
   * Handles the change of the `checkedOmitedUploadFile` state when the checkbox is clicked.
   * @returns {void}
   */
  const handleCheckedUpload = () => {
    setCheckedUploadFile(!checkedOmitedUploadFile);
  };

  /**
   * Handles the change event for updating the comment in the Create Process Batch form.
   * @param {object} eventChangeCommentTask - The change event object.
   * @param {string} eventChangeCommentTask.target.value - The new comment value.
   * @returns {void}
   */
  const handleOnChangeCommentProcess = (eventChangeCommentTask) => {
    setCommentCreateProcessBatch({
      comment: eventChangeCommentTask.target.value,
    });
  };

  /**
   * Handles the blur event for updating specific fields in the Create Process Batch form.
   * @function
   * @param {object} eventBlur - The blur event object.
   * @param {string} eventBlur.target.name - The name of the field being blurred.
   * @param {string} eventBlur.target.value - The new value of the field.
   * @returns {void}
   */
  const handleOnBlurCommentProcess = (eventBlur) => {
    setCommentCreateProcessBatch({
      ...commentCreateProcessBatch,
      [eventBlur.target.name]: eventBlur.target.value.trim(),
    });
  };

  /**
   * Handles the key press event and prevents the default action if the pressed key is "Enter".
   * @function
   * @param {object} eventKeyPress - The key press event object.
   * @param {string} eventKeyPress.key - The pressed key.
   * @returns {void}
   */
  const handleOnKeyPressCommentProcess = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * useEffect hook that updates the 'canNext' state based on the presence of a label in the 'fieldForm' object.
   * @param {object} fieldForm - The object containing the form field data.
   * @param {boolean} canNext - The state indicating whether the form can proceed to the next step.
   * @param {function} setCanNext - State setter function for updating the 'canNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (
      commentCreateProcessBatch.comment === false ||
      commentCreateProcessBatch.comment.length < MINIMUM_COMMENT_ALLOWED ||
      commentCreateProcessBatch.comment.length > MAXIMUM_COMMENT_ALLOWED ||
      regexAcceptPrimaryCharacters.test(commentCreateProcessBatch.comment) === false
    ) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [commentCreateProcessBatch.comment]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Sets the copied checked upload file to match the checked omited upload file.
   * This effect is triggered when the checked omited upload file changes.
   * @param {boolean} checkedOmitedUploadFile - Indicates the checked omited upload file.
   */
  useEffect(() => {
    setCopiedCheckedUploadFile(checkedOmitedUploadFile);
  }, [checkedOmitedUploadFile]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Reset upload form workflow and start date if the checked omited upload file changes.
   * This effect is triggered when the checked omited upload file changes.
   * @param {boolean} checkedOmitedUploadFile - Indicates if the checked omited upload file is different from the copied checked upload file.
   */
  useEffect(() => {
    if (
      isNullOrUndefined(copiedCheckedUploadFile) === false &&
      checkedOmitedUploadFile !== copiedCheckedUploadFile
    ) {
      setUploadFormWorkflow({
        uploadForm: {
          form_uuid: "",
          subject: "",
          publication_at: "",
          support_type: "",
        },
        startDate: "",
        files: [],
      });
      setUploadFormStartDate("");
    }
  }, [checkedOmitedUploadFile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingComplyProcessBatch)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="forms-fields">
          <AvForm>
            <CardHeader className="mb-4">
              {i18n.t("taskBatchProcess.label.comment")}
            </CardHeader>
            <Alert className="mbg-3 mt-2 mb-4" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
                <span className="font-weight-bold mbg-3">
                  {i18n.t("modal.DoneError.header")} :{" "}
                </span>
                {i18n.t("taskFilter.alertCommentProcess")}
              </span>
            </Alert>
            <AvGroup row>
              <Label for="label" className="is-required" sm={3}>
                {i18n.t("taskCommentsNew")}
              </Label>
              <Col md={8}>
                <AvField
                  onChange={handleOnChangeCommentProcess}
                  onKeyPress={handleOnKeyPressCommentProcess}
                  onBlur={handleOnBlurCommentProcess}
                  type="textarea"
                  name="comment"
                  id="comment"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `${i18n.t("taskFilter.requiredField")}`,
                    },
                    pattern: {
                      value: regexAcceptPrimaryCharacters,
                      errorMessage: `${i18n.t("trd.Tooltip12")}`,
                    },
                    minLength: {
                      value: 4,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: 1000,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                    },
                  }}
                  autoComplete="off"
                  value={commentCreateProcessBatch.comment}
                />
              </Col>
            </AvGroup>

            <Alert className="mbg-3 mt-2 mb-2" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
                <span className="font-weight-bold mbg-3">
                  {i18n.t("modal.DoneError.header")}
                  {" :"}
                </span>{" "}
                <span>{i18n.t("taskBatchProcess.selectOptions")}</span>
              </span>
            </Alert>

            <div className="mb-4">
              <div row className="font-weight-normal">
                {i18n.t("taskBatchProcess.checked")}
              </div>
              <div className="d-flex align-items-center pb-0 mb-4">
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={checkedOmitedUploadFile}
                  onChange={handleCheckedUpload}
                />
                {i18n.t("taskFilter.titleUploadFileProcess")}
              </div>
            </div>

            {checkFilingForm}
          </AvForm>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FormCommentProcessBatch.propTypes = {
  currentProcess: PropTypes.string,
};

export default FormCommentProcessBatch;
