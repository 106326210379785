import React, { useEffect, Fragment } from "react";
import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import { Col, Label, CustomInput } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { useListContext } from "contextAPI/ListsContext";
import { regexLabelValidation } from "utils/regexExpressions";
import i18n from "locales/i18n";

const LabelValidation = () => {
  const { handleOnChange, fieldForm } = useFieldsRecordContext();
  const { setCanNext } = useListContext();

  /**
   * Side effect to update the state of `canNext` based on the value of `fieldForm.label`.
   * If `fieldForm.label` is empty, `canNext` is set to `true`; otherwise, it's set to `false`.
   * @returns {void}
   */
  useEffect(() => {
    if (!fieldForm.label) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [fieldForm.label]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Side effect to delete specific properties from the `fieldForm` object.
   * Deletes the `required` and `search_field` properties.
   * @returns {void}
   */
  useEffect(() => {
    delete fieldForm.required
    delete fieldForm.search_field
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <AvGroup row>
        <Label for="label" className="is-required" sm={3}>
          {i18n.t("form.fieldtable2")}
        </Label>
        <Col md={6}>
          <AvInput
            id="label"
            name="label"
            type="text"
            onChange={handleOnChange}
            minLength="1"
            maxLength="100"
            autoFocus="true"
            required
            pattern={regexLabelValidation}
            autoComplete="off"
            value={fieldForm.label}
          />
          <AvFeedback>{i18n.t("form.fieldFeedback2")}</AvFeedback>
        </Col>
      </AvGroup>
      <AvGroup row>
        <Col sm={12}>
          <AvInput
            id="hide"
            name="hide"
            type="checkbox"
            onChange={handleOnChange}
            tag={CustomInput}
            required
            disabled={fieldForm.required}
            label={i18n.t("form.field39")}
            errorMessage={i18n.t("form.fieldFeedback3")}
            value={fieldForm.hide}
          />
        </Col>
      </AvGroup>
    </Fragment>
  );
};

export default LabelValidation;
