import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardHeader, Row, Col, Button, Alert } from "reactstrap";
import {
  AiFillCaretRight,
  AiOutlineFolder,
  AiFillFolderOpen,
} from "react-icons/ai";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { isNullOrUndefined } from "utils/validations";
import { enumLevelTrd } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import i18n from "locales/i18n";
import useRecord from "hooks/useRecord";
import swal from "sweetalert";

const RecordFileMoveFile = (props) => {
  const { fileId } = useParams();
  const { moveTrdFileLocation } = useRecord();
  const {
    setIsOpenFileMoveTrd,
    setIsChangePage,
    setCloseFileTables
  } = useRecordDetailContext();
  const {
    getTreeExpedientByDomainCallback,
    structureTrdExpedient
  } = useTrdContext();

  let showButtonAssing = false;
  const [expandedNodeTree, setExpandedNodeTree] = useState([]);
  const [selectedNodeTreeId, setSelectedNodeTreeId] = useState(null);
  const [loadingMoveFile, setLoadingMoveFile] = useState(null);
  const [minLevelExpedientTree] = useState(enumLevelTrd.SUBDIVISION)
  const {
    files
  } = props;
  const infoFile = files.filter((file) => file.uuid === fileId);
  const expedient = structureTrdExpedient.filter((nodeTree) => nodeTree.parentId === null)[0];
  const roots = structureTrdExpedient.filter((nodeTree) => nodeTree.level === enumLevelTrd.SUBDIVISION);
  const [fileInfo] = useState({
    backgroundId: infoFile[0].path.background_uuid,
    expedientId: infoFile[0].path.expedient_uuid,
    levelId: infoFile[0].path.level_uuid
  })
  let expedientName;

  /**
   * Renders a badge indicating the location of a file based on its level ID.
   * @param {Object} node - The node object containing the ID to compare.
   * @returns {JSX.Element|null} A badge element if the node's ID matches the file's level ID, otherwise `null`.
   */
  const renderLocation = (node) => {
    if (fileInfo.levelId === node.id === true) {
      return (
        <span className="ml-auto badge badge-success lowcase">
          {i18n.t("trd.moveFile10")}
        </span>
      );
    } else {
      return null;
    }
  };

  /**
   * Processes the `expedient` object to remove a specific label part from its `label` property if the `expedient` is not null or undefined.
   * @param {Object|null|undefined} expedient - The expedient object, which may be null or undefined.
   * @param {string} expedient.label - The label of the expedient.
   * @returns {void}
   */
  if (isNullOrUndefined(expedient) === false) {
    const nameExpedient = `(${i18n.t("trd.formTittle14")})`
    expedientName = expedient.label.replace(nameExpedient, "")
  };

  /**
   * Handles the event when the file move operation is closed.
   * Closes the file move dialog.
   * @function
   * @returns {void}
   */
  const handleOnCloseMoveFile = () => {
    setIsOpenFileMoveTrd(false);
    setCloseFileTables(true)
  }

  /**
   * Handles the click event on a selected level assignment.
   * @param {Event} event - The click event object.
   * @param {Object} node - The selected node object.
   * @returns {void}
   */
  const handleClickSelectedLevelAssing = (event, node) => {
    event.stopPropagation();
    const data = {
      new_level_trd_uuid: node.id,
      new_level_trd: node.level
    }
    setLoadingMoveFile(true);
    moveTrdFileLocation(infoFile[0].uuid, data)
      .then((response) => {
        if (response.code === 4020) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.moveFile9"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setIsChangePage(prevIsChange => !prevIsChange);
            handleOnCloseMoveFile(true);
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setLoadingMoveFile(false)
      })
  }

  /**
   *Function that toggles the expanded state of a tree node and performs related operations.
   *@param {string} nodeId - the ID of the node to toggle the expanded state
   *@param {array} structureTrd - an array of objects representing the tree structure
   *@param {array} expandedNodeTree - an array of node IDs representing the currently expanded nodes in the tree
   *@param {function} setExpandedNodeTree - the state setter function for the array of expanded node IDs
   *@param {number} selectedNodeTreeId - the ID of the currently selected node in the tree
   *@param {function} setSelectedNodeTreeId - the state setter function for the selected node ID
   *@param {function} updateCurrentPath - a function that updates the current path based on the selected node ID
   *@param {boolean} hasFetchedExpedients - a boolean value indicating whether the expedients have been fetched
   *@param {function} setHasFetchedExpedients - the state setter function for the hasFetchedExpedients variable
   */
  const toggleExpandedTree = (nodeId) => {
    const backgrund = structureTrdExpedient.find((nodeTree) => nodeTree.id === nodeId);
    const expedientNodeId = expandedNodeTree.includes(nodeId);
    if (expedientNodeId === true) {
      setExpandedNodeTree(
        expandedNodeTree.filter((nodeExpanded) => nodeExpanded !== nodeId)
      );
      if (backgrund.level === 0) {
        setExpandedNodeTree([]);
      }
    } else {
      setExpandedNodeTree([...expandedNodeTree, nodeId]);
    }
    setSelectedNodeTreeId(nodeId);
  };


  /**
  *Function that handles the click event on a node in the tree structure.
  *@param {object} node - the node object representing the clicked node
  *@param {array} expandedNodeTree - an array containing the ids of the expanded nodes in the tree structure
  *@param {function} toggleExpandedTree - a function to toggle the expanded state of a node in the tree structure
  *@param {array} structureTrd - an array representing the tree structure
  *@param {function} setSelectedNodeTree - a function to set the selected node in the tree structure
  *@param {string} setSelectedNodeTreeId - a function to set the id of the selected node in the tree structure
  */
  const handleNodeClick = (node) => {
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    toggleExpandedTree(node.id);
    if (isExpandedNodeTree === false) {
      setSelectedNodeTreeId(node.id);
    } else {
      setSelectedNodeTreeId(node.parentId);
    }
  };

  /**
   *Renders a single node of a tree structure.
   *@param {object} node - The node object representing a single node in the tree structure.
   *@param {array} structureTrd - The array representing the entire tree structure.
   *@param {array} expandedNodeTree - The array of expanded node IDs in the tree.
   *@param {number} selectedNodeTreeId - The ID of the currently selected node in the tree.
   *@param {function} toggleExpandedTree - The function to toggle the expanded state of a node in the tree.
   *@param {function} handleNodeClick - The function to handle a click event on a node.
   *@returns {JSX.Element} - The rendered JSX element representing the node.
   */
  const renderNodeFromTree = (node) => {
    const hasChildren = structureTrdExpedient.some((item) => item.level === 5);
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    let nodeStyle = "nodeStyle";
    let customExpandedStyle = "expandedStyle";
    let customLabelBackgroundStyle = "text-primary";
    let iconFolder = null;
    if (selectedNodeTreeId === node.id) {
      const isUltimateLevel = structureTrdExpedient.filter((node) => node.parentId === selectedNodeTreeId)
      const selectNode = structureTrdExpedient.filter((node) => node.id === selectedNodeTreeId)
      if (isUltimateLevel.length === 0 && selectNode[0].level >= minLevelExpedientTree && selectedNodeTreeId !== fileInfo.levelId) {
        nodeStyle += " isUltimateLevel";
        showButtonAssing = (
          <div className="ml-auto">
            <Button
              disabled={loadingMoveFile}
              key={node.id}
              className="ml-2"
              size="sm"
              color="cyan"
              onClick={(event) => handleClickSelectedLevelAssing(event, node)}
            >{i18n.t("trd.moveFile5")}</Button>
          </div>
        )
      } else {
        showButtonAssing = null;
      }

    } else {
      showButtonAssing = null;
    }
    if (selectedNodeTreeId === node.id) {
      nodeStyle += " selected";
    }

    if (fileInfo.levelId === node.id) {
      customExpandedStyle += " filePosition"
    }

    if (isExpandedNodeTree === true) {
      customExpandedStyle += " isExpanded";
    }
    if (node.parentId === null) {
      customLabelBackgroundStyle += " font-weight-bold";
    }

    if (isExpandedNodeTree === true) {
      iconFolder = <AiFillFolderOpen className="folderIcon" />;
    } else {
      iconFolder = <AiOutlineFolder className="folderIcon" />;
    }
    return (
      <div key={node.id}>
        <div
          className={nodeStyle}
          onClick={() => {
            toggleExpandedTree(node.id);
            handleNodeClick(node);
          }}
        >
          <AiFillCaretRight className={customExpandedStyle} /> &nbsp;
          {iconFolder}
          <span className={customLabelBackgroundStyle}>{node.label}</span>
          {renderLocation(node)}
          {showButtonAssing}
        </div>
        {hasChildren === true && isExpandedNodeTree === true && (
          <div className="hasChildren">
            {structureTrdExpedient
              .filter((nodeTree) => nodeTree.parentId === node.id)
              .map((child) => renderNodeFromTree(child))}
          </div>
        )}
      </div>
    );
  };

  /**
   * React effect that fetches tree expedient data by domain callback when the component mounts or when the dependency values change.
   * @returns {void}
   */
  useEffect(() => {
    getTreeExpedientByDomainCallback(fileInfo.backgroundId, fileInfo.expedientId)
  }, [getTreeExpedientByDomainCallback, fileInfo.backgroundId, fileInfo.expedientId])


  /**
   * Sets the `closeFileTables` state to `false` when the component mounts.
   * This effect only runs once on component mount due to the empty dependency array.
   * The ESLint rule to enforce dependencies in the array is intentionally disabled.
   * @returns {void}
   */
  useEffect(() => {
    setCloseFileTables(false);
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardHeader>
          <h5 className="text-cyan text-bold p-3">
            {i18n.t("trd.moveFile1")}
          </h5>
          <div className="btn ml-auto">
            <Row>
              <Button
                onClick={handleOnCloseMoveFile}
                className="btn-icon"
                color="cyan">
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  className="mr-2"
                />
                {i18n.t("trd.moveFile2")}
              </Button>
            </Row>
          </div>
        </CardHeader>
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          {i18n.t("trd.moveFile6")}{": "} <strong>{expedientName}</strong> - <span className="font-italic">{i18n.t("trd.moveFile8")}</span>
        </Alert>
        <Row>
          <Col md={12}>
            <div className="d-flex flex-column w-100">
              <div className="m-8 p-3">
                {roots.map((node) => renderNodeFromTree(node))}
              </div>
            </div>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>

  )
}

export default RecordFileMoveFile