import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";

import i18n from "locales/i18n";

const ButtonBackTrd = () => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Link to="/trd">
            <Button className="button mr-2" color="cyan">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              <span>{i18n.t("trd.butonBackTrd")}</span>
            </Button>
          </Link>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonBackTrd;
