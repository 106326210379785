import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CustomInput,
  Row,
  Col,
  Label,
  CardFooter,
} from "reactstrap";
import { useParams } from "react-router-dom";
import ButtonAddMembers from "components/atoms/ButtonAddMembers";
import { useSignatureContext } from "contextAPI/SignatureContext";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import renderSpinner from "utils/spinnerButton";
import { loaderElement } from "utils/loaderElement";
import SignatoriesListTable from "components/organism/SignatoriesListTable";
import i18n from "locales/i18n";

const SignatureForm = () => {
  const { hash } = useParams();
  const unHashInfoFile = window.atob(hash);
  const infoFile = JSON.parse(unHashInfoFile);
  const {
    isLoadingSignature,
    createSing,
    signatoriesList,
    deleteSignatories,
    setCreateSing,
    handleOnChange,
    handleOnSubmit,
    handleOnKeyPress,
    handleOnBlur,
    alertMinSignatories,
    alertMaxSignatories,
    showTableSignatories,
    setShowSignatoriesForm,
    setUuid,
  } = useSignatureContext();
  let minSignatories = null;
  let maxSignatories = null;
  let tableSignatories = null;

  /**
   * Generates an alert component based on specific conditions.
   * This function creates an alert component to display a message based on the given conditions.
   * @param {boolean} alertMinSignatories - A boolean flag to determine if the alert should indicate a minimum signatories condition.
   * @param {boolean} showTableSignatories - A boolean flag to determine if a table should be displayed.
   * @param {string} i18n.t - A function for internationalization and translation, used to retrieve the alert message.
   * @returns {JSX.Element} The generated alert component.
   */
  if (alertMinSignatories === true) {
    minSignatories = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          {i18n.t("signature.alertMin")}
        </span>
      </Alert>
    );
  } else if (showTableSignatories === false) {
    minSignatories = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          {i18n.t("signature.alertMin")}
        </span>
      </Alert>
    );
  }

  /**
   * Conditionally renders an alert component for maximum signatories.
   * If the 'alertMaxSignatories' flag is true, this function generates an alert component
   * with a danger color to indicate the presence of the maximum signatories alert.
   * @param {boolean} alertMaxSignatories - A flag indicating whether the maximum signatories alert should be displayed.
   * @returns {JSX.Element|null} - Returns the JSX element for the maximum signatories alert if 'alertMaxSignatories' is true,
   * or null if the flag is false.
   */
  if (alertMaxSignatories === true) {
    maxSignatories = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          {i18n.t("signature.alertMax")}
        </span>
      </Alert>
    );
  }

  /**
   * Conditionally renders a table of signatories based on the value of `showTableSignatories`.
   * @param {boolean} showTableSignatories - A flag indicating whether to display the table of signatories.
   * @param {Array} signatoriesList - An array of signatories to be displayed in the table.
   * @param {function} deleteSignatories - A function to delete signatories.
   * @returns {JSX.Element|null} The JSX representation of the signatories table, or null if `showTableSignatories` is false.
   */
  if (showTableSignatories === true) {
    tableSignatories = (
      <SignatoriesListTable
        signatoriesList={signatoriesList}
        deleteSignatories={deleteSignatories}
      />
    );
  }

  /**
   * A React useEffect hook that sets the UUID based on the 'infoFile' prop.
   * This effect is triggered when the 'infoFile' prop changes, updating the UUID state accordingly.
   * @param {object} infoFile - Information about the file, typically containing the 'fileId'.
   * @param {string} infoFile.fileId - The unique identifier (UUID) associated with the file.
   * @param {function} setUuid - The state setter function for updating the UUID.
   * @returns {void}
   */
  useEffect(() => {
    setUuid(infoFile.fileId);
  }, [infoFile]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that updates the 'createSing' state object when the 'signatoriesList' array changes.
   * This hook copies specific properties from the 'infoFile' and updates the 'createSing' state with this data.
   * It is typically used when the list of signatories ('signatoriesList') changes.
   * @param {Object} createSing - The current 'createSing' state object that you want to update.
   * @param {Object} infoFile - An object containing information about the file.
   * @param {boolean} infoFile.trdExistVal - A boolean indicating whether a TRD (Temporary Record Document) exists.
   * @param {string} infoFile.nameFile - The name of the file.
   * @param {string} infoFile.subjectFile - The subject of the file.
   * @param {Date} infoFile.publication - The date of publication of the file.
   * @param {string} infoFile.formId - The UUID of the associated form.
   * @param {string} infoFile.recordId - The record number.
   * @param {string} infoFile.recordUuid - The UUID of the record.
   * @param {Array} signatoriesList - An array of signatories to be included in the 'signatories' property of 'createSing'.
   * @returns {void}
   */
  useEffect(() => {
    setCreateSing({
      ...createSing,
      has_trd: infoFile.trdExistVal,
      file_name: infoFile.nameFile,
      file_subject: infoFile.subjectFile,
      file_date: infoFile.publication,
      form_uuid: infoFile.formId,
      record_number: infoFile.recordId,
      record_uuid: infoFile.recordUuid,
      signatories: signatoriesList,
    });
  }, [signatoriesList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingSignature)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container">
                <h5 className="text-info font-weight-bold">
                  {i18n.t("signature.generalOptions")}
                </h5>
              </Col>
            </Row>
          </CardBody>

          <CardBody className="pt-0">
            <AvForm onSubmit={handleOnSubmit}>
              <Row>
                <Col md="6">
                  <AvGroup>
                    <Label for="subject" className="is-required">
                      {i18n.t("signature.description")}
                    </Label>
                    <AvField
                      onChange={handleOnChange}
                      onKeyPress={handleOnKeyPress}
                      onBlur={handleOnBlur}
                      type="text"
                      name="subject"
                      id="subject"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                        pattern: {
                          value: "/^([A-Za-z0-9 À-ÿ_-]+)$/",
                          errorMessage: `${i18n.t("invalidField")}`,
                        },
                        minLength: {
                          value: 2,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: 100,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={createSing.subject}
                    />
                  </AvGroup>
                </Col>

                <Col md="6">
                  <AvGroup>
                    <Label for="notification" className="is-required">
                      {i18n.t("signature.notifications")}
                    </Label>
                    <AvField
                      onChange={handleOnChange}
                      type="select"
                      name="notification"
                      id="notification"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                      }}
                      value={createSing.notification}
                    >
                      <option value={"1"}>
                        {i18n.t("integration.requesEmail")}
                      </option>
                      <option value={"2"}>
                        {i18n.t("signature.notificationsOp1")}
                      </option>
                      <option value={"3"}>
                        {i18n.t("signature.notificationsOp2")}
                      </option>
                      <option value={"4"}>
                        {i18n.t("signature.notificationsOp3")}
                      </option>
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <AvGroup>
                    <Label for="expiration_days" className="is-required">
                      {i18n.t("signature.timeSing")}
                    </Label>
                    <AvField
                      onChange={handleOnChange}
                      onKeyPress={handleOnKeyPress}
                      onBlur={handleOnBlur}
                      type="numeric"
                      name="expiration_days"
                      id="expiration_days"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                        pattern: {
                          value: "/^([0-9]+)$/",
                          errorMessage: `${i18n.t("recordValidateNumbers")}`,
                        },
                        min: {
                          value: 1,
                          errorMessage: `${i18n.t("signature.numericMinDays")}`,
                        },
                        max: {
                          value: 60,
                          errorMessage: `${i18n.t("signature.numericMaxDays")}`,
                        },
                      }}
                      autoComplete="off"
                      value={createSing.expiration_days}
                    />
                  </AvGroup>
                </Col>

                <Col md="6">
                  <AvGroup>
                    <Label for="edit_data" className="is-required">
                      {i18n.t("signature.changeDataUasers")}
                    </Label>
                    <br />
                    <AvInput
                      onChange={(e) => {
                        const target = e.target.value;
                        const targetCheckbox = e.target.checked;
                        if (
                          target.includes("false") ||
                          target.includes("true")
                        ) {
                          setCreateSing({
                            ...createSing,
                            [e.target.name]: String(targetCheckbox),
                          });
                        }
                      }}
                      tag={CustomInput}
                      type="checkbox"
                      name="edit_data"
                      id="edit_data"
                      value={createSing.edit_data}
                    />
                    <AvFeedback>{i18n.t("fieldRequired")}</AvFeedback>
                  </AvGroup>
                </Col>
              </Row>

              <div className="divider" />

              <Row className="pb-4">
                <Col lg="5" md="4" sm="12" className="button-container">
                  <h5 className="text-info font-weight-bold">
                    {i18n.t("signature.addSignatories")}
                  </h5>
                </Col>
              </Row>
              <div className="pb-4">
                {minSignatories}
                {maxSignatories}
                <ButtonAddMembers
                  setShowSignatoriesForm={setShowSignatoriesForm}
                />
                {tableSignatories}
              </div>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  disabled={isLoadingSignature}
                  className="col-mt-3 mr-3"
                  color="gray"
                  onClick={() => window.history.back()}
                >
                  {renderSpinner(isLoadingSignature)}
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingSignature}
                  className="col-mt-3"
                  color="cyan"
                >
                  {renderSpinner(isLoadingSignature)}
                  {i18n.t("createusers.createButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default SignatureForm;
