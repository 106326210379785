import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
	Alert,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Row,
	Col,
	Button,
	Spinner,
	CardFooter,
} from "reactstrap";
import cx from "classnames";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { showAlertServiceError } from "utils/alerts";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";
import useWorkflow from "hooks/useWorkFlow";
import swal from "sweetalert";

const ModalResponsibleForGroup = (props) => {
	const {
		showModal,
		setShowModal,
		handleOnClose,
		workflowid,
		stepUuid,
		showModalGroup,
		setShowModalGroup
	} = props;

	const { assingWorkGroup, updateWorkGroupResponsibleStep } = useWorkflow()
	const [isLoadingResponsibleGroup, setIsLoadingResponsibleGroup] = useState(false);
	const [selectedUserForGroup, setSelectedUserForGroup] = useState([]);
	const [isErrorSelectGroup, setIsError] = useState(false);
	const [addGroups, setAddGroups] = useState({
		groups: [],
	});

	const {
		listNameGroups,
		getWorkGroupsBySearch,
		isEditWorkGroupStep,
		idWorkGroup
	} = useWorkflowContext()

	let options = null;
	let requiredLabel = null;
	let loadingModalResponsibleGroup = null;
	let labelButtonSubmitResponsibleGroup = null;
	const listNameGroupToEdit = listNameGroups.filter(group => group.value !== idWorkGroup)

	/**
	 * Sets the label for the submit button based on whether the work group step is being edited.
	 * @param {boolean} isEditWorkGroupStep - Flag indicating if the work group step is being edited.
	 * @param {Object} i18n - Internationalization object for translations.
	 * @param {function} i18n.t - Function to get translated messages.
	 * @returns {string} - The label for the submit button.
	 */
	if (isEditWorkGroupStep === true) {
		labelButtonSubmitResponsibleGroup = (i18n.t("createWorflow.EditButton"));
	} else {
		labelButtonSubmitResponsibleGroup = (i18n.t("createRoles.MembersButton"));
	}

	/**
	 * Sets the loading spinner element based on the loading flag.
	 * @param {boolean} isLoadingResponsibleGroup - Flag indicating if the process is loading.
	 * @returns {JSX.Element|null} - The JSX element for the loading spinner or null.
	 */
	if (isLoadingResponsibleGroup === true) {
		loadingModalResponsibleGroup = (
			<Spinner size="sm" color="secondary" type="grow" />
		)
	};

	/**
	 * Sets the label indicating a required field based on the error flag.
	 * @param {boolean} isErrorSelectGroup - Flag indicating if there is an error.
	 * @param {Object} i18n - Internationalization object for translations.
	 * @param {function} i18n.t - Function to get translated messages.
	 * @returns {JSX.Element|null} - The JSX element for the required label or null.
	 */
	if (isErrorSelectGroup === true) {
		requiredLabel = (
			<span
				className="text-danger small"
			>
				{i18n.t("fieldRequired")}
			</span>
		)
	};

	/**
	 * Determines the options list based on whether the work group step is being edited.
	 * @param {boolean} isEditWorkGroupStep - Flag indicating if the work group step is being edited.
	 * @param {Array} listNameGroupToEdit - List of options for editing a work group.
	 * @param {Array} listNameGroups - List of options for selecting a work group.
	 * @returns {Array} - The appropriate list of options based on the editing flag.
	 */
	if (isEditWorkGroupStep === true) {
		options = listNameGroupToEdit
	} else {
		options = listNameGroups
	}

	/**
	 * Closes modals and resets state related to group management. 
	 * This function performs the following actions:
	 * - Hides the modal related to groups by setting `showModalGroup` to `false`.
	 * - Hides the main modal by setting `showModal` to `false`.
	 * - Clears any existing error state by setting `isErrorSelectGroup` to `false`.
	 * - Resets the `addGroups` state to an empty groups array.
	 * - Clears the selected options by setting `selectedUserForGroup` to an empty array.
	 * @returns {void}
	 */
	const closeModalOptions = () => {
		setShowModalGroup(false)
		setShowModal(false);
		setIsError(false);
		setAddGroups({
			...addGroups,
			groups: [],
		});
		setSelectedUserForGroup([]);
	};

	/**
	 * Handles changes in the selected users option.
	 * This function updates the state based on the selected users option. It sets an error if no option is selected,
	 * otherwise, it updates the `addGroups` state with the selected value and clears any existing error.
	 * @param {Object[]} selectedUserForGroup - The selected users option. An array where each element has a `value` property.
	 * @param {Object} selectedUserForGroup[] - An individual option object from the selected options array.
	 * @param {*} selectedUserForGroup[].value - The value of the selected option.
	 * @returns {void}
	 */
	const handleOnChangeUsers = useCallback((selectedUserForGroup) => {
		if (selectedUserForGroup.length === 0) {
			setIsError(true);
		} else {
			setAddGroups({
				...addGroups,
				groups: selectedUserForGroup.value,
			});
			setIsError(false);
		}
		setSelectedUserForGroup(selectedUserForGroup);
	}, [addGroups]);

	/**
	 * Handles the submission of the form.
	 * @param {Object} event - The event object from the form submission.
	 * @param {Array} errors - An array of error objects from the form validation.
	 */
	const handleOnSubmit = (eventSubmitResponsiblesForGroup, errors) => {
		if (errors.length === 0) {
			if (addGroups.groups.length === 0 || selectedUserForGroup.length === 0) {
				setIsError(true);
				setIsLoadingResponsibleGroup(false);
				eventSubmitResponsiblesForGroup.preventDefault();
				return
			}
			if (isEditWorkGroupStep === true) {
				let selectedGroup = {
					"work_group_uuid": selectedUserForGroup.value,
				}
				setIsLoadingResponsibleGroup(true)
				updateWorkGroupResponsibleStep(workflowid, stepUuid, selectedGroup)
					.then((response) => {
						if (response.status === 202) {
							const showAlertUserReasign = () => {
								setShowModal(false);
								setShowModalGroup(false)
								swal({
									title: i18n.t("modal.DoneError.header"),
									text: i18n.t("WorkFlowEdit.currentUser"),
									icon: "success",
									button: i18n.t("modal.Done.footerButton"),
								}).then(() => {
									window.location.reload();
								});
							};
							showAlertUserReasign();
						} else {
							showAlertServiceError();
						}
					})
					.finally(() => { setIsLoadingResponsibleGroup(false) })

			} else {
				let selectedGroup = {
					"work_group_uuid": selectedUserForGroup.value,
					"step_uuid": stepUuid,
					"workflow_uuid": workflowid
				}
				setIsLoadingResponsibleGroup(true)
				assingWorkGroup(selectedGroup)
					.then((response) => {
						if (response.status === 201) {
							setShowModal(false);
							swal({
								title: i18n.t("modal.DoneError.header"),
								text: i18n.t("create.work.group.members.assignment.alert2"),
								icon: "success",
								button: i18n.t("modal.Done.footerButton"),
							}).then(() => window.location.reload());
						} else {
							showAlertServiceError();
						}
					})
					.finally(() => {
						setIsLoadingResponsibleGroup(false)
					});
			};
		};
	};

	/**
	 * Effect hook that fetches work groups by search when the component mounts.
	 * This hook runs the `getWorkGroupsBySearch` function once when the component is first rendered.
	 * @function
	 * @name useEffect
	 * @param {Function} getWorkGroupsBySearch - Function to fetch work groups based on a search query.
	 */
	useEffect(() => {
		getWorkGroupsBySearch()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<Modal
					isOpen={showModal || showModalGroup}
					toggle={handleOnClose}
					size="lg"
					className="modal-responsible-size-md"
				>
					<ModalHeader>
						<Label>
							{i18n.t("create.work.group.members.assignment")}
						</Label>
					</ModalHeader>
					<ModalBody>
						<AvForm onSubmit={handleOnSubmit}>
							<Row>
								<Col md="12">
									<Alert className="mbg-3" color="info" isOpen={true}>
										<span className="pr-2">
											<FontAwesomeIcon icon={faInfoCircle} />
										</span>
										{i18n.t("create.work.group.members.assignment.alert")}
									</Alert>{" "}
									<AvGroup>
										<Label
											for="users_uuid"
											className={cx("", {
												"labels-error": isErrorSelectGroup === true,
											})}
										>
											{i18n.t("create.work.group.members.assignment.label")}
										</Label>

										<Select
											id="users_uuid"
											name="users_uuid"
											classNamePrefix={cx("", {
												select: isErrorSelectGroup === true,
											})}
											placeholder={i18n.t("create.work.group.members.assignment.placeholder")}
											closeMenuOnSelect={true}
											components={makeAnimated()}
											value={selectedUserForGroup}
											options={options}
											onChange={handleOnChangeUsers}
											noOptionsMessage={() =>
												i18n.t("create.work.group.members.assignment.label2")
											}
										/>
										{requiredLabel}
									</AvGroup>
								</Col>
							</Row>
							<br />
							<CardFooter className="d-block text-right">
								<Button
									size="lg"
									onClick={closeModalOptions}
									className="col-mt-3 mr-3"
									color="gray"
								>
									{i18n.t("createusers.createButton2")}
								</Button>

								<Button
									type="submit"
									size="lg"
									disabled={isLoadingResponsibleGroup}
									className="col-mt-3 mr-3"
									color="cyan"
								>
									{loadingModalResponsibleGroup}
									{labelButtonSubmitResponsibleGroup}
								</Button>
							</CardFooter>
						</AvForm>
					</ModalBody>
				</Modal>
			</CSSTransitionGroup>
		</Fragment>
	);
};

export default ModalResponsibleForGroup
