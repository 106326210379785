import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import cx from "classnames";
import { Alert } from "reactstrap";
import {
  PASSWORD_EXPIRATION_DAYS
} from "constants/securityConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";
import { toast, Slide } from "react-toastify";

import LinkHome from "components/atoms/LinkHome";

class PageTitleAlt2 extends React.Component {
  state = {
    expZoomIn: false,
  };

  toggle(name) {
    this.setState({
      [name]: !this.state[name],
      progress: 0.5,
    });
  }

  notify22 = () =>
  (this.toastId = toast("You can add whatever element in this section.", {
    transition: Slide,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-center",
    type: "default",
  }));

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,

      heading,
      icon,
      subheading,
    } = this.props;

    const PASSWORD_EXPIRATION = Number(window.localStorage.getItem(PASSWORD_EXPIRATION_DAYS));


    return (

      <div className="app-page-title">
        {PASSWORD_EXPIRATION <= 7 && PASSWORD_EXPIRATION > 0 ? (
          <div className="content-alert" style={{ height: "65px", display: "flex", justifyContent: "center", width: "100%" }}>
            <Alert color="info" style={{ display: "flex", width: "100%", textAlign: "center", justifyContent: "center", zIndex: "99" }}>
              <span className="pr-2 text-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2 "
                />
              </span>
              <Link to="/renew-password">
                <p>
                  {i18n.t("password.expiration.label1")}{"\n"}{PASSWORD_EXPIRATION} {i18n.t("password.expiration.label2")}
                </p>
              </Link>
            </Alert>
          </div>
        ) : null}

        {PASSWORD_EXPIRATION === 0 ? (
          <div className="content-alert" style={{ height: "65px", display: "flex", justifyContent: "center", width: "100%" }}>
            <Alert color="danger" style={{ display: "flex", width: "100%", textAlign: "center", justifyContent: "center", zIndex: "99" }}>
              <span className="pr-2 text-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2 "
                />
              </span>
              <p>
                <Link to="/renew-password" style={{ color:"#721C24", fontWeight:"bold"}}>
                  {i18n.t("password.expiration.label3")}
                </Link>
              </p>
            </Alert>
          </div>
        ) : null}

        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div
              className={cx("page-title-icon rounded-circle", {
                "d-none": !enablePageTitleIcon,
              })}
            >
              <i className={icon} />
            </div>
            <div>
              {heading}
              <div
                className={cx("page-title-subheading", {
                  "d-none": !enablePageTitleSubheading,
                })}
              >
                {subheading}
                <LinkHome />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitleAlt2);
