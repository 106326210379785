import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';

import {
  append as svgAppend,
  attr as svgAttr,
  classes as svgClasses,
  create as svgCreate
} from 'tiny-svg';
import {
  getRoundRectPath
} from 'bpmn-js/lib/draw/BpmnRenderUtil';
import {
  is,
  getBusinessObject
} from 'bpmn-js/lib/util/ModelUtil';
import { isNil } from 'min-dash';
import { enumsCustomRenderer } from 'utils/enums';

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer) {
    super(eventBus, enumsCustomRenderer.HIGH_PRIORITY);
    this.bpmnRenderer = bpmnRenderer;
  }

  /**
   * Determines if the given BPMN element can be rendered.
   * @param {Object} element - The BPMN element to check.
   * @returns {boolean} - `true` if the element can be rendered; otherwise, `false`.
   */
  canRender(element) {
    return !element.labelTarget;
  };

  /**
   * Draws a BPMN shape on the given parent node and adds a suitability score indicator if available.
   * @param {SVGGElement} parentNode - The parent SVG element to which the shape will be appended.
   * @param {Object} element - The BPMN element to be drawn.
   * @returns {SVGElement} - The drawn BPMN shape.
   */
  drawShape(parentNode, element) {
    const shape = this.bpmnRenderer.drawShape(parentNode, element);
    const suitabilityScore = this.getSuitabilityScore(element);
    if (!isNil(suitabilityScore)) {
      const color = this.getColor(suitabilityScore);
      const rect = drawRect(parentNode, 0, 0, enumsCustomRenderer.TASK_BORDER_RADIUS, color);
      svgAttr(rect, {
        transform: 'translate(0)'
      });
      var text = svgCreate('text');
      svgAttr(text, {
        fill: '#fff',
        transform: 'translate(0)'
      });
      svgClasses(text).add('djs-label');
      svgAppend(text, document.createTextNode(suitabilityScore));
      svgAppend(parentNode, text);
    }
    return shape;
  };

  /**
   * Retrieves the path for a given BPMN shape.
   * @param {Object} shape - The BPMN shape for which the path is to be retrieved.
   * @returns {string} - The SVG path string for the given shape.
   */
  getShapePath(shape) {
    if (is(shape, 'bpmn:Task')) {
      return getRoundRectPath(shape, enumsCustomRenderer.TASK_BORDER_RADIUS);
    }
    return this.bpmnRenderer.getShapePath(shape);
  }

  /**
   * Retrieves the suitability score from the given BPMN element.
   * @param {Object} element - The BPMN element from which to retrieve the suitability score.
   * @returns {number|null} - The suitability score if it is a finite number; otherwise, `null`.
   */
  getSuitabilityScore(element) {
    const businessObject = getBusinessObject(element);
    const { suitable } = businessObject;
    return Number.isFinite(suitable) ? suitable : null;
  };

  /**
   * Determines the color based on the given suitability score.
   * @param {number} suitabilityScore - The suitability score used to determine the color.
   * @returns {string} - The color corresponding to the suitability score.
   */
  getColor(suitabilityScore) {
    if (suitabilityScore > 75) {
      return enumsCustomRenderer.COLOR_GREEN;
    } else if (suitabilityScore > 25) {
      return enumsCustomRenderer.COLOR_YELLOW;
    }
    return enumsCustomRenderer.COLOR_RED;
  };

}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer'];

/**
 * Draws an SVG rectangle and appends it to the specified parent node.
 * @param {SVGGElement} parentNode - The parent SVG element to which the rectangle will be appended.
 * @param {number} width - The width of the rectangle.
 * @param {number} height - The height of the rectangle.
 * @param {number} borderRadius - The border radius of the rectangle's corners.
 * @param {string} color - The color of the rectangle's stroke and fill.
 * @returns {SVGRectElement} - The drawn SVG rectangle element.
 */
function drawRect(parentNode, width, height, borderRadius, color) {
  const rect = svgCreate('rect');
  svgAttr(rect, {
    width: width,
    height: height,
    rx: borderRadius,
    ry: borderRadius,
    stroke: color,
    strokeWidth: 2,
    fill: color
  });
  svgAppend(parentNode, rect);
  return rect;
}