import React, { useEffect, useState } from "react";
import { useListContext } from "contextAPI/ListsContext";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { loaderElement } from "utils/loaderElement";
import i18n from "locales/i18n";

const WizardFields = (props) => {
  const { id } = useParams();
  const [buttons, setButtons] = useState({
    showPreviousBtn: false,
    showNextBtn: true,
  });

  let renderButtonPreview = null;
  let renderButtonNext = null;

  const { canNext, compState, setCompState, setItems } = useListContext();
  const { handleOnSubmit, showTabs, initialStateForm, setFieldForm, isLoading } =
    useFieldsRecordContext();

  /**
   * Generates an array of navigation states to represent the progress of a multi-step process.
   * @param {number} currentIndex - The current step index in the process.
   * @param {number} length - The total number of steps in the process.
   * @returns {object} An object containing the current step index and an array of styles
   * representing the states of each step in the process.
   */
  const getNavStates = (currentIndex, length) => {
    const styles = Array(length).fill("todo");
    styles[currentIndex] = "doing";
    if (currentIndex > 0) {
      styles.fill("done", 0, currentIndex);
    }
    return { current: currentIndex, styles };
  };

  const [state, setState] = useState({
    navState: getNavStates(0, props.steps.length),
  });

  /**
   * Determines the navigation state based on the current step in a multi-step process.
   * @param {number} currentStep - The current step in the multi-step process.
   * @param {boolean} showPreviousBtn  - Indicates whether the "Previous" button should be shown.
   * @param {boolean} showNextBtn  - Indicates whether the "Next" button should be shown.
   * @returns {Object} An object representing the navigation state with the following properties:
   */
  const checkNavState = (currentStep) => {
    switch (currentStep) {
      case 0:
        return {
          showPreviousBtn: false,
          showNextBtn: true,
        };
      case 1:
      case 2:
        return {
          showPreviousBtn: true,
          showNextBtn: true,
        };
      default:
        return {
          showPreviousBtn: false,
          showNextBtn: false,
        };
    }
  };

  /**
   * Updates the navigation state based on the provided 'next' value.
   * This function is responsible for managing the navigation state in a multi-step process.
   * It updates the current state, sets the component state to the 'next' step if applicable,
   * checks navigation buttons, and triggers a submission action if 'next' is a specific step.
   * @param {number} next - The next step or state to transition to in the multi-step process.
   * @returns {void}
   */
  const setNavState = (next) => {
    setState({
      ...state,
      navState: getNavStates(next, props.steps.length),
    });
    if (next < props.steps.length) {
      setCompState(next);
    }
    setButtons(checkNavState(next, props.steps.length));
  };

  /**
   * Function to navigate to the next component state.
   * @returns {void}
   */

  const next = async () => {
    if (compState === 2) {
      const result = await handleOnSubmit();
      if (result === "success") {
        setNavState(compState + 1);
      } else {
      }
    } else if (buttons.showNextBtn === true) {
      setNavState(compState + 1);
    }
  };

  /**
   * Navigate to the previous step or component in a multi-step process.
   * This function checks if the current component state allows for moving to the previous step.
   * If so, it updates the navigation state to the previous step and clears any associated items.
   * @returns {void}
   */
  const previous = () => {
    if (compState > 0) {
      setNavState(compState - 1);
      setItems([]);
    }
  };

  /**
   * Conditionally renders a "Previous" button if the `showPreviousBtn` flag is true.
   * This function checks if `buttons.showPreviousBtn` is true, and if so, returns
   * a JSX element for the "Previous" button. The button triggers the `previous` function
   * when clicked and displays the localized text from `i18n`.
   * @param {Object} buttons - The object containing button visibility flags.
   * @param {boolean} buttons.showPreviousBtn - Flag indicating whether to show the "Previous" button.
   * @param {Function} previous - The function to be called when the "Previous" button is clicked.
   * @returns {JSX.Element|null} - The JSX element for the "Previous" button if the flag is true,
   * or null if the flag is false.
   */
  if (buttons.showPreviousBtn === true) {
    renderButtonPreview = (
      <Button
        color="gray"
        className="btn-shadow float-left btn-wide"
        onClick={previous}
      >
        {i18n.t("itemList.designerButton2")}
      </Button>
    );
  };

  /**
   * Conditionally renders a "Next" button if the `showNextBtn` flag is true.
   * This function checks if `buttons.showNextBtn` is true, and if so, returns
   * a JSX element for the "Next" button. The button triggers the `next` function
   * when clicked, is disabled based on the `canNext` flag, and displays the localized text
   * from `i18n`.
   * @param {Object} buttons - The object containing button visibility flags.
   * @param {boolean} buttons.showNextBtn - Flag indicating whether to show the "Next" button.
   * @param {Function} next - The function to be called when the "Next" button is clicked.
   * @param {boolean} canNext - Flag indicating whether the "Next" button should be disabled.
   * @returns {JSX.Element|null} - The JSX element for the "Next" button if the flag is true,
   * or null if the flag is false.
   */
  if (buttons.showNextBtn === true) {
    renderButtonNext = (
      <Button
        color="cyan"
        className="btn-shadow btn-wide float-right"
        onClick={next}
        disabled={canNext}
      >
        {i18n.t("itemList.designerButton1")}
      </Button>
    );
  };

  /**
   * Generates a new class name by combining a base class name with a style modifier.
   * @param {string} className - The base class name to which a style modifier will be appended.
   * @param {number} i - The index indicating the style modifier to use.
   * @returns {string} The generated class name.
   */
  const getClassName = (className, i) => {
    return className + "-" + state.navState.styles[i];
  };

  /**
   * Renders a list of steps for a form wizard component.
   * @returns {JSX.Element[]} An array of JSX elements representing the steps.
   */
  const renderSteps = () => {
    return props.steps.map((s, i) => (
      <li className={getClassName("form-wizard-step", i)} key={i} value={i}>
        <em>{i + 1}</em>
        <span>{props.steps[i].name}</span>
      </li>
    ));
  };

  /**
   * useEffect hook that resets the 'fieldForm' state to its initial state when 'compState' is equal to 0.
   * @param {number} compState - The current state value used to trigger the reset when it's equal to 0.
   * @param {object} initialStateForm - The initial state object to reset 'fieldForm' to.
   * @param {function} setFieldForm - State setter function for updating the 'fieldForm' state.
   * @returns {void}
   */
  useEffect(() => {
    if (compState === 0) {
      setFieldForm(initialStateForm);
    }
  }, [compState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that resets navigation state and form fields when 'showTabs' prop changes.
   * This effect is triggered when 'showTabs' changes. It sets the navigation state to 0 and resets
   * the form fields to their initial state when the 'showTabs' prop changes.
   * @param {boolean} showTabs - Indicates whether to show tabs or not.
   * @param {function} setNavState - State setter function for navigation state.
   * @param {function} setFieldForm - State setter function for form field values.
   * @returns {void}
   */
  useEffect(() => {
    setNavState(0);
    setFieldForm(initialStateForm);
  }, [showTabs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loaderElement(isLoading)}
      <div className="p-1 d-block text-right">
        <a href={`/formsdesigner/fields/${id}`}>
          <Button className="mr-2 btn-icon" color="cyan">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
            {i18n.t("form.field45")}
          </Button>
        </a>
      </div>

      <ol className="forms-wizard">{renderSteps()}</ol>

      {props.steps[compState].component}

      <div className="clearfix">
        <div>
          {renderButtonPreview}
          {renderButtonNext}
        </div>
      </div>
    </div>
  );
};

WizardFields.defaultProps = {
  showNavigation: true,
};

export default WizardFields;