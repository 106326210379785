import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const FilterSignatureState = (props) => {
  const {
    setStatus: setStatusSignature,
    pagination: paginationSignature,
    setPagination: setPaginationSignatorie
  } = props;

  /**
   * Handles the change event for filtering signatures.
   * @param {Event} eventFilterSignature - The event object containing the filter signature.
   * @returns {void}
   */
  const handleOnChangeFilterSignature = (eventFilterSignature) => {
    setStatusSignature(eventFilterSignature.target.value);
    setPaginationSignatorie({ ...paginationSignature, page: 1 })
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("info.SignatureFilter")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem header>{i18n.t("filteruser.button")}</DropdownItem>
              <DropdownItem value={""} onClick={handleOnChangeFilterSignature}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={0} onClick={handleOnChangeFilterSignature}>
                {i18n.t("info.SignatureFilter1")}
              </DropdownItem>
              <DropdownItem value={1} onClick={handleOnChangeFilterSignature}>
                {i18n.t("info.SignatureFilter2")}
              </DropdownItem>
              <DropdownItem value={2} onClick={handleOnChangeFilterSignature}>
                {i18n.t("info.SignatureFilter3")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterSignatureState.propTypes = {
  setStatus: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired
};

export default FilterSignatureState;
