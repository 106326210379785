const positions = [
  { id: 1, number: 1, top: 0, left: 0 },
  { id: 2, number: 2, top: 0, left: 580 },
  { id: 3, number: 3, top: 450, left: 0 },
  { id: 4, number: 4, top: 450, left: 580 },
  { id: 5, number: 5, top: 920, left: 0 },
  { id: 6, number: 6, top: 920, left: 580 },
];

export const defaultStickerPosition = Object.freeze(
  positions.map(Object.freeze)
);

export const fontOptions = [
  { id: 1, value: "Arial" },
  { id: 2, value: "Courier New" },
  { id: 3, value: "Lucida Console" },
  { id: 4, value: "Times New Roman" },
  { id: 5, value: "Verdana" },
];

export const fontSizeOptions = [
  { id: 1, value: 8 },
  { id: 2, value: 10 },
  { id: 3, value: 12 },
  { id: 4, value: 14 },
  { id: 5, value: 16 },
  { id: 6, value: 18 },
  { id: 7, value: 20 },
  { id: 8, value: 24 },
  { id: 9, value: 26 },
  { id: 10, value: 28 },
  { id: 11, value: 30 },
  { id: 12, value: 32 },
  { id: 13, value: 34 },
  { id: 14, value: 36 },
];

export const initialHeightMM = 30;
export const convertToPixels = 3.779;
