import React, { useState, useCallback } from "react";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import useList from "hooks/useList";

const ListContext = React.createContext(undefined);

export function ListContextProvider({ children }) {
  const [hasForms, setHasForms] = useState(false);
  const [formNames, setFormNames] = useState([]);
  const [childList, setChildList] = useState([]);
  const [listId, setListId] = useState("");
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState({
    name: "",
  });
  const [listName, setListName] = useState({});
  const [isLoadingGetList, setIsLoadingGetList] = useState(false);
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [errorList, setErrorList] = useState(false);
  const [compState, setCompState] = useState(0);
  const [recordNext, setRecordNext] = useState(null);
  const [list, setList] = useState([]);
  const [item, setItem] = useState([]);
  const [parentModal, setParentModal] = useState("");
  const [advanced, setAdvanced] = useState(0);
  const [showCustomButton, setShowCustomButton] = useState(false);
  const [createListState, setCreateListState] = useState({
    name: "",
    parent_id: "",
    item: {
      name: "",
      parent_id: "",
    },
  });
  const [isLoadingCreateList, setIsLoadingCreateList] = useState(false);

  const { getLists, getItems, getListsById } = useList();

  /**
   * Retrieves lists based on a search query and updates the state with the fetched lists.
   * @param {number} page - The page number for pagination.
   * @param {number} per_page - The number of items per page for pagination.
   * @param {string} query - The search query used to filter lists.
   * @param {function} setIsLoadingGetList - State setter function to indicate loading status.
   * @param {function} getLists - Function to fetch lists based on pagination and search query.
   * @param {function} setList - State setter function to update the list state.
   * @param {function} showAlertServiceError - Function to display an alert for service errors.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @returns {void}
   */
  const getListsBySearch = useCallback(() => {
    let pagination = { page: 1, per_page: 100 };
    const { page, per_page } = pagination;
    setIsLoadingGetList(true);
    getLists(page, per_page, "")
      .then((response) => {
        const consultedLists = response.data.items;
        if (isNullOrUndefined(consultedLists) === false) {
          consultedLists
            .filter((item) => item.status === 1)
            .sort((positionNameA, positionNameB) => {
              const nombresA = `${positionNameA.name}  `.toLocaleLowerCase();
              const nombresB = `${positionNameB.name}  `.toLocaleLowerCase();
              return nombresA.localeCompare(nombresB);
            })
            .forEach((e) => {
              setList((prevState) => [
                ...prevState,
                {
                  value: e.uuid,
                  label: e.name,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGetList(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches lists by their IDs and updates state accordingly.
   * @param {string} id - The ID of the list to fetch.
   * @param {function} setIsLoadingGetList - State setter function to control loading state.
   * @param {function} getListsById - Function to fetch lists by their IDs.
   * @param {function} setParentModal - State setter function to update the parent modal ID.
   * @param {function} showAlertServiceError - Function to show an alert for service error.
   * @returns {void}
   */
  const getListsByIds = useCallback((id) => {
    setIsLoadingGetList(true);
    getListsById(id)
      .then((response) => {
        const parentModalId = response.data.parent_id;
        if (isNullOrUndefined(parentModalId) === false) {
          setParentModal(parentModalId);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGetList(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches items based on a search query and updates the state with the retrieved items.
   * @param {string} id - The identifier used to search for items.
   * @param {function} setIsLoadingGetList - State setter function to indicate loading state.
   * @param {function} getItems - Function to retrieve items based on the provided parameters.
   * @param {function} setItem - State setter function to update the list of items.
   * @param {function} showAlertServiceError - Function to display an alert for service errors.
   * @returns {void}
   */
  const getItemsBySearch = useCallback((id) => {
    let pagination = { page: 1, per_page: 100 };
    const { page, per_page } = pagination;
    setIsLoadingGetList(true);
    getItems(id, page, per_page, "")
      .then((response) => {
        const consultedItems = response.data.items;
        if (isNullOrUndefined(consultedItems) === false) {
          consultedItems
            .filter((item) => item.status === 1)
            .sort((positionNameA, positionNameB) => {
              const nombresA = `${positionNameA.name}  `.toLocaleLowerCase();
              const nombresB = `${positionNameB.name}  `.toLocaleLowerCase();
              return nombresA.localeCompare(nombresB);
            })
            .forEach((e) => {
              setItem((prevState) => [
                ...prevState,
                {
                  value: e.uuid,
                  label: e.name,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGetList(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ListContext.Provider
      value={{
        isLoadingGetList,
        setIsLoadingGetList,
        items,
        setItems,
        itemName,
        setItemName,
        canNext,
        setCanNext,
        listId,
        setListId,
        compState,
        setCompState,
        errorList,
        setErrorList,
        recordNext,
        setRecordNext,
        hasForms,
        setHasForms,
        isLoadingQuery,
        setIsLoadingQuery,
        formNames,
        setFormNames,
        list,
        setList,
        getListsBySearch,
        item,
        setItem,
        getItemsBySearch,
        getListsByIds,
        parentModal,
        setParentModal,
        listName,
        setListName,
        childList,
        setChildList,
        advanced,
        setAdvanced,
        showCustomButton,
        setShowCustomButton,
        createListState,
        setCreateListState,
        isLoadingCreateList,
        setIsLoadingCreateList,
      }}
    >
      {children}
    </ListContext.Provider>
  );
}
export function useListContext() {
  const context = React.useContext(ListContext);
  if (!context) {
    throw new Error(
      "useListContext debe estar dentro del proveedor ListContext."
    );
  }
  return context;
}
