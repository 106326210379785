import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";
import { enumsFormsStatus } from "utils/enums";

const FilterFormState = (props) => {
  const {
    setStatus,
    pagination,
    setPagination,
  } = props;

  /**
   * Handles the change event for filtering form states.
   * This function updates the status based on the selected filter state and resets
   * the pagination to the first page.
   * @param {Object} eventFilterFormState - The event object from the filter state change.
   * @param {Object} eventFilterFormState.target - The target element of the event.
   * @param {string} eventFilterFormState.target.value - The new value of the filter state.
   * @param {Function} setStatus - The state setter function to update the status.
   * @param {Function} setPagination - The state setter function to update the pagination.
   * @param {Object} pagination - The current pagination state.
   * @param {number} pagination.page - The current page number.
   * @returns {void}
   */
  const handleOnChangeFilterState = (eventFilterFormState) => {
    setStatus(eventFilterFormState.target.value);
    setPagination({ ...pagination, page: 1 })
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("filterFormState")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem header>{i18n.t("filterFormState")}</DropdownItem>
              <DropdownItem value={""} onClick={handleOnChangeFilterState}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={enumsFormsStatus.ERASER} onClick={handleOnChangeFilterState}>
                {i18n.t("filterFormState1")}
              </DropdownItem>
              <DropdownItem value={enumsFormsStatus.ACTIVE} onClick={handleOnChangeFilterState}>
                {i18n.t("filteruser.item2")}
              </DropdownItem>
              <DropdownItem value={enumsFormsStatus.INACTIVE} onClick={handleOnChangeFilterState}>
                {i18n.t("filteruser.item3")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterFormState.propTypes = {
  setStatus: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default FilterFormState;
