import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faImage,
  faFileAlt,
  faFileArchive,
  faPlayCircle,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

const renderIconFile = (fileName) => {
  if (fileName.includes(".pdf")) {
    return {
      icon: faFilePdf,
      className: "text-danger",
    };
  } else if (fileName.includes(".txt")) {
    return {
      icon: faFileAlt,
      className: "text-gray",
    };
  } else if (fileName.includes(".zip")) {
    return {
      icon: faFileArchive,
      className: "text-warning",
    };
  } else if (
    fileName.includes(".doc") ||
    fileName.includes(".docx") ||
    fileName.includes(".docm") ||
    fileName.includes(".dotx") ||
    fileName.includes(".dotm")
  ) {
    return {
      icon: faFileWord,
      className: "text-blueWord",
    };
  } else if (
    fileName.includes(".xls") ||
    fileName.includes(".xlsx") ||
    fileName.includes(".xlsm") ||
    fileName.includes(".xltx") ||
    fileName.includes(".xltm") ||
    fileName.includes(".xlsb") ||
    fileName.includes(".xlam")
  ) {
    return {
      icon: faFileExcel,
      className: "text-greenExcel",
    };
  } else if (
    fileName.includes(".ppt") ||
    fileName.includes(".pptx") ||
    fileName.includes(".pptm") ||
    fileName.includes(".potx") ||
    fileName.includes(".potm") ||
    fileName.includes(".ppam") ||
    fileName.includes(".ppsx") ||
    fileName.includes(".ppsm") ||
    fileName.includes(".sldx") ||
    fileName.includes(".sldm") ||
    fileName.includes(".thmx")
  ) {
    return {
      icon: faFilePowerpoint,
      className: "text-orangePpt",
    };
  } else if (
    fileName.includes(".tif") ||
    fileName.includes(".tiff") ||
    fileName.includes(".gif") ||
    fileName.includes(".jpeg") ||
    fileName.includes(".jpg") ||
    fileName.includes(".jif") ||
    fileName.includes(".jfif") ||
    fileName.includes(".fpx") ||
    fileName.includes(".pcd") ||
    fileName.includes(".png")
  ) {
    return {
      icon: faImage,
      className: "text-info",
    };
  } else if (
    fileName.includes(".avi") ||
    fileName.includes(".asf") ||
    fileName.includes(".asx") ||
    fileName.includes(".rmi") ||
    fileName.includes(".wma") ||
    fileName.includes(".wax") ||
    fileName.includes(".wav") ||
    fileName.includes(".mp3") ||
    fileName.includes(".mkv") ||
    fileName.includes("mpeg-2") ||
    fileName.includes(".wmv") ||
    fileName.includes(".mp4") ||
    fileName.includes(".mov")
  ) {
    return {
      icon: faPlayCircle,
      className: "text-danger",
    };
  } else {
    return {
      icon: faStickyNote,
      className: "text-gray",
    };
  }
};

export default renderIconFile;
