import React, { useEffect, useState } from "react";
import { useListContext } from "contextAPI/ListsContext";
import { Button } from "reactstrap";
import { enumsTaskType } from "utils/enums";
import { useTrdContext } from "contextAPI/TrdContext";
import cx from "classnames";
import i18n from "locales/i18n";

const WizardDocuments = (props) => {
  const { steps, isFreeTask, isProcess } = props;
  const [buttons, setButtons] = useState({
    showPreviousBtn: false,
    showNextBtn: true,
  });
  const {
    compState,
    setCompState,
    recordNext,
    advanced,
    setAdvanced,
    setRecordNext,
  } = useListContext();
  const { hasOrdination } = useTrdContext();
  let componentButtonPrevious = null;

  /**
   * Generates navigation states based on the current index and length of steps.
   * @param {number} currentIndex - The index of the current step in the navigation.
   * @param {number} length - The total number of steps in the navigation.
   * @returns {Object} An object containing the current index and an array of styles representing the state of each step.
   */
  const getNavStates = (currentIndex, length) => {
    const styles = Array(length).fill("todo");
    styles[currentIndex] = "doing";
    if (currentIndex > 0) {
      styles.fill("done", 0, currentIndex);
    }
    return { current: currentIndex, styles };
  };

  const [state, setState] = useState({
    navState: getNavStates(0, steps.length),
  });

  /**
   * Determines the navigation state based on the current step and total steps length.
   * @param {number} currentStep - The current step in the navigation sequence.
   * @returns {Object} An object representing the navigation state.
   * @property {boolean} showPreviousBtn - Indicates whether the "Previous" button should be shown.
   */
  const checkNavState = (currentStep) => {
    if (currentStep > 0) {
      return {
        showPreviousBtn: true,
      };
    } else if (currentStep === 0) {
      return {
        showPreviousBtn: false,
      };
    } else {
      return {
        showPreviousBtn: false,
      };
    }
  };

  /**
   * Updates the navigation and component states based on the next step index.
   * @param {number} next - The index of the next step.
   * @returns {void}
   */
  const setNavState = (next) => {
    setState({
      ...state,
      navState: getNavStates(next, steps.length),
    });
    if (next < steps.length) {
      setCompState(next);
    }
    setButtons(checkNavState(next, steps.length));
  };

  /**
   * Advances the navigation state to the next step.
   * @returns {void}
   */
  const next = () => {
    setNavState(compState + 1);
  };

  /**
   * Moves the navigation state to the previous step, if available.
   * @returns {void}
   */
  const previous = () => {
    if (compState > 0) {
      if (hasOrdination === false && compState > 1) {
        setAdvanced(0);
        setRecordNext(false);
        setNavState(compState - 2);
      } else {
        setAdvanced(0);
        setRecordNext(false);
        setNavState(compState - 1);
      }
    }
  };

  /**
   * Generates a previous button component if the condition to show the previous button is met.
   * @param {object} buttons - Object containing information about the visibility of buttons.
   * @param {boolean} buttons.showPreviousBtn - Boolean indicating whether to show the previous button.
   * @param {function} previous - Callback function to handle click event on the previous button.
   * @param {object} i18n - Internationalization object providing translations.
   * @param {string} i18n.t - Function to translate a given key to the corresponding text.
   * @returns {JSX.Element|null} - Returns the previous button component if it should be shown, otherwise null.
   */
  if (buttons.showPreviousBtn === true) {
    componentButtonPrevious = (
      <div>
        <Button
          data-test-id="btn-previous"
          color="gray"
          className={cx("btn-shadow float-left btn-wide ", {
            "btn-shadow float-left btn-wide positionButtonPrevious":
              compState === steps.length - 1 &&
              (isFreeTask !== enumsTaskType.FREE_TASK || isProcess === false),
            "btn-shadow float-left btn-wide positionButtonPreviousProcess":
              compState === steps.length - 1 &&
              (isFreeTask === enumsTaskType.FREE_TASK || isProcess === true),
          })}
          onClick={previous}
        >
          {i18n.t("itemList.designerButton2")}
        </Button>
      </div>
    );
  }

  /**
   * Sets various states to their initial values when the component unmounts.
   * This useEffect cleanup function is triggered when the component is unmounted.
   * @param {function} setNavState - State setter function for navigation state.
   * @param {function} setCompState - State setter function for component state.
   * @param {function} setAdvanced - State setter function for advanced state.
   * @param {function} setRecordNext - State setter function for recordNext state.
   * @returns {void}
   */
  useEffect(() => {
    return () => {
      setNavState(0);
      setCompState(0);
      setAdvanced(0);
      setRecordNext(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when the component state (compState) changes,
   * specifically setting the 'advanced' state to 0 when 'compState' equals 2.
   * @param {number} compState - The current state value used to determine when to reset the 'advanced' state.
   * @returns {void}
   */
  useEffect(() => {
    if (compState === 2) {
      setAdvanced(0);
    }
  }, [compState]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React effect that updates the navigation state based on changes in the 'advanced' value.
   * This effect is triggered whenever the 'advanced' value changes.
   * @returns {void}
   */
  useEffect(() => {
    setNavState(compState + advanced);
  }, [advanced]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React effect that updates navigation and component states based on changes in the 'recordNext' value.
   * This effect is triggered whenever the 'recordNext' value changes.
   * @returns {void}
   */
  useEffect(() => {
    if (recordNext === true) {
      next();
    }
  }, [recordNext]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Generates a CSS class name based on the provided base class name and index.
   * @param {string} className - The base class name.
   * @param {number} index - The index used to fetch a corresponding style from the state's navState.
   * @returns {string} The generated CSS class name.
   */
  const getClassName = (className, index) => {
    return className + "-" + state.navState.styles[index];
  };

  /**
   * Renders the list of steps for a form wizard based on the provided steps array.
   * @returns {JSX.Element[]} An array of JSX elements representing the list of steps.
   */
  const renderSteps = () => {
    return steps.map((s, i) => (
      <li className={getClassName("form-wizard-step", i)} key={i} value={i}>
        <em>{i + 1}</em>
        <span>{steps[i].name}</span>
      </li>
    ));
  };

  return (
    <div>
      <ol className="forms-wizard">{renderSteps()}</ol>
      {steps[compState].component}

      <div>{componentButtonPrevious}</div>
    </div>
  );
};

export default WizardDocuments;

WizardDocuments.defaultProps = {
  showNavigation: true,
};
