import React from "react";
import TaskUploadFilesByExpedient from "./TaskUploadFilesByExpedient";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
export default (props) => {
  const {
    pathConfiguration,
    hasExpedientFixedPath,
    currentPath,
    isProcess,
    isProcessBatch,
  } = props;
  return (
    <RecordDetailContextProvider>
      <TaskUploadFilesByExpedient
        pathConfiguration={pathConfiguration}
        hasExpedientFixedPath={hasExpedientFixedPath}
        currentPath={currentPath}
        isProcess={isProcess}
        isProcessBatch={isProcessBatch}
      />
    </RecordDetailContextProvider>
  );
};
