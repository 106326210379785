import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Card, CardBody, Button, Collapse, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getDateFormat3 } from "utils/getDateFormat3";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { loaderComponent } from "utils/loaderElement";
import { enumSizeSelect, enumsStatusProcess } from "utils/enums";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import ProcessWorkflowRecordDeatilView from "components/molecules/ProcessWorkflowRecordDeatilView";
import WorkflowTraceabilityFilesListTable from "components/organism/WorkflowTraceabilityFilesListTable";
import WorkflowTraceabilityComments from "components/organism/WorkflowTraceabilityComments";
import WorkflowTraceabilityTaskListTable from "components/organism/WorkflowTraceabilityDeatails/WorkflowTraceabilityTaskListTable";
import useWorkFlow from "hooks/useWorkFlow";
import i18n from "locales/i18n";

const WorkflowTraceabilityDetails = () => {
  const { id } = useParams();
  const [traceability, setTraceability] = useState({});
  const [task, setTask] = useState([]);
  const [status, setStatus] = useState("");
  const [isLoadingWorkflow, setIsLoadingWorkflow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [internalForm, setInternalForm] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [formExternalList, setFormExternalList] = useState([]);
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [formExternalDinamic, setFormExternalDinamic] = useState([]);
  const [externalFormSelected, setExternalFormSelected] = useState({
    recordUuid: "",
    formUuid: "",
    formName: "",
  });
  const { getWorkflowTraceability } = useWorkFlow();
  let spinnerButton = null;
  let statusTaskBadge = null;
  let collapseRecordContent = null;
  let iconButtonReview = <FontAwesomeIcon icon={faEyeSlash} className="mr-2 ml-auto " />;

  /**
   * Generates an icon button for review based on the state of isOpenCollapse.
   * If isOpenCollapse is true, it displays an eye icon indicating review.
   * @param {boolean} isOpenCollapse - A boolean value indicating whether the collapse is open or not.
   * @returns {JSX.Element|null} - The generated icon button for review, or null if isOpenCollapse is false.
   */
  if (isOpenCollapse === true) {
    iconButtonReview = <FontAwesomeIcon icon={faEye} className="mr-2 ml-auto " />;
  }

  /**
   * Generates a spinner button JSX element if the workflow loading state is true.
   * @param {boolean} isLoadingWorkflow - Indicates whether the workflow is currently loading.
   * @returns {JSX.Element|null} - Returns a JSX element representing a spinner button if the workflow is loading, otherwise returns null.
   */
  if (isLoadingWorkflow === true) {
    spinnerButton = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Determines the appropriate status badge JSX element based on the process status.
   * @param {number} status - The status of the process.
   * @returns {JSX.Element|null} The status badge JSX element corresponding to the process status, or null if no matching status is found.
   */
  switch (traceability.status) {
    case enumsStatusProcess.IN_PROGRESS:
      statusTaskBadge = <p className="badge badge-info">{status}</p>;
      break;
    case enumsStatusProcess.FINISHED:
      statusTaskBadge = <p className="badge badge-success">{status}</p>;
      break;
    case enumsStatusProcess.INTERRUPTED:
      statusTaskBadge = <p className="badge badge-danger">{status}</p>;
      break;
    default:
      statusTaskBadge = null;
  }

  /**
   * Renders detailed content of a process workflow record if `showDetail` is true.
   * @param {boolean} showDetail - Flag indicating whether to display detailed content.
   * @param {Object} externalFormSelected - Object containing information about the selected external form.
   * @param {string} externalFormSelected.recordUuid - UUID of the selected record.
   * @param {string} externalFormSelected.formUuid - UUID of the form associated with the record.
   * @param {string} externalFormSelected.formName - Name of the form associated with the record.
   * @returns {JSX.Element|null} - JSX element representing the detailed content of the process workflow record, or null if `showDetail` is false.
   */
  if (showDetail === true) {
    collapseRecordContent = (
      <div>
        <ProcessWorkflowRecordDeatilView
          recordUuid={externalFormSelected.recordUuid}
          formUuid={externalFormSelected.formUuid}
          formName={externalFormSelected.formName}
        />
      </div>
    );
  }

  /**
   * Retrieves workflow traceability data by its unique identifier and updates the corresponding state variables.
   * @param {string} id - The unique identifier of the workflow traceability data to retrieve.
   * @param {boolean} setIsLoadingWorkflow - Function to set the loading state of the workflow data.
   * @param {function} getWorkflowTraceability - Function to fetch workflow traceability data based on its identifier.
   * @param {function} setTraceability - Function to set the retrieved workflow traceability data.
   * @param {function} setTask - Function to set the tasks associated with the retrieved workflow data.
   * @param {function} setFormExternalList - Function to set the list of external records associated with the retrieved workflow data.
   * @param {function} setStatus - Function to set the status of the workflow process.
   * @param {function} showAlertServiceError - Function to display an alert in case of service error.
   * @param {object} i18n - Object containing internationalization utilities.
   * @returns {void}
   */
  const getWorkflowTraceabilityById = useCallback(() => {
    setIsLoadingWorkflow(true);
    getWorkflowTraceability(id)
      .then((response) => {
        const workflowProcessData = response.data.data;
        if (isNullOrUndefined(workflowProcessData) === false) {
          setTraceability(workflowProcessData);
          setTask(workflowProcessData.tasks);
          if (workflowProcessData.form_external_records !== null) {
            setFormExternalList(workflowProcessData.form_external_records);
          }
          if (workflowProcessData.status === enumsStatusProcess.IN_PROGRESS) {
            setStatus(i18n.t("taskDetailStatus1"));
          } else if (workflowProcessData.status === enumsStatusProcess.FINISHED) {
            setStatus(i18n.t("taskDetailStatus2"));
          } else {
            setStatus(i18n.t("taskDetailStatus3"));
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingWorkflow(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event when selecting details forms.
   * @param {object} selectedOption - The selected option representing the details form.
   * @param {string} selectedOption.record_uuid - The UUID of the record associated with the selected details form.
   * @param {string} selectedOption.value - The UUID of the selected details form.
   * @param {string} selectedOption.label - The label/name of the selected details form.
   * @param {function} setExternalFormSelected - A state setter function to update the selected external form.
   * @param {function} setSelectedOption - A state setter function to update the selected option.
   * @param {function} setShowDetail - A state setter function to control the visibility of details.
   * @param {function} setIsOpenCollapse - A state setter function to control the collapsing behavior.
   * @returns {void}
   */
  const handleOnChangedetailsFormsSelect = (selectedOption) => {
    setExternalFormSelected({
      recordUuid: selectedOption.value,
      formUuid: selectedOption.form_uuid,
      formName: selectedOption.label,
    });
    setSelectedOption(selectedOption);
    setShowDetail(false);
    setIsOpenCollapse(false);
  };

  /**
   * Handles the click event for showing detail review and toggling collapse state.
   * @param {boolean} isOpenCollapse - The current state of the collapse component.
   * @param {function} setShowDetail - State setter function to control the visibility of the detail review.
   * @param {function} setIsOpenCollapse - State setter function to control the collapse state.
   * @returns {void}
   */
  const handleOnClickDetailReview = () => {
    setShowDetail(true);
    setIsOpenCollapse(!isOpenCollapse);
  };

  /**
   * useEffect hook that triggers the retrieval of workflow traceability data by its ID.
   * It executes the provided callback function to fetch the traceability data when dependencies change.
   * @param {Function} getWorkflowTraceabilityById - The function responsible for fetching workflow traceability data by its ID.
   * @returns {void}
   */
  useEffect(() => {
    getWorkflowTraceabilityById();
  }, [getWorkflowTraceabilityById]);

  /**
   * useEffect hook that updates internal form state based on traceability data changes.
   * @param {Object|null|undefined} traceability - The traceability data object containing information about the record.
   * @param {string} traceability.record_uuid - The UUID of the record.
   * @param {string} traceability.record_number - The number of the record.
   * @param {string} traceability.form_uuid - The UUID of the associated form.
   * @param {string} traceability.form_name - The name of the associated form.
   * @param {Function} i18n.t - Function for translating internationalization keys.
   * @param {Function} setInternalForm - State setter function to update the internal form state.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(traceability) === false) {
      setInternalForm([
        {
          value: traceability.record_uuid,
          record_number: traceability.record_number,
          form_uuid: traceability.form_uuid,
          label: `${traceability.record_number} | ${traceability.form_name} ${i18n.t(
            "processFormInternal"
          )}`,
        },
      ]);
    }
  }, [traceability]);

  /**
   * useEffect hook that sets the selected option and updates external form selection based on the internal form state.
   * @param {Array} internalForm - The array containing internal form data.
   * @returns {void}
   */
  useEffect(() => {
    if (internalForm.length > 0) {
      const initialForm = internalForm[0];
      setSelectedOption(initialForm);
      setExternalFormSelected({
        recordUuid: initialForm.value,
        formUuid: initialForm.form_uuid,
        formName: initialForm.label,
      });
    }
  }, [internalForm]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that constructs a dynamic form array by combining internal and external form elements.
   * Updates the state with the constructed array whenever the 'formExternalList' prop changes.
   * @param {Array} formExternalList - The list of external forms to be integrated into the dynamic array.
   * @param {Array} internalForm - The list of internal forms to be included in the dynamic array.
   * @param {function} setFormExternalDinamic - State setter function for updating the dynamic form array.
   * @returns {void}
   */
  useEffect(() => {
    const elementsFormExternalList = [];
    formExternalList.forEach((form) => {
      elementsFormExternalList.push({
        form_uuid: form.form_uuid,
        value: form.record_uuid,
        label: `${form.record_number} | ${form.form_name} `,
      });
    });
    const concatenatedArray = [...internalForm, ...elementsFormExternalList];
    setFormExternalDinamic(concatenatedArray);
  }, [formExternalList]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingWorkflow === true) {
    return loaderComponent(true);
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody className="mb-4">
              <div>
                <Row>
                  <Col lg="5" md="4" sm="12" className="button-container">
                    <h5 className="font-weight-bold text-primary mb-2">
                      {traceability.process_name}
                    </h5>
                  </Col>
                  <Col lg="7" md="8" sm="12" className="button-container mt-2 mt-md-0">
                    <Row className="row justify-content-end">
                      <Col lg="auto" md="6" sm="12" className="align-self-end">
                        <Button
                          className="ml-auto btn-icon mr-2 "
                          color="cyan"
                          type="submit"
                          onClick={() => window.history.back()}
                        >
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                          {i18n.t("form.field59")}
                          {spinnerButton}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md="7" className="pl-3">
                    <h6 className="mb-3">
                      <span className="font-weight-bold text-cyan">
                        {i18n.t("taskDetail1")} : &nbsp;
                      </span>
                      <span className="font-weight-bold text-15">{traceability.consecutive}</span>
                    </h6>

                    <h6 className="mb-0">
                      <span className="font-weight-bold text-cyan ">
                        {i18n.t("filteruser.button")} : &nbsp;
                      </span>
                      {statusTaskBadge}
                    </h6>

                    <h6 className="mb-3">
                      <span className="font-weight-bold text-cyan">
                        {i18n.t("createWorflow.Label4")} : &nbsp;
                      </span>
                      <span className="font-weight-bold text-15">
                        {getDateFormat3(new Date(traceability.created_at))}
                      </span>
                    </h6>

                    <h6>
                      <span className="font-weight-bold text-cyan">
                        {i18n.t("RecordDetail.Heading")} :
                      </span>
                    </h6>

                    <Row className="mt-2 ">
                      <Col md="10">
                        <Select
                          id="selectForm"
                          name="selectForm"
                          maxMenuHeight={enumSizeSelect.seeThreeItems}
                          menuPlacement="auto"
                          closeMenuOnSelect={true}
                          isSearchable={true}
                          hideSelectedOptions={true}
                          components={makeAnimated()}
                          placeholder={i18n.t("form.field75")}
                          options={formExternalDinamic}
                          noOptionsMessage={() => i18n.t("createRoles.configNoForms")}
                          onChange={handleOnChangedetailsFormsSelect}
                          value={selectedOption}
                        />
                      </Col>
                      <Col md="2">
                        <Button
                          color="info"
                          className="mr-auto btn-icon btn-block"
                          onClick={handleOnClickDetailReview}
                          aria-controls="collapseOne"
                          size="lg"
                        >
                          {iconButtonReview}
                          {i18n.t("trdExpedientList8")}
                        </Button>
                      </Col>
                    </Row>
                    <Collapse
                      md="12"
                      className="pt-2 pb-3"
                      isOpen={isOpenCollapse}
                      data-parent="#accordion"
                      id="collapseOne"
                      aria-labelledby="headingOne"
                    >
                      {collapseRecordContent}
                    </Collapse>
                    <div className="mt-4">
                      <WorkflowTraceabilityTaskListTable task={task} />
                    </div>
                  </Col>

                  <Col md="5" className="task-detail-card-second-responsive">
                    <div className="padding-container-next">
                      <WorkflowTraceabilityFilesListTable id={id} />
                    </div>
                    <div className="padding-container-next">
                      <WorkflowTraceabilityComments id={id} />
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default WorkflowTraceabilityDetails;
