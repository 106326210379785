import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Button, Card, CardBody, Col, CustomInput, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { enumsPermissions, enumsUserRolesConfig } from "utils/enums";
import { useRolesContext } from "contextAPI/RolesContext";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { INTEGRATIONS } from "constants/securityConst";
import Loader from "react-loaders";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";

const UserRolesDetailPermissions = (props) => {
  const { formDetail } = props;
  const { hash } = useParams();
  const rolName = JSON.parse(window.atob(hash));
  const { getTreeExist } = useTrd();
  const { setComponentToShow } = useRolesContext();
  const permissionsAdded = formDetail.permissions;
  const [hasTrd, setHasTrd] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [permissionsForm] = useState({
    add_register: permissionsAdded.includes(enumsPermissions.ADD_RECORD),
    view_register: permissionsAdded.includes(enumsPermissions.VIEW_RECORD),
    edit_record: formDetail.permissions.includes(enumsPermissions.EDIT_RECORDS),
    upload_files: permissionsAdded.includes(enumsPermissions.UPLOAD_FILE),
    create_expedient: permissionsAdded.includes(enumsPermissions.CREATE_EXPEDIENT),
    set_expedient_path: permissionsAdded.includes(enumsPermissions.SET_EXPEDIENT),
    delete_expedient_path: permissionsAdded.includes(enumsPermissions.REMOVE_EXPEDIENT),
    view_files: permissionsAdded.includes(enumsPermissions.VIEW_FILE),
    notifications: permissionsAdded.includes(enumsPermissions.ADD_NOTIFICATION),
    annulment: permissionsAdded.includes(enumsPermissions.CANCEL_RECORD),
    start_workflow: permissionsAdded.includes(enumsPermissions.ADD_PROCESS),
    see_process: permissionsAdded.includes(enumsPermissions.VIEW_PROCESS),
    edit_file_data: formDetail.permissions.includes(enumsPermissions.EDIT_FILE_DATA),
    move_file: formDetail.permissions.includes(enumsPermissions.MOVE_FILE),
    move_to_trash: formDetail.permissions.includes(enumsPermissions.MOVE_FILE_TO_TRASH),
    sign_electronically: formDetail.permissions.includes(enumsPermissions.SIGN_ELECTRONICALLY),
  });
  const INTEGRATIONSOPT = window.localStorage.getItem(INTEGRATIONS);
  let expedietnPermissions = null;
  let elementToRender = null;
  let moveFilePermissions = null;
  let signFilepermissions = null;

  /**
   * Renders a checkbox input for electronic signature if the user has integration permissions.
   * @param {Object} INTEGRATIONSOPT - The object containing integration options.
   * @param {Object} enumsUserRolesConfig - The configuration object for user role enums.
   * @param {Function} handleOnChange - The function to handle change events on the checkbox.
   * @param {boolean} requiredCheck - Indicates if the checkbox is required.
   * @param {boolean} disabledCheck - Indicates if the checkbox is disabled.
   * @param {Object} i18n - The internationalization object for translations.
   * @param {Object} permissionsForm - The form object containing user permissions.
   * @returns {JSX.Element|null} - A JSX element for the electronic signature checkbox or null.
   */
  if (INTEGRATIONSOPT[1] === enumsUserRolesConfig.HAS_INTEGRATION) {
    signFilepermissions = (
      <Col sm={12}>
        <AvInput
          id="sign_electronically"
          name="sign_electronically"
          type="checkbox"
          tag={CustomInput}
          disabled
          label={i18n.t("signature.buttonActions")}
          value={permissionsForm.sign_electronically}
        />
      </Col>
    );
  }

  /**
   * Renders a set of permission checkboxes related to expedient actions if `hasTrd` is true.
   * @param {boolean} hasTrd - Indicates whether the user has the TRD (Temporary Records Destruction) feature enabled.
   * @param {function} handleOnChange - Event handler function to be executed when a checkbox value changes.
   * @param {boolean} requiredCheck - Determines whether the checkbox is required to be checked.
   * @param {boolean} activeSetExpedietnPath - Indicates whether the expedient path setting is currently active.
   * @param {object} i18n - Localization object containing translation functions.
   * @param {object} permissionsForm - Object containing permission settings for various expedient actions.
   * @returns {JSX.Element|null} Returns the JSX elements representing the expedient permissions checkboxes if `hasTrd` is true; otherwise, returns null.
   */
  if (hasTrd === true) {
    expedietnPermissions = (
      <div>
        <Col sm={12}>
          <AvInput
            id="create_expedient"
            name="create_expedient"
            type="checkbox"
            tag={CustomInput}
            disabled
            label={i18n.t("trd.formTittle6")}
            value={permissionsForm.create_expedient}
          />
        </Col>

        <Col sm={12}>
          <AvInput
            id="set_expedient_path"
            name="set_expedient_path"
            type="checkbox"
            tag={CustomInput}
            disabled
            label={i18n.t("trdExpedientSetPath")}
            value={permissionsForm.set_expedient_path}
          />
        </Col>

        <Col sm={12}>
          <AvInput
            id="delete_expedient_path"
            name="delete_expedient_path"
            type="checkbox"
            tag={CustomInput}
            disabled
            label={i18n.t("trdExpedientListSetPathQuit")}
            value={permissionsForm.delete_expedient_path}
          />
        </Col>
      </div>
    );

    moveFilePermissions = (
      <Col sm={12}>
        <AvInput
          id="move_file"
          name="move_file"
          type="checkbox"
          tag={CustomInput}
          disabled
          label={i18n.t("trd.moveFile1")}
          value={permissionsForm.move_file}
        />
      </Col>
    );
  }

  /**
   * Renders a dynamic element based on the value of `showLoader`.
   * If `showLoader` is true, a loading spinner is displayed.
   * Otherwise, a detailed form configuration section is rendered.
   * @param {boolean} showLoader - Indicates whether to display a loading spinner.
   * @param {string} i18n.t - A function used for translating text.
   * @param {string} rolName - The name of the role.
   * @param {object} formDetail - Details of the form being configured.
   * @param {object} permissionsForm - Object containing permissions settings for the form.
   * @param {boolean} isLoading - Indicates whether data is loading.
   * @param {function} handleOnChange - Event handler function for checkbox changes.
   * @param {ReactNode} expedietnPermissions - Custom ReactNode representing additional permissions.
   * @param {ReactNode} infoCheckWorkflow - Custom ReactNode providing workflow-related information.
   * @param {function} setComponentToShow - State setter function to control which component is displayed.
   * @returns {JSX.Element} The JSX element representing the rendered content.
   */
  if (showLoader === true) {
    elementToRender = (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader type="ball-pulse-rise" />
      </div>
    );
  } else {
    elementToRender = (
      <div>
        <div className="row justify-content-end mr-1 mb-2">
          <Button className="btn-icon" color="cyan" onClick={() => setComponentToShow("0")}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
            {i18n.t("createRoles.configDesignWorkflowsBack")}
          </Button>
        </div>
        <Alert className="mbg-3" color="info">
          <Col>
            <Row>
              <span className="pr-2">{i18n.t("createRoles.Table.tr1")}:</span>
              <span className="pr-2 font-weight-bold">{rolName}</span>
            </Row>
            <Row>
              <span className="pr-2">{i18n.t("createWorflow.form")}:</span>
              <span className="pr-2 font-weight-bold">{formDetail.form_name}</span>
            </Row>
          </Col>
        </Alert>
        <em className="text-primary text-capitalize font-weight-bold">
          {i18n.t("createRoles.configFormOptions")}
        </em>

        <br />
        <Fragment>
          <Col sm={12}>
            <AvInput
              id="add_register"
              name="add_register"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("createRoles.configFormOption1")}
              value={permissionsForm.add_register}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="view_register"
              name="view_register"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("createRoles.configFormOption2")}
              value={permissionsForm.view_register}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="edit_record"
              name="edit_record"
              type="checkbox"
              checked={permissionsForm.edit_record}
              tag={CustomInput}
              disabled
              label={"Editar radicados"}
              value={permissionsForm.edit_record}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="upload_files"
              name="upload_files"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("recordDetail.upload")}
              value={permissionsForm.upload_files}
            />
          </Col>

          {expedietnPermissions}

          <Col sm={12}>
            <AvInput
              id="view_files"
              name="view_files"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("createRoles.configFormOption3")}
              value={permissionsForm.view_files}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="notifications"
              name="notifications"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("notifications.option")}
              value={permissionsForm.notifications}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="annulment"
              name="annulment"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("RecordDetail.label4")}
              value={permissionsForm.annulment}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="start_workflow"
              name="start_workflow"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("createRoles.configStartProcess")}
              value={permissionsForm.start_workflow}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="see_process"
              name="see_process"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("createRoles.configSeeProcess")}
              value={permissionsForm.see_process}
            />
          </Col>
        </Fragment>
        <br />

        <em className="text-primary text-capitalize font-weight-bold pt-4 mt-4">
          {i18n.t("recordDetail.ActionsFiles")}
        </em>
        <Fragment className="pt-4">
          <Col sm={12}>
            <AvInput
              id="edit_file_data"
              name="edit_file_data"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("recordDetail.ModifyFiles")}
              value={permissionsForm.edit_file_data}
            />
          </Col>

          {moveFilePermissions}

          <Col sm={12}>
            <AvInput
              id="move_to_trash"
              name="move_to_trash"
              type="checkbox"
              tag={CustomInput}
              disabled
              label={i18n.t("recordDetail.MoveToTrashFiles")}
              value={permissionsForm.move_to_trash}
            />
          </Col>

          {signFilepermissions}
        </Fragment>
      </div>
    );
  }

  /**
   * useEffect hook that sets up a timer to hide a loader after a specified delay.
   * This effect is useful for scenarios where you want to display a loader for a certain
   * duration before hiding it automatically.
   * This effect utilizes setTimeout to trigger the hiding of a loader after a specified delay.
   * It also includes cleanup logic to clear the timeout when the component unmounts or when
   * the dependencies change, preventing potential memory leaks.
   * @listens SomeComponent#componentDidMount
   * @listens SomeComponent#componentWillUnmount
   * @param {function} setShowLoader - State setter function to control the visibility of the loader.
   *   It is used to hide the loader after the specified delay.
   * @returns {void}
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      getTreeExist()
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setHasTrd(response.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setShowLoader(false);
        });
    }, 1200);
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardBody>
            <AvForm>{elementToRender}</AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserRolesDetailPermissions;
