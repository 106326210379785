import React from "react";
import { Button } from "reactstrap";
import { years, months } from "utils/yearsMonthsDatePicker";
import { getMonth, getYear } from "date-fns";
import cx from "classnames";
import i18n from "locales/i18n";

/**
 * Renders a custom header for the date picker component.
 * @param {Object} params - The parameters for the custom header.
 * @param {Date} params.date - The current date.
 * @param {Function} params.changeYear - Function to change the year.
 * @param {Function} params.changeMonth - Function to change the month.
 * @param {Function} params.decreaseMonth - Function to decrease the month.
 * @param {Function} params.increaseMonth - Function to increase the month.
 * @param {boolean} params.prevMonthButtonDisabled - Indicates if the previous month button is disabled.
 * @param {boolean} params.nextMonthButtonDisabled - Indicates if the next month button is disabled.
 * @returns {JSX.Element} The custom header component.
 */
export const renderCustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className="container-datapicker">
    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
      {"<"}
    </button>
    <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    <select
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
      {">"}
    </button>
  </div>
);

/**
 * CustomInputComponent renders a button with customizable text and styles.
 * @param {Object} props - The properties object.
 * @param {string} props.value - The current value of the input.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @param {React.Ref} props.ref - The reference object to access the button DOM element.
 * @param {boolean} props.validateStyle - The flag to determine if the validation style should be applied.
 * @param {string} props.color - The color of the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const CustomInputComponent = React.forwardRef(
  ({ value, onClick, validateStyle, color }, ref) => {
    let placeholderDate;
    if (value === "") {
      placeholderDate = (
        <span className="hide-textBox d-block text-left">{i18n.t("filterQuery.datapicker")}</span>
      );
    } else {
      placeholderDate = <span className="hide-textBox d-block text-left">{value}</span>;
    }

    return (
      <Button
        className={cx("text-left-3", { "text-left-3 validate-border": validateStyle })}
        block
        type="button"
        color={color}
        size="sm"
        onClick={onClick}
        ref={ref}
      >
        {placeholderDate}
      </Button>
    );
  }
);

/**
 * CustomInputComponent renders a button with customizable text and styles.
 * @param {Object} props - The properties object.
 * @param {string} props.value - The current value of the input.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @param {React.Ref} props.ref - The reference object to access the button DOM element.
 * @param {boolean} props.validateStyle - The flag to determine if the validation style should be applied.
 * @param {string} props.color - The color of the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const CustomInputGeneralStartDate = React.forwardRef(
  ({ value, onClick, validateStyle, color }, ref) => {
    let placeholderDate;
    if (value === "") {
      placeholderDate = (
        <span className="hide-textBox d-block text-left">
          {i18n.t("processManagement.StartDate")}
        </span>
      );
    } else {
      placeholderDate = <span className="hide-textBox d-block text-left">{value}</span>;
    }

    return (
      <Button
        className={cx("text-left-3", { "text-left-3 validate-border": validateStyle })}
        block
        type="button"
        color={color}
        size="sm"
        onClick={onClick}
        ref={ref}
      >
        {placeholderDate}
      </Button>
    );
  }
);

/**
 * CustomInputComponent renders a button with customizable text and styles.
 * @param {Object} props - The properties object.
 * @param {string} props.value - The current value of the input.
 * @param {function} props.onClick - The function to be called when the button is clicked.
 * @param {React.Ref} props.ref - The reference object to access the button DOM element.
 * @param {boolean} props.validateStyle - The flag to determine if the validation style should be applied.
 * @param {string} props.color - The color of the button.
 * @returns {JSX.Element} The rendered button component.
 */
export const CustomInputGeneralEndDate = React.forwardRef(
  ({ value, onClick, validateStyle, color }, ref) => {
    let placeholderDate;
    if (value === "") {
      placeholderDate = (
        <span className="hide-textBox d-block text-left">
          {i18n.t("processManagement.EndDate")}
        </span>
      );
    } else {
      placeholderDate = <span className="hide-textBox d-block text-left">{value}</span>;
    }

    return (
      <Button
        className={cx("text-left-3", { "text-left-3 validate-border": validateStyle })}
        block
        type="button"
        color={color}
        size="sm"
        onClick={onClick}
        ref={ref}
      >
        {placeholderDate}
      </Button>
    );
  }
);
