import React from "react";
import RecordTraceability from "./RecordTraceability";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";
export default () => {
    return (
      <RecordDetailContextProvider>
        <WorkflowContextProvider>
          <RecordTraceability />
        </WorkflowContextProvider>
      </RecordDetailContextProvider>
    );
  };
  