import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const ButtonActionsWorkflow = (props) => {
  const {
    workflowId,
    processName,
    statusProcess,
    formId
  } = props;
  const obj = {
    name: processName,
    status: statusProcess,
  };
  const expObj = JSON.stringify(obj);
  const hash_expObj = window.btoa(expObj);
  const formIdObject = {
    formId: formId
  };
  const formIdExp = JSON.stringify(formIdObject);
  const formId_hash = window.btoa(formIdExp);

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem>
          <Link to={`/workflow/edit/${workflowId}`}>
            <i className="dropdown-icon lnr-pencil"> </i>
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/workflow/steps/${workflowId}/${hash_expObj}/${formId_hash}`}>
            <i className="dropdown-icon lnr-eye"> </i>
            <span>{i18n.t("createWorkflow.config")}</span>
          </Link>
        </DropdownItem>
        
        <DropdownItem>
          <Link to={`/workflow/view/${workflowId}`}>
            <i className="dropdown-icon lnr-eye"> </i>
            <span>{i18n.t("createWorkflowView.link")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsWorkflow.propTypes = {
  workflowId: PropTypes.string.isRequired,
  processName: PropTypes.string.isRequired,
  statusProcess: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

export default ButtonActionsWorkflow;
