import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";
import { enumsExportRecords } from "utils/enums";
import i18n from "locales/i18n";

const FilterExportRecords = (props) => {
  const {
    setStatus,
    pagination,
    setPagination,
  } = props;

  /**
   * Handles the change event for filtering form states.
   * This function updates the status based on the selected filter state and resets
   * the pagination to the first page.
   * @param {Object} eventFilterFormState - The event object from the filter state change.
   * @param {Object} eventFilterFormState.target - The target element of the event.
   * @param {string} eventFilterFormState.target.value - The new value of the filter state.
   * @param {Function} setStatus - The state setter function to update the status.
   * @param {Function} setPagination - The state setter function to update the pagination.
   * @param {Object} pagination - The current pagination state.
   * @param {number} pagination.page - The current page number.
   * @returns {void}
   */
  const handleOnChangeFilterState = (eventFilterFormState) => {
    setStatus(eventFilterFormState.target.value);
    setPagination({ ...pagination, page: 1 })
  };

  return (
    <Fragment>
      <div className="p-relative custom__dropdown">
        <ButtonDropdown
          className=" mt-2 mb-2 mr-2 btn-icon btn-block"
        >
          <UncontrolledButtonDropdown >
            <DropdownToggle caret className="mr-2 status-filter-record" color="cyan">
              {i18n.t("filteruser.button")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
            <DropdownItem value={enumsExportRecords.ALL} onClick={handleOnChangeFilterState}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={enumsExportRecords.IN_PROGRESS} onClick={handleOnChangeFilterState}>
                {i18n.t("createWorflow.Label3.21")}
              </DropdownItem>
              <DropdownItem value={enumsExportRecords.FINISHED} onClick={handleOnChangeFilterState}>
                {i18n.t("export.records.status2")}
              </DropdownItem>
              <DropdownItem value={enumsExportRecords.FAILED} onClick={handleOnChangeFilterState}>
                {i18n.t("export.records.status3")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </ButtonDropdown>
      </div>
    </Fragment>
  );
};

FilterExportRecords.propTypes = {
  setStatus: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default FilterExportRecords;
