import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody, CardFooter } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import ButtonCreateFund from "components/atoms/ButtonCreateFund";
import Splitter from "m-react-splitters";
import TrdTree from "components/organism/TrdTree";
import TrdForm from "components/organism/TrdForm";

const TrdGeneral = () => {
  const { showRightPanel } = useTrdContext(false);

  let splitterSize = null;
  let renderRightPanel = null;

  /**
   * Conditionally renders the right panel based on the visibility flag `showRightPanel`.
   * @param {boolean} showRightPanel - A flag indicating whether the right panel should be rendered.
   * @returns {React.Element | null} - The rendered right panel wrapped in a `div` and `Card` components if `showRightPanel` is true; otherwise, `null`.
   */
  if (showRightPanel === true) {
    renderRightPanel = (
      <div className="he-100">
        <Card className="card-border he-100">
          <TrdForm />
        </Card>
      </div>
    );
  };

  /**
   * Determines the size of a splitter based on the visibility of the right panel.
   * @param {boolean} showRightPanel - A flag indicating whether the right panel is shown.
   * @returns {string} The size of the splitter as a percentage. Returns "50%" if the right panel is shown, otherwise "100%".
   */
  if (showRightPanel === true) {
    splitterSize = "50%";
  } else {
    splitterSize = "100%";
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="btn-actions-pane-right">
            <ButtonCreateFund />
          </CardFooter>
          <CardBody>
            <div className="app-inner-layout rm-sidebar">
              <div className="app-inner-layout__wrapper">
                <div className="app-inner-layout__content ">
                  <Splitter
                    position="horizontal"
                    primaryPaneMaxHeight="auto"
                    primaryPaneMinHeight={0}
                    primaryPaneHeight="60px"
                    dispatchResize={true}
                    postPoned={false}
                  >
                    <Splitter
                      position="vertical"
                      primaryPaneMaxWidth="80%"
                      primaryPaneMinWidth={splitterSize}
                      primaryPaneWidth="400px"
                      dispatchResize={true}
                      postPoned={false}
                    >
                      <div className="he-100">
                        <Card color="gray" className="card-border he-100 p-1">
                          <TrdTree />
                        </Card>
                      </div>
                      {renderRightPanel}
                    </Splitter>
                  </Splitter>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default TrdGeneral;
