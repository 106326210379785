export const settingForgottenPassword = {
  dots: true,
  infinite: true,
  speed: 2000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
}

export const settingAnimationLogin = {
  dots: true,
  infinite: true,
  speed: 3000,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};