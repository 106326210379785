import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import ChangeOrderField from "components/organism/ChangeOrderField";

const ChangeOrderFields = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("form.fieldHeading")}
				subheading={i18n.t("form.fieldSubheading")}
				icon="next-2"
			>
				<ChangeOrderField />
			</AdminTemplate>
		</Fragment>
	)
}

export default ChangeOrderFields