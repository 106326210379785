import React from "react";
import PropTypes from "prop-types";
import { Button, CardBody, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getDateFormat2 } from "utils/getDateFormat2";
import {
  enumsFieldsTyData,
  enumsFieldsTypeControl,
  enumsFilterFormsRecords
} from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const FilterFormsRecordsTable = (props) => {
  const { queriesTemp, deleteQueries, isLoading } = props;
  let alertMaxQuery = null;

  /**
   * Creates an alert component based on the number of queries in the 'queriesTemp' array.
   * This function generates an alert component to inform the user when a specific condition is met.
   * @param {Array} queriesTemp - An array containing queries.
   * @returns {JSX.Element|null} - The alert component if the length of 'queriesTemp' is equal to 3; otherwise, null.
   */
  if (queriesTemp.length === 3) {
    alertMaxQuery = (
      <Alert className="mbg-3" color="info" isOpen={true}>
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("filterQuery.More3recordsMessage")}
      </Alert>
    );
  } else {
    alertMaxQuery = null;
  }

  return (
    <CardBody>
      {alertMaxQuery}
      <ReactTable
        data={queriesTemp}
        columns={[
          {
            columns: [
              {
                Header: i18n.t("filterQuery.operator"),
                accessor: "operator",
                Cell: ({ value }) => {
                  let statusFinal = "";
                  if (value === null || value === "" || value === undefined) {
                    statusFinal = <span>N/A</span>;
                  } else if (value === "and") {
                    statusFinal = (
                      <span>{i18n.t("filterQuery.operatorAnd")}</span>
                    );
                  } else if (value === "or") {
                    statusFinal = (
                      <span>{i18n.t("filterQuery.operatorOR")}</span>
                    );
                  }
                  return <span>{statusFinal}</span>;
                },
              },
              {
                Header: i18n.t("filterQuery.Field1"),
                accessor: "label",
                Cell: (row) => {
                  if (row.original.data_type === enumsFieldsTypeControl.NUMERIC_TEXBOX) {
                    return <span>{i18n.t("filterQuery.Field1Option1")}</span>;
                  } else {
                    return <span>{row.original.label}</span>;
                  }
                },
              },
              {
                Header: i18n.t("filterQuery.Field2"),
                accessor: "filter",
                Cell: (row) => {
                  if (
                    (row.original.data_type === enumsFieldsTyData.DATE &&
                      row.original.data_control === enumsFieldsTypeControl.DATA_PICKER) ||
                    (row.original.data_type === enumsFieldsTyData.TIME_AND_DATE &&
                      row.original.data_control === enumsFieldsTypeControl.TIME_AND_DATE_PICKER) ||
                    (row.original.data_type === enumsFieldsTyData.INTEGER &&
                      row.original.data_control === enumsFieldsTypeControl.NUMERIC_TEXBOX)
                  ) {
                    let statusFinalDate = "";
                    switch (row.original.filter) {
                      case enumsFilterFormsRecords.EQUAL:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option1")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.NOT_EQUAL:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option3")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.START_WITH:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option4")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.ENDS_IN:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option5")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.CONTAIN:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option6")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.IS_LESS_THAN:
                        statusFinalDate = (
                          <span>{i18n.t("filterQuery.Field2Option7")}</span>
                        );
                        break;
                      default:
                        break;
                    }
                    return <span>{statusFinalDate}</span>;
                  } else {
                    let statusFinal = "";
                    switch (row.original.filter) {
                      case enumsFilterFormsRecords.EQUAL:
                        statusFinal = (
                          <span>{i18n.t("filterQuery.Field2Option1")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.NOT_EQUAL:
                        statusFinal = (
                          <span>{i18n.t("filterQuery.Field2Option3")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.START_WITH:
                        statusFinal = (
                          <span>{i18n.t("filterQuery.Field2Option8")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.ENDS_IN:
                        statusFinal = (
                          <span>{i18n.t("filterQuery.Field2Option2")}</span>
                        );
                        break;
                      case enumsFilterFormsRecords.CONTAIN:
                        statusFinal = (
                          <span>{i18n.t("filterQuery.Field2Option9")}</span>
                        );
                        break;
                      default:
                        break;
                    }
                    return <span>{statusFinal}</span>;
                  }
                },
              },
              {
                Header: i18n.t("filterQuery.Field3"),
                accessor: "value",
                Cell: (row) => {
                  if (
                    row.original.data_type === enumsFieldsTyData.DATE &&
                    row.original.data_control === enumsFieldsTypeControl.DATA_PICKER
                  ) {
                    let valueFinal = row.original.value;

                    if (valueFinal) {
                      return <span>{valueFinal}</span>;
                    } else {
                      return <span>{""}</span>;
                    }
                  } else if (
                    row.original.data_type === enumsFieldsTyData.TIME_AND_DATE &&
                    row.original.data_control === enumsFieldsTypeControl.TIME_AND_DATE_PICKER
                  ) {
                    let valueFinal = getDateFormat2(
                      new Date(row.original.value)
                    );

                    if (valueFinal) {
                      return <span>{valueFinal}</span>;
                    } else {
                      return <span>{""}</span>;
                    }
                  } else return row.original.value;
                },
              },
              {
                Header: i18n.t("createRoles.MembersDelete"),
                accessor: "value",
                Cell: ({ value }) => {
                  return (
                    <div className="text-center">
                      <Button
                        className="btn-icon text-primary"
                        size="lg"
                        color="link"
                        onClick={() => deleteQueries(value)}
                      >
                        <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                          <div>
                            <i className="lnr-icon lnr-trash text-primary " />
                          </div>
                        </div>
                      </Button>
                    </div>
                  );
                },
              },
            ],
          },
        ]}
        showPagination={false}
        loading={isLoading}
        pageSize={queriesTemp.length}
        noDataText={false}
        className="-striped -highlight"
      />
    </CardBody>
  );
};

FilterFormsRecordsTable.propTypes = {
  queriesTemp: PropTypes.array.isRequired,
  deleteQueries: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default FilterFormsRecordsTable;
