import React, { useEffect, Fragment } from "react";
import Tree from "@naisutech/react-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useTrdContext } from "contextAPI/TrdContext";
import { enumLevelTrd } from "utils/enums";

function TrdTree() {
  const {
    isLoading,
    fundName,
    setLevelFolder,
    setShowRightPanel,
    setShowFormConditional,
    setTrdForm,
    setShowFolderCondition,
    setShowCreateExpedientForm,
    structureTrd,
    foldersId,
    setFoldersId,
    setParentData,
    validateSerieinSubserie,
    setShowCreateSubDivisonForm,
    setSelectLevel,
    setCurrentLevelSelected,
    setShowCreateDirectoryForm,
    setFundName,
    getTreeByDomainCallback,
    setHasSubseries,
    setHasExpedients,
  } = useTrdContext();

  /**
   *Validates the selected level and sets the respective folder ID and parent data.
   *@param {object} elementLevelTrd - The element that was selected.
   *@param {string} elementlevelTrd.id - The ID of the selected element.
   *@param {number} elementlevelTrd.level - The level of the selected element.
   *@returns {void}
   */
  const validateLevel = (elementlevelTrd) => {
    const { id, level } = elementlevelTrd;
    let validate = validateSerieinSubserie(level, id);
    switch (level) {
      case enumLevelTrd.BACKGROUND:
        setFoldersId({
          ...foldersId,
          backgroundId: id,
        });
        setParentData({ id: id, level: level });
        setShowFolderCondition(true);
        setShowFormConditional(false);
        setShowCreateExpedientForm(false);
        break;
      case enumLevelTrd.SECTION:
        setFoldersId({
          ...foldersId,
          sectionId: id,
        });
        setParentData({ id: id, level: level });
        setShowFolderCondition(true);
        setShowFormConditional(false);
        setShowCreateExpedientForm(false);
        break;
      case enumLevelTrd.SUBSECTION:
        setFoldersId({
          ...foldersId,
          subSectionId: id,
        });
        setParentData({ id: id, level: level });
        setShowFolderCondition(true);
        setShowFormConditional(false);
        setShowCreateExpedientForm(false);
        break;
      case enumLevelTrd.SERIE:
        setFoldersId({
          ...foldersId,
          serieId: id,
        });
        setParentData({ id: id, level: level - 1 });

        if (validate) {
          setShowCreateExpedientForm(false);
          setShowFormConditional(false);
          setShowFolderCondition(true);
        } else {
          setShowCreateExpedientForm(false);
          setShowFormConditional(false);
          setShowFolderCondition(true);
        }
        break;
      case enumLevelTrd.SUBSERIE:
        setFoldersId({
          ...foldersId,
          subSerieId: id,
        });
        setParentData({ id: id, level: level - 1 });
        if (validate) {
          setShowCreateExpedientForm(false);
          setShowFormConditional(false);
          setShowFolderCondition(true);
        } else {
          setShowCreateExpedientForm(false);
          setShowFormConditional(false);
          setShowFolderCondition(true);
        }
        break;
      case enumLevelTrd.EXPEDIENT:
        setFoldersId({
          ...foldersId,
          expedientId: id,
        });
        setParentData({ id: id, level: level - 1 });
        break;
      case enumLevelTrd.SUBDIVISION:
        setFoldersId({
          ...foldersId,
          subDivisionId: id,
        });
        setShowCreateSubDivisonForm(true);
        setShowCreateExpedientForm(false);
        setShowFormConditional(false);
        setShowFolderCondition(false);
        setShowCreateDirectoryForm(false);
        setParentData({ id: id, level: level - 1 });
        break;
      case enumLevelTrd.DIRECTORY:
        setFoldersId({
          ...foldersId,
          subDirectoryId: id,
        });
        setShowCreateExpedientForm(false);
        setShowCreateDirectoryForm(true);
        setParentData({ id: id, level: level - 1 });
        break;
      default:
        setShowFolderCondition(false);
        setShowCreateExpedientForm(false);
        setShowFormConditional(false);
        break;
    }
  };

  /**
   *A function that sets various state variables based on the level of a given component object from trd.
   *@param {object} elementlevelTrd - the component object containing the label, code, level, and id properties
   *@param {string} elementlevelTrd.label - the label text of the component
   *@param {string} elementlevelTrd.code - the code associated with the component
   *@param {number} elementlevelTrd.level - the level of the component
   *@param {number} elementlevelTrd.id - the id of the component
   */
  const validateLevelByComponent = (elementlevelTrd) => {
    const { label, code, level, id } = elementlevelTrd;
    switch (level) {
      case enumLevelTrd.BACKGROUND:
        setFundName(label);
        setTrdForm({ name: "", level: enumLevelTrd.BACKGROUND, code: code, id: id });
        break;
      case enumLevelTrd.SECTION:
        setFundName(label);
        setTrdForm({ name: "", level: enumLevelTrd.SECTION, code: code, id: id });
        break;
      case enumLevelTrd.SUBSECTION:
        setFundName(label);
        setTrdForm({ name: "", level: enumLevelTrd.SUBSECTION, code: code, id: id });
        break;
      case enumLevelTrd.SERIE:
        setLevelFolder(enumLevelTrd.SERIE);
        setTrdForm({ name: "", level: enumLevelTrd.SERIE, code: code, id: id });
        break;
      case enumLevelTrd.SUBSERIE:
        setLevelFolder(enumLevelTrd.SUBSERIE);
        setTrdForm({ name: "", code: code, level: enumLevelTrd.SUBSERIE, id: id });
        break;
      case enumLevelTrd.SUBDIVISION:
        setLevelFolder(enumLevelTrd.SUBDIVISION);
        setShowCreateExpedientForm(false);
        break;
      case enumLevelTrd.DIRECTORY:
        setLevelFolder(enumLevelTrd.DIRECTORY);
        setShowCreateExpedientForm(false);
        break;
      default:
        break;
    }
  };

  /**
   *Function that handles selecting an element from the trd tree and sets the corresponding state variables of the component.
   *@param {object} infoElementTrd - an object containing information about the selected element from the tree, including
   *its level, code, and whether it has subseries and/or expedients
   */
  const handleOnSelectElementTree = (infoElementTrd) => {
    console.log("infoElementTrd", infoElementTrd)
    setLevelFolder(infoElementTrd.level);
    setCurrentLevelSelected(infoElementTrd.level);
    setTrdForm({
      name: "",
      level: infoElementTrd.level,
      code: infoElementTrd.code,
    });
    setShowRightPanel(true);
    setHasSubseries(infoElementTrd.has_subseries);
    setHasExpedients(infoElementTrd.has_expedients);
    validateLevel(infoElementTrd);
    validateLevelByComponent(infoElementTrd);
    setSelectLevel(0);
  };

  /**
   *useEffect hook that fetches a tree structure by domain and sets the corresponding state variable of the component.
   *@param {function} getTreeByDomainCallback - the function that fetches the tree structure data by domain and sets
   *the corresponding state variable
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  console.log("structureTrd", structureTrd)

  return (
    <Fragment>
      <Button block className="mb-2 mr-2 btn-icon" color="cyan">
        <i className="lnr-apartment btn-icon-wrapper"> </i>
        {fundName}
      </Button>
      <Tree
        nodes={structureTrd}
        size="full"
        theme={"light"}
        isLoading={isLoading}
        onSelect={handleOnSelectElementTree}
        iconSet={{
          node: (
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className="three-icon"
            />
          ),
          file: (
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className="three-icon"
            />
          ),
        }}
      />
    </Fragment>
  );
}

export default TrdTree;
