import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import RecordsListTable from "components/organism/RecordsListTable";
import i18n from "locales/i18n";

const RecordList = () => {
    return (
        <Fragment>
            <AdminTemplate
                heading={i18n.t("recordList1")}
                subheading={i18n.t("recordList7")}
                icon="search"
            >
                <RecordsListTable/>
            </AdminTemplate>
        </Fragment>
    );
};

export default RecordList;