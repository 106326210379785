import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import ProcessManagementForm from "components/organism/ProcessManagementForm";
import i18n from "locales/i18n";

const ProcessManagement = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("processManagement.Header")}
        subheading={i18n.t("processManagement.SubHeader")}
        icon="network"
      >
        <ProcessManagementForm />
      </AdminTemplate>
    </Fragment>
  );
};

export default ProcessManagement;
