import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Card, CardFooter, CardBody } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import UserLogTable from "components/organism/UserLogTable";
import ButtonBackListUsers from "components/atoms/ButtonBackListUsers";
import useUser from "hooks/useUser";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { useParams } from "react-router-dom";

const UserLogs = () => {
  const { id } = useParams();
  const [listLogUsers, setListLogUsers] = useState([]);
  const [isLoadingLogUsers, setIsLoadingLogUsers] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const [totalPages, setTotalPages] = useState(0);

  const { getUserLogsById } = useUser();

  /**
   *Function that displays a sweetalert with a message indicating that there are no logs for the selected user.
   *@param {object} i18n - the i18n object used for translations
   */
  const showAlertNoUserLogs = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("log.user.alert"),
      icon: "info",
      timer: 4000,
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   *Function that retrieves logs by search and updates the list of logs, total pages and loading status accordingly.
   *@param {number} id - the id of the user
   *@param {object} pagination - the pagination object with page and per_page properties
   */
  const getLogsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingLogUsers(true);
    getUserLogsById(id, page, per_page)
      .then((response) => {
        if (response.data.items.length === 0) {
          showAlertNoUserLogs();
        }
        setListLogUsers(response.data.items);
        setTotalPages(response.data.pages);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingLogUsers(false);
      });
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *Executes the function to get the user logs by search criteria on component mount,
   *and re-runs whenever the function reference changes.
   *@param {Function} getLogsBySearch - The function to get the user logs by search criteria.
   *@param {Array} dependencies - The dependencies which trigger the execution of this effect when they change.
   *@returns {undefined}
   */
  useEffect(() => {
    getLogsBySearch();
  }, [getLogsBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <ButtonBackListUsers />
          </CardFooter>

          <CardBody>
            <UserLogTable
              listLogUsers={listLogUsers}
              pagination={pagination}
              isLoadingLogUsers={isLoadingLogUsers}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserLogs;
