import i18n from "locales/i18n";

export const levelClassificationTrd = (level) => {
  switch (level) {
    case 0:
      return i18n.t("trd.formTittle9");

    case 1:
      return i18n.t("trd.formTittle10");

    case 2:
      return i18n.t("trd.formTittle11");

    case 3:
      return i18n.t("trd.formTittle12");

    case 4:
      return i18n.t("trd.formTittle13");

    case 5:
      return i18n.t("trd.formTittle14");

    case 6:
      return i18n.t("trd.formTittle16");

    case 7:
      return i18n.t("trd.formTittle32.2");

    default:
      return "";
  }
};
