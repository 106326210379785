import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_DASHBOARD } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useUser() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };
  const getStorageConsumed = async () => {
    const response = await axios.get(`${URL_DASHBOARD}/storage-consumed/`, 
    {
      headers,
      endpointError: endpointError.dashboard.getStorageConsumed
    });
    return response.data;
  };

  const getTotalRecords = async () => {
    const response = await axios.get(
      `${URL_DASHBOARD}/forms-reports/monthly/`,
      {
        headers,
        endpointError: endpointError.dashboard.getTotalRecords
      }
    );
    return response.data;
  };

  const getRecordsByForm = async (year, order, page, per_page) => {
    const response = await axios.get(
      `${URL_DASHBOARD}/forms-reports/year/${year}/?sort=${order}&page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.dashboard.getRecordsByForm
      }
    );
    return response.data;
  };

  const getRecordsByYearMonth = async (year) => {
    const response = await axios.get(
      `${URL_DASHBOARD}/forms-reports/year/${year}/sum-total/`,
      {
        headers,
        endpointError: endpointError.dashboard.getRecordsByYearMonth
      }
    );
    return response.data;
  };

  return {
    getStorageConsumed,
    getTotalRecords,
    getRecordsByForm,
    getRecordsByYearMonth,
  };
}
