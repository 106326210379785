import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import cx from "classnames";
import i18n from "locales/i18n";

const SearchBox = (props) => {
  const [activeSearch, setActiveSearch] = useState(false);

  /**
   * This function handles the "Enter" key press event in the search input field.
   * It sets the search value to the value entered by the user and resets the page number
   * to 1 if pagination is defined in the props.
   * @param {Object} eventSearch - The key press event object for the search input field.
   * @returns {void}
   */
  const handleOnKeyPressForSearch = (eventSearch) => {
    if (eventSearch.key === "Enter") {
      props.setSearch(eventSearch.target.value);
      if (props.pagination !== undefined) {
        props.setPagination({ ...props.pagination, page: 1 });
      }
    }
  };

  /**
   * Handles the click event on the search button.
   * Change the 'activeSearch' state to show or hide the search field.
   * @param {boolean} activeSearch - The active search is the state for show the button.
   * @returns {void}
   */
  const handleOnClickOpenButton = () => {
    setActiveSearch(!activeSearch);
  };

  /**
   * Function that handles the click event of the button to close the search.
   * Clears the search value and changes the state of the "activeSearch" variable.
   * @param {boolean} activeSearch - The active search is the state for show the button.
   * @returns {void}
   */
  const handleOnClickCloseButton = () => {
    props.setSearch("");
    setActiveSearch(!activeSearch);
    props.setPagination({ ...props.pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div
          className={cx("search-wrapper", {
            active: activeSearch,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              className="search-input"
              placeholder={i18n.t("searchbox.placeholder")}
              onKeyPress={handleOnKeyPressForSearch}
            />
            <button className="search-icon" onClick={handleOnClickOpenButton}>
              <span />
            </button>
          </div>
          <button className="close" onClick={handleOnClickCloseButton} />
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SearchBox.propTypes = {
  setSearch: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    per_page: PropTypes.number.isRequired,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default SearchBox;
