import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { regexAcceptPrimaryCharacters } from "utils/regexExpressions";
import { enumsTaskComeFrom } from "utils/enums";
import i18n from "locales/i18n";

const ModalInterruptProcess = (props) => {
  const {
    isLoadingInterruptProcess,
    showModalInterrupt,
    handleOnCloseInterruptProcess,
    handleOnSubmitInterrupt,
    handleOnChangeInterruptMotive,
    interruptMotive,
    handleOnBlurFieldMotive,
    typeTask,
  } = props;
  let spinnerButtonInterrupt = null;
  let alertInterupt = i18n.t("create.work.interruptAlert");

  /**
   * Generates an alert message based on the type of task.
   * @param {string} typeTask - The type of task to determine the alert message. It should be one of the enumsTaskComeFrom values.
   * @param {string} enumsTaskComeFrom - An enumeration representing the possible sources of tasks.
   * @param {object} i18n - An internationalization object providing translation functionalities.
   * @returns {string} The generated alert message based on the type of task.
   */
  if (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK) {
    alertInterupt = i18n.t("create.work.interruptAlertBatch");
  }

  /**
   * Generates a spinner button component if the interrupt process is currently loading.
   * @param {boolean} isLoadingInterruptProcess - Indicates whether the interrupt process is currently loading.
   * @returns {JSX.Element|null} - Returns a spinner button component if the interrupt process is loading, otherwise returns null.
   */
  if (isLoadingInterruptProcess === true) {
    spinnerButtonInterrupt = (
      <Spinner size="sm" color="secondary" type="grow" />
    );
  }

  return (
    <Fragment>
      <Modal
        className="modal-custom"
        isOpen={showModalInterrupt}
        toggle={handleOnCloseInterruptProcess}
        backdrop={true}
        keyboard={true}
      >
        <ModalHeader>
          <h5 className="text-info font-weight-bold  font-weight-normal">
            {i18n.t("create.work.interruptTittle")}
          </h5>
        </ModalHeader>
        <AvForm onSubmit={handleOnSubmitInterrupt}>
          <ModalBody>
            <Alert className="mbg-3" color="info" isOpen={true}>
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {alertInterupt}
            </Alert>{" "}
            <AvGroup>
              <Label for="interrupt_motive" className="is-required">
                {i18n.t("create.work.interruptMotive")}
              </Label>
              <AvField
                onChange={handleOnChangeInterruptMotive}
                value={interruptMotive.reason}
                type="textarea"
                name="interrupt_motive"
                onBlur={handleOnBlurFieldMotive}
                validate={{
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("fieldRequired")}`,
                  },
                  pattern: {
                    value: regexAcceptPrimaryCharacters,
                    errorMessage: `${i18n.t("trd.Tooltip12")}`,
                  },
                  minLength: {
                    value: 4,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: 1000,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                  },
                }}
                autoComplete="off"
              ></AvField>
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              className="col-mt-3 mr-2"
              color="gray"
              onClick={handleOnCloseInterruptProcess}
            >
              {i18n.t("createusers.createButton2")}
            </Button>

            <Button
              type="submit"
              size="lg"
              disabled={isLoadingInterruptProcess}
              className="col-mt-3"
              color="cyan"
            >
              {spinnerButtonInterrupt}
              {i18n.t("modal.Done.footerButton")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

ModalInterruptProcess.propTypes = {
  showModalInterrupt: PropTypes.bool.isRequired,
  interruptMotive: PropTypes.object.isRequired,
  handleOnCloseInterruptProcess: PropTypes.func.isRequired,
  handleOnChangeInterruptMotive: PropTypes.func.isRequired,
  handleOnBlurFieldMotive: PropTypes.func.isRequired,
  isLoadingInterruptProcess: PropTypes.bool.isRequired,
  handleOnSubmitInterrupt: PropTypes.func.isRequired,
  typeTask: PropTypes.number.isRequired,
};

export default ModalInterruptProcess;
