import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Row,
  Col,
  Label,
  CardFooter,
  Spinner,
  Alert,
} from "reactstrap";
import useUser from "hooks/useUser";
import Select from "react-select";
import cx from "classnames";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";
import {
  enumsActionsTypes,
  enumsNotification,
  enumsOptionsFiles,
  enumsPriorityNotifications,
  enumsTypeStatusUser,
  enumsTypeUser,
  recordStatus,
} from "utils/enums";
import { getMonth, getYear, addYears } from "date-fns";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showAlertServiceError } from "utils/alerts";
import { NOTIFICATIONS, TYPE } from "constants/securityConst";
import { months } from "utils/yearsMonthsDatePicker";
import { useParams } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import { es, fr } from "date-fns/esm/locale";
import { regularExpresionAvFieldSubject } from "utils/regexExpressions";
import DatePicker from "react-datepicker";
import range from "lodash/range";
import * as moment from "moment";
import useWorkFlow from "hooks/useWorkFlow";
import swal from "sweetalert";
import NotificationsDetails from "components/organism/NotificationsDetails";
registerLocale("es", es);
registerLocale("fr", fr);

const Notifications = (props) => {
  const { id } = useParams();
  const NOTIFICATION = window.localStorage.getItem(NOTIFICATIONS);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const [search] = useState("");
  const { selectedOption } = useState([]);
  const [startDate, setStartDate] = useState("");
  const [isErrorDate, setIsErrorDate] = useState(false);
  const [listUsersDinamic, setListUsersDinamic] = useState([]);
  const [
    isLoadingOnSubmitNotification,
    setIsLoadingOnSubmitNotification,
  ] = useState(false);
  const [
    isErrorOnSubmitNotification,
    setIsErrorOnSubmitNotification,
  ] = useState(false);
  const [notifyCreate, setNotifyCreate] = useState({
    subject: "",
    priority: 0,
    link_files: 0,
    expiration_date: "",
    users_uuid: [],
    record_number: props.recordNumber,
    record_uuid: props.recordUuid,
    form_uuid: id,
  });
  const { getUsers } = useUser();
  const { createNotification } = useWorkFlow();

  let spinnerLoadingNotification = null;
  let requiredSelectOptionDate = null;
  let requiredSelectDate = null;
  let renderFormNotificationComponent = null;

  /**
   * Displays an error notification if there is an error on submit.
   * @param {boolean} isErrorOnSubmitNotification - Flag indicating if there is an error on submit.
   * @param {ReactElement|string|null} requiredSelectOptionDate - Element or message to be displayed when there is an error on submit.
   */
  if (isErrorOnSubmitNotification === true) {
    requiredSelectOptionDate = (
      <span className="text-danger text small"> {i18n.t("fieldRequired")} </span>
    )
  };

  /**
   * Displays an error notification if there is an error with the date.
   * @param {boolean} isErrorDate - Flag indicating if there is an error with the date.
   * @returns {ReactElement|null} - Element to be displayed when there is an error with the date, or null if no error.
   */
  if (isErrorDate === true) {
    requiredSelectDate = (
      <span className="text-danger text small"> {i18n.t("fieldRequired")} </span>
    )
  };

  /**
   * Renders a spinner loading notification if `isLoadingOnSubmitNotification` is true.
   * @param {boolean} isLoadingOnSubmitNotification - Flag indicating whether the loading notification should be displayed.
   * @returns {JSX.Element|null} The spinner element if `isLoadingOnSubmitNotification` is true, otherwise null.
   */
  if (isLoadingOnSubmitNotification === true) {
    spinnerLoadingNotification = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  const handleNotificationPriority = (eventCreateNotification) => {
    setNotifyCreate({
      ...notifyCreate,
      priority: Number(eventCreateNotification.target.value),
    });
  };

  const handleNotificationLinksFiles = (eventCreateNotification) => {
    setNotifyCreate({
      ...notifyCreate,
      link_files: Number(eventCreateNotification.target.value),
    });
  };

  /**
   * Returns the placeholder text if the value is an empty string, otherwise returns null.
   * @param {string} value - The value to be checked.
   * @param {Object} i18n - The internationalization object.
   * @returns {string|null} The placeholder text or null.
   */
  const getPlaceholderText = (value) => {
    if (value === "") {
      return i18n.t("dataTimepicker.placeHolder");
    }
  };

  /**
   *A custom input component for the date picker.
   *@param {string} value - The current selected date value.
   *@param {function} onClick - The function to call when the component is clicked.
   *@param {ref} ref - A ref object to reference the component.
   *@returns {JSX.Element} - Returns a Button element with a conditional placeholder and the current selected date value.
   */
  const CustomInputDatePicker = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      block
      className="text-left-3"
      type="button"
      size="lg"
      color="grayLigth"
      onClick={onClick}
      ref={ref}
    >
      {getPlaceholderText(value)}
      {value}
    </Button>
  ));
  const years = range(1950, getYear(new Date()) + 80, 1);
  /**
   * Callback function to retrieve a list of users by search term and sort it alphabetically by name and last name.
   * It then sets the filtered and sorted users list to the state variable listUsersDinamic.
   * Note that this function uses useCallback hook to memoize the function and avoid unnecessary re-renders.
   * @param {fucntion} getUsers - Function for get users to assign the task
   * @param {string} search - The search term used to filter the list of users.
   * @returns {Promise<void>}
   */
  const getUsersBySearch = useCallback(() => {
    let pagination = { page: 1, per_page: 100 };
    const { page, per_page } = pagination;
    getUsers(page, per_page, search).then((response) => {
      let listUsers = response.data.items;
      listUsers
        .sort((userFirstName, userLastName) => {
          const firstPositionUserFirstName = `${userFirstName.first_name} ${userFirstName.last_name}`.toLocaleLowerCase();
          const secondPositionUserLastName = `${userLastName.first_name} ${userLastName.last_name}`.toLocaleLowerCase();
          return firstPositionUserFirstName.localeCompare(secondPositionUserLastName);
        })
        .forEach((userToNotificate) => {
          if (userToNotificate.status !== enumsTypeStatusUser.LOCKED && userToNotificate.status !== enumsTypeStatusUser.INACTIVE) {
            setListUsersDinamic((prevState) => [
              ...prevState,
              {
                value: userToNotificate.uuid,
                label: `${userToNotificate.first_name} ${userToNotificate.last_name} (${userToNotificate.user_name})`,
              },
            ]);
          }
        });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for the date input field.
   * @param {Date[]} date - The selected date(s).
   */
  const handleOnChangeDate = (date) => {
    if (date.length === 0) {
      setIsErrorDate(true);
    } else {
      setStartDate(date);
      setNotifyCreate({
        ...notifyCreate,
        expiration_date: moment.utc(date).format("YYYY-MM-DDTHH:mm+00:00"),
      });
      setIsErrorDate(false);
    }
  };

  /**
   * Handles the change event for the users selection.
   * @param {Object[]} selectedOption - The selected option(s).
   */
  const handleOnChangeUsers = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorOnSubmitNotification(true);
    } else {
      setNotifyCreate({
        ...notifyCreate,
        users_uuid: selectedOption.map((user) => user.value),
      });
      setIsErrorOnSubmitNotification(false);
    }
  };

  /**
   * Handles the change event for input fields.
   * @param {Event} eventNotify - The change event object.
   */
  const handleOnChangeNotification = (eventNotify) => {
    setNotifyCreate({
      ...notifyCreate,
      [eventNotify.target.name]: eventNotify.target.value,
    });
  };

  /**
   * Handles the key press event for the notification input.
   * @param {KeyboardEvent} pressEnter - The key press event object.
   */
  const handleOnKeyPressNotification = (pressEnter) => {
    if (pressEnter.key === "Enter") {
      pressEnter.preventDefault();
    }
  };

  /**
   * Handles the submit event for the notification form.
   * @param {Event} eventNotifySubmit - The submit event object.
   * @param {string[]} errors - The array of validation errors.
   */
  const handleOnSubmitNotification = (eventNotifySubmit, errors) => {
    if (notifyCreate.users_uuid.length === 0) {
      setIsErrorOnSubmitNotification(true);
      setIsLoadingOnSubmitNotification(false);
      eventNotifySubmit.preventDefault();
    }
    if (notifyCreate.expiration_date === "") {
      setIsErrorDate(true);
      setIsLoadingOnSubmitNotification(false);
      eventNotifySubmit.preventDefault();
    }

    eventNotifySubmit.preventDefault();
    if (errors.length === 0) {
      if (
        notifyCreate.users_uuid.length === 0 ||
        notifyCreate.expiration_date === ""
      ) {
        eventNotifySubmit.preventDefault();
      } else {
        setIsLoadingOnSubmitNotification(true);
        let actions = enumsActionsTypes.ALL_ACTIONS;
        if (USERTYPE === enumsTypeUser.ADMIN) {
          actions = enumsActionsTypes.ALL_ACTIONS;
        } else if (props.isRecordCreate === true) {
          actions = enumsActionsTypes.ACTIONS_FOR_TYPE_USER;
        } else if (props.isRecordCreate === false) {
          actions = enumsActionsTypes.RESTRIC_USER;
        }
        createNotification(notifyCreate, actions)
          .then((response) => {
            if (response.status === 201) {
              const showAlertNotifyCreated = () => {
                swal({
                  title: `${i18n.t("notifications.Success")} ${response.data.data.consecutive
                    }`,
                  text: i18n.t("notifications.Success2"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(() => {
                  window.location.reload();
                });
              };
              showAlertNotifyCreated();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingOnSubmitNotification(false);
          });
      }
    }
  };

  /**
   *This useEffect hook runs when the component is mounted, and it depends on the memoized and cached getUsersBySearch function.
   *This hook calls the getUsersBySearch function to retrieve a list of users by search criteria when the component is mounted.
   *If the getUsersBySearch function changes, it will trigger the useEffect to run again and call the updated function.
   *@param {fucntion} getUsers - Function for get users to assign the task
   *@returns {void}
   */
  useEffect(() => {
    getUsersBySearch();
  }, [getUsersBySearch]);

  /**
   * Renders a form notification component if the record status is not ANNULLMENT, otherwise displays an informational alert.
   * @param {object} props - The properties object.
   * @param {string} props.status - The current status of the record.
   * @returns {ReactElement} - The rendered form notification component or an informational alert.
   */
  if (props.status !== recordStatus.ANULLMENT) {
    renderFormNotificationComponent = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <br />
        <div>
          <AvForm onSubmit={handleOnSubmitNotification}>
            <Alert className="mbg-3" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("RecordForm.Alert")}
            </Alert>

            <Row>
              <Col md="6">
                <AvGroup>
                  <Label for="subject" className="is-required">
                    {i18n.t("record.affair")}
                  </Label>
                  <AvField
                    id="subject"
                    name="subject"
                    type="text"
                    onChange={handleOnChangeNotification}
                    onKeyPress={handleOnKeyPressNotification}
                    validate={{
                      pattern: {
                        value: regularExpresionAvFieldSubject,
                        errorMessage: `${i18n.t("trd.Tooltip5")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                      minLength: {
                        value: enumsNotification.MIN_LENGTH,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 1 ${i18n.t("and")} 100 ${i18n.t(
                          "characters"
                        )}`,
                      },
                      maxLength: {
                        value: enumsNotification.MAX_LENGTH,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 1 ${i18n.t("and")} 100 ${i18n.t(
                          "characters"
                        )}`,
                      },
                    }}
                    autoComplete="off"
                    value={notifyCreate.subject}
                  />
                </AvGroup>

                <AvGroup>
                  <Label for="priority" className="is-required">
                    {i18n.t("notifications.priority")}
                  </Label>

                  <AvField
                    id="priority"
                    name="priority"
                    type="select"
                    onChange={handleNotificationPriority}
                    value={notifyCreate.priority}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                    }}
                  >
                    <option value="">
                      {i18n.t("notifications.filesOptions0")}
                    </option>
                    <option value={enumsPriorityNotifications.PRIORITY_HIGH}>
                      {i18n.t("notifications.priority1")}
                    </option>
                    <option value={enumsPriorityNotifications.PRIORITY_LOW}>
                      {i18n.t("notifications.priority2")}
                    </option>
                    <option value={enumsPriorityNotifications.PRIORITY_NORMAL}>
                      {i18n.t("notifications.priority3")}
                    </option>
                  </AvField>
                </AvGroup>

                <AvGroup>
                  <Label for="link_files" className="is-required">
                    {i18n.t("notifications.files")}
                  </Label>

                  <AvField
                    id="link_files"
                    name="link_files"
                    type="select"
                    onChange={handleNotificationLinksFiles}
                    value={notifyCreate.link_files}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                    }}
                  >
                    <option value="">
                      {i18n.t("notifications.filesOptions0")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_FILE}>
                      {i18n.t("notifications.filesOptions1")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_TWO}>
                      {i18n.t("notifications.filesOptions2")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_THREE}>
                      {i18n.t("notifications.filesOptions3")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_FOUR}>
                      {i18n.t("notifications.filesOptions4")}
                    </option>
                    <option value={enumsOptionsFiles.ALL_FILES}>
                      {i18n.t("notifications.filesOptions5")}
                    </option>
                    <option value={enumsOptionsFiles.UNLINK_FILES}>
                      {i18n.t("notifications.filesOptions6")}
                    </option>
                  </AvField>
                </AvGroup>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label
                    for="expiration_date"
                    className={cx("is-required", {
                      "labels-error": isErrorDate === true,
                    })}
                  >
                    {i18n.t("notifications.date")}
                  </Label>
                  <DatePicker
                    id="expiration_date"
                    name="expiration_date"
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="container-datapicker">
                        <button
                          type="button"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {"<"}
                        </button>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          type="button"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {">"}
                        </button>
                      </div>
                    )}
                    selected={startDate}
                    onChange={handleOnChangeDate}
                    required
                    customInput={<CustomInputDatePicker />}
                    locale={i18n.t("datapicker.language")}
                    showTimeSelect
                    timeIntervals={5}
                    fixedHeight
                    dateFormat="dd/MM/yyyy h:mm aa"
                    placeholderText={i18n.t(
                      "dataTimepicker.placeHolder"
                    )}
                    minDate={new Date()}
                    maxDate={addYears(new Date(), 2)}
                    className={cx("date__input-container ", {
                      "date_vaidate__input-container text-left-3":
                        isErrorOnSubmitNotification === true,
                    })}
                  />
                  {requiredSelectDate}
                </div>
                <Label
                  for="users_uuid"
                  className={cx("is-required", {
                    "labels-error": isErrorOnSubmitNotification === true,
                  })}
                >
                  {i18n.t("notifications.users")}
                </Label>

                <div>
                  <Select
                    id="users_uuid"
                    name="users_uuid"
                    className="select-notification"
                    classNamePrefix={cx("", {
                      select: isErrorOnSubmitNotification === true,
                    })}
                    placeholder={i18n.t(
                      "notifications.usersPlaceholder"
                    )}
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    isMulti
                    value={selectedOption}
                    options={listUsersDinamic}
                    onChange={handleOnChangeUsers}
                  />
                  {requiredSelectOptionDate}
                </div>
              </Col>
            </Row>
            <CardFooter className="d-block text-right">
              <Button
                type="submit"
                size="lg"
                disabled={isLoadingOnSubmitNotification}
                className="col-mt-3"
                color="cyan"
              >
                {spinnerLoadingNotification}
                {i18n.t("createusers.createButton")}
              </Button>
            </CardFooter>
          </AvForm>
        </div>
      </CSSTransitionGroup>
    );
  } else {
    renderFormNotificationComponent = (
      <div className="mt-3 ">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} :{" "}
            </span>
            <span>{i18n.t("RecordDetail.label7")}</span>
          </span>
        </Alert>
      </div>
    );
  };

  /**
   * Displays an alert notification if the user type is not "3" and the notification ID is not included in the notification list.
   * @param {string} USERTYPE - The type of user.
   * @param {Array<string>} NOTIFICATION - The list of notification IDs.
   * @param {string} id - The ID of the current notification.
   * @returns {ReactElement|null} - The alert notification element, or null if conditions are not met.
   */
  if (USERTYPE !== enumsTypeUser.ADMIN && NOTIFICATION.includes(id) === false) {
    return (
      <Alert color="info">
        <h4 className="alert-heading">
          {i18n.t("modal.DoneError.header")}
        </h4>
        <hr />
        <h6>{i18n.t("showNotification403")}</h6>
      </Alert>
    );
  };

  return (
    <Fragment>
      {renderFormNotificationComponent}
      <div className="divider" />
      <NotificationsDetails
        recordUuid={props.recordUuid}
        recordStatus={props.status}
      />
    </Fragment>
  );
};

Notifications.propTypes = {
  recordUuid: PropTypes.string.isRequired,
  isRecordCreate: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
};

export default Notifications;
