import React, { Fragment } from "react";
import i18n from "locales/i18n";
import Logo from "assets/images/logo-WMCloud.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { getYear } from "utils/getDateFormat";
const NotFound = () => {

  /**
   * The current year.
   * @type {number}
   */
  const currentYear = getYear();

  return (
    <Fragment>
      <div className="h-100 d-flex justify-content-center align-items-center bg-heavy-rain">
        <div>
          <div>
            <img src={Logo} alt="logo" />
          </div>
          <h1 className="d-flex h-center justify-content-center align-items-center">
            {i18n.t("page.notfound.message")}
          </h1>
          <p className="d-flex justify-content-center align-items-center">
            {i18n.t("page.notfound.message2")}
          </p>
          <br />
          <div className="d-flex h-center justify-content-center align-items-center">
            <Link to="/">
              <Button type="button" size="lg" className="col-mt-3" color="cyan">
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                {i18n.t("startup")}
              </Button>
            </Link>
            <Link to="/record/list/forms">
              <Button
                type="button"
                size="lg"
                className="col-mt-3 ml-3"
                color="cyan"
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("dashboard.label5")}
              </Button>
            </Link>
            <Link to="/record/list/form">
              <Button
                type="button"
                size="lg"
                className="col-mt-3 ml-3"
                color="cyan"
              >
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                {i18n.t("dashboard.label7")}
              </Button>
            </Link>
          </div>
          <div className="text-center opacity-8 mt-3">
            {i18n.t("copyright") + " " + currentYear + "®"}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default NotFound;
