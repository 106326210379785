import React from "react";
import RecordDetail from "./RecordDetail";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <RecordDetail isRecordCreate={false} />
    </RecordDetailContextProvider>
  );
};
