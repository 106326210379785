import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
  CardBody,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTrdContext } from "contextAPI/TrdContext";
import { trimExtraSpaces } from "utils/trimSpaces";
import { enumEditDirectory } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import useClassification from "hooks/useClassification";
import ReactTooltip from "react-tooltip";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const EditDirectoryForm = () => {
  const { setIsLoading, isLoading } = useClassification();
  const { id, backgroundId } = useParams();
  const { updateDirectoryStructure } = useTrd();

  const {
    editDirectoryFormSTR,
    setEditDirectoryFormSTR,
    validateField,
    setValidateField,
    setOptionDir,
    setLevelFolder,
    getSeriesSubseriesStructure,
  } = useTrdContext();

  let requiredValue;
  let loadinSpinnerEditDirectory = null;

  /**
   * Conditionally renders a loading spinner based on the value of `isLoading`. 
   * This code:
   * - Checks if `isLoading` is true.
   * - If true, it sets `loadinSpinnerEditDirectory` to a `Spinner` component with specific properties.
   * @param {boolean} isLoading - Flag indicating whether data is loading.
   * @returns {JSX.Element | null} The rendered `Spinner` component if `isLoading` is true, otherwise null.
   */
  if (isLoading === true) {
    loadinSpinnerEditDirectory = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };
  
/**
 * Opens the directory by setting the option directory state to true. 
 * This function:
 * - Calls `setOptionDir` with the value `true` to indicate that the directory is open.
 * @function
 * @returns {void}
 */
  const openDirectory = () => {
    setOptionDir(true);
  };

  /**
   * Determines whether a field is required based on the value of `validateField`. 
   * This code:
   * - Checks if `validateField` is true.
   * - If true, sets `requiredValue` to true.
   * - If false, sets `requiredValue` to false.
   * @param {boolean} validateField - Flag indicating whether validation is required.
   * @returns {boolean} The value of `requiredValue`, which is true if `validateField` is true, otherwise false.
   */
  if (validateField === true) {
    requiredValue = true;
  } else {
    requiredValue = false;
  }

  /**
   * Handle the change event of the input fields in the edit directory form.
   * @param {Event} eventEditDirectory - The change event.
   */
  const handleOnChangeEditDirectory = (eventEditDirectory) => {
    setValidateField(true);
    setEditDirectoryFormSTR({
      ...editDirectoryFormSTR,
      name: eventEditDirectory.target.value,
    });
  };

  /**
   * Handle the key press event in the edit directory form.
   * @param {Event} eventEditDirectory - The key press event.
   */
  const handleOnKeyPressEditDirectory = (eventEditDirectory) => {
    if (eventEditDirectory.key === "Enter") {
      eventEditDirectory.preventDefault();
    }
  };


  /**
   * Handle the blur event of the input fields in the edit directory form.
   * @param {Event} eventEditDirectory - The blur event.
   */
  const handleOnBlurEditDirectory = (eventEditDirectory) => {
    const { value } = eventEditDirectory.target;
    const trimmedValue = trimExtraSpaces(value);
    setEditDirectoryFormSTR({
      ...editDirectoryFormSTR,
      name: trimmedValue,
    });
  };

  /**
   * Handle the form submission in the edit directory form.
   * @param {Event} eventEditDirectory - The submit event.
   * @param {Array} errors - The array of validation errors.
   */
  const handleOnSubmitEditDirectory = (eventEditDirectory, errors) => {
    if (errors.length > 0) {
      eventEditDirectory.preventDefault();
      setValidateField(true);
      setIsLoading(false);
      return;
    }

    if (validateField === true) {
      setIsLoading(true);
      updateDirectoryStructure(editDirectoryFormSTR, backgroundId, id)
        .then((response) => {
          if (response.status === 202) {
            setValidateField(false);
            getSeriesSubseriesStructure(backgroundId, id);
            const showAlertEditDirectorySuccess = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("2011"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              });
            };
            showAlertEditDirectorySuccess();
          } else {
            showAlertServiceError();
          }
        })
        .catch(() => { })
        .finally((info) => {
          setIsLoading(false);
          setLevelFolder(Number(6));
          setOptionDir(true);
        });
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <CardTitle>{`${i18n.t("buttonActions.option2")} ${i18n.t(
            "trd.formTittle32.2"
          )}`}</CardTitle>
          <AvForm onSubmit={handleOnSubmitEditDirectory}>
            <AvGroup>
              <ReactTooltip
                id="name_subdivision"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.fromLabel2")}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeEditDirectory}
                onKeyPress={handleOnKeyPressEditDirectory}
                onBlur={handleOnBlurEditDirectory}
                data-tip
                data-for="name_subdivision"
                validate={{
                  pattern: {
                    value: "/^([A-Za-z0-9 À-ÿ-_]+)$/",
                    errorMessage: `${i18n.t("invalidField2")}`,
                  },
                  required: {
                    value: requiredValue,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: enumEditDirectory.MIN_LENGTH,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: enumEditDirectory.MAX_LENGTH,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                autoComplete="off"
                value={editDirectoryFormSTR.name}
              />

            </AvGroup>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={openDirectory}
              >
                {i18n.t("createusers.createButton2")}
              </Button>

              <Button
                type="submit"
                size="lg"
                disabled={isLoading}
                className="col-mt-3"
                color="cyan"
              >
                {loadinSpinnerEditDirectory}
                {i18n.t("createusers.createButton3")}
              </Button>
            </CardFooter>
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default EditDirectoryForm;
