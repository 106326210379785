import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_FORMS } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useRecord() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const createRecord = async (recordData, formId) => {
    const response = await axios.post(`${URL_FORMS}/form/forms/${formId}/records/`, recordData, {
      headers,
      endpointError: endpointError.record.createRecord,
    });
    return response;
  };

  const updateRecord = async (recordForm, formId, recordId) => {
    const response = await axios.put(
      `${URL_FORMS}/form/forms/${formId}/record/${recordId}/`,
      recordForm,
      {
        headers,
        endpointError: endpointError.record.updateRecord,
      }
    );
    return response;
  };

  const getRecords = async (formId, page, per_page) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/records/?page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.record.getRecords,
      }
    );
    return response.data;
  };

  const getRecordsById = async (formId, recordId, actions) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/records/${recordId}/?action=${actions}`,
      {
        headers,
        endpointError: endpointError.record.getRecordsById,
      }
    );
    return response.data;
  };

  const getFilesByForm = async (formId, actions, page, per_page, searchedSubject) => {
    let response;
    if (searchedSubject) {
      response = await axios.get(
        `${URL_FORMS}/file/form/${formId}/?action=${actions}&page=${page}&per_page=${per_page}&subject=${searchedSubject}`,
        {
          headers,
          endpointError: endpointError.record.getFilesByForm,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/file/form/${formId}/?action=${actions}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.record.getFilesByForm,
        }
      );
    }

    return response.data;
  };

  const getFilesByTRD = async (recordId, actions, page, per_page, searchedSubject) => {
    let response;
    if (searchedSubject) {
      response = await axios.get(
        `${URL_FORMS}/file/trd/?record_uuid=${recordId}&action=${actions}&page=${page}&per_page=${per_page}&subject=${searchedSubject}`,
        {
          headers,
          endpointError: endpointError.record.getFilesByTRD,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/file/trd/?record_uuid=${recordId}&action=${actions}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.record.getFilesByTRD,
        }
      );
    }

    return response.data;
  };

  const updateFileById = async (fileId, data) => {
    const response = await axios.patch(`${URL_FORMS}/file/trd/${fileId}/`, data, {
      headers,
      endpointError: endpointError.record.updateFileById,
    });
    return response.data;
  };

  const createFileTRD = async (recordId, folderId, formData, actions) => {
    const response = await axios.post(
      `${URL_FORMS}/file/trd/${folderId}/records/${recordId}/?action=${actions}`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileTRD,
      }
    );
    return response;
  };

  const createFileByRecord = async (recordId, formData, actions) => {
    const response = await axios.post(
      `${URL_FORMS}/file/form/${recordId}/?action=${actions}`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileByRecord,
      }
    );
    return response;
  };

  const createFileTRDByTask = async (taskId, parentId, recordId, formData) => {
    const response = await axios.post(
      `${URL_FORMS}/file/task/${taskId}/trd/${parentId}/records/${recordId}/`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileTRDByTask,
      }
    );
    return response;
  };

  const createFileByTaskRecord = async (taskId, recordId, formData) => {
    const response = await axios.post(
      `${URL_FORMS}/file/task/${taskId}/form/${recordId}/`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileByTaskRecord,
      }
    );
    return response;
  };

  const createFileTRDByProcess = async (taskId, parentId, recordId, formData) => {
    const response = await axios.post(
      `${URL_FORMS}/file/process/${taskId}/trd/${parentId}/records/${recordId}/`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileTRDByProcess,
      }
    );
    return response;
  };

  const createFileByProcessRecord = async (taskId, recordId, formData) => {
    const response = await axios.post(
      `${URL_FORMS}/file/process/${taskId}/form/${recordId}/`,
      formData,
      {
        headers,
        endpointError: endpointError.record.createFileByProcessRecord,
      }
    );
    return response;
  };

  const getRecordsByFilters = async (formId, page, per_page, data) => {
    const response = await axios.post(
      `${URL_FORMS}/form/forms/${formId}/records/search-filter/?page=${page}&per_page=${per_page}`,
      data,
      {
        headers,
        endpointError: endpointError.record.getRecordsByFilters,
      }
    );
    return response.data;
  };

  const getFreeTaskByRecord = async (recordUuid, page, per_page, search, status) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_FORMS}/task/records/${recordUuid}/free-tasks/?page=${page}&per_page=${per_page}&status=${status}`,
        {
          headers,
          endpointError: endpointError.record.getFreeTaskByRecord,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/task/records/${recordUuid}/free-tasks/?page=${page}&per_page=${per_page}&subject=${search}`,
        {
          headers,
          endpointError: endpointError.record.getFreeTaskByRecord,
        }
      );
    }

    return response.data;
  };

  const getNotifiedUsersById = async (free_task_uuid) => {
    const response = await axios.get(`${URL_FORMS}/task/${free_task_uuid}/notified-users/`, {
      headers,
      endpointError: endpointError.record.getNotifiedUsersById,
    });
    return response.data;
  };

  const moveTrdFileLocation = async (fileId, fileMoveInfo) => {
    const response = await axios.patch(`${URL_FORMS}/file/trd/${fileId}/move/`, fileMoveInfo, {
      headers,
      endpointError: endpointError.record.moveTrdFileLocation,
    });
    return response.data;
  };

  /**
   * Retrieves sticker information for a given record.
   * @async
   * @param {string} recordUuid - The unique identifier of the record.
   * @returns {Promise<Object>} A promise that resolves to the sticker information of the record.
   * @throws Will throw an error if the request fails.
   */
  const getStickerInfo = async (form_uuid, record_uuid) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${form_uuid}/record/${record_uuid}/sticker-data/`,
      {
        headers,
        endpointError: endpointError.record.getStickerInfo,
      }
    );
    return response.data;
  };

  /**
   * Moves a file to the trash by its ID and provides a reason for the action.
   * @param {string} fileId - The ID of the file to be moved to the trash.
   * @param {Object} reason - The reason for moving the file to the trash.
   * @param {string} reason.description - Description explaining why the file is being trashed.
   * @returns {Promise<Object>} - The response data from the server.
   * @throws {Error} - If the request fails.
   */
  const moveFileToTrash = async (fileId, reason) => {
    const response = await axios.post(`${URL_FORMS}/file/${fileId}/move-to-trash/`, reason, {
      headers,
      endpointError: endpointError.record.moveFileToTrash,
    });
    return response.data;
  };

  /**
   * Fetches trash data from the server with optional search and filter parameters.
   * @param {number} page - The page number to retrieve.
   * @param {number} per_page - The number of items per page.
   * @param {string} search - The search term to filter the results.
   * @param {string} [filter] - The date filter to apply (optional).
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  const getTrashData = async (page, per_page, search, filter, timeZone) => {
    let response;
    if (filter) {
      response = await axios.get(
        `${URL_FORMS}/file/trash/?page=${page}&per_page=${per_page}&name=${search}&date_filter=${filter}&time_zone=${timeZone}`,
        { headers, endpointError: endpointError.record.getTrashData }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/file/trash/?page=${page}&per_page=${per_page}&name=${search}`,
        { headers, endpointError: endpointError.record.getTrashData }
      );
    }
    return response.data;
  };

  /**
   * Restores files from the trash by their IDs.
   * @param {Array<number>} filesId - An array of file IDs to restore from the trash.
   * @returns {Promise<Object>} The response data from the API.
   * @throws Will throw an error if the API request fails.
   */
  const restoreTrashFiles = async (filesId) => {
    const response = await axios.patch(`${URL_FORMS}/file/trash/actions/?action=1`, filesId, {
      headers,
      endpointError: endpointError.record.restoreTrashFiles,
    });
    return response.data;
  };

  /**
   * Sends a PATCH request to delete files by their IDs.
   * This function makes an asynchronous request to the specified endpoint to delete files identified by the provided IDs.
   * The request is sent with the necessary headers and error handling configuration.
   * @param {string[]} filesId - An array of file IDs to be deleted.
   * @returns {Promise<Object>} A promise that resolves to the response data from the server.
   * @throws {Error} Throws an error if the request fails.
   */
  const deleteFiles = async (filesId) => {
    const response = await axios.patch(`${URL_FORMS}/file/trash/actions/?action=2`, filesId, {
      headers,
      endpointError: endpointError.record.deleteFiles,
    });
    return response.data;
  };

  return {
    createRecord,
    updateRecord,
    getRecords,
    getRecordsById,
    getFilesByForm,
    getFilesByTRD,
    createFileTRD,
    createFileByRecord,
    createFileTRDByTask,
    createFileByTaskRecord,
    createFileTRDByProcess,
    createFileByProcessRecord,
    getRecordsByFilters,
    getFreeTaskByRecord,
    getNotifiedUsersById,
    updateFileById,
    moveTrdFileLocation,
    getStickerInfo,
    moveFileToTrash,
    getTrashData,
    restoreTrashFiles,
    deleteFiles,
  };
}
