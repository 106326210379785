import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import DashboardContent from "components/organism/DashboardContent";

const Dashboard = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("dashboardheading")}
        subheading=""
        icon="home"
      >
        <DashboardContent />
      </AdminTemplate>
    </Fragment>
  );
};

export default Dashboard;
