import React, { useEffect, Fragment, useCallback, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
} from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams, Link, useHistory } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useListContext } from "contextAPI/ListsContext";
import { enumsCreateList, enumsListForm } from "utils/enums";
import { regexNameItemList } from "utils/regexExpressions";
import useList from "hooks/useList";
import swal from "sweetalert";
import i18n from "locales/i18n";
import Loader from "react-loaders";
import ReactTooltip from "react-tooltip";

const EditListForm = () => {
  const { id: listId } = useParams();
  const history = useHistory();
  const {
    list,
    setList,
    getListsBySearch,
    listName,
    setListName,
    isLoadingGetList,
    setIsLoadingGetList,
    hasForms,
    setHasForms,
    isLoadingQuery,
    setIsLoadingQuery,
    formNames,
    setFormNames,
    childList,
    setChildList,
  } = useListContext();

  const { getListsById, updateList } = useList();
  const [listNameTemp, setListNameTemp] = useState({});
  const [alertParent, setAlertParent] = useState(false);

  let componentLoadingList = null;
  let componentAlertParent = null;
  let requiredStatusList = null;
  let renderAlertHashForms = null;
  let renderNameHashForms = null;
  let renderHashChilds = null;
  let disabledStatusList = null;
  let disabledButtonEditList = null;
  let requiredName = null;

  /**
   * Handles the selection of the parent ID in an event.
   * @param {Object} eventSelectParentId - The event containing the selected parent ID information.
   * @param {Object} eventSelectParentId.target - The target element of the event.
   * @param {string} eventSelectParentId.target.value - The selected value of the parent ID.
   */
  const handleSelectParentId = (eventSelectParentId) => {
    let target = eventSelectParentId.target.value;

    if (listName.parent_id === listNameTemp.parent_id) {
      if (target === "") {
        setListName({
          parent_id: null,
        });
        setAlertParent(true);
      } else {
        setListName({
          parent_id: target,
        });
        setAlertParent(false);
      }
    } else {
      if (target === "") {
        setListName({
          ...listName,
          parent_id: null,
        });
        setAlertParent(true);
      } else {
        setListName({
          ...listName,
          parent_id: target,
        });
        setAlertParent(false);
      }
    }
  };

  /**
   * Determines the required status of the name field based on the presence of forms.
   * @param {boolean} hasForms - Indicates whether forms are present.
   * @returns {boolean} The required status of the name field:
   * `false` if `hasForms` is true.
   * `true` if `hasForms` is false.
   */
  if (hasForms === true) {
    requiredName = false;
  } else {
    requiredName = true;
  }

  /**
  * Determines the disabled status of the edit button based on loading state or unchanged list name properties.
  * @param {boolean} isLoadingGetList - Indicates whether data is loading.
  * @param {Object} listName - Current list name object.
  * @param {Object} listNameTemp - Temporary list name object for comparison.
  * @returns {boolean} The disabled status of the edit button:
  * `true` if `isLoadingGetList` is true or `listName` properties are unchanged (`listName.name === listNameTemp.name`,
  * `listName.parent_id === listNameTemp.parent_id`, `listName.status === listNameTemp.status`).
  * `false` otherwise.
  */
  if (isLoadingGetList === true ||
    (listName.name === listNameTemp.name &&
      listName.parent_id === listNameTemp.parent_id &&
      listName.status === listNameTemp.status)) {
    disabledButtonEditList = true;
  } else {
    disabledButtonEditList = false;
  };

  /**
   * Determines the disabled status of a list based on the presence of forms or children.
   * @param {boolean} hasForms - Indicates whether forms are present.
   * @param {string[]} childList - Array of childList names to be checked for presence.
   * @returns {boolean} The disabled status of the list:
   * `true` if `hasForms` is true or `childList` array has items.
   * `false` if both `hasForms` is false and `childList` array is empty.
   */
  if (hasForms === true || childList.length > 0) {
    disabledStatusList = true;
  } else {
    disabledStatusList = false;
  }

  /**
   * Conditionally renders a list item with form names if forms are present.
   * @param {boolean} hasForms - Indicates whether forms are present.
   * @param {string[]} formNames - Array of form names to be displayed when forms are present.
   * @param {Object} i18n - An object providing internationalization functions, such as `i18n.t`.
   * @returns {JSX.Element|null} JSX element of the list item with form names or null if hasForms is false.
   */
  if (hasForms === true) {
    renderNameHashForms = (
      <li>
        {`${i18n.t("list.alert1")} (${formNames.join(
          ", ")}),
        ${i18n.t("list.alert2")}`}
      </li>
    )
  };

  /**
   * Conditionally renders a list item with childList names if children are present.
   * @param {string[]} childList - Array of childList names to be displayed when children are present.
   * @param {Object} i18n - An object providing internationalization functions, such as `i18n.t`.
   * @returns {JSX.Element|null} JSX element of the list item with childList names or null if `childList` array is empty.
   */
  if (childList.length > 0) {
    renderHashChilds = (
      <li>{`${i18n.t("list.EditRelation1")} (${childList.join(
        ", "
      )})${i18n.t("list.EditRelation2")}`}</li>
    )
  };

  /**
   * Conditionally renders an alert with information about forms or children.
   * @param {boolean} hasForms - Indicates whether forms are present.
   * @param {string[]} childList - Array of childList names to be displayed when children are present.
   * @param {Object} i18n - An object providing internationalization functions, such as `i18n.t`.
   * @param {JSX.Element|null} renderNameHashForms - JSX element or null returned by `renderNameHashForms`.
   * @param {JSX.Element|null} renderHashChilds - JSX element or null returned by `renderHashChilds`.
   * @returns {JSX.Element|null} JSX element of the alert with information or null if conditions are not met.
   */
  if (hasForms === true || childList.length > 0) {
    renderAlertHashForms = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="mr-2 "
          />
          {i18n.t("modal.DoneError.header")}
          <div className="divider " />
          {renderNameHashForms}
          {renderHashChilds}
        </span>
      </Alert>
    )
  };

  /**
   * Determines the required status list based on the presence of forms.
   * @param {boolean} hasForms - Indicates whether there are forms present.
   * @returns {boolean} The required status list based on the presence of forms:
   * `false` if `hasForms` is true.
   * `true` if `hasForms` is false.
   */
  if (hasForms === true) {
    requiredStatusList = false;
  } else {
    requiredStatusList = true;
  }

  /**
   * Conditionally renders an alert message for the parent component.
   * @param {boolean} alertParent - Indicates whether to show the alert.
   * @param {Object} i18n - An object providing internationalization functions, such as `i18n.t`.
   * @returns {JSX.Element|null} JSX element of the alert message or null if alertParent is false.
   */
  if (alertParent === true) {
    componentAlertParent = (
      <span
        className="text-warning text-small"
      >
        {i18n.t("list.EditNoParent")}
      </span>
    )
  };

  /**
   * Conditionally renders a loading spinner if `isLoadingGetList` is true.
   * @function renderLoadingSpinner
   * @param {boolean} isLoadingGetList - The loading state indicating whether data is being fetched.
   * @returns {JSX.Element|null} A JSX element containing the loading spinner, or null if not loading.
   */
  if (isLoadingGetList === true) {
    componentLoadingList = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Handles the change of list name and updates the state accordingly.
   * @param {Event} eventEditListName - The event object representing 
   * the change in list name.
   */
  const handleChangeListName = (eventEditListName) => {
    if (listName.name === listNameTemp.name) {
      setListName({
        name: eventEditListName.target.value,
      });
    } else {
      setListName({
        ...listName,
        name: eventEditListName.target.value,
      });
    }
  };

  /**
   * Handles the change of list status and updates the state accordingly.
   * @param {Event} eventEditListName - The event object representing the change in list status.
   */
  const handleChangeListStatus = (eventEditListName) => {
    if (listName.status === listNameTemp.status) {
      setListName({
        status: Number(eventEditListName.target.value),
      });
    } else {
      setListName({
        ...listName,
        status: Number(eventEditListName.target.value),
      });
    }
  };

  /**
   * Cancels the current edit operation by resetting the 
   * list state to an empty array.
   */
  const cancelEditList = () => {
    setList([]);
  };

  /**
   * Fetches list details by ID and updates the state with the retrieved data.
   * This function uses the `useCallback` hook to memoize the callback function.
   * @function getListByIdFunc
   * @param {string|number} listId - The ID of the list to fetch.
   * @param {function} getListsById - Function to fetch the list by ID.
   * @param {function} setIsLoadingQuery - Function to set the loading state.
   * @param {function} setListName - Function to set the list name state.
   * @param {function} setListNameTemp - Function to set a temporary list name state.
   * @param {function} setHasForms - Function to set the state indicating if the list has related forms.
   * @param {function} setFormNames - Function to set the state with the names of related forms.
   * @param {function} setChildList - Function to set the state with childList lists.
   * @param {function} showAlertServiceError - Function to show an alert in case of a service error.
   */
  const getListByIdFunc = useCallback(() => {
    setIsLoadingQuery(true);
    getListsById(listId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setListName({
            name: response.data.name,
            parent_id: response.data.parent_id,
            status: response.data.status,
          });
          setListNameTemp({
            name: response.data.name,
            parent_id: response.data.parent_id,
            status: response.data.status,
          });
          setHasForms(response.data.related_forms.has_forms);
          setFormNames(response.data.related_forms.form_names);
          setChildList(response.data.children_list);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingQuery(false);
      });
  }, [listId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the submit event for editing a list.
   * Prevents the default form submission, sets the loading state,
   * and performs the list update if there are no errors.
   *
   * @function handleOnSubmitEditList
   * @param {Object} eventEditList - The event object generated by the form submission.
   * @param {function} eventEditList.preventDefault - Function to prevent the default action of the event.
   * @param {Array} errors - An array of errors to check before submitting the form.
   */
  const handleOnSubmitEditList = (eventEditList, errors) => {
    eventEditList.preventDefault();
    setIsLoadingGetList(true);
    if (errors.length === 0) {
      if (listId && listName) {
        updateList(listId, listName)
          .then((response) => {
            if (response.status === 202) {
              const showAlertListUpdated = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("list.designerItem5"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                });
              };
              showAlertListUpdated();
              history.push("/formsdesigner/consultlist");
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingGetList(false);
          });
      }
    } else {
      setIsLoadingGetList(false);
      return;
    }
  };

  /**
   * Handles the key press event for editing a list.
   * Prevents the default action when the Enter key is pressed.
   * @param {Object} eventEditList - The event object generated by the key press.
   * @param {string} eventEditList.key - The key value of the key pressed.
   * @param {function} eventEditList.preventDefault - Function to prevent the default action of the event.
   */
  const handleOnKeyPressEditList = (eventEditList) => {
    if (eventEditList.key === "Enter") {
      eventEditList.preventDefault();
    }
  };

  /**
   * Checks if `listId` is not null or undefined, and if so, calls `getListByIdFunc`.
   * This effect runs whenever `listId` changes.
   * @param {string|number|null|undefined} listId - The ID of the list to fetch. Can be a string, number, null, or undefined.
   */
  useEffect(() => {
    if (isNullOrUndefined(listId) === false) {
      getListByIdFunc();
    }
  }, [listId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches lists by search criteria when the component mounts.
   * The `useEffect` hook is used to call `getListsBySearch` only once, mimicking the behavior of `componentDidMount`.
   */
  useEffect(() => {
    getListsBySearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Clears the list state on component mount.
   * @returns {void}
   */
  useEffect(() => {
    setList([]);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditionally renders a loading spinner if `isLoadingQuery` is true.
   * @function renderLoadingSpinner
   * @param {boolean} isLoadingQuery - The loading state indicating whether data is being fetched.
   * @returns {JSX.Element|null} A JSX element containing the loading spinner, or null if not loading.
   */
  if (isLoadingQuery === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{i18n.t("list.designerItem4")}</CardTitle>
              <div className="divider" />
              <AvForm onSubmit={handleOnSubmitEditList}>
                {renderAlertHashForms}
                <AvGroup row>
                  <ReactTooltip
                    id="name_list"
                    name="name_list"
                    type="dark"
                    place="bottom"
                    effect="solid"
                  >
                    {i18n.t("trd.Tooltip4")}
                  </ReactTooltip>

                  <Label for="list_name" className="is-required" sm={4}>
                    {i18n.t("list.designerLabel1")}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="name"
                      name="name"
                      type="text"
                      data-tip
                      data-for="name_list"
                      autoFocus={true}
                      required
                      autoComplete="off"
                      value={listNameTemp.name}
                      onChange={handleChangeListName}
                      onKeyPress={handleOnKeyPressEditList}
                      validate={{
                        pattern: {
                          value: regexNameItemList,
                          errorMessage: `${i18n.t("list.designerFeedback3")}`,
                        },
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("list.designerFeedback1")}`,
                        },
                        minLength: {
                          value: enumsCreateList.MIN_LENGTH_NAME_LIST,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsCreateList.MAX_LENGTH_NAME_LIST,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                      }}
                    />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="parent_List" sm={4}>
                    {i18n.t("list.designerLabel2")}
                    {componentLoadingList}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="parent_List"
                      name="parent_List"
                      type="select"
                      autoComplete="off"
                      value={listNameTemp.parent_id}
                      onChange={handleSelectParentId}
                      onKeyPress={handleOnKeyPressEditList}
                    >
                      <option value={""}>{i18n.t("list.EditNoApply")}</option>
                      {
                        list.map((itemList) => {
                          if (itemList.value !== listId) {
                            return (
                              <option key={itemList.value} value={itemList.value}>
                                {itemList.label}
                              </option>
                            );
                          }
                          return null
                        })
                      }
                    </AvField>
                    {componentAlertParent}
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="status" className="is-required" sm={4}>
                    {i18n.t("form.designerLabel3")}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="status"
                      name="status"
                      type="select"
                      required={requiredStatusList}
                      autoComplete="off"
                      disabled={disabledStatusList}
                      value={listNameTemp.status}
                      onChange={handleChangeListStatus}
                      onKeyPress={handleOnKeyPressEditList}
                      validate={{
                        required: {
                          value: requiredName,
                          errorMessage: `${i18n.t("list.designerFeedback1")}`,
                        },
                      }}
                    >
                      <option value={enumsListForm.LIST_ACTIVE}>{i18n.t("list.Active")}</option>
                      <option value={enumsListForm.LIST_INACTIVE}>{i18n.t("list.Inactive")}</option>
                    </AvField>
                  </Col>
                </AvGroup>

                <CardFooter className="d-block text-right">
                  <Link to="/formsdesigner/consultlist">
                    <Button
                      size="lg"
                      disabled={isLoadingGetList}
                      className="col-mt-3 mr-3"
                      color="gray"
                      onClick={cancelEditList}
                    >
                      {componentLoadingList}
                      {i18n.t("createusers.createButton2")}
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={disabledButtonEditList}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {componentLoadingList}
                    {i18n.t("createusers.createButton3")}
                  </Button>
                </CardFooter>
              </AvForm>
            </CardBody>
          </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditListForm;
