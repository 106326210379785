import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import cx from "classnames";
import i18n from "locales/i18n";

const SearchBoxForms = (props) => {
  const { search, setSearch, pagination, setPagination } = props;

  /**
   * Handles changes to the search input value and updates the search data state.
   * @param {Object} eventChange - The event object representing the change in the input field.
   */
  const handleOnChangeSearch = (eventChange) => {
    const searchValueTiped = eventChange.target.value;
    setSearch(searchValueTiped);
  };

  /**
   * Handles keypress events for search functionality, specifically the "Enter" key.
   * @param {KeyboardEvent} eventSearch - The keypress event object for the search input field.
   * @param {object} pagination - The pagination records object.
   * @param {function} setPagination - The state setter function for updating pagination records.
   */
  const handleOnKeyPressForSearch = (eventSearch) => {
    if (eventSearch.key === "Enter") {
      if (pagination !== undefined) {
        eventSearch.preventDefault();
        setPagination({ ...pagination, page: 1 });
      }
    }
  };

  /**
   * Handles the click event of the open button, resetting the pagination page to 1 if paginationRecords is defined.
   * @param {Event} eventSearch - The click event object triggered when the open button is clicked.
   * @param {object} pagination - The pagination records object.
   * @param {function} setPagination - The state setter function for updating pagination records.
   * @returns {void}
   */
  const handleOnClickOpenButton = (eventSearch) => {
    if (pagination !== undefined) {
      eventSearch.preventDefault();
      setPagination({ ...pagination, page: 1 });
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div
          className={cx("search-wrapperExplorer ml-auto search-box", {
            active: true,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              className="search-input"
              placeholder={i18n.t("searchbox.placeholder")}
              onKeyPress={handleOnKeyPressForSearch}
              onChange={handleOnChangeSearch}
              value={search}
            />
            <button className="search-icon" onClick={handleOnClickOpenButton}>
              <span />
            </button>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SearchBoxForms.propTypes = {
  setSearch: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default SearchBoxForms;
