import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import ButtonActionsSteps from "components/atoms/ButtonActionsSteps";
import i18n from "locales/i18n";

const WorkFlowStepsTable = (props) => {
  const {
    listWorkflowSteps,
    pagination,
    setPagination,
    isLoading,
    totalPages,
    workFlowUuid,
    statusProcess,
    formId
  } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={listWorkflowSteps}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("createWorkflow.config.label1")],
                  accessor: "index",
                  Cell: (row) => {
                    return (
                      <div className="text-center">{row.original.index}</div>
                    );
                  },
                },
                {
                  Header: [i18n.t("createWorkflow.config.label2")],
                  accessor: "name",
                },
                {
                  Header: [i18n.t("createWorkflow.config.label3")],
                  accessor: "type_name",

                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === "START_EVENT") {
                      statusFinal = (
                        <div className="ml-auto badge badge-info">
                          {i18n.t("createWorkflow.config.start")}
                        </div>
                      );
                    } else if (value === "MANUAL_TASK") {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("createWorkflow.config.manualTask")}
                        </div>
                      );
                    } else if (value === "USER_TASK") {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("createWorkflow.config.userTask")}
                        </div>
                      );
                    } else if (value === "EXCLUSIVE_GATEWAY") {
                      statusFinal = (
                        <div className="ml-auto badge badge-info">
                          {i18n.t("createWorkflow.config.exclusiveGateWay")}
                        </div>
                      );
                    } else if (value === "END_EVENT") {
                      statusFinal = (
                        <div className="ml-auto badge badge-info">
                          {i18n.t("createWorkflow.config.endEvent")}
                        </div>
                      );
                    }

                    return <span>{statusFinal}</span>;
                  },
                },
              ],
            },
            {
              columns: [
                {
                  Header: [i18n.t("buttonActions.title")],
                  accessor: "actions",
                  Cell: (row) => {
                    const stepType = row.original.type_name;
                    if (
                      stepType === "END_EVENT" ||
                      stepType === "EXCLUSIVE_GATEWAY"
                    ) {
                      return null;
                    } else {
                      return (
                        <ButtonActionsSteps
                          stepType={stepType}
                          stepId={row.original.uuid}
                          workFlowUuid={workFlowUuid}
                          stepName={row.original.name}
                          statusProcess={statusProcess}
                          formId={formId}
                        />
                      );
                    }
                  },
                },
              ],
            },
          ]}
          manual
          loading={isLoading}
          pages={totalPages}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          showPageJump={false}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          defaultPageSize={pagination.per_page}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
          className="-striped -highlight"
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkFlowStepsTable.propTypes = {
  listWorkflowSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  workFlowUuid: PropTypes.string.isRequired,
  statusProcess: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

export default WorkFlowStepsTable;
