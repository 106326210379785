import React from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { regexTextBox } from "utils/regexExpressions";
import cx from "classnames";
import i18n from "locales/i18n";

const TextBox = (props) => {
  const {
    id,
    label,
    hide,
    uniqueFilingInBatch,
    required,
    isPreview,
    minLength,
    maxLength,
    handleOnChangeRecord,
    handleOnKeyPress,
    value,
  } = props;

  const { validationField, setValidationField } = useFieldsRecordContext();
  let isRequired = null;
  let hideLabelTextbox = null;
  let uniqueFieldTextBox = null;
  let fieldValidationUnique = null;
  let disbledFieldInbatch = false;
  let alertMesageInfoBatch = null;
  let fieldRequired = required;

  /**
   * Checks if unique filing is enabled in batch processing mode and updates relevant fields accordingly.
   * @param {boolean} uniqueFilingInBatch - Indicates whether unique filing is enabled in batch processing mode.
   * @param {boolean} disbledFieldInbatch - Indicates whether fields are disabled in batch processing mode.
   * @param {boolean} fieldRequired - Indicates whether fields are required.
   * @param {string} alertMesageInfoBatch - Message displayed as an alert in batch processing mode.
   * @returns {void}
   */
  if (uniqueFilingInBatch === true) {
    disbledFieldInbatch = true;
    alertMesageInfoBatch = i18n.t("taskBatchProcess.placeHolderUniqueFields");
    fieldRequired = false;
  }

  /**
   * Determines if a field is required and returns a CSS class name accordingly.
   * @param {boolean} required - A boolean value indicating whether the field is required or not.
   * @returns {string|null} Returns the CSS class name "is-required" if the field is required, otherwise null.
   */
  if (required === true) {
    isRequired = "is-required";
  } else {
    isRequired = null;
  }

  /**
   * Conditionally generates a JSX element to hide or show a labeled textbox.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled textbox.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled textbox with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelTextbox = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelTextbox = null;
  }

  /**
   * Check if a validation field's ID includes a specified ID and whether it is marked as unique.
   * If it's unique, sets a unique field text box and updates a validation flag.
   * If not unique, clears the unique field text box and resets the validation flag.
   * @param {string[]} id - The ID to check against the validation field's ID.
   * @param {boolean} unique - Whether the validation field is marked as unique.
   * @param {string} i18n - An internationalization function for localization.
   * @param {string|null} uniqueFieldTextBox - The JSX element for displaying a unique field message.
   * @param {boolean} fieldValidationUnique - A flag indicating if the field has passed unique validation.
   * @returns {void}
   */
  if (validationField.id.includes(id) && validationField.unique) {
    uniqueFieldTextBox = (
      <span className="text-danger text small">
        {i18n.t("recordValidateUnique")}
      </span>
    );
    fieldValidationUnique = true;
  } else {
    uniqueFieldTextBox = null;
    fieldValidationUnique = false;
  }

  if (isPreview === true) {
    return (
      <AvForm>
        <AvGroup row key={id}>
          <Label className={cx("", {
            "is-required": required === true
          })} sm={3}>
            {label} {hideLabelTextbox}
          </Label>
          <Col md={8}>
            <AvField
              id={id}
              name={id}
              type="text"
              key={id}
              autoComplete="off"
              disabled={disbledFieldInbatch}
              validate={{
                required: {
                  value: fieldRequired,
                  errorMessage: `${i18n.t("fieldRequired")}`,
                },
                pattern: {
                  value: regexTextBox,
                  errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                },
                minLength: {
                  value: minLength,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                    "characters"
                  )}`,
                },
                maxLength: {
                  value: maxLength,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                    "characters"
                  )}`,
                },
              }}
              onChange={(e) => handleOnChangeRecord(id, e.target.value)}
              onKeyPress={handleOnKeyPress}
              value={value}
            />
            {alertMesageInfoBatch}
            {uniqueFieldTextBox}
          </Col>
        </AvGroup>
      </AvForm>
    );
  } else if (hide === false) {
    return (
      <AvGroup row key={id}>
        <Label
          className={cx(isRequired, fieldValidationUnique === false, {
            "label-color is-required": fieldValidationUnique === true,
          })}
          sm={3}
        >
          {label}
        </Label>
        <Col md={8}>
          <AvField
            id={id}
            name={id}
            type="text"
            key={id}
            className={cx("", fieldValidationUnique === false, {
              "unique-Field": fieldValidationUnique === true,
              "field-DisabledUnique": uniqueFilingInBatch === true,
            })}
            autoComplete="off"
            disabled={disbledFieldInbatch}
            validate={{
              required: {
                value: fieldRequired,
                errorMessage: `${i18n.t("fieldRequired")}`,
              },
              pattern: {
                value: regexTextBox,
                errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
              },
              minLength: {
                value: minLength,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                  "characters"
                )}`,
              },
              maxLength: {
                value: maxLength,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                  "characters"
                )}`,
              },
            }}
            onChange={(e) => {
              handleOnChangeRecord(id, e.target.value);
              if (validationField.id.length > 0) {
                const newArrayValidated = validationField.id.filter(
                  (item) => item !== id
                );
                setValidationField({
                  id: newArrayValidated,
                  unique: true,
                });
              }
            }}
            onKeyPress={handleOnKeyPress}
            value={value}
            placeholder={alertMesageInfoBatch}
          />
          {uniqueFieldTextBox}
        </Col>
      </AvGroup>
    );
  } else {
    return null;
  }
};

TextBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  uniqueFilingInBatch: PropTypes.bool,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  minLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  handleOnChangeRecord: PropTypes.func.isRequired,
  handleOnKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string,
};
export default TextBox;
