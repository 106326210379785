import React, { Fragment, useState, useEffect, useCallback } from "react";
import Loader from "react-loaders";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Card, CardHeader, CardBody, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enumsRequestStatusSignature } from "utils/enums";
import { loaderElement } from "utils/loaderElement";
import useIntegration from "hooks/useIntegrations";
import ButtonRequestInt from "components/atoms/ButtonRequestInt";
import ButtonIntegrationConfig from "components/molecules/ButtonIntegrationConfig";
import EditIntegrationConfig from "components/molecules/EditIntegrationConfig";
import i18n from "locales/i18n";

const DetailIntegrationView = () => {
  const { id } = useParams();
  const [isLoadingGetIntegration, setIsLoadingGetIntegration] = useState(false);
  const [hasRequestGetIntegration, setHasRequest] = useState(true);
  const [consecutiveIntegration, setConsecutiveIntegration] = useState();
  const [requestUuidIntegration, setRequestUuidIntegration] = useState("");
  const [statusIntegration, setStatusIngration] = useState(0);
  const [integrationId, setIntegrationId] = useState(0)
  const [listIntegrations, setListIntegrations] = useState([]);
  const { getIntegrationById } = useIntegration();

  let showIntegrations = null;
  let showIngrationsComponent = null;
  let showButtonIntegrationConfig = null;
  let showButtonEditIntegrationConfig = null;
  let showAprovedSignatureComponent = null;

  /**
   * Determines if a component should be disabled based on its statusIntegration.
   * @param {number} statusIntegration - The current statusIntegration of the component.
   * @returns {boolean} `true` if the statusIntegration is 1, 2, 3, or 5; otherwise `false`.
   */
  const isDisabled = (statusIntegration) => {
    return statusIntegration === enumsRequestStatusSignature.ACTIVE ||
      statusIntegration === enumsRequestStatusSignature.IN_PROCESS ||
      statusIntegration === enumsRequestStatusSignature.IN_MANAGEMENT ||
      statusIntegration === enumsRequestStatusSignature.APPROVED;
  };

  /**
   * Conditionally renders an approved signature alert component.
   * If `hasRequestGetIntegration` is true and `statusIntegration` is `APPROVED`, this function sets
   * `showAprovedSignatureComponent` to a JSX element displaying an alert.
   * @param {boolean} hasRequestGetIntegration - A flag indicating if there is a request.
   * @param {number} statusIntegration - The current statusIntegration of the request.
   * @param {string} consecutiveIntegration - The consecutiveIntegration ID of the request.
   * @returns {JSX.Element|null} A JSX element representing the alert if conditions are met, otherwise null.
   */
  if (hasRequestGetIntegration === true && statusIntegration === enumsRequestStatusSignature.APPROVED) {
    showAprovedSignatureComponent = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="mr-2 "
          />
          {i18n.t("modal.DoneError.header")}
          <div className="divider " />
          {`${i18n.t(
            "integration.requestAlert1"
          )} ${consecutiveIntegration} ${i18n.t("integration.requestAlert5")}`}
        </span>
      </Alert>
    )
  }

  /**
   * Conditionally renders the edit integration config button.
   * If `statusIntegration` is `ACTIVE` and `integrationId` is `1`, this function sets
   * `showButtonEditIntegrationConfig` to a JSX element displaying the button.
   * @param {number} statusIntegration - The current statusIntegration of the request.
   * @param {number} integrationId - The ID of the integration.
   * @param {string} requestUuidIntegration - The UUID of the request.
   * @returns {JSX.Element|null} A JSX element representing the button if conditions are met, otherwise null.
   */
  if (statusIntegration === enumsRequestStatusSignature.ACTIVE && integrationId === 1) {
    showButtonEditIntegrationConfig = (
      <EditIntegrationConfig requestUuidIntegration={requestUuidIntegration} />
    )
  };

  /**
   * Conditionally renders the integration config button.
   * If `statusIntegration` is `APPROVED` and `integrationId` is `1`, this function sets
   * `showButtonIntegrationConfig` to a JSX element displaying the button.
   * @param {number} statusIntegration - The current statusIntegration of the request.
   * @param {number} integrationId - The ID of the integration.
   * @param {string} requestUuidIntegration - The UUID of the request.
   * @returns {JSX.Element|null} A JSX element representing the button if conditions are met, otherwise null.
   */
  if (statusIntegration === enumsRequestStatusSignature.APPROVED && integrationId === 1) {
    showButtonIntegrationConfig = (
      <ButtonIntegrationConfig requestUuidIntegration={requestUuidIntegration} />
    )
  }

  /**
   * Conditionally renders an alert for in-process or in-management integrations.
   * If `hasRequestGetIntegration` is true and `statusIntegration` is either `IN_PROCESS` or `IN_MANAGEMENT`,
   * this function sets `showIntegrations` to a JSX element displaying an alert.
   * @param {boolean} hasRequestGetIntegration - A flag indicating if there is a request.
   * @param {number} statusIntegration - The current statusIntegration of the request.
   * @param {string} consecutiveIntegration - The consecutiveIntegration ID of the request.
   * @returns {JSX.Element|null} A JSX element representing the alert if conditions are met, otherwise null.
   */
  if (hasRequestGetIntegration && (statusIntegration === enumsRequestStatusSignature.IN_PROCESS ||
    statusIntegration === enumsRequestStatusSignature.IN_MANAGEMENT) === true) {
    showIntegrations = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          {`${i18n.t("integration.requestAlert")} ${consecutiveIntegration}`}
        </span>
      </Alert>
    )
  };

  /**
   * Conditionally renders an alert for active integrations.
   * If `hasRequestGetIntegration` is true and `statusIntegration` is `ACTIVE`, this function sets
   * `showIntegrationsComponent` to a JSX element displaying an alert.
   * @param {boolean} hasRequestGetIntegration - A flag indicating if there is a request.
   * @param {number} statusIntegration - The current statusIntegration of the request.
   * @returns {JSX.Element|null} A JSX element representing the alert if conditions are met, otherwise null.
   */
  if (hasRequestGetIntegration && statusIntegration === enumsRequestStatusSignature.ACTIVE) {
    showIngrationsComponent = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          {`${i18n.t("integration.requestAlert4")}`}
        </span>
      </Alert>
    )
  };

  /**
   * Fetches integrations by ID and updates the state accordingly.
   * This function sets the loading state to true, retrieves the integration data by ID,
   * and updates various state variables with the retrieved data. If the data is null or undefined,
   * it shows a service error alert. Finally, it resets the loading state.
   * @function getIntegrationsBySearch
   * @param {number} id - The ID of the integration to fetch.
   * @param {Function} setIsLoadingGetIntegration - Function to set the loading state.
   * @param {Function} getIntegrationById - Function to fetch integration by ID.
   * @param {Function} setListIntegrations - Function to update the list of integrations.
   * @param {Function} setHasRequest - Function to update the request existence state.
   * @param {Function} setConsecutiveIntegration - Function to update the request ID.
   * @param {Function} setIntegrationId - Function to update the integration ID.
   * @param {Function} setStatusIngration - Function to update the request statusIntegration.
   * @param {Function} setRequestUuidIntegration - Function to update the request UUID.
   * @param {Function} showAlertServiceError - Function to show a service error alert.
   * @param {Function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @returns {void}
   */
  const getIntegrationsBySearch = useCallback(() => {
    setIsLoadingGetIntegration(true);
    getIntegrationById(id)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setListIntegrations(response.data.data);
          setHasRequest(response.data.data.request.exists);
          setConsecutiveIntegration(response.data.data.request.id);
          setIntegrationId(response.data.data.integration_id);
          setStatusIngration(response.data.data.request.status);
          setRequestUuidIntegration(response.data.data.request.uuid);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGetIntegration(false);
      });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook to fetch integrations by search when `getIntegrationsBySearch` changes.
   * This hook runs the `getIntegrationsBySearch` function whenever the `getIntegrationsBySearch`
   * dependency changes. It ensures that the integrations are fetched as soon as the component
   * mounts or whenever the search function is updated.
   * @param {Function} getIntegrationsBySearch - The function to fetch integrations by search.
   */
  useEffect(() => {
    getIntegrationsBySearch();
  }, [getIntegrationsBySearch]);

  /**
   * Renders a loading indicator if the `isLoadingGetIntegration` state is true.
   * This function checks the `isLoadingGetIntegration` state and, if it is true, returns a 
   * loading indicator wrapped in a div with specific classes for styling and 
   * alignment. The `Loader` component displays a pulse rise animation with the 
   * primary color.
   *
   * @param {boolean} isLoadingGetIntegration - The state indicating if the loading process is ongoing.
   * @returns {JSX.Element|null} A loading indicator component if `isLoadingGetIntegration` is true, otherwise null.
   */
  if (isLoadingGetIntegration === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="primary" type="ball-pulse-rise" />
      </div>
    )
  };

  return (
    <Fragment>
      {loaderElement(isLoadingGetIntegration)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <div>
            {showIntegrations}
            {showIngrationsComponent}
            {showAprovedSignatureComponent}
            <Card>
              <CardHeader className="card-header-lg">
                <div className="text-header font-weight-bold text-cyan ">
                  <h4>{listIntegrations.name}</h4>
                </div>
                <div className="btn ml-auto">
                  <Row>
                    {showButtonIntegrationConfig}
                    {showButtonEditIntegrationConfig}
                    &nbsp;&nbsp;
                    <ButtonRequestInt
                      id={id}
                      disabled={isDisabled(statusIntegration)}
                    />
                  </Row>
                </div>
              </CardHeader>
              <CardBody className=" text-center ">
                <img
                  className="datailIntegration-width"
                  alt="banner"
                  src={listIntegrations.banner}
                />
              </CardBody>
              <br />
              <div
                className="datailIntegration-padding"
                dangerouslySetInnerHTML={{
                  __html: listIntegrations.general_information,
                }}
              />
            </Card>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DetailIntegrationView;
