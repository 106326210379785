import React, { useState } from "react";
import swal from "sweetalert";
import i18n from "locales/i18n";
import useTrd from "hooks/useTrd";

const DocumentaryContext = React.createContext(undefined);
export function DocumentaryContextProvider({ children }) {

	//Extracting the necessary hooks
	const { createDocumentaryType } = useTrd()

	//global states
	const [search, setSearch] = useState("")
	const [isCreate, setIsCreate] = useState(false)
	const [loadingCreationDocumentaryType, setloadingCreationDocumentaryType] = useState(false)
	const [validate, setValidate] = useState(false)
	const [documentaryTypeName, setdocumentaryTypeName] = useState({
		name: ""
	})

	//Handle changes to documenttype name
	const handleOnChange = (e) => {
		setdocumentaryTypeName({
			...documentaryTypeName,
			[e.target.name]: e.target.value,
		});
	};

	//handle event to format document type name
	const handleOnBlur = (e) => {
		setdocumentaryTypeName({
			...documentaryTypeName,
			[e.target.name]: e.target.value.trim(),
		});
	};

	//when the creation is finished empty everything
	const handleClose = () => {
		setloadingCreationDocumentaryType(false)
		setValidate(false)
	}

	//documentary type creation
	const actionCreateDocumentaryType = async (data) => {
		const info = { name: data.name.trim() }
		//It is verified that when entering and creating a document type the validations are not lost
		if (info.name.trim().split("").length < 4) {
			setdocumentaryTypeName({ ...documentaryTypeName, name: info.name.trim() })
			return
		} else {
			setloadingCreationDocumentaryType(true)
			createDocumentaryType(info)
				.then((response) => {
					if (response.status === 201) {
						swal({
							title: i18n.t("modal.DoneError.header"),
							text: i18n.t("documentary.type.alert.create"),
							icon: "success",
							button: i18n.t("modal.Done.footerButton"),
						});
						setdocumentaryTypeName({ name: "" })
						setValidate(false)
					}
				})
				.finally(() => {
					setloadingCreationDocumentaryType(false);
					handleClose();
				})
		}
	}

	return (
		<DocumentaryContext.Provider
			value={{
				search,
				setSearch,
				isCreate,
				setIsCreate,
				documentaryTypeName,
				setdocumentaryTypeName,
				handleOnChange,
				actionCreateDocumentaryType,
				validate,
				setValidate,
				loadingCreationDocumentaryType,
				handleOnBlur
			}}
		>
			{children}
		</DocumentaryContext.Provider>
	);
}
export function useDocumentaryContext() {
	const context = React.useContext(DocumentaryContext);
	if (!context) {
		throw new Error(
			"useDocumentaryContext debe estar dentro del proveedor DocumentaryContext."
		);
	}
	return context;
}
