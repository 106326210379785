import PropTypes from 'prop-types';
import React from "react";
import { useRolesContext } from "contextAPI/RolesContext";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { enumsRolesForm } from 'utils/enums';
import { showAlertServiceError } from "utils/alerts";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionsRolesForm = (props) => {
  const {
    uuidRol,
    form_uuid,
    form_name,
    permissions,
  } = props;
  const {
    setIsDeleted,
    setFormDetail,
    setIsLoading,
    setComponentToShow,
  } = useRolesContext();
  const { deleteFormPremissionByRol } = useRoles();

  /**
   * Handles the click event, updating the component to show and setting form details.
   * This function is triggered when a click event occurs. It sets the component
   * to be displayed to "2" and updates the form details using the provided parameters.
   * @param {string} form_uuid - The UUID of the form.
   * @param {string} form_name - The name of the form.
   * @param {Array} permissions - An array representing the permissions associated with the form.
   * @param {function} setComponentToShow - The state setter function for updating the component to be displayed.
   * @param {function} setFormDetail - The state setter function for updating the form details.
   *  @returns {void}
   */
  const handleOnClick = () => {
    setComponentToShow(enumsRolesForm.VIEW_CONFIG);
    setFormDetail({
      form_uuid: form_uuid,
      form_name: form_name,
      permissions: permissions,
    });
  };


  /**
   * Handles the click event for initiating the edit mode, updating the component to show,
   * and setting the form details for editing.
   * This function sets the component to show as "3" to switch to the edit mode. It also
   * updates the form details with the provided information, including the form UUID,
   * form name, and permissions.
   * @param {string} form_uuid - The UUID of the form.
   * @param {string} form_name - The name of the form.
   * @param {Array} permissions - An array representing the permissions associated with the form.
   * @param {function} setComponentToShow - The state setter function for updating the component to be displayed.
   * @param {function} setFormDetail - The state setter function for updating the form details.
   * @returns {void}
   */
  const handleOnClickEdit = () => {
    setComponentToShow(enumsRolesForm.EDIT);
    setFormDetail({
      form_uuid: form_uuid,
      form_name: form_name,
      permissions: permissions,
    });
  };

  /**
   * Handles the deletion of a form permission by role, updating loading and deletion states.
   * This function initiates the deletion process for a form permission associated with a specific role.
   * It sets the loading state to true, marks the form as deleted, sends a deletion request to the server,
   * and displays a success message upon successful deletion. Finally, it updates the loading and deletion states.
   * @param {function} deleteFormPremissionByRol - For the function responsible for sending the deletion request.
   * @param {string} uuidRol - The UUID of the role for which the form permission is being deleted.
   * @param {string} form_uuid - The UUID of the form permission to be deleted.
   * @param {function} setIsLoading - State setter function for the loading state.
   * @param {function} setIsDeleted - State setter function for the deletion state.
   * @returns {void}
   */
  const handleOnDelete = () => {
    setIsLoading(true);
    setIsDeleted(true);
    deleteFormPremissionByRol(uuidRol, form_uuid)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createRoles.Alert.FormDelete"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setIsDeleted(false);
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem onClick={handleOnClick}>
          <i className="dropdown-icon lnr-list" />
          <span className="btn-link">{i18n.t("form.config")}</span>
        </DropdownItem>

        <DropdownItem onClick={handleOnClickEdit}>
          <i className="dropdown-icon lnr-pencil" />
          <span className="btn-link">{i18n.t("buttonActions.option2")}</span>
        </DropdownItem>

        <DropdownItem>
          <i className="dropdown-icon lnr-trash text-danger" />
          <span className="btn-link" onClick={handleOnDelete}>
            {i18n.t("buttonActions.option3")}
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsRolesForm.propTypes = {
  uuidRol: PropTypes.string.isRequired,
  form_uuid: PropTypes.string.isRequired,
  form_name: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ButtonActionsRolesForm;
