import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { loaderComponent, loaderElement } from "utils/loaderElement";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import DropZoneLogo from "components/molecules/DropZoneLogo";
import useCompany from "hooks/useCompany";
import i18n from "locales/i18n";
import swal from "sweetalert";

const StickerConfig = () => {
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isSavingLogo, setIsSavingLogo] = useState(false);
  const [fileLogo, setFileLogo] = useState([]);
  const [fileLogoLoaded, setFileLogoLoaded] = useState({});
  const { getLogoByCompany, configureLogoByCompany } = useCompany();
  let disabledSaveLogo = false;
  let logoForSticker = null;

  /**
   * Checks if the length of the fileLogo array is equal to zero and sets disabledSaveLogo accordingly.
   * @param {Array} fileLogo - The array containing file paths or references.
   */
  if (fileLogo.length === 0) {
    disabledSaveLogo = true;
  }

  /**
   * Fetches the logo for the sticker from the server and updates state accordingly.
   * @param {Function} setIsLoadingLogo - Function to set the loading state for the logo.
   * @param {Function} setFileLogoLoaded - Function to set the loaded logo file.
   * @param {Function} showAlertServiceError - Function to display an alert in case of service error.
   * @param {Function} getLogoByCompany - Function to fetch the logo by company from the server.
   */
  const getLogoForSticker = useCallback(() => {
    setIsLoadingLogo(true);
    getLogoByCompany()
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setFileLogoLoaded(response.data);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingLogo(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the submission of a logo, if there are no errors.
   * @param {Event} eventSubmit - The submit event triggering the function.
   * @param {Array} errors - An array containing any validation errors.
   */
  const handleOnSubmitLogo = (eventSubmit, errors) => {
    if (errors.length === 0) {
      if (fileLogo.length === 0) {
        eventSubmit.preventDefault();
      } else {
        setIsSavingLogo(true);
        const fileLogoFormData = new FormData();
        fileLogoFormData.append("logo", fileLogo[0]);

        configureLogoByCompany(fileLogoFormData)
          .then((response) => {
            if (response.status === 201) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("sticker.logoSaved"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
                successMode: true,
              }).then(() => {
                window.location.reload();
              });
            }
          })
          .finally(() => {
            setIsSavingLogo(false);
          });
      }
    }
  };

  /**
   * Handles the deletion of the logo by sending a request to the server.
   * @function handleOnDelete
   * @returns {void}
   */
  const handleOnDelete = () => {
    setIsSavingLogo(true);
    const fileLogoFormData = new FormData();
    fileLogoFormData.append("logo", []);

    configureLogoByCompany(fileLogoFormData)
      .then((response) => {
        if (response.status === 201) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("sticker.logoDeleted"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
            successMode: true,
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .finally(() => {
        setIsSavingLogo(false);
      });
  };

  /**
   * Renders the logo for sticker based on the availability of the loaded logo file.
   * If a logo file is loaded and all values are defined, it renders the logo along with delete button.
   * If no logo file is loaded or some values are undefined, it renders a drop zone for logo upload.
   * @param {Object} fileLogoLoaded - An object representing the loaded logo file.
   * @param {Function} handleOnDelete - A function to handle delete button click.
   * @param {Function} setFileLogo - A function to set the loaded logo file.
   * @param {boolean} disabledSaveLogo - A boolean indicating whether the save logo button is disabled.
   * @returns {JSX.Element} - JSX element containing the logo for sticker or drop zone for logo upload.
   */
  if (
    Object.keys(fileLogoLoaded).length > 0 &&
    Object.values(fileLogoLoaded).every((value) => value !== undefined)
  ) {
    logoForSticker = (
      <div className="mt-4 mb-4">
        <div className="border p-3 d-inline-block">
          <Row className="align-items-center">
            <Col md="auto">
              <img alt="logo" src={fileLogoLoaded.presigned_url} />
            </Col>
            <Col md="auto">
              <strong>
                <em>{fileLogoLoaded.name}</em>
              </strong>
            </Col>
            <Col md="auto" className="text-right">
              <Button
                className="ml-2 btn-icon btn-icon-only btn-shadow btn-outline-2x"
                size="sm"
                outline
                color="danger"
                onClick={handleOnDelete}
              >
                <i className="pe-7s-trash btn-icon-wrapper"> </i>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else {
    logoForSticker = (
      <div className="mt-4">
        <DropZoneLogo setFileLogo={setFileLogo} />
        <CardFooter className="d-block">
          <Button
            type="submit"
            size="lg"
            className="col-mt-3"
            color="cyan"
            disabled={disabledSaveLogo}
          >
            {i18n.t("createWorkflowDesign.save")}
          </Button>
        </CardFooter>
      </div>
    );
  }

  /**
   * Executes a side effect after the component has rendered.
   * This effect invokes the function to retrieve a logo for a sticker.
   * @param {Function} getLogoForSticker - The function to retrieve the logo for a sticker.
   */
  useEffect(() => {
    getLogoForSticker();
  }, [getLogoForSticker]);

  if (isLoadingLogo === true) {
    return <Card className="main-card mb-3">{loaderComponent(true)}</Card>;
  } else {
    return (
      <Fragment>
        {loaderElement(isSavingLogo)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle> {i18n.t("sticker.TitleForm")}</CardTitle>
              <div className="divider" />
              <AvForm onSubmit={handleOnSubmitLogo}>
                <span>{i18n.t("sticker.TitleBody")}</span>
                <div>{logoForSticker}</div>
              </AvForm>
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default StickerConfig;
