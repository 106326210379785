import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import ButtonActionsList from "components/atoms/ButtonActionsList";
import i18n from "locales/i18n";
import { getDateFormat2 } from "utils/getDateFormat2";

const ConsultListTable = (props) => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={props.lists}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("list.designerLabel1")],
                  accessor: "name",
                },
                {
                  Header: [i18n.t("list.designerLabel2")],
                  accessor: "parent_name",
                },
                {
                  Header: [i18n.t("form.designerLabel3")],
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("list.Active")}
                        </div>
                      );
                    } else {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("list.Inactive")}
                        </div>
                      );
                    }

                    return <span>{statusFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("itemList.designerLabel3")],
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("form.field47")],
                  accessor: "updated_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <span>{valueFinal}</span>;
                  },
                },
              ],
            },
            {
              columns: [
                {
                  Header: [i18n.t("buttonActions.title")],
                  accessor: "actions",
                  Cell: (row) => {
                    return (
                      <ButtonActionsList
                        listId={row.original.uuid}
                        parent_id={row.original.parent_id}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={props.totalPages}
          page={props.pagination.page - 1}
          showPageJump={false}
          defaultPageSize={props.pagination.per_page}
          loading={props.isLoadingConsultList}
          noDataText={i18n.t("tableRowsEmpty")}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            props.setPagination({ ...props.pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            props.setPagination({ ...props.pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

ConsultListTable.propTypes = {
  lists: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.object.isRequired,
  isLoadingConsultList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default ConsultListTable;
