import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Button,
  Label,
  Spinner,
  Alert,
} from "reactstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { useTrdContext } from "contextAPI/TrdContext";
import { showAlert } from "components/molecules/Alert/Alert";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  enumsActionsTypes,
  enumsConsultConfigurationFields,
  enumsFormStatus,
  enumsMaxFieldsSelectedSticker,
  enumsMaxMenuHeight,
  enumsMaxMinCharactersFormsDesigner,
} from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import { formsDesignerRegex } from "utils/regexExpressions";
import { loaderElement } from "utils/loaderElement";
import useForms from "hooks/useForm";
import ReactTooltip from "react-tooltip";
import FormsDesignerTrdConfiguration from "../FormsDesignerTrdConfiguration";
import TrdPathConfiguration from "components/atoms/TrdPathConfiguration";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";

const FormsDesignerForm = () => {
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(
    DESIGNER_FORMS_PERMISSIONS
  );
  const newForms = DESIGNER_FROMS_PERMISSION.split(",");
  const history = useHistory();
  const { id } = useParams();
  const formId = id;
  const [showCurrentPath, setShowCurrentPath] = useState(true);
  const [isTrdConfiguration, setIsTrdConfiguration] = useState(false);
  const [currentPath, setCurrentPath] = useState([]);
  const [currentPathForEdiition, setCurrentPathEdition] = useState({});
  const [isLoadingFormsDesigner, setIsLoadingFormsDesigner] = useState(false);
  const [fieldsFormList, setFieldsFormList] = useState([]);
  const [optionsFieldsTemp, setOptionsFieldsTemp] = useState([]);
  const [uuidStickerFields, setUuidStickerFields] = useState([]);
  const [updateDataForm, setUpdateDataForm] = useState({});
  const [noOptionsMessageFields, setNoOptionsMessageFields] = useState("");
  const [formCreate, setFormCreate] = useState({
    name: "",
    description: "",
    status: 0,
    serie_subserie_uuid: null,
    sticker_fields: [],
  });
  const [tempFormCreate, setTempFormCreate] = useState({
    name: "",
    description: "",
    status: 0,
    serie_subserie_uuid: null,
    sticker_fields: [],
  });
  const [keysFormCreate] = useState([
    "name",
    "description",
    "status",
    "serie_subserie_uuid",
    "sticker_fields",
  ]);
  const { getTreeByDomainCallback, structureTrd, isLoading: isLoadingTrd } = useTrdContext();
  const {
    createForm,
    updateForm,
    getFormsById,
    getFieldsBySticker,
  } = useForms();
  let titleForm = null;
  let trdRouteButton = null;
  let trdRoutePath = null;
  let stickerFielsSelect = null;
  let spinner = null;
  let buttonLabel = null;

  /**
   * Fetches form data by form ID, processes the data, and updates the form state.
   * Utilizes useCallback to memoize the function and prevent unnecessary re-renders.
   * @param {number} formId - The ID of the form to be fetched.
   * @param {function} setIsLoadingFormsDesigner - Function to update the loading state of the forms designer.
   * @param {function} getFormsById - Function to fetch the form data by ID.
   * @param {function} isNullOrUndefined - Utility function to check if a value is null or undefined.
   * @param {function} setFormCreate - Function to update the state of the form being created.
   * @param {function} setTempFormCreate - Function to update the temporary state of the form being created.
   * @param {function} setCurrentPathEdition - Function to update the current path for form edition.
   * @param {function} showAlertServiceError - Function to show an alert in case of a service error.
   */
  const getFormsByIdFunc = useCallback(() => {
    setIsLoadingFormsDesigner(true);
    getFormsById(formId, enumsActionsTypes.ALL_ACTIONS)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const dataForm = response.data;
          const optionsFields = dataForm.sticker_fields.map((field) => {
            return {
              label: field.name,
              value: field.uuid,
            };
          });

          setFormCreate({
            name: dataForm.name,
            description: dataForm.description,
            status: dataForm.status,
            serie_subserie_uuid: dataForm.serie_subserie_uuid,
            sticker_fields: optionsFields,
          });

          setTempFormCreate({
            name: dataForm.name,
            description: dataForm.description,
            status: dataForm.status,
            serie_subserie_uuid: dataForm.serie_subserie_uuid,
            sticker_fields: optionsFields,
          });

          setCurrentPathEdition(response.data.path);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFormsDesigner(false);
      });
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches fields by sticker ID and updates the fields form list.
   * This function is memoized using `useCallback` to optimize performance.
   * It sets the loading state, fetches the fields, processes the response,
   * and updates the fields list or shows an error alert if the response is invalid.
   * @param {number} formId - The ID of the form to fetch fields for.
   * @param {function} setIsLoadingFormsDesigner - Function to set the loading state.
   * @param {function} getFieldsBySticker - Function to fetch fields by sticker ID.
   * @param {function} setFieldsFormList - Function to update the fields form list.
   * @param {function} showAlertServiceError - Function to show an error alert.
   * @returns {void}
   */
  const getFieldsByIdForSticker = useCallback(() => {
    setIsLoadingFormsDesigner(true);
    getFieldsBySticker(formId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const activeFields = response.data;
          const optionsFields = activeFields.map((field) => {
            return {
              label: field.label,
              value: field.uuid,
            };
          });
          setFieldsFormList(optionsFields);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFormsDesigner(false);
      });
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a loading spinner if the forms designer is loading.
   * @param {boolean} isLoadingFormsDesigner - Indicates if the forms designer is currently loading.
   * @returns {JSX.Element|null} A JSX element containing the spinner if isLoadingFormsDesigner is true, otherwise null.
   */
  if (isLoadingFormsDesigner === true) {
    spinner = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Handles the change of view to TRD configuration.
   * @returns {void}
   */
  const handleChangeViewTrdConfiguration = () => {
    setIsTrdConfiguration(true);
  };

  /**
   * Deletes the TRD path.
   * @param {Event} eventDeleteTrd - The event object for the delete action.
   * @returns {void}
   */
  const deletePathTrd = (eventDeleteTrd) => {
    eventDeleteTrd.stopPropagation();
    setShowCurrentPath(false);
    setCurrentPath([]);
    setCurrentPathEdition([]);
    setFormCreate({
      ...formCreate,
      serie_subserie_uuid: null,
    });
  };

  /**
   * Handles the change event for a selection of fields, updating the state
   * accordingly if the number of selected options is within the allowed limit.
   * @param {Array<Object>} selectedOption - The array of selected options. Each option should be an object with at least a 'value' property.
   * @param {Object} selectedOption[].value - The value of the selected option.
   * @returns {null} Always returns null.
   */
  const handleOnChangeFields = (selectedOption) => {
    if (
      selectedOption.length <= enumsMaxFieldsSelectedSticker.MAX_FIELDS_SELECTED
    ) {
      const selectedValues = selectedOption.map((option) => option.value);
      setUuidStickerFields(selectedValues);

      setFormCreate({
        ...formCreate,
        sticker_fields: selectedOption,
      });
    }

    return null;
  };

  /**
   * Conditionally renders the TrdPathConfiguration component based on the provided parameters.
   * @param {boolean} showCurrentPath - A flag indicating whether to show the current path.
   * @param {boolean} isNullOrUndefined - A function that checks if the given formId is null or undefined.
   * @param {string} formId - The ID of the form to check.
   * @param {string} currentPath - The current path to be passed to the TrdPathConfiguration component.
   * @param {Function} deletePathTrd - A function to handle the deletion of a path in TrdPathConfiguration.
   * @param {string} currentPathForEdiition - The current path being edited, passed to the TrdPathConfiguration component.
   * @returns {JSX.Element|null} The TrdPathConfiguration component if the conditions are met, otherwise null.
   */
  if (showCurrentPath === true || isNullOrUndefined(formId) === false) {
    trdRoutePath = (
      <TrdPathConfiguration
        currentPath={currentPath}
        deletePathTrd={deletePathTrd}
        showCurrentPath={showCurrentPath}
        currentPathForEdiition={currentPathForEdiition}
      />
    );
  }

  /**
   * Renders a button for TRD route configuration if the length of `structureTrd` is greater than `minimumDeTrd`.
   * @param {Array} structureTrd - The array containing TRD structure data.
   * @param {boolean} showCurrentPath - A flag to determine if the current path should be shown and the button should be disabled.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - The translation function that returns translated strings based on keys.
   * @param {JSX.Element} trdRoutePath - The JSX element representing the TRD route path.
   * @param {Function} handleChangeViewTrdConfiguration - The function to handle the click event for changing the TRD configuration view.
   * @returns {JSX.Element|null} The JSX element containing the button and TRD route path, or null if the condition is not met.
   */
  if (structureTrd.length > enumsConsultConfigurationFields.MIN_TRD) {
    trdRouteButton = (
      <div className="d-flex mb-4">
        <Label for="buttonTrd" sm={2}>
          {i18n.t("trd.formsCreate")}
        </Label>
        <Col md={10}>
          <Button
            id="buttonTrd"
            disabled={showCurrentPath}
            type="button"
            className="mb-4 w-50"
            color="cyan"
            onClick={handleChangeViewTrdConfiguration}
          >
            {i18n.t("trd.formsCreate.assig")}
          </Button>
          <br />
          {trdRoutePath}
        </Col>
      </div>
    );
  }

  /**
   * Sets the form title, button label, and renders a sticker fields select component based on the formId.
   * @param {string|null|undefined} formId - The ID of the form. If null or undefined, different labels are used.
   * @param {Object} i18n - The internationalization object for translating text.
   * @param {Object} enumsMaxMenuHeight - An object containing the maximum menu height for the select component.
   * @param {Array} optionsFieldsTemp - Array of options for the select component.
   * @param {Function} handleOnChangeFields - Function to handle changes in the select component.
   * @param {string} noOptionsMessageFields - Message to display when no options are available in the select component.
   * @param {Object} formCreate - An object containing the form's current state.
   * @returns {JSX.Element|null} - The JSX for the sticker fields select component if formId is not null or undefined, otherwise null.
   */
  if (isNullOrUndefined(formId) === false) {
    titleForm = i18n.t("form.designerLabel13");
    buttonLabel = i18n.t("createusers.createButton3");
    stickerFielsSelect = (
      <AvGroup row>
        <Col md={10} className="offset-md-2">
          <Alert className="mbg-3 " color="info">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            {i18n.t("form.fieldStickerAlert")}
            <li>{i18n.t("form.field12")}</li>
            <li>{i18n.t("form.field14")}</li>
            <li>{i18n.t("filterQuery.Field1")}</li>
          </Alert>
        </Col>

        <Label for="selectFields" sm={2}>
          {i18n.t("form.fieldStickerLabel")}
        </Label>
        <Col md={10}>
          <Select
            id="selectFields"
            name="selectFields"
            maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
            menuPlacement="auto"
            closeMenuOnSelect={true}
            components={makeAnimated()}
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            placeholder={i18n.t("form.field87")}
            options={optionsFieldsTemp}
            onChange={handleOnChangeFields}
            noOptionsMessage={() => noOptionsMessageFields}
            value={formCreate.sticker_fields}
          />
        </Col>
      </AvGroup>
    );
  } else {
    titleForm = i18n.t("form.designerItem2");
    buttonLabel = i18n.t("createusers.createButton");
  }

  /**
   * Handles the key press event for the form designer.
   * @param {Event} eventKeyPressForms - The key press event object.
   * @returns {void}
   */
  const handleOnKeyPressFormsDesigner = (eventKeyPressForms) => {
    if (eventKeyPressForms.key === "Enter") {
      eventKeyPressForms.preventDefault();
    }
  };

  /**
   * Handles the change event for the form designer.
   * @param {Event} eventChangeForms - The change event object.
   * @returns {void}
   */
  const handleOnChangeFormsDesigner = (eventChangeForms) => {
    setFormCreate({
      ...formCreate,
      [eventChangeForms.target.name]: eventChangeForms.target.value,
    });
  };

  /**
   * Handles the change event for the form status.
   * @param {Event} eventChangeStatus - The change event object.
   * @returns {void}
   */
  const handleOnChangeFormsStatus = (eventChangeStatus) => {
    setFormCreate({
      ...formCreate,
      status: Number(eventChangeStatus.target.value),
    });
  };

  /**
   * Handles the form submission event for the form designer.
   * @param {Event} eventSubmitForms - The form submission event object.
   * @param {Array} errors - The array of form validation errors.
   * @returns {void}
   */
  const handleOnSubmitFormsDesigner = (eventSubmitForms, errorsDesignForms) => {
    eventSubmitForms.preventDefault();

    if (errorsDesignForms.length === 0) {
      const editFormCreate = keysFormCreate.reduce(
        (acomulatorField, fieldKey) => {
          if (formCreate[fieldKey] !== tempFormCreate[fieldKey]) {
            acomulatorField[fieldKey] = formCreate[fieldKey];
          }
          return acomulatorField;
        },
        {}
      );

      if (Object.keys(editFormCreate).length === 0) {
        showAlert({
          text: i18n.t("form.alertWarning"),
          icon: "info",
        });
        return;
      }

      setIsLoadingFormsDesigner(true);
      if (errorsDesignForms.length === 0) {
        if (
          isNullOrUndefined(formId) === false &&
          isNullOrUndefined(updateDataForm) === false
        ) {
          updateForm(updateDataForm, formId)
            .then((response) => {
              if (response.status === 202) {
                const showAlertFormUpdated = () => {
                  showAlert({
                    text: i18n.t("form.designerUpdateSuccess"),
                  });
                };
                showAlertFormUpdated();
                history.push("/formsdesigner/list");
              }
            })
            .finally(() => {
              setIsLoadingFormsDesigner(false);
            });
        } else {
          createForm(formCreate)
            .then((response) => {
              if (
                isNullOrUndefined(response.data.data.status) === false &&
                isNullOrUndefined(response.data.data.uuid) === false
              ) {
                const formId = response.data.data.uuid;
                if (response.status === 201) {
                  showAlert({
                    text: i18n.t("form.designerCreateSuccess"),
                  });
                }
                history.push(`/formsdesigner/fields/${formId}`);
              } else {
                showAlert({
                  icon: "error",
                  text: i18n.t("error.data.submit"),
                });
              }
            })
            .finally(() => {
              setIsLoadingFormsDesigner(false);
            });
        }
      } else {
        setIsLoadingFormsDesigner(false);
        return;
      }
    }
  };

  /**
   * useEffect hook that updates the data form state whenever the formCreate state changes.
   * @param {Object} formCreate - The state object containing the form data.
   * @param {string} formCreate.name - The name field of the form.
   * @param {string} formCreate.description - The description field of the form.
   * @param {string} formCreate.status - The status field of the form.
   * @param {string} formCreate.serie_subserie_uuid - The UUID of the serie or subserie.
   * @param {Array} uuidStickerFields - An array containing the sticker fields.
   */
  useEffect(() => {
    if (uuidStickerFields.length === 0) {
      const selectedValues = formCreate.sticker_fields.map(
        (option) => option.value
      );
      setUpdateDataForm({
        name: formCreate.name,
        description: formCreate.description,
        status: formCreate.status,
        serie_subserie_uuid: formCreate.serie_subserie_uuid,
        sticker_fields: selectedValues,
      });
    } else {
      setUpdateDataForm({
        name: formCreate.name,
        description: formCreate.description,
        status: formCreate.status,
        serie_subserie_uuid: formCreate.serie_subserie_uuid,
        sticker_fields: uuidStickerFields,
      });
    }
  }, [formCreate, uuidStickerFields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook that updates the available options for sticker fields based on the number of fields already selected.
   * @param {Object} formCreate - The state object containing form data.
   * @param {Array} formCreate.sticker_fields - The array of selected sticker fields in the form.
   * @param {Object} enumsMaxFieldsSelectedSticker - The object containing the maximum allowed fields for stickers.
   * @param {number} enumsMaxFieldsSelectedSticker.MAX_FIELDS_SELECTED - The maximum number of sticker fields that can be selected.
   * @param {Array} fieldsFormList - The array of all possible fields that can be selected in the form.
   * @param {Function} setOptionsFieldsTemp - State setter function to update the temporary options for fields.
   * @param {Function} setNoOptionsMessageFields - State setter function to update the message when no more options are available.
   * @param {Object} i18n - The internationalization object for translating messages.
   * @param {Function} i18n.t - The translation function.
   */
  useEffect(() => {
    if (
      formCreate.sticker_fields.length ===
      enumsMaxFieldsSelectedSticker.MAX_FIELDS_SELECTED
    ) {
      setOptionsFieldsTemp([]);
      setNoOptionsMessageFields(i18n.t("form.field88"));
    } else {
      setOptionsFieldsTemp(fieldsFormList);
      setNoOptionsMessageFields(i18n.t("form.field89"));
    }
  }, [formCreate, fieldsFormList]);

  /**
   * useEffect hook that triggers functions to fetch form data and fields data when the formId changes.
   * @param {string|null} formId - The ID of the form to fetch. If null or undefined, the functions will not be called.
   * @param {Function} getFormsByIdFunc - Function to fetch the form data by ID.
   * @param {Function} getFieldsByIdForSticker - Function to fetch the fields data for the sticker by form ID.
   */
  useEffect(() => {
    if (isNullOrUndefined(formId) === false) {
      getFormsByIdFunc();
      getFieldsByIdForSticker();
    }
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect after component render based on dependencies.
   * This effect sets the visibility of the current path based on provided conditions.
   * @param {Object} currentPathForEdiition - The current path for edition.
   * @param {string | null | undefined} formId - The form identifier.
   */
  useEffect(() => {
    if (
      isNullOrUndefined(formId) === false &&
      Object.values(currentPathForEdiition).length > 0
    ) {
      setShowCurrentPath(true);
    } else {
      setShowCurrentPath(false);
    }
  }, [currentPathForEdiition, formId]);

  /**
   * Executes the effect when the `formId` dependency changes.
   * If `formId` is not null or undefined, it checks the value of `DESIGNER_FORM` and `newForms` array.
   * If `DESIGNER_FORM` is "false" and `formId` is not included in `newForms` array, it navigates back in the browser history.
   * @param {string} formId - The ID of the form.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(formId) === false) {
      if (DESIGNER_FORM === "false" && !newForms.includes(formId)) {
        window.history.back();
      }
    }
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that fetches tree data for a domain and sets the corresponding state variable of the component.
   *The effect will be triggered whenever the 'getTreeByDomainCallback' function changes.
   *@param {function} getTreeByDomainCallback - the function that fetches the tree data for a domain and sets the corresponding state variable
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  /**
   * Renders a FormsDesignerTrdConfiguration component if the 'isTrdConfiguration' flag is true.
   * @param {boolean} isTrdConfiguration - Flag indicating whether to render the FormsDesignerTrdConfiguration component.
   * @param {string} currentPath - Current path for the configuration.
   * @param {Function} formCreate - Function to create a form.
   * @param {Function} setIsTrdConfiguration - Function to set the 'isTrdConfiguration' flag.
   * @param {Function} setCurrentPath - Function to set the current path.
   * @param {Function} setFormCreate - Function to set the 'formCreate' function.
   * @param {Function} setShowCurrentPath - Function to set the 'showCurrentPath' state.
   * @param {object} structureTrd - Structure for the TRD (Technical Requirements Document).
   * @returns {JSX.Element} - FormsDesignerTrdConfiguration component if 'isTrdConfiguration' is true, otherwise null.
   */
  if (isTrdConfiguration === true) {
    return (
      <FormsDesignerTrdConfiguration
        isTrdConfiguration={isTrdConfiguration}
        currentPath={currentPath}
        formCreate={formCreate}
        setIsTrdConfiguration={setIsTrdConfiguration}
        setCurrentPath={setCurrentPath}
        setFormCreate={setFormCreate}
        setShowCurrentPath={setShowCurrentPath}
        structureTrd={structureTrd}
      />
    );
  }

  return (
    <Fragment>
      {loaderElement(isLoadingFormsDesigner || isLoadingTrd)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{titleForm}</CardTitle>
            <div className="divider" />
            <AvForm onSubmit={handleOnSubmitFormsDesigner}>
              <AvGroup row>
                <Label for="name" className="is-required" sm={2}>
                  {i18n.t("form.designerLabel1")}
                </Label>
                <Col md={10}>
                  <ReactTooltip
                    id="name"
                    type="dark"
                    place="bottom"
                    effect="solid"
                  >
                    {i18n.t("trd.Tooltip4")}
                  </ReactTooltip>
                  <AvField
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleOnChangeFormsDesigner}
                    onKeyPress={handleOnKeyPressFormsDesigner}
                    data-tip
                    data-for="name"
                    validate={{
                      pattern: {
                        value: formsDesignerRegex,
                        errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("form.designerFeedback1")}`,
                      },
                      minLength: {
                        value:
                          enumsMaxMinCharactersFormsDesigner.MIN_CHRACTERS_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 5 ${i18n.t("and")} 250 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value:
                          enumsMaxMinCharactersFormsDesigner.MAX_CHARACTERS_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 5 ${i18n.t("and")} 250 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={formCreate.name}
                  />
                </Col>
              </AvGroup>

              <AvGroup row>
                <Label for="description" sm={2}>
                  {i18n.t("form.designerLabel2")}
                </Label>
                <Col sm={10}>
                  <ReactTooltip
                    id="description"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    {i18n.t("trd.Tooltip4")}
                  </ReactTooltip>
                  <AvField
                    id="description"
                    name="description"
                    type="textarea"
                    onChange={handleOnChangeFormsDesigner}
                    onKeyPress={handleOnKeyPressFormsDesigner}
                    data-tip
                    data-for="description"
                    autoComplete="off"
                    validate={{
                      pattern: {
                        value: formsDesignerRegex,
                        errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                      },
                      minLength: {
                        value:
                          enumsMaxMinCharactersFormsDesigner.MIN_CHRACTERS_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value:
                          enumsMaxMinCharactersFormsDesigner.MAX_CHARACTERS_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
                      },
                    }}
                    value={formCreate.description}
                  />
                </Col>
              </AvGroup>

              {stickerFielsSelect}
              {trdRouteButton}

              <AvGroup row>
                <Label for="description" className="is-required" sm={2}>
                  {i18n.t("form.designerLabel3")}
                </Label>
                <Col md={10}>
                  <AvField
                    id="status"
                    name="status"
                    type="select"
                    onChange={handleOnChangeFormsStatus}
                    required
                    errorMessage={i18n.t("form.designerFeedback2")}
                    value={formCreate.status}
                  >
                    <option value="">
                      {i18n.t("form.designerLabel3.1")}
                    </option>
                    <option value={enumsFormStatus.FORMS_STATUS_ERASER}>
                      {i18n.t("form.designerLabel3.2")}
                    </option>
                    <option value={enumsFormStatus.FORMS_STATUS_ACTIVE}>
                      {i18n.t("form.designerLabel3.3")}
                    </option>
                    <option value={enumsFormStatus.FORMS_STATUS_INACTIVE}>
                      {i18n.t("form.designerLabel3.4")}
                    </option>
                  </AvField>
                </Col>
              </AvGroup>

              <CardFooter className="d-block text-right">
                <Link to="/formsdesigner/list">
                  <Button
                    size="lg"
                    disabled={isLoadingFormsDesigner}
                    className="col-mt-3 mr-3"
                    color="gray"
                  >
                    {spinner}
                    {i18n.t("createusers.createButton2")}
                  </Button>
                </Link>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingFormsDesigner}
                  className="col-mt-3"
                  color="cyan"
                >
                  {spinner}
                  {buttonLabel}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FormsDesignerForm;
