import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, Col, CardBody, Row, Spinner, Button } from "reactstrap";
import {
  faSearch,
  faWindowRestore,
  faCheckDouble,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { loaderElement } from "utils/loaderElement";
import { enumsTaskComeFrom } from "utils/enums";
import ReassignTask from "components/molecules/ReassignTask";
import InterruptProcess from "components/molecules/InterruptProcess";
import ReactTooltip from "react-tooltip";
import ModalFilterTasks from "components/molecules/ModalFilterTasks";
import WizardProcessBatch from "components/molecules/WizardProcessBatch";
import TaskBoxListTableFilter from "components/organism/TaskBoxListTableFilter";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";

const TaskBoxList = () => {
  const {
    getTasksBySearch,
    getTasksByFilter,
    funcVerifyProcessBatch,
    totalPages,
    totalCount,
    pagination,
    setPagination,
    isLoading,
    isLoadingFilterTasks,
    isLoadingReassingTask,
    tasks,
    isFilter,
    openModalFilter,
    setOpenModalFilter,
    isVisibleButtonProcessBatch,
    copiedSelectedOptionWorkflow,
    copiedSelectedOption,
    copiedSelectedOptionStep,
    disabledButtonBatchProcess,
    setDisabledButtonBatchProcess,
    selectedFields,
    setSelectedFields,
    openWizardProcess,
    openWizardProcessBatch,
    taskSelectedByBatch,
    setTaskSelectedByBatch,
    activateReassingBatchOption,
    activateInterruptBatchOption,
    structureQueryByFilterTask,
    orderHeaderBy,
    orderHeaderType,
  } = useWorkflowContext();

  let showFormSelected = null;
  let showFormsAndSearchFilters = null;
  let buttonRestoreFilter = null;
  let alertRestorePage = null;
  let buttonAdvancedFilter = null;
  let showTooltip = null;
  let isLoadingTasks = false;
  let buttonProcessBatch = null;
  let isLoadingFilterTask = null;
  let showTotalCountTask = null;
  let isLoadingTask = false;
  let buttonReassignProcessBatch = null;
  let buttonInterruptProcessBatch = null;

  /**
   * Conditionally renders the total count of tasks based on button visibility.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating whether the process batch button is visible.
   * @param {number} totalCount - Total count of tasks.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @returns {JSX.Element} - Total count of tasks component.
   */
  if (isVisibleButtonProcessBatch === true) {
    showTotalCountTask = (
      <h6 className="text-info font-weight-bold">
        <div>
          {i18n.t("taskBoxTable10")}
          <span className="text-dark ml-2">{totalCount}</span>
        </div>
        <div>
          {i18n.t("taskFilter.label21")}:{" "}
          <span className="text-dark ml-2">{selectedFields.length}</span>
        </div>
      </h6>
    );
  } else {
    showTotalCountTask = (
      <h6 className="text-info font-weight-bold">
        <div>
          {i18n.t("taskFilter.label21")}:{" "}
          <span className="text-dark ml-2">{selectedFields.length}</span>
        </div>
      </h6>
    );
  }

  /**
   * Conditionally sets loading filter task spinner based on loading state.
   * @param {boolean} isLoadingFilterTasks - Flag indicating whether filter tasks are loading.
   * @returns {JSX.Element|null} - Spinner component or null.
   */
  if (isLoadingFilterTasks === true) {
    isLoadingFilterTask = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Conditionally renders a tooltip based on the state of the `disabledButtonBatchProcess`.
   * @param {boolean} disabledButtonBatchProcess - Flag indicating whether the button for batch processing is disabled.
   * @returns {JSX.Element|null} - Tooltip element or null if the button is not disabled.
   */
  if (disabledButtonBatchProcess === true) {
    showTooltip = (
      <ReactTooltip
        id="btn-buttonProcessBatch"
        place="top"
        type="info"
        effect="solid"
        className="custom-tooltip"
      >
        <span className="text-lowercase">{i18n.t("taskFilter.label22")}</span>
      </ReactTooltip>
    );
  }

  /**
   * Conditionally renders the process batch button based on visibility.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating whether the process batch button is visible.
   * @param {function} openWizardProcessBatch - Function to open the process batch wizard.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @param {JSX.Element} isLoadingFilterTask - JSX element for loading filter task.
   * @param {JSX.Element} showTooltip - JSX element for showing tooltip.
   * @param {boolean} disabledButtonBatchProcess - Flag indicating whether the button for batch process is disabled.
   * @param {boolean} isLoadingFilterTasks - Flag indicating whether filter tasks are loading.
   * @param {function} funcVerifyProcessBatch - Function to verify process batch.
   * @returns {JSX.Element} - Process batch button component.
   */
  if (isVisibleButtonProcessBatch === false) {
    buttonProcessBatch = (
      <Col lg="3" md="6" sm="12" className="align-self-end">
        <Button
          data-test-id="btn-buttonProcessBatch"
          className="btn-block ml-auto btn-icon btn-mb-responsive"
          color="green"
          onClick={openWizardProcessBatch}
        >
          <FontAwesomeIcon icon={faCog} className="mr-2" />
          <span>{i18n.t("taskButton1")}</span>
        </Button>
        {isLoadingFilterTask}
      </Col>
    );
  } else {
    buttonProcessBatch = (
      <Col lg="4" md="6" sm="12" className="align-self-end">
        <Button
          data-test-id="btn-buttonProcessBatch"
          className="btn-block ml-auto btn-icon btn-mb-responsive"
          color="cyan"
          data-tip
          data-for="btn-buttonProcessBatch"
          disabled={disabledButtonBatchProcess || isLoadingFilterTasks}
          onClick={() => funcVerifyProcessBatch()}
        >
          {showTooltip}
          <FontAwesomeIcon icon={faCheckDouble} className="mr-2" />
          <span>{i18n.t("taskFilter.fullFillTask")}</span>
        </Button>
        {isLoadingFilterTasks}
      </Col>
    );
  }

  /**
   * Sets the 'isLoadingTasks' flag to true if either 'isLoading' or 'isLoadingFilterTasks' is true.
   * @param {boolean} isLoading - Flag indicating whether general loading is in progress.
   * @param {boolean} isLoadingFilterTasks - Flag indicating whether loading specific tasks is in progress.
   * @param {boolean} isLoadingTasks - Flag indicating whether loading tasks in general is in progress.
   */
  if (isLoading === true || isLoadingFilterTasks === true) {
    isLoadingTasks = true;
  }

  /**
   * Set the isLoadingTask flag to true based on certain conditions.
   * @param {boolean} isLoadingTasks - Indicates whether tasks are currently loading.
   * @param {boolean} openWizardProcess - Indicates whether the wizard process is open.
   * @returns {void}
   */
  if (isLoadingTasks === true && openWizardProcess === false) {
    isLoadingTask = true;
  }

  /**
   * Represents an advanced filter button component.
   * This component renders a button used for triggering an advanced filter modal for tasks.
   * The button is wrapped in a Bootstrap Col component for layout purposes.
   * When clicked, it invokes the provided handleOnOpenModalFilterTask function to open the filter modal.
   * @constant {JSX.Element} buttonAdvancedFilter - The JSX element representing the advanced filter button.
   * @param {function} handleOnOpenModalFilterTask - The function to be called when the advanced filter modal for tasks should be opened.
   * @returns {JSX.Element} - The JSX element representing the advanced filter button.
   */
  if (isVisibleButtonProcessBatch === true) {
    buttonAdvancedFilter = (
      <Col lg="4" md="6" sm="12">
        <Button
          data-test-id="btn-advancedFilter"
          className="btn-block ml-auto btn-icon btn-block btn-mb-responsive"
          color="cyan"
          onClick={handleOnOpenModalFilterTask}
        >
          <FontAwesomeIcon icon={faSearch} className="mr-2" />
          <span>{i18n.t("taskFilter.label18")}</span>
        </Button>
      </Col>
    );
  }

  /**
   * Renders a button for restoring filters if either the filter is active or the button to process batches is not visible.
   * @param {boolean} isFilter - A flag indicating whether the filter is active.
   * @param {boolean} isVisibleButtonProcessBatch - A flag indicating the visibility of the button to process batches.
   * @param {string} i18n.t - A function for translating text, used to translate the label for the button.
   * @returns {JSX.Element|null} Returns the JSX element for the restore filter button if conditions are met, otherwise returns null.
   */
  if (isFilter === true || isVisibleButtonProcessBatch === false) {
    buttonRestoreFilter = (
      <Col lg="3" md="6" sm="12">
        <Button
          data-test-id="btn-buttonRestoreFilter"
          className="btn-block ml-auto btn-icon btn-block btn-mb-responsive"
          color="gray"
          onClick={() => window.location.reload()}
        >
          <FontAwesomeIcon icon={faWindowRestore} className="mr-2" />
          <span>{i18n.t("taskFilter.label14")}</span>
        </Button>
      </Col>
    );
  }

  /**
   * Generates a button to reassign tasks in batch if the 'activateReassingBatchOption' flag is true.
   * @param {boolean} activateReassingBatchOption - Flag indicating whether the option to reassign tasks in batch is activated.
   * @param {string[]} selectedFields - Array containing the IDs of the tasks selected for batch reassignment.
   * @param {Object} enumsTaskComeFrom - Enumeration containing task types, where 'PROCESS_BATCH_TASK' represents tasks originated from a batch process.
   * @returns {JSX.Element|null} - Returns a React element representing the button to reassign tasks in batch, or null if the option is not activated.
   */
  if (activateReassingBatchOption === true) {
    buttonReassignProcessBatch = (
      <Col lg="3" md="6" sm="12" className="align-self-end">
        <ReassignTask
          typeTask={enumsTaskComeFrom.PROCESS_BATCH_TASK}
          idsTaskSelectedByBatch={selectedFields}
        />
      </Col>
    );
  }

  /**
   * Generates an interrupt process button if the activateInterruptBatchOption flag is set to true.
   * @param {boolean} activateInterruptBatchOption - Flag indicating whether the interrupt batch option is activated.
   * @param {string[]} selectedFields - Array containing the IDs of the selected fields.
   * @param {string} enumsTaskComeFrom - Enumeration indicating the source of the task.
   * @returns {JSX.Element | null} The interrupt process button component or null if the flag is not set to true.
   */
  if (activateInterruptBatchOption === true) {
    buttonInterruptProcessBatch = (
      <Col lg="3" md="6" sm="12">
        <InterruptProcess
          typeTask={enumsTaskComeFrom.PROCESS_BATCH_TASK}
          idsTaskSelectedByBatch={selectedFields}
        />
      </Col>
    );
  }

  /**
   * Handles the action to open the modal for filtering tasks based on certain conditions.
   * This function opens the modal for filtering tasks if either of the following conditions is met:
   * The `isFilter` parameter is `true`.
   * The `isVisibleButtonProcessBatch` parameter is `false`.
   * When the modal is opened, it sets the state `openModalFilter` to `true`.
   * @param {boolean} isFilter - Flag indicating whether filtering is enabled.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating the visibility of the button for processing batches.
   * @returns {void}
   */
  function handleOnOpenModalFilterTask() {
    setOpenModalFilter(true);
  }

  /**
   * useEffect hook that fetches tasks based on search criteria when certain dependencies change.
   * @param {boolean} isFilter - A flag indicating whether a filter is applied.
   * @param {function} getTasksBySearch - Function to fetch tasks based on search criteria.
   * @param {string} orderHeaderType - The type of order header for task sorting.
   * @param {string} orderHeaderBy - The property by which tasks are sorted.
   * @param {boolean} pagination - A flag indicating whether pagination has changed.
   * @returns {void}
   */
  useEffect(() => {
    if (isFilter === false) {
      if (
        isNullOrUndefined(orderHeaderBy) === false &&
        isNullOrUndefined(orderHeaderType) === false
      ) {
        getTasksBySearch(orderHeaderType, orderHeaderBy);
      } else {
        getTasksBySearch();
      }
    } else {
      if (
        isNullOrUndefined(orderHeaderBy) === false &&
        isNullOrUndefined(orderHeaderType) === false
      ) {
        getTasksByFilter(
          structureQueryByFilterTask,
          orderHeaderType,
          orderHeaderBy
        );
      } else {
        getTasksByFilter(structureQueryByFilterTask);
      }
    }
  }, [pagination, orderHeaderBy, orderHeaderType]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the disabled state of the batch process button based on selected fields length.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {function} setDisabledButtonBatchProcess - State setter function to update disabled state of the batch process button.
   * @returns {void}
   */
  useEffect(() => {
    if (selectedFields.length > 1) {
      setDisabledButtonBatchProcess(false);
    } else {
      setDisabledButtonBatchProcess(true);
    }
  }, [selectedFields]); // eslint-disable-line react-hooks/exhaustive-deps

  if (openWizardProcess === false) {
    return (
      <Fragment>
        {loaderElement(isLoadingTask || isLoadingReassingTask)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Fragment>
            <Card className="main-card mb-3">
              <CardBody className="pb-1">
                <Row>
                  <Col lg="5" md="4" sm="12" className="button-container">
                    <Row>{showTotalCountTask}</Row>
                  </Col>
                  <Col
                    lg="7"
                    md="8"
                    sm="12"
                    className="button-container mt-2 mt-md-0 mb-4"
                  >
                    <Row className="row justify-content-end">
                      {buttonRestoreFilter}
                      {buttonInterruptProcessBatch}
                      {buttonReassignProcessBatch}
                      {buttonProcessBatch}
                      {buttonAdvancedFilter}
                    </Row>
                    {showFormSelected}
                    {showFormsAndSearchFilters}
                    {alertRestorePage}
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="pt-0">
                <TaskBoxListTableFilter
                  tasks={tasks}
                  isLoading={isLoadingFilterTasks}
                  totalPages={totalPages}
                  pagination={pagination}
                  setPagination={setPagination}
                  isFilter={isFilter}
                  selectedOptions={copiedSelectedOption}
                  selectedOptionsWorkflow={copiedSelectedOptionWorkflow}
                  selectedOptionsStep={copiedSelectedOptionStep}
                  selectedFields={selectedFields}
                  setSelectedFields={setSelectedFields}
                  isVisibleButtonProcessBatch={isVisibleButtonProcessBatch}
                  taskSelectedByBatch={taskSelectedByBatch}
                  setTaskSelectedByBatch={setTaskSelectedByBatch}
                />
                <ModalFilterTasks
                  setPagination={setPagination}
                  setOpenModalFilter={setOpenModalFilter}
                  openModalFilter={openModalFilter}
                  handleOnOpenModalFilterTask={handleOnOpenModalFilterTask}
                />
              </CardBody>
            </Card>
          </Fragment>
        </CSSTransitionGroup>
      </Fragment>
    );
  } else {
    return <WizardProcessBatch />;
  }
};

export default TaskBoxList;
