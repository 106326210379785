import React, { Fragment, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Alert } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { getDateFormat } from "utils/getDateFormat";
import { getDateFormat2 } from "utils/getDateFormat2";
import { initialPaginationForms } from "utils/initialPaginationsConfig";
import { actionFormService } from "utils/serviceHelper";
import { enumsControlDataFields } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { Link } from "react-router-dom";
import useForm from "hooks/useForm";
import i18n from "locales/i18n";
import { loaderElement } from "utils/loaderElement";

const ProcessWorkflowRecordDeatilView = (props) => {
  const { recordUuid, formUuid, formName } = props;
  const { getFields } = useForm();
  const {
    isLoading,
    setIsLoading,
    fields,
    getRecordDetail,
    recordInfo,
    recordNumber,
    setFields,
    recordFields,
    fieldsDinamic,
    setFieldsDinamic,
    setRecordFields,
  } = useRecordDetailContext();
  let fieldstoShow = [];
  let parteDeseada = "";

  /**
   * Extracts the desired part from a form name string based on a delimiter.
   * @param {string} formName - The full form name string from which the desired part is to be extracted.
   * @param {string} delimiter - The delimiter used to separate different parts of the form name.
   * @returns {string} The desired part of the form name string.
   */
  if (formName.includes("|")) {
    parteDeseada = formName.split("|")[1].trim();
  } else {
    parteDeseada = formName.trim();
  }

  /**
   * Extracts columns based on their label from a given array of fields.
   * @param {Array<Object>} fields - An array containing objects representing fields.
   * @returns {Array<Object>} - An array containing objects representing columns filtered by label.
   */
  const columnsByLabel = (fields) => {
    fields.filter((value) => {
      if (value.control_data !== 4) {
        fieldstoShow.push(value);
        return value;
      } else {
        fieldstoShow.push(value);
        return value;
      }
    });

    return fieldstoShow;
  };

  /**
   * Fetches fields by performing a search operation based on the provided form UUID,
   * and updates the state with the retrieved fields.
   * @param {string} formUuid - The UUID of the form to search for fields.
   * @param {object} initialPaginationForms - Initial pagination settings for retrieving fields.
   * @param {number} initialPaginationForms.page - The page number for pagination.
   * @param {number} initialPaginationForms.per_page - The number of items per page for pagination.
   * @param {function} setIsLoading - State setter function to indicate whether the component is in a loading state.
   * @param {function} getFields - Function to fetch fields based on provided parameters.
   * @param {function} setFields - State setter function to update the retrieved fields.
   * @param {function} showAlertServiceError - Function to show an alert for service errors.
   * @returns {void}
   */
  const getFieldsBySearch = useCallback(
    () => {
      const { page, per_page } = initialPaginationForms;
      setIsLoading(true);
      getFields(page, per_page, formUuid, actionFormService.ADMIN_ACTIONS)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setFields(response.data.items);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [formUuid] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * useEffect hook that fetches and sets record details when the component mounts or when the formUuid or recordUuid changes.
   * @param {function} getRecordDetail - Function to fetch record details.
   * @param {string} formUuid - The UUID of the form associated with the record.
   * @param {string} recordUuid - The UUID of the record to fetch details for.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(formUuid) === false &&
      isNullOrUndefined(recordUuid) === false
    ) {
      getRecordDetail(formUuid, recordUuid, actionFormService.ADMIN_ACTIONS);
    } else {
      showAlertServiceError();
    }
    return () => {
      setFields([]);
      setFieldsDinamic([]);
      setRecordFields({});
    };
  }, [getRecordDetail, formUuid, recordUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that triggers the retrieval of fields by search criteria when the form UUID changes,
   * and cleans up state variables when the component unmounts or the dependencies change.
   * @param {function} getFieldsBySearch - Function to retrieve fields based on search criteria.
   * @param {string} formUuid - The UUID of the form used as search criteria for fetching fields.
   * @returns {void}
   */
  useEffect(() => {
    getFieldsBySearch(formUuid);
    return () => {
      setFields([]);
      setFieldsDinamic([]);
      setRecordFields({});
    };
  }, [getFieldsBySearch, formUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that filters columns by label, updates dynamic fields state, and sets dependencies to trigger updates.
   * @param {Array} fields - The array of fields containing column data to be filtered.
   * @param {function} setFieldsDinamic - State setter function for updating dynamic fields.
   * @returns {void}
   */
  useEffect(() => {
    const filteredColumns = columnsByLabel(fields).filter(
      (column) => column.status === 1
    );
    setFieldsDinamic((prevState) =>
      prevState.concat(
        filteredColumns.map((column) => ({
          label: column.label,
          columnId: column.uuid,
          control: column.control_data,
        }))
      )
    );
  }, [fields, setFieldsDinamic]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDetailRecord = () => {
    return fieldsDinamic.map((column) => {
      let fieldRecordName = "";
      let fieldDateName = (
        <h6 className="font-weight-bold text-cyan">{column.label}:</h6>
      );

      /**
       * Renders a form field with a combobox control if the column's control type is COMBOBOX.
       * @param {object} column - The column configuration object.
       * @param {string} column.control - The type of control for the column.
       * @param {string} column.columnId - The unique identifier for the column.
       * @param {string} column.label - The label text for the column.
       * @param {object} recordFields - The object containing field records.
       * @param {string} fieldRecordName - The name of the field record.
       * @param {boolean} isNullOrUndefined - Indicates whether a value is null or undefined.
       * @returns {JSX.Element|null} Rendered JSX element representing the form field with combobox control.
       */
      if (column.control === enumsControlDataFields.COMBOBOX) {
        if (isNullOrUndefined(recordFields[column.columnId]) === false) {
          fieldRecordName = recordFields[column.columnId].name;
        }
        return (
          <Row className="padding-container-next" key={column.columnId}>
            <h6 className="font-weight-bold text-cyan">
              {column.label} : &nbsp;
            </h6>
            {fieldRecordName}
          </Row>
        );
      }

      /**
       * Renders a label component based on the provided column data.
       * @param {object} column - The column object containing control and label information.
       * @param {string} column.control - The type of control for the column.
       * @param {string} column.columnId - The unique identifier for the column.
       * @param {string} column.label - The label text to be displayed.
       * @param {object} enumsControlDataFields - Enumerated values for different control types.
       * @returns {JSX.Element|null} The JSX element representing the label component or null if the control type is not LABEL.
       */
      if (column.control === enumsControlDataFields.LABEL) {
        return (
          <Row className="padding-container-next" key={column.columnId}>
            <h5 className="font-weight-bold text-cyan">{column.label}</h5>
          </Row>
        );
      }

      /**
       * Renders a date field based on the provided column configuration and record data.
       * @param {object} column - The column configuration object containing information about the field.
       * @param {string} column.control - The type of control for the field (e.g., enumsControlDataFields.DATEPICKER).
       * @param {string} column.columnId - The unique identifier for the column.
       * @param {string} column.label - The label to display for the field.
       * @param {object} recordFields - An object containing the record data.
       * @param {Date} recordFields[column.columnId] - The date value for the specified column.
       * @param {function} getDateFormat - A function to format the date value.
       * @returns {JSX.Element} - Returns the JSX element representing the date field.
       */
      if (column.control === enumsControlDataFields.DATEPICKER) {
        if (isNullOrUndefined(recordFields[column.columnId]) === false) {
          fieldDateName = (
            <Fragment>
              <h6 className="font-weight-bold text-cyan">
                {column.label}: &nbsp;
              </h6>
              {getDateFormat(new Date(recordFields[column.columnId]))}
            </Fragment>
          );
        }
        return (
          <Row className="padding-container-next" key={column.columnId}>
            {fieldDateName}
          </Row>
        );
      }

      /**
       * Renders a DateTimePicker field within a row if the column control matches enumsControlDataFields.DATETIMEPICKER
       * @param {Object} column - The column object containing information about the field.
       * @param {string} column.control - The type of control for the column.
       * @param {string} column.columnId - The unique identifier for the column.
       * @param {string} column.label - The label for the column.
       * @param {Object} recordFields - An object containing data fields related to the record.
       * @param {string} fieldDateName - The name of the field that holds the formatted date.
       * @param {boolean} isNullOrUndefined - A function to check if a value is null or undefined.
       * @param {function} getDateFormat2 - A function to format dates.
       * @returns {JSX.Element} - Returns the JSX element representing the DateTimePicker field within a row.
       */
      if (column.control === enumsControlDataFields.DATETIMEPICKER) {
        if (isNullOrUndefined(recordFields[column.columnId]) === false) {
          fieldDateName = (
            <Fragment>
              <h6 className="font-weight-bold text-cyan">
                {column.label}: &nbsp;
              </h6>
              {getDateFormat2(new Date(recordFields[column.columnId]))}
            </Fragment>
          );
        }
        return (
          <Row className="padding-container-next" key={column.columnId}>
            {fieldDateName}
          </Row>
        );
      }

      /**
       * Renders a form row for a specific column if the column control type is not one of the specified types.
       * The row includes the column label and corresponding data field value.
       * @param {object} column - The column configuration object.
       * @param {string} column.control - The type of control associated with the column.
       * @param {string} column.label - The label of the column.
       * @param {string} column.columnId - The unique identifier of the column.
       * @param {object} recordFields - Object containing data fields corresponding to column IDs.
       * @returns {JSX.Element|null} Returns the JSX element representing the form row if conditions are met, otherwise null.
       */
      if (
        column.control !== enumsControlDataFields.COMBOBOX &&
        column.control !== enumsControlDataFields.LABEL &&
        column.control !== enumsControlDataFields.DATEPICKER &&
        column.control !== enumsControlDataFields.DATETIMEPICKER
      ) {
        return (
          <Row className="padding-container-next" key={column.columnId}>
            <h6 className="font-weight-bold text-cyan">
              {column.label}: &nbsp;
            </h6>
            {recordFields[column.columnId]}
          </Row>
        );
      }

      return null;
    });
  };

  return (
    <Fragment>
      {loaderElement(isLoading)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Alert color="success">
          <h5 className="alert-heading font-weight-bold">{parteDeseada}</h5>
          <hr className="mb-1 mt-1" />
          <p className="mb-0">
            {i18n.t("recordList9")}:{" "}
            <Link
              to={`/record/detail/${recordUuid}/${formUuid}`}
              className="font-weight-bold"
            >
              {recordNumber}
            </Link>
          </p>
          <p className="mb-0">
            {i18n.t("itemList.designerLabel3")}:{" "}
            <strong>{getDateFormat2(new Date(recordInfo.created_at))}</strong>
          </p>
          <p className="mb-0">
            {i18n.t("recordData3")}: <span>{recordInfo.user_name}</span>
          </p>
        </Alert>
        {renderDetailRecord()}
      </CSSTransitionGroup>
    </Fragment>
  );
};

ProcessWorkflowRecordDeatilView.propTypes = {
  recordUuid: PropTypes.string.isRequired,
  formUuid: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
};

export default ProcessWorkflowRecordDeatilView;
