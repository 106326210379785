import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import StickerConfig from "components/organism/StickerConfig";
import i18n from "locales/i18n";

const StickerPage = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("navItems.aplication.Config")}
        subheading={i18n.t("stickerSubheader")}
        icon="settings"
      >
        <StickerConfig />
      </AdminTemplate>
    </Fragment>
  );
};

export default StickerPage;
