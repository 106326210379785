import React, { useEffect, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, CardHeader, Label } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { useListContext } from "contextAPI/ListsContext";
import { enumsFieldsTyData, enumsFieldsTypeControl } from "utils/enums";
import i18n from "locales/i18n";

const FieldsTypeControl = () => {
  const { fieldForm, setFieldForm } = useFieldsRecordContext();
  const { setCanNext } = useListContext();

  /**
   * Handles the selection of a data type and updates the field form state.
   * This function takes a selection event, extracts the selected value, converts it to a number,
   * and updates the `type_data` property of the `fieldForm` state.
   * @param {Object} selectType - The event object generated by the selection.
   * @param {Object} selectType.target - The element that triggered the event.
   * @param {string} selectType.target.value - The selected value from the dropdown.
   * @returns {void}
   */
  const handleTypeData = (selectType) => {
    setFieldForm({
      ...fieldForm,
      type_data: Number(selectType.target.value),
    })
  };

  /**
   * Handles the selection of a control type and updates the field form state.
   * This function takes a selection event, extracts the selected value, converts it to a number,
   * and updates the `control_data` property of the `fieldForm` state.
   * @param {Object} selectControl - The event object generated by the selection.
   * @param {Object} selectControl.target - The element that triggered the event.
   * @param {string} selectControl.target.value - The selected value from the dropdown.
   * @returns {void}
   */
  const handleControlData = (selectControl) => {
    setFieldForm({
      ...fieldForm,
      control_data: Number(selectControl.target.value),
    })
  };

  /**
   * useEffect hook that monitors changes in the 'fieldForm' object properties and updates the 'canNext' state accordingly.
   * This hook checks if either 'type_data' or 'control_data' properties are missing or falsy in the 'fieldForm' object.
   * If either property is missing or falsy, it sets the 'canNext' state to true, indicating that the user can proceed to the next step.
   * Otherwise, if both properties are present and truthy, it sets the 'canNext' state to false.
   * @param {object} fieldForm - The object containing form field data.
   * @param {boolean} canNext - The state that indicates whether the user can proceed to the next step.
   * @param {function} setCanNext - The state setter function for updating the 'canNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (!fieldForm.type_data || !fieldForm.control_data) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [fieldForm.type_data, fieldForm.control_data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <AvForm className="margin-bottom-form">
            <CardHeader className="mb-4">
              {i18n.t("form.fieldHeading")}
            </CardHeader>

            <AvGroup row>
              <Label for="data_type" className="is-required" sm={2}>
                {i18n.t("form.field5")}
              </Label>
              <Col md={10}>
                <AvField
                  id="type_data"
                  name="type_data"
                  type="select"
                  onChange={handleTypeData}
                  required
                  errorMessage={i18n.t("form.fieldFeedback")}
                  value={fieldForm.type_data}
                >
                  <option value="">{i18n.t("form.field6")}</option>
                  <option value={enumsFieldsTyData.TEXT_STRING}>{i18n.t("form.field7")}</option>
                  <option value={enumsFieldsTyData.INTEGER}>{i18n.t("form.field8")}</option>
                  <option value={enumsFieldsTyData.DATE}>{i18n.t("form.field9")}</option>
                  <option value={enumsFieldsTyData.TIME_AND_DATE}>{i18n.t("form.field10")}</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="control_data" className="is-required" sm={2}>
                {i18n.t("form.field3")}
              </Label>
              <Col md={10}>
                <AvField
                  id="control_data"
                  name="control_data"
                  type="select"
                  onChange={handleControlData}
                  required
                  errorMessage={i18n.t("form.fieldFeedback")}
                  value={fieldForm.control_data}
                >
                  <option value="">{i18n.t("form.field11")}</option>
                  {fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && (
                    <Fragment>
                      <option value={enumsFieldsTypeControl.TEXT_BOX}>{i18n.t("form.field12")}</option>
                      <option value={enumsFieldsTypeControl.TEXT_AREA}>{i18n.t("form.field13")}</option>
                      <option value={enumsFieldsTypeControl.DROPDOWN_LIST}>{i18n.t("form.field14")}</option>
                      <option value={enumsFieldsTypeControl.LOOKUP}>{i18n.t("form.field61")}</option>
                      <option value={enumsFieldsTypeControl.LABEL}>{i18n.t("form.field15")}</option>
                    </Fragment>
                  )}
                  {fieldForm.type_data === enumsFieldsTyData.INTEGER && (
                    <option value={enumsFieldsTypeControl.NUMERIC_TEXBOX}>{i18n.t("form.field16")}</option>
                  )}
                  {fieldForm.type_data === enumsFieldsTyData.DATE && (
                    <option value={enumsFieldsTypeControl.DATA_PICKER}>{i18n.t("form.field17")}</option>
                  )}
                  {fieldForm.type_data === enumsFieldsTyData.TIME_AND_DATE && (
                    <option value={enumsFieldsTypeControl.TIME_AND_DATE_PICKER}>{i18n.t("form.field18")}</option>
                  )}
                </AvField>
              </Col>
            </AvGroup>
          </AvForm>
          <div>
            <Label className="font-weight-bold text-primary" for="Tips">
              {i18n.t("form.field19")}
            </Label>
            <span>
              <ul>
                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field7")}
                  </span>
                  <span>: {i18n.t("form.field21")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field8")}
                  </span>
                  <span>: {i18n.t("form.field22")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field9")}
                  </span>
                  <span>: {i18n.t("form.field23")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field10")}
                  </span>
                  <span>: {i18n.t("form.field24")}</span>
                </li>
              </ul>
            </span>

            <Label className="font-weight-bold text-primary" for="Tips">
              {i18n.t("form.field20")}
            </Label>
            <span>
              <ul>
                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field12")}
                  </span>
                  <span>: {i18n.t("form.field25")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field13")}
                  </span>
                  <span>: {i18n.t("form.field26")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field14")}
                  </span>
                  <span>: {i18n.t("form.field27")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field61")}
                  </span>
                  <span>
                    : {i18n.t("form.field62")}{" "}
                    <i className="font-weight-bold">
                      {i18n.t("form.field62-required")}
                    </i>
                  </span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field15")}
                  </span>
                  <span>: {i18n.t("form.field28")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field16")}
                  </span>
                  <span>: {i18n.t("form.field29")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field17")}
                  </span>
                  <span>: {i18n.t("form.field30")}</span>
                </li>

                <li>
                  <span className="font-weight-bold text-primary">
                    {i18n.t("form.field18")}
                  </span>
                  <span>: {i18n.t("form.field31")}</span>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FieldsTypeControl;
