export default class CustomContextPad {
  constructor(config, contextPad, create, elementFactory, injector, translate) {
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get("autoPlace", false);
    }
    contextPad.registerProvider(this);
  }

  /**
   * Configures the context pad entries for the given element. 
   * @param {Object} element - The element for which to configure the context pad entries.
   * @param {Object} contextPad - The context pad object.
   * @param {Object} contextPad.entries - The current context pad entries.
   * @param {Object} contextPad.autoPlace - The function for auto-placing elements.
   * @param {Function} contextPad.create - The function to start creating a new element.
   * @param {Object} contextPad.elementFactory - The factory for creating new shapes.
   */
  getContextPadEntries(element) {
    const {
      autoPlace,
      create,
      elementFactory,
    } = this;
    // eslint-disable-next-line
    function appendServiceTask(event, element) {
      if (autoPlace) {
        const shape = elementFactory.createShape({ type: "bpmn:ServiceTask" });

        autoPlace.append(element, shape);
      } else {
        appendServiceTaskStart(event, element);
      }
    }

    function appendServiceTaskStart(event) {
      const shape = elementFactory.createShape({ type: "bpmn:ServiceTask" });

      create.start(event, shape, element);
    }
  }
}

CustomContextPad.$inject = [
  "config",
  "contextPad",
  "create",
  "elementFactory",
  "injector",
  "translate",
];