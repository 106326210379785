import { capitalizeText } from "./formatText";
import i18n from "locales/i18n";

let MIN_LENGTH = 0;

/**
 * Processes error data and organizes it into an object based on error codes.
 * @param {Array<Object>} data - Array of error data objects. Each object should have a `code` and `content` property.
 * @returns {Object} An object where keys are error codes and values are the corresponding error contents.
 */
export function processErrorData(data) {
  let errorObject = {};
  data.forEach(item => {
    if (item.code === "30103") {
      errorObject[item.code] = item.content;
    } else if (item.code === "30099" && Array.isArray(item.content)) {
      errorObject[item.code] = item.content.map(workflow => workflow.name);
    } else if (item.code === "5064" && Array.isArray(item.content)) {
      errorObject[item.code] = item.content.map(workflow => workflow.name);
    }
  });
  return errorObject;
}

/**
 * Generates an HTML list from an array of workflow names.
 * @param {Array<string>} items - Array of workflow names.
 * @returns {string} HTML string representing a list of workflows.
 */
function generateList(items) {
  return items.map(
    (workflow, index) => `<li key=${index}><em>${capitalizeText(workflow)}</em></li>`
  ).join("");
}

/**
 * Builds the HTML content for an alert based on the provided error object.
 * @param {Object} errorObject - An object where keys are error codes and values are the corresponding error contents.
 * @returns {string} An HTML string representing the alert content.
 */
export function buildAlertContent(errorObject) {
  let fileListWorkflow = [];
  let fileListWorkflowInStep = [];
  let fielConfigurateAsSticker = false;

  if (errorObject["30099"] !== undefined) {
    fileListWorkflow = generateList(errorObject["30099"]);
  }

  if (errorObject["5064"] !== undefined) {
    fileListWorkflowInStep = generateList(errorObject["5064"]);
  }

  if (errorObject["30103"] !== undefined) {
    fielConfigurateAsSticker = true;
  }

  return showAlertErrors(fileListWorkflow, fileListWorkflowInStep, fielConfigurateAsSticker);
}

/**
 * Generates HTML content for an alert based on the provided data.
 * @param {Array<string>} fileListWorkflow - Array of workflow names to display in a list.
 * @param {Array<string>} fileListWorkflowInStep - Array of workflow names to display in a step-specific list.
 * @param {boolean} fielConfigurateAsSticker - Indicates if sticker configuration error exists.
 * @returns {string} HTML string representing the alert content.
 */
function showAlertErrors(fileListWorkflow, fileListWorkflowInStep, fielConfigurateAsSticker) {
  let listWorkflows = "";
  let alertContentWorkflows = "";
  let listWorkflowNotAllowed = "";
  let stickerConfiguration = "";
  let listWorkflowsInStep = "";

  if (fileListWorkflow.length > MIN_LENGTH) {
    listWorkflows = `<ul>${fileListWorkflow}</ul>`;
  }

  if (fileListWorkflowInStep.length > MIN_LENGTH) {
    alertContentWorkflows = `<ul>${fileListWorkflowInStep}</ul>`;
  }

  if (fileListWorkflow.length > MIN_LENGTH) {
    listWorkflowNotAllowed = `<span>${i18n.t("30099")}</span><br><br>${listWorkflows}`;
  }

  if (fielConfigurateAsSticker === true) {
    stickerConfiguration = `<span>${i18n.t("30103")}</span>`;
  }

  if (fileListWorkflowInStep.length > MIN_LENGTH) {
    listWorkflowsInStep = `<span>${i18n.t("5064")}:</span><br><br/>${alertContentWorkflows}`;
  }

  return `
    <div class="aling-alert">
      ${listWorkflowNotAllowed} 
      ${listWorkflowsInStep}
      ${stickerConfiguration} 
    </div>
  `;
}
