import React, { Fragment, useEffect } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useRolesContext } from "contextAPI/RolesContext";
import { Button, Col, Row } from "reactstrap";
import i18n from "locales/i18n";
import ReactTable from "react-table";
import ButtonActionsRolesForm from "components/atoms/ButtonActionsRolesForm";
import UserRolConfigForm from "../UserRolConfigForm";
import UserRolesDetailPermissions from "../UserRolesDetailPermissions";
import UserRolesEditPermissions from "../UserRolesEditPermissions";

const UserRolesFormListTable = (props) => {
  const { uuidRol } = props;
  const {
    getPermissionsByForm,
    getActiveForms,
    permissions,
    isLoadingPermissionsByForm,
    isLoadingAllForms,
    isDeleted,
    acordeon,
    setAcordeon,
    componentToShow,
    setComponentToShow,
    handleButtonClick,
    chargeForm,
    isLoading,
    setChargeForm,
    formDetail,
    setFormDetail,
  } = useRolesContext();
  let loaderTable = false;
  let noData = null;

  /**
   * Handles the click event on a row within a table, updating the form details state.
   * @param {object} row - The row object representing the clicked row.
   * @param {string} row.formUuid - The UUID of the form associated with the clicked row.
   * @param {string} row.formName - The name of the form associated with the clicked row.
   * @param {object} row.permissions - The permissions object associated with the clicked row.
   * @returns {void}
   */
  const handleRowClick = (row) => {
    const { formUuid, formName, permissions } = row.original;
    setFormDetail({
      form_uuid: formUuid,
      form_name: formName,
      permissions: permissions,
    });
  };

  /**
   * Determines the state of the loaderTable based on the loading status of various permissions and forms.
   * This function checks the loading status of permissions and forms and sets the loaderTable state accordingly.
   * If any of the specified loading conditions is true, the loaderTable is set to true, indicating a loading state.
   * Otherwise, the loaderTable is set to false, indicating that no loading is in progress.
   * @param {boolean} isLoadingPermissionsByForm - Indicates whether permissions for a specific form are currently being loaded.
   * @param {boolean} isLoadingAllForms - Indicates whether permissions for all forms are currently being loaded.
   * @param {boolean} isLoading - Indicates whether any general loading process is currently ongoing.
   * @returns {boolean} loaderTable - A boolean value indicating whether the loaderTable should be in a loading state.
   */
  if (
    isLoadingPermissionsByForm === true ||
    isLoadingAllForms === true ||
    isLoading === true
  ) {
    loaderTable = true;
  } else {
    loaderTable = false;
  }

  /**
   * Sets the 'noData' variable based on the value of 'loaderTable'.
   * If 'loaderTable' is not true, 'noData' is set to a localized string indicating empty table rows.
   * If 'loaderTable' is true, 'noData' is set to null.
   * @param {boolean} loaderTable - A flag indicating whether the table is in a loading state.
   *   If true, the table is considered to have data; if false, it's considered to have no data.
   * @returns {void}
   */
  if (loaderTable !== true) {
    noData = i18n.t("createRoles.configDesignFormsEmpty");
  } else {
    noData = null;
  }

  /**
   * useEffect hook that performs actions when certain dependencies change.
   * If the first element of the 'acordeon' array is true, it triggers several asynchronous tasks,
   * updates states, and manages component behavior based on specified dependencies.
   * @param {function} getPermissionsByForm - Function to retrieve permissions based on a form UUID.
   * @param {function} getActiveForms - Function to retrieve a list of active forms.
   * @param {function} setChargeForm - State setter function to control the loading state of a form.
   * @param {boolean[]} acordeon - An array representing the accordion state. The function triggers
   * actions only when the first element is true.
   * @param {boolean} isDeleted - State indicating whether a form has been deleted.
   * @param {boolean} chargeForm - State indicating whether a form is in the process of being loaded.
   * @param {string} uuidRol - UUID used to identify a role or user.
   * @returns {void}
   */
  useEffect(() => {
    if (acordeon[0] === true) {
      getPermissionsByForm(uuidRol);
      getActiveForms();
      setChargeForm(false);
    }
  }, [componentToShow, acordeon, isDeleted, chargeForm, uuidRol]); // eslint-disable-line react-hooks/exhaustive-deps

  switch (componentToShow) {
    case "0":
      return (
        <Fragment>
          <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container p-3 ">
                <em className="text-primary font-weight-bold">
                  {i18n.t("createRoles.addedForms")}
                </em>
              </Col>
              <div className="btn-actions-pane-right">
                <Button
                  className="button m-2"
                  color="success"
                  disabled={loaderTable}
                  onClick={() => handleButtonClick("1")}
                >
                  {i18n.t("createRoles.configDesignWorkflowsAdd")}
                </Button>
              </div>
            </Row>

            <ReactTable
              data={permissions}
              columns={[
                {
                  Header: [i18n.t("createWorflow.form")],
                  accessor: "formName",
                },

                {
                  Header: [i18n.t("buttonActions.title")],
                  Cell: (row) => {
                    return (
                      <div className="d-block w-100 text-center">
                        <ButtonActionsRolesForm
                          uuidRol={uuidRol}
                          form_uuid={row.original.formUuid}
                          form_name={row.original.formName}
                          permissions={row.original.permissions}
                          setComponentToShow={setComponentToShow}
                          setAcordeon={setAcordeon}
                          onClick={() => handleRowClick(row)}
                        />
                      </div>
                    );
                  },
                },
              ]}
              manual
              className="-striped -highlight"
              showPageJump={false}
              defaultPageSize={5}
              loading={loaderTable}
              loadingText={i18n.t("loading")}
              noDataText={noData}
              previousText={i18n.t("previousText")}
              nextText={i18n.t("nextText")}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            />
          </CSSTransitionGroup>
        </Fragment>
      );
    case "1":
      return <UserRolConfigForm uuidRol={uuidRol} />;
    case "2":
      return <UserRolesDetailPermissions formDetail={formDetail} />;
    case "3":
      return (
        <UserRolesEditPermissions uuidRol={uuidRol} formDetail={formDetail} />
      );
    default:
      return null;
  }
};

UserRolesFormListTable.propTypes = {
  uuidRol: PropTypes.string.isRequired,
};

export default UserRolesFormListTable;
