import React, { useState, useCallback } from "react";
import { Button } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import useRecord from "hooks/useRecord";
import useForm from "hooks/useForm";
import useList from "hooks/useList";
import swal from "sweetalert";
import i18n from "locales/i18n";

const FilterContext = React.createContext(undefined);

export function FilterContextProvider({ children }) {
  const { id: recordId } = useParams();
  const {
    setConcatenatedValues,
    setValueFieldLookup,
  } = useFieldsRecordContext();
  const { getItems } = useList();
  const { getFields, getFormsById } = useForm();
  const { getRecordsByFilters } = useRecord();
  const [startDate, setStartDate] = useState("", "", "");
  const [isErrorDate, setIsErrorDate] = useState(false);
  const [isErrorDateTime, setIsErrorDateTime] = useState(false);
  const [queryByRecord, setQueryByRecord] = useState({
    data_type: 5,
    filter: 1,
    operator: "and",
    value: "",
  });
  const [queryByFieldRecord, setQueryByFieldRecord] = useState({
    data_type: "",
    data_control: "",
    field_uuid: "",
    filter: 1,
    value: "",
    operator: "and",
  });
  const [queryByFieldRecordTemp, setQueryByFieldRecordTemp] = useState({
    data_type: "",
    data_control: "",
    field_uuid: "",
    filter: 1,
    value: "",
    operator: "and",
    label: "",
    item_link: "",
    formLookupName: "",
    formLookupFields: "",
    formUuid: "",
  });
  const [queryFieldsTemp, setQueryFieldsTemp] = useState([]);
  const [items, setItems] = useState([]);
  const [queries, setQueries] = useState([]);
  const [queriesRec, setQueriesRec] = useState({
    search_filter: [],
  });
  const [queriesTable, setQueriesTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, per_page: 100 });
  const [totalPages, setTotalPages] = useState(0);
  const [fields, setFields] = useState([]);
  const [dataRecordLookup, setDataRecordLookup] = useState([]);
  const [formDetail, setFormDetail] = useState({
    name: "",
    description: "",
  });
  const [isFilterForms, setIsFilterForms] = useState(false);
  const dateRegexRecord = /^\d{1,10}-\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-\d{1,10}$/;
  const dataRegexRecordEnds = /^\d{0,10}-{0,1}\d{0,8}-{0,1}\d{0,10}$/;
  const dataRegexText = /^[a-zA-Z0-9 À-ÿ_-]+$/;
  const dataRegexNumber = /^([0-9]+)$/;
  let actions = 0;

  /**
   * State Hook for managing table columns configuration.
   * @property {string[]} Header - The column header text.
   * @property {string} accessor - The accessor key for retrieving data from the row.
   * @property {boolean} base - Indicates if this column is a base configuration.
   * @property {function} Cell - A custom cell rendering function.
   * @returns {[ColumnConfig[], function]} A tuple containing the columns configuration array
   * and a function to update it.
   */
  const [columnsTable, setColumnsTable] = useState([
    {
      Header: i18n.t("recordList8"),
      accessor: "count_files",
      base: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Button
            outline
            className="mb-2 mr-2 btn-pill btn-dashed"
            color="info"
            disabled
          >
            <span className="pr-2">
              <FontAwesomeIcon icon={faFile} />
            </span>
            <span className="badge-pill text-bold">{row.count_files}</span>
          </Button>
        </div>
      ),
    },
    {
      Header: i18n.t("filteruser.button"),
      accessor: "status",
      base: true,
      Cell: ({ value }) => {
        let statusFinal = "";
        if (value === 1) {
          statusFinal = (
            <div className="ml-auto badge badge-success">
              {i18n.t("filteruser.item2")}
            </div>
          );
        } else if (value === 2) {
          statusFinal = (
            <div className="ml-auto badge badge-warning">
              {i18n.t("info.SignatureFilter3")}
            </div>
          );
        }
        return <span style={{ minWidth: "150px" }}>{statusFinal}</span>;
      },
    },
    {
      Header: i18n.t("recordList9"),
      id: "link",
      accessor: "record",
      base: true,
      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Link to={`/record/detail/${row._original.uuid}/${recordId}`}>
            {row.link}
          </Link>
        </div>
      ),
    },
    {
      Header: i18n.t("recordList10"),
      accessor: "created_at",
      base: true,
      Cell: ({ value }) => {
        let valueFinal = getDateFormat2(new Date(value));

        return <span>{valueFinal}</span>;
      },
    },
    {
      Header: i18n.t("form.field47"),
      accessor: "updated_at",
      base: true,
      Cell: ({ value }) => {
        let valueFinal = getDateFormat2(new Date(value));

        return <span>{valueFinal}</span>;
      },
    },
    {
      Header: i18n.t("createusers.label1"),
      accessor: "user_name",
      base: true,
    },
  ]);

  /**
   * Fetches form details by UUID and updates the state with the form's name and description.
   * @param {string} recordId - The UUID to identify the specific form.
   * @param {object} actions - An object containing actions or configuration for the request.
   * @param {function} setFormDetail - State setter function for updating form details.
   * @returns {void}
   */
  const getFormsByUuid = useCallback(() => {
    getFormsById(recordId, actions).then((response) => {
      const nameForm = response.data.name;
      const descriptionForm = response.data.description;
      if (isNullOrUndefined(nameForm) === false) {
        setFormDetail({
          name: nameForm,
          description: descriptionForm,
        });
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches fields based on a search criteria and updates the state with the results.
   * This function retrieves fields using the provided search criteria and updates the state with the retrieved data.
   * @param {number} id - The identifier for the search criteria.
   * @param {object} actions - An object containing action-related information.
   * @param {boolean} isSearchField - A boolean indicating whether the field is a search field.
   * @returns {void}
   */
  const getFieldsBySearch = useCallback(
    (formId) => {
      let pagination_field = { page: 1, per_page: 1000 };
      const { page, per_page } = pagination_field;
      setIsLoading(true);
      getFields(page, per_page, formId, actions, true)
        .then((response) => {
          const itemsField = response.data.items;
          if (isNullOrUndefined(itemsField) === false) {
            setFields(itemsField);
            const queryFields = itemsField
              .filter(
                (field) => field.status === 1 && field.search_field === true
              )
              .sort((firstName, secondName) => {
                const initialNames = `${firstName.label} `.toLocaleLowerCase();
                const finalNames = `${secondName.label} `.toLocaleLowerCase();
                if (initialNames > finalNames) {
                  return 1;
                } else if (finalNames > initialNames) {
                  return -1;
                } else {
                  return 0;
                }
              });

            setQueryFieldsTemp(() =>
              queryFields.map((field) => ({
                value: field.uuid,
                label: field.label,
                dataType: field.type_data,
                typeControl: field.control_data,
                itemLink: field.item_link,
                order: field.order,
                formLookupName: field.form_lookup_name,
                formLookupFields: field.form_lookup_fields,
                formUuid: field.form_uuid,
              }))
            );
            setIsFilterForms(true);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Fetches items by search using the provided ID and updates the item list in the state.
   * This function makes an asynchronous request to fetch items based on the provided ID, page, and per_page values from a paginated data source.
   * It sets the loading state to true while fetching data and updates the item list in the state if data is successfully retrieved.
   * If there are no items or an error occurs during the request, it may display an error alert.
   * @param {string} id - The ID used as a search parameter to retrieve specific items.
   * @returns {void}
   */
  const getItemsBySearch = useCallback(
    (recordId) => {
      const { page, per_page } = pagination;
      setIsLoading(true);
      getItems(recordId, page, per_page, "")
        .then((response) => {
          const itemsList = response.data.items;
          if (isNullOrUndefined(itemsList) === false) {
            setItems(itemsList);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [pagination, getItems]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the key press event and prevents the "Enter" key's default behavior.
   * @param {object} event - The key press event object.
   */
  const handleOnKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  /**
   * Handles the change event of a filter input and updates relevant state values accordingly.
   * @param {Event} eventFilter - The event object representing the filter input change.
   * @returns {void}
   */
  const handleOnChangeFilter = (eventFilter) => {
    if (
      queryByFieldRecord.data_type !== 0 &&
      queryByFieldRecord.field_uuid !== ""
    ) {
      setQueryByFieldRecord({
        ...queryByFieldRecord,
        filter: Number(eventFilter.target.value),
        value: "",
      });
      setQueryByFieldRecordTemp({
        ...queryByFieldRecordTemp,
        filter: Number(eventFilter.target.value),
        value: "",
      });
    } else {
      setQueryByRecord({
        ...queryByRecord,
        filter: Number(eventFilter.target.value),
        value: "",
      });
    }
    setStartDate("", "", "");
    setConcatenatedValues("");
    setValueFieldLookup("");
  };

  /**
   * Updates the value of a query field record based on the provided event value.
   * If a field UUID is available in the queryByFieldRecord object, it updates that field's value; otherwise,
   * it updates the value in the queryByRecord object.
   * @param {Event} eventValue - The event object containing the new value, typically from an input element.
   * @returns {void}
   */
  const handleOnChangeValue = (eventValue) => {
    if (queryByFieldRecord.field_uuid) {
      setQueryByFieldRecord({
        ...queryByFieldRecord,
        value: eventValue.target.value.toLowerCase(),
      });
      setQueryByFieldRecordTemp({
        ...queryByFieldRecordTemp,
        value: eventValue.target.value.toLowerCase(),
      });
    } else {
      setQueryByRecord({
        ...queryByRecord,
        value: eventValue.target.value.toLowerCase(),
      });
    }
  };

  /**
   * Resets and updates various query-related states and sets the start date.
   * @param {object} eventField - The event object representing a field change.
   * @param {string} eventField.target.value - The value of the field that triggered the change.
   * @returns {void}
   */
  const handleOnChangeField = (eventField) => {
    if (
      (queryByFieldRecord.data_type === 1 &&
        queryByFieldRecord.data_control === 3 &&
        queryByFieldRecord.filter === 1) ||
      (queryByFieldRecord.data_type === 1 &&
        queryByFieldRecord.data_control === 8)
    ) {
      setQueryByRecord({
        ...queryByRecord,
        data_type: 5,
        filter: 1,
        operator: "and",
        value: "",
      });
      setQueryByFieldRecord({
        ...queryByFieldRecord,
        data_type: 0,
        field_uuid: eventField.target.value.toLowerCase(),
        filter: 1,
        value: "",
      });
      setQueryByFieldRecordTemp({
        ...queryByFieldRecordTemp,
        field_uuid: eventField.target.value.toLowerCase(),
        filter: 1,
        value: "",
        uuid: "",
      });
      setStartDate("", "", "");
      setConcatenatedValues("");
      setValueFieldLookup("");
    } else {
      setQueryByRecord({
        ...queryByRecord,
        data_type: 5,
        filter: 1,
        operator: null,
        value: "",
      });
      setQueryByFieldRecord({
        ...queryByFieldRecord,
        data_type: 0,
        field_uuid: eventField.target.value.toLowerCase(),
        filter: 1,
        value: "",
      });
      setQueryByFieldRecordTemp({
        ...queryByFieldRecordTemp,
        field_uuid: eventField.target.value.toLowerCase(),
        filter: 1,
        value: "",
        uuid: "",
      });
      setStartDate("", "", "");
      setConcatenatedValues("");
      setValueFieldLookup("");
    }
  };

  /**
   * Updates the operator value in the query objects based on the provided event.
   * This function is used to handle changes in a specific form field and adjust various
   * state variables based on certain conditions.
   * @param {object} eventOperator - The event object representing a change in the operator value.
   * @param {string} eventOperator.target.value - The new operator value to set (case-insensitive).
   * @returns {void}
   */
  const handleOnChangeOperator = (eventOperator) => {
    setQueryByRecord({
      ...queryByRecord,
      operator: eventOperator.target.value.toLowerCase(),
    });
    setQueryByFieldRecord({
      ...queryByFieldRecord,
      operator: eventOperator.target.value.toLowerCase(),
    });
    setQueryByFieldRecordTemp({
      ...queryByFieldRecordTemp,
      operator: eventOperator.target.value.toLowerCase(),
    });
  };

  /**
   * Compares two objects for equality, excluding the 'operator' property.
   * This function compares two objects by iterating over their properties and checking if the properties and their values
   * are the same, excluding the 'operator' property from the comparison.
   * @param {Object} objA - The first object to compare.
   * @param {Object} objB - The second object to compare.
   * @returns {boolean} Returns true if the objects are equal (excluding the 'operator' property), otherwise false.
   */
  const areObjectsEqualExcludingOperator = (objA, objB) => {
    const propsA = Object.keys(objA).filter((key) => key !== "operator");
    const propsB = Object.keys(objB).filter((key) => key !== "operator");
    if (propsA.length !== propsB.length) {
      return false;
    }
    for (const prop of propsA) {
      if (objA[prop] !== objB[prop]) {
        return false;
      }
    }
    return true;
  };

  /**
   * Handles form submission based on various validation conditions.
   * @param {Event} eventNext - The event object representing the form submission.
   * @param {object} queryByFieldRecord - The query related to the field record.
   * @param {object} queryByRecord - The query related to the record.
   * @param {object} queryByFieldRecordTemp - Temporary query related to the field record.
   * @param {function} setIsErrorDate - A function to set the date error flag.
   * @param {function} setIsErrorDateTime - A function to set the date-time error flag.
   * @param {function} setQueriesTable - A function to set the queries table.
   * @param {function} setQueries - A function to set the queries.
   * @param {function} setShowTable - A function to set the table visibility.
   * @param {function} setQueryByRecord - A function to set the query related to the record.
   * @param {function} setQueryByFieldRecord - A function to set the query related to the field record.
   * @param {function} setQueryByFieldRecordTemp - A function to set the temporary query related to the field record.
   * @returns {void}
   */
  const handleOnSubmitSig = (eventNext) => {
    const isDuplicate = queries.some((existingQuery) => {
      return (
        areObjectsEqualExcludingOperator(existingQuery, queryByFieldRecord) ||
        areObjectsEqualExcludingOperator(existingQuery, queryByRecord)
      );
    });

    if (
      queryByFieldRecord.data_type === 3 &&
      queryByFieldRecord.data_control === 6 &&
      queryByFieldRecord.value === ""
    ) {
      setIsErrorDate(true);
      eventNext.preventDefault();
    } else if (
      queryByFieldRecord.data_type === 4 &&
      queryByFieldRecord.data_control === 7 &&
      queryByFieldRecord.value === ""
    ) {
      setIsErrorDateTime(true);
      eventNext.preventDefault();
    } else if (
      (queryByRecord.value === "" && queryByFieldRecord.value === "") ||
      (queryByRecord.data_type === 5 &&
        queryByFieldRecord.data_type === 0 &&
        queryByRecord.filter === 1 &&
        !dateRegexRecord.test(queryByRecord.value)) ||
      (queryByRecord.data_type === 5 &&
        queryByRecord.filter === 4 &&
        !dataRegexRecordEnds.test(queryByRecord.value)) ||
      (queryByFieldRecord.data_type === 1 &&
        !dataRegexText.test(queryByFieldRecord.value)) ||
      (queryByFieldRecord.data_type === 2 &&
        queryByFieldRecord.data_control === 5 &&
        !dataRegexNumber.test(queryByFieldRecord.value))
    ) {
      eventNext.preventDefault();
    } else if (isDuplicate) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("filterQuery.noParamAllowed"),
        icon: "warning",
        button: i18n.t("modal.Done.footerButton"),
      });
      eventNext.preventDefault();
    } else {
      setQueriesTable((prevState) => {
        const newQueriesTable = [...prevState];
        let newQuery = null;
        if (queryByRecord.value !== "") {
          newQuery = queryByRecord;
        } else if (queryByFieldRecordTemp.value !== "") {
          newQuery = queryByFieldRecordTemp;
        }
        if (newQuery !== null) {
          newQueriesTable.push(newQuery);
        }
        return newQueriesTable;
      });
      setQueries((prevState) => {
        const newQueries = [...prevState];
        let newQuery = "";
        if (queryByRecord.value !== "") {
          newQuery = queryByRecord;
        } else if (queryByFieldRecord.value !== "") {
          newQuery = queryByFieldRecord;
        }
        if (newQuery !== "") {
          newQueries.push(newQuery);
        }
        return newQueries;
      });
      setDataRecordLookup((prevState) => {
        if (queryByFieldRecord.data_control === 8) {
          return [
            ...prevState,
            {
              uuid: queryByFieldRecord.value,
              value: queryByFieldRecordTemp.value,
              data_control: 8,
            },
          ];
        } else {
          return prevState;
        }
      });
      setShowTable(true);
      setQueryByRecord({
        ...queryByRecord,
        value: "",
        operator: "and",
      });
      setQueryByFieldRecord({
        ...queryByFieldRecord,
        field_uuid: "",
        value: "",
        operator: "and",
      });
      setQueryByFieldRecordTemp({
        ...queryByFieldRecordTemp,
        field_uuid: "",
        value: "",
        operator: "and",
        uuid: "",
      });
      setIsErrorDate(false);
      setConcatenatedValues("");
      setValueFieldLookup("");
    }
  };

  function formatDate(fecha) {
    const parts = fecha.split("/");
    const newDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return newDate;
  }

  /**
   * Deletes queries with the specified value from the queriesTable and queries state arrays.
   * @param {string} value - The value to be removed from the queriesTable and queries arrays.
   * @returns {void}
   */
  function deleteQueries(value) {
    const currentQueries = queriesTable.filter((item) => item.value !== value);
    const newQueries = queriesTable
      .filter((item) => item.value !== value)
      .map((eventDelete) => {
        if (eventDelete.data_control === 8) {
          return {
            data_control: eventDelete.data_control,
            data_type: eventDelete.data_type,
            field_uuid: eventDelete.field_uuid,
            filter: eventDelete.filter,
            operator: eventDelete.operator,
            value: eventDelete.uuid,
          };
        } else if (eventDelete.data_control === 6) {
          return {
            data_control: eventDelete.data_control,
            data_type: eventDelete.data_type,
            field_uuid: eventDelete.field_uuid,
            filter: eventDelete.filter,
            operator: eventDelete.operator,
            value: formatDate(eventDelete.value),
          };
        } else {
          return {
            data_control: eventDelete.data_control,
            data_type: eventDelete.data_type,
            field_uuid: eventDelete.field_uuid,
            filter: eventDelete.filter,
            operator: eventDelete.operator,
            value: eventDelete.value,
          };
        }
      });
    setQueriesTable(currentQueries);
    setQueries(newQueries);
    if (currentQueries.length === 0) {
      setDataRecordLookup([]);
    }
  }

  /**
   * Handles the onBlur event for an input element, updating the queryByRecord value by trimming the input value.
   * @param {Event} eventBlur - The onBlur event object triggered by the input element.
   */
  const handleOnBlur = (eventBlur) => {
    setQueryByRecord({
      ...queryByRecord,
      value: eventBlur.target.value.trim(),
    });
  };

  /**
   * Handles the submission of a form with pagination and filters, retrieves records, and updates state accordingly.
   * @param {string} recordId - The identifier used for retrieving records.
   * @param {number} page - The current page number for pagination.
   * @param {number} per_page - The number of records to display per page.
   * @param {Array} queriesRec - An array of filter queries to apply to the records.
   * @returns {void}
   */
  const handleOnSubmit = () => {
    const { page, per_page } = pagination;
    setIsLoading(true);
    getRecordsByFilters(recordId, page, per_page, queriesRec)
      .then((response) => {
        const recordsObtained = response.data.items;
        const amountRecords = response.data.count;
        const totalPagesObtained = response.data.pages;
        if (
          isNullOrUndefined(recordsObtained) === false &&
          isNullOrUndefined(amountRecords) === false &&
          isNullOrUndefined(totalPagesObtained) === false
        ) {
          setRecords(recordsObtained);
          setCount(amountRecords);
          setTotalPages(totalPagesObtained);
          if (recordsObtained.length === 0) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("filterQuery.NorecordsMessage"),
              icon: "warning",
              button: i18n.t("modal.Done.footerButton"),
            }).then((willClose) => {
              if (willClose) {
                setQueryByRecord({
                  ...queryByRecord,
                  data_type: 5,
                  filter: 1,
                  operator: "and",
                  value: "",
                });
                setQueryByFieldRecord({
                  ...queryByFieldRecord,
                  data_type: 0,
                  data_control: "",
                  field_uuid: "",
                  filter: 1,
                  value: "",
                  operator: "and",
                });
                setQueryByFieldRecordTemp({
                  ...queryByFieldRecordTemp,
                  data_type: "",
                  data_control: "",
                  field_uuid: "",
                  filter: 1,
                  value: "",
                  operator: "and",
                  label: "",
                  item_link: "",
                  formLookupName: "",
                  formLookupFields: "",
                  formUuid: "",
                });
                setQueriesRec({ ...queriesRec, search_filter: [] });
                setQueries([]);
                setQueriesTable([]);
                setDataRecordLookup([]);
                setShowTable(false);
                setStartDate("", "", "");
              }
            });
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <FilterContext.Provider
      value={{
        recordId,
        handleOnKeyPress,
        handleOnChangeFilter,
        handleOnChangeValue,
        handleOnChangeField,
        handleOnChangeOperator,
        handleOnBlur,
        queryByRecord,
        setQueryByRecord,
        queryByFieldRecord,
        setQueryByFieldRecord,
        queryFieldsTemp,
        queries,
        queriesTable,
        setQueries,
        isLoading,
        setIsLoading,
        handleOnSubmit,
        handleOnSubmitSig,
        pagination,
        setPagination,
        count,
        setCount,
        records,
        setRecords,
        totalPages,
        setTotalPages,
        columnsTable,
        getFormsByUuid,
        getFieldsBySearch,
        fields,
        formDetail,
        setColumnsTable,
        deleteQueries,
        getItemsBySearch,
        items,
        setItems,
        queryByFieldRecordTemp,
        setQueryByFieldRecordTemp,
        queriesRec,
        showTable,
        startDate,
        setStartDate,
        isErrorDate,
        setIsErrorDate,
        isErrorDateTime,
        setIsErrorDateTime,
        dataRecordLookup,
        setDataRecordLookup,
        setQueriesTable,
        setShowTable,
        setQueriesRec,
        isFilterForms,
        setIsFilterForms,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}
export function useFilterContext() {
  const context = React.useContext(FilterContext);
  if (!context) {
    throw new Error(
      "useFilterContext debe estar dentro del proveedor FilterContext."
    );
  }
  return context;
}
