import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, CardTitle } from "reactstrap";
import { getDateFormat } from "utils/getDateFormat";
import { INTEGRATIONS } from "constants/securityConst";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import ReactTable from "react-table";
import ButtonActionsFIles from "components/atoms/ButtonActionsFIles";
import RecordFieldListInfo from "components/organism/RecordFieldListInfo";
import RecordFileMoveFile from "components/organism/RecordFileMoveFile";
import ModalTrashFile from "components/molecules/ModalTrashFile";
import i18n from "locales/i18n";

const RecordFileListTableTRD = (props) => {
  const {
    files,
    isLoadingFilesTrd,
    recordId,
    recordUuid,
    formId,
    trdExistRec,
    totalPagesFilesRecordTrd,
    paginationTrd,
    setPaginationTrd,
  } = props;
  const {
    setIsChangePage,
    setIsOpenFileEdit,
    handleOpenEditFile,
    isOpenFileEditTrd,
    closeFileTables,
    isOpenFileMoveTrd,
    handleOpenMoveFile,
    handleOpenMoveTrash,
    isOpenFileMoveTrash,
    setIsOpenFileMoveTrash,
  } = useRecordDetailContext();
  const [typeConfigurationFile] = useState("trd");
  const INTEGRATIONSOPT = window.localStorage.getItem(INTEGRATIONS);
  let modalFileToShow = null;

  /**
   * Conditionally assigns a modal JSX element for moving a file to the trash
   * to the modalFileToShow variable if isOpenFileMoveTrash is true, otherwise assigns null.
   * @param {boolean} isOpenFileMoveTrash - A boolean indicating whether the file move to trash modal is open.
   * @param {function} setIsOpenFileMoveTrash - A function to set the isOpenFileMoveTrash state.
   * @type {JSX.Element|null} The JSX element representing the modal or null.
   */
  if (isOpenFileMoveTrash === true) {
    modalFileToShow = (
      <ModalTrashFile
        isOpenFileMoveTrash={isOpenFileMoveTrash}
        setIsOpenFileMoveTrash={setIsOpenFileMoveTrash}
      />
    );
  } else {
    modalFileToShow = null;
  }

  /**
   * Renders the 'RecordFileMoveFile' component if the 'isOpenFileMoveTrd' state is true.
   * @param {boolean} isOpenFileMoveTrd - Indicates whether the file move TRD (Technical Record Document) is open.
   * @param {Array} files - An array of files to be passed as a prop to the 'RecordFileMoveFile' component.
   * @returns {JSX.Element|null} The 'RecordFileMoveFile' component if 'isOpenFileMoveTrd' is true, otherwise null.
   */
  if (isOpenFileMoveTrd === true) {
    return <RecordFileMoveFile files={files} />;
  }

  /**
   * Renders a component to display information about a list of record fields if the 'isOpenFileEditTrd' flag is true.
   * @param {boolean} isOpenFileEditTrd - Flag indicating whether the file edit TRD (Technical Reference Document) is open.
   * @param {Array} files - The list of files to be displayed in the RecordFieldListInfo component.
   * @param {function} setIsOpenFileEdit - A function to set the state of the 'isOpenFileEdit' flag.
   * @returns {JSX.Element} - The RecordFieldListInfo component with provided files and setIsOpenFileEdit function.
   */
  if (isOpenFileEditTrd === true) {
    return <RecordFieldListInfo files={files} setIsOpenFileEdit={setIsOpenFileEdit} />;
  }

  if (closeFileTables === true) {
    return (
      <Fragment>
        <Row>
          <Col md="12" className="table-files-record">
            <CardTitle>{i18n.t("recordFile.TableName2")}</CardTitle>
            <ReactTable
              data={files}
              columns={[
                {
                  columns: [
                    {
                      Header: [i18n.t("record.affair")],
                      id: "link",
                      accessor: "subject",
                      Cell: ({ row }) => {
                        return (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${row._original.presigned_url}`}
                          >
                            {row.link}
                          </a>
                        );
                      },
                    },
                    {
                      Header: [i18n.t("record.supportType")],
                      id: "support_type",
                      accessor: "support_type",
                      Cell: ({ value }) => {
                        let supportType = "";

                        if (value === "1") {
                          supportType = i18n.t("record.supportType.option1");
                        } else if (value === "2") {
                          supportType = i18n.t("record.supportType.option2");
                        } else {
                          supportType = i18n.t("record.supportType.option3");
                        }
                        return <span>{supportType}</span>;
                      },
                    },
                    {
                      Header: [i18n.t("record.dateDocument")],
                      accessor: "publication_at",
                      Cell: ({ value }) => {
                        let valueFinal = getDateFormat(new Date(value));

                        return <span>{valueFinal}</span>;
                      },
                    },
                    {
                      Header: [i18n.t("recordFile.publishDate")],
                      accessor: "created_at",
                      Cell: ({ value }) => {
                        let valueFinal = getDateFormat(new Date(value));

                        return <span>{valueFinal}</span>;
                      },
                    },
                    {
                      Header: [i18n.t("createusers.label1")],
                      accessor: "user_name",
                    },
                  ],
                },

                {
                  columns: [
                    {
                      Header: [i18n.t("buttonActions.title")],
                      accessor: "actions",
                      Cell: (row) => {
                        return (
                          <div className="d-block w-100 text-center">
                            <ButtonActionsFIles
                              fileId={row.original.uuid}
                              recordId={recordId}
                              recordUuid={recordUuid}
                              formId={formId}
                              trdExistRec2={trdExistRec}
                              nameFile={row.original.name}
                              subjectFile={row.original.subject}
                              publication={row.original.publication_at}
                              integrationOption={INTEGRATIONSOPT}
                              typeExtension={row.original.type}
                              handleOpenEditFile={handleOpenEditFile}
                              typeConfigurationFile={typeConfigurationFile}
                              handleOpenMoveFile={handleOpenMoveFile}
                              backgroundId={row.original.path.background_uuid}
                              levelId={row.original.path.level_uuid}
                              expedientId={row.original.path.expedient_uuid}
                              showActionsButtons={true}
                              handleOpenMoveTrash={handleOpenMoveTrash}
                            />
                          </div>
                        );
                      },
                    },
                  ],
                },
              ]}
              manual
              className="-striped -highlight"
              pages={totalPagesFilesRecordTrd}
              page={paginationTrd.page - 1}
              showPageJump={false}
              defaultPageSize={paginationTrd.per_page}
              loading={isLoadingFilesTrd}
              loadingText={i18n.t("loading")}
              noDataText={`${i18n.t("tableRowsEmpty")}`}
              previousText={`${i18n.t("previousText")}`}
              nextText={`${i18n.t("nextText")}`}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
              onPageSizeChange={(per_page) => {
                setPaginationTrd({
                  ...paginationTrd,
                  per_page: per_page,
                });
              }}
              onPageChange={(page) => {
                setPaginationTrd({
                  ...paginationTrd,
                  page: page + 1,
                });
                setIsChangePage(true);
              }}
            />
          </Col>
        </Row>
        {modalFileToShow}
      </Fragment>
    );
  } else if (closeFileTables === false) {
    return null;
  }
};

RecordFileListTableTRD.propTypes = {
  files: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  recordId: PropTypes.string.isRequired,
  recordUuid: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  trdExistRec: PropTypes.bool.isRequired,
};

export default RecordFileListTableTRD;
