import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardHeader, Row, Col, Button, Alert } from "reactstrap";
import {
  AiFillCaretRight,
  AiOutlineFolder,
  AiFillFolderOpen,
} from "react-icons/ai";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";

const FormsDesignerTrdConfiguration = (props) => {
  const {
    setIsTrdConfiguration,
    currentPath,
    setCurrentPath,
    formCreate,
    setFormCreate,
    setShowCurrentPath,
    structureTrd
  } = props;

  let showButtonAssing = false;
  const [expandedNodeTree, setExpandedNodeTree] = useState([]);
  const [selectedNodeTreeId, setSelectedNodeTreeId] = useState(null);
  const [minSerieOrSubSerieLevel] = useState(3);
  const roots = structureTrd.filter((nodeTree) => nodeTree.parentId === null);

  /**
   * Handles the closing of the TRD configuration.
   * @returns {void}
   */
  const handleCloseTrdConfiguration = () => {
    setIsTrdConfiguration(false)
  }

  /**
   * Handles the click event on a selected level assignment.
   * @param {Event} eventAssing - The click event object.
   * @param {Object} node - The selected node object.
   * @returns {void}
   */
  const handleClickSelectedLevelAssing = (eventAssing, node) => {
    eventAssing.stopPropagation();
    currentPath.push(node.label);
    setFormCreate({
      ...formCreate,
      serie_subserie_uuid: node.id
    });
    setShowCurrentPath(true);
    setIsTrdConfiguration(false);
  }

  /**
   *Function that handles the click event on a node in the tree structure.
   *@param {object} node - the node object representing the clicked node
   *@param {array} expandedNodeTree - an array containing the ids of the expanded nodes in the tree structure
   *@param {function} toggleExpandedTree - a function to toggle the expanded state of a node in the tree structure
   *@param {array} structureTrd - an array representing the tree structure
   *@param {function} setSelectedNodeTree - a function to set the selected node in the tree structure
   *@param {string} setSelectedNodeTreeId - a function to set the id of the selected node in the tree structure
   */
  const handleNodeClick = (node) => {
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    toggleExpandedTree(node.id);
    if (isExpandedNodeTree === false) {
      setSelectedNodeTreeId(node.id);
    } else {
      setSelectedNodeTreeId(node.parentId);
    }
  };

  /**
  *Function that toggles the expanded state of a tree node and performs related operations.
  *@param {string} nodeId - the ID of the node to toggle the expanded state
  *@param {array} structureTrd - an array of objects representing the tree structure
  *@param {array} expandedNodeTree - an array of node IDs representing the currently expanded nodes in the tree
  *@param {function} setExpandedNodeTree - the state setter function for the array of expanded node IDs
  *@param {number} selectedNodeTreeId - the ID of the currently selected node in the tree
  *@param {function} setSelectedNodeTreeId - the state setter function for the selected node ID
  *@param {function} updateCurrentPath - a function that updates the current path based on the selected node ID
  *@param {boolean} hasFetchedExpedients - a boolean value indicating whether the expedients have been fetched
  *@param {function} setHasFetchedExpedients - the state setter function for the hasFetchedExpedients variable
  */
  const toggleExpandedTree = (nodeId) => {
    const backgrund = structureTrd.find((nodeTree) => nodeTree.id === nodeId);
    const expedientNodeId = expandedNodeTree.includes(nodeId);
    if (expedientNodeId === true) {
      setExpandedNodeTree(
        expandedNodeTree.filter((nodeExpanded) => nodeExpanded !== nodeId)
      );
      if (backgrund.level === 0) {
        setExpandedNodeTree([]);
      }
    } else {
      setExpandedNodeTree([...expandedNodeTree, nodeId]);
    }
    setSelectedNodeTreeId(nodeId);
    updateCurrentPath(nodeId);
  };


  /**
   *Updates the current path based on a given nodeId.
   *@param {number|null} nodeId - The id of the node to update the current path for. If null, the current path will be cleared.
   *@param {array} structureTrd - An array representing the structure tree to search for the node.
   *@param {array} expandedNodeTree - An array of ids representing the expanded nodes in the tree.
   *@param {function} setPathRedirection - The state setter function for the path redirection.
   *@param {function} setCurrentPath - The state setter function for the current path.
   */
  const updateCurrentPath = (nodeId) => {
    if (nodeId === null) {
      setCurrentPath([]);
      return;
    }
    const getNodeById = (id) =>
      structureTrd.find((nodeTree) => nodeTree.id === id);
    const nodePath = getNodeById(nodeId);
    if (nodePath !== null) {
      const path = [nodePath];
      let parentId = nodePath.parentId;
      const isExpandedNodeTree = expandedNodeTree.includes(nodeId);
      while (parentId !== null) {
        const parentNode = getNodeById(parentId);

        if (parentNode !== null) {
          path.unshift(parentNode);
          parentId = parentNode.parentId;
        } else {
          break;
        }
      }
      const pathWithLabels = path.map((nodePath) => nodePath.label);
      if (isExpandedNodeTree === false) {
        setCurrentPath(pathWithLabels);
      } else {
        setCurrentPath(pathWithLabels.slice(0, -1));
      }
    }
  };

  /**
   *Renders a single node of a tree structure.
   *render node from tree is also in charge of establishing when a node is at the last serial 
   *level or would go up, doing the parentId validation renders an assign button
   *@param {object} node - The node object representing a single node in the tree structure.
   *@param {function} handleClickSelectedLevelAssing - This function is in charge that when you click on the assign button,
   * it assigns the value of the id of the selected node
   *@param {array} structureTrd - The array representing the entire tree structure.
   *@param {array} expandedNodeTree - The array of expanded node IDs in the tree.
   *@param {number} selectedNodeTreeId - The ID of the currently selected node in the tree.
   *@param {function} toggleExpandedTree - The function to toggle the expanded state of a node in the tree.
   *@param {function} handleNodeClick - The function to handle a click event on a node.
   *@returns {JSX.Element} - The rendered JSX element representing the node.
   */
  const renderNodeFromTree = (node) => {
    const hasChildren = structureTrd.some((item) => item.parentId === null);
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    let nodeStyle = "nodeStyle";
    let customExpandedStyle = "expandedStyle";
    let customLabelBackgroundStyle = "text-primary alingText";
    let iconFolder = null;
    if (selectedNodeTreeId === node.id) {
      nodeStyle += " selected";
    }
    if (selectedNodeTreeId === node.id) {
      const isUltimateLevel = structureTrd.filter((node) => node.parentId === selectedNodeTreeId)
      const selectNode = structureTrd.filter((node) => node.id === selectedNodeTreeId)
      if (isUltimateLevel.length === 0 && selectNode[0].level >= minSerieOrSubSerieLevel) {
        nodeStyle += " isUltimateLevel";
        showButtonAssing = (
          <div className="ml-auto">
            <Button
              key={node.id}
              className="ml-2"
              color="cyan"
              onClick={(eventAssing) => handleClickSelectedLevelAssing(eventAssing, node)}
            >{i18n.t("trd.formsCreate.assig")}</Button>
          </div>
        )
      } else {
        showButtonAssing = null;
      }
    } else {
      showButtonAssing = null;
    }

    if (isExpandedNodeTree === true) {
      customExpandedStyle += " isExpanded";
    }
    if (node.parentId === null) {
      customLabelBackgroundStyle += " font-weight-bold";
    }

    if (isExpandedNodeTree === true) {
      iconFolder = <AiFillFolderOpen className="folderIcon" />;
    } else {
      iconFolder = <AiOutlineFolder className="folderIcon" />;
    }

    return (
      <div key={node.id}>
        <div
          className={nodeStyle}
          onClick={() => {
            toggleExpandedTree(node.id);
            handleNodeClick(node);
          }}
        >
          <AiFillCaretRight className={customExpandedStyle} /> &nbsp;
          {iconFolder}
          <span className={customLabelBackgroundStyle}>{node.label}</span>
          {showButtonAssing}
        </div>
        {hasChildren === true && isExpandedNodeTree === true && (
          <div className="hasChildren">
            {structureTrd
              .filter((nodeTree) => nodeTree.parentId === node.id)
              .map((child) => renderNodeFromTree(child))}
          </div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardHeader>
            <h5 className="text-cyan text-bold p-3">
              {i18n.t("trd.formsCreate.assig1")}
            </h5>
            <div className="btn ml-auto">
              <Row>
                <Button
                  onClick={handleCloseTrdConfiguration}
                  className="btn-icon"
                  color="cyan">
                  <FontAwesomeIcon
                    icon={faArrowAltCircleLeft}
                    className="mr-2"
                  />
                  {i18n.t("trd.formsCreate.assig4")}
                </Button>
              </Row>
            </div>
          </CardHeader>
          <Alert className="mbg-3" color="info">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            {i18n.t("trd.formsCreate.assig2")}
          </Alert>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-column w-100">
                <div className="m-8 p-3">
                  {roots.map((node) => renderNodeFromTree(node))}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

FormsDesignerTrdConfiguration.propTypes = {
  setIsTrdConfiguration: PropTypes.func.isRequired,
  currentPath: PropTypes.array.isRequired,
  setCurrentPath: PropTypes.func.isRequired,
  formCreate: PropTypes.object.isRequired,
  setFormCreate: PropTypes.func.isRequired,
  setShowCurrentPath: PropTypes.func.isRequired,
  structureTrd: PropTypes.array.isRequired,
};

export default FormsDesignerTrdConfiguration