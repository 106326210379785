import React, { Fragment, useState, useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import { regexRecoveryPassword } from "utils/regexExpressions";
import { enumsRecoveryPassword } from "utils/enums";
import useUser from 'hooks/useUser'
import useLogin from "hooks/useLogin";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ForgotPassword = () => {
  const { isLogged } = useLogin();
  const { emailPasswordReset } = useUser()
  const [userEmail, setUserEmail] = useState({
    user_name: ""
  });
  const [loading, setLoading] = useState(false)

  /**
   * Handles the change event for updating the user's email.
   * @param {Event} eventChangeEmail - The event object containing the email change.
   * @returns {void}
   */
  const handleOnChageEmail = (eventChangeEmail) => {
    setUserEmail({
      [eventChangeEmail.target.name]: eventChangeEmail.target.value
    })
  }

  /**
   * Handles the submission of the recovery password form.
   * This function prevents the default form submission, checks for errors,
   * and if none are found, initiates the password reset process. Displays
   * success or error alerts based on the response.
   * @param {Object} eventRecoveryPassoword - The event object associated with the form submission.
   * @param {Array} errors - An array of error messages or validation errors.
   */
  const handleOnSubmit = (eventRecoveryPassoword, errors) => {
    eventRecoveryPassoword.preventDefault()
    if (errors.length === 0) {
      setLoading(true)
      emailPasswordReset(userEmail)
        .then((response) => {
          if (response.status === 202) {
            swal({
              title: i18n.t('modal.DoneError.header'),
              text: i18n.t("forgotPassword.alert.success"),
              icon: "success",
              button: i18n.t('modal.Done.footerButton')
            })
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  };

  /**
   * Redirects the user to the dashboard if they are logged in.
   * This effect runs whenever the value of `isLogged` changes. If `isLogged` is true,
   * the user is redirected to the "/dashboard" page.
   * @param {boolean} isLogged - A flag indicating whether the user is logged in.
   */
  useEffect(() => {
    if (isLogged === true) {
      window.location.href = "/dashboard";
    }
  }, [isLogged]);

  return (
    <Fragment>
      <div className="h-100 bg-plum-plate bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <div className="app-logo-inverse mx-auto mb-3" />
            <div className="modal-dialog w-100">
              <div className="modal-content">
                <div className="modal-body">
                  <div>
                    <div className="app-logo mx-auto mb-3" />
                    <div className="h5 modal-title text-center">
                      <h4 className="mt-2">
                        <div>{i18n.t("forgotPassword.title")}</div>
                      </h4>
                      <span className="text-small lead text-login">{i18n.t("forgotPassword.message")}</span>
                    </div>
                  </div>
                  <div>
                    <AvForm onSubmit={handleOnSubmit} >
                      <Row form>
                        <Col md={12}>
                          <AvGroup>
                            <AvField
                              id="user_name"
                              name="user_name"
                              type="text"
                              className="mt-3"
                              onChange={handleOnChageEmail}
                              validate={{
                                pattern: {
                                  value: regexRecoveryPassword,
                                  errorMessage: `${i18n.t("forgotPassword.label1")}`,
                                },
                                required: {
                                  value: true,
                                  errorMessage: `${i18n.t("form.field46")}`,
                                },
                                maxLength: {
                                  value: enumsRecoveryPassword.MAX_LENGTH,
                                },
                              }}
                              placeholder={i18n.t("forgotPassword.placeholder")}
                              required
                              value={userEmail.user_name}
                            />
                            <div className="modal-footer d-flex justify-content-between">
                              <div>
                                <div>
                                  <Link to={"/"}>
                                    {i18n.t("forgotPassword.ref")}
                                  </Link>
                                </div>
                              </div>
                              <div className="float-right">
                                <Button
                                  disabled={loading}
                                  type="submit"
                                  color="primary"
                                  size="lg"
                                >
                                  {i18n.t("forgotPassword.button")}
                                </Button>
                              </div>
                            </div>
                          </AvGroup>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
