/**
 * Array containing accepted file types for file input elements.
 * @constant {Array<string>} acceptTypeFiles - Array of accepted file types.
 */
export const acceptTypeFiles = [
  "application/vnd.oasis.opendocument.graphics",
  "image/*",
  "audio/*",
  "video/mp3",
  "video/mp4",
  "video/mpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
  "application/x-zip-compressed",
  "application/vsd.ms-outlook",
  "application/pkcs7-mime",
  "text/plain",
  "text/csv",
  "message/rfc822",
  "application/vnd.ms-outlook",
];

/**
 * Checks if a file with the given MIME type is accepted for logo upload.
 * @param {string} fileType - The MIME type of the file to be checked.
 * @returns {boolean} - Returns true if the file type is accepted for logo upload, otherwise false.
 */
export const acceptedFilesLogo = ["image/jpeg", "image/png"];

/**
 * Checks if a given filename matches the accepted file types for logos.
 * @param {string} filename - The name of the file to be checked.
 * @returns {boolean} Returns true if the filename matches the accepted file types, otherwise false.
 */
export const acceptFileLogoRegex = new RegExp(
  `^(${acceptedFilesLogo.join("|").replace(/\*/g, ".+")})$`
);

/**
 * Regular expression pattern for matching file names based on accepted file types.
 * @constant {RegExp} acceptFilesRegex
 * @param {string[]} acceptTypeFiles - Array containing accepted file types.
 * @returns {RegExp} Regular expression pattern generated based on the accepted file types.
 */
export const acceptFilesRegex = new RegExp(
  `^(${acceptTypeFiles.join("|").replace(/\*/g, ".+")})$`
);
