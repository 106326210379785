import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import useUser from "hooks/useUser";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Loader } from "react-loaders";
import {
	Button,
	Card,
	CardBody,
	CardTitle,
	Label,
	CardFooter,
	Col,
	Alert
} from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regexCreateUserWorkgroup } from "utils/regexExpressions";
import { Link, useHistory } from "react-router-dom";
import { workGroupEditionErrors } from "utils/workGroupsEditionAlerts"
import { useParams } from "react-router-dom";
import { enumsCreateUserWorkgroup } from "utils/enums";
import ReactTooltip from "react-tooltip";
import i18n from "locales/i18n";
import Swal from "sweetalert";
import LoadingComponent from "components/atoms/LoadingComponent";

const CreateUserWorkGroup = () => {
	const { id } = useParams()
	const history = useHistory()
	const workgroupId = id;
	const [isLoadingCreateWorkgroup, setIsLoadingCreateWorkgroup] = useState(false);
	const [responsibleWorkflows, setResponsibleWorkflows] = useState([]);
	const [showAlertWorkflows, setShowAlertWorkflows] = useState(false);
	const [workGroupStatus, setWorkGroupStatus] = useState({
		status: ""
	});
	const [groupName, setNameGroup] = useState({
		name: ""
	});

	const {
		create_work_group,
		updateWorkGroup,
		getWorkGroupById
	} = useUser()

	let labelButtonSubmitWorkgroup = null;
	let titleUserWorkgroup = null;
	let renderAlertsWorkflows = null;
	let renderEditWorkgroup = null;

	/**
	 * Sets the title and label for the workgroup form based on the presence of a workgroup ID. 
	 * @param {string|null|undefined} workgroupId - The ID of the workgroup, which could be null or undefined.
	 * @param {Object} i18n - An internationalization object for translations.
	 * @returns {void}
	 */
	if (isNullOrUndefined(workgroupId) === false) {
		titleUserWorkgroup = (<span>{i18n.t("create.work.group.update.label")}</span>);
		labelButtonSubmitWorkgroup = (<span>{i18n.t("createWorflow.EditButton")}</span>);
	} else {
		titleUserWorkgroup = (<span>{i18n.t("create.work.group.label")}</span>);
		labelButtonSubmitWorkgroup = (<span>{i18n.t("createusers.createButton")}</span>);
	}

	/**
	 * Sets the button disabled state based on isLoadingCreateWorkgroup flag.
	 * @param {boolean} isLoadingCreateWorkgroup - Flag indicating whether loading is disabled.
	 * @returns {boolean} - True if button should be disabled, false otherwise.
	 */
	let buttonDisabled = null
	if (isLoadingCreateWorkgroup === true) {
		buttonDisabled = true;
	} else {
		buttonDisabled = false;
	}

	/**
	 * Retrieves a work group by ID and updates the corresponding state variables.
	 * @function
	 * @returns {void}
	 */
	const getWorkGroupByIdSearch = useCallback(() => {
		setIsLoadingCreateWorkgroup(true);
		getWorkGroupById(workgroupId)
			.then((response) => {
				if (isNullOrUndefined(response.data.data) === false) {
					setNameGroup({
						name: response.data.data.name
					})
					setWorkGroupStatus({ status: response.data.data.status })
				} else {
					showAlertServiceError();
				}
			})
			.finally(() => {
				setIsLoadingCreateWorkgroup(false);
				setShowAlertWorkflows(false);
			})
	},[workgroupId, getWorkGroupById])

	/**
	 * Updates the status of the workgroup based on the selected value from the event.
	 * @param {Object} eventChageWorkgroupStatus - The event object from the status change.
	 * @param {Object} eventChageWorkgroupStatus.target - The target element of the event.
	 * @param {string} eventChageWorkgroupStatus.target.value - The new status value selected.
	 * @returns {void}
	 */
	const changeWorkgroupStatus = (eventChageWorkgroupStatus) => {
		setWorkGroupStatus({
			...workGroupStatus,
			status: Number(eventChageWorkgroupStatus.target.value),
		})
	}

	/**
	 * Handles the onChange event for creating a user work group.
	 * @param {Event} eventCreateUserWorkGroup - The event object for the onChange event.
	 * @returns {void}
	 */
	const handleOnChangeCreateUserWorkGroup = (eventCreateUserWorkGroup) => {
		setNameGroup({
			...groupName,
			[eventCreateUserWorkGroup.target.name]: eventCreateUserWorkGroup.target.value,
		});
	};

	/**
	 * Handles the key press event for creating a user work group.
	 * @param {Event} eventCreateUserWorkGroup - The event object for the key press event.
	 * @returns {void}
	 */
	const handleOnKeyPress = (eventCreateUserWorkGroup) => {
		if (eventCreateUserWorkGroup.key === "Enter") {
			eventCreateUserWorkGroup.preventDefault();
		}
	};

	/**
	 * Handles the onBlur event for creating a user work group.
	 * @param {Event} eventCreateUserWorkGroup - The event object for the onBlur event.
	 * @returns {void}
	 */
	const handleOnBlur = (eventCreateUserWorkGroup) => {
		setNameGroup({
			...groupName,
			[eventCreateUserWorkGroup.target.name]: eventCreateUserWorkGroup.target.value.trim(),
		});
	};

	/**
	 * Handles the onSubmit event for creating a user work group.
	 * @param {Event} eventCreateUserWorkGroup - The event object for the onSubmit event.
	 * @param {Array} errors - The array of errors.
	 * @returns {void}
	 */
	const handleOnSubmit = (eventCreateUserWorkGroup, errors) => {
		eventCreateUserWorkGroup.preventDefault();
		if (errors.length === 0) {
			if (workgroupId && workgroupId !== null && workgroupId !== undefined) {
				setIsLoadingCreateWorkgroup(true)
				const groupProperties = {
					name: groupName.name,
					status: workGroupStatus.status
				}
				updateWorkGroup(id, groupProperties)
					.then((response) => {
						if (response.status === 202) {
							Swal({
								title: i18n.t("modal.DoneError.header"),
								text: i18n.t("create.work.group.update.alert"),
								icon: "success",
								button: i18n.t("modal.Done.footerButton"),
							}).then(() => {
								history.push("/user/workgroup");
							})
						} else {
							showAlertServiceError();
						}
					})
					.catch((error) => {
						const status = error.response.data.code
						if (status === "10014") {
							Swal(workGroupEditionErrors(status))
							return null
						}
						if (status === "10058") {
							Swal(workGroupEditionErrors(status))
						}
						if (status === "50016") {
							setShowAlertWorkflows(true)
							setResponsibleWorkflows(error.response.data.data)
							Swal(workGroupEditionErrors(status))
						} else {
							Swal(workGroupEditionErrors(status))
								.then(() => {
									history.push("/user/workgroup");
								})
						}
					})
					.finally(() => setIsLoadingCreateWorkgroup(false))
			} else {
				setIsLoadingCreateWorkgroup(true)
				create_work_group(groupName)
					.then((response) => {
						if (response.status === 201) {
							Swal({
								title: i18n.t("modal.DoneError.header"),
								text: i18n.t("create.work.group.success"),
								icon: "success",
								button: i18n.t("modal.Done.footerButton"),
							}).then(() => {
								history.push("/user/workgroup");
							})
						} else {
							showAlertServiceError();
						}
					})
					.catch(() => {
						Swal({
							title: i18n.t("modal.DoneError.header"),
							text: i18n.t("create.work.group.exist.error"),
							icon: "error",
							dangerMode: true,
							button: i18n.t("modal.Done.footerButton"),
						});
					})
					.finally(() => {
						setIsLoadingCreateWorkgroup(false)
					})
			}
		}
	}

	/**
	 * Executes the effect to retrieve a work group by ID and search criteria.
	 * @param {String} id- recive if has id 
	 * @returns {void}
	 */
	useEffect(() => {
		if (workgroupId && workgroupId !== null && workgroupId !== undefined) {
			getWorkGroupByIdSearch()
		}
		// eslint-disable-next-line 
	}, [workgroupId])


	/**
	 * Conditionally renders a `LoadingComponent` when the `isLoadingCreateWorkgroup` variable is true.
	 * @param {boolean} isLoadingCreateWorkgroup - A boolean value indicating whether a request is currently loading.
	 * @returns {JSX.Element|null} The `LoadingComponent` if `isLoadingCreateWorkgroup` is true, otherwise `null`.
	 */
	if (isLoadingCreateWorkgroup === true) {
		return (
			<LoadingComponent />
		)
	}

	/**
	 * Renders a loader component if the workgroup ID is not null or undefined and the workgroup is loading. 
	 * @param {string|null|undefined} workgroupId - The ID of the workgroup, which could be null or undefined.
	 * @param {boolean} isLoadingCreateWorkgroup - Indicates whether the workgroup creation is currently loading.
	 * @param {Object} colors - An object containing color values.
	 * @returns {JSX.Element|null} Returns a JSX element with a loader if conditions are met, otherwise null.
	 */
	if (isNullOrUndefined(workgroupId) === false && isLoadingCreateWorkgroup === true) {
		return (
			<div className="p-absolute d-flex justify-content-center align-items-center w-100 h-50">
				<Loader color="secondary" type="ball-pulse-rise" size="small" />
			</div>
		)
	};

	/**
	 * Renders an alert for workflows if the condition is met. 
	 * @param {boolean} showAlertWorkflows - Indicates whether the alert for workflows should be shown.
	 * @param {Array} responsibleWorkflows - An array of workflow objects with `step_name` and `workflow_name` properties.
	 * @param {Object} i18n - An internationalization object for translations.
	 * @returns {JSX.Element|null} Returns a JSX element with an alert if `showAlertWorkflows` is true, otherwise null.
	 */
	if (showAlertWorkflows === true) {
		renderAlertsWorkflows = (
			<Alert className="mbg-3" color="info">
				<span className="pr-2 text-bold">
					<FontAwesomeIcon
						icon={faExclamationTriangle}
						className="mr-2 "
					/>
					{i18n.t("modal.DoneError.header")}
					<div className="divider " />
					<span className="font-weight-bold font-italic">{i18n.t("create.work.group.members.alert4")}</span>
					<div className="mt-2">
						<ul>
							{responsibleWorkflows.length > 0 && responsibleWorkflows.map(({ step_name, workflow_name }, index) => {
								return (
									<li key={index}>
										{i18n.t("create.work.group.members.label3")} {workflow_name}, {i18n.t("create.work.group.members.label4")} {step_name}
									</li>
								)
							})}
						</ul>
					</div>
				</span>
			</Alert>
		)
	};

	/**
	 * Renders the edit workgroup form if the workgroup ID is not null or undefined and the workgroup creation is not loading. 
	 * @param {string|null|undefined} workgroupId - The ID of the workgroup, which could be null or undefined.
	 * @param {boolean} isLoadingCreateWorkgroup - Indicates whether the workgroup creation is currently loading.
	 * @param {Object} i18n - An internationalization object for translations.
	 * @param {function} changeWorkgroupStatus - A function to handle the change of workgroup status.
	 * @param {Object} workGroupStatus - An object containing the current status of the workgroup.
	 * @param {number} workGroupStatus.status - The current status value of the workgroup.
	 * @returns {JSX.Element|null} Returns a JSX element with the edit workgroup form if conditions are met, otherwise null.
	 */
	if (isNullOrUndefined(workgroupId) === false && isLoadingCreateWorkgroup === false) {
		renderEditWorkgroup = (
			<AvGroup row>
				<Label for="document_type" className="is-required" sm={2}>
					{i18n.t("createWorflow.state")}
				</Label>
				<Col md={10}>
					<AvField
						id="document_type"
						name="document_type"
						type="select"
						onChange={changeWorkgroupStatus}
						required
						errorMessage={i18n.t("createusers.Feedback6")}
						value={workGroupStatus.status}
					>
						<option value={enumsCreateUserWorkgroup.STATUS_ERASER}>
							{i18n.t("createWorflow.selectedOne")}
						</option>
						<option value={enumsCreateUserWorkgroup.STATUS_ACTIVE}>
							{i18n.t("createWorflow.selectedTwo")}
						</option>
						<option value={enumsCreateUserWorkgroup.STATUS_INACTIVE}>
							{i18n.t("createWorflow.selectedThree")}
						</option>
					</AvField>
				</Col>
			</AvGroup>
		)
	};

	return (
		<Fragment>
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<div>
					<Card className="main-card mb-3">
						<CardBody>
							<CardTitle>
								{titleUserWorkgroup}
							</CardTitle>
							<AvForm onSubmit={handleOnSubmit} autoComplete="off">
								<ReactTooltip
									id="name"
									place="bottom"
									type="dark"
									effect="solid"
								>
									{i18n.t("trd.Tooltip4")}
								</ReactTooltip>
								<AvGroup row>
									<Label for="name" className="is-required" sm={2}>
										{i18n.t("create.work.group.name")}
									</Label>
									<Col md={10}>
										<AvField
											id="name"
											name="name"
											type="text"
											onChange={handleOnChangeCreateUserWorkGroup}
											onKeyPress={handleOnKeyPress}
											onBlur={handleOnBlur}
											data-tip
											data-for="name"
											validate={{
												pattern: {
													value: regexCreateUserWorkgroup,
													errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
												},
												required: {
													value: true,
													errorMessage: `${i18n.t(
														"create.work.group.alert"
													)}`,
												},
												minLength: {
													value: enumsCreateUserWorkgroup.MIN_LENGTH,
													errorMessage: `${i18n.t(
														"fieldValidateLengthBetween"
													)} 4 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
												},
												maxLength: {
													value: enumsCreateUserWorkgroup.MAX_LENGTH,
													errorMessage: `${i18n.t(
														"fieldValidateLengthBetween"
													)} 4 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
												},
											}}
											autoComplete="off"
											value={groupName.name}
										/>
									</Col>
								</AvGroup>
								{renderEditWorkgroup}
								{renderAlertsWorkflows}
								<CardFooter className="d-block text-right">
									<Link to="/user/workgroup">
										<Button size="lg" className="col-mt-3 mr-3" color="gray">
											{i18n.t("createusers.createButton2")}
										</Button>
									</Link>
									<Button
										type="submit"
										size="lg"
										disabled={buttonDisabled}
										className="col-mt-3"
										color="cyan"
									>
										{labelButtonSubmitWorkgroup}
									</Button>
								</CardFooter>
							</AvForm>
						</CardBody>
					</Card>
				</div>
			</CSSTransitionGroup>
		</Fragment>
	);
};

export default CreateUserWorkGroup;
