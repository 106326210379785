import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDateFormat2 } from "utils/getDateFormat2";
import { renderStatusExportRecords } from "utils/renderStatusExportRecord";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const ExportDataRecordTable = (props) => {
  const {
    listExportedDataRecords,
    isLoadingExportRecordList,
    paginationExportRecordList,
    setPaginationExportRecordList,
    totalPagesRecordExport
  } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={listExportedDataRecords}
          columns={[
            {
              columns: [
                {
                  Header: i18n.t("form.field9"),
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: i18n.t("createWorflow.description"),
                  accessor: "form_name",
                },
                {
                  Header: i18n.t("filteruser.button"),
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = renderStatusExportRecords(value);
                    return (
                      <div className="text-center">
                        <span>{statusFinal}</span>
                      </div>
                    )
                  },
                },
                {
                  Header: i18n.t("export.records.label3"),
                  accessor: "subject",
                  Cell: () => {
                    return (
                      <div className="text-center">
                        <Button
                          style={{ cursor: "not-allowed" }}
                          data-test-id="btn-buttonProcessBatch"
                          className="btn-block ml-auto btn-icon btn-mb-responsive"
                          color="transparent"
                          data-tip
                          data-for="btn-buttonProcessBatch"
                        >
                          <FontAwesomeIcon icon={faCloudDownloadAlt} color="gray" size="lg"
                            className="mr-2" />
                        </Button>
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPagesRecordExport}
          page={paginationExportRecordList.page - 1}
          showPageJump={false}
          defaultPageSize={paginationExportRecordList.per_page}
          loading={isLoadingExportRecordList}
          loadingText={i18n.t("loading")}
          noDataText={`${i18n.t("export.records.alert.warining2")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPaginationExportRecordList({ ...paginationExportRecordList, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPaginationExportRecordList({ ...paginationExportRecordList, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  )
};

ExportDataRecordTable.propTypes = {
  listExportedDataRecords: PropTypes.array.isRequired, 
  isLoadingExportRecordList: PropTypes.bool.isRequired,
  paginationExportRecordList: PropTypes.shape({
    page: PropTypes.number.isRequired,
    per_page: PropTypes.number.isRequired,
  }).isRequired,
  setPaginationExportRecordList: PropTypes.func.isRequired,
  totalPagesRecordExport: PropTypes.number.isRequired,
};

export default ExportDataRecordTable