import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { getDateFormat2 } from "utils/getDateFormat2";
import { capitalizeText } from "utils/formatText";
import useRecord from "hooks/useRecord";
import cx from "classnames";
import i18n from "locales/i18n";

const Sticker = (props) => {
  const {
    selectedPosition,
    isLoadingStickerInfo,
    setIsLoadingStickerInfo,
    isAutoHeight,
    isLogoLoaded,
    setIsLogoLoaded,
    isPrinting,
    setIsLoadingSticker,
  } = props;
  const { id, recordId } = useParams();
  const [stickerFieldsConfigured, setStickerFieldsConfigured] = useState([]);
  const [stickerInformation, setStickerInformation] = useState({});
  const [hasFields, setHasFields] = useState(false);
  const { getStickerInfo } = useRecord();
  let dinamicStickerFields = null;
  let logoCompany = null;

  /**
   * Fetches sticker information based on the current record and updates the state accordingly.
   * This function sets the loading state, fetches the sticker information using the provided `id` and `recordId`,
   * and then updates the state based on the response. If the response contains valid data, it updates the sticker
   * information and fields configuration. If not, it shows an alert for a service error.
   * @returns {void} No return value.
   * @param {Function} setIsLoadingStickerInfo - Function to set the loading state for sticker information.
   * @param {Function} getStickerInfo - Function to fetch sticker information, which takes `id` and `recordId` as parameters.
   * @param {string|number} id - The identifier for the sticker.
   * @param {string|number} recordId - The identifier for the record associated with the sticker.
   * @param {Function} setStickerInformation - Function to update the state with the fetched sticker information.
   * @param {Function} setHasFields - Function to update the state indicating if there are configured fields.
   * @param {Function} setStickerFieldsConfigured - Function to update the state with the configured sticker fields.
   * @param {Function} showAlertServiceError - Function to show an alert in case of a service error.
   * @param {Function} isNullOrUndefined - Function to check if a value is null or undefined.
   */
  const getStickerInfoByRecord = useCallback(() => {
    setIsLoadingStickerInfo(true);
    getStickerInfo(id, recordId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setStickerInformation(response.data);
          if (
            Array.isArray(response.data.sticker_fields) &&
            response.data.sticker_fields.length > 0
          ) {
            setHasFields(true);
            setStickerFieldsConfigured(response.data.sticker_fields);
          } else {
            setHasFields(false);
            setStickerFieldsConfigured([]);
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingStickerInfo(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders the company's logo image if a pre-signed URL is available.
   * @param {Object} stickerInformation - Information about the sticker.
   * @param {string|null} stickerInformation.presigned_url - The pre-signed URL for the logo image. If null, the logo will not be rendered.
   * @param {Function} setIsLogoLoaded - Callback function to set the logo load state.
   * @returns {JSX.Element|null} - A JSX element containing the company's logo or null if the URL is not available or the image fails to load.
   */
  if (stickerInformation.presigned_url !== null) {
    logoCompany = (
      <Col xs={3} sm={3} md={3}>
        <img
          alt="logo"
          className="sticker-image"
          src={stickerInformation.presigned_url}
          onLoad={() => setIsLogoLoaded(true)}
          onError={(() => setIsLogoLoaded(true), (logoCompany = null))}
        />
      </Col>
    );
  } else {
    logoCompany = null;
  }

  /**
   * Generates dynamic sticker fields based on configured sticker fields.
   * If there are configured sticker fields, this function maps over them and
   * creates an array of <p> elements displaying the field name and value.
   * @param {Array} stickerFieldsConfigured - An array of objects representing the configured sticker fields.
   * @param {Object} stickerFieldsConfigured[].field - An object representing a single sticker field.
   * @param {string} stickerFieldsConfigured[].field.name - The name of the sticker field.
   * @param {string|number} stickerFieldsConfigured[].field.value - The value of the sticker field.
   * @returns {Array<JSX.Element>} An array of <p> elements displaying the sticker fields.
   */
  if (stickerFieldsConfigured.length > 0) {
    dinamicStickerFields = stickerFieldsConfigured.map((field) => {
      return (
        <p key={field.value}>
          <strong>{capitalizeText(field.name)}: </strong> {field.value}
        </p>
      );
    });
  }

  /**
   * useEffect hook to manage sticker loading state based on various conditions.
   * Sets isLoadingSticker to true under specific conditions and false otherwise.
   * @param {boolean} isLoadingStickerInfo - Indicates if sticker information is currently loading.
   * @param {boolean} isPrinting - Indicates if the sticker is currently being printed.
   * @param {boolean} logoCompany - The logo of the company (can be null).
   * @param {boolean} isLogoLoaded - Indicates if the logo is currently loaded.
   */
  useEffect(() => {
    if (
      isLoadingStickerInfo === true ||
      isPrinting === true ||
      (logoCompany === null && isLogoLoaded === true) ||
      (logoCompany !== null && isLogoLoaded === false)
    ) {
      setIsLoadingSticker(true);
    } else {
      setIsLoadingSticker(false);
    }
  }, [isLoadingStickerInfo, isPrinting, logoCompany, isLogoLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook to fetch sticker information when the component mounts
   * or when getStickerInfoByRecord function changes.
   * @param {Function} getStickerInfoByRecord - Function to fetch sticker information by record.
   */
  useEffect(() => {
    getStickerInfoByRecord();
  }, [getStickerInfoByRecord]);

  return (
    <div
      className={cx("sticker ml-1 mr-1", {
        "auto-height ml-1 mr-1": isAutoHeight,
      })}
      data-position={selectedPosition}
    >
      <Row className="sticker-header">
        {logoCompany}
        <Col className="sticker-row-title" xs={9} sm={9} md={9}>
          <h3 className="h3">
            <strong>{stickerInformation.company_name}</strong>
          </h3>
          <h3 className="h3">
            <strong>{stickerInformation.form_name}</strong>
          </h3>
        </Col>
      </Row>
      <Row
        className={cx("sticker-content", {
          "sticker-content mt-2": hasFields === false,
        })}
      >
        <Col md={12}>
          <p>
            <strong>{i18n.t("recordList10")}: </strong>{" "}
            {getDateFormat2(new Date(stickerInformation.created_at))}
          </p>
          <p>
            <strong>{i18n.t("recordNumber")}:</strong>
            {stickerInformation.record_number}
          </p>
          <p>
            <strong>{i18n.t("recordFiler")}:</strong>{" "}
            {stickerInformation.user_name}
          </p>
          {dinamicStickerFields}
        </Col>
      </Row>
    </div>
  );
};

Sticker.propTypes = {
  selectedPosition: PropTypes.string.isRequired,
  isLoadingStickerInfo: PropTypes.bool.isRequired,
  setIsLoadingStickerInfo: PropTypes.func.isRequired,
  isAutoHeight: PropTypes.bool.isRequired,
  isLogoLoaded: PropTypes.bool.isRequired,
  setIsLogoLoaded: PropTypes.func.isRequired,
  isPrinting: PropTypes.bool.isRequired,
  setIsLoadingSticker: PropTypes.func.isRequired,
};

export default Sticker;
