import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import ExportDataRecordList from "components/organism/ExportDataRecordList";

const ExportData = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("export.records.header")}
				subheading={i18n.t("export.records.subHeader")}
				icon="next-2"
			>
        <ExportDataRecordList/>
			</AdminTemplate>
		</Fragment>
	)
}

export default ExportData
