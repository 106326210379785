import React, { useEffect, useState } from "react";
import { useListContext } from "contextAPI/ListsContext";
import { Button, Spinner } from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { loaderElement } from "utils/loaderElement";
import FulfillProcess from "components/molecules/FulfillProcess";
import i18n from "locales/i18n";

const MultiStepFullFill = (props) => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(true);
  const [buttons, setButtons] = useState({
    showPreviousBtn: false,
    showNextBtn: true,
  });
  const {
    canNext,
    compState,
    setCompState,
    recordNext,
    advanced,
    setAdvanced,
    setRecordNext,
  } = useListContext();
  const {
    selectedFields,
    configProcessBatch,
    setComplyTaskRequest,
    isNextStep,
  } = useWorkflowContext();
  const { setShowTrdLevelsOrdenation } = useRecordDetailContext();
  let componentButtonNext = null;
  let componentButtonPrevious = null;
  let isDisabledNextStepComply = false;
  let spinnerButtonFulfill = null;

  /**
   * Conditionally sets spinner for button fulfilling based on loading state of responsibilities list.
   * @param {boolean} isLoadingResponsilblesList - Flag indicating whether the responsibilities list is loading.
   * @param {JSX.Element|null} - Spinner component or null.
   */
  if (isLoadingRequest === true) {
    spinnerButtonFulfill = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Checks if the component state is at a specific step and if a request is loading.
   * If both conditions are met, sets a flag to disable the next step.
   * @param {number} compState - The current state of the component.
   * @param {Array} props.steps - An array containing steps.
   * @param {boolean} isLoadingRequest - Flag indicating whether a request is in progress.
   * @returns {boolean} - Returns true if the next step should be disabled, otherwise false.
   */
  if (compState === props.steps.length - 2 && isLoadingRequest === true) {
    isDisabledNextStepComply = true;
  }

  /**
   * Generates an array of navigation states to represent the progress of a multi-step process.
   * @param {number} currentIndex - The current step index in the process.
   * @param {number} length - The total number of steps in the process.
   * @returns {object} An object containing the current step index and an array of styles
   * representing the states of each step in the process.
   */
  const getNavStates = (currentIndex, length) => {
    const styles = Array(length).fill("todo");
    styles[currentIndex] = "doing";
    if (currentIndex > 0) {
      styles.fill("done", 0, currentIndex);
    }
    return { current: currentIndex, styles };
  };

  const [state, setState] = useState({
    navState: getNavStates(0, props.steps.length),
  });

  /**
   * Determines the visibility state of navigation buttons based on the current step and total number of steps.
   * @param {number} currentStep - The index of the current step in the navigation.
   * @param {object} props - The props object containing information about the steps, including their length.
   * @param {Array} props.steps - An array containing the steps.
   * @returns {object} An object containing the visibility state of navigation buttons.
   * @returns {boolean} return.showPreviousBtn - Indicates whether the previous button should be shown.
   * @returns {boolean} return.showNextBtn - Indicates whether the next button should be shown.
   */
  const checkNavState = (currentStep) => {
    const stepsLength = props.steps.length;

    switch (currentStep) {
      case 0:
        return {
          showPreviousBtn: false,
          showNextBtn: true,
        };
      case stepsLength - 1:
        return {
          showPreviousBtn: false,
          showNextBtn: false,
        };

      case 1:
        if (stepsLength === 3) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (
          stepsLength === 4 &&
          props.steps[1].name === i18n.t("taskBatchProcess.title.uploadFile")
        ) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (
          stepsLength === 4 &&
          props.steps[1].name !== i18n.t("taskBatchProcess.title.uploadFile")
        ) {
          return {
            showPreviousBtn: true,
            showNextBtn: false,
          };
        } else if (stepsLength === 5 || stepsLength === 6) {
          return {
            showPreviousBtn: true,
            showNextBtn: false,
          };
        }
        break;

      case 2:
        if (stepsLength === 2) {
          return {
            showPreviousBtn: false,
            showNextBtn: false,
          };
        } else if (stepsLength === 3) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (stepsLength === 4) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (
          stepsLength === 5 &&
          props.steps[2].name !== i18n.t("trd.formTittle14")
        ) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (
          (stepsLength === 5 &&
            props.steps[2].name === i18n.t("trd.formTittle14")) ||
          stepsLength === 6
        ) {
          return {
            showPreviousBtn: true,
            showNextBtn: false,
          };
        }
        break;

      case 3:
        if (stepsLength === 3) {
          return {
            showPreviousBtn: false,
            showNextBtn: false,
          };
        } else if (stepsLength === 5 || stepsLength === 6) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        }
        break;

      case 4:
        if (stepsLength === 3) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        } else if (stepsLength === 4) {
          return {
            showPreviousBtn: false,
            showNextBtn: false,
          };
        } else if (stepsLength === 5 || stepsLength === 6) {
          return {
            showPreviousBtn: true,
            showNextBtn: true,
          };
        }
        break;

      default:
        return {
          showPreviousBtn: false,
          showNextBtn: false,
        };
    }
  };

  /**
   * Updates the navigation state based on the provided 'next' value.
   * This function is responsible for managing the navigation state in a multi-step process.
   * It updates the current state, sets the component state to the 'next' step if applicable,
   * checks navigation buttons, and triggers a submission action if 'next' is a specific step.
   * @param {number} next - The next step or state to transition to in the multi-step process.
   * @returns {void}
   */
  const setNavState = (next) => {
    setState({
      ...state,
      navState: getNavStates(next, props.steps.length),
    });
    if (next < props.steps.length) {
      setCompState(next);
    }
    setButtons(checkNavState(next, props.steps.length));
  };

  /**
   * Advances to the next step in a multi-step process.
   * @param {object} props - The props object containing information about the steps in the process.
   * @param {Array} props.steps - An array containing the steps of the process.
   * @param {number} compState - The current state representing the active step in the process.
   * @param {function} setComplyTaskRequest - A state setter function to update whether the task complies with requirements.
   * @param {function} setNavState - A state setter function to update the navigation state indicating the active step.
   *
   * @returns {void}
   */
  const next = () => {
    const stepsLength = props.steps.length;
    const finalStep = stepsLength - 2;

    if (compState === finalStep) {
      setComplyTaskRequest(true);
    } else {
      setNavState(compState + 1);
    }
  };

  /**
   * Moves to the previous component state if available.
   * If the current component state is greater than 0, it updates various states accordingly.
   * @returns {void}
   */
  const previous = () => {
    if (compState > 0) {
      setAdvanced(0);
      setRecordNext(false);
      setNavState(compState - 1);
      setShowTrdLevelsOrdenation(false);
    }
  };

  /**
   * Generates a previous button component if the condition to show the previous button is met.
   * @param {object} buttons - Object containing information about the visibility of buttons.
   * @param {boolean} buttons.showPreviousBtn - Boolean indicating whether to show the previous button.
   * @param {function} previous - Callback function to handle click event on the previous button.
   * @param {object} i18n - Internationalization object providing translations.
   * @param {string} i18n.t - Function to translate a given key to the corresponding text.
   * @returns {JSX.Element|null} - Returns the previous button component if it should be shown, otherwise null.
   */
  if (buttons.showPreviousBtn === true) {
    componentButtonPrevious = (
      <Button
        data-test-id="btn-previous"
        color="gray"
        className="btn-shadow float-left btn-wide"
        onClick={previous}
      >
        {i18n.t("itemList.designerButton2")}
      </Button>
    );
  }

  /**
   * Generates a "Next" button component based on the provided configuration.
   * If the condition to show the next button is met, it returns a Button component.
   * @param {object} buttons - Configuration object for buttons.
   * @param {boolean} buttons.showNextBtn - Indicates whether the next button should be shown.
   * @param {function} next - Callback function to handle the click event for the next button.
   * @param {boolean} canNext - Indicates whether the next button should be enabled or disabled.
   * @param {string} i18n.t - Function for internationalization to translate the button label.
   * @returns {JSX.Element|null} The generated "Next" button component, or null if conditions are not met.
   */
  if (buttons.showNextBtn === true) {
    componentButtonNext = (
      <Button
        data-test-id="btn-next"
        color="cyan"
        className="btn-shadow btn-wide float-right"
        onClick={next}
        disabled={canNext === true || isDisabledNextStepComply === true}
      >
        {spinnerButtonFulfill}
        {i18n.t("itemList.designerButton1")}
      </Button>
    );
  }

  /**
   * Generates a new class name by combining a base class name with a style modifier.
   * @param {string} className - The base class name to which a style modifier will be appended.
   * @param {number} indexOfElement - The index indicating the style modifier to use.
   * @returns {string} The generated class name.
   */
  const getClassName = (className, indexOfElement) => {
    return className + "-" + state.navState.styles[indexOfElement];
  };

  /**
   * Renders steps for a form wizard based on the provided props.
   * @param {Object} props - The props object containing information about the steps.
   * @param {Object[]} props.steps - An array of objects representing the steps of the form wizard.
   * @param {string} props.steps[].name - The name or title of the step.
   * @param {Function} getClassName - A function used to determine the class name for the rendered step element.
   * @param {number} indexOfElement - The index of the current step element being rendered.
   */
  const renderSteps = () => {
    return props.steps.map((s, indexOfElement) => (
      <li
        className={getClassName("form-wizard-step", indexOfElement)}
        key={indexOfElement}
        value={indexOfElement}
      >
        <em>{indexOfElement + 1}</em>
        <span className={"aling-wizard"}>
          {props.steps[indexOfElement].name}
        </span>
      </li>
    ));
  };

  /**
   * Executes a side effect when the 'isNextStep' state variable changes to true,
   * incrementing the 'compState' state variable by 1 to navigate to the next step.
   * @param {boolean} isNextStep - A boolean indicating whether the next step should be triggered.
   * @param {number} compState - The current state of the component's navigation step.
   * @param {function} setNavState - State setter function for updating the navigation state.
   * @returns {void}
   */
  useEffect(() => {
    if (isNextStep === true) {
      setNavState(compState + 1);
    }
  }, [isNextStep]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the 'next' function when the 'recordNext' state is set to true.
   * This effect is triggered whenever 'recordNext' changes.
   * @param {boolean} recordNext - Boolean indicating whether to trigger the 'next' function.
   * When set to true, 'next' function will be executed.
   * Default value is false.
   * @param {function} next - The function to be executed when 'recordNext' is true.
   * It typically advances to the next step or action in the process.
   * @returns {void}
   */
  useEffect(() => {
    if (recordNext === true) {
      next();
    }
  }, [recordNext]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the navigation state based on the combined values of `compState` and `advanced`.
   * @param {string} compState - The current state of the component.
   * @param {string} advanced - The advanced state value to be added to `compState`.
   * @param {function} setNavState - The state setter function for updating the navigation state.
   * @returns {void}
   */
  useEffect(() => {
    setNavState(compState + advanced);
  }, [advanced]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loaderElement(isLoadingRequest)}
      <ol className="forms-wizard">{renderSteps()}</ol>
      {props.steps[compState].component}
      <div className="d-none">
        <FulfillProcess
          setIsLoadingRequest={setIsLoadingRequest}
          isLoadingRequest={isLoadingRequest}
          isProcessBatch={true}
          selectedFields={selectedFields}
          configProcessBatch={configProcessBatch}
          setComplyTaskRequest={setComplyTaskRequest}
          treeExist={props.treeExist}
        />
      </div>
      <div className="clearfix">
        <div>
          {componentButtonPrevious}
          {componentButtonNext}
        </div>
      </div>
    </div>
  );
};

export default MultiStepFullFill;

MultiStepFullFill.defaultProps = {
  showNavigation: true,
};
