import React, { Fragment, useState } from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter,
  Label,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showAlertServiceError } from "utils/alerts";
import { enumCancelFiled } from "utils/enums";
import { regexCancelFiled } from "utils/regexExpressions";
import useForms from "hooks/useForm";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonCancelFiled = (props) => {
  const { recordInfos } = props;
  const { annulmentRecord } = useForms();
  const { formDetail } = useRecordDetailContext();
  const [reason, setReason] = useState({ comment_reason: "" });
  const [showModal, setShowModal] = useState(false);
  const [isLoadingCancelFiled, setIsLoadingCancelFiled] = useState(false);

  let loadingSpinnerButtonCancel = null;

  /**
   * Returns a loading spinner component if loading is in progress.
   * @param {boolean} isLoadingCancelFiled - Indicates whether loading is in progress.
   * @returns {JSX.Element|null} A loading spinner component if loading is true, null otherwise.
   */
  if (isLoadingCancelFiled === true) {
    loadingSpinnerButtonCancel = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Updates the reason state based on the value from the comment input field.
   * @param {Object} eventCommentReason - The event object from the input change event.
   * @param {Function} setReason - Function to update the reason state.
   */
  const handleCommentReason = (eventCommentReason) => {
    setReason({ comment_reason: eventCommentReason.target.value });
  }

  /**
   * Handles the closing of a modal, resetting state values.
   * @param {Function} setShowModal - Function to set the visibility of the modal.
   * @param {Function} setIsError - Function to set the error state.
   * @param {Function} setReason - Function to set the reason state.
   */
  const handleOnClose = () => {
    setShowModal(false);
    setReason({ comment_reason: "" });
  };

  /**
   * Handles the form submission process, including validation, API request, and user feedback.
   * @param {Object} eventCancelRecord - The event object from the form submission.
   * @param {Array} errors - An array of validation errors.
   * @param {Function} setIsLoadingCancelFiled - Function to set the loading state.
   * @param {Function} setIsError - Function to set the error state.
   * @param {Function} setReason - Function to set the reason state.
   * @param {Function} annulmentRecord - Function to handle the record annulment API request.
   * @param {Object} formDetail - Object containing details of the form.
   * @param {Object} recordInfos - Object containing information about the record.
   * @param {Function} showAlertServiceError - Function to show an alert for service errors.
   * @param {Function} setShowModal - Function to set the visibility of the modal.
   * @param {Object} i18n - Object for internationalization, containing translation functions.
   * @param {Function} swal - Function to show SweetAlert notifications.
   */
  const handleSumbitCancelRecord = (eventCancelRecord, errors) => {
    eventCancelRecord.preventDefault();
    if (errors.length === 0) {
      setIsLoadingCancelFiled(true);
      annulmentRecord(formDetail.uuid, recordInfos.uuid, reason)
        .then((response) => {
          if (response.status === 201) {
            setReason({ comment_reason: "" });
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("RecordDetail.label9"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => window.location.reload());
            setShowModal(false);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => setIsLoadingCancelFiled(false));
    }
  };

  /**
   * Handles the click event to show a modal.
   * @param {Function} setShowModal - Function to set the visibility of the modal.
   */
  const handleOnClick = () => {
    setShowModal(true);
  };

  /**
   * Resets the form fields, hides the modal, and clears any error states.
   * @param {Function} setReason - Function to reset the reason state.
   * @param {Function} setShowModal - Function to set the visibility of the modal.
   * @param {Function} setIsError - Function to clear the error state.
   */
  const cancelRecordFiled = () => {
    setReason({ comment_reason: "" });
    setShowModal(false);
  };

  return (
    <Fragment>
      <div>
        <Button
          className="mb-2 mr-2 btn-icon btn-block"
          color="yellow"
          onClick={handleOnClick}
        >
          <FontAwesomeIcon icon={faBan} className="mr-2" />
          <span>{i18n.t("RecordDetail.label4")}</span>
        </Button>
      </div>
      <Modal
        isOpen={showModal}
        toggle={handleOnClose}
        size="lg"
        className="modal-responsible-size-md"
      >
        <ModalHeader>
          <Label>{i18n.t("RecordDetail.label4")}</Label>
        </ModalHeader>
        <ModalBody>
          <AvForm onSubmit={handleSumbitCancelRecord}>
            <Row>
              <Col md="12">
                <Alert className="mbg-3" color="info" isOpen={true}>
                  <span className="pr-2">
                    <h4 class="font-weight">{formDetail.name}</h4>
                    <hr color="cyan" />
                  </span>
                  <span>
                    {i18n.t("RecordDetail.label3")}:{" "}
                    <strong>{recordInfos.record}</strong>
                  </span>
                </Alert>{" "}
                <Label for="description" className="is-required" sm={12}>
                  {i18n.t("RecordDetail.label5")}
                </Label>
                <ReactTooltip
                  id="description"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  {i18n.t("RecordDetail.label10")}
                </ReactTooltip>
                <AvField
                  id="description"
                  name="description"
                  type="textarea"
                  onChange={handleCommentReason}
                  data-tip
                  data-for="description"
                  validate={{
                    pattern: {
                      value: regexCancelFiled,
                      errorMessage: `${i18n.t("createWorflow.alert")}`,
                    },
                    required: {
                      value: true,
                      errorMessage: `${i18n.t("fieldRequired")}`,
                    },
                    minLength: {
                      value: enumCancelFiled.MIN_LENGTH,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: enumCancelFiled.MAX_LENGTH,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                    },
                  }}
                  autoComplete="off"
                  value={reason.comment_reason}
                />
              </Col>
            </Row>

            <CardFooter
              className="d-block text-right"
            >
              <Button
                size="lg"
                onClick={cancelRecordFiled}
                className="col-mt-3 mr-3"
                color="gray"
              >
                {i18n.t("createusers.createButton2")}
              </Button>

              <Button
                type="submit"
                size="lg"
                disabled={isLoadingCancelFiled}
                className="col-mt-3"
                color="cyan"
              >
                {loadingSpinnerButtonCancel}
                {i18n.t("RecordDetail.label11")}
              </Button>
            </CardFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

ButtonCancelFiled.propTypes = {
  recordInfos: PropTypes.string.isRequired,
};

export default ButtonCancelFiled;
