import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import i18n from "locales/i18n";

const ButtonDocumentalOrdination = ({ id, backgroundId }) => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              size="large"
              color="success"
              onClick={() =>
                (window.location.href = `/trd/ordination/${id}/${backgroundId}`)
              }
            >
              <span>{i18n.t("trd.ordinationTitle")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonDocumentalOrdination;
