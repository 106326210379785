import React from "react";
import CreateList from "./CreateList";
import { ListContextProvider } from "contextAPI/ListsContext";
export default () => {
  return (
    <ListContextProvider>
      <CreateList />
    </ListContextProvider>
  );
};
