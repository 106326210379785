import React from "react";
import PropTypes from "prop-types";
import { keyRoutesLevelsName } from "utils/trdLevelsName";
import { isNullOrUndefined } from "utils/validations";
import i18n from "locales/i18n";

const CurrentPathTrd = (props) => {
  const { currentPath, pathConfig } = props;
  let route;

  /**
   * Generates a route string based on the current path for edition.
   * @param {Object} currentPathForEdition - The current path object for edition.
   * @param {Object} keyRoutesLevelsName - The mapping of keys to route names.
   * @returns {string} The generated route string.
   */
  if (isNullOrUndefined(pathConfig) === false) {
    route = Object.entries(pathConfig)
      .map(([key, value]) => {
        let routeName = `${value.name} (${keyRoutesLevelsName[key]})`;
        if (value.code !== null) {
          return `${value.code}-${routeName}`;
        }
        return routeName;
      })
      .join(" / ");
  }
  return (
    <div className="pathStyled">
      <span>
        <span className="mr-2">{i18n.t("trd.alertExpedient")}:</span>
      </span>
      <span className="font-weight-bold">{route}</span>
      {currentPath.map((label, index) => (
        <span key={index} className="font-weight-bold">
          <p className="d-inline font-weight-bold">{label}</p>
          {index !== currentPath.length - 1 && (
            <span className="d-inline font-weight-bold">/</span>
          )}
        </span>
      ))}
    </div>
  );
};
CurrentPathTrd.propTypes = {
  pathConfig: PropTypes.object.isRequired,
  currentPath: PropTypes.array.isRequired,
};
export default CurrentPathTrd;
