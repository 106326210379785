import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Label } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import cx from "classnames";
import * as moment from "moment";
import { getMonth, getYear, addYears } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { years, months } from "utils/yearsMonthsDatePicker";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { registerLocale } from "react-datepicker";
import i18n from "locales/i18n";
import { es, fr } from "date-fns/esm/locale";
registerLocale("es", es);
registerLocale("fr", fr);

const DateTimePickers = (props) => {
  const {
    id,
    label,
    hide,
    required,
    isPreview,
    isFilterForm,
    startDateFilterForm,
    edit_manually,
    show_pass_dates,
    handleOnChangeRecord,
    validateRequiredPickers,
    showExternalFormFillingProcess,
    value,
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [deletedDate, setDeletedDate] = useState(false);
  const { validationFieldDatePicker, setValidationFieldDatePicker } =
    useFieldsRecordContext();
  let isRequired = null;
  let editManually = null;
  let showPassDates = null;
  let initialDate = null;
  let selectedDate = null;
  let validateDatePickerStyle = null;
  let requiredDatePicker = null;
  let colorDatePicker = "grayLigth";

  /**
   * Custom input component for a date picker, created using React's forwardRef.
   * @param {string} value - The current value to be displayed in the input.
   * @param {function} onClick - The click event handler function for the input.
   * @param {React.Ref} ref - A ref object that is forwarded to the underlying Button component.
   * @returns {JSX.Element} A Button component acting as a custom input for a date picker.
   */
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Button
      className={cx("text-left-3", {
        "text-left-3 validate-border": validateDatePickerStyle === true,
      })}
      block
      type="button"
      color={colorDatePicker}
      size="lg"
      onClick={onClick}
      ref={ref}
    >
      {(() => {
        if (value === "") {
          return (
            <span className="hide-textBox">
              {i18n.t("filterQuery.datapicker")}
            </span>
          );
        }
      })()}
      {value}
    </Button>
  ));

  /**
   * Determines if a field is required and returns a CSS class name accordingly.
   * @param {boolean} required - A boolean value indicating whether the field is required or not.
   * @returns {string|null} Returns the CSS class name "is-required" if the field is required, otherwise null.
   */
  if (required === true) {
    isRequired = "is-required";
  } else {
    isRequired = null;
  }

  /**
   * Conditionally assigns a value to the variable 'editManually' based on the value of 'edit_manually'.
   * If 'edit_manually' is true, 'editManually' is set to null; otherwise, it is assigned a <CustomInput /> component.
   * @param {boolean} edit_manually - A boolean flag that determines the value of 'editManually'.
   * @returns {null|JSX.Element} - Returns null if 'edit_manually' is true, or a JSX element (<CustomInput />) if it's false.
   */
  if (edit_manually === true) {
    editManually = null;
  } else {
    editManually = <CustomInput />;
  }

  /**
   * Sets the value of 'showPassDates' based on the condition 'show_pass_dates'.
   * @param {boolean} show_pass_dates - A boolean value indicating whether to show pass dates.
   * @returns {Date | null} - Returns a Date object if 'show_pass_dates' is true, otherwise returns null.
   */
  if (show_pass_dates === true) {
    showPassDates = new Date();
  } else {
    showPassDates = null;
  }

  /**
   * Checks if a given string represents a valid date.
   * @param {string} dateString - The string to be evaluated as a date.
   * @returns {boolean} - True if the input string is a valid date; otherwise, false.
   */
  const isValidDate = (dateString) => {
    return !isNaN(Date.parse(dateString));
  };
  if (!isValidDate(value)) {
    initialDate = null;
  } else {
    initialDate = new Date(value);
  }

  /**
   * Sets the selected date based on certain conditions.
   * If 'deletedDate' is true, the selected date is set to null.
   * If 'startDate' is not null, the selected date is set to 'startDate'.
   * Otherwise, the selected date is set to 'initialDate'.
   * @param {boolean} deletedDate - Indicates if the date has been deleted.
   * @param {Date|null} startDate - The start date to be used if 'deletedDate' is false and 'startDate' is not null.
   * @param {Date} initialDate - The initial date to be used if 'deletedDate' is false and 'startDate' is null.
   * @returns {Date|null} The selected date based on the specified conditions.
   */
  if (deletedDate === true) {
    selectedDate = null;
  } else if (startDate !== null) {
    selectedDate = startDate;
  } else {
    selectedDate = initialDate;
  }

  /**
   * Determines the styling and content of a date picker based on validation and input conditions.
   * @param {boolean} validateRequiredPickers - Indicates if validation for the specified date picker is required.
   * @param {string} id - The unique identifier of the date picker.
   * @param {boolean} startDate - The start date value of the date picker.
   * @returns {void}
   */
  if (
    validateRequiredPickers !== undefined &&
    validateRequiredPickers[id] !== undefined
  ) {
    validateDatePickerStyle = true;
    colorDatePicker = "validate";
    requiredDatePicker = (
      <span className="text-danger text small">{i18n.t("fieldRequired")}</span>
    );
  } else if (startDate !== null) {
    validateDatePickerStyle = null;
  }

  /**
   * Validates a date picker field based on a provided ID and updates related styles and content.
   * @param {string} id - The identifier for the date picker field to validate.
   * @param {Array<string>} validationFieldDatePicker - An array of field IDs to validate against.
   * @param {boolean} validateDatePickerStyle - A flag indicating whether the date picker should display validation styles.
   * @param {string} colorDatePicker - The color style to apply to the date picker when validation is triggered.
   * @param {ReactNode} requiredDatePicker - A React component representing the required field indicator.
   * @returns {void}
   */
  if (validationFieldDatePicker !== undefined) {
    validationFieldDatePicker.id.some((field) => {
      if (field === id) {
        validateDatePickerStyle = true;
        colorDatePicker = "validate";
        requiredDatePicker = (
          <span className="text-danger text small">
            {i18n.t("fieldRequired")}
          </span>
        );
        return true;
      }
      return false;
    });
  }

  if (
    validateRequiredPickers !== undefined &&
    validateRequiredPickers === true &&
    isFilterForm === true
  ) {
    validateDatePickerStyle = true;
    colorDatePicker = "validate";
    requiredDatePicker = (
      <span className="text-danger text small">{i18n.t("fieldRequired")}</span>
    );
  } else if (
    startDateFilterForm !== null &&
    startDateFilterForm !== undefined
  ) {
    validateDatePickerStyle = null;
  }

  /**
   * Handles the change of a date value and performs various related actions.
   * @function
   * @param {Date|null|undefined} newDate - The new date value to be processed.
   * @returns {void}
   */
  const handleDateChange = (newDate) => {
    let formattedDate;
    if (newDate !== undefined && newDate !== null) {
      formattedDate = moment.utc(newDate).format("YYYY-MM-DDTHH:mm:ss+00:00");
    } else {
      formattedDate = null;
    }
    handleOnChangeRecord(id, formattedDate);
    setStartDate(newDate);
    setDeletedDate(false);
    if (validationFieldDatePicker.id.length > 0) {
      const newArrayValidated = validationFieldDatePicker.id.filter(
        (item) => item !== id
      );
      setValidationFieldDatePicker({
        required: true,
        id: newArrayValidated,
      });
    }
  };

  /**
   * Clears a record by invoking a change handler with a null value and setting a deletion flag.
   * @param {string} id - The identifier of the record to clear.
   * @param {any} required - Indicates whether the record is required for the operation.
   * @returns {void}
   */
  const handleOnClear = () => {
    handleOnChangeRecord(id, null, required);
    setDeletedDate(true);
  };

  return (
    <Fragment>
      {(() => {
        if (isPreview === true) {
          return (
            <AvForm>
              <div className="row form-group">
                <Label
                  className={cx(isRequired, {
                    "is-required label-color": validateDatePickerStyle === true,
                  })}
                  sm={3}
                >
                  {label}
                </Label>

                <Col md={8}>
                  <div className="margin-bottom-datepicker">
                    <DatePicker
                      id={id}
                      name={id}
                      className={cx("date__input-container ", {
                        "date_vaidate__input-container text-left-3":
                          validateDatePickerStyle === true,
                      })}
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div className="container-datapicker">
                          <button
                            type="button"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            type="button"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={selectedDate}
                      onChange={handleDateChange}
                      required={required}
                      customInput={editManually}
                      locale={i18n.t("datapicker.language")}
                      fixedHeight
                      fixedWidth
                      showTimeSelect
                      timeIntervals={5}
                      autoComplete="off"
                      dateFormat="dd/MM/yyyy hh:mm"
                      placeholderText={i18n.t("datapicker.placeHolder")}
                      minDate={showPassDates}
                      maxDate={addYears(new Date(), 80)}
                    />
                    {requiredDatePicker}
                  </div>
                </Col>
                {(() => {
                  if (showExternalFormFillingProcess === false) {
                    return (
                      <Col md={0.2}>
                        <FontAwesomeIcon
                          className="btn-icon"
                          color="#0072bc"
                          size="lg"
                          type="button"
                          icon={faCalendarTimes}
                          onClick={handleOnClear}
                        />
                      </Col>
                    );
                  } else {
                    return null;
                  }
                })()}
              </div>
            </AvForm>
          );
        } else if (hide === false && isFilterForm === false) {
          return (
            <div className="row form-group">
              <Label
                className={cx(isRequired, {
                  "is-required label-color": validateDatePickerStyle === true,
                })}
                sm={3}
              >
                {label}
              </Label>

              <Col md={8}>
                <div className="margin-bottom-datepicker">
                  <DatePicker
                    key={id}
                    id={id}
                    name={id}
                    className={cx("date__input-container ", {
                      "date_vaidate__input-container text-left-3":
                        validateDatePickerStyle === true,
                    })}
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="container-datapicker ">
                        <button
                          type="button"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {"<"}
                        </button>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button
                          type="button"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {">"}
                        </button>
                      </div>
                    )}
                    selected={selectedDate}
                    onChange={handleDateChange}
                    required={required}
                    customInput={editManually}
                    locale={i18n.t("datapicker.language")}
                    fixedHeight
                    fixedWidth
                    showTimeSelect
                    timeIntervals={5}
                    autoComplete="off"
                    dateFormat="dd/MM/yyyy hh:mm"
                    placeholderText={i18n.t("datapicker.placeHolder")}
                    minDate={showPassDates}
                    maxDate={addYears(new Date(), 80)}
                  />
                  {requiredDatePicker}
                </div>
              </Col>
              {(() => {
                if (showExternalFormFillingProcess === false) {
                  return (
                    <Col md={0.2}>
                      <FontAwesomeIcon
                        className="btn-icon"
                        color="#0072bc"
                        size="lg"
                        type="button"
                        icon={faCalendarTimes}
                        onClick={handleOnClear}
                      />
                    </Col>
                  );
                } else {
                  return null;
                }
              })()}
            </div>
          );
        } else if (isFilterForm === true) {
          return (
            <Col md={12}>
              <div className="margin-bottom-datepicker">
                <DatePicker
                  key={id}
                  id={id}
                  name={id}
                  className={cx("date__input-container ", {
                    "date_vaidate__input-container text-left-3":
                      validateDatePickerStyle === true,
                  })}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="container-datapicker ">
                      <button
                        type="button"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        type="button"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={startDateFilterForm}
                  onChange={handleOnChangeRecord}
                  required={required}
                  customInput={editManually}
                  locale={i18n.t("datapicker.language")}
                  fixedHeight
                  fixedWidth
                  showTimeSelect
                  timeIntervals={5}
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy hh:mm"
                  placeholderText={i18n.t("filterQuery.datapicker")}
                  minDate={showPassDates}
                  maxDate={addYears(new Date(), 80)}
                />
                {requiredDatePicker}
              </div>
            </Col>
          );
        }
      })()}
    </Fragment>
  );
};

DateTimePickers.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hide: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  edit_manually: PropTypes.bool.isRequired,
  show_pass_dates: PropTypes.bool.isRequired,
  handleOnChangeRecord: PropTypes.func.isRequired,
  showExternalFormFillingProcess: PropTypes.bool,
  isFilterForm: PropTypes.bool,
  value: PropTypes.string,
  validateRequiredPickers: (props, propName, componentName) => {
    if (props.isFilterForm) {
      if (typeof props[propName] !== "boolean") {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. It should be a boolean when 'isFilterForm' is true.`
        );
      }
    } else {
      if (typeof props[propName] !== "object") {
        return new Error(
          `Invalid prop '${propName}' supplied to '${componentName}'. It should be an object when 'isFilterForm' is false.`
        );
      }
    }

    return null;
  },
};

export default DateTimePickers;
