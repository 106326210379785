import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
  AvFeedback,
} from "availity-reactstrap-validation";
import { useListContext } from "contextAPI/ListsContext";
import { useParams } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import { enumsCreateList } from "utils/enums";
import { regexItemName } from "utils/regexExpressions";
import { isNullOrUndefined } from "utils/validations";
import cx from "classnames";
import i18n from "locales/i18n";
import useList from "hooks/useList";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";

const ButtonCreateItem = () => {
  const { id, parentId } = useParams();
  const { createItemsInList } = useList();
  const { getItemsBySearch, item, setItem } = useListContext();
  const [showModalCreateItem, setShowModalCreateItem] = useState(false);
  const [isLoadingCreateItemList, setIsLoading] = useState(false);
  const [formItem, setFormItem] = useState({
    name: "",
    parent_id: "",
  });

  let loadingCreateItemList = null;
  let disabledParentItem = null;
  let valueParentItem = null;
  let requiredValueParentItem = null;

  /**
   * Determines if the parent item value is required based on whether parentId is null or undefined.
   * @param {any} parentId - The ID of the parent item to check.
   * @param {boolean} requiredValueParentItem - Flag indicating if the parent item value is required.
   * @returns {void}
   */
  if (isNullOrUndefined(parentId) === false) {
    requiredValueParentItem = true;
  } else {
    requiredValueParentItem = false;
  }

  /**
   * Sets the value of the parent item based on whether the parentId is null or undefined.
   * @name setValueParentItem
   * @param {any} parentId - The ID of the parent item to check.
   * @param {any} valueParentItem - The value to assign to the parent item.
   * @returns {void}
   */
  if (isNullOrUndefined(parentId) === false) {
    valueParentItem = formItem.parent_id;
  } else {
    valueParentItem = null;
  }

  /**
   * Sets the disabled state of the parent item based on whether the parentId is null or undefined.
   * @param {any} parentId - The ID of the parent item to check.
   * @param {boolean} disabledParentItem - The flag to disable or enable the parent item.
   * @returns {void}
   */
  if (isNullOrUndefined(parentId) === false) {
    disabledParentItem = false;
  } else {
    disabledParentItem = true;
  }

  /**
   * Sets the loading spinner element if the item list creation is in progress.
   * @name setLoadingSpinner
   * @param {boolean} isLoadingCreateItemList - Flag indicating if the item list creation is loading.
   * @param {JSX.Element} loadingCreateItemList - The spinner element to display while loading.
   */
  if (isLoadingCreateItemList === true) {
    loadingCreateItemList = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Handles the event to close the form for creating an item.
   * Closes the modal and resets the form item state.
   * @returns {void}
   */
  const handleCloseForm = () => {
    setShowModalCreateItem(false);
    setFormItem({
      name: "",
      parent_id: "",
    });
  };

  /**
   * Handles the change event for the item name input field.
   * Updates the form item state with the new name value.
   * @param {Object} eventItemName - The event object for the name input change.
   * @returns {void}
   */
  const handleChangeItemName = (eventItemName) => {
    if (parentId) {
      setFormItem({
        ...formItem,
        name: eventItemName.target.value,
      });
    } else {
      setFormItem({
        name: eventItemName.target.value,
      });
    }
  };

  /**
   * Handles the change event for the parent ID input field.
   * Updates the form item state with the new parent ID value.
   * @name handleChangeParentId
   * @param {Object} eventItemName - The event object for the parent ID input change.
   * @returns {void}
   */
  const handleChangeParentId = (eventItemName) => {
    setFormItem({
      ...formItem,
      parent_id: eventItemName.target.value,
    })
  }

  /**
   * Handles the click event to show the modal for creating an item.
   * Sets the state to display the modal.
   * @returns {void}
   */
  const handleOnClickShowModal = () => {
    setShowModalCreateItem(true);
  };

  /**
   * Handles the event to close the modal for creating an item.
   * Sets the state to hide the modal.
   * @returns {void}
   */
  const handleOnCloseModalCreateItem = () => {
    setShowModalCreateItem(false);
  };

  /**
   * Handles the submission event for creating an item.
   * Prevents form submission if validation errors are present.
   * If there are no errors, it initiates the item creation process.
   * @param {Object} eventCreateItem - The event object for form submission.
   * @param {Array} errors - Array of validation errors.
   * @returns {void}
   */
  const handleOnSubmit = (eventCreateItem, errors) => {
    if (
      parentId &&
      (formItem.name === "" ||
        formItem.parent_id === "" ||
        formItem.parent_id === null ||
        formItem.parent_id === undefined)
    ) {
      eventCreateItem.preventDefault();
    } else {
      if (errors.length === 0) {
        setIsLoading(true);
        createItemsInList(formItem, id)
          .then((response) => {
            if (response.status === 201) {
              handleOnCloseModalCreateItem();
              const showAlertSuccessitem = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("itemList.createSucess"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                  successMode: true,
                }).then(() => {
                  window.location.reload();
                });
              };
              showAlertSuccessitem();
            } else {
              showAlertServiceError();
            }
          })
          .catch((error) => {
            if (error) {
              setFormItem({
                name: "",
                parent_id: "",
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        return;
      }
    }
  };

  /**
   * Handles the key press event during item creation.
   * Prevents the default action when the Enter key is pressed.
   * @param {Object} eventCreateItem - The event object for the key press.
   * @returns {void}
   */
  const handleOnKeyPressEventCreateItem = (eventCreateItem) => {
    if (eventCreateItem.key === "Enter") {
      eventCreateItem.preventDefault();
    }
  };

  /**
   * Executes a callback function when `parentId` changes, resetting `item` state and fetching items.
   * @param {Function} callback - Callback function to execute.
   * @param {any} deps - Dependency array triggering callback on change.
   */
  useEffect(() => {
    if (parentId) {
      setItem([]);
      getItemsBySearch(parentId);
    }
  }, [parentId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              onClick={handleOnClickShowModal}
            >
              <span>
                {"+ "}
                {i18n.t("itemList.designerItem2")}
              </span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModalCreateItem}
          toggle={handleOnCloseModalCreateItem}
          className="modal-medium-sizes"
        >
          <ModalHeader>
            <Label>{i18n.t("itemList.designerItem2")}</Label>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmit}>
              <AvGroup>
                <ReactTooltip
                  id="itemname"
                  place="bottom"
                  type="dark"
                  effect="solid"
                >
                  {i18n.t("itemList.Tooltip1")}
                </ReactTooltip>

                <Label for="name" className="is-required" sm={4}>
                  {i18n.t("itemList.designerLabel1")}
                </Label>
                <Col md={12}>
                  <AvField
                    id="name"
                    name="name"
                    type="text"
                    data-tip
                    data-for="itemname"
                    autoComplete="off"
                    value={formItem.name}
                    onChange={handleChangeItemName}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                      pattern: {
                        value: regexItemName,
                        errorMessage: `${i18n.t("itemList.designerFeedback1")}`,
                      },
                      minLength: {
                        value: enumsCreateList.MIN_LENGTH_NAME_LIST,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsCreateList.MAX_LENGTH_NAME_LIST,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                  />
                  <AvFeedback>
                    {i18n.t("itemList.designerFeedback1")}
                  </AvFeedback>
                </Col>
              </AvGroup>

              <AvGroup>
                <Label
                  for="parent_item"
                  className={cx("", {
                    parentId: "is-required",
                  })}
                  sm={4}
                >
                  {i18n.t("itemList.designerLabel2")}{" "}
                  {loadingCreateItemList}
                </Label>
                <Col md={12}>
                  <AvField
                    id="parent_item"
                    name="parent_item"
                    type="select"
                    autoComplete="off"
                    disabled={disabledParentItem}
                    value={valueParentItem}
                    onChange={handleChangeParentId}
                    onKeyPress={handleOnKeyPressEventCreateItem}
                    validate={{
                      required: {
                        value: requiredValueParentItem,
                        errorMessage: `${i18n.t("form.field46")}`,
                      },
                    }}
                  >
                    <option value={""}>
                      {i18n.t("form.designerLabel10.1")}
                    </option>
                    {item.map((itemName) => {
                      return (
                        <option key={itemName.value} value={itemName.value}>
                          {itemName.label}
                        </option>
                      );
                    })}
                  </AvField>
                </Col>
              </AvGroup>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={handleCloseForm}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingCreateItemList}
                  className="col-mt-3 mr-3"
                  color="cyan"
                >
                  {loadingCreateItemList}
                  {i18n.t("createusers.createButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonCreateItem;