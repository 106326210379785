import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserListMembersRoles from "components/organism/UserListMembersRoles";
import i18n from "locales/i18n";

const UserRolesMembers = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("createRoles.MembersPage")}
        subheading={i18n.t("createRoles.MembersPageSub")}
        icon="users"
      >
        <UserListMembersRoles />
      </AdminTemplate>
    </Fragment>
  );
};

export default UserRolesMembers;
