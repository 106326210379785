import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import { Alert, CardTitle } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import i18n from "locales/i18n";
import useWorkflow from "hooks/useWorkFlow";
import Loader from "react-loaders";
import renderIconFile from "utils/renderIconFile";

const WorkflowTraceabilityFilesListTable = (props) => {
  const { id } = props;
  const { getWorkflowTraceabilityFiles } = useWorkflow();
  const [files, setFiles] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [pageSizeToShow, setPageSizeToShow] = useState([2]);

  /**
   * Determines the number of rows to display in the table.
   * If the `workflowsTable` is empty, it defaults to showing 1 row.
   * Otherwise, it sets the number of rows to the length of `workflowsTable`.
   * @param {Object[]} workflowsTable - The array of workflow data objects.
   * @returns {number} The number of rows to display in the table.
   */
  function getPageSize(files) {
    if (files.length === 0) {
      return [2];
    }
    return files.length;
  }

  /**
   * Fetches workflow traceability files by ID and updates the component state with the retrieved data.
   * It sets the loading state to true before initiating the request and sets it back to false after the request completes.
   * If the response data is not null or undefined, it sets the files state with the response data.
   * Otherwise, it shows a service error alert.
   * @returns {void}
   */
  const getWorkflowTraceabilityFilesById = useCallback(() => {
    setIsLoadingFiles(true);
    getWorkflowTraceabilityFiles(id)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setFiles(response.data.data);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFiles(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook to update the number of rows to display in the table.
   * It sets `pageSizeToShow` based on the length of `files`.
   * @param {Object[]} files - The array of form data objects.
   */
  useEffect(() => {
    setPageSizeToShow(getPageSize(files));
  }, [files]);

  /**
   * Fetches workflow traceability files by ID and updates the component state with the retrieved data.
   * It sets the loading state to true before initiating the request and sets it back to false after the request completes.
   * If the response data is not null or undefined, it sets the files state with the response data.
   * Otherwise, it shows a service error alert.
   * @returns {void}
   */
  useEffect(() => {
    getWorkflowTraceabilityFilesById();
  }, [getWorkflowTraceabilityFilesById]);

  /**
   * Renders a loader when files are being loaded.
   * If loading is complete and no files are available, renders an informational alert.
   * @returns {JSX.Element} The loader, alert, or other UI elements based on the loading state and files.
   */
  if (isLoadingFiles === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="secondary" type="ball-pulse-rise" />
      </div>
    )
  } else if (files.length === 0) {
    return (
      <Alert className="block" color="info">
        {i18n.t("taskDetailFiles")}
      </Alert>
    )
  };

  return (
    <Fragment>
      <CardTitle className="align-items-left ">
        {i18n.t("recordDetail.files")}
      </CardTitle>
      <ReactTable
        className="table-files-style p-0"
        data={files}
        columns={[
          {
            columns: [
              {
                accessor: "name",
                Cell: (row) => (
                  <div>
                    {renderIconFile(row)}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${row.original.presigned_url}`}
                    >
                      {row.value}
                    </a>
                  </div>
                ),
              },
            ],
          },
        ]}
        manual
        pageSize={pageSizeToShow}
        showPaginationBottom={false}
        noDataText={null}
      />
    </Fragment>
  );
};

WorkflowTraceabilityFilesListTable.propTypes = {
  id: PropTypes.string.isRequired,
};

export default WorkflowTraceabilityFilesListTable;
