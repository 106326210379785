import React from "react";
import WorkFlowConfigSteps from "./WorkFlowConfigSteps";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";

export default () => {
  return (
    <WorkflowContextProvider>
      <WorkFlowConfigSteps />
    </WorkflowContextProvider>
  );
};
