import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Col, Label, Input, CustomInput } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { capitalizeText } from "utils/formatText";
import SearchBoxLookupField from "components/atoms/SearchBoxLookupField";
import useForm from "hooks/useForm";
import ReactTable from "react-table";
import swal from "sweetalert";
import i18n from "locales/i18n";


const QueryListLookup = (props) => {

  const { formLookupName, formLookupFields, formUuid } = props;
  const [isLoadingRecords, setIsLoadingRecords] = useState(false);
  const [paginationRecords, setPaginationRecords] = useState({
    page: 1,
    per_page: 5,
  });
  const [totalRecordsPages, setTotalRecordsPages] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [errorCharacters, setErrorCharacters] = useState("");
  const [countRecordsInOtherPage, setCountRecordsInOtherPage] = useState(0);
  const [listRecordsAssociated, setListRecordsAssociated] = useState([]);
  const { getRecordsByFormLookup } = useForm();
  const {
    setConcatenatedValues,
    setShowModalForLookup,
    setUuidRecordLookup,
  } = useFieldsRecordContext();
  let nodataMessage = i18n.t("lookupFilterMessageEmpty");

  /**
   * Fetches records for a form lookup, updates the state, and handles related actions.
   * @param {function} getRecordsByFormLookup - A function for fetching records by form lookup.
   * @param {string} id - The identifier for the form lookup.
   * @param {number} page - The current page of records to retrieve.
   * @param {number} per_page - The number of records to retrieve per page.
   * @param {string} searchData - The search data used for filtering records.
   * @param {object} paginationRecords - The pagination configuration object.
   * @param {function} setIsLoadingRecords - A function to set the loading state for records.
   * @param {function} setPaginationRecords - A function to update the pagination configuration for records.
   * @param {function} setSearchData - A function to set the search data used for filtering.
   * @param {function} setShowModalForLookup - A function to control the visibility of a modal.
   * @param {function} setTotalRecordsPages - A function to set the total number of record pages.
   * @param {string} formLookupName - The name of the form lookup.
   * @param {function} setListRecordsAssociated - A function to set the list of associated records.
   * @returns {void}
   */
  const getRecordsFormLookup = useCallback(
    () => {
      const { page, per_page } = paginationRecords;
      setIsLoadingRecords(true);
      getRecordsByFormLookup(formUuid, page, per_page, searchData)
        .then((response) => {
          let dataFirstField = Object.values(response.data.form_lookup_fields)[
            "0"
          ].records;
          let dataSecondField = Object.values(response.data.form_lookup_fields)[
            "1"
          ].records;
          if (
            dataFirstField.length === 0 ||
            dataSecondField.length === 0 ||
            (dataFirstField.length === 0 && dataSecondField.length === 0)
          ) {
            setListRecordsAssociated([]);

            if (searchData !== "") {
              setCountRecordsInOtherPage(response.data.count);
              setTotalRecordsPages(1);
            } else {
              setShowModalForLookup(false);
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: `${i18n.t("form.field81")} "${formLookupName}", ${i18n.t(
                  "form.field82"
                )}`,
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          } else {
            setTotalRecordsPages(response.data.pages);
            const combinedArray = {};
            dataFirstField.forEach((itemField) => {
              const { uuid, value } = itemField;
              combinedArray[uuid] = {
                ...combinedArray[uuid],
                uuid: uuid,
                firstField: value,
              };
            });
            dataSecondField.forEach((itemField) => {
              const { uuid, value } = itemField;
              combinedArray[uuid] = {
                ...combinedArray[uuid],
                secondField: value,
              };
            });
            const resultArray = Object.values(combinedArray);
            setListRecordsAssociated(resultArray);
          }
        })
        .finally(() => {
          setIsLoadingRecords(false);
        });
    },
    [paginationRecords] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Generates a message based on the count of records in another page.
   * If the count of records in another page is greater than 0, a specific message is generated
   * using internationalized strings.
   * @param {number} countRecordsInOtherPage - The count of records in another page.
   * @returns {string} nodataMessage - The message generated based on the count of records.
   */
  if (countRecordsInOtherPage > 0) {
    nodataMessage = `${i18n.t("lookupFilterMessage1")}`;
  }

  /**
   * Handles the selection of fields from an original data object and updates related state values.
   * @param {object} original - The original data object from which fields are selected.
   * @param {string} original.firstField - The value of the first field to be concatenated.
   * @param {string} original.secondField - The value of the second field to be concatenated.
   * @param {string} original.uuid - The UUID associated with the record.
   * @returns {void}
   */
  const handleSelectFields = (original) => {
    const concatenated = `${original.firstField} | ${original.secondField}`;
    setConcatenatedValues(concatenated);
    const uuidRecord = original.uuid;
    setUuidRecordLookup(uuidRecord);
    setShowModalForLookup(false);
  };

  /**
   * A React useEffect hook that invokes the 'getRecordsFormLookup' function when the 'getRecordsFormLookup' dependency changes.
   * This effect is used to trigger the 'getRecordsFormLookup' function when its dependencies change,
   * ensuring that the function is called when needed in the component's lifecycle.
   * @param {function} getRecordsFormLookup - The function responsible for fetching records from a lookup.
   * @returns {void}
   */
  useEffect(() => {
    getRecordsFormLookup();
  }, [getRecordsFormLookup]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm>
          <AvGroup row key={formUuid}>
            <Label className="isRequired" for={`"form" ${formUuid}`} sm={3}>
              {i18n.t("createWorflow.form")}
            </Label>
            <Col md={9}>
              <AvField
                id={`"form" ${formUuid}`}
                key={formUuid}
                name="lookup"
                type="text"
                autoComplete="off"
                value={formLookupName}
                disabled
              />
            </Col>
            <div className="ml-auto mb-2">
              <SearchBoxLookupField
                searchData={searchData}
                setSearchData={setSearchData}
                paginationRecords={paginationRecords}
                setPaginationRecords={setPaginationRecords}
                errorCharacters={errorCharacters}
                setErrorCharacters={setErrorCharacters}
              />
            </div>
            <Col md={12}>
              <ReactTable
                data={listRecordsAssociated}
                columns={[
                  {
                    columns: [
                      {
                        Header: capitalizeText(
                          Object.values(formLookupFields)[0].label
                        ),
                        accessor: "firstField",
                      },
                      {
                        Header: capitalizeText(
                          Object.values(formLookupFields)["1"].label
                        ),
                        accessor: "secondField",
                      },
                      {
                        Header: i18n.t("trd.selectButton"),
                        accessor: "value",
                        Cell: ({ original, index }) => {
                          return (
                            <div className="d-block w-100 text-center">
                              <Input
                                id={original.uuid}
                                name={original.uuid}
                                type="checkbox"
                                tag={CustomInput}
                                onClick={() => {
                                  handleSelectFields(original, index);
                                }}
                              />
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ]}
                manual
                className="-striped -highlight d-flex"
                pages={totalRecordsPages}
                page={paginationRecords.page - 1}
                showPageJump={false}
                defaultPageSize={paginationRecords.per_page}
                loading={isLoadingRecords}
                loadingText={i18n.t("loading")}
                noDataText={nodataMessage}
                previousText={i18n.t("previousText")}
                nextText={i18n.t("nextText")}
                pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
                ofText={<span className="px-2">{i18n.t("ofText")}</span>}
                onPageSizeChange={(per_page) => {
                  if (errorCharacters !== "") {
                    setSearchData("");
                    setErrorCharacters("");
                    setPaginationRecords({
                      ...paginationRecords,
                      per_page: per_page,
                    });
                  } else {
                    setPaginationRecords({
                      ...paginationRecords,
                      per_page: per_page,
                    });
                  }
                }}
                onPageChange={(page) => {
                  if (errorCharacters !== "") {
                    setSearchData("");
                    setErrorCharacters("");
                    setPaginationRecords({
                      ...paginationRecords,
                      page: page + 1,
                    });
                  } else {
                    setPaginationRecords({
                      ...paginationRecords,
                      page: page + 1,
                    });
                  }
                }}
              />
            </Col>
          </AvGroup>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

QueryListLookup.propTypes = {
  formLookupName: PropTypes.string.isRequired,
  formLookupFields: PropTypes.object.isRequired,
  forUuid: PropTypes.string,
};

export default QueryListLookup;
