import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import EditExpedientForm from "components/organism/EditExpedientForm";
import i18n from "locales/i18n";

const EditExpedients = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("trd.formTittle26")}
        subheading={i18n.t("trd.formTittle31")}
        icon="wallet"
      >
        <EditExpedientForm />
      </AdminTemplate>
    </Fragment>
  );
};

export default EditExpedients;
