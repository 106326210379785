import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import ButtonActionsForm from "components/atoms/ButtonActionsForm";
import i18n from "locales/i18n";

const FormListTable = (props) => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={props.listForms}
            columns={[
              {
                columns: [
                  {
                    Header: [i18n.t("form.designerLabel1")],
                    accessor: "name",
                  },
                  {
                    Header: [i18n.t("createusers.label1")],
                    accessor: "user_name",
                  },
                  {
                    Header: [i18n.t("trdExpedientList5")],
                    accessor: "status",
                    Cell: ({ value }) => {
                      let finalValue = Number(value);
                      let statusFinal = "";
                      if (finalValue === 1) {
                        statusFinal = (
                          <div className="ml-auto badge badge-warning">
                            {i18n.t("form.designerLabel3.2")}
                          </div>
                        );
                      } else if (finalValue === 2) {
                        statusFinal = (
                          <div className="ml-auto badge badge-success">
                            {i18n.t("form.designerLabel3.3")}
                          </div>
                        );
                      } else if (finalValue === 3) {
                        statusFinal = (
                          <div className="ml-auto badge badge-danger">
                            {i18n.t("form.designerLabel3.4")}
                          </div>
                        );
                      }

                      return <span>{statusFinal}</span>;
                    },
                  },
                  {
                    Header: [i18n.t("itemList.designerLabel3")],
                    accessor: "created_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    },
                  },
                  {
                    Header: [i18n.t("form.field47")],
                    accessor: "updated_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    Header: [i18n.t("buttonActions.title")],
                    accessor: "actions",
                    Cell: (row) => {
                      let status = row.original.status;
                      return <ButtonActionsForm formId={row.original.uuid} formStatus={status} />;
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={props.totalPages}
            page={props.pagination.page - 1}
            showPageJump={false}
            defaultPageSize={props.pagination.per_page}
            loading={props.isLoadingFormsList}
            noDataText={i18n.t("tableRowsEmpty")}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              props.setPagination({ ...props.pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              props.setPagination({ ...props.pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FormListTable.propTypes = {
  listForms: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingFormsList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default FormListTable;
