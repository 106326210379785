import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { enumsProcessManagementFilterApply } from "utils/enums";
import moment from "moment";
import i18n from "locales/i18n";

const ProcessManagementFiltersApply = (props) => {
  const { queryProcessData, usersDinamic, workflowsDinamic, workflowsStepsDinamic } = props;
  const filtersApplied = [];

  /**
   * Retrieves the label corresponding to the given UUID from the selected data form.
   * @param {string|null} uuid - The UUID to find the label for. If null, an empty string is returned.
   * @param {Array<{ value: string, label: string }>} dataFormSelected - The array of selected data form objects, each containing a `value` and a `label`.
   * @returns {string} - The label associated with the UUID, or an empty string if the UUID is not found.
   */
  const getLabel = (uuid, dataFormSelected) => {
    if (uuid === null) return "";
    const validateLabel = dataFormSelected.find((data) => data.value === uuid);
    if (validateLabel) {
      return validateLabel.label;
    } else {
      return "";
    }
  };

  /**
   * Adds applied filters to the `filtersApplied` array based on provided query parameters.
   * @param {Object} queryProcessData - Object containing query parameters.
   * @param {Array} filtersApplied - Array where applied filters are stored.
   * @param {Object} usersDinamic - Dynamic data for users.
   * @param {Object} workflowsDinamic - Dynamic data for workflows.
   * @param {Object} workflowsStepsDinamic - Dynamic data for workflow steps.
   */
  if (queryProcessData.user_uuid) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.RESPONSIBLE,
      value: getLabel(queryProcessData.user_uuid, usersDinamic),
    });
  }

  if (queryProcessData.workflow_uuid === false) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.PROCESS_NAME,
      value: i18n.t("processManagement.FreeProcess"),
    });
  }

  if (queryProcessData.workflow_uuid) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.PROCESS_NAME,
      value: getLabel(queryProcessData.workflow_uuid, workflowsDinamic),
    });
  }

  if (queryProcessData.process_number) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.PROCESS_NUMBER,
      value: queryProcessData.process_number,
    });
  }

  if (queryProcessData.step_uuid) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.PROCESS_STEP,
      value: getLabel(queryProcessData.step_uuid, workflowsStepsDinamic),
    });
  }

  if (queryProcessData.start_date) {
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.START_DATE,
      value: moment(queryProcessData.start_date).format("DD-MM-YYYY"),
    });
  }

  if (queryProcessData.end_date) {
    let currentDate = moment(queryProcessData.end_date);
    let newDate = currentDate.subtract(1, "days");
    filtersApplied.push({
      label: enumsProcessManagementFilterApply.END_DATE,
      value: moment(newDate).format("DD-MM-YYYY"),
    });
  }

  return (
    <div>
      <Row>
        <h6 className="text-info font-weight-bold ml-2">{i18n.t("filterQuery.apply")}</h6>
      </Row>
      <Row>
        {filtersApplied.map((filter, index) => (
          <Col key={index} md="auto" className="mb-2 ml-2">
            <span key={filter.value}>
              <strong className="text-info font-weight-bold">{filter.label}: </strong>
              <span className="text-focus">{filter.value}</span>
              {index < filtersApplied.length - 1 && ", "}
            </span>
          </Col>
        ))}
      </Row>
    </div>
  );
};

ProcessManagementFiltersApply.propTypes = {
  queryProcessData: PropTypes.object.isRequired,
  usersDinamic: PropTypes.array.isRequired,
  workflowsDinamic: PropTypes.array.isRequired,
  workflowsStepsDinamic: PropTypes.array.isRequired,
};

export default ProcessManagementFiltersApply;
