/**
 * Regular expression pattern for validating text input with allowed characters.
 * The regex allows letters (including accented characters), digits, common special characters,
 * and specific symbols like space, comma, hash, ampersand, period, at sign, hyphen, and underscore.
 * @constant {RegExp} regexTextBox - Regular expression pattern for validating text input.
 */
export const regexTextBox = /^[\wáéíóúÁÉÍÓÚüÜñÑ, #&.@s-]*$/;

/**
 * Regular expression to validate a string containing letters, numbers, spaces,
 * and certain special characters (À-ÿ, -, _). This regex is used for input
 * validation in a forms designer context.
 * @constant {RegExp}
 */
export const formsDesignerRegex = /^([A-Za-z0-9 À-ÿ-_]*)$/;

/**
 * Regular expression pattern for validating search box lookup input.
 * This regular expression pattern allows matching of alphanumeric characters,
 * along with specific special characters commonly used in search inputs such as
 * commas, percent signs, hashtags, ampersands, at symbols, whitespace, and hyphens.
 * It supports accented characters and the Spanish letter 'ñ'. The pattern is designed
 * for validating input typically used in search boxes or lookup fields.
 * @constant {RegExp}
 */
export const regexSearchBoxLookup = /^[\wáéíóúÁÉÍÓÚüÜñÑ, %#&@s-]*$/;

/**
 * Regular expression pattern for validating search box lookup input.
 * This regular expression pattern allows matching of alphanumeric characters,
 * along with specific special characters commonly used in search inputs such as
 * commas, percent signs, hashtags, ampersands, at symbols, whitespace, and hyphens.
 * It supports accented characters and the Spanish letter 'ñ'. The pattern is designed
 * for validating input typically used in search boxes or lookup fields.
 * @constant {RegExp}
 */
export const regexSearchBoxExpedient = /^[\wáéíóúÁÉÍÓÚüÜñÑ, %#&s-]*$/;

/**
 * Regular expression pattern for validating search box lookup input.
 * This regular expression pattern allows matching of alphanumeric characters,
 * along with specific special characters commonly used in search inputs such as
 * commas, percent signs, hashtags, ampersands, at symbols, whitespace, and hyphens.
 * It supports accented characters and the Spanish letter 'ñ'. The pattern is designed
 * for validating input typically used in search boxes or lookup fields.
 * @constant {RegExp}
 */
export const regexSearchExportDataRecords=  /^[\wáéíóúÁÉÍÓÚüÜñÑ, -]*$/;

/**
 * Regular expression pattern for validating names that may contain letters, digits, spaces, hyphens, and accented characters.
 * @param {string} - The input string to be matched against the regular expression pattern.
 * @returns {boolean} - Returns true if the input string matches the pattern; otherwise, returns false.
 */
export const regexNameList = /^[\w áéíóúüñÑÁÉÍÓÚÜ-]*$/;

/**
 * Regular expression pattern for validating item names.
 * This regular expression pattern allows alphanumeric characters, accented characters,
 * spaces, hyphens, apostrophes, and underscores. It is case insensitive.
 * @constant {RegExp}
 */
export const regexNameItem = /^([a-zA-Z\dáéíóúÁÉÍÓÚüÜñÑ '-]+)$/i;

/**
 * Regular expression pattern for validating login forms.
 * The regular expression pattern ensures that the input follows the standard email format with alphanumeric characters
 * and underscores before the "@" symbol, and alphanumeric characters after the "@" symbol.
 * It allows only one "@" symbol in the string and requires at least one character before
 * and after the "@" symbol.
 * @constant {RegExp}
 */
export const regexFormLogin = /^([A-Za-z0-9_]+@[A-Za-z0-9]+)$/;

/**
 * Regular expression pattern for validating subjects of uploaded files.
 * The regex pattern allows alphanumeric characters, spaces, common accented characters,
 * dashes, underscores, periods, parentheses, and spaces.
 * @constant {RegExp}
 */
export const regexSubjectUploadFile = /^[a-zA-Z0-9\sáéíóúÁÉÍÓÚüÜñÑ\-_. ()%]*$/;

/**
 * Regular expression that accepts basic alphanumeric characters,
 * as well as spaces, hyphens, underscores, periods, and commas.
 * - _ . , Ñ ñ y tíldes
 * @type {RegExp}
 */
export const regexAcceptPrimaryCharacters = /^([a-zA-Z\dáéíóúÁÉÍÓÚüÜñÑ .,_-]+)$/;

/**
 * Regular expression that accepts basic alphanumeric characters,
 * as well as spaces, hyphens, underscores, periods, and commas.
 * - _ . Ñ ñ y tíldes
 * @type {RegExp}
 */
export const regexCommentTask = /^([a-zA-Z\dáéíóúÁÉÍÓÚüÜñÑ ._-]+)$/;

/**
 * Regular expression pattern for validating user names during creation.
 * Matches strings containing only alphanumeric characters and underscores.
 * @type {RegExp}
 */
export const regexCreateUserName = /^([A-Za-z0-9_]+)$/;

/**
 * Regular expression pattern for validating email addresses during user creation.
 * @type {RegExp}
 */
export const regexCreateUserEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/;

/**
 * Regular expression to validate document type input.
 * 
 * This regular expression matches strings that:
 * - Contain only digits (0-9).
 * 
 * @type {RegExp}
 */
export const regexDocumentType = /^([0-9]+)$/;

/**
 * Regular expression to validate document type input with letters and digits.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits (0-9), plus signs (+), and hyphens (-).
 * 
 * @type {RegExp}
 */
export const regexDocumentTypeLetters = /^([A-Za-z0-9+-]+)$/;

/**
 * Regular expression to validate user phone number input.
 * 
 * This regular expression matches strings that:
 * - Contain only digits (0-9) and backspace (represented by `\b`).
 * 
 * Note: The `\b` in this regex typically represents a backspace, but in many implementations, it may be unnecessary or incorrect for phone number validation.
 * 
 * @type {RegExp}
 */
export const regexCreateUserPhone = /^[0-9\b]+$/;

/**
 * Regular expression to validate user roles input.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits, spaces, hyphens (-), and underscores (_).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * 
 * @type {RegExp}
 */
export const regexCreateUserRoles = /^([A-Za-z0-9 À-ÿ-_]*)$/;

/**
 * Regular expression to validate integration description input.
 * 
 * This regular expression matches strings that:
 * - Contain letters (uppercase and lowercase), digits (0-9), spaces, hyphens (-), underscores (_), question marks (?), and inverted question marks (¿).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * - Allow commas (,).
 * 
 * @type {RegExp}
 */
export const regexIntegrationDescription = /^[a-zA-Z0-9 À-ÿ-_?¿,]+$/;

/**
 * Regular expression pattern to validate integration contact phone numbers.
 * Allows numeric characters only.
 * @type {RegExp}
 */
export const regexIntegrationContactPhone = /^[0-9\b]+$/;

/**
 * Regular expression pattern to validate integration contact names.
 * Allows alphanumeric characters and spaces.
 * @type {RegExp}
 */
export const regexIntegrationContactName = /^[a-zA-Z0-9 À-ÿ]+$/;

/**
 * Regular expression to fully validate email addresses.
 * This regex checks for standard email format:
 * - A sequence of alphanumeric characters, dots, underscores, percent signs, plus signs, or hyphens.
 * - Followed by the '@' symbol.
 * - Followed by a domain name that includes alphanumeric characters and dots.
 * - The domain name ends with a top-level domain that has at least two characters.
 * @type {RegExp}
 */
export const regexIntegrationEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/* Regular expression to validate password expiration.
 * This regex ensures the password:
 * - Contains only alphanumeric characters and special characters: -+@+(*?!#$/(){}=.,;:)
 * - Is between 8 and 50 characters in length
 * @type {RegExp}
 */
export const regexPasswordExpiration = /^[a-zA-Z0-9-+@+(*?!#$/(){}=.,;:)+]{8,50}$/;

/**
 * Regular expression to validate recovery password input.
 * This regex ensures the input:
 * - Contains only alphanumeric characters, underscores, and an @ symbol.
 * - The @ symbol must be followed by one or more alphanumeric characters.
 * @type {RegExp}
 */
export const regexRecoveryPassword = /^([A-Za-z0-9_]*@[A-Za-z0-9]+)$/;

/**
 * Regular expression to validate password expiration input.
 * This regex ensures the password:
 * - Contains only alphanumeric characters and specific special characters: -+@(*?!#$/(){}=.,;:)
 * - Is between 8 and 50 characters in length
 * @type {RegExp}
 */
export const regexChangePasswordExpiration = /^[a-zA-Z0-9-+@+(*?!#$/(){}=.,;:)+]{8,50}$/;

/**
 * Regular expression pattern for validating password expiration containing at least one numeric character.
 * @type {RegExp}
 */
export const regexPasswordExpirationNumeric = /[0-9]/;

/**
 * Regular expression pattern for validating password expiration containing at least one alphabetic character.
 * @type {RegExp}
 */
export const regexPasswordExpirationAlphabetic = /[A-Z]/;

/**
 * Regular expression pattern for validating password expiration containing at least one numeric or alphabetic character.
 * @type {RegExp}
 */
export const regexPasswordExpirationNumericAlphabetic = /[^A-Z a-z0-9]/;

/**
 * Regular expression pattern for validating password expiration containing at least one alphabetic character.
 * @type {RegExp}
 */
export const regexNumericField = /^([0-9]+)$/;

/**
 * Regular expression for validating designer workflow names.
 * This regular expression allows alphanumeric characters, spaces, accents, hyphens, and underscores.
 * It ensures that the workflow name contains only valid characters.
 * @constant
 * @type {RegExp}
 * Regular expression to validate numeric input, including negative numbers.
 * 
 * This regex matches strings that represent whole numbers, including optional negative signs.
 * 
 * - Matches positive integers (e.g., "123").
 * - Matches negative integers (e.g., "-123").
 * - Does not match floating-point numbers, non-numeric characters, or empty strings.
 * 
 * @constant {RegExp} regexNumericTextBoxNegative
 */
export const regexNumericTextBoxNegative = /^(-?[0-9]+)$/;

/**
 * Regular expression to validate numeric input, excluding negative numbers.
 * This regex matches strings that represent whole positive numbers.
 * - Matches positive integers (e.g., "123").
 * - Does not match negative numbers, floating-point numbers, non-numeric characters, or empty strings.
 * @constant {RegExp} regexNumericTextBoxPositive
 */
export const regexNumericTextBoxPositive = /^([0-9]+)$/;

/**
 * Regular expression to validate input for text areas, allowing specific characters.
 * This regex matches strings that include:
 * - Alphanumeric characters (both uppercase and lowercase).
 * - Accented characters (e.g., á, é, í, ó, ú) and special characters (e.g., ü, ñ).
 * - Common punctuation marks and symbols (#, $, &, (), , , ., _, @, s, -).
 * It is used to ensure that the input conforms to a specific set of allowed characters,
 * commonly for user-generated content or form inputs.
 * - Matches strings containing allowed characters and spaces.
 * - Does not match strings containing characters not specified in the regex.
 * 
 * @constant {RegExp} regexTextArea
 */
export const regexTextArea = /^[#$&()a-zA-Z0-9áéíóúÁÉÍÓÚüÜñÑ, ._@s-]*$/;

/**
 * Regular expression to validate labels or text input with specific character requirements.
 * This regex matches strings that include:
 * - Alphanumeric characters (both uppercase and lowercase).
 * - Spaces and plus signs (+).
 * - Extended Latin characters (e.g., À-ÿ).
 * - Hyphens (-).
 * It is used to ensure that the input adheres to a set of allowed characters,
 * commonly for labels or descriptive text.
 * - Matches strings containing allowed characters and spaces.
 * - Does not match strings containing characters not specified in the regex.
 * @constant {RegExp} regexLabelValidation
 */
export const regexLabelValidation = /^([A-Za-z0-9 +À-ÿ-]+)$/;

/**
 * Regular expression to validate numeric input with optional minimum and maximum length constraints.
 * This regex matches strings that represent non-negative integers with optional leading zeros.
 * - Matches positive integers (e.g., "1", "123").
 * - Optionally matches the absence of a number (e.g., an empty string).
 * - Does not match negative numbers or floating-point numbers.
 * It is used to ensure that the input conforms to specific numeric constraints,
 * where the number must be a positive integer or empty.
 * @constant {RegExp} regexLenCharsMinMax
 */
export const regexLenCharsMinMax = /^(?:[1-9][0-9]*)?$/;

 /* Regular expression pattern for validating Consul configuration fields label.
 * Allows alphanumeric characters, spaces, and certain special characters (-, _, .).
 * Pattern: /^([A-Za-z0-9 À-ÿ-_.]+)$/
 */
export const regexDesignerWorkflow = /^([A-Za-z0-9 À-ÿ-_]*)$/;

/**
 * Regular expression for validating designer workflow descriptions.
 * This regular expression allows alphanumeric characters, spaces, accents, hyphens, and underscores.
 * It ensures that the workflow description contains only valid characters.
 * @constant
 * @type {RegExp}
 */
export const regexDesignerWorkflowDescription = /^([A-Za-z0-9 À-ÿ-_]*)$/;

export const regexDesignerWorkflowEdit = /^([A-Za-z0-9 À-ÿ-_]*)$/;

/* Regular expression pattern for validating Consul configuration fields label.
* Allows alphanumeric characters, spaces, and certain special characters (-, _, .).
* Pattern: /^([A-Za-z0-9 À-ÿ-_.]+)$/
*/
export const regexConsulConfigurationFieldsLabel = /^([A-Za-z0-9 À-ÿ-_.]+)$/;

/**
 * Regular expression for validating field form labels.
 * This regular expression matches strings that consist of alphanumeric characters,
 * spaces, underscores, plus signs, and a range of accented characters from À to ÿ.
 * It ensures that the label conforms to a specific pattern for valid input.
 * @constant {RegExp}
 */
export const regexFieldFormLabel = /^([A-Za-z0-9 _+À-ÿ-]+)$/;

/**
 * Regular expression to validate a trash motive string.
 * This regular expression allows:
 * - Uppercase and lowercase letters (A-Z, a-z)
 * - Digits (0-9)
 * - Spaces
 * - Characters from À-ÿ
 * - Special characters: hyphen (-), underscore (_), period (.), comma (,), colon (:), and parentheses (())
 * @type {RegExp}
 */
export const regexTrashMotive = /^([A-Za-z0-9 À-ÿ-_.,:()]*)$/;

/**
 * Regular expression to validate item list names.
 * The regex matches:
 * - Uppercase and lowercase English letters (A-Z, a-z)
 * - Digits (0-9)
 * - Spaces, hyphens (-), underscores (_)
 * - Accented characters (À-ÿ)
 * @constant {RegExp}
 */
export const regexNameItemList = /^([A-Za-z0-9 À-ÿ-_]+)$/;

/**
 * Regular expression to validate item names.
 * Allows alphanumeric characters, spaces, accented characters, hyphens, and apostrophes.
 *
 * @constant {RegExp}
 * @name regexItemName
 */
export const regexItemName = /^([A-Za-z0-9 À-ÿ-']+)$/;

/* Regular expression to validate user workgroup names.
 *
 * The pattern allows:
 * - Uppercase and lowercase letters (A-Z, a-z)
 * - Digits (0-9)
 * - Spaces
 * - Accented characters (À-ÿ)
 * - Hyphens (-)
 * - Underscores (_)
 *
 * @type {RegExp}
 */
export const regexCreateUserWorkgroup = /^([A-Za-z0-9 À-ÿ-_]*)$/;

export const regexLenMinChars = /^(?:[1-9][0-9]*)?$/;
/**
 * Regular expression for validating cancel fields.
 * 
 * This regex allows:
 * - Alphanumeric characters
 * - Space characters
 * - Accented characters (À-ÿ)
 * - Special characters: - _ . , :
 * 
 * @constant {RegExp} regexCancelFiled - The regular expression for cancel field validation.
 */
export const regexCancelFiled = /^([A-Za-z0-9 À-ÿ-_.,:]*)$/;

export const regularExpresionAvFieldSubject = /^([A-Za-z0-9 À-ÿ-_.]+)$/;

/* Regular expression pattern for validating subject expedients.
* @param {string} subject - The subject expedient string to be validated.
* @returns {boolean} Returns true if the subject expedient string matches the pattern; otherwise, false.
*/
export const regexSubjectExpedient = /^([A-Za-z0-9 À-ÿ\-_ñ/()]*)$/;

/* Regular expression to validate TRD form input.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits, plus signs (+), periods (.), and hyphens (-).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * 
 * @type {RegExp}
 */
export const regexTrdForm = /^[A-Za-z0-9 À-ÿ&.-]+$/;

/**
 * Regular expression to validate subdivision form input.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits, spaces, hyphens (-), and underscores (_).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * 
 * @type {RegExp}
 */
export const regexCreateSubDivisionForm = /^([A-Za-z0-9 À-ÿ-_]+)$/;

/**
 * Regular expression to validate series form name input.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits, spaces, ampersands (&), periods (.), and hyphens (-).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * 
 * @type {RegExp}
 */
export const regexEditSerieFormName = /^([A-Za-z0-9 À-ÿ&.-]*)$/;

/**
 * Regular expression to validate series form code input.
 * 
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits, spaces, periods (.), and hyphens (-).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * 
 * @type {RegExp}
 */
export const regexEditSerieFormCode = /^([A-Za-z0-9 .-]*)$/;

/**
 * Regular expression to validate subdivision input.
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits (0-9), spaces, hyphens (-), and underscores (_).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * @type {RegExp}
 */
export const regexEditSubDivision = /^([A-Za-z0-9 À-ÿ-_]+)$/;

/**
 * Regular expression to validate subsection code input.
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits (0-9), spaces, periods (.), and hyphens (-).
 * @type {RegExp}
 */
export const regexEditSubSectionCode = /^([A-Za-z0-9 .-]*)$/;

/**
 * Regular expression to validate subsection name input.
 * This regular expression matches strings that:
 * - Contain only letters (uppercase and lowercase), digits (0-9), spaces, ampersands (&), periods (.), and hyphens (-).
 * - Allow characters from the ASCII range (A-Z, a-z, 0-9).
 * - Allow accented characters (À-ÿ).
 * @type {RegExp}
 */
export const regexEditSubSectionName = /^[A-Za-z0-9 À-ÿ&.-]*$/;

/**
 * Regular expression for validating the format of section names.
 * This regex allows alphanumeric characters, plus signs, dots, and hyphens.
 * @type {RegExp}
 */
export const regexCreateSection = /^([A-Za-z0-9 À-ÿ&.-]*)$/;

/**
 * Regular expression for validating the format of button labels for creating funds.
 * This regex allows alphanumeric characters, accented characters, spaces, plus signs,
 * ampersands, tildes, dots, and hyphens.
 * @type {RegExp}
 */
export const regexButtonCreateFund = /^([A-Za-z0-9À-ÿ _+&ñ.-]+)$/;

/**
 * Regular expression for validating the format of background edit labels.
 * This regex allows alphanumeric characters, accented characters, spaces, dots, hyphens,
 * ampersands, and single quotes.
 *
 * @type {RegExp}
 */
export const regexEditBackground = /^([A-Za-z0-9 À-ÿ-.&_']+)$/;

export const regexDateRecord = /^\d{1,10}-\d{4}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])-\d{1,10}$/;

export const regexDataRecordEnds = /^\d{0,10}-{0,1}\d{0,8}-{0,1}\d{0,10}$/;

export const regexDataText = /^[a-zA-Z0-9 À-ÿ_-]+$/;

export const regexDataNumber =  /^([0-9]+)$/;
/**
 * Regular expression pattern for validating item names.
 * This regular expression pattern allows alphanumeric characters, accented characters,
 * spaces, hyphens, apostrophes, and underscores. It is case insensitive.
 * @constant {RegExp}
 */
export const regexEditItemName=  /^([A-Za-z0-9 À-ÿ-]+)$/;
