import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_ROLE } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useRoles() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getAllUserAndRoles = async (page, per_page, searching) => {
    const response = await axios.get(
      `${URL_ROLE}/roles/?name=${searching}&page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.roles.getAllUserAndRoles
      }
    );
    return response.data;
  };

  const createRole = async (name) => {
    const response = await axios.post(`${URL_ROLE}/roles/`, name,
      {
        headers,
        endpointError: endpointError.roles.createRole
      });
    return response;
  };

  const getRole = async (id) => {
    const response = await axios.get(`${URL_ROLE}/roles/${id}`,
      {
        headers,
        endpointError: endpointError.roles.getRole
      });
    return response.data;
  };

  const editRole = async (id, name) => {
    const response = await axios.patch(`${URL_ROLE}/roles/${id}/`, name,
      {
        headers,
        endpointError: endpointError.roles.editRole
      });
    return response;
  };

  const deleteRole = async (id) => {
    const response = await axios.delete(`${URL_ROLE}/roles/${id}`,
      {
        headers,
        endpointError: endpointError.roles.deleteRole
      });
    return response;
  };

  const getUsersByRol = async (idRol) => {
    const response = await axios.get(`${URL_ROLE}/users/roles/${idRol}/`,
      {
        headers,
        endpointError: endpointError.roles.getUsersByRol
      });
    return response;
  };

  const getMembers = async (idRol, page, per_page) => {
    const response = await axios.get(`${URL_ROLE}/roles/${idRol}/members/?page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.roles.getMembers
      });
    return response;
  };

  const addMembersByRol = async (idRol, addMembers) => {
    const response = await axios.post(
      `${URL_ROLE}/roles/${idRol}/members/`,
      addMembers,
      {
        headers,
        endpointError: endpointError.roles.addMembersByRol
      }
    );
    return response;
  };

  const deleteMember = async (idRol, idMember) => {
    const response = await axios.delete(
      `${URL_ROLE}/roles/${idRol}/members/${idMember}`,
      {
        headers,
        endpointError: endpointError.roles.deleteMember
      }
    );
    return response;
  };

  const getFormsByRol = async (id) => {
    const response = await axios.get(`${URL_ROLE}/roles/${id}/forms/`,
      {
        headers,
        endpointError: endpointError.roles.getFormsByRol
      });
    return response;
  };

  const addPermissionsByRol = async (id, permissionsForm) => {
    const response = await axios.post(
      `${URL_ROLE}/roles/${id}/form-permissions/`,
      permissionsForm,
      {
        headers,
        endpointError: endpointError.roles.addPermissionsByRol
      }
    );
    return response;
  };

  const updatePermissionsByRol = async (id, permissionsForm) => {
    const response = await axios.patch(
      `${URL_ROLE}/roles/${id}/form-permissions/`,
      permissionsForm,
      {
        headers,
        endpointError: endpointError.roles.updatePermissionsByRol
      }
    );
    return response;
  };

  const addPermissionsConfig = async (id, permissionsConfig) => {
    const response = await axios.post(
      `${URL_ROLE}/roles/${id}/permissions/`,
      permissionsConfig,
      {
        headers,
        endpointError: endpointError.roles.addPermissionsConfig
      }
    );
    return response;
  };

  const addPermissionsDesignerForms = async (
    rolId,
    permissionsDesignerForms
  ) => {
    const response = await axios.post(
      `${URL_ROLE}/roles/${rolId}/admin-forms/`,
      permissionsDesignerForms,
      {
        headers,
        endpointError: endpointError.roles.addPermissionsDesignerForms
      }
    );
    return response;
  };

  const addPermissionsDesignerWorkflows = async (
    rolId,
    permissionsDesignerWorkflows
  ) => {
    const response = await axios.post(
      `${URL_ROLE}/roles/${rolId}/admin-workflows/`,
      permissionsDesignerWorkflows,
      {
        headers,
        endpointError: endpointError.roles.addPermissionsDesignerWorkflows
      }
    );
    return response;
  };

  const getPermissionsFormsByRol = async (id, page, per_page) => {
    const response = await axios.get(
      `${URL_ROLE}/roles/${id}/form-permissions/?page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.roles.getPermissionsFormsByRol
      }
    );
    return response;
  };

  const getPermissionsConfigByRol = async (id) => {
    const response = await axios.get(`${URL_ROLE}/roles/${id}/permissions/`,
      {
        headers,
        endpointError: endpointError.roles.getPermissionsConfigByRol
      });
    return response;
  };

  const getPermissionsAdminFormsByRol = async (id) => {
    const response = await axios.get(`${URL_ROLE}/roles/${id}/admin-forms/`,
      {
        headers,
        endpointError: endpointError.roles.getPermissionsAdminFormsByRol
      });
    return response;
  };

  const getPermissionsAdminWorkflowsByRol = async (id) => {
    const response = await axios.get(
      `${URL_ROLE}/roles/${id}/admin-workflows/`,
      {
        headers,
        endpointError: endpointError.roles.getPermissionsAdminWorkflowsByRol
      }
    );
    return response;
  };

  const deleteFormPremissionByRol = async (uuidRol, uuidForm) => {
    const response = await axios.delete(
      `${URL_ROLE}/roles/${uuidRol}/forms/${uuidForm}/form-permissions/`,
      {
        headers,
        endpointError: endpointError.roles.deleteFormPremissionByRol
      }
    );
    return response;
  };

  return {
    getAllUserAndRoles,
    createRole,
    getRole,
    editRole,
    deleteRole,
    getUsersByRol,
    getMembers,
    addMembersByRol,
    deleteMember,
    getFormsByRol,
    addPermissionsByRol,
    addPermissionsConfig,
    addPermissionsDesignerForms,
    addPermissionsDesignerWorkflows,
    getPermissionsFormsByRol,
    getPermissionsConfigByRol,
    getPermissionsAdminFormsByRol,
    getPermissionsAdminWorkflowsByRol,
    updatePermissionsByRol,
    deleteFormPremissionByRol,
  };
}
