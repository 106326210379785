import React, { Fragment, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import CreateExpedientForm from "components/organism/CreateExpedientForm";
import i18n from "locales/i18n";

const ButtonCreateExpedient = (props) => {
  const { formUuid, recordUuid, expedientComeFrom } = props;
  const [showModalCreateExpedient, setShowModalCreateExpdient] = useState(false);

  /**
   * Handles the click event by setting the state to show a modal.
   * @param {Function} setShowModalCreateExpdient - State setter function to control the visibility of the modal.
   * @returns {void}
   */
  const handleOpenModalCreateExpdient = () => {
    setShowModalCreateExpdient(true);
  };

  /**
   * Handles the action to close the modal by setting the showModalCreateExpedient state to false.
   * @param {function} setShowModalCreateExpdient - State setter function for controlling the modal visibility.
   * @returns {void}
   */
  const handleOnClose = () => {
    setShowModalCreateExpdient(false);
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              onClick={handleOpenModalCreateExpdient}
            >
              <span>{i18n.t("trd.formTittle6")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModalCreateExpedient}
          toggle={handleOnClose}
          className="modal-medium-size"
        >
          <ModalHeader>
            <Label>{i18n.t("trd.formTittle6")}</Label>
          </ModalHeader>
          <ModalBody>
            <CreateExpedientForm
              formUuid={formUuid}
              recordUuid={recordUuid}
              expedientComeFrom={expedientComeFrom}
              handleOnClose={handleOnClose}
            />
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ButtonCreateExpedient.propTypes = {
  formUuid: PropTypes.string.isRequired,
  recordUuid: PropTypes.string,
  expedientComeFrom: PropTypes.number.isRequired,
};

export default ButtonCreateExpedient;
