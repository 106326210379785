import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const FilterUserState = (props) => {
  const { setUserStatus, pagination, setPagination } = props;
  /**
   *Function that handles the onChange event of a select input and updates the status and pagination properties accordingly.
   *@param {object} eventFilterUser - the event object
   */
  const handleOnChangeFilterUser = (eventFilterUser) => {
    setUserStatus(eventFilterUser.target.value);
    setPagination({ ...pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("filteruser.button")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem header>{i18n.t("filteruser.header")}</DropdownItem>
              <DropdownItem value={""} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={1} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item2")}
              </DropdownItem>
              <DropdownItem value={3} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item3")}
              </DropdownItem>
              <DropdownItem value={2} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item4")}
              </DropdownItem>
              <DropdownItem value={5} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item7")}
              </DropdownItem>
              <DropdownItem value={4} onClick={handleOnChangeFilterUser}>
                {i18n.t("filteruser.item5")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterUserState.propTypes = {
  setUserStatus: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired
};

export default FilterUserState;
