import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const ButtonActions = (props) => {
  const { userId, userUuid } = props;
  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link to={`/user/edit/${userId}`}>
            <i className="dropdown-icon lnr-pencil"> </i>
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/user/edit/status/${userId}`}>
            <i className="dropdown-icon lnr-users"></i>
            <span>{i18n.t("editStatus.change")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/user/log/${userUuid}`}>
            <i className="dropdown-icon lnr-user"> </i>
            <span>{i18n.t("User.log")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActions.propTypes = {
  userId: PropTypes.number.isRequired,
  userUuid: PropTypes.string.isRequired, 
};

export default ButtonActions;
