import React from "react";
import { useFilterContext } from "contextAPI/FilterContext";
import { getDateFormat2 } from "utils/getDateFormat2";
import { enumsFilterForms } from "utils/enums";
import i18n from "locales/i18n";

const FiltersApplyByFiltersFormsRecords = () => {
  const { queriesRec, queryFieldsTemp, dataRecordLookup } = useFilterContext();
  const orderedFieldsToShow = [
    enumsFilterForms.OPERTATOR,
    enumsFilterForms.RECORD,
    enumsFilterForms.FILTER,
    enumsFilterForms.DATA,
  ];
  let filterText = "";
  let FilterDataTypeControl = null;
  let consultedData = "N/A";
  let DataTypeControl = null;
  let DataTypeControlModified = null;
  let nameFieldToShow = null;
  let dataLookupToShow = null;
  let titleAppliedFilers = null;

  /**
   * Conditionally renders a title for applied filters based on the length of the search filter.
   * @param {Object} queriesRec - The object containing the search filters.
   * @param {Array} queriesRec.search_filter - An array representing the search filter.
   * @param {string} i18n.t - A function for internationalization and translation.
   * @returns {JSX.Element|null} - The rendered title for applied filters if the search filter is not empty; otherwise, null.
   */
  if (queriesRec.search_filter.length !== 0) {
    titleAppliedFilers = (
      <h6 className="pl-0 text-info font-weight-bold">
        {i18n.t("filterQuery.apply")}
      </h6>
    );
  }

  /**
   * Generates an array of JSX elements based on the provided filters for displaying labels in a forms filter section.
   * @param {Array} queriesRec.search_filter - An array of filters to be processed and displayed.
   * @param {Array} queryFieldsTemp - Temporary array holding information about available fields.
   * @param {Array} dataRecordLookup - Array containing data record lookups.
   * @param {Array} orderedFieldsToShow - Array specifying the order in which fields should be displayed.
   * @param {Object} enumsFilterForms - Enumerations related to filter forms.
   * @param {Function} i18n.t - A translation function for translating text.
   * @param {Function} getDateFormat2 - A function for formatting dates.
   * @returns {Array} An array of JSX elements representing the labels for the specified filters.
   */
  const labelsForFiltersForms = queriesRec.search_filter.map((filter) => {
    FilterDataTypeControl =
      filter["filter"] !== null &&
      filter["data_type"] === 3 &&
      filter["data_control"] === 6;
    DataTypeControl = filter["data_type"] === 4 && filter["data_control"] === 7;
    DataTypeControlModified =
      filter["data_type"] === 2 && filter["data_control"] === 5;
    nameFieldToShow = queryFieldsTemp
      .filter((field) => field.value === filter["field_uuid"])
      .map((field) => field.label);

    dataLookupToShow = dataRecordLookup.find(
      (field) => field.uuid === filter["value"]
    );

    if (dataLookupToShow) {
      consultedData = dataLookupToShow.value;
    }

    return orderedFieldsToShow.map((enumField, innerIndex) => {
      if (enumField === enumsFilterForms.OPERTATOR) {
        if (filter["operator"] === null) {
          return (
            <span key={enumField}>
              <strong className="font-weight-bold">{enumField}: </strong>
              <span className="text-focus">N/A</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        }

        if (filter["operator"] === "and") {
          return (
            <span key={enumField}>
              <strong className="font-weight-bold">{enumField}: </strong>
              <span className="text-focus">
                {i18n.t("filterQuery.operatorAnd")}
              </span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        } else {
          return (
            <span key={enumField}>
              <strong className="font-weight-bold">{enumField}: </strong>
              <span className="text-focus">
                {i18n.t("filterQuery.operatorOR")}
              </span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        }
      }

      if (enumField === enumsFilterForms.RECORD) {
        if (filter["data_type"] === 5) {
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">
                {i18n.t("filterQuery.Field1Option1")}
              </span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        } else {
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">{nameFieldToShow}</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        }
      }

      if (enumField === enumsFilterForms.FILTER) {
        if (
          FilterDataTypeControl ||
          DataTypeControl ||
          DataTypeControlModified
        ) {
          switch (filter["filter"]) {
            case 1:
              filterText = <span>{i18n.t("filterQuery.Field2Option1")}</span>;
              break;
            case 2:
              filterText = <span>{i18n.t("filterQuery.Field2Option3")}</span>;
              break;
            case 3:
              filterText = <span>{i18n.t("filterQuery.Field2Option4")}</span>;
              break;
            case 4:
              filterText = <span>{i18n.t("filterQuery.Field2Option5")}</span>;
              break;
            case 5:
              filterText = <span>{i18n.t("filterQuery.Field2Option6")}</span>;
              break;
            case 6:
              filterText = <span>{i18n.t("filterQuery.Field2Option7")}</span>;
              break;
            default:
              break;
          }
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">{filterText}</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        } else {
          if (filter["filter"] !== null) {
            switch (filter["filter"]) {
              case 1:
                filterText = <span>{i18n.t("filterQuery.Field2Option1")}</span>;
                break;
              case 2:
                filterText = <span>{i18n.t("filterQuery.Field2Option3")}</span>;
                break;
              case 3:
                filterText = <span>{i18n.t("filterQuery.Field2Option8")}</span>;
                break;
              case 4:
                filterText = <span>{i18n.t("filterQuery.Field2Option2")}</span>;
                break;
              case 5:
                filterText = <span>{i18n.t("filterQuery.Field2Option9")}</span>;
                break;

              default:
                break;
            }
            return (
              <span key={enumField}>
                <strong>{enumField}: </strong>
                <span className="text-focus">{filterText}</span>
                {innerIndex < orderedFieldsToShow.length - 1 && ", "}
              </span>
            );
          }
        }
      }

      if (enumField === enumsFilterForms.DATA) {
        if (filter["value"] !== null && filter["data_control"] === 8) {
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">{consultedData}</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        } else if (filter["value"] !== null && filter["data_control"] === 7) {
          let isValueFinalDate = getDateFormat2(new Date(filter["value"]));
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">{isValueFinalDate}</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        } else if (filter["value"] !== null) {
          return (
            <span key={enumField}>
              <strong>{enumField}: </strong>
              <span className="text-focus">{filter["value"]}</span>
              {innerIndex < orderedFieldsToShow.length - 1 && ", "}
            </span>
          );
        }
      }

      if (enumField !== "data_control" && enumField !== "field_uuid") {
        return (
          <span key={enumField}>
            <strong>{enumField}: </strong>
            {filter[enumField]}
            {innerIndex < orderedFieldsToShow.length - 1 && ", "}
          </span>
        );
      }

      return null;
    });
  });

  return (
    <div>
      {titleAppliedFilers}

      <ol className="pl-3">
        {labelsForFiltersForms.map((filterApplied) => (
          <li key={"formsQuery"} className="text-info">
            {filterApplied}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default FiltersApplyByFiltersFormsRecords;
