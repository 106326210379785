import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const ButtonActionsDocumentaryTypes = (props) => {

/**
 * Componente de botones de acciones para tipos de documentales.
 * @param {Object} props - Las props del componente.
 * @param {string} props.documentaryName - El nombre del documental.
 * @param {string} props.documentaryId - El ID del documental.
 * @returns {JSX.Element} - El elemento JSX que representa el componente.
 */

  const {
    documentaryName,
    documentaryId
  } = props;

  const documentaryHash = {
    name: documentaryName
  }

  const objectDocumentaryType = JSON.stringify(documentaryHash)
  const hashDocumentaryName = window.btoa(objectDocumentaryType)

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link to={`/documentary-types/list/${documentaryId}/${hashDocumentaryName}`}>
            <i className="dropdown-icon lnr-pencil" />
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>

      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsDocumentaryTypes.propTypes = {
  documentaryName: PropTypes.string.isRequired,
  documentaryId: PropTypes.string.isRequired
};

export default ButtonActionsDocumentaryTypes;
