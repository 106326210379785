import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import SignatoriesViewList from "components/organism/SignatoriesViewList";
import i18n from "locales/i18n";

const Signatories = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("signatories.ViewHeader")}
        subheading={i18n.t("signatories.ViewSubHeader")}
        icon="note2"
      >
        <SignatoriesViewList />
      </AdminTemplate>
    </Fragment>
  );
};

export default Signatories;
