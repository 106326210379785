import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { emptyBpmn } from "./emptyBpmn/emptyBpmn";
import { showAlertServiceError } from "utils/alerts";
import "bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
import bpmnlintConfig from "../../../bundled-config";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import BpmnModeler from "bpmn-js/lib/Modeler";
import lintModule from "bpmn-js-bpmnlint";
import PaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider";
import customModule from "./custom";
import customContextPadProvider from "./customContextPadProvider";
import useWorkFlowsDiagram from "hooks/useWorkflowsDiagram";
import Swal from "sweetalert";
import i18n from "locales/i18n";

const BpmnComponent = () => {
  const { id } = useParams();
  const { upload_xml } = useWorkFlowsDiagram();
  const [activateLabelSave] = useState(false);
  const [activeLabelValidator, setActiveLabelValidator] = useState(false);
  const [validateDiagramBpmn, setValidate] = useState(false);
  const [disabledSaveDiagram, setDisabledSaveDiagram] = useState(false);

  const bpmContainerRef = useRef();
  const modelerRef = useRef(null);
  var _getPaletteEntries = PaletteProvider.prototype.getPaletteEntries;

  let message = null;
  let disabledBpmnModeler = null;
  let labelClick = null;
  let renderValidateModeler = null;

  /**
   * Sets the `renderValidateModeler` variable based on the value of `activeLabelValidator`.
   * This code assigns a JSX `<div>` element containing a `<label>` to the `renderValidateModeler` variable
   * if `activeLabelValidator` is `true`. The `<div>` is styled to center its content, and the `<label>` is positioned
   * absolutely with specific styles. The text for the `<label>` is retrieved from the `i18n` object using the key
   * "createWorkflowDesign.labelValidate". If `activeLabelValidator` is `false`, `renderValidateModeler` is not set.
   * @param {boolean} activeLabelValidator - A boolean flag indicating whether the validation label should be rendered.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {JSX.Element|undefined} - Returns a JSX `<div>` element with a styled `<label>` containing translated text if `activeLabelValidator` is `true`; otherwise, returns `undefined`.
   */
  if (activeLabelValidator === true) {
    renderValidateModeler = (
      <div className="bpmn-modeler-validator">
        <label
          className="bpmn-modeler-label"
        >
          {i18n.t("createWorkflowDesign.labelValidate")}
        </label>
      </div>
    )
  };

  /**
   * Sets the `labelClick` variable based on the value of `activateLabelSave`.
   * This code assigns a JSX `<label>` element with a translated text to the `labelClick` variable
   * if `activateLabelSave` is `true`. The text is retrieved from the `i18n` object using the key 
   * "createWorkflow.click.button". If `activateLabelSave` is `false`, `labelClick` is not set.
   * @param {boolean} activateLabelSave - A boolean flag indicating whether the label should be activated or not.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {JSX.Element|undefined} - Returns a JSX `<label>` element with translated text if `activateLabelSave` is `true`; otherwise, returns `undefined`.
   */
  if (activateLabelSave === true) {
    labelClick = (
      <label>{i18n.t("createWorkflow.click.button")}</label>
    )
  };

  /**
   * Sets the `disabledBpmnModeler` variable based on the value of `validateDiagramBpmn`.
   * This code updates the `disabledBpmnModeler` variable to `false` if `validateDiagramBpmn` is `false`, 
   * and to `true` if `validateDiagramBpmn` is `true`. It effectively sets the disabledSaveDiagram state of the BPMN modeler 
   * based on the validation status.
   * @param {boolean} validateDiagramBpmn - A boolean flag indicating whether validation has passed or failed.
   * @returns {boolean} - The value of `disabledBpmnModeler`, which is `true` if `validateDiagramBpmn` is `true`, otherwise `false`.
   */
  if (validateDiagramBpmn === false) {
    disabledBpmnModeler = false;
  } else {
    disabledBpmnModeler = true;
  }

  /**
   * Sets the `message` variable based on the value of `disabledSaveDiagram`.
   * This code sets the `message` variable to `false` if `disabledSaveDiagram` is `true`, 
   * and to `true` if `disabledSaveDiagram` is `false`. It effectively inverts the value
   * of the `disabledSaveDiagram` flag.
   * @param {boolean} disabledSaveDiagram - A boolean flag indicating whether an element is disabledSaveDiagram.
   * @returns {boolean} - The value of `message` which is `true` if `disabledSaveDiagram` is `false`, otherwise `false`.
   */
  if (disabledSaveDiagram === true) {
    message = false;
  } else {
    message = true;
  }

  /**
   * Customizes the palette entries by removing specific items.
   * This method modifies the palette entries by removing predefined tools and elements from the palette.
   * It first retrieves the default palette entries using `_getPaletteEntries`, then deletes specific entries
   * such as task creation tools, separators, and various event and gateway types.
   * @param {Object} element - The element for which the palette entries are being customized. This parameter is not used
   * in the current implementation but is included for consistency with the method signature.
   * @returns {Object} - The modified palette entries object with specific entries removed.
   */
  PaletteProvider.prototype.getPaletteEntries = function (element) {
    var entries = _getPaletteEntries.apply(this);
    delete entries["create.task"];
    delete entries["tool-separator"];
    delete entries["create.group"];
    delete entries["create.data-store"];
    delete entries["lasso-tool"];
    delete entries["space-tool"];
    delete entries["create.exclusive-gateway"];
    delete entries["create.intermediate-event"];
    delete entries["create.end-event"];
    delete entries["create.terminate-end-event"];
    delete entries["create.participant-expanded"];
    delete entries["create.subprocess-expanded"];
    delete entries["create.data-object"];
    return entries;
  };

  /**
   * Validates the BPMN diagram using the provided modeler instance.
   * This asynchronous function triggers the validation process for the BPMN diagram
   * by setting the `validateDiagramBpmn` and `activeLabelValidator` states to true. It listens for the
   * `linting.completed` event emitted by the modeler, and updates the states based
   * on the results of the validation. If no issues are found in the event, it sets
   * the `disabledSaveDiagram` state to true.
   * @param {Object} modeler - An instance of BpmnModeler used for diagram validation.
   * @param {Function} setValidate - A function to set the validation state.
   * @param {Function} setActiveLabelValidator - A function to set the active label state.
   * @param {Function} setDisabledSaveDiagram - A function to set the disabledSaveDiagram state.
   * @returns {Promise<void>} - This function returns a promise that resolves when the validation setup is complete.
   */
  const validateDiagram = async (modeler) => {
    setValidate(true);
    setActiveLabelValidator(true);
    modeler.on("linting.completed", function (event) {
      setActiveLabelValidator(false);
      if (event.type === "linting.completed") {
        if (Object.keys(event.issues).length === 0) {
          setDisabledSaveDiagram(true);
        }
      }
    });
  };

  /**
   * Saves the BPMN diagram and handles the validation and upload process.
   * This asynchronous function saves the current BPMN diagram as XML, sets up an event listener for
   * the `linting.completed` event to handle validation results, and displays appropriate alerts to the user.
   * If there are no issues found during validation, it prompts the user with a confirmation dialog,
   * and if confirmed, uploads the diagram XML file. If the upload is successful, the user is redirected
   * to the workflow list page. If validation issues are found, an error message is shown.
   * @param {Object} modeler - An instance of `BpmnModeler` used for saving and validating the BPMN diagram.
   * @param {Function} setDisabledSaveDiagram - A function to update the disabledSaveDiagram state of the save button.
   * @param {Function} showAlertServiceError - A function to display an error alert if the upload fails.
   * @param {Function} upload_xml - A function to handle the XML upload process.
   * @param {string} id - The ID used for the upload request.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {Promise<void>} - This function returns a promise that resolves when the save and upload process is complete.
   */
  const saveDiagram = async (modeler) => {
    const { xml } = await modeler.saveXML();
    modeler.on("linting.completed", function (event) {
      if (Object.keys(event.issues).length === 0) {
        setDisabledSaveDiagram(false);
        Swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createWorkflowDesign.modalRequest"),
          icon: "warning",
          buttons: [
            i18n.t("createWorkflowDesign.cancel"),
            i18n.t("createWorkflowDesign.saveModal"),
          ],
        }).then((result) => {
          if (result) {
            let formData = new FormData();
            const file = new File([xml], "diagrama.xml", {
              type: "text/xml",
              lastModified: new Date().getTime(),
            });
            formData.append("file", file);
            formData.append("size", file.size);
            upload_xml(formData, id)
              .then((res) => {
                if (res.data.code === 5015) {
                  Swal({
                    title: i18n.t("modal.DoneError.header"),
                    text: i18n.t("createWorkflowDesign.modalSuccess"),
                    icon: "success",
                    button: i18n.t("modal.Done.footerButton"),
                  }).then(() => {
                    window.location = `/workflow/list`;
                  });
                } else {
                  showAlertServiceError();
                }
              })
          } else {
            setDisabledSaveDiagram(true);
          }
        });
      }
      if (Object.keys(event.issues).length > 0) {
        Swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createWorkflowDesign.modalError"),
          icon: "error",
          button: i18n.t("modal.Done.footerButton"),
        });
      }
    });

    Swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("createWorkflowDesign.validateFinish"),
      icon: "info",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Initializes and configures the BpmnModeler instance when the component mounts.
   * This effect creates a new instance of BpmnModeler, attaches it to a DOM container, and configures
   * various settings including keyboard bindings, linting rules, additional modules, and properties panel.
   * It also imports an empty BPMN XML and adjusts the canvas view to fit the viewport.
   * @function
   * @returns {void}
   */
  useEffect(() => {
    const modeler = (modelerRef.current = new BpmnModeler({
      container: bpmContainerRef.current,
      keyboard: {
        bindTo: window,
      },
      linting: {
        bpmnlint: bpmnlintConfig,
      },
      additionalModules: [customModule, customContextPadProvider, lintModule],
      propertiesPanel: {
        parent: "#propview",
      },
    }));

    modeler
      .importXML(emptyBpmn)
      .then(() => {
        const canvas = modeler.get("canvas");
        canvas.zoom("fit-viewport");
      })
  }, []);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Row>
            <Button
              onClick={() => saveDiagram(modelerRef.current)}
              disabled={message}
              className="ml-3  bpmn-modeler-button"
              color="primary"
            >
              {i18n.t("createWorkflowDesign.save")}
            </Button>
            {labelClick}
            <Button
              onClick={() => validateDiagram(modelerRef.current)}
              disabled={disabledBpmnModeler}
              className="col-mt-3 ml-3"
              color="success"
            >
              {i18n.t("createWorkflowDesign.validate")}
            </Button>

            <div className=" col-mt-1 mr-3 btn-actions-pane-right">
              <Button
                color="danger"
                onClick={() => {
                  window.location = "/WorkFlow/list";
                }}
              >
                <i className="lnr-icon lnr-cross"></i>
              </Button>
            </div>
          </Row>
        </div>

        <div
          className="bpmn-modeler-view"
          id="bpmncontainer"
          ref={bpmContainerRef}
        >
          {renderValidateModeler}
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default BpmnComponent;
