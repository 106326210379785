import React, { useState, useCallback, useEffect } from "react";
import {
  Label,
  CardBody,
} from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationForms } from "utils/initialPaginationsConfig";
import { sortByPropertyName } from "utils/sortHelpers";
import makeAnimated from "react-select/lib/animated";
import Select from "react-select";
import useWorkflow from "hooks/useWorkFlow";
import i18n from "locales/i18n";

const WorkflowsSelectByFiltersTasks = () => {
  const {
    selectedOptionsWorkflow,
    setSelectedOptionsWorkflow,
    workflowDinamic,
    setWorkflowDinamic,
    selectedOptions,
    setStructureQueryByFilterTask,
    structureQueryByFilterTask,
    setSelectedOptionsStep,
  } = useWorkflowContext();

  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(false);
  const { getWorkFlows, getWorkFlowsByForm } = useWorkflow();
  const [pagination] = useState(initialPaginationForms);
  const [search] = useState("");

  let selectWorkflowDisable = false;
  let textPlacheholder = "";

  /**
   * Determines the text based on the loading status of workflow.
   *
   * If workflow are currently being loaded (`isLoadingForms` is true),
   * it sets the text to a loading message. Otherwise, it sets the text
   * to a default message for record data.
   * @param {boolean} isLoadingForms - Indicates whether workflow are currently being loaded.
   * @param {string} text - The resulting text based on the loading status.
   * @returns {void}
   */
  if (isLoadingWorkflows === true) {
    selectWorkflowDisable = true;
    textPlacheholder = i18n.t("loading");
  } else {
    textPlacheholder = i18n.t("taskFilter.label7");
  }

  /**
   * Fetches workflows based on the selected form ID.
   * Updates the workflow dynamic state with the fetched workflows.
   * @param {string} selectFormId - The ID of the selected form.
   * @returns {void}
   */
  const getWorkflowsByForms = useCallback((selectFormId) => {
    setWorkflowDinamic([]);
    setIsLoadingWorkflows(true);
    getWorkFlowsByForm(selectFormId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          let listWorkflows = response.data.data.items;
          const orderWorkflowsList = sortByPropertyName(listWorkflows, "name");
          orderWorkflowsList.forEach((workflow) => {
            setWorkflowDinamic((prevState) => [
              ...prevState,
              {
                value: workflow.uuid,
                label: workflow.name,
              },
            ]);
          });
        } else {
          showAlertServiceError();
        }
      }).finally(() => {
        setIsLoadingWorkflows(false);
      })
  }, [selectedOptions]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches active workflows based on pagination and search parameters.
   * Updates the workflow dynamic state with the fetched workflows.
   * @returns {void}
   */
  const getActiveWorkflows = useCallback(() => {
    setWorkflowDinamic([]);
    const { page, per_page } = pagination;
    setIsLoadingWorkflows(true);
    getWorkFlows(page, per_page, search)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          let listWorkflows = response.data.items;
          const orderWorkflowsList = sortByPropertyName(listWorkflows, "name")
          orderWorkflowsList.forEach((workflow) => {
            setWorkflowDinamic((prevState) => [
              ...prevState,
              {
                value: workflow.uuid,
                label: workflow.name,
              },
            ]);
          });
        } else {
          showAlertServiceError();
        }
      }).finally(() => {
        setIsLoadingWorkflows(false);
      })
  }, [pagination, search]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for the workflows dropdown.
   * Updates the selected workflow and the structure query filter task accordingly.
   * @param {Object} selectedWorkflow - The selected workflow object.
   * @param {string|null} selectedWorkflow.value - The value of the selected workflow.
   * @param {string} selectedWorkflow.label - The label of the selected workflow.
   * @returns {void}
   */
  const handleOnChangeWorkflows = (selectedWorkflow) => {
    setSelectedOptionsWorkflow(selectedWorkflow);
    setSelectedOptionsStep("");
    if (selectedWorkflow !== null) {
      setStructureQueryByFilterTask({
        ...structureQueryByFilterTask,
        workflow_uuid: selectedWorkflow.value,
      });
    } else {
      setSelectedOptionsWorkflow({ value: null, label: i18n.t("taskFilter.label7") })
      setStructureQueryByFilterTask({
        ...structureQueryByFilterTask,
        workflow_uuid: "",
        step_uuid: "",
      });
    }
  };

  /**
   * useEffect hook to fetch active forms when no form is selected from context.
   * @param {string} selectedFormIdContext - The selected form ID from context.
   * @param {Function} getActiveForms - Function to fetch active forms.
   */
  useEffect(() => {
    if (isNullOrUndefined(selectedOptions.value) === true) {
      getActiveWorkflows();
    } else {
      getWorkflowsByForms(selectedOptions.value);
    }
  }, [selectedOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardBody className="pt-0">
      <div className="mb-4">
        <Label>
          {i18n.t("taskDetailType2")}
        </Label>
        <Select
          id="workflow"
          name="workflow"
          maxMenuHeight={120}
          menuPlacement="auto"
          isSearchable={true}
          isClearable={true}
          hideSelectedOptions={true}
          placeholder={textPlacheholder}
          closeMenuOnSelect={true}
          components={makeAnimated()}
          isDisabled={selectWorkflowDisable}
          value={selectedOptionsWorkflow}
          options={workflowDinamic}
          onChange={handleOnChangeWorkflows}
          noOptionsMessage={() => i18n.t("taskBatchProcess.text6")}
        />
      </div>
    </CardBody>
  );
};

export default WorkflowsSelectByFiltersTasks;