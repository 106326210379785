import React from "react";
import WorkFlowSteps from "./WorkFlowSteps";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";

export default () => {
  return (
    <WorkflowContextProvider>
      <WorkFlowSteps />
    </WorkflowContextProvider>
  );
};
