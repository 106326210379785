import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import ButtonActionsExp from "components/atoms/ButtonActionsExp";
import i18n from "locales/i18n";

const TrdExpedientList = (props) => {
  const {
    listExpedients,
    pagination,
    setPagination,
    isLoading,
    totalPages,
    backgroundId,
    ultimateLevel,
  } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={listExpedients}
          columns={[
            {
              columns: [
                {
                  Header: i18n.t("trdExpedientList1"),
                  accessor: "code",
                  Cell: ({ original }) => {
                    let codeValue = original.code;
                    const expedientCode = codeValue.slice(
                      codeValue.lastIndexOf("-") + 1
                    );
                    return (
                      <div className="d-flex justify-content-center">
                        <Button
                          outline
                          className="mb-2 mr-2 btn-pill btn-dashed"
                          color="info"
                          disabled
                        >
                          <span className="badge-pill text-bold">
                            {expedientCode}
                          </span>
                        </Button>
                      </div>
                    );
                  },
                },
                {
                  Header: i18n.t("trdExpedientList2"),
                  accessor: "name",
                },
                {
                  Header: i18n.t("trdExpedientList3"),
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: i18n.t("form.field47"),
                  accessor: "updated_at",
                  Cell: ({ value }) => {
                    if (value) {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    } else {
                      let valueFinal = "";
                      return <span>{valueFinal}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("trdExpedientList4"),
                  accessor: "subject",
                },
                {
                  Header: i18n.t("trdExpedientList5"),
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";

                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("trdExpedientList9")}
                        </div>
                      );
                    } else {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("trdExpedientList10")}
                        </div>
                      );
                    }

                    return (
                      <span className="d-block text-center">{statusFinal}</span>
                    );
                  },
                },
                {
                  Header: i18n.t("trdExpedientList6"),
                  accessor: "close_date",
                  Cell: ({ value }) => {
                    if (value) {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    } else {
                      let valueFinal = "";
                      return <span>{valueFinal}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("trd.expedient"),
                  accessor: "has_subdivision",
                  Cell: ({ value }) => {
                    let statusFinal = "";

                    if (value === true) {
                      statusFinal = "Si contiene";
                    } else {
                      statusFinal = "No contiene";
                    }
                    return <span>{statusFinal}</span>;
                  },
                },
              ],
            },
            {
              columns: [
                {
                  Header: i18n.t("buttonActions.title"),
                  accessor: "actions",
                  Cell: ({ row }) => {
                    return (
                      <ButtonActionsExp
                        backgroundId={backgroundId}
                        ultimateLevel={ultimateLevel}
                        expedientId={row._original.uuid}
                        expedientName={row._original.name}
                        expedientSubject={row._original.subject}
                        expedientStatus={row._original.status}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          className="-striped -highlight"
          manual
          pages={totalPages}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          loading={isLoading}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrdExpedientList.propTypes = {
  listExpedients: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  backgroundId: PropTypes.string.isRequired,
};

export default TrdExpedientList;