import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Col,
  Card,
  CardFooter,
  CardBody,
  CardHeader,
  Label,
  Spinner,
  CardTitle,
} from "reactstrap";
import { Loader } from "react-loaders";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { arrayGreaterThanZero, isNullOrUndefined } from "utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { showAlert } from "components/molecules/Alert/Alert";
import { showAlertServiceError } from "utils/alerts";
import { enumsFields, enumsFormStatus, enumsPermissionsAction } from "utils/enums";
import { paginationFieldsWorkflowFiling, paginationFormsWorkflow } from "utils/initialPaginationsConfig";
import useForm from "hooks/useForm";
import useWorkFlow from "hooks/useWorkFlow";
import WorkflowFilingFormsInStepTable from "components/organism/WorkflowFilingFormsInStepTable";
import i18n from "locales/i18n";
import swal from "sweetalert";

const WorkflowFilingFormsInStep = () => {

  const { id, workflowid } = useParams();
  const [firsRefresh, setFirsRefresh] = useState(false);
  const [configurtedFormId, setConfiguredFormId] = useState(null);
  const [dataExternalConfiguration, setDataExternalConfiguration] = useState([]);
  const [prevSelectedFields, setPrevSelectedFields] = useState([]);
  const [externalAllFields, setExternalAllFields] = useState(false);
  const [listFields, setListFields] = useState([]);
  const [formsDinamic, setFormsDinamic] = useState([]);
  const [isLoadingFormsList, setIsLoadingFormsList] = useState(false);
  const [isLoadingFieldsList, setIsLoadingFieldsList] = useState(false);
  const [isLoadingOnSubmitConfig, setIsLoadingOnSubmitConfig] = useState(false);
  const [isErrorSelect] = useState(false);
  const [paginationForms] = useState(paginationFormsWorkflow);
  const [paginationFields] = useState(paginationFieldsWorkflowFiling);
  const [selectedValueFormId, setSelectedValueFormId] = useState("");
  const [configFormforStep, setConfigFormforStep] = useState({
    form_uuid: "",
    form_fields_uuid: [],
    all_fields: false,
    required_filled_fields: false,
  });

  const {
    setFillingOutForm,
    formIdInfo,
    showExternalFormEdit,
    setShowExternalFormEdit,
  } = useWorkflowContext();

  let { formId } = formIdInfo;
  const { getForms, getFields } = useForm();
  const {
    configurationDataFormInStep,
    getDataFormExternalConfiguration,
    updateFormExternalConfiguration
  } = useWorkFlow();

  let selectValueFormId = null;
  let selectOptionsForms = null;
  let labelSubmitButton = null;
  let loadingSpinnerFillingForms = null;

  /**
   * Renders a spinner if the configuration is loading on submit.
   * @param {boolean} isLoadingOnSubmitConfig - Flag indicating whether the configuration is loading on submit.
   * @returns {JSX.Element|null} - A JSX element displaying the spinner, or `null` if not loading.
   */
  if (isLoadingOnSubmitConfig === true) {
    loadingSpinnerFillingForms = (
      <Spinner size="sm" color="secondary" type="grow" />
    );
  };

  /**
   * Determines the label for the submit button based on the showExternalFormEdit flag.
   * @param {boolean} showExternalFormEdit - Flag indicating whether the external form edit mode is active.
   * @returns {string} - The label for the submit button.
   */
  if (showExternalFormEdit === true) {
    labelSubmitButton = (i18n.t("trd.buttonUptade"))
  } else {
    labelSubmitButton = (i18n.t("createWorkflowDesign.saveModal"))
  }

  /**
   * Generates a list of option elements based on the provided dynamic forms array.
   * @param {Object[]} formsDinamic - The array of form objects.
   * @param {string} formsDinamic[].value - The value of the form.
   * @param {string} formsDinamic[].label - The label of the form.
   * @returns {JSX.Element[]|null} - An array of JSX option elements if formsDinamic is not empty; otherwise, `null`.
   */
  if (arrayGreaterThanZero(formsDinamic) === true) {
    selectOptionsForms = formsDinamic.map((form) => (
      <option
        value={form.value}
        key={form.value}
        className="text-capitalize"
      >
        {form.label}
      </option>
    ));
  };

  /**
   * Determines the message to display based on the selected form ID.
   * @param {string} selectedValueFormId - The ID of the selected form.
   * @returns {JSX.Element} - A JSX element containing the appropriate message.
   */
  if (selectedValueFormId === "") {
    selectValueFormId = (
      <span>
        {i18n.t(
          "createWorkflow.configurations.alertRequiredForms"
        )}
      </span>
    );
  } else {
    selectValueFormId = (
      <span>
        {i18n.t(
          "createWorkflow.configurations.alertRequiredFields"
        )}
      </span>
    );
  };

  /**
   * Handles the closure of workflow forms in a step by updating state to hide the form and the external 
   * form edit mode.
   * @return {void}
   */
  const handleCloseWorkflowFormsInStep = () => {
    setFillingOutForm(false);
    setShowExternalFormEdit(false);
  };

  /**
   *Retrieves the active forms and updates the component state.
   *@param {object} paginationForms - an object containing the pagination details for fetching forms
   *@param {number} paginationForms.pageForms - the current page number for fetching forms
   *@param {number} paginationForms.per_page - the number of forms to fetch per page
   *@param {function} getForms - a function to fetch forms from an external source
   *@param {string} formId - the ID of the form to exclude from the list
   *@param {function} setFormsDinamic - the state setter function for the dynamically generated forms list
   *@param {function} setIsLoadingFormsList - the state setter function for the loading state of the forms list
   */
  const getActiveForms = useCallback(() => {
    const { pageForms, per_page } = paginationForms;
    setIsLoadingFormsList(true);
    getForms(pageForms, per_page, "", enumsFormStatus.FORMS_STATUS_ACTIVE, enumsPermissionsAction.ALL_PERMISIONS)
      .then((response) => {
        let listForms = response.data.items;
        if (isNullOrUndefined(listForms) === false) {
          listForms
            .sort((firstForm, SecondForm) => {
              const firstPositionFormName = `${firstForm.name} `.toLocaleLowerCase();
              const secondPositionFormName = `${SecondForm.name} `.toLocaleLowerCase();
              return firstPositionFormName.localeCompare(secondPositionFormName);
            })
            .filter((form) => form.uuid !== formId)
            .forEach((form) => {
              setFormsDinamic((prevState) => [
                ...prevState,
                {
                  value: form.uuid,
                  label: form.name,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFormsList(false);
      });
  }, [paginationForms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *Function that handles the selected field value change event and updates the component's state accordingly.
   *@param {object} eventChangeForms - the event object triggered by the selected field value change
   *@param {string} eventChangeForms.target.value - the value of the selected field
   *@param {function} setListFields - the state setter function for the list of fields
   *@param {function} setSelectedValueFormId - the state setter function for the selected form ID
   *@param {object} configFormforStep - the current configuration object for the form step
   *@param {string} eventChangeForms.target.value - the value of the selected field (form ID)
   *@param {function} setConfigFormforStep - the state setter function for the configuration object for the form step
   *@param {function} getFieldsByForm - a function that retrieves the fields based on the provided form ID
   */
  const hanldeSelectedFieldValue = (eventChangeForms) => {
    let formId = eventChangeForms.target.value;
    setListFields([]);
    setSelectedValueFormId(eventChangeForms.target.value);
    setConfigFormforStep({
      ...configFormforStep,
      form_uuid: eventChangeForms.target.value,
      form_fields_uuid: [],
    });
    if (formId !== "") {
      getFieldsByForm(formId);
    }
  };

  /**
   * Check if a list of fields contains only label fields.
   *
   * @param {boolean} allFields - Indicates whether all fields should be checked.
   * @returns {boolean} `true` if all fields are label fields, `false` otherwise.
   */

  const hasOnlyLabels = (allFields) => {
    const labelField = 4;
    if (allFields === true) {
      const hasLabels = listFields.every((field) =>
        field.controlData === labelField);
      return hasLabels;
    } else {
      const fields = listFields.filter((field) =>
        configFormforStep.form_fields_uuid.includes(field.value));
      const hashLabels = fields.every((field) =>
        field.controlData === labelField);
      return hashLabels;
    }
  }

  /**
   * Perform validation checks on a configuration.
   * This function checks if there are empty fields or if all fields only contain labels.
   * @param {boolean} allFields - An array of fields to validate.
   * @returns {boolean} A promise that resolves to `true` if validation fails, `false` otherwise.
   */
  const validationConfig = async (allFields) => {
    const emptyFields = 0;
    if (listFields.length === emptyFields) {
      showAlert({
        text: i18n.t("createWorkflow.configurations.validation1"),
        icon: "warning"
      });
      return true;
    }
    const hasLabelsValidation = await hasOnlyLabels(allFields);
    if (hasLabelsValidation === true) {
      showAlert({
        text: i18n.t("createWorkflow.configurations.validation"),
        icon: "warning"
      });
      return true;
    }
    return false;
  }

  /**
   *Retrieves fields based on the selected form ID and updates the state with the retrieved fields.
   *@param {string} selectedValueFormId - the ID of the selected form
   *@param {number} pageFields - the page number for pagination of fields
   *@param {number} per_page - the number of fields to retrieve per page
   *@param {function} getFields - a function that retrieves fields based on specified parameters
   *@param {array} listFields - the current list of fields
   *@param {function} setListFields - the state setter function for the list of fields
   *@param {number} totalPagesFields - the total number of pages of fields
   *@param {boolean} setIsLoadingFieldsList - the state setter function for the loading state of the fields list
   */
  const getFieldsByForm = useCallback(
    (selectedValueFormId) => {
      const { pageFields, per_page } = paginationFields;
      if (selectedValueFormId !== "") {
        setIsLoadingFieldsList(true);
        getFields(pageFields, per_page, selectedValueFormId, 0)
          .then((response) => {
            if (isNullOrUndefined(response.data) === false) {
              const activeFields = response.data.items.filter(
                (field) => field.status === enumsFields.ACTIVE
              );
              activeFields.forEach((fields) => {
                setListFields((prevState) => [
                  ...prevState,
                  {
                    value: fields.uuid,
                    label: fields.label,
                    requiredField: fields.required,
                    controlData: fields.control_data,
                  },
                ]);
              });
            } else {
              showAlertServiceError();
            };
          })
          .finally(() => {
            setIsLoadingFieldsList(false);
          });
      }
    },
    [paginationFields] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   *Function that handles the submission of a configuration form for a step.
   *@param {object} eventSubmitAddFields - the event object triggered by submitting the form
   *@param {boolean} configFormforStep.all_fields - a boolean value indicating if all fields should be included in the configuration
   *@param {array} configFormforStep.form_fields_uuid - an array of UUIDs representing the selected form fields for the configuration
   *@param {function} setIsLoadingOnSubmitConfig - the state setter function for the loading state during the submission
   *@param {function} configurationDataFormInStep - a function that makes an API call to save the configuration data for the step
   *@param {string} workflowid - the ID of the workflow associated with the step
   *@param {string} id - the ID of the step
   */

  const handleSubmitConfigFormForStep = async (eventSubmitAddFields) => {
    if (
      configFormforStep.all_fields === false &&
      configFormforStep.form_fields_uuid.length === 0
    ) {
      eventSubmitAddFields.preventDefault();
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("createWorkflow.configurations.alertSelectField"),
        icon: "warning",
        button: i18n.t("modal.Done.footerButton"),
      });
    } else {
      let updateForm;
      if (configFormforStep.all_fields === true) {
        updateForm = {
          form_uuid: configFormforStep.form_uuid,
          form_fields_uuid: [],
          all_fields: configFormforStep.all_fields,
          required_filled_fields: configFormforStep.required_filled_fields,
          allow_update: true
        }
      } else {
        updateForm = {
          form_uuid: configFormforStep.form_uuid,
          form_fields_uuid: configFormforStep.form_fields_uuid,
          all_fields: configFormforStep.all_fields,
          required_filled_fields: configFormforStep.required_filled_fields,
          allow_update: true
        }
      }
      if (showExternalFormEdit === true) {
        const isNotValidConfig = await validationConfig(updateForm.all_fields);
        if (isNotValidConfig === true) return;
        setIsLoadingOnSubmitConfig(true);
        updateFormExternalConfiguration(workflowid, id, updateForm)
          .then((response) => {
            if (response.code === 5051) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("form.external.config"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingOnSubmitConfig(false);
          });
      } else {
        const isNotValidConfig = await validationConfig(configFormforStep.all_fields);
        if (isNotValidConfig === true) return;
        setIsLoadingOnSubmitConfig(true);
        configurationDataFormInStep(workflowid, id, configFormforStep)
          .then((response) => {
            if (response.status === 201) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createWorkflow.configurations.alertSaveForm"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingOnSubmitConfig(false);
          });
      }
    }
  };

  /**
 * React hook that performs an effect when the component mounts.
 * @param {boolean} showExternalFormEdit - Flag indicating whether to show external form edit.
 * @param {string} workflowid - The ID of the workflow.
 * @param {string} id - The ID of the element.
 * @returns {void}
 */
  useEffect(() => {
    if (showExternalFormEdit === true) {
      getDataFormExternalConfiguration(workflowid, id)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setSelectedValueFormId(response.data.form_uuid_external);
            setDataExternalConfiguration(response.data)
            setConfiguredFormId(response.data.form_uuid_external);
            setFirsRefresh(true);
          } else {
            showAlertServiceError();
          };
        })
    };
    // eslint-disable-next-line
  }, [])

  /**
   * React hook that performs an effect based on changes in configured form and selected value.
   * @param {string} configurtedFormId - The ID of the configured form.
   * @param {string} selectedValueFormId - The ID of the selected value form.
   * @param {object} dataExternalConfiguration - The external form configuration data.
   * @param {object[]} dataExternalConfiguration.form_fields_selected - Array of selected form fields.
   * @param {boolean} dataExternalConfiguration.external_all_fields - Indicator for all external fields.
   * @param {boolean} dataExternalConfiguration.required - Indicator for required fields.
   * @param {object} configFormforStep - Current configuration for the step.
   * @param {string} configFormforStep.form_uuid - UUID of the form for the step.
   * @param {object[]} configFormforStep.all_fields - All fields for the step.
   * @param {boolean} configFormforStep.required_filled_fields - Indicator for required filled fields.
   * @returns {void}
   */
  useEffect(() => {
    if (configurtedFormId === selectedValueFormId) {
      const completedData = dataExternalConfiguration.form_fields_selected
        .filter((field) => !field.required)
        .map((field) => field.uuid);

      setPrevSelectedFields(completedData);
      setExternalAllFields(dataExternalConfiguration.external_all_fields);
      setConfigFormforStep({
        ...configFormforStep,
        form_uuid: dataExternalConfiguration.form_uuid_external,
        all_fields: dataExternalConfiguration.external_all_fields,
        required_filled_fields: dataExternalConfiguration.required,
      })
    } else {
      setPrevSelectedFields([]);
      setExternalAllFields(false);
      setConfigFormforStep({
        ...configFormforStep,
        all_fields: false,
        required_filled_fields: false,
      })
    }
    // eslint-disable-next-line
  }, [configurtedFormId, selectedValueFormId])

  /**
   *useEffect hook that invokes the 'getActiveForms' function when the 'getActiveForms' prop changes.
   *@param {function} getActiveForms - a function that retrieves and handles active forms
   */
  useEffect(() => {
    getActiveForms();
  }, [getActiveForms]);

  /**
   *useEffect hook that fetches fields based on changes to the 'selectedValueFormId' prop and updates the 'listFields' state.
   *@param {string} selectedValueFormId - the ID of the selected form
   *@param {function} setListFields - the state setter function for the list of fields associated with the selected form
   *@param {function} getFieldsByForm - a function that retrieves fields by form ID
   *@param {object} paginationFields - an object containing pagination information for the fields
   *@param {number} paginationFields.pageFields - the current page number for the fields
   */
  useEffect(() => {
    if (selectedValueFormId !== "") {
      setListFields([]);
      getFieldsByForm(selectedValueFormId);
    }
  }, [paginationFields.pageFields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React hook that performs an effect when `firsRefresh` flag is true.
   * @param {boolean} firsRefresh - Flag indicating whether the first refresh is needed.
   * @param {string} selectedValueFormId - The ID of the selected value form.
   * @returns {void}
   */
  useEffect(() => {
    if (firsRefresh === true) {
      setListFields([]);
      getFieldsByForm(selectedValueFormId);
      setFirsRefresh(false)
    }
  }, [firsRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a loading indicator if the forms list is currently loading.
   * @param {boolean} isLoadingFormsList - Flag indicating whether the forms list is currently loading.
   * @returns {JSX.Element|null} - A JSX element displaying the loading indicator, or `null` if not loading.
   */
  if (isLoadingFormsList === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <AvForm onSubmit={handleSubmitConfigFormForStep}>
            <CardHeader className="mt-4 ml-3 ">
              <div className="card-header-title font-size-lg text-capitalize font-weight-bold">
                <CardTitle>
                  {i18n.t("createWorkflow.configurations.label5")}
                </CardTitle>
              </div>
              <div className="btn-actions-pane-right absolute right-0">
                <Button
                  className="btn-icon mb-3"
                  color="cyan"
                  onClick={() => window.history.back()}
                >
                  <FontAwesomeIcon
                    icon={faArrowAltCircleLeft}
                    className="mr-2"
                  />
                  {i18n.t("createWorkflow.config.back")}
                </Button>
              </div>
            </CardHeader>
            <div>
              <CardBody>
                <Alert color="info" className="mb-3">
                  <span className="pr-2 text-bold">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className="mr-2 "
                    />
                  </span>
                  {selectValueFormId}
                </Alert>

                <AvGroup row>
                  <Col md={2}>
                    <Label className="is-required mt-2">
                      {i18n.t("createWorflow.form")}
                    </Label>
                  </Col>
                  <Col md={10}>
                    <AvField
                      id="selectForm"
                      name="selectForm"
                      type="select"
                      required={isErrorSelect}
                      errorMessage={i18n.t(
                        "createWorkflow.config.typeResponsible"
                      )}
                      value={selectedValueFormId}
                      onChange={hanldeSelectedFieldValue}
                    >
                      <option value={""}>{i18n.t("recordData2")}</option>
                      {selectOptionsForms}
                    </AvField>
                  </Col>
                </AvGroup>

                <WorkflowFilingFormsInStepTable
                  externalAllFields={externalAllFields}
                  prevSelectedFields={prevSelectedFields}
                  listFields={listFields}
                  configFormforStep={configFormforStep}
                  setConfigFormforStep={setConfigFormforStep}
                  isLoadingFieldsList={isLoadingFieldsList}
                />
              </CardBody>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  disabled={isLoadingOnSubmitConfig === true}
                  className="col-mt-3 mr-3"
                  color="gray"
                  onClick={handleCloseWorkflowFormsInStep}
                >
                  {i18n.t("createusers.createButton2")}
                </Button>
                <Button
                  type="submit"
                  size="lg"
                  disabled={selectedValueFormId === ""}
                  className="col-mt-3"
                  color="cyan"
                >
                  {loadingSpinnerFillingForms}
                  {labelSubmitButton}
                </Button>
              </CardFooter>
            </div>
          </AvForm>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default WorkflowFilingFormsInStep;
