import React from "react";
import ProcessDetail from "./ProcessDetail";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <WorkflowContextProvider>
        <ProcessDetail />
      </WorkflowContextProvider>
    </RecordDetailContextProvider>
  );
};
