import React, { Fragment } from "react";
import i18n from "locales/i18n";
import swal from "sweetalert";

const AccessDenied = () => {
  const showAlertServiceError = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("showNotification403"),
      icon: "error",
      button: i18n.t("modal.Done.footerButton"),
      dangerMode: true,
    }).then(function () {
      window.location = "/dashboard";
    });
  };
  return (
    <Fragment>
      <div className="h-100 d-flex justify-content-center align-items-center bg-heavy-rain">
        <div>{showAlertServiceError()}</div>
      </div>
    </Fragment>
  );
};
export default AccessDenied;
