import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
} from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import QueryListLookup from "components/molecules/QueryListLookup";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "locales/i18n";

const ModalForLookup = (props) => {
  const { formLookupName, formLookupFields, formUuid } = props;
  const {
    showModalForLookup,
    setShowModalForLookup,
  } = useFieldsRecordContext();

  /**
   * Closes the modal for lookup by setting the 'showModalForLookup' state to false.
   * @param {funtion} setShowModalForLookup - The new value to set the modal.
   * @returns {void}
   */
  const handleOnCloseModal = () => {
    setShowModalForLookup(false);
  };

  return (
    <Fragment>
      <Modal isOpen={showModalForLookup} toggle={handleOnCloseModal} size="lg">
        <ModalHeader>
          <Label>{i18n.t("form.field83")}</Label>
        </ModalHeader>
        <ModalBody>
          <Alert color="info">
            <span>
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
              <span className="font-weight-bold mbg-3">
                {i18n.t("modal.DoneError.header")} :{" "}
              </span>
              <span>{i18n.t("form.field84")}</span>
            </span>
          </Alert>
          <QueryListLookup
            formLookupName={formLookupName}
            formLookupFields={formLookupFields}
            formUuid={formUuid}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            className="col-mt-3 mr-3"
            color="gray"
            onClick={handleOnCloseModal}
          >
            {i18n.t("createusers.createButton2")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

ModalForLookup.propTypes = {
  formLookupName: PropTypes.string.isRequired,
  formLookupFields: PropTypes.object.isRequired,
};

export default ModalForLookup;
