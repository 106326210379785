import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import ReactTable from "react-table";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";
import { getDateFormat2 } from "utils/getDateFormat2";
import TrdExpedientConsultationFiles from "components/organism/TrdExpedientConsultationFiles";

const TrdExpedientConsultationTable = (props) => {
  const {
    listExpedients,
    setChangeSearchFile,
    paginationTables,
    setPaginationTables,
    isLoadingExpedients,
    totalPages,
    getExpedientsData,
  } = props;

  const [showSecondTable, setShowSecondTable] = useState(false);
  const [expedientId, setExpedientId] = useState("");
  const [parentId, setParentId] = useState("");
  const [backgrundId, setBackgrundId] = useState("");

  /**
   *Function that handles the click event for file count and updates the state of the component.
   *@param {function} setShowSecondTable - the state setter function for displaying the second table
   *@param {function} setChangeSearchFile - the state setter function for changing the search file
   */
  const handleFileCountClick = () => {
    setShowSecondTable(true);
    setChangeSearchFile(false);
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="container-tables-expedients">
          {!showSecondTable && (
            <ReactTable
              data={listExpedients}
              columns={[
                {
                  columns: [
                    {
                      Header: [i18n.t("trdExpedientList3")],
                      accessor: "created_at",
                      Cell: ({ value }) => {
                        let valueFinal = getDateFormat2(new Date(value));

                        return <p>{valueFinal}</p>;
                      },
                    },
                    {
                      Header: [i18n.t("trd.expedientConsultationCountFiles")],
                      accessor: "files_count",
                      Cell: ({ row }) => (
                        <div className="d-flex justify-content-center">
                          <Button
                            outline
                            className="mb-2 mr-2 btn-pill btn-dashed"
                            color="info"
                            disabled={row.files_count === 0}
                            onClick={() => {
                              handleFileCountClick();
                              setParentId(row._original.parent_id);
                              setExpedientId(row._original.uuid);
                              setBackgrundId(
                                row._original.background_parent_id
                              );
                            }}
                          >
                            <span className="pr-2">
                              <FontAwesomeIcon icon={faFile} />
                            </span>
                            <span className="badge-pill text-bold">
                              {row.files_count}
                            </span>
                          </Button>
                        </div>
                      ),
                    },
                    {
                      Header: [i18n.t("trdExpedientList1")],
                      accessor: "code",
                    },
                    {
                      Header: [i18n.t("trdExpedientList2")],
                      accessor: "name",
                    },
                    {
                      Header: [i18n.t("trdExpedientList4")],
                      accessor: "subject",
                    },

                    {
                      Header: [i18n.t("trdExpedientList5")],
                      accessor: "status",
                      Cell: ({ value }) => {
                        let statusFinal = "";

                        if (value === 1) {
                          statusFinal = (
                            <div className="ml-auto badge badge-success">
                              {i18n.t("trdExpedientList9")}
                            </div>
                          );
                        } else {
                          statusFinal = (
                            <div className="ml-auto badge badge-danger">
                              {i18n.t("trdExpedientList10")}
                            </div>
                          );
                        }

                        return <span>{statusFinal}</span>;
                      },
                    },
                    {
                      Header: [i18n.t("trdExpedientList6")],
                      accessor: "close_date",
                      Cell: ({ value }) => {
                        if (isNullOrUndefined(value) === false) {
                          let valueFinal = getDateFormat2(new Date(value));
                          return <span>{valueFinal}</span>;
                        } else {
                          let valueFinal = "";
                          return <span>{valueFinal}</span>;
                        }
                      },
                    },
                  ],
                },
              ]}
              manual
              className="-striped -highlight table-no-border"
              pages={totalPages}
              page={paginationTables.page - 1}
              showPageJump={false}
              defaultPageSize={paginationTables.per_page}
              loading={isLoadingExpedients}
              noDataText={`${i18n.t("tableRowsEmpty")}`}
              previousText={`${i18n.t("previousText")}`}
              nextText={`${i18n.t("nextText")}`}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
              onPageSizeChange={(per_page) => {
                setPaginationTables({
                  ...paginationTables,
                  per_page: per_page,
                });
              }}
              onPageChange={(page) => {
                setPaginationTables({
                  ...paginationTables,
                  page: page + 1,
                });
              }}
            />
          )}
          {(() => {
            if (showSecondTable === true) {
              return (
                <TrdExpedientConsultationFiles
                  backgrundId={backgrundId}
                  expedientId={expedientId}
                  parentId={parentId}
                  getExpedientsData={getExpedientsData}
                  setChangeSearchFile={setChangeSearchFile}
                />
              );
            } else {
              return null;
            }
          })()}
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrdExpedientConsultationTable.propTypes = {
  listExpedients: PropTypes.array.isRequired,
  paginationTables: PropTypes.object.isRequired,
  setPaginationTables: PropTypes.func.isRequired,
  isLoadingExpedients: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  setChangeSearchFile: PropTypes.func.isRequired,
  getExpedientsData: PropTypes.func.isRequired,
};

export default TrdExpedientConsultationTable;
