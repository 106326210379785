import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvGroup,
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import { Button, Card, Col, Container, CardBody, Label } from "reactstrap";
import i18n from "locales/i18n";

const RecordsList = () => {

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Card>
            <CardBody>
              <AvForm>
                <AvGroup row>
                  <Label for="Record" className="is-required" sm={2}>{i18n.t("recordList3")}</Label>
                  <Col sm={6}>
                    <AvField
                      type="text"
                      name="Record"
                      id="Record"
                      autoComplete="off"
                      validate={{
                        required: { value: true, errorMessage: `${i18n.t("recordListfeedback")}` },
                        pattern: { value: '/^[0-9\b]+$/', errorMessage: `${i18n.t("recordListfeedback")}` },
                        minLength: { value: 4, errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t("and")} 500 ${i18n.t("characters")}` },
                        maxLength: { value: 100, errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t("and")} 500 ${i18n.t("characters")}` }
                      }}
                      placeholder={i18n.t("recordList4")}
                    />
                  </Col>
                  <Col sm={4}>
                    <Button className="mb-2 mr-2 btn-icon" color="cyan">
                      <i className="pe-7s-search btn-icon-wrapper"> </i>
                      {i18n.t("recordList5")}
                    </Button>
                  </Col>
                </AvGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default RecordsList;
