import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { loaderElement } from "utils/loaderElement";
import { Card, CardHeader, NavLink } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import useIntegration from "hooks/useIntegrations";
import InfiniteScroll from "react-infinite-scroll-component";

const IntegrationsContent = () => {
  const [isLoadingIntegrations, setIsLoadingIntegrations] = useState(false);
  const [listIntegrations, setListIntegrations] = useState([]);
  const { getIntegrations } = useIntegration();

  /**
   * Fetches integration data based on a search query and updates the state with the retrieved data.
   * @returns {void}
   */
  const getIntegrationsBySearch = useCallback(() => {
    setIsLoadingIntegrations(true);
    getIntegrations()
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setListIntegrations(response.data.data.items);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingIntegrations(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches integrations by search query when the component mounts or when the `getIntegrationsBySearch` function changes.
   * @returns {void}
   */
  useEffect(() => {
    getIntegrationsBySearch();
  }, [getIntegrationsBySearch]);

  return (
    <Fragment>
      {loaderElement(isLoadingIntegrations)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <div id="scrollableDiv" className="integrationContent-scrolleable">
            <InfiniteScroll
              dataLength={listIntegrations.length}
              scrollableTarget="scrollableDiv"
              className="app-grid-int"
            >
              {listIntegrations.map((integration) => (
                <Card
                  className="mb-3  card-shadow-primary widget-chart integrationContent-card"
                >
                  <div>
                    <CardHeader
                      className="p-3 justify-content-center align-items-center auto-height"
                    >
                      <img
                        className="auto-height"
                        alt="integrations"
                        src={integration.logo}
                      />
                    </CardHeader>

                    <NavLink
                      className="integration-content-nav"
                      id={integration.uuid}
                      key={integration.uuid}
                      href={`/integrations/${integration.uuid}`}
                    >
                      {" "}
                      <h6 className="text-left text-cyan font-weight-bold">
                        {integration.name}
                      </h6>
                    </NavLink>

                    <p className="text-left">{integration.description}</p>
                  </div>
                </Card>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default IntegrationsContent;
