import React, { useEffect, Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import FormsPreview from "components/organism/FormsPreview";
import CreateFieldOk from "components/molecules/Messages/Success/CreateFieldOk";
import FieldList from "components/organism/FieldList";
import Tabs from "react-responsive-tabs";
import { Card, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
} from "constants/securityConst";
import WizardFields from "components/molecules/WizardFields";
import FieldsTypeControl from "components/organism/FieldsTypeControl";
import FieldsValidation from "components/organism/FieldsValidation";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import FieldForm from "components/organism/FieldForm";

const FieldsDesigner = () => {
  const { id: formId } = useParams();
  const { getFormsByUuid, showTabs } = useFieldsRecordContext();
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(
    DESIGNER_FORMS_PERMISSIONS
  );
  const newForms = DESIGNER_FROMS_PERMISSION.split(",");

  let renderFieldsDesigner = null;

  /**
   * An array representing the steps in a multi-step form process.
   * Each step has a name and a corresponding component to render.
   * @type {Array<{name: string, component: JSX.Element}>}
   */
  const steps = [
    { name: [i18n.t("form.field2")], component: <FieldsTypeControl /> },
    { name: [i18n.t("form.field3.1")], component: <FieldForm /> },
    { name: [i18n.t("form.field3.2")], component: <FieldsValidation /> },
    { name: [i18n.t("form.field4")], component: <CreateFieldOk /> },
  ];

  /**
   * An array representing the steps in a multi-step form process.
   * Each step has a name and a corresponding component to render.
   * @type {Array<{name: string, component: JSX.Element}>}
   */
  const tabsContent = [
    {
      title: i18n.t("form.fieldHeading"),
      content: <FieldList />,
    },
    {
      title: i18n.t("form.field1"),
      content: <FormsPreview />,
    },
  ];

  /**
   * Retrieves an array of tab objects from the 'tabsContent' array.
   * @param {string} title : The title of the tab.
   * @param  {function} getContent : A function that returns the content of the tab.
   * @param {number} key : The unique key of the tab, typically based on its index in 'tabsContent'.
   * @returns {Array<object>} An array of tab objects, each containing the following properties:
   */
  function getTabs() {
    return tabsContent.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  /**
   * Conditionally renders the appropriate component for the fields designer.
   * If `showTabs` is false, it renders the `Tabs` component with the specified properties.
   * Otherwise, it renders a `Card` component containing the `MultiStepFields` component.
   * @param {boolean} showTabs - A flag indicating whether to show tabs or not.
   * @returns {JSX.Element} The JSX element to render based on the value of `showTabs`.
   */
  if (showTabs === false) {
    renderFieldsDesigner = (
      <Tabs
        tabsWrapperClass="body-tabs body-tabs-layout body-tabs-alt"
        transform={false}
        showInkBar={true}
        items={getTabs()}
      />
    )
  } else {
    renderFieldsDesigner = (
      <Card className="main-card mb-3">
        <CardBody>
          <div className="forms-wizard-vertical">
            <WizardFields showNavigation={true} steps={steps} />
          </div>
        </CardBody>
      </Card>
    )
  };

  /**
   * useEffect hook that checks the formId and navigates back in the browser's history if certain conditions are met.
   * @param {string} formId - The formId to be checked for specific conditions.
   * @param {string} DESIGNER_FORM - A string indicating whether the form is in designer mode.
   * @param {Array<string>} newForms - An array containing IDs of new forms.
   * @returns {void}
   */
  useEffect(() => {
    if (formId) {
      if (DESIGNER_FORM === "false" && !newForms.includes(formId)) {
        window.history.back();
      }
    }
  }, [formId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A useEffect hook that triggers the retrieval of forms by UUID when the 'getFormsByUuid' function or its dependencies change.
   * @param {string} formId - The UUID used to identify the specific form to retrieve.
   * @param {function} getFormsByUuid - A function responsible for fetching forms by UUID.
   * @returns {void}
   */
  useEffect(() => {
    getFormsByUuid(formId);
  }, [getFormsByUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("form.fieldHeading")}
        subheading={i18n.t("form.fieldSubheading")}
        icon="next-2"
      >
        {renderFieldsDesigner}
      </AdminTemplate>
    </Fragment>
  );
};
export default FieldsDesigner;
