import React, { Fragment, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useListContext } from "contextAPI/ListsContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { UPLOAD_FILES, TYPE } from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loaderComponent } from "utils/loaderElement";
import {
  enumsCreateExpedient,
  enumsExpedietnStatus,
  enumsTaskType,
} from "utils/enums";
import RecordListExpedient from "components/organism/RecordListExpedient";
import WizardDocuments from "components/molecules/WizardDocuments";
import RecordUpLoadFilesByExpedient from "components/organism/RecordUpLoadFilesByExpedient";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import TrdLevelsOrdenation from "components/molecules/TrdLevelsOrdenation";
import RecordUpLoadFiles from "components/organism/RecordUploadFiles";
import TrdLevelsTree from "components/molecules/TrdLevelsTree";
import Context from "contextAPI/UserContext";
import i18n from "locales/i18n";

const UpLoadFiles = (props) => {
  const { id } = useParams();
  const {
    isRecordCreate,
    statusData,
    isLoadingExpedietnPath,
    hasExpedientFixedPath,
    expedientFixedUuid,
    expedientStatus,
    structureTrdExpedient,
  } = props;
  const UPLOADFILES = window.localStorage.getItem(UPLOAD_FILES);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const { user } = useContext(Context);
  const { setRecordNext, setAdvanced } = useListContext();
  const {
    hasOrdination,
    expedientSetPath,
    isLoadingOrdination,
    setFoldersId,
    parentData,
    setParentData,
  } = useTrdContext();
  const {
    setParentId,
    setShowModalExpedient,
    recordUuid,
    pathConfiguration,
    setShowWizardButtons,
    currentPath,
    setCurrentPath,
  } = useRecordDetailContext();
  let steps = [];
  let optionUploadFiles;
  let componentToRenderUploadFile;

  /**
   * Object representing a record upload configuration for a specific expedient.
   * @typedef {Object} RecordUploadConfig
   * @property {string} name - The name of the record upload component.
   * @property {JSX.Element} component - The React component for uploading files to an expedient.
   * @property {boolean} hasExpedientFixedPath - Indicates whether the expedient has a fixed path.
   * @property {boolean} hasOrdination - Indicates whether ordination is present for the expedient.
   * @property {string} expedientFixedUuid - The UUID of the fixed expedient.
   * @property {string} currentPath - The current path within the expedient.
   * @property {string} recordUuid - The UUID of the record associated with the upload.
   * @property {boolean} isFreeTask - Indicates if the task is free.
   * @property {boolean} isProcess - Indicates if the upload is part of a process.
   * @property {boolean} isProcessBatch - Indicates if the upload is part of a batch process.
   */
  const recordUpLoadFilesByExpedient = {
    name: i18n.t("recordDetail.fileToExpedient"),
    component: (
      <RecordUpLoadFilesByExpedient
        pathConfiguration={pathConfiguration}
        currentPath={currentPath}
        hasExpedientFixedPath={hasExpedientFixedPath}
        hasOrdination={hasOrdination}
        isRecordCreate={isRecordCreate}
        recordUuid={recordUuid}
        isFreeTask={enumsTaskType.NO_PROCES_TASK}
        isProcess={false}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Object representing a tree component for TRD (Table of Retention and Disposal) levels.
   * @constant {Object} trdLevelsTree
   * @property {string} name - The localized name of the component, translated using i18n.
   * @property {React.Component} component - The React component representing the TRD levels tree.
   * @property {string} component.alertTitle - The title used for alerts related to the record file.
   * @property {string} component.buttonText - The text displayed on the button within the component.
   * @property {string} component.currentPath - The current path within the TRD levels tree.
   * @property {function} component.setCurrentPath - A function to update the current path within the TRD levels tree.
   * @param {string} i18n.t("recordDetail.LocationFile")} - The translation key for the localized name of the component.
   * @param {string} alertTitle - The translation key for the alert title displayed within the component.
   * @param {string} buttonText - The text displayed on the button within the component.
   * @param {string} currentPath - The current path within the TRD levels tree.
   * @param {function} setCurrentPath - A function to update the current path within the TRD levels tree.
   */
  const trdLevelsTree = {
    name: i18n.t("recordDetail.LocationFile"),
    component: (
      <TrdLevelsTree
        currentPath={currentPath}
        setCurrentPath={setCurrentPath}
      />
    ),
  };

  /**
   * Represents a record list expedient configuration object.
   * Creates a record list expedient object with specified configuration.
   * @typedef {Object} RecordListExpedient
   * @property {string} name - The localized title of the record list expedient.
   * @property {React.Component} component - The React component representing the record list expedient.
   * @param {Object} structureTrdExpedient - The structure of the TRD (Tabla de Retención Documental) expedient.
   * @param {boolean} hasExpedientFixedPath - Indicates whether the expedient has a fixed path.
   * @param {boolean} hasOrdination - Indicates whether the expedient has ordination.
   * @param {string} expedientFixedUuid - The UUID of the fixed expedient.
   * @param {string} expedientStatus - The status of the expedient.
   * @param {boolean} isLoadingOrdination - Indicates whether ordination data is currently loading.
   * @param {boolean} isLoadingExpedietnPath - Indicates whether expedient path data is currently loading.
   * @returns {RecordListExpedient} A record list expedient configuration object.
   */
  const recordListExpedient = {
    name: i18n.t("trd.formTittle14"),
    component: (
      <RecordListExpedient
        formIdInfo={id}
        expedientComeFrom={enumsCreateExpedient.RECORDS}
        hasExpedientFixedPath={hasExpedientFixedPath}
        isFreeTask={enumsTaskType.NO_PROCES_TASK}
        isProcess={false}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Object representing TRD (Table of Retention and Disposal) levels for ordering.
   * @typedef {Object} TrdLevelsOrdenation
   * @property {string} name - The translated title for the TRD form.
   * @property {React.ReactNode} component - The React component for TRD levels ordering.
   * @property {Object} structureTrdExpedients - The structure of TRD expedients.
   * @property {boolean} isFreeTask - Indicates if the task is free.
   * @property {boolean} isProcess - Indicates if it's a process.
   * @property {boolean} isProcessBatch - Indicates if it's a batch process.
   * @property {string} recordUuid - The UUID of the record associated with TRD.
   */
  const trdLevelsOrdenation = {
    name: i18n.t("trd.ordinationTitle"),
    component: (
      <TrdLevelsOrdenation
        hasOrdenation={hasOrdination}
        pathConfiguration={pathConfiguration}
        structureTrdExpedient={structureTrdExpedient}
        recordUuid={recordUuid}
        isFreeTask={enumsTaskType.NO_PROCES_TASK}
        isProcess={false}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Represents a configuration object for displaying a no-status component.
   * This component includes informational alerts and a path display.
   * @property {string} name - The name of the component, localized using i18n.
   * @property {JSX.Element} component - The JSX element representing the no-status component.
   * @property {string} component.className - The class name applied to the outer container of the component.
   * @property {JSX.Element} component.Alert - The Bootstrap alert component used for displaying information.
   * @property {string} component.Alert.className - The class name applied to the alerts within the component.
   * @property {string} component.Alert.color - The color variant of the alerts ('info' in this case).
   * @property {boolean} component.Alert.isOpen - Determines whether the alert is visible (always true for the second alert).
   * @property {JSX.Element} component.Alert.span - The span element containing text and icons within the alert.
   * @property {JSX.Element} component.Alert.span.FontAwesomeIcon - The FontAwesome icon used within the alert.
   * @property {JSX.Element} component.Alert.span.font-weight-bold - The bold text within the alert.
   * @property {string} component.Alert.span.i18n.t("modal.DoneError.header") - Localized text content retrieved using i18n.
   * @property {string} component.Alert.span.i18n.t("trdExpedientListSetPathInactive") - Localized text content retrieved using i18n.
   * @property {JSX.Element} component.Alert.div - The div element containing path information.
   * @property {JSX.Element} component.Alert.div.CurrentPathTrd - The custom path display component.
   * @property {Object} component.Alert.div.CurrentPathTrd.currentPath - The current path data passed to the CurrentPathTrd component.
   * @property {Object} component.Alert.div.CurrentPathTrd.pathConfig - The path configuration data passed to the CurrentPathTrd component.
   */
  const noStatus = {
    name: i18n.t("recordDetail.fileToExpedient"),
    component: (
      <div className="mt-3 ">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} : &nbsp;
            </span>
            <span>{i18n.t("trdExpedientListSetPathInactive")}</span>
          </span>
        </Alert>
        <Alert className="mbg-3" color="info" isOpen={true}>
          <div className="d-flex">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={expedientSetPath}
            />
          </div>
        </Alert>
      </div>
    ),
  };

  /**
   * Modifies the steps array based on the provided path configuration and related conditions.
   * @param {boolean} isNullOrUndefined - Indicates whether the pathConfiguration is null or undefined.
   * @param {boolean} hasExpedientFixedPath - Indicates if there's a fixed path for the expedient.
   * @param {string} expedientStatus - The status of the expedient (e.g., enumsExpedietnStatus.ACTIVE).
   * @param {boolean} hasOrdination - Indicates if there is ordination for the expedient.
   * @param {array} steps - The array of steps to be modified based on the configuration.
   * @param {*} trdLevelsOrdenation - Object representing TRD levels for ordination.
   * @param {*} recordUpLoadFilesByExpedient - Object representing record upload files by expedient.
   * @param {*} noStatus - Object representing the status when there's no active expedient status.
   * @param {*} recordListExpedient - Object representing the record list of expedients.
   * @param {*} trdLevelsTree - Object representing TRD levels in a tree structure.
   * @param {enumsExpedietnStatus} enumsExpedietnStatus - Enumerated type for expedient statuses.
   * @returns {void}
   */
  if (isNullOrUndefined(pathConfiguration) === false) {
    if (hasExpedientFixedPath === true) {
      if (expedientStatus === enumsExpedietnStatus.ACTIVE) {
        if (hasOrdination === true) {
          steps.splice(1, 0, trdLevelsOrdenation);
          steps.splice(2, 0, recordUpLoadFilesByExpedient);
        } else {
          steps.splice(1, 0, recordUpLoadFilesByExpedient);
        }
      } else {
        steps.splice(1, 0, noStatus);
      }
    } else {
      steps.splice(1, 0, recordListExpedient);
      steps.splice(2, 0, trdLevelsOrdenation);
      steps.splice(3, 0, recordUpLoadFilesByExpedient);
    }
  } else {
    steps.splice(1, 0, trdLevelsTree);
    steps.splice(2, 0, recordListExpedient);
    steps.splice(3, 0, trdLevelsOrdenation);
    steps.splice(4, 0, recordUpLoadFilesByExpedient);
  }

  /**
   * Determines the component to render based on the presence of the user's domain information.
   * If the user's domain is not null or undefined, it renders a MultiStepDocuments component with specified props.
   * Otherwise, it renders a RecordUpLoadFiles component with specified props.
   * @param {Object} user - The user object containing domain information.
   * @param {string} user.domain - The domain of the user.
   * @param {Array<Object>} steps - An array of step objects defining the steps for MultiStepDocuments.
   * @param {boolean} isRecordCreate - A boolean indicating whether the record creation is in progress.
   * @returns {JSX.Element} The JSX element representing the component to render based on the user's domain.
   */
  if (isNullOrUndefined(user.domain) === false) {
    componentToRenderUploadFile = (
      <WizardDocuments
        isFreeTask={enumsTaskType.NO_PROCES_TASK}
        isProcess={false}
        steps={steps}
      />
    );
  } else {
    componentToRenderUploadFile = (
      <RecordUpLoadFiles
        fileByTrd={false}
        isRecordCreate={isRecordCreate}
        status={statusData}
        recordUuid={recordUuid}
      />
    );
  }

  /**
   * Renders an upload file option based on user type and file inclusion criteria.
   * @param {string} USERTYPE - The type of the user, used for access control.
   * @param {string} id - The identifier of the file.
   * @param {Array<string>} UPLOADFILES - An array of file identifiers for comparison.
   * @param {React.Component} componentToRenderUploadFile - The React component to render for uploading files.
   * @param {string} i18n.t("modal.DoneError.header") - The translated header for the alert.
   * @param {string} i18n.t("showNotification403") - The translated notification message for unauthorized access.
   * @returns {JSX.Element} The JSX element representing the upload files option based on conditions.
   */
  if (USERTYPE !== "3" && UPLOADFILES.includes(id) === false) {
    optionUploadFiles = (
      <div className="mt-3">
        <Alert color="info">
          <h4 className="alert-heading">{i18n.t("modal.DoneError.header")}</h4>
          <hr />
          <h6>{i18n.t("showNotification403")}</h6>
        </Alert>
      </div>
    );
  } else {
    optionUploadFiles = (
      <Row className="mt-3">
        <Col md="12">
          <Fragment>{componentToRenderUploadFile}</Fragment>
        </Col>
      </Row>
    );
  }

  /**
   * Executes an effect when dependencies related to expedient data change,
   * updating parent data if certain conditions are met.
   * @param {Function} callback - A function containing logic to be executed when dependencies change.
   * @param {Array} dependencies - An array of values that, when changed, trigger the effect to run.
   * @param {string|null|undefined} expedientFixedUuid - The UUID of the expedient if it's not null or undefined.
   * @param {boolean} hasExpedientFixedPath - Indicates whether the expedient has a fixed path.
   * @param {Object} parentData - The current state of parent data to be updated.
   * @param {string} parentData.id - The identifier of the parent data.
   * @param {number} parentData.level - The level of the parent data.
   * @param {Function} setParentData - A function used to update the parentData state.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(expedientFixedUuid) === false &&
      hasExpedientFixedPath === true
    ) {
      setParentData({ ...parentData, id: expedientFixedUuid, level: 5 });
    }
  }, [hasExpedientFixedPath, expedientFixedUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React Hook that runs the specified effect function after the component has rendered.
   * This effect is executed only once, when the component is mounted.
   * @param {Function} effect - The effect function to run.
   * @param {Array} deps - An array of dependencies. If any of the dependencies change, the effect will be re-run.
   */
  useEffect(() => {
    setParentId("");
    setRecordNext(false);
    setAdvanced(0);
    setShowModalExpedient(false);
    setFoldersId({
      backgroundId: "",
      sectionId: "",
      subSectionId: "",
      serieId: "",
      subSerieId: "",
      expedientId: "",
      subDivisionId: "",
      subDirectoryId: "",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A custom effect to set the `showWizardButtons` state to `true` when the component mounts.
   * @param {function} setShowWizardButtons - The state updater function for `showWizardButtons`.
   * @return {void}
   */
  useEffect(() => {
    setShowWizardButtons(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (statusData !== 2) {
    return optionUploadFiles;
  } else {
    return (
      <div className="mt-3 ">
        {loaderComponent(isLoadingOrdination || isLoadingExpedietnPath)}
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} :{" "}
            </span>
            <span>{i18n.t("RecordDetail.label8")}</span>
          </span>
        </Alert>
      </div>
    );
  }
};

UpLoadFiles.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
  statusData: PropTypes.number.isRequired,
  isLoadingExpedietnPath: PropTypes.bool.isRequired,
  hasExpedientFixedPath: PropTypes.bool.isRequired,
  expedientFixedUuid: PropTypes.string.isRequired,
  expedientStatus: PropTypes.number.isRequired,
  structureTrdExpedient: PropTypes.array.isRequired,
};

export default UpLoadFiles;
