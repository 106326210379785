import { useTrdContext } from "contextAPI/TrdContext";
/**
 * Custom React hook to get information related to a TRD level.
 *
 * This hook takes the `trdLevel` and `numberLevel` as parameters and returns an object containing information about the specified TRD level.
 * The hook utilizes the `useTrdContext` hook to access the `trdForm` and `structureTrd` states from the TRD context.
 * The function determines whether to use the `getNameTrdByLevel` or `getNameTrdByLevel2` function based on the `trdLevel`.
 * It filters the `structureTrd` to find a matching TRD element with the same `code`, `level`, and `id` as `trdForm`.
 * The resulting `trdLevelInformation` object includes the formatted TRD name, parent ID, TRD ID, and background parent ID.
 * @param {string} trdLevel - The TRD level represented by a string such as "(Sub-Sección)", "(Sub-Serie)", etc.
 * @param {number} numberLevel - The numeric representation of the TRD level.
 * @returns {Object} An object containing information about the specified TRD level, including formatted name, parent ID, TRD ID, and background parent ID.
 */
const useEditLevelTrd = (numberLevel) => {
  const firstIndexOfTrd = 0;
  const {
    trdForm,
    structureTrd,
  } = useTrdContext();

  const structuredLevel = structureTrd.filter((trdElement) =>
  (trdElement.code === trdForm.code && trdElement.level
    === numberLevel && trdElement.id === trdForm.id));

  const trdLevelInformation = structuredLevel.map((trdElement) => {
    return {
      name: trdElement.name,
      parentId: trdElement.parentId,
      trdId: trdElement.id,
      backgroundParentId: trdElement.background_parent_id
    }
  })[firstIndexOfTrd];

  return {
    trdLevelInformation
  }
}

export default useEditLevelTrd