import React from "react";
import Loader from "react-loaders";

/**
 * Generates a loading element if the isLoading parameter is true, otherwise returns null.
 * @param {boolean} isLoading - A boolean value indicating whether to display the loading element or not.
 * @returns {JSX.Element | null} Returns a loading element (JSX) if isLoading is true, or null if it's false.
 */
export function loaderElement(isLoading) {
  if (isLoading === true) {
    return (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="#0072BC" type="ball-pulse-rise" />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

/**
 * Renders a loader component when isLoading is true.
 * @param {boolean} isLoading - A flag indicating whether the component should display the loader.
 * If true, the loader is displayed; otherwise, nothing is rendered.
 * @returns {JSX.Element | null} The loader component or null if isLoading is false.
 */
export function loaderComponent(isLoading) {
  if (isLoading === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="#0072BC" type="ball-pulse-rise" />
      </div>
    );
  } else {
    return null;
  }
}
