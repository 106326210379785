import React, { useState, useCallback } from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import { acceptFileLogoRegex, acceptedFilesLogo } from "constants/acceptFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeText } from "utils/formatText";
import { useDropzone } from "react-dropzone";
import { enumsDropLogo } from "utils/enums";
import Loader from "react-loaders";
import cx from "classnames";
import renderIconFile from "utils/renderIconFile";
import i18n from "locales/i18n";

const DropZoneLogo = (props) => {
  const { setFileLogo } = props;
  const [isValidatingFiles, setIsValidatingFiles] = useState(false);
  let fileHelper = null;
  let fileSelected = null;
  let loaderFile = null;

  /**
   * Validates the properties of a file.
   * @param {Object} file - The file object to validate.
   * @param {string} file.type - The MIME type of the file.
   * @param {number} file.width - The width of the file in pixels.
   * @param {number} file.height - The height of the file in pixels.
   * @param {number} file.size - The size of the file in bytes.
   * @returns {(null|{code: string, message: string})} Returns `null` if the file is valid, otherwise returns an object with error code and message.
   */
  const filesValidator = (file) => {
    if (!acceptFileLogoRegex.test(file.type)) {
      return {
        code: "file-invalid-type-not-allowed",
        message: i18n.t("sticker.rejectedFileType"),
      };
    }

    if (
      file.width > enumsDropLogo.MAX_WIDTH ||
      file.height > enumsDropLogo.MAX_HEIGHT
    ) {
      return {
        code: "large-width",
        message: `${i18n.t("sticker.dimensionsFile1")}${
          enumsDropLogo.MAX_WIDTH
        }x${enumsDropLogo.MAX_HEIGHT}${i18n.t(
          "sticker.dimensionsFile3"
        )}${i18n.t("sticker.dimensionsFile2")}${file.width}x${
          file.height
        }${i18n.t("sticker.dimensionsFile3")}`,
      };
    }

    if (file.size > enumsDropLogo.MAX_SIZE) {
      return {
        code: "file-too-large-to-upload",
        message: `${i18n.t("recordDetail.filesToLarge")} ${"6KB"}`,
      };
    }

    return null;
  };

  /**
   * Callback function triggered when files are dropped or selected.
   * @param {File[]} acceptedFiles - Array of accepted files.
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileLogo(acceptedFiles);
      setIsValidatingFiles(false);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Custom hook to handle file dropping and validation using react-dropzone.
   * @param {Array} acceptedFiles - Array of accepted file types.
   * @param {Array} fileRejections - Array of rejected files.
   * @returns {{
   *    acceptedFiles: Array,
   *    fileRejections: Array,
   *    getRootProps: Function,
   *    getInputProps: Function,
   *  }} - Object containing properties and functions for managing file dropzone behavior.
   */
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedFilesLogo,
    maxSize: enumsDropLogo.MAX_SIZE,
    minSize: enumsDropLogo.MIN_SIZE,
    multiple: false,
    noDrag: true,
    validator: filesValidator,
    onDrop,
    onFileDialogCancel: () => {
      setIsValidatingFiles(false);
    },
  });

  /**
   * Maps an array of accepted files to JSX elements representing each file with an icon and file information.
   * @param {Array<Object>} acceptedFiles - An array of files accepted for upload.
   * @returns {Array<JSX.Element>} An array of JSX elements representing each accepted file with icon and information.
   */
  const acceptedFileItems = acceptedFiles.map((file) => {
    const { icon, className } = renderIconFile(file.name);
    return (
      <div key={file.path}>
        <strong>
          <em>
            <FontAwesomeIcon
              className={`icon-opacity fa-2x mr-2 ml-auto ${className}`}
              icon={icon}
            />
            {capitalizeText(file.name)} - {file.size} bytes
          </em>
        </strong>
      </div>
    );
  });

  /**
   * Creates an array of JSX elements representing rejected file items with error messages.
   * @param {Array<Object>} fileRejections - Array of objects representing rejected files and associated errors.
   * @param {Object} fileRejections.file - The rejected file object.
   * @param {Array<Object>} fileRejections.errors - Array of error objects associated with the rejected file.
   * @returns {Array<JSX.Element>} Array of JSX elements representing rejected file items.
   */
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    const { icon, className } = renderIconFile(file.name);
    return (
      <div key={file.path}>
        <Alert className="mbg-3" color="danger">
          <h6 className="font-weight-bold">{i18n.t("sticker.rejectedFile")}</h6>
          <FontAwesomeIcon
            className={`icon-opacity fa-2x mr-2 ml-auto ${className}`}
            icon={icon}
          />
          {capitalizeText(file.name)} - {file.size} bytes
          <ul>
            {errors.map((error) => {
              if (
                error.code === "file-invalid-type" ||
                error.code === "file-too-large" ||
                error.code === "file-too-small"
              ) {
                return null;
              } else {
                return (
                  <li key={error.code}>
                    <em className="font-weight-bold">{`(${error.message})`}</em>
                  </li>
                );
              }
            })}
          </ul>
        </Alert>
      </div>
    );
  });

  /**
   * Generates a loader or a message based on the validation status of files.
   * If validation is ongoing, a loader is displayed. Otherwise, a message is shown.
   * @param {boolean} isValidatingFiles - Indicates whether file validation is in progress.
   * @returns {JSX.Element|string} - Loader or message element based on validation status.
   */
  if (isValidatingFiles === true) {
    loaderFile = (
      <div className="justify-content-center align-items-center color-gray">
        <Loader color="gray" type="ball-beat" size="sm" />
      </div>
    );
  } else {
    loaderFile = i18n.t("sticker.noFileSelected");
  }

  /**
   * Update the state variables based on the accepted file items.
   * If there are accepted file items, sets fileHelper to null and fileSelected to the accepted file items.
   * If there are no accepted file items, sets fileSelected to loaderFile and fileHelper to a helper component displaying a message.
   * @param {Array} acceptedFileItems - The array of accepted file items.
   */
  if (acceptedFileItems.length > 0) {
    fileHelper = null;
    fileSelected = <span>{acceptedFileItems}</span>;
  } else {
    fileSelected = loaderFile;
    fileHelper = (
      <Row>
        <div className="mb-2 mt-2 justify-content-center align-items-center color-gray">
          <em>&nbsp;{i18n.t("sticker.aceptedFile")}</em>
        </div>
      </Row>
    );
  }

  return (
    <section>
      <Row
        className={cx("", {
          "mb-4": fileHelper === null,
        })}
      >
        <Col md="auto">
          <div
            {...getRootProps({
              className: "dropzone",
              onClick: () => setIsValidatingFiles(true),
              onCancel: () => {
                setIsValidatingFiles(false);
              },
            })}
          >
            <input {...getInputProps()} />
            <Button type="button" className="mr-2">
              {i18n.t("sticker.buttonFileSelected")}
            </Button>
          </div>
        </Col>
        <Col md="auto">{fileSelected}</Col>
      </Row>
      {fileHelper}
      {fileRejectionItems}
    </section>
  );
};

export default DropZoneLogo;
