import i18n from "locales/i18n";
import { getMonth } from "date-fns";
export function getDateFormat3(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;

  const months = [
    i18n.t("datapicker.Month1"),
    i18n.t("datapicker.Month2"),
    i18n.t("datapicker.Month3"),
    i18n.t("datapicker.Month4"),
    i18n.t("datapicker.Month5"),
    i18n.t("datapicker.Month6"),
    i18n.t("datapicker.Month7"),
    i18n.t("datapicker.Month8"),
    i18n.t("datapicker.Month9"),
    i18n.t("datapicker.Month10"),
    i18n.t("datapicker.Month11"),
    i18n.t("datapicker.Month12"),
  ];
  return (
    date.getDate() +
    ` ${i18n.t("ofText")} ` +
    months[getMonth(date)] +
    ` ${i18n.t("ofText")} ` +
    date.getFullYear() +
    ` ${i18n.t("at")} ` +
    strTime
  );
}
