import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Card, CardFooter, CardBody } from "reactstrap";
import MetricsRecordsListsTable from "components/organism/MetricsRecordsListsTable";
import useDashboard from "hooks/useDashboard";
import i18n from "locales/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const MetricsRecordList = () => {
  const [recordsForm, setRecordsForm] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const currentYear = new Date().getFullYear();
  const [year] = useState(currentYear);

  const { getRecordsByForm } = useDashboard();

  /**
   *Fetches records by year using the 'getRecordsByForm' API function and updates the component state accordingly.
   *@param {number} year - The year for which records need to be retrieved.
   *@param {number} pagination.page - The current page number for pagination.
   *@param {number} pagination.per_page - The number of records per page for pagination.
   *@param {function} setIsLoading - The state setter function to indicate if data is currently being fetched (loading state).
   *@param {function} setRecordsForm - The state setter function to update the records associated with the form for the specified year.
   *@param {function} setTotalPages - The state setter function to update the total number of pages available based on the retrieved records.
   *@param {function} getRecordsByForm - The API function responsible for fetching records by year.
   */
  const getRecordsByYear = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoading(true);
    getRecordsByForm(year, -1, page, per_page)
      .then((response) => {
        setRecordsForm(response.data.items);
        setTotalPages(response.data.pages);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that invokes the 'getRecordsByYear' function to fetch and handle records based on a specific year.
   *@function
   *@name useEffectFetchRecordsByYear
   *@param {function} getRecordsByYear - The function responsible for fetching and handling records based on a specific year.
   */
  useEffect(() => {
    getRecordsByYear();
  }, [getRecordsByYear]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <Button
              className="btn-icon col-mt-3 mr-3"
              color="cyan"
              onClick={function () {
                window.location = "/metrics";
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("metrics.RecordsByformBack")}
            </Button>

            <Button className="btn-icon" color="success" disabled>
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              {i18n.t("metrics.RecordsByformYear")} {year}
            </Button>
          </CardFooter>

          <CardBody>
            <MetricsRecordsListsTable
              recordsForm={recordsForm}
              pagination={pagination}
              isLoading={isLoading}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default MetricsRecordList;
