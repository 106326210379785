import React, { useCallback } from "react";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Button,
  ModalFooter,
} from "reactstrap";
import i18n from "locales/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { enumsTypeStatusUser } from "utils/enums";
import { initialPaginationGetUsers } from "utils/initialPaginationsConfig";
import {
  showAlertServiceError,
  showAlertServiceResponsibleUser,
} from "utils/alerts";
import useWorkFlow from "hooks/useWorkFlow";
import useUser from "hooks/useUser";
import swal from "sweetalert";

const ModalGateWay = (props) => {
  const {
    showModalGateWay,
    setShowModalGateWay,
    nameModalGateWay,
    labelsSequenceFlow,
    fulfillProcesses,
    handleOnCloseFulfillProcess,
    listWorkflowSteps,
    isProcessBatch,
    fulfillProcessesBatch,
    formDataFullfilBatch,
  } = props;
  const {
    workflowId,
    complyProcessGateWay,
    setIsLoadingComplyProcessBatch,
    setComplyProcessGateWay,
    setSelectLabelSequenceFlow,
    setResponsibleUser,
    setResponsibleList,
    setUsersDinamic,
    setShowModalReasign,
    setDropdownOpen,
  } = useWorkflowContext();
  const { getListResponsibles } = useWorkFlow();
  const { getUsers } = useUser();

  /**
   * This function gets the responsible user or group for a given workflow step ID and sets the state accordingly.
   * Show the modal Reasing acording to the case
   * @param {function} getListResponsibles - The function that is executed to get the users responsible by ID.
   * @param {object} typeUsers - The object of the type of users.
   * @param {object} userResponsibleList - The object of the responsible list.
   * @param {object} complyProcessGateWay - The object of the elements for acomplish the task acoirding to the desition.
   * @param {function} fulfillProcesses - The function that is executed to acomplish the task.
   * @param {function} getUsers - The function that is executed to get users for asignation.
   * @param {string} nextStepId - The ID of the next step in the workflow.
   * @param {string} workflowId - The ID of the workflow.
   * @returns {void}
   */
  const getWorkFlowResponsibleById = useCallback(
    (nextStepId, decision) => {
      getListResponsibles(workflowId, nextStepId).then((response) => {
        if (isNullOrUndefined(response) === false) {
          let typeUsers = response.data.type_user_responsible;
          let userResponsibleList = response.data.user_responsible_list;

          if (isNullOrUndefined(userResponsibleList) === false) {
            if (userResponsibleList.length === 0) {
              showAlertServiceResponsibleUser();
            }
          }

          if (typeUsers === 1) {
            let indexDecision = null;
            if (decision === 0) {
              indexDecision = labelsSequenceFlow[0].next_step_uuid;
            } else {
              indexDecision = labelsSequenceFlow[1].next_step_uuid;
            }

            let complyProcessGateWay = {
              status: 2,
              next_user_uuid: userResponsibleList.toString(),
              next_step_uuid: indexDecision,
            };

            if (isProcessBatch === true) {
              formDataFullfilBatch.append(
                "select_responsible",
                userResponsibleList.toString()
              );
              formDataFullfilBatch.append("select_step", indexDecision);
              setIsLoadingComplyProcessBatch(true);
              fulfillProcessesBatch(formDataFullfilBatch);
            } else {
              fulfillProcesses(complyProcessGateWay);
            }

            setResponsibleUser(userResponsibleList.toString());
          } else {
            setResponsibleList(userResponsibleList);
          }

          if (typeUsers === 2) {
            setShowModalReasign(true);
          }

          if (typeUsers === 3) {
            let indexDecisionByGroup = null;
            if (decision === 0) {
              indexDecisionByGroup = labelsSequenceFlow[0].next_step_uuid;
            } else {
              indexDecisionByGroup = labelsSequenceFlow[1].next_step_uuid;
            }
            let complyProcessBygroup = {
              status: 2,
              next_user_uuid: null,
              next_step_uuid: indexDecisionByGroup,
            };
            if (isProcessBatch === true) {
              formDataFullfilBatch.append("select_step", nextStepId);
              setIsLoadingComplyProcessBatch(true);
              fulfillProcessesBatch(formDataFullfilBatch);
            } else {
              fulfillProcesses(complyProcessBygroup);
            }
          }
          let search = "";
          getUsers(
            initialPaginationGetUsers.page,
            initialPaginationGetUsers.per_page,
            search
          ).then((response) => {
            if (isNullOrUndefined(response) === false) {
              let usersList = response.data.items;
              usersList.forEach((user) => {
                if (
                  user.status !== enumsTypeStatusUser.RETIRED &&
                  user.status !== enumsTypeStatusUser.INACTIVE
                ) {
                  setUsersDinamic((prevState) => [
                    ...prevState,
                    {
                      value: user.uuid,
                      label: `${user.first_name} ${user.last_name} (${user.user_name})`,
                    },
                  ]);
                }
              });
            } else {
              showAlertServiceError();
            }
          });
        } else {
          showAlertServiceError();
        }
      });
    },
    [workflowId, formDataFullfilBatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Function to handle the submit for the fist button in modal
   * @param {string} modal - Modal showAlertShure, for confirmation of selection of desition.
   * @param {function} getWorkFlowResponsibleById - The function for get the responsibles in the next step.
   * @returns {Promise} - A promise that resolves to the server's response.
   */
  const handleButtonClick = (
    eventGateWayOutput,
    gateWayOutputSelectedParam
  ) => {
    setShowModalGateWay(false);
    const showAlertShure = () => {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("taskComply"),
        icon: "warning",
        buttons: [
          i18n.t("createusers.createButton2"),
          i18n.t("modal.Done.footerButton"),
        ],
      }).then((willClose) => {
        if (willClose) {
          let gateWayOutputSelected;
          setSelectLabelSequenceFlow(true);
          if (gateWayOutputSelectedParam === 0) {
            gateWayOutputSelected = labelsSequenceFlow[0].next_step_uuid;
          } else {
            gateWayOutputSelected = labelsSequenceFlow[1].next_step_uuid;
          }

          if (
            listWorkflowSteps.some(
              (step) =>
                step.type_name === "END_EVENT" &&
                step.uuid === gateWayOutputSelected
            )
          ) {
            if (isProcessBatch === true) {
              formDataFullfilBatch.append("select_step", gateWayOutputSelected);
              setIsLoadingComplyProcessBatch(true);
              fulfillProcessesBatch(formDataFullfilBatch);
            } else {
              fulfillProcesses({
                ...complyProcessGateWay,
                status: 2,
                next_user_uuid: null,
                next_step_uuid: gateWayOutputSelected,
              });
            }
          } else {
            setComplyProcessGateWay({
              ...complyProcessGateWay,
              next_step_uuid: gateWayOutputSelected,
            });
            getWorkFlowResponsibleById(
              gateWayOutputSelected,
              gateWayOutputSelectedParam
            );
          }
        } else {
          eventGateWayOutput.preventDefault();
        }
      });
    };
    showAlertShure();
  };

  return (
    <Modal
      className="modal-custom"
      isOpen={showModalGateWay}
      toggle={handleOnCloseFulfillProcess}
      backdrop={true}
      keyboard={true}
    >
      <ModalHeader>
        <Label>{nameModalGateWay}</Label>
      </ModalHeader>

      <ModalBody>
        <Alert className="mbg-3" color="info" isOpen={true}>
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>

          {i18n.t("createWorkflow.fullFill.modalGateway")}
        </Alert>{" "}
        <br />
        <div className="text-center">
          <Button
            type="submit"
            size="lg"
            className="col-mt-3 mr-2"
            color="cyan"
            onClick={(eventGateWayOutput) => {
              handleButtonClick(eventGateWayOutput, 0);
            }}
          >
            {(() => {
              if (labelsSequenceFlow) {
                return labelsSequenceFlow[0].name;
              }
            })()}
          </Button>
          <Button
            type="submit"
            size="lg"
            className="col-mt-3 mr-2"
            color="success"
            onClick={(eventGateWayOutput) => {
              handleButtonClick(eventGateWayOutput, 1);
            }}
          >
            {(() => {
              if (labelsSequenceFlow) {
                return labelsSequenceFlow[1].name;
              }
            })()}
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          size="lg"
          className="col-mt-3 mr-2"
          color="gray"
          onClick={() => {
            setDropdownOpen(false);
            setShowModalGateWay(false);
          }}
        >
          {i18n.t("createusers.createButton2")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalGateWay;
