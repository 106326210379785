import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody } from "reactstrap";
import ReactTable from "react-table";
import ButtonActionsWorkflow from "components/atoms/ButtonActionsWorkflow";
import i18n from "locales/i18n";
import { getDateFormat2 } from "utils/getDateFormat2";

const WorkflowListTable = (props) => {
  const {
    listWorkflows,
    pagination,
    setPagination,
    isLoadingWorkflowList,
    totalPages,
  } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={listWorkflows}
            columns={[
              {
                columns: [
                  {
                    Header: i18n.t("createWorflow.Label1"),
                    accessor: "name",
                  },
                  {
                    Header: i18n.t("createWorflow.Label2"),
                    accessor: "user_name",
                  },
                  {
                    Header: i18n.t("form.designerLabel3"),
                    accessor: "status",
                    Cell: ({ value }) => {
                      let statusFinal = "";
                      if (value === 1) {
                        statusFinal = (
                          <div className="ml-auto badge badge-warning">
                            {i18n.t("filterFormState1")}
                          </div>
                        );
                      } else if (value === 2) {
                        statusFinal = (
                          <div className="ml-auto badge badge-success">
                            {i18n.t("filteruser.item2")}
                          </div>
                        );
                      } else if (value === 3) {
                        statusFinal = (
                          <div className="ml-auto badge badge-danger">
                            {i18n.t("filteruser.item3")}
                          </div>
                        );
                      }
                      return <span>{statusFinal}</span>;
                    },
                  },
                  {
                    Header: i18n.t("itemList.designerLabel3"),
                    accessor: "created_at",

                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    },
                  },
                  {
                    Header: i18n.t("form.field47"),
                    accessor: "updated_at",
                    Cell: ({ value }) => {
                      if (value) {
                        let valueFinal = getDateFormat2(new Date(value));
                        return <span>{valueFinal}</span>;
                      } else {
                        let valueFinal = "";
                        return <span>{valueFinal}</span>;
                      }
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    Header: i18n.t("buttonActions.title"),
                    accessor: "actions",
                    Cell: (row) => {
                      return (
                        <ButtonActionsWorkflow
                          formId={row.original.form_uuid}
                          statusProcess={row.original.status}
                          workflowId={row.original.uuid}
                          processName={row.original.name}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingWorkflowList}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkflowListTable.propTypes = {
  listWorkflows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingWorkflowList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default WorkflowListTable;
