import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserRolConfig from "components/organism/UserRolConfig";
import i18n from "locales/i18n";

const UserRolesConfig = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("createRoles.configTitle")}
        subheading={i18n.t("createRoles.configSubTitle")}
        icon="note2"
      >
        <UserRolConfig />
      </AdminTemplate>
    </Fragment>
  );
};

export default UserRolesConfig;
