import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import {
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { capitalizeText } from "utils/formatText";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { enumShowOptionsTabWorkflow, enumsFilterWorkflowState } from "utils/enums";
import useWorkFlow from "hooks/useWorkFlow";
import classnames from "classnames";
import WorkFlowConfigStepsTab1Responsible from "components/organism/WorkFlowConfigStepsTab1Responsible";
import WorkFlowConfigStepsTab2OtherActions from "components/organism/WorkFlowConfigStepsTab2OtherActions";
import WorkFlowConfigStepsTab3FormData from "components/organism/WorkFlowConfigStepsTab3FormData";
import WorkFlowAddFields from "components/organism/WorkFlowAddFields";
import WorkflowFilingFormsInStep from "components/organism/WorkflowFillingFormInStep";
import swal from "sweetalert";
import i18n from "locales/i18n";

const WorkFlowConfigSteps = () => {
  const { id, workflowid, hash, formId } = useParams();
  const {
    getWorkFlowBySearch,
    getWorkFlowResponsibleById,
    typeResponsibleUser,
    loader,
    handleOnChangeOption,
    responsibleOption,
    userStep,
    showOptions,
    setShowOptions,
    reassign,
    handleOnChangeTab,
    handleOnSubmitActions,
    isLoading,
    responsibleUser,
    setUserStep,
    setLoader,
    setUsersDinamicEdit,
    responsibleList,
    listReal,
    setListReal,
    showModal,
    handleOnClick,
    handleOnClose,
    usersDinamicEdit,
    usersDinamic,
    selectedOption,
    setShowModal,
    isError,
    setIsLoading,
    setIsError,
    typeResponsibleGroup,
    nameGroup,
    handleOnClickEditWorkGroup,
    showModalGroup,
    setShowModalGroup,
    viewAddFields,
    fillingOutForm,
    setFormIdInfo,
    showExternalFormEdit,
  } = useWorkflowContext();
  const [workflowStatus, setWorkflowStatus] = useState();
  const { deleteResponsibles, getWorkFlowById } = useWorkFlow();
  let disabledOptionSelectProcess = null;
  let responsibleOptionDisabled = null;
  let disabledOptionByStatusProcess = null;
  let renderActionsFormsWorkflow = null;

  /**
   * Retrieves and processes workflow permissions from local storage.
   * @returns {string} workflowAllPermissions - All workflow form permissions as a comma-separated string.
   * @returns {string} workflowSomePermissions - Some specific workflow form permissions as a comma-separated string.
   * @returns {Array<string>} permissionsGranted - An array of permissions granted, obtained by splitting workflowSomePermissions.
   */
  const workflowAllPermissions =
    window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const workflowSomePermissions = window.localStorage.getItem(
    WORKFLOW_FORMS_PERMISSIONS
  );
  const permissionsGranted = workflowSomePermissions.split(",");

  /**
   * Copies the first element of the 'userStep' array into a new object,
   * and decodes and parses a hashed process information string.
   * @returns {Object} Returns an object containing the result of the decoding and parsing operations.
   */
  const userActive = Object.assign({}, userStep[0]);
  const unHashProcessInfo = window.atob(hash);
  const objectProcessInfo = JSON.parse(unHashProcessInfo);

  /**
   * Update options based on the workflow status and loading state.
   * @param {number} workflowStatus - The current workflow status. Should be 1 or a different number.
   * @param {boolean} isLoading - A boolean indicating whether data is currently loading.
   * @returns {void}
   */
  if (workflowStatus === enumsFilterWorkflowState.STATUS_ERASER) {
    disabledOptionByStatusProcess = false;
    disabledOptionSelectProcess = false;
  } else if (workflowStatus !== enumsFilterWorkflowState.STATUS_ERASER || isLoading) {
    disabledOptionByStatusProcess = true;
    disabledOptionSelectProcess = true;
  }

  /**
   * Update the responsibleOptionDisabled flag based on the value of responsibleOption.responsible.
   * @param {Object} responsibleOption - The responsibleOption object.
   * @param {string} responsibleOption.responsible - The responsible value to check.
   * @returns {void}
   */
  if (responsibleOption.responsible === "") {
    responsibleOptionDisabled = true;
  } else {
    responsibleOptionDisabled = false;
  }

  /**
   * Handles the deletion of a responsible entity with a confirmation dialog.
   * @param {Event} eventOnDelete - The event object triggered when attempting to delete the responsible.
   * @returns {void}
   */
  const handleOnDeleteResponsible = (eventOnDelete) => {
    eventOnDelete.preventDefault();
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("WorkFlowEdit.delete"),
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willClose) => {
      if (willClose) {
        if (
          userStep.length !== 0 ||
          listReal.length !== 0 ||
          nameGroup !== ""
        ) {
          deleteResponsibles(workflowid, id)
            .then((response) => {
              if (response.status === 202) {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("WorkFlowEdit.deleteOk"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(() => {
                  window.location.reload();
                });
              } else {
                showAlertServiceError();
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else {
        eventOnDelete.preventDefault();
        setIsLoading(false);
      }
    });
  };

  /**
   * A React useEffect hook that retrieves and updates the status of a workflow by its ID.
   *{string} workflowid - The unique identifier of the workflow to retrieve the status for.
   * @param {function} getWorkFlowById - A function that retrieves workflow information by its ID.
   * @param {function} setWorkflowStatus - A state setter function for updating the workflow status.
   * @param {function} showAlertServiceError - A function to display an alert or handle errors if the workflow status is null or undefined.
   * @returns {void}
   */
  useEffect(() => {
    getWorkFlowById(workflowid).then((response) => {
      const statusWorkflow = response.data.data.status;
      if (isNullOrUndefined(statusWorkflow) === false) {
        setWorkflowStatus(statusWorkflow);
      } else {
        showAlertServiceError();
      }
    });
  }, [workflowid]); // eslint-disable-line react-hooks/exhaustive-deps

  /** converts the hash of the formId to a readable object */
  useEffect(() => {
    const unHashFormId = window.atob(formId);
    const formIdObjet = JSON.parse(unHashFormId);
    setFormIdInfo(formIdObjet);
  }, [formId, setFormIdInfo]);

  /**
   *useEffect hook that checks if a given workflow id is valid and if not, navigates the user back to the previous page in their browser history.
   *@param {number} workflowid - the id of the workflow to check
   *@param {string} workflowAllPermissions - a string representing a boolean value indicating whether all workflow forms should be displayed
   *@param {array} permissionsGranted - an array of the ids of the new workflow forms to be displayed
   */
  useEffect(() => {
    if (workflowid) {
      if (
        workflowAllPermissions === "false" &&
        !permissionsGranted.includes(workflowid)
      ) {
        window.history.back();
      }
    }
  }, [workflowid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that fetches workflow and workflow responsible data for a given workflow id and sets the corresponding state variables of the component.
   *@param {number} workflowid - the id of the workflow to fetch data for
   *@param {number} id - the id of the user responsible for the workflow
   *@param {function} getWorkFlowBySearch - the function that fetches the workflow data by its id and sets the corresponding state variable
   *@param {function} getWorkFlowResponsibleById - the function that fetches the responsible user data by its id and sets the corresponding state variable
   */
  useEffect(() => {
    getWorkFlowBySearch(workflowid);
    getWorkFlowResponsibleById(workflowid, id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that updates the state of the component based on changes to the 'usersDinamic', 'responsibleUser', and 'responsibleList' props.
   *@param {array} usersDinamic - an array of objects representing the users associated with the component
   *@param {string} responsibleUser - a string representing the value of the responsible user
   *@param {array} responsibleList - an array of strings representing the values of the responsible users
   *@param {function} setUserStep - the state setter function for the responsible user of the component
   *@param {function} setUsersDinamicEdit - the state setter function for the users associated with the component, after removing the responsible user
   *@param {function} setListReal - the state setter function for the users associated with the component that are also in the responsible list
   *@param {function} setLoader - the state setter function for the loading state of the component
   */
  useEffect(() => {
    if (usersDinamic.length !== 0 && responsibleUser !== "") {
      const respUsr = usersDinamic.filter((user) => {
        return user.value === responsibleUser;
      });
      if (usersDinamic.length !== 0 && respUsr.length !== 0) {
        const editRespUsr = usersDinamic.filter((user) => {
          return user.value !== respUsr[0].value;
        });
        setUserStep(respUsr);
        setUsersDinamicEdit(editRespUsr);
        setLoader(false);
      }
    }
    if (
      usersDinamic.length !== 0 &&
      isNullOrUndefined(responsibleList) === false
    ) {
      if (responsibleList.length !== 0) {
        const filteredList = usersDinamic.filter((user) => {
          return responsibleList.includes(user.value);
        });
        const uniqueList = [...new Set(filteredList)];
        setListReal(uniqueList);
        setLoader(false);
      }
    }
  }, [usersDinamic, responsibleUser, responsibleList]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders different workflow configuration steps based on the selected option.
   * @param {enumShowOptionsTabWorkflow} showOptions - The selected workflow configuration tab.
   * @param {Object} objectProcessInfo - Information about the process.
   * @param {string} workflowStatus - The current status of the workflow.
   * @param {string} nameGroup - The name of the group.
   * @param {function} handleOnClickEditWorkGroup - Function to handle click event for editing a work group.
   * @param {boolean} disabledOptionByStatusProcess - Flag to disable options based on the process status.
   * @param {function} handleOnDeleteResponsible - Function to handle the deletion of a responsible user.
   * @param {boolean} showModal - Flag to show or hide a modal.
   * @param {function} setShowModal - Function to set the state of showModal.
   * @param {function} handleOnClose - Function to handle the closing of a modal.
   * @param {string} id - The ID of the workflow step.
   * @param {string} workflowid - The ID of the workflow.
   * @param {string} userStep - The current user step.
   * @param {boolean} userActive - Flag to indicate if the user is active.
   * @param {Array} usersDinamicEdit - List of dynamic users for editing.
   * @param {Array} usersDinamic - List of dynamic users.
   * @param {string} typeResponsibleGroup - The type of responsible group.
   * @param {string} typeResponsibleUser - The type of responsible user.
   * @param {Array} responsibleList - The list of responsible users.
   * @param {string} responsibleOption - The selected responsible option.
   * @param {boolean} responsibleOptionDisabled - Flag to disable the responsible option.
   * @param {string} selectedOption - The selected option.
   * @param {boolean} showModalGroup - Flag to show or hide a group modal.
   * @param {function} setShowModalGroup - Function to set the state of showModalGroup.
   * @param {Array} listReal - The real list.
   * @param {function} handleOnClick - Function to handle click events.
   * @param {function} handleOnChangeOption - Function to handle option change events.
   * @param {boolean} loader - Flag to show or hide a loader.
   * @param {boolean} isError - Flag to indicate if there is an error.
   * @param {function} setIsError - Function to set the state of isError.
   * @param {boolean} isLoading - Flag to indicate if loading is in progress.
   * @param {function} setIsLoading - Function to set the state of isLoading.
   * @param {function} handleOnSubmitActions - Function to handle submission of actions.
   * @param {function} handleOnChangeTab - Function to handle tab change events.
   * @param {boolean} reassign - Flag to indicate if reassignment is possible.
   * @param {boolean} disabledOptionSelectProcess - Flag to disable the option selection process.
   * @returns {JSX.Element} The rendered component based on the selected workflow configuration tab.
   */
  if (showOptions === enumShowOptionsTabWorkflow.CONFIG_RESPONSIBLE) {
    renderActionsFormsWorkflow = (
      <WorkFlowConfigStepsTab1Responsible
        objectProcessInfo={objectProcessInfo}
        workflowStatus={workflowStatus}
        nameGroup={nameGroup}
        handleOnClickEditWorkGroup={handleOnClickEditWorkGroup}
        disabledOptionByStatusProcess={
          disabledOptionByStatusProcess
        }
        handleOnDeleteResponsible={handleOnDeleteResponsible}
        showModal={showModal}
        setShowModal={setShowModal}
        handleOnClose={handleOnClose}
        id={id}
        workflowid={workflowid}
        userStep={userStep}
        userActive={userActive}
        usersDinamicEdit={usersDinamicEdit}
        usersDinamic={usersDinamic}
        typeResponsibleGroup={typeResponsibleGroup}
        typeResponsibleUser={typeResponsibleUser}
        responsibleList={responsibleUser}
        responsibleOption={responsibleOption}
        responsibleOptionDisabled={responsibleOptionDisabled}
        selectedOption={selectedOption}
        showModalGroup={showModalGroup}
        setShowModalGroup={setShowModalGroup}
        listReal={listReal}
        handleOnClick={handleOnClick}
        handleOnChangeOption={handleOnChangeOption}
        loader={loader}
        isError={isError}
        setIsError={setIsError}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
    );
  } else if (showOptions === enumShowOptionsTabWorkflow.CONFIG_ANOTHER_OPTIONS) {
    renderActionsFormsWorkflow = (
      <WorkFlowConfigStepsTab2OtherActions
        handleOnSubmitActions={handleOnSubmitActions}
        workflowStatus={workflowStatus}
        handleOnChangeTab={handleOnChangeTab}
        reassign={reassign}
        disabledOptionByStatusProcess={
          disabledOptionByStatusProcess
        }
        disabledOptionSelectProcess={disabledOptionSelectProcess}
        isLoading={isLoading}
      />
    );
  } else {
    renderActionsFormsWorkflow = (
      <WorkFlowConfigStepsTab3FormData
        objectProcessInfo={objectProcessInfo}
        workflowStatus={workflowStatus}
      />
    );
  }

  if (
    viewAddFields === false &&
    fillingOutForm === false &&
    showExternalFormEdit === false
  ) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card>
            <CardBody>
              <Row>
                <Col lg="5" md="4" sm="12" className="button-container">
                  <h5 className="text-info font-weight-bold">
                    {capitalizeText(i18n.t("createWorkflow.config.step"))}:
                    <span className="text-dark ml-2">
                      {capitalizeText(objectProcessInfo.name)}
                    </span>
                  </h5>
                </Col>
                <Col
                  lg="7"
                  md="8"
                  sm="12"
                  className="button-container mt-2 mt-md-0"
                >
                  <Row className="row justify-content-end">
                    <Button
                      className="btn-icon mr-2"
                      color="cyan"
                      onClick={() => window.history.back()}
                    >
                      <FontAwesomeIcon
                        icon={faArrowAltCircleLeft}
                        className="mr-2"
                      />
                      {i18n.t("createWorkflow.config.back")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="pt-0">
              <Row>
                <div size="sm" className="mb-2">
                  <Button
                    className={classnames("tab-button", {
                      active: showOptions === enumShowOptionsTabWorkflow.CONFIG_RESPONSIBLE,
                    })}
                    onClick={() => setShowOptions(enumShowOptionsTabWorkflow.CONFIG_RESPONSIBLE)}
                    color="cyan"
                  >
                    {i18n.t("createWorkflow.config.responsible")}
                  </Button>

                  <Button
                    className={classnames("tab-button", {
                      active: showOptions === enumShowOptionsTabWorkflow.CONFIG_ANOTHER_OPTIONS,
                    })}
                    onClick={() => setShowOptions(enumShowOptionsTabWorkflow.CONFIG_ANOTHER_OPTIONS)}
                    color="cyan"
                  >
                    {i18n.t("createWorkflow.config.otherActions")}
                  </Button>

                  <Button
                    className={classnames("tab-button", {
                      active: showOptions === enumShowOptionsTabWorkflow.CONFIG_FORM_DATA,
                    })}
                    onClick={() => setShowOptions(enumShowOptionsTabWorkflow.CONFIG_FORM_DATA)}
                    color="cyan"
                  >
                    {i18n.t("createWorkflow.configurations.fields1")}
                  </Button>
                </div>
              </Row>
              {renderActionsFormsWorkflow}
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  } else if (viewAddFields === true) {
    return <WorkFlowAddFields />;
  } else if (fillingOutForm === true) {
    return <WorkflowFilingFormsInStep />;
  } else if (showExternalFormEdit === true) {
    return <WorkflowFilingFormsInStep />;
  }
};

export default WorkFlowConfigSteps;
