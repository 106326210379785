import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import DetailIntegrationView from "components/organism/DetailIntegrationView";

const Integrations = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("integrations.nav")}
        subheading={i18n.t("integrations.motto")}
        icon="keypad"
      >
        <DetailIntegrationView />
      </AdminTemplate>
    </Fragment>
  );
};

export default Integrations;
