import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_TRD } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useTrd() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  /*Get the general tree of the TRD */
  const getTreeByDomain = async () => {
    const response = await axios.get(`${URL_TRD}/trd/tree/`,
      {
        headers,
        endpointError: endpointError.trd.getTreeByDomain
      });
    return response;
  };

  /*Get the expedients tree of the TRD */
  const getTreeExpedientByDomain = async (backgroundId, expedientId) => {
    const response = await axios.get(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/expedients/${expedientId}/tree/`,
      {
        headers,
        endpointError: endpointError.trd.getTreeExpedientByDomain
      }
    );
    return response;
  };

  /**
   * Retrieves expedients based on the provided parameters.
   * @param {number} page - The page number for pagination.
   * @param {number} per_page - The number of items per page.
   * @param {string} parentId - The ID of the parent series or subseries.
   * @param {string} backgroundId - The ID of the background.
   * @param {string} search - (Optional) Search query for filtering expedients by name, subject, or code.
   * @param {string} status - (Optional) Status for filtering expedients.
   * @returns {Promise<Object>} A Promise that resolves with the data of the retrieved expedients.
   */
  const getExpedients = async (
    page,
    per_page,
    parentId,
    backgroundId,
    search,
    status
  ) => {
    if (search) {
      const response = await axios.get(
        `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${parentId}/expedients/?page=${page}&per_page=${per_page}&name=${search}&subject=${search}&count_files=true&code=${search}
        `,
        {
          headers,
          endpointError: endpointError.trd.getExpedients
        }
      );
      return response.data;
    } else if (status) {
      const response = await axios.get(
        `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${parentId}/expedients/?page=${page}&per_page=${per_page}&status=${status}`,
        {
          headers,
          endpointError: endpointError.trd.getExpedients
        }
      );
      return response.data;
    } else {
      const response = await axios.get(
        `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${parentId}/expedients/?page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.trd.getExpedients
        }
      );
      return response.data;
    }
  };

  /*Get the list of expedients created in the TRD */
  const getExpedientsByName = async (
    page,
    per_page,
    parentId,
    backgroundId,
    search
  ) => {
    const response = await axios.get(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${parentId}/expedients/?page=${page}&per_page=${per_page}&name=${search}&subject=${search}&count_files=true`,
      {
        headers,
        endpointError: endpointError.trd.getExpedientsByName
      }
    );
    return response.data;
  };

  /*Get the list of files by expedient in the TRD */
  const getFilesByExpedient = async (page, per_page, expedientId, search) => {
    const response = await axios.get(
      `${URL_TRD}/file/trd/expedient/${expedientId}/files/?page=${page}&per_page=${per_page}&name=${search}&subject=${search}`,
      {
        headers,
        endpointError: endpointError.trd.getFilesByExpedient
      }
    );
    return response.data;
  };

  /*Create a new background */
  const createFund = async (element) => {
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/`,
      { name: element },
      {
        headers,
        endpointError: endpointError.trd.createFund
      }
    );
    return response;
  };

  /*Create a new Section */
  const createSection = async (section, backgroundId) => {
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/sections/`,
      section,
      {
        headers,
        endpointError: endpointError.trd.createSection
      }
    );
    return response;
  };

  /*Create a new SubSection*/
  const createSubSectionBySection = async (data, foldersId) => {
    const { backgroundId, sectionId } = foldersId;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/sections/${sectionId}/subsections/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createSubSectionBySection
      }
    );
    return response;
  };

  /*Create a new Series */
  const createSerieBySectionOrSubSection = async (
    data,
    foldersId,
    parentData
  ) => {
    const { backgroundId } = foldersId;
    const { id } = parentData;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/sections-subsections/${id}/series/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createSerieBySectionOrSubSection
      }
    );
    return response;
  };

  /*Create a new SubSeries */
  const createSubserieBySerie = async (data, foldersId, parentData) => {
    const { backgroundId } = foldersId;
    const { id } = parentData;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series/${id}/subseries/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createSubserieBySerie
      }
    );
    return response;
  };

  /*Create a new Expedient */
  const createExpedient = async (data, foldersId, expedientParent) => {
    const { backgroundId } = foldersId;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${expedientParent}/expedients/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createExpedient
      }
    );
    return response;
  };

  /*Create a new Subdivision */
  const createSubDivision = async (data, foldersId) => {
    const { backgroundId, expedientId } = foldersId;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/expedients/${expedientId}/subdivisions/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createSubDivision
      }
    );
    return response;
  };

  /*Create a new Directory */
  const createDirectory = async (data, foldersId) => {
    const { backgroundId, subDivisionId } = foldersId;
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/subdivisions/${subDivisionId}/directories/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createDirectory
      }
    );
    return response;
  };

  /*Query to know if there is a trd created*/
  const getTreeExist = async () => {
    const response = await axios.get(`${URL_TRD}/trd/tree/exists/`,
      {
        headers,
        endpointError: endpointError.trd.getTreeExist
      }
    );
    return response.data;
  };

  /*Update an existing expedient */
  const updateExpedient = async (id, exp) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/expedients/${id}/`,
      exp,
      {
        headers,
        endpointError: endpointError.trd.updateExpedient
      }
    );
    return response;
  };

  /*Update an existing Documental Unit */
  /*This update service is not used has been deprecated*/
  const updateSubDivision = async (
    editSubDivisionForm,
    backgroundId,
    subDivisionId
  ) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/subdivisions/${subDivisionId}/`,
      editSubDivisionForm,
      {
        headers,
        endpointError: endpointError.trd.updateSubDivision
      }
    );
    return response;
  };

  /*Update an existing Directory */
  const updateDirectory = async (
    editDirectoryForm,
    backgroundId,
    subDivisionId,
    subDirectoryId
  ) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/subdivisions/${subDivisionId}/directories/${subDirectoryId}/`,
      editDirectoryForm,
      {
        headers,
        endpointError: endpointError.trd.updateDirectory
      }
    );
    return response;
  };

  /*Update an existing Section */
  const updateSection = async (data, section_uuid) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/sections/${section_uuid}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateSection
      }
    );
    return response;
  };

  /*Update an existing Series */
  const updateSerie = async (uuidSerie, data) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/series/${uuidSerie}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateSerie
      }
    );
    return response;
  };

  /*Create an documentary type */
  const createDocumentaryType = async (data) => {
    const response = await axios.post(
      `${URL_TRD}/trd/documentary-type/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createDocumentaryType
      }
    );
    return response;
  };

  /*Update an existing documentary type */
  const updateDocumentaryType = async (documentaryId, data) => {
    const response = await axios.put(
      `${URL_TRD}/trd/documentary-type/${documentaryId}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateDocumentaryType
      }
    );
    return response;
  };

  /*Update an existing SubSeries */
  const updateSubSerie = async (uuidSubSerie, data) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/sub-series/${uuidSubSerie}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateSubSerie
      }
    );
    return response;
  };

  /*Update an existing SubSection */
  const updateSubSection = async (data, subSection_uuid) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/subsections/${subSection_uuid}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateSubSection
      }
    );
    return response;
  };

  /*Update an existing background */
  const updateBackground = async (data, background_uuid) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/backgrounds/${background_uuid}/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateBackground
      }
    );
    return response;
  };

  /*Get the documental ordination tree of the TRD */
  const getSerieSubserieStructure = async (backgroundId, serieSubserieId) => {
    const response = await axios.get(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${serieSubserieId}/structure/`,
      {
        headers,
        endpointError: endpointError.trd.getSerieSubserieStructure
      }
    );
    return response;
  };

  /*Create a new documental unit to the structure in a series or subseries */
  const createSubdivisionByStructure = async (
    data,
    backgroundId,
    serieSubserieId
  ) => {
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${serieSubserieId}/structure/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createSubdivisionByStructure
      }
    );
    return response;
  };

  /*Create a new directory to the structure in a series or subseries */
  const createDirectoryByStructure = async (
    data,
    backgroundId,
    subdivisionId
  ) => {
    const response = await axios.post(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/base-subdivisions/${subdivisionId}/structure/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createDirectoryByStructure
      }
    );
    return response;
  };

  /*Update an existing Subdivision in the structure in a series or subseries */
  const updateSubdivisionStructure = async (
    data,
    backgroundId,
    serieSubserieId
  ) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${serieSubserieId}/structure/base-subdivisions/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.createDirectoryByStructure
      }
    );
    return response;
  };

  /*Update an existing directory in the structure in a series or subseries */
  const updateDirectoryStructure = async (
    data,
    backgroundId,
    serieSubserieId
  ) => {
    const response = await axios.patch(
      `${URL_TRD}/trd/backgrounds/${backgroundId}/series-subseries/${serieSubserieId}}/structure/base-directories/`,
      data,
      {
        headers,
        endpointError: endpointError.trd.updateDirectoryStructure
      }
    );
    return response;
  };

  /*Get the documental types of the TRD */
  const getDocumentalTypes = async (page, per_page, searchName, status) => {
    let responseDocumentalTypes;
    if (status !== "") {
      responseDocumentalTypes = await axios.get(
        `${URL_TRD}/trd/documentary-type/?page=${page}&per_page=${per_page}&name=${searchName}&status=${status}`,
        {
          headers,
          endpointError: endpointError.trd.getDocumentalTypes
        }
      );
    } else {
      responseDocumentalTypes = await axios.get(
        `${URL_TRD}/trd/documentary-type/?page=${page}&per_page=${per_page}&name=${searchName}`,
        {
          headers,
          endpointError: endpointError.trd.getDocumentalTypes
        }
      );
    }
    return responseDocumentalTypes;
  };

  /*Delete an existing level in the TRD*/
  const deleteLevelTrd = async (uuidLevel, enumLevel) => {
    const responseDeleteLevel = await axios.delete(
      `${URL_TRD}/trd/tree/${uuidLevel}/level/${enumLevel}/`,
      {
        headers,
        endpointError: endpointError.trd.deleteLevelTrd
      }
    );
    return responseDeleteLevel;
  };

  /*Delete an existing level in the structure*/
  const deleteLevelOrdinationTrd = async (
    serieSubserieId,
    uuidLevel,
    enumLevel
  ) => {
    const responseDeleteLevelOrdination = await axios.delete(
      `${URL_TRD}/trd/series-subseries/${serieSubserieId}/level/${uuidLevel}/${enumLevel}/`,
      {
        headers,
        endpointError: endpointError.trd.deleteLevelOrdinationTrd
      }
    );
    return responseDeleteLevelOrdination;
  };

  /**
   * Retrieves the path set for an expedient record identified by its UUID.
   * @param {string} recordUuid - The UUID of the expedient record for which to retrieve the path set.
   * @returns {Promise<any>} A promise that resolves to the data representing the path set of the expedient record.
   * @throws {Error} If an error occurs during the HTTP request or data retrieval.
   */
  const getPathSetExpedient = async (recordUuid) => {
    const response = await axios.get(
      `${URL_TRD}/trd/expedients/record/${recordUuid}/path/`,
      {
        headers,
        endpointError: endpointError.trd.getPathSetExpedient
      }
    );
    return response.data;
  };

  /**
   * Sets the path information data for an expedient identified by its UUID.
   * @param {object} expedientPathSet - The path information data to be set for the expedient.
   * @param {string} expedientUuid - The UUID of the expedient for which the path information data is being set.
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * The response object typically contains information about the success or failure of the request.
   * @throws {Error} If there's an error during the HTTP request or if the response indicates an error.
   */
  const setExpedientPath = async (expedientPathSet, expedientUuid) => {
    const response = await axios.post(
      `${URL_TRD}/trd/expedients/${expedientUuid}/related-record/`,
      expedientPathSet,
      {
        headers,
        endpointError: endpointError.trd.setExpedientPath
      }
    );
    return response;
  };

  /**
   * Deletes the path set for a specific expedient record identified by its UUID.
   * @param {string} recordUuid - The UUID of the expedient record whose path set is to be deleted.
   * @returns {Promise<object>} A promise that resolves to the response object from the server indicating the success or failure of the deletion operation.
   */
  const deletePathSetExpedient = async (recordUuid, formUuid) => {
    const response = await axios.delete(
      `${URL_TRD}/trd/expedients/record/${recordUuid}/path/?form_uuid=${formUuid}`,
      {
        headers,
        endpointError: endpointError.trd.deletePathSetExpedient
      }
    );
    return response;
  };

  return {
    getTreeByDomain,
    createFund,
    createSection,
    createSerieBySectionOrSubSection,
    createSubSectionBySection,
    createSubserieBySerie,
    createExpedient,
    createSubDivision,
    getExpedients,
    getExpedientsByName,
    getFilesByExpedient,
    createDirectory,
    getTreeExpedientByDomain,
    getTreeExist,
    updateExpedient,
    updateSubDivision,
    updateDirectory,
    updateSection,
    updateSubSerie,
    updateSubSection,
    updateBackground,
    updateSerie,
    updateDocumentaryType,
    createDocumentaryType,
    getSerieSubserieStructure,
    createSubdivisionByStructure,
    createDirectoryByStructure,
    updateSubdivisionStructure,
    updateDirectoryStructure,
    getDocumentalTypes,
    deleteLevelTrd,
    deleteLevelOrdinationTrd,
    getPathSetExpedient,
    setExpedientPath,
    deletePathSetExpedient,
  };
}
