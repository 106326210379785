import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import EditListForm from "components/organism/EditListForm";
import i18n from "locales/i18n";

const EditList = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("list.designerItem4")}
        subheading={i18n.t("consultList.designerMotto")}
        icon="menu"
      >
        <EditListForm isWizard={false} />
      </AdminTemplate>
    </Fragment>
  );
};

export default EditList;
