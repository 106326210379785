import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Button, Alert, Card, CardHeader } from "reactstrap";
import {
  AiFillCaretRight,
  AiOutlineFolder,
  AiFillFolderOpen,
} from "react-icons/ai";
import { showAlertServiceErrorWithRedirect } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useListContext } from "contextAPI/ListsContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { initialPaginationRecordListExpedient } from "utils/initialPaginationsConfig";
import LoadingComponent from "components/atoms/LoadingComponent";
import cx from "classnames";
import i18n from "locales/i18n";

const TrdLevelsTree = (props) => {
  const { isProcessBatch, setCurrentPath } = props;
  const [disableButtonSelectLevel, setDisabledButtonSelectLevel] = useState(
    false
  );
  const [, setPathRedirection] = useState([]);
  const [expandedNodeTree, setExpandedNodeTree] = useState([]);
  const [selectedNodeTreeId, setSelectedNodeTreeId] = useState(null);
  const [minSerieOrSubSerieLevel] = useState(3);
  const {
    setParentId,
    setBackgroundId,
    setShowTrdLevelsOrdenation,
  } = useRecordDetailContext();
  const { getTreeByDomainCallback, structureTrd, isLoading, setPaginationRecordList } = useTrdContext();
  const { setRecordNext } = useListContext();
  const rootsTree = structureTrd.filter(
    (nodeTree) => nodeTree.parentId === null
  );
  let showButtonAssing = false;
  let titleRootTreeByBatch = null;

  /**
   * Generates a card header component based on the condition of whether the process is a batch process.
   * @param {boolean} isProcessBatch - A boolean value indicating whether the process is a batch process.
   * @returns {JSX.Element|null} The generated card header component if the process is a batch process; otherwise, null.
   */
  if (isProcessBatch === true) {
    titleRootTreeByBatch = (
      <CardHeader className="mb-4">
        {i18n.t("recordDetail.LocationFile")}
      </CardHeader>
    );
  }

  /**
   * Handles the click event on a selected level assignment.
   * @param {Event} eventAssing - The click event object.
   * @param {Object} node - The selected node object.
   * @returns {void}
   */
  const handleClickSelectedLevelAssing = (eventAssing, node) => {
    eventAssing.stopPropagation();
    setParentId(node.id);
    setRecordNext(true);
    setDisabledButtonSelectLevel(false);
  };

  /**
   *Function that handles the click event on a node in the tree structure.
   *@param {object} node - the node object representing the clicked node
   *@param {array} expandedNodeTree - an array containing the ids of the expanded nodes in the tree structure
   *@param {function} toggleExpandedTree - a function to toggle the expanded state of a node in the tree structure
   *@param {array} structureTrd - an array representing the tree structure
   *@param {function} setSelectedNodeTree - a function to set the selected node in the tree structure
   *@param {string} setSelectedNodeTreeId - a function to set the id of the selected node in the tree structure
   */
  const handleNodeClick = (node) => {
    const levelBackground = 0;
    const { id, level } = node;
    if (level === levelBackground) {
      setBackgroundId(id);
    }
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    toggleExpandedTree(node.id);
    if (isExpandedNodeTree === false) {
      setSelectedNodeTreeId(node.id);
    } else {
      setSelectedNodeTreeId(node.parentId);
    }
  };

  /**
  *Updates the current path based on a given nodeId.
  *@param {number|null} nodeId - The id of the node to update the current path for. If null, the current path will be cleared.
  *@param {array} structureTrd - An array representing the structure tree to search for the node.
  *@param {array} expandedNodeTree - An array of ids representing the expanded nodes in the tree.
  *@param {function} setPathRedirection - The state setter function for the path redirection.
  *@param {function} setCurrentPath - The state setter function for the current path.
  */
  const updateCurrentPath = (nodeId) => {
    if (nodeId === null) {
      setCurrentPath([]);
      return;
    }
    const getNodeById = (id) =>
      structureTrd.find((nodeTree) => nodeTree.id === id);
    const nodePath = getNodeById(nodeId);
    if (isNullOrUndefined(nodePath) === false) {
      const path = [nodePath];
      let parentId = nodePath.parentId;
      const isExpandedNodeTree = expandedNodeTree.includes(nodeId);
      while (parentId !== null) {
        const parentNode = getNodeById(parentId);
        if (parentNode !== null) {
          path.unshift(parentNode);
          parentId = parentNode.parentId;
        } else {
          break;
        }
      }
      const pathWithLabels = path.map((nodePath) => nodePath.label);
      const objectPath = path.map((nodePath) => nodePath);
      if (isExpandedNodeTree === false) {
        setPathRedirection(objectPath);
        setCurrentPath(pathWithLabels);
      } else {
        setCurrentPath(pathWithLabels.slice(0, -1));
      }
    } else {
      showAlertServiceErrorWithRedirect();
    }
  };

  /**
   *Function that toggles the expanded state of a tree node and performs related operations.
   *@param {string} nodeId - the ID of the node to toggle the expanded state
   *@param {array} structureTrd - an array of objects representing the tree structure
   *@param {array} expandedNodeTree - an array of node IDs representing the currently expanded nodes in the tree
   *@param {function} setExpandedNodeTree - the state setter function for the array of expanded node IDs
   *@param {number} selectedNodeTreeId - the ID of the currently selected node in the tree
   *@param {function} setSelectedNodeTreeId - the state setter function for the selected node ID
   *@param {function} updateCurrentPath - a function that updates the current path based on the selected node ID
   *@param {boolean} hasFetchedExpedients - a boolean value indicating whether the expedients have been fetched
   *@param {function} setHasFetchedExpedients - the state setter function for the hasFetchedExpedients variable
   */
  const toggleExpandedTree = (nodeId) => {
    const backgrund = structureTrd.find((nodeTree) => nodeTree.id === nodeId);
    const expedientNodeId = expandedNodeTree.includes(nodeId);
    if (expedientNodeId === true) {
      setExpandedNodeTree(
        expandedNodeTree.filter((nodeExpanded) => nodeExpanded !== nodeId)
      );
      if (backgrund.level === 0) {
        setExpandedNodeTree([]);
      }
    } else {
      setExpandedNodeTree([...expandedNodeTree, nodeId]);
    }
    updateCurrentPath(nodeId);
    setSelectedNodeTreeId(nodeId);
  };

  /**
   *Renders a single node of a tree structure.
   *render node from tree is also in charge of establishing when a node is at the last serial
   *level or would go up, doing the parentId validation renders an assign button
   *@param {object} node - The node object representing a single node in the tree structure.
   *@param {function} handleClickSelectedLevelAssing - This function is in charge that when you click on the assign button,
   * it assigns the value of the id of the selected node
   *@param {array} structureTrd - The array representing the entire tree structure.
   *@param {array} expandedNodeTree - The array of expanded node IDs in the tree.
   *@param {number} selectedNodeTreeId - The ID of the currently selected node in the tree.
   *@param {function} toggleExpandedTree - The function to toggle the expanded state of a node in the tree.
   *@param {function} handleNodeClick - The function to handle a click event on a node.
   *@returns {JSX.Element} - The rendered JSX element representing the node.
   */
  const renderNodeFromTree = (node) => {
    const hasChildren = structureTrd.some((item) => item.parentId === null);
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    let nodeStyle = "nodeStyle";
    let customExpandedStyle = "expandedStyle";
    let customLabelBackgroundStyle = "text-primary alingText";
    let iconFolder = null;
    if (selectedNodeTreeId === node.id) {
      nodeStyle += " selected";
    }
    if (selectedNodeTreeId === node.id) {
      const isUltimateLevel = structureTrd.filter(
        (node) => node.parentId === selectedNodeTreeId
      );
      const selectNode = structureTrd.filter(
        (node) => node.id === selectedNodeTreeId
      );
      if (
        isUltimateLevel.length === 0 &&
        selectNode[0].level >= minSerieOrSubSerieLevel
      ) {
        nodeStyle += " isUltimateLevel";
        showButtonAssing = (
          <div className="ml-auto">
            <Button
              key={node.id}
              className="ml-2"
              color="cyan"
              disabled={disableButtonSelectLevel}
              onClick={(eventAssing) =>
                handleClickSelectedLevelAssing(eventAssing, node)
              }
            >
              {i18n.t("trd.ordenationLevelTree2")}
            </Button>
          </div>
        );
      } else {
        showButtonAssing = null;
      }
    } else {
      showButtonAssing = null;
    }
    if (isExpandedNodeTree === true) {
      customExpandedStyle += " isExpanded";
    }
    if (node.parentId === null) {
      customLabelBackgroundStyle += " font-weight-bold";
    }
    if (isExpandedNodeTree === true) {
      iconFolder = <AiFillFolderOpen className="folderIcon" />;
    } else {
      iconFolder = <AiOutlineFolder className="folderIcon" />;
    }
    return (
      <div key={node.id}>
        <div
          className={nodeStyle}
          onClick={() => {
            toggleExpandedTree(node.id);
            handleNodeClick(node);
          }}
        >
          <AiFillCaretRight className={customExpandedStyle} /> &nbsp;
          {iconFolder}
          <span className={customLabelBackgroundStyle}>{node.label}</span>
          {showButtonAssing}
        </div>
        {hasChildren === true && isExpandedNodeTree === true && (
          <div className="hasChildren">
            {structureTrd
              .filter((nodeTree) => nodeTree.parentId === node.id)
              .map((child) => renderNodeFromTree(child))}
          </div>
        )}
      </div>
    );
  };

  /**
   * Sets the pagination record list when the component mounts.
   * @param {useEffectCallback} effect - Callback function to execute when the effect runs.
   * @param {Array} deps - Dependencies array. The effect will re-run if any value in this array changes.
   * @returns {void}
   */
  useEffect(() => {
    setPaginationRecordList(initialPaginationRecordListExpedient)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A custom effect that calls the provided callback function when mounted or when the callback function changes.
   * @param {Function} effectCallback - The callback function to be invoked.
   * @returns {void}
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  /**
   * useEffect hook that updates the state to hide TRD levels ordination.
   * @param {function} setShowTrdLevelsOrdenation - State setter function to control the visibility of TRD levels ordination.
   * @returns {void}
   */
  useEffect(() => {
    setShowTrdLevelsOrdenation(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditional rendering based on the isLoading flag.
   * @param {boolean} isLoading - A flag indicating whether data is loading.
   * @returns {JSX.Element|null} - A JSX element representing the loading component if isLoading is true, otherwise null.
   */
  if (isLoading === true) {
    return <LoadingComponent />;
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card
          className={cx("forms-fields mb-4", {
            "forms-fields mb-4 form-container-batch": isProcessBatch === true,
          })}
        >
          {titleRootTreeByBatch}
          <Alert className="mbg-3" color="info">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            {i18n.t("recordFile.tooltip")}
          </Alert>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-column w-100">
                <div className="m-8 p-3">
                  {rootsTree.map((node) => renderNodeFromTree(node))}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};
TrdLevelsTree.propTypes = {
  isProcessBatch: PropTypes.bool,
};
export default TrdLevelsTree;
