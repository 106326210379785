import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Button, Alert } from "reactstrap";
import {
  AiFillCaretRight,
  AiOutlineFolder,
  AiFillFolderOpen,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTrdContext } from "contextAPI/TrdContext";
import { useListContext } from "contextAPI/ListsContext";
import { enumsTaskType } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { DELETE_PATH_EXPEDIENT, TYPE } from "constants/securityConst";
import useTrd from "hooks/useTrd";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import i18n from "locales/i18n";

const TrdLevelsOrdenation = (props) => {
  const { id } = useParams();
  const formUuid = id;
  const {
    hasOrdenation,
    pathConfiguration,
    structureTrdExpedients,
    recordUuid,
    isFreeTask,
    isProcess,
    isProcessBatch,
  } = props;
  const DELETEPATHEXPEDIENT = window.localStorage.getItem(
    DELETE_PATH_EXPEDIENT
  );
  const USERTYPE = window.localStorage.getItem(TYPE);
  const [currentPath] = useState([]);
  const [expandedNodeTree, setExpandedNodeTree] = useState([]);
  const [selectedNodeTreeId, setSelectedNodeTreeId] = useState(null);
  let minLevelTrdRequired = 6;
  const [minLevelExpedientTree] = useState(minLevelTrdRequired);
  const {
    parentData,
    setParentData,
    setFolderName,
    structureTrdExpedient,
    hasExpedientFixedPath,
    sethasExpedientFixedPath,
    expedientSetPath,
    setExpedientSetPath,
  } = useTrdContext();
  const { setRecordNext, setAdvanced } = useListContext();
  const { deletePathSetExpedient } = useTrd();
  let showButtonAssing = false;
  let buttonDeleteExpedientPath = null;
  let nodeStructureExpedients = structureTrdExpedient;

  const rootsExpedientTree = nodeStructureExpedients.filter(
    (nodeTree) => nodeTree.level === minLevelTrdRequired
  );

  /**
   * Conditionally assigns a node structure based on the value of `hasExpedientFixedPath`.
   * @param {boolean} hasExpedientFixedPath - Indicates whether the expedient has a fixed path.
   *   If true, `nodeStructureExpedients` will be assigned the value of `structureTrdExpedients`.
   *   If false, `nodeStructureExpedients` remains unchanged.
   * @returns {void}
   */
  if (
    hasExpedientFixedPath === true &&
    isNullOrUndefined(structureTrdExpedients) === false
  ) {
    nodeStructureExpedients = structureTrdExpedients;
  }

  /**
   * Creates an informational alert component with specified content and styling.
   * @constant {JSX.Element} alertPath - The JSX element representing the informational alert.
   * @param {string} className - The CSS class name(s) to apply to the Alert component for custom styling.
   * @param {string} color - The color theme of the Alert component (e.g., "info", "success", "warning", "danger").
   * @param {string} icon - The icon to display alongside the alert content, provided as a FontAwesomeIcon component.
   * @param {string} message - The message content to display within the alert.
   * @param {string} translationKey - The translation key used to localize the message content using i18n.t().
   * @returns {JSX.Element} The informational alert component rendered with the specified content and style.
   */
  let alertPath = (
    <Alert className="mbg-3" color="info">
      <span className="pr-2">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
        <span>{i18n.t("trd.ordenationLevelTree1")}</span>
      </span>
    </Alert>
  );

  /**
   * Handles the deletion of an expedient path by making an API request to delete the path associated with the specified record UUID.
   * @returns {Promise<void>} A promise that resolves after the path deletion operation completes.
   */
  const handleOnDeleteExpedietPath = () =>
    deletePathSetExpedient(recordUuid, formUuid).then((response) => {
      if (response.status === 202) {
        setExpedientSetPath({});
        sethasExpedientFixedPath(false);
      }
    });

  /**
   * Determines if the delete button for an expedient path should be displayed based on specified conditions.
   * @param {boolean} DELETEPATHEXPEDIENT - Flag indicating whether the expedient path deletion is permitted.
   * @param {string[]} DELETEPATHEXPEDIENT - An array containing form UUIDs eligible for deletion.
   * @param {string} formUuid - The UUID of the form to be checked against the deletion list.
   * @param {string} USERTYPE - The type of user accessing the functionality.
   * @param {enumsTaskType} isFreeTask - Enum representing the task type, indicating if it's a free task.
   * @param {boolean} isProcess - Flag indicating if it's a process.
   * @param {boolean} isProcessBatch - Flag indicating if it's a batch process.
   *
   * @returns {React.ReactNode|null} - The delete expedient path button JSX element if conditions are met, otherwise null.
   */
  if (
    (isNullOrUndefined(DELETEPATHEXPEDIENT) === false &&
      DELETEPATHEXPEDIENT.includes(formUuid) === true &&
      isNullOrUndefined(USERTYPE) === false &&
      isFreeTask !== enumsTaskType.FREE_TASK &&
      isProcess !== true &&
      isProcessBatch !== true) ||
    (USERTYPE === "3" &&
      isFreeTask !== enumsTaskType.FREE_TASK &&
      isProcess !== true)
  ) {
    buttonDeleteExpedientPath = (
      <Button
        color="cyan"
        onClick={handleOnDeleteExpedietPath}
        className="ml-2"
      >
        {i18n.t("trdExpedientListSetPathQuit")}
      </Button>
    );
  }

  /**
   * Generates an alert component with information about the current path if `hasExpedientFixedPath` is true.
   * @param {boolean} hasExpedientFixedPath - Flag indicating whether there is a fixed path for the expedient.
   * @param {string} currentPath - The current path of the expedient.
   * @param {object} expedientSetPath - Configuration object defining the set path for the expedient.
   * @param {ReactNode} buttonDeleteExpedientPath - Optional button component for deleting the expedient path.
   * @returns {ReactNode|null} Returns an alert component with path information if `hasExpedientFixedPath` is true, otherwise null.
   */
  if (
    hasExpedientFixedPath === true &&
    isNullOrUndefined(pathConfiguration) === false
  ) {
    alertPath = (
      <Alert className="mbg-2" color="info">
        <Row className=" align-items-center justify-content-between">
          <Col lg="10" md="6" sm="12" className="d-flex ">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={expedientSetPath}
            />
          </Col>
        </Row>
        <Row className=" align-items-center justify-content-between">
          <Col lg="8" md="6" sm="12" className="d-flex ">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <span>{i18n.t("trd.ordenationLevelTree1")}</span>
          </Col>
          <Col lg="auto" md="6" sm="12" className="d-flex justify-content-end">
            {buttonDeleteExpedientPath}
          </Col>
        </Row>
      </Alert>
    );
  }

  /**
   * Handles the click event on a selected level assignment.
   * @param {Event} event - The click event object.
   * @param {Object} node - The selected node object.
   * @returns {void}
   */
  const handleClickSelectedLevelAssing = (_, node) => {
    const { id, label } = node;
    setFolderName(label);
    setParentData({
      ...parentData,
      id,
    });
    setAdvanced(1);
    setRecordNext(true);
  };

  /**
   *Function that toggles the expanded state of a tree node and performs related operations.
   *@param {string} nodeId - the ID of the node to toggle the expanded state
   *@param {array} structureTrd - an array of objects representing the tree structure
   *@param {array} expandedNodeTree - an array of node IDs representing the currently expanded nodes in the tree
   *@param {function} setExpandedNodeTree - the state setter function for the array of expanded node IDs
   *@param {number} selectedNodeTreeId - the ID of the currently selected node in the tree
   *@param {function} setSelectedNodeTreeId - the state setter function for the selected node ID
   *@param {function} updateCurrentPath - a function that updates the current path based on the selected node ID
   *@param {boolean} hasFetchedExpedients - a boolean value indicating whether the expedients have been fetched
   *@param {function} setHasFetchedExpedients - the state setter function for the hasFetchedExpedients variable
   */
  const toggleExpandedTree = (nodeId) => {
    const backgrund = nodeStructureExpedients.find(
      (nodeTree) => nodeTree.id === nodeId
    );
    const expedientNodeId = expandedNodeTree.includes(nodeId);
    if (expedientNodeId === true) {
      setExpandedNodeTree(
        expandedNodeTree.filter((nodeExpanded) => nodeExpanded !== nodeId)
      );
      if (backgrund.level === 0) {
        setExpandedNodeTree([]);
      }
    } else {
      setExpandedNodeTree([...expandedNodeTree, nodeId]);
    }
    setSelectedNodeTreeId(nodeId);
  };

  /**
   *Function that handles the click event on a node in the tree structure.
   *@param {object} node - the node object representing the clicked node
   *@param {array} expandedNodeTree - an array containing the ids of the expanded nodes in the tree structure
   *@param {function} toggleExpandedTree - a function to toggle the expanded state of a node in the tree structure
   *@param {array} structureTrd - an array representing the tree structure
   *@param {function} setSelectedNodeTree - a function to set the selected node in the tree structure
   *@param {string} setSelectedNodeTreeId - a function to set the id of the selected node in the tree structure
   */
  const handleNodeClick = (node) => {
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    toggleExpandedTree(node.id);
    if (isExpandedNodeTree === false) {
      setSelectedNodeTreeId(node.id);
    } else {
      setSelectedNodeTreeId(node.parentId);
    }
  };

  /**
   *Renders a single node of a tree structure.
   *@param {object} node - The node object representing a single node in the tree structure.
   *@param {array} structureTrd - The array representing the entire tree structure.
   *@param {array} expandedNodeTree - The array of expanded node IDs in the tree.
   *@param {number} selectedNodeTreeId - The ID of the currently selected node in the tree.
   *@param {function} toggleExpandedTree - The function to toggle the expanded state of a node in the tree.
   *@param {function} handleNodeClick - The function to handle a click event on a node.
   *@returns {JSX.Element} - The rendered JSX element representing the node.
   */
  const renderNodeFromTree = (node) => {
    const hasChildren = nodeStructureExpedients.some(
      (item) => item.level === 5
    );
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    let nodeStyle = "nodeStyle";
    let customExpandedStyle = "expandedStyle";
    let customLabelBackgroundStyle = "text-primary alingText";
    let iconFolder = null;
    if (selectedNodeTreeId === node.id) {
      const isUltimateLevel = nodeStructureExpedients.filter(
        (node) => node.parentId === selectedNodeTreeId
      );
      const selectNode = nodeStructureExpedients.filter(
        (node) => node.id === selectedNodeTreeId
      );
      if (
        isUltimateLevel.length === 0 &&
        selectNode[0].level >= minLevelExpedientTree
      ) {
        nodeStyle += " isUltimateLevel";
        showButtonAssing = (
          <div className="ml-auto">
            <Button
              key={node.id}
              size="sm"
              className="ml-2"
              color="cyan"
              onClick={(event) => handleClickSelectedLevelAssing(event, node)}
            >
              {i18n.t("trd.ordenationLevelTree2")}
            </Button>
          </div>
        );
      } else {
        showButtonAssing = null;
      }
    } else {
      showButtonAssing = null;
    }
    if (selectedNodeTreeId === node.id) {
      nodeStyle += " selected";
    }
    if (isExpandedNodeTree === true) {
      customExpandedStyle += " isExpanded";
    }
    if (node.parentId === null) {
      customLabelBackgroundStyle += " font-weight-bold";
    }
    if (isExpandedNodeTree === true) {
      iconFolder = <AiFillFolderOpen className="folderIcon" />;
    } else {
      iconFolder = <AiOutlineFolder className="folderIcon" />;
    }
    return (
      <div key={node.id}>
        <div
          className={nodeStyle}
          onClick={() => {
            toggleExpandedTree(node.id);
            handleNodeClick(node);
          }}
        >
          <AiFillCaretRight className={customExpandedStyle} /> &nbsp;
          {iconFolder}
          <span className={customLabelBackgroundStyle}>{node.label}</span>
          {showButtonAssing}
        </div>
        {hasChildren === true && isExpandedNodeTree === true && (
          <div className="hasChildren">
            {nodeStructureExpedients
              .filter((nodeTree) => nodeTree.parentId === node.id)
              .map((child) => renderNodeFromTree(child))}
          </div>
        )}
      </div>
    );
  };

  /**
   * Executes a side effect when the value of `nodeStructureExpedients` changes.
   * If the length of `nodeStructureExpedients` is equal to the minimum number of files,
   * it sets the folder name, updates the parent data ID, advances to the next step, and
   * triggers a record update.
   * @param {Array} nodeStructureExpedients - An array of objects representing TRD expedient structure.
   * @returns {void}
   */
  useEffect(() => {
    const minimumNumberOfFiles = 1;
    if (
      nodeStructureExpedients &&
      nodeStructureExpedients.length === minimumNumberOfFiles
    ) {
      setFolderName(nodeStructureExpedients[0].label);
      setParentData({ ...parentData, id: nodeStructureExpedients[0].id });
      setAdvanced(1);
      setRecordNext(true);
    }
  }, [nodeStructureExpedients]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when the 'hasOrdenation' dependency changes.
   * If 'hasOrdenation' is false, updates the 'advanced' state to 1 and sets 'recordNext' to true.
   * Determines whether the 'advanced' state and 'recordNext' state should be updated.
   * @param {Function} effect - Function containing the side effect logic.
   * @param {Array} dependencies - List of dependencies that, when changed, trigger the effect.
   * @param {boolean} hasOrdenation - Boolean flag indicating whether ordenation is present.
   * @param {Function} setAdvanced - State setter function for updating the 'advanced' state.
   * @param {Function} setRecordNext - State setter function for updating the 'recordNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (hasOrdenation === false) {
      setAdvanced(1);
    }
  }, [hasOrdenation]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditional rendering based on the value of 'isLoading'.
   * @param {boolean} isLoading - Indicates whether loading is in progress.
   * @returns {JSX.Element|null} The LoadingComponent if 'isLoading' is true, otherwise null.
   */
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {alertPath}
        <Row>
          <Col md={12}>
            <div className="d-flex flex-column w-100">
              <div className="m-8 p-3">
                {rootsExpedientTree.map((node) => renderNodeFromTree(node))}
              </div>
            </div>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrdLevelsOrdenation.propTypes = {
  hasOrdenation: PropTypes.bool,
  pathConfiguration: PropTypes.string,
  structureTrdExpedients: PropTypes.array.isRequired,
  recordUuid: PropTypes.string.isRequired,
  isFreeTask: PropTypes.number.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
};

export default TrdLevelsOrdenation;
