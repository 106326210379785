import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams } from "react-router-dom";
import { CardBody } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import ButtonActionsItem from "components/atoms/ButtonActionsItem";
import i18n from "locales/i18n";

const ItemsListsTable = (props) => {
  const {
    items,
    pagination,
    setPagination,
    isLoadingItemList,
    totalPages,
  } = props;
  const { parentId } = useParams();

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={items}
            columns={[
              {
                columns: [
                  {
                    Header: [i18n.t("itemList.designerLabel1")],
                    accessor: "name",
                  },
                  {
                    Header: [i18n.t("itemList.designerLabel2")],
                    accessor: "parent_name",
                  },
                  {
                    Header: [i18n.t("filteruser.button")],
                    accessor: "status",
                    Cell: ({ value }) => {
                      let statusFinal = "";
                      if (value === 1) {
                        statusFinal = (
                          <div className="ml-auto badge badge-success">
                            {i18n.t("filteruser.item2")}
                          </div>
                        );
                      } else {
                        statusFinal = (
                          <div className="ml-auto badge badge-danger">
                            {i18n.t("form.designerLabel3.4")}
                          </div>
                        );
                      }

                      return <span>{statusFinal}</span>;
                    },
                  },
                  {
                    Header: [i18n.t("itemList.designerLabel3")],
                    accessor: "created_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    },
                  },
                  {
                    Header: [i18n.t("form.field47")],
                    accessor: "updated_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));

                      return <span>{valueFinal}</span>;
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    Header: [i18n.t("buttonActions.title")],
                    accessor: "actions",
                    Cell: (row) => {
                      return (
                        <ButtonActionsItem
                          itemId={row.original.uuid}
                          listId={row.original.list_uuid}
                          parentId={parentId}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingItemList}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ItemsListsTable.propTypes = {
  items: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingItemList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};
export default ItemsListsTable;
