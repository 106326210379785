import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
  CardBody,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTrdContext } from "contextAPI/TrdContext";
import { enumLevelTrd, enumSubDivisionForm } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import { regexEditSubDivision } from "utils/regexExpressions";
import useClassification from "hooks/useClassification";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import { trimExtraSpaces } from "utils/trimSpaces";

const EditSubDivisionForm = () => {
  const { setIsLoading, isLoading } = useClassification();
  const { id, backgroundId } = useParams();
  const { updateSubdivisionStructure } = useTrd();

  const {
    editSubDivisionFormSTR,
    setEditSubDivisionFormSTR,
    setOption,
    setLevelFolder,
    validateField,
    setValidateField,
    getSeriesSubseriesStructure,
  } = useTrdContext();

  let requiredValue;
  let loadingSpinnerEditSubDivision = null;

  /**
   * Conditionally renders a loading spinner based on the value of `isLoading`. 
   * This code:
   * - Checks if `isLoading` is true.
   * - If true, sets `loadingSpinnerEditSubDivision` to a `Spinner` component with specific properties.
   * @param {boolean} isLoading - Flag indicating whether data is currently loading.
   * @returns {JSX.Element | null} The rendered `Spinner` component if `isLoading` is true, otherwise null.
   */
  if (isLoading === true) {
    loadingSpinnerEditSubDivision = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Sets the `requiredValue` based on the `validateField` flag. 
   * This code:
   * - Checks if `validateField` is true.
   * - If true, sets `requiredValue` to true.
   * - If false, sets `requiredValue` to false.
   * @param {boolean} validateField - Flag indicating whether the field is validated.
   * @param {boolean} requiredValue - The value that will be set based on `validateField`. This variable is set to true if `validateField` is true, otherwise false.
   * @returns {void}
   */
  if (validateField === true) {
    requiredValue = true;
  } else {
    requiredValue = false;
  };

  /**
   * Handle the change event of the input fields in the edit sub-division form.
   * @param {Event} eventEditSubDivision - The change event.
   */
  const handleOnChangeEditSubDivision = (eventEditSubDivision) => {
    setValidateField(true);
    setEditSubDivisionFormSTR({
      ...editSubDivisionFormSTR,
      name: eventEditSubDivision.target.value,
    });
  };

  /**
   * Handle the key press event in the edit sub-division form.
   * @param {Event} eventEditSubDivision - The key press event.
   */
  const handleOnKeyPressEditSubDivision = (eventEditSubDivision) => {
    if (eventEditSubDivision.key === "Enter") {
      eventEditSubDivision.preventEditSubDivisionDefault();
    }
  };

  /**
   * Handle the blur event of the input fields in the edit sub-division form.
   * @param {Event} eventEditSubDivision - The blur event.
   */
  const handleOnBlurEditSubDivision = (eventEditSubDivision) => {
    const { value } = eventEditSubDivision.target;
    const trimmedValue = trimExtraSpaces(value);
      setEditSubDivisionFormSTR({
        ...editSubDivisionFormSTR,
        name: trimmedValue,
      });
  };

  /**
   * Handles the click event for editing a subdivision.
   * @return {void}
   */
  const handleOnClickEditSubDivision = () => {
    setOption(true);
  }

  /**
   * Handle the form submission in the edit sub-division form.
   * @param {Event} eventEditSubDivision - The form submission event.
   * @param {Array} errors - Array of validation errors.
   */
  const handleOnSubmitEditSubDivision = (eventEditSubDivision, errors) => {
    if (errors.length > 0) {
      eventEditSubDivision.preventEditSubDivisionDefault();
      setValidateField(true);
      setIsLoading(false);
      return;
    }

    if (validateField === true) {
      setIsLoading(true);
      updateSubdivisionStructure(editSubDivisionFormSTR, backgroundId, id)
        .then((response) => {
          if (response.status === 202) {
            setValidateField(false);
            getSeriesSubseriesStructure(backgroundId, id);
            const showAlertEditSubDivisionSuccess = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("2010"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              });
            };
            showAlertEditSubDivisionSuccess();
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
          setLevelFolder(Number(enumLevelTrd.SUBDIVISION));
          setOption(true);
        });
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <CardTitle>{i18n.t("trd.formTittle32.1")}</CardTitle>
          <AvForm onSubmit={handleOnSubmitEditSubDivision}>
            <AvGroup>
              <ReactTooltip
                id="name_subdivision"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.fromLabel2")}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeEditSubDivision}
                onKeyPress={handleOnKeyPressEditSubDivision}
                onBlur={handleOnBlurEditSubDivision}
                data-tip
                data-for="name_subdivision"
                validate={{
                  pattern: {
                    value: regexEditSubDivision,
                    errorMessage: `${i18n.t("invalidField2")}`,
                  },
                  required: {
                    value: requiredValue,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: enumSubDivisionForm.MIN_LENGTH,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: enumSubDivisionForm.MAX_LENGTH,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                autoComplete="off"
                value={editSubDivisionFormSTR.name}
              />
            </AvGroup>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={handleOnClickEditSubDivision}
              >
                {i18n.t("createusers.createButton2")}
              </Button>

              <Button
                type="submit"
                size="lg"
                disabled={isLoading}
                className="col-mt-3"
                color="cyan"
              >
                {loadingSpinnerEditSubDivision}
                {i18n.t("createusers.createButton3")}
              </Button>
            </CardFooter>
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default EditSubDivisionForm;
