import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardFooter } from "reactstrap";
import {
  TYPE,
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { enumsTypeUser } from "utils/enums";
import WorkflowListTable from "components/organism/WorkflowListTable";
import FilterWorkflowState from "components/atoms/FilterWorkflowState";
import ButtonCreateWorkFlow from "components/atoms/ButtonCreateWorkFlow";
import SearchBox from "components/atoms/SearchBox";
import useWorkFlow from "hooks/useWorkFlow";
import swal from "sweetalert";
import i18n from "locales/i18n";

const WorkflowList = () => {
  const { getWorkFlowsAvailablesPerUser } = useWorkFlow();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(
    WORKFLOW_FORMS_PERMISSIONS
  );
  const workflowOrderPermissions = WORKFLOW_FORMS_PERMISSION.split(",");
  const [listWorkflows, setListWorkflows] = useState([]);
  const [isLoadingWorkflowList, setIsLoadingWorkflowList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart); 
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [workflowPermissions, setWorkflowPermissions] = useState([]);

  let workflowtoShowInList = null;
  let disableButtonCreateWorkflow = false;

  /**
   * Determines which workflow data should be displayed in a list based on user type and configuration.
   * @param {string} USERTYPE - The user type, typically a string or identifier, e.g., "3".
   * @param {string} WORKFLOW_ALL_FORM - A configuration flag indicating whether all workflows should be displayed as "true" or "false".
   * @param {Array} listWorkflows - An array of workflows available for all users.
   * @param {Array} workflowPermissions - An array of workflows with restricted access based on user permissions.
   * @returns {Array} workflowtoShowInList - The array of workflows to be displayed in the list based on the provided conditions.
   */
  if (USERTYPE === enumsTypeUser.ADMIN || WORKFLOW_ALL_FORM === "true") {
    workflowtoShowInList = listWorkflows;
  } else {
    workflowtoShowInList = workflowPermissions;
  }

  /**
   * Update the 'disableButtonCreateWorkflow' variable based on the value of 'WORKFLOW_ALL_FORM'.
   * If 'WORKFLOW_ALL_FORM' is "false," the 'disableButtonCreateWorkflow' variable is set to true,
   * indicating that the button for creating a workflow should be disabled.
   * Otherwise, it is set to false, allowing the button to remain enabled.
   * @param {string} WORKFLOW_ALL_FORM - A string representing a condition that determines whether
   * the button for creating a workflow should be disabled. Should be either "true" or "false."
   * @type {boolean} disableButtonCreateWorkflow - A boolean variable that determines whether the button
   * for creating a workflow is enabled (false) or disabled (true).
   */
  if (WORKFLOW_ALL_FORM === "false") {
    disableButtonCreateWorkflow = true;
  } else {
    disableButtonCreateWorkflow = false;
  }

  /**
   * Fetches workflows based on search criteria, updates state variables, and handles errors.
   * @param {object} pagination - An object containing pagination parameters.
   * @param {number} pagination.page - The current page number.
   * @param {number} pagination.per_page - The number of items to display per page.
   * @param {string} search - The search criteria to filter workflows.
   * @param {string} status - The status of the workflows to filter.
   * @returns {void}
   */
  const getWorkFlowBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingWorkflowList(true);
    getWorkFlowsAvailablesPerUser(page, per_page, search, status)
      .then((response) => {
        const workflowList = response.data.items;
        const pagesWorkflows = response.data.pages;
        const newWorflowPermissions = [];
        if (
          isNullOrUndefined(workflowList) === false &&
          isNullOrUndefined(pagesWorkflows) === false
        ) {
          if (workflowList.length === 0) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("createWorflow.notExist1"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            });
          }
          workflowOrderPermissions.forEach((form) => {
            const matchingWorkflows = workflowList.filter(
              (workflow) => form === workflow.uuid
            );
            newWorflowPermissions.push(...matchingWorkflows);
          });
          setWorkflowPermissions(newWorflowPermissions);
          setListWorkflows(workflowList);
          setTotalPages(pagesWorkflows);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingWorkflowList(false);
      });
  }, [pagination, search]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Perform a side effect to retrieve workflow data based on a search.
   * This effect triggers the 'getWorkFlowBySearch' function when the 'getWorkFlowBySearch' dependency changes.
   * @param {function} getWorkFlowBySearch - The function responsible for retrieving workflow data based on a search.
   * @returns {void}
   */
  useEffect(() => {
    getWorkFlowBySearch();
  }, [getWorkFlowBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearch}
              pagination={pagination}
              setPagination={setPagination}
            />

            <FilterWorkflowState
              setIsLoading={setIsLoadingWorkflowList}
              setListWorkflows={setListWorkflows}
              setStatus={setStatus}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonCreateWorkFlow disabled={disableButtonCreateWorkflow} />
          </CardFooter>

          <WorkflowListTable
            listWorkflows={workflowtoShowInList}
            pagination={pagination}
            isLoadingWorkflowList={isLoadingWorkflowList}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default WorkflowList;
