import React, { useEffect, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Row, Col, Card, CardBody, CardFooter } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { useParams } from "react-router-dom";
import { loaderElement } from "utils/loaderElement";
import { enumsFieldStatus } from "utils/enums";
import i18n from "locales/i18n";

const FormsPreview = () => {
  const { id } = useParams();

  const {
    formName,
    fieldsForm,
    typeControl,
    validateFieldsByTypeControl,
    getFieldsPreview,
    setIsPreview,
    isPreview,
    isLoading: isLoadingPreview
  } = useFieldsRecordContext();

  let renderFieldsForms = null;

  /**
   * Sorts and maps fields to render their components if their status is 1.
   * This function sorts the fields based on their `order` property in ascending order.
   * It then maps through the sorted fields and returns a <ul> element containing
   * the field's component if the component's status is 1. Otherwise, it returns null.
   * @param {Array} fieldsForm - The array of field objects to be sorted and mapped.
   * Each field object should have an `order` property and a `component` property with `props.status`.
   * @returns {Array} - An array of <ul> elements containing field components with status 1,
   * or null for fields with other statuses.
   */
  renderFieldsForms = fieldsForm.sort((firsField, secondField) => firsField.order - secondField.order)
    .map((field) => {
      if (field.component.props.status === enumsFieldStatus.ACTIVE) {
        return <ul>{field.component}</ul>;
      } else {
        return null;
      }
    });

  /**
   * Executes the 'getFieldsPreview' function with the provided 'id' and a constant value of 0 as its parameters.
   * This effect is triggered whenever the 'getFieldsPreview' function itself changes in the dependencies array.
   * @param {string} id - The identifier used by the 'getFieldsPreview' function.
   * @returns {void}*/
  useEffect(() => {
    getFieldsPreview(id, 0);
  }, [getFieldsPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that sets the preview mode and validates fields based on type control data.
   * @param {boolean} isPreview - A boolean indicating whether the component is in preview mode.
   * @param {Array<Object>} typeControl - An array of type control objects containing configuration data.
   * @returns {void}
   */
  useEffect(() => {
    setIsPreview(true);
    typeControl.map((typeControl) =>
      validateFieldsByTypeControl(
        typeControl.type_control,
        typeControl,
        isPreview
      )
    );
  }, [typeControl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingPreview)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <h5 className="text-info font-weight-bold">{formName}</h5>
                <div className="divider" />
                {renderFieldsForms}
              </CardBody>
              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  disabled={true}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={true}
                  className="col-mt-3"
                  color="cyan"
                >
                  {i18n.t("createusers.createButton")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FormsPreview;
