import React, { useState } from "react";
import PropTypes from "prop-types";
import { registerLocale } from "react-datepicker";
import { addYears } from "date-fns";
import { es, fr } from "date-fns/esm/locale";
import { renderCustomHeader } from "utils/datePickerFunctions";
import { getFormattedTimezoneOffset } from "utils/getDateFormat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import i18n from "locales/i18n";
registerLocale("es", es);
registerLocale("fr", fr);

const DatePickerTrashSelect = (props) => {
  const {
    setFilterTrash,
    setTimeZone,
    setSearchDeletedFile,
    setIsOpenDrop,
    paginationTrash,
    setPaginationTrash,
  } = props;
  const [startDate, setStartDate] = useState(null);

  /**
   * CustomInput component.
   * This is a custom input component that forwards its ref and handles click events.
   * @param {Object} props - The properties object.
   * @param {string} props.value - The value to display inside the component.
   * @param {function} props.onClick - The function to call when the component is clicked.
   * @param {React.Ref} ref - The reference to be forwarded to the component.
   * @returns {JSX.Element} The rendered custom input component.
   */
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="filterTrash-datepicker" onClick={onClick} ref={ref}>
      {i18n.t("trash.label8")}
      {value}
    </div>
  ));

  /**
   * Handles the change in the selected start date. Updates related states with the formatted date,
   * time zone, and pagination settings.
   * @param {Date} date - The selected start date. It should be a `Date` object.
   * @returns {void} This function does not return any value.
   */
  const handleStartDateChange = (date) => {
    const dateSelected = moment.utc(date).format("YYYY-MM-DDTHH:mm:ss");
    const formattedOffset = getFormattedTimezoneOffset();
    setStartDate(date);
    setFilterTrash(dateSelected);
    setIsOpenDrop(false);
    setTimeZone(formattedOffset);
    setSearchDeletedFile("");
    setPaginationTrash({ ...paginationTrash, page: 1 });
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <DatePicker
        id="start_date"
        name="start_date"
        popperPlacement="auto"
        renderCustomHeader={renderCustomHeader}
        selected={startDate}
        onChange={handleStartDateChange}
        minDate={addYears(new Date(), -3)}
        maxDate={new Date()}
        customInput={<CustomInput />}
      />
    </div>
  );
};

DatePickerTrashSelect.propTypes = {
  setFilterTrash: PropTypes.func.isRequired,
  setTimeZone: PropTypes.func.isRequired,
  setSearchDeletedFile: PropTypes.func.isRequired,
  setIsOpenDrop: PropTypes.func.isRequired,
  paginationTrash: PropTypes.object.isRequired,
  setPaginationTrash: PropTypes.func.isRequired,
};

export default DatePickerTrashSelect;
