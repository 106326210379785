import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Alert,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { enumsTypeStatusUser } from "utils/enums";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import { isNullOrUndefined } from "utils/validations";

const ButtonAddMembersToRol = (props) => {
  const { idRol } = props;
  const { getUsersByRol, addMembersByRol } = useRoles();
  const { selectedUsersId } = useState([]);
  const [showModalAddMembers, setShowModalAddMembers] = useState(false);
  const [isErrorSelectedUsers, setIsErrorSelectedUsers] = useState(false);
  const [isLoadingMembersUsers, setIsLoadingMembersUser] = useState(false);
  const [membersDinamic, setMembersDinamic] = useState([]);
  const [addMembers, setAddMembers] = useState({
    members: [],
  });

  let requiredSelectUsers = null;
  let componentIsLoadingMembersRol = null;

  /**
   * Handles closing the modal and resetting the state. 
   * @function handleCloseModal
   * @param {function} setShowModalAddMembers - Function to set the state of the modal visibility.
   * @param {function} setAddMembers - Function to set the state of the addMembers object.
   * @param {Object} addMembers - The current state of the addMembers object.
   * @param {function} setIsErrorSelectedUsers - Function to set the error state.
   */
  if (isLoadingMembersUsers === true) {
    componentIsLoadingMembersRol = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Conditionally renders a required field message if there is an error.
   * @param {boolean} isErrorSelectedUsers - The error state.
   * @param {Object} i18n - The internationalization object for translations.
   * @returns {JSX.Element|null} The JSX for the required field message or null.
   */
  if (isErrorSelectedUsers === true) {
    requiredSelectUsers = (
      <span className="text-danger text small"> {i18n.t("fieldRequired")} </span>
    )
  };

  /**
   * Fetches users by role, sorts them alphabetically, and updates the state with active users.
   * @param {string} idRol - The ID of the role to search users by.
   * @param {function} setMembersDinamic - Function to update the state with dynamic members.
   * @param {function} getUsersByRol - Function to fetch users by role.
   */
  const getUsersByRolSearch = useCallback(() => {
    getUsersByRol(idRol).then((response) => {
      if (isNullOrUndefined(response.data.data.items) === false) {
        let listUsers = response.data.data.items;
        listUsers.sort((firstUser, secondUser) => {
          const nameA = `${firstUser.first_name} ${firstUser.last_name}`.toLocaleLowerCase();
          const nameB = `${secondUser.first_name} ${secondUser.last_name}`.toLocaleLowerCase();
          return nameA.localeCompare(nameB);
        }).forEach((user) => {
          if (user.status === enumsTypeStatusUser.ACTIVE) {
            setMembersDinamic((prevState) => [
              ...prevState,
              {
                value: user.uuid,
                label: `${user.first_name} ${user.last_name} (${user.user_name})`,
              },
            ]);
          }
        });
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for selecting users.
   * @param {Array} selectedUsersId - Array of selected user IDs.
   * @param {function} setIsErrorSelectedUsers - Function to set the state of selected users error.
   * @param {function} setAddMembers - Function to update the state of addMembers object.
   * @param {Object} addMembers - The current state of the addMembers object.
   */
  const handleOnChangeUsers = (selectedUsersId) => {
    if (selectedUsersId.length === 0) {
      setIsErrorSelectedUsers(true);
    } else {
      setAddMembers({
        ...addMembers,
        members: selectedUsersId.map((eventAddUsersId) => eventAddUsersId.value),
      });
      setIsErrorSelectedUsers(false);
    }
  };

  /**
   * Handles the click event to open the modal for adding members.
   * @function handleOnClickOpenModal
   * @param {function} setShowModalAddMembers - Function to set the state of modal visibility for adding members.
   */
  const handleOnClickOpenModal = () => {
    setShowModalAddMembers(true);
  };

  const handleOnCloseModal = () => {
    setShowModalAddMembers(false);
  };

  /**
   * Handles the submission of adding members to a role.
   * @param {Object} eventAddUsersToRol - The event object from the form submission.
   * @param {Array} errors - Array of errors from the form validation.
   * @param {Object} addMembers - The state object containing members to be added.
   * @param {function} setIsErrorSelectedUsers - Function to set the state of selected users error.
   * @param {function} setIsLoadingMembersUser - Function to set the loading state for adding members.
   * @param {function} addMembersByRol - Function to add members to a role.
   * @param {string} idRol - The ID of the role to add members to.
   * @param {function} showAlertServiceError - Function to show a service error alert.
   * @param {function} setShowModalAddMembers - Function to set the state of the modal visibility.
   * @param {Object} i18n - The internationalization object for translations.
   */
  const handleOnSubmitAddMembersRol = (eventAddUsersToRol, errors) => {
    if (addMembers.members.length === 0) {
      setIsErrorSelectedUsers(true);
      setIsLoadingMembersUser(false);
      eventAddUsersToRol.preventDefault();
    }
    eventAddUsersToRol.preventDefault();
    if (errors.length === 0) {
      if (addMembers.members.length === 0) {
        eventAddUsersToRol.preventDefault();
      } else {
        setIsLoadingMembersUser(true);
        addMembersByRol(idRol, addMembers)
          .then((response) => {
            if (response.status === 201) {
              const showAlertMemberAdded = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("createRoles.MembersSwal"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(() => {
                  window.location.reload();
                });
              };
              showAlertMemberAdded();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingMembersUser(false);
            setShowModalAddMembers(false);
          });
      }
    }
  };

  /**
   * Handles closing the modal and resetting the state.
   * @function handleCloseReset
   * @param {function} setShowModalAddMembers - Function to set the state of the modal visibility.
   * @param {function} setAddMembers - Function to set the state of the addMembers object.
   * @param {Object} addMembers - The current state of the addMembers object.
   * @param {function} setIsErrorSelectedUsers - Function to set the error state.
   */
  const handleCloseReset = () => {
    setShowModalAddMembers(false);
    setAddMembers({
      ...addMembers,
      members: [],
    });
    setIsErrorSelectedUsers(false);
  }

  /**
   * Calls the getUsersByRolSearch function when the component mounts
   * or when the getUsersByRolSearch function changes.
   * @param {function} getUsersByRolSearch - The function to fetch and handle users by role.
   */
  useEffect(() => {
    getUsersByRolSearch();
  }, [getUsersByRolSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              onClick={handleOnClickOpenModal}
            >
              <span>
                {"+ "}
                {i18n.t("createRoles.AddMembers")}
              </span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModalAddMembers}
          toggle={handleOnCloseModal}
          className="modal-medium-size"
        >
          <ModalHeader>
            <Label>{i18n.t("createRoles.AddMembers")}</Label>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmitAddMembersRol}>
              <Row>
                <Col md="12">
                  <Alert className="mbg-3" color="info" isOpen={true}>
                    <span className="pr-2">
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </span>
                    {i18n.t("createRoles.MembersAlert")}
                  </Alert>{" "}
                  <AvGroup>
                    <Label
                      for="users_uuid"
                      className={cx("is-required", {
                        "labels-error": isErrorSelectedUsers === true,
                      })}
                    >
                      {i18n.t("taskReassign.users")}
                    </Label>
                    <div>
                      <Select
                        id="users_uuid"
                        name="users_uuid"
                        classNamePrefix={cx("", {
                          select: isErrorSelectedUsers === true,
                        })}
                        placeholder={i18n.t("notifications.usersPlaceholder")}
                        closeMenuOnSelect={true}
                        components={makeAnimated()}
                        isMulti
                        value={selectedUsersId}
                        options={membersDinamic}
                        onChange={handleOnChangeUsers}
                        noOptionsMessage={() => i18n.t("createRoles.MembersNousers")}
                      />
                      {requiredSelectUsers}
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={handleCloseReset}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingMembersUsers}
                  className="col-mt-3 mr-3"
                  color="cyan"
                >
                  {componentIsLoadingMembersRol}
                  {i18n.t("createRoles.MembersButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ButtonAddMembersToRol.propTypes = {
  idRol: PropTypes.string.isRequired, // Ajusta el tipo según corresponda
};

export default ButtonAddMembersToRol;