import React, { Fragment, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PropTypes from "prop-types";
import { Alert, Card, CardHeader, Col, Row } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNullOrUndefined } from "utils/validations";
import { useParams } from "react-router-dom";
import { CREATE_EXPEDIENT, TYPE } from "constants/securityConst";
import { enumsCreateExpedient } from "utils/enums";
import FilterListState from "components/atoms/FilterListState";
import ButtonCreateExpedient from "components/atoms/ButtonCreateExpedient";
import RecordExpedientList from "components/organism/RecordExpedientList";
import cx from "classnames";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import SearchBoxLookupField from "components/atoms/SearchBoxLookupField";
import i18n from "locales/i18n";

const RecordListExpedient = (props) => {
  const { id } = useParams();
  const CREATEEXPEDIENT = window.localStorage.getItem(CREATE_EXPEDIENT);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const formUuid = id;
  const {
    formIdInfo,
    expedientComeFrom,
    hasExpedientFixedPath,
    isFreeTask,
    isProcess,
    isProcessBatch,
  } = props;
  const {
    parentId,
    backgroundId,
    pathConfiguration,
    backgroudByPath,
    showTrdLevelsOrdenation,
    setShowTrdLevelsOrdenation,
    currentPath,
    pathConfig,
    recordNumber,
    recordUuid,
  } = useRecordDetailContext();
  const {
    getExpedientsBySearch,
    expedientSetPath,
    listExpedients,
    nodataMessage,
    totalPages,
    loadingExpedientList,
    errorCharacters,
    setErrorCharacters,
    searchData,
    setSearchData,
    status,
    setStatus: setStatusList,
    refreshDataExpedient,
    setIsByRecord,
    setFoldersId,
    foldersId,
    setExpedientParent,
    paginationRecordList,
    setPaginationRecordList,
  } = useTrdContext();
  const { page, per_page } = paginationRecordList;
  let backgroundIdConsult = backgroundId;
  let parentIdConsult = parentId;
  let trdLevelOrdenation = null;
  let titleExpedientTreeByBatch = null;
  let buttonCreateExpedientValidated = null;
  let pathDinamic = pathConfig;
  let formUuidVlidated = formUuid;

  /**
   * Conditionally renders a CardHeader component with a specific title based on whether the process is a batch process.
   * @param {boolean} isProcessBatch - A boolean indicating whether the process is a batch process.
   * If true, the title for the CardHeader will be rendered.
   * @param {string} i18n - The internationalization object used for translating text.
   * @returns {JSX.Element|null} - Returns the CardHeader component with the appropriate title if isProcessBatch is true,
   * otherwise returns null.
   */
  if (isProcessBatch === true) {
    titleExpedientTreeByBatch = (
      <CardHeader className="mb-4">{i18n.t("trd.formTittle14")}</CardHeader>
    );
  }

  /**
   * Sets the parent ID for consultation based on the path configuration.
   * @param {string} pathConfiguration - The path configuration.
   * @param {string} parentId - The parent ID.
   * @returns {string} The parent ID for consultation.
   */
  if (isNullOrUndefined(pathConfiguration) === false) {
    parentIdConsult = pathConfiguration;
    backgroundIdConsult = backgroudByPath;
  }

  /**
   * Checks conditions to determine if the expedient structure is available, and performs actions accordingly.
   * @param {boolean} isProcessBatch - Flag indicating if the process is a batch process.
   * @param {string} recordUuid - The UUID of the record associated with the expedient structure.
   * @param {boolean} hasExpedientFixedPath - Flag indicating if the expedient has a fixed path.
   * @param {boolean} setShowTrdLevelsOrdenation - Function to set the visibility of TRD level ordination.
   * @param {number} page - Current page number for pagination.
   * @param {number} per_page - Number of items per page for pagination.
   * @param {string} parentIdConsult - ID used for parent consultation.
   * @param {string} backgroundIdConsult - ID used for background consultation.
   * @param {object} searchData - Data used for searching expedients.
   * @param {string} status - Status of the expedient search.
   * @returns {void}
   */

  const getHasExpedientStructure = useCallback(() => {
    const shouldFetchExpedients = () => {
      return (
        isNullOrUndefined(pathConfiguration) === false ||
        isNullOrUndefined(parentId) === false ||
        hasExpedientFixedPath
      );
    };

    if (shouldFetchExpedients()) {
      getExpedientsBySearch(
        page,
        per_page,
        parentIdConsult,
        backgroundIdConsult,
        searchData,
        status
      );

      if (hasExpedientFixedPath === true) {
        setShowTrdLevelsOrdenation(false);
      }
    }
  }, [// eslint-disable-line
    paginationRecordList,
    refreshDataExpedient,
    status,
    pathConfiguration,
    parentId,
    parentIdConsult,
    backgroundIdConsult,
  ]);

  /**
   * Assigns a dynamic path based on the provided `expedientSetPath` object if it contains keys.
   * @param {Object} expedientSetPath - The object containing the path details.
   * @returns {void}
   */
  if (
    Object.keys(expedientSetPath).length > 0 &&
    isNullOrUndefined(pathConfiguration) === false
  ) {
    pathDinamic = expedientSetPath;
  }

  /**
   * Assigns the form UUID based on the source of the expedient creation.
   * @param {string} expedientComeFrom - Indicates the source of the expedient creation.
   * Should be one of the values from enumsCreateExpedient enum.
   * @param {string} formIdInfo - The form UUID or identifier to be assigned if expedientComeFrom is 'TASK'.
   * @returns {string} The validated form UUID based on the source of the expedient creation.
   */
  if (expedientComeFrom === enumsCreateExpedient.TASK) {
    formUuidVlidated = formIdInfo;
  }

  /**
   * Conditionally renders a button component for creating an expedient if certain criteria are met.
   * @param {boolean} CREATEEXPEDIENT - Boolean flag indicating whether creating an expedient is allowed.
   * @param {Array} CREATEEXPEDIENT - An array containing form UUIDs that allow expedient creation.
   * @param {string} formUuidValidated - The UUID of the validated form.
   * @param {string} USERTYPE - The type of user.
   * @param {string} recordUuid - The UUID of the record associated with the expedient.
   * @param {string} expedientComeFrom - Information about the source of the expedient.
   * @returns {JSX.Element | null} The button component for creating an expedient, or null if conditions are not met.
   */
  if (
    (isNullOrUndefined(CREATEEXPEDIENT) === false &&
      CREATEEXPEDIENT.includes(formUuidVlidated) === true &&
      isNullOrUndefined(USERTYPE) === false) ||
    USERTYPE === "3"
  ) {
    buttonCreateExpedientValidated = (
      <ButtonCreateExpedient
        formUuid={formUuidVlidated}
        recordUuid={recordUuid}
        expedientComeFrom={expedientComeFrom}
      />
    );
  }

  /**
   * Renders TRD level ordenation component if showTrdLevelsOrdenation is false.
   * This component provides options for searching, filtering, and creating expedients.
   * @param {boolean} showTrdLevelsOrdenation - Flag indicating whether to show TRD level ordenation component.
   * @param {function} setSearchData - Function to set search data.
   * @param {object} searchData - Object containing search data.
   * @param {array} paginationRecordList - List of records for pagination.
   * @param {function} setPaginationRecordList - Function to set pagination record list.
   * @param {string} errorCharacters - Error message related to characters.
   * @param {function} setErrorCharacters - Function to set error characters.
   * @param {function} setStatusList - Function to set status.
   * @returns {JSX.Element|null} - JSX element representing TRD level ordenation component, or null if showTrdLevelsOrdenation is true.
   */
  if (showTrdLevelsOrdenation === false) {
    trdLevelOrdenation = (
      <div className="mb-2">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          {i18n.t("recordFile.tooltip2")}
        </Alert>
        <Alert className="mbg-3" color="info" isOpen={true}>
          <div className="d-flex">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={pathDinamic}
            />
          </div>
        </Alert>
        <Row>
          <Col
            lg="12"
            md="12"
            sm="12"
            className="button-container mt-2 mt-md-0"
          >
            <Row className="row justify-content-end mr-2 mb-2">
              <div className="mr-2">
                <SearchBoxLookupField
                  setSearchData={setSearchData}
                  searchData={searchData}
                  paginationRecords={paginationRecordList}
                  setPaginationRecords={setPaginationRecordList}
                  errorCharacters={errorCharacters}
                  setErrorCharacters={setErrorCharacters}
                  isRecordListExpedient={true}
                />
              </div>

              <FilterListState
                isExpedients={true}
                setSearchData={setSearchData}
                setStatusList={setStatusList}
                pagination={paginationRecordList}
                setPagination={setPaginationRecordList}
              />

              {buttonCreateExpedientValidated}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  /**
   * useEffect hook that updates various state variables when the component mounts.
   * @param {object} foldersId - The state object representing folder IDs.
   * @param {string} backgroundId - The background ID to be assigned to the folders.
   * @param {function} setFoldersId - State setter function for updating folder IDs.
   * @param {boolean} setIsByRecord - State setter function for updating the flag indicating if it's by record.
   * @param {string} parentIdConsult - The parent ID for the consultation.
   * @param {function} setExpedientParent - State setter function for updating the parent ID of the expedient.
   * @returns {void}
   */
  useEffect(() => {
    setFoldersId({ ...foldersId, backgroundId: backgroundId });
    setIsByRecord(true);
    setExpedientParent(parentIdConsult);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the 'getExpedientsBySearch' function when the component mounts or when 'getExpedientsBySearch' changes.
   * This effect is responsible for fetching expedients based on search criteria.
   * @function
   * @return {void}
   */
  useEffect(() => {
    getHasExpedientStructure();
  }, [getHasExpedientStructure]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        className="scrollingContainer"
      >
        <div className="app-inner-layout rm-sidebar">
          <div className="app-inner-layout__content ">
            <Card
              className={cx("forms-fields mb-4", {
                "forms-fields mb-4 form-container-batch":
                  isProcessBatch === true,
              })}
            >
              {titleExpedientTreeByBatch}
              {trdLevelOrdenation}

              <RecordExpedientList
                recordNumber={recordNumber}
                recordUuid={recordUuid}
                formUuid={formUuid}
                isFreeTask={isFreeTask}
                isProcess={isProcess}
                isProcessBatch={isProcessBatch}
                nodataMessage={nodataMessage}
                listExpedients={listExpedients}
                pagination={paginationRecordList}
                setPagination={setPaginationRecordList}
                totalPages={totalPages}
                loadingExpedientList={loadingExpedientList}
              />
            </Card>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

RecordListExpedient.propTypes = {
  formIdInfo: PropTypes.string,
  expedientComeFrom: PropTypes.number,
  hasExpedientFixedPath: PropTypes.bool,
  isFreeTask: PropTypes.number,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
};

export default RecordListExpedient;