import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, CardFooter, CustomInput, Spinner } from "reactstrap";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { useRolesContext } from "contextAPI/RolesContext";
import { showAlertServiceError } from "utils/alerts";
import { enumsRolesAcordeon, enumsParameterRolesConfig } from "utils/enums";
import Loader from "react-loaders";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserRolConfiguration = (props) => {
  const { id } = useParams();
  const { uuidRol } = props;
  const { addPermissionsConfig } = useRoles();
  const {
    permissionsConf,
    setPermissionsConf,
    isLoadingConfig,
    setIsLoadingConfig,
    listForms,
    listWorkflows,
    rolPermissions,
    listAdminForms,
    listAdminWorkflows,
    setListAdminForm,
    setListAdminWorkflow,
    permissionsForm,
    setPermissionsForm,
    isLoadingPermmissionsByConfig,
    getPermissionsByConfig,
    acordeon,
    setAcordeon,
  } = useRolesContext();
  let loadingRolConfiguration = null;

  /**
   * Conditionally renders a loading spinner if `isLoadingConfig` is true.
   * Sets the `loadingRolConfiguration` variable to a Spinner component.
   * @function renderLoadingRolConfiguration
   * @param {boolean} isLoadingConfig - The loading state indicating whether the role configuration is being fetched.
   * @returns {JSX.Element|null} A JSX element containing the loading spinner, or null if not loading.
   */
  if (isLoadingConfig === true) {
    loadingRolConfiguration = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Handles the event to close the accordion and reset the permissions configuration.
   * Closes all sections of the accordion and clears the permissions list.
   * @returns {void}
   */
  const handleClose = () => {
    setAcordeon([false, false, false, false]);
    setPermissionsConf({
      permissions: [],
    });
  };

  /**
   * Handles changes to the role configuration form inputs.
   * Updates the `permissionsForm` state based on the input values.
   * @function handleOnChangeConfigurationRol
   * @param {Object} eventChangeRol - The event object generated by the input change.
   * @param {Object} eventChangeRol.target - The target element of the event.
   * @param {string} eventChangeRol.target.value - The value of the target element.
   * @param {boolean} eventChangeRol.target.checked - The checked state of the target element (for checkboxes).
   * @param {string} eventChangeRol.target.name - The name attribute of the target element, used as the key to update the state.
   */
  const handleOnChangeConfigurationRol = (eventChangeRol) => {
    const target = eventChangeRol.target.value;
    const targetCheckbox = eventChangeRol.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setPermissionsForm({
        ...permissionsForm,
        [eventChangeRol.target.name]: Boolean(targetCheckbox),
      });
    } else {
      setPermissionsForm({
        ...permissionsForm,
        [eventChangeRol.target.name]: target,
      });
    }
  };

  /**
   * Handles the submit event for role configuration.
   * Prevents the default form submission, updates the permissions configuration state,
   * and sends the updated configuration if there are no errors.
   * @function handleOnSubmitRolConfiguration
   * @param {Object} eventConfigurationRol - The event object generated by the form submission.
   * @param {function} eventConfigurationRol.preventDefault - Function to prevent the default action of the event.
   * @param {Array} errors - An array of errors to check before submitting the form.
   */
  const handleOnSubmitRolConfiguration = (eventConfigurationRol, errors) => {
    eventConfigurationRol.preventDefault();
    if (permissionsForm.config_trd === true) {
      setPermissionsConf(permissionsConf.permissions.push(enumsParameterRolesConfig.CONFIG_TRD));
    }
    if (permissionsForm.design_list === true) {
      setPermissionsConf(permissionsConf.permissions.push(enumsParameterRolesConfig.DESING_LIST));
    }
    if (permissionsForm.admin_users === true) {
      setPermissionsConf(permissionsConf.permissions.push(enumsParameterRolesConfig.ADMIN_USERS));
    }
    if (permissionsForm.roles === true) {
      setPermissionsConf(permissionsConf.permissions.push(enumsParameterRolesConfig.CONFIG_ROLES));
    }
    if (permissionsForm.workGroup === true) {
      setPermissionsConf(permissionsConf.permissions.push(enumsParameterRolesConfig.WORK_GROUPS));
    }
    if (errors.length === 0) {
      setIsLoadingConfig(true);
      addPermissionsConfig(id, permissionsConf)
        .then((response) => {
          if (response.status === 201) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("createRoles.configPermissions"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              setAcordeon([false, false, false, false]);
              setPermissionsConf({
                permissions: [],
              });
            });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => setIsLoadingConfig(false));
    }
  };

  /**
   * Effect hook that fetches permissions by configuration when a specific accordion section is expanded.
   * @param {Object} acordeon - The state object representing the accordion sections.
   * @param {string} uuidRol - The UUID of the role to fetch permissions for.
   * @returns {void}
   */
  useEffect(() => {
    if (acordeon[enumsRolesAcordeon.CONFIG_PAREMETER] === true) {
      getPermissionsByConfig(uuidRol);
    }
  }, [acordeon]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Updates the list of admin forms with form names based on the form UUIDs in `listAdminForms`.
   * Sets the `listAdminForm` state whenever `listAdminForms` or `listForms` changes.
   * @function useEffect
   * @param {Array<string>} listAdminForms - The array of form UUIDs for admin forms.
   * @param {Array<Object>} listForms - The array of form objects, each containing a `uuid` and `name` property.
   * @param {function} setListAdminForm - Function to set the list of admin form names.
   */
  useEffect(() => {
    const newPermissionsForms = [];

    listAdminForms.forEach((elementForm) => {
      const matchedForm = listForms.find((form) => form.uuid === elementForm);
      if (matchedForm) {
        newPermissionsForms.push(matchedForm.name);
      }
    });

    setListAdminForm(newPermissionsForms);
  }, [listAdminForms, listForms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Updates the list of admin workflows with workflow names based on the workflow UUIDs in `listAdminWorkflows`.
   * Sets the `listAdminWorkflow` state whenever `listAdminWorkflows` or `listWorkflows` changes.
   * @function useEffect
   * @param {Array<string>} listAdminWorkflows - The array of workflow UUIDs for admin workflows.
   * @param {Array<Object>} listWorkflows - The array of workflow objects, each containing a `uuid` and `name` property.
   * @param {function} setListAdminWorkflow - Function to set the list of admin workflow names.
   */
  useEffect(() => {
    const newPermissionsWorkflows = [];

    listAdminWorkflows.forEach((elementWorkflow) => {
      const matchedWorkflow = listWorkflows.find((workflow) => workflow.uuid === elementWorkflow);
      if (matchedWorkflow) {
        newPermissionsWorkflows.push(matchedWorkflow.name);
      }
    });

    setListAdminWorkflow(newPermissionsWorkflows);
  }, [listAdminWorkflows, listWorkflows]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Sets the permissions form state based on the role permissions.
   * Updates the `permissionsForm` state whenever `rolPermissions` or `isLoadingPermmissionsByConfig` changes.
   * @function useEffect
   * @param {Array<number>} rolPermissions - The array of role permissions.
   * @param {boolean} isLoadingPermmissionsByConfig - The loading state indicating whether permissions are being loaded.
   * @param {function} setPermissionsForm - Function to set the permissions form state.
   */
  useEffect(() => {
    const newPermissionsForm = {};

    if (rolPermissions.includes(enumsParameterRolesConfig.CONFIG_TRD)) {
      newPermissionsForm.config_trd = true;
    } else {
      newPermissionsForm.config_trd = false;
    }
    if (rolPermissions.includes(enumsParameterRolesConfig.DESING_LIST)) {
      newPermissionsForm.design_list = true;
    } else {
      newPermissionsForm.design_list = false;
    }
    if (rolPermissions.includes(enumsParameterRolesConfig.ADMIN_USERS)) {
      newPermissionsForm.admin_users = true;
    } else {
      newPermissionsForm.admin_users = false;
    }
    if (rolPermissions.includes(enumsParameterRolesConfig.CONFIG_ROLES)) {
      newPermissionsForm.roles = true;
    } else {
      newPermissionsForm.roles = false;
    }
    if (rolPermissions.includes(enumsParameterRolesConfig.WORK_GROUPS)) {
      newPermissionsForm.workGroup = true;
    } else {
      newPermissionsForm.workGroup = false;
    }

    setPermissionsForm(newPermissionsForm);
  }, [rolPermissions, isLoadingPermmissionsByConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a loading spinner if permissions are being loaded by configuration.
   * @param {boolean} isLoadingPermmissionsByConfig - Flag indicating if the permissions are currently being loaded.
   * @returns {JSX.Element|null} The loading spinner element if loading, otherwise null.
   */
  if (isLoadingPermmissionsByConfig === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="secondary" type="ball-pulse-rise" />
      </div>
    );
  }

  return (
    <Fragment>
      <br />
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleOnSubmitRolConfiguration}>
          <AvGroup>
            <em className="text-primary text-capitalize font-weight-bold">
              {i18n.t("createRoles.config")}
            </em>
            <AvInput
              id="config_trd"
              name="config_trd"
              type="checkbox"
              onChange={handleOnChangeConfigurationRol}
              tag={CustomInput}
              checked={permissionsForm.config_trd}
              label={i18n.t("createRoles.configTRD")}
              value={permissionsForm.config_trd}
            />

            <AvInput
              id="design_list"
              name="design_list"
              type="checkbox"
              onChange={handleOnChangeConfigurationRol}
              tag={CustomInput}
              checked={permissionsForm.design_list}
              label={i18n.t("createRoles.configDesign")}
              value={permissionsForm.design_list}
            />
          </AvGroup>
          <AvGroup>
            <em className="text-primary text-capitalize font-weight-bold mt-3 mb-3">
              {i18n.t("createRoles.configAdminUser")}
            </em>

            <AvInput
              id="admin_users"
              name="admin_users"
              type="checkbox"
              onChange={handleOnChangeConfigurationRol}
              tag={CustomInput}
              checked={permissionsForm.admin_users}
              label={i18n.t("createRoles.configAdminUserLabel")}
              value={permissionsForm.admin_users}
            />

            <AvInput
              id="roles"
              name="roles"
              type="checkbox"
              onChange={handleOnChangeConfigurationRol}
              tag={CustomInput}
              checked={permissionsForm.roles}
              label={i18n.t("createRoles.configRol")}
              value={permissionsForm.roles}
            />

            <AvInput
              id="workGroup"
              name="workGroup"
              type="checkbox"
              onChange={handleOnChangeConfigurationRol}
              tag={CustomInput}
              checked={permissionsForm.workGroup}
              label={i18n.t("create.work.group.nav")}
              value={permissionsForm.workGroup}
            />
          </AvGroup>

          <CardFooter className="d-block text-right">
            <Button
              size="lg"
              onClick={handleClose}
              disabled={isLoadingConfig}
              className="col-mt-3 mr-3"
              color="gray"
            >
              {loadingRolConfiguration}
              {i18n.t("createusers.createButton2")}
            </Button>
            <Button
              type="submit"
              size="lg"
              disabled={isLoadingConfig}
              className="col-mt-3"
              color="cyan"
            >
              {loadingRolConfiguration}
              {i18n.t("createWorkflowDesign.saveModal")}
            </Button>
          </CardFooter>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserRolConfiguration.propTypes = {
  uuidRol: PropTypes.string.isRequired,
};

export default UserRolConfiguration;
