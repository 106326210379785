import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { enumsFilterState } from "utils/enums";
import i18n from "locales/i18n";

const FilterListState = (props) => {
  const {
    isExpedients,
    setSearchData,
    setStatusList,
    pagination,
    setPagination,
  } = props;
  let titleFilter = i18n.t("filterList.header");
  let statusActiveName = i18n.t("list.Active");
  let statusInactiveName = i18n.t("list.Inactive");

  /**
   * Sets title and status names based on the value of 'isExpedients'.
   * @param {boolean} isExpedients - Indicates whether expedients are being processed.
   * If true, title and status names for expedients are set; otherwise, default values are used.
   * @param {string} i18n - The internationalization object used for translating text.
   * @returns {void}
   */
  if (isExpedients === true) {
    titleFilter = i18n.t("trdExpedientList11");
    statusActiveName = i18n.t("trdExpedientList9");
    statusInactiveName = i18n.t("trdExpedientList10");
  }
  /**
   *Function that handles the onChange event of a select input and updates the status and pagination properties accordingly.
   *@param {object} eventFilterList - the event object
   */
  const handleOnChange = (eventFilterList) => {
    if (isExpedients === true) {
      setSearchData("");
    }
    setStatusList(eventFilterList.target.value);
    setPagination({ ...pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("filteruser.button")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem header>{titleFilter}</DropdownItem>
              <DropdownItem value={enumsFilterState.ALL} onClick={handleOnChange}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={enumsFilterState.ACTIVE} onClick={handleOnChange}>
                {statusActiveName}
              </DropdownItem>
              <DropdownItem value={enumsFilterState.INACTIVE} onClick={handleOnChange}>
                {statusInactiveName}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterListState.propTypes = {
  isExpedients: PropTypes.bool,
  setSearchData: PropTypes.func,
  setStatusList: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default FilterListState;
