import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, CardTitle, Label, CardFooter, Container, Spinner } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import {
  numberLevel,
} from "utils/trdLevelsNameEdition";
import { trimExtraSpaces } from "utils/trimSpaces";
import { showAlertServiceError } from "utils/alerts";
import { enumEditSerieForm } from "utils/enums";
import {
  regexEditSerieFormCode,
  regexEditSerieFormName
} from "utils/regexExpressions";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import useEditLevelTrd from "hooks/useEditLevelTrd";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";

const EditSerieForm = () => {
  const { updateSerie } = useTrd();
  const { trdLevelInformation } = useEditLevelTrd(numberLevel.SERIE);
  const [keysTrdEdit] = useState([
    "name",
    "code",
    "parent_id",
    "background_parent_id"
  ])
  const [tempSerie, setTempSerie] = useState({
    name: "",
    code: "",
  })
  const {
    trdForm,
    setTrdForm,
    setShowFolderCondition,
    setShowEditSerieForm,
    setLevelFolder,
    setSelectLevel,
    editSerieFormService,
    setEditSerieFormService,
    getTreeByDomainCallback,
    isLoading: isLoadingEditSerie,
    setIsLoading
  } = useTrdContext();

  let loadingSpinnerEditSerie = null;

  /**
   * Conditionally renders a loading spinner if the series is being edited.
   * @param {boolean} isLoadingEditSerie - A boolean flag indicating if the series is currently being edited.
   * @returns {JSX.Element|null} The loading spinner JSX element if `isLoadingEditSerie` is true, otherwise null.
   */
  if (isLoadingEditSerie === true) {
    loadingSpinnerEditSerie = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Resets the form representing a TRD (Technical Requirement Document) series.
   * This function updates the `trdForm` state by spreading its existing properties and resetting the "name" property to an empty string
   * and the "level" property to the value of `numberLevel.SERIE`.
   * It also updates the states `levelFolder` and `selectLevel` to the value of `numberLevel.SERIE`.
   * @returns {void}
   */
  const resetSerieForm = () => {
    setTrdForm({
      ...trdForm,
      name: "",
      level: numberLevel.SERIE
    });
    setLevelFolder(numberLevel.SERIE)
    setSelectLevel(numberLevel.SERIE)
  };

  /**
   * Closes the form representing a TRD (Technical Requirement Document) series.
   * This function updates the `trdForm` state by spreading its existing properties and setting the "level" property to the value of `numberLevel.SERIE`.
   * It also sets the states `showFolderCondition` to `true` and `showEditSerieForm` to `false`.
   * Finally, it calls the `resetSerieForm` function to reset the form fields.
   * @function
   * @returns {void}
   */
  const closeSerie = () => {
    setTrdForm({
      ...trdForm,
      level: numberLevel.SERIE,
    });
    setShowFolderCondition(true);
    setShowEditSerieForm(false);
    setTrdForm(resetSerieForm);
  }

  /**
   * Handles the change event for the form inputs representing a TRD (Technical Requirement Document) series.
   * This function updates the `editSerieFormService` state by spreading its existing properties and modifying the property
   * specified by the event's target name with the corresponding value from the event.
   * @function
   * @param {Object} eventOnchange - The event object triggered by the change event on the form input.
   * @returns {void}
   */
  const handleOnChangeSerie = (eventOnchange) => {
    setEditSerieFormService({
      ...editSerieFormService,
      [eventOnchange.target.name]: eventOnchange.target.value,
    });
  };

  /**
   * Handles the key press event for form inputs representing a TRD (Technical Requirement Document) series.
   * This function checks if the key pressed is the "Enter" key. If so, it prevents the default behavior of the key press event.
   * @param {Object} eventOnkeyPressSerie - The event object triggered by the key press event on the form input.
   * @returns {void}
   */
  const handleOnKeyPressEditSerie = (eventOnkeyPressSerie) => {
    if (eventOnkeyPressSerie.key === "Enter") {
      eventOnkeyPressSerie.preventDefault();
    }
  };

  /**
   * Handles the blur event for form inputs representing a TRD (Technical Requirement Document) series.
   * This function updates the `editSerieFormService` state by spreading its existing properties and modifying the property
   * specified by the event's target name with the corresponding value from the event (after removing leading and trailing whitespace).
   * @param {Object} eventOnBlurEditSerie - The event object triggered by the blur event on the form input.
   * @returns {void}
   */
  const handleOnBlurEditSerie = (eventOnBlurEditSerie) => {
    const { value, name } = eventOnBlurEditSerie.target;
		const trimmedValue = trimExtraSpaces(value);
    setEditSerieFormService({
      ...editSerieFormService,
      [name]: trimmedValue,
    });
  };

  /**
   * Handles the submit event for the form representing a TRD (Technical Requirement Document) series.
   * This function prevents the default behavior of the submit event using `eventEditSerie.preventDefault()`.
   * It then checks if there are any validation errors (`errors` array). If there are no errors, it proceeds to update the TRD.
   * The function uses `keysTrdEdit` to iterate through specific keys in the `editSerieFormService` state and compares them with the
   * corresponding keys in the `tempSerie` state. If there are differences, it creates an object `updatedSerieTrd` with the modified
   * fields. The number of keys in `updatedSerieTrd` is checked, and if it is exactly 2, a warning message is shown using the `swal` function.
   * @param {Object} eventEditSerie - The event object triggered by the submit event on the form.
   * @param {Array} errors - An array of validation errors, if any.
   * @returns {void}
   */
  const handleOnSubmitEditSerie = (eventEditSerie, errors) => {
    eventEditSerie.preventDefault();
    if (errors.length === 0) {
      const updatedSerieTrd = keysTrdEdit.reduce((acomulatorField, fieldKey) => {
        if (editSerieFormService[fieldKey] !== tempSerie[fieldKey]) {
          acomulatorField[fieldKey] = editSerieFormService[fieldKey];
        }
        return acomulatorField;
      }, {});

      if (Object.keys(updatedSerieTrd).length === 2) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        })
        return
      }

      setIsLoading(true)
      updateSerie(trdLevelInformation.trdId, updatedSerieTrd)
        .then(response => {
          if (response.status === 202) {
            setTrdForm({
              ...trdForm,
              name: "",
              level: numberLevel.SERIE,
              code: editSerieFormService.code
            });
            setLevelFolder(numberLevel.SERIE)
            setSelectLevel(numberLevel.SERIE)
            setShowFolderCondition(true);
            setShowEditSerieForm(false)
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trd.formTittle38"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            })
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => { setIsLoading(false) })
    }
  }

  /**
   * Perform side effects when the component mounts.
   * This effect updates the `editSerieFormService` state by spreading its existing properties and setting the "name" property
   * to the value of `trdLevelInformation.name`, the "code" property to the value of `trdForm.code`, the "parent_id" property to
   * This effect is triggered only when the component mounts (empty dependency array).
   * @function
   * @returns {void}
   */
  useEffect(() => {
    setEditSerieFormService({
      ...editSerieFormService,
      name: trdLevelInformation.name,
      code: trdForm.code,
      parent_id: trdLevelInformation.parentId,
      background_parent_id: trdLevelInformation.backgroundParentId

    })
    setTempSerie({
      ...tempSerie,
      name: trdLevelInformation.name,
      code: trdForm.code
    })
    // eslint-disable-next-line 
  }, [])

  /**
   * Perform side effects when the component mounts or when the dependency `getTreeByDomainCallback` changes.
   * This effect calls the function `getTreeByDomainCallback()` to fetch tree data related to the current domain.
   * The effect is triggered when the component mounts and also when the `getTreeByDomainCallback` function reference changes.
   * @function
   * @returns {void}
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]); // eslint-disable-line 

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <CardTitle>{i18n.t("trd.formTittle37")}</CardTitle>
          <AvForm onSubmit={handleOnSubmitEditSerie}>
            <AvGroup>
              <ReactTooltip id="code" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip7")}
              </ReactTooltip>
              <Label for="code" className="is-required">
                {i18n.t("trd.fromLabel1")}
              </Label>
              <AvField
                id="code"
                name="code"
                type="text"
                onChange={handleOnChangeSerie}
                onKeyPress={handleOnKeyPressEditSerie}
                onBlur={handleOnBlurEditSerie}
                validate={{
                  pattern: {
                    value: regexEditSerieFormCode,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback1")}`,
                  },
                  minLength: {
                    value: enumEditSerieForm.MIN_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: enumEditSerieForm.MAX_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="code"
                autoComplete="off"
                value={editSerieFormService.code}
              />
            </AvGroup>

            <AvGroup>
              <ReactTooltip
                id="name"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip8")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.inputTittle4")}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeSerie}
                onKeyPress={handleOnKeyPressEditSerie}
                onBlur={handleOnBlurEditSerie}
                validate={{
                  pattern: {
                    value: regexEditSerieFormName,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: enumEditSerieForm.MIN_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumEditSerieForm.MAX_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="name"
                autoComplete="off"
                required
                value={editSerieFormService.name}

              />
            </AvGroup>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={closeSerie}
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                className="col-mt-3"
                color="cyan"
                disabled={isLoadingEditSerie}
              >
                {loadingSpinnerEditSerie}
                {i18n.t("trd.buttonUptade")}
              </Button>
            </CardFooter>
          </AvForm>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditSerieForm;
