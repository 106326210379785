import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  Alert,
  CardFooter
} from "reactstrap";
import { Loader } from "react-loaders";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useDocumentaryContext } from "contextAPI/DocumentaryTypesContext"
import i18n from "locales/i18n";

const DocumentaryTypesCreate = () => {
  const {
    setIsCreate,
    documentaryTypeName,
    handleOnChange,
    actionCreateDocumentaryType,
    validate,
    setValidate,
    loadingCreationDocumentaryType,
    handleOnBlur
  } = useDocumentaryContext();

  let loaderElement = null;

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      setValidate(true)
      handleOnSubmit()
    }
  };

  const handleOnSubmit = (e, errors) => {
    setValidate(true)
    if (errors && errors.length === 0) {
      if (documentaryTypeName.name.trim() !== "") {
        actionCreateDocumentaryType(documentaryTypeName)
      }
    }
  }

  /**
   * Renders a loader element if the loader state is true.
   * @param {boolean} loader - Indicates whether the loader should be displayed.
   * @returns {JSX.Element|null} Loader element JSX if loader state is true, otherwise null.
   */
  if (loadingCreationDocumentaryType === true) {
    loaderElement = (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="primary" type="ball-pulse-rise" />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {loaderElement}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <Button
              className="btn-icon"
              color="cyan"
              onClick={() => setIsCreate(false)}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              <span> {i18n.t("documentary.type.table.label8")}</span>
            </Button>
          </CardFooter>
          <CardBody>

            <Alert className="mbg-3" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("documentary.type.table.label5")}
            </Alert>
            <br />
            <CardTitle>
              {i18n.t("documentary.type.table.label6")}
            </CardTitle>
            <div className="divider" />
            <AvForm onSubmit={handleOnSubmit}>
              <AvGroup row>

                <Label for="list_name" className="is-required" sm={4}>
                  {i18n.t("documentary.type.table.label7")}
                </Label>
                <Col md={6}>
                  <ReactTooltip
                    id="name"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    {i18n.t("trd.Tooltip4")}
                  </ReactTooltip>
                  <AvField
                    id="name"
                    name="name"
                    type="text"
                    autoFocus={true}
                    autoComplete="off"
                    value={documentaryTypeName.name}
                    onChange={handleOnChange}
                    onKeyPress={handleOnKeyPress}
                    data-tip
                    data-for="name"
                    onBlur={handleOnBlur}
                    validate={{
                      required: {
                        value: validate,
                        errorMessage: `${i18n.t("documentary.type.label2.error")}`,
                      },
                      pattern: {
                        value: "/^([A-Za-z0-9 À-ÿ-_]+)$/",
                        errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                      },
                      minLength: {
                        value: 4,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: 100,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                  />

                </Col>
                <Col md={2}>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={loadingCreationDocumentaryType}
                    className="col-mt-3 ml-2"
                    color="cyan"
                  >
                    {i18n.t("createusers.createButton")}
                  </Button>
                </Col>
              </AvGroup>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default DocumentaryTypesCreate