import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody, CardFooter } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import ConsultListsTable from "components/organism/ConsultListsTable";
import ButtonCreateList from "components/atoms/ButtonCreateList";
import SearchBox from "components/atoms/SearchBox";
import FilterListState from "components/atoms/FilterListState";
import useList from "hooks/useList";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ConsultLists = () => {
  const [lists, setLists] = useState([]);
  const [isLoadingConsultList, setIsLoadingConsultList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchList, setSearchList] = useState("");
  const [statusList, setStatusList] = useState("");
  const { getLists } = useList();

  /**
   *Function that displays a sweetalert with a message indicating that no list were found based on the current searchList query and statusList filter.
   *@param {object} i18n - the i18n object used for translations
   */
  const showAlertConsultListNoExist = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("30006"),
      icon: "info",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   *A memoized function that fetches a list of lists based on the searchList, pagination, and statusList parameters.
   *@param {function} getUsers - the function that fetches the list data and returns a promise
   *@param {object} pagination - an object containing page and per_page properties to define the pagination of the results
   *@param {string} searchList - the searchList query to filter the users by
   *@param {string} statusList - the statusList to filter the users by
   *@param {function} showAlertConsultListNoExist - the function that displays an alert message if there are no lists found
   */
  const getListssBySearch = useCallback(() => {
    setIsLoadingConsultList(true);
    getLists(pagination.page, pagination.per_page, searchList, statusList)
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          setLists(response.data.items);
          setTotalPages(response.data.pages);
          if (response.data.items.length === 0) {
            showAlertConsultListNoExist();
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingConsultList(false);
      });
  }, [pagination, searchList, statusList]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that invokes the memoized getListssBySearch function when the component mounts or the getListssBySearch function changes.
   *@param {function} getListssBySearch - the memoized function that fetches a list of lists based on the searchList, pagination, and statusList parameters
   */
  useEffect(() => {
    getListssBySearch();
  }, [getListssBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchList}
              pagination={pagination}
              setPagination={setPagination}
            />
            <FilterListState
              setStatusList={setStatusList}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonCreateList />
          </CardFooter>
          <CardBody>
            <ConsultListsTable
              lists={lists}
              pagination={pagination}
              isLoadingConsultList={isLoadingConsultList}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ConsultLists;
