import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Card,
  Row,
  Col,
  Label,
  CustomInput,
  Spinner,
} from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRolesContext } from "contextAPI/RolesContext";
import { useParams } from "react-router-dom";
import Loader from "react-loaders";
import UserRolConfigDesignFormsTable from "components/organism/UserRolConfigDesignFormsTable";
import i18n from "locales/i18n";
import swal from "sweetalert";
import useRoles from "hooks/useRoles";
import { enumsRolesAcordeon } from "utils/enums";

const UserRolesConfigDesignForms = (props) => {
  const { addPermissionsDesignerForms } = useRoles();
  const { uuidRol } = props;
  const { id } = useParams();
  const {
    formsDinamic,
    setDisabledSelect,
    permissionsDesignerForms,
    setPermissionsDesignerForms,
    formsSelected,
    setFormsSelected,
    forms,
    setForms,
    deleteForms,
    formsTable,
    setFormsTable,
    setDisabledCheckForms,
    listAdminForms,
    allAdminForms,
    setAcordeon,
    isLoadingPermissionsAdminFormsByRol,
    acordeon,
    getActiveForms,
    getPermissionsByAdminForms
  } = useRolesContext();

  const [uniqueForms, setUniqueForms] = useState([]);
  const [loadingConfig, setLoadingConfig] = useState(false);

  let componentLoadingConfigDesignForm = null;
  let disabledSelectForms = null;
  let disabledAllForms = null;
  let valueAllForms = null;
  let buttonSubmitConfig = null;

  /**
   * Sets the state of `buttonSubmitConfig` based on the value of `loadingConfig`.
   * @param {boolean} loadingConfig - Indicates if the configuration is loading.
   * @returns {boolean} - The state indicating if the submit button should be enabled or disabled.
   */
  if (loadingConfig === true) {
    buttonSubmitConfig = true;
  } else {
    buttonSubmitConfig = false;
  }

  /**
   * Sets the value of `valueAllForms` based on `allAdminForms` and `permissionsDesignerForms.all_forms`.
   * @param {boolean} allAdminForms - Indicates if all forms are administered.
   * @param {Object} permissionsDesignerForms - Object containing the permissions for the designer forms.
   * @param {boolean} permissionsDesignerForms.all_forms - Indicates if all forms are allowed.
   * @returns {boolean} - The value indicating if all forms are selected.
   */
  if (allAdminForms === true) {
    valueAllForms = true;
  } else {
    valueAllForms = permissionsDesignerForms.all_forms;
  }

  /**
   * Enables or disables "All Forms" selection based on the length of the forms table.
   * @param {Array} formsTable - Array containing the forms.
   * @param {boolean} disabledAllForms - The state indicating if "All Forms" selection is disabled.
   */
  if (formsTable.length > 0) {
    disabledAllForms = true;
  } else {
    disabledAllForms = false;
  }

  /**
   * Enables or disables form selection based on the permissions.
   * @param {Object} permissionsDesignerForms - Object containing the permissions for the designer forms.
   * @param {boolean} permissionsDesignerForms.all_forms - Indicates if all forms are allowed.
   * @param {boolean} disabledSelectForms - The state indicating if form selection is disabled.
   */
  if (permissionsDesignerForms.all_forms) {
    disabledSelectForms = true;
  } else {
    disabledSelectForms = false;
  }

  /**
   * Renders a loading spinner if the configuration is loading.
   * @param {boolean} loadingConfig - The loading state of the configuration.
   * @param {JSX.Element} componentLoadingConfigDesignForm - The JSX element for the loading spinner.
   */
  if (loadingConfig === true) {
    componentLoadingConfigDesignForm = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Resets states and closes the designer forms.
   * @param {Object} permissionsDesignerForms - Current state of designer forms permissions.
   * @param {function} setPermissionsDesignerForms - Function to update the permissionsDesignerForms state.
   * @param {boolean} disabledCheckForms - State indicating whether check forms are disabled.
   * @param {function} setDisabledCheckForms - Function to update the disabledCheckForms state.
   * @param {Array} acordeon - Current state of accordion items.
   * @param {function} setAcordeon - Function to update the acordeon state.
   * @param {Array} forms - Current array of selected forms.
   * @param {function} setForms - Function to update the forms state.
   * @param {Array} formsTable - Current array of forms in table.
   * @param {function} setFormsTable - Function to update the formsTable state.
   * @param {Object} formsSelected - Current state of selected form.
   * @param {function} setFormsSelected - Function to update the formsSelected state.
   * @returns {void}
   */
  const handleCloseDesignerForms = () => {
    setPermissionsDesignerForms({
      all_forms: false,
      forms_uuid: []
    })
    setDisabledCheckForms(false)
    setAcordeon(([false, false, false, false]))
    setForms([])
    setFormsTable([])
    setFormsSelected({
      ...formsSelected,
      form: ""
    })
  };

  /**
   * Handles the change event for selecting a form.
   * @param {Object} eventFormsDesigner - The event object from the change event.
   * @param {Object} eventFormsDesigner.target - The target element of the event.
   * @param {string} eventFormsDesigner.target.value - The value of the target element (selected form).
   * @param {Object} formsSelected - The current state of selected forms.
   * @param {function} setFormsSelected - Function to update the `formsSelected` state.
   * @returns {void}
   */
  const handleOnChangeSig = (eventFormsDesigner) => {
    setFormsSelected({
      ...formsSelected,
      form: eventFormsDesigner.target.value,
    });
  };

  /**
   * Handles the form submission event for selecting forms.
   * @function handleOnSubmitForm
   * @param {Object} eventFormsDesigner - The event object from the form submission event.
   * @param {string} formsSelected.form - The selected form value from state.
   * @param {Array} forms - Array of selected form values.
   * @param {function} setForms - Function to update the `forms` state.
   * @param {Object} formsSelected - The current state of selected forms.
   * @param {function} setFormsSelected - Function to update the `formsSelected` state.
   * @param {function} setDisabledCheckForms - Function to update the disabled state of check forms.
   * @returns {void}
   */
  const handleOnSubmitForm = (eventFormsDesigner) => {
    if (formsSelected.form === "") {
      setDisabledCheckForms(false);
      eventFormsDesigner.preventDefault();
    } else if (forms.includes(formsSelected.form)) {
      const showAlertAlreadyExist = () => {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createRoles.configDesignFormsExist"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        });
      };
      showAlertAlreadyExist();
    } else {
      setDisabledCheckForms(true);
      setForms((prevState) => [...prevState, formsSelected.form]);
      setFormsSelected({ ...formsSelected, form: "" });
    }
  };

  /**
   * Updates the `formsTable` state based on filtered `uniqueForms` array.
   * @param {Array} uniqueForms - Array of unique form objects.
   * @param {Array} forms - Array of selected form values.
   * @param {Array} formsDinamic - Array of dynamic forms.
   * @param {function} setFormsTable - Function to update the `formsTable` state.
   */
  useEffect(() => {
    // eslint-disable-next-line
    let formsInTable = uniqueForms.filter((eventFormsDesigner) => {
      if (forms.includes(eventFormsDesigner.value)) {
        return eventFormsDesigner;
      }
    });
    setFormsTable(formsInTable);
  }, [forms, formsDinamic]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for the configuration of forms.
   * @param {Object} eventConfigForms - The event object from the change event.
   * @param {Object} eventConfigForms.target - The target element of the event.
   * @param {string} eventConfigForms.target.value - The value of the target element.
   * @param {boolean} eventConfigForms.target.checked - The checked state of the target element.
   * @param {function} setPermissionsDesignerForms - Function to update the `permissionsDesignerForms` state.
   * @param {Object} permissionsDesignerForms - The current state of designer forms permissions.
   * @param {function} setDisabledSelect - Function to update the disabled state of the select element.
   * @returns {void}
   */
  const handleOnChangeConfigForms = (eventConfigForms) => {
    const target = eventConfigForms.target.value;
    const targetCheckbox = eventConfigForms.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setPermissionsDesignerForms({
        ...permissionsDesignerForms,
        all_forms: Boolean(targetCheckbox),
      });
    }
    if (target.includes("true")) {
      setDisabledSelect(false);
    } else {
      setDisabledSelect(true);
    }
  };

  /**
   * Updates the `permissionsDesignerForms` state based on the `forms` and `formsTable` dependencies.
   * @param {boolean} allForms - Indicates if all forms are selected.
   * @param {Array} formsTable - Array of form objects containing `value` properties.
   * @param {function} setPermissionsDesignerForms - Function to update the `permissionsDesignerForms` state.
   * @param {Object} permissionsDesignerForms - The current state of designer forms permissions.
   */
  useEffect(() => {
    if (permissionsDesignerForms.all_forms === false) {
      setPermissionsDesignerForms({
        ...permissionsDesignerForms,
        forms_uuid: formsTable.map(obj => obj.value),
      });
    } else {
      setPermissionsDesignerForms({
        all_forms: true,
        forms_uuid: [],
      });
    }
  }, [forms, formsTable]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches permissions and active forms when the designer forms accordion is expanded.
   * @param {Array<boolean>} acordeon - Array representing the state of different accordions.
   * @param {string} uuidRol - The unique identifier for the role.
   * @param {Object} enumsRolesAcordeon - Object containing accordion state indices.
   * @param {function} getPermissionsByAdminForms - Function to fetch permissions by admin forms.
   * @param {function} getActiveForms - Function to fetch active forms.
   */
  const handleOnSubmitDesignForms = () => {
    setLoadingConfig(true);
    addPermissionsDesignerForms(id, permissionsDesignerForms)
      .then((response) => {
        if (response.status === 201) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createRoles.configPermissions"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setAcordeon(([false, false, false, false]))
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setLoadingConfig(false));
  };

  /**
   * Updates the unique forms state based on changes in formsDinamic.
   * @param {Array} formsDinamic - Array of dynamic forms data.
   * @param {Array} uniqueForms - Current array of unique forms.
   * @param {function} setUniqueForms - Function to update the uniqueForms state.
   * @returns {void}
   */
  useEffect(() => {
    const uniqueForms = Array.from(new Set(formsDinamic.map(form => form.label)))
      .map(label => {
        return formsDinamic.find(form => form.label === label)
      })
    setUniqueForms(() => uniqueForms)
  }, [formsDinamic])

  /**
   * Updates the forms state based on changes in listAdminForms and allAdminForms.
   * @param {Array} listAdminForms - Array of forms administered by the admin.
   * @param {boolean} allAdminForms - Boolean indicating whether all admin forms are selected.
   * @param {Array} forms - Current array of selected forms.
   * @param {function} setForms - Function to update the forms state.
   * @param {boolean} disabledCheckForms - State indicating whether check forms are disabled.
   * @param {function} setDisabledCheckForms - Function to update the disabledCheckForms state.
   * @param {boolean} disabledSelect - State indicating whether select is disabled.
   * @param {function} setDisabledSelect - Function to update the disabledSelect state.
   * @returns {void}
   */
  useEffect(() => {
    if (listAdminForms.length > 0) {
      setForms(forms.concat(listAdminForms));
      setDisabledCheckForms(true);
    } else if (allAdminForms === true) {
      setDisabledSelect(true);
    }
  }, [listAdminForms, allAdminForms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches permissions and active forms when the designer forms accordion is expanded.
   * @param {Array<boolean>} acordeon - Array representing the state of different accordions.
   * @param {string} uuidRol - The unique identifier for the role.
   * @param {Object} enumsRolesAcordeon - Object containing accordion state indices.
   * @param {function} getPermissionsByAdminForms - Function to fetch permissions by admin forms.
   * @param {function} getActiveForms - Function to fetch active forms.
   */
  useEffect(() => {
    if (acordeon[enumsRolesAcordeon.DESIGNER_FORMS] === true) {
      getPermissionsByAdminForms(uuidRol)
      getActiveForms()
    }
    // eslint-disable-next-line 
  }, [acordeon])

  /**
   * Renders a loader if permissions for admin forms by role are loading.
   * @param {boolean} isLoadingPermissionsAdminFormsByRol - Indicates if permissions for admin forms by role are loading.
   * @returns {JSX.Element|null} - A loader component if loading, otherwise null.
   */
  if (isLoadingPermissionsAdminFormsByRol === true) {
    return (
      <div className="d-flex justify-content-center">
        <Loader color="secondary" type="ball-pulse" size="small" />
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardHeader className="ml-3">
            {i18n.t("createRoles.configDesignFormsHeader")}
          </CardHeader>
          <CardBody>
            <Alert className="mbg-3" color="info">
              <span className="pr-2 text-bold">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2 "
                />
                {i18n.t("createRoles.configDesignFormsAlert")}
              </span>
            </Alert>
            <AvForm onSubmit={handleOnSubmitDesignForms}>
              <AvGroup>
                <Label for="forms" className="is-required">
                  {i18n.t("createRoles.configDesignFormsSelect")}
                </Label>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={10}>
                        <AvField
                          id="forms"
                          name="forms"
                          type="select"
                          onChange={handleOnChangeSig}
                          disabled={disabledSelectForms}
                          value={formsSelected.form}
                        >
                          <option value={""}>
                            {i18n.t("form.designerLabel10.1")}
                          </option>

                          {uniqueForms.map((eventSelectForm) => (
                            <option
                              className="text-capitalize"
                              value={eventSelectForm.value}
                              key={eventSelectForm.value}
                            >
                              {eventSelectForm.label}
                            </option>
                          ))}
                        </AvField>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          size="lg"
                          className="col-mt-3"
                          color="cyan"
                          disabled={disabledSelectForms}
                          onClick={handleOnSubmitForm}
                        >
                          +{i18n.t("createRoles.MembersButton")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4}>
                    <AvInput
                      id="all_forms"
                      name="all_forms"
                      type="checkbox"
                      onChange={handleOnChangeConfigForms}
                      tag={CustomInput}
                      label={i18n.t("filteruser.item1").toUpperCase()}
                      checked={disabledSelectForms}
                      disabled={disabledAllForms}
                      value={valueAllForms}
                    />
                  </Col>
                </Row>
              </AvGroup>
              <UserRolConfigDesignFormsTable
                formsTable={formsTable}
                deleteForms={deleteForms}
                loading={isLoadingPermissionsAdminFormsByRol}
              />
              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  disabled={loadingConfig}
                  className="col-mt-3 mr-3"
                  color="gray"
                  onClick={handleCloseDesignerForms}
                >
                  {componentLoadingConfigDesignForm}
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  className="col-mt-3"
                  color="cyan"
                  disabled={buttonSubmitConfig}
                >
                  {componentLoadingConfigDesignForm}
                  {i18n.t("createWorkflowDesign.saveModal")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserRolesConfigDesignForms.propTypes = {
  uuidRol: PropTypes.string.isRequired,
};

export default UserRolesConfigDesignForms;
