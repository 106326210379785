import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import i18n from "locales/i18n";

const Labels = (props) => {
  const { id, hide, isPreview, label } = props;
  let hideLabelField = null;

  /**
   * Conditionally generates a JSX element to hide or show a labeled LabelField.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled LabelField.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled LabelField with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelField = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelField = null;
  }

  /**
   * Renders a Label component conditionally based on preview and hide status.
   * @param {boolean} isPreview - Determines if the component is in preview mode.
   * @param {boolean} hide - Determines if the component should be hidden.
   * @param {string} id - The unique identifier for the Label component.
   * @param {string} label - The text to display inside the Label component.
   * @param {JSX.Element} hideLabelField - The element to display when in preview mode.
   * @returns {JSX.Element|null} The rendered Label component or null if hidden.
   */
  if (isPreview === true) {
    return (
      <div row>
        <Label key={id} sm={3}>
          <span className="m-h5">{label}</span> {hideLabelField}
        </Label>
      </div>
    );
  } else if (hide === false) {
    return (
      <div row>
        <Label key={id} sm={3}>
          <span className="m-h5">{label}</span>
        </Label>
      </div>
    );
  } else {
    return null;
  }
};

Labels.propTypes = {
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool,
};

export default Labels;
