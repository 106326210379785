import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams } from "react-router-dom";
import { Card, CardFooter } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import ItemsListsTable from "components/organism/ItemsListsTable";
import ButtonCreateItem from "components/molecules/ButtonCreateItem";
import SearchBox from "components/atoms/SearchBox";
import useList from "hooks/useList";
import ButtonBackList from "components/atoms/ButtonBackList";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ItemsLists = () => {
  const { id: itemListId } = useParams();
  const [items, setItems] = useState([]);
  const [isLoadingItemList, setIsLoadingItemList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchListItem, setSearchListItem] = useState("");
  const { getItems } = useList();

  /**
   * Fetches items based on searchListItem criteria and updates the associated state.
   * @param {string} itemListId - The identifier for the items retrieval.
   * @param {Object} pagination - The pagination configuration including 'page' and 'per_page'.
   * @param {number} pagination.page - The current page number.
   * @param {number} pagination.per_page - The number of items per page.
   * @param {string} searchListItem - The searchListItem criteria to filter items.
   * @param {function} setItems - State setter function for updating the list of items.
   * @param {function} setTotalPages - State setter function for updating the total number of pages.
   * @returns {void}
   */
  const getItemsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingItemList(true);
    getItems(itemListId, page, per_page, searchListItem)
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          setItems(response.data.items);
          setTotalPages(response.data.pages);
          if (response.data.items.length === 0) {
            const showAlertItemsEmpty = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("list.isEmpty"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            };
            showAlertItemsEmpty();
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingItemList(false);
      });
  }, [pagination, searchListItem]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that triggers the 'getItemsBySearch' function to fetch items based on searchListItem criteria.
   * @name useSearchEffect
   * @returns {void}
   */
  useEffect(() => {
    getItemsBySearch();
  }, [getItemsBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchListItem}
              pagination={pagination}
              setPagination={setPagination}
            />

            <ButtonBackList />
            <ButtonCreateItem />
          </CardFooter>

          <ItemsListsTable
            items={items}
            pagination={pagination}
            isLoadingItemList={isLoadingItemList}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ItemsLists;
