import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { enumsTaskComeFrom } from "utils/enums";
import { faHandPaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWorkFlow from "hooks/useWorkFlow";
import ModalInterruptProcess from "components/molecules/ModalInterruptProcess";
import i18n from "locales/i18n";
import swal from "sweetalert";

const InterruptProcess = (props) => {
  const {
    id,
    processId,
    processNumber,
    isCompletedTask,
    typeTask,
    idsTaskSelectedByBatch,
  } = props;
  const { taskSelectedByBatch, setDropdownOpen } = useWorkflowContext();
  const [showModalInterrupt, setModalInterrupt] = useState(false);
  const [isLoadingInterruptProcess, setIsLoadingInterruptProcess] = useState(
    false
  );
  const [interruptMotive, setInterruptMotive] = useState({
    reason: "",
    process_task_uuid: id,
  });
  const [interruptMotiveByBatch, setInterruptMotiveByBatch] = useState({
    reason: "",
    tasks: idsTaskSelectedByBatch,
  });
  const { interruptProcessByUUID, interruptProcessByBatch } = useWorkFlow();

  /**
   *Function to handle onClick event of interrupt process button and set the state of modalInterrupt to true.
   *@function handleOnClickInterruptProcess
   *@returns {void}
   */
  const handleOnClickInterruptProcess = () => {
    setModalInterrupt(true);
  };

  /**
   * Handles the closing of the interrupt process modal and updates state accordingly.
   * @param {boolean} [typeTask] - Type of task, used to determine the behavior of interrupt motive state update.
   * @param {string} [enumsTaskComeFrom] - Enumeration representing the origin of the task.
   * @param {boolean} setModalInterrupt - State setter function for controlling the interrupt modal visibility.
   * @param {object} interruptMotive - Current interrupt motive state.
   * @param {string} interruptMotive.reason - Reason for the interruption.
   * @param {string} interruptMotive.process_task_uuid - UUID of the process task.
   * @param {function} setInterruptMotive - State setter function for updating the interrupt motive.
   * @param {object} interruptMotiveByBatch - Current interrupt motive state by batch.
   * @param {string} interruptMotiveByBatch.reason - Reason for the interruption by batch.
   * @param {array} interruptMotiveByBatch.tasks - List of task IDs selected by batch.
   * @param {function} setInterruptMotiveByBatch - State setter function for updating the interrupt motive by batch.
   * @param {string} id - UUID of the process task.
   * @param {array} idsTaskSelectedByBatch - List of task IDs selected by batch.
   * @returns {void}
   */
  const handleOnCloseInterruptProcess = () => {
    setDropdownOpen(false);
    setModalInterrupt(false);
    if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
      setInterruptMotive({
        ...interruptMotive,
        reason: "",
        process_task_uuid: id,
      });
    } else {
      setInterruptMotiveByBatch({
        ...interruptMotiveByBatch,
        reason: "",
        tasks: idsTaskSelectedByBatch,
      });
    }
  };

  /**
   * Handles the change event for interrupt motive input.
   * @param {object} eventChangeMotive - The event object triggered by the change in the interrupt motive input.
   * @param {string} eventChangeMotive.target.value - The value entered or selected in the interrupt motive input.
   * @param {string} typeTask - The type of task, determining how to handle the interrupt motive state.
   * @param {string} enumsTaskComeFrom.PROCESS_TASK - Enum representing the task type "PROCESS_TASK".
   * @param {object} interruptMotive - The current state of interrupt motive when the task type is "PROCESS_TASK".
   * @param {string} interruptMotive.reason - The reason for the interruption.
   * @param {function} setInterruptMotive - State setter function for updating interrupt motive when the task type is "PROCESS_TASK".
   * @param {object} interruptMotiveByBatch - The current state of interrupt motive when the task type is not "PROCESS_TASK".
   * @param {string} interruptMotiveByBatch.reason - The reason for the interruption when the task type is not "PROCESS_TASK".
   * @param {function} setInterruptMotiveByBatch - State setter function for updating interrupt motive when the task type is not "PROCESS_TASK".
   * @returns {void}
   */
  const handleOnChangeInterruptMotive = (eventChangeMotive) => {
    if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
      setInterruptMotive({
        ...interruptMotive,
        reason: eventChangeMotive.target.value,
      });
    } else {
      setInterruptMotiveByBatch({
        ...interruptMotiveByBatch,
        reason: eventChangeMotive.target.value,
      });
    }
  };

  /**
   * Handles the blur event of a field related to interrupt motive in a task form.
   * @param {Event} eventBlurField - The blur event object triggered by the field.
   * It contains information about the event, such as the target element.
   * @param {string} typeTask - The type of task, which determines how to handle the interrupt motive.
   * @param {object} enumsTaskComeFrom - An object containing enumerated values for task sources.
   * @param {function} setInterruptMotive - State setter function for updating the interrupt motive when the task is from a process task.
   * @param {function} setInterruptMotiveByBatch - State setter function for updating the interrupt motive when the task is not from a process task (e.g., batch task).
   * @returns {void}
   */
  const handleOnBlurFieldMotive = (eventBlurField) => {
    if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
      setInterruptMotive({
        ...interruptMotive,
        reason: eventBlurField.target.value.trim(),
      });
    } else {
      setInterruptMotiveByBatch({
        ...interruptMotiveByBatch,
        reason: eventBlurField.target.value.trim(),
      });
    }
  };

  /**
   * Function to handle the onSubmit event of the interrupt process form and interrupt the process by sending a request to the server.
   * If there are no errors in the form, the function sets the isLoadingInterruptProcess state to true,
   * sends a request to the server to interrupt the process, and if the response status is 202,
   * closes the interrupt process modal and shows a success notification.
   * Finally, the isLoadingInterruptProcess state is set to false.
   * @param {array} errors - An array of errors returned by the form validation library.
   * @param {string} typeTask - The type of task associated with the interrupt process.
   * @param {string} enumsTaskComeFrom - An enum representing the source of the task.
   * @param {string} processId - The ID of the process to be interrupted.
   * @param {string} interruptMotive - The reason for interrupting the process.
   * @param {string} interruptMotiveByBatch - The reason for interrupting the process by batch.
   * @param {array} taskSelectedByBatch - An array of tasks selected for batch interruption.
   * @param {function} setIsLoadingInterruptProcess - State setter function for the loading state of the interrupt process.
   * @param {function} setModalInterrupt - State setter function for the interrupt process modal.
   * @param {function} setFormName - State setter function for updating the form name.
   * @returns {void}
   */
  const handleOnSubmitInterrupt = (_, errors) => {
    if (errors.length === 0) {
      setIsLoadingInterruptProcess(true);
      if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
        interruptProcessByUUID(processId, interruptMotive)
          .then((response) => {
            if (response.status === 202) {
              setModalInterrupt(false);
              swal({
                title: `${i18n.t(
                  "create.work.interruptSweetAlert01"
                )} ${processNumber}`,
                text: i18n.t("create.work.interruptSweetAlert02"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location = "/taskBox";
                setIsLoadingInterruptProcess(false);
              });
            }
          })
          .finally(() => {
            setIsLoadingInterruptProcess(false);
          });
      } else {
        interruptProcessByBatch(interruptMotiveByBatch)
          .then((response) => {
            if (response.status === 201) {
              setModalInterrupt(false);
              const taskreassigned = taskSelectedByBatch.map(
                (task) => task.consecutive
              );
              swal({
                title: `${i18n.t(
                  "create.work.interruptSweetAlert03"
                )} ${taskreassigned.join(", ")}`,
                text: i18n.t("create.work.interruptSweetAlert04"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then((willClose) => {
                if (willClose) {
                  window.location = "/taskBox";
                  setIsLoadingInterruptProcess(false);
                }
              });
            }
          })
          .finally(() => {
            setIsLoadingInterruptProcess(false);
          });
      }
    }
  };

  return (
    <Fragment>
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle
          className="ml-auto btn-icon btn-block btn-mb-responsive"
          color="danger"
          onClick={handleOnClickInterruptProcess}
          disabled={isCompletedTask}
        >
          <FontAwesomeIcon icon={faHandPaper} className="mr-2" />
          <span>{i18n.t("create.work.interruptBuuton")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>
      <ModalInterruptProcess
        showModalInterrupt={showModalInterrupt}
        interruptMotive={interruptMotive}
        handleOnCloseInterruptProcess={handleOnCloseInterruptProcess}
        handleOnChangeInterruptMotive={handleOnChangeInterruptMotive}
        handleOnBlurFieldMotive={handleOnBlurFieldMotive}
        isLoadingInterruptProcess={isLoadingInterruptProcess}
        handleOnSubmitInterrupt={handleOnSubmitInterrupt}
        typeTask={typeTask}
      />
    </Fragment>
  );
};

InterruptProcess.propTypes = {
  processNumber: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  processId: PropTypes.string.isRequired,
  isCompletedTask: PropTypes.bool,
  typeTask: PropTypes.number.isRequired,
  idsTaskSelectedByBatch: PropTypes.array,
};

export default InterruptProcess;
