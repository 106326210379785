import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const FilterNotificationDetail = (props) => {
  /**
   * Function to handle the change event on a select a filter for an element.
   * Sets the status value to the integer parsed from the selected value.
   * Also sets the pagination page value to 1.
   * @param {object} evenOnChangeFilter - The event object that triggered the function call.
   */
  const handleOnChangeFilterNotification = (evenOnChangeFilter) => {
    props.setStatus(parseInt(evenOnChangeFilter.target.value));
    props.setPagination({ ...props.pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <UncontrolledButtonDropdown>
          <DropdownToggle caret className="mr-2" color="cyan">
            {i18n.t("createWorflow.filterState")}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-hover-primary">
            <DropdownItem value={""} onClick={handleOnChangeFilterNotification}>
              {i18n.t("filteruser.item1")}
            </DropdownItem>
            <DropdownItem value={1} onClick={handleOnChangeFilterNotification}>
              {i18n.t("createWorflow.Label3.21")}
            </DropdownItem>
            <DropdownItem value={2} onClick={handleOnChangeFilterNotification}>
              {i18n.t("createWorflow.Label3.31")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterNotificationDetail.propTypes = {
  setStatus: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.object.isRequired,
};

export default FilterNotificationDetail;