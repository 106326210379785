import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Card,
  CardFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MetricsRecordsListsByYearMonthTable from "components/organism/MetricsRecordsListsByYearMonthTable";
import useDashboard from "hooks/useDashboard";
import i18n from "locales/i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const MetricsRecordListByYearMonth = () => {
  const [recordsByYear, setRecordsByYear] = useState(null);
  const [recordsByMonth, setRecordsByMonth] = useState({});
  const [isLoadingListByYear, setIsLoadingListByYear] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isOpen, setIsOpen] = useState(false);
  const { getRecordsByYearMonth } = useDashboard();

  /**
   *Function to retrieve records by year and update the component state accordingly.
   *@param {number} selectedYear - the selected year to retrieve records for
   *@param {function} getRecordsByYearMonth - a function that fetches records by year and month from an API
   *@param {function} setRecordsByYear - the state setter function for the records by year
   *@param {function} setRecordsByMonth - the state setter function for the records by month
   *@param {function} setIsLoadingListByYear - the state setter function for the loading state while retrieving records by year
   *@returns {void}
   */
  const getRecordsByYear = useCallback(() => {
    setIsLoadingListByYear(true);
    getRecordsByYearMonth(selectedYear)
      .then((response) => {
        setRecordsByYear(response.data.total_year);
        setRecordsByMonth(response.data.months_items);
      })
      .finally(() => {
        setIsLoadingListByYear(false);
      });
  }, [selectedYear]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *Function that toggles the value of 'isOpen' to control the visibility of a table content.
   *It is typically used as an event handler for a toggle button.
   *@param {boolean} isOpen - the current state indicating if the table content is open or closed
   *@param {function} setIsOpen - the state setter function for 'isOpen' that updates the visibility of the table content
   */
  const handleOntoggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   *Function that handles the selection of a year and updates the state accordingly.
   *@param {string} year - The selected year value.
   */
  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setIsOpen(false);
  };

  /**
   *Function that renders the dropdown items for selecting years.
   *@returns {Array} An array of JSX elements representing the dropdown items for selecting years.
   */
  const renderDropdownItems = () => {
    const years = [currentYear - 1, currentYear];

    return years.map((year) => (
      <DropdownItem key={year} onClick={() => handleYearSelect(year)}>
        {year}
      </DropdownItem>
    ));
  };

  /**
   *useEffect hook that calls the 'getRecordsByYear' function when the 'selectedYear' prop changes.
   *@param {number} selectedYear - the selected year for which the records should be retrieved
   *@param {function} getRecordsByYear - a function that retrieves the records based on the selected year
   */
  useEffect(() => {
    getRecordsByYear();
  }, [selectedYear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <Button
              className="btn-icon col-mt-3 mr-3"
              color="cyan"
              onClick={function () {
                window.location = "/metrics";
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("metrics.RecordsByformBack")}
            </Button>

            <ButtonDropdown isOpen={isOpen} toggle={handleOntoggle}>
              <DropdownToggle caret color="success">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                {i18n.t("metrics.RecordsByformYear") + " " + selectedYear ||
                  i18n.t("metrics.RecordsByformYear") + " " + currentYear}
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-hover-success dropMenu-size">
                {renderDropdownItems()}
              </DropdownMenu>
            </ButtonDropdown>
          </CardFooter>

          <MetricsRecordsListsByYearMonthTable
            recordsByYear={recordsByYear}
            recordsByMonth={recordsByMonth}
            isLoadingListByYear={isLoadingListByYear}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default MetricsRecordListByYearMonth;
