import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import FormList from "components/organism/FormList";
import i18n from "locales/i18n";

const FormsList = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("form.designerItem3")}
				subheading={i18n.t("form.designerListMotto")}
				icon="note2"
			>
				<FormList />
			</AdminTemplate>
		</Fragment>
	);
};

export default FormsList;