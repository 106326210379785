import React, { useState } from "react";
import { ACCESS_TOKEN_NAME, USER_DETAIL } from "constants/securityConst";

const UserContext = React.createContext(undefined);

export function UserContextProvider({ children }) {
  const [jwt, setJwt] = useState(() =>
    window.localStorage.getItem(ACCESS_TOKEN_NAME)
  );

  const [state, setState] = useState({
    loading: false,
    error: false,
    countError: 0,
  });

  const [canNext, setCanNext] = useState(false);

  const [user, setUser] = useState(() => {
    const userStored = window.localStorage.getItem(USER_DETAIL);
    if (userStored) {
      return JSON.parse(userStored);
    }
  });

  return (
    <UserContext.Provider
      value={{
        state,
        setState,
        jwt,
        setJwt,
        user,
        setUser,
        canNext,
        setCanNext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
export default UserContext;
