import React, { useState, useEffect } from 'react'
import i18n from "locales/i18n";

const ShowConfigurationPassWord = ({ changePassword }) => {

  const regexUppercase = /[A-Z]/;
  const regexLowercase = /[a-z]/;
  const regexNumber = /[0-9]/;
  const minCharacters = 8;
  // eslint-disable-next-line
  const symbolRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;

  const [showValidations, setShowValidations] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasMoreThanEightCharacters, setHasMoreThanEightCharacters] = useState(false);
  const [hashSymbol, setHashSymbol] = useState(false);

  useEffect(() => {
    if (changePassword.new_password !== "") {
      setShowValidations(true)
    } else {
      setShowValidations(false)
    }

    if (regexUppercase.test(changePassword.new_password) === true) {
      setHasUppercase(true)
    } else {
      setHasUppercase(false)
    }

    if (regexLowercase.test(changePassword.new_password) === true) {
      setHasLowercase(true)
    } else {
      setHasLowercase(false)
    }

    if (regexNumber.test(changePassword.new_password) === true) {
      setHasNumber(true)
    } else {
      setHasNumber(false)
    }

    if (changePassword.new_password.length >= minCharacters) {
      setHasMoreThanEightCharacters(true);
    } else {
      setHasMoreThanEightCharacters(false);
    }

    if (symbolRegex.test(changePassword.new_password) === true) {
      setHashSymbol(true)
    } else {
      setHashSymbol(false)
    }
    // eslint-disable-next-line
  }, [changePassword.new_password])

  return (
    <div className="password-validation">
      {(() => {
        if (showValidations === true) {
          return (
            <div>
              <li>
                {(() => {
                  if (hasUppercase === true) {
                    return (
                      <span role="img" aria-label="correct">
                        ✅ {i18n.t("login.validation1")}
                      </span>
                    );
                  } else {
                    return (
                      <span role="img" aria-label="incorrect">
                        ❌ {i18n.t("login.validation1")}
                      </span>
                    )
                  }
                })()}
              </li>

              <li>
                {(() => {
                  if (hasLowercase === true) {
                    return (
                      <span role="img" aria-label="correct">
                        ✅ {i18n.t("login.validation2")}
                      </span>
                    );
                  } else {
                    return (
                      <span role="img" aria-label="incorrect">
                        ❌ {i18n.t("login.validation2")}
                      </span>
                    )
                  }
                })()}
              </li>

              <li>
                {(() => {
                  if (hasNumber === true) {
                    return (
                      <span role="img" aria-label="correct">
                        ✅ {i18n.t("login.validation3")}
                      </span>
                    );
                  } else {
                    return (
                      <span role="img" aria-label="incorrect">
                        ❌ {i18n.t("login.validation3")}
                      </span>
                    )
                  }
                })()}
              </li>

              <li>
                {(() => {
                  if (hasMoreThanEightCharacters === true) {
                    return (
                      <span role="img" aria-label="correct">
                        ✅ {i18n.t("login.validation4")}
                      </span>
                    );
                  } else {
                    return (
                      <span role="img" aria-label="incorrect">
                        ❌  {i18n.t("login.validation4")}
                      </span>
                    )
                  }
                })()}
              </li>

              <li>
                {(() => {
                  if (hashSymbol === true) {
                    return (
                      <span role="img" aria-label="correct">
                        ✅  {i18n.t("login.validation5")}
                      </span>
                    );
                  } else {
                    return (
                      <span role="img" aria-label="incorrect">
                        ❌  {i18n.t("login.validation5")}
                      </span>
                    )
                  }
                })()}
              </li>
            </div>
          );
        }
      })()}
    </div>
  )
}

export default ShowConfigurationPassWord