import React from "react";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";
import { FilterContextProvider } from "contextAPI/FilterContext";
import TaskBox from "./TaskBox";

export default () => {
  return (
    <WorkflowContextProvider>
      <FilterContextProvider>
        <TaskBox />
      </FilterContextProvider>
    </WorkflowContextProvider>
  );
};
