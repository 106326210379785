import axios from "axios";
import { URL_LOGIN } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function loginService(username, password) {

  return axios
    .post(URL_LOGIN, { user_name: username.toLowerCase(), password: password },
      {
        endpointError: endpointError.login.loginService
      },)
    .then((response) => {
      if (response) return response.data;
    });
}
