import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import TrdExpedient from "components/organism/TrdExpedient";
import { useParams } from "react-router-dom";

const TrdExpedients = () => {
    const { id } = useParams();
    return (
        <Fragment>
            <AdminTemplate
                heading={id ? i18n.t("trdHeading") : i18n.t("trdHeading")}
                subheading={id ? i18n.t("trdSubHeading") : i18n.t("trdSubHeading")}
                icon="copy-file"
            >
                <TrdExpedient />
            </AdminTemplate>
        </Fragment>
    );
};

export default TrdExpedients;