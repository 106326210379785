import React, { useState } from "react";
import {
  Button,
  Col,
  Spinner
} from "reactstrap";
import {
  faFileExport
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showAlertExportRecordsSuccess, showAlertServiceError } from "utils/alerts";
import { getFormattedTimezoneOffset } from "utils/getDateFormat";
import { isNullOrUndefined } from "utils/validations";
import ReactTooltip from "react-tooltip";
import useForm from "hooks/useForm";
import i18n from "locales/i18n";

const ButtonExportRecords = (props) => {
  const { exportRecords } = useForm();
  const {
    records,
    recordId,
    filters,
  } = props;
  const [isLoadingExportRecord, setIsLoadingExportRecords] = useState(false);
  const timeZone = getFormattedTimezoneOffset();
  let disabledButtonExport = true;
  let isLoadingExportRecords = null;
  let showTooltip = null;
  let dataExportedRecords = {};

  /**
   * Sets the exported records data if the filters object is neither null nor undefined.
   * @param {Object|null|undefined} filters - The filters object to be applied to the exported records.
   * @returns {void}
   */
  if (isNullOrUndefined(filters) === false) {
    dataExportedRecords = filters;
  };

  if (records.length === 0) {
    showTooltip = (
      <ReactTooltip
        id="btn-buttonProcessBatch"
        place="top"
        type="info"
        effect="solid"
        className="custom-tooltip"
      >
        <span className="text-lowercase">{i18n.t("export.records.label2")}</span>
      </ReactTooltip>
    );
  }

  /**
   * Conditionally renders a loading spinner when the export record process is in progress.
   * @param {boolean} isLoadingExportRecord - Flag indicating whether the export records process is currently loading.
   * @returns {JSX.Element|null} - Returns a spinner element if loading, otherwise returns null.
   */
  if (isLoadingExportRecord === true) {
    isLoadingExportRecords = <Spinner size="sm" color="secondary" type="grow" />;
  };

  /**
   * Determines if the export button should be disabled based on the presence of records.
   * @param {Array} records - The array of records to evaluate.
   * @returns {boolean} - Returns `true` if the export button should be disabled, otherwise `false`.
   */
  if (records.length === 0) {
    disabledButtonExport = true;
  } else {
    disabledButtonExport = false;
  };

  /**
   * Handles the click event for exporting records. 
   * This function triggers the export process, manages the loading state, 
   * and handles success and error responses.
   * @returns {void}
   */
  const onClickExportRecords = () => {
    setIsLoadingExportRecords(true);
    exportRecords(recordId, dataExportedRecords, timeZone)
      .then((response) => {
        if (response.status === 200) {
          showAlertExportRecordsSuccess();
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingExportRecords(false);
      })
  };

  return (
    <Col lg="3" md="6" sm="12" className="align-self-end">
      <Button
        data-test-id="btn-buttonProcessBatch"
        className="btn-block ml-auto btn-icon btn-mb-responsive"
        color="cyan"
        data-tip
        data-for="btn-buttonProcessBatch"
        disabled={disabledButtonExport || isLoadingExportRecords}
        onClick={() => onClickExportRecords()}
      >
        {showTooltip}
        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
        <span>{i18n.t("export.records.label1")}</span>
        {isLoadingExportRecords}
      </Button>
    </Col>
  )
};

export default ButtonExportRecords;