import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { getDateFormat2 } from "utils/getDateFormat2";
import { capitalizeText } from "utils/formatText";
import { loaderElement } from "utils/loaderElement";
import { enumsMaxWidthSelectFile } from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const TrashFilesListTable = (props) => {
  const {
    listDeletedFiles,
    isLoadingTrashFiles,
    filesSelectedToRestore,
    setFilesSelectedToRestore,
    paginationTrash,
    setPaginationTrash,
    totalPages,
  } = props;

  /**
   * Handles the selection and deselection of files.
   * This function updates the state of selected files based on whether a file is checked or unchecked.
   * It either adds or removes the file's UUID from the list of files selected for restoration.
   * @param {Object} file - The file object being handled.
   * @param {Object} eventSelectFile - The event object from the selection input.
   * @param {boolean} eventSelectFile.target.checked - Indicates whether the file is checked or unchecked.
   * @param {string} file.file_uuid - The UUID of the file being selected or deselected.
   */
  const handleSelectedFiles = (file, eventSelectFile) => {
    const checkedField = eventSelectFile.target.checked;
    const { file_uuid: selectedFileId } = file;
    if (checkedField === true) {
      setFilesSelectedToRestore((prevSelectedFile) => [...prevSelectedFile, selectedFileId]);
    } else {
      setFilesSelectedToRestore((prevSelectedFile) =>
        prevSelectedFile.filter((selectedValue) => selectedValue !== selectedFileId)
      );
    }
  };

  return (
    <Fragment>
      {loaderElement(isLoadingTrashFiles)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={listDeletedFiles}
          columns={[
            {
              columns: [
                {
                  Header: i18n.t("trd.selectButton"),
                  width: enumsMaxWidthSelectFile.MAX_WIDTH,
                  accessor: "value",
                  Cell: ({ original }) => {
                    const isChecked = filesSelectedToRestore.includes(original.file_uuid);
                    return (
                      <div className="text-center">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={(eventSelectFields) =>
                            handleSelectedFiles(original, eventSelectFields)
                          }
                          disabled={filesSelectedToRestore.length >= 5 && !isChecked}
                        />
                      </div>
                    );
                  },
                },
                {
                  Header: i18n.t("trd.fromLabel2"),
                  accessor: "subject",
                  Cell: (value) => {
                    let nameFile = value.original.subject;
                    let target = value.original.presigned_url;
                    return (
                      <a href={target} target="_blank" rel="noopener noreferrer">
                        {capitalizeText(nameFile)}
                      </a>
                    );
                  },
                },
                {
                  Header: i18n.t("trash.label12"),
                  accessor: "user_name",
                },
                {
                  Header: i18n.t("trash.label13"),
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <span>{valueFinal}</span>;
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPages}
          page={paginationTrash.page - 1}
          showPageJump={false}
          defaultPageSize={paginationTrash.per_page}
          loading={isLoadingTrashFiles}
          loadingText={i18n.t("loading")}
          noDataText={`${i18n.t("trash.label10")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPaginationTrash({ ...paginationTrash, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPaginationTrash({ ...paginationTrash, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrashFilesListTable.propTypes = {
  listDeletedFiles: PropTypes.array.isRequired,
  isLoadingTrashFiles: PropTypes.bool.isRequired,
  filesSelectedToRestore: PropTypes.array.isRequired,
  setFilesSelectedToRestore: PropTypes.func.isRequired,
  paginationTrash: PropTypes.object.isRequired,
  setPaginationTrash: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default TrashFilesListTable;
