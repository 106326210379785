import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { TYPE, EDIT_REGISTER } from "constants/securityConst";
import { enumsTypeUser } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import AdminTemplate from "components/templates/AdminTemplate";
import RecordEditData from "components/organism/RecordEditData";
import AccessDenied from "components/molecules/Messages/Error/AccessDenied";
import useLogin from "hooks/useLogin";
import i18n from "locales/i18n";


const EditRecord = () => {
  const { isLogged } = useLogin();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const EDIT_RECORDS = window.localStorage.getItem(EDIT_REGISTER);
  const { id: formId } = useParams();
  let accessEditRecord;

  /**
   * Determines if the user has access to edit a record based on their login status, user type, and record ID.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} USERTYPE - The type of user (standard or admin).
   * @param {string} EDIT_RECORDS - The list of records the user can edit.
   * @param {string} id - The ID of the record to be edited.
   * @returns {boolean} - True if the user has access to edit the record, false otherwise.
   */
  if (
    (isLogged === true &&
      isNullOrUndefined(EDIT_RECORDS) === false &&
      EDIT_RECORDS.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessEditRecord = true;
  } else {
    accessEditRecord = false;
  }

  if (accessEditRecord === true) {
    return (
      <Fragment>
        <AdminTemplate
          heading={i18n.t("editRecord.header")}
          subheading={i18n.t("editRecord.subHeader")}
          icon="pen"
        >
          <RecordEditData />
        </AdminTemplate>
      </Fragment>
    );
  } else {
    return <AccessDenied />;
  }
};

export default EditRecord;
