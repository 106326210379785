import React, { Fragment, useEffect, useRef, useState } from "react";
import PaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider";
import BpmnModeler from "bpmn-js/lib/Modeler";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams } from "react-router-dom";
import { Loader } from "react-loaders";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
import {
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS
} from "constants/securityConst";
import { getDateFormat2 } from "utils/getDateFormat2";
import { Button, Card, CardBody, Col, Alert, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faTrash,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import { enumsBpmnView, enumsFilterWorkflowState } from "utils/enums";
import useWorkFlow from "hooks/useWorkFlow"
import useWorkflowsDiagram from "hooks/useWorkflowsDiagram";
import i18n from "locales/i18n";
import swal from "sweetalert";

const BpmnView = () => {
  let { model } = useParams()
  const { viewModel } = useWorkFlow();
  const { getWorkFlowById } = useWorkFlow();
  const { delete_workflow_diagram, getProcessView } = useWorkflowsDiagram();
  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(WORKFLOW_FORMS_PERMISSIONS);
  const newForms = WORKFLOW_FORMS_PERMISSION.split(",");
  const [hasModel, setHasModel] = useState(false);
  const [isBpmnLoaded, setIsBpmnLoaded] = useState(false);
  const [bpmnXmlCode, setString] = useState(null);
  const [loadingWorkflowInfo, setLoadingWorkflowInfo] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [modelo, setModelo] = useState(false);
  const [workflowInfo, setInformation] = useState(null);
  const modelerRef = useRef(null);
  const bpmContainerRef = useRef();

  var _getPaletteEntries = PaletteProvider.prototype.getPaletteEntries;
  let loaderSpinnerWorkflows = null;
  let alerWorkflowStatus = null;
  let renderStatusWorkflow = null;
  let hashModelButton = null;
  let disabledDeleteDiagram = null;
  let workflowName = null;
  let workflowUserName = null;
  let workflowDescription = null;

  /**
   * Asigna los valores de `workflowInfo` a las variables correspondientes si `workflowInfo` no es null ni undefined. 
   * @param {Object|null|undefined} workflowInfo - El objeto que contiene la información del flujo de trabajo, puede ser null o undefined.
   * @param {string} workflowInfo.name - El nombre del flujo de trabajo.
   * @param {string} workflowInfo.user_name - El nombre de usuario asociado al flujo de trabajo.
   * @param {string} workflowInfo.description - La descripción del flujo de trabajo.
   * @returns {void}
   */
  if (isNullOrUndefined(workflowInfo) === false) {
    workflowName = workflowInfo.name;
    workflowUserName = workflowInfo.user_name;
    workflowDescription = workflowInfo.description;
  };

  /**
   * Determines whether the delete diagram button should be disabled.
   * @param {Object} workflowInfo - The workflowInfo object containing the workflow status.
   * @param {boolean} hasModel - Indicates if the model is available.
   * @param {boolean} loadingDelete - Indicates if the delete operation is in progress.
   * @param {boolean} loadingWorkflowInfo - Indicates if data is being loaded.
   * @returns {boolean} True if the delete button should be disabled, false otherwise.
   */
  if (isNullOrUndefined(workflowInfo) === false && workflowInfo.status === enumsBpmnView.STATUS_ERASER &&
    hasModel && loadingDelete === false && loadingWorkflowInfo === false
  ) {
    disabledDeleteDiagram = false;
  } else {
    disabledDeleteDiagram = true;
  };

  /**
   * Renders a button for creating a model if there is no model and not loadingWorkflowInfo.
   * @param {boolean} hasModel - Indicates if the model is available.
   * @param {boolean} loadingWorkflowInfo - Indicates if the data is being loaded.
   * @param {string} model - The model identifier.
   * @returns {JSX.Element|null} The button element or null if conditions are not met.
   */
  if (hasModel === false && loadingWorkflowInfo === false) {
    hashModelButton = (
      <a href={`/workflow/designer/${model}`}>
        <Button
          className="mb-2 mr-2 btn-icon"
          color="success"
          disabled={hasModel}
        >
          + {i18n.t("createWorkflow.create.model")}
        </Button>
      </a>
    )
  };

  /**
   * Determines the status message based on the workflow status.
   * @param {Object} workflowInfo - The workflowInfo object containing the workflow status.
   * @param {string} status - The status to be checked.
   * @returns {string} The localized status message.
   */
  if (isNullOrUndefined(workflowInfo) === false) {
    if (workflowInfo.status === enumsBpmnView.STATUS_ERASER) {
      renderStatusWorkflow = i18n.t("createWorflow.selectedOne");
    }
    if (workflowInfo.status === enumsBpmnView.STATUS_ACTIVE) {
      renderStatusWorkflow = i18n.t("filteruser.item2");
    }
    if (workflowInfo.status === enumsBpmnView.STATUS_INACTIVE) {
      renderStatusWorkflow = i18n.t("filteruser.item3");
    }
  };

  /**
   * Renders an alert based on the workflow status.
   * @param {Object} workflowInfo - The workflowInfo object containing workflow status.
   * @param {string} statusToExclude - The status that should exclude rendering the alert.
   * @returns {JSX.Element|null} The alert component or null if conditions are not met.
   */
  if (isNullOrUndefined(workflowInfo) === false && workflowInfo.status !== enumsFilterWorkflowState.STATUS_ERASER) {
    alerWorkflowStatus = (
      <Alert
        className="text-primary"
        color="info"
      >
        <span className="pr-2">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        {i18n.t("createWorkflow.delete.statusAlert")}
        <br />
      </Alert>
    )
  };

  /**
   * Renders a loader spinner if `loadingWorkflowInfo` is true and `hasModel` is true.
   * @param {boolean} loadingWorkflowInfo - Indicates if the data is being loaded.
   * @param {boolean} hasModel - Indicates if the model is available.
   * @returns {JSX.Element|null} The loader spinner element or null if conditions are not met.
   */
  if (loadingWorkflowInfo === true && hasModel === true) {
    loaderSpinnerWorkflows = (
      <div className="text-center mx-auto ">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  /**
   * Retrieves the palette entries after removing specific unwanted tools.
   * This method overrides the default palette entries to exclude certain tools
   * such as 'create.task', 'hand-tool', 'global-connect-tool', and others.
   * @returns {Object} The modified palette entries with specified tools removed.
   */
  PaletteProvider.prototype.getPaletteEntries = function () {
    var entries = _getPaletteEntries.apply(this);
    delete entries["create.task"];
    delete entries["hand-tool"];
    delete entries["global-connect-tool"];
    delete entries["create.start-event"];
    delete entries["tool-separator"];
    delete entries["create.group"];
    delete entries["create.data-store"];
    delete entries["lasso-tool"];
    delete entries["space-tool"];
    delete entries["create.exclusive-gateway"];
    delete entries["create.intermediate-event"];
    delete entries["create.end-event"];
    delete entries["create.terminate-end-event"];
    delete entries["create.participant-expanded"];
    delete entries["create.subprocess-expanded"];
    delete entries["create.data-object"];
    return entries;
  };

  /**
   * Deletes a workflow diagram and handles the response.
   * This function sets a loadingWorkflowInfo state, attempts to delete the workflow diagram,
   * and displays appropriate success or error messages based on the response.
   * Finally, it resets the loadingWorkflowInfo state.
   * @function deleteDiagram
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const deleteDiagram = async () => {
    setLoadingDelete(true)
    delete_workflow_diagram(model)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createWorkflow.delete.success"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location.reload()
          })
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setLoadingDelete(false)
      )
  };

  /**
   * Retrieves the view model and updates the state accordingly.
   * This function calls the `viewModel` function with the given model, processes the response,
   * and updates the state based on whether the response data is valid and non-empty.
   * If the response data is invalid, it shows an error alert.
   * @function getViewModel
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const getViewModel = async () => {
    viewModel(model)
      .then((res) => {
        if (isNullOrUndefined(res.data) === false) {
          if (res.data.length <= 0) {
            setHasModel(false);
          }
          setModelo(res.data);
          setHasModel(true);
        } else {
          showAlertServiceError();
        }
      })
      .catch(() => {
        setLoadingWorkflowInfo(false)
      })
  };

  /**
   * Fetches workflow workflowInfo based on the model and updates state.
   * This asynchronous function calls `getWorkFlowById` with the `model` and updates the
   * state with the workflow data if available. If the data is not available, it triggers
   * an error alert.
   * @function getInformationWorkflow
   * @returns {Promise<void>} A promise that resolves when the workflow workflowInfo is fetched and state is updated.
   */
  useEffect(() => {
    if (isNullOrUndefined(modelo.presigned_url) === false) {
      const getProccessByUrl = () => {
        getProcessView(modelo.presigned_url)
          .then((response) => {
            if (isNullOrUndefined(response.data) === false) {
              return response.data
            } else {
              showAlertServiceError();
            }
          })
          .then((data) => {
            if (isNullOrUndefined(data) === false) {
              setString(data);
              setIsBpmnLoaded(true);
            } else {
              showAlertServiceError();
            }
          })
      };
      const getInformationWorkflow = async () => {
        getWorkFlowById(model)
          .then((response) => {
            if (isNullOrUndefined(response.data) === false) {
              setInformation(response.data.data)
            } else {
              showAlertServiceError();
            }
          })
      }
      getProccessByUrl()
      getInformationWorkflow()
    } else {
      setLoadingWorkflowInfo(true)
    }
  }, [modelo.presigned_url]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook that initializes the BPMN modeler based on the `isBpmnLoaded` value.
   * If `isBpmnLoaded` is `false`, it sets the loadingWorkflowInfo state to `true`.
   * If `isBpmnLoaded` is `true`, it sets the loadingWorkflowInfo state to `false`, initializes the BPMN modeler,
   * imports the BPMN XML string, and fits the model to the viewport.
   * @param {boolean} isBpmnLoaded - A isBpmnLoaded indicating whether to initialize the BPMN modeler.
   * @param {string} bpmnXmlCode - The BPMN XML string to import.
   * @param {React.MutableRefObject} modelerRef - A reference to the BPMN modeler instance.
   * @param {React.MutableRefObject} bpmContainerRef - A reference to the BPMN container DOM element.
   */
  useEffect(() => {
    if (isBpmnLoaded === false) {
      setLoadingWorkflowInfo(true);
    } else {
      setLoadingWorkflowInfo(false);
      const modeler = (modelerRef.current = new BpmnModeler({
        container: bpmContainerRef.current,
        keyboard: {
          bindTo: window
        },
        additionalModules: [],
        propertiesPanel: {
          parent: "#propview"
        }
      }));

      modeler
        .importXML(bpmnXmlCode)
        .then(() => {
          const canvas = modeler.get("canvas");
          canvas.zoom("fit-viewport");
        })
    }
  }, [isBpmnLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook that fetches workflow workflowInfo on component mount.
   * This effect runs once when the component mounts (due to the empty dependency array).
   * It defines and calls an asynchronous function `getInformationWorkflows` that fetches
   * workflow data by calling `getWorkFlowById` with `model`, and then updates the state
   * with the fetched data.
   * @returns {void}
   */
  useEffect(() => {
    const getInformationWorkflows = async () => {
      getWorkFlowById(model)
        .then((res) => {
          if (isNullOrUndefined(res.data) === false) {
            setInformation(res.data.data);
          } else {
            showAlertServiceError();
          }
        })
    }
    getInformationWorkflows();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Effect hook that triggers the `getViewModel` function on component mount.
   * This effect runs once when the component mounts (due to the empty dependency array).
   * It is used to initialize or fetch data for the component.
   * @function
   * @returns {void}
   */
  useEffect(() => {
    getViewModel();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook that checks the model and redirects if certain conditions are met.
   * This effect runs whenever the `model` dependency changes. If `model` is truthy,
   * and `WORKFLOW_ALL_FORM` is `"false"` and `model` is not included in `newForms`,
   * it navigates back to the previous page.
   * @param {any} model - The current model being checked.
   * @param {string} WORKFLOW_ALL_FORM - A isBpmnLoaded indicating whether all forms are allowed.
   * @param {Array<any>} newForms - A list of new forms to check against the model.
   */
  useEffect(() => {
    if (isNullOrUndefined(model) === false) {
      if (WORKFLOW_ALL_FORM === "false" && !newForms.includes(model)) {
        window.history.back();
      }
    }
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Col md="12">
          <Card className="main-card mb-1">
            <CardBody>
              <Alert color="cyan">
                <Row className="d-block text-right">
                  <Button
                    onClick={deleteDiagram}
                    className="mb-2 mr-2 btn-icon"
                    color="yellow"
                    disabled={disabledDeleteDiagram}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mr-2"
                    />
                    {i18n.t("buttonActions.option3")}
                  </Button>
                  {hashModelButton}
                  <a href={`/workflow/list`}>
                    <Button className="mb-2 mr-2 btn-icon" color="cyan">
                      <FontAwesomeIcon
                        icon={faArrowAltCircleLeft}
                        className="mr-2"
                      />
                      {i18n.t("createWorkflowView.button1")}
                    </Button>
                  </a>
                </Row>
                <h5 className="alert-heading"><strong>{workflowName}</strong></h5>
                <hr />

                <Fragment>
                  <p className="mb-0">
                    {i18n.t("createWorkflowView.label2")}:  <strong>{workflowUserName}</strong>
                  </p>
                  <p className="mb-0">
                    {i18n.t("createWorkflowView.label3")}:{" "}
                    <strong>
                      {renderStatusWorkflow}
                    </strong>
                  </p>
                  <p className="mb-0">
                    {i18n.t("createWorkflowView.label4")}:{" "}
                    <strong>
                      {getDateFormat2(new Date(modelo && modelo.created_at))}
                    </strong>
                  </p>
                  <p className="mb-0">
                    {i18n.t("createWorkflowView.label5")}:{" "}
                    <strong>
                      {workflowDescription}
                    </strong>
                  </p>
                </Fragment>
              </Alert>
              {alerWorkflowStatus}
            </CardBody>
          </Card>
        </Col>
        {loaderSpinnerWorkflows}
        <section className="background bpmn-background">
          <div
            className="bpmncontainer"
            id="bpmncontainer"
            ref={bpmContainerRef}
          >
          </div>
        </section>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default BpmnView