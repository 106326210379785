import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import AdminTemplate from "components/templates/AdminTemplate";
import CreateUserForm from "components/organism/CreateUserForm";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";

const CreateUser = () => {
  const { id: userId } = useParams();
  let headingLabel = null;
  let subHeadingLabel = null;

  /**
   * Sets heading and subheading labels based on the presence of a userId.
   * @param {string|null|undefined} userId - The user ID.
   * @returns {{headingLabel: string, subHeadingLabel: string}} Object containing heading and subheading labels.
   */
  if (isNullOrUndefined(userId) === false) {
    headingLabel = i18n.t("editUserHeading");
    subHeadingLabel = i18n.t("editUserSubHeading");
  } else {
    headingLabel = i18n.t("createUserHeading");
    subHeadingLabel = i18n.t("createUserSubHeading");
  }

  return (
    <Fragment>
      <AdminTemplate
        heading={headingLabel}
        subheading={subHeadingLabel}
        icon="users"
      >
        <CreateUserForm />
      </AdminTemplate>
    </Fragment>
  );
};

export default CreateUser;
