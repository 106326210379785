import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import EditItemsListForm from "components/organism/EditItemsListForm";
import i18n from "locales/i18n";

const EditItemList = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("itemList.edit")}
        subheading={i18n.t("itemList.edit2")}
        icon="menu"
      >
        <EditItemsListForm />
      </AdminTemplate>
    </Fragment>
  );
};

export default EditItemList;