import React, { useState, useCallback, useEffect } from "react";
import useRoles from "hooks/useRoles";
import useForms from "hooks/useForm";
import useWorkFlow from "hooks/useWorkFlow";
import { enumsParameterRolesConfig } from "utils/enums";

const RolesContext = React.createContext(undefined);

export function RolesContextProvider({ children }) {
  const { getForms } = useForms();
  const { getWorkFlows } = useWorkFlow();
  const {
    getAllUserAndRoles,
    getPermissionsFormsByRol,
    getPermissionsConfigByRol,
    getPermissionsAdminFormsByRol,
    getPermissionsAdminWorkflowsByRol,
  } = useRoles();
  const [rolUuid, setRolUuid] = useState("");
  const [listForms, setListForms] = useState([]);
  const [listWorkflows, setListWorkflows] = useState([]);
  const [permissionsForm, setPermissionsForm] = useState({
    config_trd: false,
    design_list: false,
    admin_users: false,
    roles: false,
    workGroup: false,
  });
  const [permissionsConf, setPermissionsConf] = useState({
    permissions: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNameRol, setIsLoadingNameRol] = useState(false);
  const [isLoadingAllForms, setIsLoadingAllForms] = useState(false);
  const [isLoadingAllWorkflows, setIsLoadingAllWorkflows] = useState(false);
  const [isLoadingPermissionsByForm, setIsLoadingPermissionsByForm] = useState(false);
  const [isLoadingPermmissionsByConfig, setIsLoadingPermmissionsByConfig] = useState(false);
  const [isLoadingPermissionsAdminFormsByRol, setIsLoadingPermissionsAdminFormsByRol] = useState(
    false
  );
  const [
    isLoadingPermissionsAdminWorkflowsByRol,
    setIsLoadingPermissionsAdminWorkflowsByRol,
  ] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [detailPermissions, setDetailPermissions] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [formDetail, setFormDetail] = useState({
    form_uuid: "",
    permissions: [],
  });
  const [permissions, setPermissions] = useState([]);
  const [formsDinamic, setFormsDinamic] = useState([]);
  const [workflowsDinamic, setWorkflowsDinamic] = useState([]);
  const [pagination] = useState({ page: 1, per_page: 100 });
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [disabledCheckWorkflows, setDisabledCheckWorkflows] = useState(false);
  const [disabledCheckForms, setDisabledCheckForms] = useState(false);
  const [permissionsDesignerForms, setPermissionsDesignerForms] = useState({
    forms_uuid: [],
    all_forms: false,
  });
  const [formsSelected, setFormsSelected] = useState({
    form: "",
  });
  const [forms, setForms] = useState([]);
  const [formsTable, setFormsTable] = useState([]);

  const [permissionsDesignerWorkflows, setPermissionsDesignerWorkflows] = useState({
    workflows_uuid: [],
    all_workflows: false,
  });
  const [workflowsSelected, setWorkflowsSelected] = useState({
    workflow: "",
  });
  const [workflows, setWorkflows] = useState([]);
  const [workflowsTable, setWorkflowsTable] = useState([]);
  const [permissionsTemp, setPermissionsTemp] = useState([]);
  const [rolPermissions, setRolePermissions] = useState([]);
  const [listAdminForms, setListAdminForms] = useState([]);
  const [listAdminWorkflows, setListAdminWorkflows] = useState([]);
  const [allAdminForms, setAllAdminForms] = useState(false);
  const [allAdminWorkflows, setAllAdminWorkflows] = useState(false);
  const [listAdminForm, setListAdminForm] = useState([]);
  const [listAdminWorkflow, setListAdminWorkflow] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [componentToShow, setComponentToShow] = useState("0");
  const [acordeon, setAcordeon] = useState([false, false, false, false]);
  const [chargeForm, setChargeForm] = useState(false);

  const getRolesBySearch = useCallback(
    (id) => {
      const { page, per_page } = pagination;
      setIsLoadingNameRol(true);
      getAllUserAndRoles(page, per_page, "")
        .then((response) => {
          let nameRolTemp = response.data.items;
          // eslint-disable-next-line
          nameRolTemp.map((e) => {
            if (e.uuid === id) {
              setRolUuid(e.uuid);
            }
          });
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoadingNameRol(false);
        });
    },
    [pagination] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getActiveForms = useCallback(() => {
    try {
      const { page, per_page } = pagination;
      setIsLoadingAllForms(true);
      getForms(page, per_page, "", "", 0).then((response) => {
        const forms = response.data.items;
        setListForms(forms);

        const sortedForms = forms.sort((positionNameA, positionNameB) => {
          const nombresA = `${positionNameA.name} `.toLocaleLowerCase();
          const nombresB = `${positionNameB.name} `.toLocaleLowerCase();
          return nombresA.localeCompare(nombresB);
        });

        setFormsDinamic(
          sortedForms.map((item) => ({
            value: item.uuid,
            label: item.name,
          }))
        );
      });
    } finally {
      setIsLoadingAllForms(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllWorkFlows = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingAllWorkflows(true);
    getWorkFlows(page, per_page)
      .then((response) => {
        setListWorkflows(response.data.items);
        let listWorkflows = response.data.items;
        listWorkflows
          .sort((a, b) => {
            const nombresA = `${a.name} `.toLocaleLowerCase();
            const nombresB = `${b.name} `.toLocaleLowerCase();
            if (nombresA > nombresB) {
              return 1;
            } else if (nombresB > nombresA) {
              return -1;
            } else {
              return 0;
            }
          })
          // eslint-disable-next-line
          .map((item) => {
            if (item) {
              setWorkflowsDinamic((prevState) => [
                ...prevState,
                {
                  value: item.uuid,
                  label: item.name,
                },
              ]);
            }
          });
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingAllWorkflows(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPermissionsByForm = useCallback(async (id) => {
    try {
      const { page, per_page } = pagination;
      setIsLoadingPermissionsByForm(true);
      const response = await getPermissionsFormsByRol(id, page, per_page);
      setPermissionsTemp(response.data.data.items);
    } finally {
      setIsLoadingPermissionsByForm(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPermissionsByConfig = useCallback(
    (id) => {
      setIsLoadingPermmissionsByConfig(true);
      getPermissionsConfigByRol(id)
        .then((response) => {
          let listConfig = response.data.data;
          setRolePermissions(listConfig);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoadingPermmissionsByConfig(false);
        });
    },
    [isDeleting] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getPermissionsByAdminForms = useCallback((id) => {
    setIsLoadingPermissionsAdminFormsByRol(true);
    getPermissionsAdminFormsByRol(id)
      .then((response) => {
        if (response.data.data.forms_uuid) {
          let listAdminForms = response.data.data.forms_uuid;
          let all = response.data.data.all_forms;
          setListAdminForms(listAdminForms);
          setAllAdminForms(all);
          setPermissionsDesignerForms({
            ...permissionsDesignerForms,
            forms_uuid: listAdminForms,
            all_forms: all,
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingPermissionsAdminFormsByRol(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPermissionsByAdminWorkflows = useCallback((id) => {
    setIsLoadingPermissionsAdminWorkflowsByRol(true);
    getPermissionsAdminWorkflowsByRol(id)
      .then((response) => {
        if (response.data.data.workflows_uuid) {
          let listAdminWorkflows = response.data.data.workflows_uuid;
          let all = response.data.data.all_workflows;
          setListAdminWorkflows(listAdminWorkflows);
          setAllAdminWorkflows(all);
          setPermissionsDesignerWorkflows({
            ...permissionsDesignerWorkflows,
            workflows_uuid: listAdminWorkflows,
            all_workflows: all,
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingPermissionsAdminWorkflowsByRol(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function deleteForms(value) {
    const newForms = forms.filter((e) => e !== value);
    setForms(newForms);
    const newFormsTable = formsTable.filter((e) => e.value !== value);
    setFormsTable(newFormsTable);
    if (newForms.length === 0) {
      setDisabledCheckForms(false);
    }
  }

  function deleteWorkflows(value) {
    const newWorkflows = workflows.filter((e) => e !== value);
    setWorkflows(newWorkflows);
    const newWorkflowsTable = workflowsTable.filter((e) => e.value !== value);
    setWorkflowsTable(newWorkflowsTable);
    if (newWorkflows.length === 0) {
      setDisabledCheckWorkflows(false);
    }
  }

  //function to reset workflows
  const handleResetWorkflows = () => {
    setPermissionsDesignerWorkflows({
      all_workflows: false,
      workflows_uuid: [],
    });
    setDisabledCheckWorkflows(false);
    setWorkflows([]);
    setWorkflowsTable([]);
    setWorkflowsSelected({
      ...workflowsSelected,
      workflow: "",
    });
  };

  //function to reset forms to their initial state
  const handleResetForms = () => {
    setForms([]);
    setFormsTable([]);
    setFormsSelected({
      ...formsSelected,
      form: "",
    });
    setDisabledSelect(false);
    setDisabledCheckForms(false);
    setPermissionsDesignerForms({
      all_forms: false,
      forms_uuid: [],
    });
  };

  /**
   * Updates the state of permissions forms and configuration based on the role permissions.
   * This function sets the state for permissions forms and configurations by checking the
   * presence of specific roles in the `rolPermissions` array. The roles are checked against
   * predefined role configuration constants.
   * @param {Function} setPermissionsForm - A state setter function for updating the permissions form state.
   * @param {Function} setPermissionsConf - A state setter function for updating the permissions configuration state.
   * @param {Array<string>} rolPermissions - An array of role permissions for the current user.
   * @param {Object} enumsParameterRolesConfig - An object containing role configuration constants.
   * @param {string} enumsParameterRolesConfig.CONFIG_TRD - The role constant for configuration TRD.
   * @param {string} enumsParameterRolesConfig.DESING_LIST - The role constant for designing lists.
   * @param {string} enumsParameterRolesConfig.ADMIN_USERS - The role constant for administering users.
   * @param {string} enumsParameterRolesConfig.CONFIG_ROLES - The role constant for configuration roles.
   * @param {string} enumsParameterRolesConfig.WORK_GROUPS - The role constant for work groups.
   */
  const setPermissionsForms = () => {
    setPermissionsForm({
      config_trd: rolPermissions.includes(enumsParameterRolesConfig.CONFIG_TRD),
      design_list: rolPermissions.includes(enumsParameterRolesConfig.DESING_LIST),
      admin_users: rolPermissions.includes(enumsParameterRolesConfig.ADMIN_USERS),
      roles: rolPermissions.includes(enumsParameterRolesConfig.CONFIG_ROLES),
      workGroup: rolPermissions.includes(enumsParameterRolesConfig.WORK_GROUPS),
    });
    setPermissionsConf({
      permissions: [],
    });
  };

  //reset component to zero
  const resetComponentToShow = () => {
    setComponentToShow("0");
  };

  //handle accordion views on click
  function handleOnClickAcordeon(tab) {
    const prevState = acordeon;
    const state = prevState.map((x, index) => (tab === index ? !x : false));
    setAcordeon(state);
  }

  //manages the state of the components depending on which component I want to access
  const handleButtonClick = (component) => {
    setComponentToShow(component);
  };

  //Effect for permissions
  useEffect(() => {
    // Lógica para comparar permissionsTemp y listForms
    let newPermissions = [];
    permissionsTemp.forEach((e) => {
      listForms.forEach((ele) => {
        if (e.form_uuid === ele.uuid) {
          newPermissions.push({
            formUuid: ele.uuid,
            formName: ele.name,
            permissions: e.permission,
          });
        }
      });
    });

    // Ordenar y establecer las nuevas permissions
    setPermissions(
      newPermissions.sort((a, b) => {
        const nombresA = `${a.name} `.toLocaleLowerCase();
        const nombresB = `${b.name} `.toLocaleLowerCase();
        return nombresA.localeCompare(nombresB);
      })
    );
  }, [permissionsTemp, listForms]);

  return (
    <RolesContext.Provider
      value={{
        getRolesBySearch,
        permissionsForm,
        setPermissionsForm,
        permissionsConf,
        setPermissionsConf,
        setPermissionsForms,
        getActiveForms,
        formsDinamic,
        setFormsDinamic,
        disabledSelect,
        setDisabledSelect,
        permissionsDesignerForms,
        setPermissionsDesignerForms,
        formsSelected,
        setFormsSelected,
        forms,
        setForms,
        deleteForms,
        formsTable,
        setFormsTable,
        disabledCheck,
        setDisabledCheck,
        disabledCheckWorkflows,
        setDisabledCheckWorkflows,
        setDisabledCheckForms,
        disabledCheckForms,
        isLoading,
        setIsLoading,
        isLoadingNameRol,
        isLoadingAllForms,
        isLoadingAllWorkflows,
        isLoadingPermissionsByForm,
        isLoadingPermmissionsByConfig,
        isLoadingPermissionsAdminFormsByRol,
        isLoadingPermissionsAdminWorkflowsByRol,
        isLoadingConfig,
        setIsLoadingConfig,
        getAllWorkFlows,
        workflowsDinamic,
        setWorkflowsDinamic,
        permissionsDesignerWorkflows,
        setPermissionsDesignerWorkflows,
        workflowsSelected,
        setWorkflowsSelected,
        workflows,
        setWorkflows,
        workflowsTable,
        setWorkflowsTable,
        deleteWorkflows,
        detailPermissions,
        setDetailPermissions,
        formDetail,
        setFormDetail,
        rolUuid,
        setRolUuid,
        listForms,
        listWorkflows,
        getPermissionsByForm,
        permissionsTemp,
        getPermissionsByConfig,
        rolPermissions,
        getPermissionsByAdminForms,
        listAdminForms,
        getPermissionsByAdminWorkflows,
        listAdminWorkflows,
        permissions,
        setPermissions,
        listAdminForm,
        setListAdminForm,
        listAdminWorkflow,
        setListAdminWorkflow,
        allAdminForms,
        setAllAdminWorkflows,
        acordeon,
        setAcordeon,
        allAdminWorkflows,
        editPermissions,
        setEditPermissions,
        handleResetWorkflows,
        handleResetForms,
        isDeleted,
        setIsDeleted,
        setIsDeleting,
        componentToShow,
        setComponentToShow,
        resetComponentToShow,
        handleOnClickAcordeon,
        handleButtonClick,
        chargeForm,
        setChargeForm,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
}
export function useRolesContext() {
  const context = React.useContext(RolesContext);
  if (!context) {
    throw new Error("useRolesContext debe estar dentro del proveedor RolesContext.");
  }
  return context;
}
