import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import useRecord from "hooks/useRecord";
import { getDateFormat } from "utils/getDateFormat";
import { getDateFormat2 } from "utils/getDateFormat2";
import { Link } from "react-router-dom";
import { VIEW_REGISTER, TYPE } from "constants/securityConst";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useFilterContext } from "contextAPI/FilterContext";
import { showAlertServiceError } from "utils/alerts";
import FiltersApplyByFiltersFormsRecords from "components/molecules/FiltersApplyByFiltersFormsRecords";
import i18n from "locales/i18n";
import ButtonExportRecords from "components/molecules/ButtonExportRecords";

const FilterFormsRecordsListTable = () => {
  const VIEWREGISTER = window.localStorage.getItem(VIEW_REGISTER);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const [quering, setQuering] = useState(false);
  const {
    recordId,
    count,
    setCount,
    records,
    setRecords,
    fields,
    isLoading,
    setIsLoading,
    pagination,
    setPagination,
    totalPages,
    setTotalPages,
    columnsTable,
    getFormsByUuid,
    formDetail,
    setColumnsTable,
    queriesRec,
  } = useFilterContext();
  const { getRecordsByFilters } = useRecord();

  /**
   * Perform a side effect in a React component to fetch forms by UUID.
   * This function utilizes the 'getFormsByUuid' function to fetch form data by a unique identifier
   * and is executed within a React useEffect hook to manage side effects.
   * @param {function} getFormsByUuid - A function responsible for fetching forms by UUID.
   * @returns {void}
   */
  useEffect(() => {
    getFormsByUuid();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook for updating table columns based on field data.
   * This hook filters out columns with a 'base' property, then generates additional
   * columns based on the 'fields' data. The additional columns are determined by
   * filtering 'fields' with a 'status' of 1 and mapping them to table column objects.
   * The formatted values for these additional columns are determined by 'type_data'.
   * @param {Array} fields - An array of field objects representing table columns.
   * @param {function} setColumnsTable - State setter function for updating table columns.
   *  @param {any} date - The date or value to format.
   * @param {number} typeData - The type of data to determine the formatting.
   * @returns {string|JSX.Element} - The formatted value or an empty span.
   */
  useEffect(() => {
    setColumnsTable((prevColumns) =>
      prevColumns.filter((column) => column.base)
    );
    const additionalColumns = fields
      .filter((column) => column.status === 1)
      .map((column) => ({
        Header: column.label,
        accessor: column.uuid,
        Cell: ({ value }) => {
          function formatValue(date, typeData) {
            if (typeData === 3) {
              return getDateFormat(new Date(date));
            }
            if (typeData === 4) {
              return getDateFormat2(new Date(date));
            }
            return date;
          }
          const formattedValue = formatValue(value, column.type_data);
          if (formattedValue) {
            return <span>{formattedValue}</span>;
          } else {
            return <span>{""}</span>;
          }
        },
      }));
    setColumnsTable((prevColumns) => [...prevColumns, ...additionalColumns]);
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes an effect that fetches records by filters when 'quering' is true.
   * This effect is triggered when the 'quering' flag is true. It fetches records based on the specified
   * parameters and updates the component's state with the retrieved data. If an error occurs during
   * the fetch operation, an error alert is displayed.
   * @param {boolean} quering - A flag that indicates whether records should be fetched.
   * @param {boolean} isLoading - A state setter function to update the loading indicator.
   * @param {function} getRecordsByFilters - A function to fetch records by filters.
   * @param {string} recordId - The identifier used for filtering records.
   * @param {number} page - The current page number for pagination.
   * @param {number} per_page - The number of records to display per page.
   * @param {object} queriesRec - Additional query parameters for record filtering.
   * @param {function} setQuering - A state setter function to update the 'quering' flag.
   * @param {function} setRecords - A state setter function to update the records data.
   * @param {function} setCount - A state setter function to update the total count of records.
   * @param {function} setTotalPages - A state setter function to update the total number of pages.
   * @param {function} showAlertServiceError - A function to display an error alert if the fetch operation fails.
   *  @returns {void}
   */
  useEffect(() => {
    if (quering) {
      setIsLoading(true);
      getRecordsByFilters(recordId, pagination.page, pagination.per_page, queriesRec)
        .then((response) => {
          if (response) {
            setQuering(false);
            setRecords(response.data.items);
            setCount(response.data.count);
            setTotalPages(response.data.pages);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  });

  if (USERTYPE !== "3" && VIEWREGISTER.includes(recordId) === false) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Alert color="info">
            <h5 className="alert-heading">
              {i18n.t("modal.DoneError.header")}
            </h5>
            <hr />
            <h6>{i18n.t("showNotification403")}</h6>
          </Alert>
        </CSSTransitionGroup>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Row>
                    <Col lg="5" md="4" sm="12" className="button-container">
                      <h5 className="text-info font-weight-bold">
                        {formDetail.name}
                      </h5>
                    </Col>

                    <Col
                      lg="7"
                      md="8"
                      sm="12"
                      className="button-container mt-2 mt-md-0"
                    >
                      <Row className="row justify-content-end">
                        <ButtonExportRecords
                          records={records}
                          recordId={recordId}
                          filters={queriesRec}
                        />
                        <Link to="/record/list/form">
                          <Button className="btn-icon mr-2 ml-1" color="cyan">
                            <FontAwesomeIcon
                              icon={faArrowAltCircleLeft}
                              className="mr-2"
                            />
                            {i18n.t("recordData5")}
                          </Button>
                        </Link>

                        <Button
                          className="btn-icon"
                          color="cyan"
                          onClick={() => {
                            setRecords([]);
                          }}
                        >
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          {i18n.t("filterQuery.button")}
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <CardBody>
                  <FiltersApplyByFiltersFormsRecords />
                  <ReactTable
                    data={records}
                    columns={columnsTable}
                    manual
                    pageSize={records.length}
                    loading={isLoading}
                    pages={totalPages}
                    previousText={`${i18n.t("previousText")}`}
                    nextText={`${i18n.t("nextText")}`}
                    pageText={
                      <span className="pr-2">{i18n.t("pageText")}</span>
                    }
                    ofText={<span className="px-2">{i18n.t("ofText")}</span>}
                    showPageJump={false}
                    defaultPageSize={pagination.per_page}
                    noDataText={`${i18n.t("tableRowsEmpty")}`}
                    onPageSizeChange={(per_page) => {
                      setPagination({ ...pagination, per_page: per_page });
                    }}
                    onPageChange={(page) => {
                      setPagination({ ...pagination, page: page + 1 });
                      setQuering(true);
                    }}
                    className="-striped -highlight"
                  />
                </CardBody>

                <CardFooter className="d-block text-right">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    {i18n.t("recordList11")} {count}
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default FilterFormsRecordsListTable;