import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import WorkFlowConfigSteps from "components/organism/WorkFlowConfigSteps";
import i18n from "locales/i18n";

const WorkFlowConfigurationSteps = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("createWorkflow.config.HeaderConfig")}
        subheading={i18n.t("createWorkflow.config.subHeading")}
        icon="note2"
      >
        <WorkFlowConfigSteps />
      </AdminTemplate>
    </Fragment>
  );
};


export default WorkFlowConfigurationSteps