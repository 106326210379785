import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";
import { useListContext } from "contextAPI/ListsContext";

const ButtonActionsItem = (props) => {
  const { itemId, listId, parentId } = props;
  const { setParentModal } = useListContext();

  /**
   * Sets the value of `parentId` in `parentModal`.
   * @return {void}
   */
  const handleSetParentModal = () => {
    setParentModal(parentId);
  };

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link to={`/formsdesigner/editItem/list/${listId}/itemslist/${itemId}`}>
            <i className="dropdown-icon lnr-pencil"> </i>
            <span onClick={handleSetParentModal} >
              {i18n.t("buttonActions.option2")}
            </span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  listId: PropTypes.string.isRequired,
  parentId: PropTypes.number.isRequired,
};

export default ButtonActionsItem;
