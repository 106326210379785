import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, CardHeader } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { enumsStatusRecord } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import SearchBox from "components/atoms/SearchBox";
import swal from "sweetalert";
import i18n from "locales/i18n";
import NotificationsDetailsTable from "components/organism/NotificationsDetailsTable";
import FilterNotificationDetail from "components/atoms/FilterNotificationDetail";
import useRecord from "hooks/useRecord";

const NotificationsDetails = (props) => {
  const { recordUuid, recordStatus } = props;
  const [listFreeTask, setListFreeTask] = useState([]);
  const [isLoadingTaskByRecord, setIsLoadingTaskByRecord] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTask, setSearchTask] = useState("");
  const [statusTask, setStatusTask] = useState("");
  const { getFreeTaskByRecord } = useRecord();

  /**
   * Callback function to retrieve the free task list for a given recordUuid, page, per_page, searchTask, and statusTask.
   * If the response has no items, a warning alert is shown.
   * The list of free tasks is updated and the total number of pages is set.
   * Finally, the loading state is set to false.
   * @throws {Error} if an error occurs during the API call.
   */
  const getFreeTaskByRecords = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingTaskByRecord(true);
    getFreeTaskByRecord(recordUuid, page, per_page, searchTask, statusTask)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          if (response.data.items.length === 0 && recordStatus !== enumsStatusRecord.CANCELED) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("recordData10"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            });
          }
          setListFreeTask(response.data.items);
          setTotalPages(response.data.pages);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingTaskByRecord(false);
      });
  }, [recordUuid, pagination, searchTask, statusTask]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Retrieves the list of free tasks for a given recordUuid, page, per_page, searchTask, and statusTask.
   * If the response has no items, a warning alert is shown.
   * @param {fucntion} getFreeTaskByRecords - Function to get the list of tasks.
   */
  useEffect(() => {
    getFreeTaskByRecords();
  }, [getFreeTaskByRecords]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <CardHeader>
            <div className="card-header-title font-size-lg text-capitalize font-weight-bold text-cyan pl-4">
              <h5>{i18n.t("recordData9").toUpperCase()}</h5>
            </div>

            <Row className="ml-auto align-center pr-3">
              <SearchBox
                setSearch={setSearchTask}
                pagination={pagination}
                setPagination={setPagination}
              />
              <FilterNotificationDetail
                setIsLoading={setIsLoadingTaskByRecord}
                setListFreeTask={setListFreeTask}
                setStatus={setStatusTask}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Row>
          </CardHeader>

          <NotificationsDetailsTable
            listFreeTask={listFreeTask}
            pagination={pagination}
            isLoading={isLoadingTaskByRecord}
            totalPages={totalPages}
            setPagination={setPagination}
            recordUuid={recordUuid}
          />
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

NotificationsDetails.propTypes = {
  recordUuid: PropTypes.string.isRequired,
  recordStatus: PropTypes.string.isRequired,
};

export default NotificationsDetails;