import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useRolesContext } from "contextAPI/RolesContext";
import {
  Card,
  Col,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { capitalizeText } from "utils/formatText";
import { enumsRolesAcordeon } from "utils/enums";
import i18n from "locales/i18n";
import ButtonBackRoles from "components/atoms/ButtonBackRoles";
import Loader from "react-loaders";
import UserRolConfiguration from "components/organism/UserRolConfiguration";
import UserRolesFormListTable from "components/organism/UserRolesFormListTable";
import UserRolesConfigDesignForms from "components/organism/UserRolesConfigDesignForms";
import UserRolesConfigDesignWorkflows from "components/organism/UserRolesConfigDesignWorkflows";

const UserRolConfig = () => {
  const { id, hash } = useParams();
  const {
    handleResetWorkflows,
    handleResetForms,
    acordeon,
    resetComponentToShow,
    handleOnClickAcordeon,
  } = useRolesContext();
  const rolName = JSON.parse(window.atob(hash));
  let nameRol = null;

  /**
   * Sets the value of the 'nameRol' variable based on the 'rolName' condition.
   * @param {string} rolName - The role name to check. If undefined, a loader is displayed.
   */
  if (rolName === undefined) {
    nameRol = (
      <div>
        <Loader color="primary" type="ball-pulse" size="small" />
      </div>
    );
  } else {
    nameRol = rolName;
  };

  /**
   * Opens the section for consultation filling.
   * Handles clicking on the accordion and resets the component to show.
   * @return {void}
   */
  const handleOpenSectionConsultationFilling = () => {
    handleOnClickAcordeon(enumsRolesAcordeon.CONSULTATION_FILLING);
    resetComponentToShow();
  }

  /**
   * Opens the section for configuration of parameters.
   * Handles clicking on the accordion and resets the component to show.
   * @return {void}
   */
  const handleOpenSectionCofigParameter = () => {
    handleOnClickAcordeon(enumsRolesAcordeon.CONFIG_PAREMETER);
    resetComponentToShow();
  }

  /**
   * Opens the section for configuring forms.
   * Handles clicking on the accordion and resets the forms.
   * @return {void}
   */
  const handleOpenSectionConfigForms = () => {
    handleOnClickAcordeon(enumsRolesAcordeon.DESIGNER_FORMS);
    handleResetForms();
  }

  /**
   * Opens the section for configuring workflows.
   * Handles clicking on the accordion and resets the workflows.
   * @return {void}
   */
  const handleOpenSectionConfigWorkflows = () => {
    handleOnClickAcordeon(enumsRolesAcordeon.DESIGNER_WORKFLOW);
    handleResetWorkflows();
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container">
                <Row>
                  <h5 className="text-info font-weight-bold ml-2">
                    {i18n.t("createRoles.configCard") + " "}
                    <span className="text-dark ml-2">
                      {capitalizeText(nameRol)}
                    </span>
                  </h5>
                </Row>
              </Col>
              <div className="btn-actions-pane-right">
                <ButtonBackRoles />
              </div>
            </Row>
          </CardBody>

          <CardBody className="pt-0">
            <div id="accordion" className="accordion-wrapper ">
              <Card>
                <CardHeader id="headingOne">
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={handleOpenSectionConsultationFilling}
                    aria-expanded={acordeon[enumsRolesAcordeon.CONSULTATION_FILLING]}
                    aria-controls="collapseOne"
                  >
                    <h5 className="text-info m-0 p-0">{`${i18n.t(
                      "recordData1"
                    )} ${i18n.t("and")} ${i18n.t("dashboard.label12")}`}</h5>
                  </Button>
                </CardHeader>
                <Collapse
                  isOpen={acordeon[enumsRolesAcordeon.CONSULTATION_FILLING]}
                  data-parent="#accordion"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                >
                  <UserRolesFormListTable uuidRol={id} />
                </Collapse>
              </Card>
              <Card>
                <CardHeader className="b-radius-0" id="headingTwo">
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={handleOpenSectionCofigParameter}
                    aria-expanded={acordeon[enumsRolesAcordeon.CONFIG_PAREMETER]}
                    aria-controls="collapseTwo"
                  >
                    <h5 className="text-info m-0 p-0">
                      {i18n.t("navItems.aplication.Parameters")}
                    </h5>
                  </Button>
                </CardHeader>
                <Collapse
                  isOpen={acordeon[enumsRolesAcordeon.CONFIG_PAREMETER]}
                  data-parent="#accordion"
                  id="collapseTwo"
                >
                  <CardBody>
                    <UserRolConfiguration uuidRol={id} />
                  </CardBody>
                </Collapse>
              </Card>
              <Card>
                <CardHeader id="headingThree">
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={handleOpenSectionConfigForms}
                    aria-expanded={acordeon[enumsRolesAcordeon.DESIGNER_FORMS]}
                    aria-controls="collapseThree"
                  >
                    <h5 className="text-info m-0 p-0">
                      {i18n.t("form.designerItem1")}
                    </h5>
                  </Button>
                </CardHeader>
                <Collapse
                  isOpen={acordeon[enumsRolesAcordeon.DESIGNER_FORMS]}
                  data-parent="#accordion"
                  id="collapseThree"
                >
                  <CardBody>
                    <UserRolesConfigDesignForms uuidRol={id} />
                  </CardBody>
                </Collapse>
              </Card>
              <Card>
                <CardHeader id="headingThree">
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={handleOpenSectionConfigWorkflows}
                    aria-expanded={acordeon[enumsRolesAcordeon.DESIGNER_WORKFLOW]}
                    aria-controls="collapseThree"
                  >
                    <h5 className="text-info m-0 p-0">
                      {i18n.t("createWorflow.titleNav")}
                    </h5>
                  </Button>
                </CardHeader>
                <Collapse
                  isOpen={acordeon[enumsRolesAcordeon.DESIGNER_WORKFLOW]}
                  data-parent="#accordion"
                  id="collapseThree"
                >
                  <CardBody>
                    <UserRolesConfigDesignWorkflows uuidRol={id} />
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserRolConfig;
