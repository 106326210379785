import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm } from "availity-reactstrap-validation";
import { Alert, Button, Card, CardBody, CardFooter } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ADD_REGISTER, TYPE } from "constants/securityConst";
import { Link, useParams } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { loaderComponent, loaderElement } from "utils/loaderElement";
import { showAlertServiceError } from "utils/alerts";
import { determineActions } from "utils/actionsRecordFieldForm";
import {
  enumsFieldFormStatus,
  enumsFields,
  enumsFormStatus,
  enumsTypeUser
} from "utils/enums";
import useRecord from "hooks/useRecord";
import swal from "sweetalert";
import i18n from "locales/i18n";

const RecordFieldForm = (props) => {
  const { isRecordCreate } = props;
  const { id } = useParams();
  const formId = id;
  const ADDREGISTER = window.localStorage.getItem(ADD_REGISTER);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const { createRecord } = useRecord();
  const [isLoadingRecord, setIsLoadingRecord] = useState(false);

  const {
    formName,
    recordForm,
    fieldsForm,
    typeControl,
    validateFieldsByTypeControl,
    getFieldsPreview,
    setIsPreview,
    isPreview,
    getFormsByUuid,
    statusForm,
    setValidationFieldDatePicker,
    isLoading: isLoadingGetFields,
  } = useFieldsRecordContext();
  let messageFiles = null;
  let renderFieldForm = null;

  /**
   * Sorts and maps an array of form fields based on their order property,
   * filtering and rendering fields with a specific status.
   * @param {Array} fieldsForm - An array containing form field objects.
   * @param {Object} field - A single form field object within the fieldsForm array.
   * @param {number} field.order - The order property determining the sort order of the fields.
   * @param {Object} field.component - The component associated with the form field.
   * @param {Object} field.component.props - The properties of the component.
   * @param {number} field.component.props.status - The status of the form field.
   * @param {string} field.id - The unique identifier for the form field.
   * @returns {Array} sortedAndMappedFields - An array of React elements representing
   *   form fields that meet the specified status condition.
   */
  const sortedAndMappedFields = fieldsForm
    .sort((firstField, secondField) => firstField.order - secondField.order)
    .map((field) => {
      if (field.component.props.status === enumsFieldFormStatus.ACTIVE) {
        return (
          <ul id={field.id} key={field.id}>
            {field.component}
          </ul>
        );
      } else {
        return null;
      }
    });

  /**
   * Generates an alert message based on the length of the 'fieldsForm' array.
   * If the 'fieldsForm' array is empty, it creates an alert with an information icon,
   * a header, and a message indicating that the fields are empty.
   * If the 'fieldsForm' array is not empty, it creates an alert with an information icon,
   * a header, and a generic alert message.
   * @param {Array} fieldsForm - An array representing the fields form.
   * @param {Object} i18n - An internationalization object for translating messages.
   * @returns {JSX.Element} messageFiles - The generated JSX element representing the alert message.
   */
  if (fieldsForm.length === 0) {
    messageFiles = (
      <Alert className="mbg-3 mt-2 mb-4" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          {i18n.t("record.fieldsEmpty")}
        </span>
      </Alert>
    );
  } else {
    messageFiles = (
      <Alert className="mbg-3 mt-2 mb-4" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
        </span>
        {i18n.t("RecordForm.Alert")}
      </Alert>
    );
  }

  /**
   * Handles form submission, performs validation, and creates a record if validation passes.
   * @param {Array} eventRecordsForms - The event object associated with the form submission.
   * @param {Array} errors - An array containing validation errors.
   * @returns {void}
   */
  const handleOnSubmit = async (eventRecordsForms, errors) => {
    const idsToValidate = fieldsForm
      .filter(
        (field) =>
          field.component.props.status === enumsFieldFormStatus.ACTIVE &&
          (field.component.props.typeControl === enumsFields.DATE_PICKER ||
            field.component.props.typeControl === enumsFields.DATE_TIME_PICKER) &&
          field.component.props.required === true &&
          (recordForm[field.id] === null || !(field.id in recordForm))
      )
      .map((field) => field.id);

    if (idsToValidate.length > 0) {
      setValidationFieldDatePicker({
        required: true,
        id: idsToValidate,
      });
      eventRecordsForms.preventDefault();
      return;
    }

    if (errors.length === 0) {
      const newRecordForm = Object.fromEntries(
        Object.entries(recordForm).filter(
          ([_, value]) => value !== "" && value !== null
        )
      );

      setIsLoadingRecord(true);
      const createRecordAndRedirect = () => {
        createRecord(newRecordForm, formId)
          .then((response) => {
            const recordId = response.data.data.uuid;
            const title = response.data.data.record;
            if (response.status === 201) {
              swal({
                title: `${title}`,
                text: `${i18n.t("recordCreated")}`,
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.href = `/record/details/${recordId}/${formId}`;
              });
            } else {
              showAlertServiceError();
            }
          })
          .catch((error) => {
            if (error) {
              setIsLoadingRecord(false);
            }
          })
          .finally(() => {
            setIsLoadingRecord(false);
          });
      };

      if (Object.keys(newRecordForm).length === 0) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("RecordForm.label2"),
          icon: "info",
          buttons: [
            i18n.t("createusers.createButton2"),
            i18n.t("modal.Done.footerButton"),
          ],
        }).then((willCreateRecord) => {
          if (willCreateRecord) {
            createRecordAndRedirect();
          } else {
            setIsLoadingRecord(false);
          }
        })
          .catch((error) => {
            if (error) {
              setIsLoadingRecord(false);
            }
          });
      } else {
        createRecordAndRedirect();
      }
    }
  };

  /**
   * Renders a form or an alert based on user type and form ID conditions.
   * This block conditionally renders either an alert message or a form card based on the user's type and 
   * whether the form ID is included in the allowed list. If the user type is not "3" and the form ID is 
   * not in the `ADDREGISTER` list, it renders an alert message indicating insufficient permissions. Otherwise,
   * it renders a form card with input fields and buttons for submission.
   * @param {string} USERTYPE - The type of the user, used to determine permissions.
   * @param {Array<string>} ADDREGISTER - The list of form IDs that are allowed for the current user.
   * @param {string} formId - The ID of the current form.
   * @param {string} formName - The name of the current form.
   * @param {Function} handleOnSubmit - The function to handle form submission.
   * @param {boolean} isLoadingRecord - Indicates whether the form submission is in progress.
   * @param {Array<Object>} fieldsForm - The list of form fields to be displayed in the form.
   * @param {JSX.Element} messageFiles - The JSX element displaying messages related to file uploads.
   * @param {JSX.Element} sortedAndMappedFields - The JSX element containing the sorted and mapped form fields.
   * @returns {JSX.Element} The JSX element to be rendered, which can be an alert or a form card.
   */
  if (USERTYPE !== enumsTypeUser.ADMIN && ADDREGISTER.includes(formId) === false) {
    renderFieldForm = (
      <Alert color="info">
        <h4 className="alert-heading">
          {i18n.t("modal.DoneError.header")}
        </h4>
        <hr />
        <h6>{i18n.t("showNotification403")}</h6>
      </Alert>
    );
  } else {
    renderFieldForm = (
      <Card className="main-card mb-3">
        <AvForm onSubmit={handleOnSubmit}>
          <CardBody>
            <h5 className="text-info font-weight-bold">{formName}</h5>
            <div className="divider" />
            {messageFiles}
            {sortedAndMappedFields}
          </CardBody>
          <CardFooter className="d-block text-right">
            <Link to="/record/list/forms">
              <Button
                size="lg"
                disabled={isLoadingRecord}
                className="col-mt-3 mr-3"
                color="gray"
              >
                {i18n.t("createusers.createButton2")}
              </Button>
            </Link>
            <Button
              type="submit"
              size="lg"
              disabled={isLoadingRecord || fieldsForm.length === 0}
              className="col-mt-3"
              color="cyan"
            >
              {i18n.t("createusers.createButton")}
            </Button>
          </CardFooter>
        </AvForm>
      </Card>
    );
  };

  /**
   * Handle the effect when the statusForm changes.
   * @returns {void}
   */
  useEffect(() => {
    if (statusForm.status === enumsFormStatus.FORMS_STATUS_INACTIVE) {
      window.location.href = "/";
    }
  }, [statusForm]);

  /**
   * Fetch the form fields preview based on user actions.
   * @returns {void}
   */
  useEffect(() => {
    const actions = determineActions(USERTYPE, isRecordCreate);
    getFieldsPreview(formId, actions);
  }, [getFieldsPreview]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handle the effect when the typeControl changes.
   * @returns {void}
   */
  useEffect(() => {
    const actions = determineActions(USERTYPE, isRecordCreate);
    getFormsByUuid(formId, actions);
  }, [getFormsByUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handle the effect when the typeControl changes.
   * @returns {void}
   */
  useEffect(() => {
    setIsPreview(false);
    typeControl.map((typeControl) =>
      validateFieldsByTypeControl(
        typeControl.type_control,
        typeControl,
        isPreview
      )
    );
  }, [typeControl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingGetFields === true) {
    return loaderComponent(isLoadingGetFields);
  } else {
    return (
      <Fragment>
        {loaderElement(isLoadingRecord)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          {renderFieldForm}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

RecordFieldForm.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
};

export default RecordFieldForm;
