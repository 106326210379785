import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import i18n from "locales/i18n";
import ButtonActionsDocumentaryTypes from "components/atoms/ButtonActionsDocumentaryTypes";

const DocumentaryTypesTable = (props) => {

  /**
   *Event handler for changing the page size of pagination.
   *@param {object} props.pagination - The current pagination state object
   *@param {number} props.pagination.per_page - The current number of items per page
   *@param {function} props.setPagination - The function to update the pagination state
   */
  const handleOnpageSizeChange = (per_page) => {
    props.setPagination({ ...props.pagination, per_page: per_page });
  };

  /**
   *Handles the change of the current page in the pagination component by updating the pagination state.
   *@param {object} props.pagination - The current pagination state object
   *@param {number} props.pagination.page - The current page number
   *@param {function} props.setPagination - The setter function to update the pagination state
   */
  const handleOnpageChange = (page) => {
    props.setPagination({ ...props.pagination, page: page + 1 });
  };
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={props.DocumentaryTypes}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("documentary.type.table.label1")],
                  accessor: "name",
                },
                {
                  Header: [i18n.t("form.designerLabel3")],
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("form.designerLabel3.3")}
                        </div>
                      );
                    } else if (value === 2) {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("form.designerLabel3.4")}
                        </div>
                      );
                    }

                    return <span>{statusFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("documentary.type.table.label3")],
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("documentary.type.table.label4")],
                  accessor: "updated_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <span>{valueFinal}</span>;
                  },
                },
              ],
            },
            {
              columns: [
                {
                  Header: [i18n.t("buttonActions.title")],
                  accessor: "actions",
                  Cell: (row) => {
                    const documentaryName = row.original.name;
                    const documentaryId = row.original.uuid
                    return (
                      <ButtonActionsDocumentaryTypes
                        documentaryName={documentaryName}
                        documentaryId={documentaryId}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={props.totalPages}
          page={props.pagination.page - 1}
          showPageJump={false}
          defaultPageSize={props.pagination.per_page}
          loading={props.isLoadingDocumentaryTypes}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={handleOnpageSizeChange}
          onPageChange={handleOnpageChange}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

DocumentaryTypesTable.propTypes = {
  DocumentaryTypes: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingDocumentaryTypes: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default DocumentaryTypesTable;
