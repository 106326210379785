import { useState, useCallback, useEffect } from "react";
import { isNullOrUndefined } from "utils/validations";
import useTrd from "hooks/useTrd";
import {
  getItem,
  setItem
} from "utils/localStorage";
import {
  FIRST_EXECUTION_BOOLEAN,
  SUBMENU_EXPEDIENT_CONSULT_BOOLEAN
} from "constants/securityConst";

const useShowConsultExpedient = () => {

  const { getTreeExist } = useTrd();
  let viewSubMenuExpedientConsult = false;

  const [firsViewExpedientConsult, setFirstViewExpediente] = useState(null);
  const [isViewExpedientConsult] = useState(() => {
    const isViewExpedientConsult = getItem(SUBMENU_EXPEDIENT_CONSULT_BOOLEAN);
    if (isNullOrUndefined(isViewExpedientConsult) === false) {
      return JSON.parse(isViewExpedientConsult);
    } else {
      return false;
    }
  });

  if (!isNullOrUndefined(firsViewExpedientConsult)) {
    viewSubMenuExpedientConsult = firsViewExpedientConsult;
  } else {
    viewSubMenuExpedientConsult = isViewExpedientConsult;
  }

  /**
   * Retrieves the existing tree data based on a search.
   * Uses the useCallback hook to memoize the function.
   *
   * @return {Promise} A promise that resolves with the existing tree data.
   */

  const getTreeExistBySearch = useCallback(() => {
    getTreeExist()
      .then((response) => {
        setItem(SUBMENU_EXPEDIENT_CONSULT_BOOLEAN, response.data);
        setFirstViewExpediente(response.data);
      })
      .catch(() => { });
  }, [getTreeExist]);

/**
 * useEffect hook that fetches existing tree data based on a search on the first execution.
 * After fetching the data, it updates the 'FIRST_EXECUTION_BOOLEAN' in localStorage to mark subsequent executions.
 * @param {function} getTreeExistBySearch - A function that retrieves existing tree data based on a search criteria.
 * @returns {void} No return value.
 */

  useEffect(() => {
    const isTheFirstExecution = getItem(FIRST_EXECUTION_BOOLEAN);
    if (isNullOrUndefined(isTheFirstExecution) === true) {
      getTreeExistBySearch();
      setItem(FIRST_EXECUTION_BOOLEAN, true);
    }
  }, [getTreeExistBySearch]);

  return {
    viewSubMenuExpedientConsult,
    getTreeExistBySearch,
  };
};

export default useShowConsultExpedient;
