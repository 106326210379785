import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import ConsultConfigurationFieldsForm from "components/organism/ConsultConfigurationFieldsForm";
import i18n from "locales/i18n";

const ConsultConfigurationFields = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("form.fieldHeading")}
				subheading={i18n.t("form.fieldSubheading")}
				icon="next-2"
			>
				<ConsultConfigurationFieldsForm />
			</AdminTemplate>
		</Fragment>
	)
}

export default ConsultConfigurationFields