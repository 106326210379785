import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  initialPaginationGetUsers,
  initialPaginationStandart,
} from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { enumsMaxMenuHeight } from "utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { getFormattedTimezoneOffset } from "utils/getDateFormat";
import { processSortAndSetUsers } from "utils/sortHelpers";
import * as moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import useCompany from "hooks/useCompany";
import useUser from "hooks/useUser";
import AuditLogsListTable from "components/organism/AuditLogsListTable";
import DateRangePickerGeneral from "components/molecules/DateRangePickerGeneral";
import i18n from "locales/i18n";
import swal from "sweetalert";

const AuditLogsLists = () => {
  const [selectedOptionsUsers, setSelectedOptionsUsers] = useState(null);
  const [usersDinamic, setUsersDinamic] = useState([]);
  const [logsLists, setLogsList] = useState([]);
  const [isLoadingGetLogs, setIsLoadingGetLogs] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isVisibleButtonRestore, setIsVisibleButtonRestore] = useState(false);
  const [errorsDate, setErrorsDate] = useState({
    startDateError: "",
    endDateError: "",
  });
  const [filterByUserOrDate, setFilterByUserOrDate] = useState({
    user_uuid: "",
    star_date: "",
    end_date: "",
  });
  const { getLogsByCompany } = useCompany();
  const { getUsers } = useUser();
  const timeZone = getFormattedTimezoneOffset();
  let selectUserDisabled = false;
  let usersSelectByFilterPlaceholder = null;
  let disbledFilterButton = true;
  let buttonRestoreFilter = null;

  /**
   * Updates the state of the user selection dropdown based on loading status.
   * If users are currently being loaded, the user selection dropdown is disabled
   * and a loading placeholder is shown. Otherwise, the placeholder indicates that
   * a user can be selected.
   * @param {boolean} isLoadingUsers - The current loading status of users.
   * @param {boolean} selectUserDisabled - A flag to disable the user selection dropdown.
   * @param {string} usersSelectByFilterPlaceholder - The placeholder text for the user selection dropdown.
   * @param {object} i18n - The internationalization object for translation.
   */
  if (isLoadingUsers === true) {
    selectUserDisabled = true;
    usersSelectByFilterPlaceholder = i18n.t("loading");
  } else {
    usersSelectByFilterPlaceholder = i18n.t("AuditLogs.Searchuser");
  }

  /**
   * Updates the state of the filter button based on the provided user search object.
   * @param {Object|null|undefined} searchUser - The search user object. If this is null or undefined, the filter button will be disabled.
   * @param {boolean} isNullOrUndefined - A function that checks if the input is null or undefined.
   * @returns {void} No return value.
   */
  if (isNullOrUndefined(searchUser) === false) {
    disbledFilterButton = false;
  }

  /**
   * Checks if the startDate and endDate are not null or undefined, and if the startDateError or endDateError messages are not equal to the
   * maximum date range error messages and sets the disbledFilterButton to false if both dates are valid.
   * @param {Date|null|undefined} startDate - The start date to be checked.
   * @param {Date|null|undefined} endDate - The end date to be checked.
   * @param {boolean} disbledFilterButton - The filter button state to be updated.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   */
  if (
    isNullOrUndefined(startDate) === false &&
    isNullOrUndefined(endDate) === false &&
    (errorsDate.startDateError !== i18n.t("processManagement.DateMaxRange") ||
      errorsDate.endDateError !== i18n.t("processManagement.DateMaxRange"))
  ) {
    disbledFilterButton = false;
  }

  /**
   * Determines if the filter button should be disabled based on the state of searchUser, startDate, and endDate.
   * @param {boolean} searchUser - Indicates whether the searchUser is null or undefined.
   * @param {boolean} startDate - Indicates whether the startDate is null or undefined.
   * @param {boolean} endDate - Indicates whether the endDate is null or undefined.
   * @param {function} isNullOrUndefined - Function that checks if a value is null or undefined.
   * @returns {void} - No return value; modifies the disbledFilterButton variable.
   */
  if (
    isNullOrUndefined(searchUser) === false &&
    isNullOrUndefined(startDate) === false &&
    isNullOrUndefined(endDate) === true
  ) {
    disbledFilterButton = true;
  }

  /**
   * Evaluates the conditions to determine if the filter button should be disabled.
   * @param {any} searchUser - The search user value, can be of any type.
   * @param {any} startDate - The start date value, can be of any type.
   * @param {any} endDate - The end date value, can be of any type.
   * @param {Function} isNullOrUndefined - A function that checks if a value is null or undefined.
   * @param {boolean} disbledFilterButton - The state of the filter button, initially false.
   * @returns {boolean} - Returns the updated state of the filter button.
   */
  if (
    isNullOrUndefined(searchUser) === false &&
    isNullOrUndefined(startDate) === true &&
    isNullOrUndefined(endDate) === false
  ) {
    disbledFilterButton = true;
  }

  /**
   * Fetches users and updates the state with a filtered and sorted list.
   * @param {number} page - The current page number for pagination.
   * @param {number} per_page - The number of users to fetch per page.
   * @param {Function} setIsLoadingUsers - Function to update the loading state for users.
   * @param {Function} getUsers - Function to fetch users from the API.
   * @param {Function} setUsersDinamic - Function to update the users state.
   * @param {Function} showAlertServiceError - Function to show an alert for service errors.
   * @param {Object} enumsTypeStatusUser - Enum containing user status types.
   */
  const getUsersBySearch = useCallback(() => {
    const { page, per_page } = initialPaginationGetUsers;
    setIsLoadingUsers(true);
    getUsers(page, per_page, "")
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          const usersList = response.data.items;
          processSortAndSetUsers(usersList, setUsersDinamic);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingUsers(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *A memoized function that fetches a list of lists based on the searchList, pagination, and statusList parameters.
   *@param {function} getUsers - the function that fetches the list data and returns a promise
   *@param {object} pagination - an object containing page and per_page properties to define the pagination of the results
   *@param {string} searchList - the searchList query to filter the users by
   *@param {string} statusList - the statusList to filter the users by
   *@param {function} showAlertConsultListNoExist - the function that displays an alert message if there are no lists found
   */
  const getLogsBySearch = useCallback(
    (filterByUserOrDate) => {
      setIsLoadingGetLogs(true);
      const { page, per_page } = pagination;
      getLogsByCompany(page, per_page, timeZone, filterByUserOrDate)
        .then((response) => {
          if (isNullOrUndefined(response.data.items) === false) {
            setLogsList(response.data.items);
            setTotalPages(response.data.pages);
            if (response.data.items.length === 0) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("AuditLogs.EmptyQuery"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingGetLogs(false);
        });
    },
    [pagination] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Handles the search button click event.
   * This function is triggered when the search button is clicked. It performs two actions:
   * 1. Sets the visibility of the restore button to true.
   * 2. Calls the `getLogsBySearch` function with the `filterByUserOrDate` parameter to fetch logs based on the search criteria.
   * @returns {void}
   */
  const handleOnSearchButton = () => {
    setIsVisibleButtonRestore(true);
    getLogsBySearch(filterByUserOrDate);
  };

  /**
   * Handles the change event when selecting users.
   * Updates the selected options state and updates the user_uuid in queryProcessData accordingly.
   * @param {Object} selectedOption - The selected option object representing the user.
   * @param {string} selectedOption.value - The UUID of the selected user.
   */
  const handleChangeUsers = (selectedOption) => {
    setSelectedOptionsUsers(selectedOption);
    if (selectedOption) {
      setSearchUser(selectedOption.value);
    } else {
      setSearchUser(null);
    }
  };

  /**
   * Handles the restoration of filter settings to their default values.
   * This function performs the following actions:
   * - Hides the restore button by setting `isVisibleButtonRestore` to `false`.
   * - Clears the selected user options by setting `selectedOptionsUsers` to `null`.
   * - Resets the search user input field to an empty string.
   * - Clears the start date filter by setting `startDate` to an empty string.
   * - Clears the end date filter by setting `endDate` to an empty string.
   * - Resets pagination to the first page by setting `pagination.page` to 1.
   * @param {function} setIsVisibleButtonRestore - Setter function to update the visibility of the restore button.
   * @param {function} setSelectedOptionsUsers - Setter function to update the selected user options.
   * @param {function} setSearchUser - Setter function to update the search user input field.
   * @param {function} setStartDate - Setter function to update the start date filter.
   * @param {function} setEndDate - Setter function to update the end date filter.
   * @param {function} setPagination - Setter function to update pagination, including the page number.
   * @returns {void}
   */
  const handleRestoreFilter = () => {
    setIsVisibleButtonRestore(false);
    setSelectedOptionsUsers(null);
    setSearchUser("");
    setStartDate("");
    setEndDate("");
    setPagination({ ...pagination, page: 1 });
  };

  /**
   * Conditionally renders a button to restore filters based on the visibility state.
   * This component renders a `Button` wrapped in `Col` components if `isVisibleButtonRestore` is `true`.
   * The button is styled with specific classes and includes an icon and label, and it triggers the `handleRestoreFilter` function when clicked.
   * @param {boolean} isVisibleButtonRestore - Determines whether the button to restore filters should be visible.
   * @param {function} handleRestoreFilter - Function to be called when the restore button is clicked.
   * @param {object} i18n - Internationalization object used to fetch the localized string for the button label.
   * @param {object} FontAwesomeIcon - Component from Font Awesome library for displaying icons.
   * @param {object} faWindowRestore - Font Awesome icon object used in the button.
   * @param {object} Col - React component used for grid layout.
   * @param {object} Button - React component used for creating buttons.
   */
  if (isVisibleButtonRestore === true) {
    buttonRestoreFilter = (
      <Col lg="2" md="12" sm="12" className="button-container mt-1 pr-0">
        <Col lg="auto" md="12" sm="12" className="align-self-end pr-0">
          <Button
            data-test-id="btn-buttonRestoreFilter"
            className="btn-block ml-auto btn-icon btn-block btn-mb-responsive"
            color="gray"
            onClick={handleRestoreFilter}
          >
            <span>
              <FontAwesomeIcon icon={faWindowRestore} className="mr-2" />
              {i18n.t("taskFilter.label14")}
            </span>
          </Button>
        </Col>
      </Col>
    );
  }

  /**
   * A React hook that updates the filter state based on user search and date range changes.
   * This effect runs whenever the `searchUser`, `startDate`, or `endDate` dependencies change. It updates the filter state with the following properties:
   * - `user_uuid`: The UUID of the user to filter by.
   * - `start_date`: The start date of the filter, formatted in UTC.
   * - `end_date`: The end date of the filter, formatted in UTC.
   * @param {Object} dependencies - The dependencies array for the effect.
   * @param {string} dependencies.searchUser - The UUID of the user to filter by.
   * @param {string} dependencies.startDate - The start date of the filter.
   * @param {string} dependencies.endDate - The end date of the filter.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(startDate) === true && isNullOrUndefined(endDate) === true) {
      setFilterByUserOrDate({
        user_uuid: searchUser,
        start_date: null,
        end_date: null,
      });
    } else if (isNullOrUndefined(searchUser) === true) {
      setFilterByUserOrDate({
        user_uuid: null,
        start_date: moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
        end_date: moment.utc(endDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
      });
    } else {
      setFilterByUserOrDate({
        user_uuid: searchUser,
        start_date: moment.utc(startDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
        end_date: moment.utc(endDate).format("YYYY-MM-DDTHH:mm:ss+00:00"),
      });
    }
  }, [searchUser, startDate, endDate]);

  /**
   *useEffect hook that invokes the memoized getListssBySearch function when the component mounts or the getListssBySearch function changes.
   *@param {function} getListssBySearch - the memoized function that fetches a list of lists based on the searchList, pagination, and statusList parameters
   */
  useEffect(() => {
    if (isNullOrUndefined(usersDinamic) === false && usersDinamic.length === 0) {
      getUsersBySearch();
    }

    if (isVisibleButtonRestore === true) {
      getLogsBySearch(filterByUserOrDate);
    } else {
      getLogsBySearch();
    }
  }, [getLogsBySearch, getUsersBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card ">
          <CardBody className="pb-1">
            <Row className="row justify-content-end">
              {buttonRestoreFilter}
              <Col lg="3" md="4" sm="12" className="button-container pr-0">
                <Col lg="12" md="12" sm="12" className="align-self-end mb-2 pr-0">
                  <Select
                    id="user_uuid"
                    name="user_uuid"
                    className="custom-tooltip"
                    maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
                    menuPlacement="auto"
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    isMulti={false}
                    isSearchable={true}
                    isClearable={true}
                    hideSelectedOptions={true}
                    placeholder={usersSelectByFilterPlaceholder}
                    options={usersDinamic}
                    isDisabled={selectUserDisabled}
                    onChange={handleChangeUsers}
                    value={selectedOptionsUsers}
                    noOptionsMessage={() => i18n.t("create.work.group.members.alert3")}
                  />
                </Col>
              </Col>
              <Col lg="4" md="4" sm="12" className="button-container pr-0">
                <Col lg="12" md="12" sm="12" className="align-self-end pr-0">
                  <DateRangePickerGeneral
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    errorsDate={errorsDate}
                    setErrorsDate={setErrorsDate}
                  />
                </Col>
              </Col>
              <Col lg="2" md="12" sm="12" className="button-container mt-1">
                <Col lg="auto" md="12" sm="12" className="align-self-end pr-0">
                  <Button
                    className="btn-block ml-auto btn-icon btn-block btn-mb-responsive "
                    color="cyan"
                    disabled={disbledFilterButton}
                    onClick={handleOnSearchButton}
                  >
                    <span>
                      <FontAwesomeIcon icon={faSearch} className="mr-2" />
                      {i18n.t("recordList5")}
                    </span>
                  </Button>
                </Col>
              </Col>
            </Row>
          </CardBody>
          <CardBody className=" pt-0 mt-0">
            <AuditLogsListTable
              logsLists={logsLists}
              pagination={pagination}
              isLoadingGetLogs={isLoadingGetLogs}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default AuditLogsLists;
