import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import RecordsList from "components/organism/RecordsList";
import i18n from "locales/i18n";

const RecordList = () => {
    return (
        <Fragment>
            <AdminTemplate
                heading={i18n.t("recordList2")}
                subheading={i18n.t("recordList6")}
                icon="search"
            >
                <RecordsList/>
            </AdminTemplate>
        </Fragment>
    );
};

export default RecordList;