import React from "react";
import UserRolesConfig from "./UserRolesConfig";
import { RolesContextProvider } from "contextAPI/RolesContext";

export default () => {
  return (
    <RolesContextProvider>
      <UserRolesConfig />
    </RolesContextProvider>
  );
};
