import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Row,
  Alert,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faInfoCircle,
  faFilePdf,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import useIntegrations from "hooks/useIntegrations";
import i18n from "locales/i18n";
import swal from "sweetalert";

const EditIntegrationConfig = (props) => {
  const { requestUuid, disabled } = props;
  const size = process.env.REACT_APP_SIZE_AGREEMENT_FILE;
  const [showModal, setShowModal] = useState(false);
  const [isLoadingEditIntegration, setIsLoadingEditIntegration] = useState(false);
  const [changeFileEditIntegration, setChangeFileEditIntegration] = useState(true);
  const [fileEditIntegration, setFileEditIntegration] = useState("");
  const [fileRefEditIntegration, setFileRefEditIntegration] = useState("");
  const [fileTempEditIntegration, setFileTempEditIntegration] = useState("");
  const [shownEditIntegration, setShownEditIntegration] = useState(false);
  const [errorFileEditIntegration, setErrorFileEditIntegration] = useState(false);
  const [errorFileSizeEditIntegration, setErrorFileSizeEditIntegration] = useState(false);
  const [userApi, setUserApi] = useState({
    request_uuid: requestUuid,
    username_validart: "",
    password_validart: "",
  });

  const [userApiTemp, setUserApiTemp] = useState({
    request_uuid: requestUuid,
    username_validart: "",
    password_validart: "",
  });
  const switchShown = () => setShownEditIntegration(!shownEditIntegration);
  const { getConfigIntegration, updateConfigIntegration } = useIntegrations();

  let loadingComponent = null;
  let showPassword = null;
  let showButtonPassword = null;
  let componentRedered = null;
  let errorIntegrationFile = null;
  let errorIntegrationFileSize = null;

  /**
   * Displays an error alert if there is an issue with the fileEditIntegration.
   * This code checks if the `errorFileEditIntegration` state is `true`, and if so, it sets 
   * the `errorIntegrationFile` variable to an alert component. This alert 
   * component includes an icon and a localized message indicating that there 
   * is an issue with the configuration fileEditIntegration.
   * @param {boolean} errorFileEditIntegration - The state indicating if there is an error with the fileEditIntegration.
   * @returns {JSX.Element|null} An alert component if `errorFileEditIntegration` is `true`, otherwise `null`.
   */
  if (errorFileEditIntegration === true) {
    errorIntegrationFile = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("integration.requestConfigFileAcc2")}
      </Alert>
    )
  };

  /**
  * Displays an error alert if the fileEditIntegration size exceeds the allowed limit.
  * This code checks if the `errorFileSizeEditIntegration` state is `true`, and if so, it sets 
  * the `errorIntegrationFileSize` variable to an alert component. This alert 
  * component includes an icon and a localized message indicating that the 
  * configuration fileEditIntegration size is too large.
  * @param {boolean} errorFileSizeEditIntegration - The state indicating if the fileEditIntegration size exceeds the allowed limit.
  * @returns {JSX.Element|null} An alert component if `errorFileSizeEditIntegration` is `true`, otherwise `null`.
  */
  if (errorFileSizeEditIntegration === true) {
    errorIntegrationFileSize = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("integration.requestConfigFileSize")}
      </Alert>
    )
  };

  /**
   * Renders a font awesome icon based on the value of the 'shownEditIntegration' variable.
   * This function conditionally assigns a font awesome icon to the 'showButtonPassword' variable
   * based on the value of the 'shownEditIntegration' variable. If 'shownEditIntegration' is true, it assigns the eye icon, otherwise,
   * it assigns the eye slash icon.
   * @param {boolean} shownEditIntegration - A flag indicating whether the password is shownEditIntegration or hidden.
   * @returns {JSX.Element} The font awesome icon component based on the value of 'shownEditIntegration'.
   */
  if (shownEditIntegration === true) {
    showButtonPassword = (
      <FontAwesomeIcon icon={faEye} />
    )
  } else {
    showButtonPassword = (
      <FontAwesomeIcon icon={faEyeSlash} />
    )
  }

  /**
   * Determines the type of password input based on the value of the 'shownEditIntegration' variable.
   * This function conditionally assigns the type of password input to the 'showPassword' variable
   * based on the value of the 'shownEditIntegration' variable. If 'shownEditIntegration' is true, it assigns the type 'text',
   * otherwise, it assigns the type 'password'.
   * @param {boolean} shownEditIntegration - A flag indicating whether the password should be shownEditIntegration or hidden.
   * @returns {string} The type of password input based on the value of 'shownEditIntegration'.
   */
  if (shownEditIntegration === true) {
    showPassword = "text";
  } else {
    showPassword = "password";
  }

  /**
   * Renders a loading spinner component if isLoading is true.
   * This function conditionally assigns a loading spinner component to the loadingComponent variable
   * if isLoading is true. Otherwise, it assigns null to loadingComponent.
   * @param {boolean} isLoading - A flag indicating whether loading is in progress.
   * @returns {JSX.Element|null} A loading spinner component if isLoading is true, otherwise null.
   */
  if (isLoadingEditIntegration === true) {
    loadingComponent = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Handles the click event to show a modal.
   * This function sets the state `showModal` to true, which triggers the display of the modal.
   * @return {void}
   */
  const handleOnClick = () => {
    setShowModal(true);
  };

  /**
   * Handles the close event for a modal.
   * This function sets the state `showModal` to false, which hides the modal.
   * @return {void}
   */
  const handleOnCloseIntegrationConfig = () => {
    setShowModal(false);
  };

  /**
   * Handles the change event for integration configuration.
   * This function updates the user API state with the new value from the input field.
   * @param {Object} eventIntegrationConfig - The event object associated with the change event.
   * @param {string} eventIntegrationConfig.target.name - The name of the input field.
   * @param {string} eventIntegrationConfig.target.value - The new value of the input field.
   */
  const handleOnChangeIntegration = (eventIntegrationConfig) => {
    setUserApi({
      ...userApi,
      [eventIntegrationConfig.target.name]: eventIntegrationConfig.target.value,
    });
  };

  /**
   * Disables the fileEditIntegration change functionality for the integration.
   * This function sets the `changeFileEditIntegration` state to `false`, indicating that the fileEditIntegration change functionality is disabled.
   */
  const changeFileIntegration = () => {
    setChangeFileEditIntegration(false);
  }

  /**
   * Handles the change event for fileEditIntegration input.
   * This function checks the type and size of the selected fileEditIntegration. If the fileEditIntegration type is not
   * "application/pdf", it sets `errorFileEditIntegration` state to true. If the fileEditIntegration size exceeds the
   * specified size limit, it sets `errorFileSizeEditIntegration` state to true. Otherwise, it sets `errorFileEditIntegration`
   * and `errorFileSizeEditIntegration` states to false and updates the `fileEditIntegration` state with the selected fileEditIntegration.
   * @param {Object} event - The event object associated with the change event.
   * @param {FileList} event.target.files - The list of files selected in the input field.
   */
  const handleFileChange = ({ target: { files } }) => {
    if (files[0].type !== "application/pdf") {
      setErrorFileEditIntegration(true);
    } else {
      setErrorFileEditIntegration(false);
    }
    if (files[0].size > size) {
      setErrorFileSizeEditIntegration(true);
    } else {
      setErrorFileSizeEditIntegration(false);
    }
    setFileEditIntegration(files[0]);
  };

  /**
   * Closes the integration configuration process and resets the state.
   * This function performs several actions to reset the state of the integration configuration process:
   * 1. Sets the `userApi` state to an object with `username_validart` and `password_validart` from `userApiTemp`.
   * 2. Resets the `fileEditIntegration` state to an empty string.
   * 3. Sets the `errorFileEditIntegration` state to `false`.
   * 4. Sets the `errorFileSizeEditIntegration` state to `false`.
   * 5. Hides the modal by setting the `showModal` state to `false`.
   * 6. Sets the `changeFileEditIntegration` state to `true`.
   * 7. Sets the `shownEditIntegration` state to `false`.
   */
  const closeIntegrationConfig = () => {
    setUserApi({
      username_validart: userApiTemp.username_validart,
      password_validart: userApiTemp.password_validart,
    });
    setFileEditIntegration("")
    setErrorFileEditIntegration(false);
    setErrorFileSizeEditIntegration(false);
    setShowModal(false);
    setChangeFileEditIntegration(true);
    setShownEditIntegration(false);
  }

  /**
   * Handles the blur event for integration configuration.
   * This function updates the user API state with the trimmed value from the input field when it loses focus.
   * @param {Object} eventIntegrationConfig - The event object associated with the blur event.
   * @param {string} eventIntegrationConfig.target.name - The name of the input field.
   * @param {string} eventIntegrationConfig.target.value - The value of the input field.
   */
  const handleOnBlurIntegration = (eventIntegrationConfig) => {
    setUserApi({
      ...userApi,
      [eventIntegrationConfig.target.name]: eventIntegrationConfig.target.value.trim(),
    });
  };

  /**
   * Fetches configuration integration by ID.
   *
   * This function sets loading state to true, then sends a request to fetch configuration integration.
   * Upon receiving the response, it updates the user API state and fileEditIntegration reference with the retrieved data.
   * It also sets temporary states for user API and fileEditIntegration.
   * Finally, it sets loading state to false.
   * @return {void}
   */
  const getConfigIntegrationById = useCallback(() => {
    setIsLoadingEditIntegration(true);
    getConfigIntegration()
      .then((response) => {
        if (isNullOrUndefined(response.data.data) === false) {
          setUserApi({
            username_validart: response.data.data.username_validart,
            password_validart: response.data.data.password_validart,
          });
          setFileRefEditIntegration(response.data.data.communication_agreement);
          setUserApiTemp({
            username_validart: response.data.data.username_validart,
            password_validart: response.data.data.password_validart,
          });
          setFileTempEditIntegration(response.data.data.communication_agreement);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingEditIntegration(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the form submission for editing integration configuration.
   * This function validates form errors and checks if there are any changes in the configuration.
   * If there are no errors and there are changes in the configuration, it sends a request to update
   * the integration configuration. Upon successful update, it shows a success message and reloads the page.
   * If there's an error during the update, it shows a service error alert.
   * @param {Object} eventSubmitEditIntegration - The event object associated with the form submission.
   * @param {Array} errors - An array containing form validation errors.
   */
  const handleOnSubmit = (eventSubmitEditIntegration, errors) => {
    if (errors.length === 0) {
      if (
        userApi.username_validart === userApiTemp.username_validart &&
        userApi.password_validart === userApiTemp.password_validart &&
        fileRefEditIntegration === fileTempEditIntegration &&
        fileEditIntegration === ""
      ) {
        eventSubmitEditIntegration.preventDefault();
        return window.alert(i18n.t("integration.EditConfigWalert"));
      } else {
        setIsLoadingEditIntegration(true);
        const formData = new FormData();
        if (userApi.username_validart !== userApiTemp.username_validart) {
          formData.append("username_validart", userApi.username_validart);
        }
        if (userApi.password_validart !== userApiTemp.password_validart) {
          formData.append("password_validart", userApi.password_validart);
        }
        if (fileEditIntegration) {
          formData.append("communication_agreement", fileEditIntegration);
        }
        updateConfigIntegration(formData)
          .then((response) => {
            if (response.status === 202) {
              const showAlertConfig = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("integration.EditConfigSalert"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                  successMode: true,
                }).then(() => {
                  handleOnCloseIntegrationConfig();
                  window.location.reload();
                });
              };
              showAlertConfig();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingEditIntegration(false);
          });
      }
    }
  };

  /**
   * Renders different components based on the value of changeFileEditIntegration.
   * If changeFileEditIntegration is true, renders a link to the existing configuration fileEditIntegration and a button to edit it.
   * If changeFileEditIntegration is false, renders an input field to upload a new configuration fileEditIntegration.
   * @returns {JSX.Element} Component to render based on the value of changeFileEditIntegration.
   */
  if (changeFileEditIntegration === true) {
    componentRedered = (
      <Row>
        <Col>
          <div className="integration-text">
            {
              <FontAwesomeIcon
                icon={faFilePdf}
                className="mr-2 ml-auto integration-icon"
              />
            }
            &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={fileRefEditIntegration}
            >
              {i18n.t("integration.EditConfigFile")}
            </a>
          </div>
        </Col>
        <Col className="d-flex align-items-center">
          <Button
            type="button"
            color="primary"
            onClick={changeFileIntegration}
          >
            {i18n.t("integration.EditConfigButton")}
          </Button>
        </Col>
      </Row>
    )
  } else {
    componentRedered = (
      <AvGroup>
        <Label
          for="communication_agreement"
          className="is-required"
        >
          {i18n.t("integration.requestConfigCom")}
        </Label>

        <AvField
          name="communication_agreement"
          id="communication_agreement"
          type="file"
          onChange={handleFileChange}
          onBlur={handleOnBlurIntegration}
          accept="application/pdf"
          validate={{
            required: {
              value: true,
              errorMessage: `${i18n.t(
                "integration.requestConfigFileReq"
              )}`,
            },
          }}
          autoComplete="off"
        />
        <span
          className="integration-communication_agreement"
        >
          {i18n.t("integration.requestConfigFileAcc")}
        </span>
        {errorIntegrationFile}
        {errorIntegrationFileSize}
      </AvGroup>
    )
  }

  /**
   * useEffect hook to fetch configuration integration by ID.
   * This useEffect hook runs once when mounted or when `getConfigIntegrationById` changes.
   * It triggers the `getConfigIntegrationById` function to fetch configuration integration by ID.
   */
  useEffect(() => {
    getConfigIntegrationById();
  }, [getConfigIntegrationById]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="info"
              onClick={handleOnClick}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              <span>{i18n.t("integration.EditConfig")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModal}
          toggle={handleOnCloseIntegrationConfig}
          className="modal-custom"
        >
          <ModalHeader>
            <Label>{i18n.t("integration.EditConfigTitle")}</Label>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmit}>
              <Col md="12">
                <Alert className="mbg-3" color="info">
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  {i18n.t("integration.requestConfigAlert")}
                </Alert>
                <AvGroup>
                  <Label for="username_validart" className="is-required">
                    {i18n.t("integration.requestConfigUser")}
                  </Label>
                  <AvField
                    id="username_validart"
                    name="username_validart"
                    type="email"
                    onChange={handleOnChangeIntegration}
                    onBlur={handleOnBlurIntegration}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigUserReq"
                        )}`,
                      },
                      email: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigTipeEmail"
                        )}`,
                      },
                    }}
                    autoComplete="off"
                    value={userApi.username_validart}
                    placeholder={i18n.t("integration.EditConfigPlaceHolder")}
                  />
                </AvGroup>

                <AvGroup>
                  <Label for="password_validart" className="is-required">
                    {i18n.t("integration.requestConfigPass")}
                  </Label>
                  <Row>
                    <Col md={10}>
                      <AvField
                        id="password_validart"
                        name="password_validart"
                        type={showPassword}
                        onChange={handleOnChangeIntegration}
                        onBlur={handleOnBlurIntegration}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: `${i18n.t(
                              "integration.requestConfigPassReq"
                            )}`,
                          },
                        }}
                        autoComplete="new-password"
                        value={userApi.password_validart}
                      />
                    </Col>
                    <Col md={1}>
                      <Button
                        size="lg"
                        ClassName="btn-icon"
                        color="primary"
                        onClick={switchShown}
                      >
                        {showButtonPassword}
                      </Button>
                    </Col>
                  </Row>
                </AvGroup>
                {componentRedered}
              </Col>
              &nbsp;&nbsp;
              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={closeIntegrationConfig}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingEditIntegration}
                  className="col-mt-3 "
                  color="cyan"
                >
                  {loadingComponent}
                  {i18n.t("createusers.createButton3")}
                </Button>
              </CardFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditIntegrationConfig;
