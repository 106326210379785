import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Col, Card, CardBody, CardFooter, Alert } from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import FieldsListTable from "components/organism/FieldsListTable";
import ButtonBackListForms from "components/atoms/ButtonBackListForms";
import ButtonCreateFields from "components/atoms/ButtonCreateFields";
import Loader from "react-loaders";
import i18n from "locales/i18n";

const FieldLists = () => {
  const { id } = useParams();
  const {
    getFieldsBySearch,
    showTabs,
    setShowTabs,
    setFieldsForm,
    fields,
    pagination,
    isLoading,
    totalPages,
    setPagination,
    setTypeControl,
    hasRecords,
    editFields,
    count,
    isLoadingDeleteField,
  } = useFieldsRecordContext();
  let loaderDelete = null;
  let alertForEdit = null;
  let alertDeleteField = null;

  /**
   * Generates an alert component based on the values of 'editFields' and 'isLoading' props.
   * @param {boolean} editFields - Indicates whether editing fields is allowed.
   * @param {boolean} isLoading - Indicates whether data is currently loading.
   * @returns {JSX.Element | null} - Returns an Alert component or null based on the conditions.
   */
  if (editFields === false && isLoading === false) {
    alertForEdit = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          <span> {i18n.t("form.alertStatusEraser")}</span>
        </span>
      </Alert>
    );
  } else {
    alertForEdit = null;
  }

  /**
   * Renders an alert if specific conditions are met.
   * @param {boolean} isLoading - Indicates if the data is currently being loaded.
   * @param {Array} fields - The array of fields.
   * @param {boolean} hasRecords - Indicates if there are records present.
   * @returns {JSX.Element|null} The alert component or null.
   */
  if (isLoading === false && fields.length === 1 && hasRecords === true) {
    alertDeleteField = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          <span> {i18n.t("form.fields.config.alert")}</span>
        </span>
      </Alert>
    );
  } else {
    alertDeleteField = null;
  }

  /**
   * Conditional loader element generator for displaying a loading indicator when 'isLoadingDeleteField' is true.
   * This function generates a loader element with a loading spinner when 'isLoadingDeleteField' is true, and null when it's false.
   * The loader element is typically used to indicate that some deletion operation is in progress.
   * @param {boolean} isLoadingDeleteField - A boolean flag indicating whether a delete operation is in progress.
   * @returns {JSX.Element | null} - A loader element or null based on the 'isLoadingDeleteField' value.
   */
  if (isLoadingDeleteField === true) {
    loaderDelete = (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="primary" type="ball-pulse-rise" />
        </div>
      </div>
    );
  } else {
    loaderDelete = null;
  }

  /**
   * An useEffect hook to initialize and retrieve form fields and type controls for a specific identifier.
   * @param {string} id - The identifier used to search for and initialize form fields.
   * @param {function} getFieldsBySearch - The function responsible for retrieving form fields based on the provided identifier.
   * @param {function} setFieldsForm - A state setter function to initialize the form fields.
   * @param {function} setTypeControl - A state setter function to initialize the type controls.
   * @returns {void}
   */
  useEffect(() => {
    setFieldsForm([]);
    setTypeControl([]);
    getFieldsBySearch(id);
  }, [getFieldsBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderDelete}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <Col>
              <ButtonBackListForms />
            </Col>
            <ButtonCreateFields formId={id} setShowTabs={setShowTabs} />
          </CardFooter>

          <CardBody>
            {alertDeleteField}
            {alertForEdit}
            <FieldsListTable
              fields={fields}
              pagination={pagination}
              isLoading={isLoading}
              totalPages={totalPages}
              setPagination={setPagination}
              showTabs={showTabs}
              hasRecords={hasRecords}
              editFields={editFields}
              count={count}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FieldLists;
