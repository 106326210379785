import React, { useState, useEffect, useCallback, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Card, CardBody, CardFooter, Button, Spinner } from "reactstrap";
import { Loader } from "react-loaders";
import { useParams, Link } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvForm } from "availity-reactstrap-validation";
import { isNullOrUndefined } from "utils/validations";
import useForm from "hooks/useForm";
import useRecord from "hooks/useRecord";
import swal from "sweetalert";
import i18n from "locales/i18n";

import TextBox from "components/molecules/Fields/Strings/TextBox";
import TextArea from "components/molecules/Fields/Strings/TextArea";
import ComboBox from "components/molecules/Fields/Strings/ComboBox";
import Lookup from "components/molecules/Fields/Strings/Lookup";
import Label from "components/molecules/Fields/Strings/Label";
import NumericTextBox from "components/molecules/Fields/Integer/NumericTextBox";
import DatePicker from "components/molecules/Fields/Date/DatePicker";
import DateTimePicker from "components/molecules/Fields/DateTime/DateTimePicker";

const RecordEditData = () => {
  const { id, recordId } = useParams();
  const [formName, setFormName] = useState("");
  const [recordNumber, setRecordNumber] = useState("");
  const [dataFormEdited, setDataFormEdited] = useState();
  const [showExternalFormFillingProcess] = useState(false);
  const [isLoadingEditForm, setIsLoadingEditForm] = useState(false);
  const [isLoadingDataForm, setIsLoadingDataForm] = useState(false);
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false);
  const [fieldsStepConfig, setFieldsStepConfig] = useState([]);
  const [fieldValue, setFieldValue] = useState({});
  const [actionProcessConfig] = useState(0);
  const [formFields, setFormFields] = useState([]);
  const [headerFieldRequest] = useState({ header: "update_fields" });
  const [requiredDates, setRequiredDates] = useState({});
  const [validateRequiredPickers, setValidateRequiredPickers] = useState({});
  const {
    validationEditField,
    setValidationField,
    setValidationFieldDatePicker,
  } = useFieldsRecordContext();
  const { getFields, getFormsById } = useForm();
  const { getRecordsById, updateRecord } = useRecord();

  /**
   * Fetches form data based on a UUID and updates the associated form name state.
   * @param {string} id - The UUID used to identify the specific form.
   * @param {object} actionProcessConfig - Configuration object for the action process.
   * @param {function} setFormName - State setter function for updating the form name.
   * @returns {void}
   *
   */
  const getFormsByUuid = useCallback(() => {
    getFormsById(id, actionProcessConfig).then((response) => {
      const nameForm = response.data.name;
      if (isNullOrUndefined(nameForm) === false) {
        setFormName(nameForm);
      } else {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("error.data.submit"),
          icon: "error",
          dangerMode: true,
          button: i18n.t("modal.Done.footerButton"),
        });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches fields for a specific step, filters them based on certain criteria, and updates relevant states.
   * @param {string} id - The identifier of the step to retrieve fields for.
   * @param {object} actionProcessConfig - Configuration object for the action process.
   * @param {function} setFieldsStepConfig - State setter function for updating fields associated with the step.
   * @param {function} setRequiredDates - State setter function for updating required date fields.
   * @returns {void}
   */
  const getFieldsByStep = useCallback(() => {
    setIsLoadingEditForm(true);
    let pagination_field = { page: 1, per_page: 1000 };
    const { page, per_page } = pagination_field;
    getFields(page, per_page, id, actionProcessConfig)
      .then((response) => {
        const fieldsByForm = response.data.items;
        if (isNullOrUndefined(fieldsByForm) === false) {
          const newFieldByForm = fieldsByForm.filter(
            (field) => field.status === 1
          );
          setFieldsStepConfig(newFieldByForm);

          const requiredDateFields = fieldsByForm.filter((field) => {
            return (
              (field.control_data === 6 || field.control_data === 7) &&
              field.required
            );
          });

          const datesReuired = requiredDateFields.reduce(
            (datesResults, date) => {
              datesResults[date.uuid] = date.uuid;
              return datesResults;
            },
            {}
          );

          setRequiredDates(datesReuired);
        } else {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("error.data.submit"),
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
      .finally(() => {
        setIsLoadingEditForm(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches a specific record for a given step and updates relevant state values.
   * @param {string} id - The identifier of the record.
   * @param {string} recordId - The unique ID of the specific record.
   * @param {object} actionProcessConfig - Configuration object for the action process.
   * @param {function} setRecordNumber - State setter function for updating the record number.
   * @param {function} setFieldValue - State setter function for updating the field value.
   * @returns {void}
   */
  const getRecordByStep = useCallback(() => {
    setIsLoadingDataForm(true);
    getRecordsById(id, recordId, actionProcessConfig)
      .then((response) => {
        const recordData = response.data.record;
        const recordValue = response.data;
        if (isNullOrUndefined(recordData) === false) {
          setRecordNumber(recordData);
          setFieldValue(recordValue);
        } else {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("error.data.submit"),
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
      .finally(() => {
        setIsLoadingDataForm(true);
      });
  }, [id, recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles key press events for field submission, preventing the default behavior if the pressed key is Enter.
   * @param {object} enterEventFieldSubmit - The key press event object for the field submission.
   * @param {string} enterEventFieldSubmit.key - The key that was pressed (e.g., "Enter").
   * @returns {void}
   */
  const handleOnKeyPressFieldSubmit = (enterEventFieldSubmit) => {
    if (enterEventFieldSubmit.key === "Enter") {
      enterEventFieldSubmit.preventDefault();
    }
  };

  /**
   * Handles the change of a form field's value identified by its UUID and updates the formFields state accordingly.
   * @param {string} fieldUuid - The UUID of the field being modified.
   * @param {any} value - The new value to assign to the field.
   * @param {Array<Object>} formFields - The array of form field objects representing the current form state.
   * @param {function} setFormFields - State setter function for updating the form fields.
   * @returns {void}
   */
  const handleChangeField = (fieldUuid, value) => {
    const fieldIndex = formFields.findIndex(
      (field) => field.field_uuid === fieldUuid
    );
    if (fieldIndex !== -1) {
      setFormFields(
        formFields.map((field) => {
          if (field.field_uuid === fieldUuid) {
            return { field_uuid: fieldUuid, value, is_modified: true };
          } else {
            return field;
          }
        })
      );
    } else {
      setFormFields([
        ...formFields,
        { field_uuid: fieldUuid, value, is_modified: true },
      ]);
    }
  };

  /**
   * Retrieves an array of UUIDs for fields that require validation in the given step configuration.
   * This function filters fields based on specific criteria and returns an array of UUIDs for
   * fields that are in an active status, have control_data values of 6 or 7, and are marked as required.
   * @returns {string[]} An array of UUIDs for fields that require validation.
   * @returns {string[]} An array of UUIDs for fields requiring validation.
   */
  const getIdsToValidate = () => {
    return fieldsStepConfig
      .filter(
        (field) =>
          field.status === 1 &&
          (field.control_data === 6 || field.control_data === 7) &&
          field.required === true
      )
      .map((field) => field.uuid);
  };

  /**
   * Handles the submission of a form field configuration.
   * @param {Event} eventFieldSubmit - The submission event for the form field.
   * @param {Array} fieldErrors - An array containing errors associated with form fields.
   * @returns {void}
   */
  const handleSubmitSendConfigField = (eventFieldSubmit, fieldErrors) => {
    const idsToValidate = getIdsToValidate();

    const idsConValueNull = dataFormEdited
      .filter(
        (element) =>
          idsToValidate.includes(element.field_uuid) && element.value === null
      )
      .map((element) => element.field_uuid);

    if (idsConValueNull.length > 0) {
      setValidationFieldDatePicker({ required: true, id: idsConValueNull });
      eventFieldSubmit.preventDefault();
      return;
    }

    if (dataFormEdited.length === 0) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("form.alertWarning"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
      eventFieldSubmit.preventDefault();
      return;
    }

    if (Object.keys(validateRequiredPickers).length > 0) {
      eventFieldSubmit.preventDefault();
      return;
    }

    if (fieldErrors.length === 0) {
      const { header } = headerFieldRequest;
      const configuredStep = { [header]: dataFormEdited };
      setIsLoadingSubmitForm(true);

      updateRecord(configuredStep, id, recordId)
        .then((response) => {
          if (response.status === 202) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("editRecord.message"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location = `/record/detail/${recordId}/${id}`;
            });
          }
        })
        .catch((error) => {
          if (error) {
            setValidationField({
              id: Object.keys(error.response.data.data),
              unique: true,
            });
          }
        })
        .finally(() => {
          setIsLoadingSubmitForm(false);
        });
    }
  };

  /**
   * useEffect hook that manages the loading state while fetching form data and related records for editing.
   * @param {function} getFormsByUuid - Function to fetch form data using a UUID.
   * @param {function} getFieldsByStep - Function to fetch fields related to a form step.
   * @param {function} getRecordByStep - Function to fetch records associated with a form step.
   * @param {function} setIsLoadingEditForm - State setter function for toggling the edit form loading state.
   * @returns {void}
   */
  useEffect(() => {
    const promises = [getFormsByUuid(), getFieldsByStep(), getRecordByStep()];
    Promise.all(promises).finally(() => {});
  }, [getFormsByUuid, getFieldsByStep, getRecordByStep]);

  /**
   * A React useEffect hook that calculates and updates the required date pickers validation status.
   * This hook evaluates each date field in the provided formFields array and checks if its value is null
   * while also checking if it is marked as required in the requiredDates object. If a date field is found
   * to be both null and required, it is recorded in the mandatoryDates object for validation.
   * @param {Array} formFields - An array containing the date fields to be validated.
   * @param {Object} requiredDates - An object containing the UUIDs of the required date fields.
   * @param {function} setValidateRequiredPickers - State setter function for updating required pickers validation status.
   * @returns {void}
   */
  useEffect(() => {
    const mandatoryDates = {};
    formFields.forEach((date) => {
      if (date.value === null && requiredDates[date.field_uuid] !== undefined) {
        mandatoryDates[date.field_uuid] = date.field_uuid;
      }
    });
    setValidateRequiredPickers(mandatoryDates);
  }, [formFields, requiredDates]);

  /**
   * useEffect hook that processes field configurations and updates an edited form data state.
   * @param {Array} fieldsStepConfig - Array of field configuration objects for the current step.
   * @param {Array} formFields - Array of form field objects to be processed and updated.
   * @param {object} fieldValue - Object containing field values indexed by field UUID.
   * @param {function} setDataFormEdited - State setter function for updating the edited form data.
   * @returns {void}
   */
  useEffect(() => {
    const fieldsForm = fieldsStepConfig.map((fieldConfig) => {
      if (fieldConfig.uuid !== null && fieldConfig.uuid !== undefined) {
        let value = null;
        if (fieldConfig.control_data === 3) {
          if (
            fieldValue[fieldConfig.uuid] &&
            fieldValue[fieldConfig.uuid].uuid !== undefined
          ) {
            value = fieldValue[fieldConfig.uuid].uuid;
          }
        } else {
          value = fieldValue[fieldConfig.uuid];
        }

        return {
          field_uuid: fieldConfig.uuid,
          value: value,
        };
      }

      return null;
    });

    const validateFieldsEdited = new Set(
      formFields.map((item) => item.field_uuid)
    );

    const formEdited = formFields.concat(
      fieldsForm
        .filter((item) => !validateFieldsEdited.has(item.field_uuid))
        .map((item) => {
          if (item.value === undefined) {
            return {
              ...item,
              value: null,
              is_modified: false,
            };
          } else {
            return {
              ...item,
              is_modified: false,
            };
          }
        })
    );
    setDataFormEdited(formEdited);
  }, [formFields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <h5 className="text-info font-weight-bold">
              {formName} - {i18n.t("recordData8")}: {recordNumber}
            </h5>

            <div className="divider" />

            <Alert className="mbg-3" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("RecordForm.Alert")}
            </Alert>
            <br />
            {(() => {
              if (isLoadingEditForm === true && isLoadingDataForm === true) {
                return (
                  <div className="loader-wrapper d-flex justify-content-center align-items-center">
                    <Loader color="#0072BC" type="ball-pulse-rise" />
                  </div>
                );
              } else {
                return (
                  <AvForm onSubmit={handleSubmitSendConfigField}>
                    <div className="pl-5">
                      {fieldsStepConfig.map((fieldConfig) => {
                        switch (fieldConfig.control_data) {
                          case 1:
                            return (
                              <TextBox
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                unique={validationEditField.unique}
                                required={fieldConfig.required}
                                isPreview={false}
                                minLength={fieldConfig.len_min_chars}
                                maxLength={fieldConfig.len_max_chars}
                                handleOnChangeRecord={handleChangeField}
                                handleOnKeyPress={handleOnKeyPressFieldSubmit}
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 2:
                            return (
                              <TextArea
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                required={fieldConfig.required}
                                isPreview={false}
                                minLength={fieldConfig.len_min_chars}
                                maxLength={fieldConfig.len_max_chars}
                                handleOnChangeRecord={handleChangeField}
                                handleOnKeyPress={handleOnKeyPressFieldSubmit}
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 3:
                            return (
                              <ComboBox
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                required={fieldConfig.required}
                                isPreview={false}
                                isFilterForm={false}
                                itemLink={fieldConfig.item_link}
                                handleOnChangeRecord={handleChangeField}
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 4:
                            return (
                              <Label
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                              />
                            );
                          case 5:
                            return (
                              <NumericTextBox
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                unique={validationEditField.unique}
                                required={fieldConfig.required}
                                isPreview={false}
                                allowedNegative={fieldConfig.allowed_negative}
                                handleOnChangeRecord={handleChangeField}
                                handleOnKeyPress={handleOnKeyPressFieldSubmit}
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 6:
                            return (
                              <DatePicker
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                required={fieldConfig.required}
                                isPreview={false}
                                isFilterForm={false}
                                edit_manually={fieldConfig.edit_manually}
                                show_pass_dates={fieldConfig.show_pass_dates}
                                handleOnChangeRecord={handleChangeField}
                                showExternalFormFillingProcess={
                                  showExternalFormFillingProcess
                                }
                                validateRequiredPickers={
                                  validateRequiredPickers
                                }
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 7:
                            return (
                              <DateTimePicker
                                id={fieldConfig.uuid}
                                label={fieldConfig.label}
                                hide={false}
                                required={fieldConfig.required}
                                isPreview={false}
                                isFilterForm={false}
                                edit_manually={fieldConfig.edit_manually}
                                show_pass_dates={fieldConfig.show_pass_dates}
                                handleOnChangeRecord={handleChangeField}
                                showExternalFormFillingProcess={
                                  showExternalFormFillingProcess
                                }
                                validateRequiredPickers={
                                  validateRequiredPickers
                                }
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );
                          case 8:
                            return (
                              <Lookup
                                id={fieldConfig.uuid}
                                hide={false}
                                label={fieldConfig.label}
                                required={fieldConfig.required}
                                isPreview={false}
                                isEdit={true}
                                isFilterForm={false}
                                handleOnChangeRecord={handleChangeField}
                                handleOnKeyPress={handleOnKeyPressFieldSubmit}
                                formLookupName={fieldConfig.form_lookup_name}
                                formLookupFields={
                                  fieldConfig.form_lookup_fields
                                }
                                formUuid={fieldConfig.form_uuid}
                                value={fieldValue[fieldConfig.uuid]}
                              />
                            );

                          default:
                            return null;
                        }
                      })}
                    </div>
                    <CardFooter className="d-block text-right">
                      <Link to={`/record/detail/${recordId}/${id}`}>
                        <Button
                          size="lg"
                          disabled={isLoadingSubmitForm}
                          onClick={() => {
                            setFormFields([]);
                          }}
                          className="col-mt-3 mr-3"
                          color="gray"
                        >
                          {i18n.t("createusers.createButton2")}
                        </Button>
                      </Link>

                      <Button
                        type="submit"
                        size="lg"
                        disabled={isLoadingSubmitForm}
                        className="col-mt-3"
                        color="cyan"
                      >
                        {(() => {
                          if (isLoadingSubmitForm === true) {
                            return (
                              <Spinner
                                size="sm"
                                color="secondary"
                                type="grow"
                              />
                            );
                          }
                        })()}
                        {i18n.t("modal.Done.footerButton")}
                      </Button>
                    </CardFooter>
                  </AvForm>
                );
              }
            })()}
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default RecordEditData;
