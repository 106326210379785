import React, { Fragment } from "react";
import { useFilterContext } from "contextAPI/FilterContext";
import AdminTemplate from "components/templates/AdminTemplate";
import FilterFormsRecords from "components/organism/FilterFormsRecords";
import FilterFormsRecordsListTable from "components/organism/FilterFormsRecordsListTable";
import i18n from "locales/i18n";

const FilterForm = () => {
  const { records } = useFilterContext();
  let tableRecords = null;

  /**
   * Conditionally renders a table component based on the length of the 'records' array.
   * If the 'records' array contains items, it renders a table using the <FilterFormsRecordsListTable /> component.
   * If the 'records' array is empty, it renders a different component using <FilterFormsRecords />.
   * @param {Array} records - An array containing the records to be displayed in the table.
   * @returns {JSX.Element} The JSX element representing the rendered table component.
   */
  if (records.length > 0) {
    tableRecords = <FilterFormsRecordsListTable />;
  } else {
    tableRecords = <FilterFormsRecords requiredFields={true}/>;
  }
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("form.designerItem3")}
        subheading={i18n.t("form.designerListMotto")}
        icon="note2"
      >
        {tableRecords}
      </AdminTemplate>
    </Fragment>
  );
};

export default FilterForm;
