import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import i18n from "locales/i18n";
import { getDateFormat2 } from "utils/getDateFormat2";

const WorkflowDetailListTable = (props) => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={props.listWorkflows}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("createWorflow.Label3")],
                  accessor: "number_process",
                  Cell: (row) => (
                    <div className="d-flex justify-content-center">
                      <Link to={`/traceability/workflow/${row.original.uuid}/${props.recordUuid}`}>
                        <Button
                          block
                          outline
                          className="mb-2 mr-2 btn-pill btn-dashed"
                          color="info"
                        >
                          <span className="badge-pill text-bold">
                            {row.original.consecutive}
                          </span>
                        </Button>
                      </Link>
                    </div>
                  ),
                },
                {
                  Header: [i18n.t("createWorflow.Label1")],
                  accessor: "process_name",
                },
                {
                  Header: [i18n.t("createWorflow.Label4")],
                  accessor: "created_at",

                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <p>{valueFinal}</p>;
                  },
                },

                {
                  Header: [i18n.t("form.designerLabel3")],
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-warning">
                          {i18n.t("createWorflow.Label3.2")}
                        </div>
                      );
                    } else if (value === 2) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("createWorflow.Label3.3")}
                        </div>
                      );
                    } else if (value === 3) {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("createWorflow.Label3.4")}
                        </div>
                      );
                    }
                    return <span>{statusFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("createWorflow.Label5")],
                  accessor: "sender_username",
                },
                {
                  Header: [i18n.t("createWorflow.Label6")],
                  accessor: "end_date",

                  Cell: ({ value }) => {
                    if (value === "0000-00-00T00:00:00+00:00") {
                      return null;
                    } else {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <p>{valueFinal}</p>;
                    }
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={props.totalPages}
          page={props.pagination.page - 1}
          showPageJump={false}
          defaultPageSize={props.listWorkflows.length}
          loading={props.isLoading}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            props.setPagination({ ...props.pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            props.setPagination({ ...props.pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkflowDetailListTable.propTypes = {
  listWorkflows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default WorkflowDetailListTable;
