import { useCallback, useContext, useState } from "react";
import loginService from "services/loginService";
import Context from "contextAPI/UserContext";
import { useHistory } from "react-router-dom";
import {
  ACCESS_TOKEN_NAME,
  USER_DETAIL,
  REFRESH_TOKEN_NAME,
  DOMAIN,
  TYPE,
  ADD_REGISTER,
  EDIT_REGISTER,
  VIEW_REGISTER,
  UPLOAD_FILES,
  CREATE_EXPEDIENT,
  SET_PATH_EXPEDIENT,
  DELETE_PATH_EXPEDIENT,
  VIEW_FILES,
  EDIT_FILE_DATA,
  MOVE_FILE,
  MOVE_FILE_TO_TRASH,
  SIGN_ELECTRONICALLY,
  NOTIFICATIONS,
  INTEGRATIONS,
  ROLES_PERMISSIONS,
  CANCEL_RECORD,
  ADD_WORKFLOW,
  VIEW_WORKFLOW,
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
  PASSWORD_EXPIRATION_DAYS,
  WORKFLOW_FORMS_PERMISSIONS,
  WORKFLOW_ALL_FORMS,
} from "constants/securityConst";
import { enumsPermissions } from "utils/enums";
import swal from "sweetalert";
import i18n from "locales/i18n";

export default function useLogin() {
  let history = useHistory();
  const { jwt, setJwt, setUser, state, setState } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Asynchronous function that performs user login with provided credentials and manages related state and local storage.
   * @function
   * @name login
   * @param {string} userName - The username entered by the user.
   * @param {string} password - The password entered by the user.
   * @param {function} setIsLoading - State setter function to update loading state.
   * @param {function} setJwt - State setter function to update the JWT token.
   * @param {function} setUser - State setter function to update user details.
   * @param {object} setState - State setter function to update component state.
   * @param {object} i18n - Object providing internationalization functionality.
   * @returns {void}
   */
  const login = useCallback(
    (userName, password) => {
      if (userName === "" && password === "") {
      } else {
        setIsLoading(true);
        loginService(userName, password)
          .then((response) => {
            let err = false;
            if (response.access) {
              window.localStorage.setItem(ACCESS_TOKEN_NAME, response.access);
              window.localStorage.setItem(REFRESH_TOKEN_NAME, response.refresh);
              window.localStorage.setItem(DOMAIN, response.user.domain);
              window.localStorage.setItem(TYPE, response.user.user_type);

              const register = response.form_permissions[enumsPermissions.ADD_RECORD];
              window.localStorage.setItem(ADD_REGISTER, JSON.stringify(register));

              const editRegister = response.form_permissions[enumsPermissions.EDIT_RECORDS];
              window.localStorage.setItem(EDIT_REGISTER, JSON.stringify(editRegister));

              const viewRegister = response.form_permissions[enumsPermissions.VIEW_RECORD];
              window.localStorage.setItem(VIEW_REGISTER, JSON.stringify(viewRegister));

              const uploadFiles = response.form_permissions[enumsPermissions.UPLOAD_FILE];
              window.localStorage.setItem(UPLOAD_FILES, JSON.stringify(uploadFiles));

              const createExpedient = response.form_permissions[enumsPermissions.CREATE_EXPEDIENT];
              window.localStorage.setItem(CREATE_EXPEDIENT, JSON.stringify(createExpedient));

              const setPathExpedient = response.form_permissions[enumsPermissions.SET_EXPEDIENT];
              window.localStorage.setItem(SET_PATH_EXPEDIENT, JSON.stringify(setPathExpedient));

              const deletePathExpedient =
                response.form_permissions[enumsPermissions.REMOVE_EXPEDIENT];
              window.localStorage.setItem(
                DELETE_PATH_EXPEDIENT,
                JSON.stringify(deletePathExpedient)
              );

              const viewFiles = response.form_permissions[enumsPermissions.VIEW_FILE];
              window.localStorage.setItem(VIEW_FILES, JSON.stringify(viewFiles));

              const editFileData = response.form_permissions[enumsPermissions.EDIT_FILE_DATA];
              window.localStorage.setItem(EDIT_FILE_DATA, JSON.stringify(editFileData));

              const moveFile = response.form_permissions[enumsPermissions.MOVE_FILE];
              window.localStorage.setItem(MOVE_FILE, JSON.stringify(moveFile));

              const moveFileToTrash =
                response.form_permissions[enumsPermissions.MOVE_FILE_TO_TRASH];
              window.localStorage.setItem(MOVE_FILE_TO_TRASH, JSON.stringify(moveFileToTrash));

              const signElectronically =
                response.form_permissions[enumsPermissions.SIGN_ELECTRONICALLY];
              window.localStorage.setItem(SIGN_ELECTRONICALLY, JSON.stringify(signElectronically));

              const notifications = response.form_permissions[enumsPermissions.ADD_NOTIFICATION];
              window.localStorage.setItem(NOTIFICATIONS, JSON.stringify(notifications));

              const addWorkflow = response.form_permissions[enumsPermissions.ADD_PROCESS];
              window.localStorage.setItem(ADD_WORKFLOW, JSON.stringify(addWorkflow));

              const viewWorkflow = response.form_permissions[enumsPermissions.VIEW_PROCESS];
              window.localStorage.setItem(VIEW_WORKFLOW, JSON.stringify(viewWorkflow));

              const cancelRecords = response.form_permissions[enumsPermissions.CANCEL_RECORD];
              window.localStorage.setItem(CANCEL_RECORD, JSON.stringify(cancelRecords));

              const userToSave = response.user;
              window.localStorage.setItem(USER_DETAIL, JSON.stringify(userToSave));

              const integrationsOption = response.integrations;
              window.localStorage.setItem(INTEGRATIONS, JSON.stringify(integrationsOption));

              const roleOption = response.roles_permissions;
              window.localStorage.setItem(ROLES_PERMISSIONS, JSON.stringify(roleOption));

              const formsDesignerOption = response.admin_forms.all_forms;
              window.localStorage.setItem(DESIGNER_FORMS, formsDesignerOption);

              const formsDesignerPermissions = response.admin_forms.forms_uuid;
              window.localStorage.setItem(DESIGNER_FORMS_PERMISSIONS, formsDesignerPermissions);

              const workflowAdminForms = response.admin_workflows.all_workflows;
              window.localStorage.setItem(WORKFLOW_ALL_FORMS, workflowAdminForms);

              const workflowsPermissions = response.admin_workflows.workflows_uuid;
              window.localStorage.setItem(WORKFLOW_FORMS_PERMISSIONS, workflowsPermissions);

              const passwordExpirationDays = response.user.password_expiration_days;
              window.localStorage.setItem(PASSWORD_EXPIRATION_DAYS, passwordExpirationDays);

              setJwt(response.access);
              setUser(userToSave);

              if (passwordExpirationDays === 0) {
                window.location.href = "/renew-password";
              }
            } else {
              err = true;
              setState({ loading: false, error: err, countError: 0 });
            }
          })
          .catch((err) => {
            window.localStorage.removeItem(ACCESS_TOKEN_NAME);
            window.localStorage.removeItem(USER_DETAIL);
            setState({
              loading: false,
              error: true,
              countError: state.countError,
            });
            if (err.response.data.code === "10024") {
              const showAlertLoginError1 = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("10024"),
                  icon: "info",
                  button: i18n.t("modal.Done.footerButton"),
                });
              };
              showAlertLoginError1();
            } else {
              const showAlertLoginError = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("modal.Error.body"),
                  icon: "error",
                  button: i18n.t("modal.Done.footerButton"),
                  dangerMode: true,
                });
              };
              showAlertLoginError();
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [setJwt] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Removes various items from local storage related to user session and preferences,
   * clears JWT token and user data, and redirects to the home page ("/").
   * @param {function} setJwt - State setter function to clear the JWT token.
   * @returns {void}
   */
  const logout = useCallback(() => {
    window.localStorage.clear();
    setJwt(null);
    setUser({});
    history.push("/");
  }, [setJwt]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    jwt,
    isLogged: Boolean(jwt),
    isLoginLoading: state.loading,
    hasLoginError: Boolean(state.error),
    hasLoginCount: state.countError,
    isLoading,
    login,
    state,
    logout,
  };
}
