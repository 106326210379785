import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import swal from "sweetalert"
import useTrd from "hooks/useTrd";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Container,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import {
  numberLevel
} from "utils/trdLevelsNameEdition";
import { enumEditSubSectionForm, enumLevelTrd } from "utils/enums";
import { regexEditSubSectionCode, regexEditSubSectionName } from "utils/regexExpressions";
import { showAlertServiceError } from "utils/alerts";
import useEditLevelTrd from "hooks/useEditLevelTrd";
import { trimExtraSpaces } from "utils/trimSpaces";
import ReactTooltip from "react-tooltip";
import i18n from "locales/i18n";

const EditSubSectionForm = () => {
  const {
    trdForm,
    setTrdForm,
    setShowFolderCondition,
    setShowEditSubSectionForm,
    getTreeByDomainCallback,
    editSubSectionFormService,
    setEditSubSectionFormService,
    setLevelFolder,
    setSelectLevel,
    setIsLoading,
    isLoading
  } = useTrdContext();

  const { updateSubSection } = useTrd()
  const { trdLevelInformation } = useEditLevelTrd(numberLevel.SUBSECTION);
  const [tempSection, setTempSection] = useState({
    name: "",
    code: "",
  })

  const [keysTrdEdit] = useState([
    "name",
    "code",
    "parent_id",
    "background_parent_id"
  ])

  /**
   * Resets the form for a subsection. 
   * @returns {void}
   */
  const resetSubSectionForm = () => {
    setTrdForm({
      ...trdForm,
      name: "",
      level: numberLevel.SUBSECTION
    });
    setLevelFolder(numberLevel.SUBSECTION)
    setSelectLevel(numberLevel.SUBSECTION)
  };

  /**
   * Cancels the editing of a subsection by resetting the form and updating state. 
   * This function performs the following actions:
   * - Updates the `trdForm` state with a new `level` of 2.
   * - Sets the `showFolderCondition` state to `true`.
   * - Sets the `showEditSubSectionForm` state to `false`.
   * - Resets the `trdForm` state to `resetSubSectionForm`.
   * @returns {void}
   */
  const cancelEditSubSection = () => {
    setTrdForm({
      ...trdForm,
      level: enumLevelTrd.SUBSECTION,
    });
    setShowFolderCondition(true);
    setShowEditSubSectionForm(false);
    setTrdForm(resetSubSectionForm);
  };

  /**
   * Handles the change event for the sub-section form fields and updates the state accordingly.
   * @function
   * @param {Event} eventOnchange - The change event object triggered by the form field.
   * @returns {void}
   */
  const handleOnChangeSubSection = (eventOnchange) => {
    setEditSubSectionFormService({
      ...editSubSectionFormService,
      [eventOnchange.target.name]: eventOnchange.target.value,
    });
  };

  /**
   * Handles the blur event for the sub-section form fields and updates the state accordingly.
   * @function
   * @param {Event} eventOnBlurSubSection - The blur event object triggered by the form field.
   * @returns {void}
   */
  const handleOnBlurSubSection = (eventOnBlurSubSection) => {
    const { value, name } = eventOnBlurSubSection.target;
		const trimmedValue = trimExtraSpaces(value);
    setEditSubSectionFormService({
      ...editSubSectionFormService,
      [name]: trimmedValue,
    });
  };

  /**
   * Displays a success alert message using SweetAlert when a sub-section is updated.
   * @function
   * @returns {void}
   */
  const alertUpdateSubSection = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trd.fromLabel5"),
      icon: "success",
      button: i18n.t("modal.Done.footerButton"),
    })
  }

  /**
   * Handles the submit event for updating a subsection. Prevents the default form submission, 
   * checks for errors, compares the current form state with the temporary section state, 
   * and updates the subsection if there are changes.
   * @param {Object} eventSubmit - The submit event object.
   * @param {Event} eventSubmit.preventDefault - Method to prevent the default form submission.
   * @param {Array} errors - An array of error messages.
   */
  const handleOnSubmitSubSection = (eventSubmit, errors) => {
    eventSubmit.preventDefault();
    if (errors.length === 0) {
      const updatedSectionTrd = keysTrdEdit.reduce((acomulatorField, fieldKey) => {
        if (editSubSectionFormService[fieldKey] !== tempSection[fieldKey]) {
          acomulatorField[fieldKey] = editSubSectionFormService[fieldKey];
        }
        return acomulatorField;
      }, {});

      if (Object.keys(updatedSectionTrd).length === 2) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        })
        return
      }

      setIsLoading(true)
      updateSubSection(updatedSectionTrd, trdLevelInformation.trdId)
        .then(response => {
          if (response.status === 202) {
            setTrdForm({
              ...trdForm,
              name: "",
              level: numberLevel.SUBSECTION,
              code: editSubSectionFormService.code
            });
            setLevelFolder(numberLevel.SUBSECTION);
            setSelectLevel(numberLevel.SUBSECTION);
            setShowFolderCondition(true);
            setShowEditSubSectionForm(false);
            alertUpdateSubSection();
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => { setIsLoading(false) })
    }
  }

  /**
   * Handles the `onKeyPress` event for a subsection.
   * This function:
   * - Checks if the key pressed is "Enter".
   * - If the "Enter" key is pressed, it prevents the default behavior of the event.
   * @param {KeyboardEvent} eventOnkeyPressSubSection - The keyboard event object containing information about the key press.
   * @returns {void}
   */
  const handleOnKeyPressSubSection = (eventOnkeyPressSubSection) => {
    if (eventOnkeyPressSubSection.key === "Enter") {
      eventOnkeyPressSubSection.preventDefault();
    }
  };


  /**
   * React hook that runs once when the component is mounted.
   * It updates the state of `editSubSectionFormService` and `tempSection` with specific values from other states.
   * @function
   * @returns {void}
   */
  useEffect(() => {
    setEditSubSectionFormService({
      ...editSubSectionFormService,
      name: trdLevelInformation.name.trim(),
      code: trdForm.code,
      parent_id: trdLevelInformation.parentId,
      background_parent_id: trdLevelInformation.backgroundParentId

    })
    setTempSection({
      ...tempSection,
      name: trdLevelInformation.name,
      code: trdForm.code
    })
    // eslint-disable-next-line 
  }, [])

  /**
   * React effect that runs when `getTreeByDomainCallback` changes.
   * This effect:
   * - Calls the `getTreeByDomainCallback` function whenever `getTreeByDomainCallback` changes.
   * @function
   * @param {Function} getTreeByDomainCallback - Callback function to fetch or update the tree data based on the domain. 
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <CardTitle>{i18n.t("trd.formTittle35")}</CardTitle>
          <AvForm onSubmit={handleOnSubmitSubSection}>
            <AvGroup>
              <ReactTooltip id="code" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip7")}
              </ReactTooltip>
              <Label for="code" className="is-required">
                {i18n.t("trd.fromLabel1")}
              </Label>
              <AvField
                id="code"
                name="code"
                type="text"
                onChange={handleOnChangeSubSection}
                onKeyPress={handleOnKeyPressSubSection}
                onBlur={handleOnBlurSubSection}
                validate={{
                  pattern: {
                    value: regexEditSubSectionCode,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.messageRequiredCodeSection")}`,
                  },
                  minLength: {
                    value: enumEditSubSectionForm.MIN_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumEditSubSectionForm.MAX_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="code"
                autoFocus={true}
                autoComplete="off"
                value={editSubSectionFormService.code}
              />
            </AvGroup>
            <AvGroup>
              <ReactTooltip
                id="name_element"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip8")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.formTittle36")}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeSubSection}
                onKeyPress={handleOnKeyPressSubSection}
                onBlur={handleOnBlurSubSection}
                validate={{
                  pattern: {
                    value: regexEditSubSectionName,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.messageRequiredNameSection")}`,
                  },
                  minLength: {
                    value: enumEditSubSectionForm.MIN_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumEditSubSectionForm.MAX_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="name_element"
                autoComplete="off"
                required
                value={editSubSectionFormService.name}
              />
            </AvGroup>

            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={cancelEditSubSection}
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                className="col-mt-3"
                disabled={isLoading}
                color="cyan"
              >
                {i18n.t("trd.buttonUptade")}
              </Button>
            </CardFooter>
          </AvForm>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditSubSectionForm;
