import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  AvForm,
  AvGroup,
  AvField,
  AvInput,
} from "availity-reactstrap-validation";
import { Col, Card, CardHeader, CustomInput, Label } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { enumFieldForm, enumsFieldsTyData } from "utils/enums";
import { useListContext } from "contextAPI/ListsContext";
import { regexFieldFormLabel } from "utils/regexExpressions";
import LabelValidation from "components/molecules/Fields/Validation/LabelValidation";
import i18n from "locales/i18n";

const FieldForm = () => {
  const { handleOnChange, fieldForm, setFieldForm } = useFieldsRecordContext();
  const { setCanNext } = useListContext();

  /**
   * Determines whether the given field should be rendered based on its type and control data.
   * @param {object} fieldForm - The field configuration object to be evaluated.
   * @returns {boolean} - Indicates whether the field should be rendered.
   */
  let typeFieldRender = false;
  if (
    (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING ||
      fieldForm.type_data === enumsFieldsTyData.INTEGER ||
      fieldForm.type_data === enumsFieldsTyData.DATE ||
      fieldForm.type_data === enumsFieldsTyData.TIME_AND_DATE) &&
    fieldForm.control_data !== enumsFieldsTyData.TIME_AND_DATE
  ) {
    typeFieldRender = true;
  } else {
    typeFieldRender = false;
  }

  /**
   * Handles the key press event to prevent form submission on Enter key press.
   * This function listens for the Enter key press event and prevents the default
   * behavior, which is to submit the form. This is useful in scenarios where
   * form submission should be controlled manually.
   * @param {Object} eventPressField - The event object generated by the key press.
   * @param {string} eventPressField.key - The key that was pressed.
   * @param {Function} eventPressField.preventDefault - Function to prevent the default action.
   * @returns {void}
   */
  const handleOnKeyPress = (eventPressField) => {
    if (eventPressField.key === "Enter") {
      eventPressField.preventDefault();
    }
  };

  /**
   * Handles the blur event for a form field.
   * This function updates the `fieldForm` state with the trimmed value of the field
   * that triggered the blur event. It uses the field's name attribute to identify
   * which part of the state to update.
   * @param {Object} eventField - The event object generated by the blur event.
   * @param {Object} eventField.target - The element that triggered the event.
   * @param {string} eventField.target.name - The name of the form input field being updated.
   * @param {string} eventField.target.value - The value entered into the form input field.
   * @returns {void}
   */
  const handleOnBlurField = (eventField) => {
    setFieldForm({
      ...fieldForm,
      [eventField.target.name]: eventField.target.value.trim(),
    });
  };

  /**
   * Configures the field name based on the 'typeFieldRender' flag.
   * Generates an input field for configuring the label of the form field.
   * @param {boolean} typeFieldRender - Flag indicating whether to render the field name configuration.
   * @param {function} handleOnChange - Event handler for input changes.
   * @param {object} fieldForm - The form field's configuration data.
   * @returns {JSX.Element|null} - JSX element containing the configured field name input, or null if not rendering.
   */
  let fieldNameConfig = null;
  if (typeFieldRender === true) {
    fieldNameConfig = (
      <div>
        <AvGroup row>
          <Label for="label" className="is-required" sm={3}>
            {i18n.t("form.fieldtable2")}
          </Label>
          <Col md={10}>
            <AvField
              id="label"
              name="label"
              type="text"
              onChange={handleOnChange}
              onKeyPress={handleOnKeyPress}
              onBlur={handleOnBlurField}
              validate={{
                required: {
                  value: true,
                  errorMessage: `${i18n.t("fieldRequired")}`,
                },
                pattern: {
                  value: regexFieldFormLabel,
                  errorMessage: `${i18n.t("form.fieldFeedback2")}`,
                },
                minLength: {
                  value: enumFieldForm.MIN_LENGTH_LABEL,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
                maxLength: {
                  value: enumFieldForm.MAX_LENGTH_LABEL,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
              }}
              autoFocus={true}
              autoComplete="off"
              value={fieldForm.label}
            />
          </Col>
        </AvGroup>
      </div>
    );
  } else {
    fieldNameConfig = null;
  }

  /**
   * Renders a set of form fields based on the value of 'typeFieldRender' for validation in a field configured.
   * This function conditionally generates form fields, including checkboxes, based on the 'typeFieldRender' value.
   * When 'typeFieldRender' is true, it creates checkbox fields for 'hide', 'required', and 'search_field'. If 'typeFieldRender' is false, it renders a custom label-based component called 'LabelValidation'.
   * @param {boolean} typeFieldRender - Indicates whether to render checkbox fields or a custom label-based component.
   * @param {function} handleOnChange - Event handler function for input value changes.
   * @param {object} fieldForm - Object containing the form field properties.
   * @param {string} i18n.t - Translation function for localizing text.
   * @returns {JSX.Element} - JSX element representing the generated form fields or custom label component.
   */
  let checkValidationField = null;
  if (typeFieldRender === true) {
    checkValidationField = (
      <div>
        <AvGroup row>
          <Col sm={12}>
            <AvInput
              id="hide"
              name="hide"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required
              disabled={fieldForm.required}
              label={i18n.t("form.field39")}
              errorMessage={i18n.t("form.fieldFeedback3")}
              value={fieldForm.hide}
            />
          </Col>
        </AvGroup>
        <AvGroup row>
          <Col sm={12}>
            <AvInput
              id="required"
              name="required"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              disabled={fieldForm.hide}
              required
              label={i18n.t("form.field36")}
              errorMessage={i18n.t("form.fieldFeedback3")}
              value={fieldForm.required}
            />
          </Col>
        </AvGroup>
        <AvGroup row>
          <Col sm={12}>
            <AvInput
              id="search_field"
              name="search_field"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required
              label={i18n.t("form.field37")}
              errorMessage={i18n.t("form.fieldFeedback3")}
              value={fieldForm.search_field}
            />
          </Col>
        </AvGroup>
      </div>
    );
  } else {
    checkValidationField = <LabelValidation />;
  }

  /**
   * useEffect hook that updates the 'canNext' state based on the presence of a label in the 'fieldForm' object.
   * @param {object} fieldForm - The object containing the form field data.
   * @param {boolean} canNext - The state indicating whether the form can proceed to the next step.
   * @param {function} setCanNext - State setter function for updating the 'canNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (
      !fieldForm.label ||
      fieldForm.label.length < enumFieldForm.MIN_LENGTH_LABEL ||
      fieldForm.label.length > enumFieldForm.MAX_LENGTH_LABEL ||
      !regexFieldFormLabel.test(fieldForm.label)
    ) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [fieldForm.label]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="forms-fields">
          <AvForm>
            <CardHeader className="mb-4">{i18n.t("form.field3.1")}</CardHeader>
            {fieldNameConfig}
            {checkValidationField}
          </AvForm>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FieldForm;
