import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ReactTable from "react-table";
import { Alert, CardTitle } from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import Loader from "react-loaders";
import i18n from "locales/i18n";
import renderIconFile from "utils/renderIconFile";

const TasksFilesListTable = (props) => {
  const { id } = props;
  const {
    getFilesTasksById,
    isLoadingFiles,
    files,
    isLoading,
  } = useWorkflowContext();
  const [pageSizeToShow, setPageSizeToShow] = useState([2]);

  /**
   * Determines the number of rows to display in the table.
   * If the `workflowsTable` is empty, it defaults to showing 1 row.
   * Otherwise, it sets the number of rows to the length of `workflowsTable`.
   * @param {Object[]} workflowsTable - The array of workflow data objects.
   * @returns {number} The number of rows to display in the table.
   */
  function getPageSize(files) {
    if (files.length === 0) {
      return [2];
    }
    return files.length;
  };

  /**
   * Effect hook to update the number of rows to display in the table.
   * It sets `pageSizeToShow` based on the length of `files`.
   * @param {Object[]} files - The array of form data objects.
   */
  useEffect(() => {
    setPageSizeToShow(getPageSize(files));
  }, [files]);

  /**
   * useEffect hook that invokes the 'getFilesTasksById' function with the provided ID when the dependency changes.
   * @param {string} id - The ID used to retrieve files or tasks.
   * @param {function} getFilesTasksById - The function responsible for fetching files or tasks by ID.
   * @returns {void}
   */
  useEffect(() => {
    getFilesTasksById(id);
  }, [getFilesTasksById]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditionally renders a loading spinner, an alert, or nothing based on the loading state and the presence of files.
   * @param {boolean} isLoadingFiles - A boolean indicating whether the files are currently loading.
   * @param {boolean} isLoading - A boolean indicating whether other content is currently loading.
   * @param {Array} files - An array representing the list of files.
   * @returns {JSX.Element|null} - A JSX element representing a loader, an alert if there are no files, or `null` if neither condition is met.
   */
  if (isLoadingFiles === true || isLoading === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="secondary" type="ball-pulse-rise" />
      </div>
    );
  } else if (files.length === 0) {
    return (
      <Alert className="block" color="info">
        {i18n.t("taskDetailFiles")}
      </Alert>
    );
  };

  return (
    <Fragment>
      <CardTitle className="align-items-left ">
        {i18n.t("recordDetail.files")}
      </CardTitle>
      <ReactTable
        className="table-files-style p-0"
        data={files}
        columns={[
          {
            columns: [
              {
                accessor: "name",
                Cell: (row) => (
                  <div>
                    {renderIconFile(row)}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${row.original.presigned_url}`}
                    >
                      {row.value}
                    </a>
                  </div>
                ),
              },
            ],
          },
        ]}
        manual
        pageSize={pageSizeToShow}
        showPaginationBottom={false}
        noDataText={null}
      />
    </Fragment>
  );
};

TasksFilesListTable.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TasksFilesListTable;
