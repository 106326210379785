import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Button, FormGroup, Label, Input, Form } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  defaultStickerPosition,
  fontOptions,
  fontSizeOptions,
} from "utils/stickerInitialPosition";
import { regexNumericField } from "utils/regexExpressions";
import { enumsMaxMinFieldsCoordsSticker } from "utils/enums";
import imagePointReference1 from "assets/images/image_points_reference.png";
import imagePointReference2 from "assets/images/image_points_reference2.png";
import i18n from "locales/i18n";

const ConfigPositionSticker = (props) => {
  const {
    initialPositionSticker,
    setInitialPositionSticker,
    setShowConfigPosition,
    localFontFamily,
    setLocalFontFamily,
    localFontSize,
    setLocalFontSize,
  } = props;
  const [buttonPressed, setButtonPressed] = useState(null);

  /**
   * Handle input change for updating initial position sticker data.
   * @param {number} index - Index of the sticker in the array.
   * @param {string} columnId - Identifier for the column in the sticker data object.
   * @param {string} value - New value to update in the specified column.
   */
  const handleInputChange = (index, columnId, value) => {
    setInitialPositionSticker((prevPositions) => {
      const newData = prevPositions.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [columnId]: parseInt(value, 10) || 0,
          };
        }
        return item;
      });
      return newData;
    });
  };

  /**
   * Handles the change of font selection.
   * @param {object} eventFontChange - The event object triggered by font change.
   * @param {string} eventFontChange.target.value - The selected font value.
   */
  const handleFontChange = (eventFontChange) => {
    const selectedFont = eventFontChange.target.value;
    setLocalFontFamily(selectedFont);
  };

  /**
   * Handles the change in font size.
   * @param {Object} eventFontSizeChange - The event object representing the font size change.
   * @param {string} eventFontSizeChange.target.value - The value of the selected font size.
   */
  const handleFontSizeChange = (eventFontSizeChange) => {
    const selectedFontSize = parseInt(eventFontSizeChange.target.value, 10);
    setLocalFontSize(selectedFontSize);
  };

  /**
   * Saves configuration changes to local storage and hides the configuration panel.
   * @returns {void}
   */
  const handleApplyChanges = () => {
    localStorage.setItem(
      "positionData",
      JSON.stringify(initialPositionSticker)
    );
    localStorage.setItem("fontFamily", localFontFamily);
    localStorage.setItem("fontSize", localFontSize.toString());
    setShowConfigPosition(false);
  };

  /**
   * Clears local storage data and resets state variables to their default values.
   * @param {function} setInitialPositionSticker - Function to set the initial position of the sticker.
   * @param {function} setLocalFontFamily - Function to set the local font family.
   * @param {function} setLocalFontSize - Function to set the local font size.
   * @param {Array} fontOptions - Array of font options where the first option will be used to reset the font family.
   * @param {Array} fontSizeOptions - Array of font size options where the first option will be used to reset the font size.
   */
  const handleClearData = () => {
    setInitialPositionSticker(defaultStickerPosition);
    setLocalFontFamily(fontOptions[0].value);
    setLocalFontSize(fontSizeOptions[0].value);
    localStorage.removeItem("positionData");
    localStorage.removeItem("fontFamily");
    localStorage.removeItem("fontSize");
  };

  /**
   * Handles the click event on the link to go back, resetting the position configuration.
   * @returns {void}
   */
  const handleLinkClickBack = () => {
    setShowConfigPosition(false);
  };

  /**
   * Handles incrementing a specific field in the initial position sticker data.
   * @param {number} index - The index of the sticker data array to update.
   * @param {string} field - The field within the sticker data object to increment.
   */
  const handleIncrement = (index, field) => {
    setInitialPositionSticker((prevPositions) => {
      const newData = prevPositions.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: item[field] + 1,
          };
        }
        return item;
      });
      return newData;
    });
  };

  /**
   * Decrements the value of a specified field in the initialPositionSticker state array.
   * @param {number} index - The index of the element in initialPositionSticker array.
   * @param {string} field - The field name whose value is to be decremented.
   */
  const handleDecrement = (index, field) => {
    setInitialPositionSticker((prevPositions) => {
      const newData = prevPositions.map((item, idx) => {
        if (idx === index && item[field] > 0) {
          return {
            ...item,
            [field]: item[field] - 1,
          };
        }
        return item;
      });
      return newData;
    });
  };

  /**
   * Starts the increment process for a specific field at a given index.
   * Sets the button pressed state and triggers the increment handler.
   * @param {number} index - The index of the item to increment.
   * @param {string} field - The field to increment.
   * @returns {void}
   */
  const startIncrement = (index, field) => {
    setButtonPressed({ index, field, direction: "up" });
    handleIncrement(index, field);
  };

  /**
   * Stops the increment operation by resetting the button pressed state.
   * @returns {void}
   */
  const stopIncrement = () => {
    setButtonPressed(null);
  };

  /**
   * Sets the button pressed state and handles decrement for a specific index and field.
   * @param {number} index - The index of the element to decrement.
   * @param {string} field - The field associated with the element to decrement.
   * @returns {void}
   */
  const startDecrement = (index, field) => {
    setButtonPressed({ index, field, direction: "down" });
    handleDecrement(index, field);
  };

  /**
   * Stops the decrement action by resetting the button state.
   * @function stopDecrement
   * @returns {void}
   */
  const stopDecrement = () => {
    setButtonPressed(null);
  };

  /**
   * Executes a callback function repeatedly at a specified interval when `buttonPressed` state is true,
   * and clears the interval when `buttonPressed` state becomes false.
   * @param {Object} buttonPressed - The state object indicating which button is pressed and its direction.
   * @param {boolean} buttonPressed.direction - The direction of the button press ("up" or "down").
   * @param {number} buttonPressed.index - The index related to the pressed button.
   * @param {string} buttonPressed.field - The field associated with the pressed button.
   * @param {any} initialPositionSticker - Additional dependency that triggers effect re-execution.
   */
  useEffect(() => {
    let interval = null;
    if (buttonPressed) {
      interval = setInterval(() => {
        if (buttonPressed.direction === "up") {
          handleIncrement(buttonPressed.index, buttonPressed.field);
        } else if (buttonPressed.direction === "down") {
          handleDecrement(buttonPressed.index, buttonPressed.field);
        }
      }, 100);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [buttonPressed, initialPositionSticker]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="6" className="mt-2">
            <div className="mt-2 mb-2">
              <h6 className="mb-4">
                <button className="link-button" onClick={handleLinkClickBack}>
                  {i18n.t("sticker.ConfigBack")}
                </button>{" "}
                {i18n.t("sticker.ConfigBackTo")}
              </h6>
              <Button color="secondary" onClick={handleClearData}>
                {i18n.t("sticker.ConfigRestoreButton")}
              </Button>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      className="text-left text-cyan heading-title-table "
                      colSpan="3"
                    >
                      <h5>{i18n.t("verticalNav.tittle2")}</h5>
                    </th>
                  </tr>
                  <tr>
                    <th>{i18n.t("sticker.ConfigTableHeaders1")}</th>
                    <th>{i18n.t("sticker.ConfigTableHeaders2")}</th>
                    <th>{i18n.t("sticker.ConfigTableHeaders3")}</th>
                  </tr>
                </thead>
                <tbody>
                  {initialPositionSticker.map((item, index) => (
                    <tr key={index}>
                      <td className="text-left">{item.number}</td>
                      <td className="text-center">
                        <div className="number-input ">
                          <Input
                            id={`left-${item.id}`}
                            type="text"
                            className="form-control"
                            value={item.left}
                            onChange={(eventCoordChangeX) =>
                              handleInputChange(
                                index,
                                "left",
                                eventCoordChangeX.target.value
                              )
                            }
                            minLength={
                              enumsMaxMinFieldsCoordsSticker.MIN_LENGTH
                            }
                            maxLength={
                              enumsMaxMinFieldsCoordsSticker.MAX_LENGTH
                            }
                            pattern={regexNumericField}
                          />
                          <button
                            className="up"
                            onMouseDown={() => startIncrement(index, "left")}
                            onMouseUp={stopIncrement}
                            onMouseLeave={stopIncrement}
                          >
                            <FontAwesomeIcon icon={faAngleUp} />
                          </button>
                          <button
                            className="down"
                            onMouseDown={() => startDecrement(index, "left")}
                            onMouseUp={stopDecrement}
                            onMouseLeave={stopDecrement}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="number-input ">
                          <Input
                            id={`top-${item.id}`}
                            type="text"
                            className="form-control"
                            value={item.top}
                            onChange={(eventCoordChangeY) => {
                              handleInputChange(
                                index,
                                "top",
                                eventCoordChangeY.target.value
                              );
                            }}
                            minLength={
                              enumsMaxMinFieldsCoordsSticker.MIN_LENGTH
                            }
                            maxLength={
                              enumsMaxMinFieldsCoordsSticker.MAX_LENGTH
                            }
                            pattern={regexNumericField}
                          />
                          <button
                            className="up"
                            onMouseDown={() => startIncrement(index, "top")}
                            onMouseUp={stopIncrement}
                            onMouseLeave={stopIncrement}
                          >
                            <FontAwesomeIcon icon={faAngleUp} />
                          </button>
                          <button
                            className="down"
                            onMouseDown={() => startDecrement(index, "top")}
                            onMouseUp={stopDecrement}
                            onMouseLeave={stopDecrement}
                          >
                            <FontAwesomeIcon icon={faAngleDown} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <Form className="mt-4">
              <FormGroup row>
                <Label for="fontFamilySelect" sm={4}>
                  {i18n.t("sticker.ConfigTableFont")}
                </Label>
                <Col sm={8}>
                  <Input
                    id="fontFamilySelect"
                    name="fontFamily"
                    type="select"
                    value={localFontFamily}
                    onChange={handleFontChange}
                  >
                    {fontOptions.map((font) => (
                      <option key={font.id} value={font.value}>
                        {font.value}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="fontSizeSelect" sm={4}>
                  {i18n.t("sticker.ConfigTableFontSize")}
                </Label>
                <Col sm={8}>
                  <Input
                    id="fontSizeSelect"
                    name="fontSize"
                    type="select"
                    value={localFontSize}
                    onChange={handleFontSizeChange}
                  >
                    {fontSizeOptions.map((size) => (
                      <option key={size.id} value={size.value}>
                        {size.value}
                      </option>
                    ))}
                  </Input>
                </Col>
              </FormGroup>
            </Form>
            <Button className="mt-4" color="cyan" onClick={handleApplyChanges}>
              {i18n.t("sticker.ConfigApplyChangesButton")}
            </Button>
          </Col>
          <Col md="6">
            <img
              alt="reference"
              src={imagePointReference1}
              className="d-block ml-4 reduced-image"
            />
            <img
              alt="reference2"
              src={imagePointReference2}
              className="d-block ml-4 mt-2 reduced-image"
            />
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ConfigPositionSticker.propTypes = {
  initialPositionSticker: PropTypes.array.isRequired,
  setInitialPositionSticker: PropTypes.func.isRequired,
  setShowConfigPosition: PropTypes.func.isRequired,
  localFontFamily: PropTypes.string.isRequired,
  setLocalFontFamily: PropTypes.func.isRequired,
  localFontSize: PropTypes.number.isRequired,
  setLocalFontSize: PropTypes.func.isRequired,
};

export default ConfigPositionSticker;
