import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
  CardBody,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import useClassification from "hooks/useClassification";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import { trimExtraSpaces } from "utils/trimSpaces";

const CreateDirectoryForm = (props) => {

  const {
    idSubDivision
  } = props;

  const {
    classificationRequest,
    setIsLoading,
    isLoading,
  } = useClassification();

  const { id, backgroundId } = useParams();

  const {
    createDirectoryForm,
    setCreateDirectoryForm,
    setOption,
    setOptionEdit,
    setSeieSubserieId,
    setSeieSubserieBackgroundId,
    createDirectoryFormSTR,
    setCreateDirectoryFormSTR,
    validateField,
    setValidateField,
    setSubDivisionId,
  } = useTrdContext();

  let requiredNameInput = null;
  if (validateField === true) {
    requiredNameInput = true;
  } else {
    requiredNameInput = false;
  }

  /**
   * Handle the change eventCreateDirectory of the input fields.
   * @param {eventCreateDirectory} eventCreateDirectory - The change eventCreateDirectory.
   */

  const handleOnChangeCreateDirectory = (eventCreateDirectory) => {
    setCreateDirectoryForm({
      ...createDirectoryForm,
      [eventCreateDirectory.target.name]: eventCreateDirectory.target.value,
    });
    setCreateDirectoryFormSTR({
      ...createDirectoryFormSTR,
      serie_subserie_uuid: id,
      directory_struct: [eventCreateDirectory.target.value],
    });
    setSeieSubserieId(id);
    setSeieSubserieBackgroundId(backgroundId);
    setSubDivisionId(idSubDivision);
  };

  /**
   * Handle the key press eventCreateDirectory.
   * @param {eventCreateDirectory} eventCreateDirectory - The key press eventCreateDirectory.
   */

  const handleOnKeyPressCreateDirectoryForm = (eventCreateDirectory) => {
    if (eventCreateDirectory.key === "Enter") {
      eventCreateDirectory.preventDefault();
    }
  };

  /**
   * Handle the blur eventCreateDirectory of the input fields.
   * @param {eventCreateDirectory} eventCreateDirectory - The blur eventCreateDirectory.
   */
  const handleOnBlurCreateDirectory = (eventCreateDirectory) => {
    const { value, name } = eventCreateDirectory.target;
    const trimmedValue = trimExtraSpaces(value);
    setCreateDirectoryForm({
      ...createDirectoryForm,
      [name]: trimmedValue,
    });
  };

  /**
   * Handle the form submission.
   * @param {eventCreateDirectory} eventCreateDirectory - The submit eventCreateDirectory.
   * @param {Array} errors - The array of validation errors.
   */

  const handleOnSubmitCreateDirectory = (eventCreateDirectory, errors) => {
    if (errors.length > 0) {
      eventCreateDirectory.preventDefault();
      setValidateField(true);
      setIsLoading(false);
      return;
    }

    if (validateField === true) {
      setIsLoading(true);
      classificationRequest(7, createDirectoryFormSTR);
    }
  };

  /**
   * Handle the click eventCreateDirectory of the directory form button.
   */

  const onClickDirectoryForm = () => {
    setOption(true);
    setOptionEdit(false);
    setCreateDirectoryFormSTR({
      ...createDirectoryFormSTR,
      serie_subserie_uuid: "",
      directory_struct: [""],
      level: 7,
    });
    setCreateDirectoryForm({
      ...createDirectoryForm,
      name: "",
      level: 7,
    });
  }

  /**
   * Handle the click eventCreateDirectory of the view directory form button.
   */

  const onClickViewDirectoryForm = () => {
    setValidateField(true);
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <CardTitle>{i18n.t("trd.formTittle8.1")}</CardTitle>
          <AvForm onSubmit={handleOnSubmitCreateDirectory}>
            <ReactTooltip
              id="name_directory"
              place="bottom"
              type="dark"
              effect="solid"
            >
              {i18n.t("trd.Tooltip4")}
            </ReactTooltip>
            <AvGroup>
              <Label for="name" className="is-required">
                {i18n.t("trd.fromLabel2")}
              </Label>
              <AvField
                onChange={handleOnChangeCreateDirectory}
                onKeyPress={handleOnKeyPressCreateDirectoryForm}
                onBlur={handleOnBlurCreateDirectory}
                type="text"
                name="name"
                id="name"
                data-tip
                data-for="name_directory"
                validate={{
                  pattern: {
                    value: "/^([A-Za-z0-9 À-ÿ-_]+)$/",
                    errorMessage: `${i18n.t("invalidField2")}`,
                  },
                  required: {
                    value: requiredNameInput,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: 4,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: 100,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                autoComplete="off"
                placeholder={i18n.t("trd.inputTittle6")}
                value={createDirectoryForm.name}
              />
            </AvGroup>

            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={onClickDirectoryForm}
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                disabled={isLoading}
                className="col-mt-3"
                color="cyan"
                onClick={onClickViewDirectoryForm}
              >

                {(() => {
                  if (isLoading === true) {
                    return <Spinner size="sm" color="secondary" type="grow" />
                  }
                })()}
                {" "}
                {i18n.t("createusers.createButton")}
              </Button>
            </CardFooter>
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

CreateDirectoryForm.propTypes = {
  idSubDivision: PropTypes.number.isRequired,
};


export default CreateDirectoryForm;
