import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserLogs from "components/organism/UserLogs";
import i18n from "locales/i18n";

const UserLog = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("administratorHeading")}
				subheading={i18n.t("administratorSubHeading")}
				icon="users"
			>
				<UserLogs />
			</AdminTemplate>
		</Fragment>
	);
};

export default UserLog;
