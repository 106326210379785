import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { getDateFormat } from "utils/getDateFormat";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const TrdExpedientConsultationFilesTable = (props) => {
  const {
    fileList,
    pagination,
    isLoadingFilesExpedients,
    totalPages,
    setPagination,
  } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={fileList}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("recordFile.name")],
                  id: "link",
                  accessor: "name",
                  Cell: ({ row }) => {
                    return (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${row._original.presigned_url}`}
                      >
                        {row.link}
                      </a>
                    );
                  },
                },
                {
                  Header: [i18n.t("record.affair")],
                  accessor: "subject",
                },
                {
                  Header: [i18n.t("record.supportType")],
                  id: "support_type",
                  accessor: "support_type",
                  Cell: ({ value }) => {
                    let supportType = "";
                    if (value === "2") {
                      supportType = i18n.t("uploadFile.fileSupport1");
                    } else {
                      supportType = i18n.t("uploadFile.fileSupport2");
                    }
                    return <p>{supportType}</p>;
                  },
                },
                {
                  Header: [i18n.t("record.dateDocument")],
                  accessor: "publication_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat(new Date(value));

                    return <p>{valueFinal}</p>;
                  },
                },
                {
                  Header: [i18n.t("recordFile.publishDate")],
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat(new Date(value));

                    return <p>{valueFinal}</p>;
                  },
                },
                {
                  Header: [i18n.t("createusers.label1")],
                  accessor: "user_name",
                },
                {
                  Header: [i18n.t("trd.formTittle16")],
                  accessor: "level",
                  /**
                   *Renders the cell content based on the provided 'row' object for level value equal to six.
                   *@param {object} row - The row object containing the data for the current cell.
                   *@param {number} row.value - The level value of the cell.
                   *@param {string} row.original.level_name - The name of the directory associated with the cell.
                   *@param {string} row.original.parent_name - The name of the subdirectory associated with the cell.
                   *@returns {JSX.Element|null} The JSX element representing the content of the cell, or null if the level value is 5.
                   */
                  Cell: (row) => {
                    const level = row.value;
                    const levelNameDirectory = row.original.level_name;
                    const levelNameSubDirectory = row.original.parent_name;

                    switch (level) {
                      case 5:
                        return null;
                      case 6:
                        return <span>{levelNameDirectory}</span>;

                      case 7:
                        return <span>{levelNameSubDirectory}</span>;

                      default:
                        break;
                    }
                  },
                },
                {
                  Header: [i18n.t("trd.formTittle44")],
                  accessor: "level",
                  /**
                   *Renders the content for a cell in a table based on the provided row data for level equal to seven.
                   *@param {object} row - the row object containing data for the current cell
                   *@param {number} row.value - the value representing the level of the cell
                   *@param {string} row.original.level_name - the name of the subdirectory associated with the cell
                   *@returns {JSX.Element|null} - The JSX element to be rendered for the cell, or null if the level is 5 or 6.
                   */
                  Cell: (row) => {
                    const level = row.value;
                    const levelNameSubDirectory = row.original.level_name;

                    switch (level) {
                      case 5:
                        return null;
                      case 6:
                        return null;

                      case 7:
                        return <span>{levelNameSubDirectory}</span>;

                      default:
                        break;
                    }
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight table-no-border "
          pages={totalPages}
          page={pagination.page - 1}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          loading={isLoadingFilesExpedients}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPagination({
              ...pagination,
              per_page: per_page,
            });
          }}
          onPageChange={(page) => {
            setPagination({
              ...pagination,
              page: page + 1,
            });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrdExpedientConsultationFilesTable.propTypes = {
  fileList: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingFilesExpedients: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default TrdExpedientConsultationFilesTable;
