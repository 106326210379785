import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Card, CardFooter, Alert, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { showAlertServiceError } from "utils/alerts";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import UserWorkGroupMembersTable from "components/organism/UserWorkGroupMembersTable";
import ButtonBackListWorkGroup from "components/atoms/ButtonBackListWorkGroup";
import ModalAddMembersToWorkGroup from "components/molecules/ModalAddMembersToWorkGroup";
import SearchBox from "components/atoms/SearchBox";
import UseUser from "hooks/useUser";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserWorkGroupMembersList = () => {
  const { id, statusGroup } = useParams();
  const { getMembersOfWorkGroup, deleteMemberOfWorkGroup } = UseUser();
  const [searchMemberWorkgroup, setSearchMemberWorkgroup] = useState("");
  const [listMembers, setListMembers] = useState([]);
  const [isLoadingMemberList, setIsLoadingMemberList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [listWorkflows, setListWorkflows] = useState([])
  const [totalPages, setTotalPages] = useState(0);
  const [isMinimumGroupMembers, setIsMinimumGroupMembers] = useState(false);
  const [countMembersWorkGroup, setCountMembersWorkgroup] = useState(0);
  const [disabledDeleteWorkGroup, setDisabledDeleteWorkGroup] = useState(null);

  const limitNumberOfMembers = Number(process.env.REACT_APP_MAXIMUM_MEMBERS) - 1;
  /**
   * Retrieves members of a work group.
   * @function
   * @param {string} id - The ID of the work group.
   * @param {number} page - The page number.
   * @param {number} perPage - The number of items per page.
   * @param {string} searchMemberWorkgroup - The searchMemberWorkgroup criteria.
   * @returns {Promise} A Promise that resolves to the group members data.
   */
  const getMemberOfGroupBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingMemberList(true);
    getMembersOfWorkGroup(id, page, per_page, searchMemberWorkgroup)
      .then((groupMembers) => {
        if (groupMembers.data !== undefined && groupMembers.data !== null) {
          setCountMembersWorkgroup(groupMembers.data.data.count)
          setListMembers(groupMembers.data.data.items);
          setTotalPages(groupMembers.data.data.pages);
          if (groupMembers.data.data.items.length === 0) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("create.work.group.members.alert3"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            });
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setIsLoadingMemberList(false));
  }, [pagination, searchMemberWorkgroup, getMembersOfWorkGroup, id]);

  /**
   * Generates an alert component based on whether the number of group members meets a minimum requirement.
   * @param {boolean} isMinimumGroupMembers - A boolean value indicating whether the number of group members meets the minimum requirement.
   * @param {Object} i18n - An internationalization object used for language localization.
   * @returns {JSX.Element|null} - The generated alert component or null if the number of group members meets the minimum requirement.
   */
  let alertIsMinimumGroupMembers = null;
  if (isMinimumGroupMembers === true) {
    alertIsMinimumGroupMembers = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold">
            {i18n.t("modal.DoneError.header")}
          </span>
          <span className="ml-2"></span>
          {i18n.t("create.work.group.members.label16")}
        </span>
      </Alert>
    )
  } else {
    alertIsMinimumGroupMembers = null;
  }

  /**
   * Generates an alert component based on the status deleting a workgroup is disabled.
   * @param {boolean} disabledDeleteWorkGroup - A boolean value indicating whether deleting a workgroup is disabled.
   * @param {Object} i18n - An internationalization object used for language localization.
   * @returns {JSX.Element|null} - The generated alert component or null if deleting the workgroup is not disabled.
   */
  let alertDisabledDeleteWorkGroup = null;
  if (disabledDeleteWorkGroup === true) {
    alertDisabledDeleteWorkGroup = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold ">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold">
            {i18n.t("modal.DoneError.header")}
          </span>
          <span className="ml-2"></span>
          {i18n.t("create.work.group.members.label16")}
        </span>
      </Alert>
    )
  } else {
    alertDisabledDeleteWorkGroup = null;
  }

  /**
   * Generates an alert component based on the count of members in a workgroup.
   *
   * @param {number} countMembersWorkGroup - The count of members in the workgroup.
   * @param {number} limitNumberOfMembers - The maximum number of members
   * @param {Object} i18n - An internationalization object used for language localization.
   * @returns {JSX.Element|null} - The generated alert component or null if the count of members is not greater than 49.
   */
  let alertCountMembersWorkGroup = null;
  if (countMembersWorkGroup > limitNumberOfMembers) {
    alertCountMembersWorkGroup = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold ">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold">
            {i18n.t("modal.DoneError.header")}
          </span>
          <span className="ml-2"></span>
          {i18n.t("create.work.group.members.label20")}
        </span>
      </Alert>
    )
  } else {
    alertCountMembersWorkGroup = null;
  }

  /**
   * Generates an alert component based on the presence of elements in the list of workflows.
   *
   * @param {Array} listWorkflows - The list of workflows to be displayed in the alert.
   * @param {Object} i18n - An internationalization object used for language localization.
   * @returns {JSX.Element|null} - The generated alert component or null if the list of workflows is empty or undefined.
   */
  let alertListWorkflows = null;
  if (listWorkflows !== undefined && listWorkflows.length > 0) {
    alertListWorkflows = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          <span className="font-weight-bold">
            {i18n.t("modal.DoneError.header")}
          </span>
          <br /><br />
          {i18n.t("create.work.group.members.label10")}
          {":"}{" ( "}
          {listWorkflows !== undefined && listWorkflows.length > 0 && listWorkflows.map(({ workflow_name, step_name }, index) => {
            let isLastIndex = index === listWorkflows.length - 1;
            let comma = "";
            if (isLastIndex !== true) {
              comma = ",";
            }
            return (
              <span>{i18n.t("create.work.group.members.label8")} {workflow_name} - {i18n.t("create.work.group.members.label9")} {step_name} {" "}{comma}{" "}</span>
            )
          })}
          {")"}{" "}
          {i18n.t("create.work.group.members.label11")}
        </span>
      </Alert>
    )
  } else {
    alertListWorkflows = null;
  }

  /**
 * Executes the effect to retrieve members of a group based on searchMemberWorkgroup criteria.
 * @param {object} pagination - the component pagination object
 * @param {string} searchMemberWorkgroup - the string of the searchMemberWorkgroup 
 * @return {void}
 */

  useEffect(() => {
    getMemberOfGroupBySearch();
    // eslint-disable-next-line
  }, [pagination, searchMemberWorkgroup]);

  /**
   * If the number of members in the work group is less than or equal to 2
   * and the status of the group is 2, disable the delete work group functionality.
   * Otherwise, enable it.
   *
   * @param {number} countMembersWorkGroup - The number of members in the work group.
   * @param {number} statusGroup - The status of the group.
   * @returns {void}
   */

  useEffect(() => {
    const timeLimitedWorkGroup = setTimeout(() => {
      if (Number(countMembersWorkGroup) <= 2 && Number(statusGroup) === 2) {
        setDisabledDeleteWorkGroup(true);
      } else {
        setDisabledDeleteWorkGroup(false);
      }
    }, 1000);
    return () => clearTimeout(timeLimitedWorkGroup); // Clear the timer when the effect is cleaned up
  }, [countMembersWorkGroup, statusGroup]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchMemberWorkgroup}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonBackListWorkGroup className="m-2" />
            <ModalAddMembersToWorkGroup
              idWorkGroup={id}
              countMembersWorkGroup={countMembersWorkGroup}
              statusGroup={statusGroup}
            />
          </CardFooter>
          <CardBody>
            {alertIsMinimumGroupMembers}
            {alertDisabledDeleteWorkGroup}
            {alertCountMembersWorkGroup}
            {alertListWorkflows}
            <UserWorkGroupMembersTable
              listMembers={listMembers}
              pagination={pagination}
              isLoading={isLoadingMemberList}
              totalPages={totalPages}
              setPagination={setPagination}
              setIsLoading={setIsLoadingMemberList}
              deleteMemberOfWorkGroup={deleteMemberOfWorkGroup}
              setListWorkflows={setListWorkflows}
              setIsMinimumGroupMembers={setIsMinimumGroupMembers}
              disabledDeleteWorkGroup={disabledDeleteWorkGroup}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserWorkGroupMembersList;