import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import SignaturesList from "components/organism/SignaturesList";
import i18n from "locales/i18n";

const ReportElectronicSignatures = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("info.SignatureNav1")}
        subheading={i18n.t("info.SignatureMotto")}
        icon="note2"
      >
        <SignaturesList />
      </AdminTemplate>
    </Fragment>
  );
};

export default ReportElectronicSignatures;
