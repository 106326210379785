import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import DocumentaryTypesCreate from "components/organism/DocumentaryTypesCreate";
import DocumentaryTypesList from "components/organism/DocumentaryTypesList";
import { useDocumentaryContext } from "contextAPI/DocumentaryTypesContext"

const DocumentaryTypes = () => {
	const {
		isCreate
	} = useDocumentaryContext();

	return (
		<Fragment>
			<AdminTemplate
				heading={isCreate ? i18n.t("documentary.type.heading.create") : i18n.t("documentary.type.heading")}
				subheading={i18n.t("documentary.type.subHeading")}
				icon="note2"
			>
				{isCreate ? (
					<DocumentaryTypesCreate />
				) : (
					<DocumentaryTypesList />
				)}
			</AdminTemplate>
		</Fragment>
	);
};

export default DocumentaryTypes;