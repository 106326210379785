import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, CardTitle, Label, CardFooter, Container, Spinner } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import {
  numberLevel
} from "utils/trdLevelsNameEdition";
import { trimExtraSpaces } from "utils/trimSpaces";
import { showAlertServiceError } from "utils/alerts";
import { enumEditSerieForm } from "utils/enums";
import { regexEditSerieFormCode, regexEditSerieFormName } from "utils/regexExpressions";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import useTrd from "hooks/useTrd";
import useEditLevelTrd from "hooks/useEditLevelTrd";
import swal from "sweetalert";

const EditSubSerieForm = () => {
  const { updateSubSerie } = useTrd()
  const { trdLevelInformation } = useEditLevelTrd(numberLevel.SUBSERIE);
  const [keysTrdEdit] = useState([
    "name",
    "code",
    "parent_id",
    "background_parent_id"
  ])
  const [tempSerie, setTempSerie] = useState({
    name: "",
    code: "",
  })
  const {
    trdForm,
    setTrdForm,
    setShowFolderCondition,
    setShowEditSerieForm,
    setLevelFolder,
    setSelectLevel,
    editSerieFormService,
    setEditSerieFormService,
    getTreeByDomainCallback,
    isLoading,
    setIsLoading
  } = useTrdContext();

  let loadingSpinnerEditSubSerie = null;

  /**
   * Conditionally renders a loading spinner based on the value of `isLoading`. 
   * This code:
   * - Checks if `isLoading` is true.
   * - If true, sets `loadingSpinnerEditSubSerie` to a `Spinner` component with specific properties.
   * @param {boolean} isLoading - Flag indicating whether data is currently loading.
   * @returns {JSX.Element | null} The rendered `Spinner` component if `isLoading` is true, otherwise null.
   */
  if (isLoading === true) {
    loadingSpinnerEditSubSerie = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Resets the subseries related state values.
   * This function sets the `trdForm` state with modified properties, resets the `level` of `trdForm` to `numberLevel.SUBSERIE`,
   * and updates other relevant state variables (`levelFolder` and `selectLevel`) to `numberLevel.SUBSERIE`.
   * @function
   * @returns {void}
   */
  const resetSubSerie = () => {
    setTrdForm({
      ...trdForm,
      name: "",
      level: numberLevel.SUBSERIE
    });
    setLevelFolder(numberLevel.SUBSERIE)
    setSelectLevel(numberLevel.SUBSERIE)
  };

  /**
   * Closes the edit subseries form and resets related state values.
   * This function updates the `trdForm` state by setting its `level` property to `numberLevel.SUBSERIE`.
   * It also shows the folder condition, hides the edit series form, and invokes the `resetSubSerie` function to reset
   * additional related state values.
   * @returns {void}
   */
  const closeEditSubSerie = () => {
    setTrdForm({
      ...trdForm,
      level: numberLevel.SUBSERIE,
    });
    setShowFolderCondition(true);
    setShowEditSerieForm(false);
    setTrdForm(resetSubSerie);
  }

  /**
   * Handles the change event for the subseries form inputs.
   * This function updates the `editSerieFormService` state by spreading its existing properties and modifying the property
   * specified by the event's target name with the corresponding value from the event.
   * @param {Object} eventOnchange - The event object triggered by the form input's change event.
   * @returns {void}
   */
  const handleOnChangeSubSerie = (eventOnchange) => {
    setEditSerieFormService({
      ...editSerieFormService,
      [eventOnchange.target.name]: eventOnchange.target.value,
    });
  };

  /**
   * Handles the key press event for the edit series form.
   * This function checks if the key pressed is the "Enter" key and prevents the default behavior if it is.
   * This is typically used to prevent form submission when the user presses "Enter" in an input field.
   * @param {Object} eventOnKeyPressSerie - The event object triggered by the key press event.
   * @returns {void}
   */
  const handleOnKeyPressEditSerie = (eventOnKeyPressSerie) => {
    if (eventOnKeyPressSerie.key === "Enter") {
      eventOnKeyPressSerie.preventDefault();
    }
  };

/**
 * Handles the blur event for the edit series form inputs.
 * This function updates the `editSerieFormService` state by spreading its existing properties and modifying the property
 * specified by the event's target name with the corresponding trimmed value from the event.
 * The value is trimmed to remove any leading and trailing whitespace.
 * @param {Object} eventOnBlurEditSerie - The event object triggered by the blur event on the form input.
 * @returns {void}
 */
  const handleOnBlurEditSerie = (eventOnBlurEditSerie) => {
    const { value, name } = eventOnBlurEditSerie.target;
		const trimmedValue = trimExtraSpaces(value);
    setEditSerieFormService({
      ...editSerieFormService,
      [name]: trimmedValue,
    });
  };

  /**
   * Handles the form submission for editing a subseries.
   * This function is triggered when the user submits the edit series form. It prevents the default form submission behavior
   * (page reload) using `eventSubmitEditSerie.preventDefault()`. It then checks if there are any validation errors (`errors`)
   * before proceeding with the update of the subseries.
   * If there are no errors, the function creates an object `updatedSubSerieTrd` by comparing the fields in `editSerieFormService`
   * with the corresponding fields in `tempSerie`. Any fields that have changed are added to `updatedSubSerieTrd`.
   * @param {Object} eventSubmitEditSerie - The event object triggered by the form submission.
   * @param {Array} errors - An array of validation errors (if any) from the form inputs.
   * @returns {void}
   */
  const handleOnSubmitEditSerie = (eventSubmitEditSerie, errors) => {
    eventSubmitEditSerie.preventDefault();
    if (errors.length === 0) {
      const updatedSubSerieTrd = keysTrdEdit.reduce((acomulatorField, fieldKey) => {
        if (editSerieFormService[fieldKey] !== tempSerie[fieldKey]) {
          acomulatorField[fieldKey] = editSerieFormService[fieldKey];
        }
        return acomulatorField;
      }, {});

      if (Object.keys(updatedSubSerieTrd).length === 2) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        })
        return
      }
      setIsLoading(true)
      updateSubSerie(trdLevelInformation.trdId, updatedSubSerieTrd)
        .then(response => {
          if (response.status === 202) {
            setTrdForm({
              ...trdForm,
              name: "",
              level: numberLevel.SUBSERIE,
              code: editSerieFormService.code
            });
            setLevelFolder(numberLevel.SUBSERIE)
            setSelectLevel(numberLevel.SUBSERIE)
            setShowFolderCondition(true);
            setShowEditSerieForm(false)
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trd.formTittle41"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            })
          } else {
            showAlertServiceError();
          }
        })
        .catch(error => {
          if (error.response.data.code === "20004") {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trd.formTittle42"),
              icon: "error",
              button: i18n.t("modal.Done.footerButton"),
              dangerMode: true,
            })
          }
        })
        .finally(() => { setIsLoading(false) })
    }
  }

  /**
   * Perform side effects when the component mounts.
   * Set initial values for state variables `editSerieFormService` and `tempSerie` based on `trdLevelInformation` and `trdForm`.
   * @returns {void}
   */

  useEffect(() => {
    setEditSerieFormService({
      ...editSerieFormService,
      name: trdLevelInformation.name,
      code: trdForm.code,
      parent_id: trdLevelInformation.parentId,
      background_parent_id: trdLevelInformation.backgroundParentId

    })

    setTempSerie({
      ...tempSerie,
      name: trdLevelInformation.name,
      code: trdForm.code
    })
    // eslint-disable-next-line 
  }, [])

  /**
   * Perform side effects when the component mounts and when `getTreeByDomainCallback` changes.
   * Calls the `getTreeByDomainCallback` function.
   * @returns {void}
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]); // eslint-disable-line 

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <CardTitle>{i18n.t("trd.formTittle40")}</CardTitle>
          <AvForm onSubmit={handleOnSubmitEditSerie}>
            <AvGroup>
              <ReactTooltip id="code" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip7")}
              </ReactTooltip>
              <Label for="code" className="is-required">
                {i18n.t("trd.fromLabel1")}
              </Label>
              <AvField
                id="code"
                name="code"
                type="text"
                onChange={handleOnChangeSubSerie}
                onKeyPress={handleOnKeyPressEditSerie}
                onBlur={handleOnBlurEditSerie}
                validate={{
                  pattern: {
                    value: regexEditSerieFormCode,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback1")}`,
                  },
                  minLength: {
                    value: enumEditSerieForm.MIN_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: enumEditSerieForm.MAX_LENGTH_CODE,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="code"
                autoComplete="off"
                value={editSerieFormService.code}
              />
            </AvGroup>

            <AvGroup>
              <ReactTooltip
                id="name"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip8")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.inputTittle5")}
              </Label>
              <AvField
                id="name"
                name="name"
                type="text"
                onChange={handleOnChangeSubSerie}
                onKeyPress={handleOnKeyPressEditSerie}
                onBlur={handleOnBlurEditSerie}
                validate={{
                  pattern: {
                    value: regexEditSerieFormName,
                    errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.feedback4")}`,
                  },
                  minLength: {
                    value: enumEditSerieForm.MIN_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumEditSerieForm.MAX_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="name"
                autoComplete="off"
                required
                value={editSerieFormService.name}

              />
            </AvGroup>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={closeEditSubSerie}
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                className="col-mt-3"
                color="cyan"
                disabled={isLoading}
              >
                {loadingSpinnerEditSubSerie}
                {i18n.t("trd.buttonUptade")}
              </Button>
            </CardFooter>
          </AvForm>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditSubSerieForm;
