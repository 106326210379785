export const URL_BASE_BACK = process.env.REACT_APP_API_URL;

export const URL_COMPLEMENT = `/gateway/api/v1`;

export const URL_LOGIN = `${URL_BASE_BACK}/gateway/authentication/log-in/`;

export const URL_DASHBOARD = `${URL_BASE_BACK}${URL_COMPLEMENT}/dashboard`;

export const URL_USERS = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_PASSWORD_CREATE = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_TRD = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_FORMS = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_LIST = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_WORKFLOW = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_ROLE = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_INTEGRATIONS = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_DOCUMENTARY_TYPES = `${URL_BASE_BACK}${URL_COMPLEMENT}`;

export const URL_COMPANIES = `${URL_BASE_BACK}${URL_COMPLEMENT}`;
