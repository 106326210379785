import React, { Fragment, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import TrdExpedientConsultationFilesTable from "components/organism/TrdExpedientConsultationFilesTable";
import SearchBoxExpedientFiles from "components/atoms/SearchBoxExpedientFiles";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const TrdExpedientConsultationFiles = (props) => {
  const {
    expedientId,
    getExpedientsData,
    parentId,
    backgrundId,
    setChangeSearchFile,
  } = props;
  const [isLoadingFilesExpedients, setIsLoadingFilesExpedients] =
    useState(false);
  const [fileList, setFileList] = useState([]);
  const [searchFile, setSearchFile] = useState("");
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const { getFilesByExpedient } = useTrd();

  /**
   *Function that retrieves files associated with an expedient search and updates the state accordingly.
   *@param {object} pagination - an object containing page and per_page properties representing the pagination settings
   *@param {number} pagination.page - the current page number
   *@param {number} pagination.per_page - the number of items per page
   *@param {string} expedientId - the ID of the expedient
   *@param {string} searchFile - the search query for the files
   *@param {function} getFilesByExpedient - a function to retrieve files by expedient
   *@param {function} getExpedientsData - a function to retrieve expedient data
   *@param {string} parentId - the ID of the parent component
   *@param {string} backgrundId - the ID of the background component
   *@param {function} swal - a function to display modal alerts
   *@param {function} i18n.t - a function to translate text
   *@param {function} setSearchFile - the state setter function for the search query
   *@param {function} setChangeSearchFile - the state setter function for indicating a change in the search query
   *@param {function} setFileList - the state setter function for the list of files associated with the expedient
   *@param {function} setTotalPages - the state setter function for the total number of pages
   *@param {function} setIsLoadingFilesExpedients - the state setter function for the loading state of the files associated with the expedient
   */
  const getFilesByExpedientSearch = useCallback(() => {
    setIsLoadingFilesExpedients(true);
    const { page, per_page } = pagination;
    getFilesByExpedient(page, per_page, expedientId, searchFile)
      .then((response) => {
        if (response.data.items.length === 0) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.expedientConsultationSearch"),
            icon: "info",
            timer: 5000,
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            setSearchFile("");
            setChangeSearchFile(false);
            setIsLoadingFilesExpedients(true);
            getFilesByExpedient(page, per_page, expedientId, "")
              .then((response) => {
                let filesByExpedient = response.data.items.sort(
                  (fristFile, secondFile) => {
                    const firstPosition =
                      `${fristFile.publication_at}`.toLocaleLowerCase();
                    const secondPosition =
                      `${secondFile.publication_at}`.toLocaleLowerCase();
                    if (firstPosition > secondPosition) {
                      return 1;
                    } else if (secondPosition > firstPosition) {
                      return -1;
                    } else {
                      return 0;
                    }
                  }
                );
                setFileList(filesByExpedient);
                setTotalPages(response.data.pages);
              })
              .finally(() => {
                setIsLoadingFilesExpedients(false);
              });
          });
        }
        let filesByExpedient = response.data.items.sort(
          (fristFile, secondFile) => {
            const firstPosition =
              `${fristFile.publication_at}`.toLocaleLowerCase();
            const secondPosition =
              `${secondFile.publication_at}`.toLocaleLowerCase();
            if (firstPosition > secondPosition) {
              return 1;
            } else if (secondPosition > firstPosition) {
              return -1;
            } else {
              return 0;
            }
          }
        );
        setFileList(filesByExpedient);
        setTotalPages(response.data.pages);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingFilesExpedients(false);
      });
  }, [pagination, expedientId, backgrundId, searchFile, getExpedientsData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that invokes the 'getFilesByExpedientSearch' function when the 'pagination' prop changes.
   *@param {number} pagination - the current pagination value
   *@param {function} getFilesByExpedientSearch - a function that retrieves files based on an expedient search
   */
  useEffect(() => {
    getFilesByExpedientSearch();
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col>
            <Button
              className="btn-icon ml-auto"
              color="cyan"
              onClick={() => {
                setSearchFile("");
                setChangeSearchFile(true);
                getExpedientsData(
                  1,
                  pagination.per_page,
                  parentId,
                  backgrundId,
                  ""
                );
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("filterQuery.buttonBack")}
            </Button>
          </Col>
          <Col>
            <SearchBoxExpedientFiles
              searchFile={searchFile}
              setSearchFile={setSearchFile}
              pagination={pagination}
              setPagination={setPagination}
              getFilesByExpedientSearch={getFilesByExpedientSearch}
              expdientId={expedientId}
            />
          </Col>
        </Row>
        <br />
        <div className="d-flex flex-column h-lg-table">
          <TrdExpedientConsultationFilesTable
            fileList={fileList}
            pagination={pagination}
            isLoadingFilesExpedients={isLoadingFilesExpedients}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

TrdExpedientConsultationFiles.propTypes = {
  expedientId: PropTypes.string.isRequired,
  getExpedientsData: PropTypes.func.isRequired,
  parentId: PropTypes.string.isRequired,
  backgrundId: PropTypes.string.isRequired,
  setChangeSearchFile: PropTypes.func.isRequired,
};

export default TrdExpedientConsultationFiles;
