export const initialPaginationFieldConfig = {
  page: 1,
  per_page: 1000,
  search: "",
};

export const initialPaginationStandart = {
  page: 1,
  per_page: 10,
};

export const initialPaginationForms = {
  page: 1,
  per_page: 1000,
};

export const initialPaginationMemebersRol = {
  page: 1,
  per_page: 10
}

export const initialPaginationGetUsers = {
  page: 1,
  per_page: 1000,
};

export const initialPaginationTasks = {
  page: 1,
  per_page: 20,
};

export const initialPaginationExpedientTree = {
  page: 1,
  per_page: 10,
};

export const initialPaginationRecordListExpedient = {
  page: 1,
  per_page: 10,
};

export const initialPaginationGetExpedientList = {
  page: 1,
  per_page: 1000,
};

export const initialPaginationRecordListTable = {
  page: 1,
  per_page: 100,
};

export const paginationFormsWorkflow = {
  pageForms: 1,
  per_page: 100,
};

export const paginationFieldsWorkflowFiling = {
  pageFields: 1,
  per_page: 100,
};

export const paginationWorkflowAddFields = {
  page: 1,
  perPage: 100,
  actions: 0 
};

export const paginationWorkflowFieldsAdd = {
  page: 1,
  perPage: 300
}
