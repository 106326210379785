import React, { useState, useCallback, useEffect } from "react";
import {
  Label,
  CardBody
} from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import makeAnimated from "react-select/lib/animated";
import Select from "react-select";
import useWorkflow from "hooks/useWorkFlow";
import i18n from "locales/i18n";

const StepsSelectFilterTasks = () => {
  const {
    selectedOptionsStep,
    setSelectedOptionsStep,
    stepsDinamic,
    setStepsDinamic,
    selectedOptionsWorkflow,
    setStructureQueryByFilterTask,
    structureQueryByFilterTask
  } = useWorkflowContext();

  const { getWorkFlowSteps } = useWorkflow();
  const [isLoadingWorkflowStep, setIsLoadingWorkflowStep] = useState(false);

  let selectStepDisable = false;
  let textPlacheholder = "";

  /**
   * Determines the text based on the loading status of workflowStep.
   *
   * If workflowStep are currently being loaded (`isLoadingForms` is true),
   * it sets the text to a loading message. Otherwise, it sets the text
   * to a default message for record data.
   * @param {boolean} isLoadingForms - Indicates whether workflowStep are currently being loaded.
   * @param {string} textPlacheholder - The resulting text based on the loading status.
   * @returns {void}
   */
  if (isLoadingWorkflowStep === true) {
    selectStepDisable = true;
    textPlacheholder = i18n.t("loading");
  } else {
    textPlacheholder = i18n.t("taskFilter.label11");
  }


  /**
   * Fetches active steps for a given workflow ID and updates the state with the fetched steps.
   * @param {string} selectWorkflowId - The ID of the selected workflow.
   * @returns {void}
   */
  const getActiveSteps = useCallback((selectWorkflowId) => {
    setIsLoadingWorkflowStep(true);
    setStepsDinamic([]);
    getWorkFlowSteps(selectWorkflowId)
      .then((workflowSteps) => {
        if (isNullOrUndefined(workflowSteps) === false) {
          workflowSteps.forEach((step) => {
            setStepsDinamic((prevState) => [
              ...prevState,
              {
                value: step.uuid,
                label: step.name,
              },
            ]);
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingWorkflowStep(false);
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
 * Handles the change event for the select step.
 * @param {Object} selectedStep - The selected step object.
 * @param {string|null} selectedStep.value - The value of the selected step.
 * @param {string} selectedStep.label - The label of the selected step.
 * @returns {void}
 */
  const handleOnChangeSelectStep = (selectedStep) => {
    setSelectedOptionsStep(selectedStep)
    if (selectedStep !== null) {
      if (selectedStep.value !== null) {
        setStructureQueryByFilterTask({
          ...structureQueryByFilterTask,
          step_uuid: selectedStep.value,
        });
      }
    } else {
      setSelectedOptionsStep({ value: null, label: i18n.t("taskFilter.label11") })
      setStructureQueryByFilterTask({
        ...structureQueryByFilterTask,
        step_uuid: null,
      });
    }
  };

  /**
   * useEffect hook to fetch active forms based on the selected workflow ID.
   *
   * @param {string} selectWorkflowId - The ID of the selected workflow.
   * @param {Function} getActiveSteps - Function to fetch active forms.
   */

  useEffect(() => {
    if (isNullOrUndefined(selectedOptionsWorkflow.value) === false) {
      getActiveSteps(selectedOptionsWorkflow.value);
    }
  }, [selectedOptionsWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardBody className="pt-0">
      <div className="mb-4">
        <Label>
          {i18n.t("taskFilter.label12")}
        </Label>
        <Select
          id="workflowStep"
          name="workflowStep"
          maxMenuHeight={120}
          menuPlacement="auto"
          isSearchable={true}
          isClearable={true}
          hideSelectedOptions={true}
          placeholder={textPlacheholder}
          closeMenuOnSelect={true}
          components={makeAnimated()}
          isDisabled={selectStepDisable}
          value={selectedOptionsStep}
          options={stepsDinamic}
          onChange={handleOnChangeSelectStep}
          noOptionsMessage={(obj) =>
            i18n.t("taskFilter.label13")
          }
        />
      </div>
    </CardBody>
  );
};


export default StepsSelectFilterTasks;