import swal from "sweetalert";
import i18n from "locales/i18n";

export const showSweetAlertInvalidPassword = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("changePassword.message3"),
    icon: "info",
    timer: 5000,
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showSweetAlertMatch = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("changePassword.message1"),
    icon: "error",
    timer: 5000,
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showSweetAlertSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("forgotPassword.successfullReset"),
    icon: "success",
    timer: 5000,
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showSweetAlertSuccessNewPassword = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("changePassword.message2"),
    icon: "success",
    timer: 5000,
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showAlertServiceError = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("error.data.submit"),
    icon: "error",
    dangerMode: true,
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showAlertNoFormsFound = (setSearch, setPagination, pagination) => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("filterQuery.NoFormsMessage"),
    icon: "warning",
    button: i18n.t("modal.Done.footerButton"),
  }).then(() => {
    setSearch("");
    setPagination({ ...pagination, page: 1 });
  });
};

export const showAlertServiceResponsibleUser = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("50064"),
    icon: "error",
    button: i18n.t("modal.Done.footerButton"),
    dangerMode: true,
  });
};

export const showAlertNoForms = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("filterQuery.NoForms"),
    icon: "warning",
    button: i18n.t("modal.Done.footerButton"),
  }).then(() => {
    window.location.href = "/"
  });
};

/* Alerts for the hook useClassification */

export const showAlertSubSectionSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle19"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
    successMode: true,
  });
};

export const showAlertSeriesSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle20"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
    successMode: true,
  });
};

export const showAlertSubSeriesSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle21"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
    successMode: true,
  });
};

export const showAlertExpedientSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle22"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showAlertSubDivisionSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle23"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showAlertDirectorySuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle24"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
  });
};

export const showAlertServiceErrorWithRedirect = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("error.data.submit"),
    icon: "error",
    dangerMode: true,
    button: i18n.t("modal.Done.footerButton"),
  }).then(() => {
    window.location.href = "/"
  })
};

export const showAlertCrateSectionSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("trd.formTittle18"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
    successMode: true,
  });
};

export const showAlertExportRecordsSuccess = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("export.records.alert.exporte"),
    icon: "success",
    button: i18n.t("modal.Done.footerButton"),
    successMode: true,
  });
};

export const showAlertNoExportDataRecord = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("export.records.alert.warining2"),
    icon: "warning",
    button: i18n.t("modal.Done.footerButton"),
  })
};

export const showAlertNoExportDataRecords = () => {
  swal({
    title: i18n.t("modal.DoneError.header"),
    text: i18n.t("export.records.alert.warining"),
    icon: "warning",
    button: i18n.t("modal.Done.footerButton"),
  }).then(() => {
    window.location.reload();
  })
};
