import React from "react";
import { enumsExportRecords } from "./enums";
import i18n from "locales/i18n";


/**
 * Renders a status badge for export records based on the current status.
 * @function renderStatusExportRecords
 * @param {string|number} status - The status of the export record, which can be a string or number. It is converted to a number internally.
 * @returns {JSX.Element} - A JSX element representing the corresponding status badge.
 */
export const renderStatusExportRecords = (status) => {
  let finalValue = Number(status);
  let statusFinal = "";
  if (finalValue === enumsExportRecords.IN_PROGRESS) {
    statusFinal = (
      <div className="ml-auto badge badge-warning">
        {i18n.t("createWorflow.Label3.21")}
      </div>
    );
  } else if (finalValue === enumsExportRecords.FINISHED) {
    statusFinal = (
      <div className="ml-auto badge badge-success">
        {i18n.t("form.designerLabel3.3")}
      </div>
    );
  } else if (finalValue === enumsExportRecords.FAILED) {
    statusFinal = (
      <div className="ml-auto badge badge-danger">
        {i18n.t("form.designerLabel3.4")}
      </div>
    );
  }

  return statusFinal;
};