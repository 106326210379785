import React from "react";
import Trd from "./Trd";
import { TrdContextProvider } from "contextAPI/TrdContext";
export default () => {
  return (
    <TrdContextProvider>
      <Trd />
    </TrdContextProvider>
  );
};
