import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Col,
  CardFooter,
  Label,
  Card,
  CardBody,
  Row,
  Spinner,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { Loader } from "react-loaders";
import { showAlertServiceError } from "utils/alerts";
import { enumsFieldsOrder } from "utils/enums";
import useForms from "hooks/useForm";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ChangeOrderField = () => {
  const { id, hash, formId } = useParams();
  const [isLoadingChangeOrderField, setIsLoadingChangeOrderField] =
    useState(false);
  const [isLoadingGetField, setIsLoadingGetField] = useState(false);
  const [order, setOrder] = useState({
    order: enumsFieldsOrder.INITIAL_FIRST_ORDER,
  });
  const [tempOrderField, setTempOrderField] = useState(null);
  const { getField, updateOrder } = useForms();
  const decodedCount = window.atob(hash);
  const numberOfFields = Number(decodedCount);
  const optionSelected = [];

  let loadingGetFields = null;

  /**
   * Conditional rendering of a loading spinner.
   * @param {boolean} isLoadingChangeOrderField - Flag indicating if the change order field is loading.
   * @returns {JSX.Element|null} - Returns a spinner element if loading, otherwise returns null.
   */
  if (isLoadingChangeOrderField === true) {
    loadingGetFields = <Spinner size="sm" color="secondary" type="grow" />
  };

  /**
   * Generates an array of numbers from 1 to 'numberOfFields' and appends each number to 'optionSelected' array.
   * @param {number} numberOfFields - The total number of fields to generate.
   * @param {Array<number>} optionSelected - The array to which the generated numbers will be appended.
   */
  for (let indexField = 1; indexField <= numberOfFields; indexField++) {
    optionSelected.push(indexField);
  }

  /**
   * Handles the change event for the order field and updates the order state.
   * @param {Object} eventChangeOrder - The change event object.
   * @param {Object} eventChangeOrder.target - The target element of the event.
   * @param {string} eventChangeOrder.target.value - The value of the target element.
   */
  const changeOrderFields = (eventChangeOrder) => {
    setOrder({
      order: Number(eventChangeOrder.target.value),
    })
  };

  /**
   * Handle form submission by preventing the default form behavior, checking for changes in the order field, and updating the order if necessary.
   * @param {Event} eventChange - The event object representing the form submission.
   * @returns {void}
   */
  const handleOnSumbit = (eventChange) => {
    eventChange.preventDefault();
    if (order.order === tempOrderField) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: `${i18n.t("form.field53")}`,
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
    } else {
      setIsLoadingChangeOrderField(true);
      updateOrder(id, order)
        .then((response) => {
          if (response.status === 202) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: `${i18n.t("form.field50")}`,
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(
              () => (window.location.href = `/formsdesigner/fields/${formId}`)
            );
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingChangeOrderField(false);
        });
    }
  };

  /**
   * Fetches field data by ID and updates the component's state with the retrieved information.
   * This useEffect hook is responsible for fetching field data by the provided ID, setting the order
   * and temporary order field in the component's state, and handling errors if the order field is not found.
   * @param {string} id - The unique identifier of the field to be fetched.
   * @param {function} getField - A function that fetches field data based on the provided ID.
   * @param {function} setOrder - A state setter function for updating the order state.
   * @param {function} setTempOrderField - A state setter function for updating the temporary order field state.
   * @param {function} setIsLoadingGetField - A state setter function for managing the loading state during the fetch operation.
   * @param {object} i18n - An internationalization object containing translations for messages.
   * @returns {void}
   */
  useEffect(() => {
    setIsLoadingGetField(true);
    getField(id)
      .then((response) => {
        const orderField = response.order;
        if (isNullOrUndefined(orderField) === false) {
          setOrder({ order: orderField });
          setTempOrderField(orderField);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGetField(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a loading spinner if the field is loading.
   * @param {boolean} isLoadingGetField - Flag indicating if the field is loading.
   * @returns {JSX.Element|null} - Returns a loading spinner element if loading, otherwise returns null.
   */
  if (isLoadingGetField === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="secondary" type="ball-pulse-rise" />
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleOnSumbit}>
          <Card className="main-card mb-3">
            <CardBody>
              <Row>
                <Col lg="5" md="4" sm="12" className="button-container">
                  <h5 className="text-info font-weight-bold">
                    {i18n.t("form.field51")}
                  </h5>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="pt-0 pl-5">
              <AvGroup row>
                <Label for="document_type" className="is-required" sm={3}>
                  {i18n.t("form.field52")}
                </Label>
                <Col md={8}>
                  <AvField
                    id="document_type"
                    name="document_type"
                    type="select"
                    onChange={changeOrderFields}
                    errorMessage={i18n.t("createusers.Feedback6")}
                    value={order.order}
                  >
                    {optionSelected.map((number) => (
                      <option value={number} key={number}>
                        {number}
                      </option>
                    ))}
                  </AvField>
                </Col>
              </AvGroup>
            </CardBody>

            <CardFooter className="d-block text-right">
              <Button
                onClick={() =>
                  (window.location.href = `/formsdesigner/fields/${formId}`)
                }
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                disabled={isLoadingChangeOrderField}
                className="col-mt-3"
                color="cyan"
              >
                {loadingGetFields}
                {i18n.t("createWorflow.EditButton")}
              </Button>
            </CardFooter>
          </Card>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ChangeOrderField;
