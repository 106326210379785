import i18n from "i18next"

export const workGroupEditionErrors = (status) => {
  switch (status) {
    case "10053":
      return {
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("create.work.group.members.alert5"),
        icon: "warning",
        dangerMode: false,
        button: i18n.t("modal.Done.footerButton")
      }
    case "50016":
      return {
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("create.work.group.members.alert6"),
        icon: "warning",
        dangerMode: false,
        button: i18n.t("modal.Done.footerButton")
      }
    case "10058":
      return {
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("create.work.group.members.label18"),
        icon: "warning",
        dangerMode: false,
        button: i18n.t("modal.Done.footerButton")
      }
    case "10014":
      return {
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("create.work.group.exist.error"),
        icon: "error",
        dangerMode: true,
        button: i18n.t("modal.Done.footerButton")
      }
    default:
      return null
  }
}
