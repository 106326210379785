import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_COMPANIES } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";
import { isNullOrUndefined } from "utils/validations";

export default function useCompany() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
    "Accept-Language": "es",
  };

  /**
   * Retrieves company information based on the provided domain.
   * @async
   * @param {string} domain - The domain of the company.
   * @returns {Promise<Object>} A Promise that resolves to the company information retrieved from the API.
   */
  const getCompany = async (domain) => {
    const response = await axios.get(`${URL_COMPANIES}/companies/${domain}/get-company`, {
      headers,
      endpointError: endpointError.company.getCompany,
    });
    return response.data;
  };

  /**
   * Retrieves the logo of a company by making an asynchronous HTTP GET request to the specified endpoint.
   * @async
   * @param {Object} headers - The headers to be included in the HTTP request.
   * @returns {Promise<Object>} The logo data of the company.
   */
  const getLogoByCompany = async () => {
    const response = await axios.get(`${URL_COMPANIES}/file/sticker-records/`, {
      headers,
      endpointError: endpointError.company.getLogoByCompany,
    });
    return response.data;
  };

  /**
   * Configures the logo by company using the provided logo file data.
   * @async
   * @param {FormData} fileLogoFormData - The FormData object containing the logo file data to be configured.
   * @returns {Promise<Object>} A promise that resolves with the response from the server after configuring the logo.
   */
  const configureLogoByCompany = async (fileLogoFormData) => {
    const response = await axios.patch(`${URL_COMPANIES}/file/sticker-records/`, fileLogoFormData, {
      headers,
      endpointError: endpointError.company.configureLogoByCompany,
    });
    return response;
  };

  /**
   * Fetches logs for a company with pagination and filtering options.
   * @param {number} page - The page number to retrieve.
   * @param {number} per_page - The number of logs per page.
   * @param {string} timeZone - The time zone to use for the logs.
   * @param {Object} filterByUserOrDate - The filter criteria, such as by user or date.
   * @returns {Promise<Object>} The response data containing the logs.
   */
  const getLogsByCompany = async (page, per_page, timeZone, filterByUserOrDate) => {
    let response;
    if (isNullOrUndefined(filterByUserOrDate) === false) {
      response = await axios.post(
        `${URL_COMPANIES}/logs/?page=${page}&per_page=${per_page}&time_zone=${timeZone}`,
        filterByUserOrDate,
        {
          headers,
          endpointError: endpointError.company.getLogsByCompany,
        }
      );
    } else {
      response = await axios.post(
        `${URL_COMPANIES}/logs/?page=${page}&per_page=${per_page}`,
        filterByUserOrDate,
        {
          headers,
          endpointError: endpointError.company.getLogsByCompany,
        }
      );
    }
    return response.data;
  };

  return {
    getCompany,
    getLogoByCompany,
    configureLogoByCompany,
    getLogsByCompany,
  };
}
