import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, Col, CardBody, Row, Spinner, Button } from "reactstrap";
import { faSearch, faWindowRestore, faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loaderElement } from "utils/loaderElement";
import { enumsTaskComeFrom } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import useWorkFlow from "hooks/useWorkFlow";
import ReassignTask from "components/molecules/ReassignTask";
import ReactTooltip from "react-tooltip";
import ProcessManagementTableFilter from "components/organism/ProcessManagementTableFilter";
import ProcessManagementFiltersApply from "components/molecules/ProcessManagementFiltersApply";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ProcessManagementList = (props) => {
  const {
    processFilteredList,
    isLoadingManagementProcess,
    totalPages,
    paginationPorcessFilter,
    setPaginationPorcessFilter,
    totalCount,
    setOrderHeaderBy,
    setOrderHeaderType,
    setFilterDone,
    queryProcessData,
    usersDinamic,
    workflowsDinamic,
    workflowsStepsDinamic,
    getQueryProcessByFilter,
  } = props;
  const { setGeneralUsersDinamic, isLoadingGeneralusers } = useWorkflowContext();
  const [activateReassingBatchOption, setActivateReassingBatchOption] = useState(false);
  const [disabledButtonBatchProcess, setDisabledButtonBatchProcess] = useState(true);
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(false);
  const [isVisibleButtonProcess, setIsVisibleButtonProccess] = useState(true);
  const [selectedFields, setSelectedFields] = useState([]);
  const [taskSelectedByBatch, setTaskSelectedByBatch] = useState([]);
  const [disabledCheck, setDisabledCheck] = useState(false);
  const { verifyManagementProcess } = useWorkFlow();
  let buttonRestoreFilter = null;
  let buttonProcess = null;
  let showTooltip = null;
  let spinnerLoadingValidating = null;
  let buttonReassignProcessBatch = null;
  let showTotalCountTasks = null;

  /**
   * Asynchronously verifies the management process and updates the UI based on the response.
   * This function sets a loading state, prepares the data to verify management tasks, sends the data for verification,
   * and then processes the response to update the UI. In case of errors, it displays a detailed error message.
   * @returns {Promise<void>} A promise that resolves when the verification process is complete.
   */
  const funcVerifyManagememtProcess = async () => {
    setIsLoadingProcessing(true);
    const selectedTasksToVerify = {
      user_uuid: queryProcessData.user_uuid,
      tasks: selectedFields,
    };
    verifyManagementProcess(selectedTasksToVerify)
      .then((response) => {
        if (response.status === 201) {
          setActivateReassingBatchOption(response.data.data.actions.allow_reassign);
          setIsVisibleButtonProccess(false);
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        const errorsList = [];
        if (isNullOrUndefined(error.response.data.data) === false) {
          const errorsResponse = error.response.data.data;
          errorsResponse.forEach((error) => {
            if (error.code === "50098") {
              errorsList.push(i18n.t("50098"));
            }
            if (error.code === "50111") {
              errorsList.push(i18n.t("50111"));
            }
            if (error.code === "50112") {
              errorsList.push(i18n.t("50112"));
            }
            if (error.code === "50113") {
              errorsList.push(i18n.t("50113"));
            }
          });
        }
        const renderErrosList = errorsList.map(
          (message, index) => `<li key=${index}><em>${message}</em></li>`
        );
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: `${i18n.t("processManagement.AlertError")}: \n`,
          icon: "error",
          button: i18n.t("modal.Done.footerButton"),
          dangerMode: true,
          content: {
            element: "div",
            attributes: {
              innerHTML: `<ul>${renderErrosList.join("")}</ul>`,
              className: "custom-content-class",
            },
          },
        });
      })
      .finally(() => {
        setIsLoadingProcessing(false);
      });
  };

  /**
   * Displays the total count of tasks and applies process management filters.
   * @param {boolean} isVisibleButtonProcess - Indicates whether the button for process management is visible.
   * @param {number} totalCount - The total number of tasks.
   * @param {Object} queryProcessData - Data object containing query parameters for the process.
   * @param {Array} usersDinamic - Dynamic list of users.
   * @param {Array} workflowsDinamic - Dynamic list of workflows.
   * @param {Array} workflowsStepsDinamic - Dynamic list of workflow steps.
   * @param {Array} selectedFields - List of selected fields for filtering tasks.
   * @param {Object} i18n - Internationalization object for translation.
   * @param {React.Component} ProcessManagementFiltersApply - React component for applying process management filters.
   * @returns {JSX.Element} JSX element displaying the total count of tasks with or without the process management filters applied.
   */
  if (isVisibleButtonProcess === true) {
    showTotalCountTasks = (
      <div>
        <h6 className="text-info font-weight-bold">
          <div>
            {i18n.t("taskBoxTable10")}
            <span className="text-dark ml-2">{totalCount}</span>
          </div>
        </h6>
        <ProcessManagementFiltersApply
          queryProcessData={queryProcessData}
          usersDinamic={usersDinamic}
          workflowsDinamic={workflowsDinamic}
          workflowsStepsDinamic={workflowsStepsDinamic}
        />
      </div>
    );
  } else {
    showTotalCountTasks = (
      <h6 className="text-info font-weight-bold">
        <div>
          {i18n.t("taskFilter.label21")}:{" "}
          <span className="text-dark ml-2">{selectedFields.length}</span>
        </div>
      </h6>
    );
  }

  /**
   * Renders a loading spinner if processing is in progress.
   * @param {boolean} isLoadingProcessing - Indicates whether the processing is currently loading.
   * @returns {JSX.Element|null} A spinner component if `isLoadingProcessing` is true, otherwise null.
   */
  if (isLoadingProcessing === true) {
    spinnerLoadingValidating = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Renders a tooltip for the batch process button if the button is disabled.
   * @param {boolean} disabledButtonBatchProcess - Indicates if the batch process button is disabled.
   * @returns {JSX.Element|null} The ReactTooltip component if the button is disabled, otherwise null.
   */
  if (disabledButtonBatchProcess === true) {
    showTooltip = (
      <ReactTooltip
        id="btn-buttonProcessBatch"
        place="top"
        type="info"
        effect="solid"
        className="custom-tooltip"
      >
        <span className="text-lowercase">{i18n.t("processManagement.TooltipButton")}</span>
      </ReactTooltip>
    );
  }

  /**
   * Restores the default state for various filter-related settings.
   * This function performs the following actions:
   * - Sets the visibility of the process button to true.
   * - Deactivates the reassignment batch option.
   * - Clears the selected fields array.
   * - Clears the array of tasks selected by batch.
   * - Enables the checkbox.
   * - Clears the dynamic general users array.
   * @returns {void}
   */
  const handleRestoreFilter = () => {
    setIsVisibleButtonProccess(true);
    setActivateReassingBatchOption(false);
    setSelectedFields([]);
    setTaskSelectedByBatch([]);
    setDisabledCheck(false);
    setGeneralUsersDinamic([]);
  };

  /**
   * Renders a button to restore filters if the button should be visible.
   * @param {boolean} isVisibleButtonProcess - A flag indicating whether the button should be visible.
   * @param {Function} handleRestoreFilter - The function to call when the restore filter button is clicked.
   * @param {Object} i18n - An internationalization object for translating text.
   * @returns {JSX.Element|null} - A JSX element representing the button if `isVisibleButtonProcess` is false, otherwise null.
   */
  if (isVisibleButtonProcess === false) {
    buttonRestoreFilter = (
      <Col lg="auto" md="6" sm="12">
        <Button
          data-test-id="btn-buttonRestoreFilter"
          className="btn-block ml-auto btn-icon btn-block btn-mb-responsive"
          color="gray"
          onClick={handleRestoreFilter}
        >
          <FontAwesomeIcon icon={faWindowRestore} className="mr-2" />
          <span>{i18n.t("taskFilter.label14")}</span>
        </Button>
      </Col>
    );
  }

  /**
   * Renders a button for processing batch operations based on visibility and state.
   * @param {boolean} isVisibleButtonProcess - Determines if the process button should be visible.
   * @param {boolean} disabledButtonBatchProcess - Indicates if the button should be disabled.
   * @param {boolean} isLoadingProcessing - Indicates if a processing action is currently loading.
   * @param {ReactNode} showTooltip - Tooltip content to be displayed.
   * @param {ReactNode} spinnerLoadingValidating - Spinner element to indicate loading state.
   * @param {Function} funcVerifyManagememtProcess - Function to be called when the button is clicked.
   * @param {Object} i18n - Internationalization object for translation.
   * @param {string} i18n.t - Function to translate text.
   * @returns {ReactNode} The process button wrapped in a Col component if isVisibleButtonProcess is true.
   */
  if (isVisibleButtonProcess === true) {
    buttonProcess = (
      <Col lg="auto" md="6" sm="12" className="align-self-end">
        <Button
          data-test-id="btn-buttonProcessBatch"
          className="btn-block ml-auto btn-icon btn-mb-responsive"
          color="cyan"
          data-tip
          data-for="btn-buttonProcessBatch"
          disabled={disabledButtonBatchProcess || isLoadingProcessing}
          onClick={() => funcVerifyManagememtProcess()}
        >
          {showTooltip}
          {spinnerLoadingValidating}
          <FontAwesomeIcon icon={faCheckDouble} className="mr-2" />
          <span>{i18n.t("processManagement.Button")}</span>
        </Button>
      </Col>
    );
  }

  /**
   * Conditionally renders a ReassignTask component if activateReassingBatchOption is true.
   * @param {boolean} activateReassingBatchOption - Flag indicating if the batch reassignment option should be activated.
   * @param {Array<string>} selectedFields - Array of selected task IDs to be reassigned.
   * @param {Object} queryProcessData - Object containing process query data.
   * @param {Function} handleRestoreFilter - Callback function to handle filter restoration.
   * @param {Array<Object>} taskSelectedByBatch - Array of selected tasks for batch reassignment.
   * @returns {JSX.Element|null} - JSX element representing the ReassignTask component or null if not activated.
   */
  if (activateReassingBatchOption === true) {
    buttonReassignProcessBatch = (
      <Col lg="auto" md="6" sm="12" className="align-self-end">
        <ReassignTask
          typeTask={enumsTaskComeFrom.PROCESS_MANAGEMENT}
          idsTaskSelectedByBatch={selectedFields}
          queryProcessData={queryProcessData}
          handleRestoreFilter={handleRestoreFilter}
          taskSelectedByManagement={taskSelectedByBatch}
          getQueryProcessByFilter={getQueryProcessByFilter}
        />
      </Col>
    );
  }

  /**
   * Button component for filtering queries.
   * @param {function} setFilterDone - Function to set the filter state to done or not.
   * @returns {JSX.Element} Button component for filtering queries.
   */
  let buttonFilterQuery = (
    <Col lg="auto" md="6" sm="12">
      <Button
        className="btn-block ml-auto btn-icon btn-block btn-mb-responsive"
        color="cyan"
        onClick={() => {
          setFilterDone(false);
        }}
      >
        <FontAwesomeIcon icon={faSearch} className="mr-2" />
        {i18n.t("filterQuery.button")}
      </Button>
    </Col>
  );

  /**
   * useEffect hook that updates the disabled state of the batch process button based on selected fields length.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {function} setDisabledButtonBatchProcess - State setter function to update disabled state of the batch process button.
   * @returns {void}
   */
  useEffect(() => {
    if (selectedFields.length > 0) {
      setDisabledButtonBatchProcess(false);
    } else {
      setDisabledButtonBatchProcess(true);
    }
  }, [selectedFields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingManagementProcess || isLoadingGeneralusers)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody className="pb-1">
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container">
                <Row>{showTotalCountTasks}</Row>
              </Col>
              <Col lg="7" md="8" sm="12" className="button-container mt-2 mt-md-0 mb-4">
                <Row className="row justify-content-end">
                  {buttonRestoreFilter}
                  {buttonReassignProcessBatch}
                  {buttonProcess}
                  {buttonFilterQuery}
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="pt-0">
            <ProcessManagementTableFilter
              processFilteredList={processFilteredList}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              taskSelectedByBatch={taskSelectedByBatch}
              setTaskSelectedByBatch={setTaskSelectedByBatch}
              disabledCheck={disabledCheck}
              setDisabledCheck={setDisabledCheck}
              isVisibleButtonProcess={isVisibleButtonProcess}
              activateReassingBatchOption={activateReassingBatchOption}
              isLoadingManagementProcess={isLoadingManagementProcess}
              totalPages={totalPages}
              paginationPorcessFilter={paginationPorcessFilter}
              setPaginationPorcessFilter={setPaginationPorcessFilter}
              setOrderHeaderBy={setOrderHeaderBy}
              setOrderHeaderType={setOrderHeaderType}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ProcessManagementList.propTypes = {
  processFilteredList: PropTypes.array.isRequired,
  isLoadingManagementProcess: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  paginationPorcessFilter: PropTypes.object.isRequired,
  setPaginationPorcessFilter: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  setOrderHeaderBy: PropTypes.func.isRequired,
  setOrderHeaderType: PropTypes.func.isRequired,
  setFilterDone: PropTypes.func.isRequired,
  queryProcessData: PropTypes.object.isRequired,
  usersDinamic: PropTypes.array.isRequired,
  workflowsDinamic: PropTypes.array.isRequired,
  workflowsStepsDinamic: PropTypes.array.isRequired,
};

export default ProcessManagementList;
