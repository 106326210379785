import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";

const ButtonBackListWorkGroup = () => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="mr-1">
          <Link to="/user/workgroup">
            <Button className="btn-icon" color="cyan">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("create.work.group.members.button1")}
            </Button>
          </Link>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonBackListWorkGroup;
