import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import i18n from "locales/i18n";

const ButtonCreateWorkFlow = (props) => {
  const { disabled } = props;

  let renderDropdownList = null;

  /**
   * Conditionally renders a dropdown list if `disabled` is `false`. 
   * @type {JSX.Element | null}
   */
  if (disabled === false) {
    renderDropdownList = (
      <div>
        <Link to="/workflow">
          <UncontrolledButtonDropdown>
            <DropdownToggle className="button" color="success">
              <span>{i18n.t("createWorflow.button")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </Link>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {renderDropdownList}
      </CSSTransitionGroup>
    </Fragment>
  );
};


ButtonCreateWorkFlow.propTypes = {
  disabled: PropTypes.bool
};


export default ButtonCreateWorkFlow;
