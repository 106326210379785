import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_INTEGRATIONS } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useIntegration() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getIntegrations = async (search) => {
    let response;
    if (search) {
      response = await axios.get(
        `${URL_INTEGRATIONS}/integrations/core/?name=${search}`,
        {
          headers,
          endpointError: endpointError.integrations.getIntegrations
        }
      );
    } else {
      response = await axios.get(`${URL_INTEGRATIONS}/integrations/core/`,
        {
          headers,
          endpointError: endpointError.integrations.getIntegrations
        });
    }
    return response;
  };

  const getIntegrationById = async (id) => {
    const response = await axios.get(
      `${URL_INTEGRATIONS}/integrations/${id}/core/`,
      {
        headers,
        endpointError: endpointError.integrations.getIntegrationById
      }
    );

    return response;
  };

  const requestInt = async (requestIntegration) => {
    const response = await axios.post(
      `${URL_INTEGRATIONS}/requests/`,
      requestIntegration,
      {
        headers,
        endpointError: endpointError.integrations.requestInt
      }
    );

    return response;
  };

  const configInt = async (formData) => {
    const response = await axios.post(
      `${URL_INTEGRATIONS}/parameters/validart/`,
      formData,
      {
        headers,
        endpointError: endpointError.integrations.configInt
      }
    );

    return response;
  };

  const createSignature = async (fileId, createSing) => {
    const response = await axios.post(
      `${URL_INTEGRATIONS}/integrations/validart/file/${fileId}/`,
      createSing,
      {
        headers,
        endpointError: endpointError.integrations.createSignature
      }
    );

    return response;
  };

  const getSignatures = async (page, per_page, search, status) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_INTEGRATIONS}/integrations/validart/signatures/?transaction_status=${status}&operation=and&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.integrations.getSignatures
        }
      );
    } else {
      response = await axios.get(
        `${URL_INTEGRATIONS}/integrations/validart/signatures/?subject=${search}&operation=and&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.integrations.getSignatures
        }
      );
    }
    return response.data;
  };

  const getConfigIntegration = async () => {
    const response = await axios.get(
      `${URL_INTEGRATIONS}/parameters/validart/`,
      {
        headers,
        endpointError: endpointError.integrations.getConfigIntegration
      }
    );

    return response;
  };

  const updateConfigIntegration = async (formData) => {
    const response = await axios.patch(
      `${URL_INTEGRATIONS}/parameters/validart/`,
      formData,
      {
        headers,
        endpointError: endpointError.integrations.updateConfigIntegration
      }
    );

    return response;
  };

  const reSendSignature = async (id) => {
    const response = await axios.get(
      `${URL_INTEGRATIONS}/integrations/validart/signatures/${id}/resend`,
      {
        headers,
        endpointError: endpointError.integrations.reSendSignature
      }
    );
    return response;
  };

  const getSignatories = async (page, per_page, id) => {
    const response = await axios.get(
      `${URL_INTEGRATIONS}/integrations/validart/signatures/${id}/signatories/?page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.integrations.getSignatories
      }
    );

    return response;
  };

  return {
    getIntegrations,
    getIntegrationById,
    requestInt,
    configInt,
    createSignature,
    getSignatures,
    getConfigIntegration,
    updateConfigIntegration,
    reSendSignature,
    getSignatories,
  };
}
