import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardFooter, CardBody } from "reactstrap";
import ButtonCreateDocumentaryTypes from "components/atoms/ButtonCreateDocumentaryTypes";
import SearchBox from "components/atoms/SearchBox";
import DocumentaryTypesTable from "components/organism/DocumentaryTypesTable";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const DocumentaryTypesList = () => {
  const [DocumentaryTypes, setDocumentaryTypes] = useState([]);
  const [isLoadingDocumentaryTypes, setIsLoadingDocumentaryTypes] = useState(
    false
  );

  /**
   *The pagination is defined in 50 items per page temporarily, and the filter will not be included until improvement resumes in HU031
   */
  const [pagination, setPagination] = useState({ page: 1, per_page: 50 });
  const [totalPages, setTotalPages] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [statusDocumentaryType] = useState("");
  const { getDocumentalTypes } = useTrd();

  /**
   *Displays a custom alert message based on the given status parameter.
   *@param {string} status - the status value used to determine the type of alert message to display
   */
  const showAlertItemsEmpty = (status, searchName) => {
    let messageForAlert = "";
    if (!status || status === undefined || status === "")
      messageForAlert = "documentary.typeNoItems";
    if (status !== "" || searchName !== "")
      messageForAlert = "documentary.typeNoItemsByFilter";

    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t(`${messageForAlert}`),
      icon: "info",
      timer: 5000,
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   *Retrieves documentary types based on the search criteria and updates the state variables of the component.
   *@param {function} getDocumentalTypes - a function that fetches the documentary types based on pagination, search criteria, and status
   *@param {object} pagination - an object containing page and per_page properties representing the pagination settings
   *@param {number} pagination.page - the current page number
   *@param {number} pagination.per_page - the number of items per page
   *@param {string} searchName - the search criteria for documentary types
   *@param {string} statusDocumentaryType - the status of the documentary types to be retrieved
   *@param {function} showAlertItemsEmpty - a function that shows an alert when no documentary types are found with the given status
   *@param {function} setDocumentaryTypes - the state setter function for the retrieved documentary types
   *@param {number} setTotalPages - the state setter function for the total number of pages of documentary types
   *@param {boolean} setIsLoadingDocumentaryTypes - the state setter function for the loading state of the documentary types
   */
  const getDocumentalTypesBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingDocumentaryTypes(true);
    getDocumentalTypes(page, per_page, searchName, statusDocumentaryType)
      .then((response) => {
        let listDocumentaryTypes = response.data.data.items;
        listDocumentaryTypes.sort(
          (FirstDocumentaryType, SecondDocumentaryType) => {
            const firstPositionDocumentaryTypeName = `${FirstDocumentaryType.name}`.toLocaleLowerCase();
            const secondPositionDocumentaryTypeName = `${SecondDocumentaryType.name}`.toLocaleLowerCase();
            if (
              firstPositionDocumentaryTypeName >
              secondPositionDocumentaryTypeName
            ) {
              return 1;
            } else if (
              secondPositionDocumentaryTypeName >
              firstPositionDocumentaryTypeName
            ) {
              return -1;
            } else {
              return 0;
            }
          }
        );
        if (listDocumentaryTypes.length === 0) {
          showAlertItemsEmpty(statusDocumentaryType, searchName);
        }
        setDocumentaryTypes(listDocumentaryTypes);
        setTotalPages(response.data.data.pages);
      })
      .finally(() => {
        setIsLoadingDocumentaryTypes(false);
      });
  }, [pagination, searchName]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that fetches documental types data and sets the corresponding state variable of the component.
   *@param {function} getDocumentalTypesBySearch - the function that fetches the documental types data and sets the corresponding state variable
   */
  useEffect(() => {
    getDocumentalTypesBySearch();
  }, [getDocumentalTypesBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchName}
              pagination={pagination}
              setPagination={setPagination}
            />

            <ButtonCreateDocumentaryTypes />
          </CardFooter>

          <CardBody>
            <DocumentaryTypesTable
              DocumentaryTypes={DocumentaryTypes}
              pagination={pagination}
              isLoadingDocumentaryTypes={isLoadingDocumentaryTypes}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DocumentaryTypesList;
