import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useParams } from "react-router-dom";
import { enumsMinMaxCharacters } from "utils/enums";
import { regexTrashMotive } from "utils/regexExpressions";
import { trimExtraSpaces } from "utils/trimSpaces";
import { showAlertServiceError } from "utils/alerts";
import ReactTooltip from "react-tooltip";
import useRecord from "hooks/useRecord";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ModalTrashFile = (props) => {
  const { isOpenFileMoveTrash, setIsOpenFileMoveTrash } = props;
  const [isLoadingEliminationFile, setIsLoadingEliminationFile] = useState(false);
  const [reasonElimination, setReasonElimination] = useState({ reason: "" });
  const { fileId } = useParams();
  const { setIsChangePage } = useRecordDetailContext();
  const { moveFileToTrash } = useRecord();
  let loadingComponent = null;

  /**
   * Conditionally assigns a loading spinner JSX element to the loadingComponent
   * variable if isLoadingEliminationFile is true, otherwise assigns null.
   * @type {JSX.Element|null} The JSX element representing a loading spinner or null.
   */
  if (isLoadingEliminationFile === true) {
    loadingComponent = <Spinner size="sm" color="secondary" type="grow" />;
  } else {
    loadingComponent = null;
  }

  /**
   * Handles the change event when a user selects a reason for elimination in a modal.
   * @param {Event} eventModalTrash - The event object representing the change event.
   */
  const handleOnChangeReason = (eventModalTrash) => {
    setReasonElimination({
      reason: eventModalTrash.target.value,
    });
  };

  /**
   * Handles the closure of the modal for deleting a file and moving it to the trash.
   * Sets the `isOpenFileMoveTrash` state to false to close the modal.
   * @return {Void}
   */
  const handleOnCloseModalDeleteFile = () => {
    setIsOpenFileMoveTrash(false);
    setReasonElimination((prevState) => ({
      ...prevState,
      reason: "",
    }));
  };

  /**
   * Handles the blur event when the user interacts with an input element, trimming and updating
   * the `reasonElimination` state with the trimmed value.
   * @return {Void}
   */
  const handleOnBlur = (eventModalTrash) => {
    const { value } = eventModalTrash.target;
    const trimmedValue = trimExtraSpaces(value);
    setReasonElimination({
      ...reasonElimination,
      reason: trimmedValue,
    });
  };

  /**
   * Handles the submission of a modal for moving a file to the trash.
   * @param {*} _ - Ignored parameter (can be any value).
   * @param {Array} errors - An array of validation errors (empty if no errors).
   * @return {Void}
   */
  const handleOnSubmitModalTrash = (_, errors) => {
    if (errors.length === 0) {
      setIsLoadingEliminationFile(true);
      moveFileToTrash(fileId, reasonElimination)
        .then((response) => {
          if (response.code === 4027) {
            setIsOpenFileMoveTrash(false);
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("recordDetail.files.remove7"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsChangePage((prevIsChange) => !prevIsChange);
          setIsLoadingEliminationFile(false);
          setIsOpenFileMoveTrash(false);
        });
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Modal isOpen={isOpenFileMoveTrash} className="size-modal-responsive modal-custom-width">
          <ModalHeader>
            <h5 className="text-info font-weight-bold  font-weight-normal">
              {i18n.t("recordDetail.files.remove6")}
            </h5>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmitModalTrash}>
              <Row>
                <Col md="12">
                  <Alert className="mbg-3" color="info" isOpen={true}>
                    <span className="pr-2 d-flex  align-items-center">
                      <FontAwesomeIcon icon={faInfoCircle} className="d-flex" />
                      <h5 className="font-weight-bold pl-2">
                        {i18n.t("recordDetail.files.remove4")}
                      </h5>
                    </span>
                    <span className="pr-2">
                      {i18n.t("recordDetail.files.remove1")}
                      <span className="font-weight-bold">
                        {i18n.t("recordDetail.files.remove2")}
                      </span>
                      {i18n.t("recordDetail.files.remove3")}
                    </span>
                  </Alert>

                  <ReactTooltip id="description" place="bottom" type="dark" effect="solid">
                    {i18n.t("RecordDetail.label10.1")}
                  </ReactTooltip>

                  <AvGroup>
                    <Label for="description" className="is-required" sm={12}>
                      {i18n.t("recordDetail.files.remove5")}
                    </Label>
                    <AvField
                      id="description"
                      name="description"
                      type="textarea"
                      onChange={handleOnChangeReason}
                      onBlur={handleOnBlur}
                      data-tip
                      data-for="description"
                      validate={{
                        pattern: {
                          value: regexTrashMotive,
                          errorMessage: `${i18n.t("createWorflow.alert")}`,
                        },
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                        minLength: {
                          value: enumsMinMaxCharacters.GENERAL_MIN,
                          errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t(
                            "and"
                          )} 150 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsMinMaxCharacters.TRASH_MOTIVE_MAX,
                          errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t(
                            "and"
                          )} 150 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={reasonElimination.reason}
                    />
                  </AvGroup>
                </Col>
              </Row>

              <CardFooter className="d-block text-right pr-0">
                <Button
                  size="lg"
                  onClick={handleOnCloseModalDeleteFile}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingEliminationFile}
                  className="col-mt-3 mr-3"
                  color="cyan"
                >
                  {loadingComponent}
                  {i18n.t("recordDetail.files.remove6")}
                </Button>
              </CardFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ModalTrashFile.propTypes = {
  isOpenFileMoveTrash: PropTypes.bool.isRequired,
  setIsOpenFileMoveTrash: PropTypes.func.isRequired,
};

export default ModalTrashFile;
