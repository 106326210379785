import React, { Fragment, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import {
  regexIntegrationContactName,
  regexIntegrationContactPhone,
  regexIntegrationDescription,
  regexIntegrationEmail
} from "utils/regexExpressions";
import { enumsRequestIntegrationForm } from "utils/enums";
import useIntegration from "hooks/useIntegrations";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import i18n from "locales/i18n";

const RequestIntegrationForm = () => {
  const { id } = useParams();
  const { requestInt } = useIntegration();
  const [isLoadingRequestIntegration, setIsLoadingRequestIntegration] = useState(false);
  const [requestIntegration, setRequestIntegration] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    description: "",
    integration_uuid: id,
  });

  let loadingComponent = null;

  /**
   * Renders a loading spinner component if the isLoading flag is true.
   * @returns {JSX.Element|null} Loading spinner component if isLoading is true, otherwise null.
   */
  if (isLoadingRequestIntegration === true) {
    loadingComponent = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Handles the change event of the integration request form inputs.
   * Updates the requestIntegration state with the new input values.
   * @param {ChangeEvent} eventIntegration - The change event object.
   * @returns {void}
   */
  const handleOnChangeRequestIntegration = (eventIntegration) => {
    setRequestIntegration({
      ...requestIntegration,
      [eventIntegration.target.name]: eventIntegration.target.value,
    });
  };

  /**
   * Handles the blur event of the integration request form inputs.
   * Updates the requestIntegration state with the trimmed input values.
   * @param {FocusEvent} eventIntegration - The blur event object.
   * @returns {void}
   */
  const handleOnBlurRequestIntegration = (eventIntegration) => {
    setRequestIntegration({
      ...requestIntegration,
      [eventIntegration.target.name]: eventIntegration.target.value.trim(),
    });
  };

  /**
   * Handles the form submission event of the integration request form.
   * Prevents the default form submission behavior.
   * If there are no validation errors, sends the integration request and displays a success alert upon successful request.
   * @param {Event} eventIntegration - The form submission event object.
   * @param {Array} errors - An array of validation errors.
   * @returns {void}
   */
  const handleOnSubmitRequestIntegration = (eventIntegration, errors) => {
    eventIntegration.preventDefault();
    if (errors.length === 0) {
      setIsLoadingRequestIntegration(true);
      requestInt(requestIntegration)
        .then((response) => {
          if (response.status === 201) {
            const showAlertRequestInt = () => {
              swal({
                title: `${i18n.t("integration.requestSolicit")} ${response.data.data.id
                  }`,
                text: i18n.t("integration.requestSolicitAlet"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location = "/integrations";
              });
            };
            showAlertRequestInt();
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingRequestIntegration(false);
        });
    }
  };

  /**
   * Handles the key press event of the integration request form inputs.
   * Prevents the default behavior if the pressed key is "Enter".
   * @param {KeyboardEvent} eventIntegration - The key press event object.
   * @returns {void}
   */
  const handleOnKeyPress = (eventIntegration) => {
    if (eventIntegration.key === "Enter") {
      eventIntegration.preventDefault();
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{i18n.t("integration.request")}</CardTitle>
              <AvForm onSubmit={handleOnSubmitRequestIntegration}>
                <AvGroup row>
                  <Label for="ContactName" className="is-required" sm={2}>
                    {i18n.t("integration.requestName")}
                  </Label>
                  <Col md={10}>
                    <ReactTooltip
                      id="ContactName"
                      place="bottom"
                      type="info"
                      effect="solid"
                    >
                      {i18n.t("integration.requestTooltip1")}
                    </ReactTooltip>
                    <AvField
                      id="contact_name"
                      name="contact_name"
                      type="text"
                      onChange={handleOnChangeRequestIntegration}
                      onBlur={handleOnBlurRequestIntegration}
                      onKeyPress={handleOnKeyPress}
                      data-tip
                      data-for="ContactName"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t(
                            "integration.requestReqName"
                          )}`,
                        },
                        minLength: {
                          value: enumsRequestIntegrationForm.CONTACT_NAME_MIN_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsRequestIntegrationForm.CONTACT_NAME_MAX_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                        },
                        pattern: {
                          value: regexIntegrationContactName,
                          errorMessage: `${i18n.t(
                            "integration.requestCharacters"
                          )}`,
                        },
                      }}
                      autoComplete="off"
                      value={requestIntegration.contact_name}
                    />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="email" className="is-required" sm={2}>
                    {i18n.t("integration.requesEmail")}
                  </Label>
                  <Col md={10}>
                    <AvField
                      id="contact_email"
                      name="contact_email"
                      type="text"
                      onChange={handleOnChangeRequestIntegration}
                      onBlur={handleOnBlurRequestIntegration}
                      onKeyPress={handleOnKeyPress}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t(
                            "integration.requestReqEmail"
                          )}`,
                        },
                        pattern: {
                          value: regexIntegrationEmail,
                          errorMessage: `${i18n.t(
                            "integration.requestConfigTipeEmail"
                          )}`,
                        },
                        minLength: {
                          value: enumsRequestIntegrationForm.CONTACT_EMAIL_MIN_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsRequestIntegrationForm.CONTACT_EMAIL_MAX_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={requestIntegration.contact_email.trim()}
                    />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="phone" className="is-required" sm={2}>
                    {i18n.t("integration.requesPhone")}
                  </Label>
                  <Col md={10}>
                    <AvField
                      id="contact_phone"
                      name="contact_phone"
                      type="text"
                      onChange={handleOnChangeRequestIntegration}
                      onBlur={handleOnBlurRequestIntegration}
                      onKeyPress={handleOnKeyPress}
                      validate={{
                        pattern: {
                          value: regexIntegrationContactPhone,
                          errorMessage: `${i18n.t(
                            "integration.requestTypeNum"
                          )}`,
                        },
                        required: {
                          value: true,
                          errorMessage: `${i18n.t(
                            "integration.requestReqPhone"
                          )}`,
                        },
                        minLength: {
                          value: enumsRequestIntegrationForm.CONTACT_PHONE_MIN_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 11 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsRequestIntegrationForm.CONTACT_PHONE_MAX_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 11 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={requestIntegration.contact_phone}
                    />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="description" className="is-required" sm={2}>
                    {i18n.t("integration.requesDescription")}
                  </Label>
                  <Col md={10}>
                    <ReactTooltip
                      id="description"
                      place="bottom"
                      type="info"
                      effect="solid"
                    >
                      {i18n.t("integration.requestTooltip2")}
                    </ReactTooltip>
                    <AvField
                      id="description"
                      name="description"
                      type="textarea"
                      onChange={handleOnChangeRequestIntegration}
                      onBlur={handleOnBlurRequestIntegration}
                      onKeyPress={handleOnKeyPress}
                      data-tip
                      data-for="description"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t(
                            "integration.requestReqDesc"
                          )}`,
                        },
                        pattern: {
                          value: regexIntegrationDescription,
                          errorMessage: `${i18n.t(
                            "integration.requestCharacters"
                          )}`,
                        },
                        minLength: {
                          value: enumsRequestIntegrationForm.DESCRIPTION_MIN_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 160 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: enumsRequestIntegrationForm.DESCRIPTION_MAX_LENGTH,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 4 ${i18n.t("and")} 160 ${i18n.t("characters")}`,
                        },
                      }}
                      autoComplete="off"
                      value={requestIntegration.description}
                    />
                  </Col>
                </AvGroup>

                <CardFooter className="d-block text-right">
                  <Link to="/integrations">
                    <Button
                      size="lg"
                      disabled={isLoadingRequestIntegration}
                      className="col-mt-3 mr-3"
                      color="gray"
                    >
                      {loadingComponent}
                      {i18n.t("createusers.createButton2")}
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isLoadingRequestIntegration}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {loadingComponent}
                    {i18n.t("integration.requestButton")}
                  </Button>
                </CardFooter>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default RequestIntegrationForm;
