import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import ButtonActionsFields from "components/atoms/ButtonActionsFields";
import { getDateFormat2 } from "utils/getDateFormat2";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";
const FieldsListTable = (props) => {
  const {
    fields,
    pagination,
    setPagination,
    isLoading,
    totalPages,
    hasRecords,
    editFields,
    count,
  } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={fields}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("form.fieldtable1")],
                  accessor: "order",
                },
                {
                  Header: [i18n.t("form.fieldtable2")],
                  accessor: "label",
                },
                {
                  Header: [i18n.t("form.field5")],
                  accessor: "type_data",

                  Cell: ({ value }) => {
                    let valueFinal = "";
                    switch (value) {
                      case 1:
                        valueFinal = [i18n.t("form.field7")];
                        break;
                      case 2:
                        valueFinal = i18n.t("form.field8");
                        break;
                      case 3:
                        valueFinal = i18n.t("form.field9");
                        break;
                      case 4:
                        valueFinal = i18n.t("form.field10");
                        break;
                      default:
                        break;
                    }
                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("form.field3")],
                  accessor: "control_data",
                  Cell: ({ value }) => {
                    let valueFinal = "";
                    switch (value) {
                      case 1:
                        valueFinal = i18n.t("form.field12");
                        break;
                      case 2:
                        valueFinal = i18n.t("form.field13");
                        break;
                      case 3:
                        valueFinal = i18n.t("form.field14");
                        break;
                      case 4:
                        valueFinal = i18n.t("form.field15");
                        break;
                      case 5:
                        valueFinal = i18n.t("form.field16");
                        break;
                      case 6:
                        valueFinal = i18n.t("form.field17");
                        break;
                      case 7:
                        valueFinal = i18n.t("form.field18");
                        break;
                      case 8:
                        valueFinal = i18n.t("form.field61");
                        break;
                      default:
                        break;
                    }
                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("itemList.designerLabel3")],
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));

                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("form.designerLabel3")],
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("filteruser.item2")}
                        </div>
                      );
                    } else {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("deleteField.state")}
                        </div>
                      );
                    }

                    return <span>{statusFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("form.field47")],
                  accessor: "updated_at",
                  Cell: ({ value }) => {
                    if (isNullOrUndefined(value) === true) return null;
                    let valueFinal = getDateFormat2(new Date(value));
                    return <span>{valueFinal}</span>;
                  },
                },
                {
                  Header: [i18n.t("createusers.label1")],
                  accessor: "user_name",
                },
              ],
            },
            {
              columns: [
                {
                  Header: [i18n.t("buttonActions.title")],
                  accessor: "actions",
                  Cell: (row) => {
                    return (
                      <ButtonActionsFields
                        fieldId={row.original.uuid}
                        formId={row.original.form_uuid}
                        hasRecords={hasRecords}
                        status={row.original.status}
                        editFields={editFields}
                        count={count}
                        fields={fields}
                        order={fields.order}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          manual
          pageSize={10}
          loading={isLoading}
          pages={totalPages}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
          className="-striped -highlight text-center"
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

FieldsListTable.propTypes = {
  fields: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  hasRecords: PropTypes.bool.isRequired,
  editFields: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
};

export default FieldsListTable;
