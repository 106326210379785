import React, { useCallback, useState } from "react";
import { isNullOrUndefined } from "utils/validations";
import Label from "components/molecules/Fields/Strings/Label";
import DatePicker from "components/molecules/Fields/Date/DatePicker";
import DateTimePicker from "components/molecules/Fields/DateTime/DateTimePicker";
import NumericTextBox from "components/molecules/Fields/Integer/NumericTextBox";
import ComboBox from "components/molecules/Fields/Strings/ComboBox";
import TextBox from "components/molecules/Fields/Strings/TextBox";
import TextArea from "components/molecules/Fields/Strings/TextArea";
import Lookup from "components/molecules/Fields/Strings/Lookup";
import useForm from "hooks/useForm";
import i18n from "locales/i18n";
import swal from "sweetalert";

const FieldsRecordContext = React.createContext(undefined);
export function FieldsRecordContextProvider({ children }) {
  const initialStateForm = {
    form_uuid: "",
    type_data: 0,
    control_data: 0,
    label: "",
    hide: false,
    required: false,
    search_field: false,
    status: 1,
  };
  const [isLoadingDeleteField, setIsLoadingDeleteField] = useState(false);
  const [formName, setFormName] = useState("");
  const [showTabs, setShowTabs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [fieldsForm, setFieldsForm] = useState([]);
  const [typeControl, setTypeControl] = useState([]);
  const [fieldForm, setFieldForm] = useState(initialStateForm);
  const [hasRecords, setHasRecords] = useState(false);
  const [recordForm, setRecordForm] = useState({});
  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, per_page: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [editFields, setEditFields] = useState(false);
  const [statusForm, setStatusForm] = useState("");
  const [count, setCount] = useState(0);
  const [recordFields, setRecordFields] = useState({});
  const [showModalForLookup, setShowModalForLookup] = useState(false);
  const [concatenatedValues, setConcatenatedValues] = useState("");
  const [uuidRecordLookup, setUuidRecordLookup] = useState("");
  const [fieldValidationUnique, setFieldValidationUnique] = useState(false);
  const [valueFieldLookup, setValueFieldLookup] = useState("");
  const [validationField, setValidationField] = useState({
    unique: false,
    id: [],
  });
  const [validationFieldDatePicker, setValidationFieldDatePicker] = useState({
    required: false,
    id: [],
  });
  const [validationEditField, setValidationEditField] = useState({
    unique: false,
    id: [],
  });
  const { getFields, createFields, getFormsById } = useForm();

  /**
   * Structures and augments data fields by combining 'type_data' and 'control_data' into 'type_control'.
   * @param {Array<Object>} data - The array of data objects to be processed and structured.
   * @returns {Array<Object>} An array of data objects with 'type_control' property added.
   */
  const structureDataFields = (data) => {
    return data.map((item) => {
      item.type_control = `${item.type_data},${item.control_data}`;
      return item;
    });
  };

  /**
   * Funtion for fetches fields by performing a search using the provided ID and pagination parameters,
   * and updates various state variables based on the fetched data.
   * @param {string} id - The identifier used for the search operation.
   * @returns {void}
   */
  const getFieldsBySearch = useCallback(
    (id) => {
      const { page, per_page } = pagination;
      setIsLoading(true);
      getFields(page, per_page, id, 0)
        .then((response) => {
          const listFields = response.data.items;
          const pages = response.data.pages;
          const hasRecords = response.data.has_records;
          const editFields = response.data.edit_fields;
          const count = response.data.count;

          if (
            isNullOrUndefined(listFields) === false &&
            isNullOrUndefined(pages) === false &&
            isNullOrUndefined(hasRecords) === false &&
            isNullOrUndefined(editFields) === false &&
            isNullOrUndefined(count) === false
          ) {
            setFields(listFields);
            setTotalPages(pages);
            setHasRecords(hasRecords);
            setEditFields(editFields);
            setCount(count);
            if (listFields.length === 0) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("form.fieldlist.isEmpty"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          } else {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("error.data.submit"),
              icon: "error",
              dangerMode: true,
              button: i18n.t("modal.Done.footerButton"),
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [pagination] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Funtion for fetches a preview of fields using the provided ID and actions, and updates the component state accordingly.
   * @param {string} id - The identifier for the fields to fetch.
   * @param {object} actions - Actions or configurations for retrieving fields.
   * @returns {void}
   */
  const getFieldsPreview = useCallback((id, actions) => {
    setIsLoading(true);
    let paginations = { page: 1, per_page: 1000 };
    const { page, per_page } = paginations;
    getFields(page, per_page, id, actions)
      .then((response) => {
        const listFields = response.data.items;
        if (isNullOrUndefined(listFields) === false) {
          setTypeControl(structureDataFields(listFields));
        } else {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("error.data.submit"),
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Retrieves form data by a given UUID, updates related states, and manages loading status.
   * @param {string} id - The UUID used to identify the specific form.
   * @returns {void}
   */
  const getFormsByUuid = useCallback((id) => {
    setIsLoading(true);
    getFormsById(id, 0)
      .then((response) => {
        const nameForm = response.data.name;
        const statusForm = response.data;
        if (
          isNullOrUndefined(nameForm) === false &&
          isNullOrUndefined(statusForm) === false
        ) {
          setFormName(nameForm);
          setStatusForm(statusForm);
        } else {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("error.data.submit"),
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for an input field, updating the state accordingly.
   * @param {Object} e - The event object triggered by the input field change.
   * @param {string} e.target.value - The value of the input field.
   * @param {boolean} e.target.checked - The checked state of the input field (for checkboxes).
   * @param {string} e.target.name - The name attribute of the input field.
   * @returns {void}
   */
  const handleOnChange = (e) => {
    const target = e.target.value;
    const targetCheckbox = e.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setFieldForm({
        ...fieldForm,
        [e.target.name]: Boolean(targetCheckbox),
      });
    } else {
      setFieldForm({
        ...fieldForm,
        [e.target.name]: target,
      });
    }
  };

  /**
   * Handles the submission of form values to create fields, and returns a result based on the outcome.
   * @param {object} values - The values to be submitted, typically from a form.
   * @returns {Promise<string>} A Promise that resolves to a string indicating the result of the submission:
   * - "success" when the fields are successfully created.
   * - "error400" when a 400 status error is encountered during the submission.
   * - "error" when an error other than a 400 status error occurs during the submission.
   */
  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    let result;
    try {
      const response = await createFields(fieldForm);
      if (response.status === 201) {
        getFieldsBySearch(fieldForm.form_uuid);
        result = "success";
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        result = "error400";
      } else {
        result = "error";
      }
    } finally {
      setIsLoading(false);
    }

    return result;
  };

  /**
   * Validates and processes fields based on the given type control, field information, and preview mode.
   * @param {string} typeControl - The type of control that determines how to handle the field.
   * @param {object} fieldInfo - Information about the field to be processed.
   * @param {boolean} isPreview - Indicates whether the processing is in preview mode.
   * @returns {void}
   */
  const validateFieldsByTypeControl = (typeControl, fieldInfo, isPreview) => {
    const {
      hide,
      unique,
      label,
      len_min_chars,
      len_max_chars,
      required,
      item_link,
      order,
      uuid,
      allowed_negative,
      show_pass_dates,
      edit_manually,
      status,
      form_lookup_name,
      form_lookup_fields,
      form_uuid,
    } = fieldInfo;

    /**
     * Handles the change of a field's value in a record form and updates the corresponding state.
     * @param {string} fieldId - The identifier of the field whose value is being changed.
     * @param {any} value - The new value to assign to the specified field.
     * @returns {void}
     */
    const handleOnChangeRecord = (fieldId, value) => {
      setRecordForm((currentValues) => {
        currentValues[fieldId] = value;
        return currentValues;
      });
    };

    /**
     * Handles the key press event and prevents the "Enter" key's default behavior.
     * @param {object} event - The key press event object.
     */
    const handleOnKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    /**
     * Dynamically adds a form field component to the 'fieldsForm' state based on the 'typeControl'.
     * @param {string} typeControl - The control type identifier for determining which component to add.
     * @param {number} order - The order of the field in the form.
     * @param {string} uuid - The unique identifier for the field.
     * @param {string} label - The label text for the field.
     * @param {boolean} hide - Indicates whether the field should be hidden.
     * @param {number} len_min_chars - The minimum character length for text-based fields.
     * @param {number} len_max_chars - The maximum character length for text-based fields.
     * @param {boolean} required - Indicates whether the field is required.
     * @param {boolean} unique - Indicates whether the field's value should be unique.
     * @param {string} status - The status of the field.
     * @param {boolean} isPreview - Indicates whether the field is in preview mode.
     * @param {function} handleOnChangeRecord - Callback function for handling changes in the field's value.
     * @param {function} handleOnKeyPress - Callback function for handling keypress events in the field.
     * @param {object} recordForm - The form record object containing field values.
     * @param {string} item_link - The link to fetch items for the ComboBox component (only for typeControl "1,3").
     * @returns {void}
     */
    switch (typeControl) {
      case "1,1":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <TextBox
                id={uuid}
                label={label}
                hide={hide}
                unique={unique}
                required={required}
                isPreview={isPreview}
                minLength={len_min_chars}
                maxLength={len_max_chars}
                handleOnChangeRecord={handleOnChangeRecord}
                handleOnKeyPress={handleOnKeyPress}
                status={status}
              />
            ),
          },
        ]);
        break;

      case "1,2":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <TextArea
                id={uuid}
                label={label}
                hide={hide}
                required={required}
                isPreview={isPreview}
                minLength={len_min_chars}
                maxLength={len_max_chars}
                handleOnChangeRecord={handleOnChangeRecord}
                handleOnKeyPress={handleOnKeyPress}
                status={status}
              />
            ),
          },
        ]);

        break;

      case "1,3":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <ComboBox
                id={uuid}
                hide={hide}
                label={label}
                required={required}
                isPreview={isPreview}
                isFilterForm={false}
                itemLink={item_link}
                handleOnChangeRecord={handleOnChangeRecord}
                status={status}
              />
            ),
          },
        ]);
        break;

      case "1,4":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <Label
                id={uuid}
                hide={hide}
                isPreview={isPreview}
                label={label}
                status={status}
              />
            ),
          },
        ]);
        break;

      case "1,8":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <Lookup
                id={uuid}
                hide={hide}
                label={label}
                required={required}
                isPreview={isPreview}
                isFilterForm={false}
                handleOnChangeRecord={handleOnChangeRecord}
                handleOnKeyPress={handleOnKeyPress}
                formLookupName={form_lookup_name}
                formLookupFields={form_lookup_fields}
                formUuid={form_uuid}
                status={status}
              />
            ),
          },
        ]);
        break;

      case "2,5":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <NumericTextBox
                id={uuid}
                label={label}
                hide={hide}
                unique={unique}
                required={required}
                isPreview={isPreview}
                allowedNegative={allowed_negative}
                handleOnChangeRecord={handleOnChangeRecord}
                handleOnKeyPress={handleOnKeyPress}
                status={status}
              />
            ),
          },
        ]);
        break;

      case "3,6":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <DatePicker
                id={uuid}
                label={label}
                hide={hide}
                required={required}
                isPreview={isPreview}
                isFilterForm={false}
                edit_manually={edit_manually}
                show_pass_dates={show_pass_dates}
                handleOnChangeRecord={handleOnChangeRecord}
                validateRequiredPickers={undefined}
                status={status}
                typeControl={typeControl}
              />
            ),
          },
        ]);
        break;

      case "4,7":
        setFieldsForm((prevState) => [
          ...prevState,
          {
            order: order,
            id: uuid,
            component: (
              <DateTimePicker
                id={uuid}
                label={label}
                hide={hide}
                required={required}
                isPreview={isPreview}
                isFilterForm={false}
                edit_manually={edit_manually}
                show_pass_dates={show_pass_dates}
                handleOnChangeRecord={handleOnChangeRecord}
                status={status}
                typeControl={typeControl}
              />
            ),
          },
        ]);
        break;

      default:
        break;
    }
  };

  return (
    <FieldsRecordContext.Provider
      value={{
        initialStateForm,
        handleOnSubmit,
        showTabs,
        setShowTabs,
        fieldForm,
        setFieldForm,
        handleOnChange,
        isLoading,
        setIsLoading,
        structureDataFields,
        validateFieldsByTypeControl,
        typeControl,
        setTypeControl,
        setFieldsForm,
        fieldsForm,
        formName,
        getFormsByUuid,
        fields,
        setPagination,
        pagination,
        totalPages,
        getFieldsBySearch,
        getFieldsPreview,
        isPreview,
        setIsPreview,
        recordForm,
        setRecordForm,
        validationField,
        setValidationField,
        validationFieldDatePicker,
        setValidationFieldDatePicker,
        hasRecords,
        setHasRecords,
        recordFields,
        setRecordFields,
        validationEditField,
        setValidationEditField,
        editFields,
        count,
        statusForm,
        showModalForLookup,
        setShowModalForLookup,
        concatenatedValues,
        setConcatenatedValues,
        uuidRecordLookup,
        setUuidRecordLookup,
        fieldValidationUnique,
        setFieldValidationUnique,
        valueFieldLookup,
        setValueFieldLookup,
        isLoadingDeleteField,
        setIsLoadingDeleteField,
      }}
    >
      {children}
    </FieldsRecordContext.Provider>
  );
}
export function useFieldsRecordContext() {
  const context = React.useContext(FieldsRecordContext);
  if (!context) {
    throw new Error(
      "useFieldsRecordContext debe estar dentro del proveedor FieldsRecordContext."
    );
  }
  return context;
}
