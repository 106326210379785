import React, { Fragment, useEffect, useCallback, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  CardFooter,
  Button,
} from "reactstrap";
import i18n from "locales/i18n";
import { useListContext } from "contextAPI/ListsContext";
import useList from "hooks/useList";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { loaderComponent, loaderElement } from "utils/loaderElement";
import { regexEditItemName } from "utils/regexExpressions";
import ReactTooltip from "react-tooltip";

const EditItemsListForm = () => {
  const { listId, id } = useParams();
  const {
    isLoading,
    setIsLoadingGetList,
    itemName,
    setItemName,
    parentModal,
    item,
    setItem,
  } = useListContext();
  const { getItemsById, updateItem } = useList();
  const [itemTemp, setItemTemp] = useState({});
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  let routeRedirect = null;
  let updateButtonDisabled = false;

  /**
   * Generates a route for redirection based on the provided parameters.
   * @param {string} listId - The unique identifier of the items list.
   * @param {string|null|undefined} parentModal - The optional parent modal identifier. Can be null or undefined.
   * @returns {string} The generated route for redirection.
   */
  if (isNullOrUndefined(parentModal) === false) {
    routeRedirect = `/formsdesigner/itemslist/${listId}/${parentModal}`;
  } else {
    routeRedirect = `/formsdesigner/itemslist/${listId}`;
  }

  /**
   * Checks if the properties of two items are identical, and sets a flag accordingly.
   * @param {Object} itemName - The first item object for comparison.
   * @param {string} itemName.name - The name property of the first item.
   * @param {string} itemName.parent_id - The parent ID property of the first item.
   * @param {string} itemName.status - The status property of the first item.
   *
   * @param {Object} itemTemp - The second item object for comparison.
   * @param {string} itemTemp.name - The name property of the second item.
   * @param {string} itemTemp.parent_id - The parent ID property of the second item.
   * @param {string} itemTemp.status - The status property of the second item.
   * @returns {boolean} updateButtonDisabled - A flag indicating whether the button should be disabled.
   */
  if (
    itemName.name === itemTemp.name &&
    itemName.parent_id === itemTemp.parent_id &&
    itemName.status === itemTemp.status
  ) {
    updateButtonDisabled = true;
  }

  /**
   * Retrieves item data by ID, updates state variables with item information,
   * and manages loading state.
   * @throws {Error} Throws an error if the response does not contain valid item data.
   * @param {string} listId - The identifier of the list containing the item.
   * @param {string} id - The unique identifier of the item to be retrieved.
   * @param {function} setIsLoadingItems - State setter function to manage the loading state for items.
   * @param {function} setItemName - State setter function to update the main item information.
   * @param {function} setItemTemp - State setter function to update a temporary copy of the item information.
   * @param {function} showAlertServiceError - Function to display an alert in case of a service error.
   * @returns {void}
   */
  const getItemsByIdFunc = useCallback(() => {
    setIsLoadingItems(true);
    getItemsById(listId, id)
      .then((response) => {
        const itemData = response.data;
        if (isNullOrUndefined(itemData) === false) {
          setItemName({
            name: itemData.name,
            parent_id: itemData.parent_id,
            status: Number(itemData.status),
          });
          setItemTemp({
            name: itemData.name,
            parent_id: itemData.parent_id,
            status: Number(itemData.status),
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingItems(false);
      });
  }, [id, listId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles key press events, preventing the default behavior if the pressed key is "Enter".
   * @param {object} eventKeyPress - The key press event object.
   * @returns {void}
   */
  const handleOnKeyPress = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * Handles the change of a field in the item name, updating the state accordingly.
   * This function is used to update the state of the item name based on changes to a specific field. If the new value
   * is different from the original value of the field, it updates only that field in the state. If the new value is the
   * same as the original value, it updates the state only for that field; otherwise, it retains the current state and
   * updates the specified field with the new value.
   * @param {string} field - The name of the field to be updated.
   * @param {any} value - The new value for the specified field.
   * @returns {void}
   */
  const handleOnchange = (field, value) => {
    if (itemName[field] === itemTemp[field]) {
      setItemName({
        [field]: value,
      });
    } else {
      setItemName({
        ...itemName,
        [field]: value,
      });
    }
  };

  /**
   * Handles the form submission, updating an item in a list, and managing the loading state.
   * @param {Event} eventSubmit - The submit event triggered by the form.
   * @param {Array} errors - An array containing validation errors, if any.
   * @returns {void}
   */
  const handleOnSubmit = (eventSubmit, errors) => {
    eventSubmit.preventDefault();
    setIsLoadingGetList(true);
    if (errors.length === 0) {
      if (listId && id) {
        updateItem(listId, id, itemName)
          .then((response) => {
            if (response.status === 202) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("itemList.UpdatedSucess"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                setIsLoadingGetList(true);
                if (isNullOrUndefined(parentModal) === true) {
                  window.location = `/formsdesigner/itemslist/${listId}`;
                } else {
                  window.location = `/formsdesigner/itemslist/${listId}/${parentModal}`;
                }
              });
            }
          })
          .finally(() => {
            setIsLoadingGetList(false);
          });
      }
    }
  };

  /**
   * useEffect hook that invokes the provided function 'getItemsByIdFunc' when dependencies change.
   * This effect is triggered whenever 'getItemsByIdFunc' changes, ensuring that the function is called
   * when its dependencies are updated.
   * @param {Function} getItemsByIdFunc - The function responsible for fetching items by their ID.
   * @returns {void}
   */
  useEffect(() => {
    getItemsByIdFunc();
  }, [getItemsByIdFunc]);

  if (isLoadingItems === true) {
    return loaderComponent(isLoadingItems);
  } else {
    return (
      <Fragment>
        {loaderElement(isLoading)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>{i18n.t("itemList.edit3")}</CardTitle>
              <AvForm onSubmit={handleOnSubmit}>
                <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
                  {i18n.t("trd.Tooltip10")}
                </ReactTooltip>
                <AvGroup row>
                  <Label for="list_name" className="is-required" sm={4}>
                    {i18n.t("itemList.designerLabel1")}{" "}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="name"
                      name="name"
                      type="text"
                      data-tip
                      data-for="name"
                      autoComplete="off"
                      value={itemTemp.name}
                      onChange={(e) => handleOnchange("name", e.target.value)}
                      onKeyPress={handleOnKeyPress}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                        pattern: {
                          value: regexEditItemName,
                          errorMessage: `${i18n.t("list.designerFeedback2")}`,
                        },
                        minLength: {
                          value: 2,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                        maxLength: {
                          value: 100,
                          errorMessage: `${i18n.t(
                            "fieldValidateLengthBetween"
                          )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                        },
                      }}
                    />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="parent_item" className="is-required" sm={4}>
                    {i18n.t("itemList.designerLabel2")}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="parent_item"
                      name="parent_item"
                      type="select"
                      autoComplete="off"
                      value={itemTemp.parent_id}
                      onChange={(e) =>
                        handleOnchange("parent_id", e.target.value)
                      }
                    >
                      <option value={""}>{i18n.t("list.EditNoApply")}</option>
                      {item.map((e) => {
                        return (
                          <option key={e.value} value={e.value}>
                            {e.label}
                          </option>
                        );
                      })}
                    </AvField>
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="status" className="is-required" sm={4}>
                    {i18n.t("form.designerLabel3")}
                  </Label>
                  <Col md={6}>
                    <AvField
                      id="status"
                      name="status"
                      type="select"
                      value={itemTemp.status}
                      onChange={(e) =>
                        handleOnchange("status", Number(e.target.value))
                      }
                      validate={{
                        required: {
                          value: true,
                          errorMessage: `${i18n.t("fieldRequired")}`,
                        },
                      }}
                    >
                      <option value={1}>{i18n.t("filteruser.item2")}</option>
                      <option value={2}>{i18n.t("filteruser.item3")}</option>
                    </AvField>
                  </Col>
                </AvGroup>

                <CardFooter className="d-block text-right">
                  <Link to={routeRedirect}>
                    <Button
                      size="lg"
                      disabled={isLoading}
                      className="col-mt-3 mr-3"
                      color="gray"
                      onClick={() => setItem([])}
                    >
                      {i18n.t("createusers.createButton2")}
                    </Button>
                  </Link>

                  <Button
                    type="submit"
                    size="lg"
                    disabled={updateButtonDisabled}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {i18n.t("createusers.createButton3")}
                  </Button>
                </CardFooter>
              </AvForm>
            </CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default EditItemsListForm;