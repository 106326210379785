import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import cx from "classnames";
import i18n from "locales/i18n";

const SearchBoxExpedientFiles = (props) => {
  const {
    searchFile,
    setSearchFile,
    pagination,
    setPagination,
    getFilesByExpedientSearch,
    expdientId,
  } = props;

  /**
   *Event handler function triggered when the search input value changes.
   *@param {object} eventChange - the event object representing the change event
   *@param {string} eventChange.target.value - the value typed in the search input
   *@param {function} setSearchExpedient - the state setter function for the search expedient value
   */
  const handleOnChangeSearch = (eventChange) => {
    const searchValueTiped = eventChange.target.value;
    setSearchFile(searchValueTiped);
  };

  /**
   *Handles the key press event for the search input.
   *@param {object} eventSearch - the key press event object
   *@param {string} eventSearch.key - the key that was pressed
   *@param {object} eventSearch.target - the target element where the key press event occurred
   *@param {string} eventSearch.target.value - the value typed in the search input
   *@param {object} pagination - the pagination object containing page and per_page properties
   *@param {number} pagination.page - the current page number
   *@param {number} pagination.per_page - the number of items per page
   *@param {string} node.id - the ID of the node
   *@param {string} node.background_parent_id - the ID of the background parent node
   *@param {string} searchExpedient - the current search value for the expedients
   *@param {function} setSearchExpedient - the state setter function for the search expedient value
   *@param {function} setPagination - the state setter function for the pagination object
   *@param {function} getExpedientsData - the function to fetch expedient data with specified parameters
   */
  const handleOnKeyPressForSearch = (eventSearch) => {
    if (eventSearch.key === "Enter") {
      const searchValueTiped = eventSearch.target.value;
      setSearchFile(searchValueTiped);
      if (pagination !== undefined) {
        setPagination({ ...pagination, page: 1 });
      }
      getFilesByExpedientSearch(1, pagination.per_page, expdientId, searchFile);
    }
  };

  /**
   *Event handler function triggered when the open button is clicked.
   *Updates the search criteria and pagination settings, and retrieves expedient data.
   *@param {string} searchExpedient - the search criteria for the expedient
   *@param {object} pagination - the current pagination settings
   *@param {number} pagination.page - the current page number
   *@param {number} pagination.per_page - the number of items per page
   *@param {number} node.id - the ID of the node
   *@param {number} node.background_parent_id - the ID of the background parent node
   *@param {function} getExpedientsData - the function to retrieve expedient data
   */
  const handleOnClickOpenButton = () => {
    setSearchFile(searchFile);
    if (pagination !== undefined) {
      setPagination({ ...pagination, page: 1 });
    }
    getFilesByExpedientSearch(1, pagination.per_page, expdientId, searchFile);
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div
          className={cx("search-wrapperExplorer ml-auto search-box", {
            active: true,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              className="search-input"
              placeholder={i18n.t("searchbox.placeholder")}
              onKeyPress={handleOnKeyPressForSearch}
              onChange={handleOnChangeSearch}
              value={searchFile}
            />
            <button className="search-icon" onClick={handleOnClickOpenButton}>
              <span />
            </button>
          </div>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SearchBoxExpedientFiles.propTypes = {
  searchFile: PropTypes.string.isRequired,
  setSearchFile: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default SearchBoxExpedientFiles;
