import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { enumsFilterWorkflowState } from "utils/enums";
import i18n from "locales/i18n";

const FilterWorkflowDetailState = (props) => {
  const { setStatus, setPagination, pagination} = props;
  
  /**
   * Function to handle the change event on a select a filter for an element.
   * Sets the status value to the integer parsed from the selected value.
   * Also sets the pagination page value to 1.
   * @param {object} evenOnChangeFilter - The event object that triggered the function call.
   */
  const handleOnChangeFilterWorkflowState = (evenOnChangeFilter) => {
    setStatus(parseInt(evenOnChangeFilter.target.value));
    setPagination({ ...pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("createWorflow.filter")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem value={enumsFilterWorkflowState.ALL} onClick={handleOnChangeFilterWorkflowState}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.IN_PROGRESS} onClick={handleOnChangeFilterWorkflowState}>
                {i18n.t("createWorflow.Label3.21")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.FINISHED} onClick={handleOnChangeFilterWorkflowState}>
                {i18n.t("createWorflow.Label3.31")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.INTERRUPTED} onClick={handleOnChangeFilterWorkflowState}>
                {i18n.t("createWorflow.Label3.41")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

FilterWorkflowDetailState.propTypes = {
  setStatus: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.object.isRequired,
};

export default FilterWorkflowDetailState;
