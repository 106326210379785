import React from 'react';
import Loader from "react-loaders";

const LoadingComponent = () => {
  return (
    <div className="text-center mx-auto my-auto">
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="primary" type="ball-pulse-rise" />
      </div>
    </div>
  )
}

export default LoadingComponent