import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, CardHeader } from "reactstrap";
import { useListContext } from "contextAPI/ListsContext";
import { regexNameList } from "utils/regexExpressions";
import { loaderElement } from "utils/loaderElement";
import { enumsCreateList } from "utils/enums";
import ReactTooltip from "react-tooltip";
import i18n from "locales/i18n";

const CreateListForm = () => {
  const {
    setCanNext,
    list,
    getListsBySearch,
    isLoading,
    createListState,
    setCreateListState,
    setErrorList,
  } = useListContext();

  /**
   * Handles key press events, preventing the default behavior if the pressed key is the "Enter" key.
   * @param {object} eventKeyPress - The key press event object.
   * @param {string} eventKeyPress.key - The key that was pressed.
   * @param {Function} eventKeyPress.preventDefault - A function to prevent the default behavior of the key press event.
   * @returns {void}
   */
  const handleOnKeyPress = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * Handles the onBlur event for the name input field of a list creation form.
   * @param {Event} eventBlurListName - The onBlur event object for the name input field.
   * @returns {void}
   */
  const handleOnBlurNameList = (eventBlurListName) => {
    if (createListState.name !== "") {
      setCreateListState({
        ...createListState,
        name: eventBlurListName.target.value.trim(),
      });
    } else {
      setCreateListState({
        name: eventBlurListName.target.value.trim(),
      });
    }
  };

  /**
   * Handles the change event for updating the name of a list.
   * @param {Event} eventChangeListName - The change event object containing information about the list name change.
   * @param {object} createListState - The current state object representing the list being created.
   * @param {string} createListState.name - The name of the list being created.
   * @param {function} setCreateListState - The state setter function to update the state of the list being created.
   * @returns {void}
   */
  const handleOnChangeNameList = (eventChangeListName) => {
    if (createListState.name !== "") {
      setCreateListState({
        ...createListState,
        name: eventChangeListName.target.value,
      });
    } else {
      setCreateListState({
        name: eventChangeListName.target.value,
      });
    }
  };

  /**
   * Handles the change event of the parent list selection.
   * @param {object} eventChangeParentList - The event object representing the change in the parent list selection.
   * @param {string} eventChangeParentList.target.value - The value selected in the parent list.
   * @returns {void}
   */
  const handleOnChangeParentList = (eventChangeParentList) => {
    if (eventChangeParentList.target.value !== "") {
      setCreateListState({
        ...createListState,
        parent_id: eventChangeParentList.target.value,
      });
    } else {
      const updatedListForm = { ...createListState };
      delete updatedListForm.parent_id;
      setCreateListState(updatedListForm);
    }
  };

  /**
   * Executes an effect to retrieve lists by search and reset error state when the component mounts.
   * @returns {void}
   */
  useEffect(() => {
    getListsBySearch();
    setErrorList(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that checks the validity of the name in the createListState object
   * and updates the state to enable or disable the next action accordingly.
   * @param {object} createListState - The state object containing the name to be validated.
   * @param {string} createListState.name - The name to be validated.
   * @param {boolean} setCanNext - State setter function for enabling or disabling the next action.
   * @returns {void}
   */
  useEffect(() => {
    if (
      !createListState.name ||
      createListState.name.length < 2 ||
      createListState.name.length > 100 ||
      !regexNameList.test(createListState.name)
    ) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [createListState.name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoading)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <AvForm className="mb-2">
            <CardHeader className="mb-4">
              {i18n.t("list.designerItem2")}
            </CardHeader>

            <AvGroup row>
              <ReactTooltip
                id="name_list"
                place="top"
                type="info"
                effect="solid"
              >
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>

              <Label for="list_name" className="is-required" sm={3}>
                {i18n.t("list.designerLabel1")}
              </Label>
              <Col md={9}>
                <AvField
                  id="name_list"
                  name="name_list"
                  type="text"
                  data-test-id="name-list"
                  data-tip
                  data-for="name_list"
                  autoComplete="off"
                  onChange={handleOnChangeNameList}
                  onBlur={handleOnBlurNameList}
                  onKeyPress={handleOnKeyPress}
                  value={createListState.name}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: `${i18n.t("fieldRequired")}`,
                    },
                    pattern: {
                      value: regexNameList,
                      errorMessage: `${i18n.t("list.designerFeedback1")}`,
                    },
                    minLength: {
                      value: enumsCreateList.MIN_LENGTH_NAME_LIST,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: enumsCreateList.MAX_LENGTH_NAME_LIST,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 2 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                  }}
                />
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="parent_List" sm={3}>
                {i18n.t("list.designerLabel2")}
              </Label>
              <Col md={9}>
                <AvField
                  id="parent_List"
                  name="parent_List"
                  type="select"
                  autoComplete="off"
                  onChange={handleOnChangeParentList}
                  onKeyPress={handleOnKeyPress}
                  disabled={isLoading}
                  value={createListState.parent_id}
                >
                  <option value={""}>{i18n.t("form.designerLabel10.1")}</option>

                  {list.map((list) => {
                    return (
                      <option key={list.value} value={list.value}>
                        {list.label}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
            </AvGroup>
          </AvForm>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default CreateListForm;
