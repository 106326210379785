import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams } from "react-router-dom";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { Alert, Row } from "reactstrap";
import { VIEW_FILES, TYPE } from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { typeUserForms } from "utils/formsQueryFunctions";
import { loaderComponent } from "utils/loaderElement";
import { enumsStatusUser } from "utils/enums";
import LoadingComponent from "components/atoms/LoadingComponent";
import RecordFileListTable from "components/organism/RecordFileListTable";
import RecordFileListTableTRD from "components/organism/RecordFileListTableTRD";
import useRecord from "hooks/useRecord";
import i18n from "locales/i18n";
import SearchBoxFiles from "components/atoms/SearchBoxFiles";

const RecordFileListTables = (props) => {
  const { isRecordCreate, trdExist } = props;
  const VIEWFILES = window.localStorage.getItem(VIEW_FILES);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const { recordId, id } = useParams();
  const {
    recordNumber,
    isChangePage,
    setIsChangePage,
    setCloseFileTables,
    setIsOpenFileMoveTrd,
    setIsOpenFileEdit,
    setIsOpenFileEditTrd,
    isOpenFileMoveTrd,
    isOpenFileEdit,
    isOpenFileEditTrd,
  } = useRecordDetailContext();
  const [files, setFiles] = useState([]);
  const [filesByRecord, setFilesByRecord] = useState([]);
  const [isLoadingFilesTrd, setIsLoadingFilesTrd] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [paginationRecord, setPaginationRecord] = useState(initialPaginationStandart);
  const [errorCharacters, setErrorCharacters] = useState("");
  const [searchedSubject, setSearchedSubject] = useState("");
  const [paginationTrd, setPaginationTrd] = useState(initialPaginationStandart);
  const [totalPagesFilesRecordTrd, setTotalPagesFilesRecordTrd] = useState(0);
  const [totalPagesFilesRecord, setTotalPagesFilesRecord] = useState(0);
  const { getFilesByTRD } = useRecord();
  const { getFilesByForm } = useRecord();
  let searchBoxFilesRender = null;
  let loaderRegisternotFound = false;

  /**
   * JSX Element for displaying an informational alert when no files are present.
   * @type {JSX.Element}
   */
  const alertNoFilesInfo = (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div className="mt-3 ">
          <Alert className="mbg-3" color="info">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
              <span className="font-weight-bold mbg-3">{i18n.t("modal.DoneError.header")} : </span>
              <span>¡{i18n.t("tableRowsEmpty")}!</span>
            </span>
          </Alert>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );

  /**
   * Renders a search box for files within a row layout, with various state management handlers.
   * @param {Object} props - The properties passed to the component.
   * @param {string} props.searchedSubject - The current value of the search input.
   * @param {Function} props.setSearchedSubject - The function to update the search input value.
   * @param {number} props.paginationRecord - The current pagination record.
   * @param {Function} props.setPaginationRecord - The function to update the pagination record.
   * @param {number} props.paginationTrd - The current pagination for the third-party data.
   * @param {Function} props.setPaginationTrd - The function to update the pagination for the third-party data.
   * @param {string} props.errorCharacters - The error message related to invalid characters in the search input.
   * @param {Function} props.setErrorCharacters - The function to update the error message for invalid characters.
   * @returns {JSX.Element} The rendered search box component within a row layout.
   */
  if (isOpenFileMoveTrd === false && isOpenFileEditTrd === false && isOpenFileEdit === false) {
    searchBoxFilesRender = (
      <Row className="mt-2 position-relative z-index-1 justify-content-end">
        <SearchBoxFiles
          isLoadingFilesTrd={isLoadingFilesTrd}
          isLoadingFiles={isLoadingFiles}
          searchedSubject={searchedSubject}
          setSearchedSubject={setSearchedSubject}
          paginationRecord={paginationRecord}
          setPaginationRecord={setPaginationRecord}
          paginationTrd={paginationTrd}
          setPaginationTrd={setPaginationTrd}
          errorCharacters={errorCharacters}
          setErrorCharacters={setErrorCharacters}
        />
      </Row>
    );
  } else {
    searchBoxFilesRender = null;
  }

  /**
   * Renders a table of files associated with a record in a TRD (Table of Record Data).
   * @param {Array} files - An array of files associated with the record.
   * @param {boolean} isLoading - Indicates whether data is currently being loaded.
   * @param {string} recordNumber - The identifier of the record.
   * @param {string} recordId - The UUID of the record.
   * @param {string} id - The identifier of the form.
   * @param {boolean} trdExist - Indicates whether the record exists in the TRD.
   * @returns {JSX.Element} The JSX component representing the table of files in the TRD.
   */
  const listTableFilesByTRD = (
    <div className="pt-3">
      <RecordFileListTableTRD
        files={files}
        isLoadingFilesTrd={isLoadingFilesTrd}
        recordId={recordNumber}
        recordUuid={recordId}
        formId={id}
        trdExistRec={trdExist}
        totalPagesFilesRecordTrd={totalPagesFilesRecordTrd}
        paginationTrd={paginationTrd}
        setPaginationTrd={setPaginationTrd}
      />
    </div>
  );

  /**
   * Displays a table of files associated with a record.
   * @param {Array} filesByRecord - An array of files associated with the record.
   * @param {boolean} isLoading - Indicates whether the component is in a loading state.
   * @param {string} recordNumber - The identifier of the record.
   * @param {string} recordId - The UUID of the record.
   * @param {string} id - The form ID.
   * @param {boolean} trdExist - Indicates whether the record exists in a TRD (Records Management) system.
   * @returns {JSX.Element} The component that displays the list of files.
   */
  const listTableFilesByRecord = (
    <div className="pt-3">
      <RecordFileListTable
        files={filesByRecord}
        isLoadingFiles={isLoadingFiles}
        recordId={recordNumber}
        recordUuid={recordId}
        formId={id}
        trdExistRec={trdExist}
        totalPagesFilesRecord={totalPagesFilesRecord}
        paginationRecord={paginationRecord}
        setPaginationRecord={setPaginationRecord}
      />
    </div>
  );

  /**
   * Sets the loaderRegisterNotFound flag based on the conditions:
   * - No files are present.
   * - No files by record are present.
   * - Both isLoadingFilesTrd and isLoadingFiles are true.
   * @param {boolean} isLoadingFilesTrd - Indicates if files for TRD are currently loading.
   * @param {boolean} isLoadingFiles - Indicates if general files are currently loading.
   * @param {Array} files - Array of files.
   * @param {Array} filesByRecord - Array of files grouped by record.
   */
  if (
    files.length === 0 &&
    filesByRecord.length === 0 &&
    isLoadingFilesTrd === true &&
    isLoadingFiles === true
  ) {
    loaderRegisternotFound = true;
  }

  /**
   * Conditionally render tables based on the presence of data.
   * This function checks the presence of two types of data (files and filesByRecord)
   * and renders tables accordingly.
   * @returns {JSX.Element} - Returns JSX elements representing tables.
   */
  const showTables = () => {
    if (
      files.length === 0 &&
      filesByRecord.length === 0 &&
      isLoadingFilesTrd === false &&
      isLoadingFiles === false
    ) {
      return <div>{alertNoFilesInfo}</div>;
    }

    if (files.length > 0 && filesByRecord.length > 0) {
      return (
        <Fragment>
          {listTableFilesByTRD}
          {listTableFilesByRecord}
        </Fragment>
      );
    }

    if (filesByRecord.length > 0) {
      return listTableFilesByRecord;
    }

    if (files.length > 0) {
      return listTableFilesByTRD;
    }

    return null;
  };

  /**
   * Fetches files by performing a search and updates the state accordingly.
   * This function fetches files by searching using the provided criteria and updates the component's state.
   * It also manages loading states and handles potential errors.
   * @param {boolean} isChangePage - A boolean flag indicating whether a page change has occurred.
   * @returns {void}
   */
  const getFilesBySearch = useCallback(() => {
    setIsLoadingFilesTrd(true);
    const { page, per_page } = paginationTrd;
    getFilesByTRD(
      recordId,
      typeUserForms(USERTYPE, isRecordCreate),
      page,
      per_page,
      searchedSubject
    )
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const filesList = response.data.items;
          setTotalPagesFilesRecordTrd(response.data.pages);
          setFiles(filesList);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFilesTrd(false);
      });
  }, [isChangePage, paginationTrd]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches files associated with a specific record and updates the state with the retrieved data.
   * This function triggers the loading state while making the API request to get files.
   * If files are successfully retrieved, they are stored in the state. If no files are found,
   * an error message is shown using 'showAlertServiceError'.
   * @param {string} recordId - The unique identifier of the record for which files are to be fetched.
   * @param {object} actions - Configuration object for the API request.
   * @param {function} setIsLoading - State setter function to toggle the loading state.
   * @param {function} setFilesByRecord - State setter function to update the files associated with the record.
   * @param {function} showAlertServiceError - Function to display an error alert if no files are found.
   * @param {boolean} isChangePage - A boolean indicating if a change in page triggers this function.
   * @returns {void}
   */
  const getFilesByRecordSearch = useCallback(() => {
    setIsLoadingFiles(true);
    const { page, per_page } = paginationRecord;
    getFilesByForm(
      recordId,
      typeUserForms(USERTYPE, isRecordCreate),
      page,
      per_page,
      searchedSubject
    )
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const filesByRecord = response.data.items;
          setTotalPagesFilesRecord(response.data.pages);
          setFilesByRecord(filesByRecord);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingFiles(false);
      });
  }, [isChangePage, paginationRecord]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect that fetches files based on search criteria and handles page change events.
   * @param {function} getFilesBySearch - A function responsible for fetching files based on a search criteria.
   *  @param {function} getFilesByRecordSearch - A function responsible for fetching files based on a search criteria.
   * @param {boolean} isChangePage - A boolean flag indicating whether a page change event has occurred.
   * @returns {void}
   */
  useEffect(() => {
    if (trdExist === true) {
      getFilesBySearch();
      getFilesByRecordSearch();
    } else {
      getFilesByRecordSearch();
    }
  }, [getFilesBySearch, getFilesByRecordSearch, isChangePage]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * An effect that updates various state variables to control file-related actions.
   * This effect is used to reset the states for closing, moving, and editing files in a component.
   * @param {boolean} setCloseFileTables - A state setter function to control the visibility of file tables.
   *   Setting this to true will close file tables.
   * @param {boolean} setIsOpenFileMoveTrd - A state setter function to control the visibility of a file move operation within a TRD (Technical Requirement Document).
   *   Setting this to false will close the file move operation within TRD.
   * @param {boolean} setIsOpenFileEditTrd - A state setter function to control the visibility of a file editing operation within a TRD.
   *   Setting this to false will close the file editing operation within TRD.
   * @param {boolean} setIsOpenFileEdit - A state setter function to control the visibility of a general file editing operation.
   *   Setting this to false will close the general file editing operation.
   * @returns {void}
   */
  useEffect(() => {
    setCloseFileTables(true);
    setIsOpenFileMoveTrd(false);
    setIsOpenFileEditTrd(false);
    setIsOpenFileEdit(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that sets the `isChangePage` state to `false` when the component mounts.
   * @returns {void}
   */
  useEffect(() => {
    setIsChangePage(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditional rendering based on the value of 'isLoading'.
   * @param {boolean} isLoading - Indicates whether loading is in progress.
   * @returns {JSX.Element|null} The LoadingComponent if 'isLoading' is true, otherwise null.
   */
  if ((isLoadingFiles === true || isLoadingFilesTrd === true) && isChangePage === false) {
    return <LoadingComponent />;
  }

  /**
   * Conditional rendering function based on user type and file view permission.
   * @param {string} USERTYPE - The user type to check against. Must be a string.
   * @param {string} id - The identifier for the file to check. Must be a string.
   * @param {string[]} VIEWFILES - An array of allowed file identifiers. Must be an array of strings.
   * @returns {JSX.Element} A React component to render based on the conditions.
   */
  if (USERTYPE !== enumsStatusUser.ADMIN && VIEWFILES.includes(id) === false) {
    return (
      <div className="pt-3">
        <Alert color="info">
          <h4 className="alert-heading">{i18n.t("modal.DoneError.header")}</h4>
          <hr />
          <h6>{i18n.t("showNotification403")}</h6>
        </Alert>
      </div>
    );
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          {searchBoxFilesRender}
          {loaderComponent(loaderRegisternotFound)}
          {showTables()}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

RecordFileListTables.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
  trdExist: PropTypes.bool.isRequired,
};

export default RecordFileListTables;
