import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Card, CardFooter, CardBody } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ButtonCreateGroupWork from "components/atoms/ButtonCreateGroupWork";
import SearchBox from "components/atoms/SearchBox";
import UserListGroupsTable from "components/organism/UserListGroupsTable";
import useUser from "hooks/useUser";
import swal from "sweetalert";
import i18n from "i18next";

const UserListOfGroups = () => {
  const { ConsultationWorkingGroups } = useUser();
  const [search, setSearch] = useState("");
  const [listGroups, setListGroups] = useState([]);
  const [isLoadingUserList, setIsLoadingUserList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);

  /**
   * Retrieves the list of groups by search term using pagination.
   * @param {number} page - The page number for pagination.
   * @param {number} per_page - The number of items per page.
   * @param {string} search - The search term.
   * @returns {void}
   */
  const getListGroupsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingUserList(true);
    ConsultationWorkingGroups(page, per_page, search)
      .then((response) => {
        if (isNullOrUndefined(response.data.data) === false) {
          setListGroups(response.data.data.items);
          setTotalPages(response.data.data.pages);
          if (response.data.data.items.length === 0) {
            if (search === "") {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("create.work.group.label5"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            } else {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("create.work.group.label6"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setIsLoadingUserList(false));
    // eslint-disable-next-line
  }, [pagination, search]);

  /**
   * Executes the getListGroupsBySearch function when the component mounts or when the dependencies (pagination, search) change.
   * @param {object} pagination - The pagination configuration object.
   * @param {number} pagination.page - The page number for pagination.
   * @param {number} pagination.per_page - The number of items per page.
   * @param {string} search - The search term.
   * @returns {void}
   */
  useEffect(() => {
    getListGroupsBySearch();
    // eslint-disable-next-line
  }, [pagination, search]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Card>
            <CardFooter className="ml-auto">
              <SearchBox
                setSearch={setSearch}
                pagination={pagination}
                setPagination={setPagination}
              />
              <ButtonCreateGroupWork />
            </CardFooter>
            <CardBody>
              <UserListGroupsTable
                listGroups={listGroups}
                pagination={pagination}
                isLoading={isLoadingUserList}
                totalPages={totalPages}
                setPagination={setPagination}
              />
            </CardBody>
          </Card>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserListOfGroups;
