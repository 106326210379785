import React, { useEffect, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardBody, NavLink, Button, Row, Col } from "reactstrap";
import { TYPE } from "constants/securityConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError, showAlertNoFormsFound, showAlertNoForms } from "utils/alerts";
import { loaderComponent } from "utils/loaderElement";
import { initialPaginationForms } from "utils/initialPaginationsConfig";
import { findStatus, typeUserForms } from "utils/formsQueryFunctions";
import { enumsFieldFormStatus } from "utils/enums";
import cx from "classnames";
import SearchBoxForms from "components/atoms/SearchBoxForms";
import useForms from "hooks/useForm";
import i18n from "locales/i18n";

const RecordListForms = (props) => {
  const { isRecordCreate } = props;
  const { getForms } = useForms();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const [listForms, setListForms] = useState([]);
  const [pagination, setPagination] = useState(initialPaginationForms);
  const [searchForms, setSearchForms] = useState("");
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  let path;
  let filterButton;

  /**
   * Displays an alert based on the searchForms parameter.
   * If the searchForms is not null or undefined, it shows a custom alert for no forms found.
   * Otherwise, it shows a default alert for no forms found.
   * @param {any} searchForms - The searchForms parameter to check.
   * @param {Function} setSearchForms - The function to set the searchForms parameter.
   * @param {Function} setPagination - The function to set pagination.
   * @param {any} pagination - The pagination parameter to set.
   */
  const displayAlertBasedOnSearch = (searchForms, setSearchForms, setPagination, pagination) => {
    if (isNullOrUndefined(searchForms) === false) {
      showAlertNoFormsFound(setSearchForms, setPagination, pagination);
    } else {
      showAlertNoForms();
    }
  }

  /**
   * Fetches forms based on searchForms criteria and updates the list of forms accordingly.
   * @throws {Error} Throws an error if the service response does not contain the expected data.
   * @param {Object} pagination - Object containing page and per_page values for pagination.
   * @param {number} pagination.page - The page number for the form list.
   * @param {number} pagination.per_page - The number of forms to display per page.
   * @param {string} searchForms - The searchForms criteria to filter forms.
   * @param {boolean} isRecordCreate - Flag indicating whether the form is being created.
   * @returns {void}
   */
  const getFormsBySearch = useCallback(() => {
    setIsLoadingForms(true);
    const { page, per_page } = pagination;
    getForms(
      page,
      per_page,
      searchForms,
      findStatus(isRecordCreate),
      typeUserForms(USERTYPE, isRecordCreate)
    )
      .then((response) => {
        const listForms = response.data.items;
        if (isNullOrUndefined(listForms) === false) {
          if (listForms.length === 0) {
            displayAlertBasedOnSearch(searchForms, setSearchForms, setPagination, pagination);
            setListForms([]);
          } else {
            let updatedForms = listForms;
            if (isRecordCreate !== true) {
              updatedForms = listForms.filter((item) => item.status !== enumsFieldFormStatus.ACTIVE);
              if (updatedForms.length === 0) {
                displayAlertBasedOnSearch(searchForms, setSearchForms, setPagination, pagination);
                setListForms([]);
              }
            }
            setListForms(updatedForms);
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingForms(false);
      });
  }, [pagination, isRecordCreate]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Generates a list of rendered forms with associated navigation links and filter buttons.
   * @param {Array} listForms - An array of form objects to be rendered.
   * @param {boolean} isRecordCreate - A flag indicating whether the forms are for record creation.
   * @returns {Array} An array of React components representing rendered forms.
   */
  const formsToRender = listForms.map((form) => {
    if (isRecordCreate === true) {
      path = `/record/create/${form.uuid}`;
    } else {
      path = `/record/list/table/${form.uuid}`;
    }

    if (isRecordCreate === true) {
      filterButton = null;
    } else {
      filterButton = (
        <Button
          href={`/record/list/forms/filters/${form.uuid}`}
          className="btn-icon border-0 btn-outline-2x text-cyan"
          color="white"
        >
          <FontAwesomeIcon icon={faSearch} className="mr-2" />
          {i18n.t("filterQuery.button")}
        </Button>
      );
    }
    return (
      <NavLink id={form.uuid} key={form.uuid} href={path} className="p-0">
        <Button
          id={form.uuid}
          className={cx("btn-icon-vertical", "btn-shadow", "btn-outline-2x", {
            "record-create-style": isRecordCreate,
            "button-style": !isRecordCreate,
          })}
          color="cyan"
          outline
        >
          <i className="pe-7s-note btn-icon-wrapper"> </i>
          {form.name}

          <div className="mt-2 text-center">{filterButton}</div>
        </Button>
      </NavLink>
    );
  });

  /**
   * Renders a container form based on loading state.
   * @returns {JSX.Element} The JSX element representing the container form.
   */
  const showContainerForm = () => {
    if (isLoadingForms === true) {
      return loaderComponent(isLoadingForms)
    } else {
      return <div className="gridContainer">{formsToRender}</div>;
    }
  }

  /**
   * useEffect hook that triggers the 'getFormsBySearch' function when dependencies change.
   * This effect is designed to respond to changes in the 'getFormsBySearch' function,
   * ensuring that the associated side effects are executed when the function or its dependencies are updated.
   * This effect is responsible for triggering the 'getFormsBySearch' function.
   * @param {function} getFormsBySearch - The function responsible for fetching forms based on a searchForms criteria.
   * @returns {void}
   */
  useEffect(() => {
    getFormsBySearch();
  }, [getFormsBySearch]);

  return (
    <div>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Card className="main-card mb-3">
            <CardBody>
              <Row>
                <Col lg="5" md="4" sm="12" className="button-container">
                  <h5 className="text-info font-weight-bold ">
                    {i18n.t("recordData2")}
                  </h5>
                </Col>
                <Col
                  lg="7"
                  md="8"
                  sm="12"
                  className="button-container mt-2 mt-md-0"
                >
                  <div className="row justify-content-end">
                    <SearchBoxForms
                      search={searchForms}
                      setSearch={setSearchForms}
                      pagination={pagination}
                      setPagination={setPagination}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardBody>
              {showContainerForm()}
            </CardBody>
          </Card>
        </div>
      </CSSTransitionGroup>
    </div>
  );
};

RecordListForms.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
};

export default RecordListForms;