import React from "react";
import PropTypes from "prop-types";
import { Card } from "reactstrap";

const ContainerFilterFormRender = (props) => {
  const { children, isAdvancedFilterTask } = props;

  /**
   * Renders children either inside a <div> or a <Card> based on the value of selectFormIdContext.
   * @param {boolean} selectFormIdContext - Boolean flag indicating whether to render children inside a <div> or a <Card>.
   * If true, children will be rendered inside a <div>. If false, children will be rendered inside a <Card>.
   * @param {ReactNode} children - The child elements to be rendered inside either a <div> or a <Card>.
   * @returns {JSX.Element} - Returns a JSX element containing either a <div> or a <Card> with the provided children.
   */
  if (isAdvancedFilterTask === true) {
    return <div>{children}</div>;
  } else {
    return <Card>{children}</Card>;
  }
};

ContainerFilterFormRender.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContainerFilterFormRender;