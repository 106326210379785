import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserList from "components/organism/UserList/UserList";
import i18n from "locales/i18n";

const UsersList = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("administratorHeading")}
        subheading={i18n.t("administratorSubHeading")}
        icon="users"
      >
        <UserList />
      </AdminTemplate>
    </Fragment>
  );
};

export default UsersList;
