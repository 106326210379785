import React, { Fragment } from "react";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faImage,
  faFileAlt,
  faFileArchive,
  faPlayCircle,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { Alert, CardTitle } from "reactstrap";
import i18n from "locales/i18n";
import Loader from "react-loaders";

const ProcessFilesListTable = ({ files, isLoadingFiles }) => {
  return (
    <Fragment>
      <CardTitle className="align-items-left ">
        {i18n.t("recordDetail.files")}
      </CardTitle>
      {(() => {
        if (isLoadingFiles === true) {
          return (
            <div className="loader-wrapper d-flex justify-content-center align-items-center">
              <Loader color="#0072BC" type="ball-pulse-rise" />
            </div>
          );
        } else if (files.length === 0) {
          return (
            <Alert className="block" color="info">
              {i18n.t("taskDetailFiles")}
            </Alert>
          );
        } else {
          return (
            <ReactTable
              className="table-files-style p-0"
              data={files}
              columns={[
                {
                  columns: [
                    {
                      accessor: "name",
                      Cell: (row) => (
                        <div>
                          {(() => {
                            if (row.original.name.includes(".pdf")) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-danger fa-2x mr-2 ml-auto "
                                  icon={faFilePdf}
                                />
                              );
                            } else if (row.original.name.includes(".txt")) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-gray fa-2x mr-2 ml-auto "
                                  icon={faFileAlt}
                                />
                              );
                            } else if (row.original.name.includes(".zip")) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-warning fa-2x mr-2 ml-auto "
                                  icon={faFileArchive}
                                />
                              );
                            } else if (
                              row.original.name.includes(".docx") ||
                              row.original.name.includes(".docm") ||
                              row.original.name.includes(".dotx") ||
                              row.original.name.includes(".dotm")
                            ) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-blueWord fa-2x mr-2 ml-auto "
                                  icon={faFileWord}
                                />
                              );
                            } else if (
                              row.original.name.includes(".xlsx") ||
                              row.original.name.includes(".xlsm") ||
                              row.original.name.includes(".xltx") ||
                              row.original.name.includes(".xltm") ||
                              row.original.name.includes(".xlsb") ||
                              row.original.name.includes(".xlam")
                            ) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-greenExcel fa-2x mr-2 ml-auto "
                                  icon={faFileExcel}
                                />
                              );
                            } else if (
                              row.original.name.includes(".pptx") ||
                              row.original.name.includes(".pptm") ||
                              row.original.name.includes(".potx") ||
                              row.original.name.includes(".potm") ||
                              row.original.name.includes(".ppam") ||
                              row.original.name.includes(".ppsx") ||
                              row.original.name.includes(".ppsm") ||
                              row.original.name.includes(".sldx") ||
                              row.original.name.includes(".sldm") ||
                              row.original.name.includes(".thmx")
                            ) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-orangePpt fa-2x mr-2 ml-auto "
                                  icon={faFilePowerpoint}
                                />
                              );
                            } else if (
                              row.original.name.includes(".tif") ||
                              row.original.name.includes(".tiff") ||
                              row.original.name.includes(".gif") ||
                              row.original.name.includes(".jpeg") ||
                              row.original.name.includes(".jpg") ||
                              row.original.name.includes(".jif") ||
                              row.original.name.includes(" .jfif") ||
                              row.original.name.includes(".fpx") ||
                              row.original.name.includes(".pcd") ||
                              row.original.name.includes(".png")
                            ) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-info fa-2x mr-2 ml-auto "
                                  icon={faImage}
                                />
                              );
                            } else if (
                              row.original.name.includes(".avi") ||
                              row.original.name.includes(".asf") ||
                              row.original.name.includes(".asx") ||
                              row.original.name.includes(".rmi") ||
                              row.original.name.includes(".wma") ||
                              row.original.name.includes(".wax") ||
                              row.original.name.includes(".wav") ||
                              row.original.name.includes(".mp3") ||
                              row.original.name.includes(".mkv") ||
                              row.original.name.includes(".mpeg-2") ||
                              row.original.name.includes(".wmv") ||
                              row.original.name.includes(".mp4") ||
                              row.original.name.includes(".mov")
                            ) {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-danger fa-2x mr-2 ml-auto "
                                  icon={faPlayCircle}
                                  c
                                />
                              );
                            } else {
                              return (
                                <FontAwesomeIcon
                                  className="icon-opacity text-gray fa-2x mr-2 ml-auto "
                                  icon={faStickyNote}
                                />
                              );
                            }
                          })()}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${row.original.presigned_url}`}
                          >
                            {row.value}
                          </a>
                        </div>
                      ),
                    },
                  ],
                },
              ]}
              manual
              pageSize={files.length ? files.length : [2]}
              showPaginationBottom={false}
              noDataText={null}
            />
          );
        }
      })()}
    </Fragment>
  );
};

export default ProcessFilesListTable;