import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import FormsDesignerForm from "components/organism/FormsDesignerForm";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";

const FormsDesigner = () => {
  const { id: formId } = useParams();

  let headingTitle = null;
  let subHeadingTitle = null;

  /**
   * Sets the heading and subheading titles based on the presence of a form ID. 
   * If `formId` is not null or undefined, `headingTitle` and `subHeadingTitle` are set 
   * to specific internationalized text strings. Otherwise, they are set to default values.
   * @param {string | number | null | undefined} formId - The ID of the form.
   * @param {Object} i18n - The internationalization object for translating text.
   * @param {Function} i18n.t - The function for retrieving translated text.
   * @param {string} i18n.t.form.designerItem1 - The internationalized string for the heading title.
   * @param {string} i18n.t.form.designerMotto - The internationalized string for the subheading title.
   * @returns {void}
   */
  if (isNullOrUndefined(formId) === false) {
    headingTitle = i18n.t("form.designerItem1");
    subHeadingTitle = i18n.t("form.designerMotto")
  } else {
    headingTitle = i18n.t("form.designerItem1");
    subHeadingTitle = i18n.t("form.designerMotto");
  }

  return (
    <Fragment>
      <AdminTemplate
        heading={headingTitle}
        subheading={subHeadingTitle}
        icon="note2"
      >
        <FormsDesignerForm />
      </AdminTemplate>
    </Fragment>
  );
};

export default FormsDesigner;
