import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import i18n from "locales/i18n";

const ButtonCreateUser = (props) => {
  const { disabled } = props;

  let renderLinkCreateForm = null;

  /**
   * Renders a link with a dropdown button if the `disabled` flag is false.
   * If `disabled` is false, it creates a link to the form designer create page
   * with a dropdown button. The button is disabled or enabled based on the `disabled` flag.
   * @param {boolean} disabled - A flag indicating whether the button should be disabled.
   * @param {Object} i18n - The internationalization object for translating text.
   * @param {Function} i18n.t - The function for retrieving translated text.
   * @param {string} i18n.t.form.designerItem2 - The internationalized string for the button text.
   * @returns {JSX.Element | null} - A JSX element containing the link and button if not disabled, or null if disabled.
   */
  if (disabled === false) {
    renderLinkCreateForm = (
      <div>
        <Link to="/formsdesigner/create">
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              disabled={disabled}
            >
              <span>+ {i18n.t("form.designerItem2")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </Link>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {renderLinkCreateForm}
      </CSSTransitionGroup>
    </Fragment>
  );
};

ButtonCreateUser.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default ButtonCreateUser;
