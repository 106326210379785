import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { regexSearchExportDataRecords } from "utils/regexExpressions";
import cx from "classnames";
import i18n from "locales/i18n";
const SearchExportDataRecord = (props) => {
  const {
    searchData,
    setSearchData,
    paginationRecords,
    setPaginationRecords,
    errorCharacters,
    setErrorCharacters,
  } = props;

  /**
   * Handles changes to the search input value and updates the search data state, validates the input against a regex pattern.
   * @param {Object} eventChange - The event object representing the change in the input field.
   */
  const handleOnChangeSearch = (eventChange) => {
    const searchValueTyped = eventChange.target.value;

    if (!regexSearchExportDataRecords.test(searchValueTyped)) {
      setErrorCharacters(i18n.t("createRoles.InputInvalid"));
    } else {
      setErrorCharacters("");
    }

    const searchValueEncoded = encodeURIComponent(searchValueTyped);
    setSearchData(searchValueEncoded);
  };

  /**
   * Handles search validation before triggering a specified action.
   * This function checks if pagination records are defined, prevents the default action of the event,
   * and then validates the search data using a regular expression (`regex`). If the search data
   * passes the validation, the specified action function is called. *
   * @param {object} event - The event object associated with the triggering action.
   * This is typically an event object from a form submission or a button click.
   * @param {function} action - The action function to be executed if the search validation passes.
   * This function will only be called if the provided search data passes the validation.
   * @returns {void}
   */
  const handleSearchValidation = (event, action) => {
    if (paginationRecords !== undefined) {
      event.preventDefault();
      const searchValueDecoded = decodeURIComponent(searchData);
      if (regexSearchExportDataRecords.test(searchValueDecoded) && errorCharacters === "") {
        if (searchValueDecoded.trim() === "") {
          return;
        }
        action();
      }
    }
  };

  /**
   * Handles keypress events for search functionality, specifically the "Enter" key.
   * @param {KeyboardEvent} eventSearch - The keypress event object for the search input field.
   * @param {object} paginationRecords - The pagination records object.
   * @param {function} setPaginationRecords - The state setter function for updating pagination records.
   */
  const handleOnKeyPressForSearch = (eventSearch) => {
    if (eventSearch.key === "Enter") {
      handleSearchValidation(eventSearch, () =>
        setPaginationRecords({ ...paginationRecords, page: 1 })
      );
    }
  };

  /**
   * Handles the click event of the open button, resetting the pagination page to 1 if paginationRecords is defined.
   * @param {Event} eventSearch - The click event object triggered when the open button is clicked.
   * @param {object} paginationRecords - The pagination records object.
   * @param {function} setPaginationRecords - The state setter function for updating pagination records.
   * @returns {void}
   */
  const handleOnClickOpenButton = (eventSearch) => {
    handleSearchValidation(eventSearch, () =>
      setPaginationRecords({ ...paginationRecords, page: 1 })
    );
  };

  return (
    <Fragment>
      <div
        className={cx("search-wrapperExplorer ml-auto search-box", {
          active: true,
        })}
      >
        <div className="input-holder">
          <input
            type="text"
            className="search-input"
            placeholder={i18n.t("searchbox.placeholder")}
            onKeyPress={handleOnKeyPressForSearch}
            onChange={handleOnChangeSearch}
            value={decodeURIComponent(searchData)}
          />
          <button className="search-icon" onClick={handleOnClickOpenButton}>
            <span />
          </button>
        </div>
        {errorCharacters && <span className="text-danger text small position-absolute">{errorCharacters}</span>}
      </div>
    </Fragment>
  );
};

SearchExportDataRecord.propTypes = {
  setSearchData: PropTypes.func.isRequired,
  searchData: PropTypes.string.isRequired,
  paginationRecords: PropTypes.object.isRequired,
  setPaginationRecords: PropTypes.func.isRequired,
};

export default SearchExportDataRecord;
