import React from "react";
import i18n from "locales/i18n";

export let DashboardNav = [
  {
    icon: "pe-7s-home",
    label: [i18n.t("startup")],
    to: "/dashboard",
  },
];

export let CreateRecordNav = [
  {
    icon: "pe-7s-pen",
    label: [i18n.t("recordData.heading")],
    to: "/record/list/forms",
  },
];

export let ConsultRecordNav = [
  {
    icon: "pe-7s-search",
    label: [i18n.t("dashboard.label7")],
    content: [
      {
        label: [i18n.t("recordList1")],
        to: "/record/list/form",
      },
    ],
  },
];

export let ConsultRecordNavTrdExplorer = [
  {
    icon: "pe-7s-search",
    label: [i18n.t("dashboard.label7")],
    content: [
      {
        label: [i18n.t("recordList1")],
        to: "/record/list/form",
      },
      {
        label: [i18n.t("navItems.aplication.ConsultTRD")],
        to: "/trd/expedients/consultation",
      },
    ],
  },
];

export let TaskBoxNav = [
  {
    icon: "pe-7s-drawer",
    label: [i18n.t("taskBox")],
    to: "/taskBox",
  },
];

export let ReportsNav = [
  {
    icon: "pe-7s-news-paper",
    label: [i18n.t("info.SignatureNav")],
    content: [
      {
        label: [i18n.t("info.SignatureNav1")],
        to: "/reports/signatures",
      },
    ],
  },
];

export let ProcessManagementNav = [
  {
    icon: "pe-7s-network",
    label: (
      <div>
        {i18n.t("processManagement.Header")}
        {"  "}
        <span className="badge badge-success badge-small"> {i18n.t("dashboard.label6")}</span>
      </div>
    ),
    to: "/process/management",
  },
];

export let TrashNav = [
  {
    icon: "pe-7s-trash",
    label: (
      <div>
        {i18n.t("trash.label1")}
        {"  "}
        <span className="badge badge-success badge-small"> {i18n.t("dashboard.label6")}</span>
      </div>
    ),
    to: "/trash",
  },
];

export let DataExportNav = [
  {
    icon: "pe-7s-download",
    label: [(
      <div>
        {i18n.t("export.records.nav.title")}
        {" "}
        <span className="badge badge-success badge-small"> {i18n.t("dashboard.label6")}</span>
      </div>
    )],
    content: [
      {
        label: [i18n.t("export.records.nav")],
        to: "/export-data",
      }
    ]
  }
];

export let ParameterNav = [
  {
    icon: "pe-7s-config",
    label: [i18n.t("navItems.aplication.Parameters")],
    content: [
      {
        label: [i18n.t("navItems.aplication.TrdOption1")],
        to: "/trd",
      },
      {
        label: [i18n.t("navItems.aplication.documentalTypes")],
        to: "/documentary-types/list",
      },
      {
        label: [i18n.t("navItems.aplication.Config")],
        to: "/application/config/sticker",
      },
    ],
  },
];

export let ParameterDocumentaryTypeNav = [
  {
    icon: "pe-7s-config",
    label: [i18n.t("navItems.aplication.Parameters")],
    content: [
      {
        label: [i18n.t("navItems.aplication.documentalTypes")],
        to: "/documentary-types/list",
      },
      {
        label: [i18n.t("navItems.aplication.Config")],
        to: "/application/config/sticker",
      },
    ],
  },
];

export let FormsDesignerNav = [
  {
    icon: "pe-7s-note",
    label: [i18n.t("form.designerItem1")],
    content: [
      {
        label: [i18n.t("form.designerItem2")],
        to: "/formsdesigner/create",
      },
      {
        label: [i18n.t("form.designerItem3")],
        to: "/formsdesigner/list",
      },
    ],
  },
];

export let FormsDesignerNavConsult = [
  {
    icon: "pe-7s-note",
    label: [i18n.t("form.designerItem1")],
    content: [
      {
        label: [i18n.t("form.designerItem3")],
        to: "/formsdesigner/list",
      },
    ],
  },
];

export let ListDesignerNav = [
  {
    icon: "pe-7s-menu",
    label: [i18n.t("list.designerItem1")],
    content: [
      {
        label: [i18n.t("list.designerItem2")],
        to: "/formsdesigner/createlist",
      },
      {
        label: [i18n.t("list.designerItem3")],
        to: "/formsdesigner/consultlist",
      },
    ],
  },
];

export let AdminUsersAndRoleGroupsNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption2")],
        to: "/user/create",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption1")],
        to: "/user/list",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption4")],
        to: "/user/roles",
      },
      {
        label: [i18n.t("create.work.group.nav")],
        to: "/user/workgroup",
      },
    ],
  },
];

export let AdminUsersAndRoleNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption2")],
        to: "/user/create",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption1")],
        to: "/user/list",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption4")],
        to: "/user/roles",
      },
    ],
  },
];

export let AdminUsersGroupsNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption2")],
        to: "/user/create",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption1")],
        to: "/user/list",
      },
      {
        label: [i18n.t("create.work.group.nav")],
        to: "/user/workgroup",
      },
    ],
  },
];

export let AdminRoleGroupsNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption4")],
        to: "/user/roles",
      },
      {
        label: [i18n.t("create.work.group.nav")],
        to: "/user/workgroup",
      },
    ],
  },
];

export let AdminUsersNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption2")],
        to: "/user/create",
      },
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption1")],
        to: "/user/list",
      },
    ],
  },
];

export let AdminUsersRoleNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("navItems.aplication.AdminUsersOption4")],
        to: "/user/roles",
      },
    ],
  },
];

export let AdminUserWorkGroupNav = [
  {
    icon: "pe-7s-users",
    label: [i18n.t("navItems.aplication.AdminUsers")],
    content: [
      {
        label: [i18n.t("create.work.group.nav")],
        to: "/user/workgroup",
      },
    ],
  },
];

export let WorkFlowDesignerNav = [
  {
    icon: "pe-7s-note",
    label: [i18n.t("createWorflow.titleNav")],
    content: [
      {
        label: [i18n.t("createWorflow.SubtitleNav")],
        to: "/WorkFlow",
      },
      {
        label: [i18n.t("createWorflow.query")],
        to: "/WorkFlow/list",
      },
    ],
  },
];

export let WorkFlowDesignerNavConsult = [
  {
    icon: "pe-7s-note",
    label: [i18n.t("createWorflow.titleNav")],
    content: [
      {
        label: [i18n.t("createWorflow.query")],
        to: "/WorkFlow/list",
      },
    ],
  },
];

export let MetricsNav = [
  {
    icon: "pe-7s-graph3",
    label: i18n.t("AuditLogs"),
    content: [
      {
        label: i18n.t("metrics"),
        to: "/metrics",
      },
      {
        label: (
          <div>
            {i18n.t("AuditLogs.nav")}
            {"  "}
            <span className="badge badge-success badge-small"> {i18n.t("dashboard.label6")}</span>
          </div>
        ),
        to: "/audit/logs",
      },
    ],
  },
];

export let IntegrationsNav = [
  {
    icon: "pe-7s-keypad",
    label: [i18n.t("integrations.nav")],
    to: "/integrations",
  },
];
