import React from "react";
import PropTypes from 'prop-types';
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "i18next";
import { Link } from "react-router-dom"

const ButtonActionsGroupWork = (props) => {

  const { 
    workGroupUuid,
    statusGroup
  } = props;

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>
        <DropdownItem>
          <Link to={`/user/workgroup/members/${workGroupUuid}/${statusGroup}`}>
            <i className="dropdown-icon lnr-users" />
            <span>{i18n.t("create.work.group.members.link")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/user/create/workgroup/${workGroupUuid}`}>
            <i className="dropdown-icon lnr-pencil" />
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsGroupWork.propTypes = {
  workGroupUuid: PropTypes.string.isRequired,
  statusGroup: PropTypes.number.isRequired,
};

export default ButtonActionsGroupWork;


