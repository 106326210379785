import React, { Fragment, useState } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import ProcessDetails from "components/organism/ProcessDetails";
import i18n from "locales/i18n";

const ProcessDetail = () => {
  const [isLoadingRequest, setIsLoadingRequest] = useState(true);

  return (
    <Fragment>
      <AdminTemplate heading={i18n.t("taskDetail")} icon="news-paper">
        <ProcessDetails
          setIsLoadingRequest={setIsLoadingRequest}
          isLoadingRequest={isLoadingRequest}
        />
      </AdminTemplate>
    </Fragment>
  );
};

export default ProcessDetail
  ;
