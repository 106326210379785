import React from "react";
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { showAlertServiceError } from "utils/alerts";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionsRoles = (props) => {
  const { rolUuid, deleteRole, setIsLoadingDeleteRol, rolName } = props;
  const expRolname = JSON.stringify(rolName);
  const hashExpRolname = window.btoa(expRolname);

  /**
   * Handles the deletion of a role and displays a confirmation dialog using a library like SweetAlert (swal).
   * This function triggers a confirmation dialog to confirm the deletion of a role. If the user confirms the deletion,
   * it makes an API call to delete the role and displays a success message if the operation is successful.
   * @param {Function} swal - A function for displaying dialog boxes (e.g., SweetAlert) to show the confirmation dialog.
   * @param {string} i18n - An internationalization library for translating messages and text.
   * @param {string} rolUuid - The UUID of the role to be deleted.
   * @param {Function} setIsLoadingDeleteRol - A state setter function to manage loading state during the deletion process.
   * @param {Function} deleteRole - A function for making an API request to delete a role.
   */
  const handleOnDelete = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("createRoles.Alert.Delete"),
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willDelete) => {
      if (willDelete) {
        setIsLoadingDeleteRol(true);
        deleteRole(rolUuid)
          .then((response) => {
            if (response.status === 202) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.Alert.Confirm.Delete"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            } else {
               showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingDeleteRol(false);
          });
      }
    });
  };

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link to={`/user/roles/members/${rolUuid}`}>
            <i className="dropdown-icon lnr-users" />
            <span>{i18n.t("createRoles.Members")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/user/roles/config/${rolUuid}/${hashExpRolname}`}>
            <i className="dropdown-icon lnr-cog" />
            <span>{i18n.t("verticalNav.tittle2")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/user/roles/edit/${rolUuid}`}>
            <i className="dropdown-icon lnr-pencil" />
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={"#"}>
            <i className="dropdown-icon lnr-trash text-danger" />
            <span onClick={handleOnDelete}>
              {i18n.t("buttonActions.option3")}
            </span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default ButtonActionsRoles;
