const ENUMS = {
  minValue: 0,
};

/**
 * Checks if a value is null or undefined.
 * @param {*} value - The value to be checked.
 * @returns {boolean} - True if the value is null or undefined, false otherwise.
 */
export const isNullOrUndefined = (value) => {
  if (value === null || value === undefined || value === "" || value === "undefined") {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks if an array has a length greater than zero and meets a minimum value requirement.
 * @param {Array} value - The array to be checked.
 * @returns {boolean} - True if the array has a length greater than zero and meets the minimum value requirement, false otherwise.
 */
export const arrayGreaterThanZero = (value) => {
  if (value === null || value === undefined || value === "" || value.length === ENUMS.minValue) {
    return false;
  } else {
    return true;
  }
};

/**
 * Parses a string representation of an array and converts it to a JavaScript array.
 * This function attempts to parse a string that represents an array where
 * the array elements are enclosed in single quotes and replaces the single quotes
 * with double quotes to make it a valid JSON string before parsing.
 * @param {string} arrayString - The string representation of an array to be parsed.
 * @returns {Array} - The parsed array. If parsing fails, an empty array is returned.
 */
const parseFilesList = (arrayString) => {
  try {
    return JSON.parse(arrayString.replace(/'/g, '"'));
  } catch (error) {
    return [];
  }
};

/**
 * Extracts an array from a given string and returns the array along with the strings before and after the array.
 * Throws an error if the input is not a string.
 * @param {string} detailMessageString - The input string containing an array.
 * @returns {object} An object containing the strings before the array, the extracted array, and the strings after the array.
 */
export const extractArrayFromString = (detailMessageString) => {
  if (typeof detailMessageString !== "string") {
    throw new Error("Input must be a string");
  }
  const matchFilesArray = detailMessageString.match(/\[[^[\]]*\]/);
  if (matchFilesArray) {
    const arrayString = matchFilesArray[0];
    const beforeArray = detailMessageString.slice(0, matchFilesArray.index);
    const afterArray = detailMessageString.slice(matchFilesArray.index + arrayString.length);
    const filesList = parseFilesList(arrayString);
    return { beforeArray, filesList, afterArray };
  }
  return { beforeArray: detailMessageString, filesList: [], afterArray: "" };
};
