import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const SignatoriesListTable = (props) => {
  const { signatoriesList, deleteSignatories } = props;
  let pageSizes;

  /**
   * Calculate the number of page sizes based on the length of the 'signatoriesList' array.
   * If the 'signatoriesList' array is empty, the number of page sizes is set to 1.
   * Otherwise, it's set to the length of the 'signatoriesList' array.
   * @param {Array} signatoriesList - An array containing signatories or elements to determine the number of page sizes.
   * @returns {number} The calculated number of page sizes based on the 'signatoriesList' array.
   */
  if (signatoriesList.length === 0) {
    pageSizes = 1;
  } else {
    pageSizes = signatoriesList.length;
  }
  return (
    <div className="pt-3">
      <ReactTable
        data={signatoriesList}
        columns={[
          {
            columns: [
              {
                Header: i18n.t("signature.pictures"),
                accessor: "proof_life",
                Cell: ({ value }) => {
                  let statusFinal = "";
                  if (value === "0") {
                    statusFinal = <span>{i18n.t("signature.picturesNo")}</span>;
                  } else if (value === "1") {
                    statusFinal = (
                      <span>{i18n.t("signature.picturesYes")}</span>
                    );
                  }
                  return <p>{statusFinal}</p>;
                },
              },
              {
                Header: i18n.t("signature.order"),
                accessor: "signature_order",
                Cell: ({ value }) => {
                  let statusFinal = "";
                  if (value === 0) {
                    statusFinal = <span>{i18n.t("signature.orderOpc")}</span>;
                  } else {
                    statusFinal = value;
                  }
                  return <p>{statusFinal}</p>;
                },
              },
              {
                Header: i18n.t("integration.requesEmail"),
                accessor: "email",
                width: 180,
              },
              {
                Header: i18n.t("signature.country"),
                accessor: "country_name",
              },
              {
                Header: i18n.t("signature.phoneNumber"),
                accessor: "phone",
              },
              {
                Header: i18n.t("signature.nameUser"),
                accessor: "name",
              },
              {
                Header: [i18n.t("createRoles.MembersDelete")],
                accessor: "uuid",
                Cell: (row) => {
                  const listableId =  row.original.uuid;
                  return (
                    <div className="text-center">
                      <Button
                        className="btn-icon text-primary"
                        size="lg"
                        color="link"
                        onClick={() => deleteSignatories(listableId)}
                      >
                        <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                          <div>
                            <i className="lnr-icon lnr-trash text-primary " />
                          </div>
                        </div>
                      </Button>
                    </div>
                  );
                },
              },
            ],
          },
        ]}
        showPagination={false}
        pageSize={pageSizes}
        noDataText={false}
        className="-striped -highlight custom-table"
      />
    </div>
  );
};

SignatoriesListTable.propTypes = {
  signatoriesList: PropTypes.array.isRequired,
  deleteSignatories: PropTypes.func,
};

export default SignatoriesListTable;
