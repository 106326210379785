import React, { Fragment  } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap";
import bg1 from "assets/utils/images/dropdown-header/abstract1.jpg";
import bg2 from "assets/utils/images/dropdown-header/abstract2.jpg";
import bg3 from "assets/utils/images/dropdown-header/abstract3.jpg";
import bg10 from "assets/utils/images/dropdown-header/abstract10.jpg";
import i18n from "locales/i18n";
import useLogin from "hooks/useLogin";
import {
  TYPE,
  ROLES_PERMISSIONS,
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS
} from "constants/securityConst";

const DashboardContent = () => {
  const USERTYPE = window.localStorage.getItem(TYPE);
  const ROLE_PERMISSION = window.localStorage.getItem(ROLES_PERMISSIONS);
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(
    DESIGNER_FORMS_PERMISSIONS
  );
  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(WORKFLOW_FORMS_PERMISSIONS);

  const { isLogged } = useLogin();
  const access = isLogged && USERTYPE !== "1" ? true : false;
  const accessTRD =
    (isLogged && USERTYPE === "2" && ROLE_PERMISSION.includes(1)) ||
      (isLogged && USERTYPE === "3")
      ? true
      : false;
  const accessDesignerForms =
    (isLogged && USERTYPE === "2" && DESIGNER_FORM === "true") ||
      (isLogged && USERTYPE === "3")
      ? true
      : false;
  const accessDesignerFormsConsult =
    isLogged && USERTYPE === "2" && DESIGNER_FROMS_PERMISSION !== ""
      ? true
      : false;
  const accessWorkflowsForms =
    (isLogged && USERTYPE === "2" && WORKFLOW_ALL_FORM === "true") ||
      (isLogged && USERTYPE === "3")
      ? true
      : false
  const accessWorkflowsFormsConsult =
    isLogged && USERTYPE === "2" && WORKFLOW_FORMS_PERMISSION !== ""
      ? true
      : false;

  const accessList =
    (isLogged && USERTYPE === "2" && ROLE_PERMISSION.includes(2)) ||
      (isLogged && USERTYPE === "3")
      ? true
      : false;
  const accessUserManager =
    (isLogged && USERTYPE === "2" && ROLE_PERMISSION.includes(3)) ||
      (isLogged && USERTYPE === "3")
      ? true
      : false;
  const accessRoles =
    (isLogged && USERTYPE === "2" && ROLE_PERMISSION.includes(4)) ||
      (isLogged && USERTYPE === "3")
      ? true
      : false;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle className="pb-3">
                  {i18n.t("modal.DoneError.footer")}
                </CardTitle>
                <Row>
                  <Col lg="3" md="4" sm="12" className="mb-5">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-primary">
                        <div
                          className="menu-header-image opacity-1"
                          style={{
                            backgroundImage: "url(" + bg3 + ")",
                          }}
                        />
                        <div className="menu-header-content">
                          <h5 className="menu-header-title">
                            {i18n.t("recordData1")}
                          </h5>
                          <h6 className="menu-header-subtitle">
                            {i18n.t("dashboard.label1")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <Nav vertical>
                      <Nav vertical>
                        <NavItem className="nav-item-header">
                          {i18n.t("dashboard.label2")}
                        </NavItem>
                        <NavItem>
                          <NavLink href="/record/list/forms">
                            <i className="nav-link-icon pe-7s-note"> </i>
                            <span>{i18n.t("dashboard.label5")}</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink href="/record/list/form">
                            <i className="nav-link-icon pe-7s-search"> </i>
                            <span>{i18n.t("dashboard.label7.1")}</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Nav>
                  </Col>

                  <Col lg="3" md="4" sm="12" className="mb-5">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-danger">
                        <div
                          className="menu-header-image"
                          style={{
                            backgroundImage: "url(" + bg1 + ")",
                          }}
                        />
                        <div className="menu-header-content">
                          <h5 className="menu-header-title">
                            {i18n.t("dashboard.label8")}
                          </h5>
                          <h6 className="menu-header-subtitle">
                            {i18n.t("dashboard.label9")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <Nav vertical>
                      <NavItem className="nav-item-header">
                        {i18n.t("dashboard.label2")}{" "}
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="/user/list"
                          disabled={access && accessUserManager ? false : true}
                        >
                          <i className="nav-link-icon pe-7s-users"> </i>
                          <span>{i18n.t("linkHome1")}</span>
                        </NavLink>

                        <NavItem>
                          <NavLink
                            href="/user/create"
                            disabled={
                              access && accessUserManager ? false : true
                            }
                          >
                            <i className="nav-link-icon pe-7s-users"> </i>
                            <span>{i18n.t("createusers.tittle")}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="/user/roles"
                            disabled={access && accessRoles ? false : true}
                          >
                            <i className="nav-link-icon pe-7s-users"> </i>
                            <span>{i18n.t("createRoles.dashboard.Title")}</span>
                            <div className="ml-auto badge badge-success">
                              {i18n.t("dashboard.label6")}
                            </div>
                          </NavLink>
                        </NavItem>
                      </NavItem>
                    </Nav>
                  </Col>

                  <Col lg="3" md="4" sm="12" className="mb-5">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-success">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: "url(" + bg2 + ")",
                          }}
                        />
                        <div className="menu-header-content">
                          <h5 className="menu-header-title">
                            {i18n.t("form.designerTittle")}
                          </h5>
                          <h6 className="menu-header-subtitle">
                            {i18n.t("dashboard.label13")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <Nav vertical>
                      <Nav vertical>
                        <NavItem className="nav-item-header">
                          {i18n.t("dashboard.label2")}
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="/formsdesigner/list"
                            disabled={
                              (access && accessDesignerForms) ||
                                (access && accessDesignerFormsConsult)
                                ? false
                                : true
                            }
                          >
                            <i className="nav-link-icon pe-7s-search"> </i>
                            <span>{i18n.t("form.designerItem3")}</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            href="/formsdesigner/create"
                            disabled={
                              access &&
                                accessDesignerForms &&
                                !accessDesignerFormsConsult
                                ? false
                                : true
                            }
                          >
                            <i className="nav-link-icon pe-7s-next-2"> </i>
                            <span>{i18n.t("form.designerItem2")}</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            href="/formsdesigner/consultlist"
                            disabled={access && accessList ? false : true}
                          >
                            <i className="nav-link-icon pe-7s-search"> </i>
                            <span>{i18n.t("list.designerItem3")}</span>
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            href="/formsdesigner/createlist"
                            disabled={access && accessList ? false : true}
                          >
                            <i className="nav-link-icon pe-7s-menu"> </i>
                            <span>{i18n.t("list.designerItem2")}</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Nav>
                  </Col>

                  <Col lg="3" md="4" sm="12" className="mb-5">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-dark">
                        <div
                          className="menu-header-image opacity-1"
                          style={{
                            backgroundImage: "url(" + bg10 + ")",
                          }}
                        />
                        <div className="menu-header-content">
                          <h5 className="menu-header-title">
                            {i18n.t("verticalNav.tittle2")}
                          </h5>
                          <h6 className="menu-header-subtitle">
                            {i18n.t("dashboard.label15")}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <Nav vertical>
                      <Nav vertical>
                        <NavItem className="nav-item-header">
                          {i18n.t("dashboard.label16")}
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="/trd"
                            disabled={access && accessTRD ? false : true}
                          >
                            <i className="nav-link-icon pe-7s-albums"> </i>
                            <span>{i18n.t("dashboard.label17")} </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="/WorkFlow"
                            disabled={
                              access && accessWorkflowsForms &&
                              !accessWorkflowsFormsConsult
                                ? false
                                : true
                            }
                          >
                            <i className="nav-link-icon pe-7s-settings"> </i>
                            <span>{i18n.t("dashboard.label20")} </span>
                            <div className="ml-auto badge badge-success">
                              {i18n.t("dashboard.label6")}
                            </div>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Nav>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DashboardContent;
