import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PageTitleAlt2 from "layout/AppMain/PageTitleAlt2";
import AppHeader from "layout/AppHeader";
import AppSidebar from "layout/AppSidebar";
import AppFooter from "layout/AppFooter";
import ThemeOptions from "layout/ThemeOptions";
import TrdExpedientConsultationTree from "components/organism/TrdExpedientConsultationTree";
import i18n from "locales/i18n";

const TrdExpedientsConsultation = (props) => {
  let {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;
  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Fragment>
          <div
            className={cx(
              "app-container app-theme-" + colorScheme,
              { "fixed-header": enableFixedHeader },
              { "fixed-sidebar": enableFixedSidebar || width < 1250 },
              { "fixed-footer": enableFixedFooter },
              { "closed-sidebar": true },
              {
                "closed-sidebar-mobile": true,
              },
              { "sidebar-mobile-open": enableMobileMenu },
              { "body-tabs-shadow-btn": enablePageTabsAlt }
            )}
          >
            <ThemeOptions />
            <AppHeader />
            <div className="app-main">
              <AppSidebar />
              <div className="app-main__outer">
                <div className="app-main__inner">
                  <CSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                  >
                    <PageTitleAlt2
                      heading={i18n.t("trd.expedientConsultationHeader")}
                      subheading={i18n.t("trd.expedientConsultationSubHeader")}
                      icon={`pe-7s-folder text-blue`}
                    />
                    <TrdExpedientConsultationTree />
                  </CSSTransitionGroup>
                </div>

                <AppFooter />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(TrdExpedientsConsultation));
