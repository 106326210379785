import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Col,
  CardFooter,
  Label,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { loaderElement } from "utils/loaderElement";
import { Link } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import { enumsDesignerWorkflow, enumsFormStatus, enumsPermissionsAction } from "utils/enums";
import { regexDesignerWorkflow, regexDesignerWorkflowDescription } from "utils/regexExpressions";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import cx from "classnames";
import makeAnimated from "react-select/lib/animated";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import Swal from "sweetalert";
import useWorkflowsDiagram from "hooks/useWorkflowsDiagram";
import useForms from "hooks/useForm";
import i18n from "locales/i18n";

const DesignerWorkflow = () => {
  const { Created_workflow } = useWorkflowsDiagram();
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  const { selectedFormsId } = useState({});
  const [pagination] = useState(initialPaginationStandart);
  const [search] = useState("");
  const [formsDinamic, setFormsDinamic] = useState([]);
  const [isErrorSelectForm, setIsErrorSelectForm] = useState(false);
  const { getForms } = useForms();
  const [formsActives, setFormsActive] = useState({
    name: "",
    description: "",
    form_uuid: "",
  });

  let labelErrorSelectedForm = null;

  /**
   * Conditionally renders an error message if there is an error.
   * If the `isErrorSelectForm` flag is true, this block sets `labelErrorSelectedForm` to
   * a span element with an error message indicating that the field is required.
   * @param {boolean} isErrorSelectForm - The flag indicating if there is an error.
   * @returns {JSX.Element|undefined} The JSX element with the error message or undefined if there is no error.
   */
  if (isErrorSelectForm === true) {
    labelErrorSelectedForm = (
      <span className="text-danger small">{i18n.t("fieldRequired")}</span>
    )
  };

  /**
   * Fetches and processes the list of active forms.
   * This function fetches forms data based on the current pagination and search parameters,
   * sorts the forms by their name in alphabetical order, and filters out the active forms to
   * update the dynamic forms state. It sets a loading state while the data is being fetched
   * and processed.
   * @returns {void}
   */
  const getActiveForms = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingForms(true);
    getForms(page, per_page, search, enumsFormStatus.FORMS_STATUS_ACTIVE, enumsPermissionsAction.ALL_PERMISIONS)
      .then((response) => {
        let listForms = response.data.items;
        if (isNullOrUndefined(listForms) === false) {
          listForms
            .sort((listFirsForms, listSecondForms) => {
              const nombresA = `${listFirsForms.name} `.toLocaleLowerCase();
              const nombresB = `${listSecondForms.name} `.toLocaleLowerCase();
              return nombresA.localeCompare(nombresB);
            })
            .forEach((form) => {
              if (form.status === enumsFormStatus.FORMS_STATUS_ACTIVE) {
                setFormsDinamic((prevState) => [
                  ...prevState,
                  {
                    value: form.uuid,
                    label: form.name,
                  },
                ]);
              }
            });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingForms(false);
      });
  }, [pagination, search]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for updating form state in a create workflow scenario.
   * This function updates the `formsActive` state object by merging its current state
   * with the new value from the event target.
   * @param {Object} eventCreateWorkflow - The event object generated by the change event.
   * @param {Object} eventCreateWorkflow.target - The element that triggered the event.
   * @param {string} eventCreateWorkflow.target.name - The name of the form input field being updated.
   * @param {string} eventCreateWorkflow.target.value - The new value entered into the form input field.
   * @returns {void}
   */
  const handleOnChangeCreateWorkflow = (eventCreateWorkflow) => {
    setFormsActive({
      ...formsActives,
      [eventCreateWorkflow.target.name]: eventCreateWorkflow.target.value,
    });
  };

  /**
   * Handles the change event for updating selected forms in a form selection scenario.
   * This function updates the `formsActive` state object with the selected form UUID and
   * manages the error state based on whether a form is selected or not.
   * @param {Array} selectedFormsId - The array of selected form IDs.
   * @param {Object} selectedFormsId.value - The value representing the selected form's UUID.
   * @returns {void}
   */
  const handleOnChangeForms = (selectedFormsId) => {
    if (selectedFormsId.length === 0) {
      setIsErrorSelectForm(true);
    } else {
      setFormsActive({
        ...formsActives,
        form_uuid: selectedFormsId.value,
      });
      setIsErrorSelectForm(false);
    }
  };

  /**
   * Handles the form submission process.
   * This function validates the form data and displays error messages if any validation fails.
   * If validation passes, it sends a request to create a new workflow with the provided data.
   * It also manages the loading state and displays success or error messages based on the response.
   * @returns {Promise<void>}
   */
  const handleOnSubmit = async () => {
    if (formsActives.form_uuid === "") {
      setIsErrorSelectForm(true);
    } else {
      const data = {
        name: formsActives.name.trim(),
        description: formsActives.description.trim(),
        form_uuid: formsActives.form_uuid,
      };
      if (data.name.length < enumsDesignerWorkflow.MIN_LENGTH_NAME) {
        Swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createWorflow.nameError"),
          icon: "error",
          button: i18n.t("modal.Done.footerButton"),
        });
        return;
      }
      if (data.description.length < enumsDesignerWorkflow.MIN_LENGTH_DESCRIPTION) {
        Swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("createWorflow.descriptionError"),
          icon: "error",
          button: i18n.t("modal.Done.footerButton"),
        });
        return;
      }
      setIsLoadingForms(true);
      Created_workflow(data)
        .then((res) => {
          if (res.data.code === 5010) {
            Swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("createWorflow.alertSuccess"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location = `/workflow/designer/${res.data.data.uuid}`;
            });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingForms(false);
        });
    }
  };

  /**
   * Handles the key press event to prevent form submission on Enter key press.
   * This function listens for the Enter key press event and prevents the default
   * behavior, which is to submit the form. This is useful in scenarios where
   * form submission should be controlled manually.
   * @name handleOnKeyPress
   * @param {Object} eventCreateWorkflow - The event object generated by the key press.
   * @param {string} eventCreateWorkflow.key - The key that was pressed.
   * @param {Function} eventCreateWorkflow.preventDefault - Function to prevent the default action.
   * @returns {void}
   */
  const handleOnKeyPress = (eventCreateWorkflow) => {
    if (eventCreateWorkflow.key === "Enter") {
      eventCreateWorkflow.preventDefault();
    }
  };

  /**
   * useEffect hook to fetch and process the list of active forms whenever the getActiveForms
   * dependency changes.
   * This hook calls the getActiveForms function to fetch and process active forms data whenever
   * there is a change in the getActiveForms dependency. This ensures that the forms data is
   * kept up to date with any changes in pagination or search parameters.
   * @param {Function} getActiveForms - The function to fetch and process active forms data.
   * @param {Array} dependencies - The list of dependencies for the useEffect hook, which includes getActiveForms.
   * @returns {void}
   */
  useEffect(() => {
    getActiveForms();
  }, [getActiveForms]);

  return (
    <Fragment>
      {loaderElement(isLoadingForms)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{i18n.t("createWorflow.title")}</CardTitle>
            <AvForm onSubmit={handleOnSubmit}>
              <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>

              <AvGroup row>
                <Label for="name" className="is-required" sm={2}>
                  {i18n.t("createWorflow.inputOne")}
                </Label>
                <Col md={10}>
                  <AvField
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleOnChangeCreateWorkflow}
                    onKeyPress={handleOnKeyPress}
                    data-tip
                    data-for="name"
                    validate={{
                      pattern: {
                        value: regexDesignerWorkflow,
                        errorMessage: `${i18n.t("createWorflow.alert")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("createWorflow.alertName")}`,
                      },
                      minLength: {
                        value: enumsDesignerWorkflow.MIN_LENGTH_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsDesignerWorkflow.MAX_LENGTH_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={formsActives.name}
                  />
                </Col>
              </AvGroup>

              <AvGroup row>
                <Label for="description" className="is-required" sm={2}>
                  {i18n.t("createWorflow.description")}
                </Label>
                <Col md={10}>
                  <AvField
                    id="description"
                    name="description"
                    type="textarea"
                    onChange={handleOnChangeCreateWorkflow}
                    onKeyPress={handleOnKeyPress}
                    data-tip
                    data-for="description"
                    validate={{
                      pattern: {
                        value: regexDesignerWorkflowDescription,
                        errorMessage: `${i18n.t("createWorflow.alert")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "createWorflow.alertDescription"
                        )}`,
                      },
                      minLength: {
                        value: enumsDesignerWorkflow.MIN_LENGTH_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsDesignerWorkflow.MAX_LENGTH_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={formsActives.description}
                  />
                </Col>
              </AvGroup>

              <div className="row form-group">
                <Label
                  for="forms_uuid"
                  className={cx("is-required", {
                    "label-color": isErrorSelectForm === true,
                  })}
                  sm={2}
                >
                  {i18n.t("form.field43")}
                </Label>
                <Col md={10}>
                  <Select
                    id="forms_uuid"
                    name="forms_uuid"
                    classNamePrefix={cx("", {
                      select: isErrorSelectForm === true,
                    })}
                    placeholder={i18n.t("recordData2")}
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    value={selectedFormsId}
                    options={formsDinamic}
                    onChange={handleOnChangeForms}
                    noOptionsMessage={() => i18n.t("createRoles.configNoForms")}
                  />
                  {labelErrorSelectedForm}
                </Col>
              </div>

              <CardFooter className="d-block text-right">
                <Link to="/WorkFlow/list">
                  <Button size="lg" className="col-mt-3 mr-3" color="gray">
                    {i18n.t("createusers.createButton2")}
                  </Button>
                </Link>
                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingForms}
                  className="col-mt-3"
                  color="cyan"
                >
                  {i18n.t("createusers.createButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DesignerWorkflow;
