import React, { Fragment, useState, useCallback, useEffect, memo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Row, Col, Label, Spinner, Alert } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TYPE, ADD_WORKFLOW, VIEW_WORKFLOW } from "constants/securityConst";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import {
  enumsOptionsFiles,
  enumsStatusUser,
  enumsSteps,
  enumsTypeResponsibleNumber,
  enumsTypeStatusUser,
  enumsTypeUser
} from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import useWorkFlow from "hooks/useWorkFlow";
import WorkflowDetailList from "components/organism/WorkflowDetailList";
import swal from "sweetalert";
import useUser from "hooks/useUser";
import useLogin from "hooks/useLogin";
import i18n from "locales/i18n";

const WorkflowStart = (props) => {
  let MIN_RESPONSIBLE_LIST = 0;

  const { isLogged } = useLogin();
  const { id } = useParams();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const ADD_WORKFLOWS = window.localStorage.getItem(ADD_WORKFLOW);
  const VIEW_WORKFLOWS = window.localStorage.getItem(VIEW_WORKFLOW);
  const addWorkflow = isLogged && ADD_WORKFLOWS.includes(id);
  const viewWorkflow = isLogged && VIEW_WORKFLOWS.includes(id);
  const [isLoadingOnSubmitProcess, setIsLoadingOnSubmitProcess] = useState(
    false
  );
  const [listWorkflowDinamic, setListWorkflowDinamic] = useState([]);
  const [isLoadingWorkflowList, setIsLoadingWorkflowList] = useState(true);
  const { selectedOption } = useState([]);
  const [showErrorStartProccess, setShowErrorStartProccess] = useState(false);
  const [typeResponsible, setTypeResponsible] = useState();
  const [usersDinamic, setUsersDinamic] = useState([]);
  const [listResponsibles, setListResponsibles] = useState([]);
  const [listNamesResponsibles, setListNamesResponsibles] = useState([]);
  const [activeStateUuid, setactiveStateUuid] = useState({
    uuid: "",
  });
  const [startWorkflow, setStartWorkflow] = useState({
    workflow_uuid: "",
    record_uuid: props.recordUuid,
    record_number: props.recordNumber,
    form_uuid: id,
    link_files: "",
    second_user_uuid: "",
  });

  const { getUsers } = useUser();
  const {
    startWorkflows,
    getWorkFlowsByForm,
    getWorkFlowSteps,
    getListResponsibles,
  } = useWorkFlow();

  let workflowSelectPlaceholder = null;
  let workflowListPlaceholder = null;
  let selectDisable = false;
  let renderAlertLabelError = null;
  let detailRecordDetailsList = null;
  let spinnerLoadingWorkflowStart = null;
  let renderListResponsibles = null;
  let renderFormWorkflowStart = null;
  let renderSelectedForm = null;

  /**
   * Conditionally renders a loading spinner based on the loading state.
   * @param {boolean} isLoadingOnSubmitProcess - Flag indicating if the submit process is loading.
   * @returns {JSX.Element|null} The rendered Spinner component or null if not loading.
   */
  if (isLoadingOnSubmitProcess === true) {
    spinnerLoadingWorkflowStart = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Conditionally renders the WorkflowDetailList component based on user type and workflow view. 
   * @param {Object} props - The props for the component.
   * @param {string} props.recordUuid - The UUID of the record.
   * @param {string} props.status - The status of the record.
   * @param {Object} startWorkflow - Object containing workflow details.
   * @param {string} startWorkflow.form_uuid - The UUID of the form in the workflow.
   * @param {boolean} USERTYPE - The type of the user (should be a string).
   * @param {boolean} viewWorkflow - Flag indicating if the workflow view is enabled.
   * @returns {JSX.Element|null} The rendered WorkflowDetailList component or null if conditions are not met.
   */
  if (USERTYPE === enumsStatusUser.ADMIN || viewWorkflow === true) {
    detailRecordDetailsList = (
      <div>
        <WorkflowDetailList
          form_uuid={startWorkflow.form_uuid}
          recordUuid={props.recordUuid}
          recordStatus={props.status}
        />
      </div>
    );
  };

  /**
   * Renders a message indicating that a field is required if the 'isErrorConsultField' flag is set to true.
   * @param {boolean} isError - A flag indicating whether the field is in an error state.
   * @param {string} i18n.t - A function used for internationalization to translate the "fieldRequired" message.
   * @returns {JSX.Element | null} - A JSX element representing the required field message or null if no message is required.
   */
  if (showErrorStartProccess === true) {
    renderAlertLabelError = (
      <span className="text-danger text small">{i18n.t("fieldRequired")}</span>
    );
  } else {
    renderAlertLabelError = null;
  }

  /**
   * Conditionally sets the selectDisable and workflowListPlaceholder variables based on the isLoadingWorkflowList flag.
   * @param {boolean} isLoadingWorkflowList - Flag indicating whether the workflow list is currently loading.
   * @returns {void}
   */
  if (isLoadingWorkflowList === true) {
    selectDisable = true;
    workflowListPlaceholder = i18n.t("loading");
  } else {
    workflowListPlaceholder = i18n.t("createWorflow.select1");
  }

  /**
   * Sets the workflow select element's placeholder and selectDisable flag based on the isLoadingWorkflowList flag.
   * @param {boolean} isLoadingWorkflowList - Flag indicating whether the workflow list is currently loading.
   * @returns {void}
   */
  if (isLoadingWorkflowList === true) {
    selectDisable = true;
    workflowSelectPlaceholder = i18n.t("loading");
  } else {
    workflowSelectPlaceholder = i18n.t("notifications.filesOptions0");
  };

  /**
   * Handles the change event for updating the `link_files` property in the `startWorkflow` state.
   * @param {Object} eventChangeLinkFiles - The event object from the change event.
   * @param {Object} eventChangeLinkFiles.target - The target element of the event.
   * @param {string} eventChangeLinkFiles.target.value - The new value from the input element.
   */
  const handleChangeListFiles = (eventChangeLinkFiles) => {
    setStartWorkflow({
      ...startWorkflow,
      link_files: Number(eventChangeLinkFiles.target.value),
    });
  };

  /**
   * Handles changes to the responsible selection input.
   * @param {Object} eventSelectResponsible - The event object from the selection change.
   * @param {Function} setactiveStateUuid - Function to update the state with the new UUID.
   * @param {Object} activeStateUuid - The current state object containing the UUID.
   */
  const handleOnChangeSelectResponsible = (eventSelectResponsible) => {
    setactiveStateUuid({
      ...activeStateUuid,
      uuid: eventSelectResponsible.target.value,
    })
  };

  /**
   *This function uses useCallback hook to memoize and cache the function to retrieve workflows by search criteria.
   *This function retrieves workflows by search criteria using the getUsersByStatus and getWorkFlowsByForm functions.
   *It sets the list of workflows by filtering the list of workflows returned by getWorkFlowsByForm and mapping them to an object
   *with the uuid as value and the name as label. It sorts the list of workflows by name. It also sets the list of users by filtering
   *the list of users returned by getUsersByStatus. It does not depend on any variable.
   * @param {string} id - The id of the form to filter workflows by.
   * @param {number} status - The status to filter users by.
   * @param {string} id - The id of the form to filter workflows by.
   * @returns {Promise} A Promise that resolves with the list of users.
   */
  const getWorkflowBySearch = useCallback(() => {
    let pagination = { page: 1, per_page: 1000 };
    const { page, per_page } = pagination;
    let search = "";
    setIsLoadingWorkflowList(true);
    getUsers(page, per_page, search).then((response) => {
      let usersList = response.data.items;
      if (isNullOrUndefined(usersList) === false) {
        usersList.forEach((userToAssignProcess) => {
          if (
            userToAssignProcess.status !== enumsTypeStatusUser.INACTIVE &&
            userToAssignProcess.status !== enumsTypeStatusUser.RETIRED
          ) {
            setUsersDinamic((prevState) => [
              ...prevState,
              {
                value: userToAssignProcess.uuid,
                label: `${userToAssignProcess.first_name} ${userToAssignProcess.last_name} (${userToAssignProcess.user_name})`,
              },
            ]);
          }
        });
      } else {
        showAlertServiceError();
      }
    }).finally(() => setIsLoadingWorkflowList(false));

    getWorkFlowsByForm(id).then((response) => {
      let listWorkflows = response.data.data.items;
      if (isNullOrUndefined(listWorkflows) === false) {
        listWorkflows
          .sort((fristWorkflow, secondWorkflow) => {
            const firstPosition = `${fristWorkflow.name}`.toLocaleLowerCase();
            const secondPosition = `${secondWorkflow.name}`.toLocaleLowerCase();
            return secondPosition.localeCompare(firstPosition);
          })
          .forEach((user) => {
            setListWorkflowDinamic((prevState) => [
              ...prevState,
              {
                value: user.uuid,
                label: `${user.name}`,
              },
            ]);
          });
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the key press event when a key is pressed during workflow start.
   * @param {Event} pressEnter - The key press event object.
   */

  const handleOnKeyPressWorkFlowStart = (pressEnter) => {
    if (pressEnter.key === "Enter") {
      pressEnter.preventDefault();
    }
  };

  /**
   * Handles the change event when a new option is selected.
   * @param {Object} selectedOption - The selected option object.
   */
  const handleOnChange = (selectedOption) => {
    if (selectedOption.length === 0) {
      setShowErrorStartProccess(true);
    } else {
      setShowErrorStartProccess(false);
      setIsLoadingOnSubmitProcess(true);
      getWorkFlowSteps(selectedOption.value).then((response) => {
        if (isNullOrUndefined(response) === false) {
          response.forEach((step) => {
            if (step.index === enumsSteps.SECOND_STEP) {
              getListResponsibles(selectedOption.value, step.uuid).then(
                (response) => {
                  setListResponsibles(response.data.user_responsible_list);
                  setTypeResponsible(response.data.type_user_responsible);
                  if (response.data.type_user_responsible === enumsTypeResponsibleNumber.groupResponsible) {
                    setStartWorkflow(prevStartWorkflow => ({
                      ...prevStartWorkflow,
                      workflow_uuid: selectedOption.value,
                      second_user_uuid: response.data.work_group_responsible.work_group_uuid,
                    }));
                  } else {
                    setStartWorkflow(prevStartWorkflow => ({
                      ...prevStartWorkflow,
                      workflow_uuid: selectedOption.value,
                      second_user_uuid: response.data.user_responsible_list[0],
                    }));
                  }
                }
              );
            }
          });
        } else {
          showAlertServiceError();
        }
      }).finally(() => setIsLoadingOnSubmitProcess(false))
    }
  };

  /**
   * Perform a search for responsibles according to the type of responsible selected
   * and update the list of responsibles with the corresponding users.
   * @param {number} typeResponsible - Selected type of responsible.
   * @param {Array} listResponsibles - List of selected responsibles.
   * @param {Array} usersDinamic - List of available users.
   * @param {Array} setListNamesResponsibles - set the List of names for responsible users.
   */

  const handleOnSubmit = (eventProcessSubmit, errors) => {
    if (startWorkflow.workflow_uuid.length === 0) {
      setShowErrorStartProccess(true);
      setIsLoadingOnSubmitProcess(false);
      eventProcessSubmit.preventDefault();
    }

    eventProcessSubmit.preventDefault();
    if (errors.length === 0) {
      if (startWorkflow.workflow_uuid.length === 0 || startWorkflow.link_files === "") {
        eventProcessSubmit.preventDefault();
      } else {
        setIsLoadingOnSubmitProcess(true);
        let actions = 0;
        if (typeResponsible === enumsTypeResponsibleNumber.listOfResponsible) {
          const data = {
            ...startWorkflow,
            workflow_uuid: startWorkflow.workflow_uuid,
            record_uuid: props.recordUuid,
            record_number: props.recordNumber,
            form_uuid: id,
            link_files: startWorkflow.link_files,
            second_user_uuid: activeStateUuid.uuid,
          };
          startWorkflows(data, actions)
            .then((response) => {
              if (response.status === 201) {
                const showAlertStartWorkflow = () => {
                  swal({
                    title: `${i18n.t("createWorflow.ProcessAlert")} ${response.data.data.consecutive
                      }`,
                    text: i18n.t("createWorflow.ProcessCreated"),
                    icon: "success",
                    button: i18n.t("modal.Done.footerButton"),
                  }).then(() => {
                    window.location.reload();
                  });
                };
                showAlertStartWorkflow();
              } else {
                showAlertServiceError();
              }
            })
            .finally(() => {
              setIsLoadingOnSubmitProcess(false);
            });
        } else {
          startWorkflows(startWorkflow, actions)
            .then((response) => {
              if (response.status === 201) {
                const showAlertStartWorkflow = () => {
                  swal({
                    title: `${i18n.t("createWorflow.ProcessAlert")} ${response.data.data.consecutive
                      }`,
                    text: i18n.t("createWorflow.ProcessCreated"),
                    icon: "success",
                    button: i18n.t("modal.Done.footerButton"),
                  }).then(() => {
                    window.location.reload();
                  });
                };
                showAlertStartWorkflow();
              } else {
                showAlertServiceError();
              }
            })
            .finally(() => {
              setIsLoadingOnSubmitProcess(false);
            });
        }
      }
    }
  };

  /**
   * Executes a side effect when the component mounts or when its dependencies change.
   * @param {Function} effect - The function containing the side effect logic.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   */
  useEffect(() => {
    if (isNullOrUndefined(listResponsibles) === false) {
      if (
        typeResponsible === enumsTypeResponsibleNumber.listOfResponsible &&
        listResponsibles.length > MIN_RESPONSIBLE_LIST &&
        usersDinamic.length > MIN_RESPONSIBLE_LIST
      ) {
        const filteredUsers = usersDinamic.filter(({ value }) =>
          listResponsibles.includes(value)
        );
        setListNamesResponsibles(filteredUsers);
      }
    }
    // eslint-disable-next-line
  }, [typeResponsible, listResponsibles, usersDinamic]);

  /**
   *This React effect is used to load the list of workflows based on the search.
   *Fired when the component is mounted or when the getWorkflowBySearch dependency changes.
   *@param {function} getWorkflowBySearch - The function to get workflows based on search.
   */
  useEffect(() => {
    getWorkflowBySearch();
  }, [getWorkflowBySearch]);

  /**
   * Conditionally renders a list of responsible users based on the type of responsible.
   * @param {number} typeResponsible - The type of responsible, where 2 triggers rendering of the list.
   * @param {Object} activeStateUuid - The state object containing the UUID value.
   * @param {Function} setactiveStateUuid - Function to update the active state UUID.
   * @param {Array} listNamesResponsibles - Array of responsible users with `value` and `label`.
   * @param {Function} handleOnKeyPressWorkFlowStart - Function to handle key press events.
   * @param {Object} i18n - Object for internationalization, containing translation functions.
   * @returns {JSX.Element|null} The rendered list of responsible users or null if typeResponsible is not 2.
   */
  if (typeResponsible === enumsTypeResponsibleNumber.listOfResponsible) {
    renderListResponsibles = (
      <Row>
        <Col md="5">
          <AvGroup>
            <Label
              for="new_responsible"
              className="is-required"
            >
              {i18n.t(
                "createWorkflow.config.listResponsibleSelects"
              )}
            </Label>
            <AvField
              id="new_responsible"
              name="new_responsible"
              type="select"
              onChange={handleOnChangeSelectResponsible}
              value={activeStateUuid.uuid}
              onKeyPress={
                handleOnKeyPressWorkFlowStart
              }
              validate={{
                required: {
                  value: true,
                  errorMessage: `${i18n.t(
                    "fieldRequired"
                  )}`,
                },
              }}
            >
              <option value="">
                {i18n.t(
                  "createWorkflow.config.listResponsibleSelectsOne"
                )}
              </option>

              {listNamesResponsibles.map((user) => (
                <option value={user.value}>
                  {user.label}
                </option>
              ))}
            </AvField>
          </AvGroup>
        </Col>
      </Row>
    );
  };

  /**
   * Conditionally renders a form or an alert based on the user type and workflow states. 
   * @param {string} USERTYPE - The type of the user, where "3" affects the rendering.
   * @param {boolean} addWorkflow - Flag indicating if adding a workflow is enabled.
   * @param {boolean} viewWorkflow - Flag indicating if viewing the workflow is enabled.
   * @param {Object} startWorkflow - Object containing the state for the workflow start.
   * @param {Function} handleOnSubmit - Function to handle form submission.
   * @param {Object} i18n - Object for internationalization, containing translation functions.
   * @param {string} workflowListPlaceholder - Placeholder text for the workflow select input.
   * @param {boolean} selectDisable - Flag indicating if the select input should be disabled.
   * @param {Object} selectedOption - The currently selected option in the workflow select input.
   * @param {Array} listWorkflowDinamic - Array of options for the workflow select input.
   * @param {Function} handleOnChange - Function to handle changes in the workflow select input.
   * @param {Function} handleOnKeyPressWorkFlowStart - Function to handle key press events in the form.
   * @param {Object} renderAlertLabelError - JSX to render error alert for the label.
   * @param {Object} renderListResponsibles - JSX to render the list of responsible users.
   * @param {boolean} isLoadingOnSubmitProcess - Flag indicating if the submit process is loading.
   * @param {JSX.Element} spinnerLoadingWorkflowStart - JSX element for the loading spinner.
   * @param {string} workflowSelectPlaceholder - Placeholder text for the file select input.
   * @returns {JSX.Element|null} The rendered form or alert based on the conditions.
   */
  if (
    USERTYPE !== enumsTypeUser.ADMIN &&
    addWorkflow === false &&
    viewWorkflow === false
  ) {
    renderFormWorkflowStart = (
      <Alert color="info">
        <h4 className="alert-heading">
          {i18n.t("modal.DoneError.header")}
        </h4>
        <hr />
        <h6>{i18n.t("showNotification403")}</h6>
      </Alert>
    );
  } else if (addWorkflow === false && viewWorkflow) {
    renderFormWorkflowStart = null;
  } else {
    renderFormWorkflowStart = (
      <div>
        <div>
          <AvForm onSubmit={handleOnSubmit}>
            <Alert className="mbg-3" color="info">
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("RecordForm.Alert")}
            </Alert>

            <Row>
              <Col md="5">
                <Label
                  for="workflow_uuid"
                  className={cx("is-required", {
                    "label-color is-required": showErrorStartProccess === true,
                  })}
                >
                  {i18n.t("createWorflow.selectLabel")}
                </Label>

                <div>
                  <Select
                    id="workflow_uuid"
                    name="workflow_uuid"
                    classNamePrefix={cx("", {
                      select: showErrorStartProccess === true,
                    })}
                    placeholder={workflowListPlaceholder}
                    isDisabled={selectDisable}
                    closeMenuOnSelect={true}
                    components={makeAnimated()}
                    value={selectedOption}
                    options={listWorkflowDinamic}
                    onChange={handleOnChange}
                    onKeyPress={
                      handleOnKeyPressWorkFlowStart
                    }
                    noOptionsMessage={() => i18n.t("createWorflow.noOptions")}
                  />
                  {renderAlertLabelError}
                </div>
              </Col>
              <Col md="5">
                <AvGroup>
                  <Label for="link_files" className="is-required">
                    {i18n.t("notifications.files")}
                  </Label>

                  <AvField
                    id="link_files"
                    name="link_files"
                    type="select"
                    onChange={handleChangeListFiles}
                    disabled={selectDisable}
                    value={startWorkflow.link_files}
                    onKeyPress={handleOnKeyPressWorkFlowStart}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "fieldRequired"
                        )}`,
                      },
                    }}
                  >
                    <option value="">
                      {workflowSelectPlaceholder}
                    </option>
                    <option value={enumsOptionsFiles.LAST_FILE}>
                      {i18n.t("notifications.filesOptions1")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_TWO}>
                      {i18n.t("notifications.filesOptions2")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_THREE}>
                      {i18n.t("notifications.filesOptions3")}
                    </option>
                    <option value={enumsOptionsFiles.LAST_FOUR}>
                      {i18n.t("notifications.filesOptions4")}
                    </option>
                    <option value={enumsOptionsFiles.ALL_FILES}>
                      {i18n.t("notifications.filesOptions5")}
                    </option>
                    <option value={enumsOptionsFiles.UNLINK_FILES}>
                      {i18n.t("notifications.filesOptions6")}
                    </option>
                  </AvField>
                </AvGroup>
              </Col>
              <Col
                md="2"
                className="workflow-start-aling"
              >
                <AvGroup>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isLoadingOnSubmitProcess}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {spinnerLoadingWorkflowStart}
                    {i18n.t("createWorflow.StarButton")}
                  </Button>
                </AvGroup>
              </Col>
            </Row>
            {renderListResponsibles}
          </AvForm>
        </div>
      </div>
    );
  };

  /**
   * Conditionally renders a form with animation or an alert message based on the status.
   * @param {Object} props - The props object containing the status.
   * @param {number} props.status - The status that determines which component to render.
   * @param {JSX.Element} renderFormWorkflowStart - JSX to render the workflow start form.
   * @param {Object} i18n - Object for internationalization, containing translation functions.
   * @returns {JSX.Element} The rendered form with animation or an alert message.
   */
  if (props.status !== 2) {
    renderSelectedForm = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <br />
        {renderFormWorkflowStart}
      </CSSTransitionGroup>
    );
  } else {
    renderSelectedForm = (
      <div className="mt-3 ">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} :{" "}
            </span>
            <span>{i18n.t("RecordDetail.label6")}</span>
          </span>
        </Alert>
      </div>
    );
  };

  return (
    <Fragment>
      {renderSelectedForm}
      <div className="divider" />
      {detailRecordDetailsList}
    </Fragment>
  );
};

WorkflowStart.propTypes = {
  recordUuid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default memo(WorkflowStart);