import axios from "axios";
import { PASSWORD_EXPIRATION_DAYS } from "../constants/securityConst";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { handleFieldsErrors } from "./axiosHandleFieldsErros";
import { showAlert } from "components/molecules/Alert/Alert";
import { errorType, timeout } from "./enums";
import { isNullOrUndefined } from "./validations";
import tranlastionEs from "locales/es/translation.json";
import swal from "sweetalert";
import i18n from "locales/i18n";

const useAxiosInterceptor = () => {
  const { setValidationField, setValidationFieldDatePicker } = useFieldsRecordContext();

  /**
   * Function that defines an Axios interceptor to handle response errors based on HTTP status codes.
   * @returns {void}
   */
  const insterceptor = () => {
    axios.interceptors.request.use(
      (config) => {
        config.timeout = timeout.ONE_HUNDRED_EIGHTY_SECONDS;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.code === errorType.ECONNABORTED && error.message.includes("timeout")) {
          showAlert({
            text: `(${error.config.endpointError})  ${i18n.t("timeout.error.alert")}`,
            icon: "error",
          }).then(() => {
            window.location.href = "/";
          });
          return Promise.reject(error);
        }

        if (!error.response && !error.status) {
          return Promise.reject(error);
        }

        switch (error.response.status) {
          case 400:
            if (error.response.status === 400) {
              let message_backend_code = error.response.data.code;
              if (message_backend_code === "30010") {
                const errorData = error.response.data.data;
                const isErrorsInFields = handleFieldsErrors(errorData);

                if (isErrorsInFields.length > 0) {
                  message_backend_code = isErrorsInFields[0];
                }
              }
              if (
                (message_backend_code >= 1000 && message_backend_code < 40001) ||
                (message_backend_code > 40001 && message_backend_code < 50046) ||
                (message_backend_code > 50046 && message_backend_code <= 500062)
              ) {
                if (isNullOrUndefined(error.response.data.data) === false) {
                  setValidationField({
                    id: Object.keys(error.response.data.data),
                    unique: true,
                  });

                  setValidationFieldDatePicker({
                    id: Object.keys(error.response.data.data),
                    required: true,
                  });
                }

                const showAlertServiceError = () => {
                  swal({
                    title: i18n.t("modal.DoneError.header"),
                    text: `${tranlastionEs[message_backend_code]}`,
                    icon: "error",
                    button: i18n.t("modal.Done.footerButton"),
                    dangerMode: true,
                  }).then(() => {
                    if (
                      Number(message_backend_code) === 30011 ||
                      Number(message_backend_code) === 30029
                    ) {
                      window.location.reload();
                    } else if (
                      Number(message_backend_code) === 500041 ||
                      Number(message_backend_code) === 500042
                    ) {
                      window.location.href = "/";
                    } else if (Number(message_backend_code) === 50059) {
                      window.location.href = "/taskBox";
                    } else {
                      return null;
                    }
                  });
                };
                showAlertServiceError();
              }
              if (message_backend_code === undefined) {
                let message_backend_code_detail = error.response.data.detail;
                const showAlertServerError1 = () => {
                  swal({
                    title: i18n.t("modal.DoneError.header"),
                    text: `${message_backend_code_detail}`,
                    icon: "error",
                    button: i18n.t("modal.Done.footerButton"),
                    dangerMode: true,
                  });
                };
                showAlertServerError1();
              }
            }
            break;

          case 401:
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("showNotification401"),
              icon: "error",
              button: i18n.t("modal.Done.footerButton"),
              dangerMode: true,
            }).then(() => {
              window.localStorage.clear();
              window.location = "/";
            });
            break;

          case 403:
            if (error.response.status === 403) {
              const expiration_days = window.localStorage.getItem(PASSWORD_EXPIRATION_DAYS);
              if (expiration_days === "0") {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: `${i18n.t("expirated.password")}`,
                  icon: "info",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(function () {
                  window.location = "/dashboard";
                });
              } else {
                let message_backend_code = error.response.data.code;
                if (message_backend_code >= 20000 && message_backend_code <= 40007) {
                  setValidationField({
                    id: Object.keys(error.response.data.data),
                    unique: true,
                  });
                  const showAlertServiceError = () => {
                    swal({
                      title: i18n.t("modal.DoneError.header"),
                      text: `${tranlastionEs[message_backend_code]}`,
                      icon: "error",
                      button: i18n.t("modal.Done.footerButton"),
                      dangerMode: true,
                    }).then(function () {
                      window.location = "/dashboard";
                    });
                  };
                  showAlertServiceError();
                }
                if (message_backend_code === undefined) {
                  const showAlertServerError1 = () => {
                    swal({
                      title: i18n.t("modal.DoneError.header"),
                      text: i18n.t("showNotification403"),
                      icon: "error",
                      button: i18n.t("modal.Done.footerButton"),
                      dangerMode: true,
                    }).then(function () {
                      window.location = "/dashboard";
                    });
                  };
                  showAlertServerError1();
                }
              }
            }
            break;

          case 500:
            if (error.response.status === 500) {
              let message_backend_code_detail = error.response.data.detail;
              const showAlertServerError1 = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: `${message_backend_code_detail}`,
                  icon: "error",
                  button: i18n.t("modal.Done.footerButton"),
                  dangerMode: true,
                });
              };
              showAlertServerError1();
            }
            let message_backend_code_detail = error.response.data.detail;
            if (message_backend_code_detail === undefined) {
              const showAlertServerError = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("showNotification500"),
                  icon: "error",
                  button: i18n.t("modal.Done.footerButton"),
                  dangerMode: true,
                });
              };
              showAlertServerError();
            }
            break;

          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  };

  insterceptor();
};

export default useAxiosInterceptor;
