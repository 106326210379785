import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardFooter } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useParams } from "react-router-dom";
import SignatoriesViewListTable from "components/organism/SignatoriesViewListTable";
import useIntegrations from "hooks/useIntegrations";
import ButtonBackSignatureList from "components/atoms/ButtonBackSignatureList";

const SignatoriesViewList = () => {
  const { id } = useParams();
  const [signatories, setSignatories] = useState([]);
  const [isLoadingSignatories, setIsLoadingSignatories] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const { getSignatories } = useIntegrations();

  /**
   * Fetches signatories based on search criteria and updates the state with the results.
   * @param {Object} pagination - An object containing page and per_page values for pagination.
   * @param {number} pagination.page - The current page number for signatory retrieval.
   * @param {number} pagination.per_page - The number of signatories to display per page.
   * @param {string} id - The identifier used for searching signatories.
   * @param {function} setIsLoadingSignatories - State setter function for loading indicator.
   * @param {function} setSignatories - State setter function for the retrieved signatories list.
   * @param {function} setTotalPages - State setter function for the total number of pages.
   * @param {function} showAlertServiceError - Function to display an alert for service errors.
   * * @returns {void}
   */
  const getSignatoriesBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingSignatories(true);
    getSignatories(page, per_page, id)
      .then((response) => {
        const signatoriesList = response.data.data.items;
        const pagesSignatories = response.data.data.pages;
        if (
          isNullOrUndefined(signatoriesList) === false &&
          isNullOrUndefined(pagesSignatories) === false
        ) {
          setSignatories(signatoriesList);
          setTotalPages(pagesSignatories);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingSignatories(false);
      });
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that calls the 'getSignatoriesBySearch' function when the 'getSignatoriesBySearch' dependency changes.
   * This effect is triggered when the 'getSignatoriesBySearch' function reference changes, typically indicating that
   * it's being updated or when other dependencies related to this function change.
   * This effect is used to trigger the 'getSignatoriesBySearch' function when its dependencies change. It is commonly used for data fetching and related side effects.
   * @param {function} getSignatoriesBySearch - The function responsible for fetching signatories based on a search query or other criteria.
   * It should return or update the signatory data as needed.
   * @returns {void}
   */
  useEffect(() => {
    getSignatoriesBySearch();
  }, [getSignatoriesBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto btn-icon mr-3">
            <ButtonBackSignatureList />
          </CardFooter>

          <SignatoriesViewListTable
            signatories={signatories}
            pagination={pagination}
            isLoadingSignatories={isLoadingSignatories}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default SignatoriesViewList;
