import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import TrdOrdinationUnit from "components/organism/TrdOrdinationUnit";

const TrdOrdinationUnits = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("trdHeading")}
        subheading={i18n.t("trdSubHeading")}
        icon="copy-file"
      >
        <TrdOrdinationUnit />
      </AdminTemplate>
    </Fragment>
  );
};

export default TrdOrdinationUnits;
