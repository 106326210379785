import React, { useEffect, Fragment, useState, useCallback } from "react";
import Slider from "react-slick";
import {
  AvForm,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Button, Label, Spinner } from "reactstrap";
import useUser from "hooks/useUser";
import ShowConfigurationPassWord from "components/atoms/ShowConfigurationPassWord";
import usePasswordToggle from "customsHooks/usePasswordToggle";
import {
  showAlertServiceError,
  showSweetAlertInvalidPassword,
  showSweetAlertMatch,
  showSweetAlertSuccessNewPassword
} from "utils/alerts";
import { enumsChangePassword } from "utils/enums";
import { settingForgottenPassword } from "utils/settingAnimations";
import { isNullOrUndefined } from "utils/validations";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ChangePassword = (props) => {
  const { location } = props;
  const {
    inputType: inputTypeNewPassword,
    TogglePasswordIcon: ToggleNewPassword
  } = usePasswordToggle();

  const {
    inputType: inputTypeConfirmPassword,
    TogglePasswordIcon: ToggleConfirmPassword
  } = usePasswordToggle();

  const params = new URLSearchParams(location.search);
  const [changePassword, setChangePasword] = useState({
    new_password: "",
    new_password_confirm: "",
  });
  const [paramsUser, setParamsUser] = useState({
    userId: "",
    token: "",
    company: "",
  });
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  const history = useHistory();
  const { createPassword } = useUser();

  let loadingComponent = null;

  /**
   * Conditionally renders a loading spinner component based on the value of isLoadingChangePassword.
   * @param {boolean} isLoadingChangePassword - A flag indicating whether the change password process is loading.
   * @returns {JSX.Element|null} A JSX element representing a spinner if loading, otherwise null.
   */
  if (isLoadingChangePassword) {
    loadingComponent = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Creates a new password for the user using the provided information and handles related actions.
   * @param {Object} changePassword - An object containing the new password and confirmation.
   * @param {Object} paramsUser - An object containing user information such as user ID and token.
   * @returns {void}
   */
  const createPasswordUser = useCallback((changePassword, paramsUser) => {
    setIsLoadingChangePassword(true);
    createPassword(changePassword, paramsUser)
      .then((response) => {
        if (isNullOrUndefined(response.status) === false) {
          if (response.status === 202) {
            showSweetAlertSuccessNewPassword();
            history.push("/");
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingChangePassword(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event when the user modifies the password input fields.
   * @param {Event} eventChangePassword - The change event object for the password input fields.
   * @returns {void}
   */
  const handleOnChangePassword = (eventChangePassword) => {
    setChangePasword({
      ...changePassword,
      [eventChangePassword.target.name]: eventChangePassword.target.value,
    });
  };

  /**
   * Handles the submission of the password change form and performs necessary validations.
   * @returns {void}
   */
  const handleOnSubmitChangePassword = () => {
    if (
      changePassword.new_password.length > 7 &&
      changePassword.new_password.match(/[0-9]/) &&
      changePassword.new_password.match(/[A-Z]/) &&
      changePassword.new_password.match(/[^A-Z a-z0-9]/)
    ) {
      if (changePassword.new_password !== changePassword.new_password_confirm) {
        showSweetAlertMatch();
      } else {
        createPasswordUser(changePassword, paramsUser);
      }
    } else {
      showSweetAlertInvalidPassword();
    }
  };

  /**
   * Executes the effect when the component mounts.
   * Parses query parameters from the URL and sets the `paramsUser` state object.
   * @returns {void}
   */
  useEffect(() => {
    const userId = params.get("user-id");
    const token = params.get("token");

    if (isNullOrUndefined(userId) === false
      && isNullOrUndefined(token) === false) {
      setParamsUser({
        userId: userId,
        token: token
      });
    } else {
      swal({
        title: i18n.t('modal.DoneError.header'),
        text: i18n.t("showNotification403"),
        icon: "error",
        button: i18n.t('modal.Done.footerButton')
      }).then(() => {
        history.push("/");
      })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settingForgottenPassword}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg changeforgottenPassword-image1"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("dashboardheading")}</h3>
                    <p>{i18n.t("changePassword.p1")}</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg changeforgottenPassword-image2"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("changePassword.t2")}</h3>
                    <p>{i18n.t("changePassword.p2")}</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-6 changeforgottenPassword-image3"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("changePassword.t3")}</h3>
                    <p>{i18n.t("changePassword.p3")}</p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
              <div className="app-logo" />
              <h4>
                <div>{i18n.t("dashboardsubheading")}</div>
                <span>{i18n.t("changePassword.tittle1")}</span>
              </h4>
              <div>
                <AvForm onSubmit={handleOnSubmitChangePassword}>
                  <Row form>
                    <Col md={12}>
                      <AvGroup>
                        <Label for="password">
                          {i18n.t("changePassword.label1")}
                        </Label>
                        <div className="position-relative">
                          <AvField
                            id="new_password"
                            name="new_password"
                            type={inputTypeNewPassword}
                            onChange={handleOnChangePassword}
                            data-tip
                            data-for="input_password1"
                            validate={{
                              required: { value: true, errorMessage: `${i18n.t("fieldRequired")}` },
                              maxLength: {
                                value: enumsChangePassword.MAX_LENGTH,
                              },
                            }}
                            placeholder={i18n.t("login.placeholder2")}
                            autoComplete="off"
                            value={changePassword.new_password}
                          />
                          {ToggleNewPassword}
                        </div>
                        <ShowConfigurationPassWord changePassword={changePassword} />
                      </AvGroup>
                    </Col>

                    <Col md={12}>
                      <AvGroup>
                        <Label for="confirm_password">
                          {i18n.t("changePassword.label2")}
                        </Label>
                        <div className="position-relative">

                          <AvField
                            onChange={handleOnChangePassword}
                            type={inputTypeConfirmPassword}
                            name="new_password_confirm"
                            id="new_password_confirm"
                            data-tip
                            data-for="input_password2"
                            validate={{
                              maxLength: {
                                value: enumsChangePassword.MAX_LENGTH,
                              },
                              required: { value: true, errorMessage: `${i18n.t("fieldRequired")}` },
                            }}
                            placeholder={i18n.t("login.placeholder2")}
                            autoComplete="off"
                            value={changePassword.new_password_confirm}
                          />
                          {ToggleConfirmPassword}
                        </div>
                      </AvGroup>
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex align-items-center">
                    <h6 className="mb-0">
                      <Link
                        to="/"
                        className="text-primary"
                      >
                        {i18n.t("forgotPassword.ref")}
                      </Link>
                    </h6>
                    <div className="ml-auto">
                      <Button
                        type="submit"
                        color="cyan"
                        size="lg"
                        disabled={isLoadingChangePassword}
                        onClick={handleOnSubmitChangePassword}
                      >
                        {loadingComponent}
                        {i18n.t("changePassword.button")}
                      </Button>
                    </div>
                  </div>
                </AvForm>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
