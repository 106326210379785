import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Card, CardBody, CardFooter, Button, Collapse, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getDateFormat3 } from "utils/getDateFormat3";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { enumsTask } from "utils/enums";
import useWorkFlow from "hooks/useWorkFlow";
import Loader from "react-loaders";
import TasksFilesListTable from "components/organism/TasksFilesListTable";
import TaskRecordDetailView from "components/organism/TasksDetails/TaskRecordDetailView";
import RecordTraceabilityTable from "./RecordTraceabilityTable";
import RecordTraceabilityComments from "./RecordTraceabilityComments";
import useRecord from "hooks/useRecord";
import i18n from "locales/i18n";

const RecordTraceabilitys = () => {
  const { id, recordId } = useParams();
  const { getTaskFromFiledByUuid } = useWorkFlow();
  const { getNotifiedUsersById } = useRecord();
  const [acordeon, setAcordeon] = useState(false);
  const [task, setTask] = useState([]);
  const [isLoadingRecord, setIsLoadingRecord] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [data, setData] = useState([]);

  let isOpenRecordTraceability = false;
  let loadingRecordTracebility = null;
  let renderStatusTask = null;
  let renderDetailViewRecord = null;

  /**
   * Conditionally renders the detail view component for a task record if `showDetail` is true.
   * @param {boolean} showDetail - A boolean indicating whether to show the detail view of the task record.
   * @param {string} recordId - The unique identifier of the record to display.
   * @param {Object} task - The task object containing information about the form.
   * @param {string} task.form_uuid - The unique identifier of the form associated with the task.
   * @returns {JSX.Element|undefined} - A JSX element representing the detail view of the task record if `showDetail` is true, or `undefined` if false.
   */
  if (showDetail === true) {
    renderDetailViewRecord = (
      <TaskRecordDetailView
        recordUuid={recordId}
        formUuid={task.form_uuid}
      />
    );
  };

  /**
   * Conditionally renders a status badge for a task based on its current status.
   * @param {Object} task - The task object containing the status.
   * @param {string} task.status - The current status of the task.
   * @param {Object} enumsTask - An object containing the possible status values for tasks.
   * @param {string} enumsTask.IN_PROGRESS - The value representing a task that is in progress.
   * @param {string} enumsTask.COMPLETED - The value representing a task that is completed.
   * @returns {JSX.Element|undefined} - A JSX element representing the status badge if the status matches a condition, or `undefined` if no condition is met.
   */
  if (task.status === enumsTask.IN_PROGRESS) {
    renderStatusTask = (
      <p className="badge badge-info">
        {i18n.t("taskDetailStatus1")}
      </p>
    )
  } else if (task.status === enumsTask.COMPLETED) {
    renderStatusTask = (
      <p className="badge badge-success">
        {i18n.t("createWorflow.Label3.31")}
      </p>
    )
  };

  /**
   * Conditionally renders a loading spinner component if `isLoadingRecord` is true.
   * @param {boolean} isLoadingRecord - A boolean indicating whether the record is currently loading.
   * @returns {JSX.Element|undefined} - A JSX element representing the loading spinner if `isLoadingRecord` is true, or `undefined` if false.
   */
  if (isLoadingRecord === true) {
    loadingRecordTracebility = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Sets the value of `isOpenRecordTraceability` based on the value of `acordeon`.
   * @param {boolean} acordeon - A boolean value that determines whether `isOpenRecordTraceability` should be true or false.
   * @returns {void} This function does not return a value. It updates the `isOpenRecordTraceability` variable.
   */
  if (acordeon === true) {
    isOpenRecordTraceability = true;
  } else {
    isOpenRecordTraceability = false;
  }

  /**
   * Retrieves tasks by ID from a file and updates the state with the fetched data.
   * @returns {void}
   */
  const getTasksById = useCallback(() => {
    setIsLoadingRecord(true);
    getTaskFromFiledByUuid(id)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setTask(response.data.data);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingRecord(false);
      });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Toggles the value of the 'acordeon' state.
   * @returns {void}
   */
  const handleOnClickAcordeon = () => {
    if (acordeon === false) {
      setAcordeon(true);
    } else {
      setAcordeon(false);
    }
  };

  /**
   * Retrieves notified users by ID and updates the state with the fetched data.
   * @returns {void}
   */
  const getNotifiedUser = useCallback(() => {
    setIsLoadingRecord(true);
    getNotifiedUsersById(id).then((response) => {
      if (isNullOrUndefined(response.data) === false) {
        setData(response.data.items);
      } else {
        showAlertServiceError();
      }
    });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the 'getNotifiedUser' function when the 'getNotifiedUser' dependency changes.
   * @returns {void}
   */
  useEffect(() => {
    getNotifiedUser();
  }, [getNotifiedUser]); // eslint-disable-line react-hooks/exhaustive-deps


  /**
   * Executes the 'getTasksById' function when the 'getTasksById' dependency changes.
   * @returns {void}
   */
  useEffect(() => {
    getTasksById();
  }, [getTasksById]);

  /**
   * Conditionally renders a loading screen with a centered loader component if `isLoadingRecord` is true.
   * @param {boolean} isLoadingRecord - A boolean indicating whether the record is currently loading.
   * @returns {JSX.Element|null} - A JSX element representing the loading screen if `isLoadingRecord` is true, or `null` if false.
   */
  if (isLoadingRecord === true) {
    return (
      <div className="text-center mx-auto my-auto">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="secondary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <Row>
                <Col md="4"></Col>

                <Col md="8">
                  <Row>
                    <Button
                      className="ml-auto btn-icon mr-2"
                      color="cyan"
                      type="submit"
                      onClick={() => window.history.back()}
                    >
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                      {i18n.t("form.field59")}
                      {loadingRecordTracebility}
                    </Button>
                  </Row>
                </Col>
              </Row>
              <div className="divider" />
              <Row>
                <Col md="7" className=" border-right">
                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("taskDetail1")} : &nbsp;
                    </h6>
                    <p className="font-weight-bold fs-5">{task.consecutive}</p>
                  </Row>

                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("filteruser.button")} : &nbsp;
                    </h6>
                    {renderStatusTask}
                  </Row>
                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("trd.fromLabel3")} : &nbsp;
                    </h6>
                    <p>{task.subject}</p>
                  </Row>
                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("taskBoxTable9")} : &nbsp;
                    </h6>
                    <p>{i18n.t("taskDetailType1")}</p>
                  </Row>
                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("taskBoxTable1")} : &nbsp;
                    </h6>
                    <p>{task.sender_fullname}</p>
                  </Row>
                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("taskDetailSend")} : &nbsp;
                    </h6>
                    <p>{getDateFormat3(new Date(task.created_at))}</p>
                  </Row>

                  <Row className="pl-3 pt-1">
                    <h6 className="font-weight-bold text-cyan">
                      {i18n.t("taskBoxTable7")} : &nbsp;
                    </h6>
                    <p className="text-danger">
                      {getDateFormat3(new Date(task.expiration_date))}
                    </p>
                  </Row>

                  <div className="pl-2 pt-1">
                    <Button
                      color="info"
                      className="mr-auto btn-icon"
                      onClick={() => handleOnClickAcordeon(setShowDetail(true))}
                      aria-expanded={handleOnClickAcordeon}
                      aria-controls="collapseOne"
                      size="lg"
                    >
                      <FontAwesomeIcon icon={faAngleDown} className="mr-2 ml-auto " />
                      {loadingRecordTracebility}
                      {i18n.t("taskDetailRecord")} : {task.record_number}
                    </Button>
                  </div>
                  <Collapse
                    className="pl-3 pt-0"
                    isOpen={isOpenRecordTraceability}
                    data-parent="#accordion"
                    id="collapseOne"
                    aria-labelledby="headingOne"
                  >
                    {renderDetailViewRecord}
                  </Collapse>
                  <RecordTraceabilityTable data={data} />
                </Col>
                <Col md="5">
                  <div className="pl-3">
                    <TasksFilesListTable id={id} />
                  </div>
                  <div className="p-3 pt-4">
                    <RecordTraceabilityComments isProcess={false} id={id} />
                  </div>
                </Col>
              </Row>
              <br />
              <CardFooter className="d-block text-right"></CardFooter>
            </CardBody>
          </Card>
        </Col>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default RecordTraceabilitys;
