import React, { Fragment, useState, useCallback, useEffect } from "react";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { Card, CardFooter } from "reactstrap";
import {
  TYPE,
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { enumsActionsTypes, enumsFormStatusDelete } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import FormListTable from "components/organism/FormListTable";
import FilterFormState from "components/atoms/FilterFormState";
import ButtonCreateForm from "components/atoms/ButtonCreateForm";
import SearchBox from "components/atoms/SearchBox";
import useForms from "hooks/useForm";
import i18n from "locales/i18n";
import swal from "sweetalert";

const FormList = () => {
  const USERTYPE = window.localStorage.getItem(TYPE);
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(
    DESIGNER_FORMS_PERMISSIONS
  );
  const newForms = DESIGNER_FROMS_PERMISSION.split(",");
  const [listForms, setListForms] = useState([]);
  const [permissionsForms, setPermissionsForms] = useState([]);
  const [isLoadingFormsList, setIsLoadingFormsList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchFormList, setSearchFormList] = useState("");
  const [statusForm, setStatusForm] = useState("");
  const { getFormsAvailablesPerUser } = useForms();

  let formstoShowInList = null;
  let disabledButtonCreateForm = null;

  /**
   * Determines the state of the create form button based on the designer form statusForm.
   * If `DESIGNER_FORM` is "false", `disabledButtonCreateForm` is set to `true`.
   * Otherwise, it is set to `false`.
   * @param {string} DESIGNER_FORM - A string representing whether the designer form is enabled ("true" or "false").
   * @returns {void}
   */
  if (DESIGNER_FORM === "false") {
    disabledButtonCreateForm = true;
  } else {
    disabledButtonCreateForm = false;
  }

  /**
   * Determines the forms to show in the list based on user type and designer form statusForm.
   * If `USERTYPE` is "3" or `DESIGNER_FORM` is "true", `formstoShowInList` is set to `listForms`.
   * Otherwise, it is set to `permissionsForms`.
   * @param {string} USERTYPE - The type of the user, which determines the forms to show.
   * @param {string} DESIGNER_FORM - A string representing whether the designer form is enabled ("true" or "false").
   * @param {Array} listForms - The list of all forms.
   * @param {Array} permissionsForms - The list of forms based on user permissions.
   * @returns {void}
   */
  if (USERTYPE === "3" || DESIGNER_FORM === "true") {
    formstoShowInList = listForms;
  } else {
    formstoShowInList = permissionsForms;
  }

  /**
   * Displays a sweet alert to inform the user that no forms were found for the given statusForm.
   * @function showAlertFormDoesntExist - function for control of the message render in alert
   * @returns {void}
   * @param {string} statusForm - The statusForm for which no forms were found.
   * @throws {Error} If the statusForm parameter is not a string.
   */
  const showAlertFormDoesntExist = (statusForm) => {
    let message = "";
    if (!statusForm || statusForm === undefined || statusForm === "")
      message = "form.fieldlist.status.all";
    if (statusForm === enumsFormStatusDelete.FORMS_STATUS_ERASER) message = "form.fieldlist.status.eraser";
    if (statusForm === enumsFormStatusDelete.FORMS_STATUS_ACTIVE) message = "form.fieldlist.status.active";
    if (statusForm === enumsFormStatusDelete.FORMS_STATUS_INACTIVE) message = "form.fieldlist.status.inactive";

    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t(`${message}`),
      icon: "info",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Retrieves forms based on the searchFormList criteria and updates the component state with the results.
   * @param {fucntion} getForms - The function for get al the forms created
   * @throws {Error} If an error occurs while retrieving the forms.
   * @param {Object} pagination - The pagination options to use for retrieving the forms.
   * @param {number} pagination.page - The page number to retrieve.
   * @param {number} pagination.per_page - The number of forms to retrieve per page.
   * @param {string} searchFormList - The searchFormList term to use for filtering the forms.
   * @param {string} statusForm - The statusForm of the forms to retrieve.
   * @param {number} [delay=0] - The delay in milliseconds to apply before retrieving the forms.
   * @returns {void}
   */
  const getFormsBySearch = useCallback(() => {
    setIsLoadingFormsList(true);
    getFormsAvailablesPerUser(pagination.page, pagination.per_page, searchFormList, statusForm, enumsActionsTypes.ALL_ACTIONS)
      .then((response) => {
        const formstemp = response.data.items;
        if (isNullOrUndefined(formstemp) === false) {
          const newFormsPermissions = formstemp.filter((ele) => {
            return newForms.includes(ele.uuid);
          });
          setPermissionsForms(newFormsPermissions);
          if (response.data.items.length === 0) {
            showAlertFormDoesntExist(statusForm);
          }
          setListForms(response.data.items);
          setTotalPages(response.data.pages);
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        if (error.response.status === 503) {
          alert(i18n.t("showNotification502"));
        }
      })
      .finally(() => {
        setIsLoadingFormsList(false);
      });
  }, [pagination, searchFormList, statusForm]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Calls the `getFormsBySearch` function when the component mounts or when the `getFormsBySearch` function changes.
   * @param {Function} getFormsBySearch - The function to call to retrieve forms based on searchFormList criteria.
   * @returns {void}
   */
  useEffect(() => {
    getFormsBySearch();
  }, [getFormsBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchFormList}
              pagination={pagination}
              setPagination={setPagination}
            />

            <FilterFormState
              setStatus={setStatusForm}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonCreateForm
              disabled={disabledButtonCreateForm}
            />
          </CardFooter>

          <FormListTable
            listForms={formstoShowInList}
            pagination={pagination}
            isLoadingFormsList={isLoadingFormsList}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FormList;
