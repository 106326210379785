import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_PASSWORD_CREATE, URL_USERS } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useUser() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getUsers = async (page, per_page, search, status) => {
    if (
      status !== null &&
      status !== undefined &&
      status !== "" &&
      page !== null &&
      page !== undefined &&
      page !== "" &&
      per_page !== null &&
      per_page !== undefined &&
      per_page !== ""
    ) {
      const responseUsersList = await axios.get(
        `${URL_USERS}/users/?status=${status}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.user.getUsers
        }
      );
      return responseUsersList.data;
    }

    if (status !== null && status !== undefined && status !== "") {
      const responseUsersList = await axios.get(
        `${URL_USERS}/users/?status=${status}`,
        {
          headers,
          endpointError: endpointError.user.getUsers
        }
      );
      return responseUsersList.data;
    }

    const responseUsersList = await axios.get(
      `${URL_USERS}/users/?user_name=${search}&first_name=${search}&last_name=${search}&page=${page}&per_page=${per_page}`,
      {
         headers,
         endpointError: endpointError.user.responseUsersList
        }
    );
    return responseUsersList.data;
  };

  const getUsersById = async (username) => {
    const response = await axios.get(
      `${URL_USERS}/users/?user_name=${username}`,
      {
        headers,
        endpointError: endpointError.user.getUsersById
      }
    );
    return response.data;
  };

  const createUser = async (user, domainUser) => {
    user.user_name = `${user.user_name}@${domainUser}`;
    const response = await axios.post(`${URL_USERS}/users/`, user,
      {
        headers,
        endpointError: endpointError.user.createUser
      });
    return response;
  };

  const updateUser = async (user, id, domainUser, isModifiedUserName) => {
    if (isModifiedUserName === true) {
      user.user_name = `${user.user_name}@${domainUser}`;
      delete user.uuid;
    } else {
      delete user.user_name
    }
    const response = await axios.patch(`${URL_USERS}/users/${id}/`, user,
      {
        headers,
        endpointError: endpointError.user.updateUser
      }
    );
    return response;
  };

  const createPassword = async (new_password, paramsUser) => {
    const { userId, token } = paramsUser;
    const response = await axios.patch(
      `${URL_PASSWORD_CREATE}/users/${userId}/new-password/`,
      new_password,
      {
        headers: { Authorization: `Bearer ${token}` },
        endpointError: endpointError.user.createPassword
      }
    );
    return response;
  };

  const updateStatus = async (user_id, data) => {
    const response = await axios.patch(
      `${URL_USERS}/users/${user_id}/status/`,
      data,
      {
        headers,
        endpointError: endpointError.user.updateStatus
      }
    );
    return response;
  };

  const emailPasswordReset = async (email) => {
    const response = await axios.post(
      `${URL_USERS}/users/send-email/password-reset/`,
      email,
      {
        endpointError: endpointError.user.emailPasswordReset
      }
    );
    return response;
  };

  const forgottenPasswordReset = async (data, paramsUser) => {
    const { userId, token } = paramsUser;
    const response = await axios.post(
      `${URL_USERS}/users/${userId}/password-reset/`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` },
        endpointError: endpointError.user.forgottenPasswordReset
      }
    );
    return response;
  };

  const getUserLogsById = async (user_id, page, per_page) => {
    const response = await axios.get(
      `${URL_USERS}/users/${user_id}/logs/?page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.user.getUserLogsById
      }
    );
    return response.data;
  };

  const changePasswordByUser = async (user_id, data) => {
    const response = await axios.patch(
      `${URL_USERS}/users/${user_id}/password-change/`,
      data,
      {
        headers,
        endpointError: endpointError.user.changePasswordByUser
      }
    );
    return response;
  };

  const create_work_group = async (data) => {
    const response = await axios.post(`${URL_USERS}/users/work-groups/`, data,
      {
        headers,
        endpointError: endpointError.user.create_work_group
      });
    return response;
  };

  const ConsultationWorkingGroups = async (page, per_page, search) => {
    let response;
    if (search) {
      response = await axios.get(
        `${URL_USERS}/users/work-groups/?page=${page}&per_page=${per_page}&name=${search}`,
        {
          headers,
          endpointError: endpointError.user.ConsultationWorkingGroups
        }
      );
    } else {
      response = await axios.get(
        `${URL_USERS}/users/work-groups/?page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.user.ConsultationWorkingGroups
        }
      );
    }
    return response;
  };

  const getUserWorkGroupByRol = async (id) => {
    const response = await axios.get(
      `${URL_USERS}/users/work-groups/${id}/users/`,
      {
        headers,
        endpointError: endpointError.user.getUserWorkGroupByRol
      }
    );
    return response;
  };

  const addUsersToWorkGroup = async (id, data) => {
    const response = await axios.post(
      `${URL_USERS}/users/work-groups/${id}/members/`,
      data,
      {
        headers,
        endpointError: endpointError.user.addUsersToWorkGroup
      }
    );
    return response;
  };

  const consultAllGroupsWork = async () => {
    const responseAllGroups = await axios.get(
      `${URL_USERS}/users/work-groups/?page=${1}&per_page=${500}`,
      {
        headers,
        endpointError: endpointError.user.consultAllGroupsWork
      }
    );
    return responseAllGroups.data;
  };

  const updateWorkGroup = async (id, data) => {
    const response = await axios.patch(
      `${URL_USERS}/users/work-groups/${id}/`,
      data,
      {
        headers,
        endpointError: endpointError.user.updateWorkGroup
      }
    );
    return response;
  };

  const getMembersOfWorkGroup = async (id, page, per_page, search) => {
    let groupMembers;
    if (search && search !== null && search !== undefined) {
      groupMembers = await axios.get(
        `${URL_USERS}/users/work-groups/${id}/members/?page=${page}&per_page=${per_page}&name=${search}`,
        {
          headers,
          endpointError: endpointError.user.getMembersOfWorkGroup
        }
      );
    } else {
      groupMembers = await axios.get(
        `${URL_USERS}/users/work-groups/${id}/members/?page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.user.getMembersOfWorkGroup
        }
      );
    }
    return groupMembers;
  };

  const getWorkGroupById = async (id) => {
    const response = await axios.get(`${URL_USERS}/users/work-groups/${id}`,
      {
        headers,
        endpointError: endpointError.user.getWorkGroupById
      });
    return response;
  };

  const getWorkGroupsByStatus = async (per_page, status) => {
    const response = await axios.get(
      `${URL_USERS}/users/work-groups/?page=1&per_page=${per_page}&status=${status}`,
      {
        headers,
        endpointError: endpointError.user.getWorkGroupsByStatus
      }
    );
    return response;
  };

  const deleteMemberOfWorkGroup = async (workgroupUuid, userId) => {
    const responseDeleteMember = await axios.delete(
      `${URL_USERS}/users/work-groups/${workgroupUuid}/members/${userId}/`,
      {
        headers,
        endpointError: endpointError.user.deleteMemberOfWorkGroup
      }
    );
    return responseDeleteMember;
  };

  return {
    getUsers,
    createUser,
    updateUser,
    getUsersById,
    createPassword,
    updateStatus,
    emailPasswordReset,
    forgottenPasswordReset,
    getUserLogsById,
    changePasswordByUser,
    create_work_group,
    ConsultationWorkingGroups,
    getUserWorkGroupByRol,
    addUsersToWorkGroup,
    consultAllGroupsWork,
    updateWorkGroup,
    getMembersOfWorkGroup,
    getWorkGroupById,
    getWorkGroupsByStatus,
    deleteMemberOfWorkGroup,
  };
}
