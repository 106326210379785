import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const SignatoriesViewListTable = (props) => {
  const {
    signatories,
    pagination,
    setPagination,
    isLoadingSignatories,
    totalPages,
  } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={signatories}
            columns={[
              {
                columns: [
                  {
                    Header: i18n.t("trd.fromLabel2"),
                    accessor: "name",
                  },
                  {
                    Header: i18n.t("signature.country"),
                    accessor: "country_name",
                  },
                  {
                    Header: i18n.t("integration.requesEmail"),
                    accessor: "email",
                  },
                  {
                    Header: i18n.t("integration.requesPhone"),
                    accessor: "phone",
                  },
                  {
                    Header: i18n.t("signatories.ViewDateSigned"),
                    accessor: "signature_date",
                    Cell: ({ value }) => {
                      if (value === "0000-00-00T00:00:00+00:00") {
                        return null;
                      } else {
                        let valueFinal = getDateFormat2(new Date(value));
                        return <p>{valueFinal}</p>;
                      }
                    },
                  },
                  {
                    Header: i18n.t("trdExpedientList5"),
                    accessor: "status",
                    Cell: ({ value }) => {
                      let statusFinal = null;
                      let badgeClass = null;

                      switch (value) {
                        case 0:
                          statusFinal = i18n.t("info.SignatureFilter1");
                          badgeClass = "badge badge-warning";
                          break;
                        case 1:
                          statusFinal = i18n.t("info.SignatureFilter2");
                          badgeClass = "badge badge-success";
                          break;
                        case 2:
                          statusFinal = i18n.t("info.SignatureFilter3");
                          badgeClass = "badge badge-danger";
                          break;
                        default:
                          statusFinal = "";
                          break;
                      }

                      return (
                        <span className="text-center justify-content-center align-items-center">
                          {statusFinal && (
                            <span className={`ml-auto ${badgeClass}`}>
                              {statusFinal}
                            </span>
                          )}
                        </span>
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingSignatories}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SignatoriesViewListTable.propTypes = {
  signatories: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingSignatories: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default SignatoriesViewListTable;
