import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { enumsFilterWorkflowState } from "utils/enums";
import i18n from "locales/i18n";

const FilterWorkflowState = (props) => {
  const {
    setStatus,
    pagination,
    setPagination,
  } = props;

  /**
   * Handles the change event for updating the workflow state and resetting pagination. 
   * @param {React.ChangeEvent<HTMLInputElement>} eventWorkflowState - The change event object from the input element.
   * @returns {void}
   */
  const handleOnChange = (eventWorkflowState) => {
    setStatus(eventWorkflowState.target.value);
    setPagination({ ...pagination, page: 1 });
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="mr-2" color="cyan">
              {i18n.t("filteruser.button")}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-hover-primary">
              <DropdownItem value={enumsFilterWorkflowState.ALL_WORKFLOWS} onClick={handleOnChange}>
                {i18n.t("filteruser.item1")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.STATUS_ERASER} onClick={handleOnChange}>
                {i18n.t("filterFormState1")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.STATUS_ACTIVE} onClick={handleOnChange}>
                {i18n.t("filteruser.item2")}
              </DropdownItem>
              <DropdownItem value={enumsFilterWorkflowState.STATUS_INACTIVE} onClick={handleOnChange}>
                {i18n.t("filteruser.item3")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FilterWorkflowState;
