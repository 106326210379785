import React, { useEffect } from "react";

const RadioButtonBoxSticker = (props) => {
  const { selectedPosition, setSelectedPosition, onPositionChange } = props;

  /**
   * Handles a change in selected position.
   * @param {any} position - The new position to set as selected.
   * @returns {void}
   */
  const handlePositionChange = (position) => {
    setSelectedPosition(position);
    onPositionChange(position);
  };

  /**
   * Executes a callback function when the component mounts.
   * This effect triggers the `handlePositionChange` function with a predefined position.
   * @param {string} position - The position to pass to `handlePositionChange`.
   * Accepts values like "top-left", "top-right", etc.
   */
  useEffect(() => {
    handlePositionChange("top-left");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="radio-button-box">
      <div className="radio-button top-left">
        <input
          id="top-left"
          name="positionSticker"
          type="radio"
          value="top-left"
          checked={selectedPosition === "top-left"}
          onChange={() => handlePositionChange("top-left")}
        />
      </div>
      <div className="radio-button top-right">
        <input
          id="top-right"
          name="positionSticker"
          type="radio"
          value="top-right"
          checked={selectedPosition === "top-right"}
          onChange={() => handlePositionChange("top-right")}
        />
      </div>
      <div className="radio-button middle-left">
        <input
          id="middle-left"
          name="positionSticker"
          type="radio"
          value="middle-left"
          checked={selectedPosition === "middle-left"}
          onChange={() => handlePositionChange("middle-left")}
        />
      </div>
      <div className="radio-button middle-right">
        <input
          id="middle-right"
          name="positionSticker"
          type="radio"
          value="middle-right"
          checked={selectedPosition === "middle-right"}
          onChange={() => handlePositionChange("middle-right")}
        />
      </div>
      <div className="radio-button bottom-right">
        <input
          id="bottom-right"
          name="positionSticker"
          type="radio"
          value="bottom-right"
          checked={selectedPosition === "bottom-right"}
          onChange={() => handlePositionChange("bottom-right")}
        />
      </div>
      <div className="radio-button bottom-left">
        <input
          id="bottom-left"
          name="positionSticker"
          type="radio"
          value="bottom-left"
          checked={selectedPosition === "bottom-left"}
          onChange={() => handlePositionChange("bottom-left")}
        />
      </div>
    </div>
  );
};

export default RadioButtonBoxSticker;
