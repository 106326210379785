import React, { Fragment, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { loaderComponent } from "utils/loaderElement";
import { useListContext } from "contextAPI/ListsContext";
import DropZone from "components/molecules/DropZone";
import useWorkFlow from "hooks/useWorkFlow";
import i18n from "locales/i18n";
import swal from "sweetalert";

const TaskUploadFiles = (props) => {
  const { fileByTrd, isProcess, isProcessBatch } = props;
  const { id, processId } = useParams();
  const { setCanNext } = useListContext();
  const {
    processUuid,
    taskProcessId,
    isLoadingComplyProcessBatch,
    processTaskBatch,
    uploadFormWorkflow,
  } = useWorkflowContext();
  const {
    files,
    setUploadForm,
    isLoading,
    setFormUuid,
    setRecordUuid,
    formUuid,
    recordUuid,
    disabledUploadFile,
    setDisabledUploadFile,
    handleOnSubmitProcess,
    handleOnSubmitTask,
  } = useRecordDetailContext();
  const { getTasksByUuid, getProcessByUuid } = useWorkFlow();
  const { parentData, isLoadingExpedietnPath } = useTrdContext();
  let idTask;
  let processIdTask;
  let processTaskButton = null;
  let taskUuid = null;
  let handleOnSubmitDrop;
  let isLoadingComponent = null;

  /**
   * Conditionally renders a spinner component based on the isLoading flag.
   * @param {boolean} isLoading - Flag indicating whether the component is in a loading state.
   * @returns {JSX.Element|null} The spinner component if isLoading is true; otherwise, returns null.
   */
  if (isLoading === true) {
    isLoadingComponent = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Handles the submission based on the provided conditions.
   * This function conditionally calls different submission handlers based on the value of 'isProcess':
   * If 'isProcess' is true, it calls 'handleOnSubmitProcess' with the provided parameters.
   * If 'isProcess' is false, it calls 'handleOnSubmitTask' with the provided parameters.
   * If 'isProcess' is undefined, it defaults to calling 'handleOnSubmit'.
   * @param {string} taskUuid - The unique identifier of the task.
   * @param {string} recordUuid - The UUID of the associated record.
   * @param {string} formUuid - The UUID of the form being submitted.
   * @param {File} fileByTrd - The file associated with the submission.
   * @returns {void}
   */
  if (isProcess === true) {
    handleOnSubmitDrop = () =>
      handleOnSubmitProcess(taskUuid, recordUuid, formUuid, fileByTrd);
  } else if (isProcess === false) {
    handleOnSubmitDrop = () =>
      handleOnSubmitTask(taskUuid, recordUuid, formUuid, fileByTrd);
  }

  /**
   * Sets the values of idTask and processIdTask based on the condition.
   * @param {boolean} processTaskBatch - Flag indicating if the process is in batch mode.
   * @param {string} id - The task ID when not in batch mode.
   * @param {string} processId - The process ID when not in batch mode.
   * @param {string} taskProcessId - The task ID when in batch mode.
   * @param {string} processUuid - The process UUID when in batch mode.
   * @returns {Object} An object containing idTask and processIdTask values.
   */
  if (processTaskBatch === true) {
    idTask = taskProcessId;
    processIdTask = processUuid;
  } else {
    idTask = id;
    processIdTask = processId;
  }

  /**
   * Assigns the appropriate task UUID based on the process status.
   * @param {boolean} isProcess - Indicates whether the process is true or not.
   * @param {string} processIdTask - The task UUID when the process is true.
   * @param {string} idTask - The task UUID when the process is false.
   * @returns {string} The assigned task UUID.
   */
  if (isProcess === true) {
    taskUuid = processIdTask;
  } else {
    taskUuid = idTask;
  }

  /**
   * Generates the process task button if processTaskBatch is false.
   * @param {boolean} processTaskBatch - Indicates whether the process task batch is false.
   * @param {Object} i18n - The i18n object used for translations.
   * @returns {JSX.Element|null} The process task button component or null if processTaskBatch is true.
   */
  if (processTaskBatch === false) {
    processTaskButton = (
      <Button
        type="submit"
        disabled={disabledUploadFile === true || isLoading === true}
        color="cyan"
      >
        {isLoadingComponent}
        {i18n.t("recordDetail.load")}
      </Button>
    );
  }

  /**
   * Displays a SweetAlert modal with a warning message.
   * @returns {void}
   */
  const showSweetAlert = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("modal.warning.body3"),
      icon: "warning",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Fetches tasks by their UUID.
   * @param {boolean} isProcess - Indicates whether the process is true or not.
   * @param {string} idTask - The UUID of the task.
   * @param {Function} getTasksByUuid - Function to get tasks by UUID.
   * @param {Function} setFormUuid - Function to set the form UUID state.
   * @param {Function} setRecordUuid - Function to set the record UUID state.
   */
  const getTasksById = useCallback(() => {
    if (isProcess === false && isProcessBatch !== true) {
      getTasksByUuid(idTask).then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setFormUuid(response.data.data.form_uuid);
          setRecordUuid(response.data.data.record_uuid);
        } else {
          showAlertServiceError();
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches process data by UUID and updates component state with form_uuid and record_uuid.
   * @function
   * @param {boolean} isProcess - Flag indicating if a process is ongoing.
   * @param {string} idTask - The task ID to retrieve process data.
   * @param {Function} getProcessByUuid - Function to retrieve process data by UUID.
   * @param {Function} setFormUuid - Function to update state with the retrieved form_uuid.
   * @param {Function} setRecordUuid - Function to update state with the retrieved record_uuid.
   * @returns {void}
   */
  const getProcessById = useCallback(() => {
    if (isProcess === true) {
      getProcessByUuid(idTask).then((response) => {
        if (isNullOrUndefined(response.data.data) === false) {
          const processInfo = response.data.data;
          setFormUuid(processInfo.form_uuid);
          setRecordUuid(processInfo.record_uuid);
        } else {
          showAlertServiceError();
        }
      });
    }
  }, [isProcess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the form submission event.
   * @param {Event} eventRecordUpload - The form submission event.
   * @param {Array} errors - An array of form validation errors.
   * @returns {void}
   */
  const handleOnSubmitDropzone = (_, errors) => {
    if (fileByTrd) {
      if (errors.length === 0 && parentData.id && files.length > 0) {
        handleOnSubmitDrop();
      } else if (!parentData.id) {
        showSweetAlert();
      }
    } else {
      if (errors.length === 0 && files.length > 0) {
        handleOnSubmitDrop();
      }
    }
  };

  /**
   * useEffect hook that enables or disables file upload based on conditions.
   * @param {Array<File>} files - The array of files to be uploaded.
   * @param {object} uploadForm - An object containing upload form data.
   * @param {string} uploadForm.subject - The subject for the upload.
   * @param {string} uploadForm.support_type - The support type for the upload.
   * @param {Date} uploadForm.publication_at - The publication date for the upload.
   * @param {function} setDisabledUploadFile - State setter function to enable/disable file upload.
   * @returns {void}
   */
  useEffect(() => {
    if (files.length > 0) {
      setDisabledUploadFile(false);
    } else {
      setDisabledUploadFile(true);
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook to invoke the getTasksById function on component mount.
   * @param {Function} getTasksById - The function to retrieve tasks data by ID.
   * @returns {void}
   */
  useEffect(() => {
    getTasksById();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook to invoke the getProcessById function on component mount.
   * @param {Function} getProcessById - The function to retrieve process data by ID.
   * @returns {void}
   */
  useEffect(() => {
    getProcessById();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUploadForm(formUuid);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the 'canNext' state based on the presence of a label in the 'fieldForm' object.
   * @param {object} fieldForm - The object containing the form field data.
   * @param {boolean} canNext - The state indicating whether the form can proceed to the next step.
   * @param {function} setCanNext - State setter function for updating the 'canNext' state.
   * @returns {void}
   */
  useEffect(() => {
    if (uploadFormWorkflow.files.length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [uploadFormWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingComplyProcessBatch === true || isLoadingExpedietnPath === true) {
    return loaderComponent(true);
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
          className="scrollingContainer"
        >
          <AvForm onSubmit={handleOnSubmitDropzone}>
            <Row>
              <Col>
                <DropZone
                  isProcessBatch={isProcessBatch}
                  fileByTrd={fileByTrd}
                  recordUuid={recordUuid}
                  taskId={taskUuid}
                  formUuid={formUuid}
                />
              </Col>
            </Row>
            <Row className="d-block text-right">
              <Col>{processTaskButton}</Col>
            </Row>
          </AvForm>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

TaskUploadFiles.propTypes = {
  fileByTrd: PropTypes.bool,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
};

export default TaskUploadFiles;
