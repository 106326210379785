import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import swal from "sweetalert";
import {
	Button,
	CardTitle,
	Label,
	CardFooter,
	Container,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { enumLevelTrd, enumsButtonCreateFund } from "utils/enums";
import { regexEditBackground } from "utils/regexExpressions";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import { trimExtraSpaces } from "utils/trimSpaces";

const EditBackground = () => {
	const {
		trdForm,
		setTrdForm,
		setShowFolderCondition,
		structureTrd,
		getTreeByDomainCallback,
		setIsLoading,
		isLoading,
		editBackground,
		setEditBackground,
		setLevelFolder,
		setSelectLevel
	} = useTrdContext();

	const { updateBackground } = useTrd()

	/**
	 * Finds the label of a TRD element in `structureTrd` where the `level` is 0 and the `id` matches `trdForm.id`.
	 * Returns the label of the first matching element or `undefined` if no match is found.
	|* @type {string|undefined}
	 * @param {string} label - The label of the TRD element.
	 */
	const nameBackground = structureTrd.find((trdElement) => {
		if (trdElement.level === enumLevelTrd.BACKGROUND && trdElement.id === trdForm.id) return trdElement.label;
		return null;
	});

	/**
	 * Finds a background element in `structureTrd` where the `level` is 0 and the `id` matches `trdForm.id`.
	 * Returns the first matching element or `undefined` if no match is found.
	 * @type {Object|undefined}
	 * @param {number} id - The unique identifier of the TRD element.
	 * @param {number} level - The level of the TRD element.
	 */
	const background = structureTrd.find((trdElement) => {
		if (trdElement.level === enumLevelTrd.BACKGROUND && trdElement.id === trdForm.id) return trdElement.id;
		return null
	});

	/**
	 * Resets the background-related information in the state.
	 * Updates the `trdForm` state to clear the `name` and set `level` to 0.
	 * Also resets the folder level and selection to 0.
	 */
	const resetBackgroundInfo = () => {
		setTrdForm({
			...trdForm,
			name: "",
			level: enumLevelTrd.BACKGROUND
		});
		setLevelFolder(enumLevelTrd.BACKGROUND);
		setSelectLevel(enumLevelTrd.BACKGROUND);
	};

	/**
	 * Closes the form for editing the background by resetting the form state
	 * and setting the level and folder visibility. 
	 * Updates the `trdForm` state to set the level to `BACKGROUND`, shows the folder condition,
	 * and resets the background-related information.
	 */
	const closeFormEditBackground = () => {
		setTrdForm({
			...trdForm,
			level: enumLevelTrd.BACKGROUND,
		});
		setShowFolderCondition(true);
		setTrdForm(resetBackgroundInfo);
	};

	/**
	 * Handles the blur event for updating the editBackground state after trimming extra spaces from the input value.
	 * @param {Object} eventOnBlur - The blur event object.
	 * @param {Object} eventOnBlur.target - The target element of the event.
	 * @param {string} eventOnBlur.target.value - The value of the target element.
	 * @param {string} eventOnBlur.target.name - The name of the target element.
	 */
	const handleOnBlur = (eventOnBlur) => {
		const { value, name } = eventOnBlur.target;
		const trimmedValue = trimExtraSpaces(value);
		setEditBackground({
			...editBackground,
			[name]: trimmedValue,
		});
	};

	/**
	 * Handles the key press event to prevent the default action when the "Enter" key is pressed.
	 *
	 * @param {KeyboardEvent} event - The key press event object.
	 * @param {string} event.key - The key value of the pressed key.
	 */
	const handleOnKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	/**
	 * Displays a success alert using SweetAlert when the background update is successful.
	 * The alert includes a title, text, icon, and button text, all retrieved from the i18n object.
	 */
	const alertUpdateBackground = () => {
		swal({
			title: i18n.t("modal.DoneError.header"),
			text: i18n.t("trd.update.background.alert.success"),
			icon: "success",
			button: i18n.t("modal.Done.footerButton"),
		})
	};

	/**
	 * Handles the form submit event to update the background data. 
	 * Prevents the default form submission, checks for validation errors,
	 * and performs the update operation. Updates the form state and shows 
	 * relevant alerts upon completion.
	 *
	 * @param {Event} event - The submit event object.
	 * @param {Array} errors - An array of validation errors.
	 */
	const handleOnSubmit = (event, errors) => {
		event.preventDefault();
		if (errors.length === 0) {
			const data = {
				name: editBackground.name.trim()
			}
			setIsLoading(true)
			updateBackground(data, background.id)
				.then(() => {
					setTrdForm({
						...trdForm,
						name: "",
						level: enumLevelTrd.BACKGROUND,
					});
					setLevelFolder(enumLevelTrd.BACKGROUND);
					setSelectLevel(enumLevelTrd.BACKGROUND);
					setShowFolderCondition(true);
					alertUpdateBackground();
				})
				.finally(() => setIsLoading(false))
		}
	};

	/**
	 * useEffect hook to initialize the `editBackground` state with the name from `nameBackground`.
	 * The name is cleaned by removing the substring "(Fondo)" and trimming any surrounding whitespace.
	 * This effect runs once when the component mounts.
	 *
	 * @param {Object} nameBackground - The object containing the label to be used for initializing state.
	 * @param {string} nameBackground.label - The label value from which "(Fondo)" is removed and trimmed.
	 */
	useEffect(() => {
		setEditBackground({
			...editBackground,
			name: nameBackground.label.replace("(Fondo)", "").trim(),
		})
		// eslint-disable-next-line
	}, [])

	/**
	 * useEffect hook to fetch the tree by domain when the component mounts or when `getTreeByDomainCallback` changes.
	 * @param {Function} getTreeByDomainCallback - The callback function to fetch the tree by domain.
	 */
	useEffect(() => {
		getTreeByDomainCallback();
	}, [getTreeByDomainCallback]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<Container fluid>
					<CardTitle>{i18n.t("trd.update.background")}</CardTitle>
					<AvForm onSubmit={handleOnSubmit}>
						<AvGroup>
							<ReactTooltip
								id="name_element"
								place="bottom"
								type="dark"
								effect="solid"
							>
								<p className="custom-tooltip-message">{i18n.t("trd.Tooltip9")}</p>
								<p className="custom-tooltip-message">{i18n.t("trd.Tooltip11")}</p>
							</ReactTooltip>
							<Label for="name" className="is-required">
								{i18n.t("trd.update.background.subtitle")}
							</Label>
							<AvField
								id="name"
								name="name"
								type="text"
								onChange={(event) => setEditBackground(event.target.value)}
								onKeyPress={handleOnKeyPress}
								onBlur={handleOnBlur}
								validate={{
									pattern: {
										value: regexEditBackground,
										errorMessage: `${i18n.t("invalidField2")}`,
									},
									required: {
										value: true,
										errorMessage: `${i18n.t("trd.update.background.alert.error")}`,
									},
									minLength: {
										value: enumsButtonCreateFund.MIN_LENGT,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
									},

									maxLength: {
										value: enumsButtonCreateFund.MAX_LENGTH,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
									},
								}}
								data-tip
								data-for="name_element"
								autoComplete="off"
								required
								value={editBackground.name}
							/>
						</AvGroup>

						<CardFooter className="d-block text-right">
							<Button
								size="lg"
								className="col-mt-3 mr-3"
								color="gray"
								onClick={closeFormEditBackground}
							>
								{i18n.t("createusers.createButton2")}
							</Button>
							<Button
								type="submit"
								size="lg"
								className="col-mt-3"
								disabled={isLoading}
								color="cyan"
							>
								{i18n.t("trd.buttonUptade")}
							</Button>
						</CardFooter>
					</AvForm>
				</Container>
			</CSSTransitionGroup>
		</Fragment>
	);
};

export default EditBackground;
