import React, { useState, Fragment, useCallback, useEffect } from 'react';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
	Button,
	Col,
	CardFooter,
	Label,
	Card,
	CardBody,
	CardTitle,
	Spinner,
	Alert
} from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { loaderElement } from 'utils/loaderElement';
import useUser from "hooks/useUser";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ChangeUserStatu = () => {
	const { id } = useParams();
	const userId = id;
	const [showAlertComponent, seShowAlertComponent] = useState(false);
	const { getUsersById, updateStatus } = useUser();
	const [isUserChanged, setIsUserChanged] = useState(false);
	const [activeState, setActiveState] = useState({
		status: "",
	});
	const [isLoadingChangeStatus, setIsLoadingChangeStatus] = useState(false);
	const [groupsResponsibleUser, setGroupsResponsibleUser] = useState(false);
	const [activeButton, setActiveButton] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [workflows, setWorkflows] = useState([]);
	const [uuid, setUuid] = useState("");
	const [hasTask, setHasTask] = useState(null);

	/**
	 * Determines the state of the submit button based on various conditions.
	 * @type {boolean} disableSubmitButton - The state of the submit button. True if the button should be disabled, false otherwise.
	 */

	let disableSubmitButton;
	if (isUserChanged === true || disabled === true || activeButton === true) {
		disableSubmitButton = true;
	} else {
		disableSubmitButton = false;
	}

	/**
	 * Handles the change event for the user status.
	 * @param {Event} eventStatus - The change event object for the user status.
	 * @return {Void}
	 */

	const handleChangeStatusUser = (eventStatus) => {
		setActiveState({
			...activeState,
			status: Number(eventStatus.target.value)
		})
		setDisabled(false)
	}

	/**
	 * Fetches user data by ID and updates relevant state variables.
	 * @param {string} userId - The ID of the user.
	 * @return {Void}
	 */

	const getUsersByIdFunc = useCallback(() => {
		setIsLoadingChangeStatus(true);
		getUsersById(userId)
			.then((response) => {
				if (response.data.items) {
					const user = response.data.items.find(
						(user) => user.user_name === userId
					);
					setUuid(user.uuid)
					setActiveState({
						status: user.status
					})
				}
			})
			.finally(() => {
				setIsLoadingChangeStatus(false);
			});
	}, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Handles the form submission for updating the user's status.
	 * @param {Event} eventSubmit - The submit event object.
	 * @return {Void}
	 */

	const handleOnSubmitUpdateUser = (eventSubmit) => {
		eventSubmit.preventDefault()
		updateStatus(uuid, activeState)
			.then(() => {
				setIsUserChanged(true)
				setDisabled(true)
				swal({
					title: i18n.t("modal.DoneError.header"),
					text: i18n.t("editStatus.success"),
					icon: "success",
					timer: 3000,
					button: i18n.t("modal.Done.footerButton"),
				}).then(() => {
					window.location.href = "/user/list"
				})

			})
			.catch((err) => {
				setIsUserChanged(false)
				if (err.response.data.code === "10040" || err.response.data.code === "10057") {
					if (err.response.data.data.workflows !== undefined && err.response.data.data.workflows !== null) {
						setWorkflows(err.response.data.data.workflows)
					}
					if (err.response.data.data.work_groups !== undefined && err.response.data.data.workflows !== null) {
						setGroupsResponsibleUser(err.response.data.data.work_groups)

					}
					if (err.response.data.data.has_tasks === "True") {
						setHasTask(true)
					}
					setActiveButton(true)
					seShowAlertComponent(true)
				}
			})
			.finally(() => setIsUserChanged(false))
	}

	/**
	 * Executes the getUsersByIdFunc function when the userId dependency changes.
	 * If userId is truthy and not undefined, it invokes the getUsersByIdFunc function.
	 * @param {string} userId - The ID of the user.
	 * @return {Void}
	 */

	useEffect(() => {
		if (userId && userId !== undefined) {
			getUsersByIdFunc();
		}
	}, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Fragment>
			{loaderElement(isLoadingChangeStatus)}
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<Card className="main-card mb-3">
					<CardBody>
						<CardTitle>{i18n.t("editStatus.change")}</CardTitle>
						<AvForm onSubmit={handleOnSubmitUpdateUser}>
							<AvGroup row>
								<Label for="document_type" className="is-required" sm={2}>
									{i18n.t("editStatus.subtitle")}
								</Label>
								<Col md={5}>
									<AvField
										type="select"
										name="document_type"
										errorMessage={i18n.t("createusers.Feedback6")}
										onChange={handleChangeStatusUser}
										value={activeState.status}
									>
										<option value={1} >
											{i18n.t("filteruser.item2")}
										</option >

										{(() => {
											if (activeState.status === 2) {
												return (
													<option value={2} disabled={true} >
														{i18n.t("filteruser.item4")}
													</option>
												)
											}
										})()}

										{(() => {
											if (activeState.status === 5) {
												return (
													<option value={5} disabled={true} >
														{i18n.t("filteruser.item7")}
													</option>
												)
											}
										})()}

										<option value={3} >
											{i18n.t("filteruser.item3")}
										</option>
										<option value={4} >
											{i18n.t("filteruser.item5")}
										</option>

									</AvField>
								</Col>
							</AvGroup>

							{(() => {
								if (showAlertComponent === true) {
									return (
										<Alert className="mbg-3" color="info">
											<span className="pr-2 text-bold">
												<FontAwesomeIcon
													icon={faExclamationTriangle}
													className="mr-2 "
												/>
												{i18n.t("modal.DoneError.header")}
												<div className="divider " />
												{(() => {
													if (groupsResponsibleUser.length > 0 && groupsResponsibleUser !== undefined) {
														return (
															<span className="font-weight-bold font-italic">{i18n.t("createUser.label2")}{":"}{" "}</span>
														)
													}
												})()}
												<br />
												<br />
												{(() => {
													if (groupsResponsibleUser.length > 0 && groupsResponsibleUser !== undefined) {
														return (
															<ul>
																{groupsResponsibleUser.map((workGroup, index) => {
																	return (
																		<li key={index}>{workGroup}</li>
																	)
																})}
															</ul>
														)
													}
												})()}
												{(() => {
													if (workflows.length > 0 && workflows !== undefined) {
														return (
															<span className="font-weight-bold font-italic">{i18n.t("createUser.label1")}{":"}{" "}</span>
														)
													}
												})()}
												<br />
												<br />
												{(() => {
													if (workflows.length > 0 && workflows !== undefined) {
														return (
															<ul>
																{workflows.map(({ step, workflow }, index) => {
																	return (
																		<li key={index}>{i18n.t("editStatus.user")} {step} - '{workflow}'</li>
																	)
																})}
															</ul>
														)
													}
												})()}
												{(() => {
													if (hasTask === true) {
														return (
															<p className="font-weight-bold font-italic">{i18n.t("editStatus.userLegend")}</p>
														)
													}
												})()}
											</span>
										</Alert>
									)
								}
							})()}

							<CardFooter className="d-block text-right">
								<Button
									onClick={() => window.location.href = "/user/list"}
									size="lg" className="col-mt-3 mr-3" color="gray">
									{i18n.t("createusers.createButton2")}
								</Button>
								<Button
									type="submit"
									size="lg"
									disabled={disableSubmitButton}
									className="col-mt-3"
									color="cyan"
								>
									{(() => {
										if (isUserChanged === true) {
											return (<Spinner size="sm" color="secondary" type="grow" />)
										}
									})()}
									{i18n.t("createWorflow.EditButton")}
								</Button>
							</CardFooter>
						</AvForm>
					</CardBody>
				</Card>
			</CSSTransitionGroup>
		</Fragment>
	)
}

export default ChangeUserStatu