import React from "react";
import ReactDOM from "react-dom";
import { UserContextProvider } from "./contextAPI/UserContext";
import { ListContextProvider } from "contextAPI/ListsContext";
import { TrdContextProvider } from "contextAPI/TrdContext";
import { FieldsRecordContextProvider } from "contextAPI/FieldsRecordContext";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
import { WorkflowContextProvider } from "contextAPI/WorkflowContext";
import "./assets/base.scss";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./config/configureStore";
import App from "./App";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider hideIconVariant maxSnack={4}>
      <Provider store={store}>
        <BrowserRouter>
          <UserContextProvider>
            <WorkflowContextProvider>
              <TrdContextProvider>
                <RecordDetailContextProvider>
                  <ListContextProvider>
                    <FieldsRecordContextProvider>
                      <App />
                    </FieldsRecordContextProvider>
                  </ListContextProvider>
                </RecordDetailContextProvider>
              </TrdContextProvider>
            </WorkflowContextProvider>
          </UserContextProvider>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
