import React from "react";
import EditRecord from "./EditRecord";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <EditRecord />
    </RecordDetailContextProvider>
  );
};
