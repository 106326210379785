import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Collapse,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCog } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { getDateFormat3 } from "utils/getDateFormat3";
import { useTrdContext } from "contextAPI/TrdContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { enumsTaskComeFrom, enumsTaskType } from "utils/enums";
import { loaderComponent } from "utils/loaderElement";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import TaskComments from "components/organism/TaskComments";
import TaskRecordDetailView from "components/organism/TasksDetails/TaskRecordDetailView";
import TasksFilesListTable from "components/organism/TasksFilesListTable";
import ButtonAtachFilesTask from "components/atoms/ButtonAtachFilesTask";
import ReassignTask from "components/molecules/ReassignTask";
import useForm from "hooks/useForm";
import Loader from "react-loaders";
import i18n from "locales/i18n";

const TasksDetails = () => {
  const { id } = useParams();
  const [isLoadingSteps, setIsLoadingSteps] = useState(false);
  const {
    setBackgroundByPath,
    setPathConfiguration,
    getBackGroundByRoute,
    setPathConfig,
  } = useRecordDetailContext();
  const {
    getFilesTasksById,
    acordeon,
    status,
    isLoading,
    handleOnClickAcordeon,
    taskDetail,
    type,
    priority,
    showDetail,
    setShowDetail,
    getTasksById,
    isProcessTask,
    handleOnSubmitComplytask,
    formIdInfo,
    isLoadingReassingTask,
  } = useWorkflowContext();
  const {
    getHasExpedientStructure,
    getTreeExpedientByDomainCallback,
    hasExpedientFixedPath,
    sethasExpedientFixedPath,
    expedientFixedUuid,
    isLoadingExpedietnPath,
    hasOrdination,
    expedientStatus,
    structureTrdExpedient,
    setExpedientSetPath,
    setExpedientParentByPath,
    foldersIdByPath,
    setFoldersIdByPath,
  } = useTrdContext();
  const { getFormsById } = useForm();
  let disableTask;

  /**
   * Fetches form data by IDs and performs state updates based on the response.
   * @param {string} formIdInfo - The identifier or information required to retrieve form data.
   * @returns {void}
   */
  const getFormsByIds = useCallback(() => {
    setIsLoadingSteps(true);
    getFormsById(formIdInfo, 0)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const path = response.data.path;
          const parentId = response.data.serie_subserie_uuid;
          const backgroundId = getBackGroundByRoute(path);
          if (parentId === null) {
            setExpedientSetPath({});
            sethasExpedientFixedPath(false);
          }
          setPathConfiguration(parentId);
          setPathConfig(path);
          setFoldersIdByPath({
            ...foldersIdByPath,
            backgroundId: backgroundId,
          });
          setExpedientParentByPath(parentId);
          setBackgroundByPath(backgroundId);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setIsLoadingSteps(false));
  }, [formIdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Determines whether the task should be disabled based on the status of the task.
   * If the status of the task is 2, the task is considered disabled and the function returns true;
   * otherwise, it returns false, indicating that the task is not disabled.
   * @param {Object} taskDetail - The object containing details of the task.
   * @param {number} taskDetail.status - The status code of the task. Assumes 2 represents a specific status.
   * @returns {boolean} disableTask - A boolean indicating whether the task should be disabled.
   */
  if (taskDetail.status === 2) {
    disableTask = true;
  } else {
    disableTask = false;
  }

  useEffect(() => {
    getFilesTasksById(id);
  }, [getFilesTasksById]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React effect that fetches forms data by form ID and updates various state values based on the response.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(formIdInfo) === false) {
      getFormsByIds();
    }
  }, [formIdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes an effect to check if the task detail record UUID is defined and not null,
   * then fetches information related to the expedient structure if available.
   * @param {object} taskDetail - The task detail object containing the record UUID.
   * @param {function} getHasExpedientStructure - Function to fetch information about the expedient structure.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(taskDetail.record_uuid) === false) {
      const recordId = taskDetail.record_uuid;
      getHasExpedientStructure(recordId);
    }
  }, [taskDetail.record_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a callback to fetch tree expedient data when specific conditions are met.
   * @param {boolean} hasExpedientFixedPath - Indicates whether there's a fixed path available for the expedient.
   * @param {string} expedientFixedUuid - The UUID of the fixed expedient to fetch the tree data for.
   * @param {function} getTreeExpedientByDomainCallback - Callback function used to fetch tree expedient data.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(expedientFixedUuid) === false &&
      hasExpedientFixedPath === true
    ) {
      getTreeExpedientByDomainCallback(null, expedientFixedUuid);
    }
  }, [hasExpedientFixedPath, expedientFixedUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that fetches tasks by their ID and triggers an update when the ID changes.
   * @param {string} id - The unique identifier of the tasks to be retrieved.
   *   This parameter triggers a re-fetch when its value changes.
   * @returns {void}
   */
  useEffect(() => {
    getTasksById(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderComponent(isLoadingReassingTask)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Col md="12">
          <Card className="main-card mb-3">
            {(() => {
              if (
                isLoading === true ||
                isLoadingExpedietnPath === true ||
                isLoadingSteps === true
              ) {
                return (
                  <div className="text-center mx-auto my-auto">
                    <div className="loader-wrapper d-flex justify-content-center align-items-center">
                      <Loader color="#0072BC" type="ball-pulse-rise" />
                    </div>
                  </div>
                );
              } else {
                return (
                  <CardBody className="mb-4">
                    <Row>
                      <Col lg="7" md="4" sm="12">
                        {(() => {
                          if (isLoading === true) {
                            return (
                              <div>
                                <Loader
                                  color="#0072BC"
                                  type="ball-pulse"
                                  size="small"
                                />
                              </div>
                            );
                          } else {
                            return (
                              <h4 className="font-weight-bold text-primary">
                                {`${i18n.t("taskDetail1")} : ${
                                  taskDetail.consecutive
                                }`}
                              </h4>
                            );
                          }
                        })()}
                      </Col>

                      <Col lg="5" md="8" sm="12">
                        <Row>
                          <Col lg="4" md="4" sm="12">
                            <ReassignTask
                              className="mr-2"
                              typeTask={enumsTaskComeFrom.FREE_TASK}
                              disabled={disableTask}
                            />
                          </Col>
                          <Col lg="4" md="4" sm="12">
                            <Button
                              className="ml-auto btn-icon mr-2 btn-block btn-mb-responsive"
                              color="green"
                              type="submit"
                              onClick={handleOnSubmitComplytask}
                              disabled={disableTask}
                            >
                              <FontAwesomeIcon icon={faCog} className="mr-2" />
                              {i18n.t("taskButton1")}
                              {(() => {
                                if (isLoading === true) {
                                  return (
                                    <Spinner
                                      size="sm"
                                      color="secondary"
                                      type="grow"
                                    />
                                  );
                                }
                              })()}{" "}
                            </Button>
                          </Col>
                          <Col lg="4" md="4" sm="12">
                            <ButtonAtachFilesTask
                              getFilesTasksById={getFilesTasksById}
                              taskId={id}
                              formIdInfo={formIdInfo}
                              hasExpedientFixedPath={hasExpedientFixedPath}
                              expedientFixedUuid={expedientFixedUuid}
                              hasOrdination={hasOrdination}
                              expedientStatus={expedientStatus}
                              structureTrdExpedient={structureTrdExpedient}
                              disabled={disableTask}
                              isFreeTask={enumsTaskType.FREE_TASK}
                              isProcess={false}
                              isCompletedTask={false}
                              isLoadingSteps={isLoadingSteps}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Col md="7" style={{ paddingLeft: 18 }}>
                        <Row style={{ paddingTop: 5 }}>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("filteruser.button")} : &nbsp;
                          </h6>

                          {(() => {
                            if (taskDetail.status === 1) {
                              return (
                                <p className="badge badge-info">{status}</p>
                              );
                            }
                          })()}

                          {(() => {
                            if (taskDetail.status === 2) {
                              return (
                                <p className="badge badge-success">{status}</p>
                              );
                            }
                          })()}

                          {(() => {
                            if (taskDetail.status === 3) {
                              return (
                                <p className="badge badge-danger">{status}</p>
                              );
                            }
                          })()}
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("taskBoxTable5")} : &nbsp;
                          </h6>
                          <p>{taskDetail.subject}</p>
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("taskBoxTable9")} : &nbsp;
                          </h6>
                          <p>{type}</p>
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("taskBoxTable1")} : &nbsp;
                          </h6>
                          <p className="text-capitalize">
                            {taskDetail.sender_fullname}
                          </p>
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("notifications.priority")} : &nbsp;
                          </h6>
                          <p className="font-weight-bold">{priority}</p>
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("taskDetailSend")} : &nbsp;
                          </h6>
                          <p>
                            {getDateFormat3(new Date(taskDetail.created_at))}
                          </p>
                        </Row>

                        <Row>
                          <h6 className="font-weight-bold text-cyan">
                            {i18n.t("taskBoxTable7")} : &nbsp;
                          </h6>
                          <p className="text-danger">
                            {getDateFormat3(
                              new Date(taskDetail.expiration_date)
                            )}
                          </p>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Button
                              color="info"
                              className="ml-auto btn-icon btn-block"
                              onClick={() =>
                                handleOnClickAcordeon(setShowDetail(true))
                              }
                              aria-expanded={handleOnClickAcordeon}
                              aria-controls="collapseOne"
                              size="lg"
                            >
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="mr-2 ml-auto "
                              />
                              {(() => {
                                if (isLoading === true) {
                                  return (
                                    <Spinner
                                      size="sm"
                                      color="secondary"
                                      type="grow"
                                    />
                                  );
                                }
                              })()}{" "}
                              {i18n.t("taskDetailRecord")} :{" "}
                              {taskDetail.record_number}
                            </Button>
                            <Collapse
                              className="pl-3 pt-0"
                              isOpen={acordeon ? true : false}
                              data-parent="#accordion"
                              id="collapseOne"
                              aria-labelledby="headingOne"
                            >
                              {(() => {
                                if (showDetail === true) {
                                  return (
                                    <TaskRecordDetailView
                                      recordUuid={taskDetail.record_uuid}
                                      formUuid={taskDetail.form_uuid}
                                    />
                                  );
                                }
                              })()}
                            </Collapse>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        md="5"
                        className="task-detail-card-second-responsive"
                      >
                        <Row>
                          <Col lg="12" md="12">
                            <TasksFilesListTable id={id} />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12" md="12" className="mt-3">
                            <TaskComments isProcess={isProcessTask} id={id} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                );
              }
            })()}
          </Card>
        </Col>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default TasksDetails;
