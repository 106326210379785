export const endpointError = {
  forms: {
    getForms: "T_FORMS_01",
    getFormsAvailablesPerUser: "T_FORMS_02",
    createForm: "T_FORMS_03",
    updateForm: "T_FORMS_04",
    createFields: "T_FORMS_05",
    getFields: "T_FORMS_06",
    getFormsById: "T_FORMS_07",
    deleteFields: "T_FORMS_08",
    getField: "T_FORMS_09",
    updateOrder: "T_FORMS_10",
    updateField: "T_FORMS_11",
    deleteForm: "T_FORMS_12",
    annulmentRecord: "T_FORMS_13",
    getFieldsStepWorkflowConfig: "T_FORMS_14",
    updateFieldsStepWorkFlowConfig: "T_FORMS_15",
    createRecordStepWorkFlowConfig: "T_FORMS_16",
    getFormsForLookup: "T_FORMS_17",
    getFieldsFormsForLookup: "T_FORMS_18",
    getRecordsByFormLookup: "T_FORMS_19",
    updateFieldsStepWorkFlowConfigBatch: "T_FORMS_20",
    getFieldsBySticker: "T_FORMS_21",
    getStickerInfo: "T_FORMS_22",
    exportRecords: "T_FORMS_23",
    getExportRecords: "T_FORMS_24",
  },
  trd: {
    getTreeByDomain: "T_TRD_01",
    getTreeExpedientByDomain: "T_TRD_02",
    getExpedients: "T_TRD_03",
    getExpedientsByName: "T_TRD_04",
    getFilesByExpedient: "T_TRD_05",
    createFund: "T_TRD_06",
    createSection: "T_TRD_07",
    createSubSectionBySection: "T_TRD_08",
    createSerieBySectionOrSubSection: "T_TRD_09",
    createSubserieBySerie: "T_TRD_10",
    createExpedient: "T_TRD_11",
    createSubDivision: "T_TRD_12",
    createDirectory: "T_TRD_13",
    getTreeExist: "T_TRD_14",
    updateExpedient: "T_TRD_15",
    updateSubDivision: "T_TRD_16",
    updateDirectory: "T_TRD_17",
    updateSection: "T_TRD_18",
    updateSerie: "T_TRD_19",
    createDocumentaryType: "T_TRD_20",
    updateDocumentaryType: "T_TRD_21",
    updateSubSerie: "T_TRD_22",
    updateSubSection: "T_TRD_23",
    updateBackground: "T_TRD_24",
    getSerieSubserieStructure: "T_TRD_25",
    createSubdivisionByStructure: "T_TRD_26",
    createDirectoryByStructure: "T_TRD_27",
    updateSubdivisionStructure: "T_TRD_28",
    updateDirectoryStructure: "T_TRD_29",
    getDocumentalTypes: "T_TRD_30",
    deleteLevelTrd: "T_TRD_31",
    deleteLevelOrdinationTrd: "T_TRD_32",
    getPathSetExpedient: "T_TRD_33",
    setExpedientPath: "T_TRD_34",
    deletePathSetExpedient: "T_TRD_35",
  },
  user: {
    getUsers: "T_USER_01",
    getUsersById: "T_USER_02",
    createUser: "T_USER_03",
    updateUser: "T_USER_04",
    createPassword: "T_USER_05",
    updateStatus: "T_USER_06",
    emailPasswordReset: "T_USER_07",
    forgottenPasswordReset: "T_USER_08",
    getUserLogsById: "T_USER_09",
    changePasswordByUser: "T_USER_10",
    create_work_group: "T_USER_11",
    ConsultationWorkingGroups: "T_USER_12",
    getUserWorkGroupByRol: "T_USER_13",
    addUsersToWorkGroup: "T_USER_14",
    consultAllGroupsWork: "T_USER_15",
    updateWorkGroup: "T_USER_16",
    getMembersOfWorkGroup: "T_USER_17",
    getWorkGroupById: "T_USER_18",
    getWorkGroupsByStatus: "T_USER_19",
    deleteMemberOfWorkGroup: "T_USER_20",
    responseUsersList: "T_USER_21",
  },
  list: {
    createListWithItem: "T_LIST_01",
    createItemsInList: "T_LIST_02",
    getLists: "T_LIST_03",
    getListsById: "T_LIST_04",
    updateList: "T_LIST_05",
    getItems: "T_LIST_06",
    getItemsById: "T_LIST_07",
    updateItem: "T_LIST_08",
  },
  workflow: {
    createNotification: "T_WORKFLOW_01",
    getTasks: "T_WORKFLOW_02",
    getTasksByUuid: "T_WORKFLOW_03",
    getTaskFromFiledByUuid: "T_WORKFLOW_04",
    getProcessByUuid: "T_WORKFLOW_05",
    fulfillTask: "T_WORKFLOW_06",
    fulfillProcess: "T_WORKFLOW_07",
    reassignTask: "T_WORKFLOW_08",
    interruptProcessByUUID: "T_WORKFLOW_09",
    interruptProcessByBatch: "T_WORKFLOW_10",
    getUserForReassing: "T_WORKFLOW_11",
    getCommentsByUuid: "T_WORKFLOW_12",
    createComment: "T_WORKFLOW_13",
    getCommentsProcessByUuid: "T_WORKFLOW_14",
    createCommentProcess: "T_WORKFLOW_15",
    getWorkFlows: "T_WORKFLOW_16",
    getWorkFlowsAvailablesPerUser: "T_WORKFLOW_17",
    getProcess: "T_WORKFLOW_18",
    getWorkFlowsByForm: "T_WORKFLOW_19",
    getWorkFlowById: "T_WORKFLOW_20",
    startWorkflows: "T_WORKFLOW_21",
    editWorkflowById: "T_WORKFLOW_22",
    getWorkFlowSteps: "T_WORKFLOW_23",
    viewModel: "T_WORKFLOW_24",
    addResponsibleStep: "T_WORKFLOW_25",
    getWorkflowTraceability: "T_WORKFLOW_26",
    addResponsibleSenderSelects: "T_WORKFLOW_27",
    getWorkflowTraceabilityFiles: "T_WORKFLOW_28",
    getListResponsibles: "T_WORKFLOW_29",
    getFilesByProcess: "T_WORKFLOW_30",
    configureActions: "T_WORKFLOW_31",
    getListUserByProcess: "T_WORKFLOW_32",
    reassignProcess: "T_WORKFLOW_33",
    editCurrentAssignedById: "T_WORKFLOW_34",
    editListAssignedById: "T_WORKFLOW_35",
    deleteResponsibles: "T_WORKFLOW_36",
    assingWorkGroup: "T_WORKFLOW_37",
    updateWorkGroupResponsibleStep: "T_WORKFLOW_38",
    cofigurationFormInOneStep: "T_WORKFLOW_39",
    updateCofigurationFormInOneStep: "T_WORKFLOW_40",
    getConfigurationFormInOneStepById: "T_WORKFLOW_41",
    deleteCofigurationFormInOneStep: "T_WORKFLOW_42",
    configurationDataFormInStep: "T_WORKFLOW_43",
    getDataFormExternalConfiguration: "T_WORKFLOW_44",
    updateFormExternalConfiguration: "T_WORKFLOW_45",
    deleteConfigurationDataFormInStep: "T_WORKFLOW_46",
    verifyProcessBatch: "T_WORKFLOW_47",
    accomplishProcessBatch: "T_WORKFLOW_48",
    advancedFilterTasks: "T_WORKFLOW_49",
    reassingProcessBatch: "T_WORKFLOW_50",
    Created_workflow: "T_WORKFLOW_51",
    Created_workflow_proccess: "T_WORKFLOW_52",
    upload_xml: "T_WORKFLOW_53",
    delete_workflow_diagram: "T_WORKFLOW_54",
    getStepsToReturn: "T_WORKFLOW_55",
    returnToStepProcess: "T_WORKFLOW_56",
    managementProcess: "T_WORKFLOW_57",
    getProcessView: "T_WORKFLOW_58",
    verifyManagementProcess: "T_WORKFLOW_58",
    managementProcessTaskReasing: "T_WORKFLOW_59",
  },
  roles: {
    getAllUserAndRoles: "T_ROLES_01",
    createRole: "T_ROLES_02",
    getRole: "T_ROLES_03",
    editRole: "T_ROLES_04",
    deleteRole: "T_ROLES_05",
    getUsersByRol: "T_ROLES_06",
    getMembers: "T_ROLES_07",
    addMembersByRol: "T_ROLES_08",
    deleteMember: "T_ROLES_09",
    getFormsByRol: "T_ROLES_10",
    addPermissionsByRol: "T_ROLES_11",
    updatePermissionsByRol: "T_ROLES_12",
    addPermissionsConfig: "T_ROLES_13",
    addPermissionsDesignerForms: "T_ROLES_14",
    addPermissionsDesignerWorkflows: "T_ROLES_15",
    getPermissionsFormsByRol: "T_ROLES_16",
    getPermissionsConfigByRol: "T_ROLES_17",
    getPermissionsAdminFormsByRol: "T_ROLES_18",
    getPermissionsAdminWorkflowsByRol: "T_ROLES_19",
    deleteFormPremissionByRol: "T_ROLES_20",
  },
  integrations: {
    getIntegrations: "T_INTEGRATIONS_01",
    getIntegrationById: "T_INTEGRATIONS_02",
    requestInt: "T_INTEGRATIONS_03",
    configInt: "T_INTEGRATIONS_04",
    createSignature: "T_INTEGRATIONS_05",
    getSignatures: "T_INTEGRATIONS_06",
    getConfigIntegration: "T_INTEGRATIONS_07",
    reSendSignature: "T_INTEGRATIONS_08",
    getSignatories: "T_INTEGRATIONS_09",
  },
  dashboard: {
    getStorageConsumed: "T_DASHBOARD_01",
    getTotalRecords: "T_DASHBOARD_02",
    getRecordsByForm: "T_DASHBOARD_03",
    getRecordsByYearMonth: "T_DASHBOARD_04",
  },
  record: {
    createRecord: "T_RECORD_01",
    updateRecord: "T_RECORD_02",
    getRecords: "T_RECORD_03",
    getRecordsById: "T_RECORD_04",
    getFilesByForm: "T_RECORD_05",
    getFilesByTRD: "T_RECORD_06",
    updateFileById: "T_RECORD_07",
    getFilesByTask: "T_RECORD_08",
    createFileTRD: "T_RECORD_09",
    createFileByRecord: "T_RECORD_10",
    createFileTRDByTask: "T_RECORD_11",
    createFileByTaskRecord: "T_RECORD_12",
    createFileTRDByProcess: "T_RECORD_13",
    createFileByProcessRecord: "T_RECORD_14",
    getRecordsByFilters: "T_RECORD_15",
    getFreeTaskByRecord: "T_RECORD_16",
    getNotifiedUsersById: "T_RECORD_17",
    moveTrdFileLocation: "T_RECORD_18",
    moveFileToTrash: "T_RECORD_19",
    getTrashData: "T_RECORD_20",
    restoreTrashFiles: "T_RECORD_21",
    deleteFiles: "T_RECORD_22",
  },
  company: {
    getCompany: "T_COMPANY_01",
    getLogoByCompany: "T_COMPANY_02",
    configureLogoByCompany: "T_COMPANY_03",
    getLogsByCompany: "T_COMPANY_04",
  },
  login: {
    loginService: "T_LOGIN_01",
  },
};
