import React, { Fragment, useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import swal from "sweetalert";
import {
  Button,
  CardTitle,
  Label,
  CardFooter,
  Container,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import {
  numberLevel,
} from "utils/trdLevelsNameEdition";
import { showAlertServiceError } from "utils/alerts";
import {
  regexEditSerieFormCode,
  regexEditSubSectionName
} from "utils/regexExpressions";
import { enumSectionForm } from "utils/enums";
import useTrd from "hooks/useTrd";
import useEditLevelTrd from "hooks/useEditLevelTrd";
import i18n from "locales/i18n";
import ReactTooltip from "react-tooltip";
import { trimExtraSpaces } from "utils/trimSpaces";

const EditSectionForm = () => {
  const {
    trdForm,
    setTrdForm,
    setShowFolderCondition,
    setShowEditSectionForm,
    getTreeByDomainCallback,
    editSectionFormService,
    setEditSectionFormService,
    setIsLoading,
    isLoading,
    setLevelFolder,
    setSelectLevel
  } = useTrdContext();

  const { updateSection } = useTrd();
  const { trdLevelInformation } = useEditLevelTrd(numberLevel.SECTION);
  const [tempSection, setTempSection] = useState({
    name: "",
    code: "",
    parent_id: ""
  });
  const [keysTrdEdit] = useState([
    "name",
    "code",
    "parent_id"
  ]);

  /**
   * Update the editSectionFormService state with the new value based on the name of the target input.
   * @param {Event} eventEditSection - The event object received from the input field when edited.
   * Typically, this is the `onChange` event of the input element.
   * This event object contains the target input field's name and value.
   * @return {void}
   */
  const handleTrdSectionInfo = (eventEditSection) => {
    setEditSectionFormService({
      ...editSectionFormService,
      [eventEditSection.target.name]: eventEditSection.target.value
    })
  }

  /**
   * Resets the TRD section state by updating the `trdForm` state to clear the `name`
   * and set the `level` to `SECTION`. Also updates the folder level and selection to `SECTION`.
   */
  const resetTrdSection = () => {
    setTrdForm({
      ...trdForm,
      name: "",
      level: numberLevel.SECTION
    });
    setLevelFolder(numberLevel.SECTION)
    setSelectLevel(numberLevel.SECTION)
  };

  /**
   * Clear the TRD section data and update the state of various variables.
   * This function is typically used to reset the form and set the initial state when editing a TRD section.
   * @return {void}
   */
  const trdSectionClear = () => {
    setTrdForm({
      ...trdForm,
      name: "",
      level: numberLevel.SECTION,
      code: editSectionFormService.code
    });
    setLevelFolder(numberLevel.SECTION);
    setSelectLevel(numberLevel.SECTION);
    setShowFolderCondition(true);
    setShowEditSectionForm(false);
  }


  /**
   * Close the TRD section edit option, reset the TRD form state, and update the state of various variables.
   * This function is typically used to close the edit option for a TRD section and reset the form to its initial state.
   * @return {void}
   */
  const closeOptionSectionEdit = () => {
    setTrdForm({
      ...trdForm,
      level: numberLevel.SECTION,
    });
    setShowFolderCondition(true);
    setShowEditSectionForm(false)
    setTrdForm(resetTrdSection)
  }

  /**
 * Handle the onBlur event for the TRD section input field and update the editSectionFormService state.
 * This function is typically used to update the editSectionFormService state with the trimmed value of the input field.
 * @param {Event} eventOnBlur - The onBlur event object received from the input field.
 * This event object contains the target input field's name and value.
 * @return {void}
 */
  const handleOnBlurSection = (eventOnBlur) => {
    const { value, name } = eventOnBlur.target;
		const trimmedValue = trimExtraSpaces(value);
    setEditSectionFormService({
      ...editSectionFormService,
      [name]: trimmedValue,
    });
  }

  /**
   * Handle the onKeyPress event for the edit section input field and prevent default behavior for the Enter key.
   * @param {Event} eventOnKeyPress - The onKeyPress event object received from the input field.
   * This event object contains information about the pressed key.
   * @return {void}
   */
  const handleOnKeyPressEditSection = (eventOnKeyPress) => {
    if (eventOnKeyPress.key === "Enter") {
      eventOnKeyPress.preventDefault();
    }
  }

  /**
   * Show a success alert to indicate that the TRD section has been updated.
   * This function is typically used to display a success message after successfully updating the TRD section.
   * @return {void}
   */
  const alertUpdateSection = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trd.fromLabel4"),
      icon: "success",
      button: i18n.t("modal.Done.footerButton"),
    })
  }

  /**
   * Handle the form submission and update the section TRD if there are no errors.
   * @param {Event} eventSubmit - The form submit event object. This event object is triggered when the form is submitted.
   * @param {Array} errorsEditSection - An array of errors related to the form validation.
   * This array contains any validation errors detected in the form fields.
   * @return {void}
   */
  const handleOnSubmit = (eventSubmit, errorsEditSection) => {
    eventSubmit.preventDefault();
    if (errorsEditSection.length === 0) {
      const updatedSectionTrd = keysTrdEdit.reduce((acomulatorField, fieldKey) => {
        if (editSectionFormService[fieldKey] !== tempSection[fieldKey]) {
          acomulatorField[fieldKey] = editSectionFormService[fieldKey];
        }
        return acomulatorField;
      }, {});

      if (Object.keys(updatedSectionTrd).length === 1) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        })
        return
      }

      setIsLoading(true)
      updateSection(updatedSectionTrd, trdLevelInformation.trdId)
        .then(response => {
          if (response.status === 202) {
            trdSectionClear();
            alertUpdateSection();
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => { setIsLoading(false) })
    }
  }

  /**
   * useEffect hook to set initial values in the editSectionFormService and tempSection states.
   * This effect runs once, on component mount, to initialize the state with specific values.
   * It updates the editSectionFormService and tempSection states with data from trdLevelInformation and trdForm.
   * Note: The effect uses an empty dependency array to run only once.
   * @return {void}
   */
    useEffect(() => {
    setEditSectionFormService({
      ...editSectionFormService,
      name: trdLevelInformation.name,
      code: trdForm.code,
      parent_id: trdLevelInformation.parentId
    })

    setTempSection({
      ...tempSection,
      name: trdLevelInformation.name,
      code: trdForm.code
    })
    // eslint-disable-next-line 
  }, [])

  /**
   * useEffect hook to fetch tree data by domain using the getTreeByDomainCallback function.
   * This effect runs when the `getTreeByDomainCallback` function changes, ensuring the data is fetched on demand.
   * Note: The effect uses the `getTreeByDomainCallback` function as a dependency to update when it changes.
   * @return {void}
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <CardTitle>{i18n.t("trd.formTittle33")}</CardTitle>
          <AvForm onSubmit={handleOnSubmit}>
            <AvGroup>
              <ReactTooltip id="code" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip7")}
              </ReactTooltip>
              <Label for="code" className="is-required">
                {i18n.t("trd.fromLabel1")}
              </Label>
              <AvField
                id="code"
                name="code"
                type="text"
                onChange={handleTrdSectionInfo}
                onKeyPress={handleOnKeyPressEditSection}
                onBlur={handleOnBlurSection}
                validate={{
                  pattern: {
                    value: regexEditSerieFormCode,
                    errorMessage: `${i18n.t("invalidField2")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.messageNameRequired")}`,
                  },
                  minLength: {
                    value: enumSectionForm.MIN_LENGT,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumSectionForm.MAX_LENGTH,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="code"
                autoFocus={true}
                autoComplete="off"
                value={editSectionFormService.code}
              />
            </AvGroup>

            <AvGroup>
              <ReactTooltip
                id="name_element"
                place="bottom"
                type="dark"
                effect="solid"
              >
                {i18n.t("trd.Tooltip8")}
              </ReactTooltip>
              <Label for="name" className="is-required">
                {i18n.t("trd.inputTittle2")}
              </Label>
              <AvField
                onChange={handleTrdSectionInfo}
                onKeyPress={handleOnKeyPressEditSection}
                onBlur={handleOnBlurSection}
                type="text"
                name="name"
                id="name"
                validate={{
                  pattern: {
                    value: regexEditSubSectionName,
                    errorMessage: `${i18n.t("invalidField2")}`,
                  },
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("trd.messageNameRequiredSection")}`,
                  },
                  minLength: {
                    value: enumSectionForm.MIN_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },

                  maxLength: {
                    value: enumSectionForm.MAX_LENGTH_NAME,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                  },
                }}
                data-tip
                data-for="name_element"
                autoComplete="off"
                required
                value={editSectionFormService.name}
              />
            </AvGroup>

            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                className="col-mt-3 mr-3"
                color="gray"
                onClick={closeOptionSectionEdit}
              >
                {i18n.t("createusers.createButton2")}
              </Button>
              <Button
                type="submit"
                size="lg"
                className="col-mt-3"
                disabled={isLoading}
                color="cyan"
              >
                {i18n.t("trd.buttonUptade")}
              </Button>
            </CardFooter>
          </AvForm>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditSectionForm;
