import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { getYear } from "utils/getDateFormat";
import { settingAnimationLogin } from "utils/settingAnimations";
import Slider from "react-slick";
import LoginForm from "components/organism/LoginForm";
import i18n from "locales/i18n";

const Login = () => {

  /**
   * The current year.
   * @type {number}
   */
  const currentYear = getYear();

  return (
    <Fragment>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...settingAnimationLogin}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-6 changeforgottenPassword-image1"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("dashboardheading")}</h3>
                    <p>{i18n.t("changePassword.p1")}</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-6 changeforgottenPassword-image2"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("changePassword.t2")}</h3>
                    <p>{i18n.t("changePassword.p2")}</p>
                  </div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg opacity-6 changeforgottenPassword-image3"
                  />
                  <div className="slider-content">
                    <h3>{i18n.t("changePassword.t3")}</h3>
                    <p>{i18n.t("changePassword.p3")}</p>
                  </div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            sm="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="8" md="10" sm="12" className="mx-auto app-login-box pt-3 pb-3">
              <div className="app-logo"></div>
              <h4 class="mt-4 mb-2">
                <div>{i18n.t("dashboardsubheading")}</div>
              </h4>
              <div>
                <LoginForm />
              </div>
              <br />
              <div className="text-center opacity-8 mt-3">{i18n.t("login.feedBack3")}</div>
              <a
                href="https://www.estrategiasdocumentales.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-lg btn btn-link  d-flex justify-content-center align-items-center"
              >
                {i18n.t("login.label5")}
              </a>
              <div className="text-center opacity-8 mt-5">
                {i18n.t("copyright") + " " + currentYear + "®"}
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Login;
