import React, { Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import { actions } from "utils/nameTypes";
import {
  generateUpdateStatusContent,
  renderFailedAttempts,
  renderUpdateUser,
} from "utils/logsHelpers";
import { getDateFormat2 } from "utils/getDateFormat2";
import i18n from "locales/i18n";

const UserLogTable = (props) => {
  /**
   * Generates content based on the specified action and row information.
   *
   * @param {Object} row - The row object containing information about the action.
   * @param {number} row.original.action - The action code.
   * @param {string} row.original.affected_user_name - The name of the affected user.
   * @param {Object[]} row.original.detail - Details related to the action.
   * @param {string} row.original.detail.field - The field being updated.
   * @param {Object} row.original.detail.status - The status details for update status action.
   *
   * @returns {Object} resultMessage - An object containing action-specific messages.
   * @returns {string} resultMessage.actionDetailLogUser - Details log message for user action.
   * @returns {string} resultMessage.actionUpdateUser - Update user message.
   * @returns {string} resultMessage.actionCreateUser - Create user message.
   * @returns {string} resultMessage.actionUpdateStatusUser - Update status user message.
   * @returns {string} resultMessage.actionPasswordAssignmentUser - Password assignment user message.
   * @returns {string} resultMessage.actionLoginUser - Login user message.
   *
   */
  const generateContentByAction = useCallback(
    (row) => {
      let resultMessage = {
        actionDetailLogUser: "",
        actionUpdateUser: "",
        actionCreateUser: "",
        actionUpdateStatusUser: "",
        actionPasswordAssignmentUser: "",
        actionLoginUser: "",
      };
      switch (row.original.action) {
        case 1:
          resultMessage.actionCreateUser =
            i18n.t("log.user.deatails.create") +
            row.original.affected_user_name;
          break;
        case 2:
          if (row.original.detail.length > 0) {
            if (row.original.detail[0].field === "failed_attempts") {
              resultMessage.actionDetailLogUser = row.original.detail;
            } else {
              resultMessage.actionUpdateUser = row.original.detail;
            }
          }
          break;
        case 3:
          resultMessage.actionCreateUser = generateUpdateStatusContent(
            row.original.detail.status,
            row
          );
          break;
        case 4:
          resultMessage.actionCreateUser =
            i18n.t("log.user.user") +
            row.original.affected_user_name +
            i18n.t("log.user.password.reset.action");
          break;
        case 5:
          if (row.original.detail.type === 1) {
            resultMessage.actionCreateUser =
              i18n.t("log.user.user") +
              row.original.affected_user_name +
              i18n.t("log.user.password.reset.forget");
          }
          if (row.original.detail.type === 2) {
            resultMessage.actionCreateUser =
              i18n.t("log.user.user") +
              row.original.affected_user_name +
              i18n.t("log.user.password.reset.expiration");
          }
          break;
        case 6:
          resultMessage.actionLoginUser =
            i18n.t("log.user.user") +
            row.original.affected_user_name +
            i18n.t("log.user.startLogin1");
          break;
        default:
          break;
      }
      return resultMessage;
    },
    [generateUpdateStatusContent] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Renders the content of the detail cell based on the action type.
   *
   * @param {Object} row - The row object containing information about the action.
   * @param {string[]} row.original.detail - Details related to the action.
   *
   * @returns {React.ReactNode} renderedDetail - The React component rendering the detail cell content.
   *
   */
  const renderDetailCell = useCallback(
    (row) => {
      const {
        actionDetailLogUser,
        actionUpdateUser,
        actionCreateUser,
        actionUpdateStatusUser,
        actionPasswordAssignmentUser,
        actionLoginUser,
      } = generateContentByAction(row);

      return (
        <div>
          {actionDetailLogUser && actionDetailLogUser.length > 0 && (
            <div>
              {actionDetailLogUser.map((msg) => renderFailedAttempts(msg))}
            </div>
          )}

          {actionUpdateUser && actionUpdateUser.length > 0 && (
            <div className="update-user-log">
              {actionUpdateUser.map((msg) => renderUpdateUser(msg))}
            </div>
          )}

          {actionCreateUser && <div>{actionCreateUser}</div>}
          {actionUpdateStatusUser && <div>{actionUpdateStatusUser}</div>}
          {actionPasswordAssignmentUser && (
            <div>{actionPasswordAssignmentUser}</div>
          )}
          {actionLoginUser && <div>{actionLoginUser}</div>}
        </div>
      );
    },
    [generateContentByAction]
  );

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={props.listLogUsers}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("log.user.header1")],
                  accessor: "action",
                  Cell: (row) => {
                    return <p>{actions(row.original.action)}</p>;
                  },
                },
                {
                  Header: [i18n.t("log.user.header2")],
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <p>{valueFinal}</p>;
                  },
                },
                {
                  Header: [i18n.t("log.user.header3")],
                  accessor: "user_name",
                  width: 200,
                },
                {
                  Header: [i18n.t("log.user.header4")],
                  accessor: "ip_address",
                },
                {
                  Header: [i18n.t("log.user.header5")],
                  accessor: "detail",
                  width: 300,
                  className: "user-detail",
                  Cell: renderDetailCell,
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={props.totalPages}
          page={props.pagination.page - 1}
          showPageJump={false}
          defaultPageSize={props.pagination.per_page}
          loading={props.isLoadingLogUsers}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            props.setPagination({ ...props.pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            props.setPagination({ ...props.pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};
UserLogTable.propTypes = {
  listLogUsers: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.object.isRequired,
  isLoadingLogUsers: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default UserLogTable;
