import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, CardBody, Col, CardFooter, CustomInput, Label, Row } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { capitalizeText } from "utils/formatText";
import { useRolesContext } from "contextAPI/RolesContext";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { enumsPermissions, enumsUserRolesConfig } from "utils/enums";
import { INTEGRATIONS } from "constants/securityConst";
import cx from "classnames";
import Loader from "react-loaders";
import makeAnimated from "react-select/lib/animated";
import useRoles from "hooks/useRoles";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import Select from "react-select";
import i18n from "locales/i18n";

const UserRolConfigForm = () => {
  const { id } = useParams();
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const { selectFormId } = useState({});
  const [formsDinamic, setFormsDinamic] = useState([]);
  const [permissionsForm, setPermissionsForm] = useState({
    add_register: false,
    view_register: false,
    edit_record: false,
    upload_files: false,
    create_expedient: false,
    set_expedient_path: false,
    delete_expedient_path: false,
    view_files: false,
    notifications: false,
    annulment: false,
    start_workflow: false,
    see_process: false,
    edit_file_data: false,
    move_file: false,
    move_to_trash: false,
    sign_electronically: false,
  });
  const [permissionsForms, setPermissionsForms] = useState({
    form_uuid: "",
    permissions: [],
  });
  const [isErrorSelectForm, setIsErrorSelectForm] = useState(false);
  const [isLoadingAllForms, setIsLoadingAllForms] = useState(false);
  const [hasTrd, setHasTrd] = useState(false);
  const INTEGRATIONSOPT = window.localStorage.getItem(INTEGRATIONS);
  const { getActiveForms, componentToShow, setComponentToShow, setChargeForm } = useRolesContext();
  const { getTreeExist } = useTrd();
  const { getFormsByRol, addPermissionsByRol } = useRoles();
  let requiredFieldSelect = null;
  let disabledCheck = false;
  let disabledCheckWorkflow = false;
  let requiredCheck = false;
  let infoCheckWorkflow = null;
  let expedietnPermissions = null;
  let activeSetExpedietnPath = false;
  let elementToRender = null;
  let disabledCheckEditRecord;
  let moveFilePermissions = null;
  let signFilepermissions = null;

  /**
   * Fetches forms based on a specified role ID, filters and sorts the data, and updates the dynamic forms state.
   * @param {string} id - The ID associated with the role for which forms are being retrieved.
   * @param {function} setIsLoadingAllForms - State setter function to control the loading state of all forms.
   * @param {function} setFormsDinamic - State setter function to update the dynamic forms list with filtered and sorted data.
   * @returns {void}
   */
  const getFormsBySearch = useCallback(() => {
    setIsLoadingAllForms(true);
    getFormsByRol(id)
      .then((response) => {
        if (isNullOrUndefined(response.data.data) === false) {
          const listForms = response.data.data.items
            .filter((item) => item.status === 2)
            .sort((firstForm, secondForm) => firstForm.name.localeCompare(secondForm.name));

          setFormsDinamic(
            listForms.map((item) => ({
              value: item.uuid,
              label: item.name,
            }))
          );
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingAllForms(false);
      });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for a form input and updates the permissionsForm state accordingly.
   * @param {object} eventChange - The change event object triggered by the input element.
   * @param {string} eventChange.target.name - The name of the input field, used as the key in permissionsForm.
   * @param {string | boolean} eventChange.target.value - The value of the input field or checkbox.
   * @param {boolean} eventChange.target.checked - The checked status for checkboxes.
   * @returns {void}
   */
  const handleOnChange = (eventChange) => {
    const target = eventChange.target.value;
    const targetCheckbox = eventChange.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setPermissionsForm({
        ...permissionsForm,
        [eventChange.target.name]: Boolean(targetCheckbox),
      });
    } else {
      setPermissionsForm({
        ...permissionsForm,
        [eventChange.target.name]: target,
      });
    }
  };

  /**
   * Handles the change event for the selection of forms and updates state accordingly.
   * This function is designed to be used as an event handler for the change event
   * when selecting forms. It updates the state based on the selected form options.
   * @param {object[]} selectFormId - An array containing the selected options representing forms.
   * @param {string} selectFormId[].value - The UUID of the selected form.
   * @param {string} selectFormId[].label - The label or display name of the selected form.
   * @returns {void}
   */
  const handleOnChangeForms = (selectFormId) => {
    if (selectFormId.length === 0) {
      setIsErrorSelectForm(true);
    } else {
      setPermissionsForms({
        ...permissionsForms,
        form_uuid: selectFormId.value,
      });
      setIsErrorSelectForm(false);
    }
  };

  /**
   * Handles the cancellation of an operation, resetting component state and permissions.
   * @returns {void}
   */
  const handleOnCancel = () => {
    setComponentToShow(enumsUserRolesConfig.CANCEL_VIEW_FORM);
    setPermissionsForm({
      ...permissionsForm,
      add_register: false,
      view_register: false,
      edit_record: false,
      upload_files: false,
      view_files: false,
      notifications: false,
      annulment: false,
      start_workflow: false,
      see_process: false,
      edit_file_data: false,
      move_file: false,
      move_to_trash: false,
      sign_electronically: false,
    });
    setPermissionsForms({
      ...permissionsForms,
      form_uuid: "",
      permissions: [],
    });
  };

  /**
   * Handles the form submission, processes permissions, and displays alerts based on conditions.
   * @param {Event} eventSubmit - The form submission event.
   * @param {Array} errors - An array of errors from form validation.
   * @returns {void}
   */
  const handleOnSubmit = (eventSubmit, errors) => {
    if (permissionsForms.form_uuid.length === 0) {
      setIsErrorSelectForm(true);
      setIsLoadingForms(false);
      eventSubmit.preventDefault();
    } else if (requiredCheck === true) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("createRoles.configNoOptions"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
    }

    if (errors.length === 0) {
      if (permissionsForms.form_uuid.length === 0) {
        eventSubmit.preventDefault();
      } else {
        setIsLoadingForms(true);
        addPermissionsByRol(id, permissionsForms)
          .then((response) => {
            if (response.status === 201) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.configAlert"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                setComponentToShow(enumsUserRolesConfig.CANCEL_VIEW_FORM);
                setChargeForm(true);
              });
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingForms(false);
          });
      }
    }
  };

  /**
   * Checks if the form UUID in the permissionsForms object is empty and sets the disabledCheck accordingly.
   * @param {object} permissionsForms - An object containing permissions and information related to a form.
   * @param {string} form_uuid - The UUID of the form to be checked.
   * @returns {boolean} disabledCheck - A boolean indicating whether the form UUID is empty.
   *   - `true` if the form UUID is empty.
   *   - `false` if the form UUID is not empty.
   */
  if (permissionsForms.form_uuid === "") {
    disabledCheck = true;
  } else {
    disabledCheck = false;
  }

  /**
   * Checks permissions related to a form's UUID and workflow initiation.
   * This function evaluates permissions related to a form's UUID and the ability to start a workflow.
   * If the form UUID is empty or the permission to start a workflow is granted, the workflow check is disabled.
   * Otherwise, the check is enabled.
   * @param {object} permissionsForms - Object containing form-related permissions.
   * @param {string} permissionsForms.form_uuid - The UUID of the form.
   * @param {boolean} permissionsForms.start_workflow - Indicates permission to start a workflow.
   * @returns {boolean} disabledCheckWorkflow - A boolean indicating whether the workflow check is disabled.
   */
  if (permissionsForms.form_uuid === "" || permissionsForm.start_workflow === true) {
    disabledCheckWorkflow = true;
  } else {
    disabledCheckWorkflow = false;
  }

  /**
   * Determines the value of `requiredCheck` based on the length of permissionsForms.permissions array.
   * @param {object} permissionsForms - An object containing permissions information.
   * @param {Array} permissionsForms.permissions - The array of permissions.
   * @returns {boolean} - The result of the required check based on the length of the permissions array.
   */
  if (permissionsForms.permissions.length === 0) {
    requiredCheck = true;
  } else {
    requiredCheck = false;
  }

  /**
   * Renders a message indicating that a field is required if the 'isErrorSelectForm' flag is set to true.
   * @param {boolean} isErrorSelectForm - A flag indicating whether the field is in an error state.
   * @param {string} i18n.t - A function used for internationalization to translate the "fieldRequired" message.
   * @returns {JSX.Element | null} - A JSX element representing the required field message or null if no message is required.
   */
  if (isErrorSelectForm === true) {
    requiredFieldSelect = <span className="text-danger text small">{i18n.t("fieldRequired")}</span>;
  } else {
    requiredFieldSelect = null;
  }

  /**
   * Conditional rendering based on the 'start_workflow' permission.
   * If the 'start_workflow' permission is true, it displays a message indicating
   * that the workflow can be started. Otherwise, it doesn't render anything.
   * @const {boolean} permissionsForm.start_workflow - The permission to start the workflow.
   * @type {JSX.Element|null} infoCheckWorkflow - Displayed information based on the permission.
   * @returns {JSX.Element|null} - The JSX element representing the information or null if the permission is false.
   */
  if (permissionsForm.start_workflow === true) {
    infoCheckWorkflow = (
      <span className="text-cyan text small">{i18n.t("createRoles.configStartProcessMes")}</span>
    );
  } else {
    infoCheckWorkflow = null;
  }

  /**
   * Checks the permission to upload files and sets the expedient path activation accordingly.
   * @param {Object} permissionsForm - The permissions object related to form actions.
   * @param {boolean} permissionsForm.upload_files - Indicates whether file uploads are allowed.
   * @param {boolean} activeSetExpedientPath - The flag to control the activation of expedient path.
   * If permissionsForm.upload_files is false, activeSetExpedientPath will be set to true.
   * @returns {void}
   */
  if (permissionsForm.upload_files === false) {
    activeSetExpedietnPath = true;
  }

  /**
   * Updates the disabledCheckEditRecord flag based on permissions.
   * @param {Object} permissionsForm - The permissions form object containing the user's permissions.
   * @param {boolean} permissionsForm.view_register - Indicates if the user has permission to view the register.
   * @param {boolean} permissionsForm.add_register - Indicates if the user has permission to add a register.
   * @param {boolean} disabledCheckEditRecord - A flag indicating whether editing the record is disabled.
   */
  if (permissionsForm.view_register === true || permissionsForm.add_register === true) {
    disabledCheckEditRecord = false;
  } else {
    disabledCheckEditRecord = true;
  }

  /**
   * Renders a checkbox input for electronic signature if the user has integration permissions.
   * @param {Object} INTEGRATIONSOPT - The object containing integration options.
   * @param {Object} enumsUserRolesConfig - The configuration object for user role enums.
   * @param {Function} handleOnChange - The function to handle change events on the checkbox.
   * @param {boolean} requiredCheck - Indicates if the checkbox is required.
   * @param {boolean} disabledCheck - Indicates if the checkbox is disabled.
   * @param {Object} i18n - The internationalization object for translations.
   * @param {Object} permissionsForm - The form object containing user permissions.
   * @returns {JSX.Element|null} - A JSX element for the electronic signature checkbox or null.
   */
  if (INTEGRATIONSOPT[1] === enumsUserRolesConfig.HAS_INTEGRATION) {
    signFilepermissions = (
      <Col sm={12}>
        <AvInput
          id="sign_electronically"
          name="sign_electronically"
          type="checkbox"
          onChange={handleOnChange}
          tag={CustomInput}
          required={requiredCheck}
          disabled={disabledCheck}
          label={i18n.t("signature.buttonActions")}
          value={permissionsForm.sign_electronically}
        />
      </Col>
    );
  }

  /**
   * Renders a set of permission checkboxes related to expedient actions if `hasTrd` is true.
   * @param {boolean} hasTrd - Indicates whether the user has the TRD (Temporary Records Destruction) feature enabled.
   * @param {function} handleOnChange - Event handler function to be executed when a checkbox value changes.
   * @param {boolean} requiredCheck - Determines whether the checkbox is required to be checked.
   * @param {boolean} activeSetExpedietnPath - Indicates whether the expedient path setting is currently active.
   * @param {object} i18n - Localization object containing translation functions.
   * @param {object} permissionsForm - Object containing permission settings for various expedient actions.
   * @returns {JSX.Element|null} Returns the JSX elements representing the expedient permissions checkboxes if `hasTrd` is true; otherwise, returns null.
   */
  if (hasTrd === true) {
    expedietnPermissions = (
      <div>
        <Col sm={12}>
          <AvInput
            id="create_expedient"
            name="create_expedient"
            type="checkbox"
            onChange={handleOnChange}
            checked={permissionsForm.create_expedient}
            tag={CustomInput}
            required={requiredCheck}
            disabled={activeSetExpedietnPath}
            label={i18n.t("trd.formTittle6")}
            value={permissionsForm.create_expedient}
          />
        </Col>

        <Col sm={12}>
          <AvInput
            id="set_expedient_path"
            name="set_expedient_path"
            type="checkbox"
            onChange={handleOnChange}
            checked={permissionsForm.set_expedient_path}
            tag={CustomInput}
            required={requiredCheck}
            disabled={activeSetExpedietnPath}
            label={i18n.t("trdExpedientSetPath")}
            value={permissionsForm.set_expedient_path}
          />
        </Col>

        <Col sm={12}>
          <AvInput
            id="delete_expedient_path"
            name="delete_expedient_path"
            type="checkbox"
            onChange={handleOnChange}
            checked={permissionsForm.delete_expedient_path}
            tag={CustomInput}
            required={requiredCheck}
            disabled={activeSetExpedietnPath}
            label={i18n.t("trdExpedientListSetPathQuit")}
            value={permissionsForm.delete_expedient_path}
          />
        </Col>
      </div>
    );

    moveFilePermissions = (
      <Col sm={12}>
        <AvInput
          id="move_file"
          name="move_file"
          type="checkbox"
          onChange={handleOnChange}
          tag={CustomInput}
          required={requiredCheck}
          disabled={disabledCheck}
          label={i18n.t("trd.moveFile1")}
          value={permissionsForm.move_file}
        />
      </Col>
    );
  }

  /**
   * Renders a specific UI element based on the state values of 'showLoader' and 'isLoadingAllForms'.
   * If either 'showLoader' or 'isLoadingAllForms' is true, a loading spinner is displayed.
   * Otherwise, a form configuration UI with various options is rendered.
   * @param {boolean} showLoader - Flag indicating whether to show a loader.
   * @param {boolean} isLoadingAllForms - Flag indicating whether all forms are currently being loaded.
   * @param {boolean} isErrorSelectForm - Flag indicating whether an error occurred during form loading.
   * @param {string} selectFormId - Currently selected option in the form select component.
   * @param {Array<object>} formsDinamic - List of dynamic form options.
   * @param {boolean} requiredFieldSelect - Flag indicating whether the form selection field is required.
   * @param {object} permissionsForm - Object containing permissions related to the form.
   * @param {function} handleOnChangeForms - Callback function invoked on form select change.
   * @param {function} handleOnChange - Callback function invoked on checkbox change.
   * @param {function} handleOnCancel - Callback function invoked on cancel button click.
   * @param {JSX.Element} expedietnPermissions - JSX element representing additional permissions.
   * @param {JSX.Element} infoCheckWorkflow - JSX element providing information about workflow-related checkbox.
   * @returns {JSX.Element} The rendered UI element based on the provided state values.
   */
  if (showLoader === true || isLoadingAllForms === true) {
    elementToRender = (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader type="ball-pulse-rise" />
      </div>
    );
  } else {
    elementToRender = (
      <div className="mt-2">
        <Row>
          <Col md={3}>
            <Label
              className={cx("is-required", {
                "labels-error": isErrorSelectForm === true,
              })}
            >
              {i18n.t("form.field43")}
            </Label>
          </Col>
          <Col md={9}>
            <Select
              id="forms_uuid"
              name="forms_uuid"
              classNamePrefix={cx("", {
                select: isErrorSelectForm === true,
              })}
              placeholder={i18n.t("recordData2")}
              closeMenuOnSelect={true}
              components={makeAnimated()}
              value={selectFormId}
              options={formsDinamic}
              onChange={handleOnChangeForms}
              noOptionsMessage={() => i18n.t("createRoles.configNoForms")}
            />
            {requiredFieldSelect}
          </Col>
        </Row>

        <em className="text-primary text-capitalize font-weight-bold mb-2">
          {i18n.t("createRoles.configFormOptions")}
        </em>
        <Fragment className="mt-4">
          <Col sm={12}>
            <AvInput
              id="add_register"
              name="add_register"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("createRoles.configFormOption1")}
              value={permissionsForm.add_register}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="view_register"
              name="view_register"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("createRoles.configFormOption2")}
              value={permissionsForm.view_register}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="edit_record"
              name="edit_record"
              type="checkbox"
              checked={permissionsForm.edit_record}
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheckEditRecord}
              label={i18n.t("form.field90")}
              value={permissionsForm.edit_record}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="upload_files"
              name="upload_files"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("recordDetail.upload")}
              value={permissionsForm.upload_files}
            />
          </Col>

          {expedietnPermissions}

          <Col sm={12}>
            <AvInput
              id="view_files"
              name="view_files"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("createRoles.configFormOption3")}
              value={permissionsForm.view_files}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="notifications"
              name="notifications"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("notifications.option")}
              value={permissionsForm.notifications}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="annulment"
              name="annulment"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("RecordDetail.label4")}
              value={permissionsForm.annulment}
            />
          </Col>

          <Col sm={12}>
            <AvInput
              id="start_workflow"
              name="start_workflow"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("createRoles.configStartProcess")}
              value={permissionsForm.start_workflow}
            />
          </Col>

          <Col sm={12}>{infoCheckWorkflow}</Col>

          <Col sm={12}>
            <AvInput
              id="see_process"
              name="see_process"
              type="checkbox"
              onChange={handleOnChange}
              checked={disabledCheckWorkflow}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheckWorkflow}
              label={i18n.t("createRoles.configSeeProcess")}
              value={permissionsForm.see_process}
            />
          </Col>
        </Fragment>
        <br />

        <em className="text-primary text-capitalize font-weight-bold pt-4 mt-4">
          {i18n.t("recordDetail.ActionsFiles")}
        </em>
        <Fragment className="pt-4">
          <Col sm={12}>
            <AvInput
              id="edit_file_data"
              name="edit_file_data"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("recordDetail.ModifyFiles")}
              value={permissionsForm.edit_file_data}
            />
          </Col>

          {moveFilePermissions}

          <Col sm={12}>
            <AvInput
              id="move_to_trash"
              name="move_to_trash"
              type="checkbox"
              onChange={handleOnChange}
              tag={CustomInput}
              required={requiredCheck}
              disabled={disabledCheck}
              label={i18n.t("recordDetail.MoveToTrashFiles")}
              value={permissionsForm.move_to_trash}
            />
          </Col>

          {signFilepermissions}
        </Fragment>

        <CardFooter className="d-block text-right mt-4">
          <Button
            size="lg"
            disabled={isLoadingForms}
            className="col-mt-3 mr-3"
            color="gray"
            onClick={handleOnCancel}
          >
            {i18n.t("createusers.createButton2")}
          </Button>

          <Button
            type="submit"
            size="lg"
            disabled={isLoadingForms}
            className="col-mt-3"
            color="cyan"
          >
            {i18n.t("createRoles.configFormAdd")}
          </Button>
        </CardFooter>
      </div>
    );
  }

  /**
   * useEffect hook that updates specific permissions based on the upload_files permission status.
   * @param {Object} permissionsForm - The current permissions form object.
   * @param {boolean} permissionsForm.upload_files - Flag indicating whether file upload is permitted.
   * @param {function} setPermissionsForm - State setter function to update permissions.
   * @returns {void}
   */
  useEffect(() => {
    if (permissionsForm.upload_files === false) {
      setPermissionsForm((prevPermissionsForm) => ({
        ...prevPermissionsForm,
        create_expedient: false,
        set_expedient_path: false,
        delete_expedient_path: false,
      }));
    }
  }, [permissionsForm.upload_files]);

  /**
   * Updates permissions for forms based on the provided permissions form object.
   * Invoked whenever the permissionsForm state changes.
   * @param {Object} permissionsForm - The permissions form object containing various permission flags.
   * @param {boolean} permissionsForm.add_register - Flag indicating permission to add a record.
   * @param {boolean} permissionsForm.view_register - Flag indicating permission to view a record.
   * @param {boolean} permissionsForm.upload_files - Flag indicating permission to upload files.
   * @param {boolean} permissionsForm.create_expedient - Flag indicating permission to create an expedient.
   * @param {boolean} permissionsForm.set_expedient_path - Flag indicating permission to set an expedient path.
   * @param {boolean} permissionsForm.delete_expedient_path - Flag indicating permission to delete an expedient path.
   * @param {boolean} permissionsForm.view_files - Flag indicating permission to view files.
   * @param {boolean} permissionsForm.notifications - Flag indicating permission to manage notifications.
   * @param {boolean} permissionsForm.start_workflow - Flag indicating permission to start a workflow.
   * @param {boolean} permissionsForm.see_process - Flag indicating permission to view processes.
   * @param {boolean} permissionsForm.annulment - Flag indicating permission for annulment.
   * @returns {void}
   */
  useEffect(() => {
    const updatePermissionsForms = () => {
      const permissions = [];

      if (permissionsForm.add_register === true) {
        permissions.push(enumsPermissions.ADD_RECORD);
      }
      if (permissionsForm.view_register === true) {
        permissions.push(enumsPermissions.VIEW_RECORD);
      }
      if (permissionsForm.edit_record === true) {
        permissions.push(enumsPermissions.EDIT_RECORDS);
      }
      if (permissionsForm.upload_files === true) {
        permissions.push(enumsPermissions.UPLOAD_FILE);
      }
      if (permissionsForm.upload_files === true && permissionsForm.create_expedient === true) {
        permissions.push(enumsPermissions.CREATE_EXPEDIENT);
      }
      if (permissionsForm.upload_files === true && permissionsForm.set_expedient_path === true) {
        permissions.push(enumsPermissions.SET_EXPEDIENT);
      }
      if (permissionsForm.upload_files === true && permissionsForm.delete_expedient_path === true) {
        permissions.push(enumsPermissions.REMOVE_EXPEDIENT);
      }
      if (permissionsForm.view_files === true) {
        permissions.push(enumsPermissions.VIEW_FILE);
      }
      if (permissionsForm.notifications === true) {
        permissions.push(enumsPermissions.ADD_NOTIFICATION);
      }
      if (permissionsForm.annulment === true) {
        permissions.push(enumsPermissions.CANCEL_RECORD);
      }
      if (permissionsForm.start_workflow === true && permissionsForm.see_process === false) {
        permissions.push(enumsPermissions.ADD_PROCESS);
        permissions.push(enumsPermissions.VIEW_PROCESS);
      }
      if (permissionsForm.see_process === true) {
        permissions.push(enumsPermissions.VIEW_PROCESS);
      }
      if (permissionsForm.edit_file_data === true) {
        permissions.push(enumsPermissions.EDIT_FILE_DATA);
      }
      if (permissionsForm.move_file === true) {
        permissions.push(enumsPermissions.MOVE_FILE);
      }
      if (permissionsForm.move_to_trash === true) {
        permissions.push(enumsPermissions.MOVE_FILE_TO_TRASH);
      }
      if (permissionsForm.sign_electronically === true) {
        permissions.push(enumsPermissions.SIGN_ELECTRONICALLY);
      }

      setPermissionsForms((prevPermissionsForms) => ({
        ...prevPermissionsForms,
        permissions: permissions,
      }));
    };

    updatePermissionsForms();
  }, [permissionsForm]);

  /**
   * Effect hook that updates the `permissionsForm` state.
   * If both `view_register` and `add_register` permissions are false,
   * it sets the `edit_record` permission to false.
   * @param {Object} permissionsForm - The state object representing permissions.
   * @param {boolean} permissionsForm.view_register - Indicates if the user can view the register.
   * @param {boolean} permissionsForm.add_register - Indicates if the user can add a new register.
   * @param {Function} setPermissionsForm - The function to update the `permissionsForm` state.
   */
  useEffect(() => {
    if (permissionsForm.view_register !== true && permissionsForm.add_register !== true) {
      setPermissionsForm({ ...permissionsForm, edit_record: false });
    }
  }, [permissionsForm.view_register, permissionsForm.add_register]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that triggers an action based on the value of the 'componentToShow' prop.
   * If 'componentToShow' is equal to "1", it invokes the 'getActiveForms' function.
   * @param {string} componentToShow - The identifier for the component to be displayed.
   *   If it equals "1", the 'getActiveForms' function is invoked.
   * @returns {void}
   */
  useEffect(() => {
    if (componentToShow === enumsUserRolesConfig.ADD_FORMS) {
      getActiveForms();
    }
  }, [componentToShow]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that triggers the 'getFormsBySearch' function when its dependencies change.
   * This effect is designed to call the 'getFormsBySearch' function when the 'getFormsBySearch'
   * dependency changes, typically when it is updated or replaced. It is commonly used to fetch
   * and update forms based on a search criteria.
   * @param {Function} getFormsBySearch - The function responsible for fetching and updating forms based on search criteria.
   * @returns {void}
   */
  useEffect(() => {
    getFormsBySearch();
  }, [getFormsBySearch]);

  /**
   * useEffect hook that sets up a timer to hide a loader after a specified delay.
   * This effect is useful for scenarios where you want to display a loader for a certain
   * duration before hiding it automatically.
   * This effect utilizes setTimeout to trigger the hiding of a loader after a specified delay.
   * It also includes cleanup logic to clear the timeout when the component unmounts or when
   * the dependencies change, preventing potential memory leaks.
   * @listens SomeComponent#componentDidMount
   * @listens SomeComponent#componentWillUnmount
   * @param {function} setShowLoader - State setter function to control the visibility of the loader.
   *   It is used to hide the loader after the specified delay.
   * @returns {void}
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      getTreeExist()
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setHasTrd(response.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setShowLoader(false);
        });
    }, 1200);
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <AvForm onSubmit={handleOnSubmit}>
            <em className="text-primary font-weight-bold pb-2">
              {capitalizeText(i18n.t("createRoles.configForm"))}
            </em>
            {elementToRender}
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserRolConfigForm;
