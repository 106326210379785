import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, Card, CardBody } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import { enumsTask } from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const RecordTraceabilityTable = (props) => {
  const { data } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={data}
                  columns={[
                    {
                      columns: [
                        {
                          Header: [i18n.t("createWorkflow.config.responsible")],
                          accessor: "responsible",
                        },
                        {
                          Header: [i18n.t("createWorflow.state")],
                          accessor: "status",
                          Cell: ({ value }) => {
                            let statusFinal = "";
                            if (value === enumsTask.IN_PROGRESS) {
                              statusFinal = (
                                <div className="ml-auto badge badge-warning">
                                  {i18n.t("taskDetailStatus1")}
                                </div>
                              );
                            } else if (value === enumsTask.COMPLETED) {
                              statusFinal = (
                                <div className="ml-auto badge badge-success">
                                  {i18n.t("taskDetailStatus2")}
                                </div>
                              );
                            } else if (value === enumsTask.INTERRUPTED) {
                              statusFinal = (
                                <div className="ml-auto badge badge-danger">
                                  {i18n.t("taskDetailStatus3")}
                                </div>
                              );
                            }
                            return <span>{statusFinal}</span>;
                          },
                        },

                        {
                          Header: [i18n.t("workflowTraceability.complianceDate")],
                          accessor: "execution_date",

                          Cell: ({ value }) => {
                            if (
                              value === "0000-00-00T00:00:00+00:00" ||
                              value === undefined ||
                              value === null
                            ) {
                              return null;
                            } else {
                              let valueFinal = getDateFormat2(new Date(value));
                              return <p>{valueFinal}</p>;
                            }
                          },
                        },
                      ],
                    },
                  ]}
                  manual
                  previousText={`${i18n.t("previousText")}`}
                  nextText={`${i18n.t("nextText")}`}
                  pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
                  ofText={<span className="px-2">{i18n.t("ofText")}</span>}
                  showPageJump={false}
                  noDataText={`${i18n.t("tableRowsEmpty")}`}
                  defaultPageSize={data.length}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

RecordTraceabilityTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RecordTraceabilityTable;