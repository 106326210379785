import React, { Fragment, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Alert } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useListContext } from "contextAPI/ListsContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { loaderElement } from "utils/loaderElement";
import { isNullOrUndefined } from "utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { enumsCreateExpedient, enumsExpedietnStatus } from "utils/enums";
import RecordListExpedient from "components/organism/RecordListExpedient";
import WizardDocuments from "components/molecules/WizardDocuments";
import TaskUploadFilesByExpedient from "components/organism/TaskUploadFilesByExpedient";
import TaskUpLoadFiles from "components/organism/TaskUploadFiles";
import TrdLevelsOrdenation from "components/molecules/TrdLevelsOrdenation";
import CurrentPathTrd from "components/atoms/CurrentPathTrd";
import Context from "contextAPI/UserContext";
import TrdLevelsTree from "components/molecules/TrdLevelsTree";
import i18n from "locales/i18n";

const TaskUploadFilesTRD = (props) => {
  const {
    formIdInfo,
    hasExpedientFixedPath,
    expedientFixedUuid,
    hasOrdination,
    expedientStatus,
    structureTrdExpedient,
    isProcess,
    isFreeTask,
    isLoadingSteps,
  } = props;
  const {
    pathConfiguration,
    setParentId,
    setShowModalExpedient,
    currentPath,
    setCurrentPath,
    recordUuid,
  } = useRecordDetailContext();
  const {
    expedientSetPath,
    setFoldersId,
    parentData,
    setParentData,
    setFolderName,
  } = useTrdContext();
  const { setRecordNext, setAdvanced } = useListContext();
  const { user } = useContext(Context);
  let steps = [];
  let componentToRenderUploadFile;

  /**
   * Task configuration object for uploading files to an expedient.
   * This object includes details necessary for rendering the file upload task component.
   * @typedef {Object} TaskUploadFilesByExpedient
   * @property {string} name - The name of the task, translated using i18n.
   * @property {React.ReactNode} component - The React component used to render the file upload task.
   * @property {boolean} hasExpedientFixedPath - Indicates whether the expedient has a fixed path.
   * @property {string} expedientFixedUuid - The UUID of the fixed expedient.
   * @property {string} currentPath - The current path for file uploads.
   * @property {string} recordUuid - The UUID of the record associated with the task.
   * @property {boolean} isFreeTask - Indicates if the task is free-form (not part of a specific process).
   * @property {boolean} isProcess - Indicates if the task is part of a process.
   * @property {boolean} isProcessBatch - Indicates if the task is part of a batch process.
   */
  const taskUploadFilesByExpedient = {
    name: i18n.t("recordDetail.fileToExpedient"),
    component: (
      <TaskUploadFilesByExpedient
        pathConfiguration={pathConfiguration}
        hasExpedientFixedPath={hasExpedientFixedPath}
        currentPath={currentPath}
        isProcess={isProcess}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Represents a tree structure component for TRD levels.
   * @typedef {Object} TrdLevelsTree
   * @property {string} name - The localized name of the component, obtained through i18n.t().
   * @property {JSX.Element} component - The JSX element representing the TrdLevelsTree component.
   * @property {string} alertTitle - The title for the alert tooltip associated with the component.
   * @property {string} buttonText - The text displayed on the button within the component.
   * @property {string} currentPath - The current path used in the TrdLevelsTree component.
   * @property {function} setCurrentPath - A function to update the current path state within the TrdLevelsTree component.
   */
  const trdLevelsTree = {
    name: i18n.t("recordDetail.LocationFile"),
    component: (
      <TrdLevelsTree
        currentPath={currentPath}
        setCurrentPath={setCurrentPath}
      />
    ),
  };

  /**
   * Represents a record list expedient object.
   * @constant {Object} recordListExpedient
   * @property {string} name - The title of the record list expedient, localized using i18n.
   * @property {JSX.Element} component - The React component representing the record list expedient.
   * @property {Object} component.props - Props passed to the `RecordListExpedient` component.
   * @property {Array<Object>} component.props.structureTrdExpedient - The structure of the TRD expedient.
   * @property {boolean} component.props.hasExpedientFixedPath - Indicates if the expedient has a fixed path.
   * @property {boolean} component.props.hasOrdination - Indicates if ordination is present.
   * @property {string} component.props.expedientFixedUuid - The UUID of the fixed expedient.
   * @property {string} component.props.expedientStatus - The status of the expedient.
   * @property {boolean} component.props.isLoadingOrdination - Indicates if ordination is currently loading.
   * @property {boolean} component.props.isLoadingExpedietnPath - Indicates if the expedient path is currently loading.
   * @property {boolean} component.props.isFreeTask - Indicates if the task is free.
   * @property {boolean} component.props.isProcess - Indicates if the item is a process.
   * @property {boolean} component.props.isProcessBatch - Indicates if the item is a batch process.
   */
  const recordListExpedient = {
    name: i18n.t("trd.formTittle14"),
    component: (
      <RecordListExpedient
        formIdInfo={formIdInfo}
        expedientComeFrom={enumsCreateExpedient.TASK}
        hasExpedientFixedPath={hasExpedientFixedPath}
        isFreeTask={isFreeTask}
        isProcess={isProcess}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Object representing TRD levels ordination configuration.
   * @property {string} name - The name of the TRD levels ordination, localized using i18n.
   * @property {JSX.Element} component - The React component for TRD levels ordination.
   * @property {Array<object>} structureTrdExpedients - The structure of TRD expedients.
   * @property {boolean} isFreeTask - Indicates if the task is free.
   * @property {boolean} isProcess - Indicates if it is a process.
   * @property {boolean} isProcessBatch - Indicates if it is a process batch.
   * @property {string} recordUuid - The UUID of the record.
   */
  const trdLevelsOrdenation = {
    name: i18n.t("trd.ordinationTitle"),
    component: (
      <TrdLevelsOrdenation
        hasOrdenation={hasOrdination}
        pathConfiguration={pathConfiguration}
        structureTrdExpedient={structureTrdExpedient}
        recordUuid={recordUuid}
        isFreeTask={isFreeTask}
        isProcess={isProcess}
        isProcessBatch={false}
      />
    ),
  };

  /**
   * Object representing a component for displaying information related to a file in an expedient without status.
   * @property {string} name - The name of the component, localized using i18n.
   * @property {JSX.Element} component - The JSX element representing the component content.
   * @returns {object} The 'noStatus' object containing information and a JSX component.
   */
  const noStatus = {
    name: i18n.t("recordDetail.fileToExpedient"),
    component: (
      <div className="mt-3 ">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} : &nbsp;
            </span>
            <span>{i18n.t("trdExpedientListSetPathInactive")}</span>
          </span>
        </Alert>
        <Alert className="mbg-3" color="info" isOpen={true}>
          <div className="d-flex">
            <span className="pr-2">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <CurrentPathTrd
              currentPath={currentPath}
              pathConfig={expedientSetPath}
            />
          </div>
        </Alert>
      </div>
    ),
  };

  /**
   * Modifies the steps array based on the provided path configuration and related conditions.
   * @param {boolean} isNullOrUndefined - Indicates whether the pathConfiguration is null or undefined.
   * @param {boolean} hasExpedientFixedPath - Indicates if there's a fixed path for the expedient.
   * @param {string} expedientStatus - The status of the expedient (e.g., enumsExpedietnStatus.ACTIVE).
   * @param {boolean} hasOrdination - Indicates if there is ordination for the expedient.
   * @param {array} steps - The array of steps to be modified based on the configuration.
   * @param {*} trdLevelsOrdenation - Object representing TRD levels for ordination.
   * @param {*} taskUploadFilesByExpedient - Object representing record upload files by expedient.
   * @param {*} noStatus - Object representing the status when there's no active expedient status.
   * @param {*} recordListExpedient - Object representing the record list of expedients.
   * @param {*} trdLevelsTree - Object representing TRD levels in a tree structure.
   * @param {enumsExpedietnStatus} enumsExpedietnStatus - Enumerated type for expedient statuses.
   * @returns {void}
   */
  if (isNullOrUndefined(pathConfiguration) === false) {
    if (hasExpedientFixedPath === true) {
      if (expedientStatus === enumsExpedietnStatus.ACTIVE) {
        if (hasOrdination === true) {
          steps.splice(1, 0, trdLevelsOrdenation);
          steps.splice(2, 0, taskUploadFilesByExpedient);
        } else {
          steps.splice(1, 0, taskUploadFilesByExpedient);
        }
      } else {
        steps.splice(1, 0, noStatus);
      }
    } else {
      steps.splice(1, 0, recordListExpedient);
      steps.splice(2, 0, trdLevelsOrdenation);
      steps.splice(3, 0, taskUploadFilesByExpedient);
    }
  } else {
    steps.splice(1, 0, trdLevelsTree);
    steps.splice(2, 0, recordListExpedient);
    steps.splice(3, 0, trdLevelsOrdenation);
    steps.splice(4, 0, taskUploadFilesByExpedient);
  }

  /**
   * Determines which component to render based on the provided conditions.
   * @param {Object} user - The user object containing domain information.
   * @param {string} user.domain - The domain of the user.
   * @returns {JSX.Element} The component to be rendered based on the conditions:
   * - If the user domain is defined and steps are not loading, renders a MultiStepDocuments component.
   * - Otherwise, renders a TaskUpLoadFiles component.
   */
  if (isNullOrUndefined(user.domain) === false && isLoadingSteps === false) {
    componentToRenderUploadFile = (
      <WizardDocuments
        isFreeTask={isFreeTask}
        isProcess={isProcess}
        steps={steps}
      />
    );
  } else {
    componentToRenderUploadFile = (
      <TaskUpLoadFiles
        fileByTrd={false}
        isProcess={isProcess}
        isProcessBatch={false}
      />
    );
  }

  /**
   * useEffect hook that updates parent data based on conditions related to expedientFixedUuid and hasExpedientFixedPath.
   * @param {string} expedientFixedUuid - The UUID of the expedient data.
   * @param {boolean} hasExpedientFixedPath - Flag indicating whether the expedient has a fixed path.
   * @param {Object} parentData - Current parent data object to be updated.
   * @param {function} setParentData - State setter function for updating parent data.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(expedientFixedUuid) === false &&
      hasExpedientFixedPath === true &&
      isNullOrUndefined(pathConfiguration) === false
    ) {
      setParentData({ ...parentData, id: expedientFixedUuid, level: 5 });
    }
  }, [hasExpedientFixedPath, expedientFixedUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React effect that initializes various state values when mounted.
   * @returns {void}
   */
  useEffect(() => {
    setParentId("");
    setAdvanced(0);
    setRecordNext(false);
    setFolderName("");
    setShowModalExpedient(false);
    setFoldersId({
      backgroundId: "",
      sectionId: "",
      subSectionId: "",
      serieId: "",
      subSerieId: "",
      expedientId: "",
      subDivisionId: "",
      subDirectoryId: "",
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingSteps)}
      <Row>
        <Col md="12">
          <Fragment>{componentToRenderUploadFile}</Fragment>
        </Col>
      </Row>
    </Fragment>
  );
};

TaskUploadFilesTRD.propTypes = {
  formIdInfo: PropTypes.string.isRequired,
  hasExpedientFixedPath: PropTypes.bool.isRequired,
  expedientFixedUuid: PropTypes.string.isRequired,
  hasOrdination: PropTypes.bool.isRequired,
  expedientStatus: PropTypes.number,
  structureTrdExpedient: PropTypes.array.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isFreeTask: PropTypes.number,
  isLoadingSteps: PropTypes.bool.isRequired,
};

export default TaskUploadFilesTRD;
