import React, { useState } from 'react'
import {
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);

  let sourceIcon = faEye;
  let inputType = "password"

  /**
   * Sets the source icon based on the visibility state.
   * @param {boolean} visible - Indicates whether the element is visible.
   * @returns {FontAwesomeIcon} The FontAwesome icon for the visibility state.
   */
  if (visible === true) {
    sourceIcon = faEye;
  } else {
    sourceIcon = faEyeSlash;
  }

  /**
   * Sets the input type based on the visibility state.
   * @param {boolean} visible - Indicates whether the input should be visible.
   * @returns {string} The type of input element.
   */
  if (visible === true) {
    inputType = "text"
  } else {
    inputType = "password"
  }

  /**
   * Sets the input type based on the visibility state.
   * @param {boolean} visible - Indicates whether the input should be visible.
   * @returns {string} The type of input element.
   */
  const handleVisibilityPassword = () => {
    setVisible(visible => !visible)
  }

  /**
   * Toggles the visibility state of the password.
   * @returns {void}
   */
  const TogglePasswordIcon = (
    <span
      className="toggle-password-button"
      onClick={handleVisibilityPassword}
    >
      <FontAwesomeIcon
        icon={sourceIcon}
        color="white"
      />
    </span>
  )

  return {
    inputType,
    TogglePasswordIcon
  }
}

export default usePasswordToggle