import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody, Row } from "reactstrap";
import ReactTable from "react-table";
import Loader from "react-loaders";
import i18n from "locales/i18n";
import Chart from "react-apexcharts";

const MetricsRecordsListsByYearMonthTable = (props) => {
  const { recordsByYear, recordsByMonth, isLoadingListByYear } = props;

  /**
   *Definition of the 'optionBar' state object for configuring a chart.
   *@typedef {Object} OptionBar
   *@property {Object} chart - Configuration options for the chart.
   *@property {Object} plotOptions - Configuration options for the plot of the chart.
   *@property {Object} dataLabels - Configuration options for the data labels of the chart.
   *@property {Object} noData - Configuration options for displaying a message when there is no data for the chart.
   *@property {Object} xaxis - Configuration options for the X-axis of the chart.
   *@property {Object} tooltip - Configuration options for the tooltip of the chart.
   *@property {Object} grid - Configuration options for the grid lines of the chart.
   *@property {string[]} colors - Array of color codes to be used for the chart.
   *@property {Object} fill - Configuration options for the fill of the chart.
   */
  const [optionBar] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 5,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      style: {
        fontSize: "12px",
        colors: ["#033c73"],
      },
    },
    noData: {
      text: i18n.t("metrics.noData"),
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#033c73",
        fontSize: "14px",
      },
    },
    xaxis: {
      tickAmount: "dataPoints",
      categories: [
        i18n.t("metrics.Month1"),
        i18n.t("metrics.Month2"),
        i18n.t("metrics.Month3"),
        i18n.t("metrics.Month4"),
        i18n.t("metrics.Month5"),
        i18n.t("metrics.Month6"),
        i18n.t("metrics.Month7"),
        i18n.t("metrics.Month8"),
        i18n.t("metrics.Month9"),
        i18n.t("metrics.Month10"),
        i18n.t("metrics.Month11"),
        i18n.t("metrics.Month12"),
      ],
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return i18n.t("metrics.RecordsByformMonth");
          },
        },
      },
      marker: {
        show: false,
      },
    },

    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },

    colors: ["#00b85d"],

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  });

  const columns = [
    {
      Header: "Mes",
      accessor: "month",
    },
    {
      Header: "Cantidad",
      accessor: "quantity",
    },
  ];

  const months = [
    i18n.t("datapicker.Month1"),
    i18n.t("datapicker.Month2"),
    i18n.t("datapicker.Month3"),
    i18n.t("datapicker.Month4"),
    i18n.t("datapicker.Month5"),
    i18n.t("datapicker.Month6"),
    i18n.t("datapicker.Month7"),
    i18n.t("datapicker.Month8"),
    i18n.t("datapicker.Month9"),
    i18n.t("datapicker.Month10"),
    i18n.t("datapicker.Month11"),
    i18n.t("datapicker.Month12"),
  ];

  /**
   *Generates a transformed data array based on the recordsByMonth object.
   *@param {object} recordsByMonth - an object containing records grouped by month, where the keys represent the month numbers
   *@returns {array} - an array of transformed data objects, each containing the month and the corresponding quantity of records
   */
  const transformedData = Object.keys(recordsByMonth).map((key) => ({
    month: months[key - 1],
    quantity: recordsByMonth[key],
  }));

  /**
   *Renders the content of a table and a graph based on the state values.
   *@param {boolean} isLoadingListByYear - a boolean indicating whether the data is currently being loaded
   *@param {string} recordsByYear - a string representing the total number of records by year
   *@param {object} recordsByMonth - an object containing the records by month
   *@param {array} transformedData - an array of transformed data to be displayed in the table
   *@param {array} columns - an array of column configurations for the table
   *@param {object} optionBar - an object containing options for the bar chart
   */
  function renderGraphic() {
    if (isLoadingListByYear === true) {
      return (
        <div className="text-center mx-auto my-auto">
          <div className="loader-wrapper d-flex justify-content-center align-items-center">
            <Loader color="#0072BC" type="ball-pulse-rise" />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Row className="d-flex align-items-center">
            <h1 className="font-weight-bold text-cyan ml-5">{recordsByYear}</h1>
            <h5 className="ml-2">{i18n.t("metrics.totalRecords")}</h5>
          </Row>

          <Chart
            series={[
              {
                data: Object.values(recordsByMonth),
              },
            ]}
            type="bar"
            height="150"
            options={optionBar}
          />

          <ReactTable
            data={transformedData}
            columns={columns}
            manual
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            showPageJump={false}
            showPagination={false}
            defaultPageSize={12}
            className="-striped -highlight"
          />
        </div>
      );
    }
  }

  let graphicRender = renderGraphic();

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>{graphicRender}</CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

MetricsRecordsListsByYearMonthTable.propTypes = {
  recordsByYear: PropTypes.number,
  recordsByMonth: PropTypes.object.isRequired,
  isLoadingListByYear: PropTypes.bool.isRequired,
};

export default MetricsRecordsListsByYearMonthTable;
