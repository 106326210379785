import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, CustomInput } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import { useTrdContext } from "contextAPI/TrdContext";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { isNullOrUndefined } from "utils/validations";
import { enumLevelTrd, enumsRecordExpedientList, enumsTaskType, enumsTypeUser } from "utils/enums";
import { useListContext } from "contextAPI/ListsContext";
import { SET_PATH_EXPEDIENT, TYPE } from "constants/securityConst";
import { showAlertServiceError } from "utils/alerts";
import TrdLevelsOrdenation from "components/molecules/TrdLevelsOrdenation";
import ReactTable from "react-table";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const RecordExpedientList = (props) => {
  const {
    recordNumber,
    recordUuid,
    formUuid,
    isFreeTask,
    isProcess,
    isProcessBatch,
    nodataMessage,
    listExpedients,
    pagination,
    setPagination,
    totalPages,
    loadingExpedientList,
  } = props;
  const SETPATHEXPEDIENT = window.localStorage.getItem(SET_PATH_EXPEDIENT);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const [backgroundById, setBackgroundById] = useState(null);
  const { setExpedientPath } = useTrd();
  const {
    getTreeExpedientByDomainCallback,
    setExpedientName,
    setParentData,
    setRecordShowExpTree,
    structureTrdExpedient,
    setStructureTrdExpedient,
    sethasExpedientFixedPath,
    setExpedientSetPath,
    expedientPathSet,
    setExpedientPathSet,
  } = useTrdContext();
  const {
    backgroudByPath,
    pathConfiguration,
    showTrdLevelsOrdenation,
    setShowTrdLevelsOrdenation,
    backgroundId,
  } = useRecordDetailContext();
  const { setAdvanced } = useListContext();
  const { getPathSetExpedient } = useTrd();

  /**
   * Handles the selection or deselection of an expedient and updates relevant state variables accordingly.
   * @param {object} original - The original data object representing the expedient.
   * @param {string} original.uuid - The UUID of the expedient.
   * @param {string} original.name - The name of the expedient.
   * @param {function} setRecordShowExpTree - State setter function for controlling the visibility of the expedient tree.
   * @param {function} setParentData - State setter function for updating parent data related to expedients.
   * @param {function} setExpedientName - State setter function for updating the name of the selected expedient.
   * @param {function} getTreeExpedientByDomainCallback - Callback function to fetch the expedient tree data by domain.
   * @param {object} backgroundById - The background data object used to fetch the expedient tree.
   * @param {function} setIsCheckedExpedient - State setter function for managing the checked state of expedients.
   * @param {function} setShowTrdLevelsOrdenation - State setter function for controlling the visibility of TRD levels ordination.
   * @returns {void}
   */
  const handleOnSelectExpedient = (original) => {
    const { uuid, name } = original;
    if (isProcessBatch === false) {
      setRecordShowExpTree(false);
      setParentData({ id: 0, level: enumLevelTrd.BACKGROUND });
      setExpedientName(name);
      getTreeExpedientByDomainCallback(backgroundById, uuid);
      setShowTrdLevelsOrdenation(false);
      setAdvanced(1);
    } else {
      setRecordShowExpTree(false);
      setParentData({ id: 0, level: enumLevelTrd.BACKGROUND });
      setExpedientName(name);
      getTreeExpedientByDomainCallback(backgroundById, uuid);
      setShowTrdLevelsOrdenation(true);
    }
  };

  /**
   * Handles setting the path for a selected expedient based on user interaction.
   * @param {Event} eventSelectExpedient - The event object triggered by selecting the expedient.
   * @param {Object} original - The original expedient object containing 'name' and 'uuid' properties.
   * @param {string} original.name - The name of the expedient.
   * @param {string} original.uuid - The UUID of the expedient.
   * @returns {void}
   */
  const handleOnSetPath = (eventSelectExpedient, original) => {
    const targetCheckbox = eventSelectExpedient.target.checked;
    const { name, uuid } = original;

    if (targetCheckbox === true) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: `${i18n.t("trdExpedientListSetPathAlert")} "${name}" ${i18n.t(
          "trdExpedientListSetPathAlert2"
        )} "${recordNumber}"${i18n.t("trdExpedientListSetPathAlert3")}`,
        icon: "info",
        buttons: [
          i18n.t("createusers.createButton2"),
          i18n.t("modal.Done.footerButton"),
        ],
      }).then((willClose) => {
        if (willClose) {
          setExpedientPath(expedientPathSet, uuid).then((response) => {
            if (response.status === 201) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("trdExpedientListSetPathAlert4"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then((willAcept) => {
                if (willAcept) {
                  getPathSetExpedient(recordUuid).then((response) => {
                    const infoParentPath = response.data;
                    if (isNullOrUndefined(infoParentPath) === false) {
                      if (Object.keys(infoParentPath.path).length > 0) {
                        sethasExpedientFixedPath(true);
                        setExpedientSetPath(infoParentPath.path);
                        setParentData({
                          id: infoParentPath.expedient_uuid,
                          level: enumLevelTrd.EXPEDIENT,
                        });
                        getTreeExpedientByDomainCallback(
                          backgroundById,
                          infoParentPath.expedient_uuid
                        );
                        setShowTrdLevelsOrdenation(false);
                      }
                    }
                  });
                }
              });
            } else {
              showAlertServiceError();
            }
          });
        } else {
          eventSelectExpedient.preventDefault();
          eventSelectExpedient.target.checked = false;
        }
      });
    }
  };

  /**
   * Array of columns configuration for expedients table.
   * @type {Array<Object>}
   */
  const columnsExpedients = [
    {
      Header: i18n.t("trdExpedientList1"),
      accessor: "code",
      Cell: ({ original }) => {
        let codeValue = original.code;
        const expedientCode = codeValue.slice(codeValue.lastIndexOf("-") + 1);
        return (
          <div className="d-flex justify-content-center">
            <Button
              outline
              className="mb-2 mr-2 btn-pill btn-dashed"
              color="info"
              disabled={original.status === enumsRecordExpedientList.EXPEDIENT_CLOSED_STATUS}
              onClick={() => handleOnSelectExpedient(original)}
            >
              <span className="badge-pill text-bold">{expedientCode}</span>
            </Button>
          </div>
        );
      },
    },
    {
      Header: i18n.t("trdExpedientList2"),
      accessor: "name",
    },
    {
      Header: i18n.t("trdExpedientList3"),
      accessor: "created_at",
      Cell: (row) => {
        let valueFinal = getDateFormat2(new Date(row.value));
        return <p>{valueFinal}</p>;
      },
    },
    {
      Header: i18n.t("trdExpedientList4"),
      accessor: "subject",
    },
    {
      Header: i18n.t("trdExpedientList5"),
      id: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let statusFinal = "";
        if (value === enumsRecordExpedientList.EXPEDIENT_OPEN_STATUS) {
          statusFinal = (
            <div className="ml-auto badge badge-success">
              {i18n.t("trdExpedientList9")}
            </div>
          );
        } else {
          statusFinal = (
            <div className="ml-auto badge badge-danger">
              {i18n.t("trdExpedientList10")}
            </div>
          );
        }

        return <span className="d-block text-center">{statusFinal}</span>;
      },
    },
    {
      Header: i18n.t("trdExpedientList6"),
      accessor: "close_date",
      Cell: (row) => {
        let valueFinal = getDateFormat2(new Date(row.value));
        return row.original.status === enumsRecordExpedientList.EXPEDIENT_CLOSED_STATUS ? (
          <span>{valueFinal}</span>
        ) : (
          <span>{""}</span>
        );
      },
    },
  ];

  /**
   * Defines a column configuration for setting expedient path based on certain conditions.
   * @param {boolean} SETPATHEXPEDIENT - Indicates if the expedient path is set.
   * @param {string} formUuid - UUID of the form.
   * @param {boolean} USERTYPE - Indicates the type of user.
   * @param {function} i18n.t - Translation function for internationalization.
   * @param {function} handleOnSetPath - Function to handle setting the expedient path.
   * @returns {object} The column configuration object for setting expedient path.
   */
  let validatedColumns = columnsExpedients;
  let columnSetExpedientPath = null;

  if (
    (isNullOrUndefined(SETPATHEXPEDIENT) === false &&
      SETPATHEXPEDIENT.includes(formUuid) === true &&
      isNullOrUndefined(USERTYPE) === false) ||
    USERTYPE === enumsTypeUser.ADMIN
  ) {
    columnSetExpedientPath = {
      Header: i18n.t("trdExpedientListSetPath"),
      accessor: "structure",
      Cell: ({ original }) => {
        return (
          <div className="d-block w-100 text-center">
            <input
              id={original.uuid}
              name={original.uuid}
              type="checkbox"
              tag={CustomInput}
              disabled={original.status === enumsRecordExpedientList.EXPEDIENT_CLOSED_STATUS}
              onChange={(eventSelectExpedient) =>
                handleOnSetPath(eventSelectExpedient, original)
              }
            />
          </div>
        );
      },
    };
  }

  /**
   * Combines specified columns based on certain conditions to create validated columns for expedients.
   * @param {string} pathConfiguration - The path configuration string.
   * @param {boolean} isProcessBatch - Indicates whether the process is a batch process.
   * @param {boolean} isProcess - Indicates whether the process is a single process.
   * @param {string} isFreeTask - The type of task being checked against the enumeration of task types.
   * @returns {Array} An array containing validated columns for expedients.
   */
  if (
    pathConfiguration !== "" &&
    isProcessBatch === false &&
    isProcess === false &&
    isFreeTask !== enumsTaskType.FREE_TASK &&
    columnSetExpedientPath !== null
  ) {
    validatedColumns = columnsExpedients.concat(columnSetExpedientPath);
  }

  /**
   * Executes a side effect when the 'pathConfiguration' dependency changes.
   * Sets the expedient path configuration based on the current values.
   * @param {string} pathConfiguration - The UUID of the selected path configuration.
   *   Determines the series and subseries associated with the expedient path.
   * @param {string} formUuid - The UUID of the form related to the expedient path.
   *   Identifies the form within the path configuration.
   * @param {string} recordUuid - The UUID of the record linked to the expedient path.
   *   Specifies the record to which the path configuration is associated.
   * @param {function} setExpedientPathSet - State setter function to update the expedient path settings.
   *   Sets the form UUID, record UUID, and series/subseries UUID based on the path configuration.
   * @returns {void}
   */
  useEffect(() => {
    if (pathConfiguration !== "") {
      setExpedientPathSet({
        form_uuid: formUuid,
        record_uuid: recordUuid,
        serie_subserie_uuid: pathConfiguration,
      });
    }
  }, [pathConfiguration]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Determine the background ID based on conditions.
   * @param {string} pathConfiguration - The path configuration value.
   * @param {string} backgroudByPath - The background value by path.
   * @param {string} backgroundId - The background ID value.
   * @returns {string} - The determined background ID.
   */
  useEffect(() => {
    if (pathConfiguration === "") {
      setBackgroundById(backgroundId);
    } else {
      setBackgroundById(backgroudByPath);
    }
  }, [pathConfiguration]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React effect that initializes the `structureTrdExpedient` state to an empty array.
   * @returns {void}
   */
  useEffect(() => {
    setStructureTrdExpedient([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Cleanup effect to hide TrdLevelsOrdenation component when the component unmounts.
   * Sets 'showTrdLevelsOrdenation' to false on unmount.
   * @return {void}
   */
  useEffect(() => {
    //return () => setShowTrdLevelsOrdenation(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (showTrdLevelsOrdenation === true) {
    return (
      <TrdLevelsOrdenation
        structureTrdExpedient={structureTrdExpedient}
        recordUuid={recordUuid}
        isFreeTask={isFreeTask}
        isProcess={isProcess}
        isProcessBatch={isProcessBatch}
      />
    );
  } else {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <ReactTable
            data={listExpedients}
            columns={[...validatedColumns]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            loading={loadingExpedientList}
            loadingText={i18n.t("loading")}
            showPageJump={false}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            pageSize={listExpedients.length}
            defaultPageSize={pagination.per_page}
            noDataText={nodataMessage}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

RecordExpedientList.propTypes = {
  recordNumber: PropTypes.string.isRequired,
  recordUuid: PropTypes.string.isRequired,
  formUuid: PropTypes.string.isRequired,
  isFreeTask: PropTypes.number.isRequired,
  isProcess: PropTypes.bool.isRequired,
  isProcessBatch: PropTypes.bool.isRequired,
  nodataMessage: PropTypes.string.isRequired,
  listExpedients: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  loadingExpedientList: PropTypes.bool.isRequired,
};

export default RecordExpedientList;
