import React, { useRef, useState, useEffect, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Button, CardBody, Col, Row } from "reactstrap";
import {
  convertToPixels,
  defaultStickerPosition,
  fontOptions,
  fontSizeOptions,
  initialHeightMM,
} from "utils/stickerInitialPosition";
import { loaderElement } from "utils/loaderElement";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";
import RadioButtonBoxSticker from "./RadioButtonBoxSticker";
import ConfigPositionSticker from "./ConfigPositionSticker";
import Sticker from "./Sticker";
import i18n from "locales/i18n";

const RecordSticker = () => {
  const printStickerRef = useRef();
  const promiseResolveRef = useRef(null);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [showConfigPosition, setShowConfigPosition] = useState(false);
  const [initialPositionSticker, setInitialPositionSticker] = useState(
    defaultStickerPosition
  );
  const [isLoadingStickerInfo, setIsLoadingStickerInfo] = useState(false);
  const [isAutoHeight, setIsAutoHeight] = useState(false);
  const [localFontFamily, setLocalFontFamily] = useState(fontOptions[0].value);
  const [localFontSize, setLocalFontSize] = useState(fontSizeOptions[0].value);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoadingSticker, setIsLoadingSticker] = useState(false);

  /**
   * Generates the styles for stickers based on their initial positions, font family, and font size.
   * @param {Array} initialPositionSticker - An array of objects representing the initial positions of stickers.
   * @param {Object} initialPositionSticker[].top - The top position of a sticker.
   * @param {Object} initialPositionSticker[].left - The left position of a sticker.
   * @param {string} localFontFamily - The font family to be used for the stickers.
   * @param {number} localFontSize - The font size to be used for the stickers.
   * @returns {Object} An object containing the CSS custom properties for the stickers.
   */
  const stickerStyles = {
    "--sticker-top-left-top": `${initialPositionSticker[0].top}px`,
    "--sticker-top-left-left": `${initialPositionSticker[0].left}px`,

    "--sticker-top-right-top": `${initialPositionSticker[1].top}px`,
    "--sticker-top-right-left": `${initialPositionSticker[1].left}px`,

    "--sticker-middle-left-top": `${initialPositionSticker[2].top}px`,
    "--sticker-middle-left-left": `${initialPositionSticker[2].left}px`,

    "--sticker-middle-right-top": `${initialPositionSticker[3].top}px`,
    "--sticker-middle-right-left": `${initialPositionSticker[3].left}px`,

    "--sticker-bottom-left-top": `${initialPositionSticker[4].top}px`,
    "--sticker-bottom-left-left": `${initialPositionSticker[4].left}px`,

    "--sticker-bottom-right-top": `${initialPositionSticker[5].top}px`,
    "--sticker-bottom-right-left": `${initialPositionSticker[5].left}px`,

    "--sticker-font-family": localFontFamily,
    "--sticker-font-size": `${localFontSize}px`,
  };

  /**
   * Handles the change in position by updating the selected position state.
   * @param {Object} position - The new position object to set as the selected position.
   */
  const handlePositionChange = (position) => {
    setSelectedPosition(position);
  };

  /**
   * Handles the configuration of the position by setting the state to show the configuration options
   * and selecting the "personalized" position.
   * @returns {void}
   */
  const handleOnConfigPosition = () => {
    setShowConfigPosition(true);
  };

  /**
   * handlePrint - A function to handle printing a React component using useReactToPrint hook.
   * @param {Object} options - The options object for the useReactToPrint hook.
   * @param {Function} options.content - A function that returns a reference to the component to be printed.
   * @param {Function} options.onBeforeGetContent - A function that returns a promise, setting a reference to the resolve function and indicating the start of the printing process.
   * @param {Function} options.onAfterPrint - A function to reset the reference and indicate the end of the printing process.
   */
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  /**
   * useEffect hook to load saved position, font family, and font size from localStorage.
   * This useEffect hook retrieves saved data from localStorage and updates the state accordingly.
   * If there is saved data for position, font family, or font size, it sets the state with this data.
   * Otherwise, it sets the state with default values.
   * @param {Function} setInitialPositionSticker - Function to set the initial position of the sticker.
   * @param {Function} setLocalFontFamily - Function to set the local font family.
   * @param {Function} setLocalFontSize - Function to set the local font size.
   * @param {Object} defaultStickerPosition - The default position of the sticker.
   * @param {Array} fontOptions - Array of available font options.
   * @param {Array} fontSizeOptions - Array of available font size options.
   */
  useEffect(() => {
    const savedDataPosition = JSON.parse(localStorage.getItem("positionData"));
    const savedFontFamily = localStorage.getItem("fontFamily");
    const savedFontSize = localStorage.getItem("fontSize");

    if (savedDataPosition) {
      setInitialPositionSticker(savedDataPosition);
    } else {
      setInitialPositionSticker(defaultStickerPosition);
    }

    if (savedFontFamily) {
      setLocalFontFamily(savedFontFamily);
    } else {
      setLocalFontFamily(fontOptions[0].value);
    }

    if (savedFontSize) {
      setLocalFontSize(parseInt(savedFontSize, 10));
    } else {
      setLocalFontSize(fontSizeOptions[0].value);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect hook to calculate and set auto height state based on sticker dimensions.
   * @function useEffect
   * @memberof component
   * @param {number} initialHeightMM - Initial height in millimeters.
   * @param {number} convertToPixels - Conversion factor from millimeters to pixels.
   * @param {boolean} setIsAutoHeight - Setter function to update auto height state.
   */
  useEffect(() => {
    const initialHeight = initialHeightMM * convertToPixels;
    const printSticker = printStickerRef.current;
    if (printSticker) {
      const stickerStyles = window.getComputedStyle(printSticker);
      const stickerHeight = parseFloat(stickerStyles.height);
      if (stickerHeight > initialHeight) {
        setIsAutoHeight(true);
      } else if (stickerHeight < initialHeight) {
        setIsAutoHeight(false);
      }
    }
  }, []);

  /**
   * Effect hook that triggers when the `isPrinting` state changes.
   * If `isPrinting` is true and `promiseResolveRef.current` exists,
   * it calls the function referenced by `promiseResolveRef.current`.
   * @param {boolean} isPrinting - The current state indicating if printing is active.
   * @param {object} promiseResolveRef - A ref object containing the resolve function of a promise to be called when printing is active.
   */
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  if (showConfigPosition === true) {
    return (
      <ConfigPositionSticker
        initialPositionSticker={initialPositionSticker}
        setInitialPositionSticker={setInitialPositionSticker}
        setShowConfigPosition={setShowConfigPosition}
        setSelectedPosition={setSelectedPosition}
        localFontFamily={localFontFamily}
        setLocalFontFamily={setLocalFontFamily}
        localFontSize={localFontSize}
        setLocalFontSize={setLocalFontSize}
      />
    );
  } else {
    return (
      <Fragment>
        {loaderElement(isLoadingSticker)}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <CardBody>
            <Alert color="info">
              <span className="pr-2 d-block mb-2">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                <span className="font-weight-bold">
                  {i18n.t("modal.DoneError.header")}{" "}
                </span>
                {i18n.t("sticker.printAlert1")}
              </span>
              <span>{i18n.t("sticker.printAlert2")}</span>
            </Alert>
            <Row>
              <Col sm={2}>
                <RadioButtonBoxSticker
                  selectedPosition={selectedPosition}
                  setSelectedPosition={setSelectedPosition}
                  onPositionChange={handlePositionChange}
                />
                <Button className="mt-2" onClick={handleOnConfigPosition}>
                  {i18n.t("integration.requestConfigButton")}
                </Button>
              </Col>
              <Col sm={10}>
                <div id="test" ref={printStickerRef} style={stickerStyles}>
                  <Sticker
                    selectedPosition={selectedPosition}
                    isLoadingStickerInfo={isLoadingStickerInfo}
                    setIsLoadingStickerInfo={setIsLoadingStickerInfo}
                    isAutoHeight={isAutoHeight}
                    isLogoLoaded={isLogoLoaded}
                    setIsLogoLoaded={setIsLogoLoaded}
                    isPrinting={isPrinting}
                    setIsLoadingSticker={setIsLoadingSticker}
                  />
                </div>
                <Button
                  className="col-mt-3 mt-2 ml-1  btn-icon"
                  size="sm"
                  color="cyan"
                  onClick={() => {
                    handlePrint(null, () => printStickerRef.current);
                  }}
                  disabled={isLoadingSticker}
                >
                  <i className="pe-7s-print btn-icon-wrapper"> </i>
                  {i18n.t("recordSticker.print")}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </CSSTransitionGroup>
      </Fragment>
    );
  }
};

export default RecordSticker;
