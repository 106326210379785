import React from 'react'
import DocumentaryTypes from './DocumentaryTypes'
import { DocumentaryContextProvider } from "contextAPI/DocumentaryTypesContext"

export default () => {
	return (
		<DocumentaryContextProvider>
			<DocumentaryTypes />
		</DocumentaryContextProvider>
	)
}