import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Card, CardBody, CardFooter } from "reactstrap";
import { useParams } from "react-router-dom";
import { useTrdContext } from "contextAPI/TrdContext";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enumsCreateExpedient } from "utils/enums";
import { initialPaginationExpedientTree } from "utils/initialPaginationsConfig";
import ButtonCreateExpedient from "components/atoms/ButtonCreateExpedient";
import ButtonBackTrd from "components/atoms/ButtonBackTrd";
import ButtonDocumentalOrdination from "components/atoms/ButtonDocumentalOrdination";
import TrdExpedientList from "components/organism/TrdExpedientList";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";

const TrdExpedient = () => {
  const { id, backgroundId } = useParams();
  const [listExpedients, setListExpedients] = useState([]);
  const [pagination, setPagination] = useState(initialPaginationExpedientTree);
  const [totalPages, setTotalPages] = useState(0);
  const [sectionName, setSectionName] = useState("");
  const { getExpedients, getTreeByDomain } = useTrd();
  const {
    setExpedientParent,
    setFoldersId,
    foldersId,
    isLoading,
    setStructureTrd,
    reStructureData,
  } = useTrdContext();

  /**
   * Fetches tree data by domain and updates the component state.
   * @param {Function} - getTreeByDomainCallback
   * @return {Void}
   */
  const getTreeByDomainCallback = useCallback(() => {
    getTreeByDomain()
      .then((response) => {
        if (response.status === 200) {
          setStructureTrd(reStructureData(response.data.data));
          let elements = response.data.data;
          // eslint-disable-next-line
          const nameElements = elements.map((trdElement) => {
            if (trdElement.level === 0 && trdElement.id === backgroundId)
              return `${trdElement.label} /... / `;

            if (
              trdElement.level === 1 &&
              trdElement.parentId === backgroundId &&
              trdElement.background_parent_id === backgroundId &&
              trdElement.id === foldersId.sectionId
            )
              return `${trdElement.label} / `;

            if (
              (trdElement.level === 3 || 4) &&
              trdElement.background_parent_id === backgroundId &&
              trdElement.id === id
            )
              return `${trdElement.label}`;
          });
          setSectionName(nameElements);
        }
      })
      .catch((error) => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches expedients based on search criteria.
   * @param {Function} - getExpedients
   * @returns void
   */
  const getExpedientsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    getExpedients(page, per_page, id, backgroundId)
      .then((response) => {
        setListExpedients(response.data.items);
        setTotalPages(response.data.pages);
      })
      .catch((error) => {});
  }, [pagination, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Sets the expedient parent ID and folders ID for the component.
   * @param {string} id - The expedient parent ID.
   * @param {string} backgroundId - The background ID.
   */
  useEffect(() => {
    setExpedientParent(id);
    setFoldersId({
      ...foldersId,
      backgroundId: backgroundId,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Calls the 'getTreeByDomainCallback' function to fetch the tree data.
   * This effect will be triggered whenever the 'getTreeByDomainCallback' function changes.
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  /**
   * Calls the 'getExpedientsBySearch' function to fetch expedients based on search criteria.
   * This effect will be triggered whenever the 'getExpedientsBySearch' function changes.
   */
  useEffect(() => {
    getExpedientsBySearch();
  }, [getExpedientsBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        className="scrollingContainer"
      >
        <Alert className="mbg-3 " color="info" isOpen={true}>
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          {i18n.t("trd.alertExpedient")}: <strong>{sectionName}</strong>
        </Alert>

        <Card>
          <CardFooter className="ml-auto">
            <ButtonBackTrd />
            <ButtonDocumentalOrdination id={id} backgroundId={backgroundId} />
            &nbsp;&nbsp;
            <ButtonCreateExpedient
              expedientComeFrom={enumsCreateExpedient.TRD_CONFIG}
            />
          </CardFooter>
          <CardBody>
            <TrdExpedientList
              ultimateLevel={id}
              listExpedients={listExpedients}
              pagination={pagination}
              totalPages={totalPages}
              backgroundId={backgroundId}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default TrdExpedient;
