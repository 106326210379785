import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { CardBody } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import ReactTable from "react-table";
import useRoles from "hooks/useRoles";
import ButtonActionsRoles from "components/atoms/ButtonActionsRoles";
import i18n from "locales/i18n";

const UserListRolesTable = (props) => {
  const {
    listUsers,
    pagination,
    setPagination,
    isLoadingRolesList,
    totalPages,
    setIsLoadingDeleteRol,
  } = props;
  const { deleteRole } = useRoles();

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={listUsers}
            columns={[
              {
                columns: [
                  {
                    Header: i18n.t("createRoles.Table.tr1"),
                    accessor: "name",
                  },
                  {
                    Header: i18n.t("createRoles.Table.tr2"),
                    accessor: "user_name",
                  },
                  {
                    Header: i18n.t("createRoles.Table.tr3"),
                    accessor: "created_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <span>{valueFinal}</span>;
                    },
                  },
                  {
                    Header: i18n.t("createRoles.Table.tr4"),
                    accessor: "updated_at",
                    Cell: ({ value }) => {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <span>{valueFinal}</span>;
                    },
                  },
                ],
              },
              {
                columns: [
                  {
                    Header: i18n.t("buttonActions.title"),
                    accessor: "actions",
                    Cell: (row) => {
                      return (
                        <ButtonActionsRoles
                          rolUuid={row.original.uuid}
                          deleteRole={deleteRole}
                          setIsLoadingDeleteRol={setIsLoadingDeleteRol}
                          rolName={row.original.name}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            page={pagination.page - 1}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoadingRolesList}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserListRolesTable.propTypes = {
  listusers: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingRolesList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  setIsLoadingDeleteRol: PropTypes.func.isRequired,
};

export default UserListRolesTable;
