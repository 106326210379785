import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialMaxUsersConfig } from "utils/initialMaxUsersConfig";
import { enumsResponsibleOptions } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Label,
  Row,
  Col,
  Alert,
  CardFooter,
} from "reactstrap";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import useWorkFlow from "hooks/useWorkFlow";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ModalProcessResponsibles = (props) => {
  const {
    showModal,
    handleOnClose,
    usersDinamicEdit,
    selectedOption,
    setShowModal,
    isError: isErrorSelectResponsible,
    setIsError: setIsErrorSelectResponsible,
    responsibleOption,
    isLoading: isLoadingSelectResponsible,
    setIsLoading: setIsLoadingSelectResponsible,
    responsibleList,
    workflowid,
    stepUuid,
    listReal,
  } = props;

  const [isRendered, setIsRendered] = useState(true)
  const [addMember, setAddMember] = useState({
    responsible_uuid: "",
  });
  const [addMembersList, setAddMembersList] = useState({
    responsible_list: [],
  });
  const [isErrorEmptyProcessResponsible, setIsErrorEmptyProcessResponsible] = useState(false);
  const [showMaxUserResponsibles, setShowMaxUserResponsible] = useState(false)
  const { editCurrentAssignedById, editListAssignedById } = useWorkFlow();
  const [numberOfMembers] = useState(initialMaxUsersConfig);
  const sizelist = addMembersList.responsible_list.length;

  let labelContentSelectUsers = null;
  let alertMessage = null;
  let labelContent = null;
  let message = null;
  let noOptionsMessage = null;
  let isMulti = null;
  let options = null;
  let renderAlertErrorResponsibleOnly = null;
  let renderAlertErrorSelectResponsible = null;
  let loadingSpinerProcessResponsible = null;

  /**
   * Determines the loading spinner to display based on the `isLoadingSelectResponsible` state.
   * @param {boolean} isLoadingSelectResponsible - A boolean indicating if the component is in a loading state.
   * @returns {JSX.Element|null} - A JSX element containing the loading spinner if `isLoadingSelectResponsible` is true, otherwise null.
   */
  if (isLoadingSelectResponsible === true) {
    loadingSpinerProcessResponsible = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Sets the value of `message` based on the value of `responsibleOption`.
   * @param {string} responsibleOption - The option indicating the responsibility status.
   * @param {Object} enumsResponsibleOptions - An object containing possible responsible options.
   * @param {string} enumsResponsibleOptions.ONLY_RESPONSIBLE - The string indicating the "only responsible" option.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {string} - The translated message based on the value of `responsibleOption`.
   */
  if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE) {
    message = i18n.t("createWorkflow.config.listResponsibleSelects");
  } else {
    message = i18n.t("createWorkflow.config.multipleResponsible");
  }

  /**
   * Sets the value of `alertMessage` based on the value of `responsibleOption`.
   * @param {string} responsibleOption - The option indicating the responsibility status.
   * @param {Object} enumsResponsibleOptions - An object containing possible responsible options.
   * @param {string} enumsResponsibleOptions.ONLY_RESPONSIBLE - The string indicating the "only responsible" option.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {string} - The translated alert message based on the value of `responsibleOption`.
   */
  if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE) {
    alertMessage = i18n.t("createWorkflow.config.select.responsible.span");
  } else {
    alertMessage = i18n.t("createWorkflow.config.stepsResponsibleLegendList1");
  }

  /**
   * Sets the value of `labelContent` based on the value of `responsibleOption`.
   * @param {string} responsibleOption - The option indicating the responsibility status.
   * @param {Object} enumsResponsibleOptions - An object containing possible responsible options.
   * @param {string} enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST - The string indicating the "only responsible list" option.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {string} - The translated label content based on the value of `responsibleOption`.
   */
  if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST) {
    labelContent = i18n.t("createWorkflow.config.listResponsibleSelectsList");
  } else {
    labelContent = i18n.t("createWorkflow.config.select.responsible");
  }

  /**
   * Renders an error alert message if `isErrorEmptyProcessResponsible` is true.
   * @param {boolean} isErrorEmptyProcessResponsible - A boolean indicating if there is a second error.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {JSX.Element|null} - A JSX element containing the error alert message, or null if there is no error.
   */
  if (isErrorEmptyProcessResponsible === true) {
    renderAlertErrorSelectResponsible = (
      <span className="text-danger small">{i18n.t("WorkFlowEdit.List")}</span>
    )
  };

  /**
   * Renders an error alert message if there is an error.
   * @param {boolean} isErrorSelectResponsible - A boolean indicating if there is an error.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {JSX.Element|null} - A JSX element containing the error alert message, or null if there is no error.
   */
  if (isErrorSelectResponsible === true) {
    renderAlertErrorResponsibleOnly = (
      <span
        className="text-danger small"
      >
        {message}
      </span>
    )
  };

  /**
   * Sets the label properties based on the values of `isErrorSelectResponsible` and `isErrorEmptyProcessResponsible`.
   * @param {boolean} isErrorSelectResponsible - A boolean indicating if there is an error.
   * @param {boolean} isErrorEmptyProcessResponsible - A boolean indicating if there is a second error.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @param {Object} labelError - The style object to be used when there is an error.
   * @param {Object} labelNoError - The style object to be used when there is no error.
   * @returns {Object} - An object containing the label properties.
   */
  if (isErrorSelectResponsible === true || isErrorEmptyProcessResponsible === true) {
    labelContentSelectUsers = i18n.t("taskReassign.users");
  } else {
    labelContentSelectUsers = i18n.t("taskReassign.users");
  }

  /**
   * Sets the value of `isMulti` based on the value of `responsibleOption`.
   * @param {string} responsibleOption - The option indicating the responsibility status.
   * @param {Object} enumsResponsibleOptions - An object containing possible responsible options.
   * @param {boolean} enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST - A boolean indicating if only responsible list is to be used.
   * @returns {boolean} - Returns `true` if `responsibleOption` is `ONLY_RESPONSIBLE_LIST`, otherwise `false`.
   */
  if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST) {
    isMulti = true;
  } else {
    isMulti = false;
  }

  /**
   * Sets the value of `options` based on the value of `showMaxUserResponsibles`.
   * @param {boolean} showMaxUserResponsibles - A boolean showMaxUserResponsibles indicating which option to select.
   * @param {Array} usersDinamicEdit - An array of options to be used when the showMaxUserResponsibles is false.
   * @returns {Array} - Returns `usersDinamicEdit` if `showMaxUserResponsibles` is `false`, otherwise returns an empty array.
   */
  if (showMaxUserResponsibles === false) {
    options = usersDinamicEdit;
  } else {
    options = [];
  }

  /**
   * Sets the value of `noOptionsMessage` based on the value of `showMaxUserResponsibles`.
   * @param {boolean} showMaxUserResponsibles - A boolean indicating which message to select.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @returns {string} - The translated message based on the value of the showMaxUserResponsibles.
   */
  if (showMaxUserResponsibles === true) {
    noOptionsMessage = i18n.t("createRoles.MembersNousersList");
  } else {
    noOptionsMessage = i18n.t("createRoles.MembersNousers");
  }

  /**
   * Closes the modal and resets various states and data.
   * This function is used to close the modal by setting `showModal` to false,
   * clear error states, reset the responsible UUID and responsible list,
   * and update other state variables as needed.
   * @function
   * @returns {void} - This function does not return any value.
   */
  const closeModalProcessResponsible = () => {
    setShowModal(false);
    setIsErrorSelectResponsible(false);
    setIsErrorEmptyProcessResponsible(false);
    setAddMember({
      ...addMember,
      responsible_uuid: "",
    });

    setAddMembersList({
      ...addMembersList,
      responsible_list: [],
    });
    setIsRendered(true)
    setShowMaxUserResponsible(false)
  };

  /**
   * Handles the change event when users are selected.
   * Depending on the responsibleOption, updates the addMember or addMembersList state and handles errors.
   * @param {object} selectedOption - The selected options (an array of objects).
   */
  const handleOnChangeUsers = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorSelectResponsible(true);
    } else {
      if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE) {
        setAddMember({
          ...addMember,
          responsible_uuid: selectedOption.value,
        });
        setIsErrorSelectResponsible(false);
      } else if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST) {
        setAddMembersList({
          ...addMembersList,
          responsible_list: selectedOption.map((user) => user.value),
        });
        setIsRendered(false)
        setIsErrorSelectResponsible(false);
        setIsErrorEmptyProcessResponsible(false);
      }
    }
  };

  /**
   * Checks if two arrays are equal by sorting them and comparing each element.
   * Returns true if the arrays are equal, otherwise false.
   * @returns {boolean} - True if the arrays are equal, false otherwise.
   */
  function areEqualResponsibles() {
    if (
      !Array.isArray(responsibleList) ||
      !Array.isArray(addMembersList.responsible_list)
    )
      return false;
    let sorted_a = [...responsibleList].sort();
    let sorted_b = [...addMembersList.responsible_list].sort();
    return (
      sorted_a.length === sorted_b.length &&
      sorted_a.every((user, index) => user === sorted_b[index])
    );
  }

  /**
   * Handles the submission of the form. It differs based on the responsibleOption:
   * - For responsibleOption 1, it updates a single user's responsible information.
   * - For responsibleOption 2, it updates a list of responsible users and performs error checks.
   *
   * @param {object} e - The event object.
   * @param {object} errors - The error object.
   */
  const handleOnSubmitResponsible = (eventSubmitResponsible) => {
    if (responsibleOption === enumsResponsibleOptions.ONLY_RESPONSIBLE) {
      if (addMember.responsible_uuid === "") {
        setIsErrorSelectResponsible(true);
        setIsLoadingSelectResponsible(false);
        eventSubmitResponsible.preventDefault();
        return;
      } else {
        setIsLoadingSelectResponsible(true);
        setIsErrorSelectResponsible(false);
        editCurrentAssignedById(workflowid, stepUuid, addMember)
          .then((response) => {
            if (response.status === 202) {
              const showAlertUserReasign = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("WorkFlowEdit.currentUser"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(() => {
                  window.location.reload();
                });
              };
              showAlertUserReasign();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingSelectResponsible(false);
            setShowModal(false);
          });
      }
    } else if (responsibleOption === "2") {
      if (addMembersList.responsible_list.length === 0 && isErrorSelectResponsible === false) {
        eventSubmitResponsible.preventDefault();
        setIsErrorEmptyProcessResponsible(true);
      } else if (
        addMembersList.responsible_list.length === 0 ||
        addMembersList.responsible_list.length === (numberOfMembers.minimumMembers - 1)
      ) {
        setIsErrorSelectResponsible(true);
        setIsLoadingSelectResponsible(false);
        eventSubmitResponsible.preventDefault();
        return;
      } else if (areEqualResponsibles(responsibleList, addMembersList.responsible_list) && isErrorSelectResponsible === false) {
        eventSubmitResponsible.preventDefault();
        const showAlertUserReasign = () => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("WorkFlowEdit.List"),
            icon: "warning",
            button: i18n.t("modal.Done.footerButton"),
          });
        };
        showAlertUserReasign();
      } else if (isErrorSelectResponsible === true) {
        eventSubmitResponsible.preventDefault()
        return;
      } else {
        setIsLoadingSelectResponsible(true);
        setIsErrorSelectResponsible(false);
        setIsErrorEmptyProcessResponsible(false);
        editListAssignedById(workflowid, stepUuid, addMembersList)
          .then((response) => {
            if (response.status === 202) {
              const showAlertUserReasign = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("WorkFlowEdit.currentUser"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                }).then(() => {
                  window.location.reload();
                });
              };
              showAlertUserReasign();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingSelectResponsible(false);
            setShowModal(false);
          });
      }
    }
  };

  /**
   * A useEffect hook that sets a showMaxUserResponsibles based on certain conditions.
   * - If isErrorSelectResponsible is true, the showMaxUserResponsibles is set to false.
   * - If listReal has a length of 50 and isRendered is true, the showMaxUserResponsibles is set to true.
   * - If sizelist exceeds numberOfMembers.limitNumberOfMembers and there is no error, the showMaxUserResponsibles is set to true.
   * - If sizelist is within the limit and isRendered is false, the showMaxUserResponsibles is set to false.
   */
  useEffect(() => {
    function isLimit() {
      if (listReal) {
        if (isErrorSelectResponsible === true) {
          return setShowMaxUserResponsible(false)
        }
        if (listReal.length === numberOfMembers.maximumMembers && isRendered === true) {
          return setShowMaxUserResponsible(true)
        }
        if (sizelist > numberOfMembers.limitNumberOfMembers && isErrorSelectResponsible === false) {
          return setShowMaxUserResponsible(true)
        }
        if (sizelist <= numberOfMembers.limitNumberOfMembers && isRendered === false) {
          return setShowMaxUserResponsible(false)
        }
      }
    }
    isLimit()
    // eslint-disable-next-line 
  }, [sizelist, listReal, isErrorSelectResponsible, showMaxUserResponsibles])

  return (
    <Modal
      isOpen={showModal}
      toggle={handleOnClose}
      className="modal-large-size"
      size="xl"
    >
      <ModalHeader>
        <Label>
          {labelContent}
        </Label>
      </ModalHeader>
      <ModalBody>
        <AvForm onSubmit={handleOnSubmitResponsible}>
          <Row>
            <Col md="12">
              <Alert className="mbg-3" color="info" isOpen={true}>
                <span className="pr-2">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                {alertMessage}
              </Alert>{" "}
              <AvGroup>
                <Label
                  for="users_uuid"
                  className={cx("", {
                    "labels-error": isErrorSelectResponsible === true || isErrorEmptyProcessResponsible === true,
                  })}
                >
                  {labelContentSelectUsers}
                </Label>
                <div>
                  <Select
                    id="users_uuid"
                    name="users_uuid"
                    classNamePrefix={cx("", {
                      select: isErrorSelectResponsible === true || isErrorEmptyProcessResponsible === true,
                    })}
                    className="select_users_scrollable"
                    placeholder={i18n.t("notifications.usersPlaceholder")}
                    closeMenuOnSelect={true}
                    isMulti={isMulti}
                    components={makeAnimated()}
                    defaultValue={listReal}
                    value={selectedOption}
                    options={options}
                    onChange={handleOnChangeUsers}
                    noOptionsMessage={() => noOptionsMessage}
                  />
                  {renderAlertErrorResponsibleOnly}

                  {renderAlertErrorSelectResponsible}
                </div>
              </AvGroup>
            </Col>
          </Row>
          <br />
          <CardFooter className="d-block text-right">
            <Button
              size="lg"
              onClick={closeModalProcessResponsible}
              className="col-mt-3 mr-3"
              color="gray"
            >
              {i18n.t("createusers.createButton2")}
            </Button>

            <Button
              type="submit"
              size="lg"
              disabled={isLoadingSelectResponsible}
              className="col-mt-3 mr-3"
              color="cyan"
            >
              {loadingSpinerProcessResponsible}
              {i18n.t("createWorflow.EditButton")}
            </Button>
          </CardFooter>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

ModalProcessResponsibles.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  usersDinamicEdit: PropTypes.array.isRequired,
  selectedOption: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  setIsErrorSelectResponsible: PropTypes.func.isRequired,
  responsibleOption: PropTypes.string.isRequired,
  isLoadingSelectResponsible: PropTypes.bool.isRequired,
  setIsLoadingSelectResponsible: PropTypes.func.isRequired,
  responsibleList: PropTypes.array.isRequired,
  workflowid: PropTypes.string.isRequired,
  stepUuid: PropTypes.string.isRequired,
  listReal: PropTypes.array.isRequired,
};

export default ModalProcessResponsibles;
