import React, { Fragment } from "react";
import { useSignatureContext } from "contextAPI/SignatureContext";
import AdminTemplate from "components/templates/AdminTemplate";
import SignatureForm from "components/organism/SignatureForm";
import SignatoriesForm from "components/organism/SignatoriesForm";
import i18n from "locales/i18n";
import { SIGN_ELECTRONICALLY, TYPE } from "constants/securityConst";
import { enumsTypeUser } from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import useLogin from "hooks/useLogin";
import { useParams } from "react-router-dom";
import AccessDenied from "components/molecules/Messages/Error/AccessDenied";

const ElectronicSignature = () => {
  const { formId } = useParams();
  const { showSignatoriesForm } = useSignatureContext();
  const { isLogged } = useLogin();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const ACCESS_SIGN_DOCUMENT = window.localStorage.getItem(SIGN_ELECTRONICALLY);
  let formToShow = null;
  let accessToSignElectronically = null;

  /**
   * Determines if the user has access to sign documents electronically based on their login status,
   * the presence of required permissions, and user type.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} ACCESS_SIGN_DOCUMENT - The access permission for signing documents.
   * @param {string} formId - The ID of the form being accessed.
   * @param {string} USERTYPE - The type of user accessing the document.
   * @param {boolean} accessToSignElectronically - The result of the access check.
   */
  if (
    (isLogged === true &&
      isNullOrUndefined(ACCESS_SIGN_DOCUMENT) === false &&
      ACCESS_SIGN_DOCUMENT.includes(formId)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessToSignElectronically = true;
  } else {
    accessToSignElectronically = false;
  }

  /**
   * Conditionally selects a form component to display based on the value of `showSignatoriesForm`.
   * @param {boolean} showSignatoriesForm - A boolean flag that determines which form component to display.
   *   - When `true`, the `SignatoriesForm` component is selected.
   *   - When `false`, the `SignatureForm` component is selected.
   * @returns {JSX.Element|null} The JSX element representing the selected form component, or `null` if neither form is to be shown.
   */
  if (showSignatoriesForm === true) {
    formToShow = <SignatoriesForm />;
  } else {
    formToShow = <SignatureForm />;
  }

  if (accessToSignElectronically === true) {
    return (
      <Fragment>
        <AdminTemplate
          heading={i18n.t("signature.heading")}
          subheading={i18n.t("signature.subHeading")}
          icon="pen"
        >
          {formToShow}
        </AdminTemplate>
      </Fragment>
    );
  } else {
    return <AccessDenied />;
  }
};

export default ElectronicSignature;
