import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Button, Label, CardFooter, Spinner } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { regexNameList, regexSubjectExpedient } from "utils/regexExpressions";
import { isNullOrUndefined } from "utils/validations";
import { enumLevelTrd, enumsCreateExpedient, enumsCreateExpedientForm, enumsStatusExpedient } from "utils/enums";
import ReactTooltip from "react-tooltip";
import useClassification from "hooks/useClassification";
import i18n from "locales/i18n";

const CreateExpedientForm = (props) => {
  const { formUuid, recordUuid, expedientComeFrom, handleOnClose } = props;
  const { classificationRequest } = useClassification();
  const {
    isByRecord,
    createExpedientForm,
    setCreateExpedientForm,
    setIsLoading,
    isLoading,
  } = useTrdContext();
  let loadingCreateExpedient = null;

  /**
   * Renders a spinner component if loading state is true.
   * @param {boolean} isLoading - A boolean indicating whether loading is in progress.
   * @returns {JSX.Element|null} The spinner component if isLoading is true, otherwise null.
   */
  if (isLoading === true) {
    loadingCreateExpedient = (
      <Spinner size="sm" color="secondary" type="grow" />
    );
  }

  /**
   * Handles the submission of the expedient creation form.
   * If there are no validation errors, it triggers a classification request.
   * If there are validation errors, it cancels the submission and sets isLoading to false.
   * Finally, it closes the form.
   * @param {Event} eventCreateExpedient - The submit event object.
   * @param {Array} errors - An array of validation errors (if any).
   */
  const handleOnSubmitCreateExpedient = (_, errors) => {
    setIsLoading(true);
    if (errors.length === 0) {
      classificationRequest(5, createExpedientForm, isByRecord);
      setCreateExpedientForm({
        name: "",
        subject: "",
        status: enumsStatusExpedient.OPEN,
        level: enumLevelTrd.EXPEDIENT,
      });
    } else {
      setIsLoading(false);
      return;
    }
    handleOnClose();
  };

  /**
   * Handles the onChange event for creating an expedient by updating the corresponding form state.
   * @param {Event} eventCreateExpedient - The event object representing the change in the expedient creation form.
   * @param {object} eventCreateExpedient.target - The target element that triggered the event.
   * @param {string} eventCreateExpedient.target.name - The name attribute of the target element, used as a key to update the form state.
   * @param {string} eventCreateExpedient.target.value - The value entered or selected in the target element, which will be capitalized and assigned to the corresponding form field.
   * @param {function} setCreateExpedientForm - State setter function for updating the create expedient form state.
   * @param {object} createExpedientForm - The current state of the create expedient form.
   * @param {string} createExpedientForm[eventCreateExpedient.target.name] - The specific field in the create expedient form state to be updated.
   * @param {function} capitalizeText - A utility function to capitalize the text values.
   * @returns {void}
   */
  const handleOnChangeCreateExpedient = (eventCreateExpedient) => {
    setCreateExpedientForm({
      ...createExpedientForm,
      [eventCreateExpedient.target.name]: eventCreateExpedient.target.value,
    });
  };

  /**
   * Handles onBlur event for creating an expedient by updating the form state.
   * This function takes an event object triggered by onBlur event and extracts the value from it.
   * It then processes the value by removing extra whitespace and updates the corresponding field
   * in the createExpedientForm state.
   * @param {object} eventCreateExpedient - The event object triggered by onBlur event.
   * @param {string} eventCreateExpedient.target.value - The value associated with the event target.
   * @param {string} eventCreateExpedient.target.name - The name of the target element within the form.
   * @param {function} setCreateExpedientForm - State setter function for updating the createExpedientForm state.
   * @returns {void}
   */
  const handleOnBlurCreateExpedient = (eventCreateExpedient) => {
    const value = eventCreateExpedient.target.value;
    const wordsArray = value.split(" ");
    const filteredWordsArray = wordsArray.filter((word) => word.trim() !== "");
    const trimmedValue = filteredWordsArray.join(" ");
    setCreateExpedientForm({
      ...createExpedientForm,
      [eventCreateExpedient.target.name]: trimmedValue,
    });
  };

  /**
   * Handles the closing of the expedient form by resetting its state and performing any necessary cleanup.
   * 
   * This function calls `handleOnClose` to perform general closing operations and then resets the state of the expedient form by setting it to its initial values:
   * - `name`: An empty string
   * - `subject`: An empty string
   * - `status`: 1
   * - `level`: 5
   * 
   * The state reset ensures that the form is cleared and ready for a new entry or to be re-opened with default values.
   */
  const handleCloseExpedientForm = () => {
    handleOnClose();
    setCreateExpedientForm({
      name: "",
      subject: "",
      status: enumsStatusExpedient.OPEN,
      level: enumLevelTrd.EXPEDIENT,
    });
  };

  /**
   * Handles the key press event for creating an expedient.
   * Prevents the default behavior when the "Enter" key is pressed.
   * @param {KeyboardEvent} eventCreateExpedient - The keyboard event object.
   */
  const handleOnKeyPressCreateExpedient = (eventCreateExpedient) => {
    if (eventCreateExpedient.key === "Enter") {
      eventCreateExpedient.preventDefault();
    }
  };

  /**
   * Updates the state of `createExpedientForm` based on the provided parameters.
   * @param {string | null | undefined} formUuid - The UUID of the form.
   * @param {string | null | undefined} recordUuid - The UUID of the record.
   * @param {string | null | undefined} expedientComeFrom - Indicates the source of the expedient (enumsCreateExpedient.RECORDS or enumsCreateExpedient.TASK).
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(formUuid) === false &&
      isNullOrUndefined(recordUuid) === false &&
      isNullOrUndefined(expedientComeFrom) === false &&
      expedientComeFrom === enumsCreateExpedient.RECORDS
    ) {
      setCreateExpedientForm({
        ...createExpedientForm,
        form_uuid: formUuid,
        record_uuid: recordUuid,
        action: expedientComeFrom,
      });
    } else if (
      isNullOrUndefined(formUuid) === false &&
      isNullOrUndefined(recordUuid) === true &&
      isNullOrUndefined(expedientComeFrom) === false &&
      expedientComeFrom === enumsCreateExpedient.TASK
    ) {
      setCreateExpedientForm({
        ...createExpedientForm,
        form_uuid: formUuid,
        action: expedientComeFrom,
      });
    } else {
      setCreateExpedientForm({
        ...createExpedientForm,
        action: expedientComeFrom,
      });
    }
  }, [recordUuid, formUuid, expedientComeFrom]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleOnSubmitCreateExpedient}>
          <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
            {i18n.t("trd.Tooltip4")}
          </ReactTooltip>
          <AvGroup>
            <Label for="name" className="is-required">
              {i18n.t("trd.fromLabel2")}
            </Label>
            <AvField
              id="name"
              name="name"
              type="text"
              onChange={handleOnChangeCreateExpedient}
              onKeyPress={handleOnKeyPressCreateExpedient}
              onBlur={handleOnBlurCreateExpedient}
              data-tip
              data-for="name"
              validate={{
                pattern: {
                  value: regexNameList,
                  errorMessage: `${i18n.t("invalidField2")}`,
                },
                required: {
                  value: true,
                  errorMessage: `${i18n.t("trd.feedback3")}`,
                },
                minLength: {
                  value: enumsCreateExpedientForm.MIN_LENGTH_NAME,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
                maxLength: {
                  value: enumsCreateExpedientForm.MAX_LENGTH_NAME,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
              }}
              autoComplete="off"
              value={createExpedientForm.name}
            />
          </AvGroup>
          <AvGroup>
            <ReactTooltip
              id="subject_expedient"
              place="bottom"
              type="dark"
              effect="solid"
            >
              {i18n.t("trd.Tooltip13")}
            </ReactTooltip>
            <Label for="subject">{i18n.t("trd.fromLabel3")}</Label>
            <AvField
              id="subject"
              name="subject"
              type="text"
              onChange={handleOnChangeCreateExpedient}
              onKeyPress={handleOnKeyPressCreateExpedient}
              onBlur={handleOnBlurCreateExpedient}
              data-tip
              data-for="subject_expedient"
              validate={{
                pattern: {
                  value: regexSubjectExpedient,
                  errorMessage: `${i18n.t("invalidField2")}`,
                },
                minLength: {
                  value: enumsCreateExpedientForm.MIN_LENGTH_SUBJECT,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
                maxLength: {
                  value: enumsCreateExpedientForm.MAX_LENGTH_SUBJECT,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
              }}
              autoComplete="off"
              value={createExpedientForm.subject}
            />
          </AvGroup>

          <CardFooter className="d-block text-right">
            <Button
              size="lg"
              className="col-mt-3 mr-3"
              color="gray"
              onClick={handleCloseExpedientForm}
            >
              {i18n.t("createusers.createButton2")}
            </Button>

            <Button
              type="submit"
              size="lg"
              disabled={isLoading}
              className="col-mt-3 mr-3"
              color="cyan"
            >
              {loadingCreateExpedient}
              {i18n.t("createusers.createButton")}
            </Button>
          </CardFooter>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

CreateExpedientForm.propTypes = {
  formUuid: PropTypes.string.isRequired,
  recordUuid: PropTypes.string,
  expedientComeFrom: PropTypes.number.isRequired,
  handleOnClose: PropTypes.func.isRequired,
};

export default CreateExpedientForm;
