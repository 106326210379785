import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Alert,
} from "reactstrap";
import { getDateFormat } from "utils/getDateFormat";
import { getDateFormat2 } from "utils/getDateFormat2";
import { useParams, Link } from "react-router-dom";
import { VIEW_REGISTER, TYPE } from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import ButtonExportRecords from "components/molecules/ButtonExportRecords";
import { initialPaginationRecordListTable } from "utils/initialPaginationsConfig";
import {
  enumsActionsTypes,
  enumsFieldsTyData,
  enumsRecordDetailView,
  enumsTypeUser,
  recordStatus
} from "utils/enums";
import ReactTable from "react-table";
import useRecord from "hooks/useRecord";
import useForm from "hooks/useForm";
import i18n from "locales/i18n";
import swal from "sweetalert";

const RecordsListsTable = () => {
  const { getFields, getFormsById } = useForm();
  const { getRecords } = useRecord();
  const VIEWREGISTER = window.localStorage.getItem(VIEW_REGISTER);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationRecordListTable);
  const [totalPagesRecords, setTotalPagesRecords] = useState(0)
  const [search] = useState("");
  const [fields, setFields] = useState([]);
  const [count, setCount] = useState(0);
  const [records, setRecords] = useState([]);
  const [formDetail, setFormDetail] = useState({
    name: "",
    description: "",
  });

  let pageSizesRecords;

  /**
   * Determines the page size based on the length of the records array.
   * If the array has any records, `pageSizesRecords` is set to the length of the array.
   * Otherwise, `pageSizesRecords` is set to an empty array.
   * @param {Array} records - The array of records to check.
   * @returns {void}
   */
  if (records.length) {
    pageSizesRecords = records.length;
  } else {
    pageSizesRecords = [];
  };

  const [columnsTable, setColumnsTable] = useState([
    {
      Header: [i18n.t("recordList8")],
      accessor: "count_files",
      Cell: ({ row }) => (

        <div className="d-flex justify-content-center">
          <Button
            outline
            className="mb-2 mr-2 btn-pill btn-dashed"
            color="info"
            disabled
          >
            <span className="pr-2">
              <FontAwesomeIcon icon={faFile} />
            </span>
            <span className="badge-pill text-bold">{row.count_files}</span>
          </Button>
        </div>
      ),
    },
    {
      Header: [i18n.t("filteruser.button")],
      accessor: "status",

      Cell: ({ value }) => {
        let statusFinal = "";
        if (value === recordStatus.ACTIVE) {
          statusFinal = (
            <div className="ml-auto badge badge-success" >
              {i18n.t("filteruser.item2")}
            </div>
          );
        } else if (value === recordStatus.ANULLMENT) {
          statusFinal = (
            <div className="ml-auto badge badge-warning">
              {i18n.t("info.SignatureFilter3")}
            </div>
          );
        }
        return <p style={{ minWidth: "150px" }}>{statusFinal}</p>;
      },
    },
    {
      Header: [i18n.t("recordList9")],
      id: "link",
      accessor: "record",

      Cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          <Link to={`/record/detail/${row._original.uuid}/${id}`}>{row.link}</Link>
        </div>
      ),
    },

    {
      Header: [i18n.t("recordList10")],
      accessor: "created_at",

      Cell: ({ value }) => {
        let valueFinal = getDateFormat2(new Date(value));

        return <p>{valueFinal}</p>;
      },
    },
    {
      Header: [i18n.t("form.field47")],
      accessor: "updated_at",

      Cell: ({ value }) => {
        let valueFinal = getDateFormat2(new Date(value));

        return <p>{valueFinal}</p>;
      },
    },
    {
      Header: [i18n.t("createusers.label1")],
      accessor: "user_name",
    },
  ]);

  /**
   * Retrieves form details based on the user's type and sets the form details in the state.
   * This function determines the actions available to the user based on their user type.
   * If the user is an admin, all actions are allowed; otherwise, restricted actions are applied.
   * It then makes an API call to retrieve the form details and updates the `formDetail` state with the response.
   * @returns {void}
   */
  const getFormsByUuid = useCallback(() => {
    let actions = enumsActionsTypes.ALL_ACTIONS;
    if (USERTYPE === enumsTypeUser.ADMIN) {
      actions = enumsActionsTypes.ALL_ACTIONS;
    } else {
      actions = enumsActionsTypes.RESTRIC_USER;
    }
    getFormsById(id, actions).then((response) => {
      setFormDetail({
        name: response.data.name,
        description: response.data.description,
      });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches records based on the current page, per_page, and search parameters.
   * This function makes an API call to fetch records and updates the state with the retrieved data.
   * It manages the loading state during the operation, and handles various scenarios:
   * - Updates the records, count, and total pages if data is returned.
   * - Shows an alert if the records list is empty.
   * - Displays a service error alert if the response data is null or undefined.
   * @function
   * @returns {void}
   */
  const getRecordsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoading(true);
    getRecords(id, page, per_page, search)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setRecords(response.data.items);
          setCount(response.data.count);
          setTotalPagesRecords(response.data.pages);
          if (response.data.items.length === 0) {
            const showAlertRecordListEmpty = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("recordList.isEmpty"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            };
            showAlertRecordListEmpty();
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination, search]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * function callback obain field by search
   * @param {string} id - The ID parameter
   * @returns {void}p
   */
  const getFieldsBySearch = useCallback(
    (id) => {
      let actions = enumsActionsTypes.ALL_ACTIONS;
      if (USERTYPE === enumsTypeUser.ADMIN) {
        actions = enumsActionsTypes.ALL_ACTIONS;
      } else {
        actions = enumsActionsTypes.RESTRIC_USER;
      }
      let pagination_field = { page: 1, per_page: 1000 };
      const { page, per_page } = pagination_field;
      setIsLoading(true);
      getFields(page, per_page, id, actions, true)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setFields(response.data.items);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Processes the `fields` array to set up table columns dynamically.
   * This effect iterates through the `fields` array, checking if each column is active.
   * Depending on the column's data type, it formats the data accordingly.
   * The formatted column data is then added to the `columnsTable` state, which configures the table's columns.
   * - If the column's type is a date, it formats the date using `getDateFormat`.
   * - If the column's type is a time and date, it formats it using `getDateFormat2`.
   * - Inactive columns are skipped.
   * The function updates the `columnsTable` state by appending new column configurations.
   * @param {Array} fields - Array of field objects that define the table columns and their properties.
   * @returns {void}
   */
  useEffect(() => {
    fields.forEach((column) => {
      if (column.status === enumsRecordDetailView.ACTIVE_COLUMN) {
        let cellData = null;
        if (column.type_data === enumsFieldsTyData.DATE) {
          cellData = ({ value }) => {
            let valueFinal = getDateFormat(new Date(value));
            if (isNullOrUndefined(value) === false) {
              return <span>{valueFinal}</span>;
            } else {
              return <span>{""}</span>;
            }
          };
        } else if (column.type_data === enumsFieldsTyData.TIME_AND_DATE) {
          cellData = ({ value }) => {
            let valueFinal = getDateFormat2(new Date(value));
            if (isNullOrUndefined(value) === false) {
              return <sapan>{valueFinal}</sapan>;
            } else {
              return <span>{""}</span>;
            }
          };
        }
        setColumnsTable((prevState) => [
          ...prevState,
          {
            Header: column.label,
            accessor: column.uuid,
            Cell: cellData
          },
        ]);
      }
    });

    /**
     * Maps over the `columnsTable` array to generate an array of column configurations
     * for a table. Each column configuration includes `Header`, `accessor`, and `Cell` properties.
     * @param {Array} columnsTable - Array of column objects to configure the table.
     * @returns {Array} An array of column configurations.
     */
    columnsTable.map((column) => {
      return {
        Header: column.Header,
        accessor: column.accessor,
        Cell: column.Cell,
      };
    });
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Ontain records by Search
   * @returns {void}
   */
  useEffect(() => {
    getRecordsBySearch();
  }, [getRecordsBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * obtain get form by uuid
   * @returns {void}
   */
  useEffect(() => {
    getFormsByUuid();
  }, [getFormsByUuid]);

  /**
   * obtain fields by search
   * @returns {void}
   */
  useEffect(() => {
    getFieldsBySearch(id);
  }, [getFieldsBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditionally renders an informational alert based on user type and view permissions.
   * If the user is not an admin and the `id` is not included in `VIEWREGISTER`, an alert is displayed.
   * The alert contains a header and a notification message, both of which are translated using the i18n library.
   * @returns {JSX.Element|null} Returns an informational alert as a JSX element if the condition is met, or `null` otherwise.
   */
  if (USERTYPE !== enumsTypeUser.ADMIN && VIEWREGISTER.includes(id) === false) {
    return (
      <Alert color="info">
        <h4 className="alert-heading">
          {i18n.t("modal.DoneError.header")}
        </h4>
        <hr />
        <h6>{i18n.t("showNotification403")}</h6>
      </Alert>
    );
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardHeader className="card-header-lg">
                <div className="text-header font-weight-bold text-cyan ">
                  <h4>{formDetail.name}</h4>
                </div>
                <Col
                  lg="7"
                  md="8"
                  sm="12"
                  className="ml-auto"
                >
                  <Row className="row justify-content-end">
                    <ButtonExportRecords
                      records={records}
                      recordId={id}
                      filters={null}
                    />
                    <Row>
                      <Link to="/record/list/form">
                        <Button className="btn-icon ml-2 mr-2" color="cyan">
                          <FontAwesomeIcon
                            icon={faArrowAltCircleLeft}
                            className="mr-2"
                          />
                          {i18n.t("recordList1")}
                        </Button>
                      </Link>
                    </Row>
                  </Row>
                </Col>
              </CardHeader>
              <CardBody>
                <ReactTable
                  manual
                  className="-striped -highlight"
                  data={records}
                  columns={columnsTable}
                  pageSize={pageSizesRecords}
                  loading={isLoading}
                  noDataText={`${i18n.t("tableRowsEmpty")}`}
                  pages={totalPagesRecords}
                  previousText={`${i18n.t("previousText")}`}
                  nextText={`${i18n.t("nextText")}`}
                  showPageJump={false}
                  defaultPageSize={pagination.per_page}
                  pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
                  ofText={<span className="px-2">{i18n.t("ofText")}</span>}
                  onPageSizeChange={(per_page) => {
                    setPagination({ ...pagination, per_page: per_page });
                  }}
                  onPageChange={(page) => {
                    setPagination({ ...pagination, page: page + 1 });
                  }}
                />
              </CardBody>
              <CardFooter className="d-block text-right">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  {i18n.t("recordList11")} {count}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default RecordsListsTable;