import PropTypes from "prop-types";
import swal from "sweetalert";
import i18n from "locales/i18n";

/**
 * Display a custom alert with specified properties.
 *
 * @param {Object} props - The properties for the alert.
 * @param {string} props.title - The title of the alert.
 * @param {string} props.text - The text content of the alert.
 * @param {string} props.icon - The icon for the alert.
 * @param {string} props.button - The button text for the alert.
 * @returns {Promise}  
 */

export const showAlert = (props) => {
  let dangerMode = false;
  return new Promise((resolve) => {
    const {
      title = i18n.t("modal.DoneError.header"),
      text,
      icon = "success",
      button = i18n.t("modal.Done.footerButton"),
    } = props;

    if (icon === "error") {
      dangerMode = true;
    }
    swal({
      title,
      text,
      icon,
      button,
      dangerMode
    }).then(() => {
      resolve();
    });
  });
};

showAlert.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
};

