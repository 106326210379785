import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, Col, Row } from "reactstrap";
import { getDateFormat2 } from "utils/getDateFormat2";
import { Link } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaDown, faSortAlphaUp } from "@fortawesome/free-solid-svg-icons";
import { enumsProcessTaskType, enumsSortedTask, enumsSortedTypeTask } from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const ProcessManagementTableFilter = (props) => {
  const {
    processFilteredList,
    selectedFields,
    setSelectedFields,
    taskSelectedByBatch,
    setTaskSelectedByBatch,
    disabledCheck,
    setDisabledCheck,
    isVisibleButtonProcess,
    activateReassingBatchOption,
    isLoadingManagementProcess,
    totalPages,
    paginationPorcessFilter,
    setPaginationPorcessFilter,
    setOrderHeaderBy,
    setOrderHeaderType,
  } = props;
  const [isClicked, setIsClicked] = useState({});
  const [clickCounts, setClickCounts] = useState({});
  let sortedConfig = [{ id: null, desc: false }];
  const [sorted, setSorted] = useState(sortedConfig);
  let disabledAccesTaskDetail = false;
  let paginationZise = null;
  let taskasigned = processFilteredList;
  let disaleSortedButton = false;
  let sortableOption = true;
  let disabledChecks = false;
  let maximumSelectedTasksAllowed = 20;

  /**
   * Updates sorting options based on the assigned tasks array length.
   * Disables sorting if no tasks are assigned.
   * @param {number} taskAssignedLength - The length of the assigned tasks array.
   * @param {boolean} disableSortedButton - Flag indicating whether to disable the sort button.
   * @param {boolean} sortableOption - Flag indicating whether sorting options are enabled.
   */
  if (taskasigned.length === 0) {
    disaleSortedButton = true;
    sortableOption = false;
  }

  /**
   * Handles selecting all tasks.
   * Clears the selected fields and selects all tasks if the checkbox is not disabled.
   * @param {Array} tasks - Array of tasks.
   * @param {boolean} disabledCheck - Flag indicating whether the checkbox is disabled.
   * @param {function} setSelectedFields - State setter function to update selected fields.
   * @param {function} setDisabledCheck - State setter function to update disabled check.
   * @returns {void}
   */
  const handleSelectAllTasks = () => {
    setSelectedFields([]);
    if (disabledCheck === false) {
      const idsOfTheTasks = processFilteredList.map((obj) => obj.uuid);
      setSelectedFields([...idsOfTheTasks]);
      setTaskSelectedByBatch([...idsOfTheTasks]);
      setTaskSelectedByBatch([...processFilteredList]);
    } else {
      setSelectedFields([]);
      setTaskSelectedByBatch([]);
    }
    setDisabledCheck((prevState) => !prevState);
  };

  /**
   * Represents a checkbox element with a label for selecting all tasks.
   * @param {boolean} isVisibleButtonProcessBatch - Determines the visibility of the checkbox button.
   * @param {boolean} disabledCheck - Determines whether the checkbox is disabled or not.
   * @param {function} handleSelectAllTasks - Callback function invoked when the checkbox state changes.
   * @returns {JSX.Element} A JSX element representing the checkbox button with a label.
   */
  let checkedTaskButton = (
    <div className="d-flex position-relative mb-2 mt-2">
      <input
        className="mr-2"
        disabled={isVisibleButtonProcess === false}
        type="checkbox"
        checked={disabledCheck}
        onChange={(eventSelectAllTasks) => handleSelectAllTasks(eventSelectAllTasks)}
      />
      {i18n.t("taskFilter.label23")}
    </div>
  );

  /**
   * Update task state based on batch selection.
   * If activateReassingBatchOption is true, updates state variables accordingly.
   * @param {boolean} activateReassingBatchOption - Indicates whether to activate batch reassignment option.
   * If true, updates taskSelectedByBatch and disables access to task details.
   * @returns {void}
   */
  if (activateReassingBatchOption === true) {
    checkedTaskButton = null;
    taskasigned = taskSelectedByBatch;
    disabledAccesTaskDetail = true;
  }

  /**
   * Checks the length of the selectedFields array and updates the disabled state accordingly.
   * @param {number} selectedFieldsLength - The length of the selectedFields array.
   * @returns {boolean} - The updated disabled state.
   */
  if (selectedFields.length > maximumSelectedTasksAllowed - 1) {
    disabledChecks = true;
  } else {
    disabledChecks = false;
  }

  /**
   * Determines whether the checkbox should be disabled for a given task ID.
   * @param {string} taskId - ID of the task.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {boolean} disabledChecks - Flag indicating whether checks are disabled.
   * @param {boolean} isVisibleButtonProcessBatch - Flag indicating whether the button for processing batch is visible.
   * @returns {boolean} - True if checkbox should be disabled, otherwise false.
   */
  const shouldDisabledCheck = (taskId) => {
    const isTaskIdNotIncluded = selectedFields.includes(taskId) === false;
    const isDisabledOrNotSelected = disabledChecks === true && isTaskIdNotIncluded === true;
    const isButtonNotVisible = isVisibleButtonProcess === false;
    return isDisabledOrNotSelected === true || isButtonNotVisible === true;
  };

  /**
   *Handles the selection of a field.
   *@param {object} field - The field object to be selected or unselected.
   *@param {object} eventSelectFields - The event object containing the checkbox state.
   *@param {boolean} eventSelectFields.target.checked - The checked state of the checkbox.
   *@param {string} field.value - The value of the field.
   *@param {function} setSelectedFields - The state setter function for the selected fields.
   *@param {array} prevSelectedFields - The previously selected fields.
   *@param {boolean} setSelectAllFields - The state setter function for the "Select All" checkbox.
   */
  const handleSelectFields = (field, eventSelectFields) => {
    const checkedField = eventSelectFields.target.checked;

    if (checkedField === true) {
      setSelectedFields((prevSelectedFields) => [...prevSelectedFields, field.uuid]);
      setTaskSelectedByBatch((prevSelectedFields) => [...prevSelectedFields, field]);
    } else {
      setSelectedFields((prevSelectedFields) =>
        prevSelectedFields.filter((selectedValue) => selectedValue !== field.uuid)
      );
      setTaskSelectedByBatch((prevSelectedFields) =>
        prevSelectedFields.filter((selectedField) => selectedField.uuid !== field.uuid)
      );
    }
  };

  /**
   * Handles the change in sorting of data based on the provided sorting configuration.
   * @param {Array<Object>} newSorted - Array containing the new sorting configuration.
   * Each object in the array represents a column and its sorting order.
   * @param {string} newSorted[].id - The identifier of the column to be sorted.
   * @param {boolean} newSorted[].desc - Indicates whether sorting is descending (true) or ascending (false).
   */
  const handleOnSortedChange = (newSorted) => {
    setSorted(newSorted);
    const datafilter = newSorted[0];

    if (isNullOrUndefined(datafilter) === false) {
      let orderBy = datafilter.id;
      let orderType;

      if (datafilter.desc === true) {
        orderType = enumsSortedTypeTask.DESCENDING;
      }

      if (datafilter.desc === false) {
        orderType = enumsSortedTypeTask.ASCENDING;
      }

      if (clickCounts[orderBy] === 2) {
        orderType = undefined;
        setSorted(sorted);
        setOrderHeaderBy(undefined);
        setOrderHeaderType(undefined);
      } else {
        setOrderHeaderBy(orderBy);
        setOrderHeaderType(orderType);
      }
    } else {
      showAlertServiceError();
    }
  };

  /**
   * Handles sorting functionality for a given column in a table.
   * Updates sorting states and click counts.
   * @param {string} column - The column identifier to sort by.
   */
  const handleSort = (column) => {
    setClickCounts((prevClickCounts) => ({
      [column]: (prevClickCounts[column] || 0) + 1,
    }));

    setIsClicked((prevIsClicked) => {
      let newHeaderIsClicked = { ...prevIsClicked };

      if (
        prevIsClicked[column] === undefined ||
        prevIsClicked[column] !== enumsSortedTypeTask.ASCENDING
      ) {
        Object.keys(prevIsClicked).forEach((key) => {
          newHeaderIsClicked[key] = false;
        });
      }

      if (prevIsClicked[column] === enumsSortedTypeTask.ASCENDING) {
        newHeaderIsClicked[column] = enumsSortedTypeTask.DESCENDING;
      } else {
        newHeaderIsClicked[column] = enumsSortedTypeTask.ASCENDING;
      }

      if (clickCounts[column] === 2) {
        newHeaderIsClicked[column] = undefined;
        setClickCounts({});
      }

      return newHeaderIsClicked;
    });
  };

  /**
   * Determines the appropriate sort icon based on the sorting state of a column.
   * @param {string} column - The column identifier for which to determine the sort icon.
   * @param {Object} isClicked - Object containing the sorting state for each column.
   * @param {boolean} isClicked[column] - Indicates whether the column has been clicked for sorting.
   * @param {boolean|undefined} isClicked[column] - Sorting state of the specified column.
   * @param {string} isClicked[column] - Sorting direction of the specified column. Can be "ASCENDING", "DESCENDING", or undefined.
   * @param {Object} enumsSortedTypeTask - Enumerated values for sorting types.
   * @param {string} enumsSortedTypeTask.ASCENDING - Constant representing ascending sorting order.
   * @param {string} enumsSortedTypeTask.DESCENDING - Constant representing descending sorting order.
   * @returns {React.Element} The appropriate FontAwesomeIcon component representing the sort icon.
   */
  const getSortIcon = (column) => {
    if (isClicked[column] === undefined || isClicked[column] === false)
      return <FontAwesomeIcon icon={faSortAlphaDown} color="gray" />;

    if (isClicked[column] === enumsSortedTypeTask.ASCENDING)
      return <FontAwesomeIcon icon={faSortAlphaDown} color="green" />;

    if (isClicked[column] === enumsSortedTypeTask.DESCENDING)
      return <FontAwesomeIcon icon={faSortAlphaUp} color="green" />;
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            {checkedTaskButton}
            <ReactTable
              data={taskasigned}
              sorted={sorted}
              onSortedChange={(newSorted) => handleOnSortedChange(newSorted)}
              columns={[
                {
                  Header: (
                    <div>
                      <span className="text-primary font-weight-bold">
                        {i18n.t("taskFilter.label24")}{" "}
                      </span>
                    </div>
                  ),
                  id: "uuid",
                  accessor: "uuid",
                  width: 70,
                  sortable: false,
                  Cell: ({ _, original }) => (
                    <div className="text-center">
                      <input
                        type="checkbox"
                        disabled={shouldDisabledCheck(original.uuid)}
                        checked={selectedFields.includes(original.uuid)}
                        onChange={(eventSelectFields) =>
                          handleSelectFields(original, eventSelectFields)
                        }
                      />
                    </div>
                  ),
                },
                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="transparent"
                            onClick={() => handleSort(enumsSortedTask.CONSECUTIVE)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("createWorflow.Label3")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.CONSECUTIVE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("createWorflow.Label3");
                    }
                  },

                  id: enumsSortedTask.CONSECUTIVE,
                  accessor: " consecutive",
                  sortable: sortableOption,
                  Cell: (row) => {
                    let linkTask;
                    if (row.original.is_free_task === false) {
                      linkTask = `/traceability/workflow/${row.original.process_uuid}/${row.original.uuid}`;
                    } else {
                      linkTask = `/traceability/record/${row.original.uuid}/${row.original.record_uuid}`;
                    }
                    return (
                      <div className="d-flex justify-content-center">
                        <Link disabled={disabledAccesTaskDetail} to={linkTask}>
                          <Button
                            block
                            outline
                            className="mb-2 mr-2 btn-pill btn-dashed"
                            color="info"
                            disabled={disabledAccesTaskDetail}
                          >
                            <span className="badge-pill text-bold">{row.original.consecutive}</span>
                          </Button>
                        </Link>
                      </div>
                    );
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.RECORD_NUMBER)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable3")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.RECORD_NUMBER)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable3");
                    }
                  },
                  id: enumsSortedTask.RECORD_NUMBER,
                  accessor: "record_number",
                  sortable: sortableOption,
                  Cell: (row) => <div className="text-center">{row.value}</div>,
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.CREATED_AT)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable4")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.CREATED_AT)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable4");
                    }
                  },
                  id: enumsSortedTask.CREATED_AT,
                  accessor: "created_at",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    return <p>{valueFinal}</p>;
                  },
                },

                {
                  Header: (
                    <div>
                      <span className="text-primary font-weight-bold">
                        {i18n.t("taskBoxTableTaskType")}{" "}
                      </span>
                    </div>
                  ),
                  id: enumsSortedTask.TASKTYPE,
                  accessor: "task_type",
                  sortable: false,
                  Cell: ({ value }) => {
                    let processTaskType = "";

                    switch (value) {
                      case enumsProcessTaskType.NORMAL:
                        processTaskType = <span>{i18n.t("taskBoxTableTaskTypeNormal")}</span>;
                        break;
                      case enumsProcessTaskType.RETURN:
                        processTaskType = <span>{i18n.t("taskBoxTableTaskTypeDevolution")}</span>;
                        break;
                      case enumsProcessTaskType.RETURN_ORIGIN:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeReturnToOrigin")}</span>
                        );
                        break;
                      case enumsProcessTaskType.REASSIGNMENT:
                        processTaskType = <span>{i18n.t("taskBoxTableTaskTypeReassign")}</span>;
                        break;
                      case enumsProcessTaskType.REASSIGNMENT_ORIGIN:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeReassignToOrigin")}</span>
                        );
                        break;

                      default:
                        break;
                    }

                    return <span className="text-center">{processTaskType}</span>;
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.PROCESS_TYPE)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable9")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.PROCESS_TYPE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable9");
                    }
                  },
                  id: enumsSortedTask.PROCESS_TYPE,
                  accessor: "type",
                  sortable: sortableOption,
                  Cell: ({ value }) => <div className="text-center">{value}</div>,
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.SENDER)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable1")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.SENDER)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable1");
                    }
                  },
                  id: enumsSortedTask.SENDER,
                  accessor: "sender_fullname",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    return <div className="text-capitalize">{value}</div>;
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.SUBJECT)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable5")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.SUBJECT)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable5");
                    }
                  },
                  id: enumsSortedTask.SUBJECT,
                  accessor: "subject",
                  sortable: sortableOption,
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.RECEPTION_DATE)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable6")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.RECEPTION_DATE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable6");
                    }
                  },
                  id: enumsSortedTask.RECEPTION_DATE,
                  accessor: "reception_date",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    let receptionDate;
                    if (value === "0000-00-00T00:00:00+00:00") {
                      receptionDate = "";
                    } else {
                      receptionDate = valueFinal;
                    }
                    return <span>{receptionDate}</span>;
                  },
                },

                {
                  Header: () => {
                    if (disabledAccesTaskDetail === false) {
                      return (
                        <div>
                          <Button
                            size="sm"
                            color="trasnparent"
                            onClick={() => handleSort(enumsSortedTask.EXPIRATION_DATE)}
                            disabled={disaleSortedButton}
                          >
                            <span className="text-primary font-weight-bold">
                              {i18n.t("taskBoxTable7")}{" "}
                            </span>
                            {getSortIcon(enumsSortedTask.EXPIRATION_DATE)}
                          </Button>
                        </div>
                      );
                    } else {
                      return i18n.t("taskBoxTable7");
                    }
                  },
                  id: enumsSortedTask.EXPIRATION_DATE,
                  accessor: "expiration_date",
                  sortable: sortableOption,
                  Cell: ({ value }) => {
                    let valueFinal = getDateFormat2(new Date(value));
                    let receptionDate;
                    if (value === "0000-00-00T00:00:00+00:00") {
                      receptionDate = "";
                    } else {
                      receptionDate = valueFinal;
                    }
                    return <span>{receptionDate}</span>;
                  },
                },
              ]}
              manual
              className="-striped -highlight"
              loading={isLoadingManagementProcess}
              pageSize={paginationZise}
              pages={totalPages}
              page={paginationPorcessFilter.page - 1}
              previousText={`${i18n.t("previousText")}`}
              nextText={`${i18n.t("nextText")}`}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
              showPageJump={false}
              showPagination={!disabledAccesTaskDetail}
              defaultPageSize={paginationPorcessFilter.per_page}
              noDataText={`${i18n.t("tableRowsEmpty")}`}
              onPageSizeChange={(per_page) => {
                setPaginationPorcessFilter({ ...paginationPorcessFilter, per_page: per_page });
              }}
              onPageChange={(page) => {
                setPaginationPorcessFilter({ ...paginationPorcessFilter, page: page + 1 });
              }}
            />
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ProcessManagementTableFilter.propTypes = {
  processFilteredList: PropTypes.array.isRequired,
  selectedFields: PropTypes.array.isRequired,
  setSelectedFields: PropTypes.func.isRequired,
  taskSelectedByBatch: PropTypes.array.isRequired,
  setTaskSelectedByBatch: PropTypes.func.isRequired,
  disabledCheck: PropTypes.bool.isRequired,
  setDisabledCheck: PropTypes.func.isRequired,
  isVisibleButtonProcess: PropTypes.bool.isRequired,
  activateReassingBatchOption: PropTypes.bool.isRequired,
  isLoadingManagementProcess: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  paginationPorcessFilter: PropTypes.object.isRequired,
  setPaginationPorcessFilter: PropTypes.func.isRequired,
  setOrderHeaderBy: PropTypes.func.isRequired,
  setOrderHeaderType: PropTypes.func.isRequired,
};

export default ProcessManagementTableFilter;
