import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, CardTitle, CardBody } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { isNullOrUndefined } from "utils/validations";
import { useTrdContext } from "contextAPI/TrdContext";
import { showAlertServiceError } from "utils/alerts";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { enumSelectLevelOptions, enumsTargetSelectLevel } from "utils/enums";

const SelectLevel = (props) => {
  const {
    setLevelFolder,
    setShowFolderCondition,
    setTrdForm,
    setShowFormConditional,
    setShowCreateExpedientForm,
    trdForm,
    selectLevel,
    setSelectLevel,
    isCreateExpedient,
    setShowEditSectionForm,
    foldersId,
  } = props;

  const {
    getTreeByDomainCallback,
    parentData,
    setShowOrdenation,
    hasExpedients,
    setShowRightPanel,
    validateDeleteLevelTrd
  } = useTrdContext();
  const { deleteLevelTrd } = useTrd();
  const asignOrderToUuidLevel = {
    1: foldersId.sectionId,
    2: foldersId.subSectionId,
    3: foldersId.serieId,
    4: foldersId.subSerieId,
  };
  const uuIdLevel = asignOrderToUuidLevel[trdForm.level];
  let renderSelectOptionForm = null;

  /**
   * Generates a JSX element with a set of <option> elements based on the value of trdForm.level.
   * @param {Object} trdForm - The form object containing the level.
   * @param {number} trdForm.level - The level of the form to determine which options to render.
   * @param {boolean} isCreateExpedient - A flag to determine if an expedient is being created.
   * @param {Object} i18n - The internationalization object used for translations.
   * @returns {JSX.Element} A JSX fragment containing the appropriate <option> elements.
   */
  switch (trdForm.level) {
    case 0:
      renderSelectOptionForm = (
        <>
          <option value={enumSelectLevelOptions.CREATE_SECTION}>
            {i18n.t("trd.formTittle2")}
          </option>
          <option value={enumSelectLevelOptions.EDIT_BACKGROUND}>
            {i18n.t("trd.update.background")}
          </option>
        </>
      );
      break;
    case 1:
      renderSelectOptionForm = (
        <>
          <option value={enumSelectLevelOptions.CREATE_SUBSECCION}>
            {i18n.t("trd.formTittle3")}
          </option>
          <option value={enumSelectLevelOptions.CREATE_SERIE}>
            {i18n.t("trd.formTittle4")}
          </option>
          <option value={enumSelectLevelOptions.EDIT_SUBDIRECTORY}>
            {i18n.t("trd.formTittle33")}
          </option>
          <option value={enumSelectLevelOptions.DELETE_SECTION}>
            {i18n.t("buttonActions.option3") +
              " " +
              i18n.t("trd.formTittle10")}
          </option>
        </>
      );
      break;
    case 2:
      renderSelectOptionForm = (
        <>
          <option value={enumSelectLevelOptions.CREATE_SERIE_SELECT}>
            {i18n.t("trd.formTittle4")}
          </option>
          <option value={enumSelectLevelOptions.EDIT_SUB_SECTION}>
            {i18n.t("trd.formTittle35")}
          </option>
          <option value={enumSelectLevelOptions.DELETE_SUB_SECTION}>
            {i18n.t("buttonActions.option3") +
              " " +
              i18n.t("trd.formTittle11")}
          </option>
        </>
      );
      break;
    case 3:
      if (isCreateExpedient === true) {
        renderSelectOptionForm = (
          <>
            <option value={enumSelectLevelOptions.CREATE_EXPEDIENT_FORM}>
              {i18n.t("trd.formTittle6")}
            </option>
            <option value={enumSelectLevelOptions.CREATE_SUB_SERIE}>
              {i18n.t("trd.formTittle5")}
            </option>
            <option value={enumSelectLevelOptions.EDIT_SERIE}>
              {i18n.t("trd.formTittle37")}
            </option>
            <option value={enumSelectLevelOptions.DELETE_SERIE}>
              {i18n.t("buttonActions.option3") +
                " " +
                i18n.t("trd.formTittle12")}
            </option>
          </>
        );
      } else {
        renderSelectOptionForm = (
          <>
            <option value={enumSelectLevelOptions.CREATE_SUB_SERIE_OPTION}>
              {i18n.t("trd.formTittle5")}
            </option>
            <option value={enumSelectLevelOptions.EDIT_SERIE_OPTION}>
              {i18n.t("trd.formTittle37")}
            </option>
            <option value={enumSelectLevelOptions.DELETE_SERIE_OPTION}>
              {" "}
              {i18n.t("buttonActions.option3") +
                " " +
                i18n.t("trd.formTittle12")}
            </option>
          </>
        );
      }
      break;
    case 4:
      renderSelectOptionForm = (
        <>
          <option value={enumSelectLevelOptions.CREATE_EXPEDIENT_OPTION}>
            {i18n.t("trd.formTittle6")}
          </option>
          <option value={enumSelectLevelOptions.EDIT_SUB_SERIES}>
            {i18n.t("trd.formTittle43")}
          </option>
          <option value={enumSelectLevelOptions.DELETE_SUB_SERIES}>
            {" "}
            {i18n.t("buttonActions.option3") +
              " " +
              i18n.t("trd.formTittle13")}
          </option>
        </>
      );
      break;
    default:
      break;
  };


  /**
   *Function that deletes a level in a TRD (Table of Documental Retention) form, and updates the state variables of the component accordingly.
   *@param {string} uuIdLevel - the UUID of the level to delete
   *@param {object} trdForm.level - the level object containing the data of the level to delete
   *@param {function} deleteLevelTrd - the function that deletes the level from the TRD form and returns a promise
   *@param {function} getTreeByDomainCallback - the function that fetches the updated tree data for the current domain and updates the corresponding state variable
   */
  function deleteLevelInTrd() {
    setShowOrdenation(true);
    deleteLevelTrd(uuIdLevel, trdForm.level)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.delteLevelSweetAlert"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          });
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        let forms;
        if (isNullOrUndefined(error.response) === false) {
          if (error.response.data.code === "500038") {
            forms = error.response.data.data.forms;
            validateDeleteLevelTrd(forms);
          }
        }
      })
      .finally(() => {
        setShowOrdenation(false);
        setShowRightPanel(false);
        getTreeByDomainCallback();
      });
  }

  /**
   *Event handler function for a change event triggered on a select input element.
   *Updates the state of the component based on the selected option.
   *@param {object} enventSelectoptionTrd - the event object for the select option change event
   *@param {string} enventSelectoptionTrd.target.value - the value of the selected option
   *@param {array} listExpedients - an array of expedient data objects
   *@param {boolean} showOrdenation - a boolean value indicating whether the organization modal should be displayed
   *@param {object} i18n - an object containing translation strings
   *@param {object} parentData - an object containing the parent data of the current folder
   *@param {object} foldersId - an object containing the ids of the current folder and its parent folder
   *@param {function} setShowOrdenation - a function that sets the state of the showOrdenation variable
   *@param {function} setSelectLevel - a function that sets the state of the selectLevel variable
   *@param {function} setTrdForm - a function that sets the state of the trdForm object
   *@param {function} setLevelFolder - a function that sets the state of the levelFolder variable
   *@param {function} setShowFormConditional - a function that sets the state of the showFormConditional variable
   *@param {function} setShowCreateExpedientForm - a function that sets the state of the showCreateExpedientForm variable
   *@param {function} setShowFolderCondition - a function that sets the state of the showFolderCondition variable
   *@param {function} setShowEditSectionForm - a function that sets the state of the showEditSectionForm variable
   *@param {function} swal - a function that displays an alert modal
   */
  const handleOnChangeSelectOptionTrd = (enventSelectoptionTrd) => {
    const target = enventSelectoptionTrd.target.value;

    if (target) {
      setSelectLevel(target);
      setTrdForm({
        ...trdForm,
        level: Number(target),
      });
      setLevelFolder(Number(target));
      setShowFormConditional(true);
      setShowCreateExpedientForm(false);
      setShowFolderCondition(false);
      setShowEditSectionForm(false);
    }

    if (target === enumsTargetSelectLevel.EDIT_SECTION) {
      setSelectLevel(target);
      setLevelFolder(Number(target));
      setShowEditSectionForm(true);
      setShowFormConditional(false);
      setShowCreateExpedientForm(false);
      setShowFolderCondition(false);
    }

    if (target === enumsTargetSelectLevel.EDIT_SECTION_FORM) {
      setSelectLevel(target);
      setLevelFolder(Number(target));
      setShowEditSectionForm(true);
      setShowFormConditional(false);
      setShowCreateExpedientForm(false);
      setShowFolderCondition(false);
    }

    if (target === enumsTargetSelectLevel.ORDENAL_DOCUMENTAL_UNIT_CREATE && hasExpedients === false) {
      setShowOrdenation(true);
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("trd.documentary.organization"),
        icon: "info",
        buttons: [i18n.t("chooseNot"), i18n.t("chooseYes")],
      }).then((willCreate) => {
        if (willCreate) {
          window.location.href = `/trd/ordination/${parentData.id}/${foldersId.backgroundId}`;
        } else {
          window.location.href = `/trd/expedient/${parentData.id}/${foldersId.backgroundId}`;
        }
      });
    } else if (target === enumsTargetSelectLevel.ORDENAL_DOCUMENTAL_UNIT_CREATE && hasExpedients === true) {
      setShowOrdenation(true);
      window.location.href = `/trd/expedient/${parentData.id}/${foldersId.backgroundId}`;
    }

    if (target === enumsTargetSelectLevel.DELETE_ORDENAL_DOCUMENTAL) {
      setShowOrdenation(true);
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("trd.delteLevel"),
        icon: "info",
        buttons: [i18n.t("chooseNot"), i18n.t("chooseYes")],
      }).then((willDelete) => {
        if (willDelete) {
          deleteLevelInTrd();
        } else {
          setShowOrdenation(false);
          setSelectLevel("");
          setTrdForm({
            ...trdForm,
            level: trdForm.level,
          });
          setShowFormConditional(false);
          setShowFolderCondition(true);
        }
      });
    }
  };

  /**
   *Executes a callback function to get a tree data structure by domain.
   *@param {Function} getTreeByDomainCallback - The callback function to retrieve the tree data.
   *@returns {undefined}
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <AvForm>
            <Row>
              <Col md="12">
                <AvGroup>
                  <CardTitle>{i18n.t("buttonActions.title")}</CardTitle>{" "}
                  <AvField
                    id="status"
                    name="status"
                    type="select"
                    onChange={handleOnChangeSelectOptionTrd}
                    value={selectLevel}
                    required
                    errorMessage={i18n.t("trd.selectTittle")}
                  >
                    <option value="">{i18n.t("trd.selectTittle")}</option>
                    {renderSelectOptionForm}
                  </AvField>
                </AvGroup>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

SelectLevel.propTypes = {
  setLevelFolder: PropTypes.func.isRequired,
  setShowFolderCondition: PropTypes.func.isRequired,
  setTrdForm: PropTypes.func.isRequired,
  setShowFormConditional: PropTypes.func.isRequired,
  setShowCreateExpedientForm: PropTypes.func.isRequired,
  trdForm: PropTypes.object.isRequired,
  selectLevel: PropTypes.number.isRequired,
  setSelectLevel: PropTypes.func.isRequired,
  isCreateExpedient: PropTypes.bool.isRequired,
  setShowEditSectionForm: PropTypes.func.isRequired,
  foldersId: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SelectLevel;