import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Button,
  Label,
  CardFooter,
  Spinner,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import { showAlert } from "components/molecules/Alert/Alert";
import { isNullOrUndefined } from "utils/validations";
import { regexNameList, regexSubjectExpedient } from "utils/regexExpressions";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationGetExpedientList } from "utils/initialPaginationsConfig";
import Loader from "react-loaders";
import ReactTooltip from "react-tooltip";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const EditExpedientForm = () => {
  const {
    setIsLoading: setIsLoadingExpedientForm,
    isLoading: isLoadingExpedientForm,
  } = useTrdContext();
  const { getExpedients, updateExpedient } = useTrd();
  const [loadingExpedients, setLoadingExpedients] = useState(true);
  const [pagination] = useState(initialPaginationGetExpedientList);
  const { expedientId, backgroundId, levelId } = useParams();
  const [exp, setExp] = useState({
    name: "",
    subject: "",
    status: "",
  });

  const [expTemp, setExpTemp] = useState({
    name: "",
    subject: "",
    status: "",
  });
  const [keysFormCreate] = useState(["name", "subject", "status"]);

  let isLoadingExpedientForms = null;

  /**
   * Renders a spinner component if isLoadingExpedientForm is true.
   * @returns {JSX.Element|null}
   */
  if (isLoadingExpedientForm === true) {
    isLoadingExpedientForms = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Retrieves expedients based on search criteria.
   * @function getExpedientsBySearch
   * @returns {void}
   */
  const getExpedientsBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setLoadingExpedients(true);
    getExpedients(page, per_page, levelId, backgroundId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const findExpedient = response.data.items.find((expedient) => expedient.uuid === expedientId)
          setExp((prevState) => ({
            ...prevState,
            name: findExpedient.name,
            subject: findExpedient.subject,
            status: findExpedient.status
          }));
          setExpTemp((prevState) => ({
            ...prevState,
            name: findExpedient.name,
            subject: findExpedient.subject,
            status: findExpedient.status
          }));
        } else {
          showAlertServiceError();
        }
      }).finally(() => setLoadingExpedients(false));
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the onChange event for editing an expedient.
   * @param {Object} eventEditExpedient - The event object containing information about the change.
   * @param {string} eventEditExpedient.name - The name of the field being edited.
   * @param {string|number} eventEditExpedient.value - The new value for the field being edited.
   */
  const handleOnChangeEditExpedient = (eventEditExpedient) => {
    const { name, value } = eventEditExpedient.target;
    setExp((prevExp) => ({
      ...prevExp,
      [name]: value,
    }));
  };

  /**
   * Updates the state of the EditSubjectExpedient object based on the change event.
   * @param {Object} eventCreateExpedient - The event object representing the change in the create expedient form.
   * @param {string} eventCreateExpedient.target.name - The name attribute of the target element triggering the event.
   * @param {string|number} eventCreateExpedient.target.value - The value entered or selected in the target element triggering the event.
   */
  const handleOnChangeEditSubjectExpedient = (eventEditExpedient) => {
    const { name, value } = eventEditExpedient.target;
    setExp((prevExp) => ({
      ...prevExp,
      [name]: value,
    }));
  };

  /**
   * Handles onBlur event for creating or editing an expedient, updating the state accordingly.
   * @param {Object} eventEditExpedient - The event object triggered by onBlur action containing information about the edited expedient.
   * @param {string} eventEditExpedient.target.value - The value entered or edited for the expedient.
   * @param {string} eventEditExpedient.target.name - The name attribute of the input field corresponding to the expedient being edited.
   * @param {function} setExp - The state setter function to update the expedient state.
   * @returns {void}
   */
  const handleOnBlurCreateExpedient = (eventEditExpedient) => {
    const value = eventEditExpedient.target.value;
    const wordsArray = value.split(" ");
    const filteredWordsArray = wordsArray.filter((word) => word.trim() !== "");
    const trimmedValue = filteredWordsArray.join(" ");
    setExp((prevExp) => ({
      ...prevExp,
      [eventEditExpedient.target.name]: trimmedValue,
    }));
  };

  /**
   * Handles the change event for updating the status of an expedient.
   * @param {Event} eventEditExpedient - The change event object.
   */
  const handleOnChangeStatus = (eventEditExpedient) => {
    const newStatus = Number(eventEditExpedient.target.value);

    setExp((prevExp) => ({
      ...prevExp,
      status: newStatus,
    }));
  };

  /**
   * Updates an expedient with the given ID and expedient data.
   * @param {number} expedientId - The ID of the expedient to update.
   * @param {Object} expedient - The updated expedient data.
   */
  const updateExpedients = (expedientId, expedient) => {
    updateExpedient(expedientId, expedient)
      .then((response) => {
        if (response.status === 202) {
          const showAlertItemListUpdated = () => {
            showAlert({
              text: i18n.t("trd.formTittle29"),
            }).then(window.history.go(-1));
          };
          showAlertItemListUpdated();
        }
      })
      .finally(() => {
        setIsLoadingExpedientForm(false);
      });
  };

  /**
   * Handles the key press event for preventing the Enter key's default behavior.
   * @param {Event} eventEditExpedient - The key press event object.
   */
  const handleOnKeyPressEditExpedient = (eventEditExpedient) => {
    if (eventEditExpedient.key === "Enter") {
      eventEditExpedient.preventDefault();
    }
  };

  /**
   * Handles the form submission for editing an expedient.
   * @param {Event} eventEditExpedient - The form submission event object.
   * @param {Array} errors - An array containing validation errors (if any).
   */
  const handleOnSubmit = (eventEditExpedient, errors) => {
    eventEditExpedient.preventDefault();
    if (errors.length === 0) {
      const editFormExpedient = keysFormCreate.reduce(
        (acomulatorField, fieldKey) => {
          if (exp[fieldKey] !== expTemp[fieldKey]) {
            acomulatorField[fieldKey] = exp[fieldKey];
          }
          return acomulatorField;
        },
        {}
      );
      if (Object.keys(editFormExpedient).length === 0) {
        showAlert({
          text: i18n.t("form.alertWarning"),
          icon: "info",
        });
        return;
      }
      setIsLoadingExpedientForm(true);
      if (editFormExpedient.status && editFormExpedient.status === 2) {
        const showAlertShure = () => {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.formTittle30"),
            icon: "info",
            buttons: [
              i18n.t("createusers.createButton2"),
              i18n.t("modal.Done.footerButton"),
            ],
          }).then((willClose) => {
            if (willClose) {
              updateExpedients(expedientId, editFormExpedient);
            } else {
              eventEditExpedient.preventDefault();
              setIsLoadingExpedientForm(false);
            }
          });
        };
        showAlertShure();
      } else if (
        isNullOrUndefined(expedientId) === false &&
        isNullOrUndefined(editFormExpedient) === false
      ) {
        updateExpedients(expedientId, editFormExpedient);
      } else {
        setIsLoadingExpedientForm(true);
      }
    }
  };

  /**
   * Executes the getExpedientsBySearch function when the component mounts.
   * @param {Function} getExpedientsBySearch - Function to retrieve expedients based on search criteria.
   * @param {Array} [] - Array of dependencies. Since it's an empty array, the effect will only run once after the initial render.
   * @returns {void}
   */
  useEffect(() => {
    getExpedientsBySearch();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingExpedients === true) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="#0072BC" type="ball-pulse-rise" />
      </div>
    )
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{i18n.t("trd.formTittle26")}</CardTitle>
            <AvForm onSubmit={handleOnSubmit}>
              <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>
              <AvGroup>
                <Label for="name" className="is-required">
                  {i18n.t("trd.fromLabel2")}
                </Label>
                <AvField
                  onChange={handleOnChangeEditExpedient}
                  onKeyPress={handleOnKeyPressEditExpedient}
                  onBlur={handleOnBlurCreateExpedient}
                  type="text"
                  name="name"
                  id="name"
                  data-tip
                  data-for="name"
                  value={exp.name}
                  validate={{
                    pattern: {
                      value: regexNameList,
                      errorMessage: `${i18n.t("invalidField2")}`,
                    },
                    required: {
                      value: true,
                      errorMessage: `${i18n.t("trd.feedback3")}`,
                    },
                    minLength: {
                      value: 4,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: 100,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                  }}
                  autoComplete="off"
                />
              </AvGroup>
              <AvGroup>
                <Label for="subject">{i18n.t("trd.fromLabel3")}</Label>
                <AvField
                  onChange={handleOnChangeEditSubjectExpedient}
                  onKeyPress={handleOnKeyPressEditExpedient}
                  onBlur={handleOnBlurCreateExpedient}
                  type="text"
                  name="subject"
                  id="subject"
                  value={exp.subject}
                  validate={{
                    pattern: {
                      value: regexSubjectExpedient,
                      errorMessage: `${i18n.t("invalidField2")}`,
                    },
                    minLength: {
                      value: 0,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 0 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                    maxLength: {
                      value: 100,
                      errorMessage: `${i18n.t(
                        "fieldValidateLengthBetween"
                      )} 0 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                    },
                  }}
                  autoComplete="off"
                />
              </AvGroup>

              <AvGroup>
                <Label for="status" sm={4}>
                  {i18n.t("form.designerLabel3")}
                </Label>
                <AvField
                  onChange={handleOnChangeStatus}
                  type="select"
                  name="status"
                  id="status"
                  value={exp.status}
                  errorMessage={i18n.t("createusers.Feedback6")}
                >
                  <option value={1}>{i18n.t("trd.formTittle27")}</option>
                  <option value={2}>{i18n.t("trd.formTittle28")}</option>
                </AvField>
              </AvGroup>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  className="col-mt-3 mr-3"
                  color="gray"
                  onClick={() => {
                    window.history.go(-1);
                  }}
                >
                  {i18n.t("createusers.createButton2")}
                </Button>
                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingExpedientForm}
                  className="col-mt-3 mr-3"
                  color="cyan"
                >
                  {isLoadingExpedientForms}
                  {i18n.t("createusers.createButton3")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default EditExpedientForm;