import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import CreateUserFormRoles from "components/organism/CreateUserFormRoles";
import i18n from "locales/i18n";

const UserRolesForm = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("dashboard.label19")}
				subheading={i18n.t("createRoles.subHeading")}
				icon="note2"
			>
				<CreateUserFormRoles />
			</AdminTemplate>
		</Fragment>
	);
};

export default UserRolesForm;
