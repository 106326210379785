import React from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionExp = (props) => {
  const {
    expedientId,
    backgroundId,
    ultimateLevel,
  } = props;
  const { deleteLevelTrd } = useTrd();

  /**
   *Function that deletes a level in a TRD (Table of documental Retention ).
   *@param {number} expedientId - the id of the expedient containing the level to be deleted
   *@returns {Promise} - a Promise that resolves with a response object indicating the status of the delete request
   */
  function deleteLevelInTrd() {
    deleteLevelTrd(expedientId, 5).then((response) => {
      if (response.status === 202) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("trd.delteLevelSweetAlert"),
          icon: "success",
          button: i18n.t("modal.Done.footerButton"),
        }).then(() => {
          window.location.reload();
        });
      }
    });
  }

  /**
   *A function that handles the deletion of a level in a TRD (Table of documental Retention ).
   *@param {function} i18n - an internationalization function used to translate text displayed in the modal
   *@param {function} deleteLevelInTrd - a function that initiates the deletion of a level in the TRD
   */
  const handleOnDeleteExpedient = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trd.delteLevel"),
      icon: "info",
      buttons: [i18n.t("chooseNot"), i18n.t("chooseYes")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteLevelInTrd();
      } else {
      }
    });
  };

  return (
    <div>
      <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
        <DropdownToggle
          caret
          className="btn-icon btn-icon-only btn btn-link font-icon-lg"
          color="link"
        >
          <i className="lnr-menu-circle btn-icon-wrapper" />
        </DropdownToggle>
        <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
          <DropdownItem>
            <Link
              to={`/trd/expedient/edit/${expedientId}/${backgroundId}/${ultimateLevel}`}
            >
              <i className="dropdown-icon lnr-pencil"> </i>
              <span>{i18n.t("buttonActions.option2")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="#">
              <i className="dropdown-icon lnr-trash text-danger"></i>
              <span onClick={handleOnDeleteExpedient}>
                {" "}
                {i18n.t("buttonActions.option3")}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

ButtonActionExp.propTypes = {
  backgroundId: PropTypes.string.isRequired,
  expedientId: PropTypes.string.isRequired,
  expedientName: PropTypes.string.isRequired,
  expedientSubject: PropTypes.string.isRequired,
  expedientStatus: PropTypes.number.isRequired,
};

export default ButtonActionExp;