import React, { Fragment } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
//import i18n from "locales/i18n";

const linkHome = () => {
  return (
    <Fragment>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} color="primary"/>
          </Link>
        </BreadcrumbItem>
        {/* <BreadcrumbItem>
          <Link to="/user/list" >
            {i18n.t("linkHome1")}
          </Link>
        </BreadcrumbItem> */}
        <BreadcrumbItem active></BreadcrumbItem>
      </Breadcrumb>
    </Fragment>
  );
};

export default linkHome;
