import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  CardTitle,
  CardBody,
  Label,
  CardFooter,
  Spinner,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { enumCreateSubDivisionForm, enumLevelTrd } from "utils/enums";
import { regexCreateSubDivisionForm } from "utils/regexExpressions";
import useClassification from "hooks/useClassification";
import ReactTooltip from "react-tooltip";
import { trimExtraSpaces } from "utils/trimSpaces";
import i18n from "locales/i18n";

const CreateSubDivisionForm = () => {
  const {
    classificationRequest,
    setIsLoading,
    isLoading,
  } = useClassification();
  const { id, backgroundId } = useParams();
  const {
    createSubDivisionForm,
    setCreateSubDivisionForm,
    hasFiles,
    createSubDivisionFormSTR,
    setCreateSubDivisionFormSTR,
    setSeieSubserieId,
    setSeieSubserieBackgroundId,
    validateField,
    setValidateField,
  } = useTrdContext();

  let renderSubDivisionForm = null;
  let renderSpinnerLoadingSubDivision = null;
  let requiredNameInput = null;

  /**
   * Renders a spinner if the loading state is true.
   * @param {boolean} isLoading - The loading state flag.
   * @returns {JSX.Element|null} A JSX element for the spinner if loading, otherwise null.
   */
  if (isLoading === true) {
    renderSpinnerLoadingSubDivision = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Sets the required state of the name input field based on the validation state.
   * @param {boolean} validateField - The validation state flag.
   * @returns {boolean} The required state of the name input field.
   */
  if (validateField === true) {
    requiredNameInput = true;
  } else {
    requiredNameInput = false;
  }

  /**
   * Handle the change event of the input fields in the create directory form.
   * @param {Event} eventCreateDirectory - The change event.
   */

  const handleOnChangeCreateSubDivision = (eventCreateDirectory) => {
    setValidateField(true);
    setCreateSubDivisionForm({
      ...createSubDivisionForm,
      [eventCreateDirectory.target.name]: eventCreateDirectory.target.value,
    });
    setCreateSubDivisionFormSTR({
      ...createSubDivisionFormSTR,
      subdivision_struct: [eventCreateDirectory.target.value],
    });
    setSeieSubserieId(id);
    setSeieSubserieBackgroundId(backgroundId);
  };

  /**
   * Handle the key press event in the create directory form.
   * @param {Event} handleOnKeyPressCreateSubDivision - The key press event.
   */

  const handleOnKeyPressCreateSubDivision = (eventCreateDirectory) => {
    if (eventCreateDirectory.key === "Enter") {
      eventCreateDirectory.preventDefault();
    }
  };

  /**
   * Handle the blur event of the input fields in the create directory form.
   * @param {Event} eventCreateDirectory - The blur event.
   */
  const handleOnBlurCreateSubDivision = (eventCreateDirectory) => {
    const { value } = eventCreateDirectory.target;
    const trimmedValue = trimExtraSpaces(value);
    setCreateSubDivisionForm({
      ...createSubDivisionForm,
      [eventCreateDirectory.target.name]: trimmedValue,
    });
    setCreateSubDivisionFormSTR({
      ...createSubDivisionFormSTR,
      subdivision_struct: [trimmedValue],
    });
  };

  /**
   * Handle the form submission in the create directory form.
   * @param {Event} eventCreateDirectory - The submit event.
   * @param {Array} errors - The array of validation errors.
   */
  const handleOnSubmitCreateSubDivision = (eventCreateDirectory, errors) => {
    if (errors.length > 0) {
      eventCreateDirectory.preventDefault();
      setValidateField(true);
      setIsLoading(false);
      return;
    }

    if (validateField === true) {
      setIsLoading(true);
      classificationRequest(enumLevelTrd.SUBDIVISION, createSubDivisionFormSTR);
    }
  };

  /**
   * Handle the click event of the create sub-division form button.
   */
  const onClickCreateSubDivisionForm = () => {
    setValidateField(true);
  }

  if (hasFiles === true) {
    renderSubDivisionForm = (
      <CardBody>
        <Alert className="mbg-3" color="info">
          <span className="pr-2 text-bold">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mr-2 "
            />
            {i18n.t("modal.DoneError.header")}
            <div className="divider " />
            {i18n.t("trd.AlertCreateSubdision")}
          </span>
        </Alert>
      </CardBody>
    )
  } else {
    renderSubDivisionForm = (
      <CardBody>
        <CardTitle>{i18n.t("trd.formTittle7.1")}</CardTitle>
        <AvForm onSubmit={handleOnSubmitCreateSubDivision}>
          <AvGroup>
            <ReactTooltip
              id="name_subdivision"
              place="bottom"
              type="dark"
              effect="solid"
            >
              {i18n.t("trd.Tooltip4")}
            </ReactTooltip>
            <Label for="name" className="is-required">
              {i18n.t("trd.fromLabel2")}
            </Label>
            <AvField
              id="name"
              name="name"
              type="text"
              onChange={handleOnChangeCreateSubDivision}
              onKeyPress={handleOnKeyPressCreateSubDivision}
              onBlur={handleOnBlurCreateSubDivision}
              data-tip
              data-for="name_subdivision"
              validate={{
                pattern: {
                  value: regexCreateSubDivisionForm,
                  errorMessage: `${i18n.t("invalidField2")}`,
                },
                required: {
                  value: requiredNameInput,
                  errorMessage: `${i18n.t("trd.feedback4")}`,
                },
                minLength: {
                  value: enumCreateSubDivisionForm.MIN_LENGTH,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
                maxLength: {
                  value: enumCreateSubDivisionForm.MAX_LENGTH,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
              }}
              autoComplete="off"
              placeholder={i18n.t("trd.inputTittle6")}
              value={createSubDivisionForm.name}
            />
          </AvGroup>
          <CardFooter className="d-block text-right">
            <Button
              type="submit"
              size="lg"
              disabled={isLoading}
              className="col-mt-3"
              color="cyan"
              onClick={onClickCreateSubDivisionForm}
            >
              {renderSpinnerLoadingSubDivision}
              {i18n.t("createusers.createButton")}
            </Button>
          </CardFooter>
        </AvForm>
      </CardBody>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {renderSubDivisionForm}
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default CreateSubDivisionForm;
