import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, CardHeader } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { enumsWorkflowDeatils } from "utils/enums";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import WorkflowDetailListTable from "components/organism/WorkflowDetailListTable";
import FilterWorkflowDetailState from "components/atoms/FilterWorkflowDetailState";
import SearchBox from "components/atoms/SearchBox";
import useWorkFlow from "hooks/useWorkFlow";
import swal from "sweetalert";
import i18n from "locales/i18n";

const WorkflowDetailList = (props) => {
  const [listWorkflows, setListWorkflows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const { getProcess } = useWorkFlow();
  const { recordUuid } = useRecordDetailContext();

  /**
   * This function uses useCallback hook to memoize and cache the function to retrieve a workflow by search criteria.
   * This function retrieves a workflow by search criteria using the pagination, search, status, form_uuid, and recordUuid.
   * If there are no matching workflows found, it displays an alert message. It sets the list of workflows, total pages and sets
   * the loading state to false. It also depends on the pagination and search variables.
   * @param {function} getProcess -Function for get the process created in a record.
   * @returns {void}
   */
  const getWorkFlowBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoading(true);
    getProcess(page, per_page, search, status, props.form_uuid, recordUuid)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          if (response.data.items.length === 0 && props.recordStatus !== enumsWorkflowDeatils.STATUS_RECORD_INACTIVE) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("createWorflow.notExist1"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            });
          }
          setListWorkflows(response.data.items);
          setTotalPages(response.data.pages);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pagination, search]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *This useEffect hook runs when the component is mounted, and it depends on the memoized and cached getWorkFlowBySearch function.
   *This hook calls the getWorkFlowBySearch function to retrieve a workflow by search criteria when the component is mounted.
   *If the getWorkFlowBySearch function changes, it will trigger the useEffect to run again and call the updated function.
   * @param {fucntion} getWorkFlowBySearch -Function to get the process task,
   */
  useEffect(() => {
    getWorkFlowBySearch();
  }, [getWorkFlowBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <CardHeader>
            <div className="card-header-title font-size-lg text-capitalize font-weight-bold text-cyan pl-4">
              <h5>{i18n.t("Procesos iniciados").toUpperCase()}</h5>
            </div>

            <Row className="ml-auto align-center pr-3">
              <SearchBox
                setSearch={setSearch}
                pagination={pagination}
                setPagination={setPagination}
              />
              <FilterWorkflowDetailState
                setIsLoading={setIsLoading}
                setListWorkflows={setListWorkflows}
                setStatus={setStatus}
                pagination={pagination}
                setPagination={setPagination}
              />
            </Row>
          </CardHeader>

          <WorkflowDetailListTable
            listWorkflows={listWorkflows}
            pagination={pagination}
            isLoading={isLoading}
            totalPages={totalPages}
            setPagination={setPagination}
            recordUuid={props.recordUuid}
          />
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkflowDetailList.propTypes = {
  form_uuid: PropTypes.string.isRequired,
};

export default WorkflowDetailList;
