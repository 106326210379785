import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Col, Card, CardFooter } from "reactstrap";
import { useParams } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { capitalizeText } from "utils/formatText";
import { initialPaginationMemebersRol } from "utils/initialPaginationsConfig";
import { loaderElement } from "utils/loaderElement";
import UserListMembersRolesTable from "components/organism/UserListMembersRolesTable";
import ButtonAddMembersToRol from "components/atoms/ButtonAddMembersToRol";
import ButtonBackRoles from "components/atoms/ButtonBackRoles";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserListMembersRoles = () => {
  const { id: roleId } = useParams();
  const [listMembers, setListMembers] = useState([]);
  const [paginationMembersRol, setPaginationMembersRol] = useState(initialPaginationMemebersRol);
  const [isLoadingListMembers, setIsLoadingListMembers] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingDeleteMembers, setIsLoadingDeleteMembers] = useState(false);
  const { getMembers } = useRoles();

  /**
   * Processes an array of members, capitalizes their names, and updates the list of members.
   * If the array is empty, displays an informational message using SweetAlert.
   * @param {Array<Object>} members - The array of member objects to be processed.
   * @returns {void}
   */
  const processMembers = (members) => {
    if (members.length === 0) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("createRoles.MembersMessage"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
    }
    let membersOrder = members.map((member) => {
      let result = {
        name: capitalizeText(`${member.first_name} ${member.last_name}`),
        uuid: member.uuid,
        userName: member.user_name,
      };
      return result;
    });
    setListMembers(membersOrder);
  }

  /**
   * Fetches members by a specific role and updates the list of members.
   * This function sets a loading state while fetching members and handles potential errors.
   * @param {string} roleId - The identifier for the role to retrieve members for.
   * @param {function} setIsLoadingListMembers - State setter function for controlling loading state.
   * @param {function} setListMembers - State setter function for updating the list of members.
   * @param {function} showAlertServiceError - A function to display an alert in case of service errors.
   * @returns {void}
   */
  const getMembersByRol = useCallback(() => {
    setIsLoadingListMembers(true);
    const { page, per_page } = paginationMembersRol;
    getMembers(roleId, page, per_page)
      .then((listMembers) => {
        const members = listMembers.data.data.items;
        if (isNullOrUndefined(members) === false) {
          setTotalPages(listMembers.data.data.pages);
          processMembers(members);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingListMembers(false);
      });
  }, [paginationMembersRol.page]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that calls the 'getMembersByRol' function when dependencies change.
   * This effect is triggered whenever 'getMembersByRol' or its dependencies change. It's typically used to fetch and update member data based on a specific role.
   * This useEffect hook is responsible for calling the 'getMembersByRol' function when its dependencies change.
   * It is commonly used to retrieve and update member data associated with a specific role.
   * @param {function} getMembersByRol - The function responsible for fetching and updating member data based on a specific role.
   */
  useEffect(() => {
    getMembersByRol();
  }, [getMembersByRol]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingDeleteMembers)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <Col>
              <ButtonBackRoles />
            </Col>
            <ButtonAddMembersToRol idRol={roleId} />
          </CardFooter>

          <UserListMembersRolesTable
            totalPages={totalPages}
            listMembers={listMembers}
            idRol={roleId}
            isLoadingListMembers={isLoadingListMembers}
            pagination={paginationMembersRol}
            setPagination={setPaginationMembersRol}
            setIsLoadingDeleteMembers={setIsLoadingDeleteMembers}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserListMembersRoles;
