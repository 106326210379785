import React, { useState, useCallback, useEffect } from "react";
import { isNullOrUndefined } from "utils/validations";
import { initialMaxUsersConfig } from "utils/initialMaxUsersConfig";
import { enumsTask, enumsTaskPriority, enumsTaskType, enumsTypeStatusUser } from "utils/enums";
import { initialPaginationGetUsers, initialPaginationTasks } from "utils/initialPaginationsConfig";
import { showAlertServiceError } from "utils/alerts";
import useWorkFlow from "hooks/useWorkFlow";
import useUser from "hooks/useUser";
import useForms from "hooks/useForm";
import i18n from "locales/i18n";
import swal from "sweetalert";

const WorkflowContext = React.createContext(undefined);

export function WorkflowContextProvider({ children }) {
  /*
   *States for Reassign task in the context
   */
  const [isLoadingReassingTask, setIsLoadingReassingTask] = useState(false);
  const [isLoadingSteps, setIsLoadingSteps] = useState(false);
  const [showModalReassignTask, setShowModalReassignTask] = useState(false);
  const [reassignTaskUsers, setReassignTaskUsers] = useState({
    users_uuid: [],
  });
  const [reassignProcessUsers, setReassignProcessUsers] = useState({
    reassign_user_uuid: "",
  });
  const [reassignProcessBatch, setReassignProcessBatch] = useState({
    reason: "",
    reassign_user_uuid: "",
    tasks: [],
  });

  const [reassignManagementProcessData, setReassignManagementProcessData] = useState({
    reason: "",
    property_user_uuid: "",
    reassign_user_uuid: "",
    tasks: [],
  });
  const [disabledButtonBatchProcess, setDisabledButtonBatchProcess] = useState(true);
  const [showReassingn, setShowReassingn] = useState(false);
  const [taskDetail, setTaskDetail] = useState({});
  const [activateReassingBatchOption, setActivateReassingBatchOption] = useState("");
  const [isLoadingGeneralusers, setIsLoadingGeneralusers] = useState(false);
  const [generalUsersDinamic, setGeneralUsersDinamic] = useState([]);
  const [activateInterruptBatchOption, setActivateInterruptBatchOption] = useState(false);
  const [taskSelectedByBatch, setTaskSelectedByBatch] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [formIdInfo, setFormIdInfo] = useState("");
  const [listWorkflowSteps, setListWorkflowSteps] = useState([]);
  const [nameProcess, setNameProcess] = useState();
  const [forms, setForms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilterTasks, setIsLoadingFilterTasks] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationTasks);
  const [paginationForms] = useState({
    page: 1,
    perPage: 100,
    search: "",
    status: 2,
    actions: 0,
  });
  const [showOptions, setShowOptions] = useState("1");
  const [reassign, setReassign] = useState({
    allow_reassign: "",
    allow_interrupt_process: "",
    allow_return_to_step: "",
  });
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [listReal, setListReal] = useState([]);
  const [loader, setLoader] = useState(false);
  const [responsibleUser, setResponsibleUser] = useState("");
  const [responsibleList, setResponsibleList] = useState([]);
  const [listNameGroups, setListNamesGroups] = useState([]);
  const [nameGroup, setNameGroup] = useState("");
  const [typeResponsibleUser, setTypeResponsibleUser] = useState(null);
  const [typeResponsibleGroup, setTypeResponsibleGroup] = useState(false);
  const [userStep, setUserStep] = useState([]);
  const [idWorkGroup, setIdWorkGroup] = useState();
  const [usersDinamic, setUsersDinamic] = useState([]);
  const [usersDinamicEdit, setUsersDinamicEdit] = useState([]);
  const [usersDinamicProcess, setUsersDinamicProcess] = useState([]);
  const [perPage] = useState(500);
  const [activeStatus] = useState(2);
  const [responsibleOption, setResponsibleOption] = useState({
    responsible: "",
  });
  const [viewAddFields, setViewAddFields] = useState(false);
  const [fillingOutForm, setFillingOutForm] = useState(false);
  const [dataFormSelected, setDataFormSelected] = useState("");
  const { selectedOption } = useState([]);
  const [isEditWorkGroupStep, setIsEditWorkGroupStep] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [stepUuid, setStepUuid] = useState("");
  const [workflowid, setWorkflowid] = useState("");
  const [addMember, setAddMember] = useState({
    user_uuid: "",
    step_uuid: "",
    workflow_uuid: "",
  });
  const [addMembersList, setAddMembersList] = useState({
    user_uuid_list: [],
    step_uuid: "",
    workflow_uuid: "",
  });
  const [isCompletedTask, setIsCompletedTask] = useState(false);
  const [isError, setIsError] = useState(false);
  const [process, setProcess] = useState({});
  const [status, setStatus] = useState("");
  const [isProcess] = useState(true);
  const [isProcessTask, setIsProcessTask] = useState(false);
  const [acordeon, setAcordeon] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [taskUuid, setTaskUuid] = useState("");
  const [tasks, setTasks] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [selectFormLabel, setSelectFormLabel] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [task, setTask] = useState({});
  const [type, setType] = useState("");
  const [priority, setPriority] = useState("");
  const [comply, setComply] = useState({});
  const [commentCreate, setCommentCreate] = useState({
    process_task_uuid: "",
    comment: "",
  });
  const [showInterruptProcess, setShowInterruptProcess] = useState(false);
  const [formName, setFormName] = useState("");
  const [formFilingName, setFormFilingName] = useState("");
  const [formExternalName, setFormExternalName] = useState("");
  const [forcedUpdateFormName, setForcedUpdateFormName] = useState(false);
  const [showModalReasign, setShowModalReasign] = useState(false);
  const [nextStepId, setNextStepId] = useState("");
  const [workflowId, setWorkflowId] = useState("");
  const [files, setFiles] = useState([]);
  const [complyProcessGateWay, setComplyProcessGateWay] = useState({
    status: 2,
    next_user_uuid: "",
    next_step_uuid: "",
  });
  const [selectLabelSequenceFlow, setSelectLabelSequenceFlow] = useState(false);
  const [requiredfilledform, setRequiredfilledform] = useState(false);
  const [fullfilCompleted, setFullfilCompleted] = useState(false);
  const [showFormFillingProcess, setShowFormsFillingProcess] = useState(false);
  const [showExternalFormFillingProcess, setShowExternalFormsFillingProcess] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [editConfigStepsTab3FormData, setEditConfigStepsTab3FormData] = useState(false);
  const [showExternalFormEdit, setShowExternalFormEdit] = useState(false);
  const [selectFormId, setSelectFormId] = useState("");
  const [firstCheck, setFirstCheck] = useState(false);
  const [isLoadingComplyProcessBatch, setIsLoadingComplyProcessBatch] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [formsDinamic, setFormsDinamic] = useState([]);
  const [workflowDinamic, setWorkflowDinamic] = useState([]);
  const [stepsDinamic, setStepsDinamic] = useState([]);
  const [selectFormIdContext, setSelectFormIdContext] = useState(false);
  const [selectWorkflowId, setSelectWorkflowId] = useState("");
  const [selectWorkflowLabel, setSelectWorkflowLabel] = useState("");
  const [selectedOptionsWorkflow, setSelectedOptionsWorkflow] = useState("");
  const [selectStepId, setSelelectStepId] = useState("");
  const [selecStepLabel, setSelectStepLabel] = useState("");
  const [selectedOptionsStep, setSelectedOptionsStep] = useState("");
  const [complyTaskRequest, setComplyTaskRequest] = useState(false);
  const [processUuid, setProcessUuid] = useState("");
  const [isVisibleButtonProcessBatch, setIsVisibleButtonProccessBatch] = useState(true);
  const [taskProcessId, setTaskProcessId] = useState("");
  const [openWizardProcess, setOpenWizardProcess] = useState(false);
  const [isOpenModalPortal, setIsOpenModalPortal] = useState(false);
  const [numberOfMembers] = useState(initialMaxUsersConfig);
  const [commentCreateProcessBatch, setCommentCreateProcessBatch] = useState({
    comment: "",
  });
  const [configProcessBatch, setConfigProcessBatch] = useState({
    tasId: "",
    recordId: "",
    formId: "",
    fileByTrd: "",
    parentId: "",
    file: [],
    uploadForm: {},
  });
  const [nextStepWizzard, setNextStepWizzard] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [checkedOmitedUploadFile, setCheckedUploadFile] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [processTaskBatch, setProcessTaskBatch] = useState(false);
  const [isLoadingResponsilblesList, setIsLoadingResponsilblesList] = useState(false);
  const [structureQueryByFilterTask, setStructureQueryByFilterTask] = useState({
    form_uuid: null,
    search_filter: [],
    workflow_uuid: null,
    step_uuid: null,
  });
  const [querySearchForm, setQuerySearchForm] = useState("");
  const [copiedSelectedOptionWorkflow, setCopiedSelectedOptionWorkflow] = useState({});
  const [copiedSelectedOptionStep, setCopiedSelectedOptionStep] = useState({});
  const [copiedSelectedOption, setCopiedSelectedOption] = useState({});
  const [uploadFormWorkflow, setUploadFormWorkflow] = useState({
    files: [],
  });
  const [uploadFormStartDate, setUploadFormStartDate] = useState("");
  const [checkedFilingForm, setCheckedFilingForm] = useState(false);
  const [isAdvancedFilterTask, setIsAdvancedFilterTask] = useState(false);
  const [formExternalList, setFormExternalList] = useState([]);
  const [orderHeaderBy, setOrderHeaderBy] = useState();
  const [orderHeaderType, setOrderHeaderType] = useState();
  const [filterDone, setFilterDone] = useState(false);

  const [showModalReturnToStep, setShowModalReturnToStep] = useState(false);
  const { selectedOptionStepToReturn } = useState([]);
  const [isErrorReturnToStep, setIsErrorReturnToStep] = useState(false);
  const [returnToStepProcessData, setReturnToStepProcessData] = useState({
    reason: "",
    return_process_task_uuid: "",
  });
  const [isLoadingStepsToReturn, setIsLoadingStepsToReturn] = useState(false);
  const [stepsToReturnList, setStepsToReturnList] = useState([]);
  const [isLoadingReturningTask, setIsLoadingReturningTask] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {
    getWorkFlowSteps,
    getListResponsibles,
    addResponsibleStep,
    addResponsibleSenderSelects,
    getProcessByUuid,
    configureActions,
    getUserForReassing,
    getTasks,
    getTasksByUuid,
    fulfillTask,
    getListUserByProcess,
    getFilesByProcess,
    getFilesByTask,
    deleteConfigurationDataFormInStep,
    verifyProcessBatch,
    advancedFilterTasks,
    getStepsToReturn,
  } = useWorkFlow();

  const { getForms } = useForms();
  const { getUsers, getWorkGroupsByStatus } = useUser();

  /**
   * Handles the click event to show a modal dialog by setting 'showModal' to true.
   */
  const handleOnClick = () => {
    setShowModal(true);
  };

  /**
   * Handles the click event to show a workgroup edit modal and sets 'isEditWorkGroupStep' to true.
   */
  const handleOnClickEditWorkGroup = () => {
    setShowModalGroup(true);
    setIsEditWorkGroupStep(true);
  };

  /**
   * Handles the close event for a modal by setting 'showModal' to false.
   */
  const handleOnClose = () => {
    setShowModal(false);
  };

  /**
   * Verifies the process batch asynchronously.
   * Sets loading state, prepares selected tasks, sends request, handles response, and catches errors.
   * @param {Array} selectedFields - Array of selected fields.
   * @param {function} setIsLoadingFilterTasks - State setter function to update loading state for filter tasks.
   * @param {function} verifyProcessBatch - Function to verify the process batch.
   * @param {function} setIsVisibleButtonProccessBatch - State setter function to update visibility of the process batch button.
   * @param {function} showAlertServiceError - Function to show alert for service error.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @param {function} isNullOrUndefined - Function to check if a value is null or undefined.
   * @returns {Promise<void>} - Promise that resolves once the verification process is complete.
   */
  const funcVerifyProcessBatch = async () => {
    setIsLoadingFilterTasks(true);
    const selectedTasksToVerify = {
      tasks: selectedFields,
    };
    verifyProcessBatch(selectedTasksToVerify)
      .then((response) => {
        if (response.status === 201) {
          setActivateReassingBatchOption(response.data.data.actions.allow_reassign);
          setActivateInterruptBatchOption(response.data.data.actions.allow_interrupt_process);
          setIsVisibleButtonProccessBatch(false);
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        const swalMessageConfig = {
          title: i18n.t("modal.DoneError.header"),
          icon: "error",
          button: i18n.t("modal.Done.footerButton"),
          dangerMode: true,
          text: `${i18n.t("taskBatchProcess.alert.error")}: \n`,
        };
        if (isNullOrUndefined(error.response) === false) {
          const errors = error.response.data.data;
          errors.forEach((error) => {
            if (error.code === "50098") {
              swalMessageConfig.text += "*" + i18n.t("50098") + "\n";
            }
            if (error.code === "50099") {
              swalMessageConfig.text += "*" + i18n.t("50099") + "\n";
            }
            if (error.code === "50100") {
              swalMessageConfig.text += "*" + i18n.t("50100") + "\n";
            }
          });
          swal(swalMessageConfig);
        }
      })
      .finally(() => {
        setIsLoadingFilterTasks(false);
      });
  };

  /**
   * Displays a warning alert indicating that the task has already been completed.
   * @returns {void}
   */
  const alertTaskCompleted = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("taskBoxTableAlertCompletedTask"),
      icon: "warning",
      button: i18n.t("modal.Done.footerButton"),
    }).then(() => {
      window.location = "/taskBox";
    });
  };

  /**
   * Displays a warning alert indicating that the user task not found.
   * @returns {void}
   */
  const alertErrorUserTask = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("50016"),
      icon: "error",
      button: i18n.t("modal.Done.footerButton"),
      dangerMode: true,
    }).then(() => {
      window.location = "/taskBox";
    });
  };

  /**
   * Displays a warning alert indicating that the task has already been interrupted.
   * @returns {void}
   */
  const alertTaskDisrupted = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("taskBoxTableAlertDisruptedTask"),
      icon: "warning",
      button: i18n.t("modal.Done.footerButton"),
    }).then(() => {
      window.location = "/taskBox";
    });
  };

  /**
   * Handles the close event for a task-related modal. It clears errors, hides the modal, and resets 'reassignTaskUsers'.
   */
  const handleOnCloseTask = () => {
    setIsError(false);
    setShowModal(false);
    setReassignTaskUsers({
      ...reassignTaskUsers,
      users_uuid: [],
    });
  };

  /**
   * Handles the close event for a process-related modal. It hides the modal, clears errors, and resets 'commentCreate' and 'reassignProcessUsers'.
   */
  const handleOnCloseProcess = () => {
    setShowModal(false);
    setIsError(false);
    setCommentCreate({
      ...commentCreate,
      comment: "",
    });
    setReassignProcessUsers({
      ...reassignProcessUsers,
      reassign_user_uuid: "",
    });
  };

  /**
   * Handles the click event to toggle the accordion state between true and false.
   */
  const handleOnClickAcordeon = () => {
    if (acordeon === false) {
      setAcordeon(true);
    } else {
      setAcordeon(false);
    }
  };

  /**
   * Fetches tasks based on the current pagination settings. Sets loading state, retrieves tasks,
   * and updates the state with task data. Displays an alert if there are no tasks found.
   */
  const getTasksBySearch = useCallback(
    (orderType, orderBy) => {
      const { page, per_page } = pagination;
      setIsLoading(true);
      getTasks(page, per_page, orderType, orderBy)
        .then((response) => {
          if (response.data.data.items.length === 0) {
            const showTasksEmpty = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("taskBoxTableAlert"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            };
            showTasksEmpty();
          }
          setTasks(response.data.data.items);
          setTotalCount(response.data.data.count);
          setTotalPages(response.data.data.pages);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [pagination] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Fetches task details by its unique identifier (UUID). Sets loading state, retrieves task details,
   * and updates the state with task information including status, type, and priority.
   * @param {string} id - The unique identifier of the task.
   */
  const getTasksByFilter = useCallback(
    (searchFilterTaskByCriteria, orderType, orderBy) => {
      const { page, per_page } = pagination;
      setIsLoadingFilterTasks(true);
      advancedFilterTasks(page, per_page, searchFilterTaskByCriteria, orderType, orderBy)
        .then((response) => {
          const tasksByAdvancedFilters = response.data;
          if (isNullOrUndefined(tasksByAdvancedFilters) === false) {
            if (tasksByAdvancedFilters.items.length === 0) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("taskFilter.label5"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
            setFilterDone(true);
            setTasks(tasksByAdvancedFilters.items);
            setIsFilter(true);
            setTotalCount(tasksByAdvancedFilters.count);
            setTotalPages(tasksByAdvancedFilters.pages);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFilterTasks(false);
        });
    },
    [pagination] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Fetches task details by its unique identifier (UUID). Sets loading state, retrieves task details,
   * and updates the state with task information including status, type, and priority.
   * @param {string} id - The unique identifier of the task.
   */
  const getTasksById = useCallback((id) => {
    setIsLoading(true);
    getTasksByUuid(id)
      .then((response) => {
        const taskDetails = response.data.data;
        if (isNullOrUndefined(taskDetails) === false) {
          setFormIdInfo(taskDetails.form_uuid);
          setTaskDetail(taskDetails);
          setTaskUuid(taskDetails.uuid);
          setComply({ status: enumsTask.COMPLETED });

          if (taskDetails.status !== enumsTask.IN_PROGRESS) {
            alertTaskCompleted();
          }

          if (taskDetails.status === enumsTask.IN_PROGRESS) {
            setStatus(i18n.t("taskDetailStatus1"));
          } else if (taskDetails.status === enumsTask.COMPLETED) {
            setStatus(i18n.t("taskDetailStatus2"));
          } else {
            setStatus(i18n.t("taskDetailStatus3"));
          }

          if (taskDetails.type === enumsTaskType.FREE_TASK) {
            setType(i18n.t("taskDetailType1"));
          } else {
            i18n.t("taskDetailType2");
          }

          if (taskDetails.priority === enumsTaskPriority.HIGH) {
            setPriority(i18n.t("notifications.priority1"));
          } else if (taskDetails.priority === enumsTaskPriority.MID) {
            setPriority(i18n.t("notifications.priority2"));
          } else {
            setPriority(i18n.t("notifications.priority3"));
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fulfills a task using the 'fulfillTask' API call. It shows a success alert and redirects to the task box upon success.
   */
  function fulfillTasks() {
    fulfillTask(taskUuid, comply)
      .then((response) => {
        if (response.status === 201) {
          const showAlertTaskfuldill = () => {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("taskComply1"),
              icon: "success",
              timer: 3000,
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location = "/taskBox";
            });
          };
          showAlertTaskfuldill();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  /**
   * Fetches process details by its unique identifier (UUID). Sets loading state, retrieves process details,
   * and updates the state with process information including form-related data, reassignment settings, and status.
   * @param {string} id - The unique identifier of the process.
   */
  const getProcessById = useCallback((id) => {
    setIsLoading(true);
    getProcessByUuid(id)
      .then((response) => {
        const processData = response.data.data;
        if (isNullOrUndefined(processData) === false) {
          setProcess(processData);
          setWorkflowId(processData.workflow_uuid);
          setNextStepId(processData.next_step_uuid);
          setTaskProcessId(processData.uuid);
          setProcessUuid(processData.process_uuid);
          setFormIdInfo(processData.form_uuid);
          setShowReassingn(processData.step_config.actions.allow_reassign);
          setShowInterruptProcess(processData.step_config.actions.allow_interrupt_process);
          if (processData.form_external_records !== null) {
            setFormExternalList(processData.form_external_records);
          }
          if (processData.status === enumsTask.COMPLETED) {
            alertTaskCompleted();
            setIsCompletedTask(true);
          }
          if (processData.status === enumsTask.INTERRUPTED) {
            alertTaskDisrupted();
            setIsCompletedTask(true);
          }
          if (processData.step_config.form_name !== null) {
            setFormFilingName(processData.step_config.form_name);
          }
          if (processData.step_config.form_name_external !== null) {
            setFormExternalName(processData.step_config.form_name_external);
          }
          if (processData.step_config.data_form_required !== null) {
            setRequiredfilledform(processData.step_config.data_form_required);
          }
          if (processData.step_config.data_form_completed !== null) {
            setFullfilCompleted(processData.step_config.data_form_completed);
          }
          if (processData.status === 1) {
            setStatus(i18n.t("taskDetailStatus1"));
          } else if (processData.status === 2) {
            setStatus(i18n.t("taskDetailStatus2"));
          } else {
            setStatus(i18n.t("taskDetailStatus3"));
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches a list of users for reassignment by their associated task's unique identifier (UUID).
   * Sorts the users by name and populates the 'usersDinamic' state with active user data.
   * @param {string} id - The unique identifier of the associated task.
   */
  const getUsersBySearch = useCallback((id) => {
    setUsersDinamic([]);
    getUserForReassing(id)
      .then((response) => {
        let listUsers = response.data.items;
        if (isNullOrUndefined(listUsers) === false) {
          listUsers
            .filter(
              (user) =>
                user.status !== enumsTypeStatusUser.RETIRED &&
                user.status !== enumsTypeStatusUser.INACTIVE
            )
            .sort((positionNameA, positionNameB) => {
              const nombresA = `${positionNameA.name} ${positionNameA.last_name} `.toLocaleLowerCase();
              const nombresB = `${positionNameB.name} ${positionNameB.last_name} `.toLocaleLowerCase();
              return nombresA.localeCompare(nombresB);
            })
            .forEach((user) => {
              setUsersDinamic((prevState) => [
                ...prevState,
                {
                  value: user.uuid,
                  label: `${user.first_name} ${user.last_name} (${user.user_name})`,
                },
              ]);
            });
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        if (error.response.data.code === "50016") {
          alertErrorUserTask();
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches workflow steps for a specific workflow by its unique identifier (UUID).
   * Sets the 'listWorkflowSteps' state with the retrieved workflow steps.
   * @param {string} id - The unique identifier of the workflow.
   */
  const getWorkFlowBySearch = useCallback((id) => {
    setIsLoadingSteps(true);
    getWorkFlowSteps(id)
      .then((response) => {
        setListWorkflowSteps(response);
      })
      .catch((error) => {
        if (error.response.data.code === "50043") {
          setTimeout(() => {
            window.location = "/workflow/list";
          }, 4000);
        } else {
        }
      })
      .finally(() => {
        setIsLoadingSteps(false);
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches information related to workflow responsibilities by its unique identifiers (workflowid and id).
   * This function sets various states to handle responsible data, including form information, user or group responsibilities,
   * reassignment settings, and user lists. It populates the 'usersDinamic' state with active users.
   * @param {string} workflowid - The unique identifier of the workflow.
   * @param {string} id - The unique identifier of the responsibility.
   */
  const getWorkFlowResponsibleById = useCallback(
    (workflowid, id) => {
      setLoader(true);
      setForcedUpdateFormName(false);
      getListResponsibles(workflowid, id).then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const responsiblesInfoData = response.data;
          const actions = response.data.actions;
          const typeUsers = response.data.type_user_responsible;
          const userResponsibleList = response.data.user_responsible_list;
          const workGroupResponsible = response.data.work_group_responsible;

          setTypeResponsibleUser(typeUsers);
          setStepUuid(id);
          setWorkflowid(workflowid);

          if (responsiblesInfoData.data_form !== null) {
            setFormName(responsiblesInfoData.data_form);
          }

          if (responsiblesInfoData.data_form_external !== null) {
            setFormExternalName(responsiblesInfoData.data_form_external);
          } else {
            setFormExternalName("");
          }

          if (isNullOrUndefined(userResponsibleList) === false) {
            if (userResponsibleList.length === 0) {
              setLoader(false);
            }
          }

          if (typeUsers === 1) {
            setResponsibleUser(userResponsibleList[0]);
          } else {
            setResponsibleList(userResponsibleList);
          }

          if (typeUsers === 3) {
            setTypeResponsibleGroup(true);
            setIdWorkGroup(workGroupResponsible.work_group_uuid);
            setLoader(false);
          }

          if (actions.allow_reassign === undefined) {
            setReassign({
              ...reassign,
              allow_reassign: false,
              allow_interrupt_process: false,
              allow_return_to_step: false,
            });
          } else {
            setReassign({
              ...reassign,
              allow_reassign: actions.allow_reassign,
              allow_interrupt_process: actions.allow_interrupt_process,
              allow_return_to_step: actions.allow_return_to_step,
            });
          }
        } else {
          showAlertServiceError();
        }

        const { page, per_page } = initialPaginationGetUsers;
        let search = "";

        getUsers(page, per_page, search).then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            const usersList = response.data.items;
            usersList.forEach((user) => {
              if (user.status !== 3 && user.status !== 4) {
                setUsersDinamic((prevState) => [
                  ...prevState,
                  {
                    value: user.uuid,
                    label: `${user.first_name} ${user.last_name} (${user.user_name})`,
                  },
                ]);
              }
            });
          } else {
            showAlertServiceError();
          }
        });
      });
    },
    [forcedUpdateFormName] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Fetches a list of work groups based on their status and number of items per page.
   * Populates the 'listNamesGroups' state with unique work group names and UUIDs.
   */
  const getWorkGroupsBySearch = useCallback(() => {
    getWorkGroupsByStatus(perPage, activeStatus).then((response) => {
      let listgroups = response.data.data.items;
      if (listgroups && listgroups !== null) {
        let uniqueListNamesGroups = listgroups.reduce((uniqueList, { name, uuid }) => {
          if (!uniqueList.some((group) => group.value === uuid)) {
            uniqueList.push({
              value: uuid,
              label: name,
            });
          }
          return uniqueList;
        }, []);
        setListNamesGroups(uniqueListNamesGroups);
      }
    });
  }, [getWorkGroupsByStatus, setListNamesGroups, activeStatus, perPage]);

  /**
   * This useEffect retrieves the name of a work group based on its UUID and sets it in the 'nameGroup' state.
   */
  useEffect(() => {
    const getNameOfWorkGroup = () => {
      const name = listNameGroups.find((group) => {
        if (group.value === idWorkGroup) {
          return group.label;
        }
        return null;
      });
      if (name !== undefined && name !== null) {
        setNameGroup(name.label);
      }
    };
    getNameOfWorkGroup();
  }, [listNameGroups, idWorkGroup]);

  /**
   * Retrieves a list of users associated with a specific process and populates the 'usersDinamicProcess' state with user data.
   */
  const getListUsersProcess = useCallback((id) => {
    getListUserByProcess(id).then((response) => {
      let listUsers = response.data.data.items;
      if (isNullOrUndefined(listUsers) === false) {
        listUsers
          .filter(
            (user) =>
              user.status !== enumsTypeStatusUser.RETIRED &&
              user.status !== enumsTypeStatusUser.INACTIVE
          )
          .sort((positionNameA, positionNameB) => {
            const nombresA = `${positionNameA.name} ${positionNameA.last_name} `.toLocaleLowerCase();
            const nombresB = `${positionNameB.name} ${positionNameB.last_name} `.toLocaleLowerCase();
            return nombresA.localeCompare(nombresB);
          })
          .forEach((user) => {
            setUsersDinamicProcess((prevState) => [
              ...prevState,
              {
                value: user.uuid,
                label: `${user.first_name} ${user.last_name} (${user.user_name})`,
              },
            ]);
          });
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches general users data and updates state with active users for dynamic selection.
   * Retrieves users data and filters out inactive and retired users.
   * This function fetches users data based on pagination settings and updates
   * the dynamic list of general users with active users only.
   * @returns {void}
   */
  const getGeneralUsers = useCallback(() => {
    const { page, per_page } = initialPaginationGetUsers;
    setIsLoadingGeneralusers(true);
    getUsers(page, per_page, "")
      .then((usersResponse) => {
        if (isNullOrUndefined(usersResponse.data.items) === false) {
          const usersList = usersResponse.data.items;
          usersList.forEach((user) => {
            if (
              user.status !== enumsTypeStatusUser.INACTIVE &&
              user.status !== enumsTypeStatusUser.RETIRED
            ) {
              setGeneralUsersDinamic((prevState) => [
                ...prevState,
                {
                  value: user.uuid,
                  label: `${user.first_name} ${user.last_name} (${user.user_name})`,
                },
              ]);
            }
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingGeneralusers(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Retrieves files associated with a specific process by its ID and updates the 'files' state.
   */
  const getFilesProcessById = useCallback(
    (processId) => {
      setIsLoadingFiles(true);
      getFilesByProcess(processId)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setFiles(response.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFiles(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Retrieves files associated with a specific task by its ID and updates the 'files' state.
   */
  const getFilesTasksById = useCallback((taskId) => {
    setIsLoadingFiles(true);
    getFilesByTask(taskId)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          setFiles(response.data);
        } else {
          showAlertServiceError();
        }
      })

      .finally(() => {
        setIsLoadingFiles(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches steps to return by process UUID and updates the state accordingly.
   * @param {string} processUuid The UUID of the process to fetch steps for.
   */
  const getStepsToReturnById = useCallback((processUuid) => {
    setIsLoadingStepsToReturn(true);
    getStepsToReturn(processUuid)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          let stepsList = response.data;
          if (stepsList.length === 0) {
            return swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("taskReturnNoStepsToReturn"),
              icon: "warning",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              setDropdownOpen(false);
              setShowModalReturnToStep(false);
            });
          } else {
            setShowModalReturnToStep(true);
            stepsList
              .sort((positionNameA, positionNameB) => {
                const nombresA = positionNameA.step_name.toLocaleLowerCase();
                const nombresB = positionNameB.step_name.toLocaleLowerCase();
                return nombresA.localeCompare(nombresB);
              })
              .forEach((step) => {
                setStepsToReturnList((prevState) => [
                  ...prevState,
                  {
                    value: step.uuid,
                    label: step.step_name,
                  },
                ]);
              });
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingStepsToReturn(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles a change in the responsible option, updating the 'responsibleOption' state with the selected value.
   */
  const handleOnChangeOption = (e) => {
    setResponsibleOption({
      ...responsibleOption,
      responsible: e.target.value,
    });
  };

  /**
   * Handles the change event when users are selected. Updates the 'addMembersList' or 'addMember' state
   * based on the 'responsibleOption' and handles errors.
   * @param {object} selectedOption - The selected options (an array of objects).
   */
  const handleOnChangeUsers = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsError(true);
    } else {
      if (responsibleOption.responsible === "2") {
        setAddMembersList({
          ...addMembersList,
          user_uuid_list: selectedOption.map((e) => e.value),
          step_uuid: stepUuid,
          workflow_uuid: workflowid,
        });
        setIsError(false);
      } else {
        setAddMember({
          ...addMember,
          user_uuid: selectedOption.value,
          step_uuid: stepUuid,
          workflow_uuid: workflowid,
        });
        setIsError(false);
      }
    }
  };

  /**
   * Handles the change event when a tab element is clicked.
   * It updates the 'reassign' state based on the tab's value and checkbox status.
   * @param {object} evetChange - The event object.
   */
  const handleOnChangeTab = (evetChange) => {
    const target = evetChange.target.value;
    const targetCheckbox = evetChange.target.checked;
    if (target.includes("false") || target.includes("true")) {
      setReassign({
        ...reassign,
        [evetChange.target.name]: Boolean(targetCheckbox),
      });
    }
  };

  /**
   * Handles the submission of a form for adding responsible users or sender selects.
   * - If responsibleOption is "1", it handles the addition of a responsible user.
   * - If responsibleOption is "2", it handles the addition of multiple responsible sender selects.
   * @param {object} evetChange - The event object.
   */
  const handleOnSubmit = (evetChange) => {
    if (responsibleOption.responsible === "1") {
      if (addMember.user_uuid === "") {
        setIsError(true);
        setIsLoading(false);
        evetChange.preventDefault();
        return;
      } else {
        setIsLoading(true);
        addResponsibleStep(addMember)
          .then((response) => {
            if (response.data.code === 5021) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createWorkflow.configurations.isSelectedUser"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
            setShowModal(false);
          });
      }
    } else if (responsibleOption.responsible === "2") {
      if (
        addMembersList.user_uuid_list.length === 0 ||
        addMembersList.user_uuid_list.length === numberOfMembers.minimumMembers - 1
      ) {
        setIsError(true);
        setIsLoading(false);
        evetChange.preventDefault();
        return;
      }
      if (addMembersList.user_uuid_list.length > numberOfMembers.maximumMembers) {
        setIsLoading(false);
        evetChange.preventDefault();
        return;
      }
      if (isError) {
        evetChange.preventDefault();
        return;
      } else {
        setIsError(false);
        setIsLoading(true);
        addResponsibleSenderSelects(addMembersList)
          .then((response) => {
            if (response.status === 201) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createWorkflow.configurations.isSelectedUser"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            }
          })
          .finally(() => {
            setIsLoading(false);
            setShowModal(false);
          });
      }
    }
  };

  /**
   * Handles the submission of the actions configuration form. If there are no errors,
   * it triggers the configuration of actions for a workflow step.
   * @param {object} _ - The event object.
   * @param {array} errors - The array of errors.
   */
  const handleOnSubmitActions = (_, errors) => {
    if (errors.length === 0) {
      setIsLoading(true);
      configureActions(workflowid, stepUuid, reassign)
        .then((response) => {
          if (response.status === 202) {
            const showAlertSaveReassign = () => {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("integration.EditConfigSalert"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then(() => {
                window.location.reload();
              });
            };
            showAlertSaveReassign();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Handles the submission of the task compliance form. Displays a confirmation dialog to fulfill the task.
   * @param {object} evetChange - The event object.
   */
  const handleOnSubmitComplytask = (evetChange) => {
    evetChange.preventDefault();
    const showAlertShure = () => {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("taskComply"),
        icon: "warning",
        timer: 10000,
        buttons: [i18n.t("createusers.createButton2"), i18n.t("modal.Done.footerButton")],
      }).then((willClose) => {
        if (willClose) {
          fulfillTasks(taskUuid, comply);
        } else {
          evetChange.preventDefault();
          setIsLoading(false);
        }
      });
    };
    showAlertShure();
  };

  /**
   * Retrieves active forms based on pagination configuration and updates the state accordingly.
   * @param {object} paginationForms - Object containing pagination parameters.
   * @param {number} paginationForms.page - The current page number.
   * @param {number} paginationForms.perPage - The number of items per page.
   * @param {string} paginationForms.search - The search query string.
   * @param {string} paginationForms.status - The status of the forms to be retrieved.
   * @param {string[]} paginationForms.actions - An array of actions associated with the forms.
   * @param {function} getForms - Function to retrieve forms based on provided parameters.
   * @param {function} setForms - State setter function for updating the list of forms.
   * @param {function} setIsLoading - State setter function to indicate loading status.
   * @returns {void}
   */
  const getActiveForms = useCallback(() => {
    const { page, perPage, search, status, actions } = paginationForms;
    setIsLoading(true);
    getForms(page, perPage, search, status, actions)
      .then((response) => {
        setForms(response.data.items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getForms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the alert for deleting the configuration form.
   * @returns {void}
   */
  const handleAlertDeleteConfigForm = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("WorkFlowEdit.deleteOk"),
      icon: "success",
      timer: 3000,
      button: i18n.t("modal.Done.footerButton"),
    });
    return;
  };

  /**
   * Handles the deletion of configuration data form in a workflow step.
   * @param {Event} eventDeleteFormInStep - The event triggered by form deletion.
   * @param {number} stepId - The ID of the workflow step.
   * @param {number} workflowId - The ID of the workflow.
   * @returns {void}
   */
  const handleDeleteConfigurationDataFormInStep = (eventDeleteFormInStep, stepId, workflowId) => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("WorkFlowEdit.delete"),
      icon: "warning",
      timer: 10000,
      buttons: [i18n.t("createusers.createButton2"), i18n.t("modal.Done.footerButton")],
    }).then((willDeleteWorkFlowConfigForm) => {
      if (willDeleteWorkFlowConfigForm) {
        deleteConfigurationDataFormInStep(workflowid, stepId).then(() => {
          setEditConfigStepsTab3FormData(false);
          setFormExternalName("");
          handleAlertDeleteConfigForm();
        });
      } else {
        eventDeleteFormInStep.preventDefault();
      }
    });
  };

  /**
   * Opens or closes the wizard process batch based on the current state.
   * @returns {void}
   */
  const openWizardProcessBatch = () => {
    setOpenWizardProcess((prevState) => !prevState);
  };

  return (
    <WorkflowContext.Provider
      value={{
        listWorkflowSteps,
        setListWorkflowSteps,
        nameProcess,
        setNameProcess,
        isLoading,
        setIsLoading,
        pagination,
        setPagination,
        getWorkFlowBySearch,
        getWorkFlowResponsibleById,
        loader,
        setLoader,
        responsibleUser,
        setResponsibleUser,
        responsibleList,
        setResponsibleList,
        typeResponsibleUser,
        setTypeResponsibleUser,
        userStep,
        setUserStep,
        usersDinamic,
        setUsersDinamic,
        responsibleOption,
        setResponsibleOption,
        handleOnChangeOption,
        showOptions,
        setShowOptions,
        selectedOption,
        showModal,
        setShowModal,
        addMember,
        setAddMember,
        addMembersList,
        setAddMembersList,
        handleOnClick,
        handleOnClose,
        isError,
        setIsError,
        handleOnChangeUsers,
        handleOnSubmit,
        getProcessById,
        process,
        status,
        isProcess,
        isProcessTask,
        setIsProcessTask,
        acordeon,
        handleOnClickAcordeon,
        showDetail,
        setShowDetail,
        reassign,
        setReassign,
        handleOnChangeTab,
        handleOnSubmitActions,
        getUsersBySearch,
        getWorkGroupsBySearch,
        getTasksBySearch,
        getTasksById,
        setTask,
        tasks,
        setTasks,
        totalPages,
        totalCount,
        task,
        setTaskUuid,
        type,
        priority,
        comply,
        handleOnSubmitComplytask,
        handleOnCloseTask,
        handleOnCloseProcess,
        getListUsersProcess,
        usersDinamicProcess,
        commentCreate,
        setCommentCreate,
        usersDinamicEdit,
        setUsersDinamicEdit,
        listReal,
        setListReal,
        typeResponsibleGroup,
        listNameGroups,
        setListNamesGroups,
        nameGroup,
        handleOnClickEditWorkGroup,
        isEditWorkGroupStep,
        idWorkGroup,
        showModalGroup,
        setShowModalGroup,
        getActiveForms,
        forms,
        dataFormSelected,
        setDataFormSelected,
        setViewAddFields,
        viewAddFields,
        fillingOutForm,
        setFillingOutForm,
        formName,
        formIdInfo,
        setFormIdInfo,
        nextStepId,
        workflowId,
        getFilesProcessById,
        getFilesTasksById,
        showModalReasign,
        setShowModalReasign,
        files,
        setFiles,
        isLoadingFiles,
        complyProcessGateWay,
        setComplyProcessGateWay,
        selectLabelSequenceFlow,
        setSelectLabelSequenceFlow,
        showInterruptProcess,
        showFormFillingProcess,
        setShowFormsFillingProcess,
        showExternalFormFillingProcess,
        setShowExternalFormsFillingProcess,
        processId,
        setProcessId,
        editConfigStepsTab3FormData,
        setEditConfigStepsTab3FormData,
        setForcedUpdateFormName,
        setFormName,
        formExternalName,
        setFormExternalName,
        requiredfilledform,
        setRequiredfilledform,
        fullfilCompleted,
        setFullfilCompleted,
        handleDeleteConfigurationDataFormInStep,
        handleAlertDeleteConfigForm,
        showExternalFormEdit,
        setShowExternalFormEdit,
        isLoadingResponsilblesList,
        setIsLoadingResponsilblesList,
        isCompletedTask,
        setIsCompletedTask,
        isFilter,
        setIsFilter,
        openModalFilter,
        setOpenModalFilter,
        isLoadingFilter,
        setIsLoadingFilter,
        getTasksByFilter,
        selectFormLabel,
        setSelectFormLabel,
        selectFormId,
        setSelectFormId,
        firstCheck,
        setFirstCheck,
        selectedOptions,
        setSelectedOptions,
        selectWorkflowId,
        setSelectWorkflowId,
        selectWorkflowLabel,
        setSelectWorkflowLabel,
        selectedOptionsWorkflow,
        setSelectedOptionsWorkflow,
        selectStepId,
        setSelelectStepId,
        selecStepLabel,
        setSelectStepLabel,
        selectedOptionsStep,
        setSelectedOptionsStep,
        selectFormIdContext,
        setSelectFormIdContext,
        formsDinamic,
        setFormsDinamic,
        workflowDinamic,
        setWorkflowDinamic,
        stepsDinamic,
        setStepsDinamic,
        selectedFields,
        setSelectedFields,
        isVisibleButtonProcessBatch,
        setIsVisibleButtonProccessBatch,
        openWizardProcess,
        setOpenWizardProcess,
        openWizardProcessBatch,
        disabledButtonBatchProcess,
        setDisabledButtonBatchProcess,
        complyTaskRequest,
        setComplyTaskRequest,
        processUuid,
        taskProcessId,
        isOpenModalPortal,
        setIsOpenModalPortal,
        commentCreateProcessBatch,
        setCommentCreateProcessBatch,
        configProcessBatch,
        setConfigProcessBatch,
        nextStepWizzard,
        setNextStepWizzard,
        currentStep,
        setCurrentStep,
        isLoadingComplyProcessBatch,
        setIsLoadingComplyProcessBatch,
        checkedOmitedUploadFile,
        setCheckedUploadFile,
        showPreviousButton,
        setShowPreviousButton,
        isNextStep,
        setIsNextStep,
        processTaskBatch,
        setProcessTaskBatch,
        structureQueryByFilterTask,
        setStructureQueryByFilterTask,
        isLoadingFilterTasks,
        setIsLoadingFilterTasks,
        querySearchForm,
        setQuerySearchForm,
        copiedSelectedOptionWorkflow,
        setCopiedSelectedOptionWorkflow,
        copiedSelectedOptionStep,
        setCopiedSelectedOptionStep,
        copiedSelectedOption,
        setCopiedSelectedOption,
        funcVerifyProcessBatch,
        uploadFormWorkflow,
        setUploadFormWorkflow,
        uploadFormStartDate,
        setUploadFormStartDate,
        taskDetail,
        setTaskDetail,
        showReassingn,
        reassignTaskUsers,
        setReassignTaskUsers,
        reassignProcessUsers,
        setReassignProcessUsers,
        reassignProcessBatch,
        setReassignProcessBatch,
        isLoadingReassingTask,
        setIsLoadingReassingTask,
        showModalReassignTask,
        setShowModalReassignTask,
        activateReassingBatchOption,
        activateInterruptBatchOption,
        taskSelectedByBatch,
        setTaskSelectedByBatch,
        formFilingName,
        checkedFilingForm,
        setCheckedFilingForm,
        isAdvancedFilterTask,
        setIsAdvancedFilterTask,
        formExternalList,
        orderHeaderBy,
        setOrderHeaderBy,
        orderHeaderType,
        setOrderHeaderType,
        filterDone,
        showModalReturnToStep,
        setShowModalReturnToStep,
        selectedOptionStepToReturn,
        isErrorReturnToStep,
        setIsErrorReturnToStep,
        returnToStepProcessData,
        setReturnToStepProcessData,
        getStepsToReturnById,
        isLoadingStepsToReturn,
        stepsToReturnList,
        setStepsToReturnList,
        isLoadingReturningTask,
        setIsLoadingReturningTask,
        dropdownOpen,
        setDropdownOpen,
        isLoadingSteps,
        isLoadingGeneralusers,
        generalUsersDinamic,
        setGeneralUsersDinamic,
        getGeneralUsers,
        reassignManagementProcessData,
        setReassignManagementProcessData,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
}
export function useWorkflowContext() {
  const context = React.useContext(WorkflowContext);
  if (!context) {
    throw new Error("useWorkflowContext debe estar dentro del proveedor WorkflowContext.");
  }
  return context;
}
