import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Button, Row } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import ModalForLookup from "components/molecules/ModalLookup/ModalLookup";
import i18n from "locales/i18n";

const Lookup = (props) => {
  const {
    id,
    hide,
    label,
    required,
    isPreview,
    isEdit,
    isFilterForm,
    handleOnChangeRecord,
    handleOnKeyPress,
    formLookupName,
    formLookupFields,
    formUuid,
    value,
  } = props;

  const {
    setShowModalForLookup,
    concatenatedValues,
    setConcatenatedValues,
    uuidRecordLookup,
    valueFieldLookup,
    setValueFieldLookup,
  } = useFieldsRecordContext();
  let hideLabelLookup = null;
  let showFormLookup = null;

  /**
   * Conditionally generates a JSX element to hide or show a labeled Lookup.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled Lookup.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled Lookup with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelLookup = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelLookup = null;
  }

  /**
   * Function to handle a click event and show a modal for lookup.
   * @returns {void}
   */
  const handleOnClickShowModal = () => {
    setShowModalForLookup(true);
  };

  /**
   * Clears the concatenated values and updates the state accordingly.
   * @param {function} setConcatenatedValues - The new value to set for concatenated values.
   * @returns {void}
   */
  const handleOnClear = () => {
    setConcatenatedValues("");
    setValueFieldLookup("");
    handleOnChangeRecord(id, null);
  };

  /**
   * A React useEffect hook that triggers a callback function when certain dependencies change.
   * This effect checks if the 'concatenatedValues' is not an empty string and calls the 'handleOnChangeRecord' function
   * with the provided 'id' and 'uuidRecordLookup'. If 'concatenatedValues' is empty, it returns null.
   * @param {string} concatenatedValues - The concatenated values to check for emptiness.
   * @param {string} id - The identifier for the record.
   * @param {string} uuidRecordLookup - The UUID used to look up the record.
   * @param {function} handleOnChangeRecord - The callback function to execute when 'concatenatedValues' is not empty.
   * @returns {null} Returns null when 'concatenatedValues' is empty.
   */
  useEffect(() => {
    if (concatenatedValues !== "") {
      handleOnChangeRecord(id, uuidRecordLookup);
    } else {
      return null;
    }
  }, [concatenatedValues, id, uuidRecordLookup]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that updates the 'valueFieldLookup' based on certain conditions.
   * This useEffect hook is designed to handle the updating of the 'valueFieldLookup' state
   * based on the provided conditions, which consider 'isFilterForm', 'isEdit', 'value', and 'concatenatedValues'.
   * @param {boolean} isFilterForm - A flag indicating whether the component is in filter form mode.
   * @param {boolean} isEdit - A flag indicating whether the component is in edit mode.
   * @param {any} value - The value to be considered for updating 'valueFieldLookup'.
   * @param {string} concatenatedValues - A string of concatenated values to be considered for updating 'valueFieldLookup'.
   * @param {function} setValueFieldLookup - A state setter function for updating 'valueFieldLookup'.
   * @returns {void}
   */
  useEffect(() => {
    if (isFilterForm) {
      if (value !== undefined && concatenatedValues === "") {
        setValueFieldLookup(value);
      } else {
        setValueFieldLookup(concatenatedValues);
      }
    } else if (isEdit) {
      if (value !== undefined && concatenatedValues === "") {
        setValueFieldLookup(value);
      } else {
        setValueFieldLookup(concatenatedValues);
      }
    } else {
      setValueFieldLookup(concatenatedValues);
    }
  }, [value, isEdit, concatenatedValues, isFilterForm]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders the form lookup component based on different conditions.
   * @param {Object} props - The props object.
   * @param {boolean} props.isPreview - Indicates if the component is in preview mode.
   * @param {boolean} props.hide - Indicates if the component should be hidden.
   * @param {boolean} props.isFilterForm - Indicates if the component is a filter form.
   * @param {string} props.id - The ID for the field.
   * @param {string} props.label - The label for the field.
   * @param {boolean} props.required - Indicates if the field is required.
   * @param {string} props.concatenatedValues - The concatenated values for the preview mode.
   * @param {string} props.valueFieldLookup - The value for the field lookup.
   * @param {Function} props.handleOnKeyPress - The function to handle key press events.
   * @param {Function} props.handleOnClickShowModal - The function to handle the show modal button click.
   * @param {Function} props.handleOnClear - The function to handle the clear button click.
   * @param {string} props.formLookupName - The name for the form lookup modal.
   * @param {Array} props.formLookupFields - The fields for the form lookup modal.
   * @param {string} props.formUuid - The UUID for the form.
   * @param {string} props.hideLabelLookup - The label for hiding the lookup.
   * @returns {JSX.Element} The rendered component.
   */
  if (isPreview === true) {
    showFormLookup = (
      <AvForm>
        <AvGroup row key={id}>
          <Label className={cx("", {
            "is-required": required === true
          })} sm={3}>
            {label} {hideLabelLookup}
          </Label>
          <Col md={8}>
            <AvField
              id={id}
              name={id}
              type="text"
              key={id}
              autoComplete="off"
              validate={{
                required: {
                  value: required,
                  errorMessage: `${i18n.t("fieldRequired")}`,
                },
              }}
              onKeyPress={handleOnKeyPress}
              value={concatenatedValues}
              disabled
            />
          </Col>
          <Col>
            <Row>
              <Button
                name="showModal"
                className="mr-1 btn-icon"
                size="sm"
                color="cyan"
                onClick={handleOnClickShowModal}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>

              <Button
                name="deleteData"
                className="btn-icon"
                size="sm"
                color="cyan"
                onClick={handleOnClear}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </Row>
          </Col>
        </AvGroup>
      </AvForm>
    );
  } else if (hide === false && isFilterForm === false) {
    showFormLookup = (
      <AvGroup row key={id}>
        <Label className={cx("", {
          "is-required": required === true
        })} sm={3}>
          {label}
        </Label>
        <Col md={8}>
          <AvField
            id={id}
            name={id}
            type="text"
            key={id}
            autoComplete="off"
            validate={{
              required: {
                value: required,
                errorMessage: `${i18n.t("fieldRequired")}`,
              },
            }}
            onKeyPress={handleOnKeyPress}
            value={valueFieldLookup}
            disabled
          />
        </Col>
        <Col>
          <Row>
            <Button
              name="showModal"
              className="mr-1 btn-icon"
              size="sm"
              color="cyan"
              onClick={handleOnClickShowModal}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>

            <Button
              name="deleteData"
              className="btn-icon"
              size="sm"
              color="cyan"
              onClick={handleOnClear}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Row>
        </Col>
      </AvGroup>
    );
  } else if (isFilterForm === true) {
    showFormLookup = (
      <AvGroup row key={id}>
        <Col md={9}>
          <AvField
            id={id}
            name={id}
            type="text"
            key={id}
            autoComplete="off"
            validate={{
              required: {
                value: required,
                errorMessage: `${i18n.t("fieldRequired")}`,
              },
            }}
            onKeyPress={handleOnKeyPress}
            value={valueFieldLookup}
            disabled
          />
        </Col>
        <Col>
          <Row>
            <Button
              name="showModal"
              className="mr-1 btn-icon"
              size="sm"
              color="cyan"
              onClick={handleOnClickShowModal}
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </Button>
          </Row>
        </Col>
      </AvGroup>
    );
  };

  return (
    <Fragment>
      {showFormLookup}
      <ModalForLookup
        formLookupName={formLookupName}
        formLookupFields={formLookupFields}
        formUuid={formUuid}
      />
    </Fragment>
  );
};

Lookup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  isFilterForm: PropTypes.bool,
  handleOnChangeRecord: PropTypes.func.isRequired,
  handleOnKeyPress: PropTypes.func.isRequired,
  formLookupName: PropTypes.string.isRequired,
  formLookupFields: PropTypes.object.isRequired,
  formUuid: PropTypes.string.isRequired,
};
export default Lookup;
