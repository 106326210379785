import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import useDashboard from "hooks/useDashboard";
import { Loader } from "react-loaders";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Chart from "react-apexcharts";
import i18n from "locales/i18n";

const MetricsContent = () => {
  const { getStorageConsumed, getTotalRecords } = useDashboard();
  const [metrics, setMetrics] = useState({});
  const [series, setSeries] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [month, setMonth] = useState("");
  const [recordsByDay, setRecordsByDay] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [year, setYear] = useState(0);
  const monthNames = {
    1: i18n.t("metrics.Month1"),
    2: i18n.t("metrics.Month2"),
    3: i18n.t("metrics.Month3"),
    4: i18n.t("metrics.Month4"),
    5: i18n.t("metrics.Month5"),
    6: i18n.t("metrics.Month6"),
    7: i18n.t("metrics.Month7"),
    8: i18n.t("metrics.Month8"),
    9: i18n.t("metrics.Month9"),
    10: i18n.t("metrics.Month10"),
    11: i18n.t("metrics.Month11"),
    12: i18n.t("metrics.Month12"),
  };

  const [optionRadial] = useState({
    labels: [i18n.t("dashboardheadingMetricsConsumed")],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            offsetY: -10,
            fontSize: "20px",
            color: "#0072bc",
          },
          value: {
            fontSize: "20px",
            color: "#033c73",
            fontWeight: "bold",
          },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15,
            },
          },
        },
        hollow: {
          size: "60%",
          background: "#e9ecef",
        },
      },
      noData: {
        text: i18n.t("metrics.noData"),
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: "#033c73",
          fontSize: "14px",
        },
      },
    },
    colors: ["#00b85d"],
    stroke: {
      lineCap: "round",
    },
  });

  const [optionBar] = useState({
    plotOptions: {
      bar: {
        borderRadius: 5,
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#033c73"],
      },
    },

    legend: {
      show: false,
    },

    noData: {
      text: i18n.t("metrics.noData"),
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#033c73",
        fontSize: "14px",
      },
    },
    colors: ["#00b85d"],
    xaxis: {
      tickAmount: "dataPoints",
      title: {
        text: i18n.t("metrics.XLegend"),
        style: {
          color: "#033c73",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    yaxis: {
      title: {
        text: i18n.t("metrics.YLegend"),
        style: {
          color: "#033c73",
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-title",
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },

    tooltip: {
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  });

  /**
   *Function that retrieves storage metrics and updates the component state accordingly.
   *@function
   *@name getMetrics
   *@async
   *@returns {void}
   *@param {function} getStorageConsumed - The function to fetch storage consumption data. It should return a Promise.
   *@param {function} setIsLoading - State setter function to update the loading state of the component.
   *@param {function} setMetrics - State setter function to update the storage metrics data of the component.
   *@param {function} setSeries - State setter function to update the storage metrics percentage series of the component.
   */
  const getMetrics = useCallback(() => {
    getStorageConsumed()
      .then((response) => {
        setIsLoading(true);
        setMetrics(response.data);
        setSeries(response.data.percentage);
      })

      .finally(() => {
        setIsLoading(false);
      });
  }, [getStorageConsumed]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *Retrieves and processes records data from the server using the 'getTotalRecords' function and updates component state accordingly.
   *@function
   *@name getRecords
   *@async
   *@param {function} getTotalRecords - A function that fetches the total records from the server
   *@param {Array} monthNames - An array of month names, where the index represents the month number (e.g., monthNames[0] = "January")
   *@param {function} setRecordsByDay - The state setter function to update the records data grouped by day and month
   *@param {function} setIsLoading - The state setter function to update the loading state of the component
   *@param {function} setTotalRecords - The state setter function to update the total number of records
   *@param {function} setYear - The state setter function to update the year
   *@param {function} setMonth - The state setter function to update the month
   */
  const getRecords = useCallback(() => {
    getTotalRecords()
      .then((response) => {
        if (response.data.days_list) {
          let valueFinal = response.data.month;
          let dayList = response.data.days_list;
          const axis = dayList.map((axisElement) => ({
            x: axisElement.day + ` ${monthNames[valueFinal] || ""}`,
            y: axisElement.total_records,
          }));
          setRecordsByDay(axis);
          setTotalRecords(response.data.total_records);
          setYear(response.data.year);
          setMonth(monthNames[valueFinal] || null);
        } else {
          setRecordsByDay([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getTotalRecords]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that calls the 'getMetrics' function when the component is mounted.
   *@function
   *@name getMetrics
   *@param {function} getMetrics - The function responsible for fetching and updating metrics data.
   */
  useEffect(() => {
    getMetrics();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that calls the 'getRecords' function to fetch and update records when the component mounts.
   *@function getRecords - A function responsible for fetching and updating records in the component's state.
   */
  useEffect(() => {
    getRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Row>
            <Col md="5">
              <Card className="mb-3  card-shadow-primary widget-chart">
                <CardHeader className="h-auto p-3 bg-third ">
                  <i className="header-icon lnr-database text-white"> </i>
                  <div>
                    <h5 className="menu-header-title text-capitalize text-white text-left">
                      {i18n.t("metrics.HeaderStorage")}
                    </h5>
                    <h6 className="menu-header-subtitle text-capitalize opacity-8 text-white text-left ">
                      {i18n.t("dashboardheadingMetricsConsumed")}
                    </h6>
                  </div>

                  <div className="btn-actions-pane-right">
                    <Button
                      type="submit"
                      size="lg"
                      disabled={isLoading}
                      className="col-mt-3"
                      color="cyan"
                      onClick={() => getMetrics()}
                    >
                      <FontAwesomeIcon
                        icon={faSyncAlt}
                        size="lg"
                        className="text-white fa-flip-horizontal"
                      />
                      {(() => {
                        if (isLoading === true) {
                          return (
                            <Spinner size="sm" color="secondary" type="grow" />
                          );
                        }
                      })()}
                    </Button>
                  </div>
                </CardHeader>
                {(() => {
                  if (isLoading === true) {
                    return (
                      <div className="text-center mx-auto my-auto">
                        <div className="loader-wrapper d-flex justify-content-center align-items-center">
                          <Loader color="#0072BC" type="ball-pulse-rise" />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <div className="mr-3 d-flex justify-content-center ">
                          <Chart
                            options={optionRadial}
                            series={[series]}
                            type="radialBar"
                            height="300"
                          />
                        </div>
                        <div className="card-footer metrics-content w-100">
                          <h6
                            className="text-center mx-auto my-auto text-primary"
                            style={{ fontSize: "20px" }}
                          >
                            <span className="text-cyan font-weight-bold">
                              {metrics.storage_consumed} GB
                            </span>{" "}
                            {i18n.t("modal.DoneError.metrics.of")}{" "}
                            <span className="text-primary font-weight-bold">
                              {metrics.allocated_storage} GB
                            </span>{" "}
                            {i18n.t("modal.DoneError.metrics.hired")}
                          </h6>
                        </div>
                      </div>
                    );
                  }
                })()}
              </Card>
            </Col>

            <Col md="7">
              <Card className="mb-3  card-shadow-primary widget-chart">
                <CardHeader className="h-auto p-3 bg-third ">
                  <i className="header-icon pe-7s-pen text-white"> </i>
                  <div>
                    <h5 className="menu-header-title text-capitalize text-white text-left">
                      {i18n.t("metrics.HeaderRecords")}
                    </h5>
                    <h6 className="menu-header-subtitle text-capitalize opacity-8 text-white text-left">
                      {i18n.t("metrics.subHeaderRecords")}
                    </h6>
                  </div>

                  <div className="btn-actions-pane-right">
                    <UncontrolledDropdown>
                      <DropdownToggle className="p-0 mr-2" color="link">
                        <Button size="lg" className="col-mt-3" color="cyan">
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            size="lg"
                            className="text-white fa-flip-horizontal"
                          />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="dropdown-menu-xl rm-pointers"
                      >
                        <div className="grid-menu grid-menu-xl grid-menu-2col">
                          <Row className="no-gutters">
                            <Col xl="6" sm="6">
                              <Link
                                className="no-underline"
                                to={"/metrics/recordListByYear"}
                              >
                                <Button
                                  className="btn-icon-vertical btn-square btn-transition"
                                  outline
                                  color="link"
                                >
                                  <i className="lnr-apartment icon-primary  btn-icon-wrapper btn-icon-lg mb-3">
                                    {" "}
                                  </i>
                                  {i18n.t("metrics.RecordsByformYearMonth")}
                                </Button>
                              </Link>
                            </Col>
                            <Col xl="6" sm="6">
                              <Link
                                className="no-underline"
                                to={"/metrics/recordListbyForm"}
                              >
                                <Button
                                  className="btn-icon-vertical btn-square btn-transition"
                                  outline
                                  color="link"
                                >
                                  <i className="lnr-database icon-primary btn-icon-wrapper btn-icon-lg mb-3">
                                    {" "}
                                  </i>
                                  {i18n.t("metrics.RecordsByformLabel")}
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </CardHeader>
                {(() => {
                  if (isLoading === true) {
                    return (
                      <div className="text-center mx-auto my-auto">
                        <div className="loader-wrapper d-flex justify-content-center align-items-center">
                          <Loader color="#0072BC" type="ball-pulse-rise" />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <Row className="text-left " style={{ padding: "10px" }}>
                          <span className="text-left badge badge-success">
                            {`${month} ${year}`}
                          </span>
                        </Row>
                        <div>
                          <Chart
                            options={optionBar}
                            series={[
                              {
                                name: i18n.t("dashboard.label3"),
                                data: recordsByDay,
                              },
                            ]}
                            type="bar"
                            height="223"
                          />
                        </div>
                        <div className="card-footer metrics-content w-100">
                          <h6
                            className="text-center mx-auto my-auto text-primary"
                            style={{ fontSize: "20px" }}
                          >
                            <span className="text-cyan font-weight-bold">
                              {i18n.t("metrics.totalRecords")}:
                            </span>{" "}
                            <span className="text-primary font-weight-bold">
                              {totalRecords}
                            </span>{" "}
                          </h6>
                        </div>
                      </div>
                    );
                  }
                })()}
              </Card>
            </Col>
          </Row>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default MetricsContent;
