import React from "react";
import RecordDetails from "./RecordDetails";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <RecordDetails isRecordCreate={true} />
    </RecordDetailContextProvider>
  );
};
