import React, { Fragment, useState, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import {
  AiFillCaretRight,
  AiOutlineFolder,
  AiFillFolderOpen,
} from "react-icons/ai";
import { Loader } from "react-loaders";
import { useTrdContext } from "contextAPI/TrdContext";
import { isNullOrUndefined } from "utils/validations";
import { initialPaginationExpedientTree } from "utils/initialPaginationsConfig";
import { showAlert } from "components/molecules/Alert/Alert";
import useTrd from "hooks/useTrd";
import SearchBoxExpedientExplorer from "components/atoms/SearchBoxExpedientExplorer";
import TrdExpedientConsultationTable from "components/organism/TrdExpedientConsultationTable";
import i18n from "locales/i18n";

const TrdExpedientConsultationTree = () => {
  const [expandedNodeTree, setExpandedNodeTree] = useState([]);
  const [selectedNodeTree, setSelectedNodeTree] = useState(null);
  const [selectedNodeTreeId, setSelectedNodeTreeId] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [hasFetchedExpedients, setHasFetchedExpedients] = useState(false);
  const [listExpedients, setListExpedients] = useState([]);
  const [isLoadingExpedients, setIsLoadingExpedients] = useState(false);
  const [paginationTables, setPaginationTables] = useState(initialPaginationExpedientTree);
  const [totalPages, setTotalPages] = useState(0);
  const [pathRedirection, setPathRedirection] = useState([]);
  const [searchExpedient, setSearchExpedient] = useState("");
  const [changeSearchFile, setChangeSearchFile] = useState(true);
  const { getTreeByDomainCallback, structureTrd, isLoading } = useTrdContext();
  const { getExpedientsByName } = useTrd();
  const roots = structureTrd.filter((nodeTree) => nodeTree.parentId === null);

  /**
   *Function that toggles the expanded state of a tree node and performs related operations.
   *@param {string} nodeId - the ID of the node to toggle the expanded state
   *@param {array} structureTrd - an array of objects representing the tree structure
   *@param {array} expandedNodeTree - an array of node IDs representing the currently expanded nodes in the tree
   *@param {function} setExpandedNodeTree - the state setter function for the array of expanded node IDs
   *@param {number} selectedNodeTreeId - the ID of the currently selected node in the tree
   *@param {function} setSelectedNodeTreeId - the state setter function for the selected node ID
   *@param {function} updateCurrentPath - a function that updates the current path based on the selected node ID
   *@param {boolean} hasFetchedExpedients - a boolean value indicating whether the expedients have been fetched
   *@param {function} setHasFetchedExpedients - the state setter function for the hasFetchedExpedients variable
   */
  const toggleExpandedTree = (nodeId) => {
    const backgrund = structureTrd.find((nodeTree) => nodeTree.id === nodeId);
    const expedientNodeId = expandedNodeTree.includes(nodeId);
    if (expedientNodeId === true) {
      setExpandedNodeTree(
        expandedNodeTree.filter((nodeExpanded) => nodeExpanded !== nodeId)
      );
      if (backgrund.level === 0) {
        setExpandedNodeTree([]);
      }
    } else {
      setExpandedNodeTree([...expandedNodeTree, nodeId]);
    }
    setSelectedNodeTreeId(nodeId);
    updateCurrentPath(nodeId);
    setHasFetchedExpedients(false);
  };

  /**
   *Updates the current path based on a given nodeId.
   *@param {number|null} nodeId - The id of the node to update the current path for. If null, the current path will be cleared.
   *@param {array} structureTrd - An array representing the structure tree to search for the node.
   *@param {array} expandedNodeTree - An array of ids representing the expanded nodes in the tree.
   *@param {function} setPathRedirection - The state setter function for the path redirection.
   *@param {function} setCurrentPath - The state setter function for the current path.
   */
  const updateCurrentPath = (nodeId) => {
    if (nodeId === null) {
      setCurrentPath([]);
      return;
    }
    const getNodeById = (id) =>
      structureTrd.find((nodeTree) => nodeTree.id === id);
    const nodePath = getNodeById(nodeId);
    if (nodePath !== null) {
      const path = [nodePath];
      let parentId = nodePath.parentId;
      const isExpandedNodeTree = expandedNodeTree.includes(nodeId);
      while (parentId !== null) {
        const parentNode = getNodeById(parentId);

        if (parentNode !== null) {
          path.unshift(parentNode);

          parentId = parentNode.parentId;
        } else {
          break;
        }
      }
      const pathWithLabels = path.map((nodePath) => nodePath.label);
      const objectPath = path.map((nodePath) => nodePath);

      if (isExpandedNodeTree === false) {
        setPathRedirection(objectPath);
        setCurrentPath(pathWithLabels);
      } else {
        setCurrentPath(pathWithLabels.slice(0, -1));
      }
    }
  };

  /**
   *Handles the click event on a path node in a tree structure.
   *@param {number} index - The index of the pathRedirection array indicating the node to handle the click event on.
   *@param {object} node - The pathRedirection node object to handle the click event on.
   *@param {number} nodeId - The id of the selected node.
   *@param {array} expandedNodeTree - An array of node ids representing the expanded nodes in the tree.
   *@param {function} setSelectedNodeTree - A state setter function for the selected node in the tree.
   *@param {function} setSelectedNodeTreeId - A state setter function for the id of the selected node in the tree.
   *@param {boolean} hasFetchedExpedients - A boolean indicating if the expedients have been fetched.
   *@param {function} setHasFetchedExpedients - A state setter function for the hasFetchedExpedients variable.
   *@param {function} renderChildrenInTable - A function that renders the children of a node in a table.
   */
  const handleOnClickPath = (index) => {
    const node = pathRedirection[index];
    const nodeId = node.id;
    const isExpandedNodeTree = expandedNodeTree.includes(nodeId);
    if (isExpandedNodeTree === true) {
      setSelectedNodeTree(node);
      setSelectedNodeTreeId(nodeId);
      setHasFetchedExpedients(false);
      setChangeSearchFile(true);
      if (
        pathRedirection[index].hasExpedients === true &&
        !hasFetchedExpedients
      ) {
        renderChildrenInTable(node);
      }
    }
  };

  /**
   *Function that handles the click event on a node in the tree structure.
   *@param {object} node - the node object representing the clicked node
   *@param {array} expandedNodeTree - an array containing the ids of the expanded nodes in the tree structure
   *@param {function} toggleExpandedTree - a function to toggle the expanded state of a node in the tree structure
   *@param {array} structureTrd - an array representing the tree structure
   *@param {function} setSelectedNodeTree - a function to set the selected node in the tree structure
   *@param {string} setSelectedNodeTreeId - a function to set the id of the selected node in the tree structure
   */
  const handleNodeClick = (node) => {
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    toggleExpandedTree(node.id);
    if (isExpandedNodeTree === false) {
      setSelectedNodeTree(node);
      setSelectedNodeTreeId(node.id);
      setChangeSearchFile(true);
      setSearchExpedient("");
    } else {
      setSelectedNodeTree(
        structureTrd.find((item) => item.id === node.parentId)
      );
      setSelectedNodeTreeId(node.parentId);
      setChangeSearchFile(true);
      setSearchExpedient("");
    }
  };

  /**
   *Renders a single node of a tree structure.
   *@param {object} node - The node object representing a single node in the tree structure.
   *@param {array} structureTrd - The array representing the entire tree structure.
   *@param {array} expandedNodeTree - The array of expanded node IDs in the tree.
   *@param {number} selectedNodeTreeId - The ID of the currently selected node in the tree.
   *@param {function} toggleExpandedTree - The function to toggle the expanded state of a node in the tree.
   *@param {function} handleNodeClick - The function to handle a click event on a node.
   *@returns {JSX.Element} - The rendered JSX element representing the node.
   */
  const renderNodeFromTree = (node) => {
    const hasChildren = structureTrd.some((item) => item.parentId === null);
    const isExpandedNodeTree = expandedNodeTree.includes(node.id);
    let nodeStyle = "nodeStyle";
    let customExpandedStyle = "expandedStyle";
    let customLabelBackgroundStyle = "text-primary alingText";
    let iconFolder = null;
    if (selectedNodeTreeId === node.id) {
      nodeStyle += " selected";
    }
    if (isExpandedNodeTree === true) {
      customExpandedStyle += " isExpanded";
    }
    if (node.parentId === null) {
      customLabelBackgroundStyle += " font-weight-bold";
    }

    if (isExpandedNodeTree === true) {
      iconFolder = <AiFillFolderOpen className="folderIcon" />;
    } else {
      iconFolder = <AiOutlineFolder className="folderIcon" />;
    }
    return (
      <div key={node.id}>
        <div
          className={nodeStyle}
          onClick={() => {
            toggleExpandedTree(node.id);
            handleNodeClick(node);
          }}
        >
          <AiFillCaretRight className={customExpandedStyle} /> &nbsp;
          {iconFolder}
          <span className={customLabelBackgroundStyle}>{node.label}</span>
        </div>
        {hasChildren === true && isExpandedNodeTree === true && (
          <div className="hasChildren">
            {structureTrd
              .filter((nodeTree) => nodeTree.parentId === node.id)
              .map((child) => renderNodeFromTree(child))}
          </div>
        )}
      </div>
    );
  };

  /**
   *Retrieves expedients data based on the provided parameters and updates the corresponding state variables.
   *@param {number} page - the page number of the expedients data to retrieve
   *@param {number} per_page - the number of expedients per page to retrieve
   *@param {number} nodeId - the ID of the node associated with the expedients
   *@param {number} background_parent_id - the ID of the background parent associated with the expedients
   *@param {string} searchExpedient - the search query for filtering expedients
   *@param {function} setIsLoadingExpedients - the state setter function for the loading state of expedients
   *@param {function} getExpedientsByName - the function that retrieves the expedients data based on the provided parameters
   *@param {object} i18n - the internationalization object for translating text
   *@param {function} swal - the function for displaying a modal popup
   *@param {function} setSearchExpedient - the state setter function for the search query of expedients
   *@param {function} setListExpedients - the state setter function for the list of expedients
   *@param {function} setTotalPages - the state setter function for the total number of pages of expedients
   *@param {number} selectedNodeTreeId - the ID of the selected node in the tree
   *@param {object} selectedNodeTree - the selected node object in the tree
   */
  const getExpedientsData = (
    page,
    per_page,
    nodeId,
    background_parent_id,
    searchExpedient
  ) => {
    setIsLoadingExpedients(true);
    getExpedientsByName(
      page,
      per_page,
      nodeId,
      background_parent_id,
      searchExpedient
    )
      .then((response) => {
        if (response.data.items.length === 0) {
          if (searchExpedient !== "") {
            showAlert({
              text: i18n.t("trd.expedientConsultationSearch"),
              icon: "info",
            }).then(() => {
              setSearchExpedient("");
              getExpedientsData(
                page,
                per_page,
                selectedNodeTreeId,
                selectedNodeTree.background_parent_id,
                ""
              );
            });
          } else {
            return showAlert({
              text: i18n.t("expedientConsultationSearchEmpty"),
              icon: "info",
            })
          }
        }
        setListExpedients(response.data.items);
        setTotalPages(response.data.pages);
      })
      .finally(() => {
        setIsLoadingExpedients(false);
      });
  };

  /**
   *Renders the children nodes of a given parent node in a table format.
   *@param {object} node - the parent node for which to render the children nodes
   *@param {array} structureTrd - the array of all nodes in the structure tree
   *@param {boolean} hasFetchedExpedients - indicates whether the expedient data has been fetched for the parent node
   *@param {object} paginationTables - the pagination information for the expedient data
   *@param {function} getExpedientsData - the function to fetch the expedient data
   *@param {function} setHasFetchedExpedients - the function to set the flag indicating whether expedient data has been fetched
   *@param {string} searchExpedient - the search query for the expedient data
   *@param {function} setSearchExpedient - the function to set the search query for the expedient data
   *@param {boolean} isLoadingExpedients - indicates whether the expedient data is currently being loaded
   *@param {array} listExpedients - the list of expedients to be displayed in the table
   *@param {number} totalPages - the total number of pages for the expedient data pagination
   *@param {function} setPaginationTables - the function to set the pagination information for the expedient data
   *@param {array} expandedNodeTree - the list of expanded node IDs in the structure tree
   *@param {function} toggleExpandedTree - the function to toggle the expansion of a node in the structure tree
   *@param {object} selectedNodeTree - the currently selected node in the structure tree
   *@param {number} selectedNodeTreeId - the ID of the currently selected node in the structure tree
   *@param {function} setSelectedNodeTree - the function to set the currently selected node in the structure tree
   *@param {function} setSelectedNodeTreeId - the function to set the ID of the currently selected node in the structure tree
   *@returns {ReactNode} The rendered table rows representing the children nodes
   */
  const renderChildrenInTable = (node) => {
    const children = structureTrd.filter(
      (nodeTree) => nodeTree.parentId === node.id
    );
    const hasExpedients = node.has_expedients === true;
    let loaderTableExpedients = null;
    if (hasExpedients === true && isNullOrUndefined(hasExpedients) === false
      && hasFetchedExpedients === false) {
      const { page, per_page } = paginationTables;
      getExpedientsData(
        page,
        per_page,
        node.id,
        node.background_parent_id,
        searchExpedient
      );
      setHasFetchedExpedients(true);
    }

    if (isLoadingExpedients === true) {
      loaderTableExpedients = (
        <div className="my-5">
          <div className="d-flex justify-content-center">
            <Loader
              className="loaderIcon"
              color="#0072BC"
              type="ball-pulse-rise"
              size="small"
            />
          </div>
        </div>
      );
    } else {
      loaderTableExpedients = (
        <div>
          <div className="ml-auto">
            {(() => {
              if (changeSearchFile === true) {
                return (
                  <SearchBoxExpedientExplorer
                    setSearchExpedient={setSearchExpedient}
                    searchExpedient={searchExpedient}
                    paginationTables={paginationTables}
                    setPaginationTables={setPaginationTables}
                    getExpedientsData={getExpedientsData}
                    node={node}
                  />
                );
              }
            })()}
          </div>
          <br />
          <div className="d-flex flex-column h-lg-table">
            <TrdExpedientConsultationTable
              listExpedients={listExpedients}
              paginationTables={paginationTables}
              isLoadingExpedients={isLoadingExpedients}
              totalPages={totalPages}
              setPaginationTables={setPaginationTables}
              setChangeSearchFile={setChangeSearchFile}
              getExpedientsData={getExpedientsData}
            />
          </div>
        </div>
      );
    }
    if (hasExpedients === true) {
      return <Fragment>{loaderTableExpedients}</Fragment>;
    } else if (children.length === 0) {
      return (
        <tr>
          <td className="text-primary">
            {i18n.t("trd.expedientConsultationExpedientExpedientEmpty")}
          </td>
        </tr>
      );
    }
    return children.map((child) => (
      <tr
        key={child.id}
        onDoubleClick={() => {
          const isExpandedNodeTree = expandedNodeTree.includes(node.id);
          if (isExpandedNodeTree) {
            toggleExpandedTree(child.id);
            setSelectedNodeTree(child);
            setSelectedNodeTreeId(child.id);
          }
        }}
      >
        <td>
          <AiOutlineFolder className="folderIconParallelView" /> &nbsp;
          <span className="btn-link cursor">{child.label}</span>
        </td>
      </tr>
    ));
  };

  /**
   *Generates the content to be rendered in a table based on the selected node tree.
   *@param {object} selectedNodeTree - the selected node tree object
   *@param {array} currentPath - an array of labels representing the current path in the node tree
   *@param {function} handleOnClickPath - a function to handle click events on the path links
   *@param {boolean} hasFetchedExpedients - a boolean value indicating if the expedients have been fetched
   *@param {function} i18n.t - a function for translating text using internationalization
   *@returns {JSX.Element} - the JSX element representing the table content
   */
  let renderTableContent;
  if (selectedNodeTreeId !== null) {
    renderTableContent = (
      <Fragment>
        <span>
          {currentPath.map((label, index) => (
            <span key={index}>
              <Link to="#" onClick={() => handleOnClickPath(index)}>
                <h5 className="path-display">{label}</h5>
              </Link>
              {index !== currentPath.length - 1 && " / "}
            </span>
          ))}
        </span>
        <br />
        <br />

        <table className="table">
          <thead>
            <tr>
              {!hasFetchedExpedients && (
                <th className="text-primary">
                  {i18n.t("trd.fromLabel2").toUpperCase()}
                </th>
              )}
            </tr>
          </thead>
          <tbody>{renderChildrenInTable(selectedNodeTree)}</tbody>
        </table>
      </Fragment>
    );
  } else {
    renderTableContent = (
      <span className="text-primary">
        {i18n.t("trd.expedientConsultationEmpty")}
      </span>
    );
  }

  /**
   *useEffect hook that fetches tree data for a domain and sets the corresponding state variable of the component.
   *The effect will be triggered whenever the 'getTreeByDomainCallback' function changes.
   *@param {function} getTreeByDomainCallback - the function that fetches the tree data for a domain and sets the corresponding state variable
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  /**
   *useEffect hook that fetches expedients data based on the current pagination settings when 'hasFetchedExpedients' flag is true.
   *@param {boolean} hasFetchedExpedients - a flag indicating whether expedients data has been fetched
   *@param {object} paginationTables - an object containing pagination settings including 'page' and 'per_page'
   *@param {number} paginationTables.page - the current page number for pagination
   *@param {number} paginationTables.per_page - the number of items per page for pagination
   *@param {string} selectedNodeTreeId - the id of the selected node in the tree
   *@param {string} selectedNodeTree.background_parent_id - the background parent id of the selected node in the tree
   *@param {string} searchExpedient - the search query for filtering expedients data
   *@param {function} getExpedientsData - the function that fetches expedients data based on the provided parameters
   */
  useEffect(() => {
    if (hasFetchedExpedients === true) {
      const { page, per_page } = paginationTables;
      getExpedientsData(
        page,
        per_page,
        selectedNodeTreeId,
        selectedNodeTree.background_parent_id,
        searchExpedient
      );
    }
  }, [paginationTables.page]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * React hook to update pagination tables when the selected node tree ID changes.
   * @param {function} setPaginationTables - A function to update pagination tables state.
   * @param {Object} initialPaginationExpedientTree - Initial pagination state for expedient tree.
   * @param {String} selectedNodeTreeId - The ID of the selected node in the tree.
   * @returns {void}
   */
  useEffect(() => {
    setPaginationTables(initialPaginationExpedientTree);
  }, [selectedNodeTreeId])

  /**
 * Renders a loader component if the isLoading state is true.
 * @param {boolean} isLoading - Indicates whether loading is in progress.
 * @returns {JSX.Element|null} Loader component JSX if isLoading state is true, otherwise null.
 */
  if (isLoading === true) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader className="primary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardHeader>
            <h5 className="text-cyan text-bold p-3">
              {i18n.t("trd.expedientConsultationExpedientCard")}
            </h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={3}>
                <div className="d-flex flex-column h-lg w-100">
                  <Card color="blueGray" className="card-border p-2 h-lg">
                    {roots.map((node) => renderNodeFromTree(node))}
                  </Card>
                </div>
              </Col>
              <Col md={9}>
                <div className="d-flex flex-column h-lg w-100">
                  <Card className="card-border h-lg">
                    <CardBody>{renderTableContent}</CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default TrdExpedientConsultationTree;
