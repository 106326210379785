import i18n from "locales/i18n";

export const trdLevel = {
  SECTION: `(${i18n.t("trd.formTittle10")})`,
  SUBSECTION: `(${i18n.t("trd.formTittle11")})`,
  SERIE: `(${i18n.t("trd.formTittle12")})`,
  SUBSERIE: `(${i18n.t("trd.formTittle13")})`
}

export const numberLevel = {
  SECTION: 1,
  SUBSECTION: 2,
  SERIE: 3,
  SUBSERIE: 4
}

/**
 * Extracts and formats the name of a TRD (Technical Requirement Document) by removing the level indicator (`typeTrdLevel`) from the original name.
 * The function looks for the last occurrence of the '-' character in `originalName`, and if found, it extracts the portion
 * of the name after that occurrence and trims any leading or trailing whitespace.
 * It then removes `typeTrdLevel` from the extracted portion and trims any leading or trailing whitespace again.
 * If the '-' character is not found in `originalName`, the function returns `originalName` unchanged.
 * @param {string} originalName - The original name of the TRD.
 * @param {string} typeTrdLevel - The level indicator to be removed from the name.
 * @returns {string} The formatted name of the TRD after removing the level indicator.
 */

export const getNameTrdByLevel = (originalName, typeTrdLevel) => {
  const lastIndex = originalName.lastIndexOf('-');
  if (lastIndex !== -1) {
    let formattedName = originalName.slice(lastIndex + 1).trim();
    formattedName = formattedName.replace(typeTrdLevel, '').trim();
    return formattedName;
  } else {
    return originalName;
  }
}

/**
 * Extracts and formats the name of a TRD (Technical Requirement Document) by removing the level indicator (`typeTrdLevel`)
 * from the original name.
 *
 * The function uses a regular expression to look for content enclosed in parentheses in `originalName`. If found, it extracts
 * the content inside the parentheses and trims any leading or trailing whitespace. It then looks for the last occurrence of
 * the '-' character before the parentheses content and extracts the text after the last hyphen. The extracted text and the
 * content inside the parentheses are then combined to form the result in the format: `textAfterLastHyphen (contentInParentheses)`.
 *
 * The `typeTrdLevel` is then removed from the result, and any leading or trailing whitespace is trimmed again.
 *
 * If the regular expression doesn't match in `originalName`, the function extracts the text after the last hyphen and trims any
 * leading or trailing whitespace as the result.
 *
 * @param {string} originalName - The original name of the TRD.
 * @param {string} typeTrdLevel - The level indicator to be removed from the name.
 * @returns {string} The formatted name of the TRD after removing the level indicator.
 */

export const getNameTrdByLevel2 = (originalName, typeTrdLevel) => {
  const regex = /(.*)\((.*?)\)/;
  const match = originalName.match(regex);

  if (match) {
    const contentInParentheses = match[2].trim();
    const lastHyphenIndex = match[1].lastIndexOf('-');
    const textAfterLastHyphen = match[1].substring(lastHyphenIndex + 1).trim();
    let result = `${textAfterLastHyphen} (${contentInParentheses})`;
    result = result.replace(typeTrdLevel, "");
    return result
  } else {
    const lastHyphenIndex = originalName.lastIndexOf('-');
    return originalName.substring(lastHyphenIndex + 1).trim();
  }
}
