import React from "react";
import PropTypes from "prop-types";
import { CardFooter, Button, Spinner } from "reactstrap";
import i18n from "locales/i18n";

const FieldStepConfigFooter = (props) => {
  const {
    isLoadingFillingForm,
    setShowFormsFillingProcess,
    setShowExternalFormsFillingProcess,
    setFormFields,
    setValidationFieldDatePicker,
    isFilingInBatch,
    disabledSubmitRecordInBatch,
  } = props;
  let diabledSubmitButton = false;
  let spinnerButton = null;

  /**
   * Generates a cancel button JSX element with specified properties and behavior.
   * @param {boolean} isLoadingFillingForm - Flag indicating whether the form is currently loading.
   * @param {function} setShowFormsFillingProcess - Function to control the visibility of forms filling process.
   * @param {function} setShowExternalFormsFillingProcess - Function to control the visibility of external forms filling process.
   * @param {function} setFormFields - Function to update the form fields state.
   * @param {function} setValidationFieldDatePicker - Function to update the validation state for date picker fields.
   * @returns {JSX.Element} - Cancel button JSX element.
   */
  let cancelButton = (
    <Button
      size="lg"
      disabled={isLoadingFillingForm}
      onClick={() => {
        setShowFormsFillingProcess(false);
        setShowExternalFormsFillingProcess(false);
        setFormFields([]);
        setValidationFieldDatePicker({ required: false, id: [] });
      }}
      className="col-mt-3 mr-3"
      color="gray"
    >
      {i18n.t("createusers.createButton2")}
    </Button>
  );

  /**
   * Conditionally sets the cancelButton variable to null if filing is done in batch mode.
   * @param {boolean} isFilingInBatch - A boolean flag indicating whether filing is being done in batch mode.
   * If true, the cancelButton variable will be set to null.
   * @returns {null} - Returns null if filing is done in batch mode and sets cancelButton to null; otherwise, no return value.
   */
  if (isFilingInBatch === true) {
    cancelButton = null;
  }

  /**
   * Checks if either the filling form is currently loading or if submitting records in batch is disabled,
   * and sets the disabled state for the submit button accordingly.
   * @param {boolean} isLoadingFillingForm - Indicates whether the filling form is currently loading.
   * @param {boolean} disabledSubmitRecordInBatch - Indicates whether submitting records in batch is disabled.
   * @param {boolean} diabledSubmitButton - The state of the submit button, which will be updated based on the conditions.
   * @returns {void}
   */
  if (isLoadingFillingForm === true || disabledSubmitRecordInBatch === true) {
    diabledSubmitButton = true;
  }

  /**
   * Generates a spinner button component based on the provided loading state.
   * @param {boolean} isLoadingFillingForm - Indicates whether the form filling process is in progress.
   * If true, the spinner button will be displayed; otherwise, it will not be rendered.
   * @returns {React.ReactNode} - The spinner button component to be rendered.
   */
  if (isLoadingFillingForm === true) {
    spinnerButton = <Spinner size="sm" color="secondary" type="grow" />;
  }

  return (
    <CardFooter className="d-block text-right">
      {cancelButton}
      <Button
        type="submit"
        size="lg"
        disabled={diabledSubmitButton}
        className="col-mt-3"
        color="cyan"
      >
        {spinnerButton}
        {i18n.t("modal.Done.footerButton")}
      </Button>
    </CardFooter>
  );
};

FieldStepConfigFooter.propTypes = {
  isLoadingFillingForm: PropTypes.bool.isRequired,
  setShowFormsFillingProcess: PropTypes.func.isRequired,
  setShowExternalFormsFillingProcess: PropTypes.func,
  setFormFields: PropTypes.func.isRequired,
  setValidationFieldDatePicker: PropTypes.func,
  isFilingInBatch: PropTypes.bool,
  disabledSubmitRecordInBatch: PropTypes.bool,
};

export default FieldStepConfigFooter;
