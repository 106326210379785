import React, { Fragment, useState } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Alert,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import useIntegrations from "hooks/useIntegrations";
import i18n from "locales/i18n";
import swal from "sweetalert";

const ButtonIntegrationConfig = (props) => {
  const { requestUuidIntegration, disabled } = props;
  const size = process.env.REACT_APP_SIZE_AGREEMENT_FILE;
  const [showModalIntegrationConfig, setShowModalIntegrationConfig] = useState(false);
  const [isLoadingIntegrationConfig, setIsLoadingIntegrationConfig] = useState(false);
  const [fileIntegrationConfig, setFileIntegrationConfig] = useState("");
  const [errorFileIntegrationConfig, setErrorFileIntegrationConfig] = useState(false);
  const [errorFileSize, setErrorFileSize] = useState(false);
  const [userApi, setUserApi] = useState({
    request_uuid: requestUuidIntegration,
    username_validart: "",
    password_validart: "",
  });
  const { configInt } = useIntegrations();
  let loadingComponent = null;
  let errorIntegrationFile = null;
  let errorIntegrationFileSize = null;

  /**
   * Displays an error alert if the fileIntegrationConfig size exceeds the allowed limit.
   * This code checks if the `errorFileSize` state is `true`, and if so, it sets 
   * the `errorIntegrationFileSize` variable to an alert component. This alert 
   * component includes an icon and a localized message indicating that the 
   * configuration fileIntegrationConfig size is too large.
   * @param {boolean} errorFileSize - The state indicating if the fileIntegrationConfig size exceeds the allowed limit.
   * @returns {JSX.Element|null} An alert component if `errorFileSize` is `true`, otherwise `null`.
   */
  if (errorFileSize === true) {
    errorIntegrationFileSize = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("integration.requestConfigFileSize")}
      </Alert>
    )
  };

  /**
   * Displays an error alert if there is an issue with the fileIntegrationConfig.
   *
   * This code checks if the `errorFileIntegrationConfig` state is `true`, and if so, it sets 
   * the `errorIntegrationFile` variable to an alert component. This alert 
   * component includes an icon and a localized message indicating that there 
   * is an issue with the configuration fileIntegrationConfig.
   *
   * @param {boolean} errorFileIntegrationConfig - The state indicating if there is an error with the fileIntegrationConfig.
   * @returns {JSX.Element|null} An alert component if `errorFileIntegrationConfig` is `true`, otherwise `null`.
   */
  if (errorFileIntegrationConfig === true) {
    errorIntegrationFile = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("integration.requestConfigFileAcc2")}
      </Alert>
    )
  };

  /**
   * Renders a loading spinner component if isLoadingIntegrationConfig is true.
   * This function conditionally assigns a loading spinner component to the loadingComponent variable
   * if isLoadingIntegrationConfig is true. Otherwise, it assigns null to loadingComponent.
   * @param {boolean} isLoadingIntegrationConfig - A flag indicating whether loading is in progress.
   * @returns {JSX.Element|null} A loading spinner component if isLoadingIntegrationConfig is true, otherwise null.
   */
  if (isLoadingIntegrationConfig === true) {
    loadingComponent = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Handles the click event to show a modal.
   * This function sets the state `showModalIntegrationConfig` to true, displaying the modal.
   * @return {void}
   */
  const handleOnClick = () => {
    setShowModalIntegrationConfig(true);
  };

  /**
   * Handles the close event for a modal.
   * This function sets the state `showModalIntegrationConfig` to false, effectively closing the modal.
   * @returns {void}
   */
  const handleOnClose = () => {
    setShowModalIntegrationConfig(false);
  };

  /**
   * Closes the integration process and resets the state.
   * This function performs several actions to reset the state of the integration process:
   * 1. Sets the `userApi` state to an object with a new request UUID and empty validation fields.
   * 2. Resets the `fileIntegrationConfig` state to an empty string.
   * 3. Sets the `errorFileIntegrationConfig` state to `false`.
   * 4. Sets the `errorFileSize` state to `false`.
   * 5. Hides the modal by setting the `showModalIntegrationConfig` state to `false`. 
   */
  const closeIntegration = () => {
    setUserApi({
      request_uuid: requestUuidIntegration,
      username_validart: "",
      password_validart: "",
    });
    setFileIntegrationConfig("");
    setErrorFileIntegrationConfig(false);
    setErrorFileSize(false);
    setShowModalIntegrationConfig(false);
  }

  /**
   * Handles the change event for integration configuration.
   * This function updates the user API state with the new value from the input field.
   * @param {Object} eventIntegrationConfig - The event object associated with the change event.
   * @param {string} eventIntegrationConfig.target.name - The name of the input field.
   * @param {string} eventIntegrationConfig.target.value - The new value of the input field.
   */
  const handleOnChange = (eventIntegrationConfig) => {
    setUserApi({
      ...userApi,
      [eventIntegrationConfig.target.name]: eventIntegrationConfig.target.value,
    });
  };

  /**
   * Handles the change event for fileIntegrationConfig input.
   * This function checks the type and size of the selected fileIntegrationConfig. If the fileIntegrationConfig type is not
   * "application/pdf", it sets `errorFileIntegrationConfig` state to true. If the fileIntegrationConfig size exceeds the
   * specified size limit, it sets `errorFileSize` state to true. Otherwise, it sets `errorFileIntegrationConfig`
   * and `errorFileSize` states to false and updates the `fileIntegrationConfig` state with the selected fileIntegrationConfig.
   * @param {Object} event - The event object associated with the change event.
   * @param {FileList} event.target.files - The list of files selected in the input field.
   * @param {boolean} errorFileIntegrationConfig - The state indicating if there's an error with the fileIntegrationConfig type.
   * @param {boolean} errorFileSize - The state indicating if there's an error with the fileIntegrationConfig size.
   * @param {Function} setErrorFileIntegrationConfig - Function to set the errorFileIntegrationConfig state.
   * @param {Function} setErrorFileSize - Function to set the errorFileSize state.
   * @param {Function} setFileIntegrationConfig - Function to set the fileIntegrationConfig state.
   * @param {number} size - The maximum allowed fileIntegrationConfig size.
   */
  const handleFileChange = ({ target: { files } }) => {
    if (files[0].type !== "application/pdf") {
      setErrorFileIntegrationConfig(true);
    } else {
      setErrorFileIntegrationConfig(false);
    }
    if (files[0].size > size) {
      setErrorFileSize(true);
    } else {
      setErrorFileSize(false);
    }
    setFileIntegrationConfig(files[0]);
  };

  /**
   * Handles the blur event for integration configuration.
   * This function updates the user API state with the trimmed value from the input field when it loses focus.
   * @param {Object} eventIntegrationConfig - The event object associated with the blur event.
   * @param {string} eventIntegrationConfig.target.name - The name of the input field.
   * @param {string} eventIntegrationConfig.target.value - The value of the input field.
   */
  const handleOnBlur = (eventIntegrationConfig) => {
    setUserApi({
      ...userApi,
      [eventIntegrationConfig.target.name]: eventIntegrationConfig.target.value.trim(),
    });
  };

  /**
   * Handles the form submission for integration configuration.
   * This function validates form errors and fileIntegrationConfig type/size. If there are no errors and the fileIntegrationConfig
   * type and size meet the requirements, it sends a request to configure the integration. Upon
   * successful configuration, it shows a success message and reloads the page. If there's an error,
   * it shows a service error alert. If there are form errors, it does nothing.
   * @param {Object} eventIntegrationConfig - The event object associated with the form submission.
   * @param {Array} errors - An array containing form validation errors.
   */
  const handleOnSubmit = (eventIntegrationConfig, errors) => {
    if (errors.length === 0) {
      if (fileIntegrationConfig.type !== "application/pdf" || fileIntegrationConfig.size > size) {
        eventIntegrationConfig.preventDefault();
      } else {
        setIsLoadingIntegrationConfig(true);
        const formData = new FormData();
        formData.append("request_uuid", userApi.request_uuid);
        formData.append("username_validart", userApi.username_validart);
        formData.append("password_validart", userApi.password_validart);
        formData.append("communication_agreement", fileIntegrationConfig);
        configInt(formData)
          .then((response) => {
            if (response.status === 201) {
              const showAlertConfig = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("integration.requestAssingSwal"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                  successMode: true,
                }).then(() => {
                  handleOnClose();
                  window.location.reload();
                });
              };
              showAlertConfig();
            } else {
              showAlertServiceError();
            }
          })
          .catch(() => {
            setUserApi("");
            setFileIntegrationConfig("");
            setIsLoadingIntegrationConfig(false);
          })
          .finally(() => {
            setIsLoadingIntegrationConfig(false);
          });
      }
    } else {
      setIsLoadingIntegrationConfig(false);
      return;
    }
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="info"
              onClick={handleOnClick}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faCog} className="mr-2" />
              <span>{i18n.t("integration.requestConfigButton")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModalIntegrationConfig}
          toggle={handleOnClose}
          className="modal-custom "
        >
          <ModalHeader>
            <Label>{i18n.t("integration.requestConfigTittle")}</Label>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmit}>
              <Col md="12">
                <Alert className="mbg-3" color="info">
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  {i18n.t("integration.requestConfigAlert")}
                </Alert>
                <AvGroup>
                  <Label for="username_validart" className="is-required">
                    {i18n.t("integration.requestConfigUser")}
                  </Label>
                  <AvField
                    id="username_validart"
                    name="username_validart"
                    type="email"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigUserReq"
                        )}`,
                      },
                      email: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigTipeEmail"
                        )}`,
                      },
                    }}
                    autoComplete="off"
                    value={userApi.username_validart}
                    placeholder="correo@dominio.com"
                  />
                </AvGroup>

                <AvGroup>
                  <Label for="password_validart" className="is-required">
                    {i18n.t("integration.requestConfigPass")}
                  </Label>
                  <AvField
                    id="password_validart"
                    name="password_validart"
                    type="password"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigPassReq"
                        )}`,
                      },
                    }}
                    autoComplete="new-password"
                    value={userApi.password_validart}
                  />
                </AvGroup>

                <AvGroup>
                  <Label for="communication_agreement" className="is-required">
                    {i18n.t("integration.requestConfigCom")}
                  </Label>

                  <AvField
                    id="communication_agreement"
                    name="communication_agreement"
                    type="file"
                    onChange={handleFileChange}
                    onBlur={handleOnBlur}
                    accept="application/pdf"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigFileReq"
                        )}`,
                      },
                    }}
                    autoComplete="off"
                  />
                  <span
                    className="detailIntegration-config"
                  >
                    {i18n.t("integration.requestConfigFileAcc")}
                  </span>

                  {errorIntegrationFile}
                  {errorIntegrationFileSize}
                </AvGroup>
              </Col>
              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={closeIntegration}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingIntegrationConfig}
                  className="col-mt-3 "
                  color="cyan"
                >
                  {loadingComponent}
                  {i18n.t("modal.Done.footerButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};


ButtonIntegrationConfig.propTypes = {
  requestUuidIntegration: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ButtonIntegrationConfig;