import React, { useEffect, Fragment, useState, useCallback, useContext } from "react";
import {
	AvForm,
	AvGroup,
	AvField,
} from "availity-reactstrap-validation";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Button, Label, Spinner } from "reactstrap";
import useUser from "hooks/useUser";
import swal from "sweetalert";
import {
	USER_DETAIL,
} from "constants/securityConst";
import {
	showAlertServiceError,
	showSweetAlertInvalidPassword,
	showSweetAlertMatch,
} from "utils/alerts";
import { getItem } from "utils/localStorage";
import { isNullOrUndefined } from "utils/validations";
import {
	regexChangePasswordExpiration,
	regexPasswordExpiration,
	regexPasswordExpirationAlphabetic,
	regexPasswordExpirationNumeric,
	regexPasswordExpirationNumericAlphabetic
} from "utils/regexExpressions";
import { enumsPasswordExpiration } from "utils/enums";
import Context from "contextAPI/UserContext";
import ShowConfigurationPassWord from "components/atoms/ShowConfigurationPassWord";
import usePasswordToggle from "customsHooks/usePasswordToggle";
import i18n from "locales/i18n";

const ChangePasswordExpiration = () => {
	const { setJwt, setUser } = useContext(Context);

	const {
		inputType: inputTypeActualPassword,
		TogglePasswordIcon: ToggleActualPassword
	} = usePasswordToggle();

	const {
		inputType: inputTypeNewPassword,
		TogglePasswordIcon: ToggleNewPassword
	} = usePasswordToggle();

	const {
		inputType: inputTypeConfirmPassword,
		TogglePasswordIcon: ToggleConfirmPassword
	} = usePasswordToggle();

	const [userId, setUserId] = useState("")
	const [changePassword, setChangePasword] = useState({
		current_password: "",
		new_password: "",
		new_password_confirm: "",
	});
	const [isLoadingPasswordExpiration, setIsLoadingPasswordExpiration] = useState(false);
	const { changePasswordByUser } = useUser();
	const history = useHistory();

	let loadingExpirationPassword = null;

	/**
	 * Renders a spinner loading component if the isLoadingPasswordExpiration state is true.
	 * @param {boolean} isLoadingPasswordExpiration - Indicates whether the password expiration loading is in progress.
	 * @returns {JSX.Element|null} Spinner loading component JSX if isLoadingPasswordExpiration state is true, otherwise null.
	 */
	if (isLoadingPasswordExpiration === true) {
		loadingExpirationPassword = (
			<Spinner size="sm" color="secondary" type="grow" />
		)
	}

	/**
	 * Shows a sweet alert with a success message and clears local storage and user state on confirmation.
	 * @returns {void}
	 */
	const showSweetAlertSuccess = () => {
		swal({
			title: i18n.t('modal.DoneError.header'),
			text: i18n.t("change.password.label7"),
			icon: "success",
			button: i18n.t('modal.Done.footerButton')
		}).then(() => {
			window.localStorage.clear()
			setJwt(null);
			setUser({});
			history.push("/")
		})
	};

	/**
	 * Creates a password for the user and updates it using the provided changePassword data.
	 * @param {string} userId - The unique identifier of the user.
	 * @param {Object} changePassword - The object containing the new password details.
	 * @param {string} changePassword.new_password - The new password to be set.
	 * @returns {void}
	 */
	const createPasswordUser = useCallback((userId, changePassword) => {
		setIsLoadingPasswordExpiration(true);
		changePasswordByUser(userId, changePassword)
			.then((response) => {
				if (isNullOrUndefined(response) === false) {
					if (response.status === 202) {
						showSweetAlertSuccess();
					}
				} else {
					showAlertServiceError();
				}
			})
			.finally(() => {
				setIsLoadingPasswordExpiration(false);
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Handles the change event when modifying password expiration fields, updating the corresponding state.
	 * @param {Object} eventPasswordExpiration - The event object representing the change event.
	 * @param {string} eventPasswordExpiration.target.name - The name of the input field being changed.
	 * @param {string} eventPasswordExpiration.target.value - The new value of the input field.
	 * @returns {void}
	 */
	const handleOnChangePasswordExpiration = (eventPasswordExpiration) => {
		setChangePasword({
			...changePassword,
			[eventPasswordExpiration.target.name]: eventPasswordExpiration.target.value,
		});
	};

	/**
	 * Handles the submission of a password change request,
	 *  including validations for password complexity and confirmation.
	 * @returns {void}
	 */
	const handleOnSubmitChangePasswordExpiration = () => {
		if (
			changePassword.new_password.length > 7 &&
			changePassword.new_password.match(regexPasswordExpirationNumeric) &&
			changePassword.new_password.match(regexPasswordExpirationAlphabetic) &&
			changePassword.new_password.match(regexPasswordExpirationNumericAlphabetic)
		) {
			if (changePassword.new_password !== changePassword.new_password_confirm) {
				showSweetAlertMatch();
			} else {
				createPasswordUser(userId, changePassword);
			}
		} else {
			showSweetAlertInvalidPassword();
		}
	};

	/**
	 * Fetches the user details from the local storage and sets the user ID using the retrieved data.
	 * @returns {void}
	 */
	useEffect(() => {
		const user = getItem(USER_DETAIL);
		if (isNullOrUndefined(user) === false) {
			setUserId(user.uuid);
		} else {
			swal({
				title: i18n.t('modal.DoneError.header'),
				text: i18n.t("showNotification403"),
				icon: "error",
				button: i18n.t('modal.Done.footerButton')
			}).then(() => {
				history.push("/");
			})
		}
		// eslint-disable-next-line 
	}, [])

	return (
		<Fragment>
			<div className="h-100">
				<Row className="h-100 no-gutters">
					<Col lg="12" className="d-none d-lg-block">
					</Col>
					<Col
						lg="12"
						md="12"
						className="h-100 d-flex bg-white justify-content-center align-items-center"
					>
						<Col lg="4" md="6" sm="8" className="mx-auto app-login-box">
							<div className="app-logo" />
							<h4>
								<div>{i18n.t("dashboardsubheading")}</div>
								<span>{i18n.t("changePassword.tittle1")}</span>
							</h4>
							<div>
								<AvForm onSubmit={handleOnSubmitChangePasswordExpiration}>
									<Row form>
										<Col md={12}>
											<AvGroup>
												<Label for="password">
													{i18n.t("change.password.label")}
												</Label>
												<div className="position-relative">
													<AvField
														id="current_password"
														name="current_password"
														type={inputTypeActualPassword}
														onChange={handleOnChangePasswordExpiration}
														data-tip
														data-for="input_password1"
														validate={{
															maxLength: {
																value: enumsPasswordExpiration.MAX_LENGTH,
															},
															required: { value: true, errorMessage: `${i18n.t("fieldRequired")}` },
															pattern: { value: regexChangePasswordExpiration, errorMessage: `${i18n.t("invalidField")}` },
														}}
														placeholder={i18n.t("change.password.label3")}
														autoComplete="off"
														value={changePassword.current_password}
													/>
													{ToggleActualPassword}
												</div>
											</AvGroup>
										</Col>
										<Col md={12}>
											<AvGroup>
												<Label for="password">
													{i18n.t("change.password.label1")}
												</Label>
												<div className="position-relative">
													<AvField
														id="new_password"
														name="new_password"
														type={inputTypeNewPassword}
														onChange={handleOnChangePasswordExpiration}
														data-tip
														data-for="input_password1"
														validate={{
															maxLength: {
																value: enumsPasswordExpiration.MAX_LENGTH,
															},
															required: { value: true, errorMessage: `${i18n.t("fieldRequired")}` },
														}}
														placeholder={i18n.t("change.password.label4")}
														autoComplete="off"
														value={changePassword.new_password}
													/>
													{ToggleNewPassword}
												</div>
												<ShowConfigurationPassWord changePassword={changePassword} />
											</AvGroup>
										</Col>

										<Col md={12}>
											<AvGroup>
												<Label for="confirm_password">
													{i18n.t("changePassword.label2")}
												</Label>
												<div className="position-relative">
													<AvField
														id="new_password_confirm"
														name="new_password_confirm"
														type={inputTypeConfirmPassword}
														onChange={handleOnChangePasswordExpiration}
														data-tip
														data-for="input_password2"
														validate={{
															maxLength: {
																value: enumsPasswordExpiration.MAX_LENGTH,
															},
															required: { value: true, errorMessage: `${i18n.t("fieldRequired")}` },
															pattern: { value: regexPasswordExpiration, errorMessage: `${i18n.t("invalidField")}` },
														}}
														placeholder={i18n.t("change.password.label4")}
														autoComplete="off"
														value={changePassword.new_password_confirm}
													/>
													{ToggleConfirmPassword}
												</div>
											</AvGroup>
										</Col>
									</Row>
									<div className="mt-4 d-flex align-items-center">
										<h6 className="mb-0">
											<Link
												to="/dashboard"
												className="text-primary"
											>
												{i18n.t("change.password.label6")}
											</Link>
										</h6>
										<div className="ml-auto">
											<Button
												type="submit"
												color="cyan"
												size="lg"
												disabled={isLoadingPasswordExpiration}
												onClick={handleOnSubmitChangePasswordExpiration}
											>
												{loadingExpirationPassword}
												{i18n.t("forgotPassword.button1")}
											</Button>
										</div>
									</div>
								</AvForm>
							</div>
						</Col>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default ChangePasswordExpiration;
