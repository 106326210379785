import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import i18n from "locales/i18n";
import Chart from "react-apexcharts";

const MetricsRecordsListsTable = (props) => {
  const {
    recordsForm,
    pagination,
    setPagination,
    isLoading,
    totalPages,
  } = props;

  const [optionBar] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 5,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      style: {
        fontSize: "12px",
        colors: ["#033c73"],
      },
    },
    noData: {
      text: i18n.t("metrics.noData"),
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#033c73",
        fontSize: "14px",
      },
    },
    xaxis: {
      tickAmount: "dataPoints",
      categories: [
        i18n.t("metrics.Month1"),
        i18n.t("metrics.Month2"),
        i18n.t("metrics.Month3"),
        i18n.t("metrics.Month4"),
        i18n.t("metrics.Month5"),
        i18n.t("metrics.Month6"),
        i18n.t("metrics.Month7"),
        i18n.t("metrics.Month8"),
        i18n.t("metrics.Month9"),
        i18n.t("metrics.Month10"),
        i18n.t("metrics.Month11"),
        i18n.t("metrics.Month12"),
      ],
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return i18n.t("metrics.RecordsByformMonth");
          },
        },
      },
      marker: {
        show: false,
      },
    },

    grid: {
      row: {
        colors: ["#fff", "#f2f2f2"],
      },
    },

    colors: ["#00b85d"],

    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  });

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={recordsForm}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("form.designerLabel1")],
                  accessor: "form_name",
                  width: 280,
                  Cell: (row) => {
                    return (
                      <div className="loader-wrapper d-flex justify-content-center align-items-center text-center ">
                        <h6 className="text-center">
                          {row.original.form_name}
                        </h6>
                      </div>
                    );
                  },
                },
                {
                  Header: [i18n.t("metrics.RecordsByform")],
                  accessor: "total_records_form",
                  width: 280,
                  Cell: (row) => {
                    return (
                      <div className="loader-wrapper d-flex justify-content-center align-items-center text-center ">
                        <strong
                          className="text-cyan"
                          style={{ fontSize: "18px" }}
                        >
                          {row.original.total_records_form}
                        </strong>
                      </div>
                    );
                  },
                },
                {
                  Header: [i18n.t("metrics.RecordsByformGraphics")],
                  accessor: "month_list",
                  Cell: (row) => {
                    return (
                      <Chart
                        series={[
                          {
                            data: Object.values(row.original.month_list),
                          },
                        ]}
                        type="bar"
                        height="150"
                        options={optionBar}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPages}
          page={pagination.page - 1}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          loading={isLoading}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

MetricsRecordsListsTable.propTypes = {
  recordsForm: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default MetricsRecordsListsTable;
