import React, { Fragment, useEffect, useRef, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Card, Label, Button, CardHeader } from "reactstrap";
import { useListContext } from "contextAPI/ListsContext";
import { getDateFormat2 } from "utils/getDateFormat2";
import { loaderElement } from "utils/loaderElement";
import { regexNameItem } from "utils/regexExpressions";
import { enumsCreateList } from "utils/enums";
import { showAlertServiceError } from "utils/alerts";
import useList from "hooks/useList";
import cx from "classnames";
import swal from "sweetalert";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const CreateItemsListForm = () => {
  const {
    isLoading,
    items,
    setItems,
    setCanNext,
    listId,
    setListId,
    setErrorList,
    item,
    getItemsBySearch,
    createListState,
    setCreateListState,
    isLoadingCreateList,
    setIsLoadingCreateList,
  } = useListContext();

  const { createListWithItem, createItemsInList } = useList();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const tableHeaderRef = useRef(null);

  let itemsTable = null;
  let isListParentId = false;
  let itemName = "";
  let itemParentId = "";

  /**
   * Object containing validation rules for item names.
   * @property {object} required - Validation rule for required field.
   * @property {boolean} required.value - Indicates if the field is required.
   * @property {string} required.errorMessage - Error message to display if the field is required.
   * @property {object} pattern - Validation rule for pattern matching.
   * @property {RegExp} pattern.value - Regular expression pattern for validation.
   * @property {string} pattern.errorMessage - Error message to display if pattern validation fails.
   * @property {object} minLength - Validation rule for minimum length.
   * @property {number} minLength.value - Minimum length allowed for the field.
   * @property {string} minLength.errorMessage - Error message to display if length is below minimum.
   * @property {object} maxLength - Validation rule for maximum length.
   * @property {number} maxLength.value - Maximum length allowed for the field.
   * @property {string} maxLength.errorMessage - Error message to display if length exceeds maximum.
   */
  let validateItemsName = {
    required: {
      value: true,
      errorMessage: `${i18n.t("form.field46")}`,
    },
    pattern: {
      value: regexNameItem,
      errorMessage: `${i18n.t("list.designerFeedback2")}`,
    },
    minLength: {
      value: enumsCreateList.MIN_LENGTH_NAME_LIST,
      errorMessage: `${i18n.t("fieldValidateLengthBetween")} 2 ${i18n.t(
        "and"
      )} 100 ${i18n.t("characters")}`,
    },
    maxLength: {
      value: enumsCreateList.MAX_LENGTH_NAME_LIST,
      errorMessage: `${i18n.t("fieldValidateLengthBetween")} 2 ${i18n.t(
        "and"
      )} 100 ${i18n.t("characters")}`,
    },
  };

  /**
   * Checks if the parent ID of a list item is undefined and sets a flag accordingly.
   * @param {Object} createListState - The state object containing information about the list item being created.
   * @param {string|number|undefined} createListState.parent_id - The parent ID of the list item.
   * @returns {boolean} - A boolean flag indicating whether the parent ID is undefined.
   */
  if (createListState.parent_id === undefined) {
    isListParentId = true;
  }

  /**
   * Object representing validation rules for a parent item in a form.
   * This object specifies whether the parent item is required or not.
   * @property {Object} required - Specifies the validation rule for the parent item's requirement.
   * @property {boolean} required.value - Indicates whether the parent item is required.
   * @property {string} required.errorMessage - Error message to be displayed if the validation fails.
   *  It should be localized using internationalization functions.
   * @param {boolean} isListParentId - Indicates whether the parent ID is part of a list.
   *  If true, the parent item is considered not required.
   *  If false, the parent item is considered required.
   * @param {function} i18n.t - Internationalization function used to translate the error message.
   *  This function should accept a string key and return the translated message.
   */
  let validateParentItem = {
    required: {
      value: !isListParentId,
      errorMessage: `${i18n.t("form.field46")}`,
    },
  };

  /**
   * Updates variables based on the properties of an item in the create list state.
   * @param {object} createListState - The state object containing information about the item being created.
   * @param {object} createListState.item - The item object within the create list state.
   * @param {string} createListState.item.name - The name of the item.
   * @param {string} createListState.item.parent_id - The ID of the parent item, if applicable.
   * @returns {void}
   */
  if (createListState.item) {
    itemName = createListState.item.name;
    itemParentId = createListState.item.parent_id;
  }

  /**
   * Resets validation states for item name and parent item if form submission has not occurred yet.
   * @param {boolean} formSubmitted - Indicates whether the form has been submitted.
   * @returns {void}
   */
  if (formSubmitted === false) {
    validateItemsName = null;
    validateParentItem = null;
  }

  /**
   * Renders a ReactTable component with provided items data.
   * @param {Array<Object>} items - An array of objects representing items data to be displayed in the table.
   * @param {Object} i18n - An object containing translation functions for internationalization.
   * @returns {JSX.Element|null} The rendered ReactTable component displaying the items data, or null if there are no items.
   */
  if (items.length > 0) {
    itemsTable = (
      <ReactTable
        data={items}
        columns={[
          {
            columns: [
              {
                Header: i18n.t("itemList.designerLabel1"),
                accessor: "name",
              },
              {
                Header: i18n.t("itemList.designerLabel2"),
                accessor: "parent_item",
              },
              {
                Header: i18n.t("itemList.designerLabel3"),
                accessor: "date",
              },
            ],
          },
        ]}
        defaultPageSize={5}
        showPageJump={false}
        noDataText={`${i18n.t("tableRowsEmpty")}`}
        pageSize={items.length}
        className="-striped -highlight"
      />
    );
  }

  /**
   * Displays a success alert for creating an item using SweetAlert.
   * @returns {void}
   */
  const showAlertCreateItem = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("itemList.createSucess"),
      icon: "success",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Handles key press events, preventing the default action if the pressed key is "Enter".
   * @param {KeyboardEvent} eventKeyPress - The keyboard event object representing the key press event.
   *  This object contains information about the key that was pressed.
   *  For example, eventKeyPress.key retrieves the pressed key.
   */
  const handleOnKeyPress = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * Handles the blur event for an item list input field.
   * This function sets the form submission status to true and updates the state
   * with the trimmed value of the input field associated with the blur event.
   * @param {Event} eventBlurListItem - The blur event object for the input field.
   * @returns {void}
   */
  const handleOnBlurItemList = (eventBlurListItem) => {
    setFormSubmitted(true);
    setCreateListState({
      ...createListState,
      item: {
        ...createListState.item,
        [eventBlurListItem.target.name]: eventBlurListItem.target.value.trim(),
      },
    });
  };

  /**
   * Handles the change event for an item, updating the form state.
   * @param {object} eventChangeNameItem - The event object triggered by the item change.
   * @param {string} eventChangeNameItem.target.name - The name attribute of the target item.
   * @param {string} eventChangeNameItem.target.value - The new value of the target item.
   * @param {function} setFormSubmitted - State setter function for form submission status.
   * @param {function} setCreateListState - State setter function for updating the createListState.
   * @param {object} createListState - The current state of the list creation form.
   * @param {object} createListState.item - The item object within the createListState.
   * @param {string} createListState.item.[eventChangeNameItem.target.name] - The property within the item object to be updated.
   * @returns {void}
   */
  const handleOnChangeItem = (eventChangeNameItem) => {
    setFormSubmitted(true);
    setCreateListState({
      ...createListState,
      item: {
        ...createListState.item,
        [eventChangeNameItem.target.name]: eventChangeNameItem.target.value,
      },
    });
  };

  /**
   * Creates a list item and updates the state with the new item's details.
   * @param {object} createListState - The state object containing information required to create the list item.
   * @param {object} createListState.item - The item object to be created.
   * @param {string} createListState.item.name - The name of the item to be created.
   * @param {string} [createListState.item.parent_id] - The parent ID of the item (optional).
   * @param {string} [createListState.parent_id] - The parent ID of the list (optional)
   * @returns {void}
   */
  const createListWithItemAndUpdateState = (createListState) => {
    createListWithItem(createListState)
      .then((response) => {
        if (response.status === 201) {
          setItems((prevState) => {
            let parentItemLabel = null;
            item.forEach((item) => {
              if (item.value === createListState.item.parent_id) {
                parentItemLabel = item.label;
              }
            });

            return [
              ...prevState,
              {
                name: createListState.item.name,
                parent_item: parentItemLabel,
                date: getDateFormat2(new Date()),
              },
            ];
          });
          setListId(response.data.data.uuid);
          showAlertCreateItem();
        }
      })
      .catch(() => {
        setErrorList(true);
      })
      .finally(() => {
        setIsLoadingCreateList(false);
        setFormSubmitted(false);
        if (tableHeaderRef.current) {
          tableHeaderRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (createListState.parent_id === undefined) {
          setCreateListState({
            ...createListState,
            item: {
              ...createListState.item,
              name: "",
            },
          });
        } else {
          setCreateListState({
            ...createListState,
            item: {
              ...createListState.item,
              name: "",
              parent_id: "",
            },
          });
        }
      });
  };

  /**
   * Creates items in a list and updates the state accordingly.
   * @param {Object} itemCreated - The item to be created in the list.
   * @param {string} itemCreated.parent_id - The ID of the parent item, if applicable.
   * @param {string} listId - The ID of the list where the item will be created.
   * @param {function} setItems - State setter function to update the items list state.
   * @param {Array<Object>} items - The current items list state.
   * @param {function} createItemsInList - Function to create items in a list.
   * @param {function} showAlertCreateItem - Function to show an alert indicating item creation.
   * @param {function} setIsLoadingCreateList - State setter function to update loading state for item creation.
   * @param {function} setFormSubmitted - State setter function to update form submission state.
   * @param {Object} createListState - The state representing the create list form.
   * @param {Object} createListState.item - The item object within the create list state.
   * @param {string} createListState.item.name - The name of the item being created.
   * @param {string} createListState.parent_id - The ID of the parent item, if applicable.
   * @param {function} setCreateListState - State setter function to update the create list state.
   * @param {Object} tableHeaderRef - Reference to the table header element.
   * @returns {void}
   */
  const createItemsInListAndUpdateState = (itemCreated, listId) => {
    createItemsInList(itemCreated, listId)
      .then((response) => {
        if (response.status === 201) {
          setItems((prevState) => {
            let parentItemLabel = null;
            item.forEach((item) => {
              if (item.value === itemCreated.parent_id) {
                parentItemLabel = item.label;
              }
            });

            return [
              ...prevState,
              {
                name: createListState.item.name,
                parent_item: parentItemLabel,
                date: getDateFormat2(new Date()),
              },
            ];
          });
          showAlertCreateItem();
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingCreateList(false);
        setFormSubmitted(false);
        if (tableHeaderRef.current) {
          tableHeaderRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (createListState.parent_id === undefined) {
          setCreateListState({
            ...createListState,
            item: {
              ...createListState.item,
              name: "",
            },
          });
        } else {
          setCreateListState({
            ...createListState,
            item: {
              ...createListState.item,
              name: "",
              parent_id: "",
            },
          });
        }
      });
  };

  /**
   * Handles the submission of a list, performing various actions based on the presence of errors and form submission status.
   * @param {Event} eventOnSubmitList - The event object representing the form submission event.
   * @param {Array} errors - An array containing any validation errors encountered during form submission.
   * @returns {void}
   */
  const handleOnSubmitList = (eventOnSubmitList, errors) => {
    if (errors.length === 0) {
      setFormSubmitted(true);
    }

    if (errors.length > 0 || formSubmitted === false) {
      eventOnSubmitList.preventDefault();
      return;
    } else {
      if (items.length === 0) {
        setIsLoadingCreateList(true);
        createListWithItemAndUpdateState(createListState);
      } else {
        const itemCreated = createListState.item;
        setIsLoadingCreateList(true);
        createItemsInListAndUpdateState(itemCreated, listId);
      }
    }
  };

  /**
   * Executes an effect that retrieves items based on the provided parent ID when the parent ID changes.
   * @param {object} createListState - The state object containing information about creating a list.
   * @param {string|number} createListState.parent_id - The ID of the parent item to search for child items.
   * @param {function} getItemsBySearch - The function responsible for retrieving items based on a search query.
   * @returns {void}
   */
  useEffect(() => {
    if (createListState.parent_id !== undefined) {
      getItemsBySearch(createListState.parent_id);
    }
  }, [createListState.parent_id]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that displays a modal alert when mounted, typically used for indicating instructions.
   * @param {Object} i18n - An internationalization object providing translation functions.
   * @param {Function} i18n.t - The translation function used to translate strings.
   * @param {Object} swalOptions - Options for configuring the displayed modal alert.
   * @param {string} swalOptions.title - The title of the modal alert.
   * @param {string} swalOptions.text - The content text of the modal alert.
   * @param {string} swalOptions.icon - The icon to display in the modal alert.
   * @param {string} swalOptions.button - The text for the button in the modal alert.
   * @returns {void}
   */
  useEffect(() => {
    const showAlertPressEnter = () => {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("list.instruction"),
        icon: "info",
        button: i18n.t("modal.Done.footerButton"),
      });
    };
    showAlertPressEnter();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that updates the state to enable/disable the "Next" button based on the length of the items array.
   * @param {Array} items - The array whose length is used to determine the state of the "Next" button.
   *  If the array is empty, the "Next" button is enabled; otherwise, it's disabled.
   * @param {boolean} canNext - State variable indicating whether the "Next" button can be enabled or not.
   * @returns {void}
   */
  useEffect(() => {
    if (items.length === 0) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingCreateList)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="forms-fields pb-4">
          <CardHeader className="mb-4">
            {i18n.t("itemList.designerItem4")}&nbsp;
            <em className="text-info ">{createListState.name}</em>
          </CardHeader>

          <AvForm
            className="mb-2"
            onSubmit={handleOnSubmitList}
            data-test-id="form-create-item"
          >
            <AvGroup row>
              <Label for="name" className="is-required" sm={2}>
                {i18n.t("itemList.designerLabel1")}{" "}
              </Label>
              <Col md={10}>
                <AvField
                  id="name"
                  name="name"
                  type="text"
                  date-test-id="item-name"
                  autoComplete="off"
                  onChange={handleOnChangeItem}
                  onKeyPress={handleOnKeyPress}
                  onBlur={handleOnBlurItemList}
                  value={itemName}
                  validate={validateItemsName}
                />
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label
                for="parent_id"
                className={cx("", {
                  "is-required": isListParentId === false,
                })}
                sm={2}
              >
                {i18n.t("itemList.designerLabel2")}{" "}
              </Label>
              <Col md={10}>
                <AvField
                  id="parent_id"
                  name="parent_id"
                  type="select"
                  date-test-id="parent-item-id"
                  autoComplete="off"
                  disabled={isListParentId}
                  onChange={handleOnChangeItem}
                  onKeyPress={handleOnKeyPress}
                  value={itemParentId}
                  validate={validateParentItem}
                >
                  <option value={""}>{i18n.t("form.designerLabel10.1")}</option>
                  {item.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </AvField>
              </Col>
            </AvGroup>
            <div className="d-block text-right">
              <Button
                date-test-id="add-item-button"
                type="submit"
                disabled={isLoading}
                color="cyan"
              >
                +{i18n.t("createRoles.configFormAdd")}
              </Button>
            </div>
          </AvForm>
        </Card>
        <div ref={tableHeaderRef}>{itemsTable}</div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default CreateItemsListForm;
