import React from "react";
import ElectronicSignature from "./ElectronicSignature";
import { SignatureContextProvider } from "contextAPI/SignatureContext";

export default () => {
  return (
    <SignatureContextProvider>
      <ElectronicSignature />
    </SignatureContextProvider>
  );
};
