import { enumsActionsTypes, enumsTypeUser } from "./enums";

/**
 * Determines the appropriate action type based on the user type and record creation status.
 * @param {string} USERTYPE - The type of user (e.g., ADMIN).
 * @param {boolean} isRecordCreate - A flag indicating whether the record is being created.
 * @returns {string} The action type to be used (e.g., ALL_ACTIONS, ACTIONS_RESTRIC_TYPE_USER).
 */
export const determineActions = (USERTYPE, isRecordCreate) => {
  if (USERTYPE === enumsTypeUser.ADMIN) {
    return enumsActionsTypes.ALL_ACTIONS;
  } else if (isRecordCreate === true) {
    return enumsActionsTypes.ACTIONS_RESTRIC_TYPE_USER;
  }
  return enumsActionsTypes.ALL_ACTIONS;
};

