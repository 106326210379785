import React, { Fragment } from "react";
import { Button } from "reactstrap";
import i18n from 'locales/i18n';

const CreateProcessBatchOk = () => {
  return (
    <Fragment>
      <div className="form-wizard-content">
        <div className="no-results">
          <div className="sa-icon sa-success animate">
            <span className="sa-line sa-tip animateSuccessTip" />
            <span className="sa-line sa-long animateSuccessLong" />
            <div className="sa-placeholder" />
            <div className="sa-fix" />
          </div>
          <div className="results-subtitle mt-4">{i18n.t('modal.Done.body')}</div>
          <div className="results-title">
            {i18n.t("taskBatchProcess.alert1")}
          </div>
          <div className="mt-3 mb-3" />
          <div className="text-center">
            <a href="/taskbox">
              <Button color="success" size="lg" className="btn-shadow btn-wide">
                {i18n.t('modal.Done.footerButton')}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default CreateProcessBatchOk;