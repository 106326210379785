import React, { Fragment, useCallback, useEffect, memo } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { ListGroupItem, ListGroup } from "reactstrap";
import { useParams } from "react-router-dom";
import { TYPE } from "constants/securityConst";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { getDateFormat } from "utils/getDateFormat";
import { getDateFormat2 } from "utils/getDateFormat2";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationForms } from "utils/initialPaginationsConfig";
import {
  enumsActions,
  enumsFieldsTypeControl,
  enumsRecordDetailView,
  enumsTypeUser
} from "utils/enums";
import useForm from "hooks/useForm";

const RecordDetailView = (props) => {
  /**
   * Component that renders something based on the provided props.
   * @param {object} props - The props object.
   * @param {boolean} props.isRecordCreate - Indicates if a record is being created.
   * @param {string} props.recordId - The ID of the record.
   * @returns {JSX.Element} The rendered component.
   */

  const { isRecordCreate, recordId } = props;

  const USERTYPE = window.localStorage.getItem(TYPE);
  const { id } = useParams();
  const { getFields } = useForm();

  const {
    isLoading,
    setIsLoading,
    fields,
    getRecordDetail,
    setFields,
    recordFields,
    fieldsDinamic,
    setFieldsDinamic,
    setRecordFields,
  } = useRecordDetailContext();

  /**
   * Filters the fields array based on the control_data value.
   * @param {Array} fields - The array of fields.
   * @returns {Array} The filtered array of fields.
   */
  const columnsByLabel = (fields) => {
    let fieldstoShow = [];

    fields.filter((value) => {
      if (value.control_data !== 4) {
        fieldstoShow.push(value);
        return value;
      } else {
        fieldstoShow.push(value);
        return value;
      }
    });

    return fieldstoShow;
  };

  /**
   * Fetches the fields by search based on the provided ID.
   * @param {string} id - The ID value.
   * @returns {void}
   */
  const getFieldsBySearch = useCallback(
    (id) => {
      let pagination_field = initialPaginationForms;
      const { page, per_page } = pagination_field;
      let actions = enumsActions.ALL_PERMISSIONS;
      if (USERTYPE === enumsTypeUser.ADMIN) {
        actions = enumsActions.ALL_PERMISSIONS;
      } else if (isRecordCreate === true) {
        actions = enumsActions.RESTRIC_FOR_TYPE_USER;
      } else if (isRecordCreate === false) {
        actions = enumsActions.NOT_ALLOWED;
      }
      setIsLoading(true);
      getFields(page, per_page, id, actions)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setFields(response.data.items);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Renders a `ListGroupItem` for a column that does not match specific control types.
   * @param {Object} column - The column object containing `control`, `columnId`, and `label`.
   * @returns {JSX.Element|null} - A JSX element containing the rendered column content or null if the control type matches specific values.
   */
  const renderExtrafields = (column) => {
    if (
      column.control !== enumsFieldsTypeControl.DROPDOWN_LIST &&
      column.control !== enumsFieldsTypeControl.NUMERIC_TEXBOX &&
      column.control !== enumsFieldsTypeControl.DATA_PICKER &&
      column.control !== enumsFieldsTypeControl.TIME_AND_DATE_PICKER
    ) {
      return (
        <ListGroupItem key={column.columnId}>
          <strong className="color-standar">{column.label}</strong>:{" "}
          {recordFields[column.columnId]}
        </ListGroupItem>
      );
    }
  };

  /**
   * Renders a formatted date and time for a given column, or just the label if no date is available.
   * @param {Object} column - The column object containing `columnId` and `label`.
   * @returns {JSX.Element} - A fragment containing the column label and a formatted date, or just the label if no date is present.
   */
  const returnDatePickerAndTime = (column) => {
    if (
      recordFields[column.columnId] !== undefined &&
      recordFields[column.columnId] !== null
    ) {
      return (
        <Fragment>
          <strong className="color-standar">
            {column.label}
          </strong>
          :{" "}
          {getDateFormat2(
            new Date(recordFields[column.columnId])
          )}
        </Fragment>
      );
    } else {
      return (
        <strong className="color-standar">
          {column.label}:
        </strong>
      );
    };
  };

  /**
   * Renders a `ListGroupItem` with a date and time picker for the specified column.
   * @param {Object} column - The column object containing `control`, `columnId`, and `label`.
   * @returns {JSX.Element|null} - A `ListGroupItem` containing the rendered date and time picker content, or null if the control type does not match.
   */
  const renderDatePickerAndTime = (column) => {
    if (column.control === enumsFieldsTypeControl.TIME_AND_DATE_PICKER) {
      return (
        <ListGroupItem key={column.columnId}>
          {returnDatePickerAndTime(column)}
        </ListGroupItem>
      );
    }
  };

  /**
   * Renders a formatted date for a given column, or just the label if no date is available.
   * @param {Object} column - The column object containing `columnId` and `label`.
   * @returns {JSX.Element} - A fragment containing the column label and a formatted date, or just the label if no date is present.
   */
  const returnDate = (column) => {
    if (
      recordFields[column.columnId] !== undefined &&
      recordFields[column.columnId] !== null
    ) {
      return (
        <Fragment>
          <strong className="color-standar">
            {column.label}
          </strong>
          :{" "}
          {getDateFormat(
            new Date(recordFields[column.columnId])
          )}
        </Fragment>
      );
    } else {
      return (
        <strong className="color-standar">
          {column.label}:
        </strong>
      );
    };
  };

  /**
   * Renders a date picker control as a `ListGroupItem` if the column's control type is `DATA_PICKER`.
   * @param {Object} column - The column object containing metadata for rendering.
   * @param {string} column.control - The type of control for the column.
   * @param {string} column.columnId - The unique identifier for the column.
   * @returns {JSX.Element|null} A `ListGroupItem` containing the date picker control, or `null` if the column control type is not `DATA_PICKER`.
   */
  const rederDatePicker = (column) => {
    if (column.control === enumsFieldsTypeControl.DATA_PICKER) {
      return (
        <ListGroupItem key={column.columnId}>
          {returnDate(column)}
        </ListGroupItem>
      );
    }
  }

  /**
   * Render the content for a specific column when the control is of type LABEL.
   * @param {Object} column - The column object containing control type, columnId, and label.
   * @returns {JSX.Element|null} - The JSX content to render for the column, or null if not applicable.
   */
  const renderLabelContent = (column) => {
    if (column.control === enumsFieldsTypeControl.LABEL) {
      return (
        <ListGroupItem key={column.columnId}>
          <h5 className="mb-3 color-standar">
            <strong>{column.label}</strong>
          </h5>
        </ListGroupItem>
      );
    }

    return null;
  };

  /**
   * Renders the name of the item in the list based on the column's `columnId`.
   * @param {Object} column - The column object containing the `columnId`.
   * @returns {string} - The name of the item if it exists in `recordFields`, otherwise an empty string.
   */
  const renderItemList = (column) => {
    if (
      recordFields[column.columnId] !== undefined &&
      recordFields[column.columnId] !== null
    ) {
      return recordFields[column.columnId].name;
    } else {
      return "";
    };
  };

  /**
   * Render the content for a specific column in the record fields.
   * @param {Object} column - The column object containing control type, columnId, and label.
   * @param {Object} recordFields - The record fields containing the values for each column.
   * @returns {JSX.Element|null} - The JSX content to render for the column, or null if not applicable.
   */
  const renderColumnContent = (column) => {
    if (column.control === enumsFieldsTypeControl.DROPDOWN_LIST) {
      return (
        <ListGroupItem key={column.columnId}>
          <strong className="color-standar">{column.label}</strong>:{" "}
          {renderItemList(column)}
        </ListGroupItem>
      );
    } else {
      return null;
    }
  };

  /**
   * Executes the specified effect function when the component mounts or when the 'recordId' dependency changes.
   * @param {Function} getRecordDetail - The function to fetch the record details.
   * @param {string|null|undefined} recordId - The ID of the record.
   * @param {string} id - The ID of the component.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(recordId) === false) {
      getRecordDetail(id, recordId, 0);
      return () => {
        setFields([]);
        setFieldsDinamic([]);
        setRecordFields({});
      };
    } else {
      showAlertServiceError();
    }
  }, [getRecordDetail, recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the specified effect function when the component mounts or when the 'getFieldsBySearch' dependency changes.
   *
   * @param {Function} getFieldsBySearch - The function to fetch the fields by search.
   * @param {string} id - The ID of the component.
   * @returns {void}
   */

  useEffect(() => {
    getFieldsBySearch(id);
    return () => {
      setFields([]);
      setFieldsDinamic([]);
      setRecordFields({});
    };
  }, [getFieldsBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the specified effect function when the component mounts or when the 'fields' dependency changes.
   *
   * @param {Array} fields - The array of fields.
   * @returns {void}
   */

  useEffect(() => {
    let columnsByLabelData = columnsByLabel(fields);
    columnsByLabelData.forEach((column) => {
      if (column.status === enumsRecordDetailView.ACTIVE_COLUMN) {
        setFieldsDinamic((prevState) => [
          ...prevState,
          {
            label: column.label,
            columnId: column.uuid,
            control: column.control_data,
          },
        ]);
      }
    });
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading === false) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <ListGroup>
            {fieldsDinamic.map((column) => (
              <Fragment>
                {renderColumnContent(column)}

                {renderLabelContent(column)}

                {rederDatePicker(column)}

                {renderDatePickerAndTime(column)}

                {renderExtrafields(column)}
              </Fragment>
            ))}
          </ListGroup>
        </CSSTransitionGroup>
      </Fragment>
    );
  } else {
    return null
  }
};

RecordDetailView.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
  recordId: PropTypes.string.isRequired,
};

export default memo(RecordDetailView);
