import React, { Fragment, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { enumsButtonCreateField } from "utils/enums";
import i18n from "locales/i18n"

const ButtonCreateFields = (props) => {
  const { setShowTabs } = props;
  const {
    statusForm,
  } = useFieldsRecordContext();
  const [disabledButton, setDisabledButton] = useState(false);

  /**
   * Effect to update the 'disabledButton' state based on 'formStatusDecode' and 'eraserStatus'.
   * This effect checks if 'formStatusDecode' is equal to 'eraserStatus' and updates the
   * 'disabledButton' state accordingly.
   * @return {void}
   */
  useEffect(() => {
    if (statusForm.status === enumsButtonCreateField.STATUS_ERASER) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [statusForm])

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              disabled={disabledButton}
              className="button"
              color="success"
              onClick={() => setShowTabs(true)}
            >
              <span> + {i18n.t("form.field2")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ButtonCreateFields.propTypes = {
  formStatus: PropTypes.string.isRequired,
};

export default ButtonCreateFields;
