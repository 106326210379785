import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const UserRolConfigDesignFormsTable = (props) => {
  const {
    formsTable,
    deleteForms,
    loading
  } = props;
  const [pageSizeToShow, setPageSizeToShow] = useState([1]);

  /**
   * Determines the number of rows to display in the table.
   * If the `workflowsTable` is empty, it defaults to showing 1 row.
   * Otherwise, it sets the number of rows to the length of `workflowsTable`.
   * @param {Object[]} workflowsTable - The array of workflow data objects.
   * @returns {number} The number of rows to display in the table.
   */
  function getPageSize(workflowsTable) {
    if (workflowsTable.length === 0) {
      return [1];
    }
    return workflowsTable.length;
  }

  /**
   * Effect hook to update the number of rows to display in the table.
   * It sets `pageSizeToShow` based on the length of `formsTable`.
   * @param {Object[]} formsTable - The array of form data objects.
   */
  useEffect(() => {
    setPageSizeToShow(getPageSize(formsTable));
  }, [formsTable]);

  return (
    <ReactTable
      data={formsTable}
      columns={[
        {
          columns: [
            {
              Header: [i18n.t("createWorflow.form")],
              accessor: "label",
            },

            {
              Header: [i18n.t("createRoles.MembersDelete")],
              accessor: "value",
              Cell: ({ value }) => {
                return (
                  <div className="text-center">
                    <Button
                      className="btn-icon text-primary"
                      size="lg"
                      color="link"
                      onClick={() => deleteForms(value)}
                    >
                      <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                        <div>
                          <i className="lnr-icon lnr-trash text-primary " />
                        </div>
                      </div>
                    </Button>
                  </div>
                );
              },
            },
          ],
        },
      ]}
      loading={loading}
      showPagination={false}
      pageSize={pageSizeToShow}
      noDataText={i18n.t("tableRowsEmptyForms")}
      className="-striped -highlight"
    />
  );
};

UserRolConfigDesignFormsTable.propTypes = {
  formsTable: PropTypes.array.isRequired, 
  deleteForms: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default UserRolConfigDesignFormsTable;
