import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import TrdGeneral from "components/organism/TrdGeneral";

const Trd = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("trdHeading")}
        subheading={i18n.t("trdSubHeading")}
        icon="folder"
      >
        <TrdGeneral />
      </AdminTemplate>
    </Fragment>
  );
};

export default Trd;
