import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Row, Col, CardBody } from "reactstrap";
import ReactTable from "react-table";
import i18n from "locales/i18n";
import { getDateFormat2 } from "utils/getDateFormat2";
import ButtonActionsGroupWork from "components/atoms/ButtonActionsGroupWork";

const UserListGroupsTable = (props) => {
  const { listGroups, pagination, setPagination, isLoading, totalPages } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md="12">
            <CardBody>
              <ReactTable
                data={listGroups}
                columns={[
                  {
                    columns: [
                      {
                        Header: [i18n.t("create.work.group.label1")],
                        accessor: "name",
                      },
                      {
                        Header: [i18n.t("create.work.group.label2")],
                        accessor: "user_name",
                      },
                      {
                        Header: [i18n.t("trdExpedientList5")],
                        accessor: "status",
                        Cell: ({ value }) => {
                          let statusFinal = "";
                          if (value === 1) {
                            statusFinal = (
                              <div className="ml-auto badge badge-warning">
                                {i18n.t("form.designerLabel3.2")}
                              </div>
                            );
                          } else if (value === 2) {
                            statusFinal = (
                              <div className="ml-auto badge badge-success">
                                {i18n.t("form.designerLabel3.3")}
                              </div>
                            );
                          } else if (value === 3) {
                            statusFinal = (
                              <div className="ml-auto badge badge-danger">
                                {i18n.t("filteruser.item3")}
                              </div>
                            );
                          }
                          return <span>{statusFinal}</span>;
                        },
                      },
                      {
                        Header: [i18n.t("itemList.designerLabel3")],
                        accessor: "created_at",
                        Cell: ({ value }) => {
                          let valueFinal = getDateFormat2(new Date(value));
                          return <span>{valueFinal}</span>;
                        },
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        Header: [i18n.t("buttonActions.title")],
                        accessor: "actions",
                        Cell: (rowGroup) => {
                          return (
                            <ButtonActionsGroupWork
                              workGroupUuid={rowGroup.original.uuid}
                              groupName={rowGroup.original.name}
                              statusGroup={rowGroup.original.status}
                            />
                          );
                        },
                      },
                    ],
                  },
                ]}
                manual
                className="-striped -highlight"
                pages={totalPages}
                showPageJump={false}
                defaultPageSize={pagination.per_page}
                loading={isLoading}
                noDataText={`${i18n.t("tableRowsEmpty")}`}
                previousText={`${i18n.t("previousText")}`}
                nextText={`${i18n.t("nextText")}`}
                pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
                ofText={<span className="px-2">{i18n.t("ofText")}</span>}
                onPageSizeChange={(per_page) => {
                  setPagination({ ...pagination, per_page: per_page });
                }}
                onPageChange={(page) => {
                  setPagination({ ...pagination, page: page + 1 });
                }}
              />
            </CardBody>
          </Col>
        </Row>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserListGroupsTable.propTypes = {
  listGroups: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default UserListGroupsTable;
