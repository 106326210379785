import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Row, CardTitle, CardBody } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import { enumLevelTrd, enumsDirectoryOptions, enumsOrdenalDocumentalUnit } from "utils/enums";
import CreateDirectoryForm from "components/organism/CreateDirectoryForm";
import EditSubDivisionForm from "components/organism/EditSubDivisionForm";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const SelectFolderDocumetnalUnit = (props) => {
  const { id, backgroundId } = useParams();
  const {
    createDirectoryForm,
    setCreateDirectoryForm,
    showCreateDirectoryForm,
    setLevelFolder,
    setShowCreateDirectoryForm,
    setSelectLevel,
    selectLevel,
    option,
    setOption,
    optionEdit,
    setOptionEdit,
    showEditSubDivisonForm,
    setShowEditSubDivisonForm,
    foldersId,
    optionDisabled,
    hasFiles,
    structureTrdSeriesSubseries,
  } = props;
  const {
    editSubDivisionFormSTR,
    setEditSubDivisionFormSTR,
    getSeriesSubseriesStructure,
    setShowCreateSubDivisonForm,
  } = useTrdContext();
  const { deleteLevelOrdinationTrd } = useTrd();
  let alertMessageHasFiles = null;
  let renderSelectLevelDocumentalOptions = null;
  let hashFilesOption = null;

  /**
   *Finds the name of the subdivision oridation from the given structureTrdSeriesSubseries array based on the matching subDivisionId.
   *@param {array} structureTrdSeriesSubseries - an array of objects representing the structure of TRD series and subseries
   *@param {object} foldersId - an object containing the subDivisionId to search for
   *@returns {object} - The matching subdivision oridation object from the structureTrdSeriesSubseries array, or null if not found.
   */
  const nameSubDivisionOridation = structureTrdSeriesSubseries.find(
    (subDivisionOrdination) => {
      if (
        subDivisionOrdination !== null &&
        subDivisionOrdination !== undefined
      ) {
        return subDivisionOrdination.id === foldersId.subDivisionId;
      }
      return subDivisionOrdination;
    }
  );

  /**
   *Function that deletes a level of ordination for a given sub-division in a TRD (Table of Retention and Disposition).
   *@param {number} id - the id of the sub-division in the TRD
   *@param {number} nameSubDivisionOridation.id - the id of the sub-division in the TRD
   *@param {number} nameSubDivisionOridation.level - the level of ordination of the sub-division in the TRD
   *@param {function} deleteLevelOrdinationTrd - the function that deletes a level of ordination in the TRD
   *@param {function} setLevelFolder - the function that sets the level of the current folder in the TRD
   *@param {function} setShowCreateSubDivisonForm - the function that sets whether to show the create sub-division form
   *@param {function} setShowCreateDirectoryForm - the function that sets whether to show the create directory form
   *@param {number} backgroundId - the id of the TRD background structure
   *@param {function} getSeriesSubseriesStructure - the function that gets the series and sub-series structure of the TRD and sets the corresponding state variable
   */
  function deleteLevelInOrdinationTrd() {
    deleteLevelOrdinationTrd(
      id,
      nameSubDivisionOridation.id,
      nameSubDivisionOridation.level
    )
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.delteLevelSweetAlert"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setLevelFolder(enumLevelTrd.SUBDIVISION);
        setShowCreateSubDivisonForm(true);
        setShowCreateDirectoryForm(false);
        getSeriesSubseriesStructure(backgroundId, id);
      });
  }

  /**
   *Handles the change event for the directory selection, updating the state variables and performing corresponding actions based on the selected documental unit.
   *@param {object} eventChangeDirectory - the event object triggered by the change event
   *@param {string} targetDocumentalUnit - the value of the selected documental unit
   *@param {function} setSelectLevel - the state setter function for the selected level
   *@param {object} createDirectoryForm - the current state of the create directory form
   *@param {function} setCreateDirectoryForm - the state setter function for the create directory form
   *@param {number} setLevelFolder - the state setter function for the level folder
   *@param {boolean} setShowCreateDirectoryForm - the state setter function for showing the create directory form
   *@param {boolean} setOption - the state setter function for the option
   *@param {boolean} setOptionEdit - the state setter function for the option edit
   *@param {object} editSubDivisionForm - the current state of the edit subdivision form
   *@param {string} nameSubDivision - the name of the subdivision
   *@param {function} setShowEditSubDivisonForm - the state setter function for showing the edit subdivision form
   *@param {object} editSubDivisionFormSTR - the current state of the edit subdivision form for structure
   *@param {object} foldersId - the folder IDs
   *@param {string} nameSubDivisionOridation - the name of the subdivision in the ordination
   *@param {function} swal - the function for displaying a modal dialog
   *@param {function} deleteLevelInOrdinationTrd - the function for deleting a level in the TRD ordination
   */
  const handleOnChangeDirectory = (eventChangeDirectory) => {
    const targetDocumentalUnit = eventChangeDirectory.target.value;
    if (targetDocumentalUnit === enumsOrdenalDocumentalUnit.CREATE_DIRECTORY) {
      setSelectLevel(enumLevelTrd.DIRECTORY);
      setCreateDirectoryForm({
        ...createDirectoryForm,
        level: Number(enumLevelTrd.DIRECTORY),
      });
      setLevelFolder(Number(enumLevelTrd.DIRECTORY));
      setShowCreateDirectoryForm(true);
      setOption(false);
      setOptionEdit(false);
    }

    if (targetDocumentalUnit === enumsDirectoryOptions.EDIT_DIRECORY_FORM) {
      setEditSubDivisionFormSTR({
        ...editSubDivisionFormSTR,
        base_subdivision_uuid: foldersId.subDivisionId,
        name: nameSubDivisionOridation.label.replace(
          "(Directorio)",
          ""
        ),
      });
      setShowEditSubDivisonForm(true);
      setOption(false);
      setOptionEdit(true);
    }

    if (targetDocumentalUnit === enumsDirectoryOptions.DELETE_DIRECTORY) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("trd.delteLevel"),
        icon: "info",
        buttons: [i18n.t("chooseNot"), i18n.t("chooseYes")],
      }).then((willDelete) => {
        if (willDelete) {
          deleteLevelInOrdinationTrd();
        } else {
          setLevelFolder(enumLevelTrd.SUBDIVISION);
          setShowCreateSubDivisonForm(true);
          setOption(false);
          setOptionEdit(false);
        }
      });
    }
  };

  /**
   * Checks if files are present and sets the hashFilesOption accordingly.
   * @param {boolean} hasFiles - Indicates whether there are files.
   * @returns {OptionElement | null} The option element if hasFiles is true, otherwise null.
   */
  if (hasFiles === false) {
    hashFilesOption = (
      <option value="1">
        {i18n.t("trd.formTittle8.1")}
      </option>
    )
  };

  /**
   * Checks if the createDirectoryForm level is DIRECTORY and sets the renderSelectLevelDocumentalOptions accordingly.
   * @param {Object} createDirectoryForm - The form data.
   * @param {number} createDirectoryForm.level - The level in the form data.
   * @param {Object} enumLevelTrd - The enumeration for levels.
   * @param {number} enumLevelTrd.DIRECTORY - The DIRECTORY level.
   * @param {OptionElement | null} hashFilesOption - The option element for hash files if it exists.
   * @returns {FragmentElement | null} The fragment element with options if the level is DIRECTORY, otherwise null.
   */
  if (createDirectoryForm.level === enumLevelTrd.DIRECTORY) {
    renderSelectLevelDocumentalOptions = (
      <Fragment>
        <option value={enumsDirectoryOptions.SELECT_OPTION_EMPTY}>
          {i18n.t("trd.selectTittle")}
        </option>
        {hashFilesOption}
        <option value={enumsDirectoryOptions.EDIT_DIRECORY_FORM}>
          {i18n.t("trd.formTittle32")}
        </option>

        <option value={enumsDirectoryOptions.DELETE_DIRECTORY}>
          {" "}
          {i18n.t("buttonActions.option3") +
            " " +
            i18n.t("trd.formTittle16")}
        </option>

      </Fragment>
    );
  };

  if (option === true) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <CardBody>
            <CardTitle>{`${i18n.t("buttonActions.title")} ${i18n.t(
              "trd.formTittle16"
            )}`}</CardTitle>
            <AvForm>
              <Row>
                <Col md="12">
                  <AvGroup>
                    <AvField
                      id="status"
                      name="status"
                      type="select"
                      onChange={handleOnChangeDirectory}
                      value={selectLevel}
                      disabled={optionDisabled}
                      required
                      errorMessage={i18n.t("trd.selectTittle")}
                    >
                      {renderSelectLevelDocumentalOptions}
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
            </AvForm>
            {alertMessageHasFiles}
          </CardBody>
        </CSSTransitionGroup>
      </Fragment>
    );
  }

  if (
    option === false &&
    optionEdit === false &&
    showCreateDirectoryForm === true &&
    hasFiles === false
  ) {
    return <CreateDirectoryForm idSubDivision={foldersId.subDivisionId} />;
  }

  if (
    option === false &&
    optionEdit === true &&
    showEditSubDivisonForm === true
  ) {
    return <EditSubDivisionForm />;
  }
};

SelectFolderDocumetnalUnit.propTypes = {
  createDirectoryForm: PropTypes.object.isRequired,
  setCreateDirectoryForm: PropTypes.func.isRequired,
  showCreateDirectoryForm: PropTypes.bool.isRequired,
  setLevelFolder: PropTypes.func.isRequired,
  setShowCreateDirectoryForm: PropTypes.func.isRequired,
  setSelectLevel: PropTypes.func.isRequired,
  selectLevel: PropTypes.number.isRequired,
  option: PropTypes.bool.isRequired,
  setOption: PropTypes.func.isRequired,
  optionEdit: PropTypes.bool.isRequired,
  setOptionEdit: PropTypes.func.isRequired,
  showEditSubDivisonForm: PropTypes.bool.isRequired,
  setShowEditSubDivisonForm: PropTypes.func.isRequired,
  foldersId: PropTypes.object.isRequired,
  optionDisabled: PropTypes.bool.isRequired,
  hasFiles: PropTypes.bool.isRequired,
  structureTrdSeriesSubseries: PropTypes.array.isRequired,
};

export default SelectFolderDocumetnalUnit;