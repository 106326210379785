import React from "react";
import RecordUpLoadFilesByExpedient from "./RecordUpLoadFilesByExpedient";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";
export default (props) => {
  const {
    pathConfiguration,
    currentPath,
    hasExpedientFixedPath,
    hasOrdination,
    isRecordCreate,
    recordUuid,
    isFreeTask,
    isProcess,
    isProcessBatch,
  } = props;
  return (
    <RecordDetailContextProvider>
      <RecordUpLoadFilesByExpedient
        pathConfiguration={pathConfiguration}
        currentPath={currentPath}
        hasExpedientFixedPath={hasExpedientFixedPath}
        hasOrdination={hasOrdination}
        isRecordCreate={isRecordCreate}
        recordUuid={recordUuid}
        isFreeTask={isFreeTask}
        isProcess={isProcess}
        isProcessBatch={isProcessBatch}
      />
    </RecordDetailContextProvider>
  );
};
