import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import ButtonActions from "components/atoms/ButtonActions";
import i18n from "locales/i18n";

const UserListTable = (props) => {
  const {
    listUsers,
    pagination,
    isLoadingUserList,
    totalPages,
    setPagination,
  } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={listUsers}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("createusers.label1")],
                  accessor: "user_name",
                },
                {
                  Header: [i18n.t("createusers.label2")],
                  accessor: "document_type",

                  Cell: ({ value }) => {
                    let valueFinal = "";

                    if (value === 1) {
                      valueFinal = "Cedula de Ciudadanía";
                    } else {
                      valueFinal = "Cedula Extranjería";
                    }

                    return <p>{valueFinal}</p>;
                  },
                },
                {
                  Header: [i18n.t("createusers.label3")],
                  accessor: "document_reference",
                },
                {
                  Header: [i18n.t("createusers.label4")],
                  accessor: "first_name",
                },
                {
                  Header: [i18n.t("createusers.label10")],
                  accessor: "last_name",
                },
                {
                  Header: [i18n.t("createusers.label5")],
                  accessor: "email",
                },
                {
                  Header: [i18n.t("createusers.label6")],
                  accessor: "phone",
                },
                {
                  Header: [i18n.t("createusers.label8")],
                  accessor: "status",
                  Cell: ({ value }) => {
                    let statusFinal = "";
                    if (value === 1) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("filteruser.item2")}
                        </div>
                      );
                    } else if (value === 2) {
                      statusFinal = (
                        <div className="ml-auto badge badge-info">
                          {i18n.t("filteruser.item4")}
                        </div>
                      );
                    } else if (value === 3) {
                      statusFinal = (
                        <div className="ml-auto badge badge-warning">
                          {i18n.t("filteruser.item3")}
                        </div>
                      );
                    } else if (value === 4) {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("filteruser.item5")}
                        </div>
                      );
                    } else if (value === 5) {
                      statusFinal = (
                        <div className="ml-auto badge badge-info">
                          {i18n.t("filteruser.item7")}
                        </div>
                      );
                    }
                    return <sapn>{statusFinal}</sapn>;
                  },
                },
              ],
            },
            {
              columns: [
                {
                  Header: [i18n.t("buttonActions.title")],
                  accessor: "actions",
                  Cell: (row) => {
                    return (
                      <ButtonActions
                        userId={row.original.user_name}
                        userUuid={row.original.uuid}
                      />
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPages}
          page={pagination.page - 1}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          loading={isLoadingUserList}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserListTable.propTypes = {
  listUsers: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.object.isRequired,
  isLoadingUserList: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default UserListTable;
