import React, { Fragment } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import AdminTemplate from "components/templates/AdminTemplate";
import CreateListForm from "components/organism/CreateListForm";
import CreateItemsListForm from "components/organism/CreateItemsListForm";
import WizardList from "components/molecules/WizardList";
import CreateListOk from "components/molecules/Messages/Success/CreateListOk";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";

const CreateList = () => {
  const { id } = useParams();
  const listID = id;
  let headerPage = i18n.t("list.designerItem1");
  let subHeaderPage = i18n.t("list.designerMotto");
  const steps = [
    {
      name: [i18n.t("list.designerItem2")],
      component: <CreateListForm isWizard={true} />,
    },
    {
      name: [i18n.t("itemList.designerItem2")],
      component: <CreateItemsListForm />,
    },
    { name: [i18n.t("form.field4")], component: <CreateListOk /> },
  ];

  /**
   * Sets the header and subheader for the page if the provided list ID is not null or undefined.
   * If the list ID is not null or undefined, it sets the header to a predefined value and the subheader to another predefined value.
   * @param {*} listID - The ID of the list to be checked. If this parameter is null or undefined, the function does not change the header or subheader.
   * @returns {void}
   */
  if (isNullOrUndefined(listID) === false) {
    headerPage = i18n.t("list.designerItem1");
    subHeaderPage = i18n.t("list.designerMotto");
  }

  return (
    <Fragment>
      <AdminTemplate
        heading={headerPage}
        subheading={subHeaderPage}
        icon="menu"
      >
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <div className="forms-wizard-vertical">
                  <WizardList showNavigation={true} steps={steps} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </AdminTemplate>
    </Fragment>
  );
};

export default CreateList;
