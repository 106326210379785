import React, { Fragment } from "react";
import { Button } from "reactstrap";
import i18n from "locales/i18n";
import { useParams } from "react-router-dom";

const CreateFieldOk = () => {
  let { id: fieldId } = useParams();

  /**
   * Redirects the user to a specific URL based on the field ID.
   * @return {void}
   */
  const redirection = () => {
    window.location.href = `/formsdesigner/fields/${fieldId}`
  };

  return (
    <Fragment>
      <div className="form-wizard-content">
        <div className="no-results">
          <div className="sa-icon sa-success animate">
            <span className="sa-line sa-tip animateSuccessTip" />
            <span className="sa-line sa-long animateSuccessLong" />
            <div className="sa-placeholder" />
            <div className="sa-fix" />
          </div>
          <div className="results-subtitle mt-4">
            {i18n.t("modal.Done.body")}
          </div>
          <div className="results-title">{i18n.t("form.fieldMessage")}</div>
          <div className="mt-3 mb-3" />
          <div className="text-center">
            <Button
              color="success"
              size="lg"
              className="btn-shadow btn-wide"
              onClick={() => redirection()}
            >
              {i18n.t("modal.Done.footerButton")}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateFieldOk;
