import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import CreateUserWorkGroup from "components/organism/CreateUserWorkgroup";
import i18n from "locales/i18n";

const UserWorkgroupForm = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("create.work.title")}
				subheading={i18n.t("create.work.subHeading")}
				icon="note2"
			>
				<CreateUserWorkGroup />
			</AdminTemplate>
		</Fragment>
	);
};

export default UserWorkgroupForm;
