import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { Button, CardFooter, CustomInput, Alert, Row, Col } from "reactstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { enumsWorkflowStatus } from "utils/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import renderSpinner from "utils/spinnerButton";
import i18n from "locales/i18n";

const WorkFlowConfigStepsTab2OtherActions = (props) => {
  const {
    handleOnSubmitActions,
    workflowStatus,
    handleOnChangeTab,
    reassign,
    disabledOptionByStatusProcess,
    disabledOptionSelectProcess,
    isLoading,
  } = props;
  let alertStatusWorkflowWrong = null;

  /**
   * Conditional Rendering of Workflow Status Alert
   * This code conditionally renders an alert component if the workflowStatus is not equal to 1.
   * The alert informs the user about an invalid workflow status.
   * @param {number} workflowStatus - The status of the workflow, where 1 indicates a valid status.
   * @param {string} i18n - An internationalization function for translating text.
   * @returns {JSX.Element|null} The JSX element representing the alert, or null if the workflowStatus is 1.
   */
  if (workflowStatus !== enumsWorkflowStatus.ERASER) {
    alertStatusWorkflowWrong = (
      <Alert className="mbg-3 ext-primary font-weight-bold" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        {i18n.t("WorkFlowEdit.Invalid")}
      </Alert>
    );
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleOnSubmitActions}>
          <Row>
            <Col lg="5" md="4" sm="12" className="button-container pb-2">
              <h6 className="text-info font-weight-bold">
                {i18n.t("createWorkflow.config.otherActions").toUpperCase()}
              </h6>
            </Col>
          </Row>

          <AvInput
            id="allow_reassign"
            name="allow_reassign"
            type="checkbox"
            label={i18n.t("createWorkflow.config.allowReassing")}
            tag={CustomInput}
            onChange={handleOnChangeTab}
            value={reassign.allow_reassign}
            disabled={disabledOptionByStatusProcess}
          />

          <AvInput
            id="allow_interrupt_process"
            name="allow_interrupt_process"
            type="checkbox"
            label={i18n.t("createWorkflow.config.allowInterrupt")}
            tag={CustomInput}
            onChange={handleOnChangeTab}
            value={reassign.allow_interrupt_process}
            disabled={disabledOptionByStatusProcess}
          />

          <AvInput
            id="allow_return_to_step"
            name="allow_return_to_step"
            type="checkbox"
            label={i18n.t("createWorkflow.config.allowStepBack")}
            tag={CustomInput}
            onChange={handleOnChangeTab}
            value={reassign.allow_return_to_step}
            disabled={disabledOptionByStatusProcess}
          />
          <br />
          {alertStatusWorkflowWrong}

          <CardFooter className="d-block text-right">
            <Button
              type="submit"
              size="lg"
              disabled={disabledOptionSelectProcess}
              className="col-mt-3"
              color="cyan"
            >
              {renderSpinner(isLoading)}
              {i18n.t("createWorkflowDesign.saveModal")}
            </Button>
          </CardFooter>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkFlowConfigStepsTab2OtherActions.propTypes = {
  handleOnSubmitActions: PropTypes.func.isRequired,
  workflowStatus: PropTypes.string.isRequired,
  handleOnChangeTab: PropTypes.func.isRequired,
  reassign: PropTypes.func,
  disabledOptionByStatusProcess: PropTypes.bool,
  disabledOptionSelectProcess: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default WorkFlowConfigStepsTab2OtherActions;
