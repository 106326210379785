import React, { Fragment, useState, useCallback, useEffect } from "react";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";
import Select from "react-select";
import i18n from "locales/i18n";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import useList from "hooks/useList";
import { useListContext } from "contextAPI/ListsContext";

const ComboBox = () => {
  const [lists, setLists] = useState([]);
  const { fieldForm, setFieldForm, setIsLoading } = useFieldsRecordContext();

  const { setCanNext } = useListContext();

  const [showForms, setShowForms] = useState(false);
  const [showLists, setShowLists] = useState(false);

  const { getLists } = useList();

  const getListsCallback = useCallback(() => {
    let pagination = { page: 1, per_page: 100 };
    const { page, per_page } = pagination;
    setIsLoading(true);
    getLists(page, per_page, "")
      .then((response) => {
        let listActive = response.data.items;
        let newListActive = listActive.filter((e) =>
          e.status === 1 ? e : null
        );
        setLists(newListActive);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getListsCallback();
  }, [getListsCallback]);

  useEffect(() => {
    if (!fieldForm.link || !fieldForm.item_link) {
      setCanNext(true);
    } else {
      setCanNext(false);
    }
  }, [fieldForm.link, !fieldForm.item_link]); // eslint-disable-line react-hooks/exhaustive-deps

  let options = lists.map((elemento) => {
    return { value: `${elemento.uuid}`, label: `${elemento.name}` };
  });

  return (
    <Fragment>
      <AvGroup row>
        <Label for="link" className="is-required" sm={4}>
          {i18n.t("form.field32")}
        </Label>
        <Col md={8}>
          <AvField
            onChange={(e) => {
              const target = e.target.value;
              if (target === "2") {
                setShowForms(true);
              } else {
                setShowForms(false);
              }
              if (e.target.value === "1") {
                setShowLists(true);
              } else {
                setShowLists(false);
              }

              setFieldForm({ ...fieldForm, link: Number(target) });
            }}
            type="select"
            name="link"
            required
            errorMessage={i18n.t("form.fieldFeedback3")}
          >
            <option value="">{i18n.t("form.field33")}</option>
            <option value={1}>{i18n.t("form.field44")}</option>
            {/* <option value={2}>{i18n.t("form.field43")}</option> */}
          </AvField>
        </Col>
      </AvGroup>

      {showForms ? (
        <AvGroup row>
          <Label for="Fomrs" className="is-required" sm={4}>
            {i18n.t("form.field43")}
          </Label>
          <Col md={8}>
            <Select />
          </Col>
        </AvGroup>
      ) : null}

      {showLists ? (
        <AvGroup row>
          <Label for="Lists" className="is-required" sm={4}>
            {i18n.t("form.field44")}
          </Label>
          <Col md={8}>
            <Select
              options={options}
              onChange={(e) => {
                let value = e.value;
                setFieldForm({
                  ...fieldForm,
                  item_link: value,
                });
              }}
            />
          </Col>
        </AvGroup>
      ) : null}
    </Fragment>
  );
};

export default ComboBox;
