import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Progress, Row } from "reactstrap";
import { DOMAIN } from "constants/securityConst";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { acceptTypeFiles, acceptFilesRegex } from "constants/acceptFiles";
import { enumsDropzoneValues } from "utils/enums";
import { regexSubjectUploadFile } from "utils/regexExpressions";
import { capitalizeText } from "utils/formatText";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { loaderElement } from "utils/loaderElement";
import Loader from "react-loaders";
import renderIconFile from "utils/renderIconFile";
import useCompany from "hooks/useCompany";
import i18n from "locales/i18n";

const DropZone = (props) => {
  const { isProcessBatch, fileByTrd, recordUuid, taskId, formUuid } = props;
  const DOMAIN_COMPANY = window.localStorage.getItem(DOMAIN);
  const [maxSize, setMaxSize] = useState(null);
  const [minSize] = useState(enumsDropzoneValues.MIN_SIZE);
  const [byte] = useState(enumsDropzoneValues.BYTE);
  const [kiloByte] = useState(enumsDropzoneValues.KILOBYTE);
  const [multiplicateFactor] = useState(enumsDropzoneValues.MULTIPLICATOR);
  const [isLoadingInfoCompany, setIsLoadingInfoCompany] = useState(false);
  const [isLoadingOndragFile, setIsLoadingOndragFile] = useState(false);
  const [errorFileMessage, setErrorFileMessage] = useState("");
  const [maxSizeExceded, setMaxSizeExceded] = useState(false);
  const { getCompany } = useCompany();
  const {
    isLoading,
    files,
    setFiles,
    uploadForm,
    hasFileWithoutTrd,
    setHasFileWithoutTrd,
    setDisabledUploadFile,
    hasErrorOccurred,
    setHasErrorOccurred,
    refreshValidateDropzone,
    setRefreshValidateDropzone,
  } = useRecordDetailContext();
  const {
    setConfigProcessBatch,
    uploadFormWorkflow,
    setUploadFormWorkflow,
  } = useWorkflowContext();
  const megabytes = bytesToMegabytes(maxSize);
  let iconDrag = "📁";
  let progressBar = null;
  let renderFilesUploaded = null;
  let rejectedFilesNotUpload = null;
  let acceptedFilestoUpload = null;
  let notAllowedFiles = "";
  let colorDropzone = "info";
  let messageErrorFound = null;
  let maxFilesByFunctionality =
    enumsDropzoneValues.RECORDS_PROCESS_TASK_MAX_FILES;
  let disableDropZone = false;
  let maxFilesAllowed = null;

  /**
   * Resets the state of a specific form file at the given index.
   * @param {number} index - The index of the file in the uploadFormWorkflow.files array to be reset.
   * It must be a non-negative integer and within the bounds of the array length.
   * @param {object} uploadFormWorkflow - The state object containing the files array to be modified.
   * @param {Array<object>} uploadFormWorkflow.files - An array of files representing the current state of the form.
   * @param {function} setUploadFormWorkflow - State setter function to update the uploadFormWorkflow state.
   * @returns {void}
   */
  const resetFormState = (index) => {
    if (index >= 0 && index < uploadFormWorkflow.files.length) {
      const nuevosArchivos = [...uploadFormWorkflow.files];
      nuevosArchivos.splice(index, 1);
      setUploadFormWorkflow({ files: nuevosArchivos });
    }
  };

  /**
   * Calculates the total size of files uploaded in the upload form workflow.
   * @returns {number} The total size of files uploaded.
   */
  const totalSizeFilesUpload = () => {
    return uploadFormWorkflow.files.reduce(
      (total, archivo) => total + archivo.size,
      0
    );
  };

  /**
   * Checks if the total size of files to be added, when combined with the total size of already uploaded files,
   * exceeds a specified maximum size limit.
   * @param {File[]} filesToAdd - An array of File objects representing the files to be added.
   * @returns {boolean} - Returns true if the total size of files to be uploaded does not exceed the maximum size limit, otherwise false.
   */
  const checkTotalSizeFilesDragged = (filesToAdd) => {
    const totalSizeOfFilesToAdd = filesToAdd.reduce(
      (accepted, file) => accepted + file.size,
      0
    );
    return totalSizeFilesUpload() + totalSizeOfFilesToAdd <= maxSize;
  };

  /**
   * Handles the onDrop event triggered when files are dropped onto a drop zone.
   * @param {File[]} acceptedFiles - An array of files accepted by the drop zone.
   * @returns {void}
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsLoadingOndragFile(true);
      setHasErrorOccurred(false);
      setMaxSizeExceded(false);
      setRefreshValidateDropzone(false);

      const handleFileLoadStart = () => {
        setIsLoadingOndragFile(true);
      };

      const handleFileError = () => {
        setHasErrorOccurred(true);
        setErrorFileMessage(i18n.t("recordDetail.errorUploadFile"));
      };

      const handleFileLoadEnd = () => {
        setIsLoadingOndragFile(false);
      };

      const loadFiles = (files) => {
        files.forEach((file) => {
          const reader = new FileReader();
          reader.onloadstart = handleFileLoadStart;
          reader.onerror = handleFileError;
          reader.onloadend = handleFileLoadEnd;
          reader.readAsArrayBuffer(file);
        });
      };

      const filteredFiles = acceptedFiles.filter((file) =>
        checkTotalSizeFilesDragged([file])
      );

      const newfilteredFiles = acceptedFiles.reduce(
        (accepted, file) => accepted + file.size,
        0
      );

      if (
        (isProcessBatch === true &&
          uploadFormWorkflow.files.length + filteredFiles.length >
            enumsDropzoneValues.PROCESS_BATCH_TASK_MAX_FILES) ||
        (isProcessBatch === false &&
          uploadFormWorkflow.files.length + filteredFiles.length >
            enumsDropzoneValues.RECORDS_PROCESS_TASK_MAX_FILES)
      ) {
        setErrorFileMessage(
          `(${i18n.t("recordDetail.alert")} ${maxFilesByFunctionality} ${i18n.t(
            "recordDetail.alert2"
          )})`
        );
        setMaxSizeExceded(true);
        setHasErrorOccurred(true);
        setIsLoadingOndragFile(false);
        return;
      }

      if (newfilteredFiles > maxSize) {
        setErrorFileMessage(i18n.t("recordDetail.errorUploadFile2"));
        setHasErrorOccurred(true);
        setIsLoadingOndragFile(false);
        return;
      }

      if (acceptedFiles.length !== filteredFiles.length) {
        setErrorFileMessage(
          i18n.t("recordDetail.errorUploadFile3") +
            bytesToMegabytes(maxSize) +
            i18n.t("recordDetail.errorUploadFile4")
        );
        setHasErrorOccurred(true);
        setIsLoadingOndragFile(false);
        return;
      }

      loadFiles(filteredFiles);

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
      setUploadFormWorkflow((prevUploadFormWorkflow) => ({
        ...prevUploadFormWorkflow,
        files: [...prevUploadFormWorkflow.files, ...filteredFiles],
      }));
      setConfigProcessBatch((prevConfigProcessBatch) => ({
        ...prevConfigProcessBatch,
        file: [...filteredFiles],
      }));
    },
    [maxSize, uploadFormWorkflow, isProcessBatch] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Determines the maximum number of files allowed based on the functionality type.
   * @param {boolean} isProcessBatch - A flag indicating whether the functionality is a process batch task.
   * @param {object} enumsDropzoneValues - An object containing enumeration values for dropzone functionalities.
   * @returns {number} The maximum number of files allowed for the given functionality.
   */
  if (isProcessBatch === true) {
    maxFilesByFunctionality = enumsDropzoneValues.PROCESS_BATCH_TASK_MAX_FILES;
    maxFilesAllowed = <em>{i18n.t("recordDetail.dragnDropMaxFilesBatch")}</em>;
  } else {
    maxFilesByFunctionality =
      enumsDropzoneValues.RECORDS_PROCESS_TASK_MAX_FILES;
    maxFilesAllowed = <em>{i18n.t("recordDetail.dragnDropMaxFiles")}</em>;
  }

  /**
   * Checks if the number of uploaded files matches the maximum allowed files for a particular functionality,
   * and sets a flag to disable the drop zone if the maximum limit is reached.
   * @param {Array} files - An array containing the uploaded files.
   * @param {number} maxFiles - The maximum number of files allowed for the functionality.
   * @returns {boolean} - Flag indicating whether the drop zone should be disabled.
   */
  if (uploadFormWorkflow.files.length === maxFilesByFunctionality) {
    disableDropZone = true;
  }

  /**
   * Custom hook that provides properties and methods for handling file drop zone functionality.
   * @param {object} options - Options object containing various configuration parameters.
   * @param {number} options.maxSize - Maximum allowed size (in bytes) for each file.
   * @param {number} options.minSize - Minimum allowed size (in bytes) for each file.
   * @param {number} options.maxFiles - Maximum allowed number of files.
   * @param {string | string[]} options.accept - Accepted file types as a MIME type string or an array of MIME types.
   * @param {boolean} options.disabled - Flag indicating whether the drop zone is disabled.
   * @param {function} options.validator - Custom function to validate files.
   * @param {function} options.onDrop - Callback function invoked when files are dropped.
   * @param {function} options.onFileDialogCancel - Callback function invoked when the file dialog is canceled.
   * @returns {object} - An object containing properties and methods for managing file drop functionality.
   * @property {boolean} isDragActive - Flag indicating whether a file is being dragged over the drop zone.
   * @property {array} fileRejections - Array containing details of files that have been rejected.
   * @property {function} getRootProps - Function to retrieve props to be spread onto the root drop zone element.
   * @property {function} getInputProps - Function to retrieve props to be spread onto the input element for file selection.
   */
  const {
    isDragActive,
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxSize: maxSize,
    minSize: minSize,
    maxFiles: maxFilesByFunctionality,
    accept: acceptTypeFiles,
    disabled: disableDropZone,
    validator: filesValidator,
    onDrop,
    onFileDialogCancel: () => {
      setIsLoadingOndragFile(false);
    },
  });

  /**
   * Determines the icon to display based on the drag activity status.
   * @param {boolean} isDragActive - Indicates whether a drag operation is currently active.
   * @returns {string} The icon representing the drag status. If `isDragActive` is `true`, returns the folder icon "📂".
   */
  if (isDragActive === true) {
    iconDrag = "📂";
  }

  /**
   * Checks if the number of files uploaded matches the maximum allowed for the given functionality
   * and sets the color of the dropzone accordingly.
   * @param {Array} uploadFormWorkflow.files - Array containing the uploaded files.
   * @param {number} maxFilesByFunctionality - The maximum number of files allowed for the specific functionality.
   * @returns {string} - The color of the dropzone. It will be "gray" if the maximum number of files has been reached.
   */
  if (uploadFormWorkflow.files.length === maxFilesByFunctionality) {
    colorDropzone = "gray";
  }

  /**
   * Retrieves information about the company associated with the provided domain.
   * Updates the component state with the maximum file size allowed by the company.
   * @param {string} domain - The domain associated with the company for which information is to be fetched.
   * @param {boolean} setIsLoadingInfoCompany - State setter function to update the loading state while fetching company information.
   * @param {function} getCompany - A function that fetches information about the company using the provided domain.
   * @param {function} setMaxSize - State setter function to update the maximum file size based on the company information.
   * @param {number} multiplicateFactor - A factor used to calculate the maximum file size based on the company's file_max_size.
   * @param {function} showAlertServiceError - A function to display an alert in case of an error fetching company information.
   * @returns {void}
   */
  const getInfoCompany = useCallback(() => {
    setIsLoadingInfoCompany(true);
    getCompany(DOMAIN_COMPANY)
      .then((response) => {
        if (isNullOrUndefined(response.data.company.file_max_size) === false) {
          const companyFileSize = response.data.company.file_max_size;
          setMaxSize(Math.ceil(companyFileSize * multiplicateFactor));
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingInfoCompany(false);
      });
  }, [DOMAIN_COMPANY]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Converts a size in bytes to either megabytes (MB) or kilobytes (KB) based on the size.
   * @param {number} fileBytes - The size in bytes to be converted.
   * @returns {string} A string representing the size in either megabytes (MB) or kilobytes (KB).
   */
  function bytesToMegabytes(fileBytes) {
    if (fileBytes >= byte) {
      return (fileBytes / byte).toFixed(2) + " MB";
    } else {
      return Math.ceil(fileBytes / kiloByte) + " KB";
    }
  }

  /**
   * Validates a file based on various criteria such as name length, allowed characters, size, and type.
   * @param {File} file - The file to be validated.
   * @returns {Object | null} An object containing a code and message if validation fails, or null if the file is valid.
   */
  function filesValidator(file) {
    let lenghtNameFile;

    const fileExtension = file.name.lastIndexOf(".");
    if (fileExtension === -1) {
      lenghtNameFile = file.name.length;
    } else {
      lenghtNameFile = fileExtension;
    }

    if (lenghtNameFile < enumsDropzoneValues.MIN_CHRACTERS_NAME_FILE) {
      return {
        code: "name-too-short",
        message: i18n.t("recordDetail.lenghtShortFilename"),
      };
    }

    if (lenghtNameFile > enumsDropzoneValues.MAX_CHARACTERS_NAME_FILE) {
      return {
        code: "name-too-large",
        message: `${i18n.t("recordDetail.lenghtFilename")}${
          enumsDropzoneValues.MAX_CHARACTERS_NAME_FILE
        }`,
      };
    }

    if (!regexSubjectUploadFile.test(file.name)) {
      return {
        code: "name-characters-not-allowed",
        message: i18n.t("recordDetail.worngCaractersFilename"),
      };
    }

    if (file.size <= minSize) {
      return {
        code: "file-too-small-to-upload",
        message: i18n.t("recordDetail.filesSizeNotAllowed"),
      };
    }

    if (file.size > maxSize) {
      return {
        code: "file-too-large-to-upload",
        message: `${i18n.t("recordDetail.filesToLarge")} ${megabytes}`,
      };
    }

    if (!acceptFilesRegex.test(file.type)) {
      return {
        code: "file-invalid-type-not-allowed",
        message: i18n.t("40024"),
      };
    }

    return null;
  }

  /**
   * Generates an array of JSX elements representing accepted file items with associated icons and buttons.
   * @param {Array<Object>} acceptedFiles - An array of accepted file objects.
   * @param {Function} renderIconFile - A function to render the icon and class name for a given file name.
   * @param {Function} capitalizeText - A function to capitalize the first letter of a string.
   * @param {Function} bytesToMegabytes - A function to convert bytes to megabytes.
   * @param {Function} resetFormState - A function to reset the state of the form.
   * @returns {Array<JSX.Element>} - An array of JSX elements representing accepted file items.
   */
  const acceptedFileItems = uploadFormWorkflow.files.map((file, index) => {
    const { icon, className } = renderIconFile(file.name);
    return (
      <Row className="mb-2">
        <Col md={8} className="font-weight-bold">
          <span key={file.path}>
            <FontAwesomeIcon
              className={`icon-opacity fa-2x mr-2 ml-auto ${className}`}
              icon={icon}
            />
            {capitalizeText(file.path)} - {bytesToMegabytes(file.size)}
          </span>
        </Col>
        <Col md={4} className="text-right">
          <Button
            className="ml-2 btn-icon btn-icon-only btn-shadow btn-outline-2x"
            size="sm"
            outline
            color="danger"
            onClick={() => resetFormState(index)}
          >
            <i className="pe-7s-trash btn-icon-wrapper"> </i>
          </Button>
        </Col>
      </Row>
    );
  });

  /**
   * Maps file rejections to a formatted representation for rendering.
   * @param {Array<Object>} fileRejections - An array of objects containing file and error details.
   * @param {Object} file - The file object containing details such as name, path, and size.
   * @param {Array<Object>} errors - An array of error objects associated with the file.
   * @param {string} icon - The icon to be rendered based on the file type.
   * @param {string} className - Additional classes for styling the rendered icon.
   * @returns {Array<JSX.Element>} An array of JSX elements representing the formatted file rejection items.
   */
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    const { icon, className } = renderIconFile(file.name);
    return (
      <div>
        <FontAwesomeIcon
          className={`icon-opacity fa-2x mr-2 ml-auto ${className}`}
          icon={icon}
        />
        {capitalizeText(file.path)} - {bytesToMegabytes(file.size)}
        <ul>
          {errors.map((error) => {
            if (error.code === "too-many-files") {
              notAllowedFiles = `${i18n.t(
                "recordDetail.alert"
              )} ${maxFilesByFunctionality} ${i18n.t("recordDetail.alert2")}`;
            }

            if (
              error.code === "file-invalid-type" ||
              error.code === "file-too-large" ||
              error.code === "file-too-small"
            ) {
              return null;
            } else {
              return (
                <li key={error.code}>
                  <em className="font-weight-bold">{`(${error.message})`}</em>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  });

  /**
   * Determines the list of errors to be rendered based on file rejection items and any not allowed files.
   * @param {Array} fileRejectionItems - List of rejected files.
   * @param {string} notAllowedFiles - List of not allowed files.
   * @returns {JSX.Element|Array} - JSX Element or array representing the errors to be rendered.
   */
  let errorsToRender = fileRejectionItems;
  if (notAllowedFiles !== "") {
    errorsToRender = (
      <ul>
        <li key={notAllowedFiles}>
          <em className="font-weight-bold">{`(${notAllowedFiles})`}</em>
        </li>
      </ul>
    );
  }

  /**
   * Generates error message and list of rejected files if error occurred during file validation.
   * @param {boolean} hasErrorOccurred - Flag indicating if an error has occurred.
   * @param {boolean} maxSizeExceeded - Flag indicating if the maximum size allowed is exceeded.
   * @param {Array<Object>} acceptedFiles - Array of accepted files.
   * @param {Function} renderIconFile - Function to render icon and class name for file.
   * @param {Function} capitalizeText - Function to capitalize text.
   * @param {Function} bytesToMegabytes - Function to convert bytes to megabytes.
   * @param {string} errorFileMessage - Error message for rejected files.
   * @param {number} notAllowedFiles - Number of files not allowed.
   * @returns {JSX.Element} - Error message and list of rejected files wrapped in an Alert component.
   */
  if (hasErrorOccurred === true) {
    let errorFilesValidatedRejection = null;
    if (maxSizeExceded === false) {
      errorFilesValidatedRejection = acceptedFiles.map((file) => {
        const { icon, className } = renderIconFile(file.path);
        return (
          <div className="mb-2">
            <FontAwesomeIcon
              className={`icon-opacity fa-2x mr-2 ml-auto ${className}`}
              icon={icon}
            />
            {capitalizeText(file.path)} - {bytesToMegabytes(file.size)}
          </div>
        );
      });
    }
    messageErrorFound = (
      <Alert color="danger">
        <span className="pr-2 d-block mb-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          <span className="font-weight-bold">
            {i18n.t("recordDetail.rejectedFile")}
          </span>
        </span>
        <span>{errorFilesValidatedRejection}</span>
        <ul>
          <li key={notAllowedFiles}>
            <em className="font-weight-bold">{errorFileMessage}</em>
          </li>
        </ul>
      </Alert>
    );
  }

  /**
   * Renders a list of accepted files for upload if there are files available.
   * @param {Array} files - Array containing files to be uploaded.
   * @param {JSX.Element} acceptedFileItems - JSX element representing the accepted file items.
   * @param {function} i18n.t - Function for translating text to the desired language.
   * @returns {JSX.Element|null} - Returns a JSX element containing the list of accepted files for upload, or null if no files are available.
   */
  if (
    uploadFormWorkflow.files.length > 0 &&
    uploadFormWorkflow.files.length <= 3
  ) {
    acceptedFilestoUpload = (
      <div className="mb-4">
        <div>
          <h6 className="text-info font-weight-bold mb-4">
            {i18n.t("recordDetail.files")}:
          </h6>
        </div>
        {acceptedFileItems}
      </div>
    );
  }

  /**
   * Renders an alert message for rejected files if there are any.
   * @param {Array} fileRejectionItems - Array containing rejected file items.
   * @param {JSX.Element} errorsToRender - JSX element representing the errors to be rendered in the alert.
   * @returns {JSX.Element|null} - Returns JSX element for the alert if there are rejected files, otherwise returns null.
   */
  if (fileRejectionItems.length > 0 && refreshValidateDropzone === false) {
    rejectedFilesNotUpload = (
      <Alert color="danger">
        <span className="pr-2 d-block mb-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          <span className="font-weight-bold">
            {i18n.t("recordDetail.rejectedFile")}
          </span>
        </span>
        {errorsToRender}
      </Alert>
    );
  }

  /**
   * Generates the rendering output for uploaded files based on the loading state.
   * @param {boolean} isLoadingOndragFile - Flag indicating whether files are currently being loaded during a drag event.
   * @param {function} loaderComponent - Function to generate the loader component for displaying loading state.
   * @param {React.ReactNode} acceptedFilestoUpload - React component representing the accepted files to be uploaded.
   * @param {React.ReactNode} rejectedFilesNotUpload - React component representing the rejected files that were not uploaded.
   * @returns {React.ReactNode} The rendered output for uploaded files based on the loading state.
   */
  if (isLoadingOndragFile === true) {
    renderFilesUploaded = (
      <div>
        <Loader type="ball-pulse" color="gray" size="sm" />
      </div>
    );
  } else {
    renderFilesUploaded = null;
  }

  /**
   * Generates a progress bar element if the isLoading state is true.
   * @param {boolean} isLoading - A boolean indicating whether loading is in progress.
   * @returns {JSX.Element|null} - A progress bar element or null if isLoading is false.
   */
  if (isLoading === true) {
    progressBar = (
      <Progress
        className="mb-3 mt-3 progress-bar-animated-alt"
        color="info"
        value={100}
      />
    );
  }

  /**
   * Executes a side effect to retrieve information about the company upon component mount.
   * @returns {void}
   */
  useEffect(() => {
    getInfoCompany();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect cleanup function to revoke Object URLs associated with preview files.
   * This effect is triggered when the 'files' dependency changes, ensuring that Object URLs
   * created for file previews are revoked to prevent memory leaks.
   * @param {Array<File>} files - An array of File objects representing the files with associated previews.
   * @returns {void}
   */
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (uploadFormWorkflow.files.length === 0) {
      setDisabledUploadFile(true);
    }
  }, [uploadFormWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when `uploadFormWorkflow.uploadForm` changes.
   * This effect updates the `configProcessBatch` state.
   * @param {Object} uploadFormWorkflow - The object containing `uploadForm`.
   * @param {string} uploadFormWorkflow.uploadForm - The value to track for changes.
   * @param {Function} setConfigProcessBatch - The function to update the state.
   * @param {Object} prevConfigProcessBatch - The previous state of `configProcessBatch`.
   * @param {string} taskId - The task ID.
   * @param {string} recordUuid - The record UUID.
   * @param {string} formUuid - The form UUID.
   * @param {any} fileByTrd - The file by TRD (To-Receive-Document).
   * @returns {void}
   */
  useEffect(() => {
    setConfigProcessBatch((prevConfigProcessBatch) => ({
      ...prevConfigProcessBatch,
      taskId,
      recordId: recordUuid,
      formId: formUuid,
      fileByTrd,
      uploadForm: uploadForm,
    }));
  }, [uploadForm]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that manages the state of file upload workflow and its associated properties.
   * @param {boolean} hasFileWithoutTrd - Indicates whether there are files without a TRD (Technical Reference Document).
   * @param {Object} uploadFormWorkflow - The current state of the upload form workflow.
   * @param {function} setHasFileWithoutTrd - State setter function for updating the flag indicating files without TRD.
   * @param {function} setUploadFormWorkflow - State setter function for updating the upload form workflow state.
   * @returns {void}
   */
  useEffect(() => {
    setHasFileWithoutTrd(uploadFormWorkflow);
    if (hasFileWithoutTrd === undefined) {
      setUploadFormWorkflow({
        ...uploadFormWorkflow,
        files: [],
      });
    }
  }, [hasFileWithoutTrd, uploadFormWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when the array of rejected files changes.
   * If there are rejected files, sets the loading state for drag-and-drop file interaction to false.
   * @param {Array} fileRejectionItems - An array containing rejected file items.
   * @returns {void}
   */
  useEffect(() => {
    if (fileRejectionItems.length > 0) {
      setIsLoadingOndragFile(false);
    }
  }, [fileRejectionItems]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingInfoCompany === true) {
    return loaderElement(true);
  } else {
    return (
      <section>
        <div
          {...getRootProps({
            className: "dropzone",
            onClick: () => {
              setIsLoadingOndragFile(true);
            },
          })}
        >
          <Alert className="mbg-3" color={colorDropzone}>
            <span className="mr-3 text-lg d-block">
              {iconDrag} {i18n.t("recordDetail.dragnDrop")}
            </span>
            <input {...getInputProps()} />
            <span className="pr-2 d-block mb-2">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              {i18n.t("recordDetail.filesSizeAllowed")}
              <span className="font-weight-bold ">
                {bytesToMegabytes(maxSize)}
              </span>
            </span>
            <span>{maxFilesAllowed}</span>
          </Alert>
        </div>
        <div>
          <aside>
            {acceptedFilestoUpload}
            {rejectedFilesNotUpload}
          </aside>
          {renderFilesUploaded}
          {progressBar}
          {messageErrorFound}
        </div>
      </section>
    );
  }
};

DropZone.propTypes = {
  isProcessBatch: PropTypes.bool.isRequired,
  fileByTrd: PropTypes.bool.isRequired,
  recordUuid: PropTypes.string.isRequired,
  taskId: PropTypes.string,
  formUuid: PropTypes.string.isRequired,
};
export default DropZone;
