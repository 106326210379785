import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import RecordTraceabilitys from "components/organism/RecordTraceabilitys";
import i18n from "locales/i18n";

const RecordTraceability = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("recordTraceability.header")}
        subheading={i18n.t("recordTraceability.subHeader")}
        icon="news-paper"
      >
        <RecordTraceabilitys />
      </AdminTemplate>
    </Fragment>
  );
};

export default RecordTraceability;
