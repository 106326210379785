import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import AuditLogsList from "components/organism/AuditLogsList";
import i18n from "locales/i18n";

const AuditLogs = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("AuditLogs.nav")}
        subheading={i18n.t("AuditLogs.subHeader")}
        icon="timer"
      >
        <AuditLogsList />
      </AdminTemplate>
    </Fragment>
  );
};

export default AuditLogs;
