import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import i18n from "locales/i18n";

const ButtonRequestInt = (props) => {
  const { id: idIntegration, disabled: disableIntegration} = props;

  let buttonToggleIntegrationReq = null;

  /**
   * Renders a button toggle for integration request.
   *
   * @param {boolean} disabled - Indicates whether the button should be disabled.
   * @param {string} id - The identifier for the integration request.
   * @returns {JSX.Element} The button toggle for the integration request.
   */
  if (disableIntegration === true) {
    buttonToggleIntegrationReq = (
      <UncontrolledButtonDropdown>
        <DropdownToggle
          className="button"
          color="success"
          disabled={disableIntegration}
        >
          <span>{i18n.t("integration.request")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>
    )
  } else {
    buttonToggleIntegrationReq = (
      <Link to={`/integrations/request/${idIntegration}`}>
        <UncontrolledButtonDropdown>
          <DropdownToggle
            className="button"
            color="success"
            disabled={disableIntegration}
          >
            <span>{i18n.t("integration.request")}</span>
          </DropdownToggle>
        </UncontrolledButtonDropdown>
      </Link>
    )
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          {buttonToggleIntegrationReq}
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

ButtonRequestInt.propTypes = {
  disabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default ButtonRequestInt;
