import React, { useState } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import useIntegrations from "hooks/useIntegrations";
import { Loader } from "react-loaders";
import { Link } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ButtonActionsSignatures = (props) => {
  const { id: idSignature, transactionStatus } = props;
  const [loader, setLoader] = useState(false);
  const { reSendSignature } = useIntegrations();
  let buttonViewSignatures = null;

  /**
   * Handles the submit event for actions related to signatures.
   * @param {Event} eventActionSignature - The event object for the action signature submit.
   * @returns {void}
   */
  const handleOnSubmitActions = (eventActionSignature) => {
    eventActionSignature.preventDefault();
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("resend.SignatureChoise"),
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willResend) => {
      if (willResend) {
        setLoader(true);
        reSendSignature(idSignature)
          .then((response) => {
            const emails = response.data.data.emails;
            if (response.status === 200) {
              const showAlertSignatureCreated = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: `${i18n.t("resend.SignatureAlert")} ${emails}`,
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                });
              };
              showAlertSignatureCreated();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };

  /**
   * Renders a loader component if the loader state is true.
   * @param {boolean} loader - The loader state indicating whether to display the loader or not.
   * @returns {JSX.Element|null} Loader component JSX if loader state is true, otherwise null.
   */
  if (loader === true) {
    return (
      <div className="text-center mx-auto my-auto">
        <div className="d-flex justify-content-center align-items-center">
          <Loader
            color="#0072BC"
            type="ball-pulse-rise"
            className="button-signture"
          />
        </div>
      </div>
    )
  };

  /**
   * Renders a button to view signature actions if the transaction status is 0.
   * @param {number} transactionStatus - The transaction status.
   * @param {Function} handleOnSubmitActions - The function to handle the submit action.
   * @returns {JSX.Element|null} Button to view signature actions if transaction status is 0, otherwise null.
   */
  if (transactionStatus === 0) {
    buttonViewSignatures = (
      <DropdownItem className="text-primary" onClick={handleOnSubmitActions}>
        <Link>
          <i className="dropdown-icon lnr-pencil text-primary"> </i>
          <span>{i18n.t("info.SignatureActions")}</span>
        </Link>
      </DropdownItem>
    )
  };

  return (
    <div>
      <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
        <DropdownToggle
          caret
          className="btn-icon btn-icon-only btn btn-link"
          color="link"
        >
          <i className="lnr-menu-circle btn-icon-wrapper" />
        </DropdownToggle>

        <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
          <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>
          {buttonViewSignatures}
          <DropdownItem>
            <Link to={`/signatories/${idSignature}`}>
              <i className="dropdown-icon lnr-list text-primary"> </i>
              <span>{i18n.t("signatories.View")}</span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export default ButtonActionsSignatures;
