/**
 * Determines the action to be taken based on the user type and record creation status.
 * @param {string} USERTYPE - The type of user, where "3" represents a specific user type.
 * @param {boolean} isRecordCreate - Indicates whether a record is being created. If true, the action is set to 1; if undefined, the action is set to 2.
 * @returns {number} actions - The determined action:
 *   - 0 if the user type is "3".
 *   - 1 if isRecordCreate is true.
 *   - 2 if isRecordCreate is undefined.
 */
export function typeUserForms(USERTYPE, isRecordCreate) {
  let actions = 0;
  if (USERTYPE === "3") {
    actions = 0;
  } else if (isRecordCreate === true) {
    actions = 1;
  } else if (isRecordCreate === undefined) {
    actions = 2;
  }
  return actions;
}

/**
 * Finds the status based on a boolean value.
 * @param {boolean} isRecordCreateBoolean - A boolean indicating if a record is being created.
 * @returns {number} - The status code. If `isRecordCreateBoolean` is true, returns 2; otherwise, returns 0.
 */
export function findStatus(isRecordCreateBoolean) {
  let status;
  if (isRecordCreateBoolean) {
    status = 2;
  } else {
    status = 0;
  }
  return status;
}
