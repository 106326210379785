import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PropTypes from "prop-types";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, Row, Alert, Spinner } from "reactstrap";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import { UPLOAD_FILES, TYPE } from "constants/securityConst";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { typeUserForms } from "utils/formsQueryFunctions";
import { enumsStatusUser } from "utils/enums";
import DropZone from "components/molecules/DropZone";
import swal from "sweetalert";
import i18n from "locales/i18n";

const RecordUploadFiles = (props) => {
  const { fileByTrd, isRecordCreate, status, recordId, recordUuid } = props;
  const UPLOADFILES = window.localStorage.getItem(UPLOAD_FILES);
  const USERTYPE = window.localStorage.getItem(TYPE);
  const { id } = useParams();
  const formUuid = id;
  const {
    files,
    isLoading,
    handleOnSubmit,
    setUploadForm,
    setFieldsDinamic,
    setRecordFields,
    setRecordIdByFile,
    disabledUploadFile,
    setDisabledUploadFile,
  } = useRecordDetailContext();
  const { parentData } = useTrdContext();

  let isLoadingComponent = null;
  let formFileVisible = null;

  /**
   * Conditional rendering of a loading spinner component based on the isLoading flag.
   * @param {boolean} isLoading - Indicates whether the loading state is active.
   * @returns {JSX.Element|null} The loading spinner component (JSX) if isLoading is true; otherwise, null.
   */
  if (isLoading === true) {
    isLoadingComponent = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Displays a SweetAlert modal with specified title, text, icon, and button text.
   * @function
   * @name showSweetAlert
   * @returns {void}
   */
  const showSweetAlert = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("modal.warning.body3"),
      icon: "warning",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Handles the form submission event.
   * @param {Event} eventRecordUpload - The form submission event.
   * @param {Array} errors - An array of form validation errors.
   * @returns {void}
   */
  const handleSubmitModalRecord = (_, errors) => {
    if (fileByTrd) {
      if (errors.length === 0 && parentData.id && files.length > 0) {
        handleOnSubmit(
          recordUuid,
          fileByTrd,
          typeUserForms(USERTYPE, isRecordCreate)
        );
      } else if (!parentData.id) {
        showSweetAlert();
      }
    } else {
      if (errors.length === 0 && files.length > 0) {
        handleOnSubmit(
          recordUuid,
          fileByTrd,
          typeUserForms(USERTYPE, isRecordCreate)
        );
      }
    }
  };

  /**
   * Executes an effect to check conditions based on file list and upload form properties,
   * and updates the state to enable file upload if all required conditions are met.
   * @param {Array<File>} files - The list of files to be uploaded.
   * @param {object} uploadForm - An object containing properties related to the upload form.
   * @param {string} uploadForm.subject - The subject of the upload form.
   * @param {string} uploadForm.support_type - The support type of the upload form.
   * @param {Date} uploadForm.publication_at - The publication date of the upload form.
   * @param {function} setDisabledUploadFile - State setter function to enable or disable file upload.
   * @returns {void}
   */
  useEffect(() => {
    if (files.length > 0) {
      setDisabledUploadFile(false);
    } else {
      setDisabledUploadFile(true);
    }
  }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect to set a record ID based on the provided file record ID.
   * @param {string} recordId - The ID of the file record used to set the record ID.
   * @returns {void}
   */
  useEffect(() => {
    setUploadForm(formUuid);
    setRecordIdByFile(recordId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Cleans up the state variables when the component unmounts.
   * @returns {void}
   */
  useEffect(() => {
    return () => {
      setFieldsDinamic([]);
      setRecordFields({});
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (USERTYPE !== enumsStatusUser.ADMIN && UPLOADFILES.includes(id) === false) {
    formFileVisible = (
      <Alert color="info">
        <h4 className="alert-heading">{i18n.t("modal.DoneError.header")}</h4>
        <hr />
        <h6>{i18n.t("showNotification403")}</h6>
      </Alert>
    );
  } else {
    formFileVisible = (
      <div className="mt-3">
        <AvForm onSubmit={handleSubmitModalRecord}>
          <Row>
            <Col>
              <DropZone
                isProcessBatch={false}
                fileByTrd={fileByTrd}
                recordUuid={recordUuid}
                taskId={null}
                formUuid={id}
              />
            </Col>
          </Row>
          <Row className="d-block text-right">
            <Col>
              <Button
                type="submit"
                disabled={disabledUploadFile === true || isLoading === true}
                color="cyan"
              >
                {isLoadingComponent}
                {i18n.t("recordDetail.load")}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </div>
    );
  }

  if (status !== 2) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
          className="scrollingContainer"
        >
          {formFileVisible}
        </CSSTransitionGroup>
      </Fragment>
    );
  } else {
    return (
      <div className="mt-3 ">
        <Alert className="mbg-3" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
            <span className="font-weight-bold mbg-3">
              {i18n.t("modal.DoneError.header")} :{" "}
            </span>
            <span>{i18n.t("RecordDetail.label8")}</span>
          </span>
        </Alert>
      </div>
    );
  }
};

RecordUploadFiles.propTypes = {
  fileByTrd: PropTypes.bool.isRequired,
  isRecordCreate: PropTypes.bool.isRequired,
  status: PropTypes.number,
  recordId: PropTypes.string,
  recordUuid: PropTypes.string.isRequired,
};

export default RecordUploadFiles;
