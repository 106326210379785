import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { keyRoutesLevelsName } from "utils/trdLevelsName";
import i18n from "locales/i18n";

const TrdPathConfiguration = (props) => {
  const {
    currentPath,
    deletePathTrd,
    currentPathForEdiition,
    showCurrentPath
  } = props;

/**
 * Generates a route string based on the current path for edition.
 * @param {Object} currentPathForEdition - The current path object for edition.
 * @param {Object} keyRoutesLevelsName - The mapping of keys to route names.
 * @returns {string} The generated route string.
 */
let route = Object.entries(currentPathForEdiition)
  .map(([key, value]) => {
    let routeName = `${value.name} (${keyRoutesLevelsName[key]})`;
    if (value.code !== null) {
      return `${value.code}-${routeName}`;
    }
    return routeName;
  })
  .join(" / ");

  if (currentPath.length > 0 && showCurrentPath === true) {
    return (
      <div className="">
        <span className="font-weight-bold font-italic mr-2">
          {i18n.t("trd.formsCreate.assig3")}:{""}
        </span>
        {currentPath.map((label, index) => (
          <span key={index}>
            {index !== currentPath.length - 1 && (
              <>
                <span className="path-display font-weight-bold font-italic">
                  {label}
                </span>
                {index !== currentPath.length - 2 && " / "}
              </>
            )}
          </span>
        ))}
        <Button
          color="cyan"
          onClick={(event) => deletePathTrd(event)}
          className="ml-2"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    );
  } else if (Object.values(currentPathForEdiition).length > 0) {
    return (
      <div className="">
        <span className="font-weight-bold font-italic mr-2">
          {i18n.t("trd.formsCreate.assig3")}:{""}
        </span>

        <span className="path-display font-weight-bold font-italic">
          {route}
        </span>
        <Button
          color="cyan"
          onClick={(eventDeleteRoute) => deletePathTrd(eventDeleteRoute)}
          className="ml-2"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </div>
    );
  } else {
    return null;
  }
};

TrdPathConfiguration.propTypes = {
   currentPath: PropTypes.string.isRequired,
   deletePathTrd: PropTypes.func.isRequired,
   currentPathForEdiition: PropTypes.string,
   showCurrentPath: PropTypes.bool
};

export default TrdPathConfiguration;
