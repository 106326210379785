import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Label } from "reactstrap";
import { registerLocale } from "react-datepicker";
import { addYears } from "date-fns";
import { es, fr } from "date-fns/esm/locale";
import { isNullOrUndefined } from "utils/validations";
import { CustomInputComponent, renderCustomHeader } from "utils/datePickerFunctions";
import { millisecondsPerDay, rangeDays } from "utils/processManagementHelpers";
import cx from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import i18n from "locales/i18n";
registerLocale("es", es);
registerLocale("fr", fr);

const DateRangePicker = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    errorsDate,
    setErrorsDate,
    dataProcessNumber,
  } = props;
  let startDateErrorMesage = null;
  let endDateErrorMesage = null;
  let colorDatePicker = "grayLigth";
  let colorDatePickerEnd = "grayLigth";
  let validateDatePickerStyle = false;
  let validateDatePickerStyleEnd = false;

  /**
   * Handles the change event for the start date picker.
   * @param {Date} date - The new start date selected by the user.
   */
  const handleStartDateChange = (date) => {
    setStartDate(date);
    validateDates(date, endDate);
  };

  /**
   * Handles the change of the end date by setting the new end date and validating the date range.
   * @param {Date} date - The new end date to be set.
   */
  const handleEndDateChange = (date) => {
    setEndDate(date);
    validateDates(startDate, date);
  };

  /**
   * Validates the start and end dates based on various criteria and sets the corresponding error messages.
   * @param {Date|null} start - The start date to be validated. Can be null.
   * @param {Date|null} end - The end date to be validated. Can be null.
   * @returns {void} - This function does not return a value.
   */
  const validateDates = (start, end) => {
    const newErrors = {};

    if (dataProcessNumber === null) {
      if (start === null && end === null) {
        newErrors.startDateError = i18n.t("processManagement.StartDateRequired");
        newErrors.endDateError = i18n.t("processManagement.EndtDateRequired");
      } else if (start === null && end !== null) {
        newErrors.startDateError = i18n.t("processManagement.StartDateRequired");
      } else if (start !== null && end === null) {
        newErrors.endDateError = i18n.t("processManagement.EndtDateRequired");
      }
    }

    if (isNullOrUndefined(start) === false && isNullOrUndefined(end) === false && start > end) {
      newErrors.startDateError = i18n.t("processManagement.StartDateGrater");
      newErrors.endDateError = i18n.t("processManagement.EndDateSmaller");
    }

    if (
      isNullOrUndefined(start) === false &&
      isNullOrUndefined(end) === false &&
      millisecondsPerDay(end, start) > rangeDays
    ) {
      newErrors.startDateError = i18n.t("processManagement.DateMaxRange");
      newErrors.endDateError = i18n.t("processManagement.DateMaxRange");
    }

    setErrorsDate(newErrors);
  };

  /**
   * Handles the validation of start date errors and updates the related styles and messages.
   * @param {Object} errorsDate - An object containing error messages for date validation.
   * @param {string} [errorsDate.startDateError] - The error message for the start date if any validation error occurs.
   * @returns {void}
   */
  if (isNullOrUndefined(errorsDate.startDateError) === false) {
    validateDatePickerStyle = true;
    colorDatePicker = "validate";
    startDateErrorMesage = (
      <span className="text-danger text small">{errorsDate.startDateError}</span>
    );
  }

  /**
   * Updates state variables based on endDateError presence.
   * Sets validateDatePickerStyleEnd and colorDatePickerEnd accordingly,
   * and assigns endDateErrorMesage if endDateError exists.
   * @param {Object} errorsDate - Error object containing endDateError.
   * @param {boolean} errorsDate.endDateError - Error status for end date.
   */
  if (isNullOrUndefined(errorsDate.endDateError) === false) {
    validateDatePickerStyleEnd = true;
    colorDatePickerEnd = "validate";
    endDateErrorMesage = <span className="text-danger text small">{errorsDate.endDateError}</span>;
  }

  return (
    <div className="form-group">
      <Row>
        <Col md={6}>
          <div>
            <Label
              for="start_date"
              className={cx(" ", { "is-required ": dataProcessNumber === null })}
            >
              {i18n.t("processManagement.StartDate")}
            </Label>
            <DatePicker
              id="start_date"
              name="start_date"
              popperPlacement="auto"
              renderCustomHeader={renderCustomHeader}
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={addYears(new Date(), -3)}
              maxDate={new Date()}
              isClearable
              customInput={
                <CustomInputComponent
                  value={startDate}
                  onClick={() => {}}
                  ref={null}
                  validateStyle={validateDatePickerStyle}
                  color={colorDatePicker}
                />
              }
            />
            {startDateErrorMesage}
          </div>
        </Col>
        <Col md={6}>
          <div>
            <Label
              for="end_date"
              className={cx(" ", { "is-required ": dataProcessNumber === null })}
            >
              {i18n.t("processManagement.EndDate")}
            </Label>
            <DatePicker
              id="end_date"
              name="end_date"
              popperPlacement="auto"
              renderCustomHeader={renderCustomHeader}
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              isClearable
              customInput={
                <CustomInputComponent
                  value={endDate}
                  onClick={() => {}}
                  ref={null}
                  validateStyle={validateDatePickerStyleEnd}
                  color={colorDatePickerEnd}
                />
              }
            />
            {endDateErrorMesage}
          </div>
        </Col>
      </Row>
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.any,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.any,
  setEndDate: PropTypes.func.isRequired,
  errorsDate: PropTypes.object.isRequired,
  setErrorsDate: PropTypes.func.isRequired,
  dataProcessNumber: PropTypes.number,
};

export default DateRangePicker;
