import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import { Alert, Row, Button, CardTitle, Spinner } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { getDateFormat2 } from "utils/getDateFormat2";
import { regexCommentTask } from "utils/regexExpressions";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { HttpStatus } from "utils/enums";
import useWorkFlow from "hooks/useWorkFlow";
import Loader from "react-loaders";
import i18n from "locales/i18n";

const TasksComments = (props) => {
  const { isProcess, id, processId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentCreate, setCommentCreate] = useState({
    process_task_uuid: id,
    comment: "",
  });
  const [commentCreateFreeTask, setCommentCreateFreeTask] = useState({
    comment: "",
  });
  const {
    getCommentsByUuid,
    createComment,
    getCommentsProcessByUuid,
    createCommentProcess,
  } = useWorkFlow();
  let minimumValueComment = 3;
  let isLoadingCreateComment = false;
  let alertNoHaveMessage = null;
  let isLoadingMessageComponent = false;
  let valueComment = null;
  let disableComment = false;

  /**
   * Checks if the length of comments meets the minimum value.
   * @param {object} commentCreate - Object containing the comment to check.
   * @param {string} commentCreate.comment - The comment to check.
   * @param {number} minimumValueComment - The minimum allowed value for the comment length.
   * @returns {boolean} `true` if the length of both comments is less than or equal to the minimum value, otherwise `false`.
   */
  if (commentCreate.comment.length <= minimumValueComment &&
    commentCreateFreeTask.comment.length <= minimumValueComment) {
    disableComment = true;
  } else {
    disableComment = false;
  }

  /**
   * Sets the value of the comment based on the process condition.
   * @param {boolean} isProcess - Indicates whether a process is true or not.
   * @param {object} commentCreate - Object containing the comments.
   * @param {string} commentCreate.comment - Comment when the process is true.
   * @param {string} commentCreateFreeTask.comment - Comment when the process is false.
   * @returns {string} The comment based on the process condition.
   */
  if (isProcess === true) {
    valueComment = commentCreate.comment;
  } else {
    valueComment = commentCreateFreeTask.comment;
  }

  /**
   * Sets the loading message component based on the loading state.
   * @param {boolean} isLoading - Indicates whether the component is in a loading state or not.
   * @returns {JSX.Element|null} The loading message component if isLoading is true, otherwise null.
   */
  if (isLoading === true) {
    isLoadingMessageComponent = (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="#0072BC" type="ball-pulse-rise" />
      </div>
    )
  }

  /**
   * Sets the alert message component when there are no comments.
   * @param {number} commentsLength - The length of the comments array.
   * @returns {JSX.Element|null} The alert message component if there are no comments, otherwise null.
   */
  if (comments.length === 0) {
    alertNoHaveMessage = (
      <div>
        <CardTitle
          style={{
            textAlign: "left",
          }}
        >
          {i18n.t("taskCommentsTittle")}
        </CardTitle>
        <br />
        <Alert className="block" color="info">
          {i18n.t("taskCommentsAlert")}
        </Alert>
      </div>
    )
  }

  /**
   * Sets the loading spinner component based on the loading state.
   * @param {boolean} isLoading - Indicates whether the component is in a loading state or not.
   * @returns {JSX.Element|null} The loading spinner component if isLoading is true, otherwise null.
   */
  if (isLoading === true) {
    isLoadingCreateComment = (<Spinner size="sm" color="secondary" type="grow" />)
  }

  /**
   * Fetches comments by ID.
   * @callback
   * @returns {void}
   */
  const getCommentsById = useCallback(() => {
    setIsLoading(true);
    if (isProcess === false) {
      getCommentsByUuid(id)
        .then((response) => {
          if (isNullOrUndefined(response) === false) {
            setComments(response.data.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Fetches comments for a process by its ID.
   * @returns {void}
   */
  const getCommentsProcessById = useCallback(() => {
    setIsLoading(true);
    if (isProcess === true) {
      getCommentsProcessByUuid(processId)
        .then((response) => {
          if (isNullOrUndefined(response) === false) {
            setComments(response.data.data);
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for the comment task input.
   * @param {Event} eventCommentTask - The event object for the comment task input change.
   * @returns {void}
   */
  const handleOnChangeCommentTask = (eventCommentTask) => {
    if (isProcess === true) {
      setCommentCreate({
        ...commentCreate,
        [eventCommentTask.target.name]: eventCommentTask.target.value,
      });
    } else {
      setCommentCreateFreeTask({
        ...commentCreateFreeTask,
        comment: eventCommentTask.target.value,
      });
    }
  };

  /**
   * Handles the submission of the comment task.
   * @param {Event} eventCommentTask - The event object for the comment task submission.
   * @param {Array} errors - Array containing validation errors.
   * @returns {void}
   */
  const handleOnSubmitCommentTask = (eventCommentTask, errors) => {
    setIsLoading(true);
    if (errors.length === 0 && isProcess === false) {
      createComment(commentCreateFreeTask, id)
        .then((response) => {
          if (response.status === HttpStatus.CREATED) {
            getCommentsById(id);
            setCommentCreateFreeTask({ ...commentCreateFreeTask, comment: "" });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (errors.length === 0 && isProcess) {
      createCommentProcess(commentCreate, processId)
        .then((response) => {
          if (response.status === HttpStatus.CREATED) {
            getCommentsProcessById(processId);
            setCommentCreate({ ...commentCreate, comment: "" });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      eventCommentTask.preventDefault();
      setIsLoading(false);
    }
  };

  /**
   * Handles the blur event for the comment input field.
   * @param {Event} eventBlur - The event object for the blur event.
   * @returns {void}
   */
  const handleOnBlurComment = (eventBlur) => {
    if (isProcess === true) {
      setCommentCreate({
        ...commentCreate,
        [eventBlur.target.name]: eventBlur.target.value.trim(),
      });
    } else {
      setCommentCreateFreeTask({
        ...commentCreateFreeTask,
        comment: eventBlur.target.value.trim(),
      });
    }
  };

  /**
   * Handles the key press event for the comment task input.
   * @param {Event} eventCommentTask - The event object for the key press.
   * @returns {void}
   */
  const handleOnKeyPressCommentTask = (eventCommentTask) => {
    if (eventCommentTask.key === "Enter") {
      eventCommentTask.preventDefault();
    }
  };

  /**
   * React effect that calls the 'getCommentsProcessById' function when the component mounts or when 
   * 'getCommentsProcessById' changes.
   * @param {Function} getCommentsProcessById The function to get comments by process ID.
   * @returns {void}
   */
  useEffect(() => {
    getCommentsProcessById();
  }, [getCommentsProcessById]);

  /**
   * React effect that invokes the 'getCommentsById' function when the component mounts or when 'getCommentsById' changes.
   * @param {Function} getCommentsById The function to retrieve comments by ID.
   * @returns {void}
   */
  useEffect(() => {
    getCommentsById();
  }, [getCommentsById]);

  return (
    <Fragment>
      {alertNoHaveMessage}
      {isLoadingMessageComponent}
      {(() => {
        if (comments.length > 0) {
          return (
            <div>
              <CardTitle
                className="text-left"
              >
                {i18n.t("taskCommentsTittle")}
              </CardTitle>
              {comments.map((elementComment, index) => (
                <div key={index}>
                  <VerticalTimeline
                    className="vertical-time-icons"
                    layout={"1-column"}
                    animate={false}
                  >
                    <VerticalTimelineElement
                      className="vertical-timeline-item"
                      icon={
                        <div className="timeline-icon border-cyan bg-success">
                          <FontAwesomeIcon
                            icon={faComment}
                            className="text-white fa-flip-horizontal"
                          />
                        </div>
                      }
                    >
                      <h6 className="text-primary">{elementComment.comment}</h6>
                      <Row>
                        {getDateFormat2(new Date(elementComment.created_at))}
                        &nbsp; &nbsp;
                        <p className="text-capitalize">{elementComment.user_fullname}</p>
                      </Row>
                    </VerticalTimelineElement>
                  </VerticalTimeline>
                </div>
              ))}
            </div>
          );
        } else {
          return null;
        }
      })()}
      <br />
      <AvForm onSubmit={handleOnSubmitCommentTask}>
        <AvGroup>
          <CardTitle>{i18n.t("taskCommentsNew")}</CardTitle>
          <AvField
            onChange={handleOnChangeCommentTask}
            value={valueComment}
            onKeyPress={handleOnKeyPressCommentTask}
            onBlur={handleOnBlurComment}
            type="textarea"
            name="comment"
            rows="4"
            validate={{
              pattern: {
                value: regexCommentTask,
                errorMessage: `${i18n.t("trd.Tooltip5")}`,
              },
              minLength: {
                value: 4,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
              },
              maxLength: {
                value: 1000,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
              },
            }}
            autoComplete="off"
          ></AvField>
        </AvGroup>
        <Button
          type="submit"
          size="lg"
          className="col-mt-3"
          color="cyan"
          disabled={isLoading === true || disableComment === true}
        >
          {isLoadingCreateComment}
          {i18n.t("taskCommentsButton")}
        </Button>
      </AvForm>
    </Fragment >
  );
};

TasksComments.propTypes = {
  isProcess: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  processId: PropTypes.string.isRequired
};

export default TasksComments;
