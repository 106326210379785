import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";

const ButtonActionsSteps = (props) => {
  const {
    stepId,
    workFlowUuid,
    stepName,
    stepType,
    statusProcess,
    formId
  } = props;

  const objctActionsSteps = {
    name: stepName,
    type: stepType,
    statusProcess: statusProcess,
  };
  const expObj = JSON.stringify(objctActionsSteps);
  const hash_expObj = window.btoa(expObj);

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>
      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem>
          <Link
            to={`/workflow/steps/configuration/${stepId}/${workFlowUuid}/${hash_expObj}/${formId}`}
          >
            <i className="dropdown-icon lnr-cog"> </i>
            <span>{i18n.t("createWorkflow.config.cofiguration")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

ButtonActionsSteps.propTypes = {
  stepId: PropTypes.string.isRequired,
  workFlowUuid: PropTypes.string.isRequired,
  stepName: PropTypes.string.isRequired,
  stepType: PropTypes.string.isRequired,
  statusProcess: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
};

export default ButtonActionsSteps;
