import React, { Fragment, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { capitalizeText } from "utils/formatText";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const WorkFlowAddFieldsTable = (props) => {
  const {
    listOfTableFields,
    handleDeleteField,
    isLoadingWorkflowAddFields,
  } = props;
  const [pagination, setPagination] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  /**
   * Handles the change in page index.
   * @param {number} pageIndex - The new page index.
   * @returns {void}
   */
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex + 1);
  };

  /**
   * Retrieves the data for the current page from the `listOfTableFields` array based on the current page and page size.
   * @param {number} currentPage - The current page index.
   * @param {number} pageSize - The number of items to display per page.
   * @returns {Array} - The data for the current page.
   */
  const paginatedData = listOfTableFields.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  /**
   * Updates the page size in the pagination state when the page size is changed.
   * @param {number} perPage - The new page size value.
   */
  const handlePageSizeChange = (perPage) => {
    setPagination({ ...pagination, per_page: perPage });
  };

  /**
   * A hook that updates the total pages based on the list of table fields, page size, and pagination.
   * @param {number} pageSize - The number of items per page.
   * @param {Array} listOfTableFields - The list of table fields.
   * @param {boolean} pagination - A flag indicating whether pagination is enabled.
   */
  useEffect(() => {
    setTotalPages(Math.ceil(listOfTableFields.length / pageSize))
  }, [pageSize, listOfTableFields, pagination])


  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={paginatedData}
          columns={[
            {
              columns: [
                {
                  Header: [i18n.t("createWorkflow.configurations.label3")],
                  accessor: "label",
                  Cell: (row) => {
                    const controlField = row.original.control;
                    const typeField = row.original.type;
                    const labelField = row.original.label;
                    const labelFieldType = {
                      textString: 1,
                      controlType: 4
                    }
                    return (
                      <div>
                        {(() => {
                          if (controlField === labelFieldType.controlType && typeField === labelFieldType.textString) {
                            return <span>{labelField} - {i18n.t("createWorkflow.configurations.label4")}</span>
                          } else {
                            return <span className="text-capitalize">{capitalizeText(labelField)}</span>;
                          }
                        })()}
                      </div>
                    );
                  },
                },
                {
                  Header: [i18n.t("createRoles.MembersDelete")],
                  accessor: "uuid",
                  Cell: (row) => {
                    const fieldId = row.original.uuid;
                    return (
                      <div className="text-center">
                        <Button
                          className="btn-icon text-primary"
                          size="lg"
                          color="link"
                          onClick={() => handleDeleteField(fieldId)}
                        >
                          {" "}
                          <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                            <div>
                              <i className="lnr-icon lnr-trash text-primary " />
                            </div>
                          </div>
                        </Button>
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPages}
          showPageJump={false}
          defaultPageSize={pageSize}
          loading={isLoadingWorkflowAddFields}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="px-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkFlowAddFieldsTable.propTypes = {
  listOfTableFields: PropTypes.array.isRequired,
  handleDeleteField: PropTypes.func.isRequired,
  isLoadingWorkflowAddFields: PropTypes.bool.isRequired,
};

export default WorkFlowAddFieldsTable;