import React from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Alert,
  Button,
  CardBody,
  CardFooter,
  Card,
  Row,
  Col,
  Label,
} from "reactstrap";
import Select from "react-select";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSignatureContext } from "contextAPI/SignatureContext";
import cx from "classnames";
import SignatoriesListTable from "components/organism/SignatoriesListTable";
import countries from "./countries.json";
import i18n from "locales/i18n";

const SignatoriesForm = () => {
  const listCountriesDinamic = countries;
  const {
    handleOnKeyPress,
    handleOnBlurSig,
    handleOnChangeSig,
    req,
    signatoriesContext,
    setSignatoriesContext,
    selectedOption,
    handleOnChangeCountries,
    signatoriesList,
    deleteSignatories,
    handleOnSubmitSig,
    isError,
    alertMinSignatories,
    alertMaxSignatories,
    setShowSignatoriesForm,
  } = useSignatureContext();
  let minSignatories = null;
  let maxSignatories = null;
  let requiredCountrySelect = null;

  /**
   * Renders a message indicating that a field is required if the 'isErrorConsultField' flag is set to true.
   * @param {boolean} isError - A flag indicating whether the field is in an error state.
   * @param {string} i18n.t - A function used for internationalization to translate the "fieldRequired" message.
   * @returns {JSX.Element | null} - A JSX element representing the required field message or null if no message is required.
   */
  if (isError === true) {
    requiredCountrySelect = (
      <span className="text-danger text small">{i18n.t("fieldRequired")}</span>
    );
  } else {
    requiredCountrySelect = null;
  }

  /**
   * Generates an alert component based on specific conditions.
   * This function creates an alert component to display a message based on the given conditions.
   * @param {boolean} alertMinSignatories - A boolean flag to determine if the alert should indicate a minimum signatories condition.
   * @param {string} i18n.t - A function for internationalization and translation, used to retrieve the alert message.
   * @returns {JSX.Element} The generated alert component.
   */
  if (alertMinSignatories === true) {
    minSignatories = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          {i18n.t("signature.alertMin")}
        </span>
      </Alert>
    );
  } else {
    minSignatories = null;
  }

  /**
   * Conditionally renders an alert component for maximum signatories.
   * If the 'alertMaxSignatories' flag is true, this function generates an alert component
   * with a danger color to indicate the presence of the maximum signatories alert.
   * @param {boolean} alertMaxSignatories - A flag indicating whether the maximum signatories alert should be displayed.
   * @returns {JSX.Element|null} - Returns the JSX element for the maximum signatories alert if 'alertMaxSignatories' is true,
   * or null if the flag is false.
   */
  if (alertMaxSignatories === true) {
    maxSignatories = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
          {i18n.t("signature.alertMax")}
        </span>
      </Alert>
    );
  } else {
    maxSignatories = null;
  }

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Card>
        <CardBody>
          <Row>
            <Col lg="5" md="4" sm="12" className="button-container">
              <h5 className="text-info font-weight-bold">
                {i18n.t("signature.addSignatories")}
              </h5>
            </Col>
          </Row>
        </CardBody>

        <CardBody className="pt-0">
          {minSignatories}
          {maxSignatories}

          <AvForm onSubmit={handleOnSubmitSig}>
            <Row>
              <Col md="3">
                <AvGroup>
                  <Label for="proof_life" className="is-required">
                    {i18n.t("signature.pictures")}
                  </Label>
                  <AvField
                    onChange={handleOnChangeSig}
                    type="select"
                    name="proof_life"
                    id="proof_life"
                    disabled={alertMaxSignatories}
                    validate={{
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                    }}
                    value={signatoriesContext.proof_life}
                  >
                    <option value={""}>
                      {i18n.t("form.designerLabel10.1")}
                    </option>
                    <option value={0}>{i18n.t("signature.picturesNo")}</option>
                    <option value={1}>{i18n.t("signature.picturesYes")}</option>
                  </AvField>
                </AvGroup>
              </Col>

              <Col md="3">
                <AvGroup>
                  <Label for="signature_order" className="is-required">
                    {i18n.t("signature.order")}
                  </Label>
                  <AvField
                    onChange={(e) =>
                      setSignatoriesContext({
                        ...signatoriesContext,
                        signature_order: Number(e.target.value),
                      })
                    }
                    type="select"
                    name="signature_order"
                    id="signature_order"
                    disabled={alertMaxSignatories ? true : false}
                    validate={{
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                    }}
                    value={signatoriesContext.signature_order}
                  >
                    <option value={""}>
                      {i18n.t("form.designerLabel10.1")}
                    </option>
                    <option value={0}>{i18n.t("signature.orderOpc")}</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </AvField>
                </AvGroup>
              </Col>

              <Col md="3">
                <Label
                  for="country_name"
                  className={cx("is-required", isError === false, {
                    "label-color is-required": isError === true,
                  })}
                >
                  {i18n.t("signature.country")}
                </Label>

                <div>
                  <Select
                    id="country_name"
                    name="country_name"
                    classNamePrefix={cx("", {
                      select: isError === true,
                    })}
                    isDisabled={alertMaxSignatories}
                    placeholder={i18n.t("signature.placeholderCountry")}
                    closeMenuOnSelect={true}
                    value={selectedOption}
                    options={listCountriesDinamic.countries}
                    onChange={handleOnChangeCountries}
                  />
                  {requiredCountrySelect}
                </div>
              </Col>

              <Col md="1">
                <AvGroup>
                  <Label for="country_code" className="is-required">
                    {i18n.t("signature.indicative")}
                  </Label>

                  <AvField
                    className="country-code"
                    type="numeric"
                    name="country_code"
                    id="country_code"
                    disabled
                    validate={{
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                    }}
                    autoComplete="off"
                    value={signatoriesContext.country_code}
                  />
                </AvGroup>
              </Col>

              <Col md="2">
                <AvGroup>
                  <Label for="phone" className="is-required">
                    {i18n.t("signature.phoneNumber")}
                  </Label>
                  <AvField
                    onChange={handleOnChangeSig}
                    onKeyPress={handleOnKeyPress}
                    onBlur={handleOnBlurSig}
                    type="numeric"
                    name="phone"
                    id="phone"
                    disabled={alertMaxSignatories ? true : false}
                    validate={{
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                      pattern: {
                        value: /^([0-9]+)$/,
                        errorMessage: `${i18n.t("recordValidateNumbers")}`,
                      },
                      min: {
                        value: 999999999,
                        errorMessage: `${i18n.t("signature.numericMinPhone")}`,
                      },
                      max: {
                        value: 99999999999999999999,
                        errorMessage: `${i18n.t("signature.numericMaxPhone")}`,
                      },
                    }}
                    autoComplete="off"
                    value={signatoriesContext.phone}
                  />
                </AvGroup>
              </Col>
            </Row>

            <Row>
              <Col md={5}>
                <AvGroup>
                  <Label for="email" className="is-required">
                    {i18n.t("integration.requesEmail")}
                  </Label>
                  <AvField
                    onChange={handleOnChangeSig}
                    onBlur={handleOnBlurSig}
                    onKeyPress={handleOnKeyPress}
                    type="email"
                    name="email"
                    id="email"
                    disabled={alertMaxSignatories}
                    validate={{
                      pattern: {
                        value: /^[\w.-]+@[\w.-]+\.\w+/,
                        errorMessage: `${i18n.t(
                          "integration.requestConfigTipeEmail"
                        )}`,
                      },
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                      minLength: {
                        value: 15,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 15 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: 100,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 15 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={signatoriesContext.email}
                  />
                </AvGroup>
              </Col>

              <Col md={5}>
                <AvGroup>
                  <Label for="name" className="is-required">
                    {i18n.t("signature.nameUser")}
                  </Label>
                  <AvField
                    onChange={handleOnChangeSig}
                    onBlur={handleOnBlurSig}
                    onKeyPress={handleOnKeyPress}
                    type="text"
                    name="name"
                    id="name"
                    disabled={alertMaxSignatories}
                    validate={{
                      required: {
                        value: req,
                        errorMessage: `${i18n.t("fieldRequired")}`,
                      },
                      minLength: {
                        value: 4,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: 50,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9 À-ÿ]+$/,
                        errorMessage: `${i18n.t(
                          "integration.requestCharacters"
                        )}`,
                      },
                    }}
                    autoComplete="off"
                    value={signatoriesContext.name}
                  />
                </AvGroup>
              </Col>

              <Col md={2} className="signatories-button-add">
                <Button
                  type="submit"
                  size="lg"
                  disabled={alertMaxSignatories}
                  className="col-mt-3"
                  color="cyan"
                >
                  +{i18n.t("createRoles.MembersButton")}
                </Button>
              </Col>
            </Row>
          </AvForm>

          <SignatoriesListTable
            signatoriesList={signatoriesList}
            deleteSignatories={deleteSignatories}
          />
        </CardBody>

        <CardFooter className="d-block text-right">
          <Button
            type="submit"
            size="lg"
            className="col-mt-3"
            color="primary"
            onClick={() => {
              setShowSignatoriesForm(false);
            }}
          >
            {i18n.t("form.field4")}
          </Button>
        </CardFooter>
      </Card>
    </CSSTransitionGroup>
  );
};

export default SignatoriesForm;
