import React, { useState, useEffect } from "react";
import { Col, Row, Button, Spinner } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { regexFormLogin } from "utils/regexExpressions";
import { useHistory, Link } from "react-router-dom";
import useLogin from "hooks/useLogin";
import "react-toastify/dist/ReactToastify.css";
import usePasswordToggle from "customsHooks/usePasswordToggle";
import i18n from "locales/i18n";

const LoginForm = () => {
  const history = useHistory();
  const { login, isLogged, isLoading } = useLogin();
  const [userLogin, setUserLogin] = useState({
    userName: "",
    password: "",
  });

  const {
    inputType: inputTypeActualPassword,
    TogglePasswordIcon: ToggleActualPassword
  } = usePasswordToggle();

  let loadingLogin = null;

  /**
   * Render loading spinner if isLoading is true.
   * @type {JSX.Element|null} loadingLogin - Loading spinner component or null if isLoading is false.
   */
  if (isLoading === true) {
    loadingLogin = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Handles onChange event for login form fields.
   * @param {Event} eventLogin - The onChange event object.
   * @returns {void}
   */
  const handleOnChangeLogin = (eventLogin) => {
    const { name, value } = eventLogin.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  /**
   * Handles onBlur event for login form fields.
   * @param {Event} eventLogin - The onBlur event object.
   * @returns {void}
   */
  const handleOnBlur = (eventLogin) => {
    const { name, value } = eventLogin.target;
    setUserLogin({ ...userLogin, [name]: value.trim() });
  };

  /**
   * Handles form submission for the login form.
   * @param {Event} eventLogin - The form submission event object.
   * @param {Array} errors - Array of validation errors.
   * @returns {Promise<void>}
   */
  const handleOnSubmit = async (eventLogin, errors) => {
    eventLogin.preventDefault();
    if (errors.length === 0) {
      login(userLogin.userName, userLogin.password);
    }
  };

  /**
   * Redirects the user to the appropriate page based on login status.
   * @param {boolean} isLogged - Indicates whether the user is logged in.
   * @param {object} history - The history object provided by React Router.
   * @returns {void}
   */
  useEffect(() => {
    if (isLogged === true) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  }, [isLogged, history]);

  return (
    <AvForm onSubmit={handleOnSubmit}>
      <Row form>
        <Col md={12}>
          <AvGroup>
            <AvField
              id="userName"
              name="userName"
              type="text"
              onChange={handleOnChangeLogin}
              onBlur={handleOnBlur}
              placeholder={i18n.t("login.placeholder1")}
              validate={{
                pattern: {
                  value: regexFormLogin,
                  errorMessage: `${i18n.t("login.feedBack")}`,
                },
                required: {
                  value: true,
                  errorMessage: `${i18n.t("login.feedBack3.1")}`,
                },
                minLength: {
                  value: 5,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 5 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
                maxLength: {
                  value: 100,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} 5 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                },
              }}
              value={userLogin.userName}
            />
          </AvGroup>
        </Col>
        <Col md={12}>
          <AvGroup>
            <div className="position-relative">
              <AvField
                id="password"
                name="password"
                type={inputTypeActualPassword}
                onChange={handleOnChangeLogin}
                onBlur={handleOnBlur}
                placeholder={i18n.t("login.placeholder2")}
                validate={{
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("login.feedBack2")}`,
                  },
                }}
                value={userLogin.password}
              />
              {ToggleActualPassword}
            </div>
          </AvGroup>
        </Col>
      </Row>

      <div className="modal-footer d-flex justify-content-between">
        <div>
          <div>
            <Link to={"/user/recoverpassword"}>
              {i18n.t("forgotPassword.label4")}
            </Link>
          </div>
        </div>
        <div className="float-left">
          <div className="float-right">
            <Button
              type="submit"
              color="cyan"
              size="lg"
              disabled={isLoading === true}
            >
              {loadingLogin}
              {i18n.t("login.button")}
            </Button>
          </div>
        </div>
      </div>
    </AvForm>
  );
};

export default LoginForm;