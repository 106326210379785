import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { enumsTypeResponsible } from "utils/enums";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import i18n from "locales/i18n";
import ModalResponsibleForGroup from "components/molecules/ModalResponsibleForGroup";

const ButtonResponsibleUser = (props) => {
  const [flagResponsibles, setFlagResponsibles] = useState(false);
  const {
    usersDinamic,
    selectedOption,
    isLoading,
    showModal,
    setShowModal,
    isError,
    handleOnClick,
    handleOnClose,
    responsibleOption,
    handleOnChangeUsers,
    handleOnSubmit,
    setAddMember,
    addMember,
    setIsError,
    setAddMembersList,
    addMembersList,
    setShowModalGroup,
    showModalGroup,
  } = useWorkflowContext();

  let typeResponsibleOption = null;
  let optionsResponsibles = null;
  let noOptionsMessage = null;
  let labelTypeResponsible = null;
  let labelErrorSelectResponsible = null;
  let renderLabelError = null;
  let headerLabelTitle = null;
  let loadingSelectResponsible = null;
  let renderSelectedOptions = null;

  /**
   * Sets the loading indicator based on the isLoading flag.
   * @param {boolean} isLoading - Flag indicating if it is loading.
   * @returns {JSX.Element|null} - The loading spinner element or null.
   */
  if (isLoading === true) {
    loadingSelectResponsible = (
      <Spinner size="sm" color="secondary" type="grow" />
    );
  };

  /**
   * Sets the header label title based on the responsible option provided.
   * @param {Object} responsibleOption - The option for the responsible party.
   * @param {Object} responsibleOption.responsible - The responsible type.
   * @param {Object} enumsTypeResponsible - Enum for the type of responsible.
   * @param {number} enumsTypeResponsible.listOfResponsible - Enum value for a list of responsible.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {function} i18n.t - Function to get translated messages.
   * @returns {string} - The header label title.
   */
  if (
    responsibleOption.responsible ===
    enumsTypeResponsible.listOfResponsible
  ) {
    headerLabelTitle = (i18n.t(
      "createWorkflow.config.listResponsibleSelectsList"
    ))
  } else {
    headerLabelTitle = (i18n.t("createWorkflow.config.select.responsible"))
  }

  const sizelist = addMembersList.user_uuid_list.length;
  const limitNumberOfMembers = Number(process.env.REACT_APP_MAXIMUM_MEMBERS) - 1;

  /**
   * Sets the error label for selecting a responsible based on the responsible option provided.
   * @param {Object} responsibleOption - The option for the responsible party.
   * @param {Object} responsibleOption.responsible - The responsible type.
   * @param {Object} enumsTypeResponsible - Enum for the type of responsible.
   * @param {number} enumsTypeResponsible.singleResponsible - Enum value for a single responsible.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {function} i18n.t - Function to get translated messages.
   * @returns {string} - The error label for selecting a responsible.
   */
  if (
    responsibleOption.responsible ===
    enumsTypeResponsible.singleResponsible
  ) {
    labelErrorSelectResponsible = (i18n.t(
      "createWorkflow.config.listResponsibleSelects"
    ));
  } else {
    labelErrorSelectResponsible = (i18n.t(
      "createWorkflow.config.multipleResponsible"
    ));
  };

  if (isError === true) {
    renderLabelError = (
      <span className="text-danger text small">{labelErrorSelectResponsible}</span>
    )
  };

  /**
   * Sets the label for the type of responsible based on the responsible option provided.
   * @param {Object} responsibleOption - The option for the responsible party.
   * @param {Object} responsibleOption.responsible - The responsible type.
   * @param {Object} enumsTypeResponsible - Enum for the type of responsible.
   * @param {number} enumsTypeResponsible.listOfResponsible - Enum value for a list of responsible.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {function} i18n.t - Function to get translated messages.
   * @returns {string} - The label for the type of responsible.
   */
  if (
    responsibleOption.responsible ===
    enumsTypeResponsible.listOfResponsible
  ) {
    labelTypeResponsible = (i18n.t(
      "createWorkflow.config.listResponsibleSelectsList"
    ))
  } else {
    labelTypeResponsible = (i18n.t("createWorkflow.config.select.responsible"))
  }

  /**
   * Determines the type of responsible option based on the responsible option provided.
   * @param {Object} responsibleOption - The option for the responsible party.
   * @param {Object} responsibleOption.responsible - The responsible type.
   * @param {number} enumsTypeResponsible.listOfResponsible - Enum for the type of responsible list.
   * @returns {boolean} - Returns true if the responsible option is a list of responsible, otherwise false.
   */
  if (
    responsibleOption.responsible === enumsTypeResponsible.listOfResponsible
  ) {
    typeResponsibleOption = true;
  } else {
    typeResponsibleOption = false;
  }

  /**
   * Sets the options for responsibles and the message to display when there are no options.
   * @param {boolean} flagResponsibles - Flag indicating whether responsibles are available.
   * @param {Array} usersDinamic - List of dynamic users to choose from when responsibles are available.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {function} i18n.t - Function to get translated messages.
   * @returns {Object} - An object containing the options for responsibles and the no options message.
   */
  if (flagResponsibles === false) {
    optionsResponsibles = usersDinamic;
    noOptionsMessage = i18n.t("createRoles.MembersNousers");
  } else {
    optionsResponsibles = [];
    noOptionsMessage = i18n.t("createRoles.MembersNousersList");
  }

  /**
   * Handles the click event on the cancel button.
   * Closes the modal, resets the error state, and resets values related to adding members.
   */
  const handleOnClickCancelButton = () => {
    setShowModal(false);
    setIsError(false);
    setAddMember({
      ...addMember,
      user_uuid: "",
    });

    setAddMembersList({
      ...addMembersList,
      user_uuid_list: [],
    });
  };

  /**
   *useEffect hook that checks if the 'sizelist' prop exceeds a limit number and sets a flag accordingly
   *@param {number} sizelist - the size of the list to be checked against the limit number
   *@param {number} limitNumberOfMembers - the limit number to be checked against the size of the list
   *@param {function} setFlagResponsibles - the state setter function for the flag that indicates if the limit has been reached
   */
  useEffect(() => {
    function isLimit() {
      if (sizelist > limitNumberOfMembers) return setFlagResponsibles(true);
      return setFlagResponsibles(false);
    }
    isLimit();
  }, [sizelist, limitNumberOfMembers]);

  /**
   * Renders the selected options based on the responsible option provided.
   * @param {Object} responsibleOption - The option for the responsible party.
   * @param {Object} responsibleOption.responsible - The responsible type.
   * @param {Object} enumsTypeResponsible - Enum for the type of responsible.
   * @param {number} enumsTypeResponsible.groupResponsible - Enum value for a group responsible.
   * @param {boolean} showModal - Flag indicating if the modal is open.
   * @param {Function} handleOnClose - Function to handle the closing of the modal.
   * @param {string} headerLabelTitle - The title for the modal header.
   * @param {Function} handleOnSubmit - Function to handle the form submission.
   * @param {boolean} isError - Flag indicating if there is an error.
   * @param {string} labelTypeResponsible - The label for the type of responsible.
   * @param {Function} cx - Function for conditional class names.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {Function} i18n.t - Function to get translated messages.
   * @param {boolean} typeResponsibleOption - Flag indicating if the responsible option is multiple.
   * @param {Array} optionsResponsibles - The options for responsibles.
   * @param {Function} handleOnChangeUsers - Function to handle the change of users selection.
   * @param {Function} makeAnimated - Function to make the select component animated.
   * @param {string} noOptionsMessage - The message to display when there are no options.
   * @param {JSX.Element} renderLabelError - The element to render for label error.
   * @param {Function} handleOnClickCancelButton - Function to handle the cancel button click.
   * @param {boolean} isLoading - Flag indicating if it is loading.
   * @param {string} loadingSelectResponsible - The loading text for select responsible button.
   * @param {Function} setShowModal - Function to set the show modal state.
   * @param {string} workflowid - The workflow ID.
   * @param {string} stepUuid - The step UUID.
   * @param {Function} setShowModalGroup - Function to set the show modal group state.
   * @param {boolean} showModalGroup - Flag indicating if the modal group is open.
   * @returns {JSX.Element|null} - The JSX element to render or null.
   */
  if (
    responsibleOption.responsible !==
    enumsTypeResponsible.groupResponsible
  ) {
    renderSelectedOptions = (
      <Modal
        isOpen={showModal}
        toggle={handleOnClose}
        className="modal-large-size"
        size="xl"
      >
        <ModalHeader>
          <Label>
            {headerLabelTitle}
          </Label>
        </ModalHeader>
        <ModalBody>
          <AvForm onSubmit={handleOnSubmit}>
            <Row>
              <Col md="12">
                <Alert className="mbg-3" color="info" isOpen={true}>
                  <span className="pr-2">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  {labelTypeResponsible}
                </Alert>{" "}
                <AvGroup>
                  <Label
                    className={cx("", {
                      "labels-error": isError === true,
                    })}
                    for="modules"
                  >
                    {i18n.t("taskReassign.users")}
                  </Label>
                  <div>
                    <Select
                      id="users_uuid"
                      name="users_uuid"
                      classNamePrefix={cx("", {
                        select: isError === true,
                      })}
                      placeholder={i18n.t(
                        "notifications.usersPlaceholder"
                      )}
                      closeMenuOnSelect={true}
                      isMulti={typeResponsibleOption}
                      components={makeAnimated()}
                      value={selectedOption}
                      options={optionsResponsibles}
                      onChange={handleOnChangeUsers}
                      noOptionsMessage={() => noOptionsMessage}
                    />
                    {renderLabelError}
                  </div>
                </AvGroup>
              </Col>
            </Row>
            <CardFooter className="d-block text-right">
              <Button
                size="lg"
                onClick={handleOnClickCancelButton}
                className="col-mt-3 mr-3"
                color="gray"
              >
                {i18n.t("createusers.createButton2")}
              </Button>

              <Button
                type="submit"
                size="lg"
                disabled={isLoading}
                className="col-mt-3 mr-3"
                color="cyan"
              >
                {loadingSelectResponsible}
                {i18n.t("createRoles.MembersButton")}
              </Button>
            </CardFooter>
          </AvForm>
        </ModalBody>
      </Modal>
    );
  } else {
    renderSelectedOptions = (
      <ModalResponsibleForGroup
        showModal={showModal}
        setShowModal={setShowModal}
        workflowid={props.workflowid}
        stepUuid={props.stepUuid}
        setShowModalGroup={setShowModalGroup}
        showModalGroup={showModalGroup}
      />
    );
  };

  return (
    <Fragment>
      <div>
        <Button
          disabled={props.disabled}
          className="btn-block"
          color="cyan"
          size="lg"
          onClick={handleOnClick}
        >
          <span>{i18n.t("createWorkflow.config.AddUser")}</span>
        </Button>
      </div>
      {renderSelectedOptions}
    </Fragment>
  );
};

ButtonResponsibleUser.propTypes = {
  disabled: PropTypes.bool.isRequired,
  workflowid: PropTypes.string.isRequired,
  stepUuid: PropTypes.string.isRequired,
};

export default ButtonResponsibleUser;