import React, { Fragment, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Label, CardBody } from "reactstrap";
import { sortByPropertyName } from "utils/sortHelpers";
import { useFilterContext } from "contextAPI/FilterContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { initialPaginationForms } from "utils/initialPaginationsConfig";
import { showAlertServiceError } from "utils/alerts";
import { isNullOrUndefined } from "utils/validations";
import { actionFormService } from "utils/serviceHelper";
import { enumsFormStatus } from "utils/enums";
import makeAnimated from "react-select/lib/animated";
import Select from "react-select";
import useForms from "hooks/useForm";
import FilterFormsRecords from "components/organism/FilterFormsRecords";
import WorkflowsSelectByFiltersTasks from "../WorkflowsSelectByFiltersTasks";
import StepsSelectFilterTasks from "../StepsSelectFilterTasks";
import i18n from "locales/i18n";

const FormsSelectByFiltersTasks = (props) => {
  const { setSelectedOptions, selectedOptions } = props;
  const {
    formsDinamic,
    setFormsDinamic,
    setSelectFormIdContext,
    selectFormIdContext,
    structureQueryByFilterTask,
    setStructureQueryByFilterTask,
    setQuerySearchForm,
    isFilter,
    setSelectedOptionsWorkflow,
    setSelectedOptionsStep,
    selectedOptionsWorkflow,
    copiedSelectedOption,
    setIsAdvancedFilterTask,
  } = useWorkflowContext();
  const {
    setShowTable,
    setQueries,
    setQueriesTable,
    setDataRecordLookup,
  } = useFilterContext();
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  const [search] = useState("");
  const { getForms } = useForms();

  let formsSelectByFilterPlaceholder = null;
  let selectFormDisable = false;
  let filterFieldsByForms = null;
  let showSelectStepByFilter = null;

  /**
   * Conditional statement to display the StepsSelectFilterTasks component if the selected workflow value is not null or undefined.
   * @return {JSX.Element|null} Returns StepsSelectFilterTasks component if selected workflow value is not null or undefined; otherwise, returns null.
   */
  if (isNullOrUndefined(selectedOptionsWorkflow.value) === false) {
    showSelectStepByFilter = (
      <StepsSelectFilterTasks />
    )
  }

  /**
   * Updates the state variables based on the loading status of forms.
   * @param {boolean} isLoadingForms - A boolean indicating whether forms are currently being loaded.
   * @param {boolean} selectFormDisable - State variable indicating whether the form selection should be disabled.
   * @param {string} formsSelectByFilterPlaceholder - State variable representing the placeholder text for the forms selection dropdown.
   * @param {function} i18n.t - A function for internationalization and translation, typically provided by an i18n library.
   * @returns {void}
   */
  if (isLoadingForms === true) {
    selectFormDisable = true;
    formsSelectByFilterPlaceholder = i18n.t("loading");
  } else {
    formsSelectByFilterPlaceholder = i18n.t("recordData2");
  }

  /**
   * Conditionally renders a component to filter records based on form IDs.
   * @type {JSX.Element} filterFieldsByForms - JSX element representing the filtered records component.
   * @param {boolean} selectFormIdContext - Indicates whether form ID context is enabled or not.
   * @param {object} structureQueryByFilterTask - Object representing the structure query used for filtering tasks.
   * @returns {JSX.Element|null} The rendered component for filtering records by form IDs or null if selectFormIdContext is false.
   */
  if (selectFormIdContext === true || (Object.keys(copiedSelectedOption).length > 0
    && selectedOptions.value !== null)) {
    filterFieldsByForms = (
      <FilterFormsRecords
        structureQueryByFilterTask={structureQueryByFilterTask}
        requiredFields={false}
      />
    );
  }

  /**
   * Fetches active forms and updates the state with dynamic form options.
   * @name getActiveForms
   * @callback
   * @returns {void}
   */
  const getActiveForms = useCallback(() => {
    const { page, per_page } = initialPaginationForms;
    const { ADMIN_ACTIONS } = actionFormService;
    const { FORM_STATUS_NULL, FORM_STATUS_DELETE } = enumsFormStatus;
    setIsLoadingForms(true);
    getForms(page, per_page, search, FORM_STATUS_NULL, ADMIN_ACTIONS)
      .then((response) => {
        const listForms = response.data.items;
        if (isNullOrUndefined(listForms) === false) {
          const orderListForms = sortByPropertyName(listForms, "name");
          orderListForms.forEach((form) => {
            if (form.status !== FORM_STATUS_DELETE) {
              setFormsDinamic((prevState) => [
                ...prevState,
                {
                  value: form.uuid,
                  label: form.name,
                },
              ]);
            }
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingForms(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the change event for selecting a form option.
   * @param {object} selectedFormOption - The selected form option object containing label and value.
   * @param {string} selectedFormOption.label - The label of the selected form option.
   * @param {string} selectedFormOption.value - The value of the selected form option.
   * @param {boolean} isFilter - Flag indicating whether a filter is applied.
   * @param {function} setSelectedOptions - State setter function for updating the selected options.
   * @param {function} setQueriesRec - State setter function for updating queries related to record.
   * @param {function} setQueries - State setter function for updating queries.
   * @param {function} setQueriesTable - State setter function for updating queries related to the table.
   * @param {function} setDataRecordLookup - State setter function for updating data record lookup.
   * @param {function} setSelectFormIdContext - State setter function for updating the select form ID context.
   * @param {function} setQuerySearchForm - State setter function for updating the query search form.
   * @param {function} setStructureQueryByFilterTask - State setter function for updating the structure query by filter task.
   * @param {boolean} isNullOrUndefined - Utility function to check if a value is null or undefined.
   * @param {boolean} isFilter - Flag indicating whether a filter is applied.
   * @param {function} setShowTable - State setter function for updating the visibility of the table.
   * @returns {void}
   */
  const handleOnChangeForms = (selectedFormOption) => {
    if (selectedFormOption !== null) {
      if (selectedFormOption.value !== "") {
        setQueries([]);
        setQueriesTable([]);
        setDataRecordLookup([]);
        setSelectFormIdContext(true);
        setSelectedOptionsWorkflow("");
        setSelectedOptionsStep("");
        setQuerySearchForm(selectedFormOption.label);
        setStructureQueryByFilterTask({
          ...structureQueryByFilterTask,
          form_uuid: selectedFormOption.value,
          workflow_uuid: null,
          step_uuid: null
        });
      }
      setSelectedOptions(selectedFormOption);
    } else {
      setSelectedOptions({ value: null, label: i18n.t("recordData2") });
      setSelectFormIdContext(false);
    }
    setSelectedOptionsWorkflow("");
    if (isFilter === false) {
      setShowTable(false);
    }
  };

  /**
   * useEffect hook that resets the dynamic forms array and fetches active forms.
   * @param {function} getActiveForms - A function used to fetch active forms.
   * @param {function} setFormsDinamic - A state setter function to reset the dynamic forms array.
   * @returns {void}
   */
  useEffect(() => {
    setFormsDinamic([]);
    getActiveForms();
  }, [getActiveForms, setFormsDinamic]);

  /**
   * A custom effect that sets the value of `isAdvancedFilterTask` based on certain conditions.
   * @param {boolean} selectFormIdContext - Indicates whether `selectFormIdContext` is true.
   * @param {object} selectedOptions - An object containing selected options.
   * @param {object} copiedSelectedOption - An object representing copied selected options.
   */
  useEffect(() => {
    if (selectFormIdContext === true || (Object.keys(copiedSelectedOption).length > 0
      && selectedOptions.value !== null)) {
      setIsAdvancedFilterTask(true);
    } else {
      setIsAdvancedFilterTask(false);
    }
  }, [selectFormIdContext, selectedOptions, copiedSelectedOption]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CardBody>
        <div className="mb-4">
          <Label for="formsSelectByFilters">
            {i18n.t("taskFilter.label15")}
          </Label>
          <Select
            id="formsSelectByFilters"
            name="formsSelectByFilters"
            maxMenuHeight={120}
            menuPlacement="auto"
            closeMenuOnSelect={true}
            components={makeAnimated()}
            isMulti={false}
            isSearchable={true}
            isClearable={true}
            placeholder={formsSelectByFilterPlaceholder}
            options={formsDinamic}
            isDisabled={selectFormDisable}
            onChange={handleOnChangeForms}
            value={selectedOptions}
            noOptionsMessage={() => i18n.t("taskBatchProcess.text5")}
          />
        </div>
      </CardBody>
      {filterFieldsByForms}
      <WorkflowsSelectByFiltersTasks />
      {showSelectStepByFilter}
    </Fragment>
  );
};

FormsSelectByFiltersTasks.propTypes = {
  setSelectedOptions: PropTypes.func,
  selectedOptions: PropTypes.func,
};

export default FormsSelectByFiltersTasks;