import React, { Fragment, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleArrows, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import {
  enumsMaxMenuHeight,
  enumsMinMaxCharacters,
  enumsProcessTaskType,
  enumsTaskComeFrom,
} from "utils/enums";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { regexAcceptPrimaryCharacters } from "utils/regexExpressions";
import Context from "contextAPI/UserContext";
import useWorkFlow from "hooks/useWorkFlow";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import swal from "sweetalert";
import i18n from "locales/i18n";

const ReassignTask = (props) => {
  const {
    taskType,
    disabled,
    typeTask,
    idsTaskSelectedByBatch,
    isCompletedTask,
    isLoadingRequest,
    queryProcessData,
    handleRestoreFilter,
    taskSelectedByManagement,
    getQueryProcessByFilter,
  } = props;
  const { id, processId } = useParams();
  const taskId = id;
  const {
    getUsersBySearch,
    getListUsersProcess,
    usersDinamic,
    usersDinamicProcess,
    selectedOption,
    commentCreate,
    setCommentCreate,
    setTaskUuid,
    setIsLoadingReassingTask,
    showModalReassignTask,
    setShowModalReassignTask,
    reassignTaskUsers,
    setReassignTaskUsers,
    reassignProcessUsers,
    setReassignProcessUsers,
    reassignProcessBatch,
    setReassignProcessBatch,
    taskDetail,
    process,
    isError,
    setIsError,
    taskSelectedByBatch,
    setDropdownOpen,
    generalUsersDinamic,
    getGeneralUsers,
    reassignManagementProcessData,
    setReassignManagementProcessData,
  } = useWorkflowContext();
  const {
    reassignTask,
    reassignProcess,
    reassingProcessBatch,
    managementProcessTaskReasing,
    createCommentProcess,
  } = useWorkFlow();
  const taskIdForUsersRef = useRef(taskId);
  const { user } = useContext(Context);
  let alertReasingTask = i18n.t("taskReassign.Alert");
  let usersList = usersDinamic;
  let isMultiSelect = true;
  let requiredUserSelect = null;
  let commentField = null;
  let spinnerButtonFulfill = null;

  /**
   * Renders a spinner component based on the isLoadingRequest flag.
   * @param {boolean} isLoadingRequest - Flag indicating whether a request is in progress.
   * @returns {JSX.Element|null} - Returns a spinner component if isLoadingRequest is true, otherwise returns null.
   */
  if (isLoadingRequest === true) {
    spinnerButtonFulfill = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Updates task-related variables based on the type of task.
   * If the task type is either PROCESS_TASK or PROCESS_BATCH_TASK, it modifies
   * alertReasingTask, usersList, and isMultiSelect accordingly.
   * @param {string} typeTask - The type of the task. Should be one of enumsTaskComeFrom.PROCESS_TASK or enumsTaskComeFrom.PROCESS_BATCH_TASK.
   * @param {string} alertReasingTask - The alert message for reassigning the task.
   * @param {Array} usersList - The list of users for dynamic processes.
   * @param {boolean} isMultiSelect - A flag indicating whether multi-select is allowed for users.
   * @returns {void}
   */
  if (
    typeTask === enumsTaskComeFrom.PROCESS_TASK ||
    typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK
  ) {
    alertReasingTask = i18n.t("processReassign.Alert");
    usersList = usersDinamicProcess;
    isMultiSelect = false;
  }

  /**
   * Sets alert message, user list, and multi-select flag based on task type.
   * @param {string} typeTask - The type of task from which settings are determined.
   * @param {string} enumsTaskComeFrom - Enum containing task types constants.
   * @param {string} i18n - Localization object for translations.
   * @param {Array} generalUsersDinamic - Array of general users for dynamic user list.
   * @returns {void}
   */
  if (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT) {
    alertReasingTask = i18n.t("processReassign.Alert");
    usersList = generalUsersDinamic;
    isMultiSelect = false;
  }

  /**
   * Renders a message indicating that a field is required if the 'isError' flag is set to true.
   * @param {boolean} isError - A flag indicating whether the field is in an error state.
   * @param {string} i18n.t - A function used for internationalization to translate the "fieldRequired" message.
   * @returns {JSX.Element | null} - A JSX element representing the required field message or null if no message is required.
   */
  if (isError === true) {
    requiredUserSelect = <span className="text-danger text small">{i18n.t("fieldRequired")}</span>;
  }

  /**
   * Handles the opening of the modal for reassigning a task.
   * This function sets the state to show the reassign task modal, allowing users to initiate the task reassignment process.
   */
  const handleOnOpenReassingTask = () => {
    setShowModalReassignTask(true);
    if (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK) {
      setReassignProcessBatch({
        ...reassignProcessBatch,
        tasks: idsTaskSelectedByBatch,
      });
    }

    if (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT) {
      setReassignManagementProcessData({
        ...reassignManagementProcessData,
        tasks: idsTaskSelectedByBatch,
      });
    }
  };

  /**
   * Handles the closing of the task reassignment modal based on the type of task.
   * This function is responsible for closing the task reassignment modal and resetting relevant state
   * based on the type of the task (FREE_TASK or other).
   * @throws {Error} Will throw an error if an unsupported task type is encountered.
   * @returns {void}
   */
  const handleOnCloseReassingTask = () => {
    setDropdownOpen(false);
    if (typeTask === enumsTaskComeFrom.FREE_TASK) {
      setIsError(false);
      setShowModalReassignTask(false);
      setReassignTaskUsers({
        ...reassignTaskUsers,
        users_uuid: [],
      });
    } else {
      setIsError(false);
      setShowModalReassignTask(false);
      setCommentCreate({
        ...commentCreate,
        comment: "",
      });
      setReassignProcessUsers({
        ...reassignProcessUsers,
        reassign_user_uuid: "",
      });
      setReassignProcessBatch({
        ...reassignProcessBatch,
        reason: "",
        reassign_user_uuid: "",
      });
      setReassignManagementProcessData({
        ...reassignManagementProcessData,
        reason: "",
        property_user_uuid: "",
        reassign_user_uuid: "",
      });
    }
  };

  /**
   * Handles the onBlur event for a comment input, updating the commentCreate state.
   * This function is designed to be used as an event handler for onBlur events on a comment input field.
   * It extracts the trimmed value from the event and updates the commentCreate state, ensuring any leading
   * or trailing white spaces are removed.
   * @param {object} eventBlur - The onBlur event object triggered by the comment input.
   * @property {string} target.value - The value entered in the comment input.
   * @returns {void}
   */
  const handleOnBlur = (eventBlur) => {
    setCommentCreate({
      ...commentCreate,
      comment: eventBlur.target.value.trim(),
    });
  };

  /**
   * Handles the change event for a comment, updating relevant state values.
   * This function is designed to be used as an event handler for changes in a comment input.
   * It updates the task UUID and the comment creation object, which includes the process task UUID and the comment text.
   * The updated values are set in the corresponding state variables.
   * @param {Object} eventChangeComment - The event object triggered by the change in the comment input.
   * @param {string} eventChangeComment.target.value - The new value of the comment input.
   * @returns {void}
   */
  const handleOnChangeComment = (eventChangeComment) => {
    setTaskUuid(processId);
    setCommentCreate({
      ...commentCreate,
      process_task_uuid: taskId,
      comment: eventChangeComment.target.value,
    });
    if (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK) {
      setReassignProcessBatch({
        ...reassignProcessBatch,
        reason: eventChangeComment.target.value,
      });
    }
    if (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT) {
      const userName = user.user_name.split("@")[0];
      setReassignManagementProcessData({
        ...reassignManagementProcessData,
        reason: `${i18n.t("processManagement.ReasonPart1")} ${userName} ${i18n.t(
          "processManagement.ReasonPart2"
        )} ${eventChangeComment.target.value} `,
      });
    }
  };

  /**
   * Conditionally renders a comment field based on the type of task.
   * @param {string} typeTask - The type of the task.
   * @param {string} enumsTaskComeFrom.FREE_TASK - Enum representing a free task.
   * @returns {JSX.Element | null} - The comment field JSX element or null if the task type is 'FREE_TASK'.
   */
  if (typeTask !== enumsTaskComeFrom.FREE_TASK) {
    commentField = (
      <AvGroup>
        <Label for="comments" className="is-required">
          {i18n.t("processReassign.motive")}
        </Label>
        <AvField
          id="comment"
          name="comment"
          type="textarea"
          onBlur={handleOnBlur}
          onChange={handleOnChangeComment}
          value={commentCreate.comment}
          validate={{
            required: {
              value: true,
              errorMessage: `${i18n.t("fieldRequired")}`,
            },
            pattern: {
              value: regexAcceptPrimaryCharacters,
              errorMessage: `${i18n.t("trd.Tooltip12")}`,
            },
            minLength: {
              value: enumsMinMaxCharacters.GENERAL_MIN,
              errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t(
                "and"
              )} 1000 ${i18n.t("characters")}`,
            },
            maxLength: {
              value: enumsMinMaxCharacters.GENERAL_MAX,
              errorMessage: `${i18n.t("fieldValidateLengthBetween")} 4 ${i18n.t(
                "and"
              )} 1000 ${i18n.t("characters")}`,
            },
          }}
          autoComplete="off"
        ></AvField>
      </AvGroup>
    );
  }

  /**
   * Handles the change event for user reassignment, updating relevant state based on the selected option.
   * - For free tasks (typeTask === enumsTaskComeFrom.FREE_TASK), it should be an array of objects.
   * - For process tasks (typeTask === enumsTaskComeFrom.PROCESS_TASK) or batch tasks (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK),
   *   it should be a single object.
   * The object structure should have at least a 'value' property representing the user UUID.
   * @param {object|array} selectedOption - The selected option(s) representing user(s) for reassignment.
   * @throws {Error} Throws an error if selectedOption is null or undefined.
   * @returns {void}
   */
  const handleOnChangeUsersReassing = (selectedOption) => {
    if (isNullOrUndefined(selectedOption) === false) {
      if (typeTask === enumsTaskComeFrom.FREE_TASK) {
        setReassignTaskUsers({
          ...reassignTaskUsers,
          users_uuid: selectedOption.map((e) => e.value),
        });
        setIsError(false);
      }

      if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
        setReassignProcessUsers({
          ...reassignProcessUsers,
          reassign_user_uuid: selectedOption.value,
        });
        setIsError(false);
      }

      if (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK) {
        setReassignProcessBatch({
          ...reassignProcessBatch,
          reassign_user_uuid: selectedOption.value,
        });
        setIsError(false);
      }

      if (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT) {
        setReassignManagementProcessData({
          ...reassignManagementProcessData,
          property_user_uuid: queryProcessData.user_uuid,
          reassign_user_uuid: selectedOption.value,
        });
        setIsError(false);
      }
    } else {
      showAlertServiceError();
    }
  };

  /**
   * Reassigns a free task to the specified users.
   * @param {string} taskUuid - The UUID of the task to be reassigned.
   * @param {Array} users - An array of user objects to whom the task is reassigned.
   *   Each user object should have necessary information for the reassignment.
   *   Example: [{ id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }]
   * @returns {void}
   * @throws {Error} If the reassignment fails, an error is thrown.
   */
  function reassingFreeTask(taskUuid, users) {
    reassignTask(taskUuid, users)
      .then((response) => {
        if (response.status === 201) {
          swal({
            title: `${i18n.t("notifications.Success")} ${taskDetail.consecutive}`,
            text: i18n.t("taskReassign.Success"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then((willClose) => {
            if (willClose) {
              window.location = "/taskBox";
              setIsLoadingReassingTask(false);
            }
          });
        }
      })
      .catch((error) => {
        if (error) {
          setIsLoadingReassingTask(false);
          setReassignTaskUsers({
            ...reassignTaskUsers,
            users_uuid: [],
          });
        }
      });
  }

  /**
   * Reassigns a process task after creating a comment.
   * @param {object} commentCreate - The configuration object for creating a comment.
   * @param {string} commentCreate.comment - The content of the comment.
   * @param {string} commentCreate.otherProperty - Other properties relevant to comment creation.
   * @param {string} taskUuid - The UUID identifying the task to which the comment is attached.
   * @param {string} processTaskUuid - The UUID identifying the process task to be reassigned.
   * @param {Array} users - An array containing user information relevant to the reassignment.
   * @returns {void}
   */
  function reassingProcessTask(commentCreate, taskUuid, processTaskUuid, users) {
    createCommentProcess(commentCreate, taskUuid).then((response) => {
      if (response.status === 201) {
        reassignProcess(processTaskUuid, users)
          .then((response) => {
            if (response.status === 201) {
              swal({
                title: `${i18n.t("notifications.Success")} ${process.consecutive}`,
                text: i18n.t("taskReassign.Success"),
                icon: "success",
                button: i18n.t("modal.Done.footerButton"),
              }).then((willClose) => {
                if (willClose) {
                  window.location = "/taskBox";
                  setIsLoadingReassingTask(false);
                }
              });
            }
          })
          .catch((error) => {
            if (error) {
              setIsLoadingReassingTask(false);
              setCommentCreate({
                ...commentCreate,
                comment: "",
              });
              setReassignProcessUsers({
                ...reassignProcessUsers,
                reassign_user_uuid: "",
              });
            }
          });
      }
    });
  }

  /**
   * Reassigns processes in batch based on the provided batch data.
   * Each object should contain necessary information for reassigning a single process.
   * @param {object[]} reassignProcessBatchData - An array of objects representing the batch data for reassigning processes.
   * @param {string} reassignProcessBatchData[].consecutive - The consecutive identifier of the process to be reassigned.
   * @param {string} reassignProcessBatchData[].otherProperty - Additional properties related to the reassignment process.
   * @returns {void}
   */
  function reassingProcessByBatch(reassignProcessBatchData) {
    reassingProcessBatch(reassignProcessBatchData).then((response) => {
      if (response.status === 201) {
        const taskreassigned = taskSelectedByBatch.map((task) => task.consecutive);
        swal({
          title: `${i18n.t("notifications.ProcessBatchSuccess")} ${taskreassigned.join(", ")}`,
          text: i18n.t("notifications.ProcessBatchSuccessMessage"),
          icon: "success",
          button: i18n.t("modal.Done.footerButton"),
        }).then((willClose) => {
          if (willClose) {
            window.location = "/taskBox";
            setIsLoadingReassingTask(false);
          }
        });
      }
    });
  }

  /**
   * Reassigns management process tasks based on reassignProcessManagementData.
   * Displays success or warning messages using SweetAlert.
   * @param {object} reassignProcessManagementData - Data for reassigning management process tasks.
   * @param {string} reassignProcessManagementData.reason - Reason for reassigning tasks.
   * @param {string} reassignProcessManagementData.property_user_uuid - UUID of the property user.
   * @param {string} reassignProcessManagementData.reassign_user_uuid - UUID of the user to whom tasks are reassigned.
   */
  function reassingManagementProcess(reassignProcessManagementData) {
    managementProcessTaskReasing(reassignProcessManagementData)
      .then((response) => {
        if (response.status === 201 && response.data.data.not_reassign_tasks.length > 0) {
          const { not_reassign_tasks: notReassignTasks } = response.data.data || {};
          const notProcecedConsecutives = taskSelectedByManagement
            .filter((task) => notReassignTasks.includes(task.uuid))
            .map((task) => task.consecutive);
          const renderWarningList = notProcecedConsecutives.map(
            (consecutive, index) => `<li key=${index}><em>${consecutive}</em></li>`
          );
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("processManagement.NotProcessed"),
            icon: "warning",
            button: i18n.t("modal.Done.footerButton"),
            content: {
              element: "div",
              attributes: {
                innerHTML: `<ul>${renderWarningList.join("")}</ul>`,
                className: "custom-content-class",
              },
            },
          }).then((willClose) => {
            if (willClose) {
              handleRestoreFilter();
              getQueryProcessByFilter(queryProcessData);
              setIsLoadingReassingTask(false);
              setCommentCreate((prevState) => ({
                ...prevState,
                comment: "",
              }));
              setReassignManagementProcessData((prevState) => ({
                ...prevState,
                reason: "",
                property_user_uuid: "",
                reassign_user_uuid: "",
              }));
            }
          });
        } else {
          const taskreassigned = taskSelectedByManagement.map((task) => task.consecutive);
          swal({
            title: `${i18n.t("notifications.ProcessBatchSuccess")} ${taskreassigned.join(", ")}`,
            text: i18n.t("notifications.ProcessBatchSuccessMessage"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then((willClose) => {
            if (willClose) {
              handleRestoreFilter();
              getQueryProcessByFilter(queryProcessData);
              setIsLoadingReassingTask(false);
              setCommentCreate((prevState) => ({
                ...prevState,
                comment: "",
              }));
              setReassignManagementProcessData((prevState) => ({
                ...prevState,
                reason: "",
                property_user_uuid: "",
                reassign_user_uuid: "",
              }));
            }
          });
        }
      })
      .catch((error) => {
        if (error) {
          setIsLoadingReassingTask(false);
          setCommentCreate((prevState) => ({
            ...prevState,
            comment: "",
          }));
          setReassignManagementProcessData((prevState) => ({
            ...prevState,
            reason: "",
            property_user_uuid: "",
            reassign_user_uuid: "",
          }));
        }
      });
  }

  /**
   * Handles the form submission for task reassignment, validating inputs and triggering reassignment actions.
   * This function is responsible for handling the form submission when reassigning a task. It performs various checks,
   * including the type of task and the presence of required data, and takes appropriate actions accordingly. It prevents
   * form submission if there are validation errors or missing data.
   * @param {Event} eventReassing - The event object representing the form submission event.
   * @param {Array} errors - An array containing validation errors, if any.
   * @returns {void}
   */
  const handleOnSubmitReassing = (eventReassing, errors) => {
    if (
      (typeTask === enumsTaskComeFrom.FREE_TASK && reassignTaskUsers.users_uuid.length === 0) ||
      (typeTask === enumsTaskComeFrom.PROCESS_TASK && !reassignProcessUsers.reassign_user_uuid) ||
      (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK &&
        !reassignProcessBatch.reassign_user_uuid) ||
      (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT &&
        !reassignManagementProcessData.reassign_user_uuid)
    ) {
      setIsError(true);
      eventReassing.preventDefault();
      return;
    }
    if (errors.length !== 0) {
      eventReassing.preventDefault();
      return;
    }

    if (typeTask === enumsTaskComeFrom.FREE_TASK) {
      setShowModalReassignTask(false);
      setIsLoadingReassingTask(true);
      reassingFreeTask(taskId, reassignTaskUsers);
    }

    if (typeTask === enumsTaskComeFrom.PROCESS_TASK) {
      setShowModalReassignTask(false);
      setIsLoadingReassingTask(true);
      reassingProcessTask(
        commentCreate,
        processId,
        commentCreate.process_task_uuid,
        reassignProcessUsers
      );
    }

    if (typeTask === enumsTaskComeFrom.PROCESS_BATCH_TASK) {
      setShowModalReassignTask(false);
      setIsLoadingReassingTask(true);
      reassingProcessByBatch(reassignProcessBatch);
    }

    if (typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT) {
      setShowModalReassignTask(false);
      setIsLoadingReassingTask(true);
      reassingManagementProcess(reassignManagementProcessData);
    }
  };

  /**
   * Executes an effect when the 'id' or 'idsTaskSelectedByBatch' dependencies change.
   * If 'id' is null, updates the current task ID for users reference to the first ID
   * from the 'idsTaskSelectedByBatch' array.
   * @param {function} callback - The function to execute when dependencies change.
   * @param {Array} dependencies - An array of values (dependencies) to monitor for changes.
   * @returns {void}
   */
  useEffect(() => {
    if (taskId == null) {
      taskIdForUsersRef.current = idsTaskSelectedByBatch[0];
    }
  }, [taskId, idsTaskSelectedByBatch]);

  /**
   * Executes an effect based on the task type.
   * If the task type is 'FREE_TASK', it retrieves users by search using the provided task ID.
   * Otherwise, it retrieves a list of users involved in the process using the provided task ID.
   * @param {string} typeTask - The type of task to determine the action.
   * @param {string} enumsTaskComeFrom - The enumeration defining the source of tasks.
   * @param {string} taskIdForUsersRef - The ID of the task for which users are to be retrieved.
   * @param {function} getUsersBySearch - A function to retrieve users by search.
   * @param {function} getListUsersProcess - A function to retrieve a list of users involved in a process.
   * @returns {void}
   */

  useEffect(() => {
    if (typeTask === enumsTaskComeFrom.FREE_TASK) {
      getUsersBySearch(taskIdForUsersRef.current);
    } else if (
      typeTask === enumsTaskComeFrom.PROCESS_MANAGEMENT &&
      generalUsersDinamic.length === 0
    ) {
      getGeneralUsers();
    } else {
      getListUsersProcess(taskIdForUsersRef.current);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle
          className="ml-auto btn-icon btn-mb-responsive"
          color="warning"
          onClick={handleOnOpenReassingTask}
          disabled={
            disabled ||
            isCompletedTask ||
            isLoadingRequest ||
            taskType === enumsProcessTaskType.RETURN
          }
        >
          {spinnerButtonFulfill}
          <FontAwesomeIcon icon={faPeopleArrows} className="mr-2" />
          <span>{i18n.t("taskReassignButton")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>

      <Modal isOpen={showModalReassignTask} className="size-modal-responsive modal-custom-width">
        <ModalHeader>
          <h5 className="text-info font-weight-bold  font-weight-normal">
            {i18n.t("taskReassign")}
          </h5>
        </ModalHeader>

        <AvForm onSubmit={handleOnSubmitReassing}>
          <ModalBody>
            <Alert color="info">
              <span>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
                <span className="font-weight-bold mbg-3">
                  {i18n.t("modal.DoneError.header")} :{" "}
                </span>
                <span>{alertReasingTask}</span>
              </span>
            </Alert>

            <div className="mb-4">
              <Label
                for="users_uuid"
                className={cx("is-required", {
                  "is-required label-color": isError === true,
                })}
              >
                {i18n.t("taskReassign.users")}
              </Label>

              <Select
                id="users_uuid"
                name="users_uuid"
                classNamePrefix={cx("", {
                  select: isError === true,
                })}
                maxMenuHeight={enumsMaxMenuHeight.MAX_MENU_HEIGHT}
                menuPlacement="auto"
                closeMenuOnSelect={true}
                components={makeAnimated()}
                isMulti={isMultiSelect}
                isSearchable={true}
                isClearable={false}
                hideSelectedOptions={true}
                placeholder={i18n.t("notifications.usersPlaceholder")}
                options={usersList}
                onChange={handleOnChangeUsersReassing}
                value={selectedOption}
                noOptionsMessage={() => i18n.t("taskReassign.NoUsers")}
              />
              {requiredUserSelect}
            </div>
            {commentField}
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              className="col-mt-3 mr-2"
              color="gray"
              onClick={handleOnCloseReassingTask}
            >
              {i18n.t("createusers.createButton2")}
            </Button>

            <Button type="submit" size="lg" className="col-mt-3" color="cyan">
              {i18n.t("modal.Done.footerButton")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

ReassignTask.propTypes = {
  taskType: PropTypes.number,
  disabled: PropTypes.bool,
  typeTask: PropTypes.number.isRequired,
  isCompletedTask: PropTypes.bool,
  idsTaskSelectedByBatch: PropTypes.array,
  isLoadingRequest: PropTypes.bool,
  queryProcessData: PropTypes.object,
  handleRestoreFilter: PropTypes.func,
  taskSelectedByManagement: PropTypes.array,
  getQueryProcessByFilter: PropTypes.func,
};

export default ReassignTask;
