import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";

const buttonActionsList = (props) => {
  const { listId, parent_id } = props;
  let linkItemList = null;

  /**
   * Constructs a URL for the items list based on the provided listId and parent_id.
   * @param {string} listId - The ID of the list.
   * @param {string|null|undefined} parent_id - The ID of the parent item. Can be null or undefined.
   * @returns {string} The constructed URL for the items list.
   */
  if (isNullOrUndefined(parent_id) === false) {
    linkItemList = `/formsdesigner/itemslist/${listId}/${parent_id}`;
  } else {
    linkItemList = `/formsdesigner/itemslist/${listId}`;
  }

  return (
    <UncontrolledButtonDropdown className="mb-2 mr-2 d-block w-100 text-center">
      <DropdownToggle
        caret
        className="btn-icon btn-icon-only btn btn-link"
        color="link"
      >
        <i className="lnr-menu-circle btn-icon-wrapper" />
      </DropdownToggle>

      <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
        <DropdownItem header>{i18n.t("buttonActions.title")}</DropdownItem>

        <DropdownItem>
          <Link
            to={linkItemList}
          >
            <i className="dropdown-icon lnr-menu"> </i>
            <span>{i18n.t("buttonActions.option4")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={`/formsdesigner/editlist/${listId}`}>
            <i className="dropdown-icon lnr-pencil"> </i>
            <span>{i18n.t("buttonActions.option2")}</span>
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

buttonActionsList.propTypes = {
  listId: PropTypes.string.isRequired,
  parent_id: PropTypes.string.isRequired,
};

export default buttonActionsList;
