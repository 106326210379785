import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Collapse,
  Alert,
  ListGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { getDateFormat3 } from "utils/getDateFormat3";
import { enumsTaskComeFrom, enumsTaskType } from "utils/enums";
import { loaderComponent, loaderElement } from "utils/loaderElement";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { useTrdContext } from "contextAPI/TrdContext";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { showAlertServiceError } from "utils/alerts";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import ButtonAtachFilesTask from "components/atoms/ButtonAtachFilesTask";
import ProcessWorkflowRecordDeatilView from "components/molecules/ProcessWorkflowRecordDeatilView";
import ProcessFilesListTable from "components/organism/ProcessFilesListTable";
import TaskComments from "components/organism/TaskComments";
import ReturnToStep from "components/molecules/ReturnToStep";
import ReassignTask from "components/molecules/ReassignTask/ReassignTask";
import ProcessFillingForms from "components/organism/ProcessFillingForms";
import FulfillProcess from "components/molecules/FulfillProcess";
import cx from "classnames";
import useForm from "hooks/useForm";
import InterruptProcess from "components/molecules/InterruptProcess";
import i18n from "locales/i18n";
import { ListItem } from "@material-ui/core";

const ProcessDetails = (props) => {
  const { setIsLoadingRequest, isLoadingRequest } = props;
  const { id, processId } = useParams();
  const [isLoadingSteps, setIsLoadingSteps] = useState(false);
  const processTaskId = id;
  const {
    setBackgroundByPath,
    setPathConfiguration,
    getBackGroundByRoute,
    setPathConfig,
  } = useRecordDetailContext();
  const {
    getProcessById,
    getFilesProcessById,
    isLoading,
    setIsLoading,
    isLoadingResponsilblesList,
    isLoadingReassingTask,
    isLoadingFiles,
    showReassingn,
    typeResponsibleUser,
    showInterruptProcess,
    showDetail,
    setShowDetail,
    showFormFillingProcess,
    setShowFormsFillingProcess,
    showExternalFormFillingProcess,
    setShowExternalFormsFillingProcess,
    files,
    process,
    status,
    isProcess,
    fullfilCompleted,
    formIdInfo,
    isCompletedTask,
    formExternalList,
    dropdownOpen,
    setDropdownOpen,
    isLoadingReturningTask,
    isLoadingStepsToReturn,
  } = useWorkflowContext();
  const {
    getHasExpedientStructure,
    getTreeExpedientByDomainCallback,
    hasExpedientFixedPath,
    sethasExpedientFixedPath,
    expedientFixedUuid,
    isLoadingExpedietnPath,
    hasOrdination,
    expedientStatus,
    structureTrdExpedient,
    setExpedientSetPath,
    setExpedientParentByPath,
    foldersIdByPath,
    setFoldersIdByPath,
  } = useTrdContext();
  const { getFormsById } = useForm();
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [internalForm, setInternalForm] = useState([]);
  const [formExternalDinamic, setFormExternalDinamic] = useState([]);
  const [externalFormSelected, setExternalFormSelected] = useState({
    recordUuid: "",
    formUuid: "",
    formName: "",
  });
  const collapseRecordRef = useRef(null);
  let titleProcess = null;
  let statusTaskBadge = null;
  let ButtonFulfillProcess = null;
  let ButtonAtachFilesProcess = null;
  let itemsDropdownButtons = [];
  let taskProcessDescription = null;
  let alertForFilingForms = null;
  let filingAlertInternalForm = null;
  let filingAlertExternalForm = null;
  let processAlertFulfillFormRequired = null;
  let processAlertFulfillFormDone = null;
  let collapseRecordContent = null;
  let iconButtonReview = (
    <FontAwesomeIcon icon={faEyeSlash} className="mr-2 ml-auto " />
  );

  /**
   * Toggles the state of a dropdown.
   * @param {boolean} dropdownOpen - The current state of the dropdown, indicating whether it is open or closed.
   * @param {function} setDropdownOpen - A function to update the state of the dropdown.
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
   * Fetches form data by IDs and performs state updates based on the response.
   * @param {string} formIdInfo - The identifier or information required to retrieve form data.
   * @returns {void}
   */
  const getFormsByIds = useCallback(() => {
    setIsLoadingSteps(true);
    getFormsById(formIdInfo, 0)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const path = response.data.path;
          const parentId = response.data.serie_subserie_uuid;
          const backgroundId = getBackGroundByRoute(path);
          if (parentId === null) {
            setExpedientSetPath({});
            sethasExpedientFixedPath(false);
          }
          setPathConfiguration(parentId);
          setPathConfig(path);
          setFoldersIdByPath({
            ...foldersIdByPath,
            backgroundId: backgroundId,
          });
          setExpedientParentByPath(parentId);
          setBackgroundByPath(backgroundId);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setIsLoadingSteps(false));
  }, [formIdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Generates an icon button for review based on the state of isOpenCollapse.
   * If isOpenCollapse is true, it displays an eye icon indicating review.
   * @param {boolean} isOpenCollapse - A boolean value indicating whether the collapse is open or not.
   * @returns {JSX.Element|null} - The generated icon button for review, or null if isOpenCollapse is false.
   */
  if (isOpenCollapse === true) {
    iconButtonReview = (
      <FontAwesomeIcon icon={faEye} className="mr-2 ml-auto " />
    );
  }

  /**
   * If isLoading is false, displays a title containing the task detail with the consecutive value of the process.
   * @param {boolean} isLoading - The loading status of the process data.
   * @param {object} process - The process data to display the title for.
   * @returns {JSX.Element|null} The title JSX element if isLoading is false, otherwise null.
   */
  if (isLoading === false) {
    titleProcess = (
      <h4 className="font-weight-bold text-primary">{`${i18n.t(
        "taskDetail1"
      )} : ${process.consecutive}`}</h4>
    );
  }

  /**
   * Determines the appropriate status badge JSX element based on the process status.
   * @param {number} status - The status of the process.
   * @returns {JSX.Element|null} The status badge JSX element corresponding to the process status, or null if no matching status is found.
   */
  switch (process.status) {
    case 1:
      statusTaskBadge = <p className="badge badge-info">{status}</p>;
      break;
    case 2:
      statusTaskBadge = <p className="badge badge-success">{status}</p>;
      break;
    case 3:
      statusTaskBadge = <p className="badge badge-danger">{status}</p>;
      break;
    default:
      statusTaskBadge = null;
  }

  /**
   * Determines the appropriate action buttons JSX element based on the isLoading and showReassign status.
   * @param {boolean} isLoading - The loading status of the process data.
   * @param {boolean} showReassign - The status of the reassign button visibility.
   * @param {boolean} typeResponsibleUser - The type of the responsible user.
   * @param {boolean} isProcess - The status of the process.
   * @param {string} task - The task to reassign.
   * @returns {JSX.Element|null} The action buttons JSX element corresponding to the isLoading and showReassign status, or null if isLoading is true.
   */
  if (isLoading === false) {
    ButtonFulfillProcess = (
      <Col lg="3" md="6" sm="12" className="align-self-end">
        <FulfillProcess
          taskType={process.task_type}
          typeResponsibleUser={typeResponsibleUser}
          setIsLoadingRequest={setIsLoadingRequest}
          isLoadingRequest={isLoadingRequest}
        />
      </Col>
    );
    ButtonAtachFilesProcess = (
      <Col lg="3" md="6" sm="12" className="align-self-end">
        <ButtonAtachFilesTask
          getFilesProcessById={getFilesProcessById}
          processId={processId}
          formIdInfo={formIdInfo}
          hasExpedientFixedPath={hasExpedientFixedPath}
          expedientFixedUuid={expedientFixedUuid}
          hasOrdination={hasOrdination}
          expedientStatus={expedientStatus}
          structureTrdExpedient={structureTrdExpedient}
          disabled={false}
          isFreeTask={enumsTaskType.NO_PROCES_TASK}
          isProcess={isProcess}
          isCompletedTask={isCompletedTask}
          isLoadingSteps={isLoadingSteps}
        />
      </Col>
    );

    itemsDropdownButtons.push(
      <ListItem>
        <ReturnToStep />
      </ListItem>
    );

    /**
     * Renders a button for reassigning a task if the 'showReassign' flag is set to true.
     * @param {boolean} showReassign - Flag indicating whether to display the reassign button.
     * @param {boolean} isCompletedTask - Flag indicating whether the task is completed.
     * @param {string} enumsTaskComeFrom - Enum indicating the origin of the task.
     * @returns {JSX.Element|null} ButtonReassignProcess - JSX element representing the reassign button.
     */
    if (showReassingn === true) {
      itemsDropdownButtons.push(
        <ListItem>
          <ReassignTask
            taskType={process.task_type}
            typeTask={enumsTaskComeFrom.PROCESS_TASK}
            isCompletedTask={isCompletedTask}
            isLoadingRequest={isLoadingRequest}
          />
        </ListItem>
      );
    }

    /**
     * Generates a button component for interrupting a process if the 'showInterruptProcess' flag is true.
     * @param {boolean} showInterruptProcess - Flag indicating whether the interrupt process button should be displayed.
     * @param {object} process - Object containing information about the process.
     * @param {string} process.consecutive - The consecutive number of the process.
     * @param {string} id - The unique identifier associated with the process.
     * @param {string} processId - The unique identifier of the process.
     * @param {boolean} isCompletedTask - Flag indicating whether the task associated with the process is completed.
     * @returns {JSX.Element|null} - The button component for interrupting the process, or null if 'showInterruptProcess' is false.
     */
    if (showInterruptProcess === true) {
      itemsDropdownButtons.push(
        <ListItem>
          <InterruptProcess
            id={id}
            processId={processId}
            typeTask={enumsTaskComeFrom.PROCESS_TASK}
            processNumber={process.consecutive}
            isCompletedTask={isCompletedTask}
          />
        </ListItem>
      );
    }
  }

  /**
   * Handles the change event when selecting details forms.
   * @param {object} selectedOption - The selected option representing the details form.
   * @param {string} selectedOption.record_uuid - The UUID of the record associated with the selected details form.
   * @param {string} selectedOption.value - The UUID of the selected details form.
   * @param {string} selectedOption.label - The label/name of the selected details form.
   * @param {function} setExternalFormSelected - A state setter function to update the selected external form.
   * @param {function} setSelectedOption - A state setter function to update the selected option.
   * @param {function} setShowDetail - A state setter function to control the visibility of details.
   * @param {function} setIsOpenCollapse - A state setter function to control the collapsing behavior.
   * @returns {void}
   */
  const handleOnChangedetailsFormsSelect = (selectedOption) => {
    setExternalFormSelected({
      recordUuid: selectedOption.value,
      formUuid: selectedOption.form_uuid,
      formName: selectedOption.label,
    });
    setSelectedOption(selectedOption);
    setShowDetail(false);
    setIsOpenCollapse(false);
  };

  /**
   * Handles the click event for showing detail review and toggling collapse state.
   * @param {boolean} isOpenCollapse - The current state of the collapse component.
   * @param {function} setShowDetail - State setter function to control the visibility of the detail review.
   * @param {function} setIsOpenCollapse - State setter function to control the collapse state.
   * @returns {void}
   */
  const handleOnClickDetailReview = () => {
    setShowDetail(true);
    setIsOpenCollapse(!isOpenCollapse);
  };

  /**
   * A React effect that fetches forms data by form ID and updates various state values based on the response.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(formIdInfo) === false) {
      getFormsByIds();
    }
  }, [formIdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes an effect when the `process.record_uuid` changes, triggering a check for an expedient structure existence.
   * @param {object} process - The process object containing the `record_uuid` property to monitor.
   * @param {function} getHasExpedientStructure - Function to check if a record has an expedient structure.
   * @param {boolean} isNullOrUndefined - Utility function to determine if a value is null or undefined.
   * @param {string} process.record_uuid - The UUID of the record to check for expedient structure.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(process.record_uuid) === false) {
      const recordId = process.record_uuid;
      getHasExpedientStructure(recordId);
    }
  }, [process.record_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a callback to fetch tree expedient data when specific conditions are met.
   * @param {boolean} hasExpedientFixedPath - Indicates whether there is a fixed path for the expedient.
   * @param {string | null | undefined} expedientFixedUuid - The UUID of the fixed expedient (can be null or undefined).
   * @param {function} getTreeExpedientByDomainCallback - Callback function used to retrieve tree expedient data.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(expedientFixedUuid) === false &&
      hasExpedientFixedPath === true
    ) {
      getTreeExpedientByDomainCallback(null, expedientFixedUuid);
    }
  }, [hasExpedientFixedPath, expedientFixedUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that sets isLoading to true, retrieves process and process files data,
   * and updates isLoading to false if process has data, or if an error occurs.
   * @param {string} id - The ID of the process to retrieve.
   * @param {string} processId - The ID of the files process to retrieve.
   * @param {function} getProcessById - The function used to retrieve the process data.
   * @param {function} getFilesProcessById - The function used to retrieve the process files data.
   * @returns {void}
   */
  useEffect(() => {
    setIsLoading(true);
    Promise.all([getProcessById(id), getFilesProcessById(processId)])
      .then(() => {
        if (Object.keys(process).length > 0) {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [id, processId, getProcessById, getFilesProcessById]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that scrolls the collapsible record into view if it's open and available.
   * @param {boolean} isOpenCollapse - Indicates whether the collapsible record is open or not.
   * @param {React.MutableRefObject<HTMLElement>} collapseRecordRef - Ref object referencing the collapsible record element.
   * @returns {void}
   */
  useEffect(() => {
    if (isOpenCollapse === true && collapseRecordRef.current) {
      collapseRecordRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isOpenCollapse]);

  /**
   * useEffect hook that updates internal form state based on the provided process data.
   * @param {function} process - The process data containing information about the form.
   * @param {array} setInternalForm - State setter function for updating the internal form state.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(process) === false) {
      setInternalForm([
        {
          value: process.record_uuid,
          record_number: process.record_number,
          form_uuid: process.form_uuid,
          label: `${process.record_number} | ${process.form_name} ${i18n.t(
            "processFormInternal"
          )}`,
        },
      ]);
    }
  }, [process]);

  /**
   * useEffect hook that sets the selected option and updates external form selection based on the internal form state.
   * @param {Array} internalForm - The array containing internal form data.
   * @returns {void}
   */
  useEffect(() => {
    if (internalForm.length > 0) {
      const initialForm = internalForm[0];
      setSelectedOption(initialForm);
      setExternalFormSelected({
        recordUuid: initialForm.value,
        formUuid: initialForm.form_uuid,
        formName: initialForm.label,
      });
    }
  }, [internalForm]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that constructs a dynamic form array by combining internal and external form elements.
   * Updates the state with the constructed array whenever the 'formExternalList' prop changes.
   * @param {Array} formExternalList - The list of external forms to be integrated into the dynamic array.
   * @param {Array} internalForm - The list of internal forms to be included in the dynamic array.
   * @param {function} setFormExternalDinamic - State setter function for updating the dynamic form array.
   * @returns {void}
   */
  useEffect(() => {
    const elementsFormExternalList = [];
    if (isNullOrUndefined(formExternalList) === false) {
      formExternalList.forEach((form) => {
        elementsFormExternalList.push({
          form_uuid: form.form_uuid,
          value: form.record_uuid,
          label: `${form.record_number} | ${form.form_name} `,
        });
      });
      const concatenatedArray = [...internalForm, ...elementsFormExternalList];
      setFormExternalDinamic(concatenatedArray);
    }
  }, [formExternalList]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    isLoadingResponsilblesList === true ||
    isLoading === true ||
    isLoadingExpedietnPath === true
  ) {
    taskProcessDescription = loaderComponent(true);
  } else {
    if (isNullOrUndefined(process.step_config) === false) {
      /**
       * Generates a filing alert internal form component if the process step configuration includes a form name.
       * @param {object} process - The process object containing step configuration.
       * @param {object} process.step_config - Configuration object for the current step of the process.
       * @param {string} process.step_config.form_name - The name of the form associated with the current step of the process.
       * @param {Function} i18n.t - Function for translating internationalization keys.
       * @param {Function} setShowFormsFillingProcess - Function to set the state for showing forms filling process.
       * @returns {JSX.Element|null} The filing alert internal form component JSX element if the form name exists in the process step configuration, otherwise null.
       */
      if (isNullOrUndefined(process.step_config.form_name) === false) {
        filingAlertInternalForm = (
          <div className="d-flex align-items-center">
            <p className="align-items-center">
              {i18n.t("form.fieldlist.label")} {" :"}{" "}
              <span className="font-weight-bold">
                {process.step_config.form_name}
              </span>
            </p>
            <Button
              size="lg"
              className="d-flex col-mr-3 mb-2 ml-auto flex-row-reverse align-items-center"
              color="cyan"
              onClick={() => {
                setShowFormsFillingProcess(true);
              }}
            >
              {i18n.t("form.fieldlist.label1")}
            </Button>
          </div>
        );
      }

      /**
       * Generates a component to alert the user about the requirement to fulfill a form, if the specified conditions are met.
       * @param {object} process - The process object containing configuration and data.
       * @param {boolean} process.step_config.data_form_required - Indicates whether the data form is required for the current process step.
       * @param {boolean} fullfilCompleted - Flag indicating whether the form fulfillment is completed.
       * @returns {JSX.Element|null} A JSX element representing the alert message if conditions are met, otherwise null.
       */
      if (
        process.step_config.data_form_required === true &&
        fullfilCompleted === false
      ) {
        processAlertFulfillFormRequired = (
          <span className="font-italic p-1">
            {i18n.t("processAlert.fulfillFormRequired")}
          </span>
        );
      }

      /**
       * Generates an alert message component when the fulfillment of a form is completed.
       * @param {boolean} fullfilCompleted - A boolean flag indicating whether the form fulfillment is completed.
       * If true, the alert message component will be generated; otherwise, it won't be.
       * @returns {JSX.Element|null} - The generated alert message component if fulfillment is completed; otherwise, null.
       */
      if (fullfilCompleted === true) {
        processAlertFulfillFormDone = (
          <span className="font-italic font-weight-bold p-1">
            {i18n.t("processAlert.fulfillFormDone")}
          </span>
        );
      }

      /**
       * Renders a filing alert for an external form if it is provided in the process step configuration.
       * This function generates JSX markup for displaying the filing alert along with form-related information.
       * @param {Object} process - The process object containing step configuration and form-related data.
       * @param {boolean} fullfilCompleted - Indicates whether the form fulfillment process is completed.
       * @param {Function} setShowExternalFormsFillingProcess - State setter function for controlling the visibility of external form filling process.
       * @param {Function} i18n.t - Function for internationalization and translation.
       * @param {React.Component} Row - Component for rendering a row in a layout.
       * @param {React.Component} Button - Component for rendering a button.
       * @param {Function} cx - Utility function for generating classNames with conditional expressions.
       * @param {React.Component} processAlertFulfillFormRequired - Component for rendering the required form alert message.
       * @param {React.Component} processAlertFulfillFormDone - Component for rendering the completed form alert message.
       * @returns {JSX.Element|null} JSX markup for the filing alert or null if no external form is provided.
       */
      if (isNullOrUndefined(process.step_config.form_name_external) === false) {
        filingAlertExternalForm = (
          <div className="d-flex align-items-center">
            <p>
              {i18n.t("processAlert.fulfillForm")}{" "}
              <span
                className={cx("align-items-center font-weight-bold", {
                  "is-required":
                    process.step_config.data_form_required === true,
                })}
              >
                {process.step_config.form_name_external}
              </span>
              <Row>{processAlertFulfillFormRequired}</Row>
              <Row>{processAlertFulfillFormDone}</Row>
            </p>

            <Button
              size="lg"
              className="d-flex col-mr-3 mb-2 ml-auto flex-row-reverse align-items-center"
              color="cyan"
              disabled={fullfilCompleted}
              onClick={() => setShowExternalFormsFillingProcess(true)}
            >
              {i18n.t("form.fieldlist.label1")}
            </Button>
          </div>
        );
      }

      /**
       * Generates an alert component for filing forms based on the presence of form names.
       * @param {Object} process - The process object containing step configuration.
       * @param {string} process.step_config.form_name - The name of the internal form.
       * @param {string} process.step_config.form_name_external - The name of the external form.
       * @param {boolean} isNullOrUndefined - Function to check if a value is null or undefined.
       * @param {React.Component} Alert - The alert component to be displayed.
       * @param {React.Component} FontAwesomeIcon - The FontAwesomeIcon component for icons.
       * @param {React.Component} filingAlertInternalForm - The alert component for the internal form.
       * @param {React.Component} filingAlertExternalForm - The alert component for the external form.
       * @param {Function} i18n.t - The translation function for internationalization.
       * @returns {React.Component|null} Returns the filing forms alert component if form names are present, otherwise null.
       */
      if (
        isNullOrUndefined(process.step_config.form_name) === false ||
        isNullOrUndefined(process.step_config.form_name_external) === false
      ) {
        alertForFilingForms = (
          <Alert className="mbg-3" color="info" isOpen={true}>
            <div className="mb-2">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
              <span className="font-weight-bold mbg-3">
                {i18n.t("modal.DoneError.header")} :{" "}
              </span>
            </div>

            <div className="mb-4">{filingAlertInternalForm}</div>
            {filingAlertExternalForm}
          </Alert>
        );
      }
    }

    /**
     * Renders a collapsible section containing detailed information about a process record.
     * @param {boolean} showDetail - A flag indicating whether to display the detailed information.
     * @param {object} process - The process object containing information about the record.
     * @param {string} process.record_number - The UUID of the record.
     * @param {string} process.form_uuid - The UUID of the associated form.
     * @param {React.RefObject} collapseRecordRef - A ref object used to access the collapsible section.
     * @returns {JSX.Element|null} Returns the JSX element for the collapsible section if `showDetail` is true, otherwise returns null.
     */
    if (showDetail === true) {
      collapseRecordContent = (
        <div ref={collapseRecordRef}>
          <ProcessWorkflowRecordDeatilView
            recordUuid={externalFormSelected.recordUuid}
            formUuid={externalFormSelected.formUuid}
            formName={externalFormSelected.formName}
          />
        </div>
      );
    }

    /**
     * Represents the task process description component, rendering various details related to a task process.
     * @returns {JSX.Element} JSX element representing the task process description.
     */
    taskProcessDescription = (
      <div>
        <Row>
          <Col lg="5" md="4" sm="12" className="button-container">
            <span className="font-weight-bold text-primary">
              {titleProcess}
            </span>
          </Col>
          <Col lg="7" md="8" sm="12" className="button-container mt-2 mt-md-0">
            <Row className="row justify-content-end">
              {ButtonFulfillProcess}
              {ButtonAtachFilesProcess}
              <Col lg="auto" md="6" sm="12" className="align-self-end">
                <UncontrolledButtonDropdown className="btn-block">
                  <DropdownToggle
                    className="ml-auto btn-icon btn-block btn-mb-responsive"
                    color="cyan"
                    onClick={toggleDropdown}
                  >
                    <span>{i18n.t("processActions")}</span>
                    <FontAwesomeIcon icon={faCaretDown} className="ml-4" />
                  </DropdownToggle>
                  <Collapse isOpen={dropdownOpen} className="custom-collapse">
                    <ListGroup className="custom-listgroup">
                      {itemsDropdownButtons}
                    </ListGroup>
                  </Collapse>
                </UncontrolledButtonDropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="7" className="pl-3">
            <h6 className="pt-2 mb-0">
              <span className="font-weight-bold text-cyan">
                {i18n.t("filteruser.button")} : &nbsp;
              </span>
              {statusTaskBadge}
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("createWorkflow.config.label1")} : &nbsp;
              </span>
              <span>{process.step_index}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("taskBoxTable5")} : &nbsp;
              </span>
              <span>{process.subject}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("processName")} : &nbsp;
              </span>
              <span>{process.process_name}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("taskBoxTable1")} : &nbsp;
              </span>
              <span className="text-capitalize">{process.sender_fullname}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("taskDetailSend")} : &nbsp;
              </span>
              <span>{getDateFormat3(new Date(process.created_at))}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("createWorkflow.config.responsible")} : &nbsp;
              </span>
              <span className="text-capitalize">{process.user_fullname}</span>
            </h6>

            <h6 className="mb-3">
              <span className="font-weight-bold text-cyan">
                {i18n.t("processReceived")} : &nbsp;
              </span>
              <span>{getDateFormat3(new Date(process.reception_date))}</span>
            </h6>

            <div>{alertForFilingForms}</div>

            <h6 className="font-weight-bold text-cyan mt-2">
              {i18n.t("RecordDetail.Heading")} :
            </h6>
            <Row className="mt-2">
              <Col md="10">
                <Select
                  id="selectForm"
                  name="selectForm"
                  maxMenuHeight={120}
                  menuPlacement="auto"
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  hideSelectedOptions={true}
                  components={makeAnimated()}
                  placeholder={i18n.t("form.field75")}
                  options={formExternalDinamic}
                  noOptionsMessage={() => i18n.t("createRoles.configNoForms")}
                  onChange={handleOnChangedetailsFormsSelect}
                  value={selectedOption}
                />
              </Col>
              <Col md="2">
                <Button
                  color="info"
                  className="mr-auto btn-icon btn-block"
                  onClick={handleOnClickDetailReview}
                  aria-controls="collapseOne"
                  size="lg"
                >
                  {iconButtonReview}
                  {i18n.t("trdExpedientList8")}
                </Button>
              </Col>
            </Row>
            <Collapse
              md="12"
              className="pt-2"
              isOpen={isOpenCollapse}
              onEntered={() =>
                collapseRecordRef.current &&
                collapseRecordRef.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
              data-parent="#accordion"
              id="collapseOne"
              aria-labelledby="headingOne"
            >
              {collapseRecordContent}
            </Collapse>
          </Col>

          <Col md="5" className="task-detail-card-second-responsive">
            <div className="padding-container-next">
              <ProcessFilesListTable
                files={files}
                isLoadingFiles={isLoadingFiles}
              />
            </div>
            <div className="padding-container-next">
              <TaskComments
                isProcess={isProcess}
                id={id}
                processId={processId}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  if (
    showFormFillingProcess === false &&
    showExternalFormFillingProcess === false
  ) {
    return (
      <Fragment>
        {loaderElement(
          isLoadingReassingTask ||
            isLoadingReturningTask ||
            isLoadingStepsToReturn
        )}
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Card className="main-card mb-3">
            <CardBody className="mb-4">{taskProcessDescription}</CardBody>
          </Card>
        </CSSTransitionGroup>
      </Fragment>
    );
  } else if (showExternalFormFillingProcess === true) {
    return (
      <ProcessFillingForms
        formName={process.step_config.form_name_external}
        recordNumber={process.record_number}
        processTaskId={processTaskId}
        recordUuid={process.record_number}
        formUuid={process.form_uuid}
        setShowFormsFillingProcess={setShowFormsFillingProcess}
        showExternalFormFillingProcess={showExternalFormFillingProcess}
        setShowExternalFormsFillingProcess={setShowExternalFormsFillingProcess}
        isFilingInBatch={false}
      />
    );
  } else {
    return (
      <ProcessFillingForms
        formName={process.step_config.form_name}
        recordNumber={process.record_number}
        processTaskId={processTaskId}
        recordUuid={process.record_number}
        formUuid={process.form_uuid}
        setShowFormsFillingProcess={setShowFormsFillingProcess}
        showExternalFormFillingProcess={showExternalFormFillingProcess}
        setShowExternalFormsFillingProcess={setShowExternalFormsFillingProcess}
        isFilingInBatch={false}
      />
    );
  }
};

ProcessDetails.propTypes = {
  setIsLoadingRequest: PropTypes.func.isRequired,
  isLoadingRequest: PropTypes.bool.isRequired,
};

export default ProcessDetails;
