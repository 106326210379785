import React from "react";
import WorkFlowList from "./WorkFlowList";
import { RecordDetailContextProvider } from "contextAPI/RecordDetailContext";

export default () => {
  return (
    <RecordDetailContextProvider>
      <WorkFlowList />
    </RecordDetailContextProvider>
  );
};




