import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";

const ButtonBackListTasks = () => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <a href="/taskBox">
            <Button className="btn-icon" color="cyan">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("taskBox")}
            </Button>
          </a>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonBackListTasks;