import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserWorkGroupMembersList from "components/organism/UserWorkGroupMembersList";
import i18n from "locales/i18n";

const UserWorkGroupMember = () => {
    return (
      <Fragment>
        <AdminTemplate
          heading={i18n.t("create.work.group.members.title")}
          subheading={i18n.t("create.work.group.members.heading")}
          icon="users"
        >
          <UserWorkGroupMembersList />
        </AdminTemplate>
      </Fragment>
    );
  };
  
  export default UserWorkGroupMember;