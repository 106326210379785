import React, { useContext, Fragment } from "react";
import useLogin from "hooks/useLogin";
import Context from "contextAPI/UserContext";
import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { faAngleDown, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import i18n from "locales/i18n";

const UserBox = () => {
  const { logout } = useLogin();
  const { user } = useContext(Context);

  const capitalize = ([first, ...rest], lowerRest = false) => {
    const capitalizeString =
      first.toUpperCase() +
      (lowerRest ? rest.join("").toLowerCase() : rest.join(""));

    return capitalizeString;
  };

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            {user && (
              <Fragment>
                {user.first_name && user.last_name && user.domain && (
                  <div className="widget-content-left  ml-3 mr-3 header-user-info">
                    <div className="widget-heading">{`${capitalize(
                      user.first_name
                    )} ${capitalize(user.last_name)}`}</div>
                    <div className="widget-subheading">
                      <b>{`${capitalize(user.domain)}`}</b>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="rm-pointers dropdown-menu-lg mt-1"
                >
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div className="menu-header-content text-left widget-content-wrapper">
                        <div className="widget-content-left">
                          {user && (
                            <Fragment>
                              {user.first_name &&
                                user.last_name &&
                                user.domain && (
                                  <Fragment>
                                    <div
                                      className="widget-heading"
                                      style={{ color: "primary" }}
                                    >
                                      {`${capitalize(
                                        user.first_name
                                      )} ${capitalize(user.last_name)}`}
                                    </div>
                                    <div className="widget-subheading opacity-8">
                                      {capitalize(user.domain)}
                                    </div>
                                  </Fragment>
                                )}
                            </Fragment>
                          )}
                        </div>
                        <div className="widget-content-right mr-2">
                          <Button
                            className="btn-pill btn-shadow btn-shine"
                            color="focus"
                            onClick={() => logout()}
                          >
                            {i18n.t("logout")}
                          </Button>
                        </div>

                      </div>

                    </div>
                    <div className="d-flex  mt-3">
                      <span className="pr-2 ml-3">
                        <FontAwesomeIcon icon={faKey} color={"#0072BC"} />
                      </span>
                      <Link className="font-weight-bold" to="/renew-password">
                        {i18n.t("forgotPassword.button1")}
                      </Link>
                    </div>

                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
