import React from "react";
import { Spinner } from "reactstrap";

/**
 * Renders a spinner component based on the provided loading status.
 * @param {boolean} isLoading - A boolean value that indicates whether content is currently loading.
 * @returns {JSX.Element | null} A Spinner component if `isLoading` is true, or null if not loading.
 */
function renderSpinner(isLoading) {
  if (isLoading === true) {
    return <Spinner size="sm" color="secondary" type="grow" />;
  } else {
    return null;
  }
}
export default renderSpinner;
