import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_WORKFLOW } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useUser() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  /**
   * Creates a notification by sending a POST request to the specified URL with the provided data.
   * @param {object} notifyCreate - The data object containing information required to create the notification.
   * @param {string} notifyCreate.<property> - Specify the properties within the notifyCreate object, such as title, message, recipient, etc.
   * @param {string} actions - The action parameter to be appended to the URL, indicating the type of notification action.
   * @param {string} URL_WORKFLOW - The base URL for the workflow where notifications are being created.
   * @param {object} headers - Optional headers to be included in the HTTP request.
   * @param {string} headers.<headerName> - Specify any specific headers required for the request, such as authorization headers, content-type, etc.
   * @returns {Promise<object>} A promise that resolves to the response object from the HTTP request.
   * @throws {Error} If the request fails or encounters an error.
   */
  const createNotification = async (notifyCreate, actions) => {
    const response = await axios.post(`${URL_WORKFLOW}/task/?action=${actions}`, notifyCreate, {
      headers,
      endpointError: endpointError.workflow.createNotification,
    });
    return response;
  };

  /**
   * Retrieves tasks from the server based on pagination parameters and optional ordering criteria.
   * @param {number} page - The page number for pagination.
   * @param {number} per_page - The number of tasks to be retrieved per page.
   * @param {string} [orderType] - Optional. The type of ordering (e.g., ascending or descending).
   * @param {string} [orderBy] - Optional. The field by which the tasks should be ordered.
   * @returns {Promise<object>} A promise that resolves to the response object containing the requested tasks.
   * @throws {Error} If there's an error during the HTTP request.
   */
  const getTasks = async (page, per_page, orderType, orderBy) => {
    if (orderType === undefined && orderBy === undefined) {
      const response = await axios.get(`${URL_WORKFLOW}/task/?page=${page}&per_page=${per_page}`, {
        headers,
        endpointError: endpointError.workflow.getTasks,
      });
      return response;
    } else {
      const response = await axios.get(
        `${URL_WORKFLOW}/task/?page=${page}&per_page=${per_page}&order_type=${orderType}&order_by=${orderBy}`,
        {
          headers,
          endpointError: endpointError.workflow.getTasks,
        }
      );
      return response;
    }
  };

  /**
   * Fetches tasks by their UUID asynchronously.
   * @param {string} taskId - The UUID of the task to retrieve.
   * @returns {Promise<Object>} The response object containing the task data.
   * @throws {Error} If an error occurs during the HTTP request.
   */
  const getTasksByUuid = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/task/${taskId}/user/`, {
      headers,
      endpointError: endpointError.workflow.getTasksByUuid,
    });
    return response;
  };

  /**
   * Retrieves task information from the server based on the provided task ID.
   * @param {string} taskId - The UUID of the task to retrieve information for.
   * @returns {Promise<object>} A promise that resolves to the response containing task information.
   * @throws {Error} If there's an error during the API call or if the task is not found.
   */
  const getTaskFromFiledByUuid = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/task/${taskId}/`, {
      headers,
      endpointError: endpointError.workflow.getTaskFromFiledByUuid,
    });
    return response;
  };

  /**
   * Retrieves process data by its unique identifier (UUID) from the server.
   * @param {string} ProcessId - The unique identifier (UUID) of the process to retrieve.
   * @returns {Promise<object>} A Promise that resolves to the response containing process data.
   * @throws {Error} If an error occurs during the API request.
   */
  const getProcessByUuid = async (ProcessId) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/process-task/${ProcessId}/`, {
      headers,
      endpointError: endpointError.workflow.getProcessByUuid,
    });
    return response;
  };

  /**
   * Fulfills a task by updating its status.
   * @param {string} taskId - The ID of the task to be fulfilled.
   * @param {object} comply - Data object containing information to comply with the task.
   * @returns {Promise<object>} A Promise that resolves to the response object from the server.
   * @throws {Error} If an error occurs during the fulfillment process.
   */
  const fulfillTask = async (taskId, comply) => {
    const response = await axios.post(`${URL_WORKFLOW}/task/${taskId}/status/`, comply, {
      headers,
      endpointError: endpointError.workflow.fulfillTask,
    });
    return response;
  };

  /**
   * Updates the status of a task in a workflow process to fulfill or comply with it.
   * @param {string} taskId - The identifier of the task to be fulfilled.
   * @param {Object} comply - The data representing compliance or fulfillment status.
   * @param {string} comply.status - The status to which the task will be updated (e.g., "fulfilled", "complied").
   * @param {Object} [comply.additionalData] - Additional data related to the fulfillment or compliance (optional).
   * @param {string} [comply.additionalData.notes] - Additional notes or comments regarding the fulfillment or compliance (optional).
   * @param {string} [comply.additionalData.resolution] - Resolution details related to the fulfillment or compliance (optional).
   * @param {string} [comply.additionalData.attachment] - A URL or reference to an attachment related to the fulfillment or compliance (optional).
   * @returns {Promise<Object>} A promise that resolves to the response object from the API call.
   * @throws {Error} If there's an error during the fulfillment process.
   */
  const fulfillProcess = async (taskId, comply) => {
    const response = await axios.patch(
      `${URL_WORKFLOW}/workflows/process-task/${taskId}/status/`,
      comply,
      {
        headers,
        endpointError: endpointError.workflow.fulfillProcess,
      }
    );
    return response;
  };

  /**
   * Reassigns a task to new users.
   * @param {string} taskId - The ID of the task to be reassigned.
   * @param {Object} reassignTaskUsers - An object containing information about the users to whom the task is being reassigned.
   * @param {string[]} reassignTaskUsers.users - An array of user IDs to whom the task is being reassigned.
   * @param {string} reassignTaskUsers.comment - Optional. A comment or reason for the task reassignment.
   * @returns {Promise<Object>} A promise that resolves to the response object from the reassignment request.
   * @throws {Error} If an error occurs during the reassignment process.
   */
  const reassignTask = async (taskId, reassignTaskUsers) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/task/${taskId}/reassign/`,
      reassignTaskUsers,
      {
        headers,
        endpointError: endpointError.workflow.reassignTask,
      }
    );
    return response;
  };

  /**
   * Interrupts a workflow process identified by its UUID with a specified motive.
   * @param {string} processId - The UUID of the workflow process to be interrupted.
   * @param {string} motive - The reason or motive for interrupting the workflow process.
   * @returns {Promise<object>} - A promise that resolves to the response object from the interruption request.
   */
  const interruptProcessByUUID = async (processId, motive) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process/${processId}/interrupt/`,
      motive,
      {
        headers,
        endpointError: endpointError.workflow.interruptProcessByUUID,
      }
    );
    return response;
  };

  /**
   * Interrupts a batch process with a specified motive.
   * @param {string} motive - The reason or motive for interrupting the batch process.
   * @returns {Promise<Object>} - A promise that resolves with the response object from the interruption request.
   * @throws {Error} - If an error occurs during the interruption process.
   */
  const interruptProcessByBatch = async (motive) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/interrupt-batch/`,
      motive,
      {
        headers,
        endpointError: endpointError.workflow.interruptProcessByBatch,
      }
    );
    return response;
  };

  /**
   * Retrieves user data for reassignment associated with a specific task ID.
   * @param {string} taskId - The unique identifier of the task for which user data is to be retrieved.
   * @returns {Promise<object>} A promise that resolves to an object containing user data for reassignment.
   * @throws {Error} If there is an issue with the HTTP request or the response data format.
   */
  const getUserForReassing = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/task/${taskId}/users/`, {
      headers,
      endpointError: endpointError.workflow.getUserForReassing,
    });
    return response.data;
  };

  /**
   * Retrieves comments associated with a specific task UUID.
   * @param {string} taskId - The UUID identifying the task for which comments are to be retrieved.
   * @returns {Promise<object>} A Promise that resolves to the response object containing comments.
   * The response object typically includes data fetched from the server.
   * @throws {Error} If there's an issue with the request or if the server returns an error response.
   */
  const getCommentsByUuid = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/task/${taskId}/comments/`, {
      headers,
      endpointError: endpointError.workflow.getCommentsByUuid,
    });

    return response;
  };

  /**
   * Creates a comment for a specific task using the provided data.
   * @param {object} commentCreate - The data object containing information to create the comment.
   * @param {string} commentCreate.content - The content of the comment.
   * @param {string} taskId - The ID of the task for which the comment is being created.
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * @throws {Error} If there is an issue with the request or response.
   */
  const createComment = async (commentCreate, taskId) => {
    const response = await axios.post(`${URL_WORKFLOW}/task/${taskId}/comments/`, commentCreate, {
      headers,
      endpointError: endpointError.workflow.createComment,
    });
    return response;
  };

  /**
   * Retrieves comments related to a specific task within a workflow process using the provided task ID.
   * @param {string} taskId - The unique identifier of the task whose comments are to be retrieved.
   * @returns {Promise<object>} A promise that resolves to the response object containing the comments related to the specified task.
   * @throws {Error} If an error occurs during the API request.
   */
  const getCommentsProcessByUuid = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/process/${taskId}/comments/`, {
      headers,
      endpointError: endpointError.workflow.getCommentsProcessByUuid,
    });
    return response;
  };

  /**
   * Creates a comment for a specific task within a workflow process.
   * @param {object} commentCreate - The object containing data to create the comment.
   * @param {string} commentCreate.text - The text content of the comment.
   * @param {string} [commentCreate.author] - The author of the comment (optional).
   * @param {string} taskId - The ID of the task associated with the comment.
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * @throws {Error} If there's an issue with the request or response.
   */
  const createCommentProcess = async (commentCreate, taskId) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process/${taskId}/comments/`,
      commentCreate,
      {
        headers,
        endpointError: endpointError.workflow.createCommentProcess,
      }
    );
    return response;
  };

  /**
   * Retrieves workflows based on specified parameters such as page, items per page, search query, and status.
   * @param {number} page - The page number of the workflows to retrieve.
   * @param {number} per_page - The number of workflows to retrieve per page.
   * @param {string} search - The search query to filter workflows by name.
   * @param {string} status - The status of workflows to filter by.
   * @returns {Promise<object>} A Promise that resolves with the data of the retrieved workflows.
   */
  const getWorkFlows = async (page, per_page, search, status) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/?status=${status}&operation=and&name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getWorkFlows,
        }
      );
    } else if (search) {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/?name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getWorkFlows,
        }
      );
    } else {
      response = await axios.get(`${URL_WORKFLOW}/workflows/?page=${page}&per_page=${per_page}`, {
        headers,
        endpointError: endpointError.workflow.getWorkFlows,
      });
    }
    return response.data;
  };

  /**
   * Retrieves available workflows per user based on specified parameters.
   * @param {number} page - The page number of the results to retrieve.
   * @param {number} per_page - The number of results per page.
   * @param {string} search - The search query to filter workflows by name.
   * @param {string} status - The status of the workflows to filter by.
   * @returns {Promise<object>} A Promise that resolves with the data containing available workflows.
   */
  const getWorkFlowsAvailablesPerUser = async (page, per_page, search, status) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/availables-per-user/?status=${status}&operation=and&name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getWorkFlowsAvailablesPerUser,
        }
      );
    } else if (search) {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/availables-per-user/?name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getWorkFlowsAvailablesPerUser,
        }
      );
    } else {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/availables-per-user/?page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getWorkFlowsAvailablesPerUser,
        }
      );
    }
    return response.data;
  };

  /**
   * Retrieves process data based on specified parameters.
   * @param {number} page - The page number of the process data to retrieve.
   * @param {number} per_page - The number of items per page to retrieve.
   * @param {string} search - The search query for filtering process data.
   * @param {string} status - The status of the process data to filter by.
   * @param {string} form_uuid - The UUID of the form associated with the process.
   * @param {string} recordUuid - The UUID of the record associated with the process.
   * @returns {Promise<object>} The process data retrieved from the server.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  const getProcess = async (page, per_page, search, status, form_uuid, recordUuid) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/process/forms/${form_uuid}/record/${recordUuid}/?status=${status}&operation=and&process_name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getProcess,
        }
      );
    } else {
      response = await axios.get(
        `${URL_WORKFLOW}/workflows/process/forms/${form_uuid}/record/${recordUuid}/?process_name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.workflow.getProcess,
        }
      );
    }
    return response.data;
  };

  /**
   * Retrieves workflows associated with a specific form UUID.
   * @param {string} formUuid - The UUID of the form for which workflows are to be retrieved.
   * @returns {Promise<Object>} A promise that resolves to the response object containing the workflows.
   * @throws {Error} If the request fails or encounters an error.
   */
  const getWorkFlowsByForm = async (formUuid) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/forms/${formUuid}`, {
      headers,
      endpointError: endpointError.workflow.getWorkFlowsByForm,
    });
    return response;
  };

  /**
   * Retrieves workflow data by its unique identifier.
   * @param {string} id - The unique identifier of the workflow to retrieve.
   * This ID is used to fetch the specific workflow data.
   * @returns {Promise<Object>} A promise that resolves to the response containing the workflow data.
   * The response object typically includes metadata and the actual workflow data.
   * It may contain properties such as `data`, `status`, `statusText`, etc.
   * @throws {Error} If an error occurs during the request or if the response status is not successful (e.g., 4xx or 5xx).
   * The error object typically includes information such as the error message and status code.
   */
  const getWorkFlowById = async (id) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/${id}/`, {
      headers,
      endpointError: endpointError.workflow.getWorkFlowById,
    });
    return response;
  };

  /**
   * Initiates workflows by sending a POST request to the workflow processing endpoint.
   * @param {object} startWorkflow - The data payload containing information to start the workflow.
   * @param {string} recordId - The identifier of the record associated with the workflow.
   * @param {object} actions - Additional actions or configuration for starting the workflow.
   * @returns {Promise<object>} - A promise that resolves to the response object from the server.
   * @throws {Error} - Throws an error if there's an issue with the request or response.
   */
  const startWorkflows = async (startWorkflow, recordId, actions) => {
    const response = await axios.post(`${URL_WORKFLOW}/workflows/process/`, startWorkflow, {
      headers,
      endpointError: endpointError.workflow.startWorkflows,
    });
    return response;
  };

  /**
   * Edits a workflow by its unique identifier.
   * @param {string} id - The unique identifier of the workflow to be edited.
   * @param {object} data - The data containing the changes to be applied to the workflow.
   * @param {object} data - The data containing the changes to be applied to the workflow.
   * @param {object} headers - Additional headers to be included in the HTTP request (optional).
   * @returns {Promise<object>} A promise that resolves to the response object containing the updated workflow data.
   */
  const editWorkflowById = async (id, data) => {
    const response = await axios.patch(`${URL_WORKFLOW}/workflows/${id}/`, data, {
      headers,
      endpointError: endpointError.workflow.editWorkflowById,
    });
    return response;
  };

  /**
   * Retrieves workflow steps associated with a given ID from the server.
   * @param {string} id - The ID of the workflow for which steps are to be retrieved.
   * @returns {Promise<Array>} A promise that resolves with an array of workflow steps.
   * @throws {Error} If there is an issue with the HTTP request or if the response does not contain expected data.
   */
  const getWorkFlowSteps = async (id) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/steps/${id}/`, {
      headers,
      endpointError: endpointError.workflow.getWorkFlowSteps,
    });
    return response.data.data;
  };

  /**
   * Retrieves view model data for a specific identifier asynchronously.
   * @param {string} id - The identifier of the view model to retrieve.
   * This parameter is used to specify which view model data to fetch.
   * @returns {Promise<Object>} A promise that resolves to the view model data.
   * The resolved value is an object containing the view model information.
   * @throws {Error} If there is an error during the data retrieval process,
   */
  const viewModel = async (id) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/file-config/${id}`, {
      headers,
      endpointError: endpointError.workflow.viewModel,
    });
    return response.data;
  };

  /**
   * Adds a responsible step to a workflow using the provided data.
   * @param {object} data - The data object containing information about the responsible step to be added.
   * @param {string} data.workflowId - The ID of the workflow to which the responsible step will be added.
   * @param {string} data.stepName - The name of the step to be added.
   * @param {string} data.responsibleUserId - The ID of the user responsible for the step.
   * @param {string} data.description - Optional. A description of the step.
   * @param {object} data.otherData - Optional. Additional data related to the step.
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * @throws {Error} If an error occurs during the request.
   */
  const addResponsibleStep = async (data) => {
    const response = await axios.post(`${URL_WORKFLOW}/workflows/step/responsible/only/`, data, {
      headers,
      endpointError: endpointError.workflow.addResponsibleStep,
    });

    return response;
  };

  /**
   * Retrieves workflow traceability data associated with the provided ID.
   * @async
   * @function
   * @name getWorkflowTraceability
   * @param {string} id - The unique identifier of the workflow process.
   * @returns {Promise<object>} A Promise that resolves to the response object containing the workflow traceability data.
   */
  const getWorkflowTraceability = async (id) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/process/${id}/`, {
      headers,
      endpointError: endpointError.workflow.getWorkflowTraceability,
    });
    return response;
  };

  /**
   * Adds responsible sender selects to a workflow step.
   * @param {Object} data - The data object containing information required for adding responsible sender selects.
   * @param {string} data.property1 - Description of property1.
   * @param {string} data.property2 - Description of property2.
   * @returns {Promise<Object>} A Promise that resolves with the response data from the server.
   * @throws {Error} If the request fails or encounters an error.
   */
  const addResponsibleSenderSelects = async (data) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/step/sender-selects/responsible/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.addResponsibleSenderSelects,
      }
    );
    return response;
  };

  /**
   * Retrieves workflow traceability files associated with a specific process ID.
   * @param {string} id - The ID of the process for which traceability files are to be retrieved.
   * @returns {Promise<object>} A Promise that resolves with the response object containing traceability files.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  const getWorkflowTraceabilityFiles = async (id) => {
    const response = await axios.get(`${URL_WORKFLOW}/file/process/${id}/`, {
      headers,
      endpointError: endpointError.workflow.getWorkflowTraceabilityFiles,
    });

    return response;
  };

  /**
   * Retrieves a list of responsibles associated with a specific workflow and step.
   * @param {string} workflow_uuid - The UUID of the workflow to retrieve responsibles from.
   * @param {string} step_uuid - The UUID of the step within the workflow to retrieve responsibles from.
   * @returns {Promise<object>} A promise that resolves with the data containing the list of responsibles.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  const getListResponsibles = async (workflow_uuid, step_uuid) => {
    const response = await axios.get(
      `${URL_WORKFLOW}/workflows/${workflow_uuid}/step/${step_uuid}/`,
      {
        headers,
        endpointError: endpointError.workflow.getListResponsibles,
      }
    );

    return response.data;
  };

  /**
   * Retrieves files associated with a specific process ID.
   * @param {string} processId - The ID of the process for which files are to be retrieved.
   * This ID uniquely identifies the process in the system.
   * @returns {Promise<any>} A Promise that resolves to the data representing the files associated
   * with the specified process ID.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  const getFilesByProcess = async (processId) => {
    const response = await axios.get(`${URL_WORKFLOW}/file/process/${processId}/`, {
      headers,
      endpointError: endpointError.workflow.getFilesByProcess,
    });
    return response.data;
  };

  /**
   * Retrieves files associated with a specific task ID from the server.
   * @param {string} taskId - The ID of the task for which files are to be retrieved.
   * @returns {Promise<Array>} A promise that resolves to an array of file data associated with the task.
   * @throws {Error} If there is an issue fetching the files from the server.
   */
  const getFilesByTask = async (taskId) => {
    const response = await axios.get(`${URL_WORKFLOW}/file/task/${taskId}/`, {
      headers,
      endpointError: endpointError.record.getFilesByTask,
    });
    return response.data;
  };

  /**
   * Configures actions for a specific workflow step.
   * @param {string} workflowUuid - The UUID of the workflow to which the step belongs.
   * @param {string} stepUuid - The UUID of the step for which actions are being configured.
   * @param {object} data - The data containing the configuration for the actions.
   * @returns {Promise<object>} - A promise that resolves with the response object from the server.
   * @throws {Error} - If an error occurs during the configuration process.
   */
  const configureActions = async (workflowUuid, stepUuid, data) => {
    const response = await axios.put(
      `${URL_WORKFLOW}/workflows/${workflowUuid}/step/${stepUuid}/actions/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.configureActions,
      }
    );
    return response;
  };

  /**
   * Retrieves a list of users associated with a specified process ID.
   * @param {string} ProcessId - The ID of the process for which users are to be fetched.
   * This ID is used to identify the specific process.
   * @returns {Promise<object>} A Promise that resolves to the response object containing
   * the list of users associated with the specified process.
   * @throws {Error} If an error occurs during the retrieval process.
   */
  const getListUserByProcess = async (ProcessId) => {
    const response = await axios.get(`${URL_WORKFLOW}/workflows/process-task/${ProcessId}/users/`, {
      headers,
      endpointError: endpointError.workflow.getListUserByProcess,
    });
    return response;
  };

  /**
   * Reassigns a workflow process task to new users.
   * @param {string} taskId - The ID of the task to be reassigned.
   * @param {object} reassignTaskUsers - Object containing data for reassigning the task to new users.
   * @param {string[]} reassignTaskUsers.users - An array of user IDs to whom the task will be reassigned.
   * @param {string} [reassignTaskUsers.comment] - Optional comment or reason for the task reassignment.
   * @returns {Promise<object>} The response object from the reassignment request.
   * @throws {Error} If the reassignment request fails.
   */
  const reassignProcess = async (taskId, reassignTaskUsers) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/${taskId}/reassign/`,
      reassignTaskUsers,
      {
        headers,
        endpointError: endpointError.workflow.reassignProcess,
      }
    );
    return response;
  };

  /**
   * Edits the current assigned user for a specific step in a workflow by ID.
   * @param {string} workflowId - The ID of the workflow containing the step.
   * @param {string} stepUuid - The UUID of the step whose assigned user is to be edited.
   * @param {Object} data - The data object containing the updated information for the assigned user.
   * @returns {Promise<Object>} A promise that resolves to the response object from the server.
   * @throws {Error} If an error occurs during the PATCH request.
   */
  const editCurrentAssignedById = async (workflowId, stepUuid, data) => {
    const response = await axios.patch(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepUuid}/single-responsible/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.editCurrentAssignedById,
      }
    );
    return response;
  };

  /**
   * Edits the list of assigned responsibles for a specific workflow step identified by workflowId and stepUuid.
   * @param {string} workflowId - The ID of the workflow to which the step belongs.
   * @param {string} stepUuid - The UUID of the step whose responsible list is to be edited.
   * @param {object} data - The data containing the changes to be made to the responsible list.
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * @throws {Error} If the request fails or encounters an error.
   */
  const editListAssignedById = async (workflowId, stepUuid, data) => {
    const response = await axios.patch(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepUuid}/responsible-list/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.editListAssignedById,
      }
    );
    return response;
  };

  /**
   * Deletes responsible(s) associated with a specific workflow step identified by workflowId and stepUuid.
   * @param {string} workflowId - The unique identifier of the workflow to which the step belongs.
   * @param {string} stepUuid - The UUID (Universally Unique Identifier) of the workflow step whose responsible(s) will be deleted.
   * @returns {Promise<object>} The response object containing information about the deletion operation.
   * @throws {Error} If the deletion operation fails or encounters an error.
   */
  const deleteResponsibles = async (workflowId, stepUuid) => {
    const response = await axios.delete(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepUuid}/responsible/`,
      {
        headers,
        endpointError: endpointError.workflow.deleteResponsibles,
      }
    );
    return response;
  };

  /**
   * Assigns a work group to a workflow step responsible.
   * @param {object} data - The data object containing information needed for assigning the work group.
   * @param {string} data.workflowId - The ID of the workflow to which the step belongs.
   * @param {string} data.stepId - The ID of the workflow step.
   * @param {string} data.workGroupId - The ID of the work group to be assigned.
   * @param {object} [data.additionalInfo] - Additional information related to the assignment (optional).
   * @returns {Promise<object>} A promise that resolves to the response object from the server.
   * @throws {Error} If an error occurs during the assignment process.
   */
  const assingWorkGroup = async (data) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/step/responsible/work-group/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.assingWorkGroup,
      }
    );
    return response;
  };

  /**
   * Updates the work group responsible for a specific step in a workflow.
   * @param {string} workflowId - The ID of the workflow to which the step belongs.
   * @param {string} stepId - The ID of the step whose work group responsible is to be updated.
   * @param {object} data - The data containing the new work group responsible information.
   * @returns {Promise<object>} The response object containing the result of the update operation.
   * @throws {Error} If an error occurs during the update process.
   */
  const updateWorkGroupResponsibleStep = async (workflowId, stepId, data) => {
    const response = await axios.patch(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepId}/work-group-responsible/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.updateWorkGroupResponsibleStep,
      }
    );
    return response;
  };

  /**
   * Configures form data for a specific step in a workflow in one step.
   * @param {string} workflowId - The ID of the workflow where the form configuration will be applied.
   * @param {string} stepId - The ID of the step within the workflow where the form configuration will be applied.
   * @param {object} data - The configuration data to be applied to the form for the specified workflow step.
   * @returns {Promise<object>} A promise that resolves to the response object containing the result of the form configuration.
   * @throws {Error} If there's an error during the form configuration process.
   */
  const cofigurationFormInOneStep = async (workflowId, stepId, data) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepId}/configuration-data-form/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.cofigurationFormInOneStep,
      }
    );
    return response;
  };

  /**
   * Updates configuration data form for a specific step in a workflow.
   * @param {string} workflowId - The ID of the workflow to which the step belongs.
   * @param {string} stepId - The ID of the step for which the configuration data form is to be updated.
   * @param {object} data - The new configuration data to be set for the step.
   * @returns {Promise<object>} The response object containing the result of the patch operation.
   * @throws {Error} If the update operation fails or encounters an error.
   */
  const updateCofigurationFormInOneStep = async (workflowId, stepId, data) => {
    const response = await axios.patch(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepId}/configuration-data-form/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.updateCofigurationFormInOneStep,
      }
    );
    return response;
  };

  /**
   * Retrieves configuration form data in a single step by its ID.
   * @param {string} workflowId - The ID of the workflow containing the desired configuration form.
   * @param {string} stepId - The ID of the step within the workflow where the configuration form is located.
   * @param {number} page - The page number for paginated results.
   * @param {number} perPage - The number of items per page for paginated results.
   * @returns {Promise<object>} A promise that resolves with the response containing the configuration form data.
   */
  const getConfigurationFormInOneStepById = async (workflowId, stepId, page, perPage) => {
    const response = await axios.get(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepId}/configuration-data-form/?page=${page}&per_page=${perPage}`,
      {
        headers,
        endpointError: endpointError.workflow.getConfigurationFormInOneStepById,
      }
    );
    return response;
  };

  /**
   * Deletes the configuration form associated with a specific step in a workflow in one step.
   * @param {string} workflowId - The ID of the workflow containing the step whose configuration form will be deleted.
   * @param {string} stepId - The ID of the step for which the configuration form will be deleted.
   * @returns {Promise<object>} A promise that resolves to the response object from the delete request.
   * @throws {Error} If the deletion operation encounters an error.
   */
  const deleteCofigurationFormInOneStep = async (workflowId, stepId) => {
    const response = await axios.delete(
      `${URL_WORKFLOW}/workflows/${workflowId}/step/${stepId}/configuration-data-form/`,
      {
        headers,
        endpointError: endpointError.workflow.deleteCofigurationFormInOneStep,
      }
    );
    return response;
  };

  /**
   * Asynchronously configures data forms for a specific step within a workflow.
   * @param {string} workflowUuid - The UUID of the workflow to which the step belongs.
   * @param {string} stepUuid - The UUID of the step for which data forms are being configured.
   * @param {object} configFormforStep - The configuration data for the form associated with the step.
   * @returns {Promise<object>} A promise that resolves to the response from the server.
   * @throws {Error} If there is an issue with the request or response.
   */
  const configurationDataFormInStep = async (workflowUuid, stepUuid, configFormforStep) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/${workflowUuid}/step/${stepUuid}/data-forms/`,
      configFormforStep,
      {
        headers,
        endpointError: endpointError.workflow.configurationDataFormInStep,
      }
    );
    return response;
  };

  /**
   * Retrieves external configuration data for a specific step in a workflow.
   * @param {string} workFlowId - The ID of the workflow to retrieve data from.
   * @param {string} stepUuid - The UUID of the specific step within the workflow.
   * @returns {Promise<object>} A promise that resolves with the external configuration data for the specified step.
   * @throws {Error} Throws an error if the retrieval process fails.
   */
  const getDataFormExternalConfiguration = async (workFlowId, stepUuid) => {
    const response = await axios.get(
      `${URL_WORKFLOW}/workflows/${workFlowId}/step/${stepUuid}/data-forms-external-config/`,
      {
        headers,
        endpointError: endpointError.workflow.getDataFormExternalConfiguration,
      }
    );
    return response.data;
  };

  /**
   * Updates the external configuration data for a specific step in a workflow.
   * @param {string} workFlowId - The ID of the workflow to which the step belongs.
   * @param {string} stepUuid - The UUID of the step whose external configuration data is to be updated.
   * @param {object} data - The updated external configuration data to be sent in the request body.
   * @returns {Promise<object>} A promise that resolves to the updated external configuration data.
   * @throws {Error} If there's an issue with the request or response.
   */
  const updateFormExternalConfiguration = async (workFlowId, stepUuid, data) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/${workFlowId}/step/${stepUuid}/data-forms/`,
      data,
      {
        headers,
        endpointError: endpointError.workflow.updateFormExternalConfiguration,
      }
    );
    return response.data;
  };

  /**
   * Deletes configuration data form associated with a specific step in a workflow.
   * @param {string} workflowUuid - The UUID of the workflow containing the step.
   * @param {string} stepUuid - The UUID of the step from which the configuration data form will be deleted.
   * @returns {Promise<object>} A Promise that resolves to the response object from the delete request.
   * @throws {Error} If there is an issue with the deletion process.
   */
  const deleteConfigurationDataFormInStep = async (workflowUuid, stepUuid) => {
    const response = await axios.delete(
      `${URL_WORKFLOW}/workflows/${workflowUuid}/step/${stepUuid}/data-forms/`,
      {
        headers,
        endpointError: endpointError.workflow.deleteConfigurationDataFormInStep,
      }
    );
    return response;
  };

  /**
   * Asynchronously verifies a batch of process tasks by sending a POST request to the server.
   * @param {Array} tasks - An array containing the process tasks to be verified.
   * @returns {Promise} A promise that resolves with the response from the server after verifying the batch of process tasks.
   * @throws {Error} If there is an error during the request or if the response status is not within the 2xx range.
   */
  const verifyProcessBatch = async (tasks) => {
    const response = await axios.post(`${URL_WORKFLOW}/task/process-batch/`, tasks, {
      headers,
      endpointError: endpointError.workflow.verifyProcessBatch,
    });
    return response;
  };

  /**
   * Executes a batch process task accomplishment operation.
   * @param {object} dataAccomplishBatch - The data object containing information required for batch accomplishment of process tasks.
   * @param {string} dataAccomplishBatch.workflowId - The ID of the workflow associated with the process tasks to be accomplished.
   * @param {Array<string>} dataAccomplishBatch.taskIds - An array of task IDs to be accomplished.
   * @param {string} dataAccomplishBatch.comment - A comment associated with the batch accomplishment.
   * @param {object} [dataAccomplishBatch.additionalData={}] - Additional data related to the batch accomplishment (optional).
   * @param {string} [dataAccomplishBatch.userId] - The ID of the user responsible for the batch accomplishment (optional).
   * @param {object} [dataAccomplishBatch.metadata={}] - Metadata associated with the batch accomplishment (optional).
   * @param {object} [dataAccomplishBatch.customAttributes={}] - Custom attributes related to the batch accomplishment (optional).
   * @returns {Promise<object>} A promise resolving to the response data from the server upon successful batch accomplishment.
   * @throws {Error} If an error occurs during the batch accomplishment process.
   */
  const accomplishProcessBatch = async (dataAccomplishBatch) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/accomplish-batch/`,
      dataAccomplishBatch,
      {
        headers,
        endpointError: endpointError.workflow.accomplishProcessBatch,
      }
    );
    return response.data;
  };

  /**
   * Filters tasks based on advanced criteria.
   * @param {number} page - The page number for paginated results.
   * @param {number} perPage - The number of items per page in the result set.
   * @param {object} searchFilterTaskByCriteria - Criteria object to filter tasks.
   * @param {string} orderType - The type of ordering (e.g., 'asc' for ascending, 'desc' for descending).
   * @param {string} orderBy - The field by which to order the tasks.
   * @returns {Promise<object>} A promise that resolves to the filtered tasks data.
   * @throws {Error} If the request fails or encounters an error.
   */
  const advancedFilterTasks = async (
    page,
    perPage,
    searchFilterTaskByCriteria,
    orderType,
    orderBy
  ) => {
    if (orderType === undefined && orderBy === undefined) {
      const response = await axios.post(
        `${URL_WORKFLOW}/task/filter/?time_zone=0&page=${page}&per_page=${perPage}`,
        searchFilterTaskByCriteria,
        {
          headers,
          endpointError: endpointError.workflow.advancedFilterTasks,
        }
      );
      return response.data;
    } else {
      const response = await axios.post(
        `${URL_WORKFLOW}/task/filter/?time_zone=0&page=${page}&per_page=${perPage}&order_type=${orderType}&order_by=${orderBy}`,
        searchFilterTaskByCriteria,
        {
          headers,
          endpointError: endpointError.workflow.advancedFilterTasks,
        }
      );
      return response.data;
    }
  };

  /**
   * Asynchronously reassigns a batch of process tasks.
   * @param {object} reassignProcessBatchData - The data object containing information required for reassigning the process batch.
   * @param {string} reassignProcessBatchData.batchId - The identifier for the batch of process tasks to be reassigned.
   * @param {string} reassignProcessBatchData.newAssigneeId - The identifier for the new assignee of the process tasks.
   * @param {string} reassignProcessBatchData.comments - Optional comments to be associated with the reassignment.
   * @param {string} reassignProcessBatchData.reason - Optional reason for the reassignment.
   * @returns {Promise<object>} A promise that resolves to the response object containing the result of the reassignment.
   * @throws {Error} If the reassignment process encounters an error.
   */
  const reassingProcessBatch = async (reassignProcessBatchData) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/reassign-batch/`,
      reassignProcessBatchData,
      {
        headers,
        endpointError: endpointError.workflow.reassingProcessBatch,
      }
    );
    return response;
  };

  /**
   * Retrieves the steps to return for a given process UUID.
   * @param {string} processUuid - The UUID of the process.
   * @returns {Promise<Array>} - A Promise that resolves to an array containing the steps to return.
   */
  const getStepsToReturn = async (processUuid) => {
    const response = await axios.get(
      `${URL_WORKFLOW}/workflows/process-task/${processUuid}/return/`,
      {
        headers,
        endpointError: endpointError.workflow.getStepsToReturn,
      }
    );
    return response.data;
  };

  /**
   * Returns the process to a specific step in the workflow.
   * @param {string} processUuid - The UUID of the process to return.
   * @param {Object} returnToStepProcessData - Data to be sent when returning the process to a step.
   * @returns {Promise<Object>} - A Promise that resolves to the data returned from the server.
   */
  const returnToStepProcess = async (processUuid, returnToStepProcessData) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/${processUuid}/return/`,
      returnToStepProcessData,
      {
        headers,
        endpointError: endpointError.workflow.returnToStepProcess,
      }
    );
    return response;
  };

  /**
   * Manages the process by fetching data from the workflow management endpoint.
   * If orderType and orderBy are not provided, it fetches the data without sorting.
   * @param {number} page - The current page number to fetch.
   * @param {number} perPage - The number of items per page.
   * @param {Object} queryProcessData - The data object containing query parameters.
   * @param {string} [orderType] - The type of ordering (e.g., ascending or descending).
   * @param {string} [orderBy] - The field by which to order the results.
   * @returns {Promise<Object>} The response data from the server.
   */
  const managementProcess = async (page, perPage, queryProcessData, orderType, orderBy) => {
    if (orderType === undefined && orderBy === undefined) {
      const response = await axios.post(
        `${URL_WORKFLOW}/workflows/process/management/?page=${page}&per_page=${perPage}`,
        queryProcessData,
        {
          headers,
          endpointError: endpointError.workflow.managementProcess,
        }
      );
      return response.data;
    } else {
      const response = await axios.post(
        `${URL_WORKFLOW}/workflows/process/management/?page=${page}&per_page=${perPage}&order_type=${orderType}&order_by=${orderBy}`,
        queryProcessData,
        {
          headers,
          endpointError: endpointError.workflow.managementProcess,
        }
      );
      return response.data;
    }
  };

  /**
   * Asynchronously verifies a batch of process tasks by sending a POST request to the server.
   * @param {Array} tasks - An array containing the process tasks to be verified.
   * @returns {Promise} A promise that resolves with the response from the server after verifying the batch of process tasks.
   * @throws {Error} If there is an error during the request or if the response status is not within the 2xx range.
   */
  const verifyManagementProcess = async (tasks) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/management/tasks/`,
      tasks,
      {
        headers,
        endpointError: endpointError.workflow.verifyManagementProcess,
      }
    );
    return response;
  };

  /**
   * Executes the reassignment of process tasks in the workflow management system.
   * @param {Object} reassignProcessBatchData - Data object containing information for batch process reassignment.
   * @param {string} reassignProcessBatchData.user_uuid - UUID of the user initiating the reassignment.
   * @param {string} reassignProcessBatchData.workflow_uuid - UUID of the workflow containing tasks to reassign.
   * @param {string} reassignProcessBatchData.step_uuid - UUID of the step within the workflow to reassign tasks from.
   * @param {number} reassignProcessBatchData.process_number - Number identifier of the process batch to reassign tasks for.
   * @param {string} reassignProcessBatchData.start_date - Start date for filtering tasks to reassign (formatted as YYYY-MM-DD).
   * @param {string} reassignProcessBatchData.end_date - End date for filtering tasks to reassign (formatted as YYYY-MM-DD).
   * @returns {Promise<Object>} Response object from the reassign process task API call.
   */
  const managementProcessTaskReasing = async (reassignProcessBatchData) => {
    const response = await axios.post(
      `${URL_WORKFLOW}/workflows/process-task/management/reassign/`,
      reassignProcessBatchData,
      {
        headers,
        endpointError: endpointError.workflow.managementProcessTaskReasing,
      }
    );
    return response;
  };

  return {
    createNotification,
    getTasks,
    getTasksByUuid,
    getTaskFromFiledByUuid,
    getProcessByUuid,
    fulfillTask,
    reassignTask,
    interruptProcessByUUID,
    interruptProcessByBatch,
    getUserForReassing,
    getCommentsByUuid,
    createComment,
    getCommentsProcessByUuid,
    createCommentProcess,
    getWorkFlows,
    startWorkflows,
    getWorkFlowsByForm,
    getWorkFlowById,
    editWorkflowById,
    viewModel,
    getProcess,
    getWorkFlowSteps,
    addResponsibleStep,
    getWorkflowTraceability,
    getWorkflowTraceabilityFiles,
    getListResponsibles,
    getFilesByProcess,
    getFilesByTask,
    addResponsibleSenderSelects,
    fulfillProcess,
    configureActions,
    getListUserByProcess,
    reassignProcess,
    editCurrentAssignedById,
    deleteResponsibles,
    editListAssignedById,
    assingWorkGroup,
    updateWorkGroupResponsibleStep,
    cofigurationFormInOneStep,
    getConfigurationFormInOneStepById,
    updateCofigurationFormInOneStep,
    deleteCofigurationFormInOneStep,
    configurationDataFormInStep,
    deleteConfigurationDataFormInStep,
    getDataFormExternalConfiguration,
    updateFormExternalConfiguration,
    getWorkFlowsAvailablesPerUser,
    verifyProcessBatch,
    accomplishProcessBatch,
    reassingProcessBatch,
    advancedFilterTasks,
    getStepsToReturn,
    returnToStepProcess,
    managementProcess,
    verifyManagementProcess,
    managementProcessTaskReasing,
  };
}
