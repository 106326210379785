import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { DropdownItem, DropdownMenu, DropdownToggle, ButtonDropdown } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { enumsFilterTrashdates } from "utils/enums";
import { getFormattedTimezoneOffset } from "utils/getDateFormat";
import DatePickerTrashSelect from "./DatePickerTrashSelect";
import i18n from "locales/i18n";

const FilterTrashElements = (props) => {
  const {
    setFilterTrash,
    setSearchDeletedFile,
    setTimeZone,
    paginationTrash,
    setPaginationTrash,
  } = props;
  const [isOpenDrop, setIsOpenDrop] = useState(false);

  /**
   *Function that toggles the value of 'isOpen' to control the visibility of a table content.
   *It is typically used as an event handler for a toggle button.
   *@param {boolean} isOpenDrop - the current state indicating if the table content is open or closed
   *@param {function} setIsOpenDrop - the state setter function for 'isOpen' that updates the visibility of the table content
   */
  const handleOntoggle = () => {
    setIsOpenDrop(!isOpenDrop);
  };

  /**
   * Handles the change event for the filter trash input.
   * This function updates the filter trash state, resets the search deleted file state,
   * sets the current time zone offset, and resets the pagination for trash items.
   * @param {Object} eventFilterTrash - The event object from the filter trash input change.
   * @param {Object} eventFilterTrash.target - The target element of the event.
   * @param {string} eventFilterTrash.target.value - The value of the target element.
   */
  const handleOnChangeFilterTrash = (eventFilterTrash) => {
    const formattedOffset = getFormattedTimezoneOffset();
    setFilterTrash(eventFilterTrash.target.value);
    setSearchDeletedFile("");
    setTimeZone(formattedOffset);
    setPaginationTrash({ ...paginationTrash, page: 1 });
  };

  return (
    <Fragment>
      <ButtonDropdown
        className=" mt-2 mb-2 mr-2 btn-icon btn-block"
        isOpen={isOpenDrop}
        toggle={handleOntoggle}
      >
        <DropdownToggle caret color="success">
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
          {i18n.t("trash.label4")}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-hover-success dropMenu-size">
          <DropdownItem value={enumsFilterTrashdates.TODAY} onClick={handleOnChangeFilterTrash}>
            {i18n.t("trash.label5")}
          </DropdownItem>
          <DropdownItem
            value={enumsFilterTrashdates.LAST_SEVEN_DAYS}
            onClick={handleOnChangeFilterTrash}
          >
            {i18n.t("trash.label6")}
          </DropdownItem>
          <DropdownItem value={enumsFilterTrashdates.ALL} onClick={handleOnChangeFilterTrash}>
            {i18n.t("trash.label7")}
          </DropdownItem>
          <DropdownItem
            className="filterTrash-datepicker-dropdown-item"
            value={""}
            onClick={(event) => event.stopPropagation()}
          >
            <DatePickerTrashSelect
              setFilterTrash={setFilterTrash}
              setTimeZone={setTimeZone}
              setSearchDeletedFile={setSearchDeletedFile}
              setIsOpenDrop={setIsOpenDrop}
              paginationTrash={paginationTrash}
              setPaginationTrash={setPaginationTrash}
            />
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </Fragment>
  );
};

FilterTrashElements.propTypes = {
  setFilterTrash: PropTypes.func.isRequired,
  setTimeZone: PropTypes.func.isRequired,
  setSearchDeletedFile: PropTypes.func.isRequired,
  paginationTrash: PropTypes.object.isRequired,
  setPaginationTrash: PropTypes.func.isRequired,
};

export default FilterTrashElements;
