import i18n from "locales/i18n";

const levelsName = {
  background: "0",
  section: "1",
  subsection: "2",
  series: "3",
  subseries: "4",
  expedient: "5",
}

export const keyRoutesLevelsName = {
  [levelsName.background] : i18n.t("trd.formTittle9"),
  [levelsName.section]: i18n.t("trd.formTittle10"),
  [levelsName.subsection]: i18n.t("trd.formTittle11"),
  [levelsName.series]: i18n.t("trd.formTittle12"),
  [levelsName.subseries]: i18n.t("trd.formTittle13"),
  [levelsName.expedient]: i18n.t("trd.formTittle14"),
};