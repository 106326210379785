import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import i18n from "locales/i18n";

const ButtonCreateUser = () => {
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <Link to="/user/create">
            <UncontrolledButtonDropdown>
              <DropdownToggle className="button" color="success">
                <span>{i18n.t("User.createButton")}</span>
              </DropdownToggle>
            </UncontrolledButtonDropdown>
          </Link>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonCreateUser;
