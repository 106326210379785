import React, { Fragment, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  CardFooter,
  Spinner,
} from "reactstrap";
import Loader from "react-loaders";
import { Link, useParams } from "react-router-dom";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { useFilterContext } from "contextAPI/FilterContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { capitalizeText } from "utils/formatText";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { isNullOrUndefined } from "utils/validations";
import {
  enumsControlDataFields,
  enumsFieldsTyData,
  enumsFieldsTypeControl,
  enumsFilterFormsRecords,
  enumsFilterFormsRequired
} from "utils/enums";
import {
  regexDataNumber,
  regexDataRecordEnds,
  regexDataText,
  regexDateRecord
} from "utils/regexExpressions";
import FilterFormsRecordsTable from "components/organism/FilterFormsRecordsTable";
import * as moment from "moment";
import cx from "classnames";
import ComboBox from "components/molecules/Fields/Strings/ComboBox";
import Lookup from "components/molecules/Fields/Strings/Lookup";
import DatePicker from "components/molecules/Fields/Date/DatePicker";
import DateTimePicker from "components/molecules/Fields/DateTime/DateTimePicker";
import ContainerFilterFormRender from "components/molecules/ContainerFilterFormRender";
import i18n from "locales/i18n";

const FilterFormsRecords = (props) => {
  const { requiredFields } = props;
  const {
    getFieldsBySearch,
    isLoading,
    queryFieldsTemp,
    queries,
    queriesTable,
    queriesRec,
    setQueriesRec,
    showTable,
    deleteQueries,
    handleOnSubmitSig,
    handleOnKeyPress,
    handleOnChangeFilter,
    handleOnChangeValue,
    handleOnBlur,
    handleOnSubmit,
    handleOnChangeField,
    handleOnChangeOperator,
    queryByRecord,
    setQueryByRecord,
    queryByFieldRecord,
    setQueryByFieldRecord,
    queryByFieldRecordTemp,
    setQueryByFieldRecordTemp,
    startDate,
    setStartDate,
    isErrorDate,
    setIsErrorDate,
    isErrorDateTime,
    setIsErrorDateTime,
    isFilterForms,
  } = useFilterContext();

  const {
    structureQueryByFilterTask,
    isAdvancedFilterTask,
    isFilter,
  } = useWorkflowContext();

  const { id } = useParams();
  const { concatenatedValues } = useFieldsRecordContext();
  let valueOperator = null;
  let valueSearchField = "";
  let patternsValidation = null;
  let valuefilter = null;
  let filterByRecord = null;
  let searchData = null;
  let headerFilterForm = null;
  let showTableFilterForm = null;
  let isLoadingFilterForm = null;
  let buttonSubmitFilterForm = null;
  let disabledcardFooterSubmitButton = false;
  let disabledcardFooterAddButton = false;
  let requiredLabelDatePicker = false;

  /**
   * Determine whether the label for the date picker is required based on error states.
   * @param {boolean} isErrorDate - Indicates whether there is an error in the date.
   * @param {boolean} isErrorDateTime - Indicates whether there is an error in the date time.
   * @returns {boolean} A boolean value indicating whether the label for the date picker is required.
   */
  if (isErrorDate === true || isErrorDateTime === true) {
    requiredLabelDatePicker = true;
  } else {
    requiredLabelDatePicker = false;
  }

  /**
   * Determines whether the "Add" button in the card footer should be disabled based on certain conditions.
   * @param {boolean} isLoading - A flag indicating whether data is currently being loaded.
   *   If set to `true`, the button will be disabled.
   * @param {number} queriesTableLength - The length of the queries table.
   * @returns {boolean} Returns `true` if the button should be disabled; otherwise, returns `false`.
   */
  if (isLoading === true || queriesTable.length === 3) {
    disabledcardFooterAddButton = true;
  }

  /**
   * Determines whether the submit button in the card footer should be disabled based on loading status and the number of queries.
   * @param {boolean} isLoading - Flag indicating whether data is currently being loaded.
   * @param {number} queries - The number of queries being processed.
   * @returns {boolean} - True if the submit button should be disabled, otherwise false.
   */
  if (isLoading === true || queries.length === 0) {
    disabledcardFooterSubmitButton = true;
  }

  /**
   * Renders a spinner component if the isLoading state is true.
   * @param {boolean} isLoading - A boolean flag indicating whether the component is in a loading state.
   * @returns {JSX.Element|null} Returns a spinner component if isLoading is true, otherwise returns null.
   */
  if (isLoading === true) {
    isLoadingFilterForm = (
      <div>
        <Spinner size="sm" color="secondary" type="grow" />
      </div>
    );
  }

  /**
   * Generates header and footer components for a filter form if the isAdvancedFilterTask is false.
   * @param {boolean} isAdvancedFilterTask - Flag indicating whether the isAdvancedFilterTask is false.
   * @param {function} handleOnSubmit - Function to handle form submission.
   * @param {string} isLoadingFilterForm - Loading state of the filter form.
   * @param {Array} queries - Array of queries.
   * @param {function} i18n.t - Function for internationalization translation.
   * @returns {Object} Object containing headerFilterForm and buttonSubmitFilterForm components.
   */
  if (isAdvancedFilterTask === false) {
    headerFilterForm = (
      <CardHeader className="card-header-lg">
        <h5 className="text-info font-weight-bold">
          {i18n.t("filterQuery.title")}
        </h5>

        <div className="btn ml-auto">
          <Link to="/record/list/form">
            <Button className="btn-icon" color="cyan">
              <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
              {i18n.t("filterQuery.newQuery")}
            </Button>
          </Link>
        </div>
      </CardHeader>
    );

    buttonSubmitFilterForm = (
      <CardFooter className="d-block text-right">
        <Button
          type="button"
          size="lg"
          data-test-id="btn-submitFilterForm"
          disabled={disabledcardFooterSubmitButton}
          onClick={handleOnSubmit}
          className="col-mt-3"
          color="cyan"
        >
          {isLoadingFilterForm}
          {i18n.t("createRoles.configFormOption2")}
        </Button>
      </CardFooter>
    );
  }

  /**
   * Generates a filter form for displaying records in a table if the 'showTable' flag is true.
   * This function creates a component containing a FilterFormsRecordsTable and a submit button,
   * allowing users to filter and interact with the records displayed in the table.
   * @param {boolean} showTable - Flag indicating whether to display the table and filter form.
   * @param {Array} queriesTable - Temporary queries used for filtering the records in the table.
   * @param {function} deleteQueries - Function to delete queries from the filter form.
   * @param {boolean} isLoading - Flag indicating whether the table data is currently being loaded.
   * @param {React.Component} buttonSubmitFilterForm - The submit button component for the filter form.
   * @returns {React.Component|null} - Returns a React component containing the filter form and submit button if 'showTable' is true; otherwise, returns null.
   */
  if (showTable === true) {
    showTableFilterForm = (
      <div>
        <FilterFormsRecordsTable
          queriesTemp={queriesTable}
          deleteQueries={deleteQueries}
          isLoading={isLoading}
        />
        {buttonSubmitFilterForm}
      </div>
    );
  }

  /**
   * Determines the value of 'valueOperator' based on the existence of 'field_uuid' in query records.
   * If 'field_uuid' exists in 'queryByFieldRecord', 'valueOperator' is set to 'queryByFieldRecord.operator'.
   * Otherwise, 'valueOperator' is set to 'queryByRecord.operator'.
   * @param {object} queryByFieldRecord - The record containing 'field_uuid' and 'operator' for a specific field.
   * @param {object} queryByRecord - The record containing 'operator' for a general query.
   * @returns {string} The determined 'valueOperator' based on the conditions.
   */
  if (queryByFieldRecord.field_uuid) {
    valueOperator = queryByFieldRecord.operator;
  } else {
    valueOperator = queryByRecord.operator;
  }

  /**
   * Determine the value for the 'valueSearchField' variable based on data types.
   * This conditional statement checks the data types of two variables, 'queryByRecord'
   * and 'queryByFieldRecord', and assigns a value to 'valueSearchField' accordingly.
   * If 'queryByRecord' has a data type of 5 and 'queryByFieldRecord' has a data type of 0,
   * 'valueSearchField' is set to an empty string; otherwise, it is set to 'queryByFieldRecord.field_uuid'.
   * @param {object} queryByRecord - The object representing 'queryByRecord' with a 'data_type' property.
   * @param {object} queryByFieldRecord - The object representing 'queryByFieldRecord' with a 'data_type' property.
   * @param {string} valueSearchField - The variable that will hold the resulting value.
   */
  if (queryByRecord.data_type === enumsControlDataFields.NUMERIC && queryByFieldRecord.data_type === enumsControlDataFields.RECORD) {
    valueSearchField = "";
  } else {
    valueSearchField = queryByFieldRecord.field_uuid;
  }

  /**
   * Defines a validation pattern for filtering records based on the 'queryByRecord' filter value.
   * @param {Object} queryByRecord - The filter configuration object.
   * @param {number} queryByRecord.filter - The filter value indicating the type of record filtering.
   *   - If `1`, it uses 'regexDateRecord' as the pattern.
   *   - Otherwise, it uses 'regexDataRecordEnds' as the pattern.
   * @returns {Object} patternsValidation - An object containing the validation pattern and error message.
   * @returns {RegExp} patternsValidation.value - The regular expression pattern to be used for validation.
   * @returns {string} patternsValidation.errorMessage - The error message to be displayed if validation fails.
   */
  if (queryByRecord.filter === enumsFilterFormsRequired.CONSULT_FOR_RECORD) {
    patternsValidation = {
      value: regexDateRecord,
      errorMessage: i18n.t("filterQuery.ErrorRecord1"),
    };
  } else {
    patternsValidation = {
      value: regexDataRecordEnds,
      errorMessage: i18n.t("filterQuery.ErrorRecord1"),
    };
  }

  /**
   * Sets the value of 'valuefilter' based on the provided query records.
   * This function checks if a field UUID is present in 'queryByFieldRecord' and sets 'valuefilter' accordingly.
   * If no field UUID is present, it falls back to using 'queryByRecord.filter'.
   * @param {Object} queryByFieldRecord - The query record containing a field UUID and filter.
   * @param {Object} queryByRecord - The query record containing a filter when no field UUID is present.
   * @param {string} valuefilter - The variable to store the selected filter value.
   * @returns {void}
   */
  if (queryByFieldRecord.field_uuid) {
    valuefilter = queryByFieldRecord.filter;
  } else {
    valuefilter = queryByRecord.filter;
  }

  /**
   * Generates a form element based on the provided query parameters and data types.
   * @param {object} queryByFieldRecord - The query parameters related to the field record.
   * @param {string} queryByFieldRecord.field_uuid - The UUID of the field.
   * @param {number} queryByFieldRecord.data_type - The data type associated with the field.
   * @param {number} queryByFieldRecord.data_control - The data control type for the field.
   * @param {number} queryByFieldRecord.filter - The filter value for the field.
   * @param {object} queryByRecord - The query parameters related to the record.
   * @param {number} queryByRecord.data_type - The data type associated with the record.
   * @param {string} queryByRecord.value - The value associated with the record.
   * @param {string} valuefilter - The value used for filtering.
   * @param {function} handleOnChangeFilter - The function to handle changes in the filter input.
   * @param {function} handleOnChangeValue - The function to handle changes in the value input.
   * @param {function} handleOnKeyPress - The function to handle keypress events.
   * @param {function} handleOnBlur - The function to handle blur events.
   * @param {object} regexDataNumber - Regular expression for validating numeric input.
   * @param {object} regexDataText - Regular expression for validating text input.
   * @param {string} concatenatedValues - Concatenated values for a specific field.
   * @param {string} startDate - The start date for date-related fields.
   * @param {boolean} isErrorDate - Indicates whether there is an error in the date input.
   * @param {boolean} isErrorDateTime - Indicates whether there is an error in the date-time input.
   * @returns {JSX.Element} The generated form element.
   */
  if (queryByFieldRecord.field_uuid === "" && queryByRecord.data_type === enumsControlDataFields.NUMERIC) {
    filterByRecord = (
      <AvField
        id="filter"
        name="filter"
        type="select"
        key="filter"
        onChange={handleOnChangeFilter}
        value={valuefilter}
      >
        <option key={"1A"} value={enumsFilterFormsRecords.EQUAL}>
          {i18n.t("filterQuery.Field2Option1")}
        </option>
        <option key={"4A"} value={enumsFilterFormsRecords.ENDS_IN}>
          {i18n.t("filterQuery.Field2Option2")}
        </option>
      </AvField>
    );
  } else if (
    queryByFieldRecord.field_uuid &&
    queryByFieldRecord.data_type === enumsFieldsTyData.TEXT_STRING &&
    queryByFieldRecord.data_control === enumsControlDataFields.LOOKUP
  ) {
    filterByRecord = (
      <AvField
        id="filter"
        name="filter"
        type="select"
        key="filter"
        onChange={handleOnChangeFilter}
        value={valuefilter}
      >
        <option key={"1A"} value={enumsFilterFormsRecords.EQUAL}>
          {i18n.t("filterQuery.Field2Option1")}
        </option>
        <option key={"2B"} value={enumsFilterFormsRecords.NOT_EQUAL}>
          {i18n.t("filterQuery.Field2Option3")}
        </option>
      </AvField>
    );
  } else if (
    queryByFieldRecord.field_uuid &&
    queryByFieldRecord.data_type === enumsFieldsTyData.TEXT_STRING &&
    queryByFieldRecord.data_control !== enumsControlDataFields.LOOKUP
  ) {
    filterByRecord = (
      <AvField
        id="filter"
        name="filter"
        type="select"
        key="filter"
        onChange={handleOnChangeFilter}
        value={valuefilter}
      >
        <option key={"1B"} value={enumsFilterFormsRecords.EQUAL}>
          {i18n.t("filterQuery.Field2Option1")}
        </option>
        <option key={"2B"} value={enumsFilterFormsRecords.NOT_EQUAL}>
          {i18n.t("filterQuery.Field2Option3")}
        </option>
        <option key={"3B"} value={enumsFilterFormsRecords.START_WITH}>
          {i18n.t("filterQuery.Field2Option8")}
        </option>
        <option key={"4B"} value={enumsFilterFormsRecords.ENDS_IN}>
          {i18n.t("filterQuery.Field2Option2")}
        </option>
        <option key={"5B"} value={enumsFilterFormsRecords.CONTAIN}>
          {i18n.t("filterQuery.Field2Option9")}
        </option>
      </AvField>
    );
  } else {
    filterByRecord = (
      <AvField
        id="filter"
        name="filter"
        type="select"
        key="filter"
        onChange={handleOnChangeFilter}
        value={valuefilter}
      >
        <option key={"1C"} value={enumsFilterFormsRecords.EQUAL}>
          {i18n.t("filterQuery.Field2Option1")}
        </option>
        <option key={"2C"} value={enumsFilterFormsRecords.NOT_EQUAL}>
          {i18n.t("filterQuery.Field2Option3")}
        </option>
        <option key={"3C"} value={enumsFilterFormsRecords.START_WITH}>
          {i18n.t("filterQuery.Field2Option4")}
        </option>
        <option key={"4C"} value={enumsFilterFormsRecords.ENDS_IN}>
          {i18n.t("filterQuery.Field2Option5")}
        </option>
        <option key={"5C"} value={enumsFilterFormsRecords.CONTAIN}>
          {i18n.t("filterQuery.Field2Option6")}
        </option>
        <option key={"6C"} value={enumsFilterFormsRecords.IS_LESS_THAN}>
          {i18n.t("filterQuery.Field2Option7")}
        </option>
      </AvField>
    );
  }

  if (queryByRecord.data_type === enumsControlDataFields.NUMERIC && queryByFieldRecord.data_type === enumsFieldsTyData.RECORD) {
    searchData = (
      <AvField
        id="value"
        name="value"
        type="text"
        key="value"
        autoComplete="off"
        validate={{
          required: {
            value: true,
            errorMessage: `${i18n.t("fieldRequired")}`,
          },
          minLength: {
            value: enumsFilterFormsRequired.MIN_LENGTH_VALUE,
          },
          maxLength: {
            value: enumsFilterFormsRequired.MAX_LENGTH_VALUE,
            errorMessage: `${i18n.t("form.field48")} `,
          },
          pattern: patternsValidation,
        }}
        onChange={handleOnChangeValue}
        onKeyPress={handleOnKeyPress}
        onBlur={handleOnBlur}
        value={queryByRecord.value}
      />
    );
  } else if (
    queryByFieldRecord.data_type === enumsFieldsTyData.INTEGER &&
    queryByFieldRecord.data_control === enumsControlDataFields.NUMERIC
  ) {
    searchData = (
      <AvField
        id="value"
        name="value"
        type="text"
        key="value"
        autoComplete="off"
        validate={{
          required: {
            value: true,
            errorMessage: `${i18n.t("fieldRequired")}`,
          },
          minLength: {
            value: enumsFilterFormsRequired.MIN_LENGTH_NUMERIC,
          },
          maxLength: {
            value: enumsFilterFormsRequired.MAX_LENGTH_NUMERIC,
            errorMessage: `${i18n.t("form.field48")} `,
          },
          pattern: {
            value: regexDataNumber,
            errorMessage: i18n.t("integration.requestTypeNum"),
          },
        }}
        onChange={handleOnChangeValue}
        onKeyPress={handleOnKeyPress}
        value={queryByFieldRecord.value}
      />
    );
  } else if (
    queryByFieldRecord.data_type === enumsFieldsTyData.TEXT_STRING &&
    queryByFieldRecord.data_control === enumsFieldsTypeControl.DROPDOWN_LIST &&
    queryByFieldRecord.filter === enumsFilterFormsRequired.CONSULT_FOR_RECORD
  ) {
    searchData = (
      <ComboBox
        id={queryByFieldRecordTemp.field_uuid}
        label={""}
        hide={false}
        required={true}
        isPreview={false}
        isFilterForm={true}
        itemLink={queryByFieldRecordTemp.item_link}
        handleOnChangeRecord={(eventSelect) => {
          setQueryByFieldRecord({
            ...queryByFieldRecord,
            value: eventSelect.target.value,
          });
          setQueryByFieldRecordTemp({
            ...queryByFieldRecordTemp,
            value: eventSelect.target.value,
          });
        }}
      />
    );
  } else if (
    queryByFieldRecord.data_type === enumsFieldsTyData.TEXT_STRING &&
    queryByFieldRecord.data_control === enumsFieldsTypeControl.LOOKUP
  ) {
    searchData = (
      <Lookup
        id={queryByFieldRecordTemp.field_uuid}
        label={""}
        hide={false}
        required={true}
        isPreview={false}
        isFilterForm={true}
        handleOnChangeRecord={(_, uuidRecordLookup) => {
          setQueryByFieldRecord({
            ...queryByFieldRecord,
            value: uuidRecordLookup,
          });
          setQueryByFieldRecordTemp({
            ...queryByFieldRecordTemp,
            uuid: uuidRecordLookup,
            value: concatenatedValues,
          });
        }}
        handleOnKeyPress={handleOnKeyPress}
        formLookupName={queryByFieldRecordTemp.form_lookup_name}
        formLookupFields={queryByFieldRecordTemp.form_lookup_fields}
        formUuid={queryByFieldRecordTemp.form_uuid}
      />
    );
  } else if (
    queryByFieldRecord.data_type === enumsFieldsTyData.DATE &&
    queryByFieldRecord.data_control === enumsFieldsTypeControl.DATA_PICKER
  ) {
    searchData = (
      <DatePicker
        id={queryByFieldRecordTemp.field_uuid}
        label={""}
        hide={false}
        required={true}
        isPreview={false}
        isFilterForm={true}
        startDateFilterForm={startDate}
        edit_manually={false}
        show_pass_dates={false}
        handleOnChangeRecord={(date) => {
          setQueryByFieldRecord({
            ...queryByFieldRecord,
            value: moment.utc(date).format("YYYY-MM-DD"),
          });
          setQueryByFieldRecordTemp({
            ...queryByFieldRecordTemp,
            value: moment.utc(date).format("DD/MM/YYYY"),
          });
          setStartDate(date);
          setIsErrorDate(false);
        }}
        validateRequiredPickers={isErrorDate}
        showExternalFormFillingProcess={false}
      />
    );
  } else if (
    queryByFieldRecord.data_type === enumsFieldsTyData.TIME_AND_DATE &&
    queryByFieldRecord.data_control === enumsFieldsTypeControl.TIME_AND_DATE_PICKER
  ) {
    searchData = (
      <DateTimePicker
        id={queryByFieldRecordTemp.field_uuid}
        label={""}
        hide={false}
        required={true}
        isPreview={false}
        isFilterForm={true}
        startDateFilterForm={startDate}
        edit_manually={false}
        show_pass_dates={false}
        handleOnChangeRecord={(date) => {
          setQueryByFieldRecord({
            ...queryByFieldRecord,
            value: moment.utc(date).format("YYYY-MM-DDTHH:mm:ss+00:00"),
          });
          setQueryByFieldRecordTemp({
            ...queryByFieldRecordTemp,
            value: moment.utc(date).format("YYYY-MM-DDTHH:mm:ss+00:00"),
          });
          setStartDate(date);
          setIsErrorDateTime(false);
        }}
        validateRequiredPickers={isErrorDateTime}
        showExternalFormFillingProcess={false}
      />
    );
  } else {
    searchData = (
      <AvField
        id="value"
        name="value"
        type="text"
        key="value"
        autoComplete="off"
        validate={{
          required: {
            value: true,
            errorMessage: `${i18n.t("fieldRequired")}`,
          },
          minLength: {
            value: enumsFilterFormsRequired.MIN_LENGTH_SEARCH_DATA,
          },
          maxLength: {
            value: enumsFilterFormsRequired.MAX_LENGTH_SEARCH_DATA,
            errorMessage: `${i18n.t("fieldValidateLengthBetween")} `,
          },
          pattern: {
            value: regexDataText,
            errorMessage: `${i18n.t("trd.Tooltip4")}`,
          },
        }}
        onChange={(eventValue) => {
          if (queryByFieldRecord.field_uuid) {
            setQueryByFieldRecord({
              ...queryByFieldRecord,
              value: eventValue.target.value.toLowerCase(),
            });
            setQueryByFieldRecordTemp({
              ...queryByFieldRecordTemp,
              value: eventValue.target.value.toLowerCase(),
            });
          } else {
            setQueryByRecord({
              ...queryByRecord,
              value: eventValue.target.value.toLowerCase(),
            });
          }
        }}
        onKeyPress={handleOnKeyPress}
        onBlur={(eventBlur) => {
          setQueryByFieldRecord({
            ...queryByFieldRecord,
            value: eventBlur.target.value.trim(),
          });
        }}
        value={queryByFieldRecord.value}
      />
    );
  }

  /**
   * useEffect hook to fetch fields by search criteria and initialize the component.
   * This hook is used to fetch fields based on a search query and initialize the component with the retrieved data.
   * @param {Function} getFieldsBySearch - A function that retrieves fields based on a search query.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(structureQueryByFilterTask.form_uuid) === false &&
      isNullOrUndefined(id) === true
    ) {
      getFieldsBySearch(structureQueryByFilterTask.form_uuid);
    } else if (isNullOrUndefined(id) === false) {
      getFieldsBySearch(id);
    }
  }, [structureQueryByFilterTask]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that updates query-related state based on query fields and a selected field UUID.
   * @param {Array} queryFieldsTemp - An array of query field data to filter and extract information from.
   * @param {string} queryByFieldRecord.field_uuid - The UUID of the selected field.
   * @returns {void}
   */
  useEffect(() => {
    let fieldData = queryFieldsTemp.filter(
      (fieldData) => fieldData.value === queryByFieldRecord.field_uuid
    );
    let itemLink = "";
    let label = "";
    let dataType = Number();
    let typeControl = Number();
    let formLookupName = "";
    let formLookupFields = "";
    let formUuid = "";

    fieldData.forEach((data) => {
      itemLink = data.itemLink;
      label = data.label;
      dataType = data.dataType;
      typeControl = data.typeControl;
      formLookupName = data.formLookupName;
      formLookupFields = data.formLookupFields;
      formUuid = data.formUuid;
    });
    setQueryByFieldRecord({
      ...queryByFieldRecord,
      data_type: dataType,
      data_control: typeControl,
    });
    setQueryByFieldRecordTemp({
      ...queryByFieldRecordTemp,
      data_type: dataType,
      data_control: typeControl,
      label: label,
      item_link: itemLink,
      form_lookup_name: formLookupName,
      form_lookup_fields: formLookupFields,
      form_uuid: formUuid,
    });
  }, [queryFieldsTemp, queryByFieldRecord.field_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React useEffect hook that performs operations on query filter objects.
   * @param {Array<Object>} queriesRec - An array of query filter objects for records.
   * @param {Array<Object>} queriesTable - An array of query filter objects for tables.
   * @returns {void}
   */
  useEffect(() => {
    if (queriesRec.search_filter.length > 0) {
      queriesRec.search_filter[0].operator = null;
    }

    if (queriesTable.length > 0) {
      queriesTable[0].operator = null;
    }
  }, [queriesRec, queriesTable]);

  /**
   * useEffect hook that updates the queries state when the 'queries' prop changes based on certain conditions.
   * @param {boolean} isFilter - Indicates whether filtering is enabled.
   * @param {boolean} isFilterForms - Indicates whether form filtering is enabled.
   * @param {object} queries - The object containing the search queries.
   * @param {function} setQueriesRec - State setter function for updating the queries state.
   * @returns {void}
   */
  useEffect(() => {
    if (isFilter === false && isFilterForms === true) {
      setQueriesRec({
        search_filter: queries,
      });
    }
  }, [queries]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect to set the query by field record when the dependency
   * 'structureQueryByFilterTask.form_uuid' changes.
   * @param {Function} setQueryByFieldRecord - A function to set the query by field record.
   * @param {Object} structureQueryByFilterTask - An object containing properties related to the structure query by filter task.
   * @param {string} structureQueryByFilterTask.form_uuid - The UUID of the form used for the structure query by filter task.
   */
  useEffect(() => {
    setQueryByFieldRecord({
      data_type: "",
      data_control: "",
      field_uuid: "",
      filter: 1,
      value: "",
      operator: "and",
    });
  }, [structureQueryByFilterTask.form_uuid]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders a loader component if the isLoading state is true.
   * @param {boolean} isLoading - Indicates whether loading is in progress.
   * @returns {JSX.Element|null} Loader component JSX if isLoading state is true, otherwise null.
   */
  if (isLoading === true && isAdvancedFilterTask === false) {
    return (
      <div>
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader className="primary" type="ball-pulse-rise" />
        </div>
      </div>
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ContainerFilterFormRender isAdvancedFilterTask={isAdvancedFilterTask}>
          {headerFilterForm}
          <AvForm onSubmit={handleOnSubmitSig}>
            <CardBody>
              <Row>
                <Col md="3">
                  <AvGroup>
                    <Label for="operator" className={cx("", {
                      "is-required": requiredFields === true,
                    })}>
                      {i18n.t("filterQuery.operator")}
                    </Label>

                    <AvField
                      id="operator"
                      name="operator"
                      type="select"
                      key="operator"
                      disabled={queriesTable.length === 0}
                      onChange={handleOnChangeOperator}
                      value={valueOperator}
                    >
                      <option key={"1D"} value={"and"}>
                        {i18n.t("filterQuery.operatorAnd")}
                      </option>
                      <option key={"2D"} value={"or"}>
                        {i18n.t("filterQuery.operatorOR")}
                      </option>
                    </AvField>
                  </AvGroup>
                </Col>

                <Col md="3">
                  <AvGroup>
                    <Label for="field_uuid" className={cx("", {
                      "is-required": requiredFields === true,
                    })}>
                      {i18n.t("filterQuery.Field1")}
                    </Label>

                    <AvField
                      id="field_uuid"
                      name="field_uuid"
                      type="select"
                      key="field_uuid"
                      disabled={isLoading}
                      onChange={handleOnChangeField}
                      value={valueSearchField}
                    >
                      <option key={"1E"} value={""}>
                        {i18n.t("filterQuery.Field1Option1")}
                      </option>
                      {queryFieldsTemp.map((field) => (
                        <option key={field.field_uuid} value={field.value}>
                          {capitalizeText(field.label)}
                        </option>
                      ))}
                    </AvField>
                  </AvGroup>
                </Col>

                <Col md="3">
                  <AvGroup>
                    <Label for="filter" className={cx("", {
                      "is-required": requiredFields === true,
                    })}>
                      {i18n.t("filterQuery.Field2")}
                    </Label>

                    {filterByRecord}
                  </AvGroup>
                </Col>
                <Col md="3">
                  <Label
                    className={cx("", {
                      "label-color is-required": requiredLabelDatePicker === true,
                      "is-required": requiredFields === true,
                    })}
                  >
                    {i18n.t("filterQuery.Field3")}
                  </Label>
                  {searchData}
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="d-block text-right">
              <Button
                type="submit"
                size="lg"
                disabled={disabledcardFooterAddButton}
                className="col-mt-3"
                color="cyan"
              >
                {isLoadingFilterForm}+{i18n.t("createRoles.MembersButton")}
              </Button>
            </CardFooter>
          </AvForm>
          {showTableFilterForm}
        </ContainerFilterFormRender>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FilterFormsRecords;