import i18n from "locales/i18n";

export const enumsTypeResponsible = {
  singleResponsible: "1",
  listOfResponsible: "2",
  groupResponsible: "3",
};

export const enumsTypeResponsibleNumber = {
  singleResponsible: 1,
  listOfResponsible: 2,
  groupResponsible: 3,
};

export const enumsTypeStatusUser = {
  ACTIVE: 1,
  LOCKED: 2,
  INACTIVE: 3,
  RETIRED: 4,
  LOCKED_BY_PASSWORD_EXPIRATION: 5,
};

export const enumsTask = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
  INTERRUPTED: 3,
};

export const enumsTaskType = {
  FREE_TASK: 1,
  PROCESS_TASK: 2,
  NO_PROCES_TASK: 0,
};

export const enumsTaskPriority = {
  HIGH: 1,
  MID: 2,
  LOW: 3,
};

export const enumsFilterForms = {
  OPERTATOR: i18n.t("filterQuery.operator"),
  RECORD: i18n.t("filterQuery.Field1"),
  FILTER: i18n.t("filterQuery.Field2"),
  DATA: i18n.t("filterQuery.Field3"),
};

export const enumsFormStatus = {
  FORM_STATUS_NULL: 0,
  FORMS_STATUS_ERASER: 1,
  FORMS_STATUS_ACTIVE: 2,
  FORMS_STATUS_INACTIVE: 3,
  FORM_STATUS_DELETE: 4,
};

export const enumsTaskComeFrom = {
  FREE_TASK: 1,
  PROCESS_TASK: 2,
  PROCESS_BATCH_TASK: 3,
  PROCESS_MANAGEMENT: 4,
};

export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
};

export const enumsSortedTask = {
  CONSECUTIVE: 1,
  RECORD_NUMBER: 2,
  CREATED_AT: 3,
  PROCESS_TYPE: 4,
  SENDER: 5,
  SUBJECT: 6,
  RECEPTION_DATE: 7,
  EXPIRATION_DATE: 8,
  TASKTYPE: 9,
};

export const enumsProcessTaskType = {
  NORMAL: 1,
  RETURN: 2,
  RETURN_ORIGIN: 3,
  REASSIGNMENT: 4,
  REASSIGNMENT_ORIGIN: 5,
};

export const enumsMinMaxCharacters = {
  GENERAL_MIN: 4,
  TRASH_MOTIVE_MAX: 150,
  GENERAL_MAX: 1000,
};

export const enumsSortedTypeTask = {
  ASCENDING: 1,
  DESCENDING: -1,
};

export const errorType = {
  ECONNABORTED: "ECONNABORTED",
};

export const timeout = {
  TWENTY_FIVE_SECONDS: 25000,
  ONE_HUNDRED_EIGHTY_SECONDS: 180000,
};

export const enumSizeSelect = {
  seeThreeItems: 120,
  seeFourItems: 150,
  seetTwoItems: 80,
};

export const enumsControlDataFields = {
  RECORD: 0,
  TEXTBOX: 1,
  TEXTAREA: 2,
  COMBOBOX: 3,
  LABEL: 4,
  NUMERIC: 5,
  DATEPICKER: 6,
  DATETIMEPICKER: 7,
  LOOKUP: 8,
};

export const enumsPermissionsLabel = {
  ADD_RECORD: "add_register",
  VIEW_RECORD: "view_register",
  UPLOAD_FILE: "upload_files",
  CREATE_EXPEDIENT: "create_expedient",
  SET_EXPEDIENT: "set_expedient_path",
  REMOVE_EXPEDIENT: "delete_expedient_path",
  VIEW_FILE: "view_files",
  ADD_NOTIFICATION: "notifications",
  CANCEL_RECORD: "annulment",
  START_WORKFLOW_PROCESS: "start_workflow",
  SEE_PROCESS: "see_process",
};

export const enumsPermissions = {
  ADD_RECORD: 1,
  VIEW_RECORD: 2,
  UPLOAD_FILE: 3,
  VIEW_FILE: 4,
  ADD_NOTIFICATION: 5,
  ADD_PROCESS: 6,
  VIEW_PROCESS: 7,
  CANCEL_RECORD: 8,
  EDIT_RECORDS: 9,
  EDIT_FILE_DATA: 10,
  MOVE_FILE: 11,
  MOVE_FILE_TO_TRASH: 12,
  SIGN_ELECTRONICALLY: 13,
  CREATE_EXPEDIENT: 14,
  SET_EXPEDIENT: 15,
  REMOVE_EXPEDIENT: 16,
};

export const enumsExpedietnStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
};

export const enumsCreateExpedient = {
  RECORDS: 1,
  TASK: 2,
  TRD_CONFIG: 3,
};

export const enumsDropzoneValues = {
  MIN_SIZE: 0.1,
  BYTE: 1048576,
  KILOBYTE: 1024,
  MULTIPLICATOR: 5242880,
  MIN_CHRACTERS_NAME_FILE: 1,
  MAX_CHARACTERS_NAME_FILE: 100,
  RECORDS_PROCESS_TASK_MAX_FILES: 3,
  PROCESS_BATCH_TASK_MAX_FILES: 1,
};

export const enumsDropLogo = {
  MIN_SIZE: 0.1,
  MAX_SIZE: 6144,
  MAX_WIDTH: 140,
  MAX_HEIGHT: 120,
};

export const enumsMaxMenuHeight = {
  MAX_MENU_HEIGHT: 120,
  MAX_MENU_HEIGHT_BASIC: 80,
};

export const enumsCreateUserForm = {
  MIN_LENGTH_USER_NAME: 5,
  MAX_LENGTH_USER_NAME: 100,
  MIN_LENGTH_EMAIL: 5,
  MAX_LENGTH_EMAIL: 100,
  MIN_LENGTH_DOCUMENT: 5,
  MAX_LENGTH_DOCUMENT: 20,
  MIN_LENGTH_FIRST_NAME: 2,
  MAX_LENGTH_FIRST_NAME: 50,
  MIN_LENGTH_LAST_NAME: 2,
  MAX_LENGTH_LAST_NAME: 50,
  MIN_LENGTH_PHONE: 5,
  MAX_LENGTH_PHONE: 20,
};

export const enumsCreateUserFormRoles = {
  MIN_LENGTH_NAME: 4,
  MAX_LENGTH_NAME: 70,
};
export const enumsRequestStatusSignature = {
  ACTIVE: 1,
  IN_PROCESS: 2,
  IN_MANAGEMENT: 3,
  DECLINED: 4,
  APPROVED: 5,
};

export const enumsRequestIntegrationForm = {
  CONTACT_NAME_MIN_LENGTH: 2,
  CONTACT_NAME_MAX_LENGTH: 50,
  CONTACT_EMAIL_MIN_LENGTH: 4,
  CONTACT_EMAIL_MAX_LENGTH: 100,
  CONTACT_PHONE_MIN_LENGTH: 4,
  CONTACT_PHONE_MAX_LENGTH: 11,
  DESCRIPTION_MIN_LENGTH: 4,
  DESCRIPTION_MAX_LENGTH: 160,
};
export const enumsRecoveryPassword = {
  MAX_LENGTH: 100,
};

export const enumsPasswordExpiration = {
  MAX_LENGTH: 50,
};

export const enumsPasswordReset = {
  MAX_LENGTH: 50,
};

export const enumsChangePassword = {
  MAX_LENGTH: 50,
};

export const enumsMaxFieldsSelectedSticker = {
  MAX_FIELDS_SELECTED: 4,
};

export const enumsMaxMinCharactersFormsDesigner = {
  MIN_CHRACTERS_NAME: 5,
  MAX_CHARACTERS_NAME: 250,
  MIN_CHRACTERS_DESCRIPTION: 4,
  MAX_CHARACTERS_DESCRIPTION: 500,
};

export const enumsMaxMinFieldsCoordsSticker = {
  MAX_LENGTH: 4,
  MIN_LENGTH: 1,
};

export const enumsWorkflowStatus = {
  ERASER: 1,
  ACTIVE: 2,
  INACTIVE: 3,
};

export const enumsProcessManagementFilterApply = {
  RESPONSIBLE: i18n.t("createWorkflow.config.responsible"),
  PROCESS_NAME: i18n.t("processName"),
  PROCESS_NUMBER: i18n.t("createWorflow.Label3"),
  PROCESS_STEP: i18n.t("taskFilter.label12"),
  START_DATE: i18n.t("processManagement.StartDate"),
  END_DATE: i18n.t("processManagement.EndDate"),
};

export const enumsDesignerWorkflow = {
  MIN_LENGTH_NAME: 4,
  MAX_LENGTH_NAME: 100,
  MIN_LENGTH_DESCRIPTION: 4,
  MAX_LENGTH_DESCRIPTION: 150,
};

export const enumShowOptionsTabWorkflow = {
  CONFIG_RESPONSIBLE: "1",
  CONFIG_ANOTHER_OPTIONS: "2",
  CONFIG_FORM_DATA: "3",
};

export const enumsResponsibleOptions = {
  ONLY_RESPONSIBLE: "1",
  ONLY_RESPONSIBLE_LIST: "2",
  GROUP_MEMBERS: "3",
};

export const enumsFilterWorkflowState = {
  ALL: "",
  IN_PROGRESS: 1,
  FINISHED: 2,
  INTERRUPTED: 3,
  ALL_WORKFLOWS: "",
  STATUS_ERASER: 1,
  STATUS_ACTIVE: 2,
  STATUS_INACTIVE: 3,
};

export const enumsBpmnView = {
  STATUS_ERASER: 1,
  STATUS_ACTIVE: 2,
  STATUS_INACTIVE: 3,
};

export const enumsCustomRenderer = {
  HIGH_PRIORITY: 0,
  TASK_BORDER_RADIUS: 0,
  COLOR_GREEN: "#52B415",
  COLOR_YELLOW: "#ffc800",
  COLOR_RED: "#cc0000",
};


export const enumsPermissionsAction = {
  ALL_PERMISIONS: 0,
}

export const WorkFlowConfigStepsTab3FormDatas = {
  FILL_FIELDS_FILING_FORM: "1",
  FILL_OUT_FORM_ONE_STEP: "2",
}

export const enumsFieldFormStatus = {
  ACTIVE: 1,
}

export const enumsFields = {
  ACTIVE: 1,
  DATE_PICKER: "3,6",
  DATE_TIME_PICKER: "4,7"
}

export const enumsNumericTextBox = {
  MAX_LENGTH: 999999999999999999,
}

export const enumsListStatus = {
  ACTIVE: 1,
}
export const enumsFormStatusDelete = {
  FORMS_STATUS_ERASER: "1",
  FORMS_STATUS_ACTIVE: "2",
  FORMS_STATUS_INACTIVE: "3",
};

export const enumsConsultConfigurationFields = {
  MIN_LENGTH_LABEL: 1,
  MAX_LENGTH_LABEL: 100,
  MIN_TRD: 1,
};

export const enumsFieldsTyData = {
  RECORD: 0,
  TEXT_STRING: 1,
  INTEGER: 2,
  DATE: 3,
  TIME_AND_DATE: 4,
};

export const enumsFieldsTypeControl = {
  TEXT_BOX: 1,
  TEXT_AREA: 2,
  DROPDOWN_LIST: 3,
  LABEL: 4,
  NUMERIC_TEXBOX: 5,
  DATA_PICKER: 6,
  TIME_AND_DATE_PICKER: 7,
  LOOKUP: 8,
};

export const enumFieldForm = {
  MIN_LENGTH_LABEL: 1,
  MAX_LENGTH_LABEL: 100,
};

export const enumsButtonCreateField = {
  STATUS_ERASER: 1,
};

export const enumsFieldStatus = {
  ACTIVE: 1,
};

export const enumsFieldsOrder = {
  INITIAL_FIRST_ORDER: 1,
};

export const enumsActionsTypes = {
  ALL_ACTIONS: 0,
  ACTIONS_FOR_TYPE_USER: 1,
  RESTRIC_USER: 2,
  ACTIONS_RESTRIC_TYPE_USER: 1,
};

export const enumsFormsStatus = {
  ERASER: 1,
  ACTIVE: 2,
  INACTIVE: 3,
};
export const enumsCreateList = {
  MIN_LENGTH_NAME_LIST: 2,
  MAX_LENGTH_NAME_LIST: 100,
};

export const enumsListForm = {
  LIST_ACTIVE: 1,
  LIST_INACTIVE: 2,
};

export const enumsUserRolesConfig = {
  ADD_FORMS: "1",
  CANCEL_VIEW_FORM: "0",
  HAS_INTEGRATION: "1",
};

export const enumsParameterRolesConfig = {
  CONFIG_TRD: 1,
  DESING_LIST: 2,
  ADMIN_USERS: 3,
  CONFIG_ROLES: 4,
  WORK_GROUPS: 5,
};

export const enumsRolesAcordeon = {
  CONSULTATION_FILLING: 0,
  CONFIG_PAREMETER: 1,
  DESIGNER_FORMS: 2,
  DESIGNER_WORKFLOW: 3,
};

export const enumsInactiveUserWorkGroup = {
  INACTIVE_USER: 3,
  RETIRED_USER: 4,
};

export const enumsCreateUserWorkgroup = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 50,
  STATUS_ERASER: 1,
  STATUS_ACTIVE: 2,
  STATUS_INACTIVE: 3,
};

export const enumsRolesForm = {
  VIEW_CONFIG: "2",
  EDIT: "3",
}

export const enumLevelTrd = {
  BACKGROUND: 0,
  SECTION: 1,
  SUBSECTION: 2,
  SERIE: 3,
  SUBSERIE: 4,
  EXPEDIENT: 5,
  SUBDIVISION: 6,
  DIRECTORY: 7,
  SUBDIRECTORY: 8,
};

export const enumsTrdForm = {
  MIN_LENGTH_CODE: 1,
  MAX_LENGTH_CODE: 500,
  MIN_LENGTH_NAME: 1,
  MAX_LENGTH_NAME: 500,
};

export const enumsCreateExpedientForm = {
  MIN_LENGTH_NAME: 4,
  MAX_LENGTH_NAME: 100,
  MIN_LENGTH_SUBJECT: 4,
  MAX_LENGTH_SUBJECT: 100,
};

export const enumCreateSubDivisionForm = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 100,
};

export const enumCreateSectionForm = {
  MIN_LENGTH_CODE: 1,
  MAX_LENGTH_CODE: 100,
  MIN_LENGTH_NAME: 1,
  MAX_LENGTH_NAME: 500,
};

export const enumEditSerieForm = {
  MIN_LENGTH_CODE: 1,
  MAX_LENGTH_CODE: 50,
  MIN_LENGTH_NAME: 1,
  MAX_LENGTH_NAME: 100,
};

export const enumEditDirectory = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 100,
};

export const enumSubDivisionForm = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 100,
};

export const enumEditSubSectionForm = {
  MIN_LENGTH_CODE: 1,
  MAX_LENGTH_CODE: 50,
  MIN_LENGTH_NAME: 1,
  MAX_LENGTH_NAME: 100,
};

export const enumsExtraLevelTrd = {
  SECTION_FORM: 9,
  SUB_SECTION_FORM: 10,
  EDIT_SERIE_FORM: 11,
  SERIE_FORM: 12,
  EDIT_BACKGROUND: 14,
  CREATE_SECTION_FORM: 15,
};

export const enumsOrdenalDocumentalUnit = {
  CREATE_DIRECTORY: "1",
  EDIT_DIRECORY_FORM: "2",
  CREATE_SUB_DIRECTORY: "3",
};

export const enumsDirectoryOptions = {
  SELECT_OPTION_EMPTY: "1",
  EDIT_DIRECORY_FORM: "2",
  DELETE_DIRECTORY: "3",
};

export const enumsSelectActionsDirectory = {
  SELECT_SUB_DIRECTORY: "1",
  CREATE_DIRECTORY: "2",
};

export const enumsSelectActionsDirectorySelect = {
  SELECT_OPTION_EMPTY: "",
  SELECT_EDIT_SUB_DIRECTORY: "1",
  SELECT_DELETE_SUB_DIRECTORY: "2",
};

export const enumsStatusExpedient = {
  OPEN: 1,
};

export const enumsTargetSelectLevel = {
  EDIT_SECTION: "9",
  EDIT_SECTION_FORM: "10",
  ORDENAL_DOCUMENTAL_UNIT_CREATE: "13",
  DELETE_ORDENAL_DOCUMENTAL: "16",
};

export const enumSelectLevelOptions = {
  CREATE_SUBSECCION: "2",
  CREATE_SERIE_SELECT: "3",
  EDIT_SUB_SECTION: "10",
  CREATE_SECTION: "15",
  DELETE_SUB_SECTION: "16",
  CREATE_SERIE: "3",
  EDIT_SUBDIRECTORY: "9",
  EDIT_BACKGROUND: "14",
  DELETE_SECTION: "16",
  CREATE_EXPEDIENT_FORM: "13",
  CREATE_SUB_SERIE: "4",
  EDIT_SERIE: "11",
  DELETE_SERIE: "16",
  CREATE_SUB_SERIE_OPTION: "4",
  EDIT_SERIE_OPTION: "11",
  DELETE_SERIE_OPTION: "16",
  CREATE_EXPEDIENT_OPTION: "13",
  EDIT_SUB_SERIES: "12",
  DELETE_SUB_SERIES: "16",
};

export const enumsFilterTrashdates = {
  TODAY: 1,
  LAST_SEVEN_DAYS: 2,
  ALL: 3,
};

export const enumsRestoreFilesCodes = {
  RESTORE_TRASH_FILES_OK: 4024,
  FILES_NOT_RESTORED: 4025,
  FILES_DELETED_OK: 4026,
};

export const enumsMaxWidthSelectFile = {
  MAX_WIDTH: 100,
};

export const enumsButtonCreateFund = {
  MIN_LENGT: 1,
  MAX_LENGTH: 500,
};

export const enumSectionForm = {
  MIN_LENGT: 1,
  MAX_LENGTH: 50,
  MIN_LENGTH_NAME: 1,
  MAX_LENGTH_NAME: 100,
};

export const enumsFileLogAction = {
  UPLOAD_FILES: 1,
  GET_FILES: 2,
  MOVE_FILE_LOCATION: 3,
  UPDATE_FILE_DATA: 4,
  ELECTRONIC_SIGNATURE_REQUEST: 5,
  MOVE_FILE_TO_TRASH: 6,
  RESTORE_FILE_TRASH: 7,
  DELETE_FILE_OF_TRASH: 8,
};

export const enumsModules = {
  FILES: 4,
};

export const recordStatus = {
  NORMAL: "",
  ACTIVE: 1,
  ANULLMENT: 2,
};

export const enumCancelFiled = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 150
};

export const enumsSteps = {
  SECOND_STEP: 2,
};

export const enumsOptionsFiles = {
  LAST_FILE: "1",
  LAST_TWO: "2",
  LAST_THREE: "3",
  LAST_FOUR: "4",
  ALL_FILES: "5",
  UNLINK_FILES: "6",
};

export const enumsPriorityNotifications = {
  PRIORITY_HIGH: "1",
  PRIORITY_LOW: "2",
  PRIORITY_NORMAL: "3",
};

export const enumsNotification = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 100,
};

export const enumsStatusRecord = {
  ACTIVE: 1,
  CANCELED: 2,
}

export const enumsStatusUser = {
  QUERY: "1",
  STANDARD: "2",
  ADMIN: "3",
};

export const enumsTypeUser = {
  QUERY: "1",
  STANDARD: "2",
  ADMIN: "3",
};

export const enumsActions = {
  ALL_PERMISSIONS: 0,
  RESTRIC_FOR_TYPE_USER: 1,
  NOT_ALLOWED: 2,
};

export const  enumsStatusProcess = {
  IN_PROGRESS: 1,
  FINISHED: 2,
  INTERRUPTED: 3,
};

export const enumsRolesPermissions = {
  TRD_CONSFIGURATION: 1,
  LIST_DESIGNER: 2,
  USER_MANAGER: 3,
  ROLE_MANAGER: 4,
  WORK_GROUP_MANAGER: 5,
};

export const enumsExportRecords = {
  ALL: "",
  IN_PROGRESS: 1,
  FINISHED: 2,
  FAILED: 3,
}

export const enumsFilterFormsRecords = {
  EQUAL: 1,
  NOT_EQUAL: 2,
  START_WITH: 3,
  ENDS_IN: 4,
  CONTAIN: 5,
  IS_LESS_THAN: 6,
};

export const enumsFilterFormsRequired = {
  CONSULT_FOR_RECORD: 1,
  MIN_LENGTH_VALUE: 1,
  MAX_LENGTH_VALUE: 30,
  MIN_LENGTH_NUMERIC: 1,
  MAX_LENGTH_NUMERIC: 18,
  MIN_LENGTH_SEARCH_DATA: 1,
  MAX_LENGTH_SEARCH_DATA: 20,
};

export const enumsRecordDetailView = {
  ACTIVE_COLUMN: 1,
};

export const enumsSupportTypes = {
  FHYSICAL: 1,
  ELECTRONIC: 2,
  GENERIC: 3,
};

export const enumsEditFile = {
  MIN_LENGTH_SUBJECT: 4,
  MAX_LENGTH_SUBJECT: 100,
};

export const enumsRecordUploadByExpedientTable = {
  IN_PROGRESS: 1,
  FINISHED: 2,
}; 

export const enumsFilterState = {
  ALL: "",
  ACTIVE: 1,
  INACTIVE: 2,
};

export const enumsRecordExpedientList = {
  EXPEDIENT_OPEN_STATUS: 1,
  EXPEDIENT_CLOSED_STATUS: 2,
};

export const enumsWorkflowDeatils = {
  STATUS_RECORD_INACTIVE: 2,
};