import i18n from "locales/i18n";

export const handleTypes = (type) => {
  switch (type) {
    case 0:
      return {
        create: i18n.t("trd.formTittle2"),
        name_element: i18n.t("trd.inputTittle2"),
      };
    case 1:
      return {
        create: i18n.t("trd.formTittle2"),
        name_element: i18n.t("trd.inputTittle2"),
      };
    case 2:
      return {
        create: i18n.t("trd.formTittle3"),
        name_element: i18n.t("trd.inputTittle3"),
      };
    case 3:
      return {
        create: i18n.t("trd.formTittle4"),
        name_element: i18n.t("trd.inputTittle4"),
      };
    case 4:
      return {
        create: i18n.t("trd.formTittle5"),
        name_element: i18n.t("trd.inputTittle5"),
      };
    case 5:
      // Expediente
      return {
        isExpedient: true,
      };
    case 6:
      // Subdivision
      return {
        isSubDivison: true,
      };
    case 7:
      // Directory
      return {
        isDirectory: true,
      };
    case 8:
      // EditDirectory
      return {
        isEditDirectory: true,
      };
    case 9:
      //EditSection
      return {
        isEditSection: true,
      };
    case 10:
      //EditSubSection
      return {
        isEditSubSection: true,
      };
    case 11:
      //EditSerie
      return {
        isEditSerie: true,
      };
    case 12:
      //EditSubSerie
      return {
        isEditSubSerie: true,
      };
    case 13:
      //CreateExpediente
      return {
        createExpedient: true,
      };

    case 14:
      // EdiFondo
      return {
        isEditBackground: true,
      };

    case 15:
      // CreateSection
      return {
        isCreateSection: true,
      };

    case 16:
      // deleteLevel
      return {
        isDeleteLevelIntrd: true,
      };

    default:
      return {
        create: "",
        name_element: "",
      };
  }
};
