import React, { Fragment, useEffect } from "react";
import Tree from "@naisutech/react-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody } from "reactstrap";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useTrdContext } from "contextAPI/TrdContext";
import i18n from "locales/i18n";

function TrdOrdinationUnitTree({ backgroundId, id }) {
  const {
    isLoading,
    setLevelFolder,
    setShowFormConditional,
    setTrdForm,
    setShowCreateExpedientForm,
    foldersId,
    setFoldersId,
    setParentData,
    setShowCreateSubDivisonForm,
    setShowCreateDirectoryForm,
    setCurrentLevelSelected,
    expedientName,
    setShowRightPanelTreeExpedients,
    setFolderName,
    setOption,
    setOptionEdit,
    setEditDirectory,
    setOptionDir,
    setShowEditSectionForm,
    recordShowExpTree,
    getSeriesSubseriesStructure,
    structureTrdSeriesSubseries,
  } = useTrdContext();

  useEffect(() => {
    getSeriesSubseriesStructure(backgroundId, id);
  }, [getSeriesSubseriesStructure]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateLevel = (element) => {
    const { id, level, label } = element;
    setFolderName(label);

    switch (level) {
      case 5:
        setFoldersId({
          ...foldersId,
          expedientId: id,
        });
        setParentData({ id: id, level: level - 1 });
        setShowCreateExpedientForm(true);
        break;
      case 6:
        setFoldersId({
          ...foldersId,
          subDivisionId: id,
        });
        setShowCreateSubDivisonForm(true);
        setShowCreateExpedientForm(false);
        setShowCreateDirectoryForm(false);
        setShowEditSectionForm(false);
        setParentData({ id: id, level: level - 1 });
        break;
      case 7:
        setFoldersId({
          ...foldersId,
          subDirectoryId: id,
        });
        setShowCreateDirectoryForm(true);
        setShowCreateExpedientForm(false);
        setShowCreateSubDivisonForm(false);
        setShowEditSectionForm(false);
        setParentData({ id: id, level: level - 1 });
        break;
      default:
        setShowCreateExpedientForm(false);
        setShowFormConditional(false);
        break;
    }
  };

  const validateLevelByComponent = (element) => {
    const { level } = element;
    switch (level) {
      case 5:
        setLevelFolder(6);
        setShowCreateExpedientForm(false);
        setShowCreateDirectoryForm(false);
        setShowEditSectionForm(false);
        break;
      case 6:
        setLevelFolder(7);
        setShowCreateExpedientForm(false);
        setShowCreateSubDivisonForm(false);
        setShowCreateDirectoryForm(true);
        setShowEditSectionForm(false);
        break;
      case 7:
        setLevelFolder(8);
        setShowCreateDirectoryForm(false);
        setShowCreateExpedientForm(false);
        setShowCreateSubDivisonForm(false);
        setShowRightPanelTreeExpedients(!recordShowExpTree ? true : false);
        setShowEditSectionForm(false);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Button block className="mb-2 mr-2 btn-icon" color="cyan">
        <i className="lnr-apartment btn-icon-wrapper"> </i>
        {expedientName}
      </Button>

      {(() => {
        if (structureTrdSeriesSubseries.length === 0) {
          return (
            <Card>
              <CardBody className="text-center">
                {i18n.t("trd.expedient.noData")}
              </CardBody>
            </Card>
          );
        } else {
          return (
            <Tree
              nodes={structureTrdSeriesSubseries}
              size="full"
              theme={"light"}
              isLoading={isLoading}
              onSelect={(info) => {
                setLevelFolder(info.level);
                setCurrentLevelSelected(info.level);
                setTrdForm({ name: "", level: info.level, code: info.code });
                setShowRightPanelTreeExpedients(
                  !recordShowExpTree ? true : false
                );
                validateLevel(info);
                validateLevelByComponent(info);
                setOption(!recordShowExpTree ? true : false);
                setOptionEdit(false);
                setOptionDir(!recordShowExpTree ? true : false);
                setEditDirectory(false);
              }}
              iconSet={{
                node: (
                  <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    color="#fd7e14"
                  />
                ),
                file: (
                  <FontAwesomeIcon
                    icon={faArrowAltCircleRight}
                    color="#fd7e14"
                  />
                ),
              }}
            />
          );
        }
      })()}
    </Fragment>
  );
}

export default TrdOrdinationUnitTree;
