/**
 * Sorts an array of objects based on a specified property name in a case-insensitive manner.
 * @param {Array} list - The array to be sorted.
 * @param {string} propertyName - The property name to use for sorting.
 * @returns {Array} The sorted array.
 */

export const sortByPropertyName = (list, propertyName) => {
  return list.sort((firstItem, secondItem) => {
    const firstProperty = `${firstItem[propertyName]}`.toLowerCase();
    const secondProperty = `${secondItem[propertyName]}`.toLowerCase();
    return firstProperty.localeCompare(secondProperty);
  });
};

/**
 * Sorts the users list by their full names in descending order and sets the dynamic users state.
 * @param {Array} usersList - The list of users to be sorted and processed.
 * @param {Function} setUsersDinamic - The state setter function for dynamic users.
 */
export const processSortAndSetUsers = (usersList, setUsersDinamic) => {
  usersList
    .sort((positionNameA, positionNameB) => {
      const nombresA = `${positionNameA.name} ${positionNameA.last_name}`.toLocaleLowerCase();
      const nombresB = `${positionNameB.name} ${positionNameB.last_name}`.toLocaleLowerCase();
      return nombresB.localeCompare(nombresA);
    })
    .forEach((user) => {
      setUsersDinamic((prevState) => [
        ...prevState,
        {
          value: user.uuid,
          label: `${user.first_name} ${user.last_name} (${user.user_name})`,
        },
      ]);
    });
};
