import React from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";
import { regexTextArea } from "utils/regexExpressions";
import cx from "classnames";
import i18n from "locales/i18n";

const TextArea = (props) => {
  const {
    id,
    label,
    hide,
    required,
    isPreview,
    minLength,
    maxLength,
    handleOnChangeRecord,
    handleOnKeyPress,
    value,
  } = props;

  let hideLabelTextArea = null;

  /**
   * Conditionally generates a JSX element to hide or show a labeled textarea.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled textarea.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled textarea with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelTextArea = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelTextArea = null;
  }

  if (isPreview === true) {
    return (
      <AvForm row>
        <AvGroup row>
          <Label className={cx("", {
            "is-required": required === true
          })} sm={3}>
            {label} {hideLabelTextArea}
          </Label>

          <Col md={8}>
            <AvField
              id={id}
              name={id}
              type="textarea"
              key={id}
              validate={{
                required: {
                  value: required,
                  errorMessage: `${i18n.t("fieldRequired")}`,
                },
                pattern: {
                  value: regexTextArea,
                  errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
                },
                minLength: {
                  value: minLength,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                    "characters"
                  )}`,
                },
                maxLength: {
                  value: maxLength,
                  errorMessage: `${i18n.t(
                    "fieldValidateLengthBetween"
                  )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                    "characters"
                  )}`,
                },
              }}
              onChange={(e) => handleOnChangeRecord(id, e.target.value)}
              onKeyPress={handleOnKeyPress}
              value={value}
            />
          </Col>
        </AvGroup>
      </AvForm>
    );
  } else if (hide === false) {
    return (
      <AvGroup row>
        <Label className={cx("", {
          "is-required": required === true
        })} sm={3}>
          {label}
        </Label>
        <Col md={8}>
          <AvField
            id={id}
            name={id}
            type="textarea"
            key={id}
            validate={{
              required: {
                value: required,
                errorMessage: `${i18n.t("fieldRequired")}`,
              },
              pattern: {
                value: regexTextArea,
                errorMessage: `${i18n.t("createRoles.InputInvalid")}`,
              },
              minLength: {
                value: minLength,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                  "characters"
                )}`,
              },
              maxLength: {
                value: maxLength,
                errorMessage: `${i18n.t(
                  "fieldValidateLengthBetween"
                )} ${minLength} ${i18n.t("and")} ${maxLength} ${i18n.t(
                  "characters"
                )}`,
              },
            }}
            onChange={(e) => handleOnChangeRecord(id, e.target.value)}
            onKeyPress={handleOnKeyPress}
            value={value}
          />
        </Col>
      </AvGroup>
    );
  } else {
    return null;
  }
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  minLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  handleOnChangeRecord: PropTypes.func.isRequired,
  handleOnKeyPress: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TextArea;
