import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Col, Alert, Row } from "reactstrap";
import ButtonResponsible from "components/atoms/ButtonResponsibleUser";
import { enumsResponsibleOptions, enumsTypeResponsibleNumber, enumsWorkflowStatus } from "utils/enums";
import {
  faInfoCircle,
  faPen,
  faTrash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingComponent from "components/atoms/LoadingComponent";
import ModalProcessResponsibles from "components/molecules/ModalProcessResponsibles";
import ModalResponsibleForGroup from "components/molecules/ModalResponsibleForGroup";
import i18n from "locales/i18n";

const WorkFlowConfigStepsTab1Responsible = (props) => {
  const {
    objectProcessInfo,
    workflowStatus,
    nameGroup,
    handleOnClickEditWorkGroup,
    disabledOptionByStatusProcess,
    handleOnDeleteResponsible,
    showModal,
    setShowModal,
    handleOnClose,
    id,
    workflowid,
    userStep,
    userActive,
    usersDinamicEdit,
    usersDinamic,
    typeResponsibleGroup,
    typeResponsibleUser,
    responsibleList,
    responsibleOption,
    responsibleOptionDisabled,
    selectedOption,
    showModalGroup,
    setShowModalGroup,
    listReal,
    handleOnClick,
    handleOnChangeOption,
    loader,
    isError,
    setIsError,
    setIsLoading,
    isLoading,
  } = props;
  let alertStatusWorkflowWrong = null;
  let disabled = false;
  let requiredStep = null;
  let renderSelectedResonsibleOptions = null;

  /**
   * Determines if a component should be disabled based on specific conditions.
   * @param {Array} userStep - An array representing user steps or actions.
   * @param {Array} listReal - An array containing real data.
   * @param {boolean} typeResponsibleGroup - A flag indicating whether the type is responsible group.
   * @returns {boolean} - A boolean value indicating whether the component should be disabled.
   */
  if (
    userStep.length > 0 ||
    listReal.length > 0 ||
    typeResponsibleGroup === true
  ) {
    disabled = true;
  }

  /**
   * Updates the 'requiredStep' variable based on the provided conditions.
   * @param {number} userStep - The length of the 'userStep' array.
   * @param {number} listReal - The length of the 'listReal' array.
   * @param {string} nameGroup - The name of a group.
   */
  if (userStep.length > 0 || listReal.length > 0 || nameGroup !== "") {
    requiredStep = false;
  } else {
    requiredStep = true;
  }

  /**
   * Conditional Rendering of Workflow Status Alert
   * This code conditionally renders an alert component if the workflowStatus is not equal to 1.
   * The alert informs the user about an invalid workflow status.
   * @param {number} workflowStatus - The status of the workflow, where 1 indicates a valid status.
   * @param {string} i18n - An internationalization function for translating text.
   * @returns {JSX.Element|null} The JSX element representing the alert, or null if the workflowStatus is 1.
   */
  if (workflowStatus !== enumsWorkflowStatus.ERASER) {
    alertStatusWorkflowWrong = (
      <Alert className="mbg-3 ext-primary font-weight-bold" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        {i18n.t("WorkFlowEdit.Invalid")}
      </Alert>
    );
  }

  /**
   * Conditional rendering of a loading component.
   * If the 'loader' prop is set to 'true', this function returns a 'LoadingComponent'.
   * @param {boolean} loader - A boolean value indicating whether to display the loading component.
   * @returns {JSX.Element|null} The 'LoadingComponent' if 'loader' is true, otherwise null.
   */
  if (loader === true) {
    return <LoadingComponent />;
  }

  /**
   * Renders different responsible options based on the user step and type of responsible user or group.
   * @param {Array} userStep - The current steps assigned to the user.
   * @param {number} typeResponsibleUser - The type of responsible user (single or list).
   * @param {boolean} typeResponsibleGroup - Flag to indicate if it is a responsible group.
   * @param {boolean} disabledOptionByStatusProcess - Flag to disable options based on the process status.
   * @param {function} handleOnClick - Function to handle click events.
   * @param {function} handleOnDeleteResponsible - Function to handle deletion of a responsible user.
   * @param {boolean} showModal - Flag to show or hide the modal.
   * @param {function} setShowModal - Function to set the state of showModal.
   * @param {function} handleOnClose - Function to handle closing of the modal.
   * @param {Array} usersDinamicEdit - List of dynamic users for editing.
   * @param {Array} usersDinamic - List of dynamic users.
   * @param {string} selectedOption - The selected option.
   * @param {boolean} isError - Flag to indicate if there is an error.
   * @param {function} setIsError - Function to set the state of isError.
   * @param {boolean} isLoading - Flag to indicate if loading is in progress.
   * @param {function} setIsLoading - Function to set the state of isLoading.
   * @param {string} workflowid - The ID of the workflow.
   * @param {string} id - The ID of the workflow step.
   * @param {string} nameGroup - The name of the group.
   * @param {function} handleOnClickEditWorkGroup - Function to handle click event for editing a work group.
   * @param {Array} responsibleList - The list of responsible users.
   * @param {Array} listReal - The real list of responsible users.
   * @param {function} setShowModalGroup - Function to set the state of showModalGroup.
   * @param {boolean} showModalGroup - Flag to show or hide the group modal.
   * @param {JSX.Element} alertStatusWorkflowWrong - JSX element to display an alert for wrong workflow status.
   * @param {Object} enumsTypeResponsibleNumber - Enum for responsible number types.
   * @param {Object} enumsResponsibleOptions - Enum for responsible options.
   * @param {Object} i18n - Internationalization object for translations.
   * @param {Object} faInfoCircle - FontAwesome icon for info circle.
   * @param {Object} faPen - FontAwesome icon for pen.
   * @param {Object} faTrash - FontAwesome icon for trash.
   * @returns {JSX.Element|null} The rendered component based on the responsible type or null.
   */
  if (
    userStep.length !== 0 &&
    typeResponsibleUser ===
    enumsTypeResponsibleNumber.singleResponsible
  ) {
    renderSelectedResonsibleOptions = (
      <div>
        {alertStatusWorkflowWrong}
        <Alert
          className="mbg-3 ext-primary text-capitalize font-weight-bold"
          color="info"
        >
          <Row>
            <Col md={8}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              {i18n.t(
                "createWorkflow.config.stepsResponsibleLegend"
              )}
              : {userActive.label}
            </Col>
            <Col md={4} className="row justify-content-end">
              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                disabled={disabledOptionByStatusProcess}
                onClick={handleOnClick}
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("buttonActions.option2")}
              </Button>
              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                disabled={disabledOptionByStatusProcess}
                onClick={handleOnDeleteResponsible}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                {i18n.t("buttonActions.option3")}
              </Button>
              <ModalProcessResponsibles
                showModal={showModal}
                handleOnClose={handleOnClose}
                usersDinamicEdit={usersDinamicEdit}
                selectedOption={selectedOption}
                setShowModal={setShowModal}
                isError={isError}
                setIsError={setIsError}
                responsibleOption={enumsResponsibleOptions.ONLY_RESPONSIBLE}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                workflowid={workflowid}
                stepUuid={id}
              />
            </Col>
          </Row>
        </Alert>
      </div>
    );
  } else if (
    typeResponsibleUser ===
    enumsTypeResponsibleNumber.listOfResponsible
  ) {
    renderSelectedResonsibleOptions = (
      <div>
        {alertStatusWorkflowWrong}
        <Alert
          className="ext-primary text-capitalize font-weight-bold"
          color="info"
        >
          <Row>
            <Col md={8}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              {i18n.t("createWorkflow.config.listResponsible")}
              <br />
              {listReal.map((e) => (
                <li key={e.value}>
                  {e.label} <br />
                </li>
              ))}
            </Col>
            <Col md={4} className="text-right ">
              <Button
                outline
                className="border-0 btn-transition"
                color="cyan"
                disabled={disabledOptionByStatusProcess}
                onClick={handleOnClick}
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("buttonActions.option2")}
              </Button>

              <ModalProcessResponsibles
                showModal={showModal}
                handleOnClose={handleOnClose}
                usersDinamicEdit={usersDinamic}
                selectedOption={selectedOption}
                setShowModal={setShowModal}
                isError={isError}
                setIsError={setIsError}
                responsibleOption={enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                responsibleList={responsibleList}
                listReal={listReal}
                workflowid={workflowid}
                stepUuid={id}
              />

              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                disabled={disabledOptionByStatusProcess}
                onClick={handleOnDeleteResponsible}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                {i18n.t("buttonActions.option3")}
              </Button>
            </Col>
          </Row>
          <br />
        </Alert>
      </div>
    );
  } else if (typeResponsibleGroup === true) {
    renderSelectedResonsibleOptions = (
      <div>
        {alertStatusWorkflowWrong}
        <Alert
          className="mbg-3 ext-primary text-capitalize font-weight-bold"
          color="info"
        >
          <Row>
            <Col md={8}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              {i18n.t(
                "create.work.group.members.assignment.label5"
              )}{" "}
              {nameGroup}
            </Col>
            <Col md={4} className="text-right">
              <Button
                outline
                className="border-0 btn-transition"
                color="cyan"
                onClick={handleOnClickEditWorkGroup}
                disabled={disabledOptionByStatusProcess}
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("buttonActions.option2")}
              </Button>

              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                onClick={handleOnDeleteResponsible}
                disabled={disabledOptionByStatusProcess}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                {i18n.t("buttonActions.option3")}
              </Button>
            </Col>
          </Row>
        </Alert>
        <ModalResponsibleForGroup
          showModal={showModal}
          setShowModal={setShowModal}
          handleOnClose={handleOnClose}
          workflowid={workflowid}
          stepUuid={id}
          setShowModalGroup={setShowModalGroup}
          showModalGroup={showModalGroup}
        />
      </div>
    );
  };

  if (objectProcessInfo.type === "START_EVENT") {
    return (
      <Alert className="mbg-3 ext-primary font-weight-bold" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("createWorkflow.edit.config2")}
        <br />
      </Alert>
    );
  } else {
    return (
      <AvForm>
        <Fragment>
          <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container pb-2">
                <h6 className="text-info font-weight-bold">
                  {i18n
                    .t("createWorkflow.config.responsibleStep")
                    .toUpperCase()}
                </h6>
              </Col>
            </Row>

            <AvGroup row>
              <Col md={8}>
                <AvField
                  id="responsibleOption"
                  name="responsibleOption"
                  type="select"
                  required={requiredStep}
                  disabled={disabled}
                  onChange={handleOnChangeOption}
                  errorMessage={i18n.t("createWorkflow.config.typeResponsible")}
                  value={responsibleOption.responsible}
                >
                  <option value={""}>
                    {i18n.t("createWorkflow.config.typeResponsibleSelect")}
                  </option>

                  <option value={enumsResponsibleOptions.ONLY_RESPONSIBLE}>
                    {i18n.t("createWorkflow.config.responsibleOnly")}
                  </option>

                  <option value={enumsResponsibleOptions.ONLY_RESPONSIBLE_LIST}>
                    {i18n.t("createWorkflow.config.responsibleOnlyList")}
                  </option>
                  <option value={enumsResponsibleOptions.GROUP_MEMBERS}>
                    {i18n.t("create.work.group.members.assignment.label4")}
                  </option>
                </AvField>
              </Col>

              <Col md={4}>
                <ButtonResponsible
                  disabled={responsibleOptionDisabled}
                  workflowid={workflowid}
                  stepUuid={id}
                />
              </Col>
            </AvGroup>
            {renderSelectedResonsibleOptions}
          </CSSTransitionGroup>
        </Fragment>
      </AvForm>
    );
  }
};

WorkFlowConfigStepsTab1Responsible.propTypes = {
  objectProcessInfo: PropTypes.object.isRequired,
  nameGroup: PropTypes.string.isRequired,
  disabledOptionByStatusProcess: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  setIsError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  workflowid: PropTypes.string.isRequired,
  userStep: PropTypes.array.isRequired,
  userActive: PropTypes.object.isRequired,
  usersDinamicEdit: PropTypes.array.isRequired,
  usersDinamic: PropTypes.array.isRequired,
  typeResponsibleGroup: PropTypes.bool.isRequired,
  typeResponsibleUser: PropTypes.number,
  responsibleList: PropTypes.string.isRequired,
  responsibleOption: PropTypes.object.isRequired,
  responsibleOptionDisabled: PropTypes.bool.isRequired,
  selectedOption: PropTypes.array,
  showModalGroup: PropTypes.bool.isRequired,
  setShowModalGroup: PropTypes.func.isRequired,
  listReal: PropTypes.array.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleOnDeleteResponsible: PropTypes.func.isRequired,
  handleOnClickEditWorkGroup: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnChangeOption: PropTypes.func.isRequired,
};

export default WorkFlowConfigStepsTab1Responsible;
