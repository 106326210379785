import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import WorkflowList from "components/organism/WorkflowList";
import i18n from "locales/i18n";

const WorkFlowList = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("createWorflow.Header")}
        subheading={i18n.t("createWorflow.SubHeader")}
        icon="note2"
      >
        <WorkflowList />
      </AdminTemplate>
    </Fragment>
  );
};

export default WorkFlowList;
