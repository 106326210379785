import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserListOfGroups from "components/organism/UserListOfGroups";
import i18n from "locales/i18n";

const userWorkGroup = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("create.work.title")}
				subheading={i18n.t("create.work.subHeading")}
				icon="note2"
			>
			<UserListOfGroups />
			</AdminTemplate>
		</Fragment>
	);
};

export default userWorkGroup;
