import { assign, forEach, isArray } from "min-dash";
import { is } from "bpmn-js/lib/util/ModelUtil";
import { isExpanded, isEventSubProcess } from "bpmn-js/lib/util/DiUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import { getChildLanes } from "bpmn-js/lib/features/modeling/util/LaneUtil";
import { hasPrimaryModifier } from "diagram-js/lib/util/Mouse";
import i18n from "locales/i18n";

export default function ContextPadProvider(
  config,
  injector,
  eventBus,
  contextPad,
  modeling,
  elementFactory,
  connect,
  create,
  popupMenu,
  canvas,
  rules,
  translate
) {
  config = config || {};

  contextPad.registerProvider(this);

  this._contextPad = contextPad;
  this._modeling = modeling;
  this._elementFactory = elementFactory;
  this._connect = connect;
  this._create = create;
  this._popupMenu = popupMenu;
  this._canvas = canvas;
  this._rules = rules;
  this._translate = translate;

  if (config.autoPlace !== false) {
    this._autoPlace = injector.get("autoPlace", false);
  }

  /**
   * Handles the end of the create event by checking if the context pad should be opened and performing an action.
   * @param {Object} event - The event object containing information about the event.
   * @param {Object} event.context - The context object associated with the event.
   * @param {Object} event.context.shape - The shape that was created.
   * @returns {void}
   */
  eventBus.on("create.end", 250, function (event) {
    var context = event.context,
      shape = context.shape;

    if (!hasPrimaryModifier(event) || !contextPad.isOpen(shape)) {
      return;
    }

    var entries = contextPad.getEntries(shape);

    if (entries.replace) {
      entries.replace.action.click(event, shape);
    }
  });
}

ContextPadProvider.$inject = [
  "config.contextPad",
  "injector",
  "eventBus",
  "contextPad",
  "modeling",
  "elementFactory",
  "connect",
  "create",
  "popupMenu",
  "canvas",
  "rules",
  "translate",
];

ContextPadProvider.prototype.getContextPadEntries = function (element) {
  var contextPad = this._contextPad,
    modeling = this._modeling,
    elementFactory = this._elementFactory,
    connect = this._connect,
    create = this._create,
    rules = this._rules,
    autoPlace = this._autoPlace,
    translate = this._translate;

  var actions = {};

  /**
   * Returns the actions if the element type is "label".
   * @param {Object} element - The element to check.
   * @param {string} element.type - The type of the element.
   * @param {any} actions - The actions to return if the element type is "label".
   * @returns {any} Returns the actions if the element type is "label"; otherwise, returns `undefined`.
   */
  if (element.type === "label") {
    return actions;
  }

  var businessObject = element.businessObject;

  /**
   * Initiates the connection process using the provided event and element.
   * @param {Object} event - The event object that triggers the connection start.
   * @param {Object} element - The element to be connected.
   * @returns {void}
   */
  function startConnect(event, element) {
    connect.start(event, element);
  }

  /**
   * Removes the specified element from the model.
   * @returns {void}
   */
  function removeElement() {
    modeling.removeElements([element]);
  }

  /**
   * Creates an action configuration for appending a new element to the model.
   * @param {string} type - The type of the element to be appended.
   * @param {string} className - The CSS class name associated with the element.
   * @param {string|Object} title - The title or options object. If not a string, it is considered as the options object.
   * @param {Object} [options] - Optional parameters for element creation, which may override default settings.
   * @returns {Object} An action configuration object containing group, className, title, and action properties.
   */
  function appendAction(type, className, title, options) {
    if (typeof title !== "string") {
      options = title;
      title = translate("Append {type}", { type: type.replace(/^bpmn:/, "") });
    }

    function appendStart(event, element) {
      var shape = elementFactory.createShape(assign({ type: type }, options));
      create.start(event, shape, {
        source: element,
      });
    }

    var append;
    if (autoPlace) {
      append = function (event, element) {
        var shape = elementFactory.createShape(assign({ type: type }, options));
        autoPlace.append(element, shape);
      };
    } else {
      append = appendStart;
    }

    return {
      group: "model",
      className: className,
      title: title,
      action: {
        dragstart: appendStart,
        click: append,
      },
    };
  }

  /**
   * Returns a function that handles the splitting of a lane element into the specified number of parts.
   * @param {number} count - The number of parts to split the lane into.
   * @returns {Function} A handler function that splits the lane and opens the context pad.
   */
  function splitLaneHandler(count) {
    return function (event, element) {
      modeling.splitLane(element, count);
      contextPad.open(element, true);
    };
  }

  /**
   * Configures actions for a BPMN element based on its type and dimensions.
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} element - The BPMN element to which actions are being added.
   * @param {number} element.height - The height of the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} translate - A function to translate titles.
   * @param {function} modeling - An object providing methods to modify the BPMN model.
   * @param {function} splitLaneHandler - A function that returns a handler to split lanes.
   * @param {function} getChildLanes - A function that retrieves child lanes of the given element.
   * @param {function} isAny - A function that checks if the element is of a specific type.
   * @param {function} isExpanded - A function that checks if the element is expanded.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (
    isAny(businessObject, ["bpmn:Lane", "bpmn:Participant"]) &&
    isExpanded(element)
  ) {
    var childLanes = getChildLanes(element);

    assign(actions, {
      "lane-insert-above": {
        group: "lane-insert-above",
        className: "bpmn-icon-lane-insert-above",
        title: translate("Add Lane above"),
        action: {
          click: function (event, element) {
            modeling.addLane(element, "top");
          },
        },
      },
    });

    /**
     * Configures actions for dividing a BPMN lane based on the number of child lanes and element height.
     * @param {Object} element - The BPMN element being processed.
     * @param {number} element.height - The height of the BPMN element.
     * @param {Array} childLanes - An array of child lanes of the BPMN element.
     * @param {Object} actions - The actions object to which new actions are added.
     * @param {function} translate - A function for translating action titles.
     * @param {function} splitLaneHandler - A function that returns a handler for splitting lanes.
     * @param {function} assign - A function to merge new actions into the actions object.
     * @returns {void}
     */
    if (childLanes.length < 2) {
      if (element.height >= 120) {
        assign(actions, {
          "lane-divide-two": {
            group: "lane-divide",
            className: "bpmn-icon-lane-divide-two",
            title: translate("Divide into two Lanes"),
            action: {
              click: splitLaneHandler(2),
            },
          },
        });
      }

      if (element.height >= 180) {
        assign(actions, {
          "lane-divide-three": {
            group: "lane-divide",
            className: "bpmn-icon-lane-divide-three",
            title: translate("Divide into three Lanes"),
            action: {
              click: splitLaneHandler(3),
            },
          },
        });
      }
    }

    assign(actions, {
      "lane-insert-below": {
        group: "lane-insert-below",
        className: "bpmn-icon-lane-insert-below",
        title: translate("Add Lane below"),
        action: {
          click: function (event, element) {
            modeling.addLane(element, "bottom");
          },
        },
      },
    });
  }

  /**
   * Configures actions for BPMN elements based on their type and properties.
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} appendAction - A function to create an action for appending a new BPMN element.
   * @param {function} is - A function that checks if the business object is of a specified type.
   * @param {function} isEventType - A function that checks if the business object is of a specified event type.
   * @param {function} isEventSubProcess - A function that checks if the business object is an event subprocess.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @param {Object} i18n - An internationalization object for translating action titles.
   * @returns {void}
   */
  if (is(businessObject, "bpmn:FlowNode")) {
    if (is(businessObject, "bpmn:EventBasedGateway")) {
      assign(actions, {
        "append.receive-task": appendAction(
          "bpmn:ReceiveTask",
          "bpmn-icon-receive-task",
          i18n.t("bpmnLint.ContextPadAppendReceiveTask")
        ),
        "append.message-intermediate-event": appendAction(
          "bpmn:IntermediateCatchEvent",
          "bpmn-icon-intermediate-event-catch-message",
          i18n.t("bpmnLint.ContextPadAppendMessageIntermediateCatchEvent"),
          { eventDefinitionType: "bpmn:MessageEventDefinition" }
        ),
        "append.timer-intermediate-event": appendAction(
          "bpmn:IntermediateCatchEvent",
          "bpmn-icon-intermediate-event-catch-timer",
          i18n.t("bpmnLint.ContextPadAppendTimerIntermediateCatchEvent"),
          { eventDefinitionType: "bpmn:TimerEventDefinition" }
        ),
        "append.condition-intermediate-event": appendAction(
          "bpmn:IntermediateCatchEvent",
          "bpmn-icon-intermediate-event-catch-condition",
          i18n.t("bpmnLint.ContextPadAppendConditionIntermediateCatchEvent"),
          { eventDefinitionType: "bpmn:ConditionalEventDefinition" }
        ),
        "append.signal-intermediate-event": appendAction(
          "bpmn:IntermediateCatchEvent",
          "bpmn-icon-intermediate-event-catch-signal",
          i18n.t("bpmnLint.ContextPadAppendSignalIntermediateCatchEvent"),
          { eventDefinitionType: "bpmn:SignalEventDefinition" }
        ),
      });
    } else if (
      isEventType(
        businessObject,
        "bpmn:BoundaryEvent",
        "bpmn:CompensateEventDefinition"
      )
    ) {
      assign(actions, {
        "append.compensation-activity": appendAction(
          "bpmn:Task",
          "bpmn-icon-task",
          i18n.t("bpmnLint.ContextPadAppendCompensationActivity"),
          {
            isForCompensation: true,
          }
        ),
      });
    } else if (is(businessObject, "bpmn:StartEvent")) {
      assign(actions, {
        "append.append-user-task": appendAction(
          "bpmn:UserTask",
          "bpmn-icon-user-task",
          i18n.t("bpmnLint.ContextPadAppendUserTask")
        ),
        "append.append-manual-task": appendAction(
          "bpmn:ManualTask",
          "bpmn-icon-manual-task",
          i18n.t("bpmnLint.ContextPadAppendManualTask")
        ),
      });
    } else if (is(businessObject, "bpmn:ExclusiveGateway")) {
      assign(actions, {
        "append.append-user-task": appendAction(
          "bpmn:UserTask",
          "bpmn-icon-user-task",
          i18n.t("bpmnLint.ContextPadAppendUserTask")
        ),
        "append.append-manual-task": appendAction(
          "bpmn:ManualTask",
          "bpmn-icon-manual-task",
          i18n.t("bpmnLint.ContextPadAppendManualTask")
        ),
        "append.end-event": appendAction(
          "bpmn:EndEvent",
          "bpmn-icon-end-event-none",
          i18n.t("bpmnLint.ContextPadAppendEndEvent")
        ),
      });
    } else if (
      !is(businessObject, "bpmn:EndEvent") &&
      !is(businessObject, "bpmn:StartEvent") &&
      !is(businessObject, "bpmn:ExclusiveGateway") &&
      !businessObject.isForCompensation &&
      !isEventType(
        businessObject,
        "bpmn:IntermediateThrowEvent",
        "bpmn:LinkEventDefinition"
      ) &&
      !isEventSubProcess(businessObject)
    ) {
      assign(actions, {
        "append.end-event": appendAction(
          "bpmn:EndEvent",
          "bpmn-icon-end-event-none",
          i18n.t("bpmnLint.ContextPadAppendEndEvent")
        ),
        "append.gateway": appendAction(
          "bpmn:ExclusiveGateway",
          "bpmn-icon-gateway-none",
          i18n.t("bpmnLint.ContextPadAppendGateway")
        ),
        "append.append-user-task": appendAction(
          "bpmn:UserTask",
          "bpmn-icon-user-task",
          i18n.t("bpmnLint.ContextPadAppendUserTask")
        ),
        "append.append-manual-task": appendAction(
          "bpmn:ManualTask",
          "bpmn-icon-manual-task",
          i18n.t("bpmnLint.ContextPadAppendManualTask")
        ),
        "append.append-terminate-end-event": appendAction(
          "bpmn:EndEvent",
          "bpmn-icon-end-event-terminate",
          i18n.t("bpmnLint.ContextPadAppendTeminateEndEvent"),
          { eventDefinitionType: "bpmn:TerminateEventDefinition" }
        ),
      });
    }
  }

  /**
   * Configures actions for BPMN elements based on their type.
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} appendAction - A function to create an action for appending a new BPMN element.
   * @param {function} startConnect - A function to start the connection process.
   * @param {Object} i18n - An internationalization object for translating titles.
   * @param {function} isAny - A function that checks if the element is of one of the specified types.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (
    isAny(businessObject, [
      "bpmn:FlowNode",
      "bpmn:InteractionNode",
      "bpmn:DataObjectReference",
      "bpmn:DataStoreReference",
    ])
  ) {
    assign(actions, {
      "append.text-annotation": appendAction(
        "bpmn:TextAnnotation",
        "bpmn-icon-text-annotation"
      ),

      connect: {
        group: "connect",
        className: "bpmn-icon-connection-multi",
        title:
          i18n.t("bpmnLint.ContextPadAppendConnect1") +
          i18n.t("bpmnLint.ContextPadAppendConnect2") +
          i18n.t("bpmnLint.ContextPadAppendConnect3"),
        action: {
          click: startConnect,
          dragstart: startConnect,
        },
      },
    });
  }

  /**
   * Configures actions for a BPMN TextAnnotation element.
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} startConnect - A function to start the connection process.
   * @param {Object} i18n - An internationalization object for translating titles.
   * @param {function} is - A function that checks if the business object is of a specified type.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (is(businessObject, "bpmn:TextAnnotation")) {
    assign(actions, {
      connect: {
        group: "connect",
        className: "bpmn-icon-connection-multi",
        title: i18n.t("bpmnLint.ContextPadAppendTextAnnotation"),
        action: {
          click: startConnect,
          dragstart: startConnect,
        },
      },
    });
  }

  /**
   * Configures actions for BPMN DataObjectReference and DataStoreReference elements.p
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} startConnect - A function to start the connection process.
   * @param {Object} i18n - An internationalization object for translating titles.
   * @param {function} isAny - A function that checks if the business object is one of the specified types.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (
    isAny(businessObject, [
      "bpmn:DataObjectReference",
      "bpmn:DataStoreReference",
    ])
  ) {
    assign(actions, {
      connect: {
        group: "connect",
        className: "bpmn-icon-connection-multi",
        title: i18n.t("bpmnLint.ContextPadAppendDataInputAssociation"),
        action: {
          click: startConnect,
          dragstart: startConnect,
        },
      },
    });
  }

  /**
   * Configures actions for BPMN Group elements.
   * @param {Object} businessObject - The business object associated with the BPMN element.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} appendAction - A function to create an action for appending a new BPMN element.
   * @param {function} is - A function that checks if the business object is of a specified type.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (is(businessObject, "bpmn:Group")) {
    assign(actions, {
      "append.text-annotation": appendAction(
        "bpmn:TextAnnotation",
        "bpmn-icon-text-annotation"
      ),
    });
  };

  var deleteAllowed = rules.allowed("elements.delete", { elements: [element] });

  /**
   * Checks if `deleteAllowed` is an array and updates its value based on the presence of the `element`.
   * @param {Array} deleteAllowed - An array that may contain elements allowing deletion.
   * @param {Object} element - The element to check for in the `deleteAllowed` array.
   * @returns {void}
   */
  if (isArray(deleteAllowed)) {
    deleteAllowed = deleteAllowed[0] === element;
  }

  /**
   * Configures a delete action for BPMN elements if deletion is allowed.
   * @param {boolean} deleteAllowed - A boolean indicating whether deletion is allowed.
   * @param {Object} actions - The actions object to which new actions are added.
   * @param {function} removeElement - A function to remove the element.
   * @param {Object} i18n - An internationalization object for translating titles.
   * @param {function} assign - A function to merge new actions into the actions object.
   * @returns {void}
   */
  if (deleteAllowed) {
    assign(actions, {
      delete: {
        group: "edit",
        className: "bpmn-icon-trash",
        title: i18n.t("bpmnLint.ContextPadAppendRemove"),
        action: {
          click: removeElement,
        },
      },
    });
  }

  return actions;
};

/**
 * Checks if an event is of a specific type and has a specific definition.
 * @param {Object} eventBo - The event object.
 * @param {string} type - The event type to check.
 * @param {string} definition - The event definition to check.
 * @returns {boolean} Returns `true` if the event is of the specified type and contains the specified definition, otherwise returns `false`.
 */
function isEventType(eventBo, type, definition) {
  var isType = eventBo.$instanceOf(type);
  var isDefinition = false;

  var definitions = eventBo.eventDefinitions || [];
  forEach(definitions, function (def) {
    if (def.$type === definition) {
      isDefinition = true;
    }
  });

  return isType && isDefinition;
}
