import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_FORMS } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useUser() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const getForms = async (page, per_page, search, status, actions) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_FORMS}/form/forms/?status=${status}&action=${actions}&operation=and&name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.forms.getForms,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/form/forms/?name=${search}&action=${actions}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.forms.getForms,
        }
      );
    }
    return response.data;
  };

  const getFormsAvailablesPerUser = async (
    page,
    per_page,
    search,
    status,
    actions
  ) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_FORMS}/form/forms/availables-per-user/?status=${status}&action=${actions}&operation=and&page=${page}&per_page=${per_page}&name=${search}`,
        {
          headers,
          endpointError: endpointError.forms.getFormsAvailablesPerUser,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/form/forms/availables-per-user/?action=${actions}&page=${page}&per_page=${per_page}&name=${search}`,
        {
          headers,
          endpointError: endpointError.forms.getFormsAvailablesPerUser,
        }
      );
    }
    return response.data;
  };

  const createForm = async (formData) => {
    const response = await axios.post(`${URL_FORMS}/form/forms/`, formData, {
      headers,
      endpointError: endpointError.forms.createForm,
    });
    return response;
  };

  const updateForm = async (form, id) => {
    const response = await axios.put(`${URL_FORMS}/form/forms/${id}/`, form, {
      headers,
      endpointError: endpointError.forms.updateForm,
    });
    return response;
  };

  const createFields = async (fieldData) => {
    const response = await axios.post(`${URL_FORMS}/form/fields/`, fieldData, {
      headers,
      endpointError: endpointError.forms.createFields,
    });
    return response;
  };

  const getFields = async (page, per_page, formId, actions, searchField) => {
    let response;
    if (searchField === true) {
      response = await axios.get(
        `${URL_FORMS}/form/forms/${formId}/fields/?search_field=${searchField}&action=${actions}`,
        {
          headers,
          endpointError: endpointError.forms.getFields,
        }
      );
      return response.data;
    } else {
      const response = await axios.get(
        `${URL_FORMS}/form/forms/${formId}/fields/?action=${actions}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.forms.getFields,
        }
      );
      return response.data;
    }
  };

  const getFormsById = async (formId, actions) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/?action=${actions}`,
      {
        headers,
        endpointError: endpointError.forms.getFormsById,
      }
    );
    return response.data;
  };

  const deleteFields = async (formId, fieldId) => {
    const response = await axios.delete(
      `${URL_FORMS}/form/forms/${formId}/fields/${fieldId}/`,
      {
        headers,
        endpointError: endpointError.forms.deleteFields,
      }
    );
    return response;
  };

  const getField = async (fieldId) => {
    const response = await axios.get(`${URL_FORMS}/form/field/${fieldId}/`, {
      headers,
      endpointError: endpointError.forms.getField,
    });
    return response.data.data;
  };

  const updateOrder = async (fieldId, data) => {
    const response = axios.patch(
      `${URL_FORMS}/form/field/${fieldId}/order/`,
      data,
      {
        headers,
        endpointError: endpointError.forms.updateOrder,
      }
    );

    return response;
  };

  const updateField = async (fieldId, data) => {
    const response = await axios.patch(
      `${URL_FORMS}/form/field/${fieldId}/`,
      data,
      {
        headers,
        endpointError: endpointError.forms.updateField,
      }
    );
    return response;
  };

  const deleteForm = async (formId) => {
    const response = await axios.delete(`${URL_FORMS}/form/forms/${formId}/`, {
      headers,
      endpointError: endpointError.forms.deleteForm,
    });
    return response;
  };

  const annulmentRecord = async (form_uuid, record_uuid, data) => {
    const response = await axios.post(
      `${URL_FORMS}/form/forms/${form_uuid}/record/${record_uuid}/cancel/`,
      data,
      {
        headers,
        endpointError: endpointError.forms.annulmentRecord,
      }
    );
    return response;
  };

  const getFieldsStepWorkflowConfig = async (porcessTaskId, externalForm) => {
    const response = await axios.get(
      `${URL_FORMS}/workflows/process-task/${porcessTaskId}/configuration-fields-form/?external_form=${externalForm}`,
      {
        headers,
        endpointError: endpointError.forms.getFieldsStepWorkflowConfig,
      }
    );
    return response;
  };

  const updateFieldsStepWorkFlowConfig = async (
    porcessTaskId,
    configuredStep
  ) => {
    const response = await axios.patch(
      `${URL_FORMS}/workflows/process-task/${porcessTaskId}/filling-in-form-fields-from-task/`,
      configuredStep,
      {
        headers,
        endpointError: endpointError.forms.updateFieldsStepWorkFlowConfig,
      }
    );
    return response;
  };

  const createRecordStepWorkFlowConfig = async (
    porcessTaskId,
    configuredStep
  ) => {
    const response = await axios.post(
      `${URL_FORMS}/workflows/process-task/${porcessTaskId}/create-record-from-task/`,
      configuredStep,
      {
        headers,
        endpointError: endpointError.forms.createRecordStepWorkFlowConfig,
      }
    );
    return response;
  };

  const getFormsForLookup = async (formId) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/forms-as-lookup/`,
      {
        headers,
        endpointError: endpointError.forms.getFormsForLookup,
      }
    );
    return response.data;
  };

  const getFieldsFormsForLookup = async (formId) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/form-as-lookup-fields/`,
      {
        headers,
        endpointError: endpointError.forms.getFieldsFormsForLookup,
      }
    );
    return response.data;
  };

  const getRecordsByFormLookup = async (formId, page, per_page, search) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/form-lookup-to-record/?page=${page}&per_page=${per_page}&record_value=${search}`,
      {
        headers,
        endpointError: endpointError.forms.getRecordsByFormLookup,
      }
    );
    return response.data;
  };

  const updateFieldsStepWorkFlowConfigBatch = async (configuredStep) => {
    const response = await axios.patch(
      `${URL_FORMS}/workflows/filling-in-form-fields-from-task/accomplish-batch/`,
      configuredStep,
      {
        headers,
        endpointError: endpointError.forms.updateFieldsStepWorkFlowConfigBatch,
      }
    );
    return response;
  };

  /**
   * Retrieves fields associated with a form based on a specific record sticker.
   * @async
   * @param {string} formId - The unique identifier of the form.
   * @returns {Promise<object>} A promise that resolves with the data containing fields associated with the specified form and record sticker.
   */
  const getFieldsBySticker = async (formId) => {
    const response = await axios.get(
      `${URL_FORMS}/form/forms/${formId}/fields/record-sticker/`,
      {
        headers,
        endpointError: endpointError.forms.getFieldsBySticker,
      }
    );
    return response.data;
  };

  /**
   * Exports records for a specific form by making an HTTP POST request.
   * @param {string} recordId - The ID of the record to be exported.
   * @returns {Promise<Object>} - A promise that resolves to the response object from the export API.
   */
  const exportRecords = async (recordId, dataExportedRecords, timeZone) => {
    const response = await axios.post(
      `${URL_FORMS}/exports/forms/${recordId}/records/?time_zone=${timeZone}`, dataExportedRecords,
      {
        headers,
        endpointError: endpointError.forms.exportRecords,
      }
    );
    return response;
  };

  /**
   * Fetches the exported records from the server based on pagination, search query, and the number of records per page.
   * @param {number} page - The page number for pagination.
   * @param {string} search - The search query string to filter the records.
   * @param {number} per_page - The number of records to fetch per page.
   * @returns {Promise<Object>} - A promise that resolves to the response object containing the exported records.
   * @throws {Error} - Throws an error if the request fails.
   */
  const getExportRecords = async (page, status, per_page, searchExport) => {
    let response;
    if (status) {
      response = await axios.get(
        `${URL_FORMS}/exports/forms/records/?page=${page}&status=${status}&per_page=${per_page}&search=${searchExport}`,
        {
          headers,
          endpointError: endpointError.forms.exportRecords,
        }
      );
    } else {
      response = await axios.get(
        `${URL_FORMS}/exports/forms/records/?page=${page}&per_page=${per_page}&search=${searchExport}`,
        {
          headers,
          endpointError: endpointError.forms.getExportRecords,
        }
      );
    }
    return response.data;
  };

  return {
    getForms,
    createForm,
    updateForm,
    getFormsById,
    createFields,
    getFields,
    deleteFields,
    getField,
    deleteForm,
    updateField,
    updateOrder,
    annulmentRecord,
    getFieldsStepWorkflowConfig,
    updateFieldsStepWorkFlowConfig,
    createRecordStepWorkFlowConfig,
    getFormsForLookup,
    getFieldsFormsForLookup,
    getRecordsByFormLookup,
    getFormsAvailablesPerUser,
    updateFieldsStepWorkFlowConfigBatch,
    getFieldsBySticker,
    exportRecords,
    getExportRecords,
  };
}
