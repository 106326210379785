import React, { useEffect, Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm } from "availity-reactstrap-validation";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useParams } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import LenCharsMinMax from "components/molecules/Fields/Validation/LenCharsMinMax";
import NumericTextBox from "components/molecules/Fields/Validation/NumericTextBox";
import ComboBox from "components/molecules/Fields/Validation/ComboBox";
import Dates from "components/molecules/Fields/Validation/Dates";
import Lookup from "components/molecules/Fields/Validation/Lookup";
import i18n from "locales/i18n";
import { enumsFieldsTyData, enumsFieldsTypeControl } from "utils/enums";

const FieldsValidation = () => {
  const { id } = useParams();
  const { setFieldForm, fieldForm } = useFieldsRecordContext();

  /**
   * Determines whether to render the 'LenCharsMinMax' component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'LenCharsMinMax' component if conditions are met; otherwise, null.
   */
  let componentLenCharsMinMax = null;
  if (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && fieldForm.control_data === enumsFieldsTypeControl.TEXT_BOX) {
    componentLenCharsMinMax = <LenCharsMinMax isTextArea={false} />;
  } else {
    componentLenCharsMinMax = null;
  }

  /**
   * Determines whether to render the 'LenCharsMinMax' for text area component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'LenCharsMinMax' for text area component if conditions are met; otherwise, null.
   */
  let componentLenCharsMinMaxTextArea = null;
  if (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && fieldForm.control_data === enumsFieldsTypeControl.TEXT_AREA) {
    componentLenCharsMinMaxTextArea = <LenCharsMinMax isTextArea={true} />;
  } else {
    componentLenCharsMinMaxTextArea = null;
  }

  /**
   * Determines whether to render the 'Combobox' component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'Combobox' component if conditions are met; otherwise, null.
   */
  let componetnComboBox = null;
  if (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && fieldForm.control_data === enumsFieldsTypeControl.DROPDOWN_LIST) {
    componetnComboBox = <ComboBox />;
  } else {
    componetnComboBox = null;
  }

  /**
   * Determines the appropriate component label based on the provided fieldForm's type and control data.
   * @param {object} fieldForm - The field configuration object to evaluate.
   * @param {number} fieldForm.type_data - The type data of the field.
   * @param {number} fieldForm.control_data - The control data of the field.
   * @returns {JSX.Element | null} - The component label as a JSX element or null if none is applicable.
   */
  let componentLabel = null;
  if (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && fieldForm.control_data === enumsFieldsTypeControl.LABEL) {
    componentLabel = (
      <Fragment>
        <Card className="forms-fields">
          <CardBody>{i18n.t("form.fieldlist.validation")}</CardBody>
        </Card>
      </Fragment>
    );
  } else {
    componentLabel = null;
  }

  /**
   * Determines whether to render the 'LookUp' component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'LenLookUp' component if conditions are met; otherwise, null.
   */
  let componentLookup = null;
  if (fieldForm.type_data === enumsFieldsTyData.TEXT_STRING && fieldForm.control_data === enumsFieldsTypeControl.LOOKUP) {
    componentLookup = <Lookup />;
  } else {
    componentLookup = null;
  }

  /**
   * Determines whether to render the 'NumericTextBox' component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'NumericTextBox' component if conditions are met; otherwise, null.
   */
  let componentNumericTextBox = null;
  if (fieldForm.type_data === enumsFieldsTyData.INTEGER && fieldForm.control_data === enumsFieldsTypeControl.NUMERIC_TEXBOX) {
    componentNumericTextBox = <NumericTextBox />;
  } else {
    componentNumericTextBox = null;
  }

  /**
   * Determines whether to render the 'Dates' component based on the 'fieldForm' properties.
   * @param {object} fieldForm - An object representing the properties of a form field.
   * @param {number} fieldForm.type_data - The type of data for the form field (e.g., 1 for text).
   * @param {number} fieldForm.control_data - The control type of the form field (e.g., 1 for input).
   * @returns {ReactNode | null} - The 'Dates' component if conditions are met; otherwise, null.
   */
  let componentDates = null;
  if (
    (fieldForm.type_data === enumsFieldsTyData.DATE && fieldForm.control_data === enumsFieldsTypeControl.DATA_PICKER) ||
    (fieldForm.type_data === enumsFieldsTyData.TIME_AND_DATE && fieldForm.control_data === enumsFieldsTypeControl.TIME_AND_DATE_PICKER)
  ) {
    componentDates = <Dates />;
  } else {
    componentDates = null;
  }

  /**
   * An useEffect hook that updates the 'form_uuid' property in the 'fieldForm' state object with the provided 'id'.
   * @param {string} id - The UUID to set in the 'form_uuid' property.
   * @param {object} fieldForm - The current state object representing form fields.
   * @param {function} setFieldForm - State setter function to update 'fieldForm'.
   * @returns {void}
   */
  useEffect(() => {
    setFieldForm({ ...fieldForm, form_uuid: id });
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <AvForm>
            <CardHeader className="mb-4">{i18n.t("form.field3.2")}</CardHeader>

            {componentLenCharsMinMax}

            {componentLenCharsMinMaxTextArea}

            {componetnComboBox}

            {componentLabel}

            {componentLookup}

            {componentNumericTextBox}

            {componentDates}
          </AvForm>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default FieldsValidation;
