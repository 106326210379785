import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { getDateFormat2 } from "utils/getDateFormat2";
import { enumsProcessTaskType, enumsSortedTask, enumsTask } from "utils/enums";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const WorkflowTraceabilityTaskListTable = (props) => {
  const { task } = props;
  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={task}
          columns={[
            {
              columns: [
                {
                  Header: i18n.t("workflowTraceability.task"),
                  accessor: "task_name",
                  Cell: (row) => {
                    let decisionGatewayName = "";
                    if (
                      row.original.exclusive_decision !== null &&
                      row.original.exclusive_decision !== undefined
                    ) {
                      decisionGatewayName = (
                        <div>
                          <div>{`${row.original.task_name}`}</div>
                          <div className=" font-weight-bold">
                            {`${row.original.exclusive_decision}`}
                          </div>
                        </div>
                      );
                    } else if (
                      row.original.exclusive_decision === null &&
                      row.original.exclusive_decision !== undefined
                    ) {
                      decisionGatewayName = (
                        <div>
                          <div>{`${row.original.task_name}`}</div>
                          <div className=" font-weight-bold">------</div>
                        </div>
                      );
                    } else {
                      decisionGatewayName = row.original.task_name;
                    }

                    return <span>{decisionGatewayName}</span>;
                  },
                },
                {
                  Header: i18n.t("createWorflow.state"),
                  accessor: "status_task",
                  Cell: (row) => {
                    let statusFinal = "";
                    let value = row.original.status_task;
                    if (value === enumsTask.IN_PROGRESS) {
                      statusFinal = (
                        <div className="ml-auto badge badge-warning">
                          {i18n.t("taskDetailStatus1")}
                        </div>
                      );
                    } else if (value === enumsTask.COMPLETED) {
                      statusFinal = (
                        <div className="ml-auto badge badge-success">
                          {i18n.t("taskDetailStatus2")}
                        </div>
                      );
                    } else if (value === enumsTask.INTERRUPTED) {
                      statusFinal = (
                        <div className="ml-auto badge badge-danger">
                          {i18n.t("taskDetailStatus3")}
                        </div>
                      );
                    }
                    if (
                      row.original.hasOwnProperty("is_executed") &&
                      row.original.is_executed === false
                    ) {
                      return (
                        <span>{i18n.t("workflowTraceability.noExecuted")}</span>
                      );
                    } else {
                      return <span>{statusFinal}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("createWorkflow.config.responsible"),
                  accessor: "user_name",
                },
                {
                  Header: i18n.t("workflowTraceability.receptionDate"),
                  accessor: "reception_date",

                  Cell: ({ value }) => {
                    if (
                      value === "0000-00-00T00:00:00+00:00" ||
                      value === undefined ||
                      value === null
                    ) {
                      return null;
                    } else {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <span>{valueFinal}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("workflowTraceability.complianceDate"),
                  accessor: "completed_at",
                  Cell: ({ value }) => {
                    if (
                      value === "0000-00-00T00:00:00+00:00" ||
                      value === undefined ||
                      value === null
                    ) {
                      return null;
                    } else {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <span>{valueFinal}</span>;
                    }
                  },
                },
                {
                  Header: (
                    <div>
                      <span className="text-primary font-weight-bold">
                        {i18n.t("taskBoxTableTaskType")}{" "}
                      </span>
                    </div>
                  ),
                  id: enumsSortedTask.TASKTYPE,
                  accessor: "task_type",
                  sortable: false,
                  Cell: ({ value }) => {
                    let processTaskType = "";

                    switch (value) {
                      case enumsProcessTaskType.NORMAL:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeNormal")}</span>
                        );
                        break;
                      case enumsProcessTaskType.RETURN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeDevolution")}
                          </span>
                        );
                        break;
                      case enumsProcessTaskType.RETURN_ORIGIN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeReturnToOrigin")}
                          </span>
                        );
                        break;
                      case enumsProcessTaskType.REASSIGNMENT:
                        processTaskType = (
                          <span>{i18n.t("taskBoxTableTaskTypeReassign")}</span>
                        );
                        break;
                      case enumsProcessTaskType.REASSIGNMENT_ORIGIN:
                        processTaskType = (
                          <span>
                            {i18n.t("taskBoxTableTaskTypeReassignToOrigin")}
                          </span>
                        );
                        break;

                      default:
                        break;
                    }

                    return (
                      <span className="text-center">{processTaskType}</span>
                    );
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          showPageJump={false}
          defaultPageSize={task.length}
          noDataText={`${i18n.t("tableRowsEmpty")}`}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

WorkflowTraceabilityTaskListTable.propTypes = {
  task: PropTypes.array.isRequired,
};

export default WorkflowTraceabilityTaskListTable;
