import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import MetricsContent from "components/organism/MetricsContent";

const Metrics = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("dashboardheadingMetrics")}
				subheading={i18n.t("dashboardheadingMetricsSubtitle")}
				icon="graph3"
			>
				<MetricsContent />
			</AdminTemplate>
		</Fragment>
	)
}

export default Metrics
