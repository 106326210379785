import axios from "axios";
import useLogin from "hooks/useLogin";
import { URL_LIST } from "constants/urlApis";
import { endpointError } from "utils/codeErrorsTimeout";

export default function useList() {
  const { jwt } = useLogin();
  const headers = {
    Authorization: `Bearer ${jwt}`,
  };

  const createListWithItem = async (list) => {
    const response = await axios.post(`${URL_LIST}/form/lists/`, list,
      {
        headers,
        endpointError: endpointError.list.createListWithItem
      });
    return response;
  };

  const createItemsInList = async (item, listId) => {
    const response = await axios.post(
      `${URL_LIST}/form/lists/${listId}/items/`,
      item,
      {
        headers,
        endpointError: endpointError.list.createItemsInList
      }
    );
    return response;
  };

  const getLists = async (page, per_page, search, status) => {
    let response;
    if (status !== null && status !== undefined && status !== "") {
      response = await axios.get(
        `${URL_LIST}/form/lists/?status=${status}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.list.getLists
        }
      );
    } else {
      response = await axios.get(
        `${URL_LIST}/form/lists/?name=${search}&page=${page}&per_page=${per_page}`,
        {
          headers,
          endpointError: endpointError.list.getLists
        }
      );
    }
    return response.data;
  };

  const getListsById = async (listId) => {
    const response = await axios.get(`${URL_LIST}/form/lists/${listId}/`,
      {
        headers,
        endpointError: endpointError.list.getListsById
      });
    return response.data;
  };

  const updateList = async (listId, listName, listStatus) => {
    const response = await axios.patch(
      `${URL_LIST}/form/lists/${listId}/`,
      listName || listStatus,
      {
        headers,
        endpointError: endpointError.list.updateList
      }
    );
    return response;
  };

  const getItems = async (listId, page, per_page, search) => {
    const response = await axios.get(
      `${URL_LIST}/form/lists/${listId}/items/?name=${search}&page=${page}&per_page=${per_page}`,
      {
        headers,
        endpointError: endpointError.list.getItems
      }
    );
    return response.data;
  };

  const getItemsById = async (listId, itemId) => {
    const response = await axios.get(
      `${URL_LIST}/form/lists/${listId}/items/${itemId}/`,
      {
        headers,
        endpointError: endpointError.list.getItemsById
      }
    );
    return response.data;
  };

  const updateItem = async (listId, itemId, itemName) => {
    const response = await axios.patch(
      `${URL_LIST}/form/lists/${listId}/items/${itemId}/`,
      itemName,
      {
        headers,
        endpointError: endpointError.list.updateItem
      }
    );
    return response;
  };

  return {
    getLists,
    createListWithItem,
    createItemsInList,
    updateList,
    getListsById,
    getItems,
    getItemsById,
    updateItem,
  };
}
