/**
 * Calculates the absolute difference in days between two dates.
 * @param {number} end - The end date in milliseconds since the Unix epoch.
 * @param {number} start - The start date in milliseconds since the Unix epoch.
 * @returns {number} The absolute number of days between the start and end dates.
 */
export const millisecondsPerDay = (end, start) => {
  return Math.abs((end - start) / (24 * 60 * 60 * 1000));
};

/**
 * @constant {number} rangeDays
 * @description Represents the maximum number of days allowed for the date range selection.
 * @default 30
 */
export const rangeDays = 29;
