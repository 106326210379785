import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Button,
  Col,
  CardFooter,
  Label,
  Card,
  CardBody,
  CardTitle,
  Alert
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { showAlertServiceError } from "utils/alerts";
import { Link, useParams } from "react-router-dom";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WORKFLOW_ALL_FORMS, WORKFLOW_FORMS_PERMISSIONS } from "constants/securityConst";
import { isNullOrUndefined } from "utils/validations";
import { regexDesignerWorkflowEdit } from "utils/regexExpressions";
import { enumsDesignerWorkflow, enumsFilterWorkflowState, enumsFormStatus, enumsPermissionsAction } from "utils/enums";
import ReactTooltip from "react-tooltip";
import useWorkflow from "hooks/useWorkFlow";
import useForms from "hooks/useForm";
import LoadingComponent from "components/atoms/LoadingComponent";
import Swal from "sweetalert";
import i18n from "locales/i18n";

const DesignerWorkflowEdit = () => {
  let { id: workflowId } = useParams();
  const [status, setStatus] = useState(null);
  const { getWorkFlowById, editWorkflowById } = useWorkflow();
  const [isLoadingEditWorkflow, setIsLoadingEditWorkflow] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState(true);
  const { getFormsById } = useForms();
  const [activeState, setActiveState] = useState({
    name: "",
  });
  const [activeForm, setActiveForm] = useState();
  const [formsActives, setFormsActive] = useState({
    name: "",
    description: "",
  });

  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(WORKFLOW_FORMS_PERMISSIONS);
  const newForms = WORKFLOW_FORMS_PERMISSION.split(",");
  let disabledStatus = false;
  let alertHashRecords = null;
  let formActive = null;
  let renderSelectStatus = null;

  /**
   * Handles the change event for updating the status.
   * This function updates the `activeState` by setting the `name` property
   * to the numeric value of the event target's value.
   * @param {Object} eventChangeStatus - The event object from the change event.
   * @param {Object} eventChangeStatus.target - The target element of the event.
   * @param {string} eventChangeStatus.target.value - The new value from the event target, expected to be a string.
   */
  const handleOnChangeStatus = (eventChangeStatus) => {
    setActiveState({
      ...activeState,
      name: Number(eventChangeStatus.target.value),
    })
  }

  /**
   * Conditionally renders a select input for workflow status.
   * This block of code checks if `workflowId` is not null or undefined. If the check passes,
   * it sets `renderSelectStatus` to a form group with a label and a select input field.
   * The select input is populated with options for different workflow statuses.
   * @param {any} workflowId - The workflow ID that is checked for null or undefined.
   * @param {function} handleOnChangeStatus - The function to handle changes in the select input.
   * @param {boolean} disabledStatus - Boolean flag to enable or disable the select input.
   * @param {Object} activeState - The state object containing the current active state.
   * @param {number} activeState.name - The current value of the active state.
   * @returns {JSX.Element|undefined} The JSX element with the select input or undefined if the condition is not met.
   */
  if (isNullOrUndefined(workflowId) === false) {
    renderSelectStatus = (
      <AvGroup row>
        <Label for="document_type" className="is-required" sm={2}>
          {i18n.t("createWorflow.state")}
        </Label>
        <Col md={10}>
          <AvField
            id="document_type"
            name="document_type"
            type="select"
            onChange={handleOnChangeStatus}
            disabled={disabledStatus}
            required
            errorMessage={i18n.t("createusers.Feedback6")}
            value={activeState.name}
          >
            <option value={enumsFilterWorkflowState.STATUS_ERASER}>
              {i18n.t("createWorflow.selectedOne")}
            </option>
            <option value={enumsFilterWorkflowState.STATUS_ACTIVE}>
              {i18n.t("createWorflow.selectedTwo")}
            </option>
            <option value={enumsFilterWorkflowState.STATUS_INACTIVE}>
              {i18n.t("createWorflow.selectedThree")}
            </option>
          </AvField>
        </Col>
      </AvGroup>
    )
  };

  /**
   * Conditionally renders a text input field for the active form.
   * This block of code checks if `workflowId` is not null or undefined. If the check passes,
   * it sets `formActive` to a column containing a disabled text input field. The text input
   * is populated with the current active form value.
   * @param {any} workflowId - The workflow ID that is checked for null or undefined.
   * @param {string} activeForm - The current value of the active form.
   * @returns {JSX.Element|undefined} The JSX element with the text input field or undefined if the condition is not met.
   */
  if (isNullOrUndefined(workflowId) === false) {
    formActive = (
      <Col md={10}>
        <AvField
          id="formulario"
          name="formulario"
          type="text"
          data-tip
          data-for="formulario"
          disabled={true}
          value={activeForm}
        />
      </Col>
    )
  };

  /**
   * Conditionally renders an alert message based on the status value.
   * If the `status` is defined and equal to 4, this block sets `alertHashRecords`
   * to an `Alert` component with an informational message and an icon.
   * @param {number} status - The current status value.
   * @returns {JSX.Element|undefined} The JSX element with the alert message or undefined if the condition is not met.
   */
  if (isNullOrUndefined(status) === false && status === enumsFormStatus.FORM_STATUS_DELETE) {
    alertHashRecords = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2 text-bold">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="mr-2 "
          />
          {i18n.t("modal.DoneError.header")}
          <div className="divider " />
          {i18n.t("deleteField.Alert2")}
        </span>
      </Alert>
    )
  };

  /**
   * See if the status is different from undefined or null and then 
   * check if it is equal to 4 set disabledStatus to true otherwise set it to false
   * @param {Number} status - The value to check.
   * @returns {boolean} True if the value is null or undefined, false otherwise.
   */
  if (isNullOrUndefined(status) === false && status === enumsFormStatus.FORM_STATUS_DELETE) {
    disabledStatus = true;
  } else {
    disabledStatus = false;
  }

  /**
   * Fetch workflow details by ID and update relevant state.
   * This function retrieves workflow data, form data, and updates state accordingly.
   * @return {Void}
   */
  const workFlowById = useCallback(() => {
    setIsLoadingRequest(true);
    getWorkFlowById(workflowId)
      .then((response) => {
        if (response.status === 200) {
          getFormsById(response.data.data.form_uuid, enumsPermissionsAction.ALL_PERMISIONS)
            .then((response) => {
              if (isNullOrUndefined(response.data) === false) {
                setStatus(response.data.status);
                setActiveForm(response.data.name);
              } else {
                showAlertServiceError();
              }
            })
          setFormsActive({
            name: response.data.data.name,
            description: response.data.data.description,
            form_uuid: response.data.data.form_uuid,
          });
          setActiveState({ name: response.data.data.status });
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        if (error.response.code !== 200) {
          window.location.href = "/workflow/list";
        }
      })
      .finally(() => {
        setIsLoadingRequest(false);
      })
    // eslint-disable-next-line
  }, [workflowId]);

  /**
   * Handle the change event, updating the active forms state with new values.
   * @param {Event} eventOnChange - The change event.
   * @return {Void}
   */
  const handleOnChange = (eventOnChange) => {
    setFormsActive({
      ...formsActives,
      [eventOnChange.target.name]: eventOnChange.target.value,
    });
  };

  /**
   * Handle the blur event, updating the active forms state with trimmed values.
   * @param {Event} eventOnBlur - The blur event.
   * @return {Void}
   */
  const handleOnBlur = (eventOnBlur) => {
    setFormsActive({
      ...formsActives,
      [eventOnBlur.target.name]: eventOnBlur.target.value.trim(),
    });
  };

  /**
   * Handle the key press event, preventing the default behavior for the "Enter" key.
   * @param {Event} eventKeyPress - The key press event.
   * @return {Void}
   */
  const handleOnKeyPress = (eventKeyPress) => {
    if (eventKeyPress.key === "Enter") {
      eventKeyPress.preventDefault();
    }
  };

  /**
   * Handle the form submission, sending data to edit a workflow.
   * This function performs data validation and handles success and error scenarios.
   * @return {Void}
   */
  const handleOnSubmit = async (_, errors) => {
    if (errors.length > 0) return;
    const data = {
      name: formsActives.name.trim(),
      description: formsActives.description.trim(),
      status: parseInt(activeState.name),
    };
    setIsLoadingEditWorkflow(true);
    editWorkflowById(workflowId, data)
      .then((response) => {
        if (response.status === 202) {
          Swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("createWorflow.updateSuccess"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location = `/WorkFlow/list`;
          });
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        const statusCode = error.response.data.code;
        const response = error.response.data.data.content;
        if (statusCode === "50031") return;
        if (response === undefined) {
          Swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("50042"),
            icon: "error",
            button: i18n.t("modal.Done.footerButton"),
            dangerMode: true
          }).then(() => {
            window.location = `/WorkFlow/list`;
          });
        } else {
          Swal({
            title: i18n.t("modal.DoneError.header"),
            text: `${`${i18n.t("50054")}
           ${response.map((element) => ` ${element.index} - ${element.name}`)}.
           ${i18n.t("createWorkflow.edit.config")}`} `,
            icon: "warning",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location = `/WorkFlow/list`;
          });
        }
      })
      .finally(() => {
        setIsLoadingEditWorkflow(false);
      });
  };

  /**
   * Execute the `workFlowById` function once when the component mounts.
   * @return {Void}
   */
  useEffect(() => {
    workFlowById();
    // eslint-disable-next-line
  }, []);

  /**
   * Execute a specific behavior when the workflowId changes.
   * @param {string|null} workflowId - The workflow identifier.
   * @param {string} WORKFLOW_ALL_FORM - A string representing whether all forms are allowed.
   * @param {Array<string>} newForms - An array of new forms.
   */
  useEffect(() => {
    if (isNullOrUndefined(workflowId) === false) {
      if (WORKFLOW_ALL_FORM === "false" && !newForms.includes(workflowId)) {
        window.history.back();
      }
    }
  }, [workflowId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
  * Render the loading component if isLoadingRequest is true.
  * @param {boolean} isLoadingRequest - Indicates whether an asynchronous request or process is being performed.
  * @returns {JSX.Element|null} - Returns the loading component if isLoadingRequest is true, otherwise returns null.
  */
  if (isLoadingRequest === true) {
    return (
      <LoadingComponent />
    )
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {alertHashRecords}
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{i18n.t("createWorflow.Edit")}</CardTitle>
            <AvForm onSubmit={handleOnSubmit}>
              <ReactTooltip id="name" place="bottom" type="dark" effect="solid">
                {i18n.t("trd.Tooltip4")}
              </ReactTooltip>

              <AvGroup row>
                <Label for="name" className="is-required" sm={2}>
                  {i18n.t("createWorflow.inputOne")}
                </Label>
                <Col md={10}>
                  <AvField
                    id="name"
                    name="name"
                    type="text"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onKeyPress={handleOnKeyPress}
                    data-tip
                    data-for="name"
                    validate={{
                      pattern: {
                        value: regexDesignerWorkflowEdit,
                        errorMessage: `${i18n.t("createWorflow.alert")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("createWorflow.alertName")}`,
                      },
                      minLength: {
                        value: enumsDesignerWorkflow.MIN_LENGTH_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsDesignerWorkflow.MAX_LENGTH_NAME,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={formsActives.name}
                  />
                </Col>
              </AvGroup>

              <AvGroup row>
                <Label for="description" className="is-required" sm={2}>
                  {i18n.t("createWorflow.description")}
                </Label>
                <Col md={10}>
                  <AvField
                    id="description"
                    name="description"
                    type="textarea"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onKeyPress={handleOnKeyPress}
                    data-tip
                    data-for="description"
                    validate={{
                      pattern: {
                        value: regexDesignerWorkflowEdit,
                        errorMessage: `${i18n.t("createWorflow.alert")}`,
                      },
                      required: {
                        value: true,
                        errorMessage: `${i18n.t(
                          "createWorflow.alertDescription"
                        )}`,
                      },
                      minLength: {
                        value: enumsDesignerWorkflow.MIN_LENGTH_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsDesignerWorkflow.MAX_LENGTH_DESCRIPTION,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 4 ${i18n.t("and")} 150 ${i18n.t("characters")}`,
                      },
                    }}
                    autoComplete="off"
                    value={formsActives.description}
                  />
                </Col>
              </AvGroup>

              <AvGroup row>
                <Label sm={2}>{i18n.t("form.field43")}</Label>
                {formActive}
              </AvGroup>
              {renderSelectStatus}
              <CardFooter className="d-block text-right">
                <Link to="/WorkFlow/list">
                  <Button size="lg" className="col-mt-3 mr-3" color="gray">
                    {i18n.t("createusers.createButton2")}
                  </Button>
                </Link>
                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoadingEditWorkflow}
                  className="col-mt-3"
                  color="cyan"
                >
                  {i18n.t("createWorflow.EditButton")}
                </Button>
              </CardFooter>
            </AvForm>
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default DesignerWorkflowEdit;
