import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import i18n from "locales/i18n";
import DocumentaryTypeEditions from "components/organism/DocumentaryTypeEditions";

const DocumentaryTypesEdition = () => {

	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("documentary.type.heading.create")}
				subheading={i18n.t("documentary.type.subHeading")}
				icon="note2"
			>
				<DocumentaryTypeEditions />
			</AdminTemplate>
		</Fragment>
	);
};

export default DocumentaryTypesEdition;