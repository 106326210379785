import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert, Button, Card, CardBody, Col, Row } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTrash, faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import { loaderElement } from "utils/loaderElement";
import { enumsRestoreFilesCodes } from "utils/enums";
import useRecord from "hooks/useRecord";
import TrashFilesListTable from "components/organism/TrashFilesListTable";
import FilterTrashElements from "components/molecules/FilterTrashElements";
import SearchBoxLookupField from "components/atoms/SearchBoxLookupField";
import swal from "sweetalert";
import i18n from "locales/i18n";

const TrashFilesList = () => {
  const [paginationTrash, setPaginationTrash] = useState(initialPaginationStandart);
  const [isLoadingTrashFiles, setIsLoadingTrashFiles] = useState(false);
  const [searchDeletedFile, setSearchDeletedFile] = useState("");
  const [filterTrash, setFilterTrash] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [listDeletedFiles, setDeletedListFiles] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [errorCharacters, setErrorCharacters] = useState("");
  const [filesSelectedToRestore, setFilesSelectedToRestore] = useState([]);
  const [isLoadingRequestTrashFiles, setIsLoadingRequestTrashFiles] = useState(false);
  const [filesToRestore, setfilesToRestore] = useState({ files: [] });
  const [isLoadingDeltingTrashFiles, setIsLoadingDeletingTrashFiles] = useState(false);
  const { getTrashData, restoreTrashFiles, deleteFiles } = useRecord();
  let disabledRestoreButton = false;

  /**
   * Updates the state of the restore button based on the selected files.
   * This function enables or disables the restore button depending on whether
   * there are any files selected to restore.
   * @param {Array} filesSelectedToRestore - The array of files selected for restoration.
   * @param {boolean} disabledRestoreButton - The current state of the restore button.
   * It will be set to `true` if no files are selected, otherwise `false`.
   */
  if (filesSelectedToRestore.length === 0) {
    disabledRestoreButton = true;
  } else {
    disabledRestoreButton = false;
  }

  /**
   * Fetches and processes the deleted files based on the current pagination and search/filter criteria.
   * This function initiates a loading state, retrieves the deleted files data, and updates the state
   * accordingly. It shows an alert if there are no deleted files or if an error occurs during the fetch operation.
   * @param {Object} paginationTrash - The current pagination settings for fetching deleted files.
   * @param {Function} getTrashData - The function to fetch the deleted files data.
   * @param {string} searchDeletedFile - The search query to filter the deleted files.
   * @param {Object} filterTrash - The filter criteria for the deleted files.
   * @param {string} timeZone - The current time zone.
   */
  const getWorkFlowBySearch = useCallback(() => {
    setIsLoadingTrashFiles(true);
    const { page, per_page } = paginationTrash;
    getTrashData(page, per_page, searchDeletedFile, filterTrash, timeZone)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const filesDeleted = response.data;
          if (filesDeleted.items.length === 0) {
            setDeletedListFiles([]);
            setTotalPages(0);
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trash.label11"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              if (searchDeletedFile !== "" || filterTrash !== "") {
                window.location.reload();
              }
            });
          } else {
            setDeletedListFiles(filesDeleted.items);
            setTotalPages(filesDeleted.pages);
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingTrashFiles(false);
      });
  }, [paginationTrash]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Restores files from the trash.
   * Initiates the restore process for the files selected to be restored from the trash.
   * Displays appropriate success or error messages based on the response.
   * @param {Array} filesToRestore - The files selected to be restored.
   * @param {Function} setIsLoadingRequestTrashFiles - Sets the loading state for the restore request.
   * @param {Function} restoreTrashFiles - Function to restore files from trash.
   * @param {Function} setFilesSelectedToRestore - Clears the selection of files to restore.
   * @param {Object} i18n - Internationalization object for translation.
   * @param {Function} swal - SweetAlert function for displaying alerts.
   */
  const restoredFiles = () => {
    setIsLoadingRequestTrashFiles(true);
    restoreTrashFiles(filesToRestore)
      .then((response) => {
        if (isNullOrUndefined(response.code) === false) {
          console.log(response.code);
          if (response.code === enumsRestoreFilesCodes.FILES_NOT_RESTORED) {
            const erroFiles = response.data;
            let errorTypeMessage = "warning";
            let errormessage = i18n.t("trash.label17");
            let dangerMode = false;
            const renderWarningList = erroFiles.map(
              (file, index) => `<li key=${index}><em>${file.file_name}</em></li>`
            );
            if (erroFiles.length === filesToRestore.files.length) {
              errorTypeMessage = "error";
              errormessage = i18n.t("trash.label18");
              dangerMode = true;
            }
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: errormessage,
              icon: errorTypeMessage,
              button: i18n.t("modal.Done.footerButton"),
              dangerMode: dangerMode,
              content: {
                element: "div",
                attributes: {
                  innerHTML: `<ul>${renderWarningList.join("")}</ul>`,
                  className: "custom-content-class",
                },
              },
            }).then(() => {
              window.location.reload();
            });
          }

          if (response.code === enumsRestoreFilesCodes.RESTORE_TRASH_FILES_OK) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trash.label15"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location.reload();
            });
          }
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingRequestTrashFiles(false);
        setFilesSelectedToRestore([]);
      });
  };

  /**
   * Deletes files permanently by calling the deleteFiles function and handles the response.
   * Shows a success message upon successful deletion and reloads the page.
   * Displays an error message if the deletion fails.
   * Sets loading state while the deletion is in progress.
   * @function deleteFilesPermanently
   * @returns {void}
   */
  const deleteFilesPermanently = () => {
    setIsLoadingDeletingTrashFiles(true);
    deleteFiles(filesToRestore)
      .then((response) => {
        if (isNullOrUndefined(response.code) === false) {
          if (response.code === enumsRestoreFilesCodes.FILES_DELETED_OK) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("trash.label23"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(() => {
              window.location.reload();
            });
          } else {
            showAlertServiceError();
          }
        }
      })
      .finally(() => {
        setIsLoadingDeletingTrashFiles(false);
        setFilesSelectedToRestore([]);
      });
  };

  /**
   * Handles the restoration of files by displaying a confirmation modal using SweetAlert.
   * The modal provides options to cancel or confirm the restoration process.
   * If the user confirms, the `restoredFiles` function is called.
   * @param {Function} restoredFiles - The function to call when the user confirms the restoration of files.
   * @param {Object} i18n - The i18n translation object.
   * @param {Function} i18n.t - The function to retrieve translated strings.
   * @returns {void}
   */
  const handleRestoreFiles = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trash.label14"),
      icon: "info",
      buttons: [i18n.t("modal.Error.footerButton"), i18n.t("modal.Done.footerButton")],
    }).then((willDelete) => {
      if (willDelete) {
        restoredFiles();
      }
    });
  };

  /**
   * Displays a confirmation modal using SweetAlert. If the user confirms the action,
   * it calls `deleteFilesPermanently` to permanently delete files.
   * @function handleDeleteFiles
   * @returns {void}
   */
  const handleDeleteFiles = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trash.label22"),
      icon: "info",
      buttons: [i18n.t("modal.Error.footerButton"), i18n.t("modal.Done.footerButton")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteFilesPermanently();
      }
    });
  };

  /**
   * Effect hook that updates the files to restore whenever the selected files change.
   * @param {Array} filesSelectedToRestore - The array of files selected to be restored.
   * @param {Function} setfilesToRestore - Function to update the state of files to restore.
   */
  useEffect(() => {
    if (filesSelectedToRestore.length > 0) {
      setfilesToRestore({ files: filesSelectedToRestore });
    }
  }, [filesSelectedToRestore]);

  /**
   * useEffect hook to fetch workflow data based on a search query.
   * This effect runs whenever the `getWorkFlowBySearch` dependency changes.
   * @param {Function} getWorkFlowBySearch - A function to fetch workflow data based on the current search query.
   * @returns {void}
   */
  useEffect(() => {
    getWorkFlowBySearch();
  }, [getWorkFlowBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook to reset the search for deleted files when either filterTrash or timeZone changes.
   * @param {string} filterTrash - The filter value indicating whether to show trashed files.
   * @param {string} timeZone - The current time zone setting.
   * @param {Function} setSearchDeletedFile - Function to reset the searchDeletedFile state.
   */
  useEffect(() => {
    if (filterTrash !== "" && timeZone !== "") {
      setSearchDeletedFile("");
      setFilesSelectedToRestore([]);
    }
    if (filterTrash !== "" || timeZone !== "") {
      setFilesSelectedToRestore([]);
    }
  }, [filterTrash, timeZone]);

  /**
   * React useEffect hook to reset filters and time zone when the searchDeletedFile state changes.
   * @param {string} searchDeletedFile - The search term for deleted files.
   * @param {function} setFilterTrash - Function to set the filter trash state.
   * @param {function} setTimeZone - Function to set the time zone state.
   */
  useEffect(() => {
    if (searchDeletedFile !== "") {
      setFilterTrash("");
      setTimeZone("");
      setFilesSelectedToRestore([]);
    }
  }, [searchDeletedFile]);

  return (
    <Fragment>
      {loaderElement(isLoadingRequestTrashFiles || isLoadingDeltingTrashFiles)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card className="main-card mb-3">
          <CardBody className="pb-1">
            <Row>
              <Col lg="12" md="12" sm="12" className="button-container mt-2 mt-md-0 ">
                <Row className="row justify-content-end">
                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <SearchBoxLookupField
                      searchData={searchDeletedFile}
                      setSearchData={setSearchDeletedFile}
                      paginationRecords={paginationTrash}
                      setPaginationRecords={setPaginationTrash}
                      errorCharacters={errorCharacters}
                      setErrorCharacters={setErrorCharacters}
                      isRecordListExpedient={false}
                    />
                  </Col>

                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <FilterTrashElements
                      setFilterTrash={setFilterTrash}
                      setTimeZone={setTimeZone}
                      setSearchDeletedFile={setSearchDeletedFile}
                      paginationTrash={paginationTrash}
                      setPaginationTrash={setPaginationTrash}
                    />
                  </Col>

                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <Button
                      id="btn-buttonRestore"
                      className="mb-2 mr-2 btn-icon btn-block"
                      color="cyan"
                      disabled={disabledRestoreButton}
                      onClick={handleRestoreFiles}
                    >
                      <FontAwesomeIcon icon={faTrashRestore} className="mr-2" />
                      {i18n.t("trash.label19")}
                    </Button>
                  </Col>

                  <Col lg="auto" md="6" sm="12" className="align-self-end">
                    <Button
                      id="btn-buttonRestore"
                      className="mb-2 mr-2 btn-icon btn-block"
                      color="warning"
                      disabled={disabledRestoreButton}
                      onClick={handleDeleteFiles}
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      {i18n.t("buttonActions.option3")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <Alert color="info">
              <span className="pr-2 text-bold">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 " />
                <span className="font-weight-bold">{i18n.t("modal.DoneError.header")}</span>
                <span className="m-2">{i18n.t("trash.label20")}</span>
              </span>
            </Alert>

            <TrashFilesListTable
              listDeletedFiles={listDeletedFiles}
              isLoadingTrashFiles={isLoadingTrashFiles}
              filesSelectedToRestore={filesSelectedToRestore}
              setFilesSelectedToRestore={setFilesSelectedToRestore}
              paginationTrash={paginationTrash}
              setPaginationTrash={setPaginationTrash}
              totalPages={totalPages}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default TrashFilesList;
