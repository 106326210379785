import useTrd from "hooks/useTrd";
import { useTrdContext } from "contextAPI/TrdContext";
import { isNullOrUndefined } from "utils/validations";
import {
  showAlertDirectorySuccess,
  showAlertExpedientSuccess,
  showAlertSeriesSuccess,
  showAlertServiceError,
  showAlertSubDivisionSuccess,
  showAlertSubSectionSuccess,
  showAlertSubSeriesSuccess
}
from "utils/alerts";
import { HttpStatus } from "utils/enums";
import i18n from "locales/i18n";

const useClassification = () => {
  const {
    foldersId,
    parentData,
    isLoading,
    setIsLoading,
    expedientParent,
    setTrdForm,
    getTreeByDomainCallback,
    isByRecord,
    setSubmitByRecord,
    createSubDivisionForm,
    setCreateSubDivisionForm,
    createDirectoryForm,
    setCreateDirectoryForm,
    createSubDivisionFormSTR,
    setCreateSubDivisionFormSTR,
    createDirectoryFormSTR,
    setCreateDirectoryFormSTR,
    getSeriesSubseriesStructure,
    seieSubserieId,
    seieSubserieBackgroundId,
    subDivisionId,
    setSeieSubserieId,
    setSeieSubserieBackgroundId,
    setSubDivisionId,
    setValidateField,
    foldersIdByPath,
    expedientParentByPath,
    setRefreshDataExpedient,
  } = useTrdContext();

  const {
    createSubSectionBySection,
    createSerieBySectionOrSubSection,
    createSubserieBySerie,
    createExpedient,
    createSubdivisionByStructure,
    createDirectoryByStructure,
  } = useTrd();

  let backgroundObjt;
  let expedientId;

  /**
   * Update `expedientId` and `backgroundObjt` based on different conditions.
   * @param {string} pathConfiguration - The path configuration value.
   * @param {string} expedientParent - The expedient parent value.
   * @param {string} backgroudByPath - The background value by path.
   * @param {object} foldersId - The folders ID object.
   */

  if (isNullOrUndefined(expedientParentByPath) === false) {
    expedientId = expedientParentByPath;
    backgroundObjt = foldersIdByPath;
  } else {
    expedientId = expedientParent;
    backgroundObjt = foldersId;
  }

  const classificationRequest = (levelFolder, trdForm) => {
    switch (levelFolder) {
      case 2:
        createSubSectionBySection(trdForm, foldersId)
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              getTreeByDomainCallback();
              showAlertSubSectionSuccess();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setTrdForm({
              ...trdForm,
              level: 2,
              name: "",
              code: i18n.t("trd.inputTittle7"),
            });
            setIsLoading(false);
          });
        break;
      case 3:
        createSerieBySectionOrSubSection(trdForm, foldersId, parentData)
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              getTreeByDomainCallback();
              showAlertSeriesSuccess();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setTrdForm({
              ...trdForm,
              level: 3,
              name: "",
              code: i18n.t("trd.inputTittle7"),
            });
            setIsLoading(false);
          });

        break;
      case 4:
        createSubserieBySerie(trdForm, foldersId, parentData)
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              getTreeByDomainCallback();
              showAlertSubSeriesSuccess();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setTrdForm({
              ...trdForm,
              level: 4,
              name: "",
              code: i18n.t("trd.inputTittle7"),
            });
            setIsLoading(false);
          });
        break;
      case 5:
        createExpedient(trdForm, backgroundObjt, expedientId)
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              setRefreshDataExpedient(prevState => !prevState);
              showAlertExpedientSuccess();
              if (isByRecord) {
                setSubmitByRecord(true);
              } else {
                getTreeByDomainCallback();
              }
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case 6:
        createSubdivisionByStructure(
          trdForm,
          seieSubserieBackgroundId,
          seieSubserieId
        )
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              setValidateField(false);
              getSeriesSubseriesStructure(
                seieSubserieBackgroundId,
                seieSubserieId
              );
              showAlertSubDivisionSuccess();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoading(false);
            setCreateSubDivisionFormSTR({
              ...createSubDivisionFormSTR,
              subdivision_struct: [""],
              level: 6,
            });
            setCreateSubDivisionForm({
              ...createSubDivisionForm,
              name: "",
              level: 6,
            });
            setSeieSubserieId("");
            setSeieSubserieBackgroundId("");
          });
        break;
      case 7:
        createDirectoryByStructure(
          trdForm,
          seieSubserieBackgroundId,
          subDivisionId
        )
          .then((response) => {
            if (response.status === HttpStatus.CREATED) {
              setValidateField(false);
              getSeriesSubseriesStructure(
                seieSubserieBackgroundId,
                seieSubserieId
              );
              showAlertDirectorySuccess();
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoading(false);
            setCreateDirectoryFormSTR({
              ...createDirectoryFormSTR,
              serie_subserie_uuid: "",
              directory_struct: [""],
              level: 7,
            });
            setCreateDirectoryForm({
              ...createDirectoryForm,
              name: "",
              level: 7,
            });
            setSeieSubserieId("");
            setSeieSubserieBackgroundId("");
            setSubDivisionId("");
          });
        break;
      default:
        break;
    }
  };
  return { isLoading, setIsLoading, classificationRequest };
};

export default useClassification;
