import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Row, CardTitle, CardBody } from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { useParams } from "react-router-dom";
import { showAlertServiceError } from "utils/alerts";
import { enumLevelTrd, enumsSelectActionsDirectory, enumsSelectActionsDirectorySelect } from "utils/enums";
import EditDirectoryForm from "components/organism/EditDirectoryForm";
import useTrd from "hooks/useTrd";
import swal from "sweetalert";
import i18n from "locales/i18n";

const SelectActionsDirectory = (props) => {
  const { id, backgroundId } = useParams();
  const {
    selectLevel,
    editDirectory,
    setEditDirectory,
    foldersId,
    optionDisabled,
    optionDir,
    setOptionDir,
    setSelectLevel,
    createDirectoryForm,
    setCreateDirectoryForm,
    setLevelFolder,
    setOptionEdit,
    setOption,
    setShowCreateDirectoryForm,
    structureTrdSeriesSubseries,
  } = props;
  const {
    editDirectoryFormSTR,
    setEditDirectoryFormSTR,
    setShowCreateSubDivisonForm,
    setCreateSubDivisionForm,
    createSubDivisionForm,
    getSeriesSubseriesStructure,
  } = useTrdContext();
  const { deleteLevelOrdinationTrd } = useTrd();

  /**
   *Finds the directory ordination object within the 'structureTrdSeriesSubseries' array based on the 'subDirectoryId' property.
   *@param {array} structureTrdSeriesSubseries - an array of directory ordination objects
   *@param {string} foldersId.subDirectoryId - the subDirectoryId to match against the id property of the directory ordination object
   *@returns {object|null} - the found directory ordination object or null if not found
   */
  const nameDirOridation = structureTrdSeriesSubseries.find(
    (directoryOrdination) => {
      if (directoryOrdination !== null && directoryOrdination !== undefined) {
        return directoryOrdination.id === foldersId.subDirectoryId;
      }
      return directoryOrdination;
    }
  );

  /**
   *Function that deletes a level in the ordination of a TRD (Tabla de Retención Documental).
   *@param {number} id - the id of the level to be deleted
   *@param {number} nameDirOridation.id - the id of the directory in which the level is located
   *@param {number} nameDirOridation.level - the level to be deleted in the ordination
   *@param {function} deleteLevelOrdinationTrd - the function that deletes the level in the TRD ordination
   *@param {function} setLevelFolder - the state setter function for the level of the folder
   *@param {function} setShowCreateDirectoryForm - the state setter function for showing the create directory form
   *@param {function} getSeriesSubseriesStructure - the function that fetches the structure of series and subseries for a given background id and level id
   *@param {number} backgroundId - the id of the background
   */
  function deleteLevelInOrdinationTrd() {
    deleteLevelOrdinationTrd(id, nameDirOridation.id, nameDirOridation.level)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("trd.delteLevelSweetAlert"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          });
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setLevelFolder(enumLevelTrd.SUBDIVISION);
        setShowCreateSubDivisonForm(true);
        setCreateSubDivisionForm({
          ...createSubDivisionForm,
          name: "",
        });
        setShowCreateDirectoryForm(false);
        getSeriesSubseriesStructure(backgroundId, id);
      });
  }

  /**
   *Handles the change event of the subdirectory selection and performs corresponding actions based on the selected target directory.
   *@param {object} eventChangeSubDirectory - the event object representing the change event of the subdirectory selection
   *@param {string} targetDirectory - the selected target directory value
   *@param {object} editDirectoryForm - the state object representing the form for editing a directory
   *@param {string} nameDirectory - the label of the directory name
   *@param {object} editDirectoryFormSTR - the state object representing the form for editing a structured directory
   *@param {object} foldersId - the state object representing the ids of the folders
   *@param {string} nameDirOridation - the label of the directory name in the ordination
   *@param {number} selectLevel - the selected level value
   *@param {object} createDirectoryForm - the state object representing the form for creating a directory
   *@param {number} levelFolder - the level of the folder
   *@param {boolean} showCreateDirectoryForm - a boolean value indicating whether to show the create directory form
   */
  const handleOnChangeSubDirectory = (eventChangeSubDirectory) => {
    const targetDirectory = eventChangeSubDirectory.target.value;
    if (targetDirectory === enumsSelectActionsDirectory.SELECT_SUB_DIRECTORY) {
      setEditDirectoryFormSTR({
        ...editDirectoryFormSTR,
        base_subdivision_uuid: foldersId.subDivisionId,
        base_directory_uuid: foldersId.subDirectoryId,
        name: nameDirOridation.label.replace("(Sub Directorio)", ""),
      });
      setOptionDir(false);
      setEditDirectory(true);
    }

    if (targetDirectory === enumsSelectActionsDirectory.CREATE_DIRECTORY) {
      swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("trd.delteLevel"),
        icon: "info",
        buttons: [i18n.t("chooseNot"), i18n.t("chooseYes")],
      }).then((willDelete) => {
        if (willDelete) {
          deleteLevelInOrdinationTrd();
        } else {
          setSelectLevel(enumLevelTrd.DIRECTORY);
          setCreateDirectoryForm({
            ...createDirectoryForm,
            level: Number(enumLevelTrd.DIRECTORY),
          });
          setLevelFolder(Number(enumLevelTrd.DIRECTORY));
          setShowCreateDirectoryForm(true);
          setOption(false);
          setOptionEdit(false);
        }
      });
    }
  };

  if (optionDir === true) {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <CardBody>
            <CardTitle>{`${i18n.t("buttonActions.title")} ${i18n.t(
              "trd.formTittle32.2"
            )}`}</CardTitle>
            <AvForm>
              <Row>
                <Col md="12">
                  <AvGroup>
                    <AvField
                      id="actions"
                      name="Actions"
                      type="select"
                      onChange={handleOnChangeSubDirectory}
                      value={selectLevel}
                      disabled={optionDisabled}
                      required
                      errorMessage={i18n.t("trd.selectTittle")}
                    >
                      <>
                        <option value={enumsSelectActionsDirectorySelect.SELECT_OPTION_EMPTY}>{i18n.t("trd.selectTittle")}</option>
                        <option value={enumsSelectActionsDirectorySelect.SELECT_EDIT_SUB_DIRECTORY}>
                          {`${i18n.t("buttonActions.option2")} ${i18n.t(
                            "trd.formTittle32.2"
                          )}`}
                        </option>
                        <option value={enumsSelectActionsDirectorySelect.SELECT_DELETE_SUB_DIRECTORY}>
                          {i18n.t("buttonActions.option3") +
                            " " +
                            i18n.t("trd.formTittle44")}
                        </option>
                      </>
                    </AvField>
                  </AvGroup>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </CSSTransitionGroup>
      </Fragment>
    );
  }

  if (optionDir === false && editDirectory === true) {
    return <EditDirectoryForm />;
  }
};

SelectActionsDirectory.propTypes = {
  selectLevel: PropTypes.number.isRequired,
  editDirectory: PropTypes.bool.isRequired,
  setEditDirectory: PropTypes.func.isRequired,
  foldersId: PropTypes.object.isRequired,
  optionDisabled: PropTypes.bool.isRequired,
  optionDir: PropTypes.bool.isRequired,
  setOptionDir: PropTypes.func.isRequired,
  setSelectLevel: PropTypes.func.isRequired,
  createDirectoryForm: PropTypes.object.isRequired,
  setCreateDirectoryForm: PropTypes.func.isRequired,
  setOption: PropTypes.func.isRequired,
  setOptionEdit: PropTypes.func.isRequired,
  setLevelFolder: PropTypes.func.isRequired,
  setShowCreateDirectoryForm: PropTypes.func.isRequired,
  structureTrdSeriesSubseries: PropTypes.array.isRequired,
};

export default SelectActionsDirectory;
