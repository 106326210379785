import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import ConsultLists from "components/organism/ConsultLists";
import i18n from "locales/i18n";

const ConsultList = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("list.designerItem3")}
				subheading={i18n.t("consultList.designerMotto")}
				icon="menu"
			>
				<ConsultLists />
			</AdminTemplate>
		</Fragment>
	);
};

export default ConsultList;