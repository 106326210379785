import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import { useDocumentaryContext } from "contextAPI/DocumentaryTypesContext";
import i18n from "locales/i18n";

const ButtonCreateDocumentaryTypes = () => {
  const { setIsCreate } = useDocumentaryContext();

  /**
   *Function that sets the 'isCreate' state variable to true, indicating that the user wants to create a new documentary type.
   *@param {function} setIsCreate - the state setter function for the 'isCreate' state variable
   */
  const handleOnclickCreateDocumentaryType = () => {
    setIsCreate(true);
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              onClick={handleOnclickCreateDocumentaryType}
            >
              <span>{i18n.t("documentary.type.table.button")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonCreateDocumentaryTypes;
