import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useTrdContext } from "contextAPI/TrdContext";
import { Alert, Card, CardFooter, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  faInfoCircle,
  faArrowAltCircleLeft,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Splitter from "m-react-splitters";
import TrdOrdinationUnitTree from "components/organism/TrdOrdinationUnitTree";
import TrdForm from "components/organism/TrdForm";
import useTrd from "hooks/useTrd";
import i18n from "locales/i18n";

const TrdOrdinationUnit = () => {
  const { id, backgroundId } = useParams();
  const {
    foldersId,
    setStructureTrd,
    reStructureData,
    setIsStructure,
    setShowCreateSubDivisonForm,
    setShowCreateDirectoryForm,
    setLevelFolder,
  } = useTrdContext();
  const { getTreeByDomain } = useTrd();
  const [sectionName, setSectionName] = useState("");

  /**
   *Callback function created using useCallback that retrieves tree data by domain and performs various operations on the data to set
   *state variables and update component behavior.
   *@param {function} getTreeByDomain - the function that retrieves tree data by domain
   *@param {number} backgroundId - the id of the background
   *@param {number} foldersId.sectionId - the id of the section within the folders
   *@param {number} id - the id of the element
   *@param {function} setIsStructure - the state setter function for the 'isStructure' variable indicating if the structure is active
   *@param {function} setStructureTrd - the state setter function for the transformed tree data
   *@param {function} setSectionName - the state setter function for the name of the section
   *@param {function} setShowCreateSubDivisonForm - the state setter function for the visibility of the create sub-division form
   */
  const getTreeByDomainCallback = useCallback(() => {
    setIsStructure(true);
    getTreeByDomain().then((response) => {
      if (response.status === 200) {
        setStructureTrd(reStructureData(response.data.data));
        let elementsStructureTree = response.data.data;

        const nameElements = elementsStructureTree.map((elementlevelTrd) => {
          if (
            elementlevelTrd.level === 0 &&
            elementlevelTrd.id === backgroundId
          )
            return `${elementlevelTrd.label} /... / `;

          if (
            elementlevelTrd.level === 1 &&
            elementlevelTrd.parentId === backgroundId &&
            elementlevelTrd.background_parent_id === backgroundId &&
            elementlevelTrd.id === foldersId.sectionId
          )
            return `${elementlevelTrd.label} / `;

          if (
            (elementlevelTrd.level === 3 || 4) &&
            elementlevelTrd.background_parent_id === backgroundId &&
            elementlevelTrd.id === id
          )
            return `${elementlevelTrd.label}`;
          return "";
        });
        setSectionName(nameElements);
        setShowCreateSubDivisonForm(true);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *Function that handles the click event for creating a directory. It sets the level of the folder, shows the form for creating a
   *sub-division, and hides the form for creating a directory.
   *@param {function} setLevelFolder - the state setter function for the level of the folder
   *@param {function} setShowCreateSubDivisonForm - the state setter function for showing the form to create a sub-division
   *@param {function} setShowCreateDirectoryForm - the state setter function for hiding the form to create a directory
   */
  const handleOnClickCreateDirectory = () => {
    setLevelFolder(6);
    setShowCreateSubDivisonForm(true);
    setShowCreateDirectoryForm(false);
  };

  /**
   *useEffect hook that invokes the 'getTreeByDomainCallback' function to fetch tree data for a specific domain.
   *@param {function} getTreeByDomainCallback - the callback function responsible for fetching tree data by domain
   */
  useEffect(() => {
    getTreeByDomainCallback();
  }, [getTreeByDomainCallback]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter>
            <div className="card-header-title font-size-lg text-capitalize font-weight-bold text-primary">
              {i18n.t("trd.ordinationTitle")}
            </div>
            <div className="btn-actions-pane-right">
              <Link to={`/trd/expedient/${id}/${backgroundId}`}>
                <Button className="button mr-2" color="cyan">
                  <FontAwesomeIcon
                    icon={faArrowAltCircleLeft}
                    className="mr-2"
                  />
                  <span>{i18n.t("form.field59")}</span>
                </Button>
              </Link>

              <Button
                className="button"
                color="success"
                onClick={handleOnClickCreateDirectory}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>{i18n.t("trd.formTittle7.1")}</span>
              </Button>
            </div>
          </CardFooter>
          <div className="p-1">
            <Alert className="mbg-3" color="info" isOpen={true}>
              <span className="pr-2">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              {i18n.t("trd.alertExpedient")}: <strong>{sectionName}</strong>
            </Alert>
          </div>

          <div className="app-inner-layout rm-sidebar">
            <div className="app-inner-layout__wrapper">
              <div className="app-inner-layout__content ">
                <Splitter
                  position="vertical"
                  primaryPaneMaxWidth="50%"
                  primaryPaneMinWidth="50%"
                  primaryPaneWidth="400px"
                  dispatchResize={true}
                  postPoned={false}
                >
                  <div className="he-100">
                    <Card color="gray" className="card-border he-100 p-1">
                      <TrdOrdinationUnitTree
                        id={id}
                        backgroundId={backgroundId}
                      />
                    </Card>
                  </div>
                  <div className="he-100">
                    <Card className="card-border he-100">
                      <TrdForm />
                    </Card>
                  </div>
                </Splitter>
              </div>
            </div>
          </div>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default TrdOrdinationUnit;
