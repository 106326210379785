import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, CardTitle } from "reactstrap";
import { getDateFormat } from "utils/getDateFormat";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { INTEGRATIONS } from "constants/securityConst";
import ReactTable from "react-table";
import ButtonActionsFIles from "components/atoms/ButtonActionsFIles";
import RecordFieldListInfo from "components/organism/RecordFieldListInfo";
import ModalTrashFile from "components/molecules/ModalTrashFile";
import i18n from "locales/i18n";

const RecordFileListTable = (props) => {
  const {
    files,
    isLoadingFiles,
    recordId,
    recordUuid,
    formId,
    trdExistRec,
    totalPagesFilesRecord,
    paginationRecord,
    setPaginationRecord,
  } = props;
  const {
    setIsChangePage,
    isOpenFileEdit,
    setIsOpenFileEdit,
    handleOpenEditFile,
    closeFileTables,
    handleOpenMoveTrash,
    isOpenFileMoveTrash,
    setIsOpenFileMoveTrash,
  } = useRecordDetailContext();
  const [typeConfigurationFile] = useState("settled");
  const INTEGRATIONSOPT = window.localStorage.getItem(INTEGRATIONS);
  let modalFileToShow = null;

  /**
   * Conditionally assigns a modal JSX element for moving a file to the trash
   * to the modalFileToShow variable if isOpenFileMoveTrash is true, otherwise assigns null.
   * @param {boolean} isOpenFileMoveTrash - A boolean indicating whether the file move to trash modal is open.
   * @param {function} setIsOpenFileMoveTrash - A function to set the isOpenFileMoveTrash state.
   * @returns {JSX.Element|null} The JSX element representing the modal or null.
   */
  if (isOpenFileMoveTrash === true) {
    modalFileToShow = (
      <ModalTrashFile
        isOpenFileMoveTrash={isOpenFileMoveTrash}
        setIsOpenFileMoveTrash={setIsOpenFileMoveTrash}
      />
    );
  } else {
    modalFileToShow = null;
  }

  /**
   * Conditionally returns a JSX element representing a record field list information component
   * if isOpenFileEdit is true, otherwise returns null.
   * @param {boolean} isOpenFileEdit - A boolean indicating whether the file edit is open.
   * @param {Array} files - An array of files.
   * @param {function} setIsOpenFileEdit - A function to set the isOpenFileEdit state.
   * @returns {JSX.Element|null} The JSX element representing the record field list info component or null.
   */
  if (isOpenFileEdit === true) {
    return <RecordFieldListInfo files={files} setIsOpenFileEdit={setIsOpenFileEdit} />;
  }

  if (closeFileTables === true) {
    return (
      <Fragment>
        <Row>
          <Col md="12" className="table-files-record">
            <CardTitle>{i18n.t("recordFile.TableName")}</CardTitle>
            <ReactTable
              data={files}
              columns={[
                {
                  columns: [
                    {
                      Header: [i18n.t("record.affair")],
                      id: "link",
                      accessor: "subject",
                      Cell: ({ row }) => {
                        return (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${row._original.presigned_url}`}
                          >
                            {row.link}
                          </a>
                        );
                      },
                    },
                    {
                      Header: [i18n.t("record.supportType")],
                      accessor: "support_type",
                      Cell: ({ value }) => {
                        let supportType = "";

                        if (value === "1") {
                          supportType = i18n.t("record.supportType.option1");
                        } else if (value === "2") {
                          supportType = i18n.t("record.supportType.option2");
                        } else {
                          supportType = i18n.t("record.supportType.option3");
                        }
                        return <span>{supportType}</span>;
                      },
                    },
                    {
                      Header: [i18n.t("record.dateDocument")],
                      accessor: "publication_at",
                      Cell: ({ value }) => {
                        let valueFinal = getDateFormat(new Date(value));

                        return <p>{valueFinal}</p>;
                      },
                    },
                    {
                      Header: [i18n.t("recordFile.publishDate")],
                      accessor: "created_at",
                      Cell: ({ value }) => {
                        let valueFinal = getDateFormat(new Date(value));

                        return <p>{valueFinal}</p>;
                      },
                    },
                    {
                      Header: [i18n.t("createusers.label1")],
                      accessor: "user_name",
                    },
                  ],
                },

                {
                  columns: [
                    {
                      Header: [i18n.t("buttonActions.title")],
                      accessor: "actions",
                      Cell: (row) => {
                        return (
                          <div className="d-block w-100 text-center">
                            <ButtonActionsFIles
                              fileId={row.original.uuid}
                              recordId={recordId}
                              recordUuid={recordUuid}
                              formId={formId}
                              trdExistRec2={trdExistRec}
                              nameFile={row.original.name}
                              subjectFile={row.original.subject}
                              publication={row.original.publication_at}
                              integrationOption={INTEGRATIONSOPT}
                              typeExtension={row.original.type}
                              handleOpenEditFile={handleOpenEditFile}
                              typeConfigurationFile={typeConfigurationFile}
                              showActionsButtons={false}
                              handleOpenMoveTrash={handleOpenMoveTrash}
                            />
                          </div>
                        );
                      },
                    },
                  ],
                },
              ]}
              manual
              className="-striped -highlight"
              pages={totalPagesFilesRecord}
              page={paginationRecord.page - 1}
              showPageJump={false}
              defaultPageSize={paginationRecord.per_page}
              loading={isLoadingFiles}
              loadingText={i18n.t("loading")}
              noDataText={`${i18n.t("tableRowsEmpty")}`}
              previousText={`${i18n.t("previousText")}`}
              nextText={`${i18n.t("nextText")}`}
              pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
              ofText={<span className="px-2">{i18n.t("ofText")}</span>}
              onPageSizeChange={(per_page) => {
                setPaginationRecord({
                  ...paginationRecord,
                  per_page: per_page,
                });
              }}
              onPageChange={(page) => {
                setPaginationRecord({
                  ...paginationRecord,
                  page: page + 1,
                });
                setIsChangePage(true);
              }}
            />
          </Col>
        </Row>
        {modalFileToShow}
      </Fragment>
    );
  } else if (closeFileTables === false) {
    return null;
  }
};

RecordFileListTable.propTypes = {
  files: PropTypes.array.isRequired,
  isLoadingFiles: PropTypes.bool,
  recordId: PropTypes.string.isRequired,
  recordUuid: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  trdExistRec: PropTypes.bool.isRequired,
  totalPagesFilesRecord: PropTypes.number.isRequired,
  paginationRecord: PropTypes.object.isRequired,
  setPaginationRecord: PropTypes.bool.isRequired,
};

export default RecordFileListTable;
