import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { initialPaginationFieldConfig } from "utils/initialPaginationsConfig";
import { showAlertServiceError } from "utils/alerts";
import { enumsListStatus } from "utils/enums";
import cx from "classnames";
import useList from "hooks/useList";
import i18n from "locales/i18n";

const ComboBox = (props) => {
  const {
    id,
    label,
    hide,
    required,
    isPreview,
    isFilterForm,
    itemLink,
    handleOnChangeRecord,
    value,
  } = props;

  const { getItems } = useList();
  const [pagination] = useState(initialPaginationFieldConfig);
  const [itemsByList, setListByList] = useState([]);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  let hideLabelComboBox = null;
  let nameItemValue = "";
  let loadingComboBox = null;

  /**
   * Sets the `loadingComboBox` content based on the `isLoadingItems` flag.
   * @param {boolean} isLoadingItems - A flag indicating whether items are currently being loaded.
   * @returns {string} The localized text to be displayed in the combo box. 
   */
  if (isLoadingItems === true) {
    loadingComboBox = (i18n.t("loading"));
  } else {
    loadingComboBox = (i18n.t("form.designerLabel10.1"));
  }

  /**
   * Conditionally generates a JSX element to hide or show a labeled comboBox.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled comboBox.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled comboBox with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelComboBox = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelComboBox = null;
  }

  /**
   * Updates the 'nameItemValue' based on the provided 'value' object.
   * This function checks if the 'value' object is defined and if its 'name' property is not null.
   * If both conditions are met, it assigns the value of 'value.uuid' to 'nameItemValue'.
   * @param {object} value - The object to extract the 'uuid' property from.
   * @param {string} nameItemValue - The variable to be updated with the 'uuid' value if conditions are met.
   * @returns {void}
   */
  if (value !== undefined && value.name !== null) {
    nameItemValue = value.uuid;
  }

  /**
   * Handles the change event for a combo box and updates the record with the new value.
   * @param {Event} eventChangeComboBox - The change event triggered by the combo box.
   */
  const handleChangeComboBox = (eventChangeComboBox) => {
    handleOnChangeRecord(id, eventChangeComboBox.target.value)
  }

  /**
   * Generate an array of option elements based on the status of items in the provided list.
   * @param {Array} itemsByList - The list of items to generate options from.
   * @returns {Array} An array of JSX option elements.
   */
  const generateOptions = (itemsByList) => {
    return itemsByList.map((item) => {
      if (item.status === enumsListStatus.ACTIVE && isFilterForm === false) {
        return (
          <option id={item.uuid} key={item.uuid} value={item.uuid}>
            {item.name}
          </option>
        );
      }

      if (isFilterForm === true) {
        return (
          <option id={item.uuid} key={item.uuid} value={item.name}>
            {item.name}
          </option>
        );
      }

      return null;
    });
  };

  /**
   * Fetches items using the provided item link and updates the list based on specified parameters.
   * @param {string} itemLink - The link or identifier for the items to be fetched.
   * @param {number} pageNumber - The page number of items to retrieve.
   * @param {number} perPage - The number of items to fetch per page.
   * @param {string} searchQuery - Optional search query to filter items (can be an empty string).
   * @returns {void}
   */
  useEffect(() => {
    if (isPreview === false && hide === true) return null;
    const { per_page } = pagination;
    setIsLoadingItems(true);
    getItems(itemLink, 1, per_page, "")
      .then((response) => {
        const itemsList = response.data.items;
        if (isNullOrUndefined(itemsList) === false) {
          setListByList(itemsList);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingItems(false);
      });
    // eslint-disable-next-line
  }, [itemLink, hide, pagination]);

  /**
   * Renders a form with a select field conditionally based on the preview, hide, and filter form status.
   * @param {boolean} isPreview - Determines if the component is in preview mode.
   * @param {boolean} hide - Determines if the component should be hidden.
   * @param {boolean} isFilterForm - Determines if the component is part of a filter form.
   * @param {string} id - The unique identifier for the select field.
   * @param {string} label - The label text for the select field.
   * @param {boolean} required - Determines if the select field is required.
   * @param {Function} handleChangeComboBox - The function to handle change events for the select field.
   * @param {Function} handleOnChangeRecord - The function to handle change events for filter forms.
   * @param {JSX.Element} hideLabelComboBox - The element to display when in preview mode.
   * @param {Array} itemsByList - The list of items to generate options for the select field.
   * @param {string} nameItemValue - The current value of the select field.
   * @param {Function} generateOptions - The function to generate options for the select field.
   * @param {string} loadingComboBox - The loading text for the combo box.
   * @param {Function} i18n - The internationalization function for translating text.
   * @param {string} isRequired - The class name for required fields.
   * @returns {JSX.Element|null} The rendered form with the select field or null if hidden.
   */
  if (isPreview === true) {
    return (
      <AvForm>
        <AvGroup row>
          <Label className={cx("", {
            "is-required": required === true
          })} sm={3}>
            {label} {hideLabelComboBox}
          </Label>
          <Col md={8}>
            <AvField
              id={id}
              name={id}
              type="select"
              key={id}
              required={required}
              onChange={handleChangeComboBox}
              errorMessage={i18n.t("form.field46")}
              value={nameItemValue}
            >
              <option value="">{i18n.t("form.designerLabel10.1")}</option>
              {generateOptions(itemsByList)}
            </AvField>
          </Col>
        </AvGroup>
      </AvForm>
    );
  } else if (hide === false && isFilterForm === false) {
    return (
      <AvGroup row>
        <Label className={cx("", {
          "is-required": required === true
        })} sm={3}>
          {label}
        </Label>
        <Col md={8}>
          <AvField
            id={id}
            name={id}
            type="select"
            key={id}
            required={required}
            onChange={handleChangeComboBox}
            errorMessage={i18n.t("form.field46")}
            value={nameItemValue}
          >
            <option value="">
              {loadingComboBox}
            </option>
            {generateOptions(itemsByList)}
          </AvField>
        </Col>
      </AvGroup>
    );
  } else if (isFilterForm === true) {
    return (
      <Col md={12}>
        <AvField
          key={id}
          id={id}
          name={id}
          type="select"
          required={required}
          onChange={handleOnChangeRecord}
          errorMessage={i18n.t("form.field46")}
          value={nameItemValue}
        >
          <option value="">
            {loadingComboBox}
          </option>
          {generateOptions(itemsByList)}
        </AvField>
      </Col>
    );
  } else {
    return null;
  };
};

ComboBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  isFilterForm: PropTypes.bool,
  itemLink: PropTypes.string.isRequired,
  handleOnChangeRecord: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default React.memo(ComboBox);
