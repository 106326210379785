export const handleFieldsErrors = (errorData) => {
  const errorKeys = [];

  Object.keys(errorData).forEach((externalKey) => {
    const arrayOfErrors = errorData[externalKey];
    arrayOfErrors.forEach((error) => {
      for (const key in error) {
        if (error.hasOwnProperty(key)) {
          errorKeys.push(key);
        }
      }
    });
  });

  return errorKeys;
}