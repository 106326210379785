import i18n from "locales/i18n";
import range from "lodash/range";
import { getYear } from "date-fns";

export const years = range(1950, getYear(new Date()) + 80, 1);
export const months = [
  i18n.t("datapicker.Month1"),
  i18n.t("datapicker.Month2"),
  i18n.t("datapicker.Month3"),
  i18n.t("datapicker.Month4"),
  i18n.t("datapicker.Month5"),
  i18n.t("datapicker.Month6"),
  i18n.t("datapicker.Month7"),
  i18n.t("datapicker.Month8"),
  i18n.t("datapicker.Month9"),
  i18n.t("datapicker.Month10"),
  i18n.t("datapicker.Month11"),
  i18n.t("datapicker.Month12"),
];
