import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  DashboardNav,
  CreateRecordNav,
  ConsultRecordNav,
  ConsultRecordNavTrdExplorer,
  TaskBoxNav,
  ReportsNav,
  ProcessManagementNav,
  TrashNav,
  ParameterNav,
  FormsDesignerNav,
  FormsDesignerNavConsult,
  ListDesignerNav,
  AdminUsersAndRoleGroupsNav,
  AdminUsersAndRoleNav,
  AdminUsersGroupsNav,
  AdminRoleGroupsNav,
  AdminUsersNav,
  AdminUsersRoleNav,
  MetricsNav,
  WorkFlowDesignerNav,
  IntegrationsNav,
  WorkFlowDesignerNavConsult,
  AdminUserWorkGroupNav,
  ParameterDocumentaryTypeNav,
  DataExportNav,
} from "./NavItems";
import {
  TYPE,
  INTEGRATIONS,
  ROLES_PERMISSIONS,
  DESIGNER_FORMS,
  DESIGNER_FORMS_PERMISSIONS,
  WORKFLOW_ALL_FORMS,
  WORKFLOW_FORMS_PERMISSIONS,
} from "constants/securityConst";
import { enumsRolesPermissions, enumsTypeUser } from "utils/enums";
import useLogin from "hooks/useLogin";
import useShowConsultExpedient from "customsHooks/useShowConsultExpedient";
import MetisMenu from "react-metismenu";
import i18n from "locales/i18n";

const Nav = () => {
  const USERTYPE = window.localStorage.getItem(TYPE);
  const ROLE_PERMISSION = window.localStorage.getItem(ROLES_PERMISSIONS);

  //APPLICATION OF PERMISSIONS IN FORMS
  const DESIGNER_FORM = window.localStorage.getItem(DESIGNER_FORMS);
  const DESIGNER_FROMS_PERMISSION = window.localStorage.getItem(DESIGNER_FORMS_PERMISSIONS);

  //APPLICATION OF PERMISSIONS IN WORKFLOWS
  const WORKFLOW_ALL_FORM = window.localStorage.getItem(WORKFLOW_ALL_FORMS);
  const WORKFLOW_FORMS_PERMISSION = window.localStorage.getItem(WORKFLOW_FORMS_PERMISSIONS);

  //APPLICATION OF PERMISSIONS IN INTEGRATIONS
  const INTEGRATIONSOPT = window.localStorage.getItem(INTEGRATIONS);

  //CONTROL AND PERMISSIONS
  const { isLogged } = useLogin();
  const { viewSubMenuExpedientConsult } = useShowConsultExpedient();

  let access;
  let accessTRD;
  let accessDesignerForms;
  let accessDesignerFormsConsult;
  let accessWorkflowsForms;
  let accessWorkflowsFormsConsult;
  let accessList;
  let accessUserManager;
  let accessRoles;
  let accessIntegrations;
  let accessWorkGroups;

  /**
   * Checks if the user is logged in and has a user type other than QUERY.
   * Sets access to true if conditions are met, false otherwise.
   */
  if (isLogged === true && USERTYPE !== enumsTypeUser.QUERY) {
    access = true;
  } else {
    access = false;
  }

  /**
   * Checks the user's access to TRD based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as a query user,
   * sets the access to TRD to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.TRD_CONSFIGURATION)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessTRD = true;
  } else {
    accessTRD = false;
  }

  /**
   * Checks the user's access to designer forms based on their login status, user type, and designer form permissions.
   * If the user is logged in, has a standard user type, and has designer form permissions, or if the user is logged in as an admin,
   * sets the access to designer forms to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true && USERTYPE === enumsTypeUser.STANDARD && DESIGNER_FORM === "true") ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessDesignerForms = true;
  } else {
    accessDesignerForms = false;
  }

  /**
   * Checks if the user is logged in, has a standard user type, and has permission for designer forms.
   * Sets accessDesignerFormsConsult to true if conditions are met, false otherwise.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    DESIGNER_FROMS_PERMISSION !== ""
  ) {
    accessDesignerFormsConsult = true;
  } else {
    accessDesignerFormsConsult = false;
  }

  /**
   * Checks the user's access to the workflows forms based on their login status, user type, and permissions.
   * Sets the accessWorkflowsForms variable to true if the user is logged in as a standard user with access to all workflow forms or as an admin; otherwise, sets it to false.
   */
  if (
    (isLogged === true && USERTYPE === enumsTypeUser.STANDARD && WORKFLOW_ALL_FORM === "true") ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessWorkflowsForms = true;
  } else {
    accessWorkflowsForms = false;
  }

  /**
   * Checks if the user is logged in, has a standard user type, and has permission to access workflow forms.
   * Sets the accessWorkflowsFormsConsult variable accordingly.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    WORKFLOW_FORMS_PERMISSION !== ""
  ) {
    accessWorkflowsFormsConsult = true;
  } else {
    accessWorkflowsFormsConsult = false;
  }

  /**
   * Checks the user's access rights based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as an admin,
   * sets the accessList flag to true; otherwise, sets it to false.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.LIST_DESIGNER)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessList = true;
  } else {
    accessList = false;
  }

  /**
   * Checks the user's access rights to the user manager based on their login status, user type, and role permissions.
   * If the user is logged in as a standard user with specific role permissions or as an admin, grants access to the user manager.
   * @returns {boolean} - True if the user has access to the user manager, false otherwise.
   */
  if (
    (isLogged === true &&
      USERTYPE === enumsTypeUser.STANDARD &&
      ROLE_PERMISSION.includes(enumsRolesPermissions.USER_MANAGER)) ||
    (isLogged === true && USERTYPE === enumsTypeUser.ADMIN)
  ) {
    accessUserManager = true;
  } else {
    accessUserManager = false;
  }

  /**
   * Checks the user's access roles based on login status, user type, and role permissions.
   * If the user is logged in, has a standard user type, and specific role permissions, sets accessRoles to true.
   * If the user is logged in and has an admin user type, sets accessRoles to true.
   * Otherwise, sets accessRoles to false.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    ROLE_PERMISSION.includes(enumsRolesPermissions.ROLE_MANAGER)
  ) {
    accessRoles = true;
  } else if (isLogged === true && USERTYPE === enumsTypeUser.ADMIN) {
    accessRoles = true;
  } else {
    accessRoles = false;
  }

  /**
   * Determines the access to integrations based on the user's login status and user type.
   * If the user is logged in and not an admin, access to integrations is denied; otherwise, access is granted.
   */
  if (isLogged === true && USERTYPE !== enumsTypeUser.ADMIN) {
    accessIntegrations = false;
  } else {
    accessIntegrations = true;
  }

  /**
   * Determines if the user has access to work groups based on their login status, user type, and role permissions.
   * @param {boolean} isLogged - Indicates if the user is logged in.
   * @param {string} USERTYPE - The type of user (QUERY, STANDARD, ADMIN).
   * @param {array} ROLE_PERMISSION - The array of role permissions.
   * @returns {boolean} - True if the user has access to work groups, false otherwise.
   */
  if (
    isLogged === true &&
    USERTYPE === enumsTypeUser.STANDARD &&
    ROLE_PERMISSION.includes(enumsRolesPermissions.WORK_GROUP_MANAGER)
  ) {
    accessWorkGroups = true;
  } else if (isLogged === true && USERTYPE === enumsTypeUser.ADMIN) {
    accessWorkGroups = true;
  } else {
    accessWorkGroups = false;
  }

  /**
   *Toggles the state of the mobile sidebar menu.
   *@param {boolean} enableMobileMenu - A boolean representing the current state of the mobile sidebar menu. True if the menu is enabled, false otherwise.
   *@param {function} setEnableMobileMenu - A function used to set the state of the mobile sidebar menu. It takes a boolean parameter to enable or disable the menu.
   */
  const toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <Fragment>
      <h5 className="app-sidebar__heading"> </h5>
      <MetisMenu
        content={DashboardNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      <h5 className="app-sidebar__heading">{i18n.t("verticalNav.tittle1")}</h5>
      <MetisMenu
        content={CreateRecordNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />
      {(() => {
        if (viewSubMenuExpedientConsult === true) {
          return (
            <MetisMenu
              content={ConsultRecordNavTrdExplorer}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return (
            <MetisMenu
              content={ConsultRecordNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        }
      })()}

      <MetisMenu
        content={TaskBoxNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      {(() => {
        if (INTEGRATIONSOPT[1] === "1") {
          return (
            <MetisMenu
              content={ReportsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true) {
          return (
            <MetisMenu
              content={ProcessManagementNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      <MetisMenu
        content={TrashNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      <MetisMenu
        content={DataExportNav}
        onSelected={toggleMobileSidebar}
        activeLinkFromLocation
        className="vertical-nav-menu"
        iconNamePrefix=""
        classNameStateIcon="pe-7s-angle-down"
      />

      {(() => {
        if (access === true) {
          return <h5 className="app-sidebar__heading">{i18n.t("verticalNav.tittle2")}</h5>;
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true && accessTRD === true) {
          return (
            <MetisMenu
              content={ParameterNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true && accessTRD === false) {
          return (
            <MetisMenu
              content={ParameterDocumentaryTypeNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessDesignerForms === true &&
          accessDesignerFormsConsult === false
        ) {
          return (
            <MetisMenu
              content={FormsDesignerNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessDesignerForms === false &&
          accessDesignerFormsConsult === true
        ) {
          return (
            <MetisMenu
              content={FormsDesignerNavConsult}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true && accessList === true) {
          return (
            <MetisMenu
              content={ListDesignerNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === true &&
          accessRoles === true &&
          accessWorkGroups === true
        ) {
          return (
            <MetisMenu
              content={AdminUsersAndRoleGroupsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === true &&
          accessRoles === true &&
          accessWorkGroups === false
        ) {
          return (
            <MetisMenu
              content={AdminUsersAndRoleNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === true &&
          accessRoles === false &&
          accessWorkGroups === true
        ) {
          return (
            <MetisMenu
              content={AdminUsersGroupsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === false &&
          accessRoles === true &&
          accessWorkGroups === true
        ) {
          return (
            <MetisMenu
              content={AdminRoleGroupsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === true &&
          accessRoles === false &&
          accessWorkGroups === false
        ) {
          return (
            <MetisMenu
              content={AdminUsersNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === false &&
          accessRoles === true &&
          accessWorkGroups === false
        ) {
          return (
            <MetisMenu
              content={AdminUsersRoleNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessUserManager === false &&
          accessRoles === false &&
          accessWorkGroups === true
        ) {
          return (
            <MetisMenu
              content={AdminUserWorkGroupNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessWorkflowsForms === true &&
          accessWorkflowsFormsConsult === false
        ) {
          return (
            <MetisMenu
              content={WorkFlowDesignerNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (
          access === true &&
          accessWorkflowsForms === false &&
          accessWorkflowsFormsConsult === true
        ) {
          return (
            <MetisMenu
              content={WorkFlowDesignerNavConsult}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true) {
          return (
            <MetisMenu
              content={MetricsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true && accessIntegrations === true) {
          return <h5 className="app-sidebar__heading">{i18n.t("integrations.nav")}</h5>;
        } else {
          return null;
        }
      })()}

      {(() => {
        if (access === true && accessIntegrations === true) {
          return (
            <MetisMenu
              content={IntegrationsNav}
              onSelected={toggleMobileSidebar}
              activeLinkFromLocation
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          );
        } else {
          return null;
        }
      })()}
    </Fragment>
  );
};

/**
 *Maps the state properties to the props of a component for accessing 'enableMobileMenu' state from the Redux store.
 *@param {Object} state - The current state object from the Redux store.
 *@returns {Object} An object containing the 'enableMobileMenu' property that will be accessible as a prop in the component.
 */
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

/**
 *Connects the 'setEnableMobileMenu' action creator to the component's props,
 *allowing the component to dispatch the action to enable or disable the mobile menu through the Redux store.
 *@param {function} dispatch - A function provided by Redux to dispatch actions to the store
 *@returns {object} An object containing the 'setEnableMobileMenu' prop, which is a function that can be used to
 *dispatch the action to enable or disable the mobile menu.
 */
const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
