import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { getDateFormat2 } from "utils/getDateFormat2";
import { enumsFileLogAction, enumsModules } from "utils/enums";
import { extractArrayFromString, isNullOrUndefined } from "utils/validations";
import ReactTable from "react-table";
import i18n from "locales/i18n";

const AuditLogsListTable = (props) => {
  const { logsLists, pagination, setPagination, isLoadingGetLogs, totalPages } = props;

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <ReactTable
          data={logsLists}
          columns={[
            {
              columns: [
                {
                  Header: i18n.t("AuditLogs.Module"),
                  accessor: "module",
                  Cell: ({ value }) => {
                    let module = "";
                    if (isNullOrUndefined(value) === false) {
                      switch (value) {
                        case enumsModules.FILES:
                          module = <span>{i18n.t("recordDetail.files")}</span>;
                          break;

                        default:
                          break;
                      }

                      return <span>{module}</span>;
                    } else {
                      return <span>{i18n.t("metrics.noData")}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("log.user.header1"),
                  accessor: "action",
                  Cell: ({ value }) => {
                    let actionFile = "";
                    if (isNullOrUndefined(value) === false) {
                      switch (value) {
                        case enumsFileLogAction.UPLOAD_FILES:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsUploadFiles")}</span>;
                          break;
                        case enumsFileLogAction.GET_FILES:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsGetFiles")}</span>;
                          break;
                        case enumsFileLogAction.MOVE_FILE_LOCATION:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsMoveFileLocation")}</span>;
                          break;
                        case enumsFileLogAction.UPDATE_FILE_DATA:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsUpdateFileData")}</span>;
                          break;
                        case enumsFileLogAction.ELECTRONIC_SIGNATURE_REQUEST:
                          actionFile = (
                            <span>{i18n.t("AuditLogs.ActionsElectronicSignature")}</span>
                          );
                          break;
                        case enumsFileLogAction.MOVE_FILE_TO_TRASH:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsMoveFileToTrash")}</span>;
                          break;
                        case enumsFileLogAction.RESTORE_FILE_TRASH:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsRestoreFile")}</span>;
                          break;
                        case enumsFileLogAction.DELETE_FILE_OF_TRASH:
                          actionFile = <span>{i18n.t("AuditLogs.ActionsDeleteFileOftrash")}</span>;
                          break;

                        default:
                          actionFile = <span>{i18n.t("metrics.noData")}</span>;
                          break;
                      }

                      return <span>{actionFile}</span>;
                    } else {
                      return <span>{i18n.t("metrics.noData")}</span>;
                    }
                  },
                },
                {
                  Header: i18n.t("log.user.header3"),
                  accessor: "affected_user_name",
                },
                {
                  Header: i18n.t("form.field9"),
                  accessor: "created_at",
                  Cell: ({ value }) => {
                    if (isNullOrUndefined(value) === false) {
                      let valueFinal = getDateFormat2(new Date(value));
                      return <span>{valueFinal}</span>;
                    } else {
                      return <span>{i18n.t("metrics.noData")}</span>;
                    }
                  },
                },
                {
                  Header: [i18n.t("log.user.header5")],
                  accessor: "detail",
                  Cell: ({ value }) => {
                    if (isNullOrUndefined(value) === false) {
                      const { beforeArray, filesList, afterArray } = extractArrayFromString(value);
                      return (
                        <div>
                          <span>{beforeArray}</span>
                          <ul>
                            {filesList.map((file, index) => (
                              <li key={index}>{file}</li>
                            ))}
                          </ul>
                          <span>{afterArray}</span>
                        </div>
                      );
                    } else {
                      return <span>{i18n.t("metrics.noData")}</span>;
                    }
                  },
                },
              ],
            },
          ]}
          manual
          className="-striped -highlight"
          pages={totalPages}
          page={pagination.page - 1}
          showPageJump={false}
          defaultPageSize={pagination.per_page}
          loading={isLoadingGetLogs}
          noDataText={i18n.t("tableRowsEmpty")}
          previousText={`${i18n.t("previousText")}`}
          nextText={`${i18n.t("nextText")}`}
          pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
          ofText={<span className="px-2">{i18n.t("ofText")}</span>}
          onPageSizeChange={(per_page) => {
            setPagination({ ...pagination, per_page: per_page });
          }}
          onPageChange={(page) => {
            setPagination({ ...pagination, page: page + 1 });
          }}
        />
      </CSSTransitionGroup>
    </Fragment>
  );
};

AuditLogsListTable.propTypes = {
  logsLists: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  isLoadingGetLogs: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default AuditLogsListTable;
