import React, { Fragment } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faStepBackward,
} from "@fortawesome/free-solid-svg-icons";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { regexAcceptPrimaryCharacters } from "utils/regexExpressions";
import useWorkFlow from "hooks/useWorkFlow";
import cx from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { isNullOrUndefined } from "utils/validations";
import { enumsMinMaxCharacters } from "utils/enums";

const ReturnToStep = () => {
  const { id } = useParams();
  const taskId = id;
  const {
    process,
    getStepsToReturnById,
    isErrorReturnToStep,
    setIsErrorReturnToStep,
    selectedOptionStepToReturn,
    showModalReturnToStep,
    setShowModalReturnToStep,
    returnToStepProcessData,
    setReturnToStepProcessData,
    isLoadingStepsToReturn,
    stepsToReturnList,
    setStepsToReturnList,
    isLoadingReturningTask,
    setIsLoadingReturningTask,
    setDropdownOpen,
  } = useWorkflowContext();
  const { returnToStepProcess } = useWorkFlow();
  let selectStepToReturnDisable = false;
  let stepSelectToReturnPlaceholder = null;
  let requiredStepSelect = null;
  let spinnerButtonFulfill = null;

  /**
   * Updates the placeholder for the step select dropdown based on the loading state.
   * If loading steps to return, sets placeholder to "loading", otherwise sets it to "taskReturnStepSelect".
   * @param {boolean} isLoadingStepsToReturn - Flag indicating whether steps to return are currently loading.
   */
  if (isLoadingStepsToReturn === true) {
    selectStepToReturnDisable = true;
    stepSelectToReturnPlaceholder = i18n.t("loading");
  } else {
    stepSelectToReturnPlaceholder = i18n.t("taskReturnStepSelect");
  }

  /**
   * Creates a required step selection element based on the isErrorReturnToStep flag.
   * @param {boolean} isErrorReturnToStep - Flag indicating whether an error has occurred and the step needs to be selected again.
   * @returns {JSX.Element} - The JSX element representing the required step selection.
   */
  if (isErrorReturnToStep === true) {
    requiredStepSelect = (
      <span className="text-danger text small">{i18n.t("fieldRequired")}</span>
    );
  }

  /**
   * Generates a spinner button fulfilling JSX element based on the isLoadingReturningTask state.
   * @param {boolean} isLoadingReturningTask - Indicates whether the task is currently loading.
   * @returns {JSX.Element|null} - Returns a spinner button fulfilling JSX element or null.
   */
  if (isLoadingReturningTask === true) {
    spinnerButtonFulfill = <Spinner size="sm" color="secondary" type="grow" />;
  }

  /**
   * Handles the event when returning to a specific step is initiated.
   * Sets the state to show the modal for returning to the step
   * @returns {void}
   */
  const handleOnOpenReturnToStep = () => {
    getStepsToReturnById(id);
  };

  /**
   * Handles closing the modal and resetting state related to returning to a step process.
   * @returns {void}
   */
  const handleOnCloseReturnToStep = () => {
    setDropdownOpen(false);
    setShowModalReturnToStep(false);
    setIsErrorReturnToStep(false);
    setStepsToReturnList([]);
    setReturnToStepProcessData({
      ...returnToStepProcessData,
      return_process_task_uuid: "",
    });
  };

  /**
   * Handles onBlur event for updating comment creation state.
   * @param {Event} eventBlur - The onBlur event object.
   */
  const handleOnBlur = (eventBlur) => {
    setReturnToStepProcessData({
      ...returnToStepProcessData,
      reason: eventBlur.target.value.trim(),
    });
  };

  /**
   * Handles the change event when updating the comment for returning to a previous step in the process.
   * Updates the commentCreate state and the returnToStepProcessData state with the new comment value.
   * @param {Event} eventChangeComment - The change event object containing the new comment value.
   */
  const handleOnChangeMotiveReturnToStep = (eventChangeComment) => {
    setReturnToStepProcessData({
      ...returnToStepProcessData,
      reason: eventChangeComment.target.value,
    });
  };

  /**
   * Handles the change event when selecting a step to return in a process.
   * @param {object} selectedOptionStepToReturn - The selected option representing the step to return.
   * @param {string} selectedOptionStepToReturn.value - The UUID of the step to return.
   */
  const handleOnChangeStepToReturn = (selectedOptionStepToReturn) => {
    if (isNullOrUndefined(selectedOptionStepToReturn) === false) {
      setReturnToStepProcessData({
        ...returnToStepProcessData,
        return_process_task_uuid: selectedOptionStepToReturn.value,
      });
      setIsErrorReturnToStep(false);
    }
  };

  /**
   * Creates a comment for a task and then returns the task to a specific step in the process.
   * @param {Object} commentCreate - The data object for creating a comment, containing at least the comment text.
   * @param {string} taskUuid - The UUID of the task to which the comment is related.
   * @param {Object} returnToStepProcessData - The data object containing information necessary for returning the task to a specific step in the process.
   */
  function returnToStepByTaskProcess(taskUuid, returnToStepProcessData) {
    returnToStepProcess(taskUuid, returnToStepProcessData)
      .then((response) => {
        if (response.status === 201) {
          swal({
            title: `${i18n.t("notifications.Success")} ${process.consecutive}`,
            text: i18n.t("taskReturnSuccessAlert"),
            icon: "success",
            button: i18n.t("modal.Done.footerButton"),
          }).then(() => {
            window.location = "/taskBox";
            setIsLoadingReturningTask(false);
          });
        }
      })
      .catch((error) => {
        if (error) {
          setIsLoadingReturningTask(false);
          setReturnToStepProcessData({
            ...returnToStepProcessData,
            reason: "",
            return_process_task_uuid: "",
          });
        }
      })
      .finally(() => {
        setIsLoadingReturningTask(false);
      });
  }

  /**
   * Handles the submission action for returning to a specific step in a process.
   * @param {Event} eventReassing - The event object triggered on form submission.
   * @param {Array} errors - An array containing any validation errors.
   * @returns {void}
   */
  const handleOnSubmitReturnToStep = (eventReassing, errors) => {
    if (returnToStepProcessData.return_process_task_uuid === "") {
      setIsErrorReturnToStep(true);
      eventReassing.preventDefault();
      return;
    }
    if (errors.length !== 0) {
      eventReassing.preventDefault();
      return;
    } else {
      setShowModalReturnToStep(false);
      setIsLoadingReturningTask(true);
      returnToStepByTaskProcess(taskId, returnToStepProcessData);
    }
  };

  return (
    <Fragment>
      <UncontrolledButtonDropdown className="btn-block">
        <DropdownToggle
          className="ml-auto btn-icon btn-mb-responsive"
          color="focus"
          onClick={handleOnOpenReturnToStep}
        >
          <FontAwesomeIcon icon={faStepBackward} className="mr-2" />
          <span>{i18n.t("taskReturnButton")}</span>
        </DropdownToggle>
      </UncontrolledButtonDropdown>

      <Modal
        isOpen={showModalReturnToStep}
        className="size-modal-responsive modal-custom-width"
      >
        <ModalHeader>
          <h5 className="text-info font-weight-bold  font-weight-normal">
            {i18n.t("taskReturnHeader")}
          </h5>
        </ModalHeader>

        <AvForm onSubmit={handleOnSubmitReturnToStep}>
          <ModalBody>
            <Alert color="info">
              <span>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
                <span className="font-weight-bold mbg-3">
                  {i18n.t("modal.DoneError.header")} :{" "}
                </span>
                <span>{i18n.t("taskReturnAlert")}</span>
              </span>
            </Alert>

            <div className="mb-4">
              <Label
                for="steps_uuid"
                className={cx("is-required", {
                  "is-required label-color": isErrorReturnToStep === true,
                })}
              >
                {i18n.t("taskReturnTo")}
              </Label>

              <Select
                id="steps_uuid"
                name="steps_uuid"
                classNamePrefix={cx("", {
                  select: isErrorReturnToStep === true,
                })}
                maxMenuHeight={120}
                menuPlacement="auto"
                closeMenuOnSelect={true}
                components={makeAnimated()}
                isMulti={false}
                isSearchable={true}
                isClearable={false}
                isDisabled={selectStepToReturnDisable}
                hideSelectedOptions={true}
                placeholder={stepSelectToReturnPlaceholder}
                options={stepsToReturnList}
                noOptionsMessage={() => i18n.t("taskReturnStepSelectNoOptions")}
                onChange={handleOnChangeStepToReturn}
                value={selectedOptionStepToReturn}
              />
              {requiredStepSelect}
            </div>

            <AvGroup>
              <Label for="comments" className="is-required">
                {i18n.t("taskReturnMotive")}
              </Label>
              <AvField
                id="comment"
                name="comment"
                type="textarea"
                onBlur={handleOnBlur}
                onChange={handleOnChangeMotiveReturnToStep}
                value={returnToStepProcessData.reason}
                autoComplete="off"
                validate={{
                  required: {
                    value: true,
                    errorMessage: `${i18n.t("fieldRequired")}`,
                  },
                  pattern: {
                    value: regexAcceptPrimaryCharacters,
                    errorMessage: `${i18n.t("trd.Tooltip12")}`,
                  },
                  minLength: {
                    value: enumsMinMaxCharacters.GENERAL_MIN,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                  },
                  maxLength: {
                    value: enumsMinMaxCharacters.GENERAL_MAX,
                    errorMessage: `${i18n.t(
                      "fieldValidateLengthBetween"
                    )} 4 ${i18n.t("and")} 1000 ${i18n.t("characters")}`,
                  },
                }}
              ></AvField>
            </AvGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              className="col-mt-3 mr-2"
              color="gray"
              onClick={handleOnCloseReturnToStep}
            >
              {i18n.t("createusers.createButton2")}
            </Button>

            <Button type="submit" size="lg" className="col-mt-3" color="cyan">
              {spinnerButtonFulfill}
              {i18n.t("modal.Done.footerButton")}
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

export default ReturnToStep;
