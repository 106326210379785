import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import UserListRoles from "components/organism/UserListRoles";
import i18n from "locales/i18n";

const UsersRoles = () => {
	return (
		<Fragment>
			<AdminTemplate
				heading={i18n.t("dashboard.label19")}
				subheading={i18n.t("createRoles.subHeading")}
				icon="note2"
			>
				< UserListRoles />
			</AdminTemplate>
		</Fragment>
	);
};

export default UsersRoles;
