import React, { Fragment, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Button,
  Spinner,
  CardFooter,
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvField,
} from "availity-reactstrap-validation";
import { trimExtraSpaces } from "utils/trimSpaces";
import { enumsButtonCreateFund } from "utils/enums";
import { regexButtonCreateFund } from "utils/regexExpressions";
import { showAlertServiceError } from "utils/alerts";
import { useTrdContext } from "contextAPI/TrdContext";
import i18n from "locales/i18n";
import useTrd from "hooks/useTrd";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";

const ButtonCreateFund = () => {
  const { createFund } = useTrd();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { foldersId, setFoldersId, setFundName, fundName, getTreeByDomainCallback } = useTrdContext();

  let loadingCreateFund = null;

  /**
   * Conditionally renders a loading spinner if the `isLoading` state is true.
   * @param {boolean} isLoading - The state indicating whether the loading spinner should be displayed.
   * @returns {JSX.Element|null} The spinner element if `isLoading` is true, otherwise null.
   */
  if (isLoading === true) {
    loadingCreateFund = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  };

  /**
   * Handles the click event to show the modal.
   * @param {function} setShowModal - The new value from the input element.
   */
  const handleOnClick = () => {
    setShowModal(true);
  };

  /**
   * Handles the action to close the create fund modal.
   * Resets the fund name and hides the modal.
   */
  const handleCloseCreateFund = () => {
    setFundName("");
    setShowModal(false)
  };

  /**
   * Handles the change event for updating the fund name.
   * @param {Object} eventCreateFound - The event object from the change event.
   * @param {Object} eventCreateFound.target - The target element of the event.
   * @param {string} eventCreateFound.target.value - The new value from the input element.
   */
  const handleChangeNameFound = (eventCreateFound) => {
    setFundName(eventCreateFound.target.value)
  }

  /**
   * Handles the blur event for updating the fund name after trimming extra spaces.
   * @param {Object} eventOnBlur - The blur event object.
   * @param {Object} eventOnBlur.target - The target element of the event.
   * @param {string} eventOnBlur.target.value - The value of the target element.
   */
  const handleOnBlurSection = (eventOnBlur) => {
    const { value } = eventOnBlur.target;
    const trimmedValue = trimExtraSpaces(value);
    setFundName(trimmedValue);
  }

  const showAlertSuccessFund = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("trd.formTittle25"),
      icon: "success",
      button: i18n.t("modal.Done.footerButton"),
      successMode: true,
    });
  };

  /**
   * Handles the submit event for creating a fund. Shows a loading state, validates errors,
   * and performs the create fund operation. Depending on the response status, it updates the state,
   * shows appropriate alerts, and performs necessary callbacks.
   * @param {Event} _ - The submit event object (unused).
   * @param {Array} errors - An array of validation errors.
   */
  const handleOnSubmit = (_, errors) => {
    setIsLoading(true);
    if (errors.length === 0) {
      createFund(fundName)
        .then((response) => {
          if (response.status === 201) {
            getTreeByDomainCallback()
            setFoldersId({ ...foldersId, backgroundId: response.data.uuid });
            showAlertSuccessFund();
            handleOnClose();
          } else {
            showAlertServiceError();
          }
        })
        .catch(() => {
          setFundName("");
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      return;
    }
  };

  /**
   * Handles the action to close the modal.
   * Sets the state to hide the modal.
   */
  const handleOnClose = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <UncontrolledButtonDropdown>
            <DropdownToggle
              className="button"
              color="success"
              onClick={handleOnClick}
            >
              <span>{i18n.t("trd.formTittle1")}</span>
            </DropdownToggle>
          </UncontrolledButtonDropdown>
        </div>
        <Modal
          isOpen={showModal}
          toggle={handleOnClose}
          className="modal-medium-size"
        >
          <ModalHeader>
            <Label >{i18n.t("trd.formTittle1")}</Label>
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={handleOnSubmit}>
              <Col md="12">
                <AvGroup>
                  <ReactTooltip
                    id="nameFund"
                    place="bottom"
                    type="dark"
                    effect="solid"
                  >
                    {i18n.t("trd.Tooltip1")}
                  </ReactTooltip>
                  <Label for="name" className="is-required">{i18n.t("trd.inputTittle1")}</Label>
                  <AvField
                    id="name"
                    name="name"
                    type="input"
                    onChange={handleChangeNameFound}
                    onBlur={handleOnBlurSection}
                    data-tip
                    data-for="nameFund"
                    validate={{
                      required: { value: true, errorMessage: `${i18n.t("trd.feedback2")}` },
                      pattern: { value: regexButtonCreateFund, errorMessage: `${i18n.t("invalidField")}` },
                      minLength: {
                        value: enumsButtonCreateFund.MIN_LENGT,
                        errorMessage: `${i18n.t("fieldValidateLengthBetween")} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`
                      },
                      maxLength: {
                        value: enumsButtonCreateFund.MAX_LENGTH,
                        errorMessage: `${i18n.t("fieldValidateLengthBetween")} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`
                      }
                    }}
                    autoComplete="off"
                    value={fundName}
                    placeholder={i18n.t("trd.placeholderCreateFund")}
                  />
                </AvGroup>
              </Col>

              <CardFooter className="d-block text-right">
                <Button
                  size="lg"
                  onClick={handleCloseCreateFund}
                  className="col-mt-3 mr-3"
                  color="gray"
                >
                  {i18n.t("createusers.createButton2")}
                </Button>

                <Button
                  type="submit"
                  size="lg"
                  disabled={isLoading}
                  className="col-mt-3 mr-3"
                  color="cyan"
                >
                  {loadingCreateFund}
                  {i18n.t("createusers.createButton")}
                </Button>
              </CardFooter>

            </AvForm>
          </ModalBody>
        </Modal>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ButtonCreateFund;
