import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import TasksDetails from "components/organism/TasksDetails";
import i18n from "locales/i18n";

const TaskDetail = () => {
  return (
    <Fragment>
      <AdminTemplate heading={i18n.t("taskDetail")} icon="news-paper">
        <TasksDetails />
      </AdminTemplate>
    </Fragment>
  );
};

export default TaskDetail;
