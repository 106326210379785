import React, { Fragment, useEffect, useState } from "react";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Row,
  Col,
  Card,
  CardFooter,
  CardBody,
  Label,
  CustomInput,
  Button,
  Spinner,
  Alert,
} from "reactstrap";
import { capitalizeText } from "utils/formatText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faPen,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { nameTypes, controlData } from "utils/nameTypes";
import { useLocation } from "react-router-dom";
import { isNullOrUndefined } from "utils/validations";
import { initialPaginationFieldConfig } from "utils/initialPaginationsConfig";
import { enumSizeSelect, enumsConsultConfigurationFields, enumsControlDataFields } from "utils/enums";
import { loaderElement } from "utils/loaderElement";
import { showAlertServiceError } from "utils/alerts";
import { regexConsulConfigurationFieldsLabel } from "utils/regexExpressions";
import cx from "classnames";
import makeAnimated from "react-select/lib/animated";
import Select from "react-select";
import UseForm from "hooks/useForm";
import useList from "hooks/useList";
import swal from "sweetalert";
import i18n from "locales/i18n";
import LoadingComponent from "components/atoms/LoadingComponent";

const ConsultConfigurationFieldsForm = () => {
  const { id, form_uuid, status, hashRecord } = useParams();
  const fieldId = id;
  const { pathname } = useLocation();
  const decodedData = window.atob(hashRecord);
  const statusObject = JSON.parse(decodedData);
  const [disabledHide, setDisabledHide] = useState(true);
  const [isErrorConsultField, setIsErrorConsultField] = useState(false);
  const [disabledReq, setDisabledReq] = useState(true);
  const [changeReq, setChangeReq] = useState(false);
  const [isLoadingFieldConsult, setIsLoadingFieldConsult] = useState(false);
  const [editField, setEditField] = useState(true);
  const [editListField, setEditFieldList] = useState(true);
  const [nameFieldConsult, setNameFieldConsult] = useState("");
  const [listConsult, setListConsultDinamic] = useState([]);
  const { selectedOption } = useState({});
  const [itemParentInfo, setItemParentInfo] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoadingFieldsConsult, setIsLoadingFieldsConsult] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [fieldsFormList, setFieldsFormList] = useState([]);
  const [optionsFieldsTemp, setOptionsFieldsTemp] = useState();
  const [countFieldsAdded, setCountFieldsAdded] = useState({ uuid: [] });
  const [noOptionsMessageFields, setNoOptionsMessageFields] = useState("");
  const [fieldsLookup, setFieldsLookup] = useState();
  const [keysFieldsConfig] = useState([
    "label",
    "search_field",
    "hide",
    "required",
    "item_link",
  ]);
  const [pagination] = useState(initialPaginationFieldConfig);
  const [fieldData, setFieldData] = useState({
    id: "",
    label: "",
    typeData: "",
    len_min: null,
    len_max: null,
    hide: null,
    required: null,
    search_field: null,
    unique: null,
    control_data: "",
    allowed_negative: null,
    show_pass_dates: null,
    edit_manually: null,
    item_link: null,
    form_lookup_uuid: null,
    form_lookup_name: null,
    form_lookup_fields: null,
    forms_lookup_associated: [],
  });
  const [tempFieldData, setTempData] = useState({
    label: null,
    hide: null,
    required: null,
    unique: null,
    search_field: null,
    item_link: null,
    form_lookup_fields: null,
  });
  const { getField, updateField, getFieldsFormsForLookup } = UseForm();
  const { getLists } = useList();
  const maxOptions = 2;
  const controDataLookup = 8;
  let alertForList = null;
  let alertForAssociatedToLookup = null;
  let alertForLooKup = null;
  let editButton = null;
  let showMinMaxConfig = null;
  let requiredFieldSelect = null;
  let loaderElementConsult = null;
  let renderFormEditManually = null;
  let renderEditFields = null;
  let renderAllowedNegativeCheckBox = null;
  let renderShowPassDatesCheckBox = null;
  let renderUniqueFieldCheckBox = null;
  let renderSearchFielCheckBox = null;
  let renderRequiredCheckBox = null;
  let renderHideCheckBox = null;
  const path = pathname.split("/")[2];

  /**
   * Updates the field configuration based on the provided event value.
   * @param {Event} eventFieldChange - The event object representing the field change.
   * @param {boolean} eventFieldChange.target.checked - The new value of the field configuration (checked or unchecked).
   * @returns {void}
   */
  const handleOnChangeFielConfig = (eventFieldChange) => {
    setFieldData({
      ...fieldData,
      [eventFieldChange.target.name]: eventFieldChange.target.checked,
    });
  };

  /**
   * Conditionally renders a form field with a checkbox for hiding a field.
   * This function checks if `fieldData.hide` is not null or undefined.
   * If the condition is met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for hiding the field. The field includes an
   * onChange handler, a disabled state, and displays a label with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.hide - The flag indicating whether the field should be hidden.
   * @param {boolean} disabledHide - Flag indicating whether the hide checkbox should be disabled.
   * @param {Function} handleOnChangeFielConfig - Function to handle changes to the field configuration.
   * @returns {JSX.Element|null} - The JSX element for the hide checkbox form field if the condition is met,
   * or null if the condition is not met.
   */
  if (isNullOrUndefined(fieldData.hide) === false) {
    renderHideCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="hide"
            name="hide"
            type="checkbox"
            onChange={handleOnChangeFielConfig}
            disabled={disabledHide}
            tag={CustomInput}
            label={i18n.t("form.field39")}
            value={fieldData.hide}
          />
        </Col>
      </AvGroup>
    );
  }

  /**
   * Conditionally renders a form field with a checkbox for marking a field as required.
   * This function checks if `fieldData.required` is not null or undefined and if
   * `fieldData.control_data` is not equal to `enumsControlDataFields.LABEL`.
   * If both conditions are met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for the required field. The field includes an
   * onChange handler, a disabled state, and displays a label with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.required - The flag indicating whether the field is required.
   * @param {*} fieldData.control_data - The control data type of the field.
   * @param {boolean} disabledReq - Flag indicating whether the required checkbox should be disabled.
   * @param {Function} handleOnChangeFielConfig - Function to handle changes to the field configuration.
   * @param {Object} enumsControlDataFields - Enum object containing control data field types.
   * @returns {JSX.Element|null} - The JSX element for the required checkbox form field if the conditions are met,
   * or null if the conditions are not met.
   */
  if (
    isNullOrUndefined(fieldData.required) === false &&
    fieldData.control_data !== enumsControlDataFields.LABEL
  ) {
    renderRequiredCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            name="required"
            type="checkbox"
            onChange={handleOnChangeFielConfig}
            tag={CustomInput}
            label={i18n.t("form.field36")}
            disabled={disabledReq}
            value={fieldData.required}
          />
        </Col>
      </AvGroup>
    );
  };

  /**
   * Conditionally renders a form field with a checkbox for marking a field as a search field.
   * This function checks if `fieldData.search_field` is not null or undefined.
   * If the condition is met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for the search field. The field includes an
   * onChange handler, a disabled state based on `editField`, and displays a label
   * with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.search_field - The flag indicating whether the field is a search field.
   * @param {boolean} editField - Flag indicating whether the field should be editable.
   * @param {Function} handleOnChangeFielConfig - Function to handle changes to the field configuration.
   * @returns {JSX.Element|null} - The JSX element for the search field checkbox form field if the condition is met,
   * or null if the condition is not met.
   */
  if (isNullOrUndefined(fieldData.search_field) === false) {
    renderSearchFielCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="search_field"
            name="search_field"
            type="checkbox"
            disabled={editField}
            tag={CustomInput}
            onChange={handleOnChangeFielConfig}
            label={i18n.t("form.field37")}
            value={fieldData.search_field}
          />
        </Col>
      </AvGroup>
    );
  };

  /**
   * Conditionally renders a form field with a checkbox for marking a field as unique.
   * This function checks if `fieldData.unique` is not null or undefined and if `editField` is true.
   * If both conditions are met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for the unique field. The checkbox is disabled and
   * displays a label with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.unique - The flag indicating whether the field is unique.
   * @param {boolean} editField - Flag indicating whether the field should be editable.
   * @returns {JSX.Element|null} - The JSX element for the unique field checkbox form field if the conditions are met,
   * or null if the conditions are not met.
   */
  if (
    isNullOrUndefined(fieldData.unique) === false &&
    editField === true
  ) {
    renderUniqueFieldCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="unique"
            name="unique"
            type="checkbox"
            disabled={true}
            tag={CustomInput}
            label={i18n.t("form.field38")}
            value={fieldData.unique}
          />
        </Col>
      </AvGroup>
    );
  };

  /** 
   * Conditionally renders a checkbox input field for displaying past dates based on the value of `fieldData.show_pass_dates` and `editField`.
   * This field is rendered only if:
   * - `fieldData.show_pass_dates` is neither null nor undefined
   * - `editField` is true
   * 
   * The rendered checkbox is disabled and uses `CustomInput` for rendering.
   * @return {void} {JSX.Element|undefined} renderShowPassDatesCheckBox - A JSX element containing an AvField component for the checkbox, or undefined if the conditions are not met.
   */
  if (
    isNullOrUndefined(fieldData.show_pass_dates) === false &&
    editField === true
  ) {
    renderShowPassDatesCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="show_pass_dates"
            name="show_pass_dates"
            type="checkbox"
            disabled={true}
            tag={CustomInput}
            label={i18n.t("form.field42")}
            value={fieldData.show_pass_dates}
          />
        </Col>
      </AvGroup>
    );
  };

  /**
   * Conditionally renders a checkbox input field based on the `fieldData.allowed_negative` value
   * and the `editField` flag. The checkbox is rendered inside a form group and is disabled.
   * @param {Object} fieldData - The data object that includes the `allowed_negative` property.
   * @param {boolean} editField - A flag indicating whether the field should be editable.
   * @param {function} i18n.t - A function for translating text used in labels and error messages.
   * @param {React.Element} CustomInput - A custom input component used for rendering the checkbox.
   * @returns {React.Element | null} - The rendered checkbox element wrapped in a form group if conditions are met; otherwise, `null`.
   */
  if (
    isNullOrUndefined(fieldData.allowed_negative) === false &&
    editField === true
  ) {
    renderAllowedNegativeCheckBox = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="allowed_negative"
            name="allowed_negative"
            type="checkbox"
            disabled={true}
            tag={CustomInput}
            required
            label={i18n.t("form.field40")}
            errorMessage={i18n.t("form.fieldFeedback3")}
            value={fieldData.allowed_negative}
          />
        </Col>
      </AvGroup>
    );
  };

  /**
   * Conditionally renders a form field for manual editing if `edit_manually` is not null
   * and `editField` is true.
   * This function checks if `fieldData.edit_manually` is not null and `editField` is true.
   * If both conditions are met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for manual editing. The field is disabled and
   * displays a label with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.edit_manually - The flag indicating whether manual editing is enabled.
   * @param {boolean} editField - Flag indicating whether the field is in edit mode.
   * @returns {JSX.Element|null} - The JSX element for the manual editing form field if conditions are met,
   * or null if the conditions are not met.
   */
  if (editField === false) {
    renderEditFields = (
      <Fragment>
        <CardFooter className="d-block text-right">
          <Button
            onClick={() =>
              (window.location.href = `/formsdesigner/fields/${form_uuid}`)
            }
            size="lg"
            className="col-mt-3 mr-3"
            color="gray"
          >
            {i18n.t("createusers.createButton2")}
          </Button>
          <Button
            type="submit"
            size="lg"
            disabled={isLoadingFieldConsult}
            className="col-mt-3"
            color="cyan"
          >
            {loaderElementConsult}
            {i18n.t("createusers.createButton3")}
          </Button>
        </CardFooter>
      </Fragment>
    );
  };

  /**
   * Conditionally renders a form field for manual editing if `edit_manually` is not null
   * and `editField` is true.
   * This function checks if `fieldData.edit_manually` is not null and `editField` is true.
   * If both conditions are met, it returns a JSX element for an `AvGroup` containing
   * an `AvField` with a custom input for manual editing. The field is disabled and
   * displays a label with localized text from `i18n`.
   * @param {Object} fieldData - The data object containing field attributes.
   * @param {*} fieldData.edit_manually - The flag indicating whether manual editing is enabled.
   * @param {boolean} editField - Flag indicating whether the field is in edit mode.
   * @returns {JSX.Element|null} - The JSX element for the manual editing form field if conditions are met,
   * or null if the conditions are not met.
   */
  if (fieldData.edit_manually !== null && editField === true) {
    renderFormEditManually = (
      <AvGroup row>
        <Col sm={12}>
          <AvField
            id="editmanually"
            name="editmanually"
            type="checkbox"
            disabled={true}
            tag={CustomInput}
            label={i18n.t("form.field41")}
            value={fieldData.edit_manually}
          />
        </Col>
      </AvGroup>
    )
  };

  /**
   * Conditionally renders a Spinner component if isLoadingFieldConsult is true.
   * @param {boolean} isLoadingFieldConsult - A boolean flag indicating whether to display the Spinner.
   * @returns {JSX.Element|null} - Returns the Spinner component if isLoadingFieldConsult is true, otherwise null.
   */
  if (isLoadingFieldConsult === true) {
    loaderElementConsult = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

  /**
   * Determines whether a redirect is disabled based on the provided status.
   * @param {string} status - The status value to check. Should be a string representing "true" or "false".
   * @returns {boolean} Returns `true` if the status is "false," indicating that the redirect is disabled, or `false` otherwise.
   */
  let disabledRedirect;
  if (status === "false") {
    disabledRedirect = true;
  } else {
    disabledRedirect = false;
  }

  /**
   * Generates an alert component based on the provided conditions.
   * This function creates an Alert component with an informative message and styling
   * based on the values of `itemParentInfo` and `editField`. If `itemParentInfo` is
   * not null or undefined and `editField` is false, an informational alert is generated.
   * Otherwise, the `alertForList` variable is set to null.
   * @param {any} itemParentInfo - Information related to the parent item.
   * @param {boolean} editField - Indicates whether an edit operation is in progress.
   * @returns {React.ReactNode | null} - Returns a JSX element representing an alert
   * component or null.
   */
  if (isNullOrUndefined(itemParentInfo) === false && editField === false) {
    alertForList = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          <span>{i18n.t("form.fieldlist.label2")}</span>
        </span>
      </Alert>
    );
  } else {
    alertForList = null;
  }

  /**
   * Conditionally generates an alert component for lookup fields based on certain conditions.
   * @param {boolean} isNullOrUndefined - A boolean indicating whether the `fieldData.form_lookup_fields` is null or undefined.
   * @param {boolean} editField - A boolean indicating whether the user is in edit mode.
   * @returns {ReactNode|null} - The generated alert component or `null` if the conditions are not met.
   */
  if (
    isNullOrUndefined(fieldData.form_lookup_fields) === false &&
    editListField === false
  ) {
    alertForLooKup = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          <span>{i18n.t("form.field85")}</span>
          <li>{i18n.t("form.field70")}</li>
          <li>{i18n.t("form.field71")}</li>
        </span>
      </Alert>
    );
  } else {
    alertForLooKup = null;
  }

  /**
   * Generates an alert component to inform the user about associated forms when specific conditions are met.
   * @param {Object} fieldData - The data associated with the field.
   * @param {boolean} fieldData.forms_lookup_associated - Indicates whether there are associated forms.
   * @param {number} fieldData.control_data - Control data indicating a specific condition.
   * @returns {JSX.Element|null} - Returns an Alert component with information about associated forms if conditions are met; otherwise, returns null.
   */
  if (
    isNullOrUndefined(fieldData.forms_lookup_associated) === false &&
    fieldData.control_data === 1 &&
    fieldData.forms_lookup_associated.length > 0
  ) {
    alertForAssociatedToLookup = (
      <Alert color="info">
        <span>
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2 " />
          <span className="font-weight-bold mbg-3">
            {i18n.t("modal.DoneError.header")} :{" "}
          </span>
          <span>
            {i18n.t("30098")}
            {fieldData.forms_lookup_associated.map((form) => {
              return <li>{form.name}</li>;
            })}
          </span>
        </span>
      </Alert>
    );
  } else {
    alertForAssociatedToLookup = null;
  }

  /**
   * Conditionally renders an edit button and minimum/maximum configuration fields based on the value of 'editField'.
   * @param {boolean} editField - A boolean flag indicating whether the edit mode is enabled.
   * @param {boolean} disabledRedirect - A boolean flag indicating whether the redirect is disabled for the edit button.
   * @param {string} fieldId - The ID of the field.
   * @param {string} form_uuid - The UUID of the form.
   * @param {string} hashRecord - The hash record associated with the configuration.
   * @param {object} i18n - An internationalization object for translation.
   * @param {object} fieldData - An object containing field data, including 'len_min' and 'len_max' values.
   * @returns {JSX.Element} - The rendered edit button and minimum/maximum configuration fields based on the 'editField' condition.
   */
  if (editField === true) {
    editButton = (
      <Button
        disabled={disabledRedirect}
        className="btn-icon mr-2"
        color="cyan"
        onClick={() =>
          (window.location.href = `/configuration/edit/${fieldId}/${form_uuid}/${hashRecord}`)
        }
      >
        <FontAwesomeIcon icon={faPen} className="mr-2" />
        {i18n.t("buttonActions.option2")}
      </Button>
    );
    showMinMaxConfig = (
      <div>
        <AvGroup row>
          <Label for="label" sm={3}>
            {i18n.t("form.field35")}
          </Label>
          <Col md={8}>
            <AvField
              id="label"
              name="len_min"
              type="text"
              autoFocus={true}
              autoComplete="off"
              disabled={editField}
              value={fieldData.len_min}
            />
          </Col>
        </AvGroup>

        <AvGroup row>
          <Label for="label" sm={3}>
            {i18n.t("form.field34")}
          </Label>
          <Col md={8}>
            <AvField
              id="label"
              name="len_max"
              type="text"
              autoFocus={true}
              autoComplete="off"
              disabled={editField}
              value={fieldData.len_max}
            />
          </Col>
        </AvGroup>
      </div>
    );
  } else {
    editButton = null;
    showMinMaxConfig = null;
  }

  /**
   * Renders a message indicating that a field is required if the 'isErrorConsultField' flag is set to true.
   * @param {boolean} isErrorConsultField - A flag indicating whether the field is in an error state.
   * @param {string} i18n.t - A function used for internationalization to translate the "fieldRequired" message.
   * @returns {JSX.Element | null} - A JSX element representing the required field message or null if no message is required.
   */
  if (isErrorConsultField === true) {
    requiredFieldSelect = (
      <span className="text-danger text small">{i18n.t("form.field86")}</span>
    );
  } else {
    requiredFieldSelect = null;
  }

  /**
   * Updates the label of the field data based on the provided event value.
   * @param {Event} eventFieldChangeLabel - The event object representing the field label change.
   * @param {string} eventFieldChangeLabel.target.value - The new value of the field label.
   * @returns {void}
   */
  const handleOnChangeFieldLabel = (eventFieldChangeLabel) => {
    setFieldData({
      ...fieldData,
      label: eventFieldChangeLabel.target.value,
    });
  };

  /**
   * Handles the change of the selected option.
   * @param {Array} selectedOption - The selected option.
   * @return {void}
   */
  const handleOnChangeForms = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorConsultField(true);
    } else {
      setFieldData({
        ...fieldData,
        item_link: selectedOption.value,
      });
      setIsErrorConsultField(false);
    }
  };

  /**
   * Extracts UUIDs from the `form_lookup_fields` object within `fieldData`.
   * @returns {string[]} An array of UUIDs extracted from the `form_lookup_fields` object.
   */
  const getFields = () => {
    return Object.values(fieldData.form_lookup_fields).map((item) => item.uuid);
  };

  /**
   * Perform a validation check for lookup fields based on certain conditions.
   * @param {object} fieldData - An object containing field data, including `control_data`.
   * @param {string} controDataLookup - The control data specific to lookup fields.
   * @param {boolean} isErrorConsultField - A boolean indicating whether there is an error in the consulted field.
   * @param {number} maxOptions - The maximum number of options allowed.
   * @param {object} countFieldsAdded - An object representing the count of fields added.
   * @param {number} countFieldsAdded.uuid.length - The length of the 'uuid' property within 'countFieldsAdded'.
   * @returns {boolean|null} - `true` if the validation conditions are met, `null` otherwise.
   */
  const lookupValidation = () => {
    if (fieldData.control_data === controDataLookup) {
      if (
        isErrorConsultField === true ||
        countFieldsAdded.uuid.length < maxOptions
      ) {
        return true;
      }
    } else {
      return null;
    }
  };

  /**
   * Build an array of options fields based on the input fields data.
   *
   * @param {Array} fields - An array of field objects containing information.
   * @param {string} fields[].name - The name of the field.
   * @param {boolean} fields[].required - A boolean indicating whether the field is required.
   * @param {boolean} fields[].unique - A boolean indicating whether the field is unique.
   * @param {string} fields[].uuid - The UUID of the field.
   * @param {object} i18n - An object with a translation function for generating localized labels.
   * @returns {Array} An array of options fields with labels and values.
   */

  const buildOptionsFields = (fields) => {
    return fields.map((field) => {
      const labelOptions = {
        true: {
          true: i18n.t("form.field74"),
          false: i18n.t("form.field73"),
        },
        false: {
          true: i18n.t("form.field72"),
          false: "",
        },
      };
      const label = `${field.name} ${labelOptions[field.required][field.unique]
        }`;
      return {
        label,
        value: `${field.uuid}`,
        required: field.required,
        unique: field.unique,
      };
    });
  };

  /**
   * Funtion for handles the change of selected options in a field input.
   * @param {Array} selectedOption - The selected options in the field input.
   * @returns {null} - This function does not return a value.
   */

  const handleOnChangeFields = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorConsultField(true);
    } else {
      const selectedValues = selectedOption.map((option) => option.value);
      setIsErrorConsultField(false);
      setCountFieldsAdded({
        ...countFieldsAdded,
        uuid: selectedValues,
      });
      setFieldsLookup({
        form_lookup_fields: {
          0: selectedValues[0] || null,
          1: selectedValues[1] || null,
        },
      });
    }
    return null;
  };

  /**
   * Handles the form submission for the field consultation configuration.
   * @param {Object} eventSubmitConsultField - The event object representing the form submission.
   * @param {Array} errorsConsultField - An array of validation errors.
   * @returns {void}
   */
  const handleSumbitConsultConfiguration = (
    eventSubmitConsultField,
    errorsConsultField
  ) => {
    let dataConfigPayload;
    eventSubmitConsultField.preventDefault();
    const validateLookup = lookupValidation();
    if (validateLookup === true) return setIsErrorConsultField(true);
    if (errorsConsultField.length === 0) {
      const fieldConfigPayload = keysFieldsConfig.reduce(
        (acomulatorField, fieldKey) => {
          if (fieldData[fieldKey] !== tempFieldData[fieldKey]) {
            acomulatorField[fieldKey] = fieldData[fieldKey];
          }
          return acomulatorField;
        },
        {}
      );
      if (
        Object.keys(fieldConfigPayload).length === 0 &&
        fieldsLookup === undefined
      ) {
        swal({
          title: i18n.t("modal.DoneError.header"),
          text: i18n.t("form.alertWarning"),
          icon: "info",
          button: i18n.t("modal.Done.footerButton"),
        });
        return;
      }

      if (
        Object.keys(fieldConfigPayload).length === 0 &&
        fieldsLookup !== undefined
      ) {
        dataConfigPayload = { ...fieldsLookup, ...fieldConfigPayload };
      } else {
        dataConfigPayload = { ...fieldConfigPayload };
      }
      setIsLoadingFieldConsult(true);
      updateField(fieldId, dataConfigPayload)
        .then((response) => {
          if (response.status === 202) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("form.alerSuccess"),
              icon: "success",
              button: i18n.t("modal.Done.footerButton"),
            }).then(
              () =>
                (window.location.href = `/formsdesigner/fields/${form_uuid}`)
            );
          }
        })
        .finally(() => setIsLoadingFieldConsult(false));
    }
  };

  /**
   * Handles the side effects when the component mounts.
   * Through the response that arrives, fill the fieldData state with the properties
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(fieldId) === false) {
      setIsLoadingFieldsConsult(true);
      getField(fieldId).then((response) => {
        if (isNullOrUndefined(response) === false) {
          setFieldData({
            ...fieldData,
            id: response.uuid,
            label: response.label,
            typeData: response.type_data,
            len_min: response.len_min_chars,
            len_max: response.len_max_chars,
            hide: response.hide,
            required: response.required,
            search_field: response.search_field,
            unique: response.unique,
            control_data: response.control_data,
            allowed_negative: response.allowed_negative,
            show_pass_dates: response.show_pass_dates,
            edit_manually: response.edit_manually,
            item_link: response.item_link,
            list_name: response.list_name,
            form_lookup_uuid: response.form_lookup_uuid,
            form_lookup_name: response.form_lookup_name,
            form_lookup_fields: response.form_lookup_fields,
            forms_lookup_associated: response.forms_lookup_associated,
          });
          setTempData({
            label: response.label,
            hide: response.hide,
            required: response.required,
            unique: response.unique,
            search_field: response.search_field,
            item_link: response.item_link,
            form_lookup_fields: response.form_lookup_fields,
          });
          setItemParentInfo(response.item_link);
          setNameFieldConsult(response.label);
          setChangeReq(response.required);
        } else {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("error.data.submit"),
            icon: "error",
            dangerMode: true,
            button: i18n.t("modal.Done.footerButton"),
          });
        }
      })
        .finally(() => {
          setIsLoadingFieldsConsult(false);
        })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Performs a list search and updates the status of `formsDinamic`.
   * @return {Void}
   */
  useEffect(() => {
    if (fieldData.control_data === enumsControlDataFields.COMBOBOX) {
      const { page, per_page, search } = pagination;
      setIsErrorConsultField(true);
      getLists(page, per_page, search).then((response) => {
        const dataListsField = response.data.items;
        if (isNullOrUndefined(dataListsField) === false) {
          dataListsField
            .sort((fristConsult, secondConsult) => {
              const fristConsultField =
                `${fristConsult.name} `.toLocaleLowerCase();
              const secondConsultField =
                `${secondConsult.name} `.toLocaleLowerCase();
              if (fristConsultField > secondConsultField) {
                return 1;
              } else if (secondConsultField > fristConsultField) {
                return -1;
              } else {
                return 0;
              }
            })
            .forEach((fieldConsult) => {
              if (fieldConsult.status === 1) {
                setListConsultDinamic((prevFieldConsult) => [
                  ...prevFieldConsult,
                  {
                    value: fieldConsult.uuid,
                    label: fieldConsult.name,
                  },
                ]);
              }
            });
        } else {
          showAlertServiceError();
        }
      })
    }
  }, [fieldData.control_data]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect for fetching and processing lookup fields data.
   * @param {object} fieldData - An object containing form data, including `form_lookup_uuid`.
   * @param {string} fieldData.form_lookup_uuid - The UUID for lookup fields data.
   * @param {boolean} isNullOrUndefined - A function to check if a value is null or undefined.
   * @param {function} setIsLoadingFields - A function to set the loading state.
   * @param {function} getFieldsFormsForLookup - A function to fetch lookup fields data.
   * @param {function} buildOptionsFields - A function to build options fields from the fetched data.
   * @param {function} getFields - A function to extract UUIDs from `form_lookup_fields`.
   * @param {function} setCountFieldsAdded - A function to set the count of fields added.
   * @param {function} setDefaultOptions - A function to set default options fields.
   * @param {function} setFieldsFormList - A function to set the fields form list.
   * @returns {void}
   */
  useEffect(() => {
    if (
      isNullOrUndefined(fieldData.form_lookup_uuid) === false &&
      fieldData.control_data === 8
    ) {
      setIsLoadingFields(true);
      getFieldsFormsForLookup(fieldData.form_lookup_uuid)
        .then((response) => {
          let activeFields = response.data;
          if(isNullOrUndefined(activeFields) === false){
          if (activeFields.length > 0) {
            let optionsFields = buildOptionsFields(activeFields);
            const fieldsData = getFields();
            setCountFieldsAdded({ uuid: fieldsData });
            const defaultOptions = activeFields.filter((field) =>
              fieldsData.includes(field.uuid)
            );
            let optionsFieldsDefault = buildOptionsFields(defaultOptions);
            setDefaultOptions(optionsFieldsDefault);
            setFieldsFormList(optionsFields);
          } else {
            showAlertServiceError();
          }
        } else {
          showAlertServiceError();
        }
        })
        .finally(() => {
          setIsLoadingFields(false);
        });
    }
    // eslint-disable-next-line
  }, [fieldData.control_data, fieldData.form_lookup_uuid]);

  /**
   * A useEffect hook that checks conditions related to form field configurations
   * and updates the 'canNext' and 'isErrorFieldsValid' state variables accordingly.
   * @param {function} setCanNext - A state setter function to control the 'canNext' state.
   * @param {any} relatedOption - The related option to be checked.
   * @param {string} fieldForm.form_lookup_uuid - The UUID of the form lookup.
   * @param {object} fieldForm.form_lookup_fields - The fields configuration for form lookup.
   * @param {string[]} countFieldsAdded.uuid - An array of UUIDs representing added fields.
   * @param {object[]} fieldsFormList - A list of form fields.
   * @returns {void}
   */
  useEffect(() => {
    const hasUnique = countFieldsAdded.uuid.some((id) =>
      fieldsFormList.find((item) => item.value === id && item.unique === true)
    );
    const hasRequired = countFieldsAdded.uuid.some((id) =>
      fieldsFormList.find((item) => item.value === id && item.required === true)
    );
    if (hasUnique && hasRequired) {
      setIsErrorConsultField(false);
    } else {
      setIsErrorConsultField(true);
    }
  }, [countFieldsAdded, fieldsFormList]);

  /**
   * useEffect for updating the count of fields added based on `form_lookup_fields`.
   * @param {object} fieldData - An object containing form data, including `form_lookup_fields`.
   * @param {object} fieldData.form_lookup_fields - An object representing lookup fields data.
   * @param {function} setCountFieldsAdded - A function to set the count of fields added.
   * @param {function} getFields - A function to extract UUIDs from `form_lookup_fields`.
   * @param {boolean} isNullOrUndefined - A function to check if a value is null or undefined.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(fieldData.form_lookup_fields) === false) {
      const fieldsData = getFields();
      setCountFieldsAdded({
        uuid: fieldsData,
      });
    }
    // eslint-disable-next-line
  }, [fieldData.form_lookup_fields]);

  /**
   * useEffect hook that updates temporary options fields and a message based on field count.
   * @param {object} countFieldsAdded - An object representing the count of added fields with a 'uuid' property.
   * @param {number} maxOptions - The maximum allowed number of options fields.
   * @param {array} fieldsFormList - An array of fields for a form.
   * @param {function} setOptionsFieldsTemp - State setter function for temporary options fields.
   * @param {function} setNoOptionsMessageFields - State setter function for the message when no options are available.
   * @returns {void}
   */
  useEffect(() => {
    if (countFieldsAdded.uuid.length === maxOptions) {
      setOptionsFieldsTemp([]);
      setNoOptionsMessageFields(i18n.t("form.field77"));
    } else {
      setOptionsFieldsTemp(fieldsFormList);
      setNoOptionsMessageFields(null);
    }
  }, [countFieldsAdded, fieldsFormList]);

  /**
   * Handles the side effects when the component mounts.
   * @returns {void}
   */
  useEffect(() => {
    if (path === "edit") {
      setEditField(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (path === "edit" && statusObject === false) {
      setEditFieldList(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the side effects when certain dependencies change.
   * @returns {void}
   */
  useEffect(() => {
    if (editField === false) {
      setDisabledHide(false);
    }
    if (editField === false && changeReq === true) {
      setDisabledReq(false);
    }
    if (fieldData.required === true) {
      setFieldData({ ...fieldData, hide: false });
      setDisabledHide(true);
    }
    if (fieldData.hide === true) {
      setFieldData({ ...fieldData, required: false });
      setDisabledReq(true);
    }
    if (
      fieldData.control_data === enumsControlDataFields.TEXTBOX &&
      fieldData.forms_lookup_associated.length > 0
    ) {
      setDisabledReq(true);
    }
  }, [fieldData.required, fieldData.hide, changeReq]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Conditional rendering of a loading component based on the value of `isLoadingFields`.
   * @param {boolean} isLoadingFields - A boolean indicating whether data is currently being loaded.
   * @returns {JSX.Element|null} - A loading component or `null` depending on the value of `isLoadingFields`.
   */
  if (isLoadingFields === true) {
    return <LoadingComponent />;
  }

  /**
  * Conditional rendering of a loading component based on the value of `isLoadingFields`.
  * @param {boolean} isLoadingFieldsConsult - A boolean indicating whether data is currently being loaded.
  * @returns {JSX.Element|null} - A loading component or `null` depending on the value of `isLoadingFields`.
  */
  if (isLoadingFieldsConsult === true && fieldData.control_data !== enumsControlDataFields.LOOKUP) {
    return <LoadingComponent />;
  }

  return (
    <Fragment>
      {loaderElement(isLoadingFieldConsult)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <AvForm onSubmit={handleSumbitConsultConfiguration}>
          <Card className="main-card mb-3">
            <CardBody>
              <Row>
                <Col lg="5" md="4" sm="12" className="button-container">
                  <h5 className="text-info font-weight-bold">
                    {capitalizeText(nameFieldConsult)}
                  </h5>
                  <h6 className="text-info font-weight-bold">
                    {i18n.t("form.field5") + ":"}{" "}
                    <span className="text-dark ml-2">
                      {nameTypes(fieldData.typeData)}
                    </span>
                  </h6>
                  <h6 className="text-info font-weight-bold">
                    {i18n.t("form.field3") + ":"}
                    <span className="text-dark ml-2">
                      {controlData(fieldData.control_data)}
                    </span>
                  </h6>
                </Col>

                <Col
                  lg="7"
                  md="8"
                  sm="12"
                  className="button-container mt-2 mt-md-0"
                >
                  <Row className="row justify-content-end">
                    {editButton}
                    <Button
                      className="btn-icon"
                      color="cyan"
                      onClick={() =>
                        (window.location.href = `/formsdesigner/fields/${form_uuid}`)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faArrowAltCircleLeft}
                        className="mr-2"
                      />
                      {i18n.t("form.field45")}
                    </Button>
                  </Row>
                </Col>
              </Row>
              <div className="pt-1">
                {alertForList}
                {alertForLooKup}
                {alertForAssociatedToLookup}
              </div>
            </CardBody>

            <CardBody className="pt-0 pl-5">
              <div className="row form-group">
                <Label for="label" className="is-required" sm={3}>
                  {i18n.t("form.fieldtable2")}
                </Label>
                <Col md={8}>
                  <AvField
                    id="label"
                    name="label"
                    type="text"
                    autoFocus={true}
                    autoComplete="off"
                    disabled={editField}
                    onChange={handleOnChangeFieldLabel}
                    value={fieldData.label}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: `${i18n.t("form.fieldFeedback2")}`,
                      },
                      pattern: {
                        value: regexConsulConfigurationFieldsLabel,
                        errorMessage: `${i18n.t(
                          "integration.requestCharacters"
                        )}`,
                      },
                      minLength: {
                        value: enumsConsultConfigurationFields.MIN_LENGTH_LABEL,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                      maxLength: {
                        value: enumsConsultConfigurationFields.MAX_LENGTH_LABEL,
                        errorMessage: `${i18n.t(
                          "fieldValidateLengthBetween"
                        )} 1 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                      },
                    }}
                  />
                </Col>
              </div>
              {(() => {
                if (isNullOrUndefined(fieldData) === false) {
                  if (
                    fieldData.control_data === enumsControlDataFields.TEXTBOX ||
                    fieldData.control_data === enumsControlDataFields.TEXTAREA
                  ) {
                    return showMinMaxConfig;
                  } else if (fieldData.control_data === enumsControlDataFields.COMBOBOX) {
                    return (
                      <AvGroup row>
                        <Label for="listName" sm={3}>
                          {i18n.t("form.fieldlist.label3")}
                        </Label>
                        <Col md={8}>
                          <div className="margin-bottom-datepicker">
                            <Select
                              id="listName"
                              name="listName"
                              className="container_select_consult_configuration"
                              maxMenuHeight={enumSizeSelect.seeThreeItems}
                              menuPlacement="auto"
                              closeMenuOnSelect={true}
                              isSearchable={true}
                              isDisabled={editListField}
                              components={makeAnimated()}
                              options={listConsult}
                              placeholder={i18n.t("recordData2")}
                              noOptionsMessage={() => i18n.t("list.item.empty")}
                              onChange={handleOnChangeForms}
                              value={selectedOption}
                              defaultValue={{
                                value: fieldData.id,
                                label: fieldData.list_name,
                              }}
                            />
                          </div>
                        </Col>
                      </AvGroup>
                    );
                  } else if (fieldData.control_data === enumsControlDataFields.LOOKUP) {
                    return (
                      <div>
                        <div className="row form-group">
                          <Label for="selectForm" sm={3}>
                            {i18n.t("form.field43")}
                          </Label>
                          <Col md={8}>
                            <div className="margin-bottom-datepicker">
                              <Select
                                id="selectForm"
                                name="selectForm"
                                maxMenuHeight={enumSizeSelect.seetTwoItems}
                                menuPlacement="auto"
                                closeMenuOnSelect={true}
                                isSearchable={false}
                                isDisabled={true}
                                hideSelectedOptions={true}
                                components={makeAnimated()}
                                placeholder={i18n.t("form.field75")}
                                value={selectedOption}
                                defaultValue={{
                                  value: fieldData.form_lookup_uuid,
                                  label: fieldData.form_lookup_name,
                                }}
                              />
                            </div>
                          </Col>
                        </div>
                        <div className="row form-group">
                          <Label
                            for="fieldsLookup"
                            className={cx(
                              "is-required",
                              isErrorConsultField === false,
                              {
                                "label-color is-required":
                                  isErrorConsultField === true,
                              }
                            )}
                            sm={3}
                          >
                            {i18n.t("form.fieldHeading")}
                          </Label>

                          <Col md={8}>
                            <Select
                              id="selectFields"
                              name="selectFields"
                              classNamePrefix={cx("", {
                                select: isErrorConsultField === true,
                              })}
                              maxMenuHeight={enumSizeSelect.seetTwoItems}
                              menuPlacement="auto"
                              closeMenuOnSelect={true}
                              components={makeAnimated()}
                              isMulti={true}
                              isSearchable={false}
                              isClearable={false}
                              isDisabled={editListField}
                              hideSelectedOptions={true}
                              placeholder={i18n.t("form.field76")}
                              options={optionsFieldsTemp}
                              onChange={handleOnChangeFields}
                              noOptionsMessage={() => noOptionsMessageFields}
                              value={selectedOption}
                              defaultValue={defaultOptions}
                            />
                            {requiredFieldSelect}
                          </Col>
                        </div>
                      </div>
                    );
                  }
                }
              })()}

              <Fragment>
                {renderHideCheckBox}

                {renderRequiredCheckBox}

                {renderSearchFielCheckBox}

                {renderUniqueFieldCheckBox}

                {renderFormEditManually}

                {renderShowPassDatesCheckBox}

                {renderAllowedNegativeCheckBox}

              </Fragment>
            </CardBody>
            {renderEditFields}
          </Card>
        </AvForm>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default ConsultConfigurationFieldsForm;