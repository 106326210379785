import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Button, CardBody } from "reactstrap";
import { useParams } from 'react-router-dom'
import { showAlertServiceError } from "utils/alerts";
import { capitalizeText } from "utils/formatText";
import ReactTable from "react-table";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserWorkGroupMembersTable = (props) => {
  const {
    listMembers,
    pagination,
    isLoading,
    totalPages,
    setPagination,
    setIsLoading,
    deleteMemberOfWorkGroup,
    setListWorkflows,
    setIsMinimumGroupMembers,
    disabledDeleteWorkGroup
  } = props;

  const { id } = useParams();
  const workgroudId = id;

  let isLoadingMembers = false;
  let disabledIconTrash = false;

  /**
   * Determines if the icon for deleting a work group should be disabled based on the provided condition.
   * @param {boolean} disabledDeleteWorkGroup - Indicates whether deleting the work group is disabled.
   * @returns {boolean} Returns true if the icon should be disabled, otherwise false.
   */
  if (disabledDeleteWorkGroup === true && disabledDeleteWorkGroup !== null) {
    disabledIconTrash = true;
  } else {
    disabledIconTrash = false;
  }

  /**
   * Determines if the members are loading based on the provided condition.
   * @param {boolean} disabledDeleteWorkGroup - Indicates whether deleting the work group is disabled.
   * @returns {boolean} Returns true if members are loading, otherwise false.
   */
  if (disabledDeleteWorkGroup === null) {
    isLoadingMembers = true;
  } else {
    isLoadingMembers = false;
  }

  /**
   * Handles the deletion of a member from the work group.
   * @param {string} userId - The ID of the user to delete.
   * @returns {void}
   */

  const handleOnDeleteMember = (userId) => {
    setIsLoading(true);
    deleteMemberOfWorkGroup(workgroudId, userId)
      .then((response) => {
        if (response.status === 202) {
          swal({
            title: i18n.t(
              "modal.DoneError.header"
            ),
            text: i18n.t(
              "create.work.group.members.label12"
            ),
            icon: "success",
            button: i18n.t(
              "modal.Done.footerButton"
            ),
          })
            .then(() => {
              window.location.reload();
            });
        } else {
          showAlertServiceError();
        }
      })
      .catch((error) => {
        if (error.response.data.code === "10056") {
          setListWorkflows(error.response.data.data)
        }
        if (error.response.data.code === "10058") {
          setIsMinimumGroupMembers(true)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <CardBody>
          <ReactTable
            data={listMembers}
            columns={[
              {
                columns: [
                  {
                    Header: [i18n.t("create.work.group.members.table.label1")],
                    accessor: "first_name",

                    Cell: (row) => {
                      const userName = capitalizeText(row.original.first_name)
                        + " " + capitalizeText(row.original.last_name)
                      return (
                        <span>{userName}</span>
                      )
                    }
                  },
                  {
                    Header: [i18n.t("create.work.group.members.table.label2")],
                    accessor: "user_name",
                  },
                  {
                    Header: [i18n.t("create.work.group.members.label13")],
                    accessor: "delele_user",

                    Cell: (row) => {
                      const userId = row.original.uuid;
                      return (
                        <div className="text-center">
                          <Button
                            className="btn-icon text-primary"
                            size="lg"
                            color="link"
                            disabled={disabledIconTrash}
                            onClick={() => handleOnDeleteMember(userId)}
                          >
                            {" "}
                            <div className="avatar-icon-wrapper avatar-icon-sm avatar-icon-add">
                              <div>
                                <i className="lnr-icon lnr-trash text-primary " />
                              </div>
                            </div>
                          </Button>
                        </div>
                      );
                    },

                  },
                ],
              },
            ]}
            manual
            className="-striped -highlight"
            pages={totalPages}
            showPageJump={false}
            defaultPageSize={pagination.per_page}
            loading={isLoading || isLoadingMembers}
            noDataText={`${i18n.t("tableRowsEmpty")}`}
            previousText={`${i18n.t("previousText")}`}
            nextText={`${i18n.t("nextText")}`}
            pageText={<span className="pr-2">{i18n.t("pageText")}</span>}
            ofText={<span className="px-2">{i18n.t("ofText")}</span>}
            onPageSizeChange={(per_page) => {
              setPagination({ ...pagination, per_page: per_page });
            }}
            onPageChange={(page) => {
              setPagination({ ...pagination, page: page + 1 });
            }}
          />
        </CardBody>
      </CSSTransitionGroup>
    </Fragment>
  );
};

UserWorkGroupMembersTable.propTypes = {
  listMembers: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  deleteMemberOfWorkGroup: PropTypes.func.isRequired,
  setListWorkflows: PropTypes.func.isRequired,
  setIsMinimumGroupMembers: PropTypes.func.isRequired
};

export default UserWorkGroupMembersTable;
