import React from "react";
import PropTypes from "prop-types";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label } from "reactstrap";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { isNullOrUndefined } from "utils/validations";
import { enumsNumericTextBox } from "utils/enums";
import { regexNumericTextBoxNegative, regexNumericTextBoxPositive } from "utils/regexExpressions";
import cx from "classnames";
import i18n from "locales/i18n";

const NumericTextBox = (props) => {
  const {
    id,
    label,
    hide,
    uniqueFilingInBatch,
    required,
    isPreview,
    allowedNegative,
    handleOnChangeRecord,
    handleOnKeyPress,
    value,
  } = props;

  const { validationField, setValidationField } = useFieldsRecordContext();
  let isRequired = null;
  let hideLabelNumeric = null;
  let patternValue = null;
  let errorMessageValue = null;
  let uniqueFieldNumeric = null;
  let fieldValidationUnique = null;
  let disbledFieldInbatch = false;
  let alertMesageInfoBatch = null;
  let fieldRequired = required;

  /**
   * Checks if unique filing is enabled in batch processing mode and updates relevant fields accordingly.
   * @param {boolean} uniqueFilingInBatch - Indicates whether unique filing is enabled in batch processing mode.
   * @param {boolean} disbledFieldInbatch - Indicates whether fields are disabled in batch processing mode.
   * @param {boolean} fieldRequired - Indicates whether fields are required.
   * @param {string} alertMesageInfoBatch - Message displayed as an alert in batch processing mode.
   * @returns {void}
   */
  if (uniqueFilingInBatch === true) {
    disbledFieldInbatch = true;
    fieldRequired = false;
    alertMesageInfoBatch = i18n.t("taskBatchProcess.placeHolderUniqueFields");
  }

  /**
   * Determines if a field is required and returns a CSS class name accordingly.
   * @param {boolean} required - A boolean value indicating whether the field is required or not.
   * @returns {string|null} Returns the CSS class name "is-required" if the field is required, otherwise null.
   */
  if (required === true) {
    isRequired = "is-required";
  } else {
    isRequired = null;
  }

  /**
   * Conditionally generates a JSX element to hide or show a labeled numeric.
   * @param {boolean} hide - A boolean flag indicating whether to hide the labeled numeric.
   * @param {string} i18n.t - A function for translating text labels.
   * @returns {JSX.Element|null} - A JSX element representing a labeled numeric with optional hiding text, or null if 'hide' is false.
   */
  if (hide === true) {
    hideLabelNumeric = (
      <i className="hide-textBox custom-opacity">({i18n.t("form.field49")})</i>
    );
  } else {
    hideLabelNumeric = null;
  }

  /**
   * Validates a value based on whether negative values are allowed or not, using regular expressions.
   * @param {boolean} allowedNegative - A flag indicating whether negative values are allowed.
   * @param {object} i18n - An internationalization object for translating error messages.
   * @returns {boolean} Returns `true` if the value matches the allowed pattern, `false` otherwise.
   */
  if (allowedNegative === true) {
    patternValue = regexNumericTextBoxNegative;
    errorMessageValue = i18n.t("recordValidateNegative");
  } else {
    patternValue = regexNumericTextBoxPositive;
    errorMessageValue = i18n.t("recordValidatePositive");
  }

  /**
   * Check if a validation field's ID includes a specified ID and whether it is marked as unique.
   * If it's unique, sets a unique field text box and updates a validation flag.
   * If not unique, clears the unique field text box and resets the validation flag.
   * @param {string[]} id - The ID to check against the validation field's ID.
   * @param {boolean} unique - Whether the validation field is marked as unique.
   * @param {string} i18n - An internationalization function for localization.
   * @param {string|null} uniqueFieldTextBox - The JSX element for displaying a unique field message.
   * @param {boolean} fieldValidationUnique - A flag indicating if the field has passed unique validation.
   * @returns {void}
   */
  if (validationField.id.includes(id) && validationField.unique) {
    uniqueFieldNumeric = (
      <span className="text-danger text small">
        {i18n.t("recordValidateUnique")}
      </span>
    );
    fieldValidationUnique = true;
  } else {
    uniqueFieldNumeric = null;
    fieldValidationUnique = false;
  }

  if (isPreview === true) {
    return (
      <AvForm>
        <AvGroup row>
          <Label className={cx("", {
            "is-required": required === true,
          })} sm={3}>
            {label} {hideLabelNumeric}
          </Label>
          <Col md={8}>
            <AvField
              id={id}
              name={id}
              type="text"
              key={id}
              autoComplete="off"
              disabled={disbledFieldInbatch}
              validate={{
                pattern: {
                  value: patternValue,
                  errorMessage: errorMessageValue,
                },
                required: {
                  value: fieldRequired,
                  errorMessage: `${i18n.t("fieldRequired")}`,
                },
                max: {
                  value: enumsNumericTextBox.MAX_LENGTH,
                  errorMessage: `${i18n.t("form.field48")}`,
                },
              }}
              onChange={(eventInputChangeValue) => {
                const inputValue = eventInputChangeValue.target.value;
                if (isNullOrUndefined(inputValue) === false) {
                  handleOnChangeRecord(id, Number(inputValue));
                }
              }}
              onKeyPress={handleOnKeyPress}
              value={value}
            />

            {uniqueFieldNumeric}
          </Col>
        </AvGroup>
      </AvForm>
    );
  } else if (hide === false) {
    return (
      <AvGroup row>
        <Label
          className={cx(isRequired, fieldValidationUnique === false, {
            "label-color is-required": fieldValidationUnique === true,
          })}
          sm={3}
        >
          {label}
        </Label>
        <Col md={8}>
          <AvField
            id={id}
            name={id}
            type="text"
            key={id}
            autoComplete="off"
            disabled={disbledFieldInbatch}
            className={cx("", fieldValidationUnique === false, {
              "unique-Field": fieldValidationUnique === true,
              "field-DisabledUnique": uniqueFilingInBatch === true,
            })}
            validate={{
              pattern: {
                value: patternValue,
                errorMessage: errorMessageValue,
              },
              required: {
                value: fieldRequired,
                errorMessage: `${i18n.t("fieldRequired")}`,
              },
              max: {
                value: enumsNumericTextBox.MAX_LENGTH,
                errorMessage: `${i18n.t("form.field48")}`,
              },
            }}
            onChange={(eventInputChangeValue) => {
              const inputValue = eventInputChangeValue.target.value;
              if (isNullOrUndefined(inputValue) === true) {
                handleOnChangeRecord(id, null);
              } else {
                handleOnChangeRecord(id, Number(inputValue));
              }
              if (validationField.id.length > 0) {
                const newArrayValidated = validationField.id.filter(
                  (item) => item !== id
                );
                setValidationField({
                  id: newArrayValidated,
                  unique: true,
                });
              }
            }}
            onKeyPress={handleOnKeyPress}
            value={value}
            placeholder={alertMesageInfoBatch}
          />
          {uniqueFieldNumeric}
        </Col>
      </AvGroup>
    );
  } else {
    return null;
  }
};
NumericTextBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  uniqueFilingInBatch: PropTypes.bool,
  hide: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  allowedNegative: PropTypes.bool.isRequired,
  handleOnChangeRecord: PropTypes.func.isRequired,
  handleOnKeyPress: PropTypes.func.isRequired,
  value: PropTypes.number,
};
export default NumericTextBox;
