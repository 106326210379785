import React from "react";
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  ModalFooter,
  Spinner,
} from "reactstrap";
import cx from "classnames";
import makeAnimated from "react-select/lib/animated";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { loaderElement } from "utils/loaderElement";
import Select from "react-select";
import i18n from "locales/i18n";


const ModalResponsibleStep = (props) => {
  const {
    showModalReasign,
    handleOnCloseFulfillProcess,
    handleOnSubmitForm,
    handleOnChangeUsersFulfillProcess,
    isErrorSelectUser,
    selectedOption,
    filteredUsers,
    isLoading,
    isLoadingComplyProcessBatch,
  } = props;
  let spinnerLoadingProcess = null;
  let selectLabelErrorResponsible = null;

  /**
   * Conditionally renders label and select components for modal responsible based on error state.
   * @param {boolean} isErrorSelectUser - Flag indicating whether an error occurred for the select user.
   * @param {Object} labelError - CSS styles for label when error occurs.
   * @param {Object} labelNoError - CSS styles for label when no error occurs.
   * @param {JSX.Element} i18n - JSX element for internationalization.
   * @param {JSX.Element} selectedOption - Currently selected option.
   * @param {Array} filteredUsers - Array of filtered user options.
   * @param {function} handleOnChangeUsersFulfillProcess - Function to handle change in selected user for fulfilling process.
   * @returns {JSX.Element} - Label and select components for modal responsible.
   */
  if (isErrorSelectUser === true) {
    selectLabelErrorResponsible = (
      <div>
        <span className="text-danger small">{i18n.t("fieldRequired")}</span>
      </div>
    );
  }

  /**
   * Conditionally renders a spinner component if the comply process batch is loading.
   * @param {boolean} isLoadingComplyProcessBatch - Flag indicating whether the comply process batch is loading.
   * @returns {JSX.Element|null} - Spinner component or null.
   */
  if (isLoadingComplyProcessBatch === true) {
    spinnerLoadingProcess = <Spinner size="sm" color="secondary" type="grow" />;
  }

  return (
    <Modal
      className="modal-custom"
      isOpen={showModalReasign}
      toggle={handleOnCloseFulfillProcess}
      backdrop={true}
      keyboard={true}
    >
      {loaderElement(isLoadingComplyProcessBatch)}
      <ModalHeader>
        <Label>{i18n.t("createWorkflow.fullFill.selectUser")}</Label>
      </ModalHeader>
      <AvForm onSubmit={handleOnSubmitForm}>
        <ModalBody>
          <Row>
            <Col md="12">
              <Alert className="mbg-3" color="info" isOpen={true}>
                <span className="pr-2">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
                {i18n.t("createWorkflow.fullFill.selectUser.bagde")}
              </Alert>{" "}
              <AvGroup>
                <Label
                  for="modules"
                  className={cx("is-required", {
                    "label-color": isErrorSelectUser === true,
                  })}
                >
                  {i18n.t("taskReassign.users")}
                </Label>
                <Select
                  id="users_uuid"
                  name="users_uuid"
                  data-test-id="users_uuid"
                  classNamePrefix={cx("", {
                    select: isErrorSelectUser === true,
                  })}
                  placeholder={i18n.t("notifications.usersPlaceholder")}
                  closeMenuOnSelect={true}
                  components={makeAnimated()}
                  value={selectedOption}
                  options={filteredUsers}
                  onChange={handleOnChangeUsersFulfillProcess}
                  noOptionsMessage={() => i18n.t("createRoles.MembersNousers")}
                />
                {selectLabelErrorResponsible}
              </AvGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            className="col-mt-3 mr-2"
            color="gray"
            onClick={handleOnCloseFulfillProcess}
          >
            {i18n.t("createusers.createButton2")}
          </Button>
          <Button
            type="submit"
            size="lg"
            disabled={isLoading}
            className="col-mt-3"
            color="cyan"
          >
            {spinnerLoadingProcess}
            {i18n.t("modal.Done.footerButton")}
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default ModalResponsibleStep;
