import React, { Fragment } from "react";
import AdminTemplate from "components/templates/AdminTemplate";
import DesignerWorkFlowEdit from "components/organism/DesignerWorkFlowEdit";
import i18n from "locales/i18n";

const WorkFlowEdit = () => {
  return (
    <Fragment>
      <AdminTemplate
        heading={i18n.t("createWorflow.Edit")}
        subheading={i18n.t("createWorflow.headingEdit")}
        icon="note2"
      >
        <DesignerWorkFlowEdit />
      </AdminTemplate>
    </Fragment>
  )
}

export default WorkFlowEdit