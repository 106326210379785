import React, { Fragment } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import {
	Button,
	CardTitle,
	Label,
	CardFooter,
	Spinner,
	CardBody,
} from "reactstrap";
import { useTrdContext } from "contextAPI/TrdContext";
import { trimExtraSpaces } from "utils/trimSpaces";
import { enumCreateSectionForm, enumLevelTrd } from "utils/enums";
import { showAlertCrateSectionSuccess, showAlertServiceError } from "utils/alerts";
import { regexCreateSection, regexTrdForm } from "utils/regexExpressions";
import useTrd from 'hooks/useTrd';
import ReactTooltip from "react-tooltip";
import i18n from "locales/i18n";

const CreateSectionForm = () => {
	const {
		trdForm,
		foldersId,
		isLoading,
		setIsLoading,
		setTrdForm,
		getTreeByDomainCallback,
	} = useTrdContext();
	const {
		createSection,
	} = useTrd();

	let renderLoadingComponent = null;

	/**
	 * Renders a loading spinner component if the loading state is true.
	 * @param {boolean} isLoading - The loading state flag for section creation.
	 * @returns {JSX.Element|null} A JSX element for the spinner if loading, otherwise null.
	 */
	if (isLoading === true) {
		renderLoadingComponent = (
			<Spinner size="sm" color="secondary" type="grow" />
		)
	};

	/**
	 * Handles the blur event for input fields, updating the form state with the trimmed value.
	 * @param {Object} eventHandleBlurName - The event object from the blur event.
	 * @param {string} eventHandleBlurName.target.name - The name of the input field that triggered the blur event.
	 * @param {string} eventHandleBlurName.target.value - The current value of the input field.
	 */
	const handleOnBlur = (eventOnBlur) => {
		const { value, name } = eventOnBlur.target;
		const trimmedValue = trimExtraSpaces(value);
		setTrdForm({
			...trdForm,
			[name]: trimmedValue,
		});
	};

	/**
	 * Handles the change event for input fields, updating the form state with the new value.
	 * @param {Object} eventHandleChange - The event object from the change event.
	 * @param {string} eventHandleChange.target.name - The name of the input field that triggered the change event.
	 * @param {string} eventHandleChange.target.value - The new value of the input field.
	 */
	const handleOnChange = (eventHandleChange) => {
		setTrdForm({
			...trdForm,
			[eventHandleChange.target.name]: eventHandleChange.target.value,
		});
	};

	/**
	 * Handles the key press event, preventing the default action if the Enter key is pressed.
	 * @param {Object} eventKeyPresCreateSection - The event object from the key press event.
	 * @param {string} eventKeyPresCreateSection.key - The key value of the pressed key.
	 * @param {function} eventKeyPresCreateSection.preventDefault - A method to prevent the default action associated with the event.
	 */
	const handleOnKeyPress = (eventKeyPresCreateSection) => {
		if (eventKeyPresCreateSection.key === "Enter") {
			eventKeyPresCreateSection.preventDefault();
		}
	};

	/**
	 * Handles the form submission, processes the data, and manages loading and error states.
	 * @param {Object} eventSubmitCreateSection - The event object from the form submission.
	 * @param {Array} errors - An array of validation errors.
	 * @param {Function} createSection - Function to create a section with the provided data.
	 * @param {Function} getTreeByDomainCallback - Callback function to refresh the tree view.
	 * @param {Function} showAlertCrateSectionSuccess - Function to show a success alert.
	 * @param {Function} showAlertServiceError - Function to show an error alert.
	 * @param {Object} enumLevelTrd - Enum object containing level constants.
	 * @param {Object} trdForm - The current form state.
	 * @param {Function} setIsLoading - Function to set the loading state.
	 * @param {Function} setTrdForm - Function to update the form state.
	 * @param {Object} i18n - The internationalization object used for translations.
	 * @param {Object} foldersId - Object containing folder IDs.
	 * @returns {void}
	 */
	const handleOnSubmit = (eventSubmitCreateSection, errors) => {
		eventSubmitCreateSection.preventDefault()
		setIsLoading(true);
		if (errors.length === 0) {
			const data = {
				code: trdForm.code,
				id: trdForm.id,
				level: enumLevelTrd.SECTION,
				name: trdForm.name
			}
			setIsLoading(true)
			createSection(data, foldersId.backgroundId)
				.then((response) => {
					if (response.status === 201) {
						getTreeByDomainCallback();
						showAlertCrateSectionSuccess();
					} else {
						showAlertServiceError();
					}
				})
				.finally(() => {
					setTrdForm({
						...trdForm,
						level: enumLevelTrd.BACKGROUND,
						name: "",
						code: i18n.t("trd.inputTittle7"),
					});
					setIsLoading(false);
				});
		}
		setIsLoading(false);
	};

	return (
		<Fragment>
			<CSSTransitionGroup
				component="div"
				transitionName="TabsAnimation"
				transitionAppear={true}
				transitionAppearTimeout={0}
				transitionEnter={false}
				transitionLeave={false}
			>
				<CardBody>
					<AvForm onSubmit={handleOnSubmit}>
						<AvGroup>
							<CardTitle>{i18n.t("navItems.aplication.TrdOption2")}</CardTitle>
							<ReactTooltip
								id="code"
								place="bottom"
								type="dark"
								effect="solid"
							>
								{i18n.t("trd.Tooltip2")}
							</ReactTooltip>
							<Label for="code" className="is-required">
								{i18n.t("trd.fromLabel1")}
							</Label>
							<AvField
								id="code"
								name="code"
								type="text"
								onChange={handleOnChange}
								onKeyPress={handleOnKeyPress}
								onBlur={handleOnBlur}
								validate={{
									pattern: {
										value: regexTrdForm,
										errorMessage: `${i18n.t("trd.formCodeValidation")}`,
									},
									required: {
										value: true,
										errorMessage: `${i18n.t("trd.feedback1")}`,
									},
									minLength: {
										value: enumCreateSectionForm.MIN_LENGTH_NAME,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
									},

									maxLength: {
										value: enumCreateSectionForm.MAX_LENGTH_NAME,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
									},
								}}
								data-tip
								data-for="code"
								autoFocus={true}
								autoComplete="off"
								value={trdForm.code}
							/>
						</AvGroup>

						<AvGroup>
							<ReactTooltip
								id="name_element"
								place="bottom"
								type="dark"
								effect="solid"
							>
								{i18n.t("trd.Tooltip3")}
							</ReactTooltip>
							<Label for="name" className="is-required">
								{i18n.t("trd.inputTittle2")}
							</Label>
							<AvField
								id="name"
								name="name"
								type="text"
								onChange={handleOnChange}
								onKeyPress={handleOnKeyPress}
								onBlur={handleOnBlur}
								placeholder={i18n.t("trd.inputTittle6")}
								validate={{
									pattern: {
										value: regexCreateSection,
										errorMessage: `${i18n.t("trd.feedback4")}`,
									},
									required: {
										value: true,
										errorMessage: `${i18n.t("trd.feedback4")}`,
									},
									minLength: {
										value: enumCreateSectionForm.MIN_LENGTH_CODE,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
									},

									maxLength: {
										value: enumCreateSectionForm.MAX_LENGTH_CODE,
										errorMessage: `${i18n.t(
											"fieldValidateLengthBetween"
										)} 1 ${i18n.t("and")} 500 ${i18n.t("characters")}`,
									},
								}}
								data-tip
								data-for="name_element"
								autoComplete="off"
								required
								value={trdForm.name}
							/>
						</AvGroup>
						<CardFooter className="d-block text-right">
							<Button
								type="submit"
								size="lg"
								className="col-mt-3"
								disabled={isLoading}
								color="cyan"
							>
								{renderLoadingComponent}
								{i18n.t("createusers.createButton")}
							</Button>
						</CardFooter>
					</AvForm>
				</CardBody>
			</CSSTransitionGroup>
		</Fragment>
	)
}

export default CreateSectionForm