import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useParams } from "react-router-dom";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Button, Col, Alert, Row } from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { showAlertServiceError } from "utils/alerts";
import { WorkFlowConfigStepsTab3FormDatas, enumsFilterWorkflowState, enumsWorkflowStatus } from "utils/enums";  
import WorkFlowAddFields from "components/organism/WorkFlowAddFields";
import useWorkFlow from "hooks/useWorkFlow";
import swal from "sweetalert";
import i18n from "locales/i18n";

const WorkFlowConfigStepsTab3FormData = (props) => {
  const { objectProcessInfo, workflowStatus } = props;
  const { id, workflowid } = useParams();
  const stepId = id;
  const { deleteCofigurationFormInOneStep } = useWorkFlow();
  const {
    dataFormSelected,
    setDataFormSelected,
    setViewAddFields,
    viewAddFields,
    formName,
    editConfigStepsTab3FormData,
    setEditConfigStepsTab3FormData,
    setFormName,
    setFillingOutForm,
    formExternalName,
    handleDeleteConfigurationDataFormInStep,
    handleAlertDeleteConfigForm,
    setShowExternalFormEdit,
  } = useWorkflowContext();
  let dataFormSelectedDisabled;
  let requiredStep;
  let disabledSelect;
  let alertStatusWorkflowWrong = null;
  let showConfigurationExternalForm = null;
  let showFormNameEditOptions = null;
  let optionFieldsFilingForm = null;
  let optionFillOutFormInOneStep = null;

  /**
   * Renders an option element with a default message if the external form name is null or undefined.
   * @param {string|null|undefined} formExternalName - The name of the external form.
   * @returns {JSX.Element|null} - A JSX option element with a default message, or `null` if the form name is not null or undefined.
   */
  if (isNullOrUndefined(formExternalName) === true) {
    optionFillOutFormInOneStep = (
      <option value={WorkFlowConfigStepsTab3FormDatas.FILL_OUT_FORM_ONE_STEP}>
        {i18n.t("createWorkflow.configurations.label9")}
      </option>
    );
  };

  /**
   * Renders an option element with a default message if the form name is null or undefined.
   * @param {string|null|undefined} formName - The name of the form.
   * @returns {JSX.Element|null} - A JSX option element with a default message, or `null` if the form name is not null or undefined.
   */
  if (isNullOrUndefined(formName) === true) {
    optionFieldsFilingForm = (
      <option value={WorkFlowConfigStepsTab3FormDatas.FILL_FIELDS_FILING_FORM}>
        {i18n.t("createWorkflow.configurations.label7")}
      </option>
    );
  };

  /**
   * Handles the action to show an external form for editing and hides other elements.
   * This function hides the configuration steps tab, additional fields view, and then shows the external form for editing.
   * @returns {void}
   */
  const handleShowExternelForm = () => {
    setEditConfigStepsTab3FormData(false);
    setViewAddFields(false);
    setShowExternalFormEdit(true);
  };

  /**
   * Handles the deletion of a workflow configuration form and displays a confirmation dialog.
   * This function displays a confirmation dialog using the 'swal' library to confirm the deletion of a workflow configuration form.
   * If the user confirms the deletion, it calls the 'deleteCofigurationFormInOneStep' function to delete the form with the provided 'workflowid' and 'stepId'.
   * If the deletion is successful (response status 202), it updates the state by setting 'setEditConfigStepsTab3FormData' to 'false', clearing 'setFormName',
   * and invoking the 'handleAlertDeleteConfigForm' function.
   * If the user cancels the deletion, it prevents the default behavior of the 'eventDeleteWorkflow'.
   * @param {Event} eventDeleteWorkflow - The event object associated with the delete action.
   * @returns {void}
   */
  const handleDeleteWorkflowConfigForm = (eventDeleteWorkflow) => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("WorkFlowEdit.delete"),
      icon: "warning",
      buttons: [
        i18n.t("createusers.createButton2"),
        i18n.t("modal.Done.footerButton"),
      ],
    }).then((willDeleteWorkFlowConfigForm) => {
      if (willDeleteWorkFlowConfigForm) {
        deleteCofigurationFormInOneStep(workflowid, stepId).then((response) => {
          if (response.status === 202) {
            setEditConfigStepsTab3FormData(false);
            setFormName("");
            handleAlertDeleteConfigForm();
          } else {
            showAlertServiceError();
          }
        });
      } else {
        eventDeleteWorkflow.preventDefault();
      }
    });
  };

  /**
   * Deletes a configuration form associated with a workflow step.
   * @param {Event} eventDeleteFormInStep - The event object that triggered the form deletion.
   * @param {string} stepId - The unique identifier of the workflow step from which the form should be deleted.
   * @param {string} workflowid - The unique identifier of the workflow to which the step belongs.
   * @returns {void}
   */
  const deleteConfigurationFormInStep = (eventDeleteFormInStep) => {
    handleDeleteConfigurationDataFormInStep(
      eventDeleteFormInStep,
      stepId,
      workflowid
    );
  };

  /**
   * Handles the click event to enable editing of configuration steps for tab 3's form data and displays additional fields.
   * This function sets the `editConfigStepsTab3FormData` state to `true` and `viewAddFields` state to `true`, enabling the editing of
   * configuration steps and displaying additional fields on the tab.
   * @returns {void}
   */
  const handleOnClickEditConfigStepsTab3FormData = () => {
    setEditConfigStepsTab3FormData(true);
    setViewAddFields(true);
  };

  /**
   * Renders the form name edit options if the form name is not null or undefined.
   * @param {string|null|undefined} formName - The name of the form.
   * @param {number} workflowStatus - The current status of the workflow.
   * @returns {JSX.Element|null} - A JSX element displaying the form name edit options, or `null` if the form name is null or undefined.
   */
  if (isNullOrUndefined(formName) === false) {
    showFormNameEditOptions = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Alert
          className="mbg-3 ext-primary text-capitalize font-weight-bold"
          color="info"
        >
          <Row>
            <Col md={8}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              {i18n.t("createWorkflow.configurations.fields")}:{" "}
              {formName}
            </Col>

            <Col md={4} className="text-right">
              <Button
                outline
                className="border-0 btn-transition"
                color="cyan"
                disabled={workflowStatus !== enumsFilterWorkflowState.STATUS_ERASER}
                onClick={handleOnClickEditConfigStepsTab3FormData}
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("buttonActions.option2")}
              </Button>

              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                disabled={workflowStatus !== enumsFilterWorkflowState.STATUS_ERASER}
                onClick={handleDeleteWorkflowConfigForm}
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                {i18n.t("buttonActions.option3")}
              </Button>
            </Col>
          </Row>
        </Alert>
      </CSSTransitionGroup>
    );
  } else {
    showFormNameEditOptions = null;
  };

  /**
   * Renders the external form configuration alert if the form name is not null or undefined.
   * @param {string|null|undefined} formExternalName - The name of the external form.
   * @param {number} workflowStatus - The current status of the workflow.
   * @returns {JSX.Element|null} - A JSX element displaying the configuration alert, or `null` if the form name is null or undefined.
   */
  if (isNullOrUndefined(formExternalName) === false) {
    showConfigurationExternalForm = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Alert
          className="mbg-3 ext-primary font-weight-bold"
          color="info"
        >
          <Row>
            <Col md={8}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="mr-2"
              />
              {i18n.t(
                "createWorkflow.configurations.labelAlertForms"
              )}{" "}
              {formExternalName}
            </Col>

            <Col md={4} className="text-right">
              <Button
                outline
                className="border-0 btn-transition"
                color="cyan"
                disabled={workflowStatus !== enumsFilterWorkflowState.STATUS_ERASER}
                onClick={handleShowExternelForm}
              >
                <FontAwesomeIcon icon={faPen} className="mr-2" />
                {i18n.t("buttonActions.option2")}
              </Button>

              <Button
                outline
                type="button"
                className="border-0 btn-transition"
                color="cyan"
                disabled={workflowStatus !== enumsWorkflowStatus.ERASER}
                onClick={(eventDeleteFormInStep) =>
                  deleteConfigurationFormInStep(
                    eventDeleteFormInStep
                  )
                }
              >
                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                {i18n.t("buttonActions.option3")}
              </Button>
            </Col>
          </Row>
        </Alert>
      </CSSTransitionGroup>
    );
  } else {
    showConfigurationExternalForm = null;
  };

  /**
   * Conditional Rendering of Workflow Status Alert
   * This code conditionally renders an alert component if the workflowStatus is not equal to 1.
   * The alert informs the user about an invalid workflow status.
   * @param {number} workflowStatus - The status of the workflow, where 1 indicates a valid status.
   * @param {string} i18n - An internationalization function for translating text.
   * @returns {JSX.Element|null} The JSX element representing the alert, or null if the workflowStatus is 1.
   */
  if (workflowStatus !== enumsWorkflowStatus.ERASER) {
    alertStatusWorkflowWrong = (
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Alert className="mbg-3 ext-primary font-weight-bold" color="info">
          <span className="pr-2">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
          {i18n.t("WorkFlowEdit.Invalid")}
        </Alert>
      </CSSTransitionGroup>
    );
  }

  /**
   * Update the state variables based on the value of 'dataFormSelected'.
   * This function checks the value of 'dataFormSelected' and updates two state variables,
   * 'dataFormSelectedDisabled' and 'requiredStep', based on its value.
   * @param {string} dataFormSelected - The selected data form. If this value is an empty string,
   * the function will disable the dataFormSelected and set the requiredStep to true.
   * @param {boolean} dataFormSelectedDisabled - A boolean state variable that indicates whether the
   * 'dataFormSelected' is disabled or not.
   * @param {boolean} requiredStep - A boolean state variable that indicates whether a step is required.
   * @returns {void}
   */
  if (dataFormSelected === "") {
    dataFormSelectedDisabled = true;
    requiredStep = true;
  } else {
    dataFormSelectedDisabled = false;
    requiredStep = false;
  }

  /**
   * Determine the value of the 'disabledSelect' variable based on the provided form names
   * @param {string | null | undefined} formName - The name of the form.
   * @param {string | null | undefined} formExternalName - The external name of the form.
   * @returns {boolean} - True if either 'formName' or 'formExternalName' is not null or undefined; otherwise, false.
   */
  if (
    isNullOrUndefined(formName) === false &&
    isNullOrUndefined(formExternalName) === false
  ) {
    disabledSelect = true;
  } else {
    disabledSelect = false;
  };

  /**
   * Handle a click event to set the view based on the selected dataForm.
   * This function updates the view state based on the value of the dataFormSelected variable.
   * @returns {void}
   */
  const handleOnClickSetView = () => {
    if (dataFormSelected === WorkFlowConfigStepsTab3FormDatas.FILL_FIELDS_FILING_FORM) {
      setViewAddFields(true);
    } else if (dataFormSelected === WorkFlowConfigStepsTab3FormDatas.FILL_OUT_FORM_ONE_STEP) {
      setFillingOutForm(true);
    }
  };

  /**
   * Handles a change event for a responsible option and updates the selected data form.
   * @param {Event} eventResponsibleOption - The change event object triggered when a responsible option is selected.
   * @param {*} eventResponsibleOption.target - The target element that triggered the event.
   * @param {*} eventResponsibleOption.target.value - The value associated with the selected responsible option.
   * @returns {void}
   */
  const handleOnChangeOption = (eventResponsibleOption) => {
    setDataFormSelected(eventResponsibleOption.target.value);
  };

  if (objectProcessInfo.type === "START_EVENT") {
    return (
      <Alert className="mbg-3 ext-primary font-weight-bold" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("createWorkflow.edit.config2")}
        <br />
      </Alert>
    );
  } else if (viewAddFields === true) {
    return (
      <WorkFlowAddFields
        setViewAddFields={editConfigStepsTab3FormData}
        viewAddFields={viewAddFields}
        editConfigStepsTab3FormData={editConfigStepsTab3FormData}
      />
    );
  } else {
    return (
      <AvForm>
        <Fragment>
          <CSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
          >
            <Row>
              <Col lg="5" md="4" sm="12" className="button-container pb-2">
                <h6 className="text-info font-weight-bold">
                  {i18n
                    .t("createWorkflow.configurations.fields1")
                    .toUpperCase()}
                </h6>
              </Col>
            </Row>

            <AvGroup row>
              <Col md={8}>
                <AvField
                  id="dataFormSelected"
                  name="dataFormSelected"
                  type="select"
                  disabled={disabledSelect || workflowStatus !== enumsWorkflowStatus.ERASER}
                  required={requiredStep}
                  onChange={handleOnChangeOption}
                  errorMessage={i18n.t("createWorkflow.configurations.label6")}
                  value={dataFormSelected}
                >
                  <option value={""}>
                    {i18n.t("createWorkflow.configurations.fields2")}
                  </option>

                  {optionFieldsFilingForm}

                  {optionFillOutFormInOneStep}

                </AvField>
              </Col>
              <Col md={4}>
                <div>
                  <Button
                    disabled={dataFormSelectedDisabled || workflowStatus !== enumsFilterWorkflowState.STATUS_ERASER}
                    className="btn-block"
                    color="cyan"
                    size="lg"
                    onClick={handleOnClickSetView}
                  >
                    {i18n.t("createWorkflow.config.AddUser")}
                  </Button>
                </div>
              </Col>
            </AvGroup>

            {alertStatusWorkflowWrong}

            {showFormNameEditOptions}

            {showConfigurationExternalForm}
          </CSSTransitionGroup>
        </Fragment>
      </AvForm>
    );
  }
};

WorkFlowConfigStepsTab3FormData.protoTypes = {
  objectProcessInfo: PropTypes.object.isRequired,
  workflowStatus: PropTypes.number.isRequired,
};

export default WorkFlowConfigStepsTab3FormData;
