import i18n from "locales/i18n";

export default class CustomPalette {
  constructor(bpmnFactory, create, elementFactory, palette) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;

    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const { bpmnFactory, create, elementFactory } = this;

    /**
     * Creates a function to start the creation of a BPMN User Task with a given suitability score.
     * @param {number} suitabilityScore - The suitability score to be assigned to the User Task.
     * @returns {Function} - A function that takes an event and starts the creation of a User Task.
     */
    function createUserTask(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:UserTask");
        businessObject.suitable = suitabilityScore;
        const shape = elementFactory.createShape({
          type: "bpmn:UserTask",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    };

    /**
     * Creates a function to start the creation of a BPMN Manual Task with a given suitability score.
     * @param {number} suitabilityScore - The suitability score to be assigned to the Manual Task.
     * @returns {Function} - A function that takes an event and starts the creation of a Manual Task.
     */
    function manualTask(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:ManualTask");
        businessObject.suitable = suitabilityScore;
        const shape = elementFactory.createShape({
          type: "bpmn:ManualTask",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    };

    /**
     * Creates a function to start the creation of a BPMN Exclusive Gateway with a given suitability score.
     * @param {number} suitabilityScore - The suitability score to be assigned to the Exclusive Gateway.
     * @returns {Function} - A function that takes an event and starts the creation of an Exclusive Gateway.
     */
    function exclusiveGates(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:ExclusiveGateway");
        businessObject.suitable = suitabilityScore;
        const shape = elementFactory.createShape({
          type: "bpmn:ExclusiveGateway",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    };

    /**
     * Creates a function to start the creation of a BPMN End Event with a given suitability score.
     * @param {number} suitabilityScore - The suitability score to be assigned to the End Event.
     * @returns {Function} - A function that takes an event and starts the creation of an End Event.
     */
    function endEvent(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:EndEvent");
        businessObject.suitable = suitabilityScore;
        const shape = elementFactory.createShape({
          type: "bpmn:EndEvent",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    };

    /**
     * Creates a function to start the creation of a BPMN Terminate End Event with a given suitability score.
     * @param {number} suitabilityScore - The suitability score to be assigned to the Terminate End Event.
     * @returns {Function} - A function that takes an event and starts the creation of a Terminate End Event.
     */
    function terminateEndEvent(suitabilityScore) {
      return function (event) {
        const businessObject = bpmnFactory.create("bpmn:EndEvent");
        businessObject.suitable = suitabilityScore;
        const shape = elementFactory.createShape({
          type: "bpmn:EndEvent",
          eventDefinitionType: "bpmn:TerminateEventDefinition",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    };

    return {
      "create.user-task": {
        group: "activity",
        className: "bpmn-icon-user-task",
        title: i18n.t("bpmnLint.CustomPaletteCreateUserTask"),
        action: {
          dragstart: createUserTask(),
        },
      },
      "create.manual-task": {
        group: "activity",
        className: "bpmn-icon-manual-task",
        title: i18n.t("bpmnLint.CustomPaletteCreateManualTask"),
        action: {
          dragstart: manualTask(),
        },
      },
      "create.exclusive-gateway": {
        group: "activity",
        className: "bpmn-icon-gateway-none",
        title: i18n.t("bpmnLint.CustomPaletteCreateExclusiveGateway"),
        action: {
          dragstart: exclusiveGates(),
        },
      },

      "create.end-event": {
        group: "activity",
        className: "bpmn-icon-end-event-none",
        title: i18n.t("bpmnLint.CustomPaletteCreateEndEvent"),
        action: {
          dragstart: endEvent(),
        },
      },

      "create.terminate-end-event": {
        group: "activity",
        className: "bpmn-icon-end-event-terminate",
        title: i18n.t("bpmnLint.CustomPaletteCreateTerminationEndEvent"),
        action: {
          dragstart: terminateEndEvent(),
        },
      },
    };
  }
}

CustomPalette.$inject = [
  "bpmnFactory",
  "create",
  "elementFactory",
  "palette",
  "translate",
];
