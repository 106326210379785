import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import UserListTable from "components/organism/UserListTable";
import FilterUserState from "components/atoms/FilterUserState";
import ButtonCreateUser from "components/atoms/ButtonCreateUser";
import SearchBox from "components/atoms/SearchBox";
import useUser from "hooks/useUser";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserList= () => {
  const [listUsers, setListUsers] = useState([]);
  const [isLoadingUserList, setIsLoadingUserList] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const { getUsers } = useUser();

  /**
   *Function that displays a sweetalert with a message indicating that no users were found based on the current searchUser query and userStatus filter.
   *@param {object} i18n - the i18n object used for translations
   */
  const showAlertUserNoExist = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("filteruser.item6"),
      icon: "info",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   *A memoized function that fetches a list of users based on the searchUser, pagination, and userStatus parameters.
   *@param {function} getUsers - the function that fetches the users data and returns a promise
   *@param {object} pagination - an object containing page and per_page properties to define the pagination of the results
   *@param {string} searchUser - the searchUser query to filter the users by
   *@param {string} userStatus - the userStatus to filter the users by
   *@param {function} showAlertUserNoExist - the function that displays an alert message if there are no users found
   */
  const getUsersBySearch = useCallback(() => {
    setIsLoadingUserList(true);
    getUsers(pagination.page, pagination.per_page, searchUser, userStatus)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          if (response.data.items.length === 0) {
            showAlertUserNoExist();
          }
          setListUsers(response.data.items);
          setTotalPages(response.data.pages);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingUserList(false);
      });
  }, [pagination, searchUser, userStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   *useEffect hook that invokes the memoized getUsersBySearch function when the component mounts or the getUsersBySearch function changes.
   *@param {function} getUsersBySearch - the memoized function that fetches a list of users based on the searchUser, pagination, and userStatus parameters
   */
  useEffect(() => {
    getUsersBySearch();
  }, [getUsersBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchUser}
              pagination={pagination}
              setPagination={setPagination}
            />
            <FilterUserState
              setUserStatus={setUserStatus}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonCreateUser />
          </CardFooter>
          <CardBody>
            <UserListTable
              listUsers={listUsers}
              pagination={pagination}
              isLoadingUserList={isLoadingUserList}
              totalPages={totalPages}
              setPagination={setPagination}
            />
          </CardBody>
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserList;
