import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { useTrdContext } from "contextAPI/TrdContext";
import { enumsCreateExpedient } from "utils/enums";
import { loaderElement } from "utils/loaderElement";
import useTrd from "hooks/useTrd";
import useForm from "hooks/useForm";
import MultiStepFullFill from "components/molecules/WizardFullFill";
import FormCommentProcessBatch from "components/molecules/FormCommentProcessBatch";
import CreateProcessBatchOk from "../Messages/Success/CreateProcessBatchOk";
import ButtonBackListTasks from "components/atoms/ButtonBackListTasks";
import TrdLevelsTree from "components/molecules/TrdLevelsTree";
import RecordListExpedient from "components/organism/RecordListExpedient";
import TaskUploadFilesByExpedient from "components/organism/TaskUploadFilesByExpedient";
import TaskUploadFiles from "components/organism/TaskUploadFiles";
import ProcessFillingForms from "components/organism/ProcessFillingForms";
import i18n from "locales/i18n";

const WizardProcessBatch = () => {
  const [treeExist, setTreeExist] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const {
    getProcessById,
    selectedFields,
    formFilingName,
    process,
    setShowFormsFillingProcess,
    checkedFilingForm,
    checkedOmitedUploadFile,
    setProcessTaskBatch,
    formIdInfo,
    isLoadingComplyProcessBatch,
  } = useWorkflowContext();
  const {
    setBackgroundByPath,
    setPathConfiguration,
    pathConfiguration,
    getBackGroundByRoute,
    getParentIdByRoute,
    setPathConfig,
    currentPath,
    setCurrentPath,
  } = useRecordDetailContext();
  const {
    setExpedientParentByPath,
    foldersIdByPath,
    setFoldersIdByPath,
  } = useTrdContext();
  const { getFormsById } = useForm();
  const { getTreeExist } = useTrd();
  let FIRST_INDEX_TASK = 0;

  /**
   * Defines an array of steps for a batch process, each step containing a name and a corresponding component.
   * @constant {Array} steps - An array of step objects representing the steps in the batch process.
   * @param {string} name - The name of the step, often localized using the i18n.t function.
   * @param {React.Component} component - The React component associated with the step.
   * @param {string} currentProcess - The name of the current process, used to determine the step's context or behavior.
   * @returns {Array} An array of step objects, each containing a name and a corresponding component.
   */
  let steps = [
    {
      name: i18n.t("taskBatchProcess.titleComments"),
      component: <FormCommentProcessBatch currentProcess={formFilingName} />,
    },
    { name: i18n.t("form.field4"), component: <CreateProcessBatchOk /> },
  ];

  /**
   * Represents a TRD (Table of Retention and Disposal) levels tree configuration.
   * This configuration includes the name of the tree and its corresponding component.
   * @property {string} name - The name of the TRD levels tree, localized using i18n.t().
   * @property {React.Component} component - The React component representing the TRD levels tree.
   * @param {boolean} isProcessBatch - A boolean indicating whether the process is batched.
   */
  const trdLevelsTree = {
    name: i18n.t("recordDetail.LocationFile"),
    component: (
      <TrdLevelsTree
        isProcessBatch={true}
        currentPath={currentPath}
        setCurrentPath={setCurrentPath}
      />
    ),
  };

  /**
   * Object representing a record list expedient for a TRD (Table of Retention and Disposal).
   * @property {string} name - The name of the record list expedient, typically localized using i18n.
   * @property {ReactElement} component - The React component representing the record list expedient.
   * @property {boolean} isProcessBatch - Indicates whether the expedient is part of a batch process.
   */
  const recordListExpedient = {
    name: i18n.t("trd.formTittle14"),
    component: (
      <RecordListExpedient
        formIdInfo={formIdInfo}
        expedientComeFrom={enumsCreateExpedient.TASK}
        isProcess={false}
        isProcessBatch={true}
      />
    ),
  };

  /**
   * Represents a task configuration for uploading files associated with an expedient.
   * @constant {Object} taskUploadFilesByExpedient
   * @property {string} name - The name of the task, localized using the i18n library.
   * @property {React.Component} component - The React component associated with the task, allowing file uploads for expedient processing.
   * @param {boolean} isProcess - A flag indicating whether the file upload is part of a process.
   * @param {boolean} isProcessBatch - A flag indicating whether the file upload supports batch processing.
   */
  const taskUploadFilesByExpedient = {
    name: i18n.t("recordDetail.fileToExpedient"),
    component: (
      <TaskUploadFilesByExpedient isProcess={false} isProcessBatch={true} />
    ),
  };

  /**
   * Represents a task configuration object for uploading files.
   * @constant {Object} taskUploadFiles
   * @property {string} name - The name/title of the task, localized using internationalization (i18n).
   * @property {React.Component} component - The React component to be rendered for the task, which includes uploading files.
   * @param {boolean} isProcessBatch - A flag indicating whether the file upload process is part of a batch process.
   */
  const taskUploadFiles = {
    name: i18n.t("taskBatchProcess.title.uploadFile"),
    component: <TaskUploadFiles isProcess={false} isProcessBatch={true} />,
  };

  /**
   * Represents a step for filing a form in a batch process.
   * @constant {object} filingFormInStep
   * @property {string} name - The name of the step, localized using internationalization.
   * @property {React.Component} component - The React component associated with the step.
   * @param {string} formFilingName - The name of the form being filled.
   * @param {string} selectedFields - The selected fields for the process task.
   * @param {string} processTaskId - The ID of the process task.
   * @param {string} formUuid - The UUID of the form associated with the process.
   * @param {function} setShowFormsFillingProcess - Function to control the display of the forms filling process.
   * @param {boolean} showExternalFormFillingProcess - Flag indicating whether to show external form filling process.
   * @param {boolean} isFilingInBatch - Flag indicating whether the filing is in batch mode.
   */
  const filingFormInStep = {
    name: i18n.t("taskBatchProcess.fillingForm"),
    component: (
      <ProcessFillingForms
        formName={formFilingName}
        processTaskId={selectedFields[FIRST_INDEX_TASK]}
        formUuid={process.form_uuid}
        selectedFields={selectedFields}
        setShowFormsFillingProcess={setShowFormsFillingProcess}
        showExternalFormFillingProcess={false}
        isFilingInBatch={true}
        isLoadingComplyProcessBatch={isLoadingComplyProcessBatch}
      />
    ),
  };

  /**
   * Inserts specific steps into the wizard based on certain conditions.
   * @param {boolean} checkedOmitedUploadFile - Indicates whether omited upload file is checked.
   * @param {boolean} treeExist - Indicates whether a tree exists.
   * @param {string} pathConfiguration - The path configuration value.
   * @param {Array} steps - The array of wizard steps.
   */
  if (checkedOmitedUploadFile === true) {
    if (treeExist === false) {
      steps.splice(1, 0, taskUploadFiles);
    } else {
      if (pathConfiguration !== "") {
        steps.splice(1, 0, recordListExpedient);
        steps.splice(2, 0, taskUploadFilesByExpedient);
      } else {
        steps.splice(1, 0, trdLevelsTree);
        steps.splice(2, 0, recordListExpedient);
        steps.splice(3, 0, taskUploadFilesByExpedient);
      }
    }
  }

  /**
   * Inserts a filing form step into the steps array at a specific index based on certain conditions.
   * @param {boolean} checkedFilingForm - Indicates whether the filing form is checked.
   * @param {number} steps.length - The current length of the steps array.
   * @param {any} filingFormInStep - The filing form step to be inserted into the steps array.
   * @returns {void}
   */
  if (checkedFilingForm === true) {
    if (steps.length === 2) {
      steps.splice(1, 0, filingFormInStep);
    } else if (steps.length === 3) {
      steps.splice(2, 0, filingFormInStep);
    } else if (steps.length === 4) {
      steps.splice(3, 0, filingFormInStep);
    } else {
      steps.splice(4, 0, filingFormInStep);
    }
  }

  /**
   * Fetches tree existence information using a callback function.
   * @param {Function} getTreeExist - Function to fetch tree existence information.
   * @param {Function} setTreeExist - Function to set the tree existence state.
   * @returns {void}
   */
  const getTreeExistBySearch = useCallback(() => {
    getTreeExist().then((response) => {
      if (isNullOrUndefined(response.data) === false) {
        setTreeExist(response.data);
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes an effect when the 'formFilingName' state changes to determine whether to show the forms filling process.
   * @param {Function} callback - The function to execute as the effect. It checks the value of 'formFilingName' and sets 'showFormsFillingProcess' accordingly.
   * @param {Array} dependencies - An array of dependencies. The effect will only re-run if one of these dependencies has changed.
   * @param {string} formFilingName - The name of the form being filled.
   * @param {Function} isNullOrUndefined - A function that checks if a value is null or undefined.
   * @param {boolean} showFormsFillingProcess - A state variable indicating whether to show the forms filling process.
   */
  useEffect(() => {
    if (isNullOrUndefined(formFilingName) === false) {
      setShowFormsFillingProcess(true);
    }
  }, [formFilingName]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes an effect to fetch form data by its ID and update various state values based on the response.
   * @param {function} callback - The function containing the side effect to be executed.
   * @param {Array} dependencies - An array of dependencies that trigger the effect when changed.
   * @returns {void}
   */
  useEffect(() => {
    if (checkedOmitedUploadFile === true) {
      setIsLoadingForm(true);
      getFormsById(formIdInfo, 0).then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const path = response.data.path;
          setPathConfig(path);
          const backgroundId = getBackGroundByRoute(path);
          const parentId = getParentIdByRoute(path);
          setFoldersIdByPath({
            ...foldersIdByPath,
            backgroundId: backgroundId,
          });
          setExpedientParentByPath(parentId);
          setBackgroundByPath(backgroundId);
          setPathConfiguration(parentId);
        } else {
          showAlertServiceError();
        }
      })
        .finally(() => {
          setIsLoadingForm(false);
        });
    }
  }, [checkedOmitedUploadFile, formIdInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Effect that fetches tree existence data by invoking the getTreeExistBySearch function.
   * @returns {void}
   */
  useEffect(() => {
    getTreeExistBySearch();
  }, [getTreeExistBySearch]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that sets the state to indicate the processing of a task batch.
   * This effect runs once after the component mounts.
   * @param {boolean} setProcessTaskBatch - State setter function to set the flag indicating processing of a task batch.
   * @returns {void}
   */
  useEffect(() => {
    setProcessTaskBatch(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * A React effect to fetch process information by ID if a valid ID is present in the selectedFields array.
   * @param {Array} selectedFields - The array of selected fields.
   * @param {Function} getProcessById - Function to get process information by ID.
   * @param {Function} showAlertServiceError - Function to show an alert for service error.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(selectedFields[FIRST_INDEX_TASK]) === false) {
      getProcessById(selectedFields[FIRST_INDEX_TASK]);
    } else {
      showAlertServiceError();
    }
  }, [getProcessById]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {loaderElement(isLoadingForm)}
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <div className="ml-auto mt-3 mr-3">
              <ButtonBackListTasks />
            </div>
            <CardBody>
              <div className="forms-wizard-vertical">
                <MultiStepFullFill
                  treeExist={treeExist}
                  showNavigation={true}
                  steps={steps}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default WizardProcessBatch;
