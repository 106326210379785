import React, { Fragment, useState, useEffect, useCallback } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  Label,
  CardFooter,
  Spinner,
  Container,
} from "reactstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { DOMAIN } from "constants/securityConst";
import { showAlertServiceError } from "utils/alerts";
import { loaderElement } from "utils/loaderElement";
import useUser from "hooks/useUser";
import { isNullOrUndefined } from "utils/validations";
import { enumsCreateUserForm } from "utils/enums";
import {
  regexCreateUserEmail,
  regexCreateUserName,
  regexCreateUserPhone,
  regexDocumentType,
  regexDocumentTypeLetters
} from "utils/regexExpressions";
import swal from "sweetalert";
import i18n from "locales/i18n";

const CreateUserForm = () => {
  const { id } = useParams();
  const idUser = id;
  const DOMAIN_USER = window.localStorage.getItem(DOMAIN);
  const { getUsersById, createUser, updateUser } = useUser();
  const history = useHistory();
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [userCreate, setUserCreate] = useState({
    user_name: "",
    document_type: 0,
    document_reference: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    user_type: 0,
  });
  const [tempUser, setTempUser] = useState({
    user_name: "",
    document_type: 0,
    document_reference: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    user_type: 0,
  })
  const [keysFieldsConfig] = useState([
    'user_name',
    'document_type',
    'document_reference',
    'first_name',
    'last_name',
    'email',
    'phone',
    'user_type',
  ])
  const [uuidUser, setUserUuid] = useState("")

  let spinnerLoading = null;
  let titleButton = null;
  let headingTitle = null;
  let patternValue;

  /**
   * Sets the title for the button based on the presence of an idUser.
   * @param {string|null|undefined} idUser - The user ID.
   * @returns {string} The title for the button.
   */
  if (isNullOrUndefined(idUser) === false) {
    titleButton = i18n.t("createusers.createButton3");
    headingTitle = i18n.t("editUserHeading");
  } else {
    titleButton = i18n.t("createusers.createButton");
    headingTitle = i18n.t("createusers.tittle");
  }

  /**
   * Renders a spinner loading component if the isLoadingUser state is true.
   * @param {boolean} isLoadingUser - The isLoadingUser state indicating whether to display the spinner or not.
   * @returns {JSX.Element|null} Spinner loading component JSX if isLoadingUser state is true, otherwise null.
   */
  if (isLoadingUser === true) {
    spinnerLoading = (
      <Spinner size="sm" color="secondary" type="grow" />
    )
  }

/**
 * Sets the appropriate pattern value based on the user's document type.
 * If the user's document type is 1, `patternValue` is set to `regexDocumentType`.
 * Otherwise, it is set to `regexDocumentTypeLetters`.
 * @param {Object} userCreate - The user object containing the document type.
 * @param {number} userCreate.document_type - The document type of the user.
 * @param {RegExp} regexDocumentType - The regular expression for document type 1.
 * @param {RegExp} regexDocumentTypeLetters - The regular expression for other document types.
 * @returns {void}
 */
  if (userCreate.document_type === 1) {
    patternValue = regexDocumentType;
  } else {
    patternValue = regexDocumentTypeLetters;
  }

  /**
   * Handles the change event for the user document.
   * @param {Object} eventUserDocument - The event object from the user document input.
   * @param {Object} eventUserDocument.target - The target element of the event.
   * @param {string|number} eventUserDocument.target.value - The value of the target element, representing the document type.
   */
  const handleChangeUserDocument = (eventUserDocument) => {
    setUserCreate({
      ...userCreate,
      document_type: Number(eventUserDocument.target.value),
    })
  };

  /**
   * Handles the onChange event for a user input.
   * @param {Event} eventUsers - The event object triggered by the onChange event.
   * @return {Void}
   */
  const handleOnChangeUser = (eventUsers) => {
    setUserCreate({
      ...userCreate,
      [eventUsers.target.name]: eventUsers.target.value,
    });
  };

  /**
   * Handles the onChange event for a user input.
   * @param {Event} eventUsers - The event object triggered by the onChange event.
   * @return {Void}
   */
  const handleOnchangeUserType = (eventChangeUser) => {
    setUserCreate({
      ...userCreate,
      user_type: Number(eventChangeUser.target.value),
    })
  }

  /**
   * Handles the key press event for a user input.
   * @param {KeyboardEvent} eventUser - The key press event object.
   */
  const handleOnKeyPressUser = (eventUser) => {
    if (eventUser.key === "Enter") {
      eventUser.preventDefault();
    }
  };

  /**
   * Retrieves user data by user ID and updates the state.
   * @param {string} idUser - The ID of the user to retrieve.
   */
  const getUsersByIdFunc = useCallback(() => {
    setIsLoadingUser(true);
    getUsersById(idUser)
      .then((response) => {
        if (isNullOrUndefined(response.data.items) === false) {
          const user = response.data.items.find(
            (user) => user.user_name === idUser
          );
          const deleteDomain = user.user_name.replace(`@${DOMAIN_USER}`, "");
          user.user_name = deleteDomain;
          setUserCreate({
            user_name: user.user_name,
            document_type: user.document_type,
            document_reference: user.document_reference,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            user_type: user.user_type,
          });
          setTempUser({
            user_name: user.user_name,
            document_type: user.document_type,
            document_reference: user.document_reference,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            phone: user.phone,
            user_type: user.user_type,
          });
          setUserUuid(user.uuid)
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingUser(false);
      })
  }, [idUser]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Handles the form submission for creating or updating a user.
   * @param {Event} eventSubmit - The submit event object.
   * @param {Array} errorsSubmit - An array of form validation errors.
   * @return {void}
   */
  const handleOnSubmitUser = (eventSubmit, errorsSubmit) => {
    eventSubmit.preventDefault();
    if (errorsSubmit.length === 0) {
      if (idUser && userCreate) {
        let isModifiedUserName = false;
        const userUpdated = keysFieldsConfig.reduce((acomulatorField, fieldKey) => {
          if (userCreate[fieldKey] !== tempUser[fieldKey]) {
            acomulatorField[fieldKey] = userCreate[fieldKey];
          }
          return acomulatorField;
        }, {});

        if ("user_name" in userUpdated) {
          isModifiedUserName = true;
        } else {
          isModifiedUserName = false;
        }

        if (Object.keys(userUpdated).length === 0) {
          swal({
            title: i18n.t("modal.DoneError.header"),
            text: i18n.t("form.alertWarning"),
            icon: "info",
            button: i18n.t("modal.Done.footerButton"),
          })
          return
        }

        updateUser(userUpdated, uuidUser, DOMAIN_USER, isModifiedUserName)
          .then((response) => {
            setIsLoadingUser(true);
            if (response.status === 202) {
              const showAlertUserUpdated = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("modal.editUserDone.body2"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                });
              };
              showAlertUserUpdated();
              history.push("/user/list");
            } else {
              showAlertServiceError();
            }
          })
          .finally(() => {
            setIsLoadingUser(false);
          });
      } else {
        createUser(userCreate, DOMAIN_USER)
          .then((response) => {
            if (response.status === 201) {
              const showAlertUserCreated = () => {
                swal({
                  title: i18n.t("modal.DoneError.header"),
                  text: i18n.t("modal.CreateUserDone.body2"),
                  icon: "success",
                  button: i18n.t("modal.Done.footerButton"),
                });
              };
              showAlertUserCreated();
              history.push("/user/list");
            } else {
              showAlertServiceError();
            }
          })
          .catch(() => {
            setUserCreate({
              ...userCreate,
              user_name: userCreate.user_name.replace(`@${DOMAIN_USER}`, "")
            });
          })
          .finally(() => {
            setIsLoadingUser(false);
          });
      }
    } else {
      setIsLoadingUser(false);
      return;
    }
  };

  /**
   * Executes the getUsersByIdFunc function when the idUser dependency changes.
   * If idUser is truthy, it invokes the getUsersByIdFunc function.
   * @param {string} idUser - The ID of the user.
   */
  useEffect(() => {
    if (isNullOrUndefined(idUser) === false) {
      getUsersByIdFunc();
    }
  }, [idUser]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes the effect to delete the domain from the user name in the userCreate state when isLoadingUser dependency changes.
   * It replaces the domain in the user name with an empty string.
   * @param {boolean} isLoadingUser - A flag indicating if the user data is currently being loaded.
   */
  useEffect(() => {
    const deleteDomain = userCreate.user_name.replace(`@${DOMAIN_USER}`, "");
    setUserCreate({ ...userCreate, user_name: deleteDomain });
  }, [isLoadingUser]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      {loaderElement(isLoadingUser)}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>
                {headingTitle}
              </CardTitle>
              <AvForm onSubmit={handleOnSubmitUser} autoComplete="off">
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <AvGroup>
                          <Label for="userName" className="is-required">
                            {i18n.t("createusers.label1")}
                          </Label>
                          <AvField
                            id="user_name"
                            name="user_name"
                            type="text"
                            onChange={handleOnChangeUser}
                            onKeyPress={handleOnKeyPressUser}
                            placeholder={i18n.t("createusers.placeholder1")}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: `${i18n.t("fieldRequired")}`,
                              },
                              minLength: {
                                value: enumsCreateUserForm.MIN_LENGTH_USER_NAME,
                                errorMessage: `${i18n.t(
                                  "fieldValidateLengthBetween"
                                )} 5 ${i18n.t("and")} 100 ${i18n.t(
                                  "characters"
                                )}`,
                              },
                              maxLength: {
                                value: enumsCreateUserForm.MAX_LENGTH_USER_NAME,
                                errorMessage: `${i18n.t(
                                  "fieldValidateLengthBetween"
                                )} 5 ${i18n.t("and")} 100 ${i18n.t(
                                  "characters"
                                )}`,
                              },

                              pattern: {
                                value: regexCreateUserName,
                                errorMessage: `${i18n.t("userValidation")}`,
                              }
                            }}

                            autoComplete="off"
                            value={userCreate.user_name}
                          />
                        </AvGroup>
                      </Col>
                      <Col md="6">
                        <Label for="domain">{i18n.t("createUserDomain")}</Label>
                        <AvField
                          id="domain"
                          name="domain"
                          type="text"
                          disabled
                          placeholder={i18n.t("createusers.placeholder1")}
                          autoComplete="off"
                          value={`@${DOMAIN_USER}`}
                        />
                      </Col>
                    </Row>

                    <AvGroup>
                      <Label for="email" className="is-required">
                        {i18n.t("createusers.label5")}
                      </Label>
                      <AvField
                        id="email"
                        name="email"
                        type="text"
                        onChange={handleOnChangeUser}
                        onKeyPress={handleOnKeyPressUser}
                        validate={{
                          pattern: {
                            value: regexCreateUserEmail,
                            errorMessage: `${i18n.t("emailValidation")}`,
                          },
                          required: {
                            value: true,
                            errorMessage: `${i18n.t("fieldRequired")}`,
                          },
                          minLength: {
                            value: enumsCreateUserForm.MIN_LENGTH_EMAIL,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                          },
                          maxLength: {
                            value: enumsCreateUserForm.MAX_LENGTH_EMAIL,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 100 ${i18n.t("characters")}`,
                          },
                        }}
                        placeholder={i18n.t("createusers.placeholder3")}
                        autoComplete="off"
                        value={userCreate.email}
                      />
                    </AvGroup>
                    <AvGroup>
                      <Label for="document_type" className="is-required">
                        {i18n.t("createusers.label2")}
                      </Label>
                      <AvField
                        id="document_type"
                        name="document_type"
                        type="select"
                        onChange={handleChangeUserDocument}
                        required
                        errorMessage={i18n.t("createusers.Feedback6")}
                        value={userCreate.document_type}
                      >
                        <option value="">
                          {i18n.t("createUser.selectDocument")}
                        </option>
                        <option value={1}>
                          {i18n.t("createusers.documentType.option1")}
                        </option>
                        <option value={2}>
                          {i18n.t("createusers.documentType.option2")}
                        </option>
                      </AvField>
                    </AvGroup>

                    <AvGroup>
                      <Label for="documentNumber" className="is-required">
                        {i18n.t("createusers.label3")}
                      </Label>
                      <AvField
                        id="document_reference"
                        name="document_reference"
                        type="text"
                        onChange={handleOnChangeUser}
                        onKeyPress={handleOnKeyPressUser}
                        validate={{
                          pattern: {
                            value: patternValue,
                            errorMessage: `${i18n.t("createUserDocument")}`,
                          },
                          required: {
                            value: true,
                            errorMessage: `${i18n.t("fieldRequired")}`,
                          },
                          minLength: {
                            value: enumsCreateUserForm.MIN_LENGTH_DOCUMENT,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 20 ${i18n.t("characters")}`,
                          },
                          maxLength: {
                            value: enumsCreateUserForm.MAX_LENGTH_DOCUMENT,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 20 ${i18n.t("characters")}`,
                          },
                        }}
                        placeholder={i18n.t("createusers.placeholder2")}
                        autoComplete="off"
                        value={userCreate.document_reference}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label for="firstame" className="is-required">
                        {i18n.t("createusers.label4")}
                      </Label>
                      <AvField
                        id="first_name"
                        name="first_name"
                        type="text"
                        onChange={handleOnChangeUser}
                        onKeyPress={handleOnKeyPressUser}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: `${i18n.t("fieldRequired")}`,
                          },
                          minLength: {
                            value: enumsCreateUserForm.MIN_LENGTH_FIRST_NAME,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                          },
                          maxLength: {
                            value: enumsCreateUserForm.MAX_LENGTH_FIRST_NAME,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                          },
                        }}
                        autoComplete="off"
                        value={userCreate.first_name}
                      />
                    </AvGroup>

                    <AvGroup>
                      <Label for="lastname" className="is-required">
                        {i18n.t("createusers.label10")}
                      </Label>
                      <AvField
                        id="last_name"
                        name="last_name"
                        type="text"
                        onChange={handleOnChangeUser}
                        onKeyPress={handleOnKeyPressUser}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: `${i18n.t("fieldRequired")}`,
                          },
                          minLength: {
                            value: enumsCreateUserForm.MIN_LENGTH_LAST_NAME,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                          },
                          maxLength: {
                            value: enumsCreateUserForm.MAX_LENGTH_LAST_NAME,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 2 ${i18n.t("and")} 50 ${i18n.t("characters")}`,
                          },
                        }}
                        autoComplete="off"
                        value={userCreate.last_name}
                      />
                    </AvGroup>

                    <AvGroup>
                      <Label for="phone">{i18n.t("createusers.label6")}</Label>
                      <AvField
                        id="phone"
                        name="phone"
                        type="text"
                        onChange={handleOnChangeUser}
                        onKeyPress={handleOnKeyPressUser}
                        validate={{
                          pattern: {
                            value: regexCreateUserPhone,
                            errorMessage:
                              "El télefono ingresado no es correcto",
                          },
                          minLength: {
                            value: enumsCreateUserForm.MIN_LENGTH_PHONE,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 20 ${i18n.t("characters")}`,
                          },
                          maxLength: {
                            value: enumsCreateUserForm.MAX_LENGTH_PHONE,
                            errorMessage: `${i18n.t(
                              "fieldValidateLengthBetween"
                            )} 5 ${i18n.t("and")} 20 ${i18n.t("characters")}`,
                          },
                        }}
                        autoComplete="off"
                        value={userCreate.phone}
                      />
                    </AvGroup>

                    <AvGroup>
                      <Label for="user_type" className="is-required">
                        {i18n.t("createusers.label7")}
                      </Label>
                      <AvField
                        id="user_type"
                        name="user_type"
                        type="select"
                        onChange={handleOnchangeUserType}
                        required
                        errorMessage={i18n.t("createusers.Feedback8")}
                        value={userCreate.user_type}
                      >
                        <option value="">
                          {i18n.t("createusers.Feedback7")}
                        </option>
                        <option value={1}>
                          {i18n.t("createusers.userType.option1")}
                        </option>
                        <option value={2}>
                          {i18n.t("createusers.userType.option2")}
                        </option>
                      </AvField>
                    </AvGroup>
                  </Col>
                </Row>
                <CardFooter className="d-block text-right">
                  <Link to="/user/list">
                    <Button
                      size="lg"
                      disabled={isLoadingUser}
                      className="col-mt-3 mr-3"
                      color="gray"
                    >
                      {spinnerLoading}
                      {i18n.t("createusers.createButton2")}
                    </Button>
                  </Link>
                  <Button
                    type="submit"
                    size="lg"
                    disabled={isLoadingUser}
                    className="col-mt-3"
                    color="cyan"
                  >
                    {spinnerLoading}
                    {titleButton}
                  </Button>
                </CardFooter>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default CreateUserForm;
