import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardFooter } from "reactstrap";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import Loader from "react-loaders";
import UserListRolesTable from "components/organism/UserListRolesTable";
import ButtonCreateUserRoles from "components/atoms/ButtonCreateUserRoles";
import SearchBox from "components/atoms/SearchBox";
import useRoles from "hooks/useRoles";
import swal from "sweetalert";
import i18n from "locales/i18n";

const UserListRoles = () => {
  const [listUsers, setListUsers] = useState([]);
  const [isLoadingDeleteRol, setIsLoadingDeleteRol] = useState(false);
  const [isLoadingRolesList, setIsLoadingRoles] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchListUser, setSearchListUser] = useState("");
  const [searchAlertCount, setSearchAlertCount] = useState(0);
  const { getAllUserAndRoles } = useRoles();
  let loaderDeleteRol = null;
  
  /**
   * Conditional loader element generator for displaying a loading indicator when 'isLoadingDeleteField' is true.
   * This function generates a loader element with a loading spinner when 'isLoadingDeleteField' is true, and null when it's false.
   * The loader element is typically used to indicate that some deletion operation is in progress.
   * @param {boolean} isLoadingDeleteRol - A boolean flag indicating whether a delete operation is in progress.
   * @returns {JSX.Element | null} - A loader element or null based on the 'isLoadingDeleteField' value.
   */
  if (isLoadingDeleteRol === true) {
    loaderDeleteRol = (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="primary" type="ball-pulse-rise" />
        </div>
      </div>
    );
  } else {
    loaderDeleteRol = null;
  }

  /**
   * Fetches roles based on a searchListUser query and updates the component's state accordingly.
   * This function retrieves a list of roles matching the searchListUser query, updates the user interface
   * with the results, and handles error scenarios gracefully.
   * @param {void} () - No parameters are passed directly to the function, but it relies on the following external variables:
   * @param {object} pagination - An object containing pagination information.
   * @param {number} pagination.page - The current page number.
   * @param {number} pagination.per_page - The number of items to display per page.
   * @param {string} searchListUser - The searchListUser query used to filter roles.
   * @returns {void}
   */
  const getRolesBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingRoles(true);
    getAllUserAndRoles(page, per_page, searchListUser)
      .then((response) => {
        const rolesList = response.data.items;
        const pagesRoles = response.data.pages;
        if (
          isNullOrUndefined(rolesList) === false &&
          isNullOrUndefined(pagesRoles) === false
        ) {
          if (rolesList.length === 0) {
            if (searchAlertCount === 0 && searchListUser.length === 0) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.UsersAll.Alert"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
              setSearchAlertCount(2);
            }
            if (searchListUser.length > 0) {
              swal({
                title: i18n.t("modal.DoneError.header"),
                text: i18n.t("createRoles.Alert"),
                icon: "info",
                button: i18n.t("modal.Done.footerButton"),
              });
            }
          }
          setListUsers(rolesList);
          setTotalPages(pagesRoles);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingRoles(false);
      });
  }, [pagination, searchListUser]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when the `getRolesBySearch` dependency changes.
   * This effect is responsible for calling the `getRolesBySearch` function.
   * You should ensure that `getRolesBySearch` is defined elsewhere in your code.
   * @param {Function} getRolesBySearch - A function responsible for searching and retrieving roles.
   * @returns {void}
   */
  useEffect(() => {
    getRolesBySearch();
  }, [getRolesBySearch]);

  return (
    <Fragment>
      {loaderDeleteRol}
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto">
            <SearchBox
              setSearch={setSearchListUser}
              pagination={pagination}
              setPagination={setPagination}
            />
            <ButtonCreateUserRoles />
          </CardFooter>

          <UserListRolesTable
            listUsers={listUsers}
            isLoadingRolesList={isLoadingRolesList}
            totalPages={totalPages}
            pagination={pagination}
            setPagination={setPagination}
            setIsLoadingDeleteRol={setIsLoadingDeleteRol}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default UserListRoles;
