import React, { Fragment, useState, useEffect } from "react";
import { AvGroup } from "availity-reactstrap-validation";
import { Col, Label, Alert, Card, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { useFieldsRecordContext } from "contextAPI/FieldsRecordContext";
import { useListContext } from "contextAPI/ListsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { customStylesSelect } from "components/organism/Notifications/Notifications.styles";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import makeAnimated from "react-select/lib/animated";
import Loader from "react-loaders";
import cx from "classnames";
import useForm from "hooks/useForm";
import Select from "react-select";
import i18n from "locales/i18n";

const Lookup = () => {
  const { id } = useParams();
  const { fieldForm, setFieldForm, isLoading } = useFieldsRecordContext();
  const { setCanNext } = useListContext();
  const { getFormsForLookup, getFieldsFormsForLookup } = useForm();
  const [isLoadingForms, setIsLoadingForms] = useState(false);
  const [isLoadingFields, setIsLoadingFields] = useState(false);
  const [relatedOption, setRelatedOption] = useState();
  const [messageInfoForms, setMessageInfoForms] = useState(false);
  const [messageInfoFields, setMessageInfoFields] = useState(false);
  const [formsLists, setFormsList] = useState([]);
  const [fieldsFormList, setFieldsFormList] = useState([]);
  const [showFormsList, setShowFormsList] = useState(false);
  const [showFieldsLists, setShowFieldsLists] = useState(false);
  const { selectedOption } = useState([]);
  const [countFieldsAdded, setCountFieldsAdded] = useState({ uuid: [] });
  const optionsLinkTo = [{ value: 1, label: i18n.t("form.field63") }];
  const [noOptionsMessageFields, setNoOptionsMessageFields] = useState("");
  const [optionsFieldsTemp, setOptionsFieldsTemp] = useState();
  const [isErrorFieldsValid, setIsErrorFieldsValid] = useState(false);
  const maxOptions = 2;
  let infoCharacteristicsForm = null;
  let infoCharacteristicsFields = null;
  let noOptionMessageForms = null;
  let showListForms = null;
  let showListFields = null;
  let classNameError = null;
  let feedBackSelectFields = null;
  let loaderForms = null;
  let loaderFields = null;
  let loaderElement = null;

  /**
   * Conditional rendering of a loader component based on the provided loading state.
   * @param {boolean} isLoadingForms - Indicates whether forms are currently being loaded.
   * @param {boolean} isLoading - Indicates whether data is currently being loaded.
   * @returns {JSX.Element|null} - Returns a loader component JSX element or null based on the provided loading state.
   */
  if (isLoadingForms === true) {
    loaderForms = (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="primary" type="ball-pulse-rise" />
      </div>
    );
  } else if (isLoadingFields === true) {
    loaderFields = (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Loader color="primary" type="ball-pulse-rise" />
      </div>
    );
  }

  if (isLoading === true) {
    loaderElement = (
      <div className="loader-overlay">
        <div className="loader-wrapper d-flex justify-content-center align-items-center">
          <Loader color="primary" type="ball-pulse-rise" />
        </div>
      </div>
    );
  }

  /**
   * Handles the change event for a select input related to linking options.
   * This function updates the state and performs actions based on the selected link option.
   * @param {object} selectLink - The select input element containing the selected link option.
   * @param {number} selectLink.value - The value of the selected link option.
   * @returns {void}
   */
  const handleOnChangeLinkTo = (selectLink) => {
    const targetLinkSelected = selectLink.value;
    setFieldForm({ ...fieldForm, selected_option: Number(targetLinkSelected) });
    setRelatedOption(targetLinkSelected);
    if (targetLinkSelected === 1) {
      setShowFormsList(true);
      setIsLoadingForms(true);
      setMessageInfoForms(true);
      getFormsForLookup(id)
        .then((response) => {
          let activeForms = response.data;
          if (isNullOrUndefined(activeForms) === false) {
            if (activeForms.length > 0) {
              let options = activeForms.map((Form) => {
                return { value: `${Form.uuid}`, label: `${Form.name}` };
              });
              setFormsList(options);
            } else {
              setFormsList([]);
              setCanNext(true);
            }
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingForms(false);
        });
    } else {
      setShowFormsList(false);
    }
  };

  /**
   * Conditionally renders an informational message as an Alert component if 'messageInfoForms' is true.
   * @param {boolean} messageInfoForms - A boolean value indicating whether to display the informational message.
   * @param {string} i18n.t - A translation function used to retrieve localized text.
   * @returns {React.Element|null} - An Alert component containing informational content or null if 'messageInfoForms' is false.
   */
  if (messageInfoForms === true) {
    infoCharacteristicsForm = (
      <Alert className="mbg-3" color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("form.field64")}
        <li>{i18n.t("form.field65")}</li>
        <li>{i18n.t("form.field66")}</li>
        <li>{i18n.t("form.field67")}</li>
      </Alert>
    );
  } else {
    infoCharacteristicsForm = null;
  }

  /**
   * Conditional function that returns a message component when there are no options available in the 'formsLists' array.
   * @returns {JSX.Element | null} - A JSX element containing the message when 'formsLists' is empty, or null if it's not.
   */
  if (formsLists.length === 0) {
    noOptionMessageForms = () => {
      return <span>{i18n.t("form.field68")}</span>;
    };
  } else {
    noOptionMessageForms = () => i18n.t("createRoles.configNoForms");
  }


  /**
   * Handles the change event when a form UUID is selected. This function updates the state
   * and fetches associated fields based on the selected form UUID.
   * @param {string} formUuid - The UUID of the selected form.
   * @returns {void}
   */
  const handleOnChangeForms = (formUuid) => {
    let formUuidSelected = formUuid.value;
    if (
      formUuidSelected !== undefined &&
      formUuidSelected !== null &&
      formUuidSelected !== ""
    ) {
      setFieldForm({
        ...fieldForm,
        form_lookup_uuid: formUuidSelected,
      });
      setMessageInfoFields(true);
      setIsLoadingFields(true);
      getFieldsFormsForLookup(formUuidSelected)
        .then((response) => {
          let activeFields = response.data;
          if (isNullOrUndefined(activeFields) === false) {
            if (activeFields.length > 0) {
              let optionsFields = activeFields.map((field) => {
                const labelOptions = {
                  true: {
                    true: i18n.t("form.field74"),
                    false: i18n.t("form.field73"),
                  },
                  false: {
                    true: i18n.t("form.field72"),
                    false: "",
                  },
                };
                const label = `${field.name} ${labelOptions[field.required][field.unique]
                  }`;
                return {
                  label,
                  value: `${field.uuid}`,
                  required: field.required,
                  unique: field.unique,
                };
              });

              setFieldsFormList(optionsFields);
              setShowFieldsLists(true);
            } else {
              setShowFieldsLists(false);
            }
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingFields(false);
        });
    } else {
      setShowFieldsLists(false);
    }
  };

  /**
   * Generates a message with information characteristics fields if 'messageInfoFields' is true, otherwise, sets 'infoCharacteristicsFields' to null.
   * @param {boolean} messageInfoFields - Indicates whether to display information about characteristics fields.
   * @param {string} i18n.t - A function for translating text using internationalization.
   * @returns {React.ReactNode | null} - Returns a React node containing the information message or null.
   */
  if (messageInfoFields === true) {
    infoCharacteristicsFields = (
      <Alert color="info">
        <span className="pr-2">
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        {i18n.t("form.field69")}
        <li>{i18n.t("form.field70")}</li>
        <li>{i18n.t("form.field71")}</li>
      </Alert>
    );
  } else {
    infoCharacteristicsFields = null;
  }


  /**
   * Funtion for handles the change of selected options in a field input.
   * @param {Array} selectedOption - The selected options in the field input.
   * @returns {null} - This function does not return a value.
   */
  const handleOnChangeFields = (selectedOption) => {
    if (selectedOption.length === 0) {
      setIsErrorFieldsValid(true);
    } else {
      const selectedValues = selectedOption.map((option) => option.value);
      setCountFieldsAdded({
        ...countFieldsAdded,
        uuid: selectedValues,
      });
      setFieldForm({
        ...fieldForm,
        form_lookup_fields: {
          0: selectedValues[0] || null,
          1: selectedValues[1] || null,
        },
      });
    }
    return null;
  };

  /**
   * Updates the class name and feedback message for a select field based on the validity of its error fields.
   * @param {boolean} isErrorFieldsValid - A boolean indicating whether the error fields are valid.
   * @param {object} customStylesSelect - Custom styles to be applied to the select field.
   * @param {string} i18n.t("form.field79") - The translated message to be displayed as feedback when error fields are invalid.
   * @returns {object} An object containing the updated class name and feedback message for the select field.
   */
  if (isErrorFieldsValid === true) {
    classNameError = customStylesSelect;
    feedBackSelectFields = (
      <span className="text-danger rbc-event-label">
        {i18n.t("form.field79")}
      </span>
    );
  } else {
    feedBackSelectFields = null;
    classNameError = {};
  }

  /**
   * Conditional rendering of a form selection component based on the 'showFormsList' flag.
   * @param {boolean} showFormsList - A boolean flag indicating whether to show the form selection component.
   * @param {React.Component} infoCharacteristicsForm - The React component displaying form information.
   * @param {string} i18n.t - A function for translating text to the desired language.
   * @param {string} formsLists - An array of options for the form selection.
   * @param {string} noOptionMessageForms - The message to display when there are no form options available.
   * @param {function} handleOnChangeForms - The callback function to handle changes in the selected form.
   * @returns {JSX.Element|null} - Returns the JSX element for the form selection component if 'showFormsList' is true, or null otherwise.
   */
  if (showFormsList === true) {
    showListForms = (
      <AvGroup>
        <div className="heper-form-lookup">{infoCharacteristicsForm}</div>
        <Row>
          <Label for="selectForm" className="is-required" sm={2}>
            {i18n.t("form.field43")}
          </Label>
          <Col md={10}>
            <Select
              id="selectForm"
              name="selectForm"
              maxMenuHeight={80}
              menuPlacement="auto"
              closeMenuOnSelect={true}
              isSearchable={false}
              hideSelectedOptions={true}
              components={makeAnimated()}
              placeholder={i18n.t("form.field75")}
              options={formsLists}
              noOptionsMessage={noOptionMessageForms}
              onChange={handleOnChangeForms}
            />
          </Col>
        </Row>
      </AvGroup>
    );
  } else {
    showListForms = null;
  }

  /**
   * Conditional rendering of a list of fields if 'showFieldsLists' is true.
   * @param {boolean} showFieldsLists - A flag indicating whether to display the list of fields.
   * @param {React.Component} infoCharacteristicsFields - The component containing information about characteristics fields.
   * @param {string} isErrorFieldsValid - A string indicating if there's an error in the fields validation.
   * @param {string} classNameError - The CSS class name for styling error conditions.
   * @param {string} i18n.t - A function for translating text.
   * @param {React.Component} Label - The label component for form fields.
   * @param {React.Component} Row - The row component for form layout.
   * @param {number} sm - The column size for small screens.
   * @param {React.Component} Col - The column component for form layout.
   * @param {React.Component} Select - The select component for field selection.
   * @param {function} handleOnChangeFields - The event handler for field selection changes.
   * @param {object[]} optionsFieldsTemp - An array of options for the field selection.
   * @param {object[]} selectedOption - The currently selected options for fields.
   * @param {function} noOptionsMessageFields - A function to generate a message when no options are available.
   * @param {React.Component} feedBackSelectFields - The component for feedback related to field selection.
   * @returns {React.Component|null} - A component displaying the list of fields or null if 'showFieldsLists' is false.
   */
  if (showFieldsLists === true) {
    showListFields = (
      <AvGroup>
        <div className="heper-form-lookup">{infoCharacteristicsFields}</div>
        <Row>
          <Label
            for="selectFields"
            className={cx("is-required", isErrorFieldsValid === false, {
              "labels-error is-required": isErrorFieldsValid === true,
            })}
            sm={2}
          >
            {i18n.t("form.fieldHeading")}
          </Label>
          <Col md={10}>
            <Select
              id="selectFields"
              name="selectFields"
              style={classNameError}
              maxMenuHeight={80}
              menuPlacement="auto"
              closeMenuOnSelect={true}
              components={makeAnimated()}
              isMulti={true}
              isSearchable={false}
              isClearable={false}
              hideSelectedOptions={true}
              placeholder={i18n.t("form.field76")}
              options={optionsFieldsTemp}
              onChange={handleOnChangeFields}
              noOptionsMessage={() => noOptionsMessageFields}
              value={selectedOption}
            />
            {feedBackSelectFields}
          </Col>
        </Row>
      </AvGroup>
    );
  } else {
    showListFields = null;
  }

  /**
   * useEffect hook that updates temporary options fields and a message based on field count.
   * @param {object} countFieldsAdded - An object representing the count of added fields with a 'uuid' property.
   * @param {number} maxOptions - The maximum allowed number of options fields.
   * @param {array} fieldsFormList - An array of fields for a form.
   * @param {function} setOptionsFieldsTemp - State setter function for temporary options fields.
   * @param {function} setNoOptionsMessageFields - State setter function for the message when no options are available.
   * @returns {void}
   */
  useEffect(() => {
    if (countFieldsAdded.uuid.length === maxOptions) {
      setOptionsFieldsTemp([]);
      setNoOptionsMessageFields(i18n.t("form.field77"));
    } else {
      setOptionsFieldsTemp(fieldsFormList);
      setNoOptionsMessageFields(null);
    }
  }, [countFieldsAdded, fieldsFormList]);

  /**
   * A useEffect hook that checks conditions related to form field configurations
   * and updates the 'canNext' and 'isErrorFieldsValid' state variables accordingly.
   * @param {function} setCanNext - A state setter function to control the 'canNext' state.
   * @param {any} relatedOption - The related option to be checked.
   * @param {string} fieldForm.form_lookup_uuid - The UUID of the form lookup.
   * @param {object} fieldForm.form_lookup_fields - The fields configuration for form lookup.
   * @param {string[]} countFieldsAdded.uuid - An array of UUIDs representing added fields.
   * @param {object[]} fieldsFormList - A list of form fields.
   * @returns {void}
   */
  useEffect(() => {
    const isRelatedOptionDefined = relatedOption !== undefined;
    const isFormLookupUuidDefined = fieldForm.form_lookup_uuid !== undefined;
    const isFormLookupFieldsDefined =
      fieldForm.form_lookup_fields !== undefined;
    const isField0Defined =
      fieldForm.form_lookup_fields &&
      fieldForm.form_lookup_fields["0"] !== undefined;
    const isField1Defined =
      fieldForm.form_lookup_fields &&
      fieldForm.form_lookup_fields["1"] !== undefined;
    const isCountFieldsValid = countFieldsAdded.uuid.length === 2;

    if (
      isRelatedOptionDefined &&
      isFormLookupUuidDefined &&
      isFormLookupFieldsDefined &&
      isField0Defined &&
      isField1Defined &&
      isCountFieldsValid
    ) {
      const hasUnique = countFieldsAdded.uuid.some((id) =>
        fieldsFormList.find((item) => item.value === id && item.unique === true)
      );
      const hasRequired = countFieldsAdded.uuid.some((id) =>
        fieldsFormList.find(
          (item) => item.value === id && item.required === true
        )
      );

      if (hasUnique && hasRequired) {
        setCanNext(false);
        setIsErrorFieldsValid(false);
      } else {
        setIsErrorFieldsValid(true);
        setCanNext(true);
      }
    } else {
      setCanNext(true);
    }
  }, [
    setCanNext,
    relatedOption,
    fieldForm.form_lookup_uuid,
    fieldForm.form_lookup_fields,
    countFieldsAdded.uuid,
    fieldsFormList,
  ]);

  return (
    <Fragment>
      {loaderElement}
      <Card className="forms-fields">
        <AvGroup row>
          <Label for="link" className="is-required" sm={2}>
            {i18n.t("form.field32")}
          </Label>
          <Col md={10}>
            <Select
              id="link"
              name="link"
              className="margin-bottom-select"
              options={optionsLinkTo}
              value={selectedOption}
              onChange={handleOnChangeLinkTo}
              required
              isClearable={false}
              isSearchable={false}
              placeholder={i18n.t("form.field33")}
            />
          </Col>
        </AvGroup>
        {loaderForms}
        {showListForms}
        {loaderFields}
        {showListFields}
      </Card>
    </Fragment>
  );
};

export default Lookup;
