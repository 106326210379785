import React, { useEffect, useCallback, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useRecordDetailContext } from "contextAPI/RecordDetailContext";
import { Button, Card, CardBody, Col, Alert, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { TYPE, CANCEL_RECORD, EDIT_REGISTER } from "constants/securityConst";
import { getDateFormat2 } from "utils/getDateFormat2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faPencilAlt,
  faPlusSquare,
  faSearch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useTrdContext } from "contextAPI/TrdContext";
import { capitalizeText } from "utils/formatText";
import { isNullOrUndefined } from "utils/validations";
import { loaderElement } from "utils/loaderElement";
import { showAlertServiceError } from "utils/alerts";
import { enumsActions, enumsFormStatus, enumsTypeUser, recordStatus } from "utils/enums";
import useForm from "hooks/useForm";
import AdminTemplate from "components/templates/AdminTemplate";
import Tabs from "react-responsive-tabs";
import RecordDetailView from "components/organism/RecordDetailView";
import UpLoadFilesTRD from "components/organism/UpLoadFilesTRD";
import RecordUploadFiles from "components/organism/RecordUploadFiles";
import RecordFileListTables from "components/organism/RecordFileListTables";
import Notifications from "components/organism/Notifications";
import WorkflowStart from "components/organism/WorkflowStart";
import useTrd from "hooks/useTrd";
import useRecord from "hooks/useRecord";
import ButtonCancelFiled from "components/atoms/ButtonCancelFiled";
import i18n from "locales/i18n";
import RecordSticker from "components/organism/RecordSticker";

const RecordDetail = (props) => {
  const { isRecordCreate } = props;
  const { id, recordId } = useParams();
  const USERTYPE = window.localStorage.getItem(TYPE);
  const CANCEL_RECORDS = window.localStorage.getItem(CANCEL_RECORD);
  const EDIT_RECORDS = window.localStorage.getItem(EDIT_REGISTER);
  const cancelButton = CANCEL_RECORDS.includes(id);
  const editRecord = EDIT_RECORDS.includes(id);
  const [status, setStatus] = useState(null);
  const [isLoadingDetailRecord, setIsLoadingDetailRecord] = useState(true);
  const {
    setRecordInfo,
    recordInfo,
    setFieldsDinamic,
    setRecordFields,
    formDetail,
    setFormDetail,
    recordUuid,
    recordFields,
    setRecordUuid,
    pathConfiguration,
    setPathConfiguration,
    setBackgroundByPath,
    getBackGroundByRoute,
    getParentIdByRoute,
    isLoading,
    setPathConfig,
  } = useRecordDetailContext();
  const {
    setExpedientParentByPath,
    foldersIdByPath,
    setFoldersIdByPath,
    getHasExpedientStructure,
    getTreeExpedientByDomainCallback,
    hasExpedientFixedPath,
    sethasExpedientFixedPath,
    expedientFixedUuid,
    isLoadingExpedietnPath,
    expedientStatus,
    structureTrdExpedient,
    setExpedientSetPath,
  } = useTrdContext();
  const { getRecordsById } = useRecord();
  const [data, setData] = useState({
    responsible: "",
    comment_reason: "",
    status: "",
  });
  const [recordInfos, setRecordInfos] = useState({
    record: "",
    uuid: "",
    status: "",
  });
  const { getTreeExist } = useTrd();
  const { getFormsById } = useForm();
  const [treeExist, setTreeExist] = useState(false);
  let uploadFilesContent = null;
  let renderHeaderRecordDetail = null;
  let renderCancelRecordButton = null;
  let hrefRedirectRecordCreate = null;
  let hrefRedirectRecordEdit = null;
  let disabledButtonEdit = null;
  let disabledButtonCreateRecord = null;
  let editRecordButton = null;

  /**
   * Determines if the button to create a record should be disabled based on the form status.
   * @param {string} status - The current status of the form.
   * @param {Object} enumsFormStatus - An object containing possible form statuses.
   * @param {string} enumsFormStatus.FORMS_STATUS_INACTIVE - The inactive status for a form.
   * @returns {boolean} True if the button should be disabled, false otherwise.
   */
  if (status === enumsFormStatus.FORMS_STATUS_INACTIVE) {
    disabledButtonCreateRecord = true;
  } else {
    disabledButtonCreateRecord = false;
  }

  /**
   * Determines the redirect URL for editing a record based on the status of the record and the form.
   * @param {Object} recordInfos - An object containing information about the record.
   * @param {string} recordInfos.status - The status of the record.
   * @param {Object} recordStatus - An object containing possible record statuses.
   * @param {string} recordStatus.ACTIVE - The active status for a record.
   * @param {string} status - The current status of the form.
   * @param {Object} enumsFormStatus - An object containing possible form statuses.
   * @param {string} enumsFormStatus.FORMS_STATUS_ACTIVE - The active status for a form.
   * @param {string} id - The ID of the record to be edited.
   * @param {string} recordId - The specific record ID within the form.
   * @returns {string} The URL to redirect to for editing the record.
   */
  if (
    recordInfos.status !== recordStatus.ACTIVE ||
    status !== enumsFormStatus.FORMS_STATUS_ACTIVE
  ) {
    hrefRedirectRecordEdit = "#";
    disabledButtonEdit = true;
  } else {
    hrefRedirectRecordEdit = `/record/edit/${id}/${recordId}`;
    disabledButtonEdit = false;
  }

  /**
   * Sets the URL for redirecting based on the form status.
   * @param {string} status - The current status of the form.
   * @param {Object} enumsFormStatus - An object containing form status constants.
   * @param {string} enumsFormStatus.FORMS_STATUS_INACTIVE - The constant representing the inactive status of the form.
   * @param {string} id - The unique identifier for the record to be created.
   * @returns {string} The URL for redirecting to create a new record or a placeholder URL if the form is inactive.
   */
  if (status === enumsFormStatus.FORMS_STATUS_INACTIVE) {
    hrefRedirectRecordCreate = "#";
  } else {
    hrefRedirectRecordCreate = `/record/create/${id}`;
  }

  /**
   * Conditionally renders the Cancel Record button based on user type and data status.
   * @param {string} USERTYPE - The type of the user.
   * @param {boolean} cancelButton - Flag indicating if the cancel button should be displayed.
   * @param {Object} data - The data object containing the status of the record.
   * @param {string} data.status - The status of the record.
   * @param {Object} recordFields - The fields of the record.
   * @param {Object} recordFields.record - The record object within the record fields.
   * @param {string} recordUuid - The unique identifier for the record.
   * @param {Object} recordInfos - Additional information about the record.
   * @param {React.Component} ButtonCancelFiled - The React component to render the cancel button.
   * @param {Object} recordStatus - The status constants for the record.
   * @param {string} recordStatus.NORMAL - The normal status constant for the record.
   */
  if (USERTYPE === enumsTypeUser.ADMIN || cancelButton === true) {
    if (data.status === recordStatus.NORMAL) {
      renderCancelRecordButton = (
        <ButtonCancelFiled
          recordFields_record={recordFields.record && recordFields.record}
          recordUuid={recordUuid}
          recordInfos={recordInfos}
        />
      );
    }
  }

  if (USERTYPE === enumsTypeUser.ADMIN || editRecord === true) {
    editRecordButton = (
      <a href={hrefRedirectRecordEdit}>
        <Button
          id="edit_record"
          className="mb-2 mr-0 btn-icon btn-block"
          color="cyan"
          disabled={disabledButtonEdit}
        >
          <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
          {i18n.t("buttonActions.option2")}
        </Button>
      </a>
    );
  }

  /**
   * Sets the `renderHeaderRecordDetail` variable based on the status of the `data` object.
   * This code assigns a JSX `<Alert>` element to the `renderHeaderRecordDetail` variable
   * if `data.status` is equal to `recordStatus.ANULLMENT`. The `<Alert>` element is styled with
   * a danger color and contains various elements such as a FontAwesome icon and translated text
   * from the `i18n` object. If `data.status` is not `recordStatus.ANULLMENT`, `renderHeaderRecordDetail` is not set.
   * @param {Object} data - The data object containing the record details.
   * @param {string} data.status - The status of the record.
   * @param {string} data.responsible - The name of the responsible person for the record.
   * @param {string} data.comment_reason - The reason for the record's status.
   * @param {Object} recordStatus - An object containing different record statuses.
   * @param {string} recordStatus.ANULLMENT - The specific status indicating annulment.
   * @param {Object} i18n - The internationalization object used for translations.
   * @param {Function} i18n.t - A function to translate keys to the appropriate language string.
   * @param {Function} capitalizeText - A function to capitalize text.
   * @returns {JSX.Element|undefined} - Returns a JSX `<Alert>` element with the record details if `data.status` is `recordStatus.ANULLMENT`; otherwise, returns `undefined`.
   */
  if (data.status === recordStatus.ANULLMENT) {
    renderHeaderRecordDetail = (
      <Alert className="mbg-3" color="danger">
        <span className="pr-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          <span className="font-weight-bold mbg-3">{i18n.t("modal.DoneError.header")} : </span>
          <span className="mb-0 mt-1">
            {i18n.t("RecordDetail.label1")}{" "}
            <strong>{capitalizeText(data && data.responsible)}. </strong>
          </span>
          <span className="mb-0">
            {i18n.t("RecordDetail.label2")}: <strong>{data.comment_reason}</strong>
          </span>
        </span>
      </Alert>
    );
  }

  /**
   * Renders the appropriate component for uploading files based on the existence of a tree.
   * @param {boolean} treeExist - Indicates whether a tree structure exists.
   * @param {boolean} isRecordCreate - Flag indicating if a record is being created.
   * @param {string} statusData - The status data associated with the component.
   * @param {boolean} isLoadingExpedietnPath - Flag indicating whether the expedient path is currently loading.
   * @param {boolean} hasExpedientFixedPath - Flag indicating whether an expedient has a fixed path.
   * @param {string} expedientFixedUuid - The UUID of the fixed expedient.
   * @param {string} expedientStatus - The status of the expedient.
   * @param {object} structureTrdExpedient - The structure of the TRD (Table of Retention and Disposition) expedient.
   * @param {string} recordId - The ID of the record associated with the component.
   * @returns {JSX.Element} The rendered component for uploading files.
   */
  if (treeExist === true) {
    uploadFilesContent = (
      <UpLoadFilesTRD
        isRecordCreate={isRecordCreate}
        statusData={data.status}
        isLoadingExpedietnPath={isLoadingExpedietnPath}
        hasExpedientFixedPath={hasExpedientFixedPath}
        expedientFixedUuid={expedientFixedUuid}
        expedientStatus={expedientStatus}
        structureTrdExpedient={structureTrdExpedient}
      />
    );
  } else {
    uploadFilesContent = (
      <RecordUploadFiles
        fileByTrd={false}
        isRecordCreate={isRecordCreate}
        status={data.status}
        recordId={recordId}
        recordUuid={recordUuid}
      />
    );
  }

  /**
   * Array containing objects representing tab content data for display.
   * @type {Array<Object>}
   * @constant
   */
  const tabsContent = [
    {
      title: i18n.t("buttonActions.option1"),
      content: <RecordDetailView isRecordCreate={isRecordCreate} recordId={recordId} />,
      isRecordCreate: true,
    },
    {
      title: i18n.t("stickerMain"),
      content: <RecordSticker />,
    },
    {
      title: i18n.t("recordDetail.files"),
      content: <RecordFileListTables isRecordCreate={isRecordCreate} trdExist={treeExist} />,
    },
    {
      title: i18n.t("recordDetail.upload"),
      content: uploadFilesContent,
    },
    {
      title: i18n.t("notifications.option"),
      content: (
        <Notifications
          isRecordCreate={isRecordCreate}
          recordUuid={recordUuid}
          status={data.status}
          recordNumber={recordInfos.record}
        />
      ),
    },
    {
      title: i18n.t("createWorflow.Tab"),
      content: (
        <WorkflowStart
          isRecordCreate={isRecordCreate}
          recordUuid={recordUuid}
          status={data.status}
          recordNumber={recordInfos.record}
        />
      ),
    },
  ];

  /**
   * This function is in charge of setting the formDetail object given the endpoint's response,
   * it is also in charge of supplying the state of the form
   * @function
   * @returns {void}
   */
  const getFormsByUuid = useCallback(() => {
    let actions = enumsActions.ALL_PERMISSIONS;
    if (USERTYPE === enumsTypeUser.ADMIN) {
      actions = enumsActions.ALL_PERMISSIONS;
    } else if (isRecordCreate === false) {
      actions = enumsActions.NOT_ALLOWED;
    }
    setIsLoadingDetailRecord(true);
    getFormsById(id, actions)
      .then((response) => {
        if (isNullOrUndefined(response.data) === false) {
          const path = response.data.path;
          setPathConfig(response.data.path);
          const backgroundId = getBackGroundByRoute(path);
          const parentId = getParentIdByRoute(path);
          setFoldersIdByPath({
            ...foldersIdByPath,
            backgroundId: backgroundId,
          });
          setExpedientParentByPath(parentId);
          setBackgroundByPath(backgroundId);
          setPathConfiguration(parentId);
          setFormDetail({
            name: response.data.name,
            description: response.data.description,
            uuid: response.data.uuid,
          });
          setStatus(response.data.status);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => setIsLoadingDetailRecord(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Renders the RecordDetails component.
   * @param {String} recordId - This property is in charge of supplying the id of the record.
   * @param {Object} formDetail - This is in charge of bringing the data about the form and its specifications.
   * @returns {Void}
   */
  const getRecord = useCallback(() => {
    if (isNullOrUndefined(recordId) === false && isNullOrUndefined(formDetail.uuid) === false) {
      setIsLoadingDetailRecord(true);
      getRecordsById(formDetail.uuid, recordId, enumsActions.ALL_PERMISSIONS)
        .then((response) => {
          if (isNullOrUndefined(response.data) === false) {
            setRecordFields(response.data);
            setRecordInfo({
              created_at: response.data.created_at,
              user_name: response.data.user_name,
            });
            setRecordUuid(response.data.uuid);
            setRecordInfos({
              record: response.data.record,
              uuid: response.data.uuid,
              status: response.data.status,
            });
          } else {
            showAlertServiceError();
          }
        })
        .finally(() => {
          setIsLoadingDetailRecord(false);
        });
    }
  }, [formDetail.uuid, recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Retrieves the existing tree data by making an API call and updates the component state
   * with the response data.
   * @function
   * @returns {void}
   */
  const getTreeExistBySearch = useCallback(() => {
    getTreeExist().then((response) => {
      if (isNullOrUndefined(response.data) === false) {
        setTreeExist(response.data);
      } else {
        showAlertServiceError();
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * useEffect hook that responds to changes in the 'pathConfiguration' prop.
   * If 'pathConfiguration' is null or undefined, it updates state accordingly.
   * @param {object|null|undefined} pathConfiguration - The configuration object representing the path.
   * @param {function} setExpedientSetPath - State setter function to update the expedient path.
   * @param {function} sethasExpedientFixedPath - State setter function to update the flag indicating if the expedient path is fixed.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(pathConfiguration) === true) {
      setExpedientSetPath({});
      sethasExpedientFixedPath(false);
    }
  }, [pathConfiguration]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect when either the `pathConfiguration` or `recordId` dependencies change.
   * If `pathConfiguration` is not null or undefined, it calls `getHasExpedientStructure` with the provided `recordId`.
   * @function
   * @name useEffect
   * @param {Function} callback - The callback function to execute when dependencies change.
   * @param {Array} dependencies - An array of dependencies that trigger the effect when changed.
   * @param {object} pathConfiguration - The configuration object representing a path.
   * @param {string | number} recordId - The identifier of the record to check for expedient structure.
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(pathConfiguration) === false) {
      getHasExpedientStructure(recordId);
    }
  }, [pathConfiguration, recordId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Executes a side effect in response to changes in the `hasExpedientFixedPath` and `expedientFixedUuid` dependencies.
   * If both conditions are met (i.e., `expedientFixedUuid` is not null or undefined and `hasExpedientFixedPath` is true),
   * it calls the `getTreeExpedientByDomainCallback` function with specific parameters.
   * @param {boolean} hasExpedientFixedPath - Indicates whether an expedient has a fixed path.
   * @param {string | null | undefined} expedientFixedUuid - The UUID of the fixed expedient (can be null or undefined).
   * @returns {void}
   */
  useEffect(() => {
    if (isNullOrUndefined(expedientFixedUuid) === false && hasExpedientFixedPath === true) {
      getTreeExpedientByDomainCallback(null, expedientFixedUuid);
    }
  }, [hasExpedientFixedPath, expedientFixedUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This effect is in charge of bringing the file data when the component is assembled
   * @param { Function } getRecord - This function is used to retrieve the records
   * @returns {void} - Cleanup function.
   */
  useEffect(() => {
    getFormsByUuid();
  }, [getFormsByUuid]);

  /**
   * This effect works when there is data in the recordFields.cancellation_data property, a setData type
   * function is established to fill in the data with the information that is required for this case.
   * @param { Object } recordFields - in charge of bringing the fields of the record
   * @returns {void}
   */
  useEffect(() => {
    if (recordFields.cancellation_data) {
      setData({
        ...data,
        responsible: recordFields.cancellation_data.responsible,
        comment_reason: recordFields.cancellation_data.comment_reason,
        status: recordFields.status,
        recordFields_record: recordFields.record,
      });
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [recordFields]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This effect is in charge of bringing the file data when the component is assembled
   * @param { Function } getRecord - This function is used to retrieve the records
   * @returns {void}
   */
  useEffect(() => {
    getRecord();
  }, [getRecord]);

  /**
   * Cleans up state variables when the component unmounts setFieldsDinamic, setRecordFields.
   * @param {Function} getTreeExistBySearch - This function is in charge of bringing existing tree by search
   * @returns {void} - Cleanup function.
   */
  useEffect(() => {
    getTreeExistBySearch();
  }, [getTreeExistBySearch]);

  /**
   * Cleans up state variables when the component unmounts setFieldsDinamic, setRecordFields.
   * @returns {void} - Cleanup function.
   */
  useEffect(() => {
    return () => {
      setFieldsDinamic([]);
      setRecordFields({});
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingDetailRecord === false) {
    return (
      <Fragment>
        {loaderElement(isLoadingDetailRecord === true || isLoading === true)}
        <AdminTemplate
          heading={i18n.t("RecordDetail.Heading")}
          subheading={i18n.t("RecordDetail.subHeading")}
          icon="news-paper"
        >
          <Card>
            <CardBody>
              {renderHeaderRecordDetail}
              <Alert color="success">
                <Col lg="12" md="12" sm="12" className="button-container mt-2 mt-md-0 mb-2">
                  <Row className="row justify-content-end">
                    <Col lg="auto" md="6" sm="12" className="align-self-end">
                      {renderCancelRecordButton}
                    </Col>

                    <Col lg="auto" md="6" sm="12" className="align-self-end">
                      <a href={`/record/list/table/${id}`}>
                        <Button
                          id="query_result"
                          className="mb-2 mr-2 btn-icon btn-block"
                          color="cyan"
                        >
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} className="mr-2" />
                          {i18n.t("recordData7")}
                        </Button>
                      </a>
                    </Col>

                    <Col lg="auto" md="6" sm="12" className="align-self-end">
                      <a href={hrefRedirectRecordCreate}>
                        <Button
                          id="new_record"
                          className="mb-2 mr-2 btn-icon btn-block"
                          color="cyan"
                          disabled={disabledButtonCreateRecord}
                        >
                          <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
                          {i18n.t("recordData4")}
                        </Button>
                      </a>
                    </Col>

                    <Col lg="auto" md="6" sm="12" className="align-self-end">
                      <a href={`/record/list/form`}>
                        <Button
                          id="new_query"
                          className="mb-2 mr-2 btn-icon btn-block"
                          color="cyan"
                        >
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                          {i18n.t("recordData5")}
                        </Button>
                      </a>
                    </Col>

                    <Col lg="auto" md="6" sm="12" className="align-self-end">
                      {editRecordButton}
                    </Col>
                  </Row>
                </Col>

                <h5 className="alert-heading font-weight-bold">{formDetail.name}</h5>
                <p className="mb-0">{formDetail.description}</p>
                <hr className="mb-1 mt-1" />
                <p className="mb-0 mt-0">
                  {i18n.t("recordList9")}: <strong>{recordInfos.record}</strong>
                </p>
                <p className="mb-0">
                  {i18n.t("itemList.designerLabel3")}:{" "}
                  <strong>{getDateFormat2(new Date(recordInfo.created_at))}</strong>
                </p>
                <p className="mb-0">
                  {i18n.t("recordData3")}: <strong>{recordInfo.user_name}</strong>
                </p>
              </Alert>

              <div>
                <Tabs
                  tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big"
                  transform={false}
                  showInkBar={true}
                  items={tabsContent}
                />
              </div>
            </CardBody>
          </Card>
        </AdminTemplate>
      </Fragment>
    );
  } else {
    return null;
  }
};

RecordDetail.propTypes = {
  isRecordCreate: PropTypes.bool.isRequired,
};

export default RecordDetail;
