export const ACCESS_TOKEN_NAME = "access_token";
export const REFRESH_TOKEN_NAME = "refresh_token";
export const USER_DETAIL = "user";
export const DOMAIN = "dominio";
export const TYPE = "user_type";
export const ADD_REGISTER = "form_permissions_add_register";
export const EDIT_REGISTER = "form_permissions_edit_register";
export const VIEW_REGISTER = "form_permissions_view_register";
export const UPLOAD_FILES = "form_permissions_upload_files";
export const CREATE_EXPEDIENT = "form_permissions_create_expedient";
export const SET_PATH_EXPEDIENT = "form_permissions_set_path_expedient";
export const DELETE_PATH_EXPEDIENT = "form_permissions_delete_path_expedient";
export const VIEW_FILES = "form_permissions_view_files";
export const EDIT_FILE_DATA = "form_permissions_edit_file_data";
export const MOVE_FILE = "form_permissions_move_file";
export const MOVE_FILE_TO_TRASH = "form_permissions_move_file_to_trash";
export const SIGN_ELECTRONICALLY = "form_permissions_sign_electronically";
export const NOTIFICATIONS = "form_permissions_notifications";
export const CANCEL_RECORD = "form_permissions_cancel_record";
export const ADD_WORKFLOW = "form_permissions_add_workflow";
export const VIEW_WORKFLOW = "form_permissions_view_workflow";
export const INTEGRATIONS = "integrations_option";
export const ROLES_PERMISSIONS = "roles_permissions";
export const DESIGNER_FORMS_PERMISSIONS = "designer_forms_permissions";
export const DESIGNER_FORMS = "designer_forms";
export const PASSWORD_EXPIRATION_DAYS = "password_expiration_days";
export const LIMIT_EXPIRATION_DAY = "0";
export const WORKFLOW_FORMS_PERMISSIONS = "workflow_forms_permissions";
export const WORKFLOW_ALL_FORMS = "workflow_all_forms";
export const FIRST_EXECUTION_BOOLEAN = "firs_execution_boolean";
export const SUBMENU_EXPEDIENT_CONSULT_BOOLEAN = "submenu_expedient_consult_boolean";
