import React, { Fragment, useState, useCallback, useEffect } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Card, CardFooter } from "reactstrap";
import { initialPaginationStandart } from "utils/initialPaginationsConfig";
import { isNullOrUndefined } from "utils/validations";
import { showAlertServiceError } from "utils/alerts";
import SignatureListTable from "components/organism/SignatureListTable";
import FilterSignatureState from "components/atoms/FilterSignatureState";
import SearchBox from "components/atoms/SearchBox";
import useIntegrations from "hooks/useIntegrations";
import i18n from "locales/i18n";
import swal from "sweetalert";

const SignaturesList = () => {
  const [listSignatures, setListSignatures] = useState([]);
  const [isLoadingSignatures, setIsLoadingSignatures] = useState(false);
  const [pagination, setPagination] = useState(initialPaginationStandart);
  const [totalPages, setTotalPages] = useState(0);
  const [searchSignatorie, setSearchSignatorie] = useState("");
  const [statusSignatorie, setStatusSignatorie] = useState("");
  const { getSignatures } = useIntegrations();

  /**
   * Fetches signatures based on searchSignatorie criteria and updates the component's state.
   * This function makes an asynchronous call to retrieve a list of signatures based on
   * the provided pagination, searchSignatorie query, and statusSignatorie. It then updates the component's
   * state with the fetched data or displays an alert in case of an error.
   * @param {Object} pagination - An object containing pagination information.
   * @param {number} pagination.page - The current page number.
   * @param {number} pagination.per_page - The number of items to display per page.
   * @param {string} searchSignatorie - The searchSignatorie query to filter signatures.
   * @param {string} statusSignatorie - The statusSignatorie to filter signatures.
   * @returns {void}
   */
  const getSignaturesBySearch = useCallback(() => {
    const { page, per_page } = pagination;
    setIsLoadingSignatures(true);
    getSignatures(page, per_page, searchSignatorie, statusSignatorie)
      .then((response) => {
        const signatureList = response.data.items;
        const pagesSignatures = response.data.pages;
        if (
          isNullOrUndefined(signatureList) === false &&
          isNullOrUndefined(pagesSignatures) === false
        ) {
          if (signatureList.length === 0) {
            swal({
              title: i18n.t("modal.DoneError.header"),
              text: i18n.t("info.SignatureAlert"),
              icon: "info",
              button: i18n.t("modal.Done.footerButton"),
            });
          }
          setListSignatures(signatureList);
          setTotalPages(pagesSignatures);
        } else {
          showAlertServiceError();
        }
      })
      .finally(() => {
        setIsLoadingSignatures(false);
      });
  }, [pagination, searchSignatorie, statusSignatorie]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Perform an effect when the component mounts to retrieve signatures based on a searchSignatorie.
   * This effect is triggered when the component mounts, and it calls the 'getSignaturesBySearch' function to retrieve signatures based on a searchSignatorie.
   * @param {function} getSignaturesBySearch - The function to retrieve signatures based on a searchSignatorie query.
   * @returns {void}
   */
  useEffect(() => {
    getSignaturesBySearch();
  }, [getSignaturesBySearch]);

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Card>
          <CardFooter className="ml-auto btn-icon mr-3">
            <SearchBox
              setSearch={setSearchSignatorie}
              pagination={pagination}
              setPagination={setPagination}
            />
            <FilterSignatureState
              setIsLoading={setIsLoadingSignatures}
              setListSignatures={setListSignatures}
              setStatus={setStatusSignatorie}
              pagination={pagination}
              setPagination={setPagination}
            />
          </CardFooter>

          <SignatureListTable
            listSignatures={listSignatures}
            pagination={pagination}
            isLoadingSignatures={isLoadingSignatures}
            totalPages={totalPages}
            setPagination={setPagination}
          />
        </Card>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default SignaturesList;
