import i18n from "locales/i18n";
import React from "react";
import { nameProperty } from "utils/nameTypes";

/**
   * Generates content for updating user status based on the specified status and row information.
   *
   * @param {number} status - The status code indicating the user status.
   * @param {Object} row - The row object containing information about the user.
   * @param {string} row.original.affected_user_name - The name of the affected user.
   *
   * @returns {string} updateStatusContent - The generated content for updating user status.
   */
export const generateUpdateStatusContent = (userStatus, row) => {
    const statusTranslations = {
        1: i18n.t("log.user.status.one"),
        2: i18n.t("log.user.status.two"),
        3: i18n.t("log.user.status.three"),
        4: i18n.t("log.user.status.four"),
    };

    return (
        i18n.t("log.user.deatails.update") +
        row.original.affected_user_name +
        " " +
        statusTranslations[userStatus]
    );
};

/**
   * Renders the content for failed attempts log messages based on the provided message object.
   *
   * @param {Object} msg - The message object containing information about the failed attempts.
   * @param {number} msg.id - The unique identifier for the message.
   * @param {number} msg.old_value - The old value of failed attempts.
   * @param {number} msg.new_value - The new value of failed attempts.
   *
   * @returns {React.ReactNode} renderedFailedAttempts - The React component rendering the failed attempts log message.
   */
export const renderFailedAttempts = (msg) => {
    const failedAttemptsZero = 0
    if (msg.old_value >= failedAttemptsZero){
        if (msg.new_value > failedAttemptsZero) {
            return (
                <div key={msg.id}>
                    <span>
                        {i18n.t("log.user.failed.attempts")} {msg.old_value} {i18n.t("log.user.label2")} {msg.new_value}
                    </span>
                </div>
            );
        } else if (msg.new_value === failedAttemptsZero) {
            return (
                <div key={msg.id}>
                    <span>
                        {i18n.t("log.user.failed.attempts.restart")}
                    </span>
                </div>
            );
        }
    }
    
    return null;
};

/**
   * Returns the localized document type based on the provided value.
   *
   * @param {number} msg_value - The value indicating the document type.
   *
   * @returns {string} documentType - The localized document type.
   */
const returnDocumentType = (msg_value) => {
    if (msg_value === 1) {
        return i18n.t(
            "createusers.documentType.option1"
        );
    } else {
        return i18n.t(
            "createusers.documentType.option2"
        );
    }
}

/**
   * Returns the localized user type based on the provided value.
   *
   * @param {number} msg_value - The value indicating the user type.
   *
   * @returns {string} userType - The localized user type.
   */
const returnChangeUserType = (msg_value) => {
    switch (msg_value) {
        case 1:
            return i18n.t("dashboard.label12");
        case 2:
            return i18n.t("dashboard.label11");
        case 3:
            return i18n.t("log.user.admin");
        default:
            return "";
    }
}

/**
   * Retrieves log details based on the provided message and field name.
   *
   * @param {Object} msg - The message object containing details about the log.
   * @param {string} msg.field - The field being modified.
   * @param {number|string} msg.old_value - The old value of the field.
   * @param {number|string} msg.new_value - The new value of the field.
   * @param {string} fieldName - The name of the field for which log details are requested.
   *
   * @returns {string} logDetails - The log details for the specified field.
   */
const getLogDetails = (msg, fieldName) => {
    const getLabel = (label) => i18n.t(`log.user.${label}`);
    const getModifiedDetails = (field, oldValue, newValue) => {
        return `${i18n.t("log.user.details.modified")} ${nameProperty(field)} ${getLabel("label1")} ${oldValue} ${getLabel("label2")} ${newValue}`;
    };

    switch (fieldName) {
        case "document_type":
            return getModifiedDetails(msg.field, returnDocumentType(msg.old_value), returnDocumentType(msg.new_value));
        case "user_type":
            return getModifiedDetails(msg.field, returnChangeUserType(msg.old_value), returnChangeUserType(msg.new_value));
        default:
            return `${i18n.t("log.user.details.modified")} ${nameProperty(msg.field)} ${getLabel("label1")} ${msg.old_value} ${getLabel("label2")} ${msg.new_value}`;
    }
};

/**
 * Renders the content for updating user log messages based on the provided message.
 *
 * @param {Object} msg - The message object containing information about the update user log.
 * @param {string} msg.field - The field being updated.
 * @param {number|string} msg.old_value - The old value of the field.
 * @param {number|string} msg.new_value - The new value of the field.
 *
 * @returns {React.ReactNode} renderedUpdateUser - The React component rendering the update user log message.
 */
export const renderUpdateUser = (msg) => {
    if (msg.field === "document_type" || msg.field === "user_type") {
        return (
            <li>
                <span>{getLogDetails(msg, msg.field)}</span>
            </li>
        );
    } else {
        return (
            <li>
                <span>{getLogDetails(msg, msg.field)}</span>
            </li>
        );
    }
};