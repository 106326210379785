import React, { useCallback, useState } from "react";
import { useTrdContext } from "contextAPI/TrdContext";
import { useWorkflowContext } from "contextAPI/WorkflowContext";
import { isNullOrUndefined } from "utils/validations";
import useTrd from "hooks/useTrd";
import useRecord from "hooks/useRecord";
import swal from "sweetalert";
import i18n from "locales/i18n";
import { capitalizeText } from "utils/formatText";

const RecordDetailContext = React.createContext(undefined);

export function RecordDetailContextProvider({ children }) {
  const { getTreeByDomain } = useTrd();
  const {
    getRecordsById,
    createFileTRD,
    createFileByRecord,
    createFileTRDByTask,
    createFileByTaskRecord,
    createFileTRDByProcess,
    createFileByProcessRecord,
  } = useRecord();
  const { reStructureData, parentData } = useTrdContext();
  const { uploadFormWorkflow, setUploadFormWorkflow } = useWorkflowContext();
  const [hasFileWithoutTrd, setHasFileWithoutTrd] = useState();
  const [recordIdByFile, setRecordIdByFile] = useState(null);
  const [isOpenFileEdit, setIsOpenFileEdit] = useState(false);
  const [isOpenFileEditTrd, setIsOpenFileEditTrd] = useState(false);
  const [isOpenFileMoveTrd, setIsOpenFileMoveTrd] = useState(false);
  const [closeFileTables, setCloseFileTables] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadForm, setUploadForm] = useState();
  const [files, setFiles] = useState([]);
  const [isChangePage, setIsChangePage] = useState(false);
  const [recordFields, setRecordFields] = useState({});
  const [recordInfo, setRecordInfo] = useState({
    created_at: "",
    user_name: "",
  });
  const [formDetail, setFormDetail] = useState({
    name: "",
    description: "",
  });
  const [recordNumber, setRecordNumber] = useState("");
  const [fields, setFields] = useState([]);
  const [fieldsDinamic, setFieldsDinamic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [parentId, setParentId] = useState("");
  const [backgroundId, setBackgroundId] = useState("");
  const [recordUuid, setRecordUuid] = useState("");
  const [formUuid, setFormUuid] = useState("");
  const [structureRecordTRD, setStructureRecordTRD] = useState([]);
  const [showModalExpedient, setShowModalExpedient] = useState(false);
  const [isErrorDate, setIsErrorDate] = useState(false);
  const [isErrorDateTime, setIsErrorDateTime] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [pathConfiguration, setPathConfiguration] = useState(null);
  const [backgroudByPath, setBackgroundByPath] = useState(null);
  const [showTrdLevelsOrdenation, setShowTrdLevelsOrdenation] = useState(false);
  const [showWizardButtons, setShowWizardButtons] = useState(false);
  const [pathConfig, setPathConfig] = useState();
  const [currentPath, setCurrentPath] = useState([]);
  const [disabledUploadFile, setDisabledUploadFile] = useState(true);
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
  const [refreshValidateDropzone, setRefreshValidateDropzone] = useState(false);
  const [isOpenFileMoveTrash, setIsOpenFileMoveTrash] = useState(false);

  /**
   * Fetches detailed information about a specific record identified by the given form ID and record ID.
   * Updates various state variables based on the retrieved record data.
   * @param {string} formId - The ID of the form to which the record belongs.
   * @param {string} recordId - The ID of the specific record to fetch details for.
   * @param {object} actions - Additional actions or configurations for retrieving the record.
   * @param {function} setIsLoading - State setter function to indicate loading state.
   * @param {function} setRecordNumber - State setter function for updating the record number.
   * @param {function} setRecordFields - State setter function for updating the record fields.
   * @param {function} setRecordInfo - State setter function for updating the record information.
   * @param {function} setRecordUuid - State setter function for updating the record UUID.
   * @returns {void}
   */
  const getRecordDetail = useCallback((formId, recordId, actions) => {
    setIsLoading(true);
    getRecordsById(formId, recordId, actions)
      .then((response) => {
        setRecordNumber(response.data.record);
        setRecordFields(response.data);
        setRecordInfo({
          created_at: response.data.created_at,
          user_name: response.data.user_name,
        });
        setRecordUuid(response.data.uuid);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setRecordFields({});
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Displays a SweetAlert (pop-up notification) with success message and customizable content.
   * @param {Object} options - Configuration options for the SweetAlert.
   * @param {string} options.title - The title of the SweetAlert popup.
   * @param {string} options.text - The text content of the SweetAlert popup.
   * @param {string} options.icon - The icon type displayed in the SweetAlert popup (e.g., "success", "error", "warning").
   * @param {string} options.button - The text displayed on the button in the SweetAlert popup.
   * @returns {void}
   */
  const showSweetAlert = () => {
    swal({
      title: i18n.t("modal.DoneError.header"),
      text: i18n.t("recordDetail.AlertFiles"),
      icon: "success",
      button: i18n.t("modal.Done.footerButton"),
    });
  };

  /**
   * Resets various state values related to form uploading and management.
   * @param {function} setUploadFormWorkflow - State setter function for the upload form workflow.
   * @param {object} uploadFormWorkflow - Current state of the upload form workflow.
   * @param {function} setHasFileWithoutTrd - State setter function for indicating whether there are files without TRD.
   * @param {function} setDisabledUploadFile - State setter function for the upload file disable flag.
   * @param {function} setStartDate - State setter function for the start date value.
   * @param {function} setUploadForm - State setter function for the upload form state.
   * @param {object} uploadForm - Current state of the upload form.
   * @param {function} setFiles - State setter function for the files array.
   */
  const resetFormState = () => {
    setUploadFormWorkflow({
      ...uploadFormWorkflow,
      files: [],
    });
    setRefreshValidateDropzone(true);
    setHasErrorOccurred(false);
    setHasFileWithoutTrd();
    setDisabledUploadFile(true);
    setFiles([]);
  };

  /**
   * Handles errors caught during API requests.
   * @param {object} error - The error object caught.
   * @param {object} error.response - The response object within the error.
   * @param {object} error.response.data - The data object within the response.
   * @param {string} error.response.data.code - The error code.
   * @param {object[]} error.response.data.data - An array containing error details.
   * @param {string} error.response.data.data[].file - The file associated with the error.
   */
  const handleCatchError = (error) => {
    if (
      error &&
      error.response.data.code === "500054" &&
      isNullOrUndefined(error.response.data.data[500036]) === false
    ) {
      let errorFilesList = error.response.data.data[500036];
      let fileListItems = errorFilesList.map(
        (file, index) => `<li key=${index}><em>${capitalizeText(file)}</em></li>`
      );

      swal({
        title: i18n.t("modal.DoneError.header"),
        text: `${i18n.t("500054")}`,
        content: {
          element: "div",
          attributes: {
            innerHTML: `<ul>${fileListItems.join("")}</ul>`,
            className: "custom-content-class",
          },
        },
        icon: "error",
        dangerMode: true,
        button: i18n.t("modal.Done.footerButton"),
      });
    }
    resetFormState();
  };

  /**
   * Handles form submission by uploading files associated with a record.
   * @param {string} recordId - The ID of the record to which the files are being attached.
   * @param {boolean} fileByTrd - Indicates whether the file is being uploaded for a TRD (true) or by record (false).
   * @param {object} actions - Actions object containing various action functions.
   *   - {function} resetFormState - Function to reset form-related state.
   *   - {function} showSweetAlert - Function to display a sweet alert notification.
   *   - {function} setIsLoading - Function to set loading state.
   *   - {function} setIsOpenModal - Function to toggle the modal state.
   * @returns {void}
   */
  const handleOnSubmit = (recordId, fileByTrd, actions) => {
    const formData = new FormData();
    const uploadedFiles = uploadFormWorkflow.files;
    setIsLoading(true);
    if (isNullOrUndefined(files) === false && files.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("form_uuid", uploadForm);

      if (fileByTrd) {
        createFileTRD(recordId, parentData.id, formData, actions)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      } else {
        createFileByRecord(recordIdByFile, formData, actions)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      }
    } else {
      setIsLoading(false);
    }
    return isLoading;
  };

  /**
   * Handles form submission for a specific process task, record, and form ID, with optional file upload.
   * @param {string} taskId - The ID of the process task.
   * @param {string} recordId - The ID of the record associated with the form submission.
   * @param {string} formId - The ID of the form being submitted.
   * @param {boolean} fileByTrd - Indicates whether the file should be processed by TRD (true) or not (false).
   * @param {Array<File>} files - An array of files to be uploaded.
   * @param {Object} uploadForm - An object containing additional form data such as subject, publication date, and support type.
   * @param {function} createFileTRDByProcess - Function to create a file associated with a process using TRD.
   * @param {function} createFileByProcessRecord - Function to create a file associated with a process record.
   * @param {function} resetFormState - Function to reset the form state after successful or failed submission.
   * @param {function} showSweetAlert - Function to display a sweet alert or notification upon successful submission.
   * @param {function} setIsLoading - State setter function to control loading state.
   * @param {function} setIsOpenModal - State setter function to control the visibility of a modal.
   * @returns {void}
   */
  const handleOnSubmitProcess = (taskId, recordId, formId, fileByTrd) => {
    setIsLoading(true);
    const formData = new FormData();
    let uploadedFiles = uploadFormWorkflow.files;

    if (uploadFormWorkflow.files.length > 0) {
      uploadedFiles = uploadFormWorkflow.files;
    } else {
      uploadedFiles = files;
    }

    if (isNullOrUndefined(files) === false && files.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("form_uuid", formId);

      if (fileByTrd) {
        createFileTRDByProcess(taskId, parentData.id, recordId, formData)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      } else {
        createFileByProcessRecord(taskId, recordId, formData)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            resetFormState();
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      }
    } else {
      setIsLoading(false);
    }
    return isLoading;
  };

  /**
   * Handles the submission of a task with associated file uploads.
   * @param {string} taskId - The ID of the task being submitted.
   * @param {string} recordId - The ID of the record associated with the task.
   * @param {string} formId - The ID of the form related to the task.
   * @param {boolean} fileByTrd - Flag indicating whether to create a TRD file by task.
   * @param {Array<File>} files - Array of File objects representing the files to be uploaded.
   * @param {Object} uploadForm - Object containing upload form data (e.g., subject, publication_at, support_type).
   * @param {function} createFileTRDByTask - Function to create a TRD file associated with the task.
   * @param {function} createFileByTaskRecord - Function to create a file associated with the task record.
   * @param {function} resetFormState - Function to reset the form state after successful submission.
   * @param {function} showSweetAlert - Function to display a success alert after successful submission.
   * @param {function} setIsLoading - State setter function to update loading state.
   * @param {function} setIsOpenModal - State setter function to control the visibility of a modal.
   * @returns {void}
   */
  const handleOnSubmitTask = (taskId, recordId, formId, fileByTrd) => {
    setIsLoading(true);
    const formData = new FormData();
    let uploadedFiles = uploadFormWorkflow.files;

    if (uploadFormWorkflow.files.length > 0) {
      uploadedFiles = uploadFormWorkflow.files;
    } else {
      uploadedFiles = files;
    }

    if (isNullOrUndefined(files) === false && files.length > 0) {
      uploadedFiles.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("form_uuid", formId);

      if (fileByTrd) {
        createFileTRDByTask(taskId, parentData.id, recordId, formData)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      } else {
        createFileByTaskRecord(taskId, recordId, formData)
          .then((response) => {
            if (response.status === 201) {
              resetFormState();
              showSweetAlert();
            }
          })
          .finally(() => {
            setHasErrorOccurred(false);
            setIsLoading(false);
            setIsOpenModal(false);
          })
          .catch((error) => {
            handleCatchError(error);
          });
      }
    } else {
      setIsLoading(false);
    }

    return isLoading;
  };

  /**
   * Fetches a general tree structure related to a domain and updates the state accordingly.
   * @param {function} setIsLoading - State setter function for setting loading state.
   * @param {function} setStructureRecordTRD - State setter function for updating the tree structure related to records.
   * @param {function} getTreeByDomain - Function responsible for fetching the tree structure by domain.
   * @param {function} reStructureData - Function to restructure the fetched tree data.
   * @returns {void}
   */
  const getTreeGeneral = useCallback(
    () => {
      setIsLoading(true);
      getTreeByDomain()
        .then((response) => {
          if (response.status === 200) {
            setStructureRecordTRD(reStructureData(response.data.data));
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setStructureRecordTRD([]);
        });
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * Validates whether a record can be created in a subseries based on the specified criteria.
   * @param {number} level - The level of the record within the hierarchy.
   * @param {string} id - The identifier of the record.
   * @param {Array<object>} expedientRecord - The array of records representing the expedient structure.
   * @returns {boolean} - Returns true if the record can be created, otherwise false.
   */
  const validateSerieinSubserieRecord = (level, id, expedientRecord) => {
    let isCreate = false;

    const validateSection = expedientRecord.some((structure) => structure.parentId === id);

    if (level === 4) {
      isCreate = true;
      return isCreate;
    } else if (validateSection) {
      isCreate = false;
    } else {
      isCreate = true;
    }
    return isCreate;
  };

  /**
   * Handles the opening of the file edit functionality based on the specified category.
   * @param {Event} event - The event object triggered by the user action.
   * @param {string} category - The category of the file ("trd" or other).
   * @returns {void}
   */
  const handleOpenEditFile = (event, category) => {
    event.stopPropagation();
    setCloseFileTables(false);
    if (category === "trd") {
      setIsOpenFileEdit(false);
      setIsOpenFileEditTrd(true);
    } else {
      setIsOpenFileEdit(true);
      setIsOpenFileEditTrd(false);
    }
  };

  /**
   * Handles the event to open a file move action, preventing event propagation and executing specific actions based on conditions.
   * @param {Event} eventMoveFile - The event object triggering the file move action.
   * @param {string} backgroundId - The background ID associated with the file.
   * @param {string} levelId - The level ID of the file.
   * @param {string} expedientId - The expedient ID of the file.
   * @returns {void}
   */
  const handleOpenMoveFile = (eventMoveFile, backgroundId, levelId, expedientId) => {
    eventMoveFile.stopPropagation();
    if (levelId === expedientId) {
      return swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("trd.moveFile7"),
        icon: "info",
        button: { text: i18n.t("modal.Done.footerButton") },
      });
    }
    if (backgroundId === null) {
      return swal({
        title: i18n.t("modal.DoneError.header"),
        text: i18n.t("recordDetail.AlertFiles"),
        icon: "warning",
        button: { text: i18n.t("modal.Done.footerButton") },
      });
    }
    setIsOpenFileMoveTrd(true);
  };

  /**
   * Retrieves the UUID from the first object in the provided route object based on a minimum size condition.
   * @param {object} route - The route object containing one or more objects.
   * @returns {string | undefined} The UUID of the first object in the route, or undefined if no valid object exists.
   */
  const getBackGroundByRoute = (route) => {
    const minimumSizeOfObject = 0;
    const routeSize = Object.values(route).length;
    if (routeSize > minimumSizeOfObject) {
      return route[minimumSizeOfObject].uuid;
    }
  };

  /**
   * Retrieves the parent ID from a provided route object based on specific conditions.
   * @param {Object} route - The route object containing nested key-value pairs representing the route structure.
   * @returns {string} The parent ID extracted from the route object. If no valid parent ID is found, an empty string is returned.
   */
  const getParentIdByRoute = (route) => {
    let parentId = "";

    for (const [key, value] of Object.entries(route)) {
      if (key === "4" && value.uuid !== undefined) {
        parentId = value.uuid;
        break;
      } else if (key === "3" && value.uuid !== undefined) {
        parentId = value.uuid;
      }
    }
    return parentId;
  };

  /**
   * Toggles the state of opening or closing the file move trash component.
   * @param {Event} event - The event object that triggered the function.
   * @returns {void}
   */
  const handleOpenMoveTrash = (event) => {
    event.stopPropagation();
    setIsOpenFileMoveTrash((prevState) => !prevState);
  };

  return (
    <RecordDetailContext.Provider
      value={{
        isOpenModal,
        setIsOpenModal,
        uploadForm,
        setUploadForm,
        files,
        setFiles,
        isLoading,
        setIsLoading,
        handleOnSubmit,
        handleOnSubmitTask,
        parentId,
        setParentId,
        validateSerieinSubserieRecord,
        structureRecordTRD,
        setStructureRecordTRD,
        getTreeGeneral,
        showModalExpedient,
        setShowModalExpedient,
        backgroundId,
        setBackgroundId,
        recordFields,
        setRecordFields,
        fields,
        setFields,
        getRecordDetail,
        fieldsDinamic,
        setFieldsDinamic,
        setRecordUuid,
        recordInfo,
        recordUuid,
        formDetail,
        setFormDetail,
        formUuid,
        setFormUuid,
        isErrorDate,
        setIsErrorDate,
        isErrorDateTime,
        setIsErrorDateTime,
        startDate,
        setStartDate,
        handleOnSubmitProcess,
        setRecordInfo,
        isChangePage,
        setIsChangePage,
        recordIdByFile,
        setRecordIdByFile,
        isOpenFileEdit,
        setIsOpenFileEdit,
        handleOpenEditFile,
        isOpenFileEditTrd,
        setIsOpenFileEditTrd,
        closeFileTables,
        setCloseFileTables,
        recordNumber,
        pathConfiguration,
        setPathConfiguration,
        backgroudByPath,
        setBackgroundByPath,
        showTrdLevelsOrdenation,
        setShowTrdLevelsOrdenation,
        showWizardButtons,
        setShowWizardButtons,
        isOpenFileMoveTrd,
        setIsOpenFileMoveTrd,
        handleOpenMoveFile,
        getBackGroundByRoute,
        getParentIdByRoute,
        currentPath,
        setCurrentPath,
        pathConfig,
        setPathConfig,
        disabledUploadFile,
        setDisabledUploadFile,
        hasFileWithoutTrd,
        setHasFileWithoutTrd,
        hasErrorOccurred,
        setHasErrorOccurred,
        refreshValidateDropzone,
        setRefreshValidateDropzone,
        handleOpenMoveTrash,
        isOpenFileMoveTrash,
        setIsOpenFileMoveTrash,
      }}
    >
      {children}
    </RecordDetailContext.Provider>
  );
}

export function useRecordDetailContext() {
  const context = React.useContext(RecordDetailContext);
  if (!context) {
    throw new Error("useRecordDetailContext debe estar dentro del proveedor RecordDetailContext.");
  }
  return context;
}
