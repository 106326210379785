import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { UncontrolledButtonDropdown, DropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import i18n from "locales/i18n";

const ButtonAddMembers = (props) => {
  const { setShowSignatoriesForm } = props;

  /**
   * Handles a click event by displaying the signatories form.
   * This function is used to respond to a click event by setting a state to show the signatories form.
   * @param {function} setShowSignatoriesForm - The state setter function to control the visibility of the signatories form.
   * @returns {void} This function does not return a value.
   */
  const handleOnClick = () => {
    setShowSignatoriesForm(true);
  };

  return (
    <Fragment>
      <div>
        <UncontrolledButtonDropdown>
          <DropdownToggle
            className="button"
            color="info"
            size="lg"
            onClick={handleOnClick}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>{i18n.t("createRoles.configFormAdd")}</span>
          </DropdownToggle>
        </UncontrolledButtonDropdown>
      </div>
    </Fragment>
  );
};

ButtonAddMembers.propTypes = {
  setShowSignatoriesForm: PropTypes.func.isRequired,
};

export default ButtonAddMembers;
